import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import {
  Container,
  Typography,
  Grid,
  TableRow,
  Box,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Tab,
  Tabs,
} from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import { LineChart } from './LineChart';
import dataService from '../../../../config/dataService';
import { Api } from '../../../../config/api';
import { toast } from 'react-toastify';
import { RefundChart } from './RefundChart';

import Loader from '../../../../component/customComponents/loader/Loader';
import AdminIndex from '../../AdminIndex';
//#region ALL HOOKS AND VARIABALS
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


const { getRefundChartData, getRevenueChartData, getChartData } = AdminIndex.AdminSagaActions;
export default function Reports() {
  let isToken = localStorage.getItem('auth');
  let token = localStorage.getItem('auth');
  const dispatch = AdminIndex.useDispatch();
  const [duration, setDuration] = useState('Daily');
  const [durationRevenue, setDurationRevenue] = useState('Daily');
  const [refundDuration, setRefundDuration] = useState('Daily');
  const [type, setType] = useState('Regular');
  const [data, setData] = useState([]);
  const [refundData, setRefundData] = useState([]);
  const [revenueData, setRevenueData] = useState([]);
  const [value, setValue] = useState(1);
  const [value1, setValue1] = useState(0);

  const rows = [
    createData('28-04-2022', 'Name', 'Name', 'Name', '500000', 'Approved'),
    createData('28-04-2022', 'Name', 'Name', 'Name', '500000', 'Approved'),
    createData('28-04-2022', 'Name', 'Name', 'Name', '500000', 'Approved'),
    createData('28-04-2022', 'Name', 'Name', 'Name', '500000', 'Approved'),
    createData('28-04-2022', 'Name', 'Name', 'Name', '500000', 'Approved'),
  ];
  //#endregion

  //#region ALL EVENT HANDLERS AND  FUCTIONS
  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeTab1 = (event, newValue) => {
    setValue1(newValue);
  };

  const handleChangeDuration = (event) => {
    setDuration(event.target.value);
  };

  const handleChangeDurationRevenue = (event) => {
    setDurationRevenue(event.target.value);
  };

  const handleChangeRefundDuration = (event) => {
    setRefundDuration(event.target.value);
  };
  const handleChangeType = (event) => {
    setType(event.target.value);
  };

  function createData(date, user, seller, product, price, status) {
    return { date, user, seller, product, price, status };
  }

  const getRefundChartdata = async (e) => {
    let urlencoded = new URLSearchParams();
    dispatch(getRefundChartData(token,urlencoded));
    // await dataService
    //   .post(Api.Admin.chartDetailsForRefund, urlencoded)
    //   .then((res) => {
    //     if (res.data.status == 1) {
    //       setRefundData(res.data.data[0]);
    //     }
    //   })
    //   .catch((err) => console.log(err?.message || 'Something went wrong'));
  };

  const loading = AdminIndex.useSelector((state) => {
    return state.AdminSaga.loading;
  });

  const refundChartData = AdminIndex.useSelector((state) => {
    return state.AdminSaga.refundChartData;
  });

  useEffect(() => {
    setRefundData(refundChartData);
  }, [refundChartData]);

  const fetchRevenueChartdata = async (e) => {
    let urlencoded = new URLSearchParams();
    dispatch(getRevenueChartData(token,urlencoded))
    // await dataService
    //   .post(Api.Admin.totalRevenueGenerated, urlencoded)
    //   .then((res) => {
    //     if (res.data.status == 1) {
    //       setRevenueData(res.data.data[durationRevenue]);
    //     }
    //   })
    //   .catch((err) => console.log(err?.message || 'Something went wrong'));
  };

  const revenueChartData = AdminIndex.useSelector((state) => {
    return state.AdminSaga.revenueChartData;
  });

  useEffect(() => {
    setRevenueData(revenueChartData);
  }, [revenueChartData]);

  const fetchChartdata = async (e) => {
    if (e) {
      let val = e.target.value;
      let urlencoded = new URLSearchParams();
      if (val == 'Regular') {
        urlencoded.append('orderType', 0);
      } else if (val == 'Custom') {
        urlencoded.append('orderType', 1);
      }
      dispatch(getChartData(token,urlencoded));
      // await dataService
      //   .post(Api.Admin.chartDetails, urlencoded)
      //   .then((res) => {
      //     if (res.data.status == 1) {
      //       setData(res.data.data[0]);
      //     }
      //   })
      // .catch((err) => console.log(err?.message || 'Something went wrong'));
    } else {
      let urlencoded = new URLSearchParams();
      dispatch(getChartData(token,urlencoded));
      // await dataService
      //   .post(Api.Admin.chartDetails, urlencoded)
      //   .then((res) => {
      //     if (res.data.status == 1) {
      //       setData(res.data.data[0]);
      //     }
      //   })
      //   .catch((err) => console.log(err?.message || 'Something went wrong'));
    }
  };

  const chartData = AdminIndex.useSelector((state) => {
    return state.AdminSaga.chartData;
  });

  useEffect(() => {
    setData(chartData);
  }, [chartData]);

  useEffect(() => {
    fetchChartdata();
    getRefundChartdata();
    fetchRevenueChartdata();
  }, []);

  isToken =
    isToken == '' || isToken == null || isToken == undefined ? false : true;
  //#endregion

  //#region RETURN JSX
  return (
    <>{loading ? (
      <Loader />
    ) : (
      <>
      <Container maxWidth="false">
        <Box
          sx={{
            display: 'flex',
            bgcolor: 'background.paper',
            borderRadius: 1,
          }}
        >
          <Box sx={{ flexGrow: 1 }}></Box>
          <Box
            sx={{ width: '100%', typography: 'body1', mt: { xs: 2, lg: 0 } }}
          >
            <TabContext value={value}>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: 'black',
                  display: 'flex',
                  py: 2,
                }}
              >
                <Grid alignItems="center" container>
                  <Grid item xs={3.5} lg={2}>
                    <FormControl
                      fullWidth
                      size="small"
                      sx={{ my: 1, width: '230px', alignSelf: 'center' }}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Duration
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={duration}
                        label="Duration"
                        onChange={handleChangeDuration}
                        sx={{
                          borderRadius: 0,
                        }}
                      >
                        <MenuItem value={'Daily'}>Daily</MenuItem>
                        <MenuItem value={'Weekly'}>Weekly</MenuItem>
                        <MenuItem value={'Monthly'}>Monthly</MenuItem>
                        <MenuItem value={'Quarterly'}>Quarterly</MenuItem>
                        <MenuItem value={'Yearly'}>Yearly</MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl
                      fullWidth
                      size="small"
                      sx={{ my: 1, width: '230px', alignSelf: 'center' }}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={type}
                        label="Type"
                        onChange={(e) => {
                          handleChangeType(e);
                          fetchChartdata(e);
                        }}
                        sx={{
                          borderRadius: 0,
                        }}
                      >
                        <MenuItem value={'Regular'}>Regular Order</MenuItem>
                        <MenuItem value={'Custom'}>Custom Order</MenuItem>
                        <MenuItem value={'Combined'}>Combined Order</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={10}>
                    <Tabs
                      value={value}
                      TabIndicatorProps={{ sx: { display: 'none' } }}
                      onChange={handleChangeTab}
                      aria-label="lab API tabs example"
                      sx={{
                        width: '100%',
                        display: 'felx',
                        '& .MuiTabs-flexContainer': {
                          justifyContent: 'space-around !important',
                        },
                        borderBottom: 0,
                        '& .Mui-selected ': {
                          bgcolor: '#2596be',
                          m: 2,
                          borderRadius: '25px',
                          color: 'black !important',
                          width: { xs: '40px', lg: '220px' },
                        },
                        '& .MuiTabs-indicator': {
                          height: 0,
                          color: 'black',
                        },
                      }}
                    >
                      <Tab
                        sx={{ textTransform: 'none !important' }}
                        label="7 Days"
                        value="1"
                      />
                      <Tab
                        sx={{ textTransform: 'none !important' }}
                        label="30 Days"
                        value="2"
                      />
                      <Tab
                        sx={{ textTransform: 'none !important' }}
                        label="90 Days"
                        value="3"
                      />
                    </Tabs>
                  </Grid>
                </Grid>
              </Box>
            </TabContext>
          </Box>
        </Box>
      </Container>
      <Container sx={{ mt: 2, p: 3 }} maxWidth="false">
        {duration == 'Daily' ? (
          <Grid container sx={{ display: 'flex', justifyContent: 'center' }}>
            <Grid item xs={12} md={6} lg={10} sm={12}>
              <Typography align="center" variant="h4" sx={{ mb: 2 }}>
                Transaction volume
              </Typography>
              <LineChart price={data} chartType="line" type={duration} />
            </Grid>
          </Grid>
        ) : (
          <Grid container>
            <Grid item xs={12} md={6} lg={6} sm={12}>
              <Typography align="center" variant="h4" sx={{ mb: 2 }}>
                Transaction volume
              </Typography>
              <LineChart price={data} chartType="line" type={duration} />
            </Grid>
            <Grid item xs={12} md={6} lg={6} sm={12}>
              <Typography align="center" variant="h4" sx={{ mb: 2 }}>
                Transaction volume
              </Typography>
              <LineChart
                price={data}
                chartType="column"
                type={duration}
                color={{
                  type: 'solid',
                  colors: ['transparent'],
                }}
              />
            </Grid>
          </Grid>
        )}
      </Container>
      <br />
      <br />

      <Container maxWidth="false">
        <Box
          sx={{
            display: 'flex',
            bgcolor: 'background.paper',
            borderRadius: 1,
          }}
        >
          <Box sx={{ flexGrow: 1 }}></Box>
          <Box
            sx={{ width: '100%', typography: 'body1', mt: { xs: 2, lg: 0 } }}
          >
            <TabContext value={value}>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: 'black',
                  display: 'flex',
                  py: 2,
                }}
              >
                <Grid alignItems="center" container>
                  <Grid item xs={3.5} lg={2}>
                    <FormControl
                      fullWidth
                      size="small"
                      sx={{ my: 1, width: '230px', alignSelf: 'center' }}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Duration
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={refundDuration}
                        label="Duration"
                        onChange={handleChangeRefundDuration}
                        sx={{
                          borderRadius: 0,
                        }}
                      >
                        <MenuItem value={'Daily'}>Daily</MenuItem>
                        <MenuItem value={'Weekly'}>Weekly</MenuItem>
                        <MenuItem value={'Monthly'}>Monthly</MenuItem>
                        <MenuItem value={'Quarterly'}>Quarterly</MenuItem>
                        <MenuItem value={'Yearly'}>Yearly</MenuItem>
                      </Select>
                    </FormControl>
                    {/* <FormControl
                      fullWidth
                      size="small"
                      sx={{ my: 1, width: '230px', alignSelf: 'center' }}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={type}
                        label="Type"
                        onChange={(e) => {
                          handleChangeType(e);
                          fetchChartdata(e);
                        }}
                        sx={{
                          borderRadius: 0,
                        }}
                      >
                        <MenuItem value={'Regular'}>Regular Order</MenuItem>
                        <MenuItem value={'Custom'}>Custom Order</MenuItem>
                        <MenuItem value={'Combined'}>Combined Order</MenuItem>
                      </Select>
                    </FormControl> */}
                  </Grid>
                  {/* <Grid item xs={12} lg={10}>
                    <Tabs
                      value={value}
                      TabIndicatorProps={{ sx: { display: 'none' } }}
                      onChange={handleChangeTab}
                      aria-label="lab API tabs example"
                      sx={{
                        width: '100%',
                        display: 'felx',
                        '& .MuiTabs-flexContainer': {
                          justifyContent: 'space-around !important',
                        },
                        borderBottom: 0,
                        '& .Mui-selected ': {
                          bgcolor: '#2596be',
                          m: 2,
                          borderRadius: '25px',
                          color: 'black !important',
                          width: { xs: '40px', lg: '220px' },
                        },
                        '& .MuiTabs-indicator': {
                          height: 0,
                          color: 'black',
                        },
                      }}
                    >
                      <Tab
                        sx={{ textTransform: 'none !important' }}
                        label="7 Days"
                        value="1"
                      />
                      <Tab
                        sx={{ textTransform: 'none !important' }}
                        label="30 Days"
                        value="2"
                      />
                      <Tab
                        sx={{ textTransform: 'none !important' }}
                        label="90 Days"
                        value="3"
                      />
                    </Tabs>
                  </Grid> */}
                </Grid>
              </Box>
            </TabContext>
          </Box>
        </Box>
      </Container>
      <Container sx={{ mt: 2, p: 3 }} maxWidth="false">
        {refundDuration == 'Daily' ? (
          <Grid container sx={{ display: 'flex', justifyContent: 'center' }}>
            <Grid item xs={12} md={6} lg={10} sm={12}>
              <Typography align="center" variant="h4" sx={{ mb: 2 }}>
                Refund volume
              </Typography>
              <RefundChart
                price={refundData}
                chartType="line"
                type={refundDuration}
              />
            </Grid>
          </Grid>
        ) : (
          <Grid container>
            <Grid item xs={12} md={6} lg={6} sm={12}>
              <Typography align="center" variant="h4" sx={{ mb: 2 }}>
                Refund volume
              </Typography>
              <RefundChart
                price={refundData}
                chartType="line"
                type={refundDuration}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6} sm={12}>
              <Typography align="center" variant="h4" sx={{ mb: 2 }}>
                Refund volume
              </Typography>
              <RefundChart
                price={refundData}
                chartType="column"
                type={refundDuration}
                color={{
                  type: 'solid',
                  colors: ['transparent'],
                }}
              />
            </Grid>
          </Grid>
        )}
      </Container>

      <br />
      <br />

      <Container maxWidth="false">
        <Box
          sx={{
            display: 'flex',
            bgcolor: 'background.paper',
            borderRadius: 1,
          }}
        >
          <Box sx={{ flexGrow: 1 }}></Box>
          <Box
            sx={{ width: '100%', typography: 'body1', mt: { xs: 2, lg: 0 } }}
          >
            <TabContext value={value}>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: 'black',
                  display: 'flex',
                  py: 2,
                }}
              >
                <Grid alignItems="center" container>
                  <Grid item xs={3.5} lg={2}>
                    <FormControl
                      fullWidth
                      size="small"
                      sx={{ my: 1, width: '230px', alignSelf: 'center' }}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Duration
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={durationRevenue}
                        label="Duration"
                        onChange={handleChangeDurationRevenue}
                        sx={{
                          borderRadius: 0,
                        }}
                      >
                        <MenuItem value={'Daily'}>Daily</MenuItem>
                        <MenuItem value={'Weekly'}>Weekly</MenuItem>
                        <MenuItem value={'Monthly'}>Monthly</MenuItem>
                        <MenuItem value={'Quarterly'}>Quarterly</MenuItem>
                        <MenuItem value={'Yearly'}>Yearly</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
            </TabContext>
          </Box>
        </Box>
      </Container>
      <Container sx={{ mt: 2, p: 3 }} maxWidth="false">
        {durationRevenue == 'Daily' ? (
          <Grid container sx={{ display: 'flex', justifyContent: 'center' }}>
            <Grid item xs={12} md={6} lg={10} sm={12}>
              <Typography align="center" variant="h4" sx={{ mb: 2 }}>
                Revenue volume
              </Typography>
              <LineChart price={revenueData} chartType="line" type={duration} />
            </Grid>
          </Grid>
        ) : (
          <Grid container>
            <Grid item xs={12} md={6} lg={6} sm={12}>
              <Typography align="center" variant="h4" sx={{ mb: 2 }}>
                Revenue volume
              </Typography>
              <LineChart price={revenueData} chartType="line" type={durationRevenue} />
            </Grid>
            <Grid item xs={12} md={6} lg={6} sm={12}>
              <Typography align="center" variant="h4" sx={{ mb: 2 }}>
                Revenue volume
              </Typography>
              <LineChart
                price={revenueData}
                chartType="column"
                type={durationRevenue}
                color={{
                  type: 'solid',
                  colors: ['transparent'],
                }}
              />
            </Grid>
          </Grid>
        )}
      </Container>
      </>
    )}
    </>
  );
}
//#endregion
