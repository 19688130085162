import React, { useState } from "react";
import Box from "@mui/material/Box";
import { Button, Typography } from "@mui/material";
import svg from "../../../../assets/svg";
import png from "../../../../assets/png";
import Header from "../../../../component/user/defaultLayout/Header";
import Footer from "../../../../component/user/defaultLayout/Footer";
import FormHelperText from "@mui/material/FormHelperText";
import TextField from "@mui/material/TextField";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { styled } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const Input = styled("input")({
  display: "none",
});

const AddProduct1 = () => {
  const [age, setAge] = useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return (
    <div>
      <Header />
      <Box className="addproduct-1-sec">
        <Box className="container">
          <Box sx={{ width: 1 }}>
            <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={0.5}>
              <Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
              >
                <Box className="addinfo-text-box">
                  <Typography variant="h2" className="addinfo-text">
                    Gemstone
                  </Typography>
                </Box>
              </Box>
              <Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 6",
                }}
              >
                <Box className="refrence-product-input addproduct-1-input-box">
                  <Box className="input-box addproduct-input-box">
                    <FormHelperText className="input-lable">
                      Type of gemstone
                    </FormHelperText>
                    <TextField
                      fullWidth
                      id="fullWidth"
                      className="form-control border-none"
                      value=""
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 6",
                }}
              >
                <Box className="refrence-product-input">
                  <Box className="input-box addproduct-1-input-box">
                    <FormHelperText className="input-lable">
                      No. of gemstones
                    </FormHelperText>
                    <TextField
                      fullWidth
                      id="fullWidth"
                      className="form-control border-none"
                      value=""
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
              >
                <Box className="refrence-product-input">
                  <Box className="input-box addproduct-1-input-box">
                    <FormHelperText className="input-lable">
                      Total Gemstone Price
                    </FormHelperText>
                    <TextField
                      fullWidth
                      id="fullWidth"
                      className="form-control border-none"
                      value=""
                    />
                  </Box>
                </Box>
              </Box>
              <Box gridColumn="span 12">
                <Box className="addproduct-textarea-main input-box addproduct-1-textarea-main">
                  <FormHelperText className="input-lable">
                    Additional Information
                  </FormHelperText>
                  <TextareaAutosize
                    className="addinfo-textarea add-1-info-textarea"
                    aria-label="empty textarea"
                    placeholder=""
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          <Box sx={{ width: 1 }}>
            <Box
              display="grid"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 0, sm: 1, md: 0.5, lg: 0.5 }}
            >
              <Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
              >
                <Box className="addinfo-text-box">
                  <Typography
                    variant="h2"
                    className="addinfo-text product-dimension"
                  >
                    Product Dimensions
                  </Typography>
                </Box>
              </Box>
              <Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 4",
                  lg: "span 4",
                }}
              >
                <Box className="refrence-product-input addproduct-1-input-box">
                  <Box className="input-box addproduct-1-input-box">
                    <FormHelperText className="input-lable">
                      Inner circumference
                    </FormHelperText>
                    <TextField
                      fullWidth
                      id="fullWidth"
                      className="form-control border-none"
                      value=""
                    />
                    <span className="addproduct-mm-text">mm</span>
                  </Box>
                </Box>
              </Box>
              <Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 3",
                  lg: "span 3",
                }}
              >
                <Box className="refrence-product-input">
                  <Box className="input-box addproduct-1-input-box">
                    <FormHelperText className="input-lable">
                      Length
                    </FormHelperText>
                    <TextField
                      fullWidth
                      id="fullWidth"
                      className="form-control border-none"
                      value=""
                    />
                    <span className="addproduct-mm-text">mm</span>
                  </Box>
                </Box>
              </Box>
              <Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 5",
                  lg: "span 5",
                }}
              >
                <Box className="refrence-product-input">
                  <Box className="input-box addproduct-1-input-box">
                    <FormHelperText className="input-lable">
                      Depth
                    </FormHelperText>
                    <TextField
                      fullWidth
                      id="fullWidth"
                      className="form-control border-none"
                      value=""
                    />
                    <span className="addproduct-mm-text">mm</span>
                  </Box>
                </Box>
              </Box>
              <Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 12",
                  lg: "span 12",
                }}
              >
                <Box className="refrence-product-input">
                  <Box className="input-box addproduct-1-input-box">
                    <FormHelperText className="input-lable">
                      Meta Title
                    </FormHelperText>
                    <TextField
                      fullWidth
                      id="fullWidth"
                      className="form-control border-none"
                      value=""
                    />
                    <Typography
                      variant="p"
                      component="p"
                      className="form-validation-text"
                    >
                      A short 2-3 words
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
              >
                <Box className="addproduct-textarea-main input-box ">
                  <FormHelperText className="input-lable">
                    Meta Description
                  </FormHelperText>
                  <TextareaAutosize
                    className="addinfo-textarea"
                    aria-label="empty textarea"
                    placeholder=""
                  />
                </Box>
              </Box>
              <Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
              >
                <Box className="primary-btn-box next-btn-box">
                  <Button className="primary-btn next-btn">Submit</Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Footer />
    </div>
  );
};

export default AddProduct1;
