import React, { useState, useEffect } from 'react';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import SearchIcon from '@mui/icons-material/Search';
import {
  Typography,
  Button,
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  InputBase,
  Container,
  Menu,
  MenuItem,
  TablePagination,
  Tooltip,
} from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Api } from '../../../../config/api';
import dataService from '../../../../config/dataService';
import NoDataFound from '../../../../component/customComponents/noDataFound/NoDataFound';
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../../../../component/customComponents/loader/Loader';
import Mui from '../../../../component/customComponents/mui/Mui';

import AdminIndex from '../../AdminIndex';

//#region ALL HOOKS AND VARIABALS
const columns = [
  { id: 'userid', label: 'User Id' },
  { id: 'username', label: 'User Name', align: 'center' },
  {
    id: 'company',
    label: 'Company',
    align: 'center',
  },
  { id: 'status', label: 'Status', align: 'center' },
  { id: 'action', label: '' },
];

const { getUserList, deleteUser } = AdminIndex.AdminSagaActions;

export default function UserManagement() {
  let token = localStorage.getItem('auth');
  const dispatch = AdminIndex.useDispatch();
  const history = useHistory();
  // const [loading, setLoading] = useState(false);
  const [flag, setFlag] = useState(false);
  const [open, setOpen] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const openManu = Boolean(anchorEl);
  const [userlist, setUserlist] = useState([]);
  const [tempUserlist, setTempUserlist] = useState([]);
  const [val, setVal] = useState('');
  const [status, setStatus] = useState('');
  const [flag1, setFlag1] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    fetchuserlist();
    setInterval(() => {
      setFlag(true);
    }, 500);
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  function createData(userCount, name, company_name, id, value) {
    return { userCount, name, company_name, id, value };
  }

  // const rows = userlist[0]
  //   ? userlist.map((element, index) =>
  //       createData(
  //         element.userCount,
  //         element.name,
  //         element.company_name,
  //         element._id,
  //         element.approveStatus == '0' ? (
  //           <Typography>Pending</Typography>
  //         ) : element.approveStatus == '1' ? (
  //           <Typography sx={{ color: 'green' }}>Approved</Typography>
  //         ) : (
  //           <Typography sx={{ color: 'red' }}>Rejected</Typography>
  //         )
  //       )
  //     )
  //   : [];
  //#endregion

  //#region ALL EVENT HANDLERS AND  FUCTIONS
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  let fetchuserlist = async () => {
    dispatch(getUserList(token));
    // setLoading(true);
    // await dataService
    //   .get(Api.Admin.getUserList, {
    //     headers: {
    //       auth: token,
    //     },
    //   })
    //   .then((res) => {
    //     setUserlist(res.data.data);
    //     setTempUserlist(res.data.data);
    //   })
    //   .catch((err) => toast.error(err?.message || 'Something went wrong'))
    //   .finally(() => {
    //     setLoading(false);
    //   });
  };

  const loading = AdminIndex.useSelector((state) => {
    return state.AdminSaga.loading;
  });
  
  const userList = AdminIndex.useSelector((state) => {
    return state.AdminSaga.userList;
  });

  useEffect(() => {
    setUserlist(userList);
    setTempUserlist(userList);
  }, [userList]);

  const deleteUsers = async (id) => {
    let urlencoded = new URLSearchParams();
    urlencoded.append('id', id);
    urlencoded.append('isDeleted', '1');

    dispatch(deleteUser(token,urlencoded));
    // try {
    //   const deleteUser = await dataService.post(
    //     Api.Admin.deleteUser,
    //     urlencoded,
    //     {
    //       headers: {
    //         auth: token,
    //       },
    //     }
    //   );
    //   if (deleteUser.data.status == 1) {
    //     toast.success('User removed successfully');
    //   }
    // } catch (err) {
    //   toast.error(err?.message || 'Something went wrong');
    // }
    // fetchuserlist();
  };

  const filter = (e) => {
    let data = e.target.value;
    let res = data.trim().toLowerCase();
    if (status != '') {
      let filterData = tempUserlist.filter(
        (value) =>
          (value?.name?.toLowerCase().includes(res) ||
            value?.company_name?.toLowerCase().includes(res) ||
            value?.userCount?.toLowerCase().includes(res)) &&
          value.approveStatus == status
      );
      setUserlist(filterData);
      setVal(e.target.value);
    } else {
      let filterData = tempUserlist.filter(
        (value) =>
          value?.name?.toLowerCase().includes(res) ||
          value?.company_name?.toLowerCase().includes(res) ||
          value?.userCount?.toLowerCase().includes(res)
      );
      setUserlist(filterData);
      setVal(e.target.value);
    }
  };

  const filterPendingUsers = (e) => {
    let res = e.trim().toLowerCase();
    if (e != '') {
      let filterPendingUsersData = tempUserlist.filter(
        (value) =>
          (value?.name?.toLowerCase().includes(res) ||
            value?.company_name?.toLowerCase().includes(res) ||
            value?.userCount?.toLowerCase().includes(res)) &&
          value.approveStatus == '0'
      );
      setUserlist(filterPendingUsersData);
    } else {
      let filterPendingUsersData = tempUserlist.filter(
        (value) => value.approveStatus == '0'
      );
      setUserlist(filterPendingUsersData);
    }
  };
  const filterAcceptedUsers = (e) => {
    let res = e.trim().toLowerCase();
    if (e != '') {
      let filterAcceptedUsersData = tempUserlist.filter(
        (value) =>
          (value?.name?.toLowerCase().includes(res) ||
            value?.company_name?.toLowerCase().includes(res) ||
            value?.userCount?.toLowerCase().includes(res)) &&
          value.approveStatus == '1'
      );
      setUserlist(filterAcceptedUsersData);
    } else {
      let filterAcceptedUsersData = tempUserlist.filter(
        (value) => value.approveStatus == '1'
      );
      setUserlist(filterAcceptedUsersData);
    }
  };
  const filterRejectedUsers = (e) => {
    let res = e.trim().toLowerCase();
    if (e != '') {
      let filterRejectedUsersData = tempUserlist.filter(
        (value) =>
          (value?.name?.toLowerCase().includes(res) ||
            value?.company_name?.toLowerCase().includes(res) ||
            value?.userCount?.toLowerCase().includes(res)) &&
          value.approveStatus == '2'
      );
      setUserlist(filterRejectedUsersData);
    } else {
      let filterRejectedUsersData = tempUserlist.filter(
        (value) => value.approveStatus == '2'
      );
      setUserlist(filterRejectedUsersData);
    }
  };
  const filterAllUsers = (e) => {
    let filterAllUsersData = tempUserlist.filter((value) => value);
    setUserlist(filterAllUsersData);
  };

  //#endregion

  //#region RETURN JSX
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Container sx={{ mt: 2, mb: 5 }} maxWidth="false">
          <Typography variant="h4" align="center" sx={{ fontWeight: 'bold' }}>
            User
          </Typography>
          <br />
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box>
              <Mui.GrayTooltip
                disableFocusListener
                title="Search by ( User Id / User Name / Company Name)"
                placement="bottom-start"
                className="admin-tooltip"
                // sx={{whiteSpace: 'pre', minWidth: 'fit-content'}}
              >
                <Mui.Search>
                  <Mui.SearchIconWrapper>
                    <SearchIcon />
                  </Mui.SearchIconWrapper>
                  <Mui.StyledInputBase
                    onChange={filter}
                    value={val}
                    placeholder="Search"
                    inputProps={{ 'aria-label': 'search' }}
                    sx={{
                      width: '100%',
                      '& .MuiInputBase-input': {
                        width: '100%',
                        '&:focus, &.MuiInputBase-input': {
                          width: '100%',
                        },
                      },
                    }}
                  />
                </Mui.Search>
              </Mui.GrayTooltip>
            </Box>
            <Box sx={{ display: 'flex', alignSelf: 'center' }}>
              <>
                <Button
                  id="demo-positioned-button"
                  aria-controls={open ? 'demo-positioned-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
                  variant="outlined"
                  sx={{
                    borderRadius: '6px',
                    borderRadius: 0,
                    textTransform: 'none',
                  }}
                >
                  Filter <FilterAltIcon />
                </Button>
                <Menu
                  id="demo-positioned-menu"
                  aria-labelledby="demo-positioned-button"
                  anchorEl={anchorEl}
                  open={openManu}
                  onClose={() => setAnchorEl(null)}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <MenuItem
                    selected={flag1 == 2}
                    onClick={() => {
                      filterPendingUsers(val);
                      setAnchorEl(null);
                      setStatus('0');
                      setFlag1('2');
                    }}
                  >
                    Pending
                  </MenuItem>
                  <MenuItem
                    selected={flag1 == 3}
                    onClick={() => {
                      filterAcceptedUsers(val);
                      setAnchorEl(null);
                      setStatus('1');
                      setFlag1('3');
                    }}
                  >
                    Accepted
                  </MenuItem>
                  <MenuItem
                    selected={flag1 == 4}
                    onClick={() => {
                      filterRejectedUsers(val);
                      setAnchorEl(null);
                      setStatus('2');
                      setFlag1('4');
                    }}
                  >
                    Rejected
                  </MenuItem>
                  <MenuItem
                    selected={flag1 == '5'}
                    onClick={() => {
                      filterAllUsers();
                      setAnchorEl(null);
                      setStatus('');
                      setFlag1('5');
                    }}
                  >
                    All
                  </MenuItem>
                  {/* <MenuItem onClick={handleClose}>Logout</MenuItem> */}
                </Menu>
              </>
            </Box>
          </Box>
          <br />
          <TableContainer
            elevation={0}
            sx={{
              maxWidth: {
                xs: '365px',
                sm: '600px',
                md: '100%',
                lg: '100%',
              },
              '&::-webkit-scrollbar': {
                width: 10,
              },
              '&::-webkit-scrollbar': {
                height: '8px',
                width: '12px',
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: '#fff',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(224, 224, 224, 1)',
                borderRadius: '30px',
                width: '20px',
              },
              '&::-webkit-scrollbar-thumb:hover': {
                background: '#B0B0B0',
              },
            }}
            component={Paper}
          >
            <Table
              sx={{
                width: {
                  xs: 'max-content',
                  sm: 'max-content',
                  md: '100%',
                  lg: '100%',
                },
              }}
              size="small"
            >
              <TableHead>
                <TableRow sx={{ bgcolor: 'black' }}>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth, bgcolor: 'red' }}
                      className="pagination-table-header-row"
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {userlist.length
                  ? userlist
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row._id}
                            sx={{
                              '&:last-child td, &:last-child th': {
                                border: 0,
                                whiteSpace: 'nowrap',
                              },
                            }}
                          >
                            <Mui.StyledTableCell align="left">
                              {row?.userCount}
                            </Mui.StyledTableCell>
                            <Mui.StyledTableCell align="center">
                              {row?.name}
                            </Mui.StyledTableCell>
                            <Mui.StyledTableCell align="center">
                              {row.company_name}
                            </Mui.StyledTableCell>
                            <Mui.StyledTableCell align="center">
                              {row.approveStatus == '0' ? (
                                <Typography>Pending</Typography>
                              ) : row.approveStatus == '1' ? (
                                <Typography sx={{ color: 'green' }}>
                                  Approved
                                </Typography>
                              ) : (
                                <Typography sx={{ color: 'red' }}>
                                  Rejected
                                </Typography>
                              )}
                            </Mui.StyledTableCell>
                            <Mui.StyledTableCell align="right">
                              {/* <Switch {...label} defaultChecked /> */}
                              <Button
                                variant="contained"
                                sx={{
                                  width: '90px',
                                  borderRadius: 5,
                                  textTransform: 'none',
                                }}
                                onClick={() =>
                                  history.push(
                                    `/admin/usermanagement/manageuser/${row._id}`
                                  )
                                }
                              >
                                View
                              </Button>
                              <Button
                                variant="contained"
                                sx={{
                                  ml: 1,
                                  width: '90px',
                                  borderRadius: 5,
                                  textTransform: 'none',
                                }}
                                onClick={() => {
                                  if (
                                    window.confirm(
                                      'Are you sure you want to delete this user?'
                                    )
                                  ) {
                                    deleteUsers(row._id);
                                  }
                                }}
                              >
                                Remove
                              </Button>
                            </Mui.StyledTableCell>
                          </TableRow>
                        );
                      })
                  : ''}
              </TableBody>
            </Table>
            {!userlist.length && flag ? <NoDataFound /> : ''}
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            component="div"
            count={userlist.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Container>
      )}
    </>
  );
}
//#endregion
