import React, { useState, useEffect } from "react";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import UserIndex from "../../UserIndex";
import UserSagaActions from "../../../../redux/Usersaga/action";
import { Grid, IconButton, Modal } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import DisabledByDefaultIcon from "@mui/icons-material/DisabledByDefault";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { price } from "../../../../component/customComponents/commonFunctions/CommonFunctions";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Img = UserIndex.styled("img")({
  display: "block",
  maxWidth: "100%",
  height: "auto",
  maxHeight: "50vh",
  borderRadius: "6px",
});

const { ViewReceivedOrderSaga, UpdateDeliveryStausSaga } = UserSagaActions;

const Accordion = UserIndex.styled((props) => (
  <UserIndex.MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = UserIndex.styled((props) => (
  <UserIndex.MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = UserIndex.styled(UserIndex.MuiAccordion)(
  ({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: "1px solid rgba(0, 0, 0, .125)",
  })
);

const OrdersReceivedViewPage = () => {
  const [expanded, setExpanded] = useState("panel1");
  const [clicked, setClicked] = useState(false);
  const [orderData, setOrderData] = useState("");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [viewOrder, setViewOrder] = useState([]);
  const [productIdData, setProductIdData] = useState("");
  const [customeProductIdData, setCustomeProductIdData] = useState("");
  const [newData, setNewData] = useState([]);
  const [dispatchStatus, setDispatchStatus] = useState();
  const [dispatchProductId, setDispatchProductId] = useState("");
  const [dispatchStatusError, setDispatchStatusError] = useState("");
  const [imageError, setImageError] = useState("");
  const [images, setImages] = useState([]);
  const [Url, setUrl] = useState([]);
  const [dispatchOpen, setDispatchOpen] = useState(false);
  const handleDispatchOpen = () => setDispatchOpen(true);
  const handleDispatchClose = () => {
    setDispatchOpen(false);
  };

  const dispatch = UserIndex.useDispatch();
  const history = UserIndex.useHistory();
  const params = UserIndex.useParams();
  const navigate = UserIndex.useHistory();
  const loding = UserIndex.useSelector((state) => {
    return state.Usersaga.loading;
  });
  const Token = UserIndex.useSelector((state) => {
    return state.Usersaga.Token;
  });
  const ViewReceivedOrderdata = UserIndex.useSelector((state) => {
    return state.Usersaga.ViewReceivedOrderdata;
  });
  const UpdateDeliveryStausData = UserIndex.useSelector((state) => {
    return state.Usersaga.UpdateDeliveryStausData;
  });
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  useEffect(() => {
    setViewOrder(ViewReceivedOrderdata);
    let arr = [];
    let finalArray = [];
    if (ViewReceivedOrderdata.custom_product_id) {
      let obj = {};
      // obj = { ...data }
      if (arr.includes(ViewReceivedOrderdata.order_item_id) === false) {
        arr.push(ViewReceivedOrderdata.order_item_id);
        finalArray.push({
          index: ViewReceivedOrderdata.order_item_id,
          data: [
            {
              ...ViewReceivedOrderdata.custom_product_id,
              amount: ViewReceivedOrderdata.totalAmount,
              productName: ViewReceivedOrderdata.productName,
            },
          ],
        });
      } else {
        const myIndex = finalArray.findIndex(
          (el) => el.index === ViewReceivedOrderdata.custom_product_id
        );
        finalArray[myIndex].data.push(ViewReceivedOrderdata.custom_product_id);
      }
    } else {
      ViewReceivedOrderdata?.products?.map((data, i, array) => {
        let obj = {};
        obj = { ...data };
        if (arr.includes(data.order_item_id) === false) {
          arr.push(data.order_item_id);
          finalArray.push({ index: data.order_item_id, data: [data] });
        } else {
          const myIndex = finalArray.findIndex(
            (el) => el.index === data.order_item_id
          );
          finalArray[myIndex].data.push(data);
        }
      });
    }
    setNewData(finalArray);
  }, [ViewReceivedOrderdata]);

  const getViewReceivedOrder = async () => {
    let urlencoded = new URLSearchParams();
    urlencoded.append("id", params.receive_id);
    dispatch(ViewReceivedOrderSaga(urlencoded, navigate, Token));
  };

  useEffect(() => {
    getViewReceivedOrder();
  }, []);

  useEffect(() => {
    setOrderData(ViewReceivedOrderdata);
  }, [ViewReceivedOrderdata]);

  const dispatchModal = (e) => {
    setDispatchStatus(e.target.value);
    if (e.target.value == "1") {
      setDispatchOpen(true);
    }
  };

  const [oldImages, setOldImages] = useState([]);
  const [fileType, setFileType] = useState("");

  const [{ src, backend, oldImage }, setImg] = useState({
    src: "",
    backend: {},
    oldImage: "",
  });
  const removeImg = () => {
    setImg({
      src: "",
      oldImage: "",
      backend: {},
    });
  };
  const removeOldImg = () => {
    setImg({
      backend: "",
      src: "",
      backend: {},
    });
  };
  const handleImg = (e) => {
    const MAX_FILE_SIZE = 51200; // 50MB
    setFileType(e.target.files[0].type);
    const fileSizeKiloBytes = e.target.files[0].size / 1024;
    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      return;
    }
    if (e.target.files[0]) {
      setImg({
        src: window.URL.createObjectURL(e.target.files[0]),
        backend: e.target.files[0],
        oldImage: "",
      });
    }
  };

  useEffect(() => {
    orderData?.products?.map((data, index) => {
      setDispatchStatus(data.orderItemStatus);
    });
  }, [orderData,ViewReceivedOrderdata]);

  const errorHandling = () => {
    if (src == "") {
      setImageError("Please Select Image/PDF");
    }
  };

  const getUpdateDeliveryStaus = async () => {
    errorHandling();
    if (src == "") {
      setDispatchOpen(true);
    } else {
      let formdata = new FormData();
      formdata.append("id", params.receive_id);
      formdata.append("orderItemStatus", dispatchStatus);
      if (dispatchProductId != "") {
        formdata.append("productId", dispatchProductId);
      }
      formdata.append("images", backend);
      dispatch(UpdateDeliveryStausSaga(formdata, navigate, Token));
      setClicked(true);
      if (dispatch) {
        setDispatchOpen(false);
      } else {
      }
    }
  };

  useEffect(() => {
    setProductIdData(newData?.[0]?.data?.[0]?.productId);
  }, [newData?.[0]?.data?.[0]?.productId]);

  useEffect(() => {
    setCustomeProductIdData(ViewReceivedOrderdata.custom_product_id);
  }, [ViewReceivedOrderdata.custom_product_id]);

  const goldFields = () => {
    return (
      <>
        {productIdData?.gold?.length
          ? productIdData?.gold?.map((gold) => {
              return (
                <>
                  <UserIndex.Box className="order-data-main">
                    <UserIndex.Box className="order-inner-main">
                      <UserIndex.Typography
                        component="h3"
                        variant="h3"
                        className="order-sub-title"
                      >
                        Gold Wt(gm) -
                      </UserIndex.Typography>
                      <UserIndex.Typography
                        component="p"
                        variant="p"
                        className="order-sub-data"
                      >
                        {gold.weight}
                      </UserIndex.Typography>
                    </UserIndex.Box>
                    <UserIndex.Box className="order-inner-main">
                      <UserIndex.Typography
                        component="h3"
                        variant="h3"
                        className="order-sub-title"
                      >
                        Gold Purity -
                      </UserIndex.Typography>
                      <UserIndex.Typography
                        component="p"
                        variant="p"
                        className="order-sub-data"
                      >
                        {gold.purity}
                      </UserIndex.Typography>
                    </UserIndex.Box>
                  </UserIndex.Box>
                </>
              );
            })
          : customeProductIdData?.gold?.length
          ? customeProductIdData?.gold?.map((gold) => {
              return (
                <>
                  <UserIndex.Box className="order-data-main">
                    <UserIndex.Box className="order-inner-main">
                      <UserIndex.Typography
                        component="h3"
                        variant="h3"
                        className="order-sub-title"
                      >
                        Gold Wt(gm) -
                      </UserIndex.Typography>
                      <UserIndex.Typography
                        component="p"
                        variant="p"
                        className="order-sub-data"
                      >
                        {gold?.weight}
                      </UserIndex.Typography>
                    </UserIndex.Box>
                    <UserIndex.Box className="order-inner-main">
                      <UserIndex.Typography
                        component="h3"
                        variant="h3"
                        className="order-sub-title"
                      >
                        Gold Purity -
                      </UserIndex.Typography>
                      <UserIndex.Typography
                        component="p"
                        variant="p"
                        className="order-sub-data"
                      >
                        {gold?.purity}
                      </UserIndex.Typography>
                    </UserIndex.Box>
                  </UserIndex.Box>
                </>
              );
            })
          : ""}
      </>
    );
  };

  const silverFields = () => {
    return (
      <>
        {productIdData.silver?.length
          ? productIdData.silver?.map((silver) => {
              return (
                <>
                  <UserIndex.Box className="order-data-main">
                    <UserIndex.Box className="order-inner-main">
                      <UserIndex.Typography
                        component="h3"
                        variant="h3"
                        className="order-sub-title"
                      >
                        Silver Wt(gm) -
                      </UserIndex.Typography>
                      <UserIndex.Typography
                        component="p"
                        variant="p"
                        className="order-sub-data"
                      >
                        {silver?.weight}
                      </UserIndex.Typography>
                    </UserIndex.Box>
                    <UserIndex.Box className="order-inner-main">
                      <UserIndex.Typography
                        component="h3"
                        variant="h3"
                        className="order-sub-title"
                      >
                        Silver Purity -
                      </UserIndex.Typography>
                      <UserIndex.Typography
                        component="p"
                        variant="p"
                        className="order-sub-data"
                      >
                        {silver?.purity}
                      </UserIndex.Typography>
                    </UserIndex.Box>
                  </UserIndex.Box>
                </>
              );
            })
          : customeProductIdData?.silver?.length
          ? customeProductIdData?.silver?.map((silver) => {
              return (
                <>
                  <UserIndex.Box className="order-data-main">
                    <UserIndex.Box className="order-inner-main">
                      <UserIndex.Typography
                        component="h3"
                        variant="h3"
                        className="order-sub-title"
                      >
                        Silver Wt(gm) -
                      </UserIndex.Typography>
                      <UserIndex.Typography
                        component="p"
                        variant="p"
                        className="order-sub-data"
                      >
                        {silver.weight}
                      </UserIndex.Typography>
                    </UserIndex.Box>
                    <UserIndex.Box className="order-inner-main">
                      <UserIndex.Typography
                        component="h3"
                        variant="h3"
                        className="order-sub-title"
                      >
                        Silver Purity -
                      </UserIndex.Typography>
                      <UserIndex.Typography
                        component="p"
                        variant="p"
                        className="order-sub-data"
                      >
                        {silver.purity}
                      </UserIndex.Typography>
                    </UserIndex.Box>
                  </UserIndex.Box>
                </>
              );
            })
          : ""}
      </>
    );
  };

  const gemstoneFields = () => {
    return (
      <>
        {productIdData?.gemstone?.length
          ? productIdData?.gemstone?.map((gems) => {
              return (
                <UserIndex.Box className="order-data-main">
                  <UserIndex.Box className="order-inner-main">
                    <UserIndex.Typography
                      component="h3"
                      variant="h3"
                      className="order-sub-title"
                    >
                      Gemstone Wt(gm) -
                    </UserIndex.Typography>
                    <UserIndex.Typography
                      component="p"
                      variant="p"
                      className="order-sub-data"
                    >
                      {gems.total_weight}
                    </UserIndex.Typography>
                  </UserIndex.Box>
                  <UserIndex.Box className="order-inner-main">
                    <UserIndex.Typography
                      component="h3"
                      variant="h3"
                      className="order-sub-title"
                    >
                      Gemstone Purity -
                    </UserIndex.Typography>
                    <UserIndex.Typography
                      component="p"
                      variant="p"
                      className="order-sub-data"
                    >
                      {gems.purity}
                    </UserIndex.Typography>
                  </UserIndex.Box>
                </UserIndex.Box>
              );
            })
          : customeProductIdData?.gemstone?.length
          ? customeProductIdData?.gemstone?.map((gems) => {
              return (
                <UserIndex.Box className="order-data-main">
                  <UserIndex.Box className="order-inner-main">
                    <UserIndex.Typography
                      component="h3"
                      variant="h3"
                      className="order-sub-title"
                    >
                      Gemstone Wt(gm) -
                    </UserIndex.Typography>
                    <UserIndex.Typography
                      component="p"
                      variant="p"
                      className="order-sub-data"
                    >
                      {gems.total_weight}
                    </UserIndex.Typography>
                  </UserIndex.Box>
                  <UserIndex.Box className="order-inner-main">
                    <UserIndex.Typography
                      component="h3"
                      variant="h3"
                      className="order-sub-title"
                    >
                      Gemstone Purity -
                    </UserIndex.Typography>
                    <UserIndex.Typography
                      component="p"
                      variant="p"
                      className="order-sub-data"
                    >
                      {gems.purity}
                    </UserIndex.Typography>
                  </UserIndex.Box>
                </UserIndex.Box>
              );
            })
          : ""}
      </>
    );
  };

  const diamondFields = () => {
    return (
      <>
        {productIdData?.diamond?.length
          ? productIdData?.diamond?.map((diam) => {
              return (
                <UserIndex.Box className="order-data-main">
                  <UserIndex.Box className="order-inner-main">
                    <UserIndex.Typography
                      component="h3"
                      variant="h3"
                      className="order-sub-title"
                    >
                      Diamond Wt(gm) -
                    </UserIndex.Typography>
                    <UserIndex.Typography
                      component="p"
                      variant="p"
                      className="order-sub-data"
                    >
                      {diam.total_weight}
                    </UserIndex.Typography>
                  </UserIndex.Box>
                  <UserIndex.Box className="order-inner-main">
                    <UserIndex.Typography
                      component="h3"
                      variant="h3"
                      className="order-sub-title"
                    >
                      Diamond Purity -
                    </UserIndex.Typography>
                    <UserIndex.Typography
                      component="p"
                      variant="p"
                      className="order-sub-data"
                    >
                      {diam.purity}
                    </UserIndex.Typography>
                  </UserIndex.Box>
                </UserIndex.Box>
              );
            })
          : customeProductIdData?.diamond?.length
          ? customeProductIdData?.diamond?.map((diam) => {
              return (
                <UserIndex.Box className="order-data-main">
                  <UserIndex.Box className="order-inner-main">
                    <UserIndex.Typography
                      component="h3"
                      variant="h3"
                      className="order-sub-title"
                    >
                      Diamond Wt(gm) -
                    </UserIndex.Typography>
                    <UserIndex.Typography
                      component="p"
                      variant="p"
                      className="order-sub-data"
                    >
                      {diam.total_weight}
                    </UserIndex.Typography>
                  </UserIndex.Box>
                  <UserIndex.Box className="order-inner-main">
                    <UserIndex.Typography
                      component="h3"
                      variant="h3"
                      className="order-sub-title"
                    >
                      Diamond Purity -
                    </UserIndex.Typography>
                    <UserIndex.Typography
                      component="p"
                      variant="p"
                      className="order-sub-data"
                    >
                      {diam.purity}
                    </UserIndex.Typography>
                  </UserIndex.Box>
                </UserIndex.Box>
              );
            })
          : ""}
      </>
    );
  };

  const renderFieldMaterialWise = () => {
    if (productIdData) {
      switch (true) {
        case productIdData?.gold?.length > 0 &&
          productIdData?.diamond?.length > 0 &&
          productIdData?.materialIdArray?.length === 2:
          return (
            <>
              {goldFields()}
              {diamondFields()}
            </>
          );
        case productIdData?.gold?.length > 0 &&
          productIdData?.gemstone?.length > 0 &&
          productIdData?.materialIdArray?.length === 2:
          return (
            <>
              {goldFields()}
              {gemstoneFields()}
            </>
          );
        case productIdData?.silver?.length > 0 &&
          productIdData?.diamond?.length > 0 &&
          productIdData?.materialIdArray?.length === 2:
          return (
            <>
              {silverFields()}
              {diamondFields()}
            </>
          );
        case productIdData?.silver?.length > 0 &&
          productIdData?.gemstone?.length > 0 &&
          productIdData?.materialIdArray?.length === 2:
          return (
            <>
              {silverFields()}
              {gemstoneFields()}
            </>
          );
        case productIdData?.silver?.length > 0 &&
          productIdData?.gold?.length > 0 &&
          productIdData?.materialIdArray?.length === 2:
          return (
            <>
              {silverFields()}
              {goldFields()}
            </>
          );
        case productIdData?.gemstone?.length > 0 &&
          productIdData?.diamond?.length > 0 &&
          productIdData?.materialIdArray?.length === 2:
          return (
            <>
              {gemstoneFields()}
              {diamondFields()}
            </>
          );
        case productIdData?.gold?.length > 0 &&
          productIdData?.silver?.length > 0 &&
          productIdData?.diamond?.length > 0 &&
          productIdData?.materialIdArray?.length === 3:
          return (
            <>
              {silverFields()}
              {goldFields()}
              {diamondFields()}
            </>
          );
        case productIdData?.silver?.length > 0 &&
          productIdData?.gold?.length > 0 &&
          productIdData?.gemstone?.length > 0 &&
          productIdData?.materialIdArray?.length === 3:
          return (
            <>
              {silverFields()}
              {goldFields()}
              {gemstoneFields()}
            </>
          );
        case productIdData?.gold?.length > 0 &&
          productIdData?.diamond?.length > 0 &&
          productIdData?.gemstone?.length > 0 &&
          productIdData?.materialIdArray?.length === 3:
          return (
            <>
              {goldFields()}
              {diamondFields()}
              {gemstoneFields()}
            </>
          );
        case productIdData?.silver?.length > 0 &&
          productIdData?.diamond?.length > 0 &&
          productIdData?.gemstone?.length > 0 &&
          productIdData?.materialIdArray?.length === 3:
          return (
            <>
              {silverFields()}
              {gemstoneFields()}
              {diamondFields()}
            </>
          );
        case productIdData?.gold?.length > 0 &&
          productIdData?.silver?.length > 0 &&
          productIdData?.gemstone?.length > 0 &&
          productIdData?.diamond?.length > 0 &&
          productIdData?.materialIdArray?.length === 4:
          return (
            <>
              {goldFields()}
              {silverFields()}
              {gemstoneFields()}
              {diamondFields()}
            </>
          );
        case productIdData?.gold?.length > 0:
          return goldFields();
        case productIdData?.silver?.length > 0:
          return silverFields();
        case productIdData?.diamond?.length > 0:
          return diamondFields();
        case productIdData?.gemstone?.length > 0:
          return gemstoneFields();
        default:
          return "";
      }
    } else {
      switch (true) {
        case customeProductIdData?.gold?.length > 0 &&
          customeProductIdData?.diamond?.length > 0 &&
          customeProductIdData?.materialIdArray?.length === 2:
          return (
            <>
              {goldFields()}
              {diamondFields()}
            </>
          );
        case customeProductIdData?.gold?.length > 0 &&
          customeProductIdData?.gemstone?.length > 0 &&
          customeProductIdData?.materialIdArray?.length === 2:
          return (
            <>
              {goldFields()}
              {gemstoneFields()}
            </>
          );
        case customeProductIdData?.silver?.length > 0 &&
          customeProductIdData?.diamond?.length > 0 &&
          customeProductIdData?.materialIdArray?.length === 2:
          return (
            <>
              {silverFields()}
              {diamondFields()}
            </>
          );
        case customeProductIdData?.silver?.length > 0 &&
          customeProductIdData?.gemstone?.length > 0 &&
          customeProductIdData?.materialIdArray?.length === 2:
          return (
            <>
              {silverFields()}
              {gemstoneFields()}
            </>
          );
        case customeProductIdData?.silver?.length > 0 &&
          customeProductIdData?.gold?.length > 0 &&
          customeProductIdData?.materialIdArray?.length === 2:
          return (
            <>
              {silverFields()}
              {goldFields()}
            </>
          );
        case customeProductIdData?.gemstone?.length > 0 &&
          customeProductIdData?.diamond?.length > 0 &&
          customeProductIdData?.materialIdArray?.length === 2:
          return (
            <>
              {gemstoneFields()}
              {diamondFields()}
            </>
          );
        case customeProductIdData?.gold?.length > 0 &&
          customeProductIdData?.silver?.length > 0 &&
          customeProductIdData?.diamond?.length > 0 &&
          customeProductIdData?.materialIdArray?.length === 3:
          return (
            <>
              {silverFields()}
              {goldFields()}
              {diamondFields()}
            </>
          );
        case customeProductIdData?.silver?.length > 0 &&
          customeProductIdData?.gold?.length > 0 &&
          customeProductIdData?.gemstone?.length > 0 &&
          customeProductIdData?.materialIdArray?.length === 3:
          return (
            <>
              {silverFields()}
              {goldFields()}
              {gemstoneFields()}
            </>
          );
        case customeProductIdData?.gold?.length > 0 &&
          customeProductIdData?.diamond?.length > 0 &&
          customeProductIdData?.gemstone?.length > 0 &&
          customeProductIdData?.materialIdArray?.length === 3:
          return (
            <>
              {goldFields()}
              {diamondFields()}
              {gemstoneFields()}
            </>
          );
        case customeProductIdData?.silver?.length > 0 &&
          customeProductIdData?.diamond?.length > 0 &&
          customeProductIdData?.gemstone?.length > 0 &&
          customeProductIdData?.materialIdArray?.length === 3:
          return (
            <>
              {silverFields()}
              {gemstoneFields()}
              {diamondFields()}
            </>
          );
        case customeProductIdData?.gold?.length > 0 &&
          customeProductIdData?.silver?.length > 0 &&
          customeProductIdData?.gemstone?.length > 0 &&
          customeProductIdData?.diamond?.length > 0 &&
          customeProductIdData?.materialIdArray?.length === 4:
          return (
            <>
              {goldFields()}
              {silverFields()}
              {gemstoneFields()}
              {diamondFields()}
            </>
          );
        case customeProductIdData?.gold?.length > 0:
          return goldFields();
        case customeProductIdData?.silver?.length > 0:
          return silverFields();
        case customeProductIdData?.diamond?.length > 0:
          return diamondFields();
        case customeProductIdData?.gemstone?.length > 0:
          return gemstoneFields();
        default:
          return "";
      }
    }
  };
  return (
    <div>
      <UserIndex.Header />
      <UserIndex.Box className="body-bg">
        <UserIndex.Box className="order-place-sec">
          <UserIndex.Box className="container">
            <UserIndex.Box className="row">
              <UserIndex.Box className="addinfo-text-box pg-main-title-box">
                <UserIndex.Typography
                  variant="h2"
                  className="addinfo-text pg-main-title"
                >
                  My Orders
                </UserIndex.Typography>
              </UserIndex.Box>
            </UserIndex.Box>
            <UserIndex.Box className="row">
              <UserIndex.Box className="order-place-content-main">
                <UserIndex.Box className="order-content-box">
                  <UserIndex.Box className="order-content-left">
                    <UserIndex.Box className="order-data-main">
                      <UserIndex.Box className="order-inner-main">
                        <UserIndex.Typography
                          component="h3"
                          variant="h3"
                          className="order-sub-title"
                        >
                          Order Date -
                        </UserIndex.Typography>
                        <UserIndex.Typography
                          component="p"
                          variant="p"
                          className="order-sub-data"
                        >
                          {new Date(orderData.createdAt).toLocaleDateString()}
                        </UserIndex.Typography>
                      </UserIndex.Box>
                    </UserIndex.Box>
                  </UserIndex.Box>
                  <UserIndex.Box className="order-content-right">
                    <UserIndex.Box className="order-data-main">
                      <UserIndex.Box className="order-inner-main">
                        <UserIndex.Typography
                          component="h3"
                          variant="h3"
                          className="order-sub-title"
                        >
                          Total Amount - {price(orderData.totalAmount)}
                        </UserIndex.Typography>
                        <UserIndex.Typography
                          component="p"
                          variant="p"
                          className="order-sub-data"
                        ></UserIndex.Typography>
                      </UserIndex.Box>
                      <UserIndex.Box className="order-inner-main">
                        <UserIndex.Typography
                          component="h3"
                          variant="h3"
                          className="order-sub-title"
                        >
                          Payment Method -
                        </UserIndex.Typography>
                        <UserIndex.Typography
                          component="p"
                          variant="p"
                          className="order-sub-data"
                        >
                          Paid by {orderData.paymentMethod}
                        </UserIndex.Typography>
                      </UserIndex.Box>
                    </UserIndex.Box>
                  </UserIndex.Box>
                </UserIndex.Box>
                <UserIndex.Box className="deliver-address-box">
                  <UserIndex.Typography
                    variant="h3"
                    component="h3"
                    className="deliver-address-text"
                  >
                    Buyer Information
                  </UserIndex.Typography>
                </UserIndex.Box>
                <UserIndex.Box className="order-content-box or-content-box">
                  <UserIndex.Box className="order-content-left">
                    <UserIndex.Box className="order-inner-main">
                      <UserIndex.Typography
                        component="h3"
                        variant="h3"
                        className="order-sub-title"
                      >
                        Name -
                      </UserIndex.Typography>
                      <UserIndex.Typography
                        component="p"
                        variant="p"
                        className="order-sub-data"
                      >
                        {orderData.user_id?.company_name}
                      </UserIndex.Typography>
                    </UserIndex.Box>
                    <UserIndex.Box className="order-inner-main">
                      <UserIndex.Typography
                        component="h3"
                        variant="h3"
                        className="order-sub-title"
                      >
                        Owner -
                      </UserIndex.Typography>
                      <UserIndex.Typography
                        component="p"
                        variant="p"
                        className="order-sub-data"
                      >
                        {orderData.user_id?.name}
                      </UserIndex.Typography>
                    </UserIndex.Box>
                    <UserIndex.Box className="order-inner-main">
                      <UserIndex.Typography
                        component="h3"
                        variant="h3"
                        className="order-sub-title"
                      >
                        Phone No -
                      </UserIndex.Typography>
                      <UserIndex.Typography
                        component="p"
                        variant="p"
                        className="order-sub-data"
                      >
                        {orderData.user_id?.contact_number}
                      </UserIndex.Typography>
                    </UserIndex.Box>
                    <UserIndex.Box className="order-inner-main">
                      <UserIndex.Typography
                        component="h3"
                        variant="h3"
                        className="order-sub-title"
                      >
                        Email -
                      </UserIndex.Typography>
                      <UserIndex.Typography
                        component="p"
                        variant="p"
                        className="order-sub-data"
                      >
                        {orderData.user_id?.email}
                      </UserIndex.Typography>
                    </UserIndex.Box>
                  </UserIndex.Box>
                  <UserIndex.Box className="order-content-left">
                    <UserIndex.Box className="order-inner-main">
                      <UserIndex.Typography
                        component="h3"
                        variant="h3"
                        className="order-sub-title"
                      >
                        Address -
                      </UserIndex.Typography>
                      <UserIndex.Typography
                        component="p"
                        variant="p"
                        className="order-sub-data or-address-para"
                      >
                        {orderData.user_id?.company_address}
                      </UserIndex.Typography>
                    </UserIndex.Box>
                  </UserIndex.Box>
                </UserIndex.Box>
                <UserIndex.Box className="order-place-accordian">
                  {newData?.length
                    ? newData?.map((data, index) => {
                        return (
                          <Accordion
                            // expanded={expanded === "panel1"}
                            // onChange={handleChange("panel1")}
                            className="po-accordian"
                          >
                            <AccordionSummary
                              aria-controls="panel1d-content"
                              id="panel1d-header"
                              className="po-acc-summary"
                            >
                              <UserIndex.Box className="po-summary-title-box">
                                <UserIndex.Typography className="po-sub-title">
                                  Order Item Id - {data.index}
                                </UserIndex.Typography>
                                <UserIndex.Box className="order-btn-end-box">
                                  <UserIndex.Box className="or-track-btn-box  primary-btn-box">
                                    <UserIndex.PrimaryButton
                                      // onClick={handleOpen}
                                      btnclass="primary-btn or-track-btn"
                                      buttonvalue="Track"
                                    />
                                  </UserIndex.Box>
                                  {/* <UserIndex.Box className="po-return-btn-box primary-btn-box">
                                    <UserIndex.PrimaryButton
                                        // onClick={handleOpen}
                                        btnclass="primary-btn po-return-btn"
                                        buttonvalue="Return"
                                      />
                                  </UserIndex.Box> */}
                                </UserIndex.Box>
                              </UserIndex.Box>
                            </AccordionSummary>

                            <UserIndex.Box>
                              <Modal
                                className="order-modal-sec"
                                aria-labelledby="transition-modal-title"
                                aria-describedby="transition-modal-description"
                                open={dispatchOpen}
                                onClose={handleDispatchClose}
                                closeAfterTransition
                              >
                                <Fade in={dispatchOpen}>
                                  <UserIndex.Box sx={style}>
                                    <UserIndex.Box className="input-box">
                                      <UserIndex.Typography
                                        className="input-lable"
                                        component="p"
                                        variant="p"
                                      >
                                        Upload Image OR PDF File
                                      </UserIndex.Typography>
                                      <UserIndex.Box className="upload-reason-video">
                                        <UserIndex.Box className="add-product-left">
                                          <div>
                                            <UserIndex.Box className="add-product-left">
                                              <UserIndex.Box className="add-upload-order-bg">
                                                <UserIndex.Button
                                                  variant="contained"
                                                  component="label"
                                                  className="add-upload-order-btn"
                                                >
                                                  + Upload Image/PDF
                                                  <input
                                                    type="file"
                                                    hidden
                                                    accept="image/png, image/jpeg, application/pdf"
                                                    onChange={(e) =>
                                                      handleImg(e)
                                                    }
                                                    name="newImg"
                                                  />
                                                </UserIndex.Button>
                                              </UserIndex.Box>
                                            </UserIndex.Box>

                                            <UserIndex.Box
                                              sx={{ margin: "10px 10px" }}
                                              className="error-input-lable"
                                            >
                                              {imageError}
                                            </UserIndex.Box>

                                            <UserIndex.Box className="file-preview-main">
                                              {src === "" ||
                                              src === undefined ? (
                                                ""
                                              ) : (
                                                <>
                                                  <UserIndex.Box
                                                    sx={{
                                                      position: "relative",
                                                    }}
                                                  >
                                                    <UserIndex.IconButton
                                                      onClick={() =>
                                                        removeImg()
                                                      }
                                                      sx={{
                                                        position: "absolute",
                                                        left: "86%",
                                                        color: "#c29958",
                                                        zIndex: "1",
                                                      }}
                                                      color="error"
                                                      aria-label="upload picture"
                                                      component="label"
                                                    >
                                                      <DisabledByDefaultIcon />
                                                    </UserIndex.IconButton>
                                                    {fileType == "image/png" ||
                                                    fileType == "image/jpeg" ? (
                                                      <img
                                                        src={src}
                                                        style={{
                                                          width: "100%",
                                                          height: "25vh",
                                                        }}
                                                      />
                                                    ) : (
                                                      <object
                                                        width="100%"
                                                        height="400"
                                                        data={src}
                                                        type="application/pdf"
                                                      >
                                                        {" "}
                                                      </object>
                                                    )}
                                                  </UserIndex.Box>
                                                </>
                                              )}
                                            </UserIndex.Box>
                                          </div>
                                        </UserIndex.Box>
                                      </UserIndex.Box>
                                    </UserIndex.Box>
                                    <UserIndex.Box className="modal-btn-box">
                                      <UserIndex.PrimaryButton
                                        onClick={() => {
                                          getUpdateDeliveryStaus();
                                          // errorHandling()
                                        }}
                                        btnclass="modal-btn btn-dispatch-modal"
                                        buttonvalue="Submit"
                                      />
                                    </UserIndex.Box>
                                  </UserIndex.Box>
                                </Fade>
                              </Modal>
                            </UserIndex.Box>
                            {data?.data?.length
                              ? data?.data?.map((data, index) => {
                                  return (
                                    <AccordionDetails className="po-acc-details">
                                      <UserIndex.Box className="po-inner-accordian-main">
                                        <Accordion className="po-accordian po-inner-accordian">
                                          <AccordionSummary
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                            className="po-acc-summary po-inner-accordian-summary"
                                          >
                                            <UserIndex.Box className="order-main-row">
                                              <UserIndex.Box className="order-inner-main order-divide-row">
                                                <UserIndex.Typography
                                                  component="h3"
                                                  variant="h3"
                                                  className="order-sub-title"
                                                >
                                                  Product Id -
                                                </UserIndex.Typography>
                                                <UserIndex.Typography
                                                  component="p"
                                                  variant="p"
                                                  className="order-sub-data"
                                                >
                                                  {data.orderProductId
                                                    ? data.orderProductId
                                                    : data.product_id}
                                                </UserIndex.Typography>
                                              </UserIndex.Box>
                                              <UserIndex.Box className="order-inner-main order-divide-row">
                                                <UserIndex.Typography
                                                  component="h3"
                                                  variant="h3"
                                                  className="order-sub-title"
                                                >
                                                  Price -
                                                </UserIndex.Typography>
                                                <UserIndex.Typography
                                                  component="p"
                                                  variant="p"
                                                  className="order-sub-data"
                                                >
                                                  {data?.price
                                                    ? price(data?.price)
                                                    : price(data?.amount)}
                                                </UserIndex.Typography>
                                              </UserIndex.Box>
                                              <UserIndex.Box className="order-last-box">
                                                <UserIndex.Box className="order-inner-main order-inner-main-last">
                                                  <UserIndex.Typography
                                                    component="h3"
                                                    variant="h3"
                                                    className="order-sub-title"
                                                  >
                                                    Status
                                                  </UserIndex.Typography>
                                                  <UserIndex.Box className=" po-dropdown">
                                                    <UserIndex.FormControl className="po-form-control">
                                                      <UserIndex.Select
                                                        className="po-select"
                                                        // name="dispatch"
                                                        defaultValue={
                                                          data.orderItemStatus
                                                        }
                                                        value={dispatchStatus}
                                                        onChange={(e) => {
                                                          dispatchModal(e);
                                                        }}
                                                        onClick={(e) => {
                                                          setDispatchProductId(
                                                            data.productId._id
                                                          );
                                                        }}
                                                        inputProps={{
                                                          "aria-label":
                                                            "Without label",
                                                        }}
                                                      >
                                                        <UserIndex.MenuItem
                                                          value={"0"}
                                                          disabled
                                                        >
                                                          Order Placed
                                                        </UserIndex.MenuItem>
                                                        <UserIndex.MenuItem
                                                          value={"1"}
                                                          disabled={data.orderItemStatus === "2" ||
                                                          data.orderItemStatus === "3" ||
                                                          data.orderItemStatus === "4" ||
                                                          clicked}
                                                        >
                                                          Dispatched
                                                        </UserIndex.MenuItem>
                                                        <UserIndex.MenuItem
                                                          value={"2"}
                                                          disabled
                                                        >
                                                          Delivered
                                                        </UserIndex.MenuItem>
                                                        <UserIndex.MenuItem
                                                          value={"3"}
                                                          disabled
                                                        >
                                                          Payment Completed
                                                        </UserIndex.MenuItem>
                                                        <UserIndex.MenuItem
                                                          value={"4"}
                                                          disabled
                                                        >
                                                          Order Cancel
                                                        </UserIndex.MenuItem>
                                                      </UserIndex.Select>
                                                      <span>
                                                        <img
                                                          src={
                                                            UserIndex.png
                                                              .blackdown
                                                          }
                                                          className="black-down-img"
                                                        ></img>
                                                      </span>
                                                    </UserIndex.FormControl>
                                                  </UserIndex.Box>
                                                </UserIndex.Box>
                                              </UserIndex.Box>
                                            </UserIndex.Box>
                                          </AccordionSummary>
                                          <AccordionDetails className="po-acc-details po-inner-accordian-details">
                                            <UserIndex.Box className="accordian-order-content-main">
                                              <UserIndex.Box className="order-content-first">
                                                <UserIndex.Box className="order-data-main">
                                                  <UserIndex.Box className="order-inner-main">
                                                    <UserIndex.Typography
                                                      component="h3"
                                                      variant="h3"
                                                      className="order-sub-title"
                                                    >
                                                      Product Name -
                                                    </UserIndex.Typography>
                                                    <UserIndex.Typography
                                                      component="p"
                                                      variant="p"
                                                      className="order-sub-data"
                                                    >
                                                      {data.productName}
                                                    </UserIndex.Typography>
                                                  </UserIndex.Box>
                                                  <UserIndex.Box className="order-inner-main">
                                                    <UserIndex.Typography
                                                      component="h3"
                                                      variant="h3"
                                                      className="order-sub-title"
                                                    >
                                                      Qty -
                                                    </UserIndex.Typography>
                                                    <UserIndex.Typography
                                                      component="p"
                                                      variant="p"
                                                      className="order-sub-data"
                                                    >
                                                      {data.quantity}
                                                    </UserIndex.Typography>
                                                  </UserIndex.Box>
                                                  {renderFieldMaterialWise()}
                                                </UserIndex.Box>
                                              </UserIndex.Box>
                                              <UserIndex.Box className="order-content-second">
                                                <UserIndex.Box className="order-inner-main">
                                                  <UserIndex.Typography
                                                    component="h3"
                                                    variant="h3"
                                                    className="order-sub-title"
                                                  >
                                                    Discount -
                                                  </UserIndex.Typography>
                                                  <UserIndex.Typography
                                                    component="p"
                                                    variant="p"
                                                    className="order-sub-data"
                                                  >
                                                    {data.discount}%
                                                  </UserIndex.Typography>
                                                </UserIndex.Box>
                                                <UserIndex.Box className="order-inner-main">
                                                  <UserIndex.Typography
                                                    component="h3"
                                                    variant="h3"
                                                    className="order-sub-title"
                                                  >
                                                    Taxes -
                                                  </UserIndex.Typography>
                                                  <UserIndex.Typography
                                                    component="p"
                                                    variant="p"
                                                    className="order-sub-data"
                                                  >
                                                    {data.taxes}%
                                                  </UserIndex.Typography>
                                                </UserIndex.Box>
                                              </UserIndex.Box>
                                              <UserIndex.Box className="order-content-third">
                                                <UserIndex.Box className="order-data-main">
                                                  <UserIndex.Box className="order-inner-main">
                                                    <UserIndex.Box className="order-rate-main">
                                                      <UserIndex.Typography
                                                        component="h3"
                                                        variant="h3"
                                                        className="order-sub-title"
                                                      >
                                                        Rate Out of 5
                                                      </UserIndex.Typography>
                                                      <UserIndex.Typography
                                                        component="p"
                                                        variant="p"
                                                        className="order-sub-data primary-text-color muli-bold"
                                                      >
                                                        {data?.rate
                                                          ?.rate_from_user
                                                          ? data?.rate
                                                              ?.rate_from_user
                                                          : "-"}
                                                      </UserIndex.Typography>
                                                    </UserIndex.Box>
                                                  </UserIndex.Box>
                                                </UserIndex.Box>
                                              </UserIndex.Box>
                                            </UserIndex.Box>
                                          </AccordionDetails>
                                        </Accordion>
                                      </UserIndex.Box>
                                    </AccordionDetails>
                                  );
                                })
                              : "No Record Found"}
                          </Accordion>
                        );
                      })
                    : "No Record Found"}
                </UserIndex.Box>
              </UserIndex.Box>
            </UserIndex.Box>
          </UserIndex.Box>
        </UserIndex.Box>
      </UserIndex.Box>
      <UserIndex.Footer />
    </div>
  );
};

export default OrdersReceivedViewPage;
