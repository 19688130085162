const actions = {
  LODER_ON: "LODER_ON",
  LODER_OFF: "LODER_OFF",
  ADMIN_LOGOUT_REQUEST: "ADMIN_LOGOUT_REQUEST",
  ADMIN_LOGOUT: "ADMIN_LOGOUT",

  ADMIN_LODER_ON: "ADMIN_LODER_ON",
  ADMIN_LODER_OFF: "ADMIN_LODER_OFF",

  ADMIN_ERROR: "ADMIN_ERROR",

  ADMIN_LOGIN_REQUEST: "ADMIN_LOGIN_REQUEST",
  ADMIN_LOGIN_SUCCESS: "ADMIN_LOGIN_SUCCESS",

  ADMIN_FORGOT_PASSWORD_REQUEST: "ADMIN_FORGOT_PASSWORD_REQUEST",
  ADMIN_FORGOT_PASSWORD_SUCCESS: "ADMIN_FORGOT_PASSWORD_SUCCESS",

  ADMIN_OTP_VERIFY_REQUEST: "ADMIN_OTP_VERIFY_REQUEST",
  ADMIN_OTP_VERIFY_SUCCESS: "ADMIN_OTP_VERIFY_SUCCESS",

  ADMIN_RESET_PASSWORD_REQUEST: "ADMIN_RESET_PASSWORD_REQUEST",
  ADMIN_RESET_PASSWORD_SUCCESS: "ADMIN_RESET_PASSWORD_SUCCESS",

  //Dashboard
  GET_DYNAMIC_COUNT_REQUEST: "GET_DYNAMIC_COUNT_REQUEST",
  GET_DYNAMIC_COUNT_SUCCESS: "GET_DYNAMIC_COUNT_SUCCESS",

  // User Management
  GET_USER_LIST_REQUEST: "GET_USER_LIST_REQUEST",
  GET_USER_LIST_SUCCESS: "GET_USER_LIST_SUCCESS",

  // Get Single User Detail
  GET_USER_DETAIL_REQUEST: "GET_USER_DETAIL_REQUEST",
  GET_USER_DETAIL_SUCCESS: "GET_USER_DETAIL_SUCCESS",

  // Delete User
  DELETE_USER_REQUEST: "DELETE_USER_REQUEST",
  DELETE_USER_SUCCESS: "DELETE_USER_SUCCESS",

  // Get All Product
  GET_ALL_PRODUCT_REQUEST: "GET_ALL_PRODUCT_REQUEST",
  GET_ALL_PRODUCT_SUCCESS: "GET_ALL_PRODUCT_SUCCESS",

  // Get All Customer Product
  GET_ALL_CUSTOM_PRODUCT_REQUEST: "GET_ALL_CUSTOM_PRODUCT_REQUEST",
  GET_ALL_CUSTOM_PRODUCT_SUCCESS: "GET_ALL_CUSTOM_PRODUCT_SUCCESS",

  // Get Product Detail
  GET_PRODUCT_DETAIL_REQUEST: "GET_PRODUCT_DETAIL_REQUEST",
  GET_PRODUCT_DETAIL_SUCCESS: "GET_PRODUCT_DETAIL_SUCCESS",

  // Product Approve
  PRODUCT_APPROVE_REQUEST: "PRODUCT_APPROVE_REQUEST",
  PRODUCT_APPROVE_SUCCESS: "PRODUCT_APPROVE_SUCCESS",

  // Product Reject
  PRODUCT_REJECT_REQUEST: "PRODUCT_REJECT_REQUEST",
  PRODUCT_REJECT_SUCCESS: "PRODUCT_REJECT_SUCCESS",

  // Product Delete
  PRODUCT_DELETE_REQUEST: "PRODUCT_DELETE_REQUEST",
  PRODUCT_DELETE_SUCCESS: "PRODUCT_DELETE_SUCCESS",

  // Get Item Data
  GET_ITEM_DATA_REQUEST: "GET_ITEM_DATA_REQUEST",
  GET_ITEM_DATA_SUCCESS: "GET_ITEM_DATA_SUCCESS",

  // Get Material Data
  GET_MATERIAL_LIST_REQUEST: "GET_MATERIAL_LIST_REQUEST",
  GET_MATERIAL_LIST_SUCCESS: "GET_MATERIAL_LIST_SUCCESS",

  // Get Category Data
  GET_CATEGORY_LIST_REQUEST: "GET_CATEGORY_LIST_REQUEST",
  GET_CATEGORY_LIST_SUCCESS: "GET_CATEGORY_LIST_SUCCESS",

  // Update Product Data
  UPDATE_PRODUCT_REQUEST: "UPDATE_PRODUCT_REQUEST",
  UPDATE_PRODUCT_SUCCESS: "UPDATE_PRODUCT_SUCCESS",

  // Approve Custom Product Data
  APPROVE_CUSTOM_PRODUCT_REQUEST: "APPROVE_CUSTOM_PRODUCT_REQUEST",
  APPROVE_CUSTOM_PRODUCT_SUCCESS: "APPROVE_CUSTOM_PRODUCT_SUCCESS",

  // Custom Product Reject
  CUSTOM_PRODUCT_REJECT_REQUEST: "CUSTOM_PRODUCT_REJECT_REQUEST",
  CUSTOM_PRODUCT_REJECT_SUCCESS: "CUSTOM_PRODUCT_REJECT_SUCCESS",

  // Custom Product Detail
  CUSTOM_PRODUCT_DETAIL_REQUEST: "CUSTOM_PRODUCT_DETAIL_REQUEST",
  CUSTOM_PRODUCT_DETAIL_SUCCESS: "CUSTOM_PRODUCT_DETAIL_SUCCESS",

  // Update Custom Product Data
  UPDATE_CUSTOM_PRODUCT_REQUEST: "UPDATE_CUSTOM_PRODUCT_REQUEST",
  UPDATE_CUSTOM_PRODUCT_SUCCESS: "UPDATE_CUSTOM_PRODUCT_SUCCESS",

  // Get Order List
  GET_ALL_ORDERS_REQUEST: "GET_ALL_ORDERS_REQUEST",
  GET_ALL_ORDERS_SUCCESS: "GET_ALL_ORDERS_SUCCESS",

  // Get Specific Order Details
  GET_ORDER_DETAIL_REQUEST: "GET_ORDER_DETAIL_REQUEST",
  GET_ORDER_DETAIL_SUCCESS: "GET_ORDER_DETAIL_SUCCESS",

  // Approve Order
  APPROVE_ORDER_REQUEST: "APPROVE_ORDER_REQUEST",
  APPROVE_ORDER_SUCCESS: "APPROVE_ORDER_SUCCESS",

  // Reject Order
  REJECT_ORDER_REQUEST: "REJECT_ORDER_REQUEST",
  REJECT_ORDER_SUCCESS: "REJECT_ORDER_SUCCESS",

  // Get Order Rating
  ORDER_RATING_REQUEST: "ORDER_RATING_REQUEST",
  ORDER_RATING_SUCCESS: "ORDER_RATING_SUCCESS",

  // Update Order Rating
  ORDER_RATING_UPDATE_REQUEST: "ORDER_RATING_UPDATE_REQUEST",
  ORDER_RATING_UPDATE_SUCCESS: "ORDER_RATING_UPDATE_SUCCESS",

  // Update Order Status
  ORDER_STATUS_UPDATE_REQUEST: "ORDER_STATUS_UPDATE_REQUEST",
  ORDER_STATUS_UPDATE_SUCCESS: "ORDER_STATUS_UPDATE_SUCCESS",

  // Get All Return Orders List
  GET_ALL_RETURN_ORDERS_REQUEST: "GET_ALL_RETURN_ORDERS_REQUEST",
  GET_ALL_RETURN_ORDERS_SUCCESS: "GET_ALL_RETURN_ORDERS_SUCCESS",

  // Get Return Order Detail
  GET_RETURN_ORDER_DETAIL_REQUEST: "GET_RETURN_ORDER_DETAIL_REQUEST",
  GET_RETURN_ORDER_DETAIL_SUCCESS: "GET_RETURN_ORDER_DETAIL_SUCCESS",

  // Accept Return Order
  ACCEPT_RETURN_ORDER_REQUEST: "ACCEPT_RETURN_ORDER_REQUEST",
  ACCEPT_RETURN_ORDER_SUCCESS: "ACCEPT_RETURN_ORDER_SUCCESS",

  // Reject Return Order
  REJECT_RETURN_ORDER_REQUEST: "REJECT_RETURN_ORDER_REQUEST",
  REJECT_RETURN_ORDER_SUCCESS: "REJECT_RETURN_ORDER_SUCCESS",

  // Update Order Transaction id
  UPDATE_ORDER_TRANSACTION_ID_REQUEST: "UPDATE_ORDER_TRANSACTION_ID_REQUEST",
  UPDATE_ORDER_TRANSACTION_ID_SUCCESS: "UPDATE_ORDER_TRANSACTION_ID_SUCCESS",

  // Delete Category
  DELETE_CATEGORY_REQUEST: "DELETE_CATEGORY_REQUEST",
  DELETE_CATEGORY_SUCCESS: "DELETE_CATEGORY_SUCCESS",

  // Add Category
  ADD_CATEGORY_REQUEST: "ADD_CATEGORY_REQUEST",
  ADD_CATEGORY_SUCCESS: "ADD_CATEGORY_SUCCESS",

  // Update Category
  UPDATE_CATEGORY_REQUEST: "UPDATE_CATEGORY_REQUEST",
  UPDATE_CATEGORY_SUCCESS: "UPDATE_CATEGORY_SUCCESS",

  // Get Single Category
  GET_SINGLE_CATEGORY_REQUEST: "GET_SINGLE_CATEGORY_REQUEST",
  GET_SINGLE_CATEGORY_SUCCESS: "GET_SINGLE_CATEGORY_SUCCESS",

  // Delete Material
  DELETE_MATERIAL_REQUEST: "DELETE_MATERIAL_REQUEST",
  DELETE_MATERIAL_SUCCESS: "DELETE_MATERIAL_SUCCESS",

  // Add Material
  ADD_MATERIAL_REQUEST: "ADD_MATERIAL_REQUEST",
  ADD_MATERIAL_SUCCESS: "ADD_MATERIAL_SUCCESS",

  // Get Single Material
  GET_SINGLE_MATERIAL_REQUEST: "GET_SINGLE_MATERIAL_REQUEST",
  GET_SINGLE_MATERIAL_SUCCESS: "GET_SINGLE_MATERIAL_SUCCESS",

  // Add Material
  EDIT_MATERIAL_REQUEST: "EDIT_MATERIAL_REQUEST",
  EDIT_MATERIAL_SUCCESS: "EDIT_MATERIAL_SUCCESS",

  // Delete Item
  DELETE_ITEM_REQUEST: "DELETE_ITEM_REQUEST",
  DELETE_ITEM_SUCCESS: "DELETE_ITEM_SUCCESS",

  // Add Item
  ADD_ITEM_REQUEST: "ADD_ITEM_REQUEST",
  ADD_ITEM_SUCCESS: "ADD_ITEM_SUCCESS",

  // Get Single Item
  GET_SINGLE_ITEM_REQUEST: "GET_SINGLE_ITEM_REQUEST",
  GET_SINGLE_ITEM_SUCCESS: "GET_SINGLE_ITEM_SUCCESS",

  // Edit Item
  EDIT_ITEM_REQUEST: "EDIT_ITEM_REQUEST",
  EDIT_ITEM_SUCCESS: "EDIT_ITEM_SUCCESS",

  // Payment List
  GET_PAYMENT_LIST_REQUEST: "GET_PAYMENT_LIST_REQUEST",
  GET_PAYMENT_LIST_SUCCESS: "GET_PAYMENT_LIST_SUCCESS",

  // Refund Chart Data
  GET_REFUND_CHART_DATA_REQUEST: "GET_REFUND_CHART_DATA_REQUEST",
  GET_REFUND_CHART_DATA_SUCCESS: "GET_REFUND_CHART_DATA_SUCCESS",

  // Revenue Chart Data
  GET_REVENUE_CHART_DATA_REQUEST: "GET_REVENUE_CHART_DATA_REQUEST",
  GET_REVENUE_CHART_DATA_SUCCESS: "GET_REVENUE_CHART_DATA_SUCCESS",

  // Revenue Chart Data
  GET_CHART_DATA_REQUEST: "GET_CHART_DATA_REQUEST",
  GET_CHART_DATA_SUCCESS: "GET_CHART_DATA_SUCCESS",

  // Get Bank Details
  GET_BANK_DETAILS_REQUEST: "GET_BANK_DETAILS_REQUEST",
  GET_BANK_DETAILS_SUCCESS: "GET_BANK_DETAILS_SUCCESS",

  // Get Making Charge Details
  GET_MAKING_CHARGE_REQUEST: "GET_MAKING_CHARGE_REQUEST",
  GET_MAKING_CHARGE_SUCCESS: "GET_MAKING_CHARGE_SUCCESS",

  // Get Notification Details
  GET_NOTIFICATION_DETAIL_REQUEST: "GET_NOTIFICATION_DETAIL_REQUEST",
  GET_NOTIFICATION_DETAIL_SUCCESS: "GET_NOTIFICATION_DETAIL_SUCCESS",

  // Get Customer Service Id
  GET_CUSTOMER_SERVICEID_REQUEST: "GET_CUSTOMER_SERVICEID_REQUEST",
  GET_CUSTOMER_SERVICEID_SUCCESS: "GET_CUSTOMER_SERVICEID_SUCCESS",

  // Get All CMS
  GET_ALL_CMS_REQUEST: "GET_ALL_CMS_REQUEST",
  GET_ALL_CMS_SUCCESS: "GET_ALL_CMS_SUCCESS",

  // Get Term & Condition
  GET_TERM_CONDITION_REQUEST: "GET_TERM_CONDITION_REQUEST",
  GET_TERM_CONDITION_SUCCESS: "GET_TERM_CONDITION_SUCCESS",

  // Update Term & Condition
  UPDATE_TERM_CONDITION_REQUEST: "UPDATE_TERM_CONDITION_REQUEST",
  UPDATE_TERM_CONDITION_SUCCESS: "UPDATE_TERM_CONDITION_SUCCESS",
// get privacy policy
  GET_PRIVACY_POLICY_REQUEST:"GET_PRIVACY_POLICY_REQUEST",
  GET_PRIVACY_POLICY_SUCCESS:"GET_PRIVACY_POLICY_SUCCESS",
  
// update privacy policy
UPDATE_PRIVACY_POLICY_REQUEST:"UPDATE_PRIVACY_POLICY_REQUEST",
UPDATE_PRIVACY_POLICY_SUCCESS:"UPDATE_PRIVACY_POLICY_SUCCESS",
// get retun policy
  GET_RETURN_POLICY_REQUEST:"GET_RETURN_POLICY_REQUEST",
  GET_RETURN_POLICY_SUCCESS:"GET_RETURN_POLICY_SUCCESS",
  
// update return policy
UPDATE_RETURN_POLICY_REQUEST:"UPDATE_RETURN_POLICY_REQUEST",
UPDATE_RETURN_POLICY_SUCCESS:"UPDATE_RETURN_POLICY_SUCCESS",

  // Get FAQ list
  GET_FAQ_LIST_REQUEST: "GET_FAQ_LIST_REQUEST",
  GET_FAQ_LIST_SUCCESS: "GET_FAQ_LIST_SUCCESS",

  // Delete FAQ
  DELETE_FAQ_REQUEST: "DELETE_FAQ_REQUEST",
  DELETE_FAQ_SUCCESS: "DELETE_FAQ_SUCCESS",

  // Add FAQ
  ADD_FAQ_REQUEST: "ADD_FAQ_REQUEST",
  ADD_FAQ_SUCCESS: "ADD_FAQ_SUCCESS",

  // Update FAQ
  UPDATE_FAQ_REQUEST: "UPDATE_FAQ_REQUEST",
  UPDATE_FAQ_SUCCESS: "UPDATE_FAQ_SUCCESS",

  // Get FAQ detail
  GET_FAQ_DETAIL_REQUEST: "GET_FAQ_DETAIL_REQUEST",
  GET_FAQ_DETAIL_SUCCESS: "GET_FAQ_DETAIL_SUCCESS",

  // Get Blog List
  GET_BLOG_LIST_REQUEST: "GET_BLOG_LIST_REQUEST",
  GET_BLOG_LIST_SUCCESS: "GET_BLOG_LIST_SUCCESS",

  // Get Guideline List
  GET_GUIDELINE_LIST_REQUEST: "GET_GUIDELINE_LIST_REQUEST",
  GET_GUIDELINE_LIST_SUCCESS: "GET_GUIDELINE_LIST_SUCCESS",

  // Update Blog
  UPDATE_BLOG_REQUEST: "UPDATE_BLOG_REQUEST",
  UPDATE_BLOG_SUCCESS: "UPDATE_BLOG_SUCCESS",

  // Update Customer Service
  UPDATE_CUSTOMER_SERVICE_REQUEST: "UPDATE_CUSTOMER_SERVICE_REQUEST",
  UPDATE_CUSTOMER_SERVICE_SUCCESS: "UPDATE_CUSTOMER_SERVICE_SUCCESS",

  // Get Top Deal List
  GET_DEAL_LIST_REQUEST: "GET_DEAL_LIST_REQUEST",
  GET_DEAL_LIST_SUCCESS: "GET_DEAL_LIST_SUCCESS",

  // Delete deal
  DELETE_DEAL_REQUEST: "DELETE_DEAL_REQUEST",
  DELETE_DEAL_SUCCESS: "DELETE_DEAL_SUCCESS",

  // Add deal
  ADD_DEAL_REQUEST: "ADD_DEAL_REQUEST",
  ADD_DEAL_SUCCESS: "ADD_DEAL_SUCCESS",

  // Get single deal details
  GET_SINGLE_DEAL_REQUEST: "GET_SINGLE_DEAL_REQUEST",
  GET_SINGLE_DEAL_SUCCESS: "GET_SINGLE_DEAL_SUCCESS",

  // Update deal
  UPDATE_DEAL_REQUEST: "UPDATE_DEAL_REQUEST",
  UPDATE_DEAL_SUCCESS: "UPDATE_DEAL_SUCCESS",

  // Get Benefit List
  GET_BENEFIT_LIST_REQUEST: "GET_BENEFIT_LIST_REQUEST",
  GET_BENEFIT_LIST_SUCCESS: "GET_BENEFIT_LIST_SUCCESS",

  // Add Benefit
  ADD_BENEFIT_REQUEST: "ADD_BENEFIT_REQUEST",
  ADD_BENEFIT_SUCCESS: "ADD_BENEFIT_SUCCESS",

  // Add Guideline
  ADD_GUIDELINE_REQUEST: "ADD_GUIDELINE_REQUEST",
  ADD_GUIDELINE_SUCCESS: "ADD_GUIDELINE_SUCCESS",

  // Get product description list
  LIST_PRODUCT_DESCRIPTION_REQUEST: "LIST_PRODUCT_DESCRIPTION_REQUEST",
  LIST_PRODUCT_DESCRIPTION_SUCCESS: "LIST_PRODUCT_DESCRIPTION_SUCCESS",

  // Add product description list
  ADD_PRODUCT_DESCRIPTION_REQUEST: "ADD_PRODUCT_DESCRIPTION_REQUEST",
  ADD_PRODUCT_DESCRIPTION_SUCCESS: "ADD_PRODUCT_DESCRIPTION_SUCCESS",

  // Delete product description list
  DELETE_PRODUCT_DESCRIPTION_REQUEST: "DELETE_PRODUCT_DESCRIPTION_REQUEST",
  DELETE_PRODUCT_DESCRIPTION_SUCCESS: "DELETE_PRODUCT_DESCRIPTION_SUCCESS",

  // Get signle product description
  SINGLE_PRODUCT_DESCRIPTION_REQUEST: "SINGLE_PRODUCT_DESCRIPTION_REQUEST",
  SINGLE_PRODUCT_DESCRIPTION_SUCCESS: "SINGLE_PRODUCT_DESCRIPTION_SUCCESS",

  // Update product description list
  UPDATE_PRODUCT_DESCRIPTION_REQUEST: "UPDATE_PRODUCT_DESCRIPTION_REQUEST",
  UPDATE_PRODUCT_DESCRIPTION_SUCCESS: "UPDATE_PRODUCT_DESCRIPTION_SUCCESS",

  // Get product pricing list
  LIST_PRODUCT_PRICING_REQUEST: "LIST_PRODUCT_PRICING_REQUEST",
  LIST_PRODUCT_PRICING_SUCCESS: "LIST_PRODUCT_PRICING_SUCCESS",

  // Delete product pricing
  DELETE_PRODUCT_PRICING_REQUEST: "DELETE_PRODUCT_PRICING_REQUEST",
  DELETE_PRODUCT_PRICING_SUCCESS: "DELETE_PRODUCT_PRICING_SUCCESS",

  // Add product pricing
  ADD_PRODUCT_PRICING_REQUEST: "ADD_PRODUCT_PRICING_REQUEST",
  ADD_PRODUCT_PRICING_SUCCESS: "ADD_PRODUCT_PRICING_SUCCESS",

  // Update product pricing
  UPDATE_PRODUCT_PRICING_REQUEST: "UPDATE_PRODUCT_PRICING_REQUEST",
  UPDATE_PRODUCT_PRICING_SUCCESS: "UPDATE_PRODUCT_PRICING_SUCCESS",

  // Get single product pricing
  SINGLE_PRODUCT_PRICING_REQUEST: "SINGLE_PRODUCT_PRICING_REQUEST",
  SINGLE_PRODUCT_PRICING_SUCCESS: "SINGLE_PRODUCT_PRICING_SUCCESS",

  // Get product purity list
  LIST_PRODUCT_PURITY_REQUEST: "LIST_PRODUCT_PURITY_REQUEST",
  LIST_PRODUCT_PURITY_SUCCESS: "LIST_PRODUCT_PURITY_SUCCESS",

  // Delete product purity
  DELETE_PRODUCT_PURITY_REQUEST: "DELETE_PRODUCT_PURITY_REQUEST",
  DELETE_PRODUCT_PURITY_SUCCESS: "DELETE_PRODUCT_PURITY_SUCCESS",

  // Add product purity
  ADD_PRODUCT_PURITY_REQUEST: "ADD_PRODUCT_PURITY_REQUEST",
  ADD_PRODUCT_PURITY_SUCCESS: "ADD_PRODUCT_PURITY_SUCCESS",

  // Update product purity
  UPDATE_PRODUCT_PURITY_REQUEST: "UPDATE_PRODUCT_PURITY_REQUEST",
  UPDATE_PRODUCT_PURITY_SUCCESS: "UPDATE_PRODUCT_PURITY_SUCCESS",

  // Get single product purity
  SINGLE_PRODUCT_PURITY_REQUEST: "SINGLE_PRODUCT_PURITY_REQUEST",
  SINGLE_PRODUCT_PURITY_SUCCESS: "SINGLE_PRODUCT_PURITY_SUCCESS",

  // Update term and condition
  UPDATE_BLOG_TERM_CONDITION_REQUEST: "UPDATE_BLOG_TERM_CONDITION_REQUEST",
  UPDATE_BLOG_TERM_CONDITION_SUCCESS: "UPDATE_BLOG_TERM_CONDITION_SUCCESS",

  // Update fill form guideline
  UPDATE_FILL_FORM_GUIDELINE_REQUEST: "UPDATE_FILL_FORM_GUIDELINE_REQUEST",
  UPDATE_FILL_FORM_GUIDELINE_SUCCESS: "UPDATE_FILL_FORM_GUIDELINE_SUCCESS",

  // Update membership content
  UPDATE_MEMBERSHIP_CONTENT_REQUEST: "UPDATE_MEMBERSHIP_CONTENT_REQUEST",
  UPDATE_MEMBERSHIP_CONTENT_SUCCESS: "UPDATE_MEMBERSHIP_CONTENT_SUCCESS",

  logoutSaga: (navigate) => ({
    type: actions.ADMIN_LOGOUT_REQUEST,
    navigate: navigate,
  }),
  loginSaga: (data, navigate) => ({
    type: actions.ADMIN_LOGIN_REQUEST,
    payload: data,
    navigate: navigate,
  }),
  forgotPasswordSaga: (data, navigate) => ({
    type: actions.ADMIN_FORGOT_PASSWORD_REQUEST,
    payload: data,
    navigate: navigate,
  }),
  otpVerifySaga: (data, navigate) => ({
    type: actions.ADMIN_OTP_VERIFY_REQUEST,
    payload: data,
    navigate: navigate,
  }),
  resetPasswordSaga: (data, navigate) => ({
    type: actions.ADMIN_RESET_PASSWORD_REQUEST,
    payload: data,
    navigate: navigate,
  }),
  getDynamicCount: (token) => ({
    type: actions.GET_DYNAMIC_COUNT_REQUEST,
    token: token,
  }),
  getUserList: (token) => ({
    type: actions.GET_USER_LIST_REQUEST,
    token: token,
  }),
  getUserDetail: (token, param) => ({
    type: actions.GET_USER_DETAIL_REQUEST,
    param: param,
    token: token,
  }),
  deleteUser: (token, param) => ({
    type: actions.DELETE_USER_REQUEST,
    param: param,
    token: token,
  }),
  getAllProduct: (token, param) => ({
    type: actions.GET_ALL_PRODUCT_REQUEST,
    param: param,
    token: token,
  }),
  getAllCustomProduct: (token, param) => ({
    type: actions.GET_ALL_CUSTOM_PRODUCT_REQUEST,
    param: param,
    token: token,
  }),
  getProductDetail: (token, param) => ({
    type: actions.GET_PRODUCT_DETAIL_REQUEST,
    param: param,
    token: token,
  }),
  approveProduct: (token, param, navigate) => ({
    type: actions.PRODUCT_APPROVE_REQUEST,
    param: param,
    token: token,
    navigate: navigate,
  }),
  rejectProduct: (token, param, navigate) => ({
    type: actions.PRODUCT_REJECT_REQUEST,
    param: param,
    token: token,
    navigate: navigate,
  }),
  deleteProduct: (token, param, navigate) => ({
    type: actions.PRODUCT_DELETE_REQUEST,
    param: param,
    token: token,
    navigate: navigate,
  }),
  getItemData: (token, param) => ({
    type: actions.GET_ITEM_DATA_REQUEST,
    param: param,
    token: token,
  }),
  getMaterialList: (token, param) => ({
    type: actions.GET_MATERIAL_LIST_REQUEST,
    param: param,
    token: token,
  }),
  getAllCategoryList: (token, param) => ({
    type: actions.GET_CATEGORY_LIST_REQUEST,
    param: param,
    token: token,
  }),
  updateProduct: (token, param, navigate) => ({
    type: actions.UPDATE_PRODUCT_REQUEST,
    param: param,
    token: token,
    navigate: navigate,
  }),
  approveCustomProduct: (token, param, navigate) => ({
    type: actions.APPROVE_CUSTOM_PRODUCT_REQUEST,
    param: param,
    token: token,
    navigate: navigate,
  }),
  rejectCustomProduct: (token, param, navigate) => ({
    type: actions.CUSTOM_PRODUCT_REJECT_REQUEST,
    param: param,
    token: token,
    navigate: navigate,
  }),
  getCustomProductDetail: (token, param) => ({
    type: actions.CUSTOM_PRODUCT_DETAIL_REQUEST,
    param: param,
    token: token,
  }),
  updateCustomProduct: (token, param, navigate) => ({
    type: actions.UPDATE_CUSTOM_PRODUCT_REQUEST,
    param: param,
    token: token,
    navigate: navigate,
  }),
  getAllOrders: (token, param) => ({
    type: actions.GET_ALL_ORDERS_REQUEST,
    param: param,
    token: token,
  }),
  getOrderDetail: (token, param) => ({
    type: actions.GET_ORDER_DETAIL_REQUEST,
    param: param,
    token: token,
  }),
  approveOrder: (token, param, navigate) => ({
    type: actions.APPROVE_ORDER_REQUEST,
    param: param,
    token: token,
    navigate: navigate,
  }),
  rejectOrder: (token, param, navigate) => ({
    type: actions.REJECT_ORDER_REQUEST,
    param: param,
    token: token,
    navigate: navigate,
  }),
  getOrderRating: (token, param) => ({
    type: actions.ORDER_RATING_REQUEST,
    param: param,
    token: token,
  }),
  updateOrderRating: (token, param) => ({
    type: actions.ORDER_RATING_UPDATE_REQUEST,
    param: param,
    token: token,
  }),
  updateOrderStatus: (token, param) => ({
    type: actions.ORDER_STATUS_UPDATE_REQUEST,
    param: param,
    token: token,
  }),
  getAllReturnOrders: (token, param) => ({
    type: actions.GET_ALL_RETURN_ORDERS_REQUEST,
    param: param,
    token: token,
  }),
  getReturnOrderDetail: (token, param) => ({
    type: actions.GET_RETURN_ORDER_DETAIL_REQUEST,
    param: param,
    token: token,
  }),
  approveReturnOrder: (token, param, navigate) => ({
    type: actions.ACCEPT_RETURN_ORDER_REQUEST,
    param: param,
    token: token,
    navigate: navigate,
  }),
  rejectReturnOrder: (token, param, navigate) => ({
    type: actions.REJECT_RETURN_ORDER_REQUEST,
    param: param,
    token: token,
    navigate: navigate,
  }),
  updateOrderTransactionId: (token, param, navigate) => ({
    type: actions.UPDATE_ORDER_TRANSACTION_ID_REQUEST,
    param: param,
    token: token,
    navigate: navigate,
  }),
  deleteCategory: (token, param) => ({
    type: actions.DELETE_CATEGORY_REQUEST,
    param: param,
    token: token,
  }),
  addCategory: (token, param, navigate) => ({
    type: actions.ADD_CATEGORY_REQUEST,
    param: param,
    token: token,
    navigate: navigate,
  }),
  getCategoryDetail: (token, param) => ({
    type: actions.GET_SINGLE_CATEGORY_REQUEST,
    param: param,
    token: token,
  }),
  updateCategory: (token, param, navigate) => ({
    type: actions.UPDATE_CATEGORY_REQUEST,
    param: param,
    token: token,
    navigate: navigate,
  }),
  deleteMaterial: (token, param) => ({
    type: actions.DELETE_MATERIAL_REQUEST,
    param: param,
    token: token,
  }),
  addNewMaterial: (token, param, navigate) => ({
    type: actions.ADD_MATERIAL_REQUEST,
    param: param,
    token: token,
    navigate: navigate,
  }),
  editMaterial: (token, param, navigate) => ({
    type: actions.EDIT_MATERIAL_REQUEST,
    param: param,
    token: token,
    navigate: navigate,
  }),
  getMaterialDetail: (token, param) => ({
    type: actions.GET_SINGLE_MATERIAL_REQUEST,
    param: param,
    token: token,
  }),
  deleteItem: (token, param) => ({
    type: actions.DELETE_ITEM_REQUEST,
    param: param,
    token: token,
  }),
  addItem: (token, param, navigate) => ({
    type: actions.ADD_ITEM_REQUEST,
    param: param,
    token: token,
    navigate: navigate,
  }),
  getItemDetail: (token, param) => ({
    type: actions.GET_SINGLE_ITEM_REQUEST,
    param: param,
    token: token,
  }),
  editItem: (token, param, navigate) => ({
    type: actions.EDIT_ITEM_REQUEST,
    param: param,
    token: token,
    navigate: navigate,
  }),
  getPaymentList: (token, param) => ({
    type: actions.GET_PAYMENT_LIST_REQUEST,
    param: param,
    token: token,
  }),
  getRefundChartData: (token, param) => ({
    type: actions.GET_REFUND_CHART_DATA_REQUEST,
    param: param,
    token: token,
  }),
  getRevenueChartData: (token, param) => ({
    type: actions.GET_REVENUE_CHART_DATA_REQUEST,
    param: param,
    token: token,
  }),
  getChartData: (token, param) => ({
    type: actions.GET_CHART_DATA_REQUEST,
    param: param,
    token: token,
  }),
  bankDetails: (token, param) => ({
    type: actions.GET_BANK_DETAILS_REQUEST,
    param: param,
    token: token,
  }),
  getMakingCharges: (token, param) => ({
    type: actions.GET_MAKING_CHARGE_REQUEST,
    param: param,
    token: token,
  }),
  getNotificationDetail: (token, param) => ({
    type: actions.GET_NOTIFICATION_DETAIL_REQUEST,
    param: param,
    token: token,
  }),
  listCustomerServices: (token, param) => ({
    type: actions.GET_CUSTOMER_SERVICEID_REQUEST,
    param: param,
    token: token,
  }),
  getAllCMS: (token, param) => ({
    type: actions.GET_ALL_CMS_REQUEST,
    param: param,
    token: token,
  }),
  getTermCondition: (token, param) => ({
    type: actions.GET_TERM_CONDITION_REQUEST,
    param: param,
    token: token,
  }),
  updateTermCondition: (token, param, navigate) => ({
    type: actions.UPDATE_TERM_CONDITION_REQUEST,
    param: param,
    token: token,
    navigate: navigate,
  }),
  getPrivacyPolicy: (token, param) => ({
    type: actions.GET_PRIVACY_POLICY_REQUEST,
    param: param,
    token: token,
  }),
  updatePrivacyPolicy: (token, param, navigate) => ({
    type: actions.UPDATE_PRIVACY_POLICY_REQUEST,
    param: param,
    token: token,
    navigate: navigate,
  }),
  getReturnPolicy: (token, param) => ({
    type: actions.GET_RETURN_POLICY_REQUEST,
    param: param,
    token: token,
  }),
  updateReturnPolicy: (token, param, navigate) => ({
    type: actions.UPDATE_RETURN_POLICY_REQUEST,
    param: param,
    token: token,
    navigate: navigate,
  }),
  getFAQList: (token, param) => ({
    type: actions.GET_FAQ_LIST_REQUEST,
    param: param,
    token: token,
  }),
  deleteFAQ: (token, param) => ({
    type: actions.DELETE_FAQ_REQUEST,
    param: param,
    token: token,
  }),
  addNewFAQ: (token, param, navigate) => ({
    type: actions.ADD_FAQ_REQUEST,
    param: param,
    token: token,
    navigate: navigate,
  }),
  getSpecificFAQ: (token, param) => ({
    type: actions.GET_FAQ_DETAIL_REQUEST,
    param: param,
    token: token,
  }),
  updateSpecificFAQ: (token, param, navigate) => ({
    type: actions.UPDATE_FAQ_REQUEST,
    param: param,
    token: token,
    navigate: navigate,
  }),
  getBlogList: (token, param) => ({
    type: actions.GET_BLOG_LIST_REQUEST,
    param: param,
    token: token,
  }),
  getGuidelineList: (token, param) => ({
    type: actions.GET_GUIDELINE_LIST_REQUEST,
    param: param,
    token: token,
  }),
  updateSingleBlog: (token, param, navigate) => ({
    type: actions.UPDATE_BLOG_REQUEST,
    param: param,
    token: token,
    navigate: navigate,
  }),
  updateCustomerService: (token, param, navigate) => ({
    type: actions.UPDATE_CUSTOMER_SERVICE_REQUEST,
    param: param,
    token: token,
    navigate: navigate,
  }),
  getDealList: (token, param) => ({
    type: actions.GET_DEAL_LIST_REQUEST,
    param: param,
    token: token,
  }),
  deleteSingleDeal: (token, param) => ({
    type: actions.DELETE_DEAL_REQUEST,
    param: param,
    token: token,
  }),
  addNewDeal: (token, param, navigate) => ({
    type: actions.ADD_DEAL_REQUEST,
    param: param,
    token: token,
    navigate: navigate,
  }),
  getSingleDeal: (token, param) => ({
    type: actions.GET_SINGLE_DEAL_REQUEST,
    param: param,
    token: token,
  }),
  updateDeal: (token, param, navigate) => ({
    type: actions.UPDATE_DEAL_REQUEST,
    param: param,
    token: token,
    navigate: navigate,
  }),
  getBenefitList: (token, param) => ({
    type: actions.GET_BENEFIT_LIST_REQUEST,
    param: param,
    token: token,
  }),
  addBenefit: (token, param, navigate) => ({
    type: actions.ADD_BENEFIT_REQUEST,
    param: param,
    token: token,
    navigate: navigate,
  }),
  addGuideline: (token, param, navigate) => ({
    type: actions.ADD_GUIDELINE_REQUEST,
    param: param,
    token: token,
    navigate: navigate,
  }),
  getProductDescriptionList: (token, param) => ({
    type: actions.LIST_PRODUCT_DESCRIPTION_REQUEST,
    param: param,
    token: token,
  }),
  addProductDescription: (token, param, navigate) => ({
    type: actions.ADD_PRODUCT_DESCRIPTION_REQUEST,
    param: param,
    token: token,
    navigate: navigate,
  }),
  deleteProductDescription: (token, param, navigate) => ({
    type: actions.DELETE_PRODUCT_DESCRIPTION_REQUEST,
    param: param,
    token: token,
    navigate: navigate,
  }),
  getSingleProductDescription: (token, param) => ({
    type: actions.SINGLE_PRODUCT_DESCRIPTION_REQUEST,
    param: param,
    token: token,
  }),
  updateProductDescription: (token, param, navigate) => ({
    type: actions.UPDATE_PRODUCT_DESCRIPTION_REQUEST,
    param: param,
    token: token,
    navigate: navigate,
  }),
  getProductPricingList: (token, param) => ({
    type: actions.LIST_PRODUCT_PRICING_REQUEST,
    param: param,
    token: token,
  }),
  deleteProductPricing: (token, param, navigate) => ({
    type: actions.DELETE_PRODUCT_PRICING_REQUEST,
    param: param,
    token: token,
    navigate: navigate,
  }),
  addProductPricing: (token, param, navigate) => ({
    type: actions.ADD_PRODUCT_PRICING_REQUEST,
    param: param,
    token: token,
    navigate: navigate,
  }),
  updateProductPricing: (token, param, navigate) => ({
    type: actions.UPDATE_PRODUCT_PRICING_REQUEST,
    param: param,
    token: token,
    navigate: navigate,
  }),
  getSingleProductPricing: (token, param) => ({
    type: actions.SINGLE_PRODUCT_PRICING_REQUEST,
    param: param,
    token: token,
  }),
  getProductPurity: (token, param) => ({
    type: actions.LIST_PRODUCT_PURITY_REQUEST,
    param: param,
    token: token,
  }),
  deleteProductPurity: (token, param) => ({
    type: actions.DELETE_PRODUCT_PURITY_REQUEST,
    param: param,
    token: token,
  }),
  addProductPurity: (token, param, navigate) => ({
    type: actions.ADD_PRODUCT_PURITY_REQUEST,
    param: param,
    token: token,
    navigate: navigate,
  }),
  updateProductPurity: (token, param, navigate) => ({
    type: actions.UPDATE_PRODUCT_PURITY_REQUEST,
    param: param,
    token: token,
    navigate: navigate,
  }),
  getSingleProductPurity: (token, param) => ({
    type: actions.SINGLE_PRODUCT_PURITY_REQUEST,
    param: param,
    token: token,
  }),
  updateBlogTermCondition: (token, param, navigate) => ({
    type: actions.UPDATE_BLOG_TERM_CONDITION_REQUEST,
    param: param,
    token: token,
    navigate: navigate,
  }),
  updateFormFillGuideline: (token, param, navigate) => ({
    type: actions.UPDATE_FILL_FORM_GUIDELINE_REQUEST,
    param: param,
    token: token,
    navigate: navigate,
  }),
  updateMembershipContent: (token, param, navigate) => ({
    type: actions.UPDATE_MEMBERSHIP_CONTENT_REQUEST,
    param: param,
    token: token,
    navigate: navigate,
  }),
};

export default actions;
