import React from 'react'

//#region ALL HOOKS AND VARIABALS
const SingleMultipleOrders = () => {
//#endregion

//#region ALL EVENT HANDLERS AND  FUCTIONS 
//#endregion

//#region RETURN JSX 
  return (
    <div>SingleMultipleOrders</div>
  )
}

export default SingleMultipleOrders
//#endregion