import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { price } from "../../../../component/customComponents/commonFunctions/CommonFunctions";
import UserIndex from "../../UserIndex";

const { ProductSaga, addcartSaga, OrderPlaceSaga } = UserIndex.UserSagaActions;

export default function ProductDetails() {
  let params = UserIndex.useParams();
  const dispatch = UserIndex.useDispatch();
  const history = UserIndex.useHistory();
  const navigate = UserIndex.useHistory();
  const [product, setProduct] = useState();
  const [qty, setQty] = useState(1);
  const [buyNowData, setBuyNowData] = useState("");
  const [material, setMaterial] = useState();

  const qtyPlus = () => {
    setQty(qty + 1);
  };
  const qtyMinus = () => {
    setQty(qty - 1);
  };
  const loading = UserIndex.useSelector((state) => {
    return state.Usersaga.loading;
  });

  const Token = UserIndex.useSelector((state) => {
    return state.Usersaga.Token;
  });

  const Userdata = UserIndex.useSelector((state) => {
    return state.Usersaga.Userdata;
  });

  const ProductDetaildata = UserIndex.useSelector((state) => {
    return state.Usersaga.ProductDetaildata;
  });

  const getProduct = () => {
    let urlencoded = new URLSearchParams();
    urlencoded.append("id", params.id);
    dispatch(ProductSaga(urlencoded, navigate, Token));
  };

  const Cartdata = UserIndex.useSelector((state) => {
    return state.Usersaga.Cartdata;
  });

  const OrderPlacedata = UserIndex.useSelector((state) => {
    return state.Usersaga.OrderPlacedata;
  });

  const getAddToCart = () => {
    let urlencoded = new URLSearchParams();
    urlencoded.append("productId", params.id);
    urlencoded.append("quantity", qty);
    urlencoded.append("shipping", "100");
    dispatch(addcartSaga(urlencoded, navigate, Token));
  };

  useEffect(() => {
    getProduct();
  }, []);

  useEffect(() => {
    setProduct(ProductDetaildata);
  }, [ProductDetaildata]);

  const productQty = {
    id: params.id,
    qty: qty,
    totalPrice: product?.[0]?.total_payable_amount,
  };

  useEffect(() => {
    setBuyNowData(productQty);
  }, [qty]);

  useEffect(() => {
    let arr = [];
    {
      product?.[0]?.materialIdArray?.map((item) => {
        arr.push(item?.material_id?.material);
      });
    }

    setMaterial(arr);
  }, [product?.[0]]);

  const goldFields = () => {
    return (
      <>
        {product?.[0]?.gold
          ? product?.[0]?.gold?.map((gold) => {
              return (
                <UserIndex.Typography
                  variant="h6"
                  component="h6"
                  className="weight-text"
                >
                  Gold Wt(gm) :{gold?.weight}g
                </UserIndex.Typography>
              );
            })
          : ""}
      </>
    );
  };

  const silverFields = () => {
    return (
      <>
        {product?.[0]?.silver?.length
          ? product?.[0]?.silver?.map((silver) => {
              return (
                <UserIndex.Typography
                  variant="h6"
                  component="h6"
                  className="weight-text"
                >
                  Silver Wt(gm) :{silver?.weight} g
                </UserIndex.Typography>
              );
            })
          : ""}
      </>
    );
  };

  const gemstoneFields = () => {
    return (
      <>
        {product?.[0]?.gemstone?.length
          ? product?.[0]?.gemstone?.map((gems) => {
              return (
                <UserIndex.Typography
                  variant="h6"
                  component="h6"
                  className="weight-text"
                >
                  Gemstone Wt(gm) :{gems.total_weight}g
                </UserIndex.Typography>
              );
            })
          : ""}
      </>
    );
  };

  const diamondFields = () => {
    return (
      <>
        {product?.[0]?.diamond?.length
          ? product?.[0]?.diamond?.map((diam) => {
              return (
                <UserIndex.Typography
                  variant="h6"
                  component="h6"
                  className="weight-text"
                >
                  Diamond Wt(gm) :{diam.total_weight}g
                </UserIndex.Typography>
              );
            })
          : ""}
      </>
    );
  };

  const renderFieldMaterialWise = () => {
    switch (true) {
      case product?.[0]?.gold?.length > 0 &&
        product?.[0]?.diamond?.length > 0 &&
        product?.[0]?.materialIdArray?.length === 2:
        return (
          <>
            {goldFields()}
            {diamondFields()}
          </>
        );
      case product?.[0]?.gold?.length > 0 &&
        product?.[0]?.gemstone?.length > 0 &&
        product?.[0]?.materialIdArray?.length === 2:
        return (
          <>
            {goldFields()}
            {gemstoneFields()}
          </>
        );
      case product?.[0]?.silver?.length > 0 &&
        product?.[0]?.diamond?.length > 0 &&
        product?.[0]?.materialIdArray?.length === 2:
        return (
          <>
            {silverFields()}
            {diamondFields()}
          </>
        );
      case product?.[0]?.silver?.length > 0 &&
        product?.[0]?.gemstone?.length > 0 &&
        product?.[0]?.materialIdArray?.length === 2:
        return (
          <>
            {silverFields()}
            {gemstoneFields()}
          </>
        );
      case product?.[0]?.silver?.length > 0 &&
        product?.[0]?.gold?.length > 0 &&
        product?.[0]?.materialIdArray?.length === 2:
        return (
          <>
            {silverFields()}
            {goldFields()}
          </>
        );
      case product?.[0]?.gemstone?.length > 0 &&
        product?.[0]?.diamond?.length > 0 &&
        product?.[0]?.materialIdArray?.length === 2:
        return (
          <>
            {gemstoneFields()}
            {diamondFields()}
          </>
        );
      case product?.[0]?.gold?.length > 0 &&
        product?.[0]?.silver?.length > 0 &&
        product?.[0]?.diamond?.length > 0 &&
        product?.[0]?.materialIdArray?.length === 3:
        return (
          <>
            {silverFields()}
            {goldFields()}
            {diamondFields()}
          </>
        );
      case product?.[0]?.silver?.length > 0 &&
        product?.[0]?.gold?.length > 0 &&
        product?.[0]?.gemstone?.length > 0 &&
        product?.[0]?.materialIdArray?.length === 3:
        return (
          <>
            {silverFields()}
            {goldFields()}
            {gemstoneFields()}
          </>
        );
      case product?.[0]?.gold?.length > 0 &&
        product?.[0]?.diamond?.length > 0 &&
        product?.[0]?.gemstone?.length > 0 &&
        product?.[0]?.materialIdArray?.length === 3:
        return (
          <>
            {goldFields()}
            {diamondFields()}
            {gemstoneFields()}
          </>
        );
      case product?.[0]?.silver?.length > 0 &&
        product?.[0]?.diamond?.length > 0 &&
        product?.[0]?.gemstone?.length > 0 &&
        product?.[0]?.materialIdArray?.length === 3:
        return (
          <>
            {silverFields()}
            {gemstoneFields()}
            {diamondFields()}
          </>
        );
      case product?.[0]?.gold?.length > 0 &&
        product?.[0]?.silver?.length > 0 &&
        product?.[0]?.gemstone?.length > 0 &&
        product?.[0]?.diamond?.length > 0 &&
        product?.[0]?.materialIdArray?.length === 4:
        return (
          <>
            {goldFields()}
            {silverFields()}
            {gemstoneFields()}
            {diamondFields()}
          </>
        );
      case product?.[0]?.gold?.length > 0:
        return goldFields();
      case product?.[0]?.silver?.length > 0:
        return silverFields();
      case product?.[0]?.diamond?.length > 0:
        return diamondFields();
      case product?.[0]?.gemstone?.length > 0:
        return gemstoneFields();
      default:
        return "";
    }
  };

  const showToastMessage = () => {
    toast.error("Please login first to view/buy product");
  };

  const showCustomToastMessage = () => {
    toast.error("You need to login to request custom product")
  };

  return (
    <div>
      <UserIndex.Header />
      <UserIndex.Box className="rings1-flower-sec">
        <UserIndex.Box className="container">
          <UserIndex.Box sx={{ width: 1 }}>
            <UserIndex.Box
              display="grid"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 0, sm: 5, md: 8, lg: 8 }}
            >
              <UserIndex.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 6",
                }}
              >
                <UserIndex.Box className="rings-left-banner-box">
                  <img
                    src={product?.[0]?.image[0]}
                    className="rings-left-banner-img"
                  ></img>
                </UserIndex.Box>
                <UserIndex.Box className="rings1-images-main">
                  {product?.[0]?.image?.map((element) => (
                    <UserIndex.Box
                      sx={{ display: "flex" }}
                      className="rings1-img-box"
                    >
                      <img src={element} className="rings1-img"></img>
                    </UserIndex.Box>
                  ))}
                </UserIndex.Box>
              </UserIndex.Box>
              <UserIndex.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 6",
                }}
              >
                <UserIndex.Box className="flower-text-box">
                  <UserIndex.Typography
                    variant="h4"
                    component="h4"
                    className="flower-title"
                  >
                    {product?.[0]?.item_id?.item}
                  </UserIndex.Typography>

                  <UserIndex.Typography
                    variant="h6"
                    component="h6"
                    className="rosegold-text"
                  >
                    {material?.toString()}
                  </UserIndex.Typography>

                  {renderFieldMaterialWise()}

                  <UserIndex.Typography
                    variant="h6"
                    component="h6"
                    className="rings1-price-text"
                  >
                    ₹ {price(product?.[0]?.total_payable_amount)}
                  </UserIndex.Typography>

                  <UserIndex.Typography
                    variant="h6"
                    component="h6"
                    className="description-text"
                  >
                    Description
                  </UserIndex.Typography>

                  <UserIndex.Typography
                    variant="p"
                    component="p"
                    className="description-para mb-26"
                  >
                    {product?.[0]?.product_dimension?.meta_description}
                  </UserIndex.Typography>
                </UserIndex.Box>

                {Userdata?.[0]?._id === product?.[0]?.user_id?._id ? (
                  ""
                ) : (
                  <>
                    {product?.[0]?.no_of_pieces_available_inStock !== 0 ? (
                      <>
                        <UserIndex.Box className="add-quenty-box rings1-btn-box">
                          <UserIndex.Box className="add-quenty-btn-box">
                            <UserIndex.Box className="quenty-text">
                              Qty :
                            </UserIndex.Box>
                            <UserIndex.Box className="quenty-btn-box">
                              <UserIndex.ButtonGroup className="qty-btn-group">
                                {qty > 1 ? (
                                  <UserIndex.Button
                                    className="btn btn-qty"
                                    onClick={qtyMinus}
                                  >
                                    -
                                  </UserIndex.Button>
                                ) : (
                                  <UserIndex.Button
                                    disabled
                                    className="btn btn-qty"
                                    onClick={qtyMinus}
                                  >
                                    -
                                  </UserIndex.Button>
                                )}

                                <UserIndex.Button className="btn btn-qty">
                                  {qty}
                                </UserIndex.Button>

                                <UserIndex.Button
                                  className="btn btn-qty"
                                  onClick={qtyPlus}
                                >
                                  +
                                </UserIndex.Button>
                              </UserIndex.ButtonGroup>
                            </UserIndex.Box>
                          </UserIndex.Box>

                          {Token && Token !== null ? (
                            <>
                              <UserIndex.Button
                                className="btn rings1-btn add-cart-btn"
                                onClick={() => {
                                  getAddToCart(Cartdata);
                                }}
                              >
                                Add to Cart
                              </UserIndex.Button>
                            </>
                          ) : (
                            <>
                              <UserIndex.Button
                                className="btn rings1-btn add-cart-btn"
                                onClick={() => {
                                  showToastMessage();
                                }}
                              >
                                Add to Cart
                              </UserIndex.Button>
                            </>
                          )}
                        </UserIndex.Box>

                        <UserIndex.Box className="rings1-btn-box">
                          {Token && Token !== null ? (
                            <>
                              <UserIndex.Button
                                onClick={() => {
                                  history.push("/user/requestingproduct");
                                }}
                                className="btn rings1-btn customis-btn"
                              >
                                Customize
                              </UserIndex.Button>
                            </>
                          ) : (
                            <>
                              <UserIndex.Button
                                onClick={() => {
                                  showCustomToastMessage();
                                }}
                                className="btn rings1-btn customis-btn"
                              >
                                Customize
                              </UserIndex.Button>
                            </>
                          )}

                          {Token && Token !== null ? (
                            <>
                              <UserIndex.Button
                                onClick={() => {
                                  history.push(
                                    `/user/paymentmethodid/${window.btoa(
                                      JSON.stringify(buyNowData)
                                    )}`
                                  );
                                }}
                                // buyNowData
                                className="btn rings1-btn buy-now-btn"
                              >
                                Buy Now
                              </UserIndex.Button>
                            </>
                          ) : (
                            <>
                              <UserIndex.Button
                                onClick={() => {
                                  showToastMessage();
                                }}
                                // buyNowData
                                className="btn rings1-btn buy-now-btn"
                              >
                                Buy Now
                              </UserIndex.Button>
                            </>
                          )}
                        </UserIndex.Box>
                      </>
                    ) : (
                      <>
                        <UserIndex.Box
                          sx={{
                            color: "#FF0303",
                            fontWeight: 600,
                            fontSize: "19px",
                            fontFamily: "mulish-semibold",
                          }}
                        >
                          Out Of Stock
                        </UserIndex.Box>
                      </>
                    )}
                  </>
                )}
              </UserIndex.Box>
            </UserIndex.Box>
          </UserIndex.Box>
        </UserIndex.Box>
      </UserIndex.Box>
      <UserIndex.ProductDescription data={product?.[0]} />
      <UserIndex.Certificates data={product?.[0]} />
      <UserIndex.Footer />
    </div>
  );
}
