import { Box } from '@mui/material'
import React from 'react'
import Gif from '../../../assets/gif'
const Loader = () => {
  return (
    // <div class="loading">Loading&#8230;</div>
    <div className="loading">
       {/* <Box sx={{width:'10px',height:'10px'}}>    */}
      <img className='loading-diamond' src={Gif.loading} />
      {/* </Box> */}
    </div>
  )
}

export default Loader