// import React from 'react'

// const ForgotPasswordEmail = () => {
//   return (
//     <div>ForgotPasswordEmail</div>
//   )
// }

// export default ForgotPasswordEmail
import React from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Api } from "../../../../config/api";
import dataService from "../../../../config/dataService";
import Png from "../../../../assets/png";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import { ErrorMsg } from "../../../../component/customComponents/errorMsg/ErrorMsg";
import { EMAIL_REGEX } from "../../../../component/customComponents/validation/Validation";
import Mui from "../../../../component/customComponents/mui/Mui";
import { useSelector, useDispatch } from "react-redux";
// import actions from "../../../../redux/adminSaga/action";
import actions from "../../../../redux/Usersaga/action";
import { CircularProgress } from "@mui/material";

const { ForgotPasswordSaga } = actions;
//#region ALL HOOKS AND VARIABALS
const ForgotPasswordEmail = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const navigate = useHistory();
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const loading = useSelector((state) => {
    return state.AdminSaga.loading;
  });

  //#endregion

  //#region ALL EVENT HANDLERS AND  FUCTIONS

  const onSubmit = async (data) => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("email", data.email);
    dispatch(ForgotPasswordSaga(urlencoded, navigate));
    // await dataService.post(Api.Admin.forgotPassword, urlencoded).then((res) => {
    //   if (res?.data?.data?.status == 1) {
    //     toast.success(res.data.message);
    //     history.push("/admin/otpverify");
    //   }
    // });
  };
  //#endregion

  //#region RETURN JSX
  return (
    <Mui.Card
      variant="outlined"
      sx={{
        margin: "auto",
        marginTop: { sm: "10%", xs: "50%" },
        width: { sm: 500, xs: "100%" },
        maxWidth: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Mui.Box
          sx={{
            margin: "auto",
            width: { sm: 500, xs: "100%" },
            maxWidth: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Mui.Box>
            <img src={Png.footerlogo} className="login-zaverica-logo"></img>
          </Mui.Box>
          <h2 style={{ textAlign: "center" }}>Forgot Password User</h2>
          <Mui.TextField
            autoComplete="off"
            sx={{
              "& .MuiFormHelperText-root": { ml: 0, mt: "5px" },
              margin: "auto",
              my: 2,
              width: { sm: "70%", xs: "85%" },
            }}
            fullWidth
            label="Email"
            id="fullWidth"
            name="email"
            {...register("email", {
              required: <ErrorMsg msg="Please enter email" />,
              pattern: {
                value: EMAIL_REGEX,
                message: <ErrorMsg msg="Please enter valid Email" />,
              },
            })}
            // error={Boolean(errors.email)}
            helperText={<ErrorMessage errors={errors} name="email" />}
          />
          <Mui.Box sx={{ display: "flex", justifyContent: "center" }}>
            <Mui.Button
              //   sx={{ my: 2, width: "150px", textTransform: "none" }}
              sx={{
                my: 2,
                width: "72%",
                textTransform: "none",
                backgroundColor: "#c29958",
              }}
              variant="contained"
              type="submit"
            >
             Send OTP
              {loading && (
                <CircularProgress sx={{ color: "white", ml: 1 }} size="20px" />
              )}
            </Mui.Button>
          </Mui.Box>
        </Mui.Box>
      </form>
    </Mui.Card>
  );
};
export default ForgotPasswordEmail;
//#endregion
