import React, { useEffect, useState } from 'react'
import UserIndex from '../../UserIndex'

const { catelogueSellerSaga } = UserIndex.UserSagaActions

const CataloguesSeller = () => {
  let params = UserIndex.useParams()
  const dispatch = UserIndex.useDispatch()
  const history = UserIndex.useHistory()
  const navigate = UserIndex.useHistory()

  const [seller, setSeller] = useState('')

  const CatelogueSeller = UserIndex.useSelector((state) => {
    return state.Usersaga.CatelogueSeller
  })

  const getListSeller = async () => {
    let urlencoded = new URLSearchParams()
    urlencoded.append('item_id', params.item_id)
    dispatch(catelogueSellerSaga(urlencoded, navigate))
  }

  useState(() => {
    setSeller(CatelogueSeller)
  }, [CatelogueSeller])

  useEffect(() => {
    getListSeller()
  }, [params.item_id])

  useEffect(() => {
    getListSeller()
  }, [])

  return (
    <div>
      <UserIndex.Header />
      <UserIndex.Box style={{ backgroundColor: '#f6f6f6' }}>
        <UserIndex.Box className="catalogues-main-container">
          <UserIndex.Box>
            <UserIndex.Box className="title-faq-asked">
              <UserIndex.Typography
                sx={{ textAlign: 'left' }}
                className="frequently-text"
              >
                Catalogues Sellers
              </UserIndex.Typography>
            </UserIndex.Box>
            <UserIndex.Box sx={{ width: 1 }}>
              <UserIndex.Box
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                gap={{ xs: 3, sm: 5, md: 4, lg: 4 }}
              >
                {CatelogueSeller.length
                  ? CatelogueSeller.map((data) => {
                      let itemData = {
                        itemId: params?.item_id,
                        sellerId: data?._id,
                      }
                      return (
                        <>
                          <UserIndex.Box
                            gridColumn={{
                              xs: 'span 12',
                              sm: 'span 6',
                              md: 'span 3',
                              lg: 'span 3',
                            }}
                            sx={{ cursor: 'pointer' }}
                            onClick={() => {
                              history.push(
                                `/user/cataloguedetails/${window.btoa(
                                  JSON.stringify(itemData),
                                )}`,
                              )
                            }}
                          >
                            <UserIndex.Box className="product-box">
                              <UserIndex.Box className="product-img-box">
                                <img
                                  src={data.uploadBrandImage}
                                  className="product-img"
                                ></img>
                              </UserIndex.Box>
                              <UserIndex.Box className="product-content">
                                <UserIndex.Box className="product-sub-title">
                                  {data.company_name}
                                </UserIndex.Box>
                                <UserIndex.Box className="product-sub-title">
                                  {data.business_type}
                                </UserIndex.Box>
                                <UserIndex.Box className="product-sub-title">
                                  {data.name}
                                </UserIndex.Box>
                              </UserIndex.Box>
                            </UserIndex.Box>
                          </UserIndex.Box>
                        </>
                      )
                    })
                  : 'No Sellers Found'}
              </UserIndex.Box>
            </UserIndex.Box>
          </UserIndex.Box>
        </UserIndex.Box>
      </UserIndex.Box>
      <UserIndex.Footer />
    </div>
  )
}

export default CataloguesSeller
