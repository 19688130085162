import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import {
  Button,
  Typography,
  Grid,
  Tooltip,
  Box,
  FormHelperText,
  TextareaAutosize,
  MenuItem,
  FormControl,
  Select,
  TextField,
} from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload';
import png from '../../../../assets/png';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Api } from '../../../../config/api';
import dataService from '../../../../config/dataService';
import { toast } from 'react-toastify';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import ContainedButton from '../../../../component/customComponents/buttons/ContainedButton';
import ImgUploadButton from '../../../../component/customComponents/buttons/ImgUploadButton';
import { useFieldArray, useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import {
  ErrorMsg,
  TextAreaErrorMsg,
} from '../../../../component/customComponents/errorMsg/ErrorMsg';
import {
  minNoOfPiecesAvailableInStockLength,
  maxNoOfPiecesAvailableInStockLength,
  minSpecifyLength,
  maxSpecifyLength,
  minAdditionalDetailsLength,
  maxAdditionalDetailsLength,
  minPurityLength,
  maxPurityLength,
  minWeightLength,
  maxWeightLength,
  minMakingChargesLength,
  makingChargesLength,
  minOtherChargesLength,
  maxOtherChargesLength,
  minPieceLength,
  maxPieceLength,
  minQuantityLength,
  maxQuantityLength,
  minPricePerQuantityLength,
  maxPricePerQuantityLength,
  minSuppliersMakingChargesLength,
  maxSuppliersMakingChargesLength,
  minProductDimensionsInnerCircumferenceLength,
  maxProductDimensionsInnerCircumferenceLength,
  minProductDimensionsLengthsLength,
  maxProductDimensionsLengthsLength,
  minProductDimensionsDepthLength,
  maxProductDimensionsDepthLength,
  minProductDimensionsMetaTitleLength,
  maxProductDimensionsMetaTitleLength,
  minProductDimensionsMetaDescriptionLength,
  maxProductDimensionsMetaDescriptionLength,
  NO_WHITE_SPACE_BEGINNING_END,
  NO_DECIMAL,
} from '../../../../component/customComponents/validation/Validation';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

//#region ALL HOOKS AND VARIABALS
const Img = styled('img')({
  display: 'block',
  maxWidth: '100%',
  height: 'auto',
  maxHeight: '50vh',
  borderRadius: '6px',
});

const AddProducts = () => {
  let token = localStorage.getItem('auth');
  const history = useHistory();
  const [image, setImage] = useState([]);
  const [show, setShow] = useState(true);
  const [materialId, setMaterialId] = useState([]);
  const [selectItem, setSelectItem] = useState([]);
  const [selectMaterial, setSelectMaterial] = useState([]);
  const [selectCategory, setSelectCategory] = useState([]);
  let materialArray = [];

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
    watch,
  } = useForm({
    defaultValues: {
      gemstoneInput: [
        {
          piece: '',
          quantity: '',
          purity: '',
          total_weight: '',
          price_per_quantity: '',
          price_of_total_quantity: 0,
          final_price: 0,
          making_charge: '',
        },
      ],

      diamondInput: [
        {
          piece: '',
          quantity: '',
          purity: '',
          total_weight: '',
          price_per_quantity: '',
          price_of_total_quantity: 0,
          final_price: 0,
          making_charge: '',
        },
      ],
    },
  });

  const {
    fields: dynamicGemstoneInputFields,
    append: dynamicGemstoneInputAppend,
    remove: dynamicGemstoneInputRemove,
  } = useFieldArray({ control, name: 'gemstoneInput' });

  const {
    fields: dynamicDiamondInputFields,
    append: dynamicDiamondInputAppend,
    remove: dynamicDiamondInputRemove,
  } = useFieldArray({ control, name: 'diamondInput' });

  useEffect(() => {
    watch('material').length >= 1 &&
      watch('material').map((item) => {
        let temp = selectMaterial.filter((a) => a.material == item);
        materialArray.push({ material_id: temp[0]?._id });
        setMaterialId(materialArray);
      });
  }, [watch('material')]);

  useEffect(() => {
    getItemData();
    getMaterialData();
    getCategoryData();
  }, []);
  //#endregion

  //#region ALL EVENT HANDLERS AND  FUCTIONS
  const nextPage = () => {
    if (
      watch('noOfPiecesAvailableInStock') !== '' &&
      watch('item') !== '' &&
      watch('material') !== '' &&
      watch('category') !== '' &&
      watch('specify') !== '' &&
      watch('additionalDetails') !== '' &&
      image != ''
    ) {
      setShow(false);
    }
  };

  const getItemData = async () => {
    const urlencoded = new URLSearchParams();
    try {
      const itemData = await dataService.post(Api.Admin.listItem, urlencoded);
      setSelectItem(itemData.data.data);
    } catch (err) {
      toast.error(err?.message || 'Something went wrong4');
    }
  };
  const getMaterialData = async () => {
    const urlencoded = new URLSearchParams();
    try {
      const materialData = await dataService.post(
        Api.Admin.listMaterial,
        urlencoded
      );
      setSelectMaterial(materialData.data.data);
    } catch (err) {
      toast.error(err?.message || 'Something went wrong3');
    }
  };
  const getCategoryData = async () => {
    const urlencoded = new URLSearchParams();
    try {
      const categoryData = await dataService.post(
        Api.Admin.getAllCategory,
        urlencoded
      );
      setSelectCategory(categoryData.data.data);
    } catch (err) {
      toast.error(err?.message || 'Something went wrong2');
    }
  };

  const handleImg = (e) => {
    if (e.target.files && e.target.files[0]) {
      setImage([...image, ...e.target.files]);
    } else if (!e.target.files.length) {
      toast.error('select image');
    }
  };
  const removeImg = () => {
    setImage([]);
  };

  const removeSpecificImg = (element) => {
    const filterIndex = image.indexOf(element);
    if (filterIndex > -1) {
      image.splice(filterIndex, 1);
      setImage(image.filter((element, i) => i !== element));
    }
  };

  const goldFields = () => {
    return (
      <>
        <Box sx={{ border: 1, p: 2, my: 1 }}>
          <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={1}>
            <Box
              gridColumn={{
                xs: 'span 12',
                sm: 'span 12',
                md: 'span 12',
                lg: 'span 12',
              }}
            >
              <Box className="addinfo-text-box-1">
                <Typography variant="h2" className="addinfo-text-1">
                  Gold
                </Typography>
              </Box>
            </Box>
            <Box
              gridColumn={{
                xs: 'span 12',
                sm: 'span 6',
                md: 'span 6',
                lg: 'span 6',
              }}
            >
              <Box className="refrence-product-input addproduct-1-input-box">
                <Box className="input-box addproduct-input-box">
                  <FormHelperText className="input-lable">
                    Purity(%)
                  </FormHelperText>
                  <TextField
                    autoComplete="off"
                    sx={{
                      // mt: 2,
                      '& .MuiFormHelperText-root': { ml: 0, mt: '5px' },
                    }}
                    fullWidth
                    size="small"
                    className="form-control border-none"
                    name="goldPurity"
                    type="text"
                    {...register(`goldPurity`, {
                      required: <ErrorMsg msg="Please enter Purity(%)" />,
                      minLength: {
                        value: minPurityLength,
                        message: (
                          <ErrorMsg
                            msg={`Minimum ${minPurityLength} Characters Allowed`}
                          />
                        ),
                      },
                      maxLength: {
                        value: maxPurityLength,
                        message: (
                          <ErrorMsg
                            msg={`Maximum ${maxPurityLength} Characters Allowed`}
                          />
                        ),
                      },
                      pattern: {
                        value: NO_WHITE_SPACE_BEGINNING_END,
                        message: (
                          <ErrorMsg
                            msg={
                              'Whitespace is not allowed at beginning or end'
                            }
                          />
                        ),
                      },
                      validate: {
                        isNumber: (val) =>
                          !isNaN(val) || <ErrorMsg msg={'Must be a Number'} />,
                      },
                    })}
                    helperText={
                      <ErrorMessage errors={errors} name={`goldPurity`} />
                    }
                  />
                </Box>
              </Box>
            </Box>
            <Box
              gridColumn={{
                xs: 'span 12',
                sm: 'span 6',
                md: 'span 6',
                lg: 'span 6',
              }}
            >
              <Box className="refrence-product-input">
                <Box className="input-box addproduct-1-input-box">
                  <FormHelperText className="input-lable">
                    Weight(gm)
                  </FormHelperText>
                  <TextField
                    autoComplete="off"
                    sx={{
                      // mt: 2,
                      '& .MuiFormHelperText-root': { ml: 0, mt: '5px' },
                    }}
                    fullWidth
                    size="small"
                    className="form-control border-none"
                    name="goldWeight"
                    type="text"
                    {...register(`goldWeight`, {
                      required: <ErrorMsg msg="Please enter Weight(gm)" />,
                      minLength: {
                        value: minWeightLength,
                        message: (
                          <ErrorMsg
                            msg={`Minimum ${minWeightLength} Characters Allowed`}
                          />
                        ),
                      },
                      maxLength: {
                        value: maxWeightLength,
                        message: (
                          <ErrorMsg
                            msg={`Maximum ${maxWeightLength} Characters Allowed`}
                          />
                        ),
                      },
                      pattern: {
                        value: NO_WHITE_SPACE_BEGINNING_END,
                        message: (
                          <ErrorMsg
                            msg={
                              'Whitespace is not allowed at beginning or end'
                            }
                          />
                        ),
                      },
                      validate: {
                        isNumber: (val) =>
                          !isNaN(val) || <ErrorMsg msg={'Must be a Number'} />,
                      },
                    })}
                    helperText={
                      <ErrorMessage errors={errors} name={`goldWeight`} />
                    }
                  />
                </Box>
              </Box>
            </Box>
            <Box
              gridColumn={{
                xs: 'span 12',
                sm: 'span 6',
                md: 'span 6',
                lg: 'span 6',
              }}
            >
              <Box className="refrence-product-input">
                <Box className="input-box addproduct-1-input-box">
                  <FormHelperText className="input-lable">
                    Making Charges(%)
                  </FormHelperText>
                  <TextField
                    autoComplete="off"
                    sx={{
                      // mt: 2,
                      '& .MuiFormHelperText-root': { ml: 0, mt: '5px' },
                    }}
                    fullWidth
                    size="small"
                    className="form-control border-none"
                    name="goldMakingCharges"
                    type="text"
                    {...register(`goldMakingCharges`, {
                      required: (
                        <ErrorMsg msg="Please enter Making Charges(%)" />
                      ),
                      minLength: {
                        value: minMakingChargesLength,
                        message: (
                          <ErrorMsg
                            msg={`Minimum ${minMakingChargesLength} Characters Allowed`}
                          />
                        ),
                      },
                      maxLength: {
                        value: makingChargesLength,
                        message: (
                          <ErrorMsg
                            msg={`Maximum ${makingChargesLength} Characters Allowed`}
                          />
                        ),
                      },
                      pattern: {
                        value: NO_WHITE_SPACE_BEGINNING_END,
                        message: (
                          <ErrorMsg
                            msg={
                              'Whitespace is not allowed at beginning or end'
                            }
                          />
                        ),
                      },
                      validate: {
                        isNumber: (val) =>
                          !isNaN(val) || <ErrorMsg msg={'Must be a Number'} />,
                      },
                    })}
                    helperText={
                      <ErrorMessage
                        errors={errors}
                        name={`goldMakingCharges`}
                      />
                    }
                  />
                </Box>
              </Box>
            </Box>
            <Box
              gridColumn={{
                xs: 'span 12',
                sm: 'span 6',
                md: 'span 6',
                lg: 'span 6',
              }}
            >
              <Box className="refrence-product-input">
                <Box className="input-box addproduct-1-input-box">
                  <FormHelperText className="input-lable">
                    Other Charges
                  </FormHelperText>
                  <TextField
                    autoComplete="off"
                    sx={{
                      // mt: 2,
                      '& .MuiFormHelperText-root': { ml: 0, mt: '5px' },
                    }}
                    fullWidth
                    size="small"
                    className="form-control border-none"
                    name="goldOtherCharges"
                    type="text"
                    {...register(`goldOtherCharges`, {
                      required: <ErrorMsg msg="Please enter Other Charges" />,
                      minLength: {
                        value: minOtherChargesLength,
                        message: (
                          <ErrorMsg
                            msg={`Minimum ${minOtherChargesLength} Characters Allowed`}
                          />
                        ),
                      },
                      maxLength: {
                        value: maxOtherChargesLength,
                        message: (
                          <ErrorMsg
                            msg={`Maximum ${maxOtherChargesLength} Characters Allowed`}
                          />
                        ),
                      },
                      pattern: {
                        value: NO_WHITE_SPACE_BEGINNING_END,
                        message: (
                          <ErrorMsg
                            msg={
                              'Whitespace is not allowed at beginning or end'
                            }
                          />
                        ),
                      },
                      validate: {
                        isNumber: (val) =>
                          !isNaN(val) || <ErrorMsg msg={'Must be a Number'} />,
                      },
                    })}
                    helperText={
                      <ErrorMessage errors={errors} name={`goldOtherCharges`} />
                    }
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </>
    );
  };
  const silverFields = () => {
    return (
      <>
        <Box sx={{ border: 1, p: 2, my: 1 }}>
          <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={1}>
            <Box
              gridColumn={{
                xs: 'span 12',
                sm: 'span 12',
                md: 'span 12',
                lg: 'span 12',
              }}
            >
              <Box className="addinfo-text-box-1">
                <Typography variant="h2" className="addinfo-text-1">
                  Silver
                </Typography>
              </Box>
            </Box>
            <Box
              gridColumn={{
                xs: 'span 12',
                sm: 'span 6',
                md: 'span 6',
                lg: 'span 6',
              }}
            >
              <Box className="refrence-product-input addproduct-1-input-box">
                <Box className="input-box addproduct-input-box">
                  <FormHelperText className="input-lable">
                    Purity(%)
                  </FormHelperText>
                  <TextField
                    autoComplete="off"
                    sx={{
                      // mt: 2,
                      '& .MuiFormHelperText-root': { ml: 0, mt: '5px' },
                    }}
                    fullWidth
                    size="small"
                    className="form-control border-none"
                    name="silverPurity"
                    type="text"
                    {...register(`silverPurity`, {
                      required: <ErrorMsg msg="Please enter Purity(%)" />,
                      minLength: {
                        value: minPurityLength,
                        message: (
                          <ErrorMsg
                            msg={`Minimum ${minPurityLength} Characters Allowed`}
                          />
                        ),
                      },
                      maxLength: {
                        value: maxPurityLength,
                        message: (
                          <ErrorMsg
                            msg={`Maximum ${maxPurityLength} Characters Allowed`}
                          />
                        ),
                      },
                      pattern: {
                        value: NO_WHITE_SPACE_BEGINNING_END,
                        message: (
                          <ErrorMsg
                            msg={
                              'Whitespace is not allowed at beginning or end'
                            }
                          />
                        ),
                      },
                      validate: {
                        isNumber: (val) =>
                          !isNaN(val) || <ErrorMsg msg={'Must be a Number'} />,
                      },
                    })}
                    helperText={
                      <ErrorMessage errors={errors} name={`silverPurity`} />
                    }
                  />
                </Box>
              </Box>
            </Box>
            <Box
              gridColumn={{
                xs: 'span 12',
                sm: 'span 6',
                md: 'span 6',
                lg: 'span 6',
              }}
            >
              <Box className="refrence-product-input">
                <Box className="input-box addproduct-1-input-box">
                  <FormHelperText className="input-lable">
                    Weight(gm)
                  </FormHelperText>
                  <TextField
                    autoComplete="off"
                    sx={{
                      // mt: 2,
                      '& .MuiFormHelperText-root': { ml: 0, mt: '5px' },
                    }}
                    fullWidth
                    size="small"
                    className="form-control border-none"
                    name="silverWeight"
                    type="text"
                    {...register(`silverWeight`, {
                      required: <ErrorMsg msg="Please enter Weight(gm)" />,
                      minLength: {
                        value: minWeightLength,
                        message: (
                          <ErrorMsg
                            msg={`Minimum ${minWeightLength} Characters Allowed`}
                          />
                        ),
                      },
                      maxLength: {
                        value: maxWeightLength,
                        message: (
                          <ErrorMsg
                            msg={`Maximum ${maxWeightLength} Characters Allowed`}
                          />
                        ),
                      },
                      pattern: {
                        value: NO_WHITE_SPACE_BEGINNING_END,
                        message: (
                          <ErrorMsg
                            msg={
                              'Whitespace is not allowed at beginning or end'
                            }
                          />
                        ),
                      },
                      validate: {
                        isNumber: (val) =>
                          !isNaN(val) || <ErrorMsg msg={'Must be a Number'} />,
                      },
                    })}
                    helperText={
                      <ErrorMessage errors={errors} name={`silverWeight`} />
                    }
                  />
                </Box>
              </Box>
            </Box>
            <Box
              gridColumn={{
                xs: 'span 12',
                sm: 'span 6',
                md: 'span 6',
                lg: 'span 6',
              }}
            >
              <Box className="refrence-product-input">
                <Box className="input-box addproduct-1-input-box">
                  <FormHelperText className="input-lable">
                    Making Charges(%)
                  </FormHelperText>
                  <TextField
                    autoComplete="off"
                    sx={{
                      // mt: 2,
                      '& .MuiFormHelperText-root': { ml: 0, mt: '5px' },
                    }}
                    fullWidth
                    size="small"
                    className="form-control border-none"
                    name="silverMakingCharges"
                    type="text"
                    {...register(`silverMakingCharges`, {
                      required: (
                        <ErrorMsg msg="Please enter Making Charges(%)" />
                      ),
                      minLength: {
                        value: minMakingChargesLength,
                        message: (
                          <ErrorMsg
                            msg={`Minimum ${minMakingChargesLength} Characters Allowed`}
                          />
                        ),
                      },
                      maxLength: {
                        value: makingChargesLength,
                        message: (
                          <ErrorMsg
                            msg={`Maximum ${makingChargesLength} Characters Allowed`}
                          />
                        ),
                      },
                      pattern: {
                        value: NO_WHITE_SPACE_BEGINNING_END,
                        message: (
                          <ErrorMsg
                            msg={
                              'Whitespace is not allowed at beginning or end'
                            }
                          />
                        ),
                      },
                      validate: {
                        isNumber: (val) =>
                          !isNaN(val) || <ErrorMsg msg={'Must be a Number'} />,
                      },
                    })}
                    helperText={
                      <ErrorMessage
                        errors={errors}
                        name={`silverMakingCharges`}
                      />
                    }
                  />
                </Box>
              </Box>
            </Box>
            <Box
              gridColumn={{
                xs: 'span 12',
                sm: 'span 6',
                md: 'span 6',
                lg: 'span 6',
              }}
            >
              <Box className="refrence-product-input">
                <Box className="input-box addproduct-1-input-box">
                  <FormHelperText className="input-lable">
                    Other Charges
                  </FormHelperText>
                  <TextField
                    autoComplete="off"
                    sx={{
                      // mt: 2,
                      '& .MuiFormHelperText-root': { ml: 0, mt: '5px' },
                    }}
                    fullWidth
                    size="small"
                    className="form-control border-none"
                    name="silverOtherCharges"
                    type="text"
                    {...register(`silverOtherCharges`, {
                      required: <ErrorMsg msg="Please enter Other Charges" />,
                      minLength: {
                        value: minOtherChargesLength,
                        message: (
                          <ErrorMsg
                            msg={`Minimum ${minOtherChargesLength} Characters Allowed`}
                          />
                        ),
                      },
                      maxLength: {
                        value: maxOtherChargesLength,
                        message: (
                          <ErrorMsg
                            msg={`Maximum ${maxOtherChargesLength} Characters Allowed`}
                          />
                        ),
                      },
                      pattern: {
                        value: NO_WHITE_SPACE_BEGINNING_END,
                        message: (
                          <ErrorMsg
                            msg={
                              'Whitespace is not allowed at beginning or end'
                            }
                          />
                        ),
                      },

                      validate: {
                        isNumber: (val) =>
                          !isNaN(val) || <ErrorMsg msg={'Must be a Number'} />,
                      },
                    })}
                    helperText={
                      <ErrorMessage
                        errors={errors}
                        name={`silverOtherCharges`}
                      />
                    }
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </>
    );
  };
  const gemstoneFields = () => {
    return (
      <>
        {dynamicGemstoneInputFields.map((input, index) => (
          <Box sx={{ border: 1, p: 2, my: 1 }}>
            <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={1}>
              <Box
                gridColumn={{
                  xs: 'span 12',
                  sm: 'span 12',
                  md: 'span 12',
                  lg: 'span 12',
                }}
              >
                <Box className="addinfo-text-box-1">
                  <Typography variant="h2" className="addinfo-text-1">
                    Gemstone
                    {watch('gemstoneInput').length >= 2
                      ? ` - ${index + 1}`
                      : ''}
                  </Typography>
                </Box>
              </Box>
              <Box
                gridColumn={{
                  xs: 'span 12',
                  sm: 'span 6',
                  md: 'span 6',
                  lg: 'span 6',
                }}
              >
                <Box className="refrence-product-input addproduct-1-input-box">
                  <Box className="input-box addproduct-input-box">
                    <FormHelperText className="input-lable">
                      Piece
                    </FormHelperText>
                    <TextField
                      autoComplete="off"
                      sx={{
                        // mt: 2,
                        '& .MuiFormHelperText-root': { ml: 0, mt: '5px' },
                      }}
                      fullWidth
                      size="small"
                      className="form-control border-none"
                      name="piece"
                      type="text"
                      {...register(`gemstoneInput.${index}.piece`, {
                        required: <ErrorMsg msg="Please enter Piece" />,
                        minLength: {
                          value: minPieceLength,
                          message: (
                            <ErrorMsg
                              msg={`Minimum ${minPieceLength} Characters Allowed`}
                            />
                          ),
                        },
                        maxLength: {
                          value: maxPieceLength,
                          message: (
                            <ErrorMsg
                              msg={`Maximum ${maxPieceLength} Characters Allowed`}
                            />
                          ),
                        },
                        pattern: {
                          value: NO_WHITE_SPACE_BEGINNING_END,
                          message: (
                            <ErrorMsg
                              msg={
                                'Whitespace is not allowed at beginning or end'
                              }
                            />
                          ),
                        },
                        validate: {
                          isNumber: (val) =>
                            !isNaN(val) || (
                              <ErrorMsg msg={'Must be a Number'} />
                            ),
                          noDecimal: (val) =>
                            NO_DECIMAL.test(val) || (
                              <ErrorMsg msg={'Must be Non Decimal number'} />
                            ),
                        },
                      })}
                      helperText={
                        <ErrorMessage
                          errors={errors}
                          name={`gemstoneInput.${index}.piece`}
                        />
                      }
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                gridColumn={{
                  xs: 'span 12',
                  sm: 'span 6',
                  md: 'span 6',
                  lg: 'span 6',
                }}
              >
                <Box className="refrence-product-input">
                  <Box className="input-box addproduct-1-input-box">
                    <FormHelperText className="input-lable">
                      Quantity
                    </FormHelperText>
                    <TextField
                      autoComplete="off"
                      sx={{
                        // mt: 2,
                        '& .MuiFormHelperText-root': { ml: 0, mt: '5px' },
                      }}
                      fullWidth
                      size="small"
                      className="form-control border-none"
                      name="quantity"
                      type="text"
                      {...register(`gemstoneInput.${index}.quantity`, {
                        required: <ErrorMsg msg="Please enter Quantity" />,
                        minLength: {
                          value: minQuantityLength,
                          message: (
                            <ErrorMsg
                              msg={`Minimum ${minQuantityLength} Characters Allowed`}
                            />
                          ),
                        },
                        maxLength: {
                          value: maxQuantityLength,
                          message: (
                            <ErrorMsg
                              msg={`Maximum ${maxQuantityLength} Characters Allowed`}
                            />
                          ),
                        },
                        pattern: {
                          value: NO_WHITE_SPACE_BEGINNING_END,
                          message: (
                            <ErrorMsg
                              msg={
                                'Whitespace is not allowed at beginning or end'
                              }
                            />
                          ),
                        },
                        validate: {
                          isNumber: (val) =>
                            !isNaN(val) || (
                              <ErrorMsg msg={'Must be a Number'} />
                            ),
                          noDecimal: (val) =>
                            NO_DECIMAL.test(val) || (
                              <ErrorMsg msg={'Must be Non Decimal number'} />
                            ),
                        },
                      })}
                      helperText={
                        <ErrorMessage
                          errors={errors}
                          name={`gemstoneInput.${index}.quantity`}
                        />
                      }
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                gridColumn={{
                  xs: 'span 12',
                  sm: 'span 6',
                  md: 'span 6',
                  lg: 'span 6',
                }}
              >
                <Box className="refrence-product-input">
                  <Box className="input-box addproduct-1-input-box">
                    <FormHelperText className="input-lable">
                      Purity(Carat)
                    </FormHelperText>
                    <TextField
                      autoComplete="off"
                      sx={{
                        // mt: 2,
                        '& .MuiFormHelperText-root': { ml: 0, mt: '5px' },
                      }}
                      fullWidth
                      size="small"
                      className="form-control border-none"
                      name="purity"
                      type="text"
                      {...register(`gemstoneInput.${index}.purity`, {
                        required: <ErrorMsg msg="Please enter Purity(Carat)" />,
                        minLength: {
                          value: minPurityLength,
                          message: (
                            <ErrorMsg
                              msg={`Minimum ${minPurityLength} Characters Allowed`}
                            />
                          ),
                        },
                        maxLength: {
                          value: maxPurityLength,
                          message: (
                            <ErrorMsg
                              msg={`Maximum ${maxPurityLength} Characters Allowed`}
                            />
                          ),
                        },
                        pattern: {
                          value: NO_WHITE_SPACE_BEGINNING_END,
                          message: (
                            <ErrorMsg
                              msg={
                                'Whitespace is not allowed at beginning or end'
                              }
                            />
                          ),
                        },
                        validate: {
                          isNumber: (val) =>
                            !isNaN(val) || (
                              <ErrorMsg msg={'Must be a Number'} />
                            ),
                        },
                      })}
                      helperText={
                        <ErrorMessage
                          errors={errors}
                          name={`gemstoneInput.${index}.purity`}
                        />
                      }
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                gridColumn={{
                  xs: 'span 12',
                  sm: 'span 6',
                  md: 'span 6',
                  lg: 'span 6',
                }}
              >
                <Box className="refrence-product-input">
                  <Box className="input-box addproduct-1-input-box">
                    <FormHelperText className="input-lable">
                      Weight(gm)
                    </FormHelperText>
                    <TextField
                      autoComplete="off"
                      sx={{
                        // mt: 2,
                        '& .MuiFormHelperText-root': { ml: 0, mt: '5px' },
                      }}
                      fullWidth
                      size="small"
                      className="form-control border-none"
                      name="total_weight"
                      type="text"
                      {...register(`gemstoneInput.${index}.total_weight`, {
                        required: <ErrorMsg msg="Please enter Weight(gm)" />,
                        minLength: {
                          value: minWeightLength,
                          message: (
                            <ErrorMsg
                              msg={`Minimum ${minWeightLength} Characters Allowed`}
                            />
                          ),
                        },
                        maxLength: {
                          value: maxWeightLength,
                          message: (
                            <ErrorMsg
                              msg={`Maximum ${maxWeightLength} Characters Allowed`}
                            />
                          ),
                        },
                        pattern: {
                          value: NO_WHITE_SPACE_BEGINNING_END,
                          message: (
                            <ErrorMsg
                              msg={
                                'Whitespace is not allowed at beginning or end'
                              }
                            />
                          ),
                        },
                        validate: {
                          isNumber: (val) =>
                            !isNaN(val) || (
                              <ErrorMsg msg={'Must be a Number'} />
                            ),
                        },
                      })}
                      helperText={
                        <ErrorMessage
                          errors={errors}
                          name={`gemstoneInput.${index}.total_weight`}
                        />
                      }
                    />
                  </Box>
                </Box>
              </Box>
              <>
                <Box
                  gridColumn={{
                    xs: 'span 12',
                    sm: 'span 6',
                    md: 'span 6',
                    lg: 'span 6',
                  }}
                >
                  <Box className="refrence-product-input">
                    <Box className="input-box addproduct-1-input-box">
                      <FormHelperText className="input-lable">
                        Price per quantity(Rs)
                      </FormHelperText>
                      <TextField
                        autoComplete="off"
                        sx={{
                          // mt: 2,
                          '& .MuiFormHelperText-root': { ml: 0, mt: '5px' },
                        }}
                        fullWidth
                        size="small"
                        className="form-control border-none"
                        name="  "
                        type="text"
                        {...register(
                          `gemstoneInput.${index}.price_per_quantity`,
                          {
                            required: (
                              <ErrorMsg msg="Please enter Price per quantity(Rs)" />
                            ),
                            minLength: {
                              value: minPricePerQuantityLength,
                              message: (
                                <ErrorMsg
                                  msg={`Minimum ${minPricePerQuantityLength} Characters Allowed`}
                                />
                              ),
                            },
                            maxLength: {
                              value: maxPricePerQuantityLength,
                              message: (
                                <ErrorMsg
                                  msg={`Maximum ${maxPricePerQuantityLength} Characters Allowed`}
                                />
                              ),
                            },
                            pattern: {
                              value: NO_WHITE_SPACE_BEGINNING_END,
                              message: (
                                <ErrorMsg
                                  msg={
                                    'Whitespace is not allowed at beginning or end'
                                  }
                                />
                              ),
                            },
                            validate: {
                              isNumber: (val) =>
                                !isNaN(val) || (
                                  <ErrorMsg msg={'Must be a Number'} />
                                ),
                            },
                          }
                        )}
                        helperText={
                          <ErrorMessage
                            errors={errors}
                            name={`gemstoneInput.${index}.price_per_quantity`}
                          />
                        }
                      />
                    </Box>
                  </Box>
                </Box>
                <Box
                  gridColumn={{
                    xs: 'span 12',
                    sm: 'span 6',
                    md: 'span 6',
                    lg: 'span 6',
                  }}
                >
                  <Box className="addproduct-textarea-main input-box addproduct-1-textarea-main">
                    <FormHelperText className="input-lable">
                      Suppliers Making Charges(Rs)
                    </FormHelperText>
                    <TextField
                      autoComplete="off"
                      sx={{
                        // mt: 2,
                        '& .MuiFormHelperText-root': { ml: 0, mt: '5px' },
                      }}
                      fullWidth
                      size="small"
                      className="form-control border-none"
                      name="making_charge"
                      type="text"
                      {...register(`gemstoneInput.${index}.making_charge`, {
                        required: (
                          <ErrorMsg msg="Please enter Suppliers Making Charges(Rs)" />
                        ),
                        minLength: {
                          value: minSuppliersMakingChargesLength,
                          message: (
                            <ErrorMsg
                              msg={`Minimum ${minSuppliersMakingChargesLength} Characters Allowed`}
                            />
                          ),
                        },
                        maxLength: {
                          value: maxSuppliersMakingChargesLength,
                          message: (
                            <ErrorMsg
                              msg={`Maximum ${maxSuppliersMakingChargesLength} Characters Allowed`}
                            />
                          ),
                        },
                        pattern: {
                          value: NO_WHITE_SPACE_BEGINNING_END,
                          message: (
                            <ErrorMsg
                              msg={
                                'Whitespace is not allowed at beginning or end'
                              }
                            />
                          ),
                        },
                        validate: {
                          isNumber: (val) =>
                            !isNaN(val) || (
                              <ErrorMsg msg={'Must be a Number'} />
                            ),
                        },
                      })}
                      helperText={
                        <ErrorMessage
                          errors={errors}
                          name={`gemstoneInput.${index}.making_charge`}
                        />
                      }
                    />
                  </Box>
                </Box>
              </>
              <Box
                gridColumn={{
                  xs: 'span 12',
                  sm: 'span 12',
                  md: 'span 12',
                  lg: 'span 12',
                }}
                sx={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                <ContainedButton
                  display={watch('gemstoneInput').length <= 1 && 'none'}
                  onClick={() => dynamicGemstoneInputRemove(input)}
                  name="Remove"
                />
              </Box>
            </Box>
          </Box>
        ))}
        <Button
          variant="contained"
          onClick={() => {
            dynamicGemstoneInputAppend({
              piece: '',
              quantity: '',
              purity: '',
              total_weight: '',
              price_per_quantity: '',
              price_of_total_quantity: 0,
              final_price: 0,
              making_charge: '',
            });
          }}
          sx={{
            my: 2,
            textTransform: 'none',
          }}
        >
          Add more Gemstone
        </Button>
      </>
    );
  };

  const diamondFields = () => {
    return (
      <>
        {dynamicDiamondInputFields.map((input, index) => (
          <Box sx={{ border: 1, p: 2, my: 1 }}>
            <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={1}>
              <Box
                gridColumn={{
                  xs: 'span 12',
                  sm: 'span 12',
                  md: 'span 12',
                  lg: 'span 12',
                }}
              ></Box>
              <Box
                gridColumn={{
                  xs: 'span 12',
                  sm: 'span 12',
                  md: 'span 12',
                  lg: 'span 12',
                }}
              >
                <Box className="addinfo-text-box-1">
                  <Typography variant="h2" className="addinfo-text-1">
                    Diamond
                    {watch('diamondInput').length >= 2 ? ` - ${index + 1}` : ''}
                  </Typography>
                </Box>
              </Box>
              <Box
                gridColumn={{
                  xs: 'span 12',
                  sm: 'span 6',
                  md: 'span 6',
                  lg: 'span 6',
                }}
              >
                <Box className="refrence-product-input addproduct-1-input-box">
                  <Box className="input-box addproduct-input-box">
                    <FormHelperText className="input-lable">
                      Piece
                    </FormHelperText>
                    <TextField
                      autoComplete="off"
                      sx={{
                        // mt: 2,
                        '& .MuiFormHelperText-root': { ml: 0, mt: '5px' },
                      }}
                      fullWidth
                      size="small"
                      className="form-control border-none"
                      name="piece"
                      type="text"
                      {...register(`diamondInput.${index}.piece`, {
                        required: <ErrorMsg msg="Please enter Piece" />,
                        minLength: {
                          value: minPieceLength,
                          message: (
                            <ErrorMsg
                              msg={`Minimum ${minPieceLength} Characters Allowed`}
                            />
                          ),
                        },
                        maxLength: {
                          value: maxPieceLength,
                          message: (
                            <ErrorMsg
                              msg={`Maximum ${maxPieceLength} Characters Allowed`}
                            />
                          ),
                        },
                        pattern: {
                          value: NO_WHITE_SPACE_BEGINNING_END,
                          message: (
                            <ErrorMsg
                              msg={
                                'Whitespace is not allowed at beginning or end'
                              }
                            />
                          ),
                        },
                        validate: {
                          isNumber: (val) =>
                            !isNaN(val) || (
                              <ErrorMsg msg={'Must be a Number'} />
                            ),
                          noDecimal: (val) =>
                            NO_DECIMAL.test(val) || (
                              <ErrorMsg msg={'Must be Non Decimal number'} />
                            ),
                        },
                      })}
                      helperText={
                        <ErrorMessage
                          errors={errors}
                          name={`diamondInput.${index}.piece`}
                        />
                      }
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                gridColumn={{
                  xs: 'span 12',
                  sm: 'span 6',
                  md: 'span 6',
                  lg: 'span 6',
                }}
              >
                <Box className="refrence-product-input">
                  <Box className="input-box addproduct-1-input-box">
                    <FormHelperText className="input-lable">
                      Quantity
                    </FormHelperText>
                    <TextField
                      autoComplete="off"
                      sx={{
                        // mt: 2,
                        '& .MuiFormHelperText-root': { ml: 0, mt: '5px' },
                      }}
                      fullWidth
                      size="small"
                      className="form-control border-none"
                      name="quantity"
                      type="text"
                      {...register(`diamondInput.${index}.quantity`, {
                        required: <ErrorMsg msg="Please enter Quantity" />,
                        minLength: {
                          value: minQuantityLength,
                          message: (
                            <ErrorMsg
                              msg={`Minimum ${minQuantityLength} Characters Allowed`}
                            />
                          ),
                        },
                        maxLength: {
                          value: maxQuantityLength,
                          message: (
                            <ErrorMsg
                              msg={`Maximum ${maxQuantityLength} Characters Allowed`}
                            />
                          ),
                        },
                        pattern: {
                          value: NO_WHITE_SPACE_BEGINNING_END,
                          message: (
                            <ErrorMsg
                              msg={
                                'Whitespace is not allowed at beginning or end'
                              }
                            />
                          ),
                        },
                        validate: {
                          isNumber: (val) =>
                            !isNaN(val) || (
                              <ErrorMsg msg={'Must be a Number'} />
                            ),
                          noDecimal: (val) =>
                            NO_DECIMAL.test(val) || (
                              <ErrorMsg msg={'Must be Non Decimal number'} />
                            ),
                        },
                      })}
                      helperText={
                        <ErrorMessage
                          errors={errors}
                          name={`diamondInput.${index}.quantity`}
                        />
                      }
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                gridColumn={{
                  xs: 'span 12',
                  sm: 'span 6',
                  md: 'span 6',
                  lg: 'span 6',
                }}
              >
                <Box className="refrence-product-input">
                  <Box className="input-box addproduct-1-input-box">
                    <FormHelperText className="input-lable">
                      Purity(Carat)
                    </FormHelperText>
                    <TextField
                      autoComplete="off"
                      sx={{
                        // mt: 2,
                        '& .MuiFormHelperText-root': { ml: 0, mt: '5px' },
                      }}
                      fullWidth
                      size="small"
                      className="form-control border-none"
                      name="purity"
                      type="text"
                      {...register(`diamondInput.${index}.purity`, {
                        required: <ErrorMsg msg="Please enter Purity(Carat)" />,
                        minLength: {
                          value: minPurityLength,
                          message: (
                            <ErrorMsg
                              msg={`Minimum ${minPurityLength} Characters Allowed`}
                            />
                          ),
                        },
                        maxLength: {
                          value: maxPurityLength,
                          message: (
                            <ErrorMsg
                              msg={`Maximum ${maxPurityLength} Characters Allowed`}
                            />
                          ),
                        },
                        pattern: {
                          value: NO_WHITE_SPACE_BEGINNING_END,
                          message: <ErrorMsg msg={'"?" mark is required'} />,
                        },
                        validate: {
                          isNumber: (val) =>
                            !isNaN(val) || (
                              <ErrorMsg msg={'Must be a Number'} />
                            ),
                        },
                      })}
                      helperText={
                        <ErrorMessage
                          errors={errors}
                          name={`diamondInput.${index}.purity`}
                        />
                      }
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                gridColumn={{
                  xs: 'span 12',
                  sm: 'span 6',
                  md: 'span 6',
                  lg: 'span 6',
                }}
              >
                <Box className="refrence-product-input">
                  <Box className="input-box addproduct-1-input-box">
                    <FormHelperText className="input-lable">
                      Weight(gm)
                    </FormHelperText>
                    <TextField
                      autoComplete="off"
                      sx={{
                        // mt: 2,
                        '& .MuiFormHelperText-root': { ml: 0, mt: '5px' },
                      }}
                      fullWidth
                      size="small"
                      className="form-control border-none"
                      name="total_weight"
                      type="text"
                      {...register(`diamondInput.${index}.total_weight`, {
                        required: <ErrorMsg msg="Please enter  Weight(gm)" />,
                        minLength: {
                          value: minWeightLength,
                          message: (
                            <ErrorMsg
                              msg={`Minimum ${minWeightLength} Characters Allowed`}
                            />
                          ),
                        },
                        maxLength: {
                          value: maxWeightLength,
                          message: (
                            <ErrorMsg
                              msg={`Maximum ${maxWeightLength} Characters Allowed`}
                            />
                          ),
                        },
                        pattern: {
                          value: NO_WHITE_SPACE_BEGINNING_END,
                          message: (
                            <ErrorMsg
                              msg={
                                'Whitespace is not allowed at beginning or end'
                              }
                            />
                          ),
                        },
                        validate: {
                          isNumber: (val) =>
                            !isNaN(val) || (
                              <ErrorMsg msg={'Must be a Number'} />
                            ),
                        },
                      })}
                      helperText={
                        <ErrorMessage
                          errors={errors}
                          name={`diamondInput.${index}.total_weight`}
                        />
                      }
                    />
                  </Box>
                </Box>
              </Box>
              <>
                <Box
                  gridColumn={{
                    xs: 'span 12',
                    sm: 'span 6',
                    md: 'span 6',
                    lg: 'span 6',
                  }}
                >
                  <Box className="refrence-product-input">
                    <Box className="input-box addproduct-1-input-box">
                      <FormHelperText className="input-lable">
                        Price per quantity(Rs)
                      </FormHelperText>
                      <TextField
                        autoComplete="off"
                        sx={{
                          // mt: 2,
                          '& .MuiFormHelperText-root': { ml: 0, mt: '5px' },
                        }}
                        fullWidth
                        size="small"
                        className="form-control border-none"
                        name="price_per_quantity"
                        type="text"
                        {...register(
                          `diamondInput.${index}.price_per_quantity`,
                          {
                            required: (
                              <ErrorMsg msg="Please enter Price per quantity(Rs)" />
                            ),
                            minLength: {
                              value: minPricePerQuantityLength,
                              message: (
                                <ErrorMsg
                                  msg={`Minimum ${minPricePerQuantityLength} Characters Allowed`}
                                />
                              ),
                            },
                            maxLength: {
                              value: maxPricePerQuantityLength,
                              message: (
                                <ErrorMsg
                                  msg={`Maximum ${maxPricePerQuantityLength} Characters Allowed`}
                                />
                              ),
                            },
                            pattern: {
                              value: NO_WHITE_SPACE_BEGINNING_END,
                              message: (
                                <ErrorMsg msg={'Price per quantity(Rs)'} />
                              ),
                            },
                            validate: {
                              isNumber: (val) =>
                                !isNaN(val) || (
                                  <ErrorMsg msg={'Must be a Number'} />
                                ),
                            },
                          }
                        )}
                        helperText={
                          <ErrorMessage
                            errors={errors}
                            name={`diamondInput.${index}.price_per_quantity`}
                          />
                        }
                      />
                    </Box>
                  </Box>
                </Box>
                <Box
                  gridColumn={{
                    xs: 'span 12',
                    sm: 'span 6',
                    md: 'span 6',
                    lg: 'span 6',
                  }}
                >
                  <Box className="addproduct-textarea-main input-box addproduct-1-textarea-main">
                    <FormHelperText className="input-lable">
                      Suppliers Making Charges(Rs)
                    </FormHelperText>
                    <TextField
                      autoComplete="off"
                      sx={{
                        // mt: 2,
                        '& .MuiFormHelperText-root': { ml: 0, mt: '5px' },
                      }}
                      fullWidth
                      size="small"
                      className="form-control border-none"
                      name="making_charge"
                      type="text"
                      {...register(`diamondInput.${index}.making_charge`, {
                        required: (
                          <ErrorMsg msg="Please enter Suppliers Making Charges(Rs)" />
                        ),
                        minLength: {
                          value: minSuppliersMakingChargesLength,
                          message: (
                            <ErrorMsg
                              msg={`Minimum ${minSuppliersMakingChargesLength} Characters Allowed`}
                            />
                          ),
                        },
                        maxLength: {
                          value: maxSuppliersMakingChargesLength,
                          message: (
                            <ErrorMsg
                              msg={`Maximum ${maxSuppliersMakingChargesLength} Characters Allowed`}
                            />
                          ),
                        },
                        pattern: {
                          value: NO_WHITE_SPACE_BEGINNING_END,
                          message: (
                            <ErrorMsg
                              msg={
                                'Whitespace is not allowed at beginning or end'
                              }
                            />
                          ),
                        },
                        validate: {
                          isNumber: (val) =>
                            !isNaN(val) || (
                              <ErrorMsg msg={'Must be a Number'} />
                            ),
                        },
                      })}
                      helperText={
                        <ErrorMessage
                          errors={errors}
                          name={`diamondInput.${index}.making_charge`}
                        />
                      }
                    />
                  </Box>
                </Box>
              </>
              <Box
                gridColumn={{
                  xs: 'span 12',
                  sm: 'span 12',
                  md: 'span 12',
                  lg: 'span 12',
                }}
                sx={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                <ContainedButton
                  display={watch('diamondInput').length <= 1 && 'none'}
                  onClick={() => dynamicDiamondInputRemove(input)}
                  name="Remove"
                />
              </Box>
            </Box>
          </Box>
        ))}
        <Button
          variant="contained"
          onClick={() => {
            dynamicDiamondInputAppend({
              piece: '',
              quantity: '',
              purity: '',
              total_weight: '',
              price_per_quantity: '',
              price_of_total_quantity: 0,
              final_price: 0,
              making_charge: '',
            });
          }}
          sx={{
            my: 2,
            textTransform: 'none',
          }}
        >
          Add More Diamond
        </Button>
      </>
    );
  };

  const renderFieldMaterialWise = () => {
    switch (true) {
      case watch('material').includes('Gold') && watch('material').length === 1:
        return goldFields();
      case watch('material').includes('Silver') &&
        watch('material').length === 1:
        return silverFields();
      case watch('material').includes('Diamond') &&
        watch('material').length === 1:
        return diamondFields();
      case watch('material').includes('Gemstone') &&
        watch('material').length === 1:
        return gemstoneFields();
      case watch('material').includes('Gold') &&
        watch('material').includes('Diamond') &&
        watch('material').length === 2:
        return (
          <>
            {goldFields()}
            {diamondFields()}
          </>
        );
      case watch('material').includes('Gold') &&
        watch('material').includes('Gemstone') &&
        watch('material').length === 2:
        return (
          <>
            {goldFields()}
            {gemstoneFields()}
          </>
        );
      case watch('material').includes('Silver') &&
        watch('material').includes('Diamond') &&
        watch('material').length === 2:
        return (
          <>
            {silverFields()}
            {diamondFields()}
          </>
        );
      case watch('material').includes('Silver') &&
        watch('material').includes('Gemstone') &&
        watch('material').length === 2:
        return (
          <>
            {silverFields()}
            {gemstoneFields()}
          </>
        );
      case watch('material').includes('Silver') &&
        watch('material').includes('Gold') &&
        watch('material').length === 2:
        return (
          <>
            {silverFields()}
            {goldFields()}
          </>
        );
      case watch('material').includes('Gemstone') &&
        watch('material').includes('Diamond') &&
        watch('material').length === 2:
        return (
          <>
            {gemstoneFields()}
            {diamondFields()}
          </>
        );
      case watch('material').includes('Gold') &&
        watch('material').includes('Silver') &&
        watch('material').includes('Diamond') &&
        watch('material').length === 3:
        return (
          <>
            {silverFields()}
            {goldFields()}
            {diamondFields()}
          </>
        );
      case watch('material').includes('Gold') &&
        watch('material').includes('Silver') &&
        watch('material').includes('Gemstone') &&
        watch('material').length === 3:
        return (
          <>
            {silverFields()}
            {goldFields()}
            {gemstoneFields()}
          </>
        );
      case watch('material').includes('Gold') &&
        watch('material').includes('Diamond') &&
        watch('material').includes('Gemstone') &&
        watch('material').length === 3:
        return (
          <>
            {goldFields()}
            {diamondFields()}
            {gemstoneFields()}
          </>
        );
      case watch('material').includes('Silver') &&
        watch('material').includes('Diamond') &&
        watch('material').includes('Gemstone') &&
        watch('material').length === 3:
        return (
          <>
            {silverFields()}
            {gemstoneFields()}
            {diamondFields()}
          </>
        );
      case watch('material').includes('Gold') &&
        watch('material').includes('Silver') &&
        watch('material').includes('Gemstone') &&
        watch('material').includes('Diamond') &&
        watch('material').length === 4:
        return (
          <>
            {goldFields()}
            {silverFields()}
            {gemstoneFields()}
            {diamondFields()}
          </>
        );

      default:
        return <h1>No Form found</h1>;
    }
  };

  const submit = async (data) => {
    // e.preventDefault();
    let formdata = new FormData();
    try {
      formdata.append(
        'no_of_pieces_available_inStock',
        data.noOfPiecesAvailableInStock?.trim()
      );
      formdata.append('item_id', data.item);
      formdata.append('materialIdArray', JSON.stringify(materialId));
      formdata.append('category_id', data.category?.trim());
      formdata.append('specify', data.specify?.trim());
      formdata.append('additional_details', data.additionalDetails?.trim());
      for (let i = 0; i < image.length; i++) {
        formdata.append('images', image[i]);
      }

      if (
        watch('material').includes('Gold') &&
        watch('material').length === 1
      ) {
        formdata.append('purityGold', data.goldPurity?.trim());
        formdata.append('weightGold', data.goldWeight?.trim());
        formdata.append('making_chargesGold', data.goldMakingCharges?.trim());
        formdata.append('other_chargesGold', data.goldOtherCharges?.trim());
      } else if (
        watch('material').includes('Silver') &&
        watch('material').length === 1
      ) {
        formdata.append('puritySilver', data.silverPurity?.trim());
        formdata.append('weightSilver', data.silverWeight?.trim());
        formdata.append(
          'making_chargesSilver',
          data.silverMakingCharges?.trim()
        );
        formdata.append('other_chargesSilver', data.silverOtherCharges?.trim());
      } else if (
        watch('material').includes('Gemstone') &&
        watch('material').length === 1
      ) {
        formdata.append('gemstoneArray', JSON.stringify(data.gemstoneInput));
      } else if (
        watch('material').includes('Diamond') &&
        watch('material').length === 1
      ) {
        formdata.append('diamondArray', JSON.stringify(data.diamondInput));
      } else if (
        watch('material').includes('Gold') &&
        watch('material').includes('Diamond') &&
        watch('material').length === 2
      ) {
        formdata.append('purityGold', data.goldPurity?.trim());
        formdata.append('weightGold', data.goldWeight?.trim());
        formdata.append('making_chargesGold', data.goldMakingCharges?.trim());
        formdata.append('other_chargesGold', data.goldOtherCharges?.trim());
        formdata.append('diamondArray', JSON.stringify(data.diamondInput));
      } else if (
        watch('material').includes('Gold') &&
        watch('material').includes('Gemstone') &&
        watch('material').length === 2
      ) {
        formdata.append('purityGold', data.goldPurity?.trim());
        formdata.append('weightGold', data.goldWeight?.trim());
        formdata.append('making_chargesGold', data.goldMakingCharges?.trim());
        formdata.append('other_chargesGold', data.goldOtherCharges?.trim());
        formdata.append('gemstoneArray', JSON.stringify(data.gemstoneInput));
      } else if (
        watch('material').includes('Silver') &&
        watch('material').includes('Diamond') &&
        watch('material').length === 2
      ) {
        formdata.append('puritySilver', data.silverPurity?.trim());
        formdata.append('weightSilver', data.silverWeight?.trim());
        formdata.append(
          'making_chargesSilver',
          data.silverMakingCharges?.trim()
        );
        formdata.append('other_chargesSilver', data.silverOtherCharges?.trim());
        formdata.append('diamondArray', JSON.stringify(data.diamondInput));
      } else if (
        watch('material').includes('Silver') &&
        watch('material').includes('Gemstone') &&
        watch('material').length === 2
      ) {
        formdata.append('puritySilver', data.silverPurity?.trim());
        formdata.append('weightSilver', data.silverWeight?.trim());
        formdata.append(
          'making_chargesSilver',
          data.silverMakingCharges?.trim()
        );
        formdata.append('other_chargesSilver', data.silverOtherCharges?.trim());
        formdata.append('gemstoneArray', JSON.stringify(data.gemstoneInput));
      } else if (
        watch('material').includes('Silver') &&
        watch('material').includes('Gold') &&
        watch('material').length === 2
      ) {
        formdata.append('puritySilver', data.silverPurity?.trim());
        formdata.append('weightSilver', data.silverWeight?.trim());
        formdata.append(
          'making_chargesSilver',
          data.silverMakingCharges?.trim()
        );
        formdata.append('other_chargesSilver', data.silverOtherCharges?.trim());
        formdata.append('purityGold', data.goldPurity?.trim());
        formdata.append('weightGold', data.goldWeight?.trim());
        formdata.append('making_chargesGold', data.goldMakingCharges?.trim());
        formdata.append('other_chargesGold', data.goldOtherCharges?.trim());
      } else if (
        watch('material').includes('Diamond') &&
        watch('material').includes('Gemstone') &&
        watch('material').length === 2
      ) {
        formdata.append('diamondArray', JSON.stringify(data.diamondInput));
        formdata.append('gemstoneArray', JSON.stringify(data.gemstoneInput));
      } else if (
        watch('material').includes('Silver') &&
        watch('material').includes('Gold') &&
        watch('material').includes('Diamond') &&
        watch('material').length === 3
      ) {
        formdata.append('puritySilver', data.silverPurity?.trim());
        formdata.append('weightSilver', data.silverWeight?.trim());
        formdata.append(
          'making_chargesSilver',
          data.silverMakingCharges?.trim()
        );
        formdata.append('other_chargesSilver', data.silverOtherCharges?.trim());
        formdata.append('purityGold', data.goldPurity?.trim());
        formdata.append('weightGold', data.goldWeight?.trim());
        formdata.append('making_chargesGold', data.goldMakingCharges?.trim());
        formdata.append('other_chargesGold', data.goldOtherCharges?.trim());
        formdata.append('diamondArray', JSON.stringify(data.diamondInput));
      } else if (
        watch('material').includes('Silver') &&
        watch('material').includes('Gold') &&
        watch('material').includes('Gemstone') &&
        watch('material').length === 3
      ) {
        formdata.append('puritySilver', data.silverPurity?.trim());
        formdata.append('weightSilver', data.silverWeight?.trim());
        formdata.append(
          'making_chargesSilver',
          data.silverMakingCharges?.trim()
        );
        formdata.append('other_chargesSilver', data.silverOtherCharges?.trim());
        formdata.append('purityGold', data.goldPurity?.trim());
        formdata.append('weightGold', data.goldWeight?.trim());
        formdata.append('making_chargesGold', data.goldMakingCharges?.trim());
        formdata.append('other_chargesGold', data.goldOtherCharges?.trim());
        formdata.append('gemstoneArray', JSON.stringify(data.gemstoneInput));
      } else if (
        watch('material').includes('Diamond') &&
        watch('material').includes('Gold') &&
        watch('material').includes('Gemstone') &&
        watch('material').length === 3
      ) {
        formdata.append('purityGold', data.goldPurity?.trim());
        formdata.append('weightGold', data.goldWeight?.trim());
        formdata.append('making_chargesGold', data.goldMakingCharges?.trim());
        formdata.append('other_chargesGold', data.goldOtherCharges?.trim());
        formdata.append('gemstoneArray', JSON.stringify(data.gemstoneInput));
        formdata.append('diamondArray', JSON.stringify(data.diamondInput));
      } else if (
        watch('material').includes('Diamond') &&
        watch('material').includes('Silver') &&
        watch('material').includes('Gemstone') &&
        watch('material').length === 3
      ) {
        formdata.append('puritySilver', data.silverPurity?.trim());
        formdata.append('weightSilver', data.silverWeight?.trim());
        formdata.append(
          'making_chargesSilver',
          data.silverMakingCharges?.trim()
        );
        formdata.append('other_chargesSilver', data.silverOtherCharges?.trim());
        formdata.append('gemstoneArray', JSON.stringify(data.gemstoneInput));
        formdata.append('diamondArray', JSON.stringify(data.diamondInput));
      } else if (
        watch('material').includes('Silver') &&
        watch('material').includes('Gold') &&
        watch('material').includes('Gemstone') &&
        watch('material').includes('Diamond') &&
        watch('material').length === 4
      ) {
        formdata.append('puritySilver', data.silverPurity?.trim());
        formdata.append('weightSilver', data.silverWeight?.trim());
        formdata.append(
          'making_chargesSilver',
          data.silverMakingCharges?.trim()
        );
        formdata.append('other_chargesSilver', data.silverOtherCharges?.trim());
        formdata.append('purityGold', data.goldPurity?.trim());
        formdata.append('weightGold', data.goldWeight?.trim());
        formdata.append('making_chargesGold', data.goldMakingCharges?.trim());
        formdata.append('other_chargesGold', data.goldOtherCharges?.trim());
        formdata.append('gemstoneArray', JSON.stringify(data.gemstoneInput));
        formdata.append('diamondArray', JSON.stringify(data.diamondInput));
      }

      formdata.append('inner_circumference', data.innerCircumference?.trim());
      formdata.append('length', data.length?.trim());
      formdata.append('depth', data.depth?.trim());
      formdata.append('meta_title', data.metaTitle?.trim());
      formdata.append('meta_description', data.metaDescription?.trim());

      const userResp = await dataService.post(
        Api.Admin.createProduct,
        formdata,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            auth: token,
          },
        }
      );

      if (userResp.data.status === 1) {
        toast.success('Product added successfully');
        history.push('/admin/productmanagement/uploadedproducts');
      }
    } catch (err) {
      toast.error(err?.message || 'Something went wrong1');
    }
  };

  //#endregion

  //#region RETURN JSX
  return (
    <div>
      <form onSubmit={handleSubmit(submit)}>
        {show ? (
          <Box className="addproduct-sec">
            <Box className="container">
              <Box sx={{ width: 1 }}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={{ xs: 2, sm: 5, md: 1, lg: 1 }}
                >
                  <Box
                    gridColumn={{
                      xs: 'span 12',
                      sm: 'span 12',
                      md: 'span 12',
                      lg: 'span 12',
                    }}
                  >
                    <Box className="addinfo-text-box-1">
                      <Typography variant="h2" className="addinfo-text-1">
                        Product Description:
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    gridColumn={{
                      xs: 'span 12',
                      sm: 'span 12',
                      md: 'span 12',
                      lg: 'span 12',
                    }}
                  >
                    <Box className="addproduct-textarea-main input-box ">
                      <FormHelperText className="input-lable">
                        Product Images
                      </FormHelperText>
                      <Box sx={{ width: '100%', position: 'relative' }}>
                        {!image[0] ? (
                          <>
                            <Button
                              component="label"
                              sx={{ borderRadius: 0, textTransform: 'none' }}
                              variant="contained"
                              onChange={handleImg}
                            >
                              Upload
                              <UploadIcon />
                              <input
                                multiple
                                name="files"
                                hidden
                                accept="image/*"
                                type="file"
                                {...register('files', {
                                  required: (
                                    <ErrorMsg msg="Please select Image" />
                                  ),
                                })}
                              />
                            </Button>
                            <ErrorMessage errors={errors} name="files" />
                          </>
                        ) : (
                          <>
                            <Box sx={{ display: 'flex' }}>
                              <Button
                                component="label"
                                sx={{ borderRadius: 0, textTransform: 'none' }}
                                variant="contained"
                                onChange={handleImg}
                              >
                                Upload
                                <UploadIcon />
                                <input
                                  multiple
                                  name="files"
                                  hidden
                                  accept="image/*"
                                  type="file"
                                  {...register('files', {
                                    required: (
                                      <ErrorMsg msg="Please select Image" />
                                    ),
                                  })}
                                />
                              </Button>
                              <ErrorMessage errors={errors} name="files" />
                              <Tooltip title="Remove Images" placement="bottom">
                                <DisabledByDefaultIcon
                                  className="remove-img-icon"
                                  onClick={removeImg}
                                  fontSize="large"
                                  sx={{
                                    color: 'background: black(0.5)',
                                    ml: 1,
                                    cursor: 'pointer',
                                    // position: 'absolute',
                                    // top: '-30px',
                                    // right: '0px',
                                  }}
                                />
                              </Tooltip>
                            </Box>
                          </>
                        )}
                        <Grid container>
                          {image && image[0]
                            ? image?.map((element) => (
                                <Grid item xs={12} lg={3} alignSelf="center">
                                  <Box className="product-img-box">
                                    <a
                                      key={element}
                                      href={window.URL.createObjectURL(element)}
                                      download
                                    >
                                      <Tooltip title="Tap to Download">
                                        <Img
                                          key={element}
                                          sx={{
                                            my: 2,
                                            height: '130px',
                                            width: '200px',
                                            borderRadius: '0px',
                                          }}
                                          src={window.URL.createObjectURL(
                                            element
                                          )}
                                          alt="no image"
                                        />
                                      </Tooltip>
                                    </a>
                                    <Button className="product-top-btn">
                                      <Tooltip
                                        title="Remove this Image"
                                        placement="bottom"
                                      >
                                        <DisabledByDefaultIcon
                                          className="remove-img-icon"
                                          onClick={() =>
                                            removeSpecificImg(element)
                                          }
                                          sx={{
                                            mt: 5,
                                          }}
                                        />
                                      </Tooltip>
                                    </Button>
                                  </Box>
                                </Grid>
                              ))
                            : ''}
                        </Grid>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    gridColumn={{
                      xs: 'span 12',
                      sm: 'span 6',
                      md: 'span 6',
                      lg: 'span 6',
                    }}
                  >
                    <Box className="refrence-product-input addproduct-input-box">
                      <Box className="input-box addproduct-input-box">
                        <FormHelperText className="input-lable">
                          No of pieces available in stock
                        </FormHelperText>
                        <TextField
                          autoComplete="off"
                          sx={{
                            // mt: 2,
                            '& .MuiFormHelperText-root': { ml: 0, mt: '5px' },
                          }}
                          fullWidth
                          size="small"
                          className="form-control border-none"
                          name="noOfPiecesAvailableInStock"
                          type="number"
                          {...register(`noOfPiecesAvailableInStock`, {
                            required: (
                              <ErrorMsg msg="Please enter No of pieces available in stock" />
                            ),
                            minLength: {
                              value: minNoOfPiecesAvailableInStockLength,
                              message: (
                                <ErrorMsg
                                  msg={`Minimum ${minNoOfPiecesAvailableInStockLength} Characters Allowed`}
                                />
                              ),
                            },
                            maxLength: {
                              value: maxNoOfPiecesAvailableInStockLength,
                              message: (
                                <ErrorMsg
                                  msg={`Maximum ${maxNoOfPiecesAvailableInStockLength} Characters Allowed`}
                                />
                              ),
                            },
                            pattern: {
                              value: NO_WHITE_SPACE_BEGINNING_END,
                              message: (
                                <ErrorMsg
                                  msg={
                                    'Whitespace is not allowed at beginning or end'
                                  }
                                />
                              ),
                            },
                            validate: {
                              isNumber: (val) =>
                                !isNaN(val) || (
                                  <ErrorMsg msg={'Must be a Number'} />
                                ),
                            },
                          })}
                          helperText={
                            <ErrorMessage
                              errors={errors}
                              name={`noOfPiecesAvailableInStock`}
                            />
                          }
                        />
                      </Box>
                    </Box>
                  </Box>

                  <Box
                    gridColumn={{
                      xs: 'span 12',
                      sm: 'span 6',
                      md: 'span 6',
                      lg: 'span 6',
                    }}
                  >
                    <Box className="input-box ">
                      <FormHelperText className="input-lable">
                        Item
                      </FormHelperText>
                      <FormControl className="membership-formcontrol">
                        <Controller
                          name="item"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value } }) => (
                            <Select
                              sx={{
                                '& .MuiFormHelperText-root': {
                                  ml: 0,
                                  mt: '5px',
                                },
                              }}
                              defaultValue="1"
                              labelId="item"
                              className="membershop-select"
                              id="item"
                              value={value}
                              onChange={onChange}
                              inputProps={{ 'aria-label': 'Without label' }}
                              {...register('item', {
                                required: <ErrorMsg msg="Please select Item" />,
                              })}
                            >
                              {selectItem?.map((item) => (
                                <MenuItem value={item._id}>
                                  {item.item}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        />
                        <span>
                          <img
                            src={png.blackdown}
                            className="black-down-img1"
                          ></img>
                        </span>
                      </FormControl>
                      <ErrorMessage errors={errors} name="item" />
                    </Box>
                  </Box>
                  <Box
                    gridColumn={{
                      xs: 'span 12',
                      sm: 'span 6',
                      md: 'span 6',
                      lg: 'span 6',
                    }}
                  >
                    <Box className="refrence-product-input addproduct-drop-box">
                      <Box className="input-box">
                        <FormHelperText className="input-lable">
                          Material
                        </FormHelperText>
                        <FormControl className="membership-formcontrol">
                          <Controller
                            name="material"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value } }) => (
                              <Select
                                sx={{
                                  '& .MuiFormHelperText-root': {
                                    ml: 0,
                                    mt: '5px',
                                  },
                                }}
                                multiple
                                className="membershop-select"
                                value={value ? value : []}
                                onChange={onChange}
                                labelId="material"
                                id="material"
                                inputProps={{ 'aria-label': 'Without label' }}
                                {...register('material', {
                                  required: (
                                    <ErrorMsg msg="Please select Material" />
                                  ),
                                })}
                              >
                                {selectMaterial?.map((material) => (
                                  <MenuItem value={material.material}>
                                    {material.material}
                                  </MenuItem>
                                ))}
                              </Select>
                            )}
                          />
                          <span>
                            <img
                              src={png.blackdown}
                              className="black-down-img1"
                            ></img>
                          </span>
                        </FormControl>
                        <ErrorMessage errors={errors} name="material" />
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    gridColumn={{
                      xs: 'span 12',
                      sm: 'span 6',
                      md: 'span 6',
                      lg: 'span 6',
                    }}
                  >
                    {/* <Box className="refrence-product-input addproduct-drop-box"> */}
                    <Box className="input-box">
                      <FormHelperText className="input-lable">
                        Category
                      </FormHelperText>
                      <FormControl className="membership-formcontrol">
                        <Controller
                          name="category"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value } }) => (
                            <Select
                              sx={{
                                '& .MuiFormHelperText-root': {
                                  ml: 0,
                                  mt: '5px',
                                },
                              }}
                              className="membershop-select"
                              value={value}
                              labelId="category"
                              onChange={onChange}
                              id="category"
                              inputProps={{ 'aria-label': 'Without label' }}
                              {...register('category', {
                                required: (
                                  <ErrorMsg msg="Please select Category" />
                                ),
                              })}
                            >
                              {selectCategory?.map((category) => (
                                <MenuItem value={category._id}>
                                  {category.category_name}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        />
                        <span>
                          <img
                            src={png.blackdown}
                            className="black-down-img1"
                          ></img>
                        </span>
                      </FormControl>
                      <ErrorMessage errors={errors} name="category" />
                      {/* </Box> */}
                    </Box>
                  </Box>
                  <Box
                    gridColumn={{
                      xs: 'span 12',
                      sm: 'span 12',
                      md: 'span 12',
                      lg: 'span 12',
                    }}
                  >
                    <Box className="refrence-product-input">
                      <Box className="input-box addproduct-input-box">
                        <FormHelperText className="input-lable">
                          Specify
                        </FormHelperText>
                        <TextField
                          autoComplete="off"
                          sx={{
                            // mt: 2,
                            '& .MuiFormHelperText-root': { ml: 0, mt: '5px' },
                          }}
                          fullWidth
                          size="small"
                          className="form-control border-none"
                          name="specify"
                          type="text"
                          {...register(`specify`, {
                            required: <ErrorMsg msg="Please enter Specify" />,
                            minLength: {
                              value: minSpecifyLength,
                              message: (
                                <ErrorMsg
                                  msg={`Minimum ${minSpecifyLength} Characters Allowed`}
                                />
                              ),
                            },
                            maxLength: {
                              value: maxSpecifyLength,
                              message: (
                                <ErrorMsg
                                  msg={`Maximum ${maxSpecifyLength} Characters Allowed`}
                                />
                              ),
                            },
                            pattern: {
                              value: NO_WHITE_SPACE_BEGINNING_END,
                              message: (
                                <ErrorMsg
                                  msg={
                                    'Whitespace is not allowed at beginning or end'
                                  }
                                />
                              ),
                            },
                          })}
                          helperText={
                            <ErrorMessage errors={errors} name={`specify`} />
                          }
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    gridColumn={{
                      xs: 'span 12',
                      sm: 'span 12',
                      md: 'span 12',
                      lg: 'span 12',
                    }}
                  >
                    <Box className="addproduct-textarea-main input-box ">
                      <FormHelperText className="input-lable">
                        Additional Details
                      </FormHelperText>
                      <TextareaAutosize
                        autoComplete="off"
                        fullWidth
                        size="small"
                        className="addinfo-textarea admin-addproduct-area text-area-control-admin"
                        name="additionalDetails"
                        type="text"
                        {...register(`additionalDetails`, {
                          required: (
                            <TextAreaErrorMsg msg="Please enter Additional Details" />
                          ),
                          minLength: {
                            value: minAdditionalDetailsLength,
                            message: (
                              <TextAreaErrorMsg
                                msg={`Minimum ${minAdditionalDetailsLength} Characters Allowed`}
                              />
                            ),
                          },
                          maxLength: {
                            value: maxAdditionalDetailsLength,
                            message: (
                              <TextAreaErrorMsg
                                msg={`Maximum ${maxAdditionalDetailsLength} Characters Allowed`}
                              />
                            ),
                          },
                          pattern: {
                            value: NO_WHITE_SPACE_BEGINNING_END,
                            message: (
                              <TextAreaErrorMsg
                                msg={
                                  'Whitespace is not allowed at beginning or end'
                                }
                              />
                            ),
                          },
                        })}
                      />
                      <ErrorMessage errors={errors} name="additionalDetails" />
                    </Box>
                  </Box>
                  <Box
                    gridColumn={{
                      xs: 'span 12',
                      sm: 'span 12',
                      md: 'span 12',
                      lg: 'span 12',
                    }}
                  >
                    <Box className="primary-btn-box next-btn-box product-details-btn-box">
                      <Button
                        variant="contained"
                        className=" next-btn-1"
                        onClick={(e) => {
                          nextPage(e);
                        }}
                        type="submit"
                      >
                        Next
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        ) : (
          <Box className="addproduct-1-sec">
            <Box className="container">
              <Box>{renderFieldMaterialWise()}</Box>
              <Box sx={{ width: 1, mt: 5 }}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={{ xs: 0, sm: 1, md: 1, lg: 1 }}
                >
                  <Box
                    gridColumn={{
                      xs: 'span 12',
                      sm: 'span 12',
                      md: 'span 12',
                      lg: 'span 12',
                    }}
                  >
                    <Box className="addinfo-text-box-1">
                      <Typography
                        variant="h2"
                        className="addinfo-text-1 product-dimension-1"
                      >
                        Product Dimensions
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    gridColumn={{
                      xs: 'span 12',
                      sm: 'span 6',
                      md: 'span 4',
                      lg: 'span 4',
                    }}
                  >
                    <Box className="refrence-product-input addproduct-1-input-box">
                      <Box className="input-box addproduct-1-input-box">
                        <FormHelperText className="input-lable">
                          Inner circumference
                        </FormHelperText>
                        <TextField
                          autoComplete="off"
                          sx={{
                            // mt: 2,
                            '& .MuiFormHelperText-root': { ml: 0, mt: '5px' },
                          }}
                          fullWidth
                          size="small"
                          className="form-control border-none"
                          name="innerCircumference"
                          type="text"
                          {...register(`innerCircumference`, {
                            required: (
                              <ErrorMsg msg="Please enter Inner circumference" />
                            ),
                            minLength: {
                              value:
                                minProductDimensionsInnerCircumferenceLength,
                              message: (
                                <ErrorMsg
                                  msg={`Minimum ${minProductDimensionsInnerCircumferenceLength} Characters Allowed`}
                                />
                              ),
                            },
                            maxLength: {
                              value:
                                maxProductDimensionsInnerCircumferenceLength,
                              message: (
                                <ErrorMsg
                                  msg={`Maximum ${maxProductDimensionsInnerCircumferenceLength} Characters Allowed`}
                                />
                              ),
                            },
                            pattern: {
                              value: NO_WHITE_SPACE_BEGINNING_END,
                              message: (
                                <ErrorMsg
                                  msg={
                                    'Whitespace is not allowed at beginning or end'
                                  }
                                />
                              ),
                            },
                            validate: {
                              isNumber: (val) =>
                                !isNaN(val) || (
                                  <ErrorMsg msg={'Must be a Number'} />
                                ),
                            },
                          })}
                          helperText={
                            <ErrorMessage
                              errors={errors}
                              name={`innerCircumference`}
                            />
                          }
                        />
                        <span className="addproduct-mm-text">mm</span>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    gridColumn={{
                      xs: 'span 12',
                      sm: 'span 6',
                      md: 'span 3',
                      lg: 'span 3',
                    }}
                  >
                    <Box className="refrence-product-input">
                      <Box className="input-box addproduct-1-input-box">
                        <FormHelperText className="input-lable">
                          Length
                        </FormHelperText>
                        <TextField
                          autoComplete="off"
                          sx={{
                            // mt: 2,
                            '& .MuiFormHelperText-root': { ml: 0, mt: '5px' },
                          }}
                          fullWidth
                          size="small"
                          className="form-control border-none"
                          name="length"
                          type="text"
                          {...register(`length`, {
                            required: <ErrorMsg msg="Please enter Length" />,
                            minLength: {
                              value: minProductDimensionsLengthsLength,
                              message: (
                                <ErrorMsg
                                  msg={`Minimum ${minProductDimensionsLengthsLength} Characters Allowed`}
                                />
                              ),
                            },
                            maxLength: {
                              value: maxProductDimensionsLengthsLength,
                              message: (
                                <ErrorMsg
                                  msg={`Maximum ${maxProductDimensionsLengthsLength} Characters Allowed`}
                                />
                              ),
                            },
                            pattern: {
                              value: NO_WHITE_SPACE_BEGINNING_END,
                              message: (
                                <ErrorMsg
                                  msg={
                                    'Whitespace is not allowed at beginning or end'
                                  }
                                />
                              ),
                            },
                            validate: {
                              isNumber: (val) =>
                                !isNaN(val) || (
                                  <ErrorMsg msg={'Must be a Number'} />
                                ),
                            },
                          })}
                          helperText={
                            <ErrorMessage errors={errors} name={`length`} />
                          }
                        />
                        <span className="addproduct-mm-text">mm</span>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    gridColumn={{
                      xs: 'span 12',
                      sm: 'span 6',
                      md: 'span 5',
                      lg: 'span 5',
                    }}
                  >
                    <Box className="refrence-product-input">
                      <Box className="input-box addproduct-1-input-box">
                        <FormHelperText className="input-lable">
                          Depth
                        </FormHelperText>
                        <TextField
                          autoComplete="off"
                          sx={{
                            // mt: 2,
                            '& .MuiFormHelperText-root': { ml: 0, mt: '5px' },
                          }}
                          fullWidth
                          size="small"
                          className="form-control border-none"
                          name="depth"
                          type="text"
                          {...register(`depth`, {
                            required: <ErrorMsg msg="Please enter Depth" />,
                            minLength: {
                              value: minProductDimensionsDepthLength,
                              message: (
                                <ErrorMsg
                                  msg={`Minimum ${minProductDimensionsDepthLength} Characters Allowed`}
                                />
                              ),
                            },
                            maxLength: {
                              value: maxProductDimensionsDepthLength,
                              message: (
                                <ErrorMsg
                                  msg={`Maximum ${maxProductDimensionsDepthLength} Characters Allowed`}
                                />
                              ),
                            },
                            pattern: {
                              value: NO_WHITE_SPACE_BEGINNING_END,
                              message: (
                                <ErrorMsg
                                  msg={
                                    'Whitespace is not allowed at beginning or end'
                                  }
                                />
                              ),
                            },
                            validate: {
                              isNumber: (val) =>
                                !isNaN(val) || (
                                  <ErrorMsg msg={'Must be a Number'} />
                                ),
                            },
                          })}
                          helperText={
                            <ErrorMessage errors={errors} name={`depth`} />
                          }
                        />
                        <span className="addproduct-mm-text">mm</span>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    gridColumn={{
                      xs: 'span 12',
                      sm: 'span 6',
                      md: 'span 12',
                      lg: 'span 12',
                    }}
                  >
                    <Box className="refrence-product-input">
                      <Box className="input-box addproduct-1-input-box">
                        <FormHelperText className="input-lable">
                          Meta Title
                        </FormHelperText>
                        <TextField
                          autoComplete="off"
                          sx={{
                            // mt: 2,
                            '& .MuiFormHelperText-root': { ml: 0, mt: '5px' },
                          }}
                          fullWidth
                          size="small"
                          className="form-control border-none"
                          name="metaTitle"
                          type="text"
                          {...register(`metaTitle`, {
                            required: (
                              <ErrorMsg msg="Please enter Meta Title" />
                            ),
                            minLength: {
                              value: minProductDimensionsMetaTitleLength,
                              message: (
                                <ErrorMsg
                                  msg={`Minimum ${minProductDimensionsMetaTitleLength} Characters Allowed`}
                                />
                              ),
                            },
                            maxLength: {
                              value: maxProductDimensionsMetaTitleLength,
                              message: (
                                <ErrorMsg
                                  msg={`Maximum ${maxProductDimensionsMetaTitleLength} Characters Allowed`}
                                />
                              ),
                            },
                            pattern: {
                              value: NO_WHITE_SPACE_BEGINNING_END,
                              message: (
                                <ErrorMsg
                                  msg={
                                    'Whitespace is not allowed at beginning or end'
                                  }
                                />
                              ),
                            },
                          })}
                          helperText={
                            <ErrorMessage errors={errors} name={`metaTitle`} />
                          }
                        />
                        <Typography
                          variant="p"
                          component="p"
                          className="form-validation-text"
                        >
                          A short 2-3 words
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    gridColumn={{
                      xs: 'span 12',
                      sm: 'span 12',
                      md: 'span 12',
                      lg: 'span 12',
                    }}
                  >
                    <Box className="addproduct-textarea-main input-box ">
                      <FormHelperText className="input-lable">
                        Meta Description
                      </FormHelperText>
                      <TextareaAutosize
                        autoComplete="off"
                        // sx={{
                        //   // mt: 2,
                        //   '& .MuiFormHelperText-root': { ml: 0, mt: '5px' },
                        // }}
                        fullWidth
                        size="small"
                        className="addinfo-textarea admin-addproduct-area text-area-control-admin"
                        name="metaDescription"
                        type="text"
                        {...register(`metaDescription`, {
                          required: (
                            <ErrorMsg msg="Please enter Meta Description" />
                          ),
                          minLength: {
                            value: minProductDimensionsMetaDescriptionLength,
                            message: (
                              <ErrorMsg
                                msg={`Minimum ${minProductDimensionsMetaDescriptionLength} Characters Allowed`}
                              />
                            ),
                          },
                          maxLength: {
                            value: maxProductDimensionsMetaDescriptionLength,
                            message: (
                              <ErrorMsg
                                msg={`Maximum ${maxProductDimensionsMetaDescriptionLength} Characters Allowed`}
                              />
                            ),
                          },
                          pattern: {
                            value: NO_WHITE_SPACE_BEGINNING_END,
                            message: (
                              <ErrorMsg
                                msg={
                                  'Whitespace is not allowed at beginning or end'
                                }
                              />
                            ),
                          },
                        })}
                        helperText={
                          <ErrorMessage
                            errors={errors}
                            name={`metaDescription`}
                          />
                        }
                      />
                      <ErrorMessage errors={errors} name="metaDescription" />
                    </Box>
                  </Box>
                  <Box
                    gridColumn={{
                      xs: 'span 12',
                      sm: 'span 12',
                      md: 'span 12',
                      lg: 'span 12',
                    }}
                  >
                    <Box className="primary-btn-box next-back-btn-box">
                      <Button
                        variant="contained"
                        className=" back-btn-1"
                        onClick={() => {
                          setShow(true);
                        }}
                      >
                        Back
                      </Button>
                      <Button
                        variant="contained"
                        className=" next-btn-1"
                        type="submit"
                        // onClick={handleSave}
                      >
                        Submit
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </form>
    </div>
  );
};

export default AddProducts;
//#endregion
