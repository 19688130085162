import React, { useState, useEffect } from 'react';
import { Box } from '@mui/system';
import { useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import dataService from '../../../../config/dataService';
import { Api } from '../../../../config/api';
import PdfDownloader from '../../../../component/customComponents/pdfDownloader/PdfDownloader';
const ReactDOMServer = require('react-dom/server');
const HtmlToReactParser = require('html-to-react').Parser;

const AdminToSellerInvoice = () => {
  let token = localStorage.getItem('auth');
  const { id, pdfName } = useParams();
  const [templetData, setTempletData] = useState({});

  let elem = templetData;
  var htmlToReactParser = new HtmlToReactParser();
  var reactElement = htmlToReactParser.parse(elem);
  var reactHtml = ReactDOMServer.renderToStaticMarkup(reactElement);

  const getTempletPdf = async () => {
    const urlencoded = new URLSearchParams();
    try {
      urlencoded.append('id', id);
      const tempData = await dataService.post(
        Api.Admin.downloadSellerInvoice,
        urlencoded,
        {
          headers: {
            auth: token,
          },
        }
      );
      setTempletData(tempData.data);
    } catch (err) {
      toast.error(err?.message || 'Something went wrong');
    }
  };

  useEffect(() => {
    getTempletPdf();
  }, []);

  return (
    <div>
      <>
        <Box id="pdf">{reactElement}</Box>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box
            sx={{
              width: '100%',
              maxWidth: '700px',
              display: 'flex',
              justifyContent: 'flex-end',
              my: 5,
            }}
          >
            <PdfDownloader
              rootElement={elem}
              rootElementId="pdf"
              name={pdfName}
              flag='flag'
            />
          </Box>
        </Box>
      </>
    </div>
  );
};

export default AdminToSellerInvoice;
