import SearchIcon from '@mui/icons-material/Search';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import SaveIcon from '@mui/icons-material/Save';
export default {
  SearchIcon,
  VisibilityOff,
  Visibility,
  SaveIcon
};
