//#region ALL IMPORTS
import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  Grid,
  Container,
  Typography,
  Button,
  TextareaAutosize,
  Box,
  FormHelperText,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DisabledByDefaultIcon from "@mui/icons-material/DisabledByDefault";
import { useHistory } from "react-router-dom";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { ToastContainer, toast } from "react-toastify";
import { Api } from "../../../../../../config/api";
import dataService from "../../../../../../config/dataService";
import ContainedButton from "../../../../../../component/customComponents/buttons/ContainedButton";
import ImgUploadButton from "../../../../../../component/customComponents/buttons/ImgUploadButton";
import { useFieldArray, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import {
  ErrorMsg,
  TextAreaErrorMsg,
} from "../../../../../../component/customComponents/errorMsg/ErrorMsg";
import {
  minGuidelinesHeadingLength,
  guidelinesHeadingLength,
  minGuidelinesDescriptionLength,
  guidelinesDescriptionLength,
  NO_WHITE_SPACE_BEGINNING_END,
} from "../../../../../../component/customComponents/validation/Validation";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import UploadIcon from "@mui/icons-material/Upload";
//#endregion

import AdminIndex from '../../../../AdminIndex';
//#region ALL HOOKS AND VARIABALS
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Img = styled("img")({
  display: "block",
  maxWidth: "100%",
  height: "auto",
  maxHeight: "50vh",
  borderRadius: "6px",
});

const { addGuideline } = AdminIndex.AdminSagaActions;
const AddGuidelines = () => {
  let token = localStorage.getItem("auth");
  const history = useHistory();
  const dispatch = AdminIndex.useDispatch();
  const navigate = AdminIndex.useHistory();
  const [image, setImage] = useState({});
  const [data, setData] = useState({});

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
    watch,
  } = useForm({
    defaultValues: {
      guidelineArray: [
        { heading: "", description: "", guideline_type: "", image: "" },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    name: "guidelineArray",
    control,
  });

  const schema = yup.object().shape({
    files: yup.mixed().test("require", "Please select a file", (value) => {
      return value && value.length;
    }),
  });

  //#endregion

  //#region ALL EVENT HANDLERS AND  FUCTIONS
  const handleImg = (e, i) => {
    var file = e.target.files[0];
    setImage((prev) => ({
      ...prev,
      ["image" + i]: file,
    }));
  };

  useEffect(() => {
    for (let i = 0; i < watch("guidelineArray").length; i++) {
      if (image["image" + i] !== "") {
        setValue(`guidelineArray.${i}.image`, image["image" + i]);
      } else {
        setValue(`guidelineArray.${i}.image`, "");
      }
    }
  }, [fields, watch("guidelineArray"), image]);

  const removeImg = (i) => {
    setImage((prev) => ({
      ...prev,
      ["image" + i]: "",
    }));
  };

  const handleSave = async (data) => {
    try {
      let formdata = new FormData();
      formdata.append("main_heading", data?.guidelinesHeading?.trim());
      formdata.append("main_sub_heading", data?.guidelinesSubHeading?.trim());
      formdata.append("main_description", data?.guidelinesDescription?.trim());
      formdata.append("guideline_array", data?.guidelineArray);
      dispatch(addGuideline(token, formdata, navigate));
      // const blogData = await dataService.post(
      //   Api.Admin.addGuideline,
      //   formdata,
      //   {
      //     headers: {
      //       auth: token,
      //     },
      //   }
      // );
      // if (blogData.data.status === 1) {
      //   toast.success("Guidelines added successfully");
      //   history.push("/admin/guidelines");
      // }
    } catch (err) {
      toast.error(err?.message || "Something went wrong");
    }
  };
  //#endregion

  //#region RETURN JSX
  return (
    <>
      <form onSubmit={handleSubmit(handleSave)}>
        <Container maxWidth="false">
          <Typography sx={{ my: 2 }} align="center" variant="h4">
            Add Guideline
          </Typography>

          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography align="left" sx={{ mt: 2 }}>
                Guidelines Heading
              </Typography>
              <TextField
                autoComplete="off"
                sx={{
                  mt: 2,
                  "& .MuiFormHelperText-root": { ml: 0, mt: "5px" },
                }}
                size="small"
                fullWidth
                className="form-control border-none"
                name="guidelinesHeading"
                type="text"
                {...register("guidelinesHeading", {
                  required: <ErrorMsg msg="Please enter Heading" />,
                  minLength: {
                    value: minGuidelinesHeadingLength,
                    message: (
                      <ErrorMsg
                        msg={`Minimum ${minGuidelinesHeadingLength} Characters Allowed`}
                      />
                    ),
                  },
                  maxLength: {
                    value: guidelinesHeadingLength,
                    message: (
                      <ErrorMsg
                        msg={`Maximum ${guidelinesHeadingLength} Characters Allowed`}
                      />
                    ),
                  },
                  pattern: {
                    value: NO_WHITE_SPACE_BEGINNING_END,
                    message: (
                      <ErrorMsg msg="Whitespace is not allowed at beginning or end" />
                    ),
                  },
                })}
                helperText={
                  <ErrorMessage errors={errors} name="guidelinesHeading" />
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography align="left" sx={{ mt: 2 }}>
                Guidelines Sub-Heading
              </Typography>
              <TextField
                autoComplete="off"
                sx={{
                  mt: 2,
                  "& .MuiFormHelperText-root": { ml: 0, mt: "5px" },
                }}
                size="small"
                fullWidth
                className="form-control border-none"
                name="guidelinesSubHeading"
                type="text"
                {...register("guidelinesSubHeading", {
                  required: <ErrorMsg msg="Please enter Heading" />,
                  minLength: {
                    value: minGuidelinesHeadingLength,
                    message: (
                      <ErrorMsg
                        msg={`Minimum ${minGuidelinesHeadingLength} Characters Allowed`}
                      />
                    ),
                  },
                  maxLength: {
                    value: guidelinesHeadingLength,
                    message: (
                      <ErrorMsg
                        msg={`Maximum ${guidelinesHeadingLength} Characters Allowed`}
                      />
                    ),
                  },
                  pattern: {
                    value: NO_WHITE_SPACE_BEGINNING_END,
                    message: (
                      <ErrorMsg msg="Whitespace is not allowed at beginning or end" />
                    ),
                  },
                })}
                helperText={
                  <ErrorMessage errors={errors} name="guidelinesSubHeading" />
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography align="left" sx={{ mt: 2 }}>
                Description
              </Typography>
              <Box className="text-area-control-main">
                <TextareaAutosize
                  autoComplete="off"
                  minRows={0}
                  size="small"
                  fullWidth
                  className="form-control border-none text-area-control1 text-area-control-admin"
                  name="guidelinesDescription"
                  type="text"
                  {...register("guidelinesDescription", {
                    required: (
                      <TextAreaErrorMsg msg="Please enter Description" />
                    ),
                    minLength: {
                      value: minGuidelinesDescriptionLength,
                      message: (
                        <TextAreaErrorMsg
                          msg={`Minimum ${minGuidelinesDescriptionLength} Characters Allowed`}
                        />
                      ),
                    },
                    maxLength: {
                      value: guidelinesDescriptionLength,
                      message: (
                        <TextAreaErrorMsg
                          msg={`Maximum ${guidelinesDescriptionLength} Characters Allowed`}
                        />
                      ),
                    },
                    pattern: {
                      value: NO_WHITE_SPACE_BEGINNING_END,
                      message: (
                        <TextAreaErrorMsg msg="Whitespace is not allowed at beginning or end" />
                      ),
                    },
                  })}
                />
                <ErrorMessage errors={errors} name="guidelinesDescription" />
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ border: 1, my: 3 }}></Box>
        </Container>
        {fields?.map((input, index) => {
          return (
            <>
              <Container>
                <Accordion
                  sx={{
                    marginBottom: 1,
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{
                      // backgroundColor: '#c29958',
                      "& .MuiButtonBase-root-MuiAccordionSummary-root": {
                        borderRadius: "10px !important",
                      },
                    }}
                  >
                    <Grid container>
                      <Grid item lg={1.5}>{`Card - ${index + 1} )  `}</Grid>
                      <Grid item lg={10}>
                        {/* {input.question} */}
                        {watch(`guidelineArray.${index}.heading`)}
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={1} sx={{ mt: 2, border: 1, p: 2 }}>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Typography align="left" sx={{ mt: 2 }}>
                          Question
                        </Typography>
                        <TextField
                          autoComplete="off"
                          sx={{
                            mt: 2,
                            "& .MuiFormHelperText-root": { ml: 0, mt: "5px" },
                          }}
                          size="small"
                          fullWidth
                          className="form-control border-none"
                          name="heading"
                          type="text"
                          {...register(`guidelineArray.${index}.heading`, {
                            required: <ErrorMsg msg="Please enter Question" />,
                            minLength: {
                              value: minGuidelinesHeadingLength,
                              message: (
                                <ErrorMsg
                                  msg={`Minimum ${minGuidelinesHeadingLength} Characters Allowed`}
                                />
                              ),
                            },
                            maxLength: {
                              value: guidelinesHeadingLength,
                              message: (
                                <ErrorMsg
                                  msg={`Maximum ${guidelinesHeadingLength} Characters Allowed`}
                                />
                              ),
                            },
                            pattern: {
                              value: NO_WHITE_SPACE_BEGINNING_END,
                              message: (
                                <ErrorMsg
                                  msg={
                                    "Whitespace is not allowed at beginning or end"
                                  }
                                />
                              ),
                            },
                          })}
                          helperText={
                            <ErrorMessage
                              errors={errors}
                              name={`guidelineArray.${index}.heading`}
                            />
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Typography align="left" sx={{ mt: 2 }}>
                          Answer
                        </Typography>
                        <Box className="text-area-control-main">
                          <TextareaAutosize
                            autoComplete="off"
                            minRows={0}
                            maxRows={5}
                            size="small"
                            fullWidth
                            className="form-control border-none text-area-control1 text-area-control-admin"
                            name="description"
                            type="text"
                            {...register(
                              `guidelineArray.${index}.description`,
                              {
                                required: (
                                  <TextAreaErrorMsg msg="Please enter Answer" />
                                ),
                                minLength: {
                                  value: minGuidelinesDescriptionLength,
                                  message: (
                                    <TextAreaErrorMsg
                                      msg={`Minimum ${minGuidelinesDescriptionLength} Characters Allowed`}
                                    />
                                  ),
                                },
                                maxLength: {
                                  value: guidelinesDescriptionLength,
                                  message: (
                                    <TextAreaErrorMsg
                                      msg={`Maximum ${guidelinesDescriptionLength} Characters Allowed`}
                                    />
                                  ),
                                },
                                pattern: {
                                  value: NO_WHITE_SPACE_BEGINNING_END,
                                  message: (
                                    <TextAreaErrorMsg msg="Whitespace is not allowed at beginning or end" />
                                  ),
                                },
                              }
                            )}
                          />
                          <ErrorMessage
                            errors={errors}
                            name={`guidelineArray.${index}.description`}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Typography align="left" sx={{ mt: 2 }}>
                          Guidline Type
                        </Typography>
                        <Box className="text-area-control-main">
                          <FormControl className="membership-formcontrol-cms">
                            <Select
                              className="form-control border-none text-area-control1"
                              fullWidth
                              name="guideline_type"
                              displayEmpty
                              inputProps={{ "aria-label": "Without label" }}
                              {...register(
                                `guidelineArray.${index}.guideline_type`,
                                {
                                  required: (
                                    <ErrorMsg msg="Please select Guideline's Type" />
                                  ),
                                }
                              )}
                            >
                              <MenuItem
                                value={"Uploading Products on Zaverica"}
                              >
                                Uploading Products on Zaverica
                              </MenuItem>
                              <MenuItem value={"Requesting Custom Products"}>
                                Requesting Custom Products
                              </MenuItem>
                              <MenuItem value={"Placing Bulk Order"}>
                                Placing Bulk Order
                              </MenuItem>
                              <MenuItem value={"Trading Precious Stone"}>
                                Trading Precious Stone
                              </MenuItem>
                            </Select>
                          </FormControl>
                          <ErrorMessage
                            errors={errors}
                            name={`guidelineArray.${index}.guideline_type`}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Typography align="left" sx={{ mt: 2, mb: 1.5 }}>
                          Upload Image
                        </Typography>
                        <Box sx={{ width: "100%", position: "relative" }}>
                          {image["image" + index] === "" ||
                          image["image" + index] === undefined ? (
                            <>
                              <Button
                                component="label"
                                sx={{ borderRadius: 0, textTransform: "none" }}
                                variant="contained"
                                onChange={(e) => handleImg(e, index)}
                              >
                                Upload
                                <UploadIcon />
                                <input
                                  name="image"
                                  hidden
                                  accept="image/*"
                                  type="file"
                                  {...register(
                                    `guidelineArray.${index}.image`,
                                    {
                                      required: (
                                        <ErrorMsg msg="Please select Image" />
                                      ),
                                    }
                                  )}
                                />
                              </Button>
                              <ErrorMessage
                                errors={errors}
                                name={`guidelineArray.${index}.image`}
                              />
                            </>
                          ) : (
                            <Box className="aboutUs-img-box">
                              <Img
                                sx={{
                                  my: 2,
                                  borderRadius: "0px",
                                  height: "130px",
                                  width: "200px",
                                }}
                                src={window.URL.createObjectURL(
                                  image["image" + index]
                                )}
                                alt="no image"
                              />
                              <Button className="aboutUs-top-btn">
                                <Tooltip
                                  title="Remove this Image"
                                  placement="bottom"
                                >
                                  <DisabledByDefaultIcon
                                    className="remove-img-icon"
                                    onClick={() => removeImg(index)}
                                    sx={{
                                      // bgcolor: 'black(0.5)',
                                      // color: 'black',
                                      mt: 5,
                                    }}
                                  />
                                </Tooltip>
                              </Button>
                            </Box>
                          )}
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        textAlign="right"
                      >
                        <ContainedButton
                          // display={inputFields.length <= 1 && 'none'}
                          onClick={() => remove(index)}
                          name="Remove"
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Container>
            </>
          );
        })}
        <Container>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              disabled={fields.length == 9 && true}
              variant="contained"
              sx={{ textTransform: "none" }}
              onClick={() => {
                if (fields.length <= 9) {
                  append({
                    heading: "",
                    description: "",
                    guideline_type: "",
                    image: "",
                  });
                }
              }}
            >
              Add More
            </Button>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center", my: 5 }}>
            <ContainedButton name="Submit" type="submit" />
          </Box>
        </Container>
      </form>
    </>
  );
};

export default AddGuidelines;
//#endregion
