import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import {
  tableCellClasses,
  TableCell,
  Typography,
  Button,
  Container,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Table,
  TableBody,
  Box,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import { Api } from '../../../../../config/api';
import dataService from '../../../../../config/dataService';
import CommonButton from '../../../../../component/customComponents/buttons/CommonButton';
import { toast } from 'react-toastify';
//#region ALL HOOKS AND VARIABALS

import AdminIndex from '../../../AdminIndex';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


const { getBlogList, getGuidelineList, getAllCMS } = AdminIndex.AdminSagaActions;
export default function Blogs() {
  let token = localStorage.getItem('auth');
  const history = useHistory();
  const dispatch = AdminIndex.useDispatch();
  const [getData, setGetData] = useState([]);
  const [getTempData, setGetTempData] = useState([]);
  const [blogId, setBlogId] = useState([]);
  const [guidelinesId, setGuidelinesId] = useState([]);
  const [idArray, setIdArray] = useState('');

  const [aboutUsId, setAboutUsId] = useState('');
  const [tAndcId, setTAndcId] = useState('');

  const isKeyInArray = (array, key) => {
    return array.some((obj) => obj.hasOwnProperty(key));
  };

  const blogsAddBtn = (
    <CommonButton display={blogId && 'none'} name="Add" onClick={() => history.push('/admin/blogs/addblogs')} />
  );
  const blogsEditBtn = (
    <CommonButton display={!blogId && 'none'} name="Edit" onClick={() => history.push(`/admin/blogs/updateblogs/${blogId}`)} />
  );
  const guidelinesAddBtn = (
    <CommonButton
      display={guidelinesId && 'none'}
      name="Add"
      onClick={() => history.push('/admin/blogs/addguidelines')}
    />
  );
  const guidelinesEditBtn = (
    <CommonButton
      display={!guidelinesId && 'none'}
      name="Edit"
      onClick={() => history.push(`/admin/blogs/updateguidelines/${blogId}`)}
    />
  );
  const productDetailsBtn = (
    <CommonButton
      name="Add"
      onClick={() => history.push('/admin/productdetails')}
    />
  );
  const productPricingBtn = (
    <CommonButton
      name="Add"
      onClick={() => history.push('/admin/productpricing')}
    />
  );
  const productPurityBtn = (
    <CommonButton
      name="Add"
      onClick={() => history.push('/admin/productpurity')}
    />
  );

  function createData(content, addBtn, editBtn) {
    return { content, addBtn, editBtn };
  }

  const blogsData = async () => {
    let urlencoded = new URLSearchParams();
    dispatch(getBlogList(token, urlencoded));
    // await dataService
    //   .post(Api.Admin.listBlog, urlencoded, {
    //     headers: {
    //       auth: token,
    //     },
    //   })
    //   .then((res) => {
    //     setBlogId(res?.data?.data?.[0]?._id);
    //   })
    //   .catch((err) => toast.error(err?.message || 'Something went wrong'));
  };

  const loading = AdminIndex.useSelector((state) => {
    return state.AdminSaga.loading;
  });

  const blogList = AdminIndex.useSelector((state) => {
    return state.AdminSaga.blogList;
  });

  useEffect(() => {
    setBlogId(blogList?.[0]?._id);
  }, [blogList]);

  const guidlinesData = async () => {
    let urlencoded = new URLSearchParams();
    dispatch(getGuidelineList(token, urlencoded));
    // await dataService
    //   .post(Api.Admin.listGuideline, urlencoded, {
    //     headers: {
    //       auth: token,
    //     },
    //   })
    //   .then((res) => {
    //     setGuidelinesId(res?.data?.data?.[0]?._id);
    //   })
    //   .catch((err) => toast.error(err?.message || 'Something went wrong'));
  };

  const guidelineList = AdminIndex.useSelector((state) => {
    return state.AdminSaga.guidelineList;
  });

  useEffect(() => {
    setGuidelinesId(guidelineList?.[0]?._id);
  }, [guidelineList]);

  const rows = [
    createData('Blogs', blogsAddBtn, blogsEditBtn),
    createData('Guidelines', guidelinesAddBtn, guidelinesEditBtn),
    createData('Product Dimensions', productDetailsBtn),
    createData('Product Pricing', productPricingBtn),
    createData('Product Purity', productPurityBtn),
  ];

  useEffect(() => {
    getAboutUsData();
    blogsData();
    guidlinesData()
  }, []);

  //#endregion

  //#region ALL EVENT HANDLERS AND  FUCTIONS
  const getAboutUsData = async (e) => {
    let formdata = new URLSearchParams();
    try {
      dispatch(getAllCMS(token, formdata));
      // const Data = await dataService.post(Api.Admin.getAllCms, formdata);

      // setGetData(Data?.data?.data);
      // setGetTempData(Data.data.data);
    } catch (err) {
      toast.error(err?.message || 'Something went wrong');
    }
  };

  const allCMS = AdminIndex.useSelector((state) => {
    return state.AdminSaga.allCMS;
  });

  useEffect(() => {
    setGetData(allCMS);
    setGetTempData(allCMS);
  }, [allCMS]);

  useEffect(() => {
    filter();
  }, [getData, getTempData]);

  const filter = () => {
    let filterData = getTempData.filter((Data) => {
      if (Object.getOwnPropertyNames(Data).includes('about_us')) {
        setAboutUsId(Data._id);
      }
      if (Object.getOwnPropertyNames(Data).includes('terms_condition')) {
        setTAndcId(Data._id);
      }
    });
    return setIdArray(filterData);
  };
  //#endregion

  //#region RETURN JSX
  return (
    <>
      <Container maxWidth="false">
        <Typography
          sx={{ my: 2, fontWeight: 'bold' }}
          variant="h4"
          align="center"
        >
          Blogs Management
        </Typography>
        <TableContainer
          elevation={0}
          sx={{
            mt: 8,
            maxWidth: {
              xs: '365px',
              sm: '600px',
              md: '100%',
              lg: '100%',
            },
            '&::-webkit-scrollbar': {
              width: 10,
            },
            '&::-webkit-scrollbar': {
              height: '8px',
              width: '12px',
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: '#fff',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(224, 224, 224, 1)',
              borderRadius: '30px',
              width: '20px',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              background: '#B0B0B0',
            },
          }}
          component={Paper}
        >
          <Table>
            <TableHead>
              <TableRow sx={{ whiteSpace: 'nowrap' }}>
                <StyledTableCell
                  colSpan={2}
                  align="left"
                  sx={{ fontWeight: 'bold', fontSize: '25px' }}
                >
                  Content
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <StyledTableRow key={row.name} sx={{ whiteSpace: 'nowrap' }}>
                  <StyledTableCell align="left">{row.content}</StyledTableCell>
                  <StyledTableCell align="right">
                    {row.addBtn}
                    {row.editBtn}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </>
  );
}
//#endregion
