import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Button,
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Menu,
  MenuItem,
  InputBase,
  TablePagination,
  Tooltip,
} from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled, alpha } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import dataService from '../../../../config/dataService';
import { Api } from '../../../../config/api';
import ContainedButton from '../../../../component/customComponents/buttons/ContainedButton';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import NoDataFound from '../../../../component/customComponents/noDataFound/NoDataFound';
import Png from '../../../../assets/png';
import { toast } from 'react-toastify';
import Loader from '../../../../component/customComponents/loader/Loader';
import {
  commaSeparated,
  price,
} from '../../../../component/customComponents/commonFunctions/CommonFunctions';
import moment from 'moment';
import Mui from '../../../../component/customComponents/mui/Mui';

import AdminIndex from '../../AdminIndex';
//#region ALL HOOKS AND VARIABALS

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: '25px',
  backgroundColor: alpha(theme.palette.common.white, 0.25),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '250px',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: '540px',
  },
  border: '1px solid black',
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));


const { getAllOrders } = AdminIndex.AdminSagaActions;
export default function RejectedOrders() {
  let token = localStorage.getItem('auth');
  const dispatch = AdminIndex.useDispatch();
  const [flag, setFlag] = useState(false);
  const [open, setOpen] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const openManu = Boolean(anchorEl);
  const history = useHistory();
  const [orderData, setOrderData] = useState([]);
  const [tempOrderData, setTempOrderData] = useState([]);
  const [array, setArray] = useState([]);
  const [tempArray, setTempArray] = useState([]);
  const [transactionIdrderData, setTransactionIdOrderData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    getAllOrder();
    setInterval(() => {
      setFlag(true);
    }, 500);
  }, []);

  useEffect(() => {
    filterRejectedOrders();
  }, [orderData]);

  //#endregion

  //#region ALL EVENT HANDLERS AND  FUCTIONS
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const loading = AdminIndex.useSelector((state) => {
    return state.AdminSaga.loading;
  });

  const getAllOrder = async () => {
    let formdata = new FormData();
    dispatch(getAllOrders(token,formdata))
    // await dataService
    //   .post(Api.Admin.getallorder, formdata, {
    //     headers: {
    //       auth: token,
    //     },
    //   })
    //   .then((res) => {
    //     setOrderData(res.data.data);
    //     setTempOrderData(res.data.data);
    //     setTransactionIdOrderData(res?.data?.data?.products);
    //   })
    //   .catch((err) => toast.error(err?.message || 'Something went wrong'))
    //   .finally(() => {
    //     // setLoading(false);
    //   });
  };

  // useEffect(() => {
  //   setLoading(loadingState);
  // }, [loadingState]);

  const rejectedOrder = AdminIndex.useSelector((state) => {
    return state.AdminSaga.orders;
  });

  useEffect(() => {
    setOrderData(rejectedOrder);
    setTempOrderData(rejectedOrder);
    setTransactionIdOrderData(rejectedOrder?.products);
  }, [rejectedOrder]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseSorting = (e) => {
    let closeData = tempOrderData.filter(
      (val) => val.orderItemStatus == 'Close'
    );
    setOrderData(closeData);
  };

  const handleOpenSorting = (e) => {
    let openData = tempOrderData.filter(
      (val) => val.orderItemStatus !== 'Close'
    );
    setOrderData(openData);
  };

  const filterRejectedOrders = () => {
    var filtered = [];
    for (var arr in orderData) {
      if (orderData[arr]?.approveStatus == '2') {
        filtered.push(orderData[arr]);
      }
    }
    setArray(filtered);
    setTempArray(filtered);
  };

  const filter = (e) => {
    let data = e.target.value;
    let value = data.trim().toLowerCase();
    let filterData = tempArray.filter(
      (data) =>
        data?.order_id?.toLowerCase().includes(value) ||
        data?.products?.[0]?.transaction_id?.toLowerCase().includes(value)
    );
    setArray(filterData);
  };

  const orderStatusFilter = () => {
    return (
      <>
        <span>Order Status</span>
        <Button
          id="demo-positioned-button"
          aria-controls={open ? 'demo-positioned-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          // variant="outlined"
          sx={{
            // borderRadius: '6px',
            // borderRadius: 0,
            textTransform: 'none',
          }}
          className="filter-dropdown-btn"
        >
          <ArrowDropDownIcon />
        </Button>
        <Menu
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          anchorEl={anchorEl}
          open={openManu}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <MenuItem
            onClick={() => {
              handleCloseSorting();
              setAnchorEl(null);
            }}
          >
            Close
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleOpenSorting();
              setAnchorEl(null);
            }}
          >
            Open
          </MenuItem>
          <MenuItem
            onClick={() => {
              getAllOrder();
              setAnchorEl(null);
            }}
          >
            All
          </MenuItem>
          {/* <MenuItem >
          Rejected
        </MenuItem> */}
          {/* <MenuItem onClick={handleClose}>Logout</MenuItem> */}
        </Menu>
      </>
    );
  };

  const columns = [
    { id: 'date', label: 'Date' },
    { id: 'orderid', label: 'Order Id', align: 'center' },
    { id: 'price', label: 'Price', align: 'center' },
    { id: 'transactionid', label: 'Transaction Id', align: 'center' },
    {
      id: 'orderstatus',
      label:
        // orderStatusFilter()
        'Order Status',
      align: 'center',
    },
    {
      id: 'reason',
      label:
        // orderStatusFilter()
        'Reason',
      align: 'center',
    },
    // { id: 'action', label: '' },
  ];
  //#endregion

  //#region RETURN JSX
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Container maxWidth="false">
          <Typography variant="h4" align="center" sx={{ fontWeight: 'bold' }}>
            Rejected Orders
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 5, mb: 2 }}>
            <Mui.GrayTooltip
              title="Search by (Order Id / Transaction Id)"
              placement="bottom-start"
            >
              <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  sx={{
                    width: '100%',
                    '& .MuiInputBase-input': {
                      width: '100%',
                      '&:focus, &.MuiInputBase-input': {
                        width: '100%',
                      },
                    },
                  }}
                  placeholder="Search"
                  inputProps={{ 'aria-label': 'search' }}
                  onChange={filter}
                />
              </Search>
            </Mui.GrayTooltip>
          </Box>
          <TableContainer
            elevation={0}
            sx={{
              maxWidth: {
                xs: '365px',
                sm: '600px',
                md: '100%',
                lg: '100%',
              },
              '&::-webkit-scrollbar': {
                width: 10,
              },
              '&::-webkit-scrollbar': {
                height: '8px',
                width: '12px',
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: '#fff',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(224, 224, 224, 1)',
                borderRadius: '30px',
                width: '20px',
              },
              '&::-webkit-scrollbar-thumb:hover': {
                background: '#B0B0B0',
              },
            }}
            component={Paper}
          >
            <Table
              sx={{
                width: {
                  xs: 'max-content',
                  sm: 'max-content',
                  md: '100%',
                  lg: '100%',
                },
              }}
              size="small"
            >
              <TableHead>
                <TableRow sx={{ bgcolor: 'black', whiteSpace: 'nowrap' }}>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth, bgcolor: 'red' }}
                      className="pagination-table-header-row"
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {array.length
                  ? array
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row._id}
                            sx={{
                              '&:last-child td, &:last-child th': { border: 0 },
                            }}
                          >
                            <StyledTableCell align="left">
                              {/* {new Date(row.createdAt).toLocaleDateString()} */}
                              {moment(row.createdAt).format('DD/MM/YYYY')}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row.order_id}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {price(row.totalAmount)}
                              <span>
                                <img
                                  src={Png.rupee}
                                  className="rupee-icon"
                                ></img>
                              </span>
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {/* {row.totalAmount} */}
                              {/* ******** */}
                              {row.products?.[0]?.transaction_id
                                ? row.products?.[0]?.transaction_id
                                : row?.transaction_id}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row.orderItemStatus == 'Close'
                                ? 'Close'
                                : 'Open'}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row.reasonOfRejection}
                            </StyledTableCell>

                            {/* <StyledTableCell align="right">
                              <ContainedButton
                                name="View"
                                onClick={() =>
                                  history.push(
                                    `/admin/pendingorders/pendingorderdescription/${row._id}`
                                  )
                                }
                              />
                            </StyledTableCell> */}
                          </TableRow>
                        );
                      })
                  : ''}
              </TableBody>
            </Table>
            {!orderData.length && flag ? <NoDataFound /> : ''}
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            component="div"
            count={array.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Container>
      )}
    </>
  );
}
//#endregion
