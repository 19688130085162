import React, { useEffect, useState } from 'react';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import {
  Typography,
  Button,
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Container,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Api } from '../../../../config/api';
import dataService from '../../../../config/dataService';
import { toast } from 'react-toastify';
import moment from 'moment';
//#region ALL HOOKS AND VARIABALS

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function ProductManagement() {
  const history = useHistory();
  let token = localStorage.getItem('auth');
  const [productData, setProductData] = useState([]);
  const [seller, setSeller] = useState({});

  useEffect(() => {
    getProductData();
    getAdminDetails();
  }, []);
  //#endregion

  //#region ALL EVENT HANDLERS AND  FUCTIONS
  const getProductData = async () => {
    const urlencoded = new URLSearchParams();
    try {
      const Data = await dataService.get(Api.Admin.getAllProduct, urlencoded);
      setProductData(Data.data.data);
    } catch (err) {
      toast.error(err?.message || 'Something went wrong');
    }
  };

  const getAdminDetails = async () => {
    try {
      const adminData = await dataService.get(Api.Admin.getAdmin, {
        headers: {
          auth: token,
        },
      });
      setSeller(adminData.data.data);
    } catch (err) {
      toast.error(err?.message || 'Something went wrong');
    }
  };
  //#endregion

  //#region RETURN JSX
  return (
    <>
      <Container sx={{ mt: 2, mb: 5 }} maxWidth="false">
        <Typography variant="h4" align="center" sx={{ fontWeight: 'bold' }}>
          Product Management
        </Typography>
        <br />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <Box>
            <Button
              variant="outlined"
              sx={{
                borderRadius: '6px',
                borderRadius: 0,
                textTransform: 'none',
              }}
            >
              Filter <FilterAltIcon />
            </Button>
            <Button
              variant="contained"
              sx={{ borderRadius: 0, textTransform: 'none', ml: 2 }}
              onClick={() => {
                history.push('/admin/productmanagement/addproducts');
              }}
            >
              Add Product
            </Button>
          </Box>
        </Box>
        <br />
        <TableContainer
          elevation={0}
          sx={{
            maxWidth: {
              xs: '365px',
              sm: '600px',
              md: '100%',
              lg: '100%',
            },
            '&::-webkit-scrollbar': {
              width: 10,
            },
            '&::-webkit-scrollbar': {
              height: '8px',
              width: '12px',
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: '#fff',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(224, 224, 224, 1)',
              borderRadius: '30px',
              width: '20px',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              background: '#B0B0B0',
            },
          }}
          component={Paper}
        >
          <Table
            sx={{
              width: {
                xs: 'max-content',
                sm: 'max-content',
                md: '100%',
                lg: '100%',
              },
            }}
            size="small"
          >
            <TableHead>
              <StyledTableRow>
                <StyledTableCell align="left">Date</StyledTableCell>
                <StyledTableCell align="center">Seller</StyledTableCell>
                <StyledTableCell align="center">Product Id</StyledTableCell>
                <StyledTableCell align="center">Category</StyledTableCell>
                <StyledTableCell align="center">Product</StyledTableCell>
                <StyledTableCell align="left" colSpan={2}>
                  Price
                </StyledTableCell>
                <StyledTableCell align="center"></StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {productData.length
                ? productData.map((row, index) => (
                    <StyledTableRow
                      key={row.name}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <StyledTableCell align="left">
                        {new Date(row.updatedAt).toLocaleDateString()}
                        {moment(row.updatedAt).format('DD/MM/YYYY')}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.name || row.company_name}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.product_id}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row?.material_id?.material}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row?.item_id?.item}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {parseInt(
                          row.silver?.other_charges || row.gold?.other_charges
                        ) +
                          parseInt(
                            row.silver?.making_charges ||
                              row.gold?.making_charges
                          )}{' '}
                        Rs/-
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {row.value}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Button
                          variant="contained"
                          sx={{
                            ml: 1,
                            width: '90px',
                            borderRadius: 5,
                            textTransform: 'none',
                          }}
                        >
                          View
                        </Button>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
                : 'No record found'}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </>
  );
}
//#endregion
