//#region ALL IMPORTS
import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import {
  Grid,
  Container,
  Typography,
  Button,
  TextareaAutosize,
  Box,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Tooltip,
} from '@mui/material';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import { useHistory } from 'react-router-dom';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { ToastContainer, toast } from 'react-toastify';
import { Api } from '../../../../../../config/api';
import dataService from '../../../../../../config/dataService';
import ContainedButton from '../../../../../../component/customComponents/buttons/ContainedButton';
import ImgUploadButton from '../../../../../../component/customComponents/buttons/ImgUploadButton';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import {
  ErrorMsg,
  TextAreaErrorMsg,
} from '../../../../../../component/customComponents/errorMsg/ErrorMsg';
import {
  minDetailsHeadingLength,
  detailsHeadingLength,
  minDetailsDescriptionLength,
  detailsDescriptionLength,
  NO_WHITE_SPACE_BEGINNING_END,
} from '../../../../../../component/customComponents/validation/Validation';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import UploadIcon from '@mui/icons-material/Upload';
//#endregion

import AdminIndex from '../../../../AdminIndex';
const { addProductDescription } = AdminIndex.AdminSagaActions;
//#region ALL HOOKS AND VARIABALS
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const Img = styled('img')({
  display: 'block',
  maxWidth: '100%',
  height: 'auto',
  maxHeight: '50vh',
  borderRadius: '6px',
});


const AddProductDetails = () => {
  let token = localStorage.getItem('auth');
  const history = useHistory();
  const dispatch = AdminIndex.useDispatch();
  const navigate = AdminIndex.useHistory();
  const [image, setImage] = useState('');

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const schema = yup.object().shape({
    files: yup.mixed().test('require', 'Please select a file', (value) => {
      return value && value.length;
    }),
  });
  //#endregion

  //#region ALL EVENT HANDLERS AND  FUCTIONS

  const handleImg = (e) => {
    var file = e.target.files[0];
    setImage(file);
  };

  const removeImg = () => {
    setImage('');
  };

  const handleSave = async (data) => {
    try {
      let formdata = new FormData();
      formdata.append('heading', data?.productDetailsHeading?.trim());
      formdata.append('description', data?.productDetailsDescription?.trim());
      formdata.append('product_type', data?.item?.trim());
      formdata.append('images', image);

      dispatch(addProductDescription(token, formdata, navigate));
      // const blogData = await dataService.post(
      //   Api.Admin.addProductDescription,
      //   formdata,
      //   {
      //     headers: {
      //       auth: token,
      //     },
      //   }
      // );
      // if (blogData.data.status === 1) {
      //   toast.success('Product details added successfully');
      //   history.push('/admin/productdetails');
      // }
    } catch (err) {
      toast.error(err?.message || 'Something went wrong');
    }
  };
  //#endregion

  //#region RETURN JSX
  return (
    <>
      <form onSubmit={handleSubmit(handleSave)}>
        <Container maxWidth="false">
          <Typography sx={{ my: 2 }} align="center" variant="h4">
            Add Product Dimensions
          </Typography>

          <Grid container spacing={5}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography align="left" sx={{ mt: 2 }}>
              Product Dimensions Heading
              </Typography>
              <TextField
                autoComplete="off"
                sx={{
                  mt: 2,
                  '& .MuiFormHelperText-root': { ml: 0, mt: '5px' },
                }}
                size="small"
                fullWidth
                className="form-control border-none"
                name="productDetailsHeading"
                type="text"
                {...register('productDetailsHeading', {
                  required: <ErrorMsg msg="Please enter Heading" />,
                  minLength: {
                    value: minDetailsHeadingLength,
                    message: (
                      <ErrorMsg
                        msg={`Minimum ${minDetailsHeadingLength} Characters Allowed`}
                      />
                    ),
                  },
                  maxLength: {
                    value: detailsHeadingLength,
                    message: (
                      <ErrorMsg
                        msg={`Maximum ${detailsHeadingLength} Characters Allowed`}
                      />
                    ),
                  },
                  pattern: {
                    value: NO_WHITE_SPACE_BEGINNING_END,
                    message: (
                      <ErrorMsg msg="Whitespace is not allowed at beginning or end" />
                    ),
                  },
                })}
                helperText={
                  <ErrorMessage errors={errors} name="productDetailsHeading" />
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography align="left" sx={{ mt: 2 }}>
                Description
              </Typography>
              <Box className="text-area-control-main">
                <TextareaAutosize
                  autoComplete="off"
                  minRows={0}
                  size="small"
                  fullWidth
                  className="form-control border-none text-area-control1 text-area-control-admin"
                  name="productDetailsDescription"
                  type="text"
                  {...register('productDetailsDescription', {
                    required: (
                      <TextAreaErrorMsg msg="Please enter Description" />
                    ),
                    minLength: {
                      value: minDetailsDescriptionLength,
                      message: (
                        <TextAreaErrorMsg
                          msg={`Minimum ${minDetailsDescriptionLength} Characters Allowed`}
                        />
                      ),
                    },
                    maxLength: {
                      value: detailsDescriptionLength,
                      message: (
                        <TextAreaErrorMsg
                          msg={`Maximum ${detailsDescriptionLength} Characters Allowed`}
                        />
                      ),
                    },
                    pattern: {
                      value: NO_WHITE_SPACE_BEGINNING_END,
                      message: (
                        <TextAreaErrorMsg msg="Whitespace is not allowed at beginning or end" />
                      ),
                    },
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="productDetailsDescription"
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography align="left" sx={{ mt: 2 }}>
                Item
              </Typography>
              <Box className="text-area-control-main">
                <FormControl className="membership-formcontrol-cms">
                  <Select
                    className="form-control border-none text-area-control1"
                    fullWidth
                    name="item"
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    {...register('item', {
                      required: <ErrorMsg msg="Please select Item" />,
                    })}
                  >
                    <MenuItem value={'Ring'}>Ring</MenuItem>
                    <MenuItem value={'Necklace'}>Necklace</MenuItem>
                    <MenuItem value={'Mangalsutra'}>Mangalsutra</MenuItem>
                  </Select>
                </FormControl>
                <ErrorMessage errors={errors} name="item" />
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography align="left" sx={{ mt: 2, mb: 1.5 }}>
                Upload Image
              </Typography>
              <Box sx={{ width: '100%', position: 'relative' }}>
                {image === '' ? (
                  <>
                    <Button
                      component="label"
                      sx={{ borderRadius: 0, textTransform: 'none' }}
                      variant="contained"
                      onChange={handleImg}
                    >
                      Upload
                      <UploadIcon />
                      <input
                        name="files"
                        hidden
                        accept="image/*"
                        type="file"
                        {...register('files', {
                          required: <ErrorMsg msg="Please select Image" />,
                        })}
                      />
                    </Button>
                    <ErrorMessage errors={errors} name="files" />
                  </>
                ) : null}
                {image === '' ? (
                  ''
                ) : (
                  <Box className="aboutUs-img-box">
                    <Img
                      sx={{
                        my: 2,
                        borderRadius: '0px',
                        height: '130px',
                        width: '200px',
                      }}
                      src={window.URL.createObjectURL(image)}
                      alt="no image"
                    />
                    <Button className="aboutUs-top-btn">
                      <Tooltip title="Remove this Image" placement="bottom">
                        <DisabledByDefaultIcon
                          className="remove-img-icon"
                          onClick={removeImg}
                          sx={{
                            // bgcolor: 'black(0.5)',
                            // color: 'black',
                            mt: 5,
                          }}
                        />
                      </Tooltip>
                    </Button>
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ display: 'flex', justifyContent: 'center', my: 5 }}>
            <ContainedButton name="Submit" type="submit" />
          </Box>
        </Container>
      </form>
    </>
  );
};

export default AddProductDetails;
//#endregion
