import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Button,
  TablePagination,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Menu,
  MenuItem,
} from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import dataService from '../../../../config/dataService';
import { Api } from '../../../../config/api';
import ContainedButton from '../../../../component/customComponents/buttons/ContainedButton';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import NoDataFound from '../../../../component/customComponents/noDataFound/NoDataFound';
import { toast } from 'react-toastify';
import Loader from '../../../../component/customComponents/loader/Loader';
import moment from 'moment';

import AdminIndex from '../../AdminIndex';
//#region ALL HOOKS AND VARIABALS
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


const { getAllReturnOrders } = AdminIndex.AdminSagaActions;
export default function ReturnOrders() {
  let token = localStorage.getItem('auth');
  const dispatch = AdminIndex.useDispatch();
  const history = useHistory();
  // const [loading, setLoading] = useState(false);
  const [flag, setFlag] = useState(false);
  const [returnOrderData, setReturnOrderData] = useState([]);
  const [returnOrderProductData, setReturnOrderProductData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const openManu = Boolean(anchorEl);
  const [open, setOpen] = useState(true);
  const [data, setData] = useState([]);
  const [tempData, setTempData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    getAllReturnOrder();
    setInterval(() => {
      setFlag(true);
    }, 500);
  }, []);
  //#endregion

  //#region ALL EVENT HANDLERS AND  FUCTIONS
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getAllReturnOrder = async () => {
    let formdata = new FormData();
    dispatch(getAllReturnOrders(token,formdata));
    // await dataService
    //   .post(Api.Admin.getAllReturnOrderAdmin, formdata, {
    //     headers: {
    //       auth: token,
    //     },
    //   })
    //   .then((res) => {
    //     setReturnOrderData(res.data.data);
    //     setReturnOrderProductData(res.data.data[0]?.products[0]);
    //     setTempData(res.data.data);
    //   })
    //   .catch((err) => toast.error(err?.message || 'Something went wrong'))
    //   .finally(() => {
    //     setLoading(false);
    //   });
  };

  const loading = AdminIndex.useSelector((state) => {
    return state.AdminSaga.loading;
  });

  const returnOrders = AdminIndex.useSelector((state) => {
    return state.AdminSaga.returnOrders;
  });

  useEffect(() => {
    setReturnOrderData(returnOrders);
    setReturnOrderProductData(returnOrders?returnOrders[0]?.products[0]:[]);
    setTempData(returnOrders);
  }, [returnOrders]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const sortDateInAscedingOrder = (e) => {
    let sortedDate = tempData.sort(
      (a, b) =>
        moment(...a.createdAt.split('/').reverse()) -
        moment(...b.createdAt.split('/').reverse())
    );
    setData(sortedDate);
  };

  const sortDateInDescendingOrder = (e) => {
    let sortedDate = tempData.sort(
      (a, b) =>
        moment(...b.createdAt.split('/').reverse()) -
        moment(...a.createdAt.split('/').reverse())
    );
    setData(sortedDate);
  };

  const dateFilter = () => {
    return (
      <>
        <span>Return Date</span>
        <Button
          id="demo-positioned-button"
          aria-controls={open ? 'demo-positioned-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          sx={{
            textTransform: 'none',
          }}
          className="filter-dropdown-btn"
        >
          <ArrowDropDownIcon />
        </Button>
        <Menu
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          anchorEl={anchorEl}
          open={openManu}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <MenuItem
            onClick={() => {
              sortDateInAscedingOrder();
              setAnchorEl(null);
            }}
          >
            Asceding Order
          </MenuItem>
          <MenuItem
            onClick={() => {
              sortDateInDescendingOrder();
              setAnchorEl(null);
            }}
          >
            Descending Order
          </MenuItem>
        </Menu>
      </>
    );
  };

  const columns = [
    { id: 'returndate', label: dateFilter() },
    { id: 'orderitemid', label: 'Order Item Id' },
    { id: 'orderid', label: 'Order Id' },
    { id: 'returnid	', label: 'Return Id	' },
    { id: 'status', label: 'Status' },
    { id: 'action', label: '' },
  ];

  //#endregion

  //#region RETURN JSX
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Container sx={{ mt: 2, mb: 5 }} maxWidth="false">
          <Typography variant="h4" align="center" sx={{ fontWeight: 'bold' }}>
            Return Orders
          </Typography>
          <br />
          <TableContainer
            elevation={0}
            sx={{
              maxWidth: {
                xs: '365px',
                sm: '600px',
                md: '100%',
                lg: '100%',
              },
              '&::-webkit-scrollbar': {
                width: 10,
              },
              '&::-webkit-scrollbar': {
                height: '8px',
                width: '12px',
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: '#fff',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(224, 224, 224, 1)',
                borderRadius: '30px',
                width: '20px',
              },
              '&::-webkit-scrollbar-thumb:hover': {
                background: '#B0B0B0',
              },
            }}
            component={Paper}
          >
            <Table
              sx={{
                width: {
                  xs: 'max-content',
                  sm: 'max-content',
                  md: '100%',
                  lg: '100%',
                },
              }}
              size="small"
            >
              <TableHead>
                <TableRow sx={{ bgcolor: 'black', whiteSpace: 'nowrap' }}>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth, bgcolor: 'red' }}
                      className="pagination-table-header-row"
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {returnOrderData.length
                  ? returnOrderData
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row._id}
                            sx={{
                              '&:last-child td, &:last-child th': { border: 0 },
                            }}
                          >
                            <StyledTableCell align="left">
                              {/* {new Date(row.createdAt).toLocaleDateString()} */}
                              {moment(row.createdAt).format('DD/MM/YYYY')}
                            </StyledTableCell>
                            {/* {returnOrderData?.length
                        ? row?.products.map((product, j) => (
                            <StyledTableCell align="left">{product?.index}{console.log(product.index, 'uio')}</StyledTableCell>
                          ))
                        : 'No data found'}
                      {console.log(row?.products[i]?.index, 'op0')} */}
                            <StyledTableCell>
                              {returnOrderProductData.order_item_id}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {/* {row.totalAmount} */}
                              {row?.order_id?.order_id}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {/* {row.orderItemStatus == 'Close' ? 'Close' : 'Open'} */}
                              {row.return_id}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {/* {row.orderItemStatus == 'Close' ? 'Close' : 'Open'} */}
                              {row.return_status == '0' ? (
                                <Typography>Pending</Typography>
                              ) : row.return_status == '1' ||
                                row.return_status == '3' ? (
                                <Typography sx={{ color: 'green' }}>
                                  Approved
                                </Typography>
                              ) : row.return_status == '2' ? (
                                <Typography sx={{ color: 'red' }}>
                                  Rejected
                                </Typography>
                              ) : (
                                <Typography>Pending</Typography>
                              )}
                            </StyledTableCell>

                            <StyledTableCell align="right">
                              <ContainedButton
                                name="View"
                                onClick={() =>
                                  history.push(
                                    `/admin/returnorders/viewreturnorder/${row._id}`
                                  )
                                }
                              />
                            </StyledTableCell>
                          </TableRow>
                        );
                      })
                  : ''}
              </TableBody>
            </Table>
            {!returnOrderData.length && flag ? <NoDataFound /> : ''}
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            component="div"
            count={returnOrderData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Container>
      )}
    </>
  );
}
//#endregion
