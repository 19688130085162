import React, { useEffect, useState } from "react";
import FilterSection from "../../../../component/user/productList/FilterSection";
import ProductGrid from "../../../../component/user/productList/ProductGrid";
import UserIndex from "../../UserIndex";

const { FilterMaterialAndItemSaga } = UserIndex.UserSagaActions;

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <UserIndex.Box sx={{ p: 3 }}>
          <UserIndex.Typography>{children}</UserIndex.Typography>
        </UserIndex.Box>
      )}
    </div>
  );
}

const FilterPage = () => {
  const [value, setValue] = useState(0);
  const [page, setPage] = useState(2);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterData, setFilterData] = useState("");
  const [categoryData, setCategoryData] = useState("");


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const params = UserIndex.useParams();
  const dispatch = UserIndex.useDispatch();
  const history = UserIndex.useHistory();
  const navigate = UserIndex.useHistory();


  const loding = UserIndex.useSelector((state) => {
    return state.Usersaga.loading;
  });
  const FilterMaterialAndItemData = UserIndex.useSelector((state) => {
    return state.Usersaga.FilterMaterialAndItemData;
  });

  const Categorymenudata = UserIndex.useSelector((state) => {
    return state.Usersaga.Categorymenudata;
  });

  useEffect(() => {
    setCategoryData(Categorymenudata);
  }, [Categorymenudata]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getFilterData = () => {
    let urlencoded = new URLSearchParams();
    urlencoded.append("keyword", params.filter_text);
    dispatch(FilterMaterialAndItemSaga(urlencoded, navigate));
  };

  useEffect(() => {
    getFilterData();
  }, []);

  useEffect(() => {
    setFilterData(FilterMaterialAndItemData);
  }, [FilterMaterialAndItemData]);

  return (
    <div>
      <UserIndex.Header />
      <UserIndex.Box className="inner-hero-sec">
        <UserIndex.Box className="container">
          <UserIndex.Box className="hero-title-box">
            <UserIndex.Typography variant="h4" className="hero-inner-title">
              <span>{params.filter_text}</span>
            </UserIndex.Typography>
          </UserIndex.Box>
        </UserIndex.Box>
      </UserIndex.Box>
      <UserIndex.Box className="rings-section">
        <UserIndex.Box className="container">
          <UserIndex.Box>
            <UserIndex.Box
              display="grid"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 0, sm: 0, md: 4, lg: 4 }}
            >
              <UserIndex.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 3",
                  lg: "span 3",
                }}
              >
                <UserIndex.FilterSection />
              </UserIndex.Box>
              <UserIndex.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 9",
                  lg: "span 9",
                }}
              >
                <UserIndex.Box>
                  <TabPanel value={value} index={0} className="tabspanel">
                    <UserIndex.Box sx={{ width: 1 }}>
                      <UserIndex.Box
                        display="grid"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gap={{ xs: 3, sm: 5, md: 4, lg: 4 }}
                      >
                        {filterData?.length
                          ? filterData?.map((item, err) => {
                              return <UserIndex.ProductGrid item={item} />;
                            })
                          : "No Records Found"}
                      </UserIndex.Box>
                    </UserIndex.Box>
                  </TabPanel>
                </UserIndex.Box>

                <UserIndex.Box>
                  <UserIndex.Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={{ xs: 0, sm: 0, md: 0, lg: 5 }}
                  >
                    <UserIndex.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 12",
                        md: "span 12",
                        lg: "span 12",
                      }}
                    >
                      <UserIndex.Box className="pagination-box-mens-womens">
                        {filterData.length <= 10 ? (
                          ""
                        ) : (
                          <UserIndex.Pagination
                            // count={3}
                            variant="outlined"
                            shape="rounded"
                            className="pagination"
                            component="div"
                            count={100}
                            page={page}
                            onPageChange={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                          />
                        )}
                      </UserIndex.Box>
                    </UserIndex.Box>
                  </UserIndex.Box>
                </UserIndex.Box>
              </UserIndex.Box>
            </UserIndex.Box>
          </UserIndex.Box>
          {/* <UserIndex.Box>
            <UserIndex.Box
              display="grid"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 0, sm: 0, md: 4, lg: 4 }}
            >
              <UserIndex.Box
                gridColumn={{
                  xs: 'span 12',
                  sm: 'span 12',
                  md: 'span 9',
                  lg: 'span 9',
                }}
              >
                <UserIndex.Box>
                  <TabPanel value={value} index={0} className="tabspanel">
                    <UserIndex.Box sx={{ width: 1 }}>
                      <UserIndex.Box
                        display="grid"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gap={{ xs: 3, sm: 5, md: 4, lg: 4 }}
                      >
                        {filterData?.length
                          ? filterData?.map((item, err) => {
                              return <ProductGrid item={item} />
                            })
                          : 'No Records Found'}
                      </UserIndex.Box>
                    </UserIndex.Box>
                  </TabPanel>
                </UserIndex.Box>


                <UserIndex.Box>
                  <UserIndex.Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={{ xs: 0, sm: 0, md: 0, lg: 5 }}
                  >
                    <UserIndex.Box
                      gridColumn={{
                        xs: 'span 12',
                        sm: 'span 12',
                        md: 'span 12',
                        lg: 'span 12',
                      }}
                    >
                      <UserIndex.Box className="pagination-box-mens-womens">
                        {filterData.length <= 10 ? (
                          ''
                        ) : (
                          <UserIndex.Pagination
                            // count={3}
                            variant="outlined"
                            shape="rounded"
                            className="pagination"
                            component="div"
                            count={100}
                            page={page}
                            onPageChange={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                          />
                        )}
                      </UserIndex.Box>
                    </UserIndex.Box>
                  </UserIndex.Box>
                </UserIndex.Box>
              </UserIndex.Box>
            </UserIndex.Box>
          </UserIndex.Box> */}
        </UserIndex.Box>
      </UserIndex.Box>
      <UserIndex.Footer />
    </div>
  );
};

export default FilterPage;
