import React from "react";
import Mui from "../../../../component/customComponents/mui/Mui";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Api } from "../../../../config/api";
import dataService from "../../../../config/dataService";
import Png from "../../../../assets/png";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { ErrorMsg } from "../../../../component/customComponents/errorMsg/ErrorMsg";
import { useSelector, useDispatch } from "react-redux";
import actions from "../../../../redux/adminSaga/action";
import { CircularProgress } from "@mui/material";

const { otpVerifySaga } = actions;
//#region ALL HOOKS AND VARIABALS
const OtpVerify = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const navigate = useHistory();
  let adminData1 = JSON.parse(localStorage.getItem("admin-details"));

  const adminData = useSelector((state) => {
    return state.AdminSaga.forgotPasswordData;
  });

  const loading = useSelector((state) => {
    return state.AdminSaga.loading;
  });

  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    formState: { errors },
  } = useForm();
  //#endregion

  //#region ALL EVENT HANDLERS AND  FUCTIONS
  const onSubmit = async (data) => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("otp", data?.otp);
    urlencoded.append("id", adminData?._id);
    dispatch(otpVerifySaga(urlencoded, navigate));
    // await dataService.post(Api.Admin.verifyOtp, urlencoded).then((res) => {
    //   if (res.data.status === 1) {
    //     toast.success(`${res.data.message}`);
    //     history.push('/admin/resetpassword');
    //     reset();
    //   } else if (res.data.status === 0) {
    //     toast.error(`${res.data.message}`);
    //   }
    // });
  };
  //#endregion

  //#region RETURN JSX
  return (
    <Mui.Card
      variant="outlined"
      sx={{
        margin: "auto",
        marginTop: { sm: "10%", xs: "50%" },
        width: { sm: 500, xs: "100%" },
        maxWidth: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Mui.Box
        sx={{
          margin: "auto",
          width: { sm: 500, xs: "100%" },
          maxWidth: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Mui.Box>
            <img src={Png.footerlogo} className="login-zaverica-logo"></img>
          </Mui.Box>
          <h2 style={{ textAlign: "center" }}>Please Verify Otp</h2>
          <Mui.Box sx={{ display: "flex", justifyContent: "center" }}>
            <Mui.Box sx={{ width: "300px", py: 2 }}>
              <Controller
                name="otp"
                control={control}
                rules={{
                  required: <ErrorMsg msg="Otp is required" />,
                  minLength: {
                    value: 4,
                    message: <ErrorMsg msg="Min length is 4 characters" />,
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <Mui.MuiOtpInput
                    style={{ width: "300px" }}
                    length={4}
                    value={value}
                    onChange={onChange}
                    name="otp"
                  />
                )}
              />
              <ErrorMessage errors={errors} name="otp" />
            </Mui.Box>
          </Mui.Box>
          <Mui.Box sx={{ display: "flex", justifyContent: "center" }}>
            <Mui.Button
              sx={{ my: 2, width: "150px", textTransform: "none" }}
              variant="contained"
              type="submit"
              // onClick={(e) => handelSubmit(e)}
              // onMouseEnter={(e) => handelSubmit(e)}
              // onKeyDown={(e) => handelSubmit(e)}
            >
              Verify Otp
              {loading && (
                <CircularProgress sx={{ color: "white", ml: 1 }} size="20px" />
              )}
            </Mui.Button>
          </Mui.Box>
        </form>
      </Mui.Box>
    </Mui.Card>
  );
};
export default OtpVerify;
//#endregion
