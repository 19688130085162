const USER_NAME_REGEX = /^(([A-Za-z]*_)*(([A-Za-z*_]+\s)*[A-Za-z])){1,40}$/;
const PASSWORD_REGEX =
  /^(([A-Za-z0-9]*_)*(([A-Za-z0-9*_]+\s)*[A-Za-z0-9])){1,40}$/;
const CITY_REGEX =
  /^[a-zA-Z\u0080-\u024F]+(?:. |-| |')*([1-9a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$/;
const STATE_REGEX =
  /^[a-zA-Z\u0080-\u024F]+(?:. |-| |')*([1-9a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$/;
const ADDRESS_REGEX = /^[a-zA-Z0-9\s,'-]*$/;
const STRING = /^[a-zA-Z0-9\s,'-]*$/;
const CONTACT_NO_REGEX =
  /^((\+1)?[\s-]?)?\(?[1-9]\d\d\)?[\s-]?[1-9]\d\d[\s-]?\d\d\d\d/;

const EMAIL_REGEX =/^(?!.*\.\.)(?!.*--)(?!\.)[a-zA-Z0-9._-]+(?<![-.])@[a-zA-Z0-9-]+(?<!-)\.[a-zA-Z]{2,}$/
const ZIP_CODE_REGEX = /^([0-9]{6})(?:[-\s]*([0-9]{5}))?$/;
const IFSC_REGEX = /^[A-Z]{4}0[A-Z0-9]{6}$/;
const QUESTION_REGEX = /[a-zA-Z][a-zA-Z ][?]+/;
const MATERIAL_REGEX = /^(([A-Za-z]*_)*(([A-Za-z*_]+\s)*[A-Za-z])){1,12}$/;
const ITEM_REGEX = /^(([A-Za-z]*_)*(([A-Za-z*_]+\s)*[A-Za-z])){1,12}$/;
const CATEGORY_REGEX = /^(([A-Za-z]*_)*(([A-Za-z*_]+\s)*[A-Za-z])){1,12}$/;
const DATE_REGEX =
  /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;
const NO_WHITE_SPACE_BEGINNING_END = /^[^\s]+(\s+[^\s]+)*$/;
// const MAKING_CHARGES = /^(100(\.0{1,2})?|[1-9]?\d(\.\d{1,2})?)$/;
const MAKING_CHARGES = /^100(\.0{1,2})?%?$|^[0-9]{1,2}(\.[0-9]{1,2})?%?$/;
// const TCS_CHARGES = /^(100(\.0{1,2})?|[1-9]?\d(\.\d{1,2})?)$/;
const TCS_CHARGES = /^100(\.0{1,2})?%?$|^[0-9]{1,2}(\.[0-9]{1,2})?%?$/;
// const PURITY_PERCENTAGE_REGEX = /^(100(\.0{1,2})?|[1-9]?\d(\.\d{1,2})?)$/;
const PURITY_PERCENTAGE_REGEX =
  /^100(\.0{1,2})?%?$|^[0-9]{1,2}(\.[0-9]{1,2})?%?$/;
const NO_DECIMAL = /^[1-9]\d*$/;
const ALPHANUMRIC_REGEX = /^[A-Za-z0-9\s]+$/;
const ALPHABET_IS_MUST_REGEX = /^[a-zA-Z]+[a-zA-Z\s\d\W]*$/;
const QUANTITY_REGEX = /^(1[0-9]|[1-9]|20)$/;
const PURITY_IN_CARAT_REGEX = /^([1-9][0-9]?|100)(\.[0-9]{1,2})?$/;
const PRODUCT_WEIGHT_REGEX = /^([1-9][0-9]{0,7})$/;

// add product length
const minNoOfPiecesAvailableInStockLength = 1;
const maxNoOfPiecesAvailableInStockLength = 8;
const minSpecifyLength = 6;
const maxSpecifyLength = 60;
const minAdditionalDetailsLength = 10;
const maxAdditionalDetailsLength = 1500;

const minPurityLength = 1;
const maxPurityLength = 6;
const minPurityCaratLength = 1;
const maxPurityCaratLength = 4;
const minWeightLength = 1;
const maxWeightLength = 8;
const minOtherChargesLength = 1;
const maxOtherChargesLength = 8;

const minPieceLength = 1;
const maxPieceLength = 6;
const minQuantityLength = 1;
const maxQuantityLength = 2;
const minPricePerQuantityLength = 1;
const maxPricePerQuantityLength = 8;
const minSuppliersMakingChargesLength = 1;
const maxSuppliersMakingChargesLength = 8;

const minProductDimensionsInnerCircumferenceLength = 1;
const maxProductDimensionsInnerCircumferenceLength = 5;
const minProductDimensionsLengthsLength = 1;
const maxProductDimensionsLengthsLength = 5;
const minProductDimensionsDepthLength = 1;
const maxProductDimensionsDepthLength = 5;
const minProductDimensionsMetaTitleLength = 6;
const maxProductDimensionsMetaTitleLength = 30;
const minProductDimensionsMetaDescriptionLength = 10;
const maxProductDimensionsMetaDescriptionLength = 150;

// heading length
const minHeadingLength = 6;
const headingLength = 60;
const minDescriptionLength = 10;
const descriptionLength = 1500;
const minSubDescriptionLength = 10;
const subDescriptionLength = 250;

// top deals heading length
const minTopDealsHeadingLength = 3;
const topDealsHeadingLength = 15;
const minTopDealsSubHeadingLength = 3;
const topDealsSubHeadingLength = 18;

// Blogs heading length
const minBlogHeadingLength = 6;
const blogHeadingLength = 25;
const minBlogSubHeadingLength = 10;
const blogSubHeadingLength = 250;
const minBlogDescriptionLength = 10;
const blogDescriptionLength = 1500;

// material & item length
const minMaterialLength = 3;
const materialLength = 12;
const minItemLength = 3;
const itemLength = 12;

// bank details length
const minBankNameLength = 3;
const bankNameLength = 40;
const minAccountNameLength = 3;
const accountNameLength = 40;
const accountNumberLength = 12;
const ifscCodeLength = 11;

// customer services length
const minCustomerServiceHeadingLength = 6;
const customerServiceHeadingLength = 60;
const minCustomerServiceDescriptionLength = 10;
const customerServiceDescriptionLength = 1500;
const minQuestionLength = 16;
const questionLength = 250;
const minAnswerLength = 3;
const answerLength = 1500;

// product pridcing heading & Description length
const minPricingHeadingLength = 6;
const pricingHeadingLength = 60;
const minPricingDescriptionLength = 10;
const pricingDescriptionLength = 1500;

// about us heading & Description length
const minAboutUsHeadingLength = 6;
const aboutUsHeadingLength = 60;
const minAboutUsDescriptionLength = 10;
const aboutUsDescriptionLength = 1500;

// product details heading & Description length
const minDetailsHeadingLength = 6;
const detailsHeadingLength = 60;
const minDetailsDescriptionLength = 10;
const detailsDescriptionLength = 1500;

// guidelines heading & Description length
const minGuidelinesHeadingLength = 6;
const guidelinesHeadingLength = 60;
const minGuidelinesDescriptionLength = 10;
const guidelinesDescriptionLength = 1500;

// membership t and c heading & Description length
const minMembershipTAndCHeadingLength = 6;
const membershipTAndCHeadingLength = 60;

// membership content heading & Description length
const minMembershipContentHeadingLength = 6;
const membershipContentHeadingLength = 60;

// membership form filling guide heading & Description length
const minMembershipFFGHeadingLength = 6;
const membershipFFGHeadingLength = 60;

// making charges length
const minMakingChargesLength = 1;
const makingChargesLength = 6;

// tcs charges length
const minTcsChargesLength = 1;
const tcsChargesLength = 6;

// category length
const minCategoryNameLength = 3;
const categoryNameLength = 40;
const minCategoryDescriptionLength = 3;
const categoryDescriptionLength = 40;

// rejection reason length
const minRejectReasonLength = 3;
const rejectReasonLength = 40;

export {
  // length
  minRejectReasonLength,
  rejectReasonLength,
  headingLength,
  descriptionLength,
  minSubDescriptionLength,
  subDescriptionLength,
  minHeadingLength,
  minDescriptionLength,
  minMaterialLength,
  materialLength,
  minItemLength,
  itemLength,
  minCustomerServiceHeadingLength,
  customerServiceHeadingLength,
  minCustomerServiceDescriptionLength,
  customerServiceDescriptionLength,
  minQuestionLength,
  questionLength,
  minAnswerLength,
  answerLength,
  minPricingHeadingLength,
  pricingHeadingLength,
  minPricingDescriptionLength,
  pricingDescriptionLength,
  minDetailsHeadingLength,
  detailsHeadingLength,
  minDetailsDescriptionLength,
  detailsDescriptionLength,
  minGuidelinesHeadingLength,
  guidelinesHeadingLength,
  minGuidelinesDescriptionLength,
  guidelinesDescriptionLength,
  minBlogHeadingLength,
  blogHeadingLength,
  minBlogDescriptionLength,
  blogDescriptionLength,
  minBlogSubHeadingLength,
  blogSubHeadingLength,
  minBankNameLength,
  bankNameLength,
  minAccountNameLength,
  accountNameLength,
  accountNumberLength,
  ifscCodeLength,
  minMakingChargesLength,
  makingChargesLength,
  minTcsChargesLength,
  tcsChargesLength,
  minTopDealsHeadingLength,
  topDealsHeadingLength,
  minTopDealsSubHeadingLength,
  topDealsSubHeadingLength,
  minAboutUsHeadingLength,
  aboutUsHeadingLength,
  minAboutUsDescriptionLength,
  aboutUsDescriptionLength,
  minCategoryNameLength,
  categoryNameLength,
  minCategoryDescriptionLength,
  categoryDescriptionLength,
  minMembershipTAndCHeadingLength,
  membershipTAndCHeadingLength,
  minMembershipContentHeadingLength,
  membershipContentHeadingLength,
  minMembershipFFGHeadingLength,
  membershipFFGHeadingLength,
  minNoOfPiecesAvailableInStockLength,
  maxNoOfPiecesAvailableInStockLength,
  minSpecifyLength,
  maxSpecifyLength,
  minAdditionalDetailsLength,
  maxAdditionalDetailsLength,
  minPurityLength,
  maxPurityLength,
  minPurityCaratLength,
  maxPurityCaratLength,
  minWeightLength,
  maxWeightLength,
  minOtherChargesLength,
  maxOtherChargesLength,
  minPieceLength,
  maxPieceLength,
  minQuantityLength,
  maxQuantityLength,
  minPricePerQuantityLength,
  maxPricePerQuantityLength,
  minSuppliersMakingChargesLength,
  maxSuppliersMakingChargesLength,
  minProductDimensionsInnerCircumferenceLength,
  maxProductDimensionsInnerCircumferenceLength,
  minProductDimensionsLengthsLength,
  maxProductDimensionsLengthsLength,
  minProductDimensionsDepthLength,
  maxProductDimensionsDepthLength,
  minProductDimensionsMetaTitleLength,
  maxProductDimensionsMetaTitleLength,
  minProductDimensionsMetaDescriptionLength,
  maxProductDimensionsMetaDescriptionLength,

  // regEx
  USER_NAME_REGEX,
  ITEM_REGEX,
  MATERIAL_REGEX,
  CATEGORY_REGEX,
  QUESTION_REGEX,
  NO_WHITE_SPACE_BEGINNING_END,
  IFSC_REGEX,
  MAKING_CHARGES,
  TCS_CHARGES,
  NO_DECIMAL,
  ALPHANUMRIC_REGEX,
  ALPHABET_IS_MUST_REGEX,
  PURITY_PERCENTAGE_REGEX,
  EMAIL_REGEX,
  QUANTITY_REGEX,
  PURITY_IN_CARAT_REGEX,
  PRODUCT_WEIGHT_REGEX,
};

export const minLength = (value, lengthVal) => {
  if (value.length > lengthVal) {
    return true;
  }
};
export const maxLength = (value, lengthVal) => {
  if (value.length < lengthVal) {
    return true;
  }
};

export const noWhiteSpace = (string) => {
  let newStr = string.trim();
  if (newStr.charAt(0) !== ' ' && newStr?.charAt(newStr.length - 1)) {
    return true;
  }
};

export const regExValidation = {
  name(value) {
    return new RegExp(USER_NAME_REGEX).test(value);
  },
  password(value) {
    return new RegExp(PASSWORD_REGEX).test(value);
  },
  email(value) {
    return new RegExp(EMAIL_REGEX).test(value);
  },
  contactNo(value) {
    return new RegExp(CONTACT_NO_REGEX).test(value);
  },
  ifsc(value) {
    return new RegExp(IFSC_REGEX).test(value);
  },
  question(value) {
    return new RegExp(QUESTION_REGEX).test(value);
  },
  string(value) {
    return new RegExp(STRING).test(value);
  },
  date(value) {
    return new RegExp(DATE_REGEX).test(value);
  },
  // city(value) {
  //     return new RegExp(CITY_REGEX).test(value)
  // },
  // state(value) {
  //     return new RegExp(STATE_REGEX).test(value)
  // },
  // zipCode(value) {
  //     return new RegExp(ZIP_CODE_REGEX).test(value)
  // },
};
