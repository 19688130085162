import React, { useEffect, useState } from "react";
import UserIndex from "../../UserIndex";

const { ListProductPricingSaga } = UserIndex.UserSagaActions;

export default function Goldinfo() {
  const [productPricingData, setProductPricingData] = useState("");
  const params = UserIndex.useParams();
  const dispatch = UserIndex.useDispatch();
  const navigate = UserIndex.useHistory();
  const loding = UserIndex.useSelector((state) => {
    return state.Usersaga.loading;
  });
  const ListProductPricingData = UserIndex.useSelector((state) => {
    return state.Usersaga.ListProductPricingData;
  });

  const getProductPricingData = async () => {
    let urlencoded = new URLSearchParams();
    dispatch(ListProductPricingSaga(urlencoded, navigate));
  };

  // useEffect(() => {
  //   setProductPricingData(ListProductPricingData);
  // }, [ListProductPricingData]);

  useEffect(() => {
    getProductPricingData();
  }, []);

  useEffect(() => {
    let newcategory = ListProductPricingData?.filter((element) => {
      if (element.product_type == params.category) {
        return element;
      } else {
        return;
      }
    });
    setProductPricingData(newcategory?.[0]);
  }, [ListProductPricingData, params]);

  return (
    <div>
      <UserIndex.Header />
      {ListProductPricingData && ListProductPricingData.length ? (
        <>
          <UserIndex.Box
            className="goldinfo-sec"
            sx={{ backgroundImage: `url(${productPricingData?.image})` }}
          >
            <UserIndex.Box className="container">
              <UserIndex.Box className="goldinfo-banner-main">
                <UserIndex.Box className="faq-title-main customer-title-main gold-main">
                  <UserIndex.Typography className="faq-typo-custom" variant="h1">
                    {productPricingData?.product_type}
                  </UserIndex.Typography>
                  <UserIndex.Box className="img-side-yellow">
                    <img
                      src={UserIndex.png.banneryellow}
                      className="yellow-left-banner"
                    ></img>
                  </UserIndex.Box>
                </UserIndex.Box>
              </UserIndex.Box>
            </UserIndex.Box>
          </UserIndex.Box>
          <UserIndex.Box className="goldinfo-content-main">
            <UserIndex.Box className="container">
              <UserIndex.Box className="goldinfo-content">
                <UserIndex.Typography
                  variant="h6"
                  component="h6"
                  className="goldinfo-sub-text"
                >
                  {productPricingData?.heading}
                </UserIndex.Typography>

                <UserIndex.Typography
                  variantMapping="p"
                  component="p"
                  className="goldinfo-para mb-30"
                >
                  {productPricingData?.description}
                </UserIndex.Typography>
              </UserIndex.Box>
            </UserIndex.Box>
          </UserIndex.Box>
        </>
      ) : (
        <UserIndex.Typography className="view-cart-empty-message">No Record Found</UserIndex.Typography>
      )}

      <UserIndex.Footer />
    </div>
  );
}
