import React, { useEffect, useState } from 'react'
import UserIndex from '../../UserIndex'

const { ListBlogSaga } = UserIndex.UserSagaActions

export default function Blogs() {
  const [blogData, setBlogData] = useState('')
  const dispatch = UserIndex.useDispatch()
  const navigate = UserIndex.useHistory()
  const loding = UserIndex.useSelector((state) => {
    return state.Usersaga.loading
  })
  const ListBlogData = UserIndex.useSelector((state) => {
    return state.Usersaga.ListBlogData
  })

  const getListBlog = async () => {
    let urlencoded = new URLSearchParams()
    dispatch(ListBlogSaga(urlencoded, navigate))
  }

  useEffect(() => {
    getListBlog()
  }, [])

  useEffect(() => {
    setBlogData(ListBlogData)
  }, [ListBlogData])


  return (
    <div>
      <UserIndex.Header />

      <UserIndex.Box className="inner-hero-sec">
        <UserIndex.Box className="container">
          <UserIndex.Box className="hero-title-box">
            <UserIndex.Typography variant="h4" className="hero-inner-title">
              <span>How to use the website</span>
            </UserIndex.Typography>
          </UserIndex.Box>
        </UserIndex.Box>
      </UserIndex.Box>

      <UserIndex.Box className="website-blogs-main">
        <UserIndex.Box className="container">
          <UserIndex.Box className="website-titles">
            <UserIndex.Typography variant="h5" className="how-website-text">
              {blogData[0]?.heading}
            </UserIndex.Typography>
          </UserIndex.Box>
          {blogData?.[0]?.add_more?.length
            ? blogData?.[0]?.add_more?.map((data, i) => {
                return (
                  <UserIndex.Box className="website-inner-details">
                    <UserIndex.Box className="title-lorem-blog-main">
                      <UserIndex.Typography
                        variant="h4"
                        component="h4"
                        className="title-blog-details"
                      >
                        {data?.sub_heading}
                      </UserIndex.Typography>
                    </UserIndex.Box>
                    <UserIndex.Box className="desc-lorem-blog">
                      <UserIndex.Typography
                        variant="p"
                        component="p"
                        className="desc-blog-details"
                      >
                        {data.description}
                      </UserIndex.Typography>
                      {/* <UserIndex.Typography variant='p' component='p' className='desc-blog-details'> Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo.</UserIndex.Typography> */}
                    </UserIndex.Box>
                  </UserIndex.Box>
                )
              })
            : 'No Records Found'}

          {/* <UserIndex.Box className='website-inner-details'>
            <UserIndex.Box className='title-lorem-blog-main'>
              <UserIndex.Typography variant='h4' component='h4' className='title-blog-details'>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et </UserIndex.Typography>
            </UserIndex.Box>
            <UserIndex.Box className='desc-lorem-blog'>
              <UserIndex.Typography variant='p' component='p' className='desc-blog-details'> Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren,</UserIndex.Typography>

            </UserIndex.Box>
          </UserIndex.Box> */}
        </UserIndex.Box>
      </UserIndex.Box>
      <UserIndex.Footer />
    </div>
  )
}
