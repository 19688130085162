import React, { useEffect, useState } from "react";
import png from "../../../../assets/png";
import {
  Box,
  Typography,
  FormHelperText,
  CircularProgress,
  OutlinedInput,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import UserSagaActions from "../../../../redux/Usersaga/action";
import { styled } from "@mui/system";
import { useHistory } from "react-router-dom";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { ToastContainer, toast } from "react-toastify";
import { PrimaryButton } from "../../../../component/user/button/Button";
import { ConstructionOutlined } from "@mui/icons-material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const { ResetPasswordSaga ,updateModalSaga,stateEmpty} = UserSagaActions;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 631,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const StyledProgress = styled(CircularProgress)(() => ({
  position: "absolute",
  top: "6px",
  left: "25px",
}));

const UserForgotPassword = ({ Closemodel,backBtnType ,handleOpenSignup,OpenLoginModel}) => {
  const dispatch = useDispatch();
  const navigate = useHistory();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const [values, setValues] = useState({
    showPassword: false,
  });

  const ResetPasswordData = useSelector((state) => {
    return state.Usersaga.ResetPasswordData;
  });

  const ForgotPasswordData = useSelector((state) => {
    return state.Usersaga.ForgotPasswordData;
  });


  const [valuesConfirmPassword, setValuesConfirmPassword] = useState({
    showConfirmPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowConfirmPassword = () => {
    setValuesConfirmPassword({
      ...valuesConfirmPassword,
      showConfirmPassword: !valuesConfirmPassword.showConfirmPassword,
    });
  };

  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);
  };
  const handleConfirmPasswordValue = (e) => {
    setConfirmPassword(e.target.value);
  };
  const handleConfirmPassword = (e) => {
    // if (password == e.target.value) {
    //   setConfirmPasswordError("");
    // } else {
    //   setConfirmPasswordError("Does not match");
    // }
    // setConfirmPassword(e.target.value);
    // if (password != confirmPassword) {
    //   setConfirmPasswordError("Does not match");
    // } else {
    //   setConfirmPassword("");
    // }
    if (confirmPassword !== "") {
      if (password != confirmPassword) {
        setConfirmPasswordError("Confirm password doesn't match with password");
        return false;
      } else {
        setConfirmPasswordError("");
        return true;
      }
    }
  };

  const passworderrorHandling = () => {
    const digitVal = new RegExp(/(?=.*\d)/);
    const specVal = new RegExp(/(?=.*\W)/);
    const capitalVal = new RegExp(/(?=.*[A-Z])/);
    if (
      password === "" ||
      !digitVal.test(password) ||
      !specVal.test(password) ||
      !capitalVal.test(password)
    ) {
      setPasswordError(
        "Please enter minimun 8 characters, atleast 1 number, atleast 1 capital letter, and atleast 1 special character"
      );
      return false;
    } else {
      setPasswordError("");
      return true;
    }
  };

  const confirmPassworderrorHandling = () => {
    const digitVal = new RegExp(/(?=.*\d)/);
    const specVal = new RegExp(/(?=.*\W)/);
    const capitalVal = new RegExp(/(?=.*[A-Z])/);
    if (
      confirmPassword === "" ||
      !digitVal.test(confirmPassword) ||
      !specVal.test(confirmPassword) ||
      !capitalVal.test(confirmPassword)
    ) {
      // "Please enter minimun 8 characters, atleast 1 number, atleast 1 capital letter, and atleast 1 special character"

      setConfirmPasswordError("Please enter confirm password");
      return false;
    } else {
      // setPasswordError("");
      setConfirmPasswordError("");
      return true;
    }
  };

  const handleSubmit = async () => {
    if (
      (passworderrorHandling(),
      confirmPassworderrorHandling(),
      handleConfirmPassword())
    ) {
      if (password !== "" && confirmPasswordError == "") {

        let urlencoded = {
          newPass: password,
          email: ForgotPasswordData.email,
        };
        dispatch(ResetPasswordSaga(urlencoded, navigate));
        Closemodel(false);
      }
    }
  };

  return (
    <div>
      <ValidatorForm>
        <Box sx={style}>
        <Box className="login-close-icon">
              <CloseIcon
                onClick={() => {
                  Closemodel(false);
                  dispatch(updateModalSaga(navigate));
                }}
              />
            </Box>
          <Box className="modal-logo-box">
            <img src={png.footerlogo} className="modal-logo-img"></img>
          </Box>
          <Box className="modal-content">
            {/* <Typography
              className="modal-modal-title"
              variant="h6"
              component="h2"
            >
              Change Password
            </Typography> */}
              <h2 style={{ textAlign: "center" }}>Change Password</h2>
            <Box className="input-box">
              <FormHelperText className="input-lable">
                Password
                <span style={{ color: "red", fontSize: "15px" }}> * </span>
              </FormHelperText>
              <OutlinedInput
                type={values.showPassword ? "text" : "password"}
                fullWidth
                id="fullWidth"
                className="form-control border-none"
                name="password"
                value={password}
                inputProps={{ maxLength: 20 }}
                onChange={(e) => {
                  handlePassword(e);
                  // passworderrorHandling();
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                // validators={["required", "password"]}
                // errorMessages={["Please enter Password"]}
              />
              <Box className="sign-up-error-message">{passwordError}</Box>
            </Box>
            <Box className="input-box">
              <FormHelperText className="input-lable">
                Confirm Password
                <span style={{ color: "red", fontSize: "15px" }}> * </span>
              </FormHelperText>
              <OutlinedInput
                type={
                  valuesConfirmPassword.showConfirmPassword
                    ? "text"
                    : "password"
                }
                fullWidth
                id="fullWidth"
                className="form-control border-none"
                name="confirmPassword"
                value={confirmPassword}
                inputProps={{ maxLength: 20 }}
                onChange={(e) => {
                  // handleConfirmPassword(e);
                  handleConfirmPasswordValue(e);
                  // confirmPassworderrorHandling()
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseDownConfirmPassword}
                      edge="end"
                    >
                      {valuesConfirmPassword.showConfirmPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                // validators={["required"]}
                // errorMessages={["Please enter Password"]}
              />
              <Box className="sign-up-error-message">
                {confirmPasswordError}
              </Box>
            </Box>
            <Box className="modal-btn-box">
              <PrimaryButton
                btnclass="btn modal-btn"
                onClick={() => {
                  handleSubmit();
                }}
                buttonvalue="Submit"
              />
            </Box>
            {backBtnType ? (
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    cursor: "pointer",
                    marginTop: "7px",
                    color: "rgb(194, 153, 88)",
                    fontSize: "14px",
                  }}
                >
                  <Typography
                    variant="body1"
                    // component="p"
                    className="forgot_password"
                    onClick={() => {
                      OpenLoginModel(true);
                      Closemodel(false);
                      dispatch(updateModalSaga(navigate));
                      // handleCloseSignup();
                      dispatch(stateEmpty(navigate));
                    }}
                  >
                    Back to Sign In?
                  </Typography>
                </Box>
              </>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  cursor: "pointer",
                  marginTop: "7px",
                  color: "rgb(194, 153, 88)",
                  fontSize: "14px",
                }}
              >
                <Typography
                  variant="body1"
                  // component="p"
                  className="forgot_password"
                  onClick={() => {
                    handleOpenSignup(true);
                    Closemodel(false);
                    dispatch(updateModalSaga(navigate));
                    // handleCloseSignup();
                  }}
                >
                  Back to Sign Up?
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </ValidatorForm>
    </div>
  );
};

export default UserForgotPassword;
