import React, { useEffect, useState } from 'react';
import {
  Typography,
  Button,
  Box,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Container,
  TablePagination,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useHistory } from 'react-router-dom';
import { Api } from '../../../../config/api';
import dataService from '../../../../config/dataService';
import NoDataFound from '../../../../component/customComponents/noDataFound/NoDataFound';
import { toast, ToastContainer } from 'react-toastify';
import Loader from '../../../../component/customComponents/loader/Loader';
import { ConvertIntoTwoDigits } from '../../../../component/customComponents/commonFunctions/CommonFunctions';
import moment from 'moment';

import AdminIndex from '../../AdminIndex';

//#region ALL HOOKS AND VARIABALS
const columns = [
  { id: 'srno', label: 'Sr No' },
  { id: 'category', label: 'Category', align: 'left' },
  { id: 'totalproduct', label: 'Total Products', align: 'center' },
  { id: 'lastedited	', label: 'Last Edited	', align: 'left' },
  { id: 'action', label: 'Action', align: 'center' },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const { getAllCategoryList, getAllProduct, deleteCategory } = AdminIndex.AdminSagaActions;
export default function CategoryManagement() {
  let token = localStorage.getItem('auth');
  const history = useHistory();
  const dispatch = AdminIndex.useDispatch();
  // const [loading, setLoading] = useState(false);
  const [flag, setFlag] = useState(false);
  const [userAtgent, setUserAtgent] = useState([]);
  const [data, setData] = useState([]);
  const [goldCount, setGoldCount] = useState('');
  const [silverCount, setSilverCount] = useState('');
  const [diamondCount, setDiamondCount] = useState('');
  const [kundanCount, setKundanCount] = useState('');
  const [preciousCount, setPreciousCount] = useState('');
  const [mensCount, setMensCount] = useState('');
  const [womensCount, setWomensCount] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    categoryData();
    getInfo();
    setInterval(() => {
      setFlag(true);
    }, 500);
  }, []);

  useEffect(() => {
    categoryCount();
  }, [data]);

  //#endregion

  //#region ALL EVENT HANDLERS AND  FUCTIONS
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const categoryData = async () => {
    let formdata = new FormData();
    
    dispatch(getAllCategoryList(token,formdata));
    // await dataService
    //   .post(Api.Admin.getAllCategory, formdata)
    //   .then((res) => {
    //     setUserAtgent(res.data.data);
    //   })
    //   .catch((err) =>
    //     toast.error(err?.message || 'Something went wrong')
    //   )
    //   .finally(() => {
    //     setLoading(false);
    //   });
  };
  const loading = AdminIndex.useSelector((state) => {
    return state.AdminSaga.loading;
  });

  const categoryListData = AdminIndex.useSelector((state) => {
    return state.AdminSaga.categoryData;
  });

  useEffect(() => {
    setUserAtgent(categoryListData);
  }, [categoryListData]);

  const deleteSingleCategory = async (id) => {
    let urlencoded = new URLSearchParams();
    urlencoded.append('id', id);

    try {
      dispatch(deleteCategory(token,urlencoded));
      // const deleteCategory = await dataService.post(
      //   Api.Admin.deleteCategory,
      //   urlencoded,
      //   {
      //     headers: {
      //       auth: token,
      //     },
      //   }
      // );
      // if (deleteCategory.data.status == 1) {
      //   toast.success('Category deleted successfully');
      // }
    } catch (err) {
      toast.error(err?.message || 'Something went wrong');
    }
    categoryData();
  };

  const getInfo = async () => {
    const urlencoded = new URLSearchParams();

    try {
      dispatch(getAllProduct(token,urlencoded));
      // const Data = await dataService.get(Api.Admin.getAllProduct, urlencoded, {
      //   headers: {
      //     auth: token,
      //   },
      // });
      // setData(Data.data.data);
    } catch (err) {
      toast.error(err?.message || 'Something went wrong');
    }
  };

  const allProduct = AdminIndex.useSelector((state) => {
    return state.AdminSaga.allProduct;
  });

  useEffect(() => {
    setData(allProduct);
  }, [allProduct]);

  const categoryArray = data.map((val) => val?.category_id?.category_name);

  let goldCountVal = categoryArray.filter((val) =>
    val?.includes('Gold Jewellery' || 'gold Jewellery' || 'gold' || 'Gold')
  );
  let silverCountVal = categoryArray.filter((val) =>
    val?.includes(
      'Silver Jewellery' || 'silver Jewellery' || 'silver' || 'Silver'
    )
  );
  let diamondCountVal = categoryArray.filter((val) =>
    val?.includes(
      'Diamond Jewellery' || 'diamond Jewellery' || 'diamond' || 'Diamond'
    )
  );
  let preciousStoneCountVal = categoryArray.filter((val) =>
    val?.includes(
      'Precious Stone' || 'precious Stone' || 'Precious' || 'precious'
    )
  );
  let kundanCountVal = categoryArray.filter((val) =>
    val?.includes(
      'Kundan Jewellery' || 'kundan Jewellery' || 'Kundan' || 'kundan'
    )
  );
  let womensCountVal = categoryArray.filter((val) =>
    val?.includes('Women' || 'women')
  );
  let mensCountVal = categoryArray.filter((val) =>
    val?.includes('Men' || 'men')
  );

  const categoryCount = () => {
    if (goldCountVal.length >= 1) {
      setGoldCount(goldCountVal.length);
    } else {
      setGoldCount('0');
    }
    if (silverCountVal.length >= 1) {
      setSilverCount(silverCountVal.length);
    } else {
      setSilverCount('0');
    }
    if (diamondCountVal.length >= 1) {
      setDiamondCount(diamondCountVal.length);
    } else {
      setDiamondCount('0');
    }
    if (preciousStoneCountVal.length >= 1) {
      setPreciousCount(preciousStoneCountVal.length);
    } else {
      setPreciousCount('0');
    }
    if (kundanCountVal.length >= 1) {
      setKundanCount(kundanCountVal.length);
    } else {
      setKundanCount('0');
    }
    if (womensCountVal.length >= 1) {
      setWomensCount(womensCountVal.length);
    } else {
      setWomensCount('0');
    }
    if (mensCountVal.length >= 1) {
      setMensCount(mensCountVal.length);
    } else {
      setMensCount('0');
    }
  };

  // below functionality is used to count number of same values in an array
  // var map = categoryArray.reduce(function (prev, cur) {
  //   prev[cur] = (prev[cur] || 0) + 1;
  //   return prev;
  // }, {});
  //#endregion

  //#region RETURN JSX
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Container sx={{ mt: 2 }} maxWidth="false">
          <Typography variant="h4" align="center" sx={{ fontWeight: 'bold' }}>
            Category Management
          </Typography>
          <br />
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ flexGrow: 1 }} />
            <Button
              onClick={() => {
                history.push('/admin/categorymanagement/addcategory');
              }}
              variant="contained"
              sx={{
                borderRadius: '6px',
                margin: '0px 15px 0px 0px',
                height: '28px',
                background: '#3198F9 0% 0% no-repeat padding-box',
                borderRadius: 0,
                px: 5,
                py: 3,
                textTransform: 'none',
              }}
            >
              Add Category
            </Button>
          </Box>
          <br />
          <TableContainer
            elevation={0}
            sx={{
              maxWidth: {
                xs: '365px',
                sm: '600px',
                md: '100%',
                lg: '100%',
              },
              '&::-webkit-scrollbar': {
                width: 10,
              },
              '&::-webkit-scrollbar': {
                height: '8px',
                width: '12px',
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: '#fff',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(224, 224, 224, 1)',
                borderRadius: '30px',
                width: '20px',
              },
              '&::-webkit-scrollbar-thumb:hover': {
                background: '#B0B0B0',
              },
            }}
            component={Paper}
          >
            <Table
              sx={{
                width: {
                  xs: 'max-content',
                  sm: 'max-content',
                  md: '100%',
                  lg: '100%',
                },
              }}
              size="small"
            >
              <TableHead>
                <TableRow sx={{ bgcolor: 'black' }}>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth, bgcolor: 'red' }}
                      className="pagination-table-header-row"
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {userAtgent.length
                  ? userAtgent
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row._id}
                            sx={{
                              '&:last-child td, &:last-child th': { border: 0 },
                            }}
                          >
                            <StyledTableCell align="left">
                              {index + 1}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row.category_name}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row.category_name.includes('Gold' || 'gold')
                                ? ConvertIntoTwoDigits(goldCount)
                                : row.category_name.includes(
                                    'Silver' || 'silver'
                                  )
                                ? ConvertIntoTwoDigits(silverCount)
                                : row.category_name.includes(
                                    'Diamond' || 'diamond'
                                  )
                                ? ConvertIntoTwoDigits(diamondCount)
                                : row.category_name.includes(
                                    'Kundan' || 'kundan'
                                  )
                                ? ConvertIntoTwoDigits(kundanCount)
                                : row.category_name.includes('Women' || 'women')
                                ? ConvertIntoTwoDigits(womensCount)
                                : row.category_name.includes(
                                    'Precious' || 'precious'
                                  )
                                ? ConvertIntoTwoDigits(preciousCount)
                                : row.category_name.includes('Men' || 'men')
                                ? ConvertIntoTwoDigits(mensCount)
                                : 'No data'}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {/* {new Date(row.updatedAt).toLocaleDateString()} */}
                              {moment(row.updatedAt).format('DD/MM/YYYY')}
                            </StyledTableCell>
                            {/* <StyledTableCell align="left">
                        {row.catalogue}
                      </StyledTableCell>
                      <StyledTableCell
                        align="left"
                        sx={
                          row.status == 'active'
                            ? { color: 'green' }
                            : { color: 'red' }
                        }
                      >
                        {row.status}
                      </StyledTableCell> */}
                            <StyledTableCell
                              align="center"
                              sx={{ whiteSpace: 'nowrap' }}
                            >
                              <Button>
                                <EditIcon
                                  sx={{ m: 1 }}
                                  onClick={() => {
                                    history.push(
                                      `/admin/categorymanagement/updatecategory/${row._id}`
                                    );
                                  }}
                                />
                              </Button>
                              <Button>
                                <DeleteIcon
                                  sx={{ m: 1, color: 'red' }}
                                  onClick={() => {
                                    if (
                                      window.confirm(
                                        'Are you sure you want to delete this Category?'
                                      )
                                    ) {
                                      deleteSingleCategory(row._id);
                                    }
                                  }}
                                />
                              </Button>
                            </StyledTableCell>
                          </TableRow>
                        );
                      })
                  : ''}
              </TableBody>
            </Table>
            {!userAtgent.length && flag ? <NoDataFound /> : ''}
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            component="div"
            count={userAtgent.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Container>
      )}
    </>
  );
}
//#endregion
