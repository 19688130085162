import actions from "./action";
import { toast } from "react-toastify";

const initialState = {
  auth: "",
  loading: false,
  islogin: false,
  adminData: [],
  forgotPasswordData: [],
  otpVerifyData: [],
  resetPasswordData: [],
  dynamicCount: [],
  userList: [],
  userDetail: {},
  allProduct: [],
  allCustomProduct: [],
  productDetail: {},
  customProductDetail: {},
  itemData: [],
  materialData: [],
  categoryData: [],
  orders: [],
  orderDetail: {},
  orderRatings: [],
  returnOrders: [],
  returnOrderDetail: {},
  returnOrders: [],
  categoryDetail: {},
  materialDetail: {},
  itemDetail: {},
  refundChartData: [],
  revenueChartData: [],
  chartData: [],
  bankDetail: {},
  makingChargeDetail: [],
  notificationDetail: {},
  customerServices: [],
  allCMS: [],
  termCondition: {},
  returnPolicy:{},
  privacyPolicy:{},
  faqs: [],
  FAQDetail: {},
  blogList: [],
  guidelineList: [],
  topDeals: [],
  singleDeal: {},
  benefitList: [],
  productDescription: [],
  singleProductDescription: {},
  productPricing: [],
  singleProductPricing: {},
  productPurity: [],
  singleProductPurity: {},
  termConditionList: [],
  listFormFillGuideline: [],
};

export default function AdminSaga(state = initialState, action) {
  switch (action.type) {
    case actions.ADMIN_LOGOUT:
      toast.success("Logged out successfully");
      localStorage.removeItem("auth");
      // localStorage.removeItem('admin-details');
      return initialState;
    case actions.ADMIN_ERROR:
      toast.error(action.message);
      return {
        ...state,
        loading: false,
      };
    case actions.ADMIN_LODER_ON:
      return {
        ...state,
        loading: true,
      };
    case actions.ADMIN_LOGIN_SUCCESS:
      toast.success("Logged in Successfully ");
      localStorage.setItem("auth", action.data[0].auth);
      localStorage.setItem("admin-details", JSON.stringify(action.data));
      return {
        ...state,
        adminData: action.data,
        auth: action.data[0].auth,
        islogin: true,
        loading: false,
      };
    case actions.ADMIN_FORGOT_PASSWORD_SUCCESS:
      toast.success("Reset password mail sent");
      return {
        ...state,
        forgotPasswordData: action.data,
        loading: false,
      };
    case actions.ADMIN_OTP_VERIFY_SUCCESS:
      toast.success("Email verified");
      return {
        ...state,
        otpVerifyData: action.data,
        loading: false,
      };
    case actions.ADMIN_RESET_PASSWORD_SUCCESS:
      toast.success("Password has been reset");
      return {
        ...state,
        resetPasswordData: action.data,
        loading: false,
      };
    case actions.GET_DYNAMIC_COUNT_SUCCESS:
      return {
        ...state,
        dynamicCount: action.data,
        loading: false,
      };
    case actions.GET_USER_LIST_SUCCESS:
      return {
        ...state,
        userList: action.data,
        loading: false,
      };
    case actions.GET_USER_DETAIL_SUCCESS:
      return {
        ...state,
        userDetail: action.data,
        loading: false,
      };
    case actions.DELETE_USER_SUCCESS:
      toast.success("User deleted successfully");
      return {
        ...state,
        userList: action.data,
        loading: false,
      };
    case actions.GET_ALL_PRODUCT_SUCCESS:
      return {
        ...state,
        allProduct: action.data,
        loading: false,
      };
    case actions.GET_ALL_CUSTOM_PRODUCT_SUCCESS:
      return {
        ...state,
        allCustomProduct: action.data,
        loading: false,
      };
    case actions.GET_PRODUCT_DETAIL_SUCCESS:
      return {
        ...state,
        productDetail: action.data,
        loading: false,
      };
    case actions.PRODUCT_APPROVE_SUCCESS:
      toast.success("Product accepted successfully");
      return {
        ...state,
        loading: false,
      };
    case actions.PRODUCT_REJECT_SUCCESS:
      toast.success("Product rejected successfully");
      return {
        ...state,
        loading: false,
      };
    case actions.PRODUCT_DELETE_SUCCESS:
      toast.success("Product deleted successfully");
      return {
        ...state,
        loading: false,
      };
    case actions.GET_ITEM_DATA_SUCCESS:
      return {
        ...state,
        itemData: action.data,
        loading: false,
      };
    case actions.GET_MATERIAL_LIST_SUCCESS:
      return {
        ...state,
        materialData: action.data,
        loading: false,
      };
    case actions.GET_CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        categoryData: action.data,
        loading: false,
      };
    case actions.UPDATE_PRODUCT_SUCCESS:
      toast.success("Product updated successfully");
      return {
        ...state,
        loading: false,
      };
    case actions.APPROVE_CUSTOM_PRODUCT_SUCCESS:
      toast.success("Custom Product accepted successfully");
      return {
        ...state,
        loading: false,
      };
    case actions.CUSTOM_PRODUCT_REJECT_SUCCESS:
      toast.success("Custom Product rejected successfully");
      return {
        ...state,
        loading: false,
      };
    case actions.CUSTOM_PRODUCT_DETAIL_SUCCESS:
      return {
        ...state,
        customProductDetail: action.data,
        loading: false,
      };
    case actions.UPDATE_CUSTOM_PRODUCT_SUCCESS:
      toast.success("Custom product updated successfully");
      return {
        ...state,
        loading: false,
      };
    case actions.GET_ALL_ORDERS_SUCCESS:
      return {
        ...state,
        orders: action.data,
        loading: false,
      };
    case actions.GET_ORDER_DETAIL_SUCCESS:
      return {
        ...state,
        orderDetail: action.data,
        loading: false,
      };
    case actions.APPROVE_ORDER_SUCCESS:
      toast.success("Order accepted successfully");
      return {
        ...state,
        loading: false,
      };
    case actions.REJECT_ORDER_SUCCESS:
      toast.success("Order rejected successfully");
      return {
        ...state,
        loading: false,
      };
    case actions.ORDER_RATING_SUCCESS:
      return {
        ...state,
        orderRatings: action.data,
        loading: false,
      };
    case actions.ORDER_RATING_UPDATE_SUCCESS:
      toast.success(action.data.message);
      return {
        ...state,
        loading: false,
      };
    case actions.ORDER_STATUS_UPDATE_SUCCESS:
      toast.success("Order Item Status updated successfully");
      return {
        ...state,
        loading: false,
      };
    case actions.GET_ALL_RETURN_ORDERS_SUCCESS:
      return {
        ...state,
        returnOrders: action.data,
        loading: false,
      };
    case actions.GET_RETURN_ORDER_DETAIL_SUCCESS:
      return {
        ...state,
        returnOrderDetail: action.data,
        loading: false,
      };
    case actions.ACCEPT_RETURN_ORDER_SUCCESS:
      toast.success("Return order request Accepted successfully");
      return {
        ...state,
        loading: false,
      };
    case actions.REJECT_RETURN_ORDER_SUCCESS:
      toast.success("Return order request Rejected successfully");
      return {
        ...state,
        loading: false,
      };
    case actions.UPDATE_ORDER_TRANSACTION_ID_SUCCESS:
      toast.success("Transaction Id updated successfully");
      return {
        ...state,
        loading: false,
      };
    case actions.DELETE_CATEGORY_SUCCESS:
      toast.success("Category deleted successfully");
      return {
        ...state,
        loading: false,
      };
    case actions.ADD_CATEGORY_SUCCESS:
      toast.success("Category added successfully");
      return {
        ...state,
        loading: false,
      };
    case actions.GET_SINGLE_CATEGORY_SUCCESS:
      return {
        ...state,
        categoryDetail: action.data,
        loading: false,
      };
    case actions.UPDATE_CATEGORY_SUCCESS:
      toast.success("Category updated successfully");
      return {
        ...state,
        loading: false,
      };
    case actions.DELETE_MATERIAL_SUCCESS:
      toast.success("Material deleted successfully");
      return {
        ...state,
        loading: false,
      };
    case actions.ADD_MATERIAL_SUCCESS:
      toast.success("Material added successfully");
      return {
        ...state,
        loading: false,
      };
    case actions.GET_SINGLE_MATERIAL_SUCCESS:
      return {
        ...state,
        materialDetail: action.data,
        loading: false,
      };
    case actions.EDIT_MATERIAL_SUCCESS:
      toast.success("Material updated successfully");
      return {
        ...state,
        loading: false,
      };
    case actions.DELETE_ITEM_SUCCESS:
      toast.success("Item deleted successfully");
      return {
        ...state,
        loading: false,
      };
    case actions.ADD_ITEM_SUCCESS:
      toast.success("Item added successfully");
      return {
        ...state,
        loading: false,
      };
    case actions.GET_SINGLE_ITEM_SUCCESS:
      return {
        ...state,
        itemDetail: action.data,
        loading: false,
      };
    case actions.EDIT_ITEM_SUCCESS:
      toast.success("Item updated successfully");
      return {
        ...state,
        loading: false,
      };
    case actions.GET_PAYMENT_LIST_SUCCESS:
      return {
        ...state,
        paymentData: action.data,
        loading: false,
      };
    case actions.GET_REFUND_CHART_DATA_REQUEST:
      return {
        ...state,
        refundChartData: action.data,
        loading: false,
      };
    case actions.GET_REVENUE_CHART_DATA_SUCCESS:
      return {
        ...state,
        revenueChartData: action.data,
        loading: false,
      };
    case actions.GET_CHART_DATA_SUCCESS:
      return {
        ...state,
        chartData: action.data,
        loading: false,
      };
    case actions.GET_BANK_DETAILS_SUCCESS:
      return {
        ...state,
        bankDetail: action.data,
        loading: false,
      };
    case actions.GET_MAKING_CHARGE_SUCCESS:
      return {
        ...state,
        makingChargeDetail: action.data,
        loading: false,
      };
    case actions.GET_NOTIFICATION_DETAIL_SUCCESS:
      return {
        ...state,
        notificationDetail: action.data,
        loading: false,
      };
    case actions.GET_CUSTOMER_SERVICEID_SUCCESS:
      return {
        ...state,
        customerServices: action.data,
        loading: false,
      };
    case actions.GET_ALL_CMS_SUCCESS:
      return {
        ...state,
        allCMS: action.data,
        loading: false,
      };
    case actions.GET_TERM_CONDITION_SUCCESS:
      return {
        ...state,
        termCondition: action.data,
        loading: false,
      };
    case actions.UPDATE_TERM_CONDITION_SUCCESS:
      toast.success("Terms & Conditions updated successfully");
      return {
        ...state,
        loading: false,
      };
    case actions.GET_PRIVACY_POLICY_SUCCESS:
      return {
        ...state,
        privacyPolicy: action.data,
        loading: false,
      };
    case actions.UPDATE_PRIVACY_POLICY_SUCCESS:
      toast.success("Privacy & Policy updated successfully");
      return {
        ...state,
        loading: false,
      };
    case actions.GET_RETURN_POLICY_SUCCESS:
      return {
        ...state,
        returnPolicy: action.data,
        loading: false,
      };
    case actions.UPDATE_RETURN_POLICY_SUCCESS:
      toast.success("Return policy updated successfully");
      return {
        ...state,
        loading: false,
      };
    case actions.GET_FAQ_LIST_SUCCESS:
      return {
        ...state,
        faqs: action.data,
        loading: false,
      };
    case actions.DELETE_FAQ_SUCCESS:
      toast.success("FAQ deleted successfully");
      return {
        ...state,
        loading: false,
      };
    case actions.ADD_FAQ_SUCCESS:
      toast.success("FAQ added successfully");
      return {
        ...state,
        loading: false,
      };
    case actions.GET_FAQ_DETAIL_SUCCESS:
      return {
        ...state,
        FAQDetail: action.data,
        loading: false,
      };
    case actions.UPDATE_FAQ_SUCCESS:
      toast.success("FAQ updated successfully");
      return {
        ...state,
        loading: false,
      };
    case actions.GET_BLOG_LIST_SUCCESS:
      return {
        ...state,
        blogList: action.data,
        loading: false,
      };
    case actions.GET_GUIDELINE_LIST_SUCCESS:
      return {
        ...state,
        guidelineList: action.data,
        loading: false,
      };
    case actions.UPDATE_BLOG_SUCCESS:
      toast.success("Blog updated successfully");
      return {
        ...state,
        loading: false,
      };
    case actions.UPDATE_CUSTOMER_SERVICE_SUCCESS:
      toast.success("Customer Services updated successfully");
      return {
        ...state,
        loading: false,
      };
    case actions.GET_DEAL_LIST_SUCCESS:
      return {
        ...state,
        topDeals: action.data,
        loading: false,
      };
    case actions.DELETE_DEAL_SUCCESS:
      toast.success("Deal deleted successfully");
      return {
        ...state,
        loading: false,
      };
    case actions.ADD_DEAL_SUCCESS:
      toast.success("Deal added successfully");
      return {
        ...state,
        loading: false,
      };
    case actions.GET_SINGLE_DEAL_SUCCESS:
      return {
        ...state,
        singleDeal: action.data,
        loading: false,
      };
    case actions.UPDATE_DEAL_SUCCESS:
      toast.success("Deal updated successfully");
      return {
        ...state,
        loading: false,
      };
    case actions.GET_BENEFIT_LIST_SUCCESS:
      return {
        ...state,
        benefitList: action.data.data,
        termConditionList: action.data.listTermsCondition,
        listFormFillGuideline: action.data.listFormFillGuideline,
        loading: false,
      };
    case actions.ADD_BENEFIT_SUCCESS:
      toast.success("Membership content updated successfully");
      return {
        ...state,
        loading: false,
      };
    case actions.ADD_GUIDELINE_SUCCESS:
      toast.success("Guidelines added successfully");
      return {
        ...state,
        loading: false,
      };
    case actions.LIST_PRODUCT_DESCRIPTION_SUCCESS:
      return {
        ...state,
        productDescription: action.data,
        loading: false,
      };
    case actions.ADD_PRODUCT_DESCRIPTION_SUCCESS:
      toast.success("Product details added successfully");
      return {
        ...state,
        loading: false,
      };
    case actions.DELETE_PRODUCT_DESCRIPTION_SUCCESS:
      toast.success("Product description deleted successfully");
      return {
        ...state,
        loading: false,
      };
    case actions.SINGLE_PRODUCT_DESCRIPTION_SUCCESS:
      return {
        ...state,
        singleProductDescription: action.data,
        loading: false,
      };
    case actions.UPDATE_PRODUCT_DESCRIPTION_SUCCESS:
      toast.success("Product details updated successfully");
      return {
        ...state,
        loading: false,
      };
    case actions.LIST_PRODUCT_PRICING_SUCCESS:
      return {
        ...state,
        productPricing: action.data,
        loading: false,
      };
    case actions.DELETE_PRODUCT_PRICING_SUCCESS:
      toast.success("Product pricing deleted successfully");
      return {
        ...state,
        loading: false,
      };
    case actions.ADD_PRODUCT_PRICING_SUCCESS:
      toast.success("Product pricing added successfully");
      return {
        ...state,
        loading: false,
      };
    case actions.UPDATE_PRODUCT_PRICING_SUCCESS:
      toast.success("Product pricing updated successfully");
      return {
        ...state,
        loading: false,
      };
    case actions.SINGLE_PRODUCT_PRICING_SUCCESS:
      return {
        ...state,
        singleProductPricing: action.data,
        loading: false,
      };
    case actions.LIST_PRODUCT_PURITY_SUCCESS:
      return {
        ...state,
        productPurity: action.data,
        loading: false,
      };
    case actions.DELETE_PRODUCT_PURITY_SUCCESS:
      toast.success("Product purity deleted successfully");
      return {
        ...state,
        loading: false,
      };
    case actions.ADD_PRODUCT_PURITY_REQUEST:
      toast.success("Product Purity added successfully");
      return {
        ...state,
        loading: false,
      };
    case actions.UPDATE_PRODUCT_PURITY_REQUEST:
      toast.success("Product Purity updated successfully");
      return {
        ...state,
        loading: false,
      };
    case actions.SINGLE_PRODUCT_PURITY_SUCCESS:
      return {
        ...state,
        singleProductPurity: action.data,
        loading: false,
      };
    case actions.UPDATE_BLOG_TERM_CONDITION_SUCCESS:
      toast.success("Terms and Conditions updated successfully");
      return {
        ...state,
        loading: false,
      };
    case actions.UPDATE_FILL_FORM_GUIDELINE_SUCCESS:
      toast.success("Fill form guideline updated successfully");
      return {
        ...state,
        loading: false,
      };
    case actions.UPDATE_MEMBERSHIP_CONTENT_SUCCESS:
      toast.success("Membership content updated successfully");
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
