import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import {
  Typography,
  Container,
  Card,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Divider,
  Button,
} from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Logo from '../../../../assets/images/bulbul-ahmed-QFWGnLHnBNQ-unsplash.jpg';
import Image1 from '../../../../assets/images/alex-azabache-y2ErhoE92KA-unsplash.jpg';
import Image2 from '../../../../assets/images/atul-vinayak-jKvwtbrxzdY-unsplash.jpg';
import ColumnChart from './columnChart';
import AreaChart from './areaChart';
import { Api } from '../../../../config/api';
import dataService from '../../../../config/dataService';
import { toast } from 'react-toastify';
import Loader from '../../../../component/customComponents/loader/Loader';
import { ConvertIntoTwoDigits } from '../../../../component/customComponents/commonFunctions/CommonFunctions';

import AdminIndex from '../../AdminIndex';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

//#region ALL HOOKS AND VARIABALS
const StyledCard = styled(Card)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'left',
  justifyContent: 'left',
  padding: '25px',
  width: '100%',
  border: '1px solid',
  borderRadius: '0px',
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const carousel = (
  <Container
    zeroMinWidth
    sx={{
      width: { xs: '330px', md: '350px' },
      height: { xs: 'auto', md: '200px' },
    }}
  >
    <OwlCarousel
      sx={{
        zIndex: 0,
        display: 'gird',
        justifyContent: 'center',
      }}
      className="owl-theme"
      margin={1}
      nav
      items="1"
      dots={false}
      autoplay
      loop
    >
      <img zIndex={0} height="200px" width="100%" src={Logo} />

      <img height="200px" width="100%" src={Image1} />

      <img height="200px" width="100%" src={Image2} />
    </OwlCarousel>
  </Container>
);

const { getDynamicCount } = AdminIndex.AdminSagaActions;

export default function DashBoard() {
  const dispatch = AdminIndex.useDispatch();
  let isToken = localStorage.getItem('auth');
  let token = localStorage.getItem('auth');
  // const [loading, setLoading] = useState(false);
  const [productData, setProductData] = useState([]);
  isToken =
    isToken == '' || isToken == null || isToken == undefined ? false : true;

  function createData1(date, buyer, seller, product, bidprice) {
    return { date, buyer, seller, product, bidprice };
  }

  const getProductData = async () => {
    dispatch(getDynamicCount(token));
  };

  useEffect(() => {
    getProductData();
  }, []);

  const loading = AdminIndex.useSelector((state) => {
    return state.AdminSaga.loading;
  });
  
  const dynamicCount = AdminIndex.useSelector((state) => {
    return state.AdminSaga.dynamicCount;
  });

  useEffect(() => {
    setProductData(dynamicCount);
  }, [dynamicCount]);

  const rows1 = [
    createData1('24-04-2022', 'Name', 'Name', 'Name', '0000'),
    createData1('24-04-2022', 'Name', 'Name', 'Name', '0000'),
    createData1('24-04-2022', 'Name', 'Name', 'Name', '0000'),
    createData1('24-04-2022', 'Name', 'Name', 'Name', '0000'),
  ];

  let supportBox = (
    <Box sx={{ display: 'flex', m: 1, border: 1, p: 2 }}>
      <Typography alignSelf="center">Support request From User</Typography>
      <Box sx={{ flexGrow: { xs: 0.5, md: 1, lg: 1 } }} />
      <Button variant="contained" sx={{ textTransform: 'none' }}>
        View
      </Button>
    </Box>
  );

  let boxData = [
    supportBox,
    supportBox,
    supportBox,
    supportBox,
    supportBox,
    supportBox,
    supportBox,
    supportBox,
    supportBox,
    supportBox,
    supportBox,
  ];

  let itemList = boxData.map((item, index) => {
    return <Box key={index}>{item}</Box>;
  });

  const options = {
    items: 1,
    loop: true,
    margin: 0,
    nav: true,
    dots: false,
    stagePadding: 0,
  };
  //#endregion

  //#region ALL EVENT HANDLERS AND  FUCTIONS
  //#endregion

  //#region RETURN JSX
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Container
            sx={{
              mt: 2,
              //  borderBottom: 1,
              p: 3,
            }}
            maxWidth="false"
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={4} lg={4} sm={6}>
                <StyledCard
                  sx={{ backgroundColor: '#f5f5f5', minHeight: '140px' }}
                >
                  <Grid container>
                    <Grid item lg={6}>
                      Total Products{' '}
                    </Grid>
                    <Grid item lg={1}>
                      :
                    </Grid>
                    <Grid item lg={5}>
                      <strong>
                        {productData?.productCount
                          ? ConvertIntoTwoDigits(productData?.productCount)
                          : '00'}
                      </strong>
                    </Grid>
                    <Grid item lg={6}>
                      Approved Products
                    </Grid>
                    <Grid item lg={1}>
                      :
                    </Grid>
                    <Grid item lg={5}>
                      <strong>
                        {productData?.uploadedProduct?.[0]?.uploadedProductCount
                          ? ConvertIntoTwoDigits(
                              productData?.uploadedProduct?.[0]
                                ?.uploadedProductCount
                            )
                          : '00'}
                      </strong>
                    </Grid>
                    <Grid item lg={6}>
                      Pending Products
                    </Grid>
                    <Grid item lg={1}>
                      :
                    </Grid>
                    <Grid item lg={5}>
                      <strong>
                        {productData?.pendingProduct?.[0]?.pendingProductCount
                          ? ConvertIntoTwoDigits(
                              productData?.pendingProduct?.[0]
                                ?.pendingProductCount
                            )
                          : '00'}
                      </strong>
                    </Grid>
                    <Grid item lg={6}>
                      Rejected Products
                    </Grid>
                    <Grid item lg={1}>
                      :
                    </Grid>
                    <Grid item lg={5}>
                      <strong>
                        {productData?.rajectedProduct?.[0]?.rejectedProductCount
                          ? ConvertIntoTwoDigits(
                              productData?.rajectedProduct?.[0]
                                ?.rejectedProductCount
                            )
                          : '00'}
                      </strong>
                    </Grid>
                  </Grid>
                </StyledCard>
              </Grid>

              <Grid item xs={12} md={4} lg={4} sm={6}>
                <StyledCard
                  sx={{ backgroundColor: '#f5f5f5', minHeight: '148px' }}
                >
                  <Grid container>
                    <Grid item lg={6}>
                      Total Orders{' '}
                    </Grid>
                    <Grid item lg={1}>
                      :
                    </Grid>
                    <Grid item lg={5}>
                      <strong>
                        {productData?.orderCount
                          ? ConvertIntoTwoDigits(productData?.orderCount)
                          : '00'}
                      </strong>
                    </Grid>
                    <Grid item lg={6}>
                      Close Orders
                    </Grid>
                    <Grid item lg={1}>
                      :
                    </Grid>
                    <Grid item lg={5}>
                      <strong>
                        {productData?.closedOrders?.[0]?.closedOrderCount
                          ? ConvertIntoTwoDigits(
                              productData?.closedOrders?.[0]?.closedOrderCount
                            )
                          : '00'}
                      </strong>
                    </Grid>
                    <Grid item lg={6}>
                      Open Orders
                    </Grid>
                    <Grid item lg={1}>
                      :
                    </Grid>
                    <Grid item lg={5}>
                      <strong>
                        {productData?.openOrders?.[0]?.openOrderCount
                          ? ConvertIntoTwoDigits(
                              productData?.openOrders?.[0]?.openOrderCount
                            )
                          : '00'}
                      </strong>
                    </Grid>
                  </Grid>
                </StyledCard>
              </Grid>

              <Grid item xs={12} md={4} lg={4} sm={6}>
                <StyledCard sx={{ backgroundColor: '#f5f5f5' }}>
                  <Grid container>
                    <Grid item lg={6}>
                      Total Users{' '}
                    </Grid>
                    <Grid item lg={1}>
                      :
                    </Grid>
                    <Grid item lg={5}>
                      <strong>
                        {productData?.userCount
                          ? ConvertIntoTwoDigits(productData?.userCount)
                          : '00'}
                      </strong>
                    </Grid>
                    <Grid item lg={6}>
                      Approved Users
                    </Grid>
                    <Grid item lg={1}>
                      :
                    </Grid>
                    <Grid item lg={5}>
                      <strong>
                        {productData?.acceptedUsers?.[0]?.acceptedUsersCount
                          ? ConvertIntoTwoDigits(
                              productData?.acceptedUsers?.[0]
                                ?.acceptedUsersCount
                            )
                          : '00'}
                      </strong>
                    </Grid>
                    <Grid item lg={6}>
                      Pending Users
                    </Grid>
                    <Grid item lg={1}>
                      :
                    </Grid>
                    <Grid item lg={5}>
                      <strong>
                        {productData?.pendingUsers?.[0]?.pendingUsersCount
                          ? ConvertIntoTwoDigits(
                              productData?.pendingUsers?.[0]?.pendingUsersCount
                            )
                          : '00'}
                      </strong>
                    </Grid>
                    <Grid item lg={6}>
                      Rejected Users
                    </Grid>
                    <Grid item lg={1}>
                      :
                    </Grid>
                    <Grid item lg={5}>
                      <strong>
                        {productData?.rejectedUsers?.[0]?.rejectedUsersCount
                          ? ConvertIntoTwoDigits(
                              productData?.rejectedUsers?.[0]
                                ?.rejectedUsersCount
                            )
                          : '00'}
                      </strong>
                    </Grid>
                  </Grid>
                </StyledCard>
              </Grid>
              <Grid item xs={12} md={4} lg={4} sm={6}>
                <StyledCard sx={{ backgroundColor: '#f5f5f5' }}>
                  <Grid container>
                    <Grid item lg={6}>
                      Total Returns{' '}
                    </Grid>
                    <Grid item lg={1}>
                      :
                    </Grid>
                    <Grid item lg={5}>
                      <strong>
                        {productData?.returnOrderCount
                          ? ConvertIntoTwoDigits(productData?.returnOrderCount)
                          : '00'}
                      </strong>
                    </Grid>
                    <Grid item lg={6}>
                      Approved Returns
                    </Grid>
                    <Grid item lg={1}>
                      :
                    </Grid>
                    <Grid item lg={5}>
                      <strong>
                        {productData?.acceptedReturnOrder?.[0]
                          ?.acceptedReturnOrderCount
                          ? ConvertIntoTwoDigits(
                              productData?.acceptedReturnOrder?.[0]
                                ?.acceptedReturnOrderCount
                            )
                          : '00'}
                      </strong>
                    </Grid>
                    <Grid item lg={6}>
                      Pending Returns
                    </Grid>
                    <Grid item lg={1}>
                      :
                    </Grid>
                    <Grid item lg={5}>
                      <strong>
                        {productData?.pendingReturnOrder?.[0]
                          ?.pendingReturnOrderCount
                          ? ConvertIntoTwoDigits(
                              productData?.pendingReturnOrder?.[0]
                                ?.pendingReturnOrderCount
                            )
                          : '00'}
                      </strong>
                    </Grid>
                    <Grid item lg={6}>
                      Rejected Returns
                    </Grid>
                    <Grid item lg={1}>
                      :
                    </Grid>
                    <Grid item lg={5}>
                      <strong>
                        {productData?.rejectedReturnOrder?.[0]
                          ?.rejectedReturnOrderCount
                          ? ConvertIntoTwoDigits(
                              productData?.rejectedReturnOrder?.[0]
                                ?.rejectedReturnOrderCount
                            )
                          : '00'}
                      </strong>
                    </Grid>
                  </Grid>
                </StyledCard>
              </Grid>

              <Grid item xs={12} md={4} lg={4} sm={6}>
                <StyledCard
                  sx={{ backgroundColor: '#f5f5f5', minHeight: '148px' }}
                >
                  <Grid container>
                    <Grid item lg={10}>
                      Total Custom Products Orders
                    </Grid>
                    <Grid item lg={1}>
                      :
                    </Grid>
                    <Grid item lg={1}>
                      <strong>
                        {productData?.customProductOrder?.[0]
                          ?.customProductOrderCount
                          ? ConvertIntoTwoDigits(
                              productData?.customProductOrder?.[0]
                                ?.customProductOrderCount
                            )
                          : '00'}
                      </strong>
                    </Grid>
                    <Grid item lg={10}>
                      Closed Custom Products Orders
                    </Grid>
                    <Grid item lg={1}>
                      :
                    </Grid>
                    <Grid item lg={1}>
                      <strong>
                        {productData?.closedCustomOrders?.[0]
                          ?.closedCustomOrdersCount
                          ? ConvertIntoTwoDigits(
                              productData?.closedCustomOrders?.[0]
                                ?.closedCustomOrdersCount
                            )
                          : '00'}
                      </strong>
                    </Grid>
                    <Grid item lg={10}>
                      Open Custom Products Orders
                    </Grid>
                    <Grid item lg={1}>
                      :
                    </Grid>
                    <Grid item lg={1}>
                      <strong>
                        {productData?.openCustomOrders?.[0]
                          ?.openCustomOrderCount
                          ? ConvertIntoTwoDigits(
                              productData?.openCustomOrders?.[0]
                                ?.openCustomOrderCount
                            )
                          : '00'}
                      </strong>
                    </Grid>
                  </Grid>
                </StyledCard>
              </Grid>
              <Grid item xs={12} md={4} lg={4} sm={6}>
                <StyledCard
                  sx={{ backgroundColor: '#f5f5f5', minHeight: '148px' }}
                >
                  <Grid container>
                    <Grid item lg={10}>
                      Total Custom Requests
                    </Grid>
                    <Grid item lg={1}>
                      :
                    </Grid>
                    <Grid item lg={1}>
                      <strong>
                        {productData?.customRequestCount
                          ? ConvertIntoTwoDigits(
                              productData?.customRequestCount
                            )
                          : '00'}
                      </strong>
                    </Grid>
                    <Grid item lg={10}>
                      Accepted Requests
                    </Grid>
                    <Grid item lg={1}>
                      :
                    </Grid>
                    <Grid item lg={1}>
                      <strong>
                        {productData?.acceptedCustomeRequest?.[0]
                          ?.acceptedCustomeRequestCount
                          ? ConvertIntoTwoDigits(
                              productData?.acceptedCustomeRequest?.[0]
                                ?.acceptedCustomeRequestCount
                            )
                          : '00'}
                      </strong>
                    </Grid>
                    <Grid item lg={10}>
                      Requests Pending for Order
                    </Grid>
                    <Grid item lg={1}>
                      :
                    </Grid>
                    <Grid item lg={1}>
                      <strong>
                        {productData?.requestsPendingForOrder?.[0]
                          ?.requestsPendingForOrderCount
                          ? ConvertIntoTwoDigits(
                              productData?.requestsPendingForOrder?.[0]
                                ?.requestsPendingForOrderCount
                            )
                          : '00'}
                      </strong>
                    </Grid>
                  </Grid>
                </StyledCard>
              </Grid>
              <Grid item xs={12} md={4} lg={4} sm={6}>
                <StyledCard
                  sx={{ backgroundColor: '#f5f5f5', minHeight: '148px' }}
                >
                  <Grid container>
                    <Grid item lg={9}>
                      Total Pending Payments
                    </Grid>
                    <Grid item lg={1}>
                      :
                    </Grid>
                    <Grid item lg={2}>
                      <strong>-</strong>
                    </Grid>
                    <Grid item lg={9}>
                      Pending Payments to Seller
                    </Grid>
                    <Grid item lg={1}>
                      :
                    </Grid>
                    <Grid item lg={2}>
                      <strong>-</strong>
                    </Grid>
                    <Grid item lg={9}>
                      Pending Refunds
                    </Grid>
                    <Grid item lg={1}>
                      :
                    </Grid>
                    <Grid item lg={2}>
                      <strong>-</strong>
                    </Grid>
                  </Grid>
                </StyledCard>
              </Grid>
              <Grid item xs={12} md={4} lg={4} sm={6}>
                <StyledCard
                  sx={{ backgroundColor: '#f5f5f5', minHeight: '148px' }}
                >
                  <Typography sx={{ mt: 2 }} align="center" variant="p">
                    Total Income
                  </Typography>
                  <Typography sx={{ mb: 2 }} align="center" variant="p">
                    <strong>-</strong>
                  </Typography>
                </StyledCard>
              </Grid>
            </Grid>
          </Container>
          <br />
          <br />
          {/* <Container sx={{ mt: 2, borderBottom: 1, p: 2 }} maxWidth="false">
            <Grid Container>
              <Grid container spacing={2}>
                <Grid
                  sx={{ borderRight: { xs: 0, lg: 1 } }}
                  item
                  xs={12}
                  md={6}
                  lg={6}
                  sm={12}
                >
                  <Typography align="center" variant="h4">
                    Product
                  </Typography>
                  <ColumnChart />
                </Grid>
                <Grid item xs={12} md={6} lg={6} sm={12}>
                  <Typography align="center" variant="h4">
                    Sales
                  </Typography>
                  <AreaChart />
                </Grid>
              </Grid>
            </Grid>
          </Container> */}
          <br />
          <br />
          {/* <Container maxWidth="false">
        <Typography sx={{ fontWeight: 'bold' }} variant="h4" align="center">
          Bid Product
        </Typography>
      </Container>
      <Container sx={{ mt: 2, borderBottom: 1, p: 4 }} maxWidth="false">
        <TableContainer
          elevation={0}
          sx={{
            maxWidth: {
              xs: '335px',
              sm: '600px',
              md: '100%',
              lg: '100%',
            },
            '&::-webkit-scrollbar': {
              width: 10,
            },
            '&::-webkit-scrollbar': {
              height: '8px',
              width: '12px',
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: '#fff',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(224, 224, 224, 1)',
              borderRadius: '30px',
              width: '20px',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              background: '#B0B0B0',
            },
          }}
          component={Paper}
        >
          <Table
            sx={{
              width: {
                xs: 'max-content',
                sm: 'max-content',
                md: '100%',
                lg: '100%',
              },
            }}
            size="small"
          >
            <TableHead>
              <StyledTableRow>
                <StyledTableCell align="left">Date</StyledTableCell>
                <StyledTableCell align="left">Buyer</StyledTableCell>
                <StyledTableCell align="left">Seller</StyledTableCell>
                <StyledTableCell align="left">Product</StyledTableCell>
                <StyledTableCell align="left" colSpan={2}>
                  Bid Price
                </StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {rows1.map((row) => (
                <StyledTableRow key={row.name}>
                  <StyledTableCell align="left">{row.date}</StyledTableCell>
                  <StyledTableCell align="left">{row.buyer}</StyledTableCell>
                  <StyledTableCell align="left">{row.seller}</StyledTableCell>
                  <StyledTableCell align="left">{row.product}</StyledTableCell>
                  <StyledTableCell align="left">{row.bidprice}</StyledTableCell>
                  <StyledTableCell align="center">
                    <Button variant="contained" sx={{ textTransform: 'none' }}>
                      View
                    </Button>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container> */}
          {/* <Container sx={{ mt: 2, p: 2 }} maxWidth="false">
            <Grid justifyItems="center" container spacing={2}>
              <Grid
                sx={{ borderRight: { xs: 0, lg: 1 }, p: { xs: 0, lg: 2 } }}
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
              >
                <Typography
                  sx={{ my: 1, mb: 3, fontWeight: 'bold' }}
                  align="center"
                  variant="h5"
                >
                  Most Ordered Catalogue
                </Typography>
                {carousel}

                <Typography sx={{ mt: 5, mb: 1 }} align="center" variant="h5">
                  Catalouge Name
                </Typography>
                <Divider sx={{ borderColor: 'black', mt: 8 }}></Divider>
                <Typography
                  sx={{ mt: 8, mb: 3, fontWeight: 'bold' }}
                  align="center"
                  variant="h5"
                >
                  Top Seller
                </Typography>
                {carousel}
                <Typography sx={{ mt: 5, mb: 1 }} align="center" variant="h5">
                  {' '}
                  Seller Name
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Box sx={{ flexDirection: 'column' }}>
                  <Typography
                    sx={{ my: 1, fontWeight: 'bold', p: 1 }}
                    align="center"
                    variant="h5"
                  >
                    Support
                  </Typography>
                  {itemList}
                </Box>
              </Grid>
            </Grid>
          </Container> */}
        </>
      )}
    </>
  );
}
//#endregion
