import React, { useState } from "react";
import Mui from "../../../../component/customComponents/mui/Mui";
import MuiIcon from "../../../../component/customComponents/mui/MuiIcon";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Png from "../../../../assets/png";
import { useSelector, useDispatch } from "react-redux";
import actions from "../../../../redux/adminSaga/action";
import { regExValidation } from "../../../../component/customComponents/validation/Validation";
import { LoadingButton } from "@mui/lab";
import { CircularProgress, FormHelperText } from "@mui/material";
//#region ALL HOOKS AND VARIABALS

const { loginSaga } = actions;

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useHistory();
  const history = useHistory();
  const [user, setUser] = useState({
    email: "",
    password: "",
  });
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });

  const loading = useSelector((state) => {
    return state.AdminSaga.loading;
  });
  //#endregion

  //#region ALL EVENT HANDLERS AND  FUCTIONS

  const emailValidation = () => {
    if (user.email == "") {
      setEmailError("Please enter email");
    } else if (!regExValidation.email(user.email)) {
      setEmailError("Please enter valid email");
    } else {
      setEmailError("");
    }
  };

  const passwordValidation = () => {
    if (user.password == "") {
      setPasswordError("Please enter password");
    } else {
      setPasswordError("");
    }
  };

  const handelSubmit = async (e) => {
    e.preventDefault();
    emailValidation();
    passwordValidation();

    try {
      if (user.email != "" && user.password != "") {
        const urlencoded = new URLSearchParams();
        urlencoded.append("email", user.email.trim());
        urlencoded.append("password", user.password.trim());
        dispatch(loginSaga(urlencoded, navigate));
      }
    } catch (err) {
      toast.error(err?.message || "Something went wrong");
    }
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  //#endregion

  //#region RETURN JSX
  return (
    <Mui.Card
      variant="outlined"
      sx={{
        margin: "auto",
        marginTop: { sm: "10%", xs: "50%" },
        width: { sm: 500, xs: "100%" },
        maxWidth: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Mui.Box
        sx={{
          margin: "auto",
          width: { sm: 500, xs: "100%" },
          maxWidth: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Mui.Box>
          <img src={Png.footerlogo} className="login-zaverica-logo"></img>
        </Mui.Box>
        <h1 style={{ textAlign: "center" }}>Login</h1>
        <Mui.Box className="abx">
          <Mui.Box sx={{ textAlign: "center" }}>
            <FormHelperText
              sx={{ margin: "auto", fontSize: "15px" }}
              className="login-title"
            >
              Email
            </FormHelperText>
            <Mui.TextField
              autoComplete="off"
              sx={{
                margin: "auto",
                // marginTop: 2,
                width: { sm: "70%", xs: "85%" },
              }}
              // required
              fullWidth
              // id="Email"
              value={user.email}
              placeholder="Enter email"
              className="light-blank-placeholder"
              onChange={(e) => {
                setUser({ ...user, email: e.target.value });
                emailValidation(e);
              }}
              onKeyUp={(e) => emailValidation(e)}
            />
            <Mui.FormHelperText
              sx={{ color: "red", marginLeft: "15% !important" }}
            >
              {emailError}
            </Mui.FormHelperText>
          </Mui.Box>

          <Mui.Box
            sx={{
              textAlign: "center",
              marginTop: 2,
            }}
          >
            <FormHelperText
              sx={{ margin: "auto", fontSize: "15px" }}
              className="login-title"
            >
              Password
            </FormHelperText>
            <Mui.FormControl
              variant="outlined"
              sx={{
                margin: "auto",
                // marginTop: 2,
                width: { sm: "70%", xs: "85%" },
              }}
            >
              {/* <Mui.InputLabel htmlFor="outlined-adornment-password">
                Password
              </Mui.InputLabel> */}
              <Mui.OutlinedInput
                autoComplete="off"
                // required
                fullWidth
                value={user.password}
                placeholder="Enter password"
                className="light-blank-placeholder"
                onChange={(e) => {
                  setUser({ ...user, password: e.target.value });
                  passwordValidation(e);
                }}
                onKeyUp={(e) => passwordValidation(e)}
                type={values.showPassword ? "text" : "password"}
                endAdornment={
                  <Mui.InputAdornment position="end">
                    <Mui.IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? (
                        <MuiIcon.Visibility />
                      ) : (
                        <MuiIcon.VisibilityOff />
                      )}
                    </Mui.IconButton>
                  </Mui.InputAdornment>
                }
                label=""
              />
            </Mui.FormControl>
          </Mui.Box>
          <Mui.FormHelperText
            sx={{ color: "red", marginLeft: "15% !important" }}
          >
            {passwordError}
          </Mui.FormHelperText>
        </Mui.Box>
        <Mui.Box
          sx={{
            width: "425px",
            display: "flex",
            justifyContent: "flex-end",
            py: 2,
          }}
        >
          <Link
            style={{ color: "cornflowerblue" }}
            to="/admin/forgotpassword"
            underline="none"
            // className="admin-forgot-para common-para"
          >
            Forgot Password?
          </Link>
        </Mui.Box>
        <Mui.Box sx={{ display: "flex", justifyContent: "center" }}>
          <Mui.Button
            sx={{ my: 2, width: "150px", textTransform: "none" }}
            variant="contained"
            onClick={(e) => handelSubmit(e)}
            // onMouseEnter={(e) => handelSubmit(e)}
            // onKeyDown={(e) => handelSubmit(e)}
          >
            Submit
            {loading && (
              <CircularProgress sx={{ color: "white", ml: 1 }} size="20px" />
            )}
          </Mui.Button>
        </Mui.Box>
      </Mui.Box>
    </Mui.Card>
  );
};
export default Login;
//#endregion
