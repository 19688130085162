import React, { useEffect, useState } from "react";
import UserIndex from "../../UserIndex";

const { GetAllCmsSaga } = UserIndex.UserSagaActions;

export default function TermsConditions() {


    const dispatch = UserIndex.useDispatch();
    const history = UserIndex.useHistory();
    const navigate = UserIndex.useHistory();
    const loding = UserIndex.useSelector((state) => {
        return state.Usersaga.loading;
    });
    const FaqData = UserIndex.useSelector((state) => {
        return state.Usersaga.GetAllCmsData;
    });
    const getFaq = async () => {
        let urlencoded = new URLSearchParams();
        dispatch(GetAllCmsSaga(urlencoded, navigate));
    };

    useEffect(() => {
        getFaq();
    }, []);



    return (
        <div>
            <UserIndex.Header />
            <UserIndex.Box
                className="faq-main-banner"
                sx={{
                    background:
                        `linear-gradient(45deg,rgb(34 34 34 / 76%), rgb(34 34 34 / 76%)),url(${UserIndex.png?.faqBanner})`
                }}
            >
                <UserIndex.Box className="container">
                    <UserIndex.Box className="faq-inner-content">
                        {/* <UserIndex.Typography className="lot-text">
                            A LOT MORE QUESTIONS
                        </UserIndex.Typography> */}
                        <UserIndex.Box className="faq-title-main">
                            <UserIndex.Typography className="faq-typo-custom" variant="h1">
                                {" "}
                                Terms & Conditions
                            </UserIndex.Typography>
                            <UserIndex.Box className="img-side-yellow">
                                <img
                                    src={UserIndex.png.banneryellow}
                                    className="yellow-left-banner"
                                ></img>
                            </UserIndex.Box>
                        </UserIndex.Box>
                    </UserIndex.Box>
                </UserIndex.Box>
            </UserIndex.Box>

            <UserIndex.Box className="faq-question-main">
                <UserIndex.Box className="container">
                    <UserIndex.Box className="title-faq-asked">
                        <UserIndex.Typography className="frequently-text">
                        {FaqData?.heading_terms_condition}
                        </UserIndex.Typography>
                    </UserIndex.Box>
                    <UserIndex.Box className="faq-accrodions">
                        <UserIndex.Box className="details-content-return-policy">
                            <UserIndex.Typography className="details-content-faq-inner">
                                {FaqData?.terms_condition}
                            </UserIndex.Typography>
                        </UserIndex.Box>
                    </UserIndex.Box>
                </UserIndex.Box>
            </UserIndex.Box>
            <UserIndex.Footer />
        </div>
    );
}
