const USER = 'user';
const ADMIN = 'admin';
const Api = {
  Admin: {
    loginAdmin: `loginAdmin`,
    createAdmin: `createAdmin`,
    getAdmin: `getAdmin`,
    addCategory: `addCategory`,
    getAllCategory: `getAllCategory`,
    updateCategory: `updateCategory`,
    getCategory: `getCategory`,
    getUserList: `getUserList`,
    userDetails: `userDetails`,
    deleteCategory: `deleteCategory`,
    addSubCategory: `addSubCategory`,
    createProduct: `createProduct`,
    getAllProduct: `getAllProduct`,
    getProduct: `getProduct`,
    deleteProduct: `deleteProduct`,
    userCreateProduct: `userCreateProduct`,
    productRejected: `productRejected`,
    productApprove: `productApprove`,
    userProductUpdate: `userProductUpdate`,
    updateProduct: `updateProduct`,
    deleteProduct: `deleteProduct`,
    deleteUser: 'deleteUser',
    listMaterial: 'listMaterial',
    PaymentDetails: 'PaymentDetails',
    singlePayment: 'singlePayment',
    addMaterial: 'addMaterial',
    updateMaterial: 'updateMaterial',
    deleteMaterial: 'deleteMaterial',
    singleMaterial: 'singleMaterial',
    addItem: 'addItem',
    updateItem: 'updateItem',
    listItem: 'listItem',
    deleteItem: 'deleteItem',
    singleItem: 'singleItem',
    approveUser: 'approveUser',
    listItem: 'listItem',
    listMaterial: 'listMaterial',
    createTermsAndCondition: 'createTermsAndCondition',
    getAllCms: 'getAllCms',
    getallorder: 'getallorder',
    viewOrderAdmin: 'viewOrderAdmin',
    approveOrder: 'approveOrder',
rejectOrder: 'rejectOrder',
    addFaqs: 'addFaqs',
    listFaqs: 'listFaqs',
    deleteFaqs: 'deleteFaqs',
    singleFaqs: 'singleFaqs',
    updateFaqs: 'updateFaqs',
    getCms: 'getCms',
    recievedCustomProductAdmin: 'recievedCustomProductAdmin',
    getSingleCustomProductAdmin: 'getSingleCustomProductAdmin',
    listPayment: 'listPayment',
    updateOrderstatus: 'updateOrderstatus',
    getAllRating: 'getAllRating',
    addUpdateRatingAdmin: 'addUpdateRatingAdmin',
    singleRating: 'singleRating',
    approveCustomProductAdmin: 'approveCustomProductAdmin',
    rejectedCustomProductAdmin: 'rejectedCustomProductAdmin',
    getSingleCustomProductAdmin: 'getSingleCustomProductAdmin',
    updateCustomProductAdmin: 'updateCustomProductAdmin',
    getAllReturnOrderAdmin: 'getAllReturnOrderAdmin',
    getSingleReturnOrderAdmin: 'getSingleReturnOrderAdmin',
    rejectRetrunOrder: 'rejectRetrunOrder',
    approveRetrunOrder: 'approveRetrunOrder',
    rejectRetrunOrder: 'rejectRetrunOrder',
    updateTransactionStatus: 'updateTransactionStatus',
    addBlog: 'addBlog',
    updateBlog: 'updateBlog',
    listBlog: 'listBlog',
    singleBlog: 'singleBlog',
    deleteBlog: 'deleteBlog',
    addGuideline: 'addGuideline',
    updateGuideline: 'updateGuideline',
    listGuideline: 'listGuideline',
    singleguideline: 'singleguideline',
    deleteguideline: 'deleteguideline',
    addProductDescription: 'addProductDescription',
    updateProductDescription: 'updateProductDescription',
    listProductDescription: 'listProductDescription',
    singleProductDescription: 'singleProductDescription',
    deleteProductDescription: 'deleteProductDescription',
    addProductPricing: 'addProductPricing',
    updateProductPricing: 'updateProductPricing',
    listProductPricing: 'listProductPricing',
    singleProductPricing: 'singleProductPricing',
    deleteProductPricing: 'deleteProductPricing',
    addProductPurity: 'addProductPurity',
    updateProductPurity: 'updateProductPurity',
    singleProductPurity: 'singleProductPurity',
    listProductPurity: 'listProductPurity',
    deleteProductPurity: 'deleteProductPurity',
    addBankDetails: 'addBankDetails',
    singleBankDetails: 'singleBankDetails',
    listBankDetails: 'listBankDetails',
    updateBankDetails: 'updateBankDetails',
    addZavericaMakingCharges: 'addZavericaMakingCharges',
    updateZavericaMakingCharges: 'updateZavericaMakingCharges',
    listZavericaMakingCharges: 'listZavericaMakingCharges',
    singleZavericaMakingCharges: 'singleZavericaMakingCharges',
    addcustomerService: 'addcustomerService',
    listCustomerServices: 'listCustomerServices',
    singleCustomerServices: 'singleCustomerServices',
    updateCustomerServices: 'updateCustomerServices',
    addTopDeals: 'addTopDeals',
    updateTopDeals: 'updateTopDeals',
    listTopDeals: 'listTopDeals',
    singleTopDeal: 'singleTopDeal',
    deleteTopDeal: 'deleteTopDeal',
    addBenefits: 'addBenefits',
    listBenefits: 'listBenefits',
    singleBenefits: 'singleBenefits',
    templatePurchaseOrderPdf: 'templatePurchaseOrderPdf',
    adminToSellerInvoice: 'adminToSellerInvoice',
    downloadSellerInvoice: 'downloadSellerInvoice',
    getDynamicCount: 'getDynamicCount',
    addNotification: 'addNotification',
    updateNotification: 'updateNotification',
    notificationDetails: 'notificationDetails',
    listNotificationDetails: 'listNotificationDetails',
    chartDetails: 'chartDetails',
    chartDetailsForRefund: 'chartDetailsForRefund',
    totalRevenueGenerated: 'totalRevenueGenerated',
    forgotPassword: `${ADMIN}/forgotPassword`,
    verifyOtp: `${ADMIN}/verifyOtp`,
    resetPassword: `${ADMIN}/resetPassword`,
  },
  User: {
    signUp: `${USER}/signUp`,
    signIn: `${USER}/signIn`,
    Login: 'login',
    getAllProductUserside: `${USER}/getAllProductUserside`,
    getUserProduct: `${USER}/getUserProduct`,
    getProduct: `${USER}/getProduct`,
    getCategoryMenu: `${USER}/getCategoryMenu`,
    contactUs: `${USER}/addContactUs`,
  },
};

export { Api };
