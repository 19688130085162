import { all, takeEvery, put, fork, call } from "redux-saga/effects"; // You can import select
import actions from "./action";

import {
  forgotPasswordApiHandler,
  loginApiHandler,
  otpVerifyApiHandler,
  resetPasswordApiHandler,
  getDynamicCountApiHandler,
  getUserListApiHandler,
  getUserDetailApiHandler,
  deleteUserApiHandler,
  getPendingProductApiHandler,
  getProductDetailApiHandler,
  approveProductApiHandler,
  rejectProductApiHandler,
  deleteProductApiHandler,
  getItemApiHandler,
  getMaterialDataApiHandler,
  getCategoryDataApiHandler,
  updateProductApiHandler,
  approveCustomProductApiHandler,
  rejectCustomProductApiHandler,
  getCustomProductDetailApiHandler,
  updateCustomProductApiHandler,
  getAllOrdersApiHandler,
  getOrderDetailApiHandler,
  approveOrderApiHandler,
  rejectOrderApiHandler,
  getOrderRatingApiHandler,
  updateOrderRatingApiHandler,
  updateOrderStatusApiHandler,
  getAllReturnOrdersApiHandler,
  getReturnOrderDetailApiHandler,
  acceptReturnOrderApiHandler,
  rejectReturnOrderApiHandler,
  updateOrderTransactionIdApiHandler,
  deleteCategoryApiHandler,
  addCategoryApiHandler,
  getSingleCategoryApiHandler,
  updateCategoryApiHandler,
  deleteMaterialApiHandler,
  addMaterialApiHandler,
  getSingleMaterialApiHandler,
  updateMaterialApiHandler,
  deleteItemApiHandler,
  addItemApiHandler,
  getSingleItemApiHandler,
  updateItemApiHandler,
  paymentListApiHandler,
  refundChartDataApiHandler,
  revenueChartDataApiHandler,
  chartDataApiHandler,
  getBankDetailApiHandler,
  getMakingChargeApiHandler,
  getNotificationDetailApiHandler,
  getCustomerServicesListApiHandler,
  getAllCMSApiHandler,
  getTermConditionApiHandler,
  updateTermConditionApiHandler,
  getFAQListApiHandler,
  deleteFAQApiHandler,
  addFAQApiHandler,
  getFAQDetailApiHandler,
  updateFAQApiHandler,
  getBlogListApiHandler,
  getGuidelineListApiHandler,
  updateBlogApiHandler,
  updateCustomerServiceApiHandler,
  getDealListApiHandler,
  deleteDealApiHandler,
  addDealApiHandler,
  getSingleDealApiHandler,
  updateDealApiHandler,
  getBenefitListApiHandler,
  addBenefitApiHandler,
  addGuidelineApiHandler,
  getProductDescriptionListApiHandler,
  addProductDescriptionApiHandler,
  deleteProductDescriptionApiHandler,
  getSingleProductDescriptionApiHandler,
  updateProductDescriptionApiHandler,
  getProductPricingApiHandler,
  deleteProductPricingApiHandler,
  addProductPricingApiHandler,
  updateProductPricingApiHandler,
  getSingleProductPricingApiHandler,
  getProductPurityApiHandler,
  deleteProductPurityApiHandler,
  addProductPurityApiHandler,
  updateProductPurityApiHandler,
  getSingleProductPurityApiHandler,
  updateBlogTermConditionApiHandler,
  getAllCustomProductApiHandler,
} from "../../services/AdminServices";

// Admin logout
export function* logOutRequest() {
  yield takeEvery("ADMIN_LOGOUT_REQUEST", function* (data) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    yield put({
      type: actions.ADMIN_LOGOUT,
    });
    data.navigate.push("/admin/login");
  });
}

// Admin login
export function* loginRequest() {
  yield takeEvery("ADMIN_LOGIN_REQUEST", function* (data) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield loginApiHandler(data.payload);

    if (response.status == 1 && response.data) {
      yield put({
        type: actions.ADMIN_LOGIN_SUCCESS,
        data: response.data,
      });
      data.navigate.push(`/admin`);
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Forgot password
export function* forgotPasswordRequest() {
  yield takeEvery("ADMIN_FORGOT_PASSWORD_REQUEST", function* (data) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield forgotPasswordApiHandler(data.payload);

    // if (response.status == 1) {
    if (response?.data?.status == 1) {
      yield put({
        type: actions.ADMIN_FORGOT_PASSWORD_SUCCESS,
        data: response.data,
        message: response.message,
      });
      data.navigate.push("/admin/otpverify");
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Verify OTP
export function* otpVerifyRequest() {
  yield takeEvery("ADMIN_OTP_VERIFY_REQUEST", function* (data) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield otpVerifyApiHandler(data.payload);

    if (response.status == 1) {
      yield put({
        type: actions.ADMIN_OTP_VERIFY_SUCCESS,
        data: response.data,
      });
      data.navigate.push("/admin/resetpassword");
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Reset password
export function* resetPasswordRequest() {
  yield takeEvery("ADMIN_RESET_PASSWORD_REQUEST", function* (data) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield resetPasswordApiHandler(data.payload);

    if (response.status == 1) {
      yield put({
        type: actions.ADMIN_RESET_PASSWORD_SUCCESS,
        data: response.data,
      });
      data.navigate.push("/admin/login");
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Get counts for dashboard
export function* GetDynamicCountRequest() {
  yield takeEvery("GET_DYNAMIC_COUNT_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield getDynamicCountApiHandler(
      payload.payload,
      payload.token
    );
    if (response.status == 1) {
      yield put({
        type: actions.GET_DYNAMIC_COUNT_SUCCESS,
        data: response.data,
      });
      // payload.navigate.push("/user/mypreciousstone")
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Get all users list
export function* GetUserListRequest() {
  yield takeEvery("GET_USER_LIST_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield getUserListApiHandler(
      payload.payload,
      payload.token
    );
    if (response.status == 1) {
      yield put({
        type: actions.GET_USER_LIST_SUCCESS,
        data: response.data,
      });
      // payload.navigate.push("/user/mypreciousstone")
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Get specific user details
export function* GetUserDetailRequest() {
  yield takeEvery("GET_USER_DETAIL_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield getUserDetailApiHandler(
      payload.param,
      payload.token
    );
    if (response.status == 1) {
      yield put({
        type: actions.GET_USER_DETAIL_SUCCESS,
        data: response.data,
      });
      // payload.navigate.push("/user/mypreciousstone")
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Delete specific user
export function* DeleteUserRequest() {
  yield takeEvery("DELETE_USER_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield deleteUserApiHandler(payload.param, payload.token);
    if (response.status == 1) {
      yield put({
        type: actions.DELETE_USER_SUCCESS,
        data: response.data,
      });

      const userlist = yield getUserListApiHandler(
        payload.payload,
        payload.token
      );
      if (userlist.status == 1) {
        yield put({
          type: actions.GET_USER_LIST_SUCCESS,
          data: userlist.data,
        });
        // payload.navigate.push("/user/mypreciousstone")
      } else {
        yield put({ type: actions.ADMIN_ERROR, message: response.message });
      }
      // payload.navigate.push("/user/mypreciousstone")
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Get all product list
export function* GetAllProductRequest() {
  yield takeEvery("GET_ALL_PRODUCT_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield getPendingProductApiHandler(
      payload.payload,
      payload.token
    );
    if (response.status == 1) {
      yield put({
        type: actions.GET_ALL_PRODUCT_SUCCESS,
        data: response.data,
      });
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Get all custom product list
export function* GetAllCustomProductRequest() {
  yield takeEvery("GET_ALL_CUSTOM_PRODUCT_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield getAllCustomProductApiHandler(
      payload.payload,
      payload.token
    );
    if (response.status == 1) {
      yield put({
        type: actions.GET_ALL_CUSTOM_PRODUCT_SUCCESS,
        data: response.data,
      });
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Get single product details
export function* GetProductDetailRequest() {
  yield takeEvery("GET_PRODUCT_DETAIL_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield getProductDetailApiHandler(
      payload.param,
      payload.token
    );
    if (response.status == 1) {
      yield put({
        type: actions.GET_PRODUCT_DETAIL_SUCCESS,
        data: response.data,
      });
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Product approve
export function* ApproveProductRequest() {
  yield takeEvery("PRODUCT_APPROVE_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield approveProductApiHandler(
      payload.param,
      payload.token
    );
    if (response.status == 1) {
      yield put({
        type: actions.PRODUCT_APPROVE_SUCCESS,
        data: response.data,
      });

      payload.navigate.push("/admin/productmanagement/pendingproducts");
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Product reject
export function* RejectProductRequest() {
  yield takeEvery("PRODUCT_REJECT_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield rejectProductApiHandler(
      payload.param,
      payload.token
    );
    if (response.status == 1) {
      yield put({
        type: actions.PRODUCT_REJECT_SUCCESS,
        data: response.data,
      });

      payload.navigate.push("/admin/productmanagement/rejectedproducts");
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Product delete
export function* DeleteProductRequest() {
  yield takeEvery("PRODUCT_DELETE_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield deleteProductApiHandler(
      payload.param,
      payload.token
    );
    if (response.status == 1) {
      yield put({
        type: actions.PRODUCT_DELETE_SUCCESS,
        data: response.data,
      });

      payload.navigate.push("/admin/productmanagement/pendingproducts");
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Get item list
export function* GetItemListRequest() {
  yield takeEvery("GET_ITEM_DATA_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield getItemApiHandler(payload.payload, payload.token);
    if (response.status == 1) {
      yield put({
        type: actions.GET_ITEM_DATA_SUCCESS,
        data: response.data,
      });
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Get material list
export function* GetMaterialListRequest() {
  yield takeEvery("GET_MATERIAL_LIST_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield getMaterialDataApiHandler(
      payload.payload,
      payload.token
    );
    if (response.status == 1) {
      yield put({
        type: actions.GET_MATERIAL_LIST_SUCCESS,
        data: response.data,
      });
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Get All Category list
export function* GetCategoryListRequest() {
  yield takeEvery("GET_CATEGORY_LIST_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield getCategoryDataApiHandler(
      payload.payload,
      payload.token
    );
    if (response.status == 1) {
      yield put({
        type: actions.GET_CATEGORY_LIST_SUCCESS,
        data: response.data,
      });
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Update product
export function* UpdateProductRequest() {
  yield takeEvery("UPDATE_PRODUCT_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield updateProductApiHandler(
      payload.param,
      payload.token
    );
    if (response.status == 1) {
      yield put({
        type: actions.UPDATE_PRODUCT_SUCCESS,
        data: response.data,
      });

      payload.navigate.push("/admin/productmanagement/pendingproducts");
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Custom Product approve by admin
export function* CustomProductApproveRequest() {
  yield takeEvery("APPROVE_CUSTOM_PRODUCT_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield approveCustomProductApiHandler(
      payload.param,
      payload.token
    );
    if (response.status == 1) {
      yield put({
        type: actions.APPROVE_CUSTOM_PRODUCT_SUCCESS,
        data: response.data,
      });

      payload.navigate.push("/admin/productmanagement/customproducts");
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Custom Product reject by admin
export function* CustomProductRejectRequest() {
  yield takeEvery("CUSTOM_PRODUCT_REJECT_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield rejectCustomProductApiHandler(
      payload.param,
      payload.token
    );
    if (response.status == 1) {
      yield put({
        type: actions.CUSTOM_PRODUCT_REJECT_SUCCESS,
        data: response.data,
      });

      payload.navigate.push("/admin/productmanagement/customproducts");
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Get single custom product details
export function* GetCustomProductDetailRequest() {
  yield takeEvery("CUSTOM_PRODUCT_DETAIL_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield getCustomProductDetailApiHandler(
      payload.param,
      payload.token
    );
    if (response.status == 1) {
      yield put({
        type: actions.CUSTOM_PRODUCT_DETAIL_SUCCESS,
        data: response.data,
      });
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Update custom product
export function* UpdateCustomProductRequest() {
  yield takeEvery("UPDATE_CUSTOM_PRODUCT_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield updateCustomProductApiHandler(
      payload.param,
      payload.token
    );
    if (response.status == 1) {
      yield put({
        type: actions.UPDATE_CUSTOM_PRODUCT_SUCCESS,
        data: response.data,
      });

      payload.navigate.push("/admin/productmanagement/customproducts");
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Get all orders list
export function* GetAllOrderRequest() {
  yield takeEvery("GET_ALL_ORDERS_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield getAllOrdersApiHandler(
      payload.payload,
      payload.token
    );
    if (response.status == 1) {
      yield put({
        type: actions.GET_ALL_ORDERS_SUCCESS,
        data: response.data,
      });
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Get Specific Order Details
export function* GetOrderDetailRequest() {
  yield takeEvery("GET_ORDER_DETAIL_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield getOrderDetailApiHandler(
      payload.param,
      payload.token
    );
    if (response.status == 1) {
      yield put({
        type: actions.GET_ORDER_DETAIL_SUCCESS,
        data: response.data,
      });
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Approve Order
export function* ApproveOrder() {
  yield takeEvery("APPROVE_ORDER_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield approveOrderApiHandler(payload.param, payload.token);
    if (response.status == 1) {
      yield put({
        type: actions.APPROVE_ORDER_SUCCESS,
        data: response.data,
      });

      payload.navigate.push("/admin/ordermanagement");
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Reject Order
export function* RejectOrder() {
  yield takeEvery("REJECT_ORDER_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield rejectOrderApiHandler(payload.param, payload.token);
    if (response.status == 1) {
      yield put({
        type: actions.REJECT_ORDER_SUCCESS,
        data: response.data,
      });

      payload.navigate.push("/admin/rejectedorders");
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Get Order Rating
export function* getOrderRating() {
  yield takeEvery("ORDER_RATING_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield getOrderRatingApiHandler(
      payload.param,
      payload.token
    );
    if (response.status == 1) {
      yield put({
        type: actions.ORDER_RATING_SUCCESS,
        data: response.data,
      });
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Update Order Rating
export function* updateOrderRating() {
  yield takeEvery("ORDER_RATING_UPDATE_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield updateOrderRatingApiHandler(
      payload.param,
      payload.token
    );
    if (response.status == 1) {
      yield put({
        type: actions.ORDER_RATING_UPDATE_SUCCESS,
        data: response,
      });
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Update Order Status
export function* updateOrderStatus() {
  yield takeEvery("ORDER_STATUS_UPDATE_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield updateOrderStatusApiHandler(
      payload.param,
      payload.token
    );
    if (response.status == 1) {
      yield put({
        type: actions.ORDER_STATUS_UPDATE_SUCCESS,
        data: response.data,
      });

      payload.navigate.push("/admin/paymentmanagement");
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Get all return orders list
export function* GetAllReturnOrderRequest() {
  yield takeEvery("GET_ALL_RETURN_ORDERS_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield getAllReturnOrdersApiHandler(
      payload.payload,
      payload.token
    );
    if (response.status == 1) {
      yield put({
        type: actions.GET_ALL_RETURN_ORDERS_SUCCESS,
        data: response.data,
      });
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Get Return orders details
export function* GetReturnOrderDetailRequest() {
  yield takeEvery("GET_RETURN_ORDER_DETAIL_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield getReturnOrderDetailApiHandler(
      payload.param,
      payload.token
    );
    if (response.status == 1) {
      yield put({
        type: actions.GET_RETURN_ORDER_DETAIL_SUCCESS,
        data: response.data,
      });
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Accept Return order
export function* AcceptReturnOrderRequest() {
  yield takeEvery("ACCEPT_RETURN_ORDER_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield acceptReturnOrderApiHandler(
      payload.param,
      payload.token
    );
    if (response.status == 1) {
      yield put({
        type: actions.ACCEPT_RETURN_ORDER_SUCCESS,
        data: response.data,
      });

      payload.navigate.push("/admin/paymentmanagement");
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Reject Return order
export function* RejectReturnOrderRequest() {
  yield takeEvery("REJECT_RETURN_ORDER_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield rejectReturnOrderApiHandler(
      payload.param,
      payload.token
    );
    if (response.status == 1) {
      yield put({
        type: actions.REJECT_RETURN_ORDER_SUCCESS,
        data: response.data,
      });

      payload.navigate.push("/admin/returnorders");
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Update Order Transaction Id
export function* UpdateOrderTransactionId() {
  yield takeEvery("UPDATE_ORDER_TRANSACTION_ID_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield updateOrderTransactionIdApiHandler(
      payload.param,
      payload.token
    );
    if (response.status == 1) {
      yield put({
        type: actions.UPDATE_ORDER_TRANSACTION_ID_SUCCESS,
        data: response.data,
      });

      payload.navigate.push("/admin/paymentmanagement");
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Delete Category
export function* DeleteCategoryRequest() {
  yield takeEvery("DELETE_CATEGORY_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield deleteCategoryApiHandler(
      payload.param,
      payload.token
    );
    if (response.status == 1) {
      yield put({
        type: actions.DELETE_CATEGORY_SUCCESS,
        data: response.data,
      });

      const userlist = yield getCategoryDataApiHandler(
        payload.payload,
        payload.token
      );
      if (userlist.status == 1) {
        yield put({
          type: actions.GET_CATEGORY_LIST_SUCCESS,
          data: userlist.data,
        });
      } else {
        yield put({ type: actions.ADMIN_ERROR, message: response.message });
      }
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Add Category
export function* AddCategoryRequest() {
  yield takeEvery("ADD_CATEGORY_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield addCategoryApiHandler(payload.param, payload.token);
    if (response.status == 1) {
      yield put({
        type: actions.ADD_CATEGORY_SUCCESS,
        data: response.data,
      });

      const categoryList = yield getCategoryDataApiHandler(
        payload.payload,
        payload.token
      );
      if (categoryList.status == 1) {
        yield put({
          type: actions.GET_CATEGORY_LIST_SUCCESS,
          data: categoryList.data,
        });
        payload.navigate.push("/admin/categorymanagement");
      } else {
        yield put({ type: actions.ADMIN_ERROR, message: response.message });
      }
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Get signle category details
export function* GetSingleCategoryDetailRequest() {
  yield takeEvery("GET_SINGLE_CATEGORY_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield getSingleCategoryApiHandler(
      payload.param,
      payload.token
    );
    if (response.status == 1) {
      yield put({
        type: actions.GET_SINGLE_CATEGORY_SUCCESS,
        data: response.data,
      });
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Update Category
export function* UpdateCategoryRequest() {
  yield takeEvery("UPDATE_CATEGORY_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield updateCategoryApiHandler(
      payload.param,
      payload.token
    );
    if (response.status == 1) {
      yield put({
        type: actions.UPDATE_CATEGORY_SUCCESS,
        data: response.data,
      });

      const categoryList = yield getCategoryDataApiHandler(
        payload.payload,
        payload.token
      );
      if (categoryList.status == 1) {
        yield put({
          type: actions.GET_CATEGORY_LIST_SUCCESS,
          data: categoryList.data,
        });
        payload.navigate.push("/admin/categorymanagement");
      } else {
        yield put({ type: actions.ADMIN_ERROR, message: response.message });
      }
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Delete Material
export function* DeleteMaterialRequest() {
  yield takeEvery("DELETE_MATERIAL_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield deleteMaterialApiHandler(
      payload.param,
      payload.token
    );
    if (response.status == 1) {
      yield put({
        type: actions.DELETE_MATERIAL_SUCCESS,
        data: response.data,
      });

      const userlist = yield getMaterialDataApiHandler(
        payload.payload,
        payload.token
      );
      if (userlist.status == 1) {
        yield put({
          type: actions.GET_MATERIAL_LIST_SUCCESS,
          data: userlist.data,
        });
      } else {
        yield put({ type: actions.ADMIN_ERROR, message: response.message });
      }
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Add Material
export function* AddMaterialRequest() {
  yield takeEvery("ADD_MATERIAL_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield addMaterialApiHandler(payload.param, payload.token);
    if (response.status == 1) {
      yield put({
        type: actions.ADD_MATERIAL_SUCCESS,
        data: response.data,
      });

      const categoryList = yield getMaterialDataApiHandler(
        payload.payload,
        payload.token
      );
      if (categoryList.status == 1) {
        yield put({
          type: actions.GET_MATERIAL_LIST_SUCCESS,
          data: categoryList.data,
        });
        payload.navigate.push("/admin/materialmanagement");
      } else {
        yield put({ type: actions.ADMIN_ERROR, message: response.message });
      }
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Get signle material details
export function* GetSingleMaterialDetailRequest() {
  yield takeEvery("GET_SINGLE_MATERIAL_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield getSingleMaterialApiHandler(
      payload.param,
      payload.token
    );
    if (response.status == 1) {
      yield put({
        type: actions.GET_SINGLE_MATERIAL_SUCCESS,
        data: response.data,
      });
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Update Material
export function* UpdateMaterialRequest() {
  yield takeEvery("EDIT_MATERIAL_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield updateMaterialApiHandler(
      payload.param,
      payload.token
    );
    if (response.status == 1) {
      yield put({
        type: actions.EDIT_MATERIAL_SUCCESS,
        data: response.data,
      });

      const categoryList = yield getMaterialDataApiHandler(
        payload.payload,
        payload.token
      );
      if (categoryList.status == 1) {
        yield put({
          type: actions.GET_MATERIAL_LIST_SUCCESS,
          data: categoryList.data,
        });
        payload.navigate.push("/admin/materialmanagement");
      } else {
        yield put({ type: actions.ADMIN_ERROR, message: response.message });
      }
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Delete Item
export function* DeleteitemRequest() {
  yield takeEvery("DELETE_ITEM_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield deleteItemApiHandler(payload.param, payload.token);
    if (response.status == 1) {
      yield put({
        type: actions.DELETE_ITEM_SUCCESS,
        data: response.data,
      });

      const userlist = yield getItemApiHandler(payload.payload, payload.token);
      if (userlist.status == 1) {
        yield put({
          type: actions.GET_ITEM_DATA_SUCCESS,
          data: userlist.data,
        });
      } else {
        yield put({ type: actions.ADMIN_ERROR, message: response.message });
      }
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Add Item
export function* AddItemRequest() {
  yield takeEvery("ADD_ITEM_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield addItemApiHandler(payload.param, payload.token);
    if (response.status == 1) {
      yield put({
        type: actions.ADD_ITEM_SUCCESS,
        data: response.data,
      });

      const categoryList = yield getItemApiHandler(
        payload.payload,
        payload.token
      );
      if (categoryList.status == 1) {
        yield put({
          type: actions.GET_ITEM_DATA_SUCCESS,
          data: categoryList.data,
        });
        payload.navigate.push("/admin/itemmanagement");
      } else {
        yield put({ type: actions.ADMIN_ERROR, message: response.message });
      }
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Get signle item details
export function* GetSingleItemDetailRequest() {
  yield takeEvery("GET_SINGLE_ITEM_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield getSingleItemApiHandler(
      payload.param,
      payload.token
    );
    if (response.status == 1) {
      yield put({
        type: actions.GET_SINGLE_ITEM_SUCCESS,
        data: response.data,
      });
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Update Item
export function* UpdateItemRequest() {
  yield takeEvery("EDIT_ITEM_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield updateItemApiHandler(payload.param, payload.token);
    if (response.status == 1) {
      yield put({
        type: actions.EDIT_ITEM_SUCCESS,
        data: response.data,
      });

      const categoryList = yield getItemApiHandler(
        payload.payload,
        payload.token
      );
      if (categoryList.status == 1) {
        yield put({
          type: actions.GET_ITEM_DATA_SUCCESS,
          data: categoryList.data,
        });
        payload.navigate.push("/admin/itemmanagement");
      } else {
        yield put({ type: actions.ADMIN_ERROR, message: response.message });
      }
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Get payment list
export function* GetPaymentListRequest() {
  yield takeEvery("GET_PAYMENT_LIST_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield paymentListApiHandler(payload.param, payload.token);
    if (response.status == 1) {
      yield put({
        type: actions.GET_PAYMENT_LIST_SUCCESS,
        data: response.data,
      });
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Get refund chart data
export function* GetRefundChartDataRequest() {
  yield takeEvery("GET_REFUND_CHART_DATA_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield refundChartDataApiHandler(
      payload.param,
      payload.token
    );
    if (response.status == 1) {
      yield put({
        type: actions.GET_REFUND_CHART_DATA_SUCCESS,
        data: response.data,
      });
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Get revenue chart data
export function* GetRevenueChartDataRequest() {
  yield takeEvery("GET_REVENUE_CHART_DATA_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield revenueChartDataApiHandler(
      payload.param,
      payload.token
    );
    if (response.status == 1) {
      yield put({
        type: actions.GET_REVENUE_CHART_DATA_SUCCESS,
        data: response.data,
      });
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Get chart data
export function* GetChartDataRequest() {
  yield takeEvery("GET_CHART_DATA_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield chartDataApiHandler(payload.param, payload.token);
    if (response.status == 1) {
      yield put({
        type: actions.GET_CHART_DATA_SUCCESS,
        data: response.data,
      });
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Get banks details
export function* GetBankDetailRequest() {
  yield takeEvery("GET_BANK_DETAILS_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield getBankDetailApiHandler(
      payload.param,
      payload.token
    );
    if (response.status == 1) {
      yield put({
        type: actions.GET_BANK_DETAILS_SUCCESS,
        data: response.data,
      });
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Get Making charge details
export function* GetMakingChargeRequest() {
  yield takeEvery("GET_MAKING_CHARGE_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield getMakingChargeApiHandler(
      payload.param,
      payload.token
    );
    if (response.status == 1) {
      yield put({
        type: actions.GET_MAKING_CHARGE_SUCCESS,
        data: response.data,
      });
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Get Notification details
export function* GetNotificationDetailRequest() {
  yield takeEvery("GET_NOTIFICATION_DETAIL_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield getNotificationDetailApiHandler(
      payload.param,
      payload.token
    );
    if (response.status == 1) {
      yield put({
        type: actions.GET_NOTIFICATION_DETAIL_SUCCESS,
        data: response.data,
      });
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Get Customer Services List
export function* GetCustomerServicesListRequest() {
  yield takeEvery("GET_CUSTOMER_SERVICEID_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield getCustomerServicesListApiHandler(
      payload.param,
      payload.token
    );
    if (response.status == 1) {
      yield put({
        type: actions.GET_CUSTOMER_SERVICEID_SUCCESS,
        data: response.data,
      });
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Get All CMS
export function* GetAllCMSRequest() {
  yield takeEvery("GET_ALL_CMS_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield getAllCMSApiHandler(payload.param, payload.token);
    if (response.status == 1) {
      yield put({
        type: actions.GET_ALL_CMS_SUCCESS,
        data: response.data,
      });
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Get Term and Condition
export function* GetTermandConditionRequest() {
  yield takeEvery("GET_TERM_CONDITION_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield getTermConditionApiHandler(
      payload.param,
      payload.token
    );
    if (response.status == 1) {
      yield put({
        type: actions.GET_TERM_CONDITION_SUCCESS,
        data: response.data,
      });
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Update Term and Condition
export function* UpdateTermandConditionRequest() {
  yield takeEvery("UPDATE_TERM_CONDITION_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield updateTermConditionApiHandler(
      payload.param,
      payload.token
    );
    if (response.status == 1) {
      yield put({
        type: actions.UPDATE_TERM_CONDITION_SUCCESS,
        data: response.data,
      });

      payload.navigate.push("/admin/contentmanagement");
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}
// Get privacy and policy
export function* GetPrivacyAndPolicyRequest() {
  yield takeEvery("GET_PRIVACY_POLICY_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield getTermConditionApiHandler(
      payload.param,
      payload.token
    );
    if (response.status == 1) {
      yield put({
        type: actions.GET_PRIVACY_POLICY_SUCCESS,
        data: response.data,
      });
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Update privacy and policy
export function* UpdatePrivacyAndPolicyRequest() {
  yield takeEvery("UPDATE_PRIVACY_POLICY_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield updateTermConditionApiHandler(
      payload.param,
      payload.token
    );
    if (response.status == 1) {
      yield put({
        type: actions.UPDATE_PRIVACY_POLICY_SUCCESS,
        data: response.data,
      });

      payload.navigate.push("/admin/contentmanagement");
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}


// Get return policy
export function* GetReturnPolicyRequest() {
  yield takeEvery("GET_RETURN_POLICY_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield getTermConditionApiHandler(
      payload.param,
      payload.token
    );
    if (response.status == 1) {
      yield put({
        type: actions.GET_RETURN_POLICY_SUCCESS,
        data: response.data,
      });
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Update return and policy
export function* UpdateReturnPolicyRequest() {
  yield takeEvery("UPDATE_RETURN_POLICY_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield updateTermConditionApiHandler(
      payload.param,
      payload.token
    );
    if (response.status == 1) {
      yield put({
        type: actions.UPDATE_RETURN_POLICY_SUCCESS,
        data: response.data,
      });

      payload.navigate.push("/admin/contentmanagement");
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}



// Get FAQ list
export function* GetFAQListRequest() {
  yield takeEvery("GET_FAQ_LIST_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield getFAQListApiHandler(payload.param, payload.token);
    if (response.status == 1) {
      yield put({
        type: actions.GET_FAQ_LIST_SUCCESS,
        data: response.data,
      });
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Delete FAQ
export function* DeleteFAQRequest() {
  yield takeEvery("DELETE_FAQ_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield deleteFAQApiHandler(payload.param, payload.token);
    if (response.status == 1) {
      yield put({
        type: actions.DELETE_FAQ_SUCCESS,
      });
      const response = yield getFAQListApiHandler(payload.param, payload.token);
      if (response.status == 1) {
        yield put({
          type: actions.GET_FAQ_LIST_SUCCESS,
          data: response.data,
        });
      }
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Add FAQ
export function* AddFAQRequest() {
  yield takeEvery("ADD_FAQ_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield addFAQApiHandler(payload.param, payload.token);
    if (response.status == 1) {
      yield put({
        type: actions.ADD_FAQ_SUCCESS,
      });
      const response = yield getFAQListApiHandler(payload.param, payload.token);
      if (response.status == 1) {
        yield put({
          type: actions.GET_FAQ_LIST_SUCCESS,
          data: response.data,
        });
      }
      payload.navigate.push("/admin/contentmanagement/faq");
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Update FAQ
export function* UpdateFAQRequest() {
  yield takeEvery("UPDATE_FAQ_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield updateFAQApiHandler(payload.param, payload.token);
    if (response.status == 1) {
      yield put({
        type: actions.UPDATE_FAQ_SUCCESS,
      });
      const response = yield getFAQListApiHandler(payload.param, payload.token);
      if (response.status == 1) {
        yield put({
          type: actions.GET_FAQ_LIST_SUCCESS,
          data: response.data,
        });
      }
      payload.navigate.push("/admin/contentmanagement/faq");
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Get single FAQ details
export function* GetFAQDetailRequest() {
  yield takeEvery("GET_FAQ_DETAIL_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield getFAQDetailApiHandler(payload.param, payload.token);
    if (response.status == 1) {
      yield put({
        type: actions.GET_FAQ_DETAIL_SUCCESS,
        data: response.data,
      });
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Get Blog List
export function* GetBlogListRequest() {
  yield takeEvery("GET_BLOG_LIST_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield getBlogListApiHandler(payload.param, payload.token);
    if (response.status == 1) {
      yield put({
        type: actions.GET_BLOG_LIST_SUCCESS,
        data: response.data,
      });
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Get Guideline List
export function* GetGuidelineListRequest() {
  yield takeEvery("GET_GUIDELINE_LIST_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield getGuidelineListApiHandler(
      payload.param,
      payload.token
    );
    if (response.status == 1) {
      yield put({
        type: actions.GET_GUIDELINE_LIST_SUCCESS,
        data: response.data,
      });
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Update Single Blog
export function* UpdateBlogRequest() {
  yield takeEvery("UPDATE_BLOG_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield updateBlogApiHandler(payload.param, payload.token);
    if (response.status == 1) {
      yield put({
        type: actions.UPDATE_BLOG_SUCCESS,
        data: response.data,
      });

      payload.navigate.push("/admin/blogs");
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Update Customer Service
export function* UpdateCustomerServiceRequest() {
  yield takeEvery("UPDATE_CUSTOMER_SERVICE_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield updateCustomerServiceApiHandler(
      payload.param,
      payload.token
    );
    if (response.status == 1) {
      yield put({
        type: actions.UPDATE_CUSTOMER_SERVICE_SUCCESS,
        data: response.data,
      });

      payload.navigate.push("/admin/contentmanagement");
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Get Top Deal List
export function* GetDealListRequest() {
  yield takeEvery("GET_DEAL_LIST_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield getDealListApiHandler(payload.param, payload.token);
    if (response.status == 1) {
      yield put({
        type: actions.GET_DEAL_LIST_SUCCESS,
        data: response.data,
      });
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Deal delete
export function* DeleteDealRequest() {
  yield takeEvery("DELETE_DEAL_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield deleteDealApiHandler(payload.param, payload.token);
    if (response.status == 1) {
      yield put({
        type: actions.DELETE_DEAL_SUCCESS,
        data: response.data,
      });

      const response1 = yield getDealListApiHandler(
        payload.param,
        payload.token
      );
      if (response1.status == 1) {
        yield put({
          type: actions.GET_DEAL_LIST_SUCCESS,
          data: response.data,
        });
      } else {
      }

      payload.navigate.push("/admin/contentmanagement/topdeals");
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Add Top Deal
export function* AddTopDealRequest() {
  yield takeEvery("ADD_DEAL_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield addDealApiHandler(payload.param, payload.token);
    if (response.status == 1) {
      yield put({
        type: actions.ADD_DEAL_SUCCESS,
        data: response.data,
      });

      payload.navigate.push("/admin/contentmanagement/topdeals");
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Get Single Deal Detail
export function* GetSingleDealRequest() {
  yield takeEvery("GET_SINGLE_DEAL_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield getSingleDealApiHandler(
      payload.param,
      payload.token
    );
    if (response.status == 1) {
      yield put({
        type: actions.GET_SINGLE_DEAL_SUCCESS,
        data: response.data,
      });
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Update Top Deal
export function* UpdateTopDealRequest() {
  yield takeEvery("UPDATE_DEAL_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield updateDealApiHandler(payload.param, payload.token);
    if (response.status == 1) {
      yield put({
        type: actions.UPDATE_DEAL_SUCCESS,
        data: response.data,
      });

      payload.navigate.push("/admin/contentmanagement/topdeals");
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Get Single Deal Detail
export function* GetBenefitListRequest() {
  yield takeEvery("GET_BENEFIT_LIST_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield getBenefitListApiHandler(
      payload.param,
      payload.token
    );
    if (response.status == 1) {
      yield put({
        type: actions.GET_BENEFIT_LIST_SUCCESS,
        data: response,
      });
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Add Benefit
export function* AddBenefitRequest() {
  yield takeEvery("ADD_BENEFIT_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield addBenefitApiHandler(payload.param, payload.token);
    if (response.status == 1) {
      yield put({
        type: actions.ADD_BENEFIT_SUCCESS,
        data: response.data,
      });

      payload.navigate.push("/admin/membership");
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Add Guideline
export function* AddGuidelineRequest() {
  yield takeEvery("ADD_GUIDELINE_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield addGuidelineApiHandler(payload.param, payload.token);
    if (response.status == 1) {
      yield put({
        type: actions.ADD_GUIDELINE_SUCCESS,
        data: response.data,
      });

      payload.navigate.push("/admin/guidelines");
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Get Product Description List
export function* GetProductDescriptionListRequest() {
  yield takeEvery("LIST_PRODUCT_DESCRIPTION_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield getProductDescriptionListApiHandler(
      payload.param,
      payload.token
    );
    if (response.status == 1) {
      yield put({
        type: actions.LIST_PRODUCT_DESCRIPTION_SUCCESS,
        data: response.data,
      });
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Add Product Description
export function* AddProductDescriptionRequest() {
  yield takeEvery("ADD_PRODUCT_DESCRIPTION_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield addProductDescriptionApiHandler(
      payload.param,
      payload.token
    );
    if (response.status == 1) {
      yield put({
        type: actions.ADD_PRODUCT_DESCRIPTION_SUCCESS,
        data: response.data,
      });

      payload.navigate.push("/admin/productdetails");
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Delete Product Description
export function* DeleteProductDescriptionRequest() {
  yield takeEvery("DELETE_PRODUCT_DESCRIPTION_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield deleteProductDescriptionApiHandler(
      payload.param,
      payload.token
    );
    if (response.status == 1) {
      yield put({
        type: actions.DELETE_PRODUCT_DESCRIPTION_SUCCESS,
        data: response.data,
      });

      const response1 = yield getProductDescriptionListApiHandler(
        payload.param,
        payload.token
      );
      if (response1.status == 1) {
        yield put({
          type: actions.LIST_PRODUCT_DESCRIPTION_SUCCESS,
          data: response1.data,
        });
      } else {
      }
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Get Single Product Description
export function* GetSingleProductDescriptionRequest() {
  yield takeEvery("SINGLE_PRODUCT_DESCRIPTION_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield getSingleProductDescriptionApiHandler(
      payload.param,
      payload.token
    );
    if (response.status == 1) {
      yield put({
        type: actions.SINGLE_PRODUCT_DESCRIPTION_SUCCESS,
        data: response.data,
      });
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Update Product Description
export function* UpdateProductDescriptionRequest() {
  yield takeEvery("UPDATE_PRODUCT_DESCRIPTION_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield updateProductDescriptionApiHandler(
      payload.param,
      payload.token
    );
    if (response.status == 1) {
      yield put({
        type: actions.UPDATE_PRODUCT_DESCRIPTION_SUCCESS,
        data: response.data,
      });

      payload.navigate.push("/admin/productdetails");
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Get Product Pricing list
export function* GetProductPricingRequest() {
  yield takeEvery("LIST_PRODUCT_PRICING_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield getProductPricingApiHandler(
      payload.param,
      payload.token
    );
    if (response.status == 1) {
      yield put({
        type: actions.LIST_PRODUCT_PRICING_SUCCESS,
        data: response.data,
      });
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Delete Product Description
export function* DeleteProductPricingRequest() {
  yield takeEvery("DELETE_PRODUCT_PRICING_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield deleteProductPricingApiHandler(
      payload.param,
      payload.token
    );
    if (response.status == 1) {
      yield put({
        type: actions.DELETE_PRODUCT_PRICING_SUCCESS,
        data: response.data,
      });

      const response1 = yield getProductPricingApiHandler(
        payload.param,
        payload.token
      );
      if (response1.status == 1) {
        yield put({
          type: actions.LIST_PRODUCT_PRICING_SUCCESS,
          data: response1.data,
        });
      } else {
      }
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Add Product Pricing
export function* AddProductPricingRequest() {
  yield takeEvery("ADD_PRODUCT_PRICING_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield addProductPricingApiHandler(
      payload.param,
      payload.token
    );
    if (response.status == 1) {
      yield put({
        type: actions.ADD_PRODUCT_PRICING_SUCCESS,
        data: response.data,
      });

      payload.navigate.push("/admin/productpricing");
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Update Product Pricing
export function* UpdateProductPricingRequest() {
  yield takeEvery("UPDATE_PRODUCT_PRICING_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield updateProductPricingApiHandler(
      payload.param,
      payload.token
    );
    if (response.status == 1) {
      yield put({
        type: actions.UPDATE_PRODUCT_PRICING_SUCCESS,
        data: response.data,
      });

      payload.navigate.push("/admin/productpricing");
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Get Single Product Pricing list
export function* GetSingleProductPricingRequest() {
  yield takeEvery("SINGLE_PRODUCT_PRICING_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield getSingleProductPricingApiHandler(
      payload.param,
      payload.token
    );
    if (response.status == 1) {
      yield put({
        type: actions.SINGLE_PRODUCT_PRICING_SUCCESS,
        data: response.data,
      });
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Get Product Purity list
export function* GetProductPurityRequest() {
  yield takeEvery("LIST_PRODUCT_PURITY_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield getProductPurityApiHandler(
      payload.param,
      payload.token
    );
    if (response.status == 1) {
      yield put({
        type: actions.LIST_PRODUCT_PURITY_SUCCESS,
        data: response.data,
      });
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Delete Product Purity
export function* DeleteProductPurityRequest() {
  yield takeEvery("DELETE_PRODUCT_PURITY_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield deleteProductPurityApiHandler(
      payload.param,
      payload.token
    );
    if (response.status == 1) {
      yield put({
        type: actions.DELETE_PRODUCT_PURITY_SUCCESS,
        data: response.data,
      });

      const response1 = yield getProductPurityApiHandler(
        payload.param,
        payload.token
      );
      if (response1.status == 1) {
        yield put({
          type: actions.LIST_PRODUCT_PURITY_SUCCESS,
          data: response1.data,
        });
      } else {
      }
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Add Product Purity
export function* AddProductPurityRequest() {
  yield takeEvery("ADD_PRODUCT_PURITY_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield addProductPurityApiHandler(
      payload.param,
      payload.token
    );
    if (response.status == 1) {
      yield put({
        type: actions.ADD_PRODUCT_PURITY_SUCCESS,
        data: response.data,
      });

      payload.navigate.push("/admin/productpurity");
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Update Product Purity
export function* UpdateProductPurityRequest() {
  yield takeEvery("UPDATE_PRODUCT_PURITY_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield updateProductPurityApiHandler(
      payload.param,
      payload.token
    );
    if (response.status == 1) {
      yield put({
        type: actions.UPDATE_PRODUCT_PURITY_SUCCESS,
        data: response.data,
      });

      payload.navigate.push("/admin/productpurity");
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Get Single Product Purity
export function* GetSingleProductPurityRequest() {
  yield takeEvery("SINGLE_PRODUCT_PURITY_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield getSingleProductPurityApiHandler(
      payload.param,
      payload.token
    );
    if (response.status == 1) {
      yield put({
        type: actions.SINGLE_PRODUCT_PURITY_SUCCESS,
        data: response.data,
      });
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Update Blog Term and Condition Purity
export function* UpdateBlogTermConditionRequest() {
  yield takeEvery("UPDATE_BLOG_TERM_CONDITION_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield updateBlogTermConditionApiHandler(
      payload.param,
      payload.token
    );
    if (response.status == 1) {
      yield put({
        type: actions.UPDATE_BLOG_TERM_CONDITION_SUCCESS,
        data: response.data,
      });

      payload.navigate.push("/admin/membership");
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Update Fill Form Guideline
export function* UpdateFillFormGuidelineRequest() {
  yield takeEvery("UPDATE_FILL_FORM_GUIDELINE_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield updateBlogTermConditionApiHandler(
      payload.param,
      payload.token
    );
    if (response.status == 1) {
      yield put({
        type: actions.UPDATE_FILL_FORM_GUIDELINE_SUCCESS,
        data: response.data,
      });

      payload.navigate.push("/admin/membership");
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

// Update Membersahip Content
export function* UpdateMembershipContentRequest() {
  yield takeEvery("UPDATE_MEMBERSHIP_CONTENT_REQUEST", function* (payload) {
    yield put({
      type: actions.ADMIN_LODER_ON,
    });
    const response = yield updateBlogTermConditionApiHandler(
      payload.param,
      payload.token
    );
    if (response.status == 1) {
      yield put({
        type: actions.UPDATE_MEMBERSHIP_CONTENT_SUCCESS,
        data: response.data,
      });

      payload.navigate.push("/admin/membership");
    } else {
      yield put({ type: actions.ADMIN_ERROR, message: response.message });
    }
  });
}

export default function* adminSaga() {
  yield all([
    fork(loginRequest),
    fork(logOutRequest),
    fork(forgotPasswordRequest),
    fork(otpVerifyRequest),
    fork(resetPasswordRequest),
    fork(GetDynamicCountRequest),
    fork(GetUserListRequest),
    fork(GetUserDetailRequest),
    fork(DeleteUserRequest),
    fork(GetAllProductRequest),
    fork(GetProductDetailRequest),
    fork(ApproveProductRequest),
    fork(RejectProductRequest),
    fork(DeleteProductRequest),
    fork(GetItemListRequest),
    fork(GetMaterialListRequest),
    fork(GetCategoryListRequest),
    fork(UpdateProductRequest),
    fork(CustomProductApproveRequest),
    fork(CustomProductRejectRequest),
    fork(GetCustomProductDetailRequest),
    fork(UpdateCustomProductRequest),
    fork(GetAllOrderRequest),
    fork(GetOrderDetailRequest),
    fork(ApproveOrder),
    fork(RejectOrder),
    fork(getOrderRating),
    fork(updateOrderRating),
    fork(updateOrderStatus),
    fork(GetAllReturnOrderRequest),
    fork(GetReturnOrderDetailRequest),
    fork(AcceptReturnOrderRequest),
    fork(RejectReturnOrderRequest),
    fork(UpdateOrderTransactionId),
    fork(DeleteCategoryRequest),
    fork(AddCategoryRequest),
    fork(GetSingleCategoryDetailRequest),
    fork(UpdateCategoryRequest),
    fork(DeleteMaterialRequest),
    fork(AddMaterialRequest),
    fork(GetSingleMaterialDetailRequest),
    fork(UpdateMaterialRequest),
    fork(DeleteitemRequest),
    fork(AddItemRequest),
    fork(GetSingleItemDetailRequest),
    fork(UpdateItemRequest),
    fork(GetPaymentListRequest),
    fork(GetRefundChartDataRequest),
    fork(GetRevenueChartDataRequest),
    fork(GetChartDataRequest),
    fork(GetBankDetailRequest),
    fork(GetMakingChargeRequest),
    fork(GetNotificationDetailRequest),
    fork(GetCustomerServicesListRequest),
    fork(GetAllCMSRequest),
    fork(GetTermandConditionRequest),
    fork(UpdateTermandConditionRequest),
    fork(GetPrivacyAndPolicyRequest),
    fork(UpdatePrivacyAndPolicyRequest),
    fork(GetReturnPolicyRequest),
    fork(UpdateReturnPolicyRequest),
    fork(GetFAQListRequest),
    fork(DeleteFAQRequest),
    fork(AddFAQRequest),
    fork(GetFAQDetailRequest),
    fork(UpdateFAQRequest),
    fork(GetBlogListRequest),
    fork(GetGuidelineListRequest),
    fork(UpdateBlogRequest),
    fork(UpdateCustomerServiceRequest),
    fork(GetDealListRequest),
    fork(DeleteDealRequest),
    fork(AddTopDealRequest),
    fork(GetSingleDealRequest),
    fork(UpdateTopDealRequest),
    fork(GetBenefitListRequest),
    fork(AddBenefitRequest),
    fork(AddGuidelineRequest),
    fork(GetProductDescriptionListRequest),
    fork(AddProductDescriptionRequest),
    fork(DeleteProductDescriptionRequest),
    fork(GetSingleProductDescriptionRequest),
    fork(UpdateProductDescriptionRequest),
    fork(GetProductPricingRequest),
    fork(DeleteProductPricingRequest),
    fork(AddProductPricingRequest),
    fork(UpdateProductPricingRequest),
    fork(GetSingleProductPricingRequest),
    fork(GetProductPurityRequest),
    fork(DeleteProductPurityRequest),
    fork(AddProductPurityRequest),
    fork(UpdateProductPurityRequest),
    fork(GetSingleProductPurityRequest),
    fork(UpdateBlogTermConditionRequest),
    fork(UpdateFillFormGuidelineRequest),
    fork(UpdateMembershipContentRequest),
    fork(GetAllCustomProductRequest),
  ]);
}
