import React, { useEffect } from 'react'
import Box from '@mui/material/Box'
import { Button, Typography } from '@mui/material'
import svg from '../../../assets/svg'
import png from '../../../assets/png'

const ProductDescription = ({ data }) => {
  const goldFields = () => {
    return (
      <>
        {data?.gold?.length
          ? data?.gold?.map((gold) => {
              return (
                <>
                  <Box className="product-heading">Gold</Box>
                  <Box className="product-flex">
                    <Box className="product-desc-left">
                      <Box className="product-data">
                        Purity<span className="end-colun">:</span>
                      </Box>
                      <Box className="product-data">
                        Weight<span className="end-colun">:</span>
                      </Box>
                      <Box className="product-data">
                        Making Charges<span className="end-colun">:</span>
                      </Box>
                    </Box>
                    <Box className="product-desc-right">
                      <Box className="product-data right-side-data">
                        {gold && gold?.purity} %
                      </Box>
                      <Box className="product-data right-side-data">
                        {gold && gold?.weight} gm
                      </Box>
                      <Box className="product-data right-side-data">
                        {gold && gold?.making_charges} %
                      </Box>
                    </Box>
                  </Box>
                </>
              )
            })
          : ''}
      </>
    )
  }

  const silverFields = () => {
    return (
      <>
        {data?.silver?.length
          ? data?.silver?.map((silver) => {
              return (
                <>
                  <Box className="product-heading">Silver</Box>
                  <Box className="product-flex">
                    <Box className="product-desc-left">
                      <Box className="product-data">
                        Purity<span className="end-colun">:</span>
                      </Box>
                      <Box className="product-data">
                        Weight<span className="end-colun">:</span>
                      </Box>
                      <Box className="product-data">
                        Making Charges<span className="end-colun">:</span>
                      </Box>
                    </Box>
                    <Box className="product-desc-right">
                      <Box className="product-data right-side-data">
                        {silver && silver?.purity} %
                      </Box>
                      <Box className="product-data right-side-data">
                        {silver && silver?.weight} gm
                      </Box>
                      <Box className="product-data right-side-data">
                        {silver && silver?.making_charges} %
                      </Box>
                    </Box>
                  </Box>
                </>
              )
            })
          : ''}
      </>
    )
  }

  const gemstoneFields = () => {
    return (
      <>
        {data?.gemstone?.length
          ? data?.gemstone?.map((gems) => {
              return (
                <>
                  <Box className="product-heading">Gemstone</Box>
                  <Box className="product-flex">
                    <Box className="product-desc-left">
                      <Box className="product-data">
                        Purity<span className="end-colun">:</span>
                      </Box>
                      <Box className="product-data">
                        Weight<span className="end-colun">:</span>
                      </Box>
                      <Box className="product-data">
                        Making Charges<span className="end-colun">:</span>
                      </Box>
                    </Box>
                    <Box className="product-desc-right">
                      <Box className="product-data right-side-data">
                        {gems && gems.purity} %
                      </Box>
                      <Box className="product-data right-side-data">
                        {gems && gems?.total_weight} gm
                      </Box>
                      <Box className="product-data right-side-data">
                        {gems && gems?.making_charge} %
                      </Box>
                    </Box>
                  </Box>
                </>
              )
            })
          : ''}
      </>
    )
  }

  const diamondFields = () => {
    return (
      <>
        {data?.diamond?.length
          ? data?.diamond?.map((daim) => {
              return (
                <>
                  <Box className="product-heading">Diamond</Box>
                  <Box className="product-flex">
                    <Box className="product-desc-left">
                      <Box className="product-data">
                        Purity<span className="end-colun">:</span>
                      </Box>
                      <Box className="product-data">
                        Weight<span className="end-colun">:</span>
                      </Box>
                      <Box className="product-data">
                        Making Charges<span className="end-colun">:</span>
                      </Box>
                    </Box>
                    <Box className="product-desc-right">
                      <Box className="product-data right-side-data">
                        {daim && daim?.purity} %
                      </Box>
                      <Box className="product-data right-side-data">
                        {daim && daim?.total_weight} gm
                      </Box>
                      <Box className="product-data right-side-data">
                        {daim && daim?.making_charge} %
                      </Box>
                    </Box>
                  </Box>
                </>
              )
            })
          : ''}
      </>
    )
  }

  const renderFieldMaterialWise = () => {
    switch (true) {
      case data?.gold?.length > 0 &&
        data?.diamond?.length > 0 &&
        data?.materialIdArray?.length === 2:
        return (
          <>
            {goldFields()}
            {diamondFields()}
          </>
        )
      case data?.gold?.length > 0 &&
        data?.gemstone?.length > 0 &&
        data?.materialIdArray?.length === 2:
        return (
          <>
            {goldFields()}
            {gemstoneFields()}
          </>
        )
      case data?.silver?.length > 0 &&
        data?.diamond?.length > 0 &&
        data?.materialIdArray?.length === 2:
        return (
          <>
            {silverFields()}
            {diamondFields()}
          </>
        )
      case data?.silver?.length > 0 &&
        data?.gemstone?.length > 0 &&
        data?.materialIdArray?.length === 2:
        return (
          <>
            {silverFields()}
            {gemstoneFields()}
          </>
        )
      case data?.silver?.length > 0 &&
        data?.gold?.length > 0 &&
        data?.materialIdArray?.length === 2:
        return (
          <>
            {silverFields()}
            {goldFields()}
          </>
        )
      case data?.gemstone?.length > 0 &&
        data?.diamond?.length > 0 &&
        data?.materialIdArray?.length === 2:
        return (
          <>
            {gemstoneFields()}
            {diamondFields()}
          </>
        )
      case data?.gold?.length > 0 &&
        data?.silver?.length > 0 &&
        data?.diamond?.length > 0 &&
        data?.materialIdArray?.length === 3:
        return (
          <>
            {silverFields()}
            {goldFields()}
            {diamondFields()}
          </>
        )
      case data?.silver?.length > 0 &&
        data?.gold?.length > 0 &&
        data?.gemstone?.length > 0 &&
        data?.materialIdArray?.length === 3:
        return (
          <>
            {silverFields()}
            {goldFields()}
            {gemstoneFields()}
          </>
        )
      case data?.gold?.length > 0 &&
        data?.diamond?.length > 0 &&
        data?.gemstone?.length > 0 &&
        data?.materialIdArray?.length === 3:
        return (
          <>
            {goldFields()}
            {diamondFields()}
            {gemstoneFields()}
          </>
        )
      case data?.silver?.length > 0 &&
        data?.diamond?.length > 0 &&
        data?.gemstone?.length > 0 &&
        data?.materialIdArray?.length === 3:
        return (
          <>
            {silverFields()}
            {gemstoneFields()}
            {diamondFields()}
          </>
        )
      case data?.gold?.length > 0 &&
        data?.silver?.length > 0 &&
        data?.gemstone?.length > 0 &&
        data?.diamond?.length > 0 &&
        data?.materialIdArray?.length === 4:
        return (
          <>
            {goldFields()}
            {silverFields()}
            {gemstoneFields()}
            {diamondFields()}
          </>
        )
      case data?.gold?.length > 0:
        return goldFields()
      case data?.silver?.length > 0:
        return silverFields()
      case data?.diamond?.length > 0:
        return diamondFields()
      case data?.gemstone?.length > 0:
        return gemstoneFields()
      default:
        return ''
    }
  }

  return (
    <div>
      <Box className="product-desc-sec ">
        <Box className="container">
          <Box className="product-desc-title-box">
            <Typography
              variant="h4"
              component="h4"
              className="product-desc-title"
            >
              Product Description
            </Typography>
          </Box>
          <Box className="product-desc-content-box">
            <Box className="product-desc-main">
              <Box className="product-heading">Dimension</Box>
              <Box className="product-flex">
                <Box className="product-desc-left">
                  <Box className="product-data">
                    Length<span className="end-colun">:</span>
                  </Box>
                </Box>
                <Box className="product-desc-right">
                  <Box className="product-data right-side-data">
                    {data && data?.product_dimension?.length}
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box className="product-desc-main">
              <Box className="product-heading">Material</Box>
              {/* <Box className="product-flex"> */}
              {/* <Box className="product-desc-left">
                  <Box className="product-data">
                    Purity<span className="end-colun">:</span>
                  </Box>
                  <Box className="product-data">
                    Weight<span className="end-colun">:</span>
                  </Box>
                  <Box className="product-data">
                    Making Charges<span className="end-colun">:</span>
                  </Box>
                </Box> */}

              {/* <Box className="product-desc-right">
                  <Box className="product-data right-side-data">
                    {(() => {
                      if (data && data?.material_id?.material === "Gold") {
                        return <> {data && data?.gold?.purity} %</>;
                      } else if (
                        data &&
                        data?.material_id?.material === "Silver"
                      ) {
                        return <> {data && data?.silver?.purity} %</>;
                      } else if (
                        data &&
                        data?.material_id?.material == "Diamond"
                      ) {
                        return <> {data && data?.diamond?.purity} %</>;
                      } else {
                        return <> {data && data[0]?.gemstone?.purity} %</>;
                      }
                    })()}
                  </Box>
                  <Box className="product-data right-side-data">
                    {(() => {
                      if (data?.material_id?.material == "Gold") {
                        return <>{data && data?.gold?.weight} gm</>;
                      } else if (data?.material_id?.material == "Silver") {
                        return <> {data && data?.silver?.weight} gm</>;
                      } else if (data?.material_id?.material == "Diamond") {
                        return <> {data && data?.diamond?.weight} gm</>;
                      } else {
                        return <> {data && data?.gemstone?.weight} gm</>;
                      }
                    })()}
                  </Box>
                  <Box className="product-data right-side-data">
                    {(() => {
                      if (data?.material_id?.material == "Gold") {
                        return <>{data && data?.gold?.making_charges} %</>;
                      } else if (data?.material_id?.material == "Silver") {
                        return <> {data && data?.silver?.making_charges} %</>;
                      } else if (data?.material_id?.material == "Diamond") {
                        return <> {data && data?.diamond?.making_charges} %</>;
                      } else {
                        return <> {data && data?.gemstone?.making_charges} %</>;
                      }
                    })()}
                  </Box>
                </Box> */}

              {renderFieldMaterialWise()}

              {/* </Box> */}
            </Box>

            {/* <Box className="product-desc-main">
              <Box className="product-heading">Price Backup</Box>
              <Box className="product-flex">
                <Box className="product-desc-left">
                  <Box className="product-data">
                    Gold<span className="end-colun">:</span>
                  </Box>
                  <Box className="product-data">
                    Making Charges<span className="end-colun">:</span>
                  </Box>
                  <Box className="product-data">
                    Diamond<span className="end-colun">:</span>
                  </Box>
                  <Box className="product-data">
                    Gemstone<span className="end-colun">:</span>
                  </Box>
                </Box>
                <Box className="product-desc-right">
                  <Box className="product-data right-side-data"> </Box>
                  <Box className="product-data right-side-data"> </Box>
                  <Box className="product-data right-side-data"> </Box>
                  <Box className="product-data right-side-data"> </Box>
                </Box>
              </Box>
            </Box> */}

            {/* <Box className="product-desc-main">
              <Box className="product-data">GST</Box>
              <Box className="product-flex">
                <Box className="product-desc-left">
                  <Box className="product-heading">
                    Total Price<span className="end-colun">:</span>
                  </Box>
                </Box>
                <Box className="product-desc-right">
                  <Box className="product-data right-side-data"></Box>
                </Box>
              </Box>
            </Box> */}
          </Box>
        </Box>
      </Box>
    </div>
  )
}

export default ProductDescription
