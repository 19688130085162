import React, { useEffect, useState } from "react";
import UserIndex from "../../UserIndex";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { TextField } from "@mui/material";
import { PrimaryButton } from "../../../../component/user/button/Button";
import { contactUsAction } from "../../../../services/services";
import { toast } from "react-toastify";

const { FaqSaga } = UserIndex.UserSagaActions;

export default function ContactUs() {
    const [faq, setFaq] = useState("");

    const dispatch = UserIndex.useDispatch();
    const history = UserIndex.useHistory();
    const navigate = UserIndex.useHistory();
    const { Token } = UserIndex.useSelector((state) => {
        return state.Usersaga;
    });
    const FaqData = UserIndex.useSelector((state) => {
        return state.Usersaga.FaqData;
    });

    const [user, setUser] = useState({
        email: "",
        name: "",
        description: ""
    });

    const [emailError, setEmailError] = useState("");
    const [nameError, setNameError] = useState("");
    const [discriptionError, setDiscriptionError] = useState("");

    const [values, setValues] = React.useState({
        showPassword: false,
    });

    const handleChangeUser = (name, value) => {
        setUser({
            ...user,
            [name]: value,
        });
    };


    const errorHandling = () => {
        // Regular expression for email validation
       const emailRegex = /^(?!.*\.\.)(?!.*--)(?!\.)[a-zA-Z0-9._-]+(?<![-.])@[a-zA-Z0-9-]+(?<!-)\.[a-zA-Z]{2,}$/

        // Email validation
        if (user?.email === "") {
            setEmailError("Please enter email");
        } else if (!emailRegex.test(user?.email)) {
            setEmailError("Please enter a valid email");
        } else {
            setEmailError("");
        }

        return true;
    };
    const nameErrorHandle = () => {
        const nameRegex = /^[A-Za-z\s]+$/;
        if (user?.name === "") {
            setNameError("Please enter name");
            return false;
        } else if (!nameRegex.test(user?.name)) {
            setNameError("Please enter a valid full name (letters and spaces only)");
            return false;
        } else {
            setNameError("");
        }
        return true;
    }
    const disErrorHandle = () => {
        if (user?.description == "") {
            setDiscriptionError("Please enter a description");
            return false;
        } else {
            setDiscriptionError("");
        }
        return true;
    }

console.log(nameError,emailError,discriptionError);

    const handleSignUpSubmit = async () => {
        console.log(errorHandling(), "errorHandling()");
        console.log(nameErrorHandle(), "nameErrorHandle()");
        console.log(disErrorHandle(), "disErrorHandle()");
        if (errorHandling() && nameErrorHandle() && disErrorHandle()) {
            console.log("error1");
            contactUsAction(user, Token).then((res) => {
                console.log(res, 'nirmal');
                toast.success(res?.message)
                history.push('/user')

            })
        }
    };


    return (
        <div>
            <UserIndex.Header />
            <UserIndex.Box
                className="faq-main-banner"
                sx={{
                    background:
                        `linear-gradient(45deg,rgb(34 34 34 / 76%), rgb(34 34 34 / 76%)),url(${UserIndex.png?.faqBanner})`
                }}
            >
                <UserIndex.Box className="container">
                    <UserIndex.Box className="faq-inner-content">
                        {/* <UserIndex.Typography className="lot-text">
                            A LOT MORE QUESTIONS
                        </UserIndex.Typography> */}
                        <UserIndex.Box className="faq-title-main">
                            <UserIndex.Typography className="faq-typo-custom" variant="h1">
                                {" "}
                                Contact Us
                            </UserIndex.Typography>
                            <UserIndex.Box className="img-side-yellow">
                                <img
                                    src={UserIndex.png.banneryellow}
                                    className="yellow-left-banner"
                                ></img>
                            </UserIndex.Box>
                        </UserIndex.Box>
                    </UserIndex.Box>
                </UserIndex.Box>
            </UserIndex.Box>
            <ValidatorForm>
                <UserIndex.Box className="faq-question-main">
                    <UserIndex.Box className="container">
                        <UserIndex.Box className="main-form-question">

                            <UserIndex.Box className="input-box">
                                <UserIndex.FormHelperText className="input-lable">
                                    Full Name
                                    <span style={{ color: "red", fontSize: "15px" }}> </span>
                                </UserIndex.FormHelperText>
                                <UserIndex.TextField
                                    fullWidth
                                    id="fullWidth"
                                    className="form-control border-none"
                                    name="name"
                                    value={user?.name}
                                    placeholder="Enter full name"
                                    autoComplete="off"
                                    type="text"
                                    onChange={(e) => {
                                        if (e.target.value.length < 41) {
                                            handleChangeUser(e.target.name, e.target.value.trimStart().replace(/\s\s+/g, " "));
                                        }
                                    }}
                                    onKeyPress={(e) => {
                                        // Check if the pressed key is a special symbol or a number
                                        if (/[^\w\s]/.test(e.key) || /\d/.test(e.key)) {
                                            e.preventDefault();
                                        }
                                    }}
                                />
                                <UserIndex.Box className="sign-up-error-message">{nameError}</UserIndex.Box>
                            </UserIndex.Box>

                            <UserIndex.Box className="input-box">
                                <UserIndex.FormHelperText className="input-lable">
                                    Email
                                    <span style={{ color: "red", fontSize: "15px" }}> </span>
                                </UserIndex.FormHelperText>
                                <TextValidator
                                    fullWidth
                                    id="fullWidth"
                                    className="form-control border-none"
                                    name="email"
                                    value={user?.email}
                                    placeholder="Enter email"
                                    onChange={(e) => {
                                        if (e.target.value.length < 41) {
                                            handleChangeUser(e.target.name, e.target.value.trimStart());
                                        }
                                    }}
                                // validators={["required", "isEmail"]}
                                // errorMessages={[
                                //   "Please enter email",
                                //   "Please enter valid email",
                                // ]}
                                />
                                <UserIndex.Box className="sign-up-error-message">{emailError}</UserIndex.Box>
                            </UserIndex.Box>
                            <UserIndex.Box className="input-box">
                                <UserIndex.FormHelperText className="input-lable">
                                    Description
                                    <span style={{ color: "red", fontSize: "15px" }}> </span>
                                </UserIndex.FormHelperText>
                                <UserIndex.Box className="text-area-control-main custon-text-area-main">
                                    <UserIndex.TextareaAutosize
                                        autoComplete="off"
                                        minRows={0}
                                        maxRows={5}
                                        size="small"
                                        fullWidth
                                        className="form-control border-none text-area-control1 text-area-control-admin custon-text-area"
                                        name="description"
                                        type="text"
                                        placeholder="Enter description"
                                        value={user?.description}
                                        onChange={(e) => {
                                            if (e.target.value.length < 100) {
                                                handleChangeUser(e.target.name, e.target.value.trimStart().replace(/\s\s+/g, " "));
                                            }
                                        }}
                                    />
                                    {/* <ErrorMessage errors={errors} name="tAndC" /> */}
                                </UserIndex.Box>
                                <UserIndex.Box className="sign-up-error-message">{discriptionError}</UserIndex.Box>
                            </UserIndex.Box>
                            <UserIndex.Box className="modal-btn-box">
                                <PrimaryButton
                                    btnclass="btn modal-btn"
                                    onClick={
                                        handleSignUpSubmit

                                    }
                                    buttonvalue="Submit"
                                />
                            </UserIndex.Box>
                        </UserIndex.Box>

                    </UserIndex.Box>
                </UserIndex.Box>
            </ValidatorForm>
            <UserIndex.Footer />
        </div>
    );
}
