import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Api } from '../../../../config/api';
import dataService from '../../../../config/dataService';
import ContainedButton from '../../../../component/customComponents/buttons/ContainedButton';
import NoDataFound from '../../../../component/customComponents/noDataFound/NoDataFound';
import { toast } from 'react-toastify';
import Loader from '../../../../component/customComponents/loader/Loader';
import { price } from '../../../../component/customComponents/commonFunctions/CommonFunctions';
import moment from 'moment';
import { RupeeSymbol } from '../../../../component/customComponents/rupeeSymbol/RupeeSymbol';
import Mui from '../../../../component/customComponents/mui/Mui';
import MuiIcon from '../../../../component/customComponents/mui/MuiIcon';

import AdminIndex from '../../AdminIndex';

//#region ALL HOOKS AND VARIABALS
const columns = [
  { id: 'date', label: 'Date' },
  { id: 'seller', label: 'Seller', align: 'center' },
  {
    id: 'productid	',
    label: 'Product Id	',
    align: 'center',
  },
  {
    id: 'category',
    label: 'Category',
    align: 'center',
    width: '10px !important',
  },
  { id: 'product', label: 'Product', align: 'center' },
  {
    id: 'invoicetocustomer',
    label: <RupeeSymbol heading="Invoice to Customer" />,
    align: 'center',
  },
  {
    id: 'invoicetoseller',
    label: <RupeeSymbol heading="Invoice to Seller" />,
    align: 'center',
  },
  { id: 'action', label: '' },
];


const { getAllProduct } = AdminIndex.AdminSagaActions;
export default function PendingProducts() {
  const history = useHistory();
  let token = localStorage.getItem('auth');
  const dispatch = AdminIndex.useDispatch();
  // const [loading, setLoading] = useState(false);
  const [flag, setFlag] = useState(false);
  const [productData, setProductData] = useState([]);
  const [array, setArray] = useState([]);
  const [tempArray, setTempArray] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    getProductData();
    // getAdminDetails();
    setInterval(() => {
      setFlag(true);
    }, 500);
  }, []);
  //#endregion

  //#region ALL EVENT HANDLERS AND  FUCTIONS
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getProductData = async () => {
    
    dispatch(getAllProduct(token));
    // setLoading(true);
    // const urlencoded = new URLSearchParams();
    // await dataService
    //   .get(Api.Admin.getAllProduct, urlencoded)
    //   .then((res) => {
    //     setProductData(res.data.data);
    //   })
    //   .catch((err) => toast.error(err?.message || 'Something went wrong'))
    //   .finally(() => {
    //     setLoading(false);
    //   });
  };

  const loading = AdminIndex.useSelector((state) => {
    return state.AdminSaga.loading;
  });
  
  const pendingProduct = AdminIndex.useSelector((state) => {
    return state.AdminSaga.allProduct;
  });

  useEffect(() => {
    setProductData(pendingProduct);
  }, [pendingProduct]);

  const getAdminDetails = async () => {
    try {
      const adminData = await dataService.get(Api.Admin.getAdmin, {
        headers: {
          auth: token,
        },
      });
    } catch (err) {
      toast.error(err?.message || 'Something went wrong');
    }
  };

  const filterPendingProducts = () => {
    var filtered = [];
    for (var arr in productData) {
      if (productData[arr]?.status == '0') {
        filtered.push(productData[arr]);
      }
    }
    setArray(filtered);
    setTempArray(filtered);
  };

  const filter = (e) => {
    let data = e.target.value;
    let value = data.trim().toLowerCase();
    let filterData = tempArray.filter(
      (data) =>
        data?.name?.toLowerCase().includes(value) ||
        data?.company_name?.toLowerCase().includes(value) ||
        data?.product_id?.toLowerCase().includes(value) ||
        data?.materialIdArray
          ?.map((val) =>
            val.material_id?.material?.toLowerCase().includes(value)
          )
          .includes(true) ||
        data?.item_id?.item?.toLowerCase().includes(value)
    );
    setArray(filterData);
  };

  useEffect(() => {
    filterPendingProducts();
  }, [productData]);
  //#endregion

  //#region RETURN JSX
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Mui.Container sx={{ mt: 2, mb: 5 }} maxWidth="false">
          <Mui.Typography
            variant="h4"
            align="center"
            sx={{ fontWeight: 'bold' }}
          >
            Pending Products
          </Mui.Typography>
          <br />
          <Mui.Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Mui.Box>
              <Mui.GrayTooltip
                title="Search by (Seller / Product Id / Category / Product)"
                placement="bottom-start"
              >
                <Mui.SearchMaxWidth>
                  <Mui.SearchIconWrapper>
                    <MuiIcon.SearchIcon />
                  </Mui.SearchIconWrapper>
                  <Mui.StyledInputBase
                    onChange={filter}
                    placeholder="Search"
                    inputProps={{ 'aria-label': 'search' }}
                    sx={{
                      width: '100%',
                      '& .MuiInputBase-input': {
                        width: '100%',
                        '&:focus, &.MuiInputBase-input': {
                          width: '100%',
                        },
                      },
                    }}
                  />
                </Mui.SearchMaxWidth>
              </Mui.GrayTooltip>
            </Mui.Box>
            {/* <Box sx={{ display: 'flex', alignSelf: 'center' }}>
              <Button
                variant="contained"
                sx={{ borderRadius: 0, textTransform: 'none', ml: 2 }}
                onClick={() => {
                  history.push('/admin/productmanagement/addproducts');
                }}
              >
                Add Product
              </Button>
            </Box> */}
          </Mui.Box>
          <br />
          <Mui.Box className="main-payment-table">
            <Mui.TableContainer
              elevation={0}
              sx={{
                maxWidth: {
                  xs: '365px',
                  sm: '600px',
                  md: '100%',
                  lg: '100%',
                },
                '&::-webkit-scrollbar': {
                  width: 10,
                },
                '&::-webkit-scrollbar': {
                  height: '8px',
                  width: '12px',
                },
                '&::-webkit-scrollbar-track': {
                  backgroundColor: '#fff',
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: 'rgba(224, 224, 224, 1)',
                  borderRadius: '30px',
                  width: '20px',
                },
                '&::-webkit-scrollbar-thumb:hover': {
                  background: '#B0B0B0',
                },
              }}
              component={Mui.Paper}
            >
              <Mui.Table
                sx={{
                  width: {
                    xs: 'max-content',
                    sm: 'max-content',
                    md: '100%',
                    lg: '100%',
                  },
                }}
                size="small"
              >
                <Mui.TableHead>
                  <Mui.TableRow sx={{ bgcolor: 'black', whiteSpace: 'nowrap' }}>
                    {columns.map((column) => (
                      <Mui.TableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          minWidth: column.minWidth,
                          bgcolor: 'red',
                          width: column.width,
                        }}
                        className="pagination-table-header-row"
                      >
                        {column.label}
                      </Mui.TableCell>
                    ))}
                  </Mui.TableRow>
                </Mui.TableHead>
                <Mui.TableBody>
                  {array.length
                    ? array
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => {
                          return (
                            <Mui.StyledTableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row._id}
                              sx={{
                                '&:last-child td, &:last-child th': {
                                  border: 0,
                                },
                                whiteSpace: 'nowrap',
                              }}
                            >
                              <Mui.StyledTableCell
                                align="left"
                                sx={{ border: '0px' }}
                              >
                                {/* {new Date(row.updatedAt).toLocaleDateString()} */}
                                {moment(row.updatedAt).format('DD/MM/YYYY')}
                              </Mui.StyledTableCell>
                              <Mui.StyledTableCell
                                align="center"
                                sx={{ border: '0px' }}
                              >
                                {row.name || row.company_name}
                              </Mui.StyledTableCell>
                              <Mui.StyledTableCell
                                align="center"
                                sx={{ border: '0px' }}
                              >
                                {row.product_id}
                              </Mui.StyledTableCell>
                              {/* <StyledTableCell align="center">
                              {row?.materialIdArray?.map((val) => (
                                <ol>{val.material_id.material}</ol>
                              ))}
                            </StyledTableCell> */}
                              <Mui.GrayTooltip1
                                title={String(row?.materialIdArray
                                    ?.map((val) => val.material_id?.material)
                                    .sort()).replace(/,/g, ', ')}
                                placement="bottom"
                              >
                                <Mui.StyledTableCell
                                  align="center"
                                  sx={{
                                    display: 'block',
                                    width: '90px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden !important',
                                    textOverflow: 'ellipsis',
                                    p: '14px',
                                    border: '0px',
                                  }}
                                >
                                  {row?.materialIdArray
                                    ?.map((val) => val.material_id?.material)
                                    .sort()}
                                </Mui.StyledTableCell>
                              </Mui.GrayTooltip1>
                              <Mui.StyledTableCell
                                align="center"
                                sx={{ border: '0px' }}
                              >
                                {row?.item_id?.item}
                              </Mui.StyledTableCell>
                              <Mui.StyledTableCell
                                align="center"
                                sx={{ border: '0px' }}
                              >
                                {price(row?.total_payable_amount)}
                              </Mui.StyledTableCell>
                              <Mui.StyledTableCell
                                align="center"
                                sx={{ border: '0px' }}
                              >
                                {price(row?.admin_to_seller)}
                              </Mui.StyledTableCell>
                              <Mui.StyledTableCell
                                align="center"
                                sx={{ border: '0px' }}
                              >
                                <ContainedButton
                                  name="View"
                                  onClick={() =>
                                    history.push(
                                      `/admin/productmanagement/pendingproducts/viewpendingproducts/${row._id}`
                                    )
                                  }
                                />
                                <ContainedButton
                                  className="common-button"
                                  name="Edit"
                                  onClick={() =>
                                    history.push(
                                      `/admin/updateproductdetails/${row?._id}`
                                    )
                                  }
                                />
                              </Mui.StyledTableCell>
                            </Mui.StyledTableRow>
                          );
                        })
                    : ''}
                </Mui.TableBody>
              </Mui.Table>
              {array.length == 0 && flag ? <NoDataFound /> : ''}
            </Mui.TableContainer>
            <Mui.TablePagination
              rowsPerPageOptions={[5, 10, 15]}
              component="div"
              count={array.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Mui.Box>
        </Mui.Container>
      )}
    </>
  );
}
//#endregion
