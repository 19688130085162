import React, { useState, useEffect } from "react";
import UserIndex from "../../UserIndex";

const {
  ViewCustomProductSaga,
  RaiseCustomProductSaga,
  RecievedCustomProductSaga,
  AcceptCustomProductSaga,
  GetSingleCustomProductSaga,
} = UserIndex.UserSagaActions;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 5,
};

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <UserIndex.Box sx={{ p: 3 }}>
          <UserIndex.Typography>{children}</UserIndex.Typography>
        </UserIndex.Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: UserIndex.PropTypes.node,
  index: UserIndex.PropTypes.number.isRequired,
  value: UserIndex.PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const arrayIncludesInObj = (arr, key, valueToCheck) => {
  return arr.some((value) => value[key] === valueToCheck);
};

const CustomeProduct = () => {
  const [value, setValue] = useState(0);
  const [tab, setTab] = useState(0);
  const [requestRaised, setRequestRaised] = useState("");
  const [Url, setUrl] = useState([]);
  const [requestRecieved, setRequestRecieved] = useState("");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [goldMakingCharge, setGoldMakingCharge] = useState("");
  const [silverMakingCharge, setSilverMakingCharge] = useState("");
  const [diamondMakingCharge, setDiamondMakingCharge] = useState("");
  const [diamondPricePerQuantity, setDiamondPricePerQuantity] = useState("");
  const [gemstoneMakingCharge, setGemstoneMakingCharge] = useState("");
  const [gemstonePricePerQuantity, setGemstonePricePerQuantity] = useState("");
  const [singleCustomProductId, setSingleCustomProductId] = useState("");
  const [sellerDetalis, setSellerDetails] = useState();
  const [goldMakingChargeError, setGoldMakingChargeError] = useState("");
  const [silverMakingChargeError, setSilverMakingChargeError] = useState("");
  const [diamondMakingChargeError, setDiamondMakingChargeError] = useState("");
  const [diamondPricePerQuantityError, setDiamondPricePerQuantityError] =
    useState("");
  const [gemstoneMakingChargeError, setGemstoneMakingChargeError] =
    useState("");
  const [gemstonePricePerQuantityError, setGemstonePricePerQuantityError] =
    useState("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const params = UserIndex.useParams();
  const dispatch = UserIndex.useDispatch();
  const history = UserIndex.useHistory();
  const navigate = UserIndex.useHistory();
  const loding = UserIndex.useSelector((state) => {
    return state.Usersaga.loading;
  });
  const Token = UserIndex.useSelector((state) => {
    return state.Usersaga.Token;
  });
  const ViewCustomProductData = UserIndex.useSelector((state) => {
    return state.Usersaga.ViewCustomProductData;
  });
  const RaiseCustomProductData = UserIndex.useSelector((state) => {
    return state.Usersaga.RaiseCustomProductData;
  });
  const RecievedCustomProductData = UserIndex.useSelector((state) => {
    return state.Usersaga.RecievedCustomProductData;
  });

  const AcceptCustomProductData = UserIndex.useSelector((state) => {
    return state.Usersaga.AcceptCustomProductData;
  });
  const Userdata = UserIndex.useSelector((state) => {
    return state.Usersaga.Userdata;
  });

  const GetSingleCustomProductData = UserIndex.useSelector((state) => {
    return state.Usersaga.GetSingleCustomProductData;
  });

  const getViewCustomProduct = async () => {
    let urlencoded = new URLSearchParams();
    dispatch(ViewCustomProductSaga(urlencoded, navigate, Token));
  };

  const getRecievedCustomProduct = async () => {
    let urlencoded = new URLSearchParams();
    dispatch(RecievedCustomProductSaga(urlencoded, navigate, Token));
  };

  useEffect(() => {
    getRecievedCustomProduct();
    getViewCustomProduct();
  }, [tab, open]);

  useEffect(() => {
    setRequestRecieved(RecievedCustomProductData);
    setRequestRaised(ViewCustomProductData);
    ViewCustomProductData?.map((row) => {
      setUrl(row.image);
    });
  }, [ViewCustomProductData, open]);

  useEffect(() => {
    setRequestRecieved(RecievedCustomProductData);
  }, [RecievedCustomProductData, open]);

  const handelsubmit = (raised) => {
    let urlencoded = new FormData();
    urlencoded.append("id", raised._id);
    urlencoded.append("isSubmitted", "1");
    dispatch(RaiseCustomProductSaga(urlencoded, navigate, Token));
  };

  const getSingleCustomProductData = (id) => {
    setSingleCustomProductId(id);
    let urlencoded = new URLSearchParams();
    urlencoded.append("id", id);
    dispatch(GetSingleCustomProductSaga(urlencoded, navigate, Token));
  };

  const handleChangeGoldMaking = (e) => {
    setGoldMakingCharge(e.target.value);
  };

  const handleChangeSilverMaking = (e) => {
    setSilverMakingCharge(e.target.value);
  };

  const handleChangegemstoneMaking = (e) => {
    setGemstoneMakingCharge(e.target.value);
  };

  const handleChangegemstonePricePerQuantity = (e) => {
    setGemstonePricePerQuantity(e.target.value);
  };

  const handleChangeDiamondMaking = (e) => {
    setDiamondMakingCharge(e.target.value);
  };

  const handleChangeDiamondPricePerQuantity = (e) => {
    setDiamondPricePerQuantity(e.target.value);
  };

  const errorHandlingFields = () => {
    if (!goldMakingCharge) {
      setGoldMakingChargeError("Please enter Supplier'smaking charge");
    } else if (Number(goldMakingCharge) <= 0.99) {
      setGoldMakingChargeError("Making charge cannot be zero");
    } else if (Number(goldMakingCharge) >= 100) {
      setGoldMakingChargeError("Making charge cannot be out of 100");
    } else {
      setGoldMakingChargeError("");
    }

    if (!silverMakingCharge) {
      setSilverMakingChargeError("Please enter Supplier'smaking charge");
    } else if (Number(silverMakingCharge) <= 0.99) {
      setSilverMakingChargeError("Making charge cannot be zero");
    } else if (Number(silverMakingCharge) >= 100) {
      setSilverMakingChargeError("Making charge cannot be out of 100");
    } else {
      setSilverMakingChargeError("");
    }

    if (!diamondMakingCharge) {
      setDiamondMakingChargeError("Please enter Supplier'smaking charge");
    } else if (Number(diamondMakingCharge) <= 0.99) {
      setDiamondMakingChargeError("Making charge cannot be zero");
    } else if (Number(diamondMakingCharge) >= 100) {
      setDiamondMakingChargeError("Making charge cannot be out of 100");
    } else {
      setDiamondMakingChargeError("");
    }

    if (!diamondPricePerQuantity) {
      setDiamondPricePerQuantityError("Please enter Supplier'smaking charge");
    } else if (Number(diamondPricePerQuantity) <= 0.99) {
      setDiamondPricePerQuantityError("Making charge cannot be zero");
    } else {
      setDiamondPricePerQuantityError("");
    }

    if (!gemstoneMakingCharge) {
      setGemstoneMakingChargeError("Please enter Supplier'smaking charge");
    } else if (Number(gemstoneMakingCharge) <= 0.99) {
      setGemstoneMakingChargeError("Making charge cannot be zero");
    } else if (Number(gemstoneMakingCharge) >= 100) {
      setGemstoneMakingChargeError("Making charge cannot be out of 100");
    } else {
      setGemstoneMakingChargeError("");
    }

    if (!gemstonePricePerQuantity) {
      setGemstonePricePerQuantityError("Please enter Supplier'smaking charge");
    } else if (Number(gemstonePricePerQuantity) <= 0.99) {
      setGemstonePricePerQuantityError("Making charge cannot be zero");
    } else {
      setGemstonePricePerQuantityError("");
    }
  };

  const getAcceptCustomProduct = async () => {
    // const customProductCondition =
    //   !goldMakingCharge ||
    //   Number(goldMakingCharge) >= 100 ||
    //   Number(goldMakingCharge) <= 0.99 ||
    //   !silverMakingCharge ||
    //   Number(silverMakingCharge) >= 100 ||
    //   Number(silverMakingCharge) <= 0.99;
    // errorHandlingFields();
    // if (customProductCondition) {
    //   return;
    // }
    let urlencoded = new URLSearchParams();
    urlencoded.append("id", singleCustomProductId);
    switch (true) {
    }
    if (
      GetSingleCustomProductData?.gold.length !== 0 &&
      GetSingleCustomProductData?.materialIdArray.length === 1
    ) {
      urlencoded.append("making_chargesGold", goldMakingCharge);
    } else if (
      GetSingleCustomProductData?.silver &&
      GetSingleCustomProductData?.materialIdArray.length === 1
    ) {
      urlencoded.append("making_chargesSilver", silverMakingCharge);
    } else if (
      GetSingleCustomProductData.gemstone.length > 0 &&
      GetSingleCustomProductData?.materialIdArray.length === 1
    ) {
      urlencoded.append("making_chargesGemstone", gemstoneMakingCharge);
      urlencoded.append(
        "price_per_quantity_gemstone",
        gemstonePricePerQuantity
      );
    } else if (
      GetSingleCustomProductData.diamond.length > 0 &&
      GetSingleCustomProductData?.materialIdArray.length === 1
    ) {
      urlencoded.append("making_chargesDiamond", diamondMakingCharge);
      urlencoded.append("price_per_quantity_diamond", diamondPricePerQuantity);
    } else if (
      GetSingleCustomProductData?.gold &&
      GetSingleCustomProductData.diamond.length > 0 &&
      GetSingleCustomProductData?.materialIdArray.length === 2
    ) {
      urlencoded.append("making_chargesGold", goldMakingCharge);
      urlencoded.append("making_chargesDiamond", diamondMakingCharge);
      urlencoded.append("price_per_quantity_diamond", diamondPricePerQuantity);
    } else if (
      GetSingleCustomProductData?.gold &&
      GetSingleCustomProductData.gemstone.length > 0 &&
      GetSingleCustomProductData?.materialIdArray.length === 2
    ) {
      urlencoded.append("making_chargesGold", goldMakingCharge);
      urlencoded.append("making_chargesGemstone", gemstoneMakingCharge);
      urlencoded.append(
        "price_per_quantity_gemstone",
        gemstonePricePerQuantity
      );
    } else if (
      GetSingleCustomProductData?.silver &&
      GetSingleCustomProductData.diamond.length > 0 &&
      GetSingleCustomProductData?.materialIdArray.length === 2
    ) {
      urlencoded.append("making_chargesSilver", silverMakingCharge);
      urlencoded.append("making_chargesDiamond", diamondMakingCharge);
      urlencoded.append("price_per_quantity_diamond", diamondPricePerQuantity);
    } else if (
      GetSingleCustomProductData?.silver &&
      GetSingleCustomProductData.gemstone.length > 0 &&
      GetSingleCustomProductData?.materialIdArray.length === 2
    ) {
      urlencoded.append("making_chargesSilver", silverMakingCharge);
      urlencoded.append("making_chargesGemstone", gemstoneMakingCharge);
      urlencoded.append(
        "price_per_quantity_gemstone",
        gemstonePricePerQuantity
      );
    } else if (
      GetSingleCustomProductData?.gold &&
      GetSingleCustomProductData?.silver &&
      GetSingleCustomProductData?.materialIdArray.length === 2
    ) {
      urlencoded.append("making_chargesSilver", silverMakingCharge);
      urlencoded.append("making_chargesGold", goldMakingCharge);
    } else if (
      GetSingleCustomProductData.diamond.length > 0 &&
      GetSingleCustomProductData.gemstone.length > 0 &&
      GetSingleCustomProductData?.materialIdArray.length === 2
    ) {
      urlencoded.append("making_chargesGemstone", gemstoneMakingCharge);
      urlencoded.append(
        "price_per_quantity_gemstone",
        gemstonePricePerQuantity
      );
      urlencoded.append("making_chargesDiamond", diamondMakingCharge);
      urlencoded.append("price_per_quantity_diamond", diamondPricePerQuantity);
    } else if (
      GetSingleCustomProductData?.gold &&
      GetSingleCustomProductData?.silver &&
      GetSingleCustomProductData.diamond.length > 0 &&
      GetSingleCustomProductData?.materialIdArray.length === 3
    ) {
      urlencoded.append("making_chargesGold", goldMakingCharge);
      urlencoded.append("making_chargesSilver", silverMakingCharge);
      urlencoded.append("making_chargesDiamond", diamondMakingCharge);
      urlencoded.append("price_per_quantity_diamond", diamondPricePerQuantity);
    } else if (
      GetSingleCustomProductData?.gold &&
      GetSingleCustomProductData?.silver &&
      GetSingleCustomProductData.gemstone.length > 0 &&
      GetSingleCustomProductData?.materialIdArray.length === 3
    ) {
      urlencoded.append("making_chargesGold", goldMakingCharge);
      urlencoded.append("making_chargesSilver", silverMakingCharge);
      urlencoded.append("making_chargesGemstone", gemstoneMakingCharge);
      urlencoded.append(
        "price_per_quantity_gemstone",
        gemstonePricePerQuantity
      );
    } else if (
      GetSingleCustomProductData?.gold &&
      GetSingleCustomProductData.diamond.length > 0 &&
      GetSingleCustomProductData.gemstone.length > 0 &&
      GetSingleCustomProductData?.materialIdArray.length === 3
    ) {
      urlencoded.append("making_chargesGold", goldMakingCharge);
      urlencoded.append("making_chargesGemstone", gemstoneMakingCharge);
      urlencoded.append(
        "price_per_quantity_gemstone",
        gemstonePricePerQuantity
      );
      urlencoded.append("making_chargesDiamond", diamondMakingCharge);
      urlencoded.append("price_per_quantity_diamond", diamondPricePerQuantity);
    } else if (
      GetSingleCustomProductData?.silver &&
      GetSingleCustomProductData.diamond.length > 0 &&
      GetSingleCustomProductData.gemstone.length > 0 &&
      GetSingleCustomProductData?.materialIdArray.length === 3
    ) {
      urlencoded.append("making_chargesSilver", silverMakingCharge);
      urlencoded.append("making_chargesGemstone", gemstoneMakingCharge);
      urlencoded.append(
        "price_per_quantity_gemstone",
        gemstonePricePerQuantity
      );
      urlencoded.append("making_chargesDiamond", diamondMakingCharge);
      urlencoded.append("price_per_quantity_diamond", diamondPricePerQuantity);
    } else if (
      GetSingleCustomProductData?.gold &&
      GetSingleCustomProductData?.silver &&
      GetSingleCustomProductData.diamond.length > 0 &&
      GetSingleCustomProductData.gemstone.length > 0 &&
      GetSingleCustomProductData?.materialIdArray.length === 4
    ) {
      urlencoded.append("making_chargesGold", goldMakingCharge);
      urlencoded.append("making_chargesSilver", silverMakingCharge);
      urlencoded.append("making_chargesGemstone", gemstoneMakingCharge);
      urlencoded.append(
        "price_per_quantity_gemstone",
        gemstonePricePerQuantity
      );
      urlencoded.append("making_chargesDiamond", diamondMakingCharge);
      urlencoded.append("price_per_quantity_diamond", diamondPricePerQuantity);
    }
    dispatch(AcceptCustomProductSaga(urlencoded, navigate, Token));
    handleClose();
  };

  const getSellerData = () => {
    RecievedCustomProductData.map((row) => {
      // setSellerDetails(row.sellers);
    });
  };

  useEffect(() => {
    // if(RecievedCustomProductData.length > 0){
    getSellerData();
    // }
  }, [RecievedCustomProductData, sellerDetalis]);

  const goldFields = () => {
    return (
      <>
        <UserIndex.Box className="custome-making-main">
          {GetSingleCustomProductData?.gold?.map((item, i) => {
            return (
              <UserIndex.Box className="addinfo-text-box custome-making-charge">
                <UserIndex.Typography className="custom-modal-heading-text">
                  Gold
                </UserIndex.Typography>
                <UserIndex.Box className="refrence-product-input custome-product-text-field">
                  <UserIndex.Box className="input-box addproduct-1-input-box">
                    <UserIndex.FormHelperText className="input-lable">
                      Making Charges (In %)
                    </UserIndex.FormHelperText>
                    <UserIndex.TextField
                      className="form-control border-none customproduct-control"
                      id="outlined-basic"
                      variant="outlined"
                      type="number"
                      value={goldMakingCharge}
                      onChange={handleChangeGoldMaking}
                    />
                    <UserIndex.FormHelperText className="error-input-lable">
                      {goldMakingChargeError}
                    </UserIndex.FormHelperText>
                  </UserIndex.Box>
                </UserIndex.Box>
              </UserIndex.Box>
            );
          })}
        </UserIndex.Box>
      </>
    );
  };

  const silverFields = () => {
    return (
      <>
        <UserIndex.Box className="custome-making-main">
          {GetSingleCustomProductData?.silver?.map((item, i) => {
            return (
              <UserIndex.Box className="addinfo-text-box custome-making-charge">
                <UserIndex.Typography className="custom-modal-heading-text">
                  Silver
                </UserIndex.Typography>
                <UserIndex.Box className="refrence-product-input">
                  <UserIndex.Box className="input-box addproduct-1-input-box">
                    <UserIndex.FormHelperText className="input-lable">
                      Making Charges (In %)
                    </UserIndex.FormHelperText>

                    <UserIndex.TextField
                      className="form-control border-none customproduct-control"
                      id="outlined-basic"
                      variant="outlined"
                      type="number"
                      value={silverMakingCharge}
                      onChange={handleChangeSilverMaking}
                    />

                    <UserIndex.FormHelperText className="error-input-lable">
                      {silverMakingChargeError}
                    </UserIndex.FormHelperText>
                  </UserIndex.Box>
                </UserIndex.Box>
              </UserIndex.Box>
            );
          })}
        </UserIndex.Box>
      </>
    );
  };

  const gemstoneFields = () => {
    return (
      <>
        <UserIndex.Box className="custome-making-main">
          <UserIndex.Box sx={{ display: "contents" }}>
            {GetSingleCustomProductData?.gemstone?.map((item, i) => {
              return (
                <>
                  <UserIndex.Box className="addinfo-text-box custome-making-charge">
                    <UserIndex.Typography className="custom-modal-heading-text">
                      Gemstone
                    </UserIndex.Typography>
                    <UserIndex.Box className="custome-product-charge-box">
                      <UserIndex.Box className="refrence-product-input">
                        <UserIndex.Box className="input-box addproduct-1-input-box">
                          <UserIndex.FormHelperText className="input-lable">
                            Making Charges (In %)
                          </UserIndex.FormHelperText>

                          <UserIndex.TextField
                            className="form-control border-none customproduct-control"
                            id="outlined-basic"
                            variant="outlined"
                            type="number"
                            value={gemstoneMakingCharge}
                            onChange={handleChangegemstoneMaking}
                          />

                          <UserIndex.FormHelperText className="error-input-lable">
                            {gemstoneMakingChargeError}
                          </UserIndex.FormHelperText>
                        </UserIndex.Box>
                      </UserIndex.Box>
                      <UserIndex.Box className="refrence-product-input ">
                        <UserIndex.Box className="input-box addproduct-1-input-box">
                          <UserIndex.FormHelperText className="input-lable">
                            Price Per Quantity
                          </UserIndex.FormHelperText>

                          <UserIndex.TextField
                            className="form-control border-none customproduct-control"
                            id="outlined-basic"
                            variant="outlined"
                            type="number"
                            value={gemstonePricePerQuantity}
                            onChange={handleChangegemstonePricePerQuantity}
                          />

                          <UserIndex.FormHelperText className="error-input-lable">
                            {gemstonePricePerQuantityError}
                          </UserIndex.FormHelperText>
                        </UserIndex.Box>
                      </UserIndex.Box>
                    </UserIndex.Box>
                  </UserIndex.Box>
                </>
              );
            })}
          </UserIndex.Box>
        </UserIndex.Box>
      </>
    );
  };

  const diamondFields = () => {
    return (
      <>
        <UserIndex.Box className="custome-making-main">
          <UserIndex.Box sx={{ display: "contents" }}>
            {GetSingleCustomProductData?.diamond?.map((item, i) => {
              return (
                <>
                  <UserIndex.Box className="addinfo-text-box custome-making-charge">
                    <UserIndex.Typography className="custom-modal-heading-text">
                      Diamond
                    </UserIndex.Typography>
                    <UserIndex.Box className="custome-product-charge-box">
                      <UserIndex.Box className="refrence-product-input ">
                        <UserIndex.Box className="input-box addproduct-1-input-box">
                          <UserIndex.FormHelperText className="input-lable">
                            Making Charges (In %)
                          </UserIndex.FormHelperText>

                          <UserIndex.TextField
                            className="form-control border-none customproduct-control"
                            id="outlined-basic"
                            variant="outlined"
                            type="number"
                            value={diamondMakingCharge}
                            onChange={handleChangeDiamondMaking}
                          />

                          <UserIndex.FormHelperText className="error-input-lable">
                            {diamondMakingChargeError}
                          </UserIndex.FormHelperText>
                        </UserIndex.Box>
                      </UserIndex.Box>
                      <UserIndex.Box className="refrence-product-input ">
                        <UserIndex.Box className="input-box addproduct-1-input-box">
                          <UserIndex.FormHelperText className="input-lable">
                            Price Per Quantity
                          </UserIndex.FormHelperText>

                          <UserIndex.TextField
                            className="form-control border-none customproduct-control"
                            id="outlined-basic"
                            variant="outlined"
                            type="number"
                            value={diamondPricePerQuantity}
                            onChange={handleChangeDiamondPricePerQuantity}
                          />

                          <UserIndex.FormHelperText className="error-input-lable">
                            {diamondPricePerQuantityError}
                          </UserIndex.FormHelperText>
                        </UserIndex.Box>
                      </UserIndex.Box>
                    </UserIndex.Box>
                  </UserIndex.Box>
                </>
              );
            })}
          </UserIndex.Box>
        </UserIndex.Box>
      </>
    );
  };

  const renderFieldMaterialWise = () => {
    switch (true) {
      case GetSingleCustomProductData?.gold?.length > 0 &&
        GetSingleCustomProductData?.materialIdArray?.length === 1:
        return goldFields();
      case GetSingleCustomProductData?.silver?.length > 0 &&
        GetSingleCustomProductData?.materialIdArray?.length === 1:
        return silverFields();
      case GetSingleCustomProductData?.diamond?.length > 0 &&
        GetSingleCustomProductData?.materialIdArray?.length === 1:
        return diamondFields();
      case GetSingleCustomProductData?.gemstone?.length > 0 &&
        GetSingleCustomProductData?.materialIdArray?.length === 1:
        return gemstoneFields();
      case GetSingleCustomProductData?.gold?.length > 0 &&
        GetSingleCustomProductData?.diamond?.length > 0 &&
        GetSingleCustomProductData?.materialIdArray?.length === 2:
        return (
          <>
            {goldFields()}
            {diamondFields()}
          </>
        );
      case GetSingleCustomProductData?.gold?.length > 0 &&
        GetSingleCustomProductData.gemstone?.length > 0 &&
        GetSingleCustomProductData?.materialIdArray?.length === 2:
        return (
          <>
            {goldFields()}
            {gemstoneFields()}
          </>
        );
      case GetSingleCustomProductData?.silver?.length > 0 &&
        GetSingleCustomProductData.diamond?.length > 0 &&
        GetSingleCustomProductData?.materialIdArray?.length === 2:
        return (
          <>
            {silverFields()}
            {diamondFields()}
          </>
        );
      case GetSingleCustomProductData?.silver?.length > 0 &&
        GetSingleCustomProductData.gemstone?.length > 0 &&
        GetSingleCustomProductData?.materialIdArray?.length === 2:
        return (
          <>
            {silverFields()}
            {gemstoneFields()}
          </>
        );
      case GetSingleCustomProductData?.silver?.length > 0 &&
        GetSingleCustomProductData?.gold?.length > 0 &&
        GetSingleCustomProductData?.materialIdArray?.length === 2:
        return (
          <>
            {silverFields()}
            {goldFields()}
          </>
        );
      case GetSingleCustomProductData.gemstone?.length > 0 &&
        GetSingleCustomProductData.diamond?.length > 0 &&
        GetSingleCustomProductData?.materialIdArray?.length === 2:
        return (
          <>
            {gemstoneFields()}
            {diamondFields()}
          </>
        );
      case GetSingleCustomProductData?.gold?.length > 0 &&
        GetSingleCustomProductData?.silver?.length > 0 &&
        GetSingleCustomProductData.diamond?.length > 0 &&
        GetSingleCustomProductData?.materialIdArray?.length === 3:
        return (
          <>
            {silverFields()}
            {goldFields()}
            {diamondFields()}
          </>
        );
      case GetSingleCustomProductData?.gold?.length > 0 &&
        GetSingleCustomProductData?.silver?.length > 0 &&
        GetSingleCustomProductData?.gemstone?.length > 0 &&
        GetSingleCustomProductData?.materialIdArray?.length === 3:
        return (
          <>
            {silverFields()}
            {goldFields()}
            {gemstoneFields()}
          </>
        );
      case GetSingleCustomProductData?.gold?.length > 0 &&
        GetSingleCustomProductData.diamond?.length > 0 &&
        GetSingleCustomProductData.gemstone?.length > 0 &&
        GetSingleCustomProductData?.materialIdArray?.length === 3:
        return (
          <>
            {goldFields()}
            {diamondFields()}
            {gemstoneFields()}
          </>
        );
      case GetSingleCustomProductData?.silver?.length > 0 &&
        GetSingleCustomProductData.diamond?.length > 0 &&
        GetSingleCustomProductData.gemstone?.length > 0 &&
        GetSingleCustomProductData?.materialIdArray?.length === 3:
        return (
          <>
            {silverFields()}
            {gemstoneFields()}
            {diamondFields()}
          </>
        );
      case GetSingleCustomProductData?.gold?.length > 0 &&
        GetSingleCustomProductData?.silver?.length > 0 &&
        GetSingleCustomProductData?.diamond?.length > 0 &&
        GetSingleCustomProductData?.gemstone?.length > 0 &&
        GetSingleCustomProductData?.materialIdArray?.length === 4:
        return (
          <>
            {goldFields()}
            {silverFields()}
            {gemstoneFields()}
            {diamondFields()}
          </>
        );

      default:
        return;
    }
  };

  return (
    <div>
      <UserIndex.Header />
      <UserIndex.Box className="body-bg">
        <UserIndex.Box className="myorder-sec">
          <UserIndex.Box className="container">
            <UserIndex.Box className="row">
              <UserIndex.Box className="addinfo-text-box pg-main-title-box">
                <UserIndex.Typography
                  variant="h2"
                  className="addinfo-text pg-main-title"
                >
                  Custom Products
                </UserIndex.Typography>
              </UserIndex.Box>
              <UserIndex.Box className="mo-tabs-box">
                <UserIndex.Box className="mo-tabs-main custom-tabs-main">
                  <UserIndex.Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    className="mo-tabs"
                  >
                    <UserIndex.Tab
                      label="Request Raised"
                      onClick={() => setTab(0)}
                      className="mo-tabs-lable"
                    />
                    <UserIndex.Tab
                      label="Request Received"
                      onClick={() => setTab(2)}
                      className="mo-tabs-lable"
                    />
                  </UserIndex.Tabs>
                </UserIndex.Box>
                {tab == 0 ? (
                  <>
                    <UserIndex.Box className="mo-table-main" sx={{}}>
                      <UserIndex.TableContainer
                        component={UserIndex.Paper}
                        className="mo-table-container"
                      >
                        <UserIndex.Table
                          sx={{ minWidth: 650 }}
                          aria-label="simple table"
                          className="mo-table "
                        >
                          <UserIndex.TableHead className="mo-tablehead">
                            <UserIndex.TableRow>
                              <UserIndex.TableCell className="custom-th">
                                Image
                              </UserIndex.TableCell>
                              <UserIndex.TableCell className="custom-th">
                                Meta Title
                              </UserIndex.TableCell>
                              <UserIndex.TableCell className="custom-th">
                                Specify
                              </UserIndex.TableCell>
                              <UserIndex.TableCell className="custom-th">
                                Acceptance Window
                              </UserIndex.TableCell>
                              <UserIndex.TableCell className="custom-th">
                                Comments
                              </UserIndex.TableCell>
                              <UserIndex.TableCell className="custom-th">
                                Status
                              </UserIndex.TableCell>
                              <UserIndex.TableCell className="custom-th">
                                Action
                              </UserIndex.TableCell>
                            </UserIndex.TableRow>
                          </UserIndex.TableHead>
                          <UserIndex.TableBody className="mo-table-body">
                            {requestRaised?.length
                              ? requestRaised.map((raised, index) => {
                                  return (
                                    <UserIndex.TableRow
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      <UserIndex.TableCell
                                        component="td"
                                        scope="row"
                                        className="custom-td"
                                      >
                                        <img
                                          src={raised?.image[0]}
                                          className="custome-img"
                                        ></img>
                                      </UserIndex.TableCell>
                                      <UserIndex.TableCell
                                        component="td"
                                        className="custom-td"
                                      >
                                        {raised.product_dimension.meta_title}
                                      </UserIndex.TableCell>
                                      <UserIndex.TableCell
                                        component="td"
                                        className="custom-td"
                                      >
                                        {raised?.specify}
                                      </UserIndex.TableCell>
                                      <UserIndex.TableCell
                                        component="td"
                                        className="custom-td"
                                      >
                                        {raised?.accept_by_seller == "1" ? (
                                          <>
                                            <UserIndex.Box
                                              sx={{ color: "#198F0B" }}
                                            >
                                              Accepted
                                            </UserIndex.Box>
                                          </>
                                        ) : (
                                          <>
                                            {raised?.status == "0" ? (
                                              <>
                                                <UserIndex.Box
                                                  sx={{ color: "#FF6A28" }}
                                                >
                                                  Pending
                                                </UserIndex.Box>
                                              </>
                                            ) : raised?.status == "1" ? (
                                              <>
                                                <UserIndex.Box
                                                  sx={{ color: "#198F0B" }}
                                                >
                                                  Approved
                                                </UserIndex.Box>
                                              </>
                                            ) : raised?.status == "2" ? (
                                              <>
                                                <UserIndex.Box
                                                  sx={{ color: "#FF0303" }}
                                                >
                                                  Rejected
                                                </UserIndex.Box>
                                              </>
                                            ) : (
                                              ""
                                            )}
                                          </>
                                        )}
                                      </UserIndex.TableCell>
                                      <UserIndex.TableCell
                                        component="td"
                                        className="custom-td"
                                      >
                                        {raised?.reasonOfRejection
                                          ? raised?.reasonOfRejection
                                          : "-"}
                                      </UserIndex.TableCell>
                                      <UserIndex.TableCell
                                        component="td"
                                        className="custom-td"
                                      >
                                        {raised?.received == "1"
                                          ? "Request Allocated"
                                          : "-"}
                                      </UserIndex.TableCell>
                                      <UserIndex.TableCell
                                        component="td"
                                        className="custom-td"
                                      >
                                        {raised?.isSubmitted == "0" ? (
                                          <>
                                            <UserIndex.Box className="primary-btn-box custome-edit-btn-box">
                                              <UserIndex.PrimaryButton
                                                onClick={() =>
                                                  history.push(
                                                    `/user/requestingproductid/${raised?._id}`
                                                  )
                                                }
                                                btnclass="primary-btn custome-edit-btn"
                                                buttonvalue="Edit"
                                              />
                                              <UserIndex.PrimaryButton
                                                onClick={() => {
                                                  handelsubmit(raised);
                                                }}
                                                btnclass="primary-btn custome-submit-btn"
                                                buttonvalue="Submit"
                                              />
                                            </UserIndex.Box>
                                          </>
                                        ) : (
                                          <>
                                            <UserIndex.Box className="primary-btn-box ">
                                              <UserIndex.PrimaryButton
                                                onClick={() => {
                                                  history.push(
                                                    `/user/viewcustomproductdata/${raised?._id}`
                                                  );
                                                }}
                                                btnclass="primary-btn custome-submit-btn"
                                                buttonvalue="View"
                                              />
                                            </UserIndex.Box>
                                          </>
                                        )}
                                      </UserIndex.TableCell>
                                    </UserIndex.TableRow>
                                  );
                                })
                              : "No custom product found"}
                          </UserIndex.TableBody>
                        </UserIndex.Table>
                      </UserIndex.TableContainer>
                    </UserIndex.Box>
                  </>
                ) : (
                  <>
                    <UserIndex.Box className="mo-table-main" sx={{}}>
                      <UserIndex.TableContainer
                        component={UserIndex.Paper}
                        className="mo-table-container"
                      >
                        <UserIndex.Table
                          sx={{ minWidth: 650 }}
                          aria-label="simple table"
                          className="mo-table"
                        >
                          <UserIndex.TableHead className="mo-tablehead">
                            <UserIndex.TableRow>
                              <UserIndex.TableCell className="th-recieved">
                                Image
                              </UserIndex.TableCell>
                              <UserIndex.TableCell className="th-recieved">
                                Meta Title
                              </UserIndex.TableCell>
                              <UserIndex.TableCell className="th-recieved">
                                Specify
                              </UserIndex.TableCell>
                              <UserIndex.TableCell className="th-recieved">
                                Status
                              </UserIndex.TableCell>
                              <UserIndex.TableCell className="th-recieved">
                                Allocation Status
                              </UserIndex.TableCell>
                              <UserIndex.TableCell className="th-recieved">
                                Action
                              </UserIndex.TableCell>
                            </UserIndex.TableRow>
                          </UserIndex.TableHead>
                          <UserIndex.TableBody className="mo-table-body">
                            {requestRecieved.length
                              ? requestRecieved.map((item, index) => {
                                  return (
                                    <UserIndex.TableRow
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      <UserIndex.TableCell
                                        component="td"
                                        scope="row"
                                        className="td-recieved"
                                      >
                                        <img
                                          src={item?.image[0]}
                                          className="custome-img"
                                        ></img>
                                      </UserIndex.TableCell>
                                      <UserIndex.TableCell
                                        component="td"
                                        className="td-recieved"
                                      >
                                        {item?.product_dimension?.meta_title}
                                      </UserIndex.TableCell>
                                      <UserIndex.TableCell
                                        component="td"
                                        className="td-recieved"
                                      >
                                        {item?.specify}
                                      </UserIndex.TableCell>
                                      <UserIndex.TableCell
                                        component="td"
                                        className="td-recieved success-text"
                                      >
                                        {item?.time_out ? (
                                          <>
                                            <UserIndex.Box
                                              sx={{ color: "#FF0303" }}
                                            >
                                              Unavailable
                                            </UserIndex.Box>
                                          </>
                                        ) : (
                                          <>
                                            <UserIndex.Box
                                              sx={{ color: "#198F0B" }}
                                            >
                                              Available
                                            </UserIndex.Box>
                                          </>
                                        )}
                                      </UserIndex.TableCell>
                                      <UserIndex.TableCell
                                        component="td"
                                        className="td-recieved"
                                      >
                                        {item.allocate_to_seller_id ? (
                                          <>
                                            {item.allocate_to_seller_id ==
                                            Userdata[0]._id
                                              ? "Allocated To You"
                                              : "Allocated To Other User"}
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </UserIndex.TableCell>
                                      <UserIndex.TableCell
                                        component="td"
                                        className="td-recieved"
                                      >
                                        <UserIndex.Box className="primary-btn-box myorder-view-btn-box">
                                          {/* {item?.time_out ? ( */}
                                          {/* <> */}
                                          {arrayIncludesInObj(
                                            item.sellers,
                                            "seller_id",
                                            Userdata?.[0]?._id
                                          ) || item?.time_out === true ? (
                                            item?.sellers?.map(
                                              (seller, index, arr) => {
                                                return (
                                                  <>
                                                    {seller?.seller_id ==
                                                    Userdata?.[0]?._id ? (
                                                      <UserIndex.Tooltip title="You have already Accepted custom product Request">
                                                        <span>
                                                          <UserIndex.Button
                                                            disabled
                                                            className="primary-btn custome-product-accept-button"
                                                          >
                                                            Accept
                                                          </UserIndex.Button>
                                                        </span>
                                                      </UserIndex.Tooltip>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </>
                                                );
                                              }
                                            )
                                          ) : (
                                            <>
                                              <UserIndex.PrimaryButton
                                                onClick={() => {
                                                  handleOpen();
                                                  getSingleCustomProductData(
                                                    item?._id
                                                  );
                                                }}
                                                btnclass="primary-btn"
                                                buttonvalue="Accept"
                                              />
                                            </>
                                          )}
                                          {/* </> */}
                                          {/* ) : (
                                            <>
                                              <UserIndex.PrimaryButton
                                                onClick={() => {
                                                  handleOpen()
                                                  getSingleCustomProductData(
                                                    item?._id,
                                                  )
                                                }}
                                                btnclass="primary-btn myorder-view-btn"
                                                buttonvalue="Accept"
                                              />
                                            </>
                                          )} */}
                                        </UserIndex.Box>
                                      </UserIndex.TableCell>
                                    </UserIndex.TableRow>
                                  );
                                })
                              : "No Record found"}
                          </UserIndex.TableBody>
                        </UserIndex.Table>
                      </UserIndex.TableContainer>
                    </UserIndex.Box>
                  </>
                )}
              </UserIndex.Box>
            </UserIndex.Box>
          </UserIndex.Box>
        </UserIndex.Box>
      </UserIndex.Box>

      <UserIndex.Box>
        <UserIndex.Box>
          <UserIndex.Modal
            open={open}
            onClose={handleClose}
            className=" login-modal custome-product-modal"
          >
            <UserIndex.Box sx={style}>
              {renderFieldMaterialWise()}
              <UserIndex.Box>
                <UserIndex.PrimaryButton
                  onClick={() => {
                    getAcceptCustomProduct();
                  }}
                  btnclass="custom-product-modal-btn"
                  buttonvalue="Submit"
                />
              </UserIndex.Box>
            </UserIndex.Box>
          </UserIndex.Modal>
        </UserIndex.Box>
      </UserIndex.Box>

      <UserIndex.Footer />
    </div>
  );
};

export default CustomeProduct;
