import React, { useEffect, useState } from "react";
import UserIndex from "../../UserIndex";

const { FaqSaga } = UserIndex.UserSagaActions;

export default function Faq() {
  const [expanded, setExpanded] = useState(false);
  const [faq, setFaq] = useState("");

  const dispatch = UserIndex.useDispatch();
  const history = UserIndex.useHistory();
  const navigate = UserIndex.useHistory();
  const loding = UserIndex.useSelector((state) => {
    return state.Usersaga.loading;
  });
  const FaqData = UserIndex.useSelector((state) => {
    return state.Usersaga.FaqData;
  });
  const getFaq = async () => {
    let urlencoded = new URLSearchParams();
    dispatch(FaqSaga(urlencoded, navigate));
  };

  useEffect(() => {
    getFaq();
  }, []);

  useEffect(() => {
    setFaq(FaqData);
  }, [FaqData]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <div>
      <UserIndex.Header />
      <UserIndex.Box
        className="faq-main-banner"
        sx={{
          background:
          `linear-gradient(45deg,rgb(34 34 34 / 76%), rgb(34 34 34 / 76%)),url(${UserIndex.png?.faqBanner})`
        }}
      >
        <UserIndex.Box className="container">
          <UserIndex.Box className="faq-inner-content">
            <UserIndex.Typography className="lot-text">
              A LOT MORE QUESTIONS
            </UserIndex.Typography>
            <UserIndex.Box className="faq-title-main">
              <UserIndex.Typography className="faq-typo-custom" variant="h1">
                {" "}
                FAQ
              </UserIndex.Typography>
              <UserIndex.Box className="img-side-yellow">
                <img
                  src={UserIndex.png.banneryellow}
                  className="yellow-left-banner"
                ></img>
              </UserIndex.Box>
            </UserIndex.Box>
          </UserIndex.Box>
        </UserIndex.Box>
      </UserIndex.Box>

      <UserIndex.Box className="faq-question-main">
        <UserIndex.Box className="container">
          <UserIndex.Box className="title-faq-asked">
            <UserIndex.Typography className="frequently-text">
              Frequently Asked Questions
            </UserIndex.Typography>
          </UserIndex.Box>
          <UserIndex.Box className="faq-accrodions">
            {faq?.length
              ? faq?.map((item, index) => {
                  return (
                    <UserIndex.Accordion
                      expanded={expanded === `panel${index}`}
                      onChange={handleChange(`panel${index}`)}
                      className="faq-accro-main"
                    >
                      <UserIndex.AccordionSummary
                        expandIcon={<UserIndex.ExpandMoreIcon />}
                        aria-controls={`panel${index}bh-content`}
                        id={`panel${index}bh-header`}
                        className="summary-faq-accro"
                      >
                        <UserIndex.Typography className="typo-faq-accro">
                          <span className="question-accro">Q{index + 1}. </span>
                          {item.question}
                        </UserIndex.Typography>
                      </UserIndex.AccordionSummary>
                      <UserIndex.AccordionDetails className="details-content-faq">
                        <UserIndex.Typography className="details-content-faq-inner">
                          {item.answer}
                        </UserIndex.Typography>
                      </UserIndex.AccordionDetails>
                    </UserIndex.Accordion>
                  );
                })
              : "No Record Found"}
          </UserIndex.Box>
        </UserIndex.Box>
      </UserIndex.Box>
      <UserIndex.Footer />
    </div>
  );
}
