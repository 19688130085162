import React, { useState } from 'react';
import { Box, styled } from '@mui/system';
import { Typography, TextField, Grid, Button } from '@mui/material';
import HighlightOffTwoToneIcon from '@mui/icons-material/CancelTwoTone';

//#region ALL HOOKS AND VARIABALS
const Img = styled('img')({
  display: 'block',
  maxWidth: '100%',
  height: 'auto',
  maxHeight: '50vh',
  borderRadius: '6px',
});

const AddUser = () => {
  const [{ alt, src }, setImg] = useState({
    src: '',
    alt: 'Upload an Image',
  });
//#endregion

//#region ALL EVENT HANDLERS AND  FUCTIONS 
  const handleImg = (e) => {
    if (e.target.files[0]) {
      setImg({
        src: URL.createObjectURL(e.target.files[0]),
        alt: e.target.files[0].name,
      });
    }
  };

  const removeImg = () => {
    setImg({
      src: '',
      alt: 'Upload an Image',
    });
  };
//#endregion

//#region RETURN JSX 
  return (
    <>
      <Grid
        container
        spacing={4}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={6}>
          <Typography
            sx={{ fontWeight: 'bold', my: 2 }}
            align="center"
            variant="h4"
          >
            Add User
          </Typography>
          <Typography
            sx={{ fontWeight: 'bold', my: 2 }}
            align="center"
            variant="h6"
          >
            Personal Details
          </Typography>
        </Grid>

        <Grid item xs={6} sx={{ width: { xs: '250px', lg: '417px' } }}>
          <TextField
            id="standard-helperText"
            label="Name"
            defaultValue="sam"
            variant="standard"
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sx={{ width: { xs: '250px', lg: '417px' } }}>
          <TextField
            id="standard-helperText"
            label="Mobile Number"
            defaultValue="1234567890"
            variant="standard"
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sx={{ width: { xs: '250px', lg: '417px' } }}>
          <TextField
            id="standard-helperText"
            label="Email"
            defaultValue="sam@gmail.com"
            variant="standard"
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <Button
            sx={{
              backgroundColor: '#000000',
              '&:hover': {
                background: '#000000',
              },
            }}
            variant="contained"
          >
            Next
          </Button>
        </Grid>
        <Box sx={{ border: 1, width: '74%', mt: 4, color: '#8080806b' }} />
        <Grid item xs={6}>
          <Typography sx={{ fontWeight: 'bold' }} variant="h6">
            Business Details
          </Typography>
        </Grid>
        <Grid item xs={6} sx={{ width: { xs: '250px', lg: '417px' } }}>
          <TextField
            id="standard-helperText"
            label="Business Name"
            defaultValue="Name"
            variant="standard"
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sx={{ width: { xs: '250px', lg: '417px' } }}>
          <TextField
            id="standard-helperText"
            label="Owner Name"
            defaultValue="sam"
            variant="standard"
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sx={{ width: { xs: '250px', lg: '417px' } }}>
          <TextField
            id="standard-helperText"
            label="Pan No"
            defaultValue="0000000"
            variant="standard"
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sx={{ width: { xs: '250px', lg: '417px' } }}>
          <TextField
            id="standard-helperText"
            label="Address"
            defaultValue="Address"
            variant="standard"
            helperText="0-250"
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sx={{ width: { xs: '250px', lg: '417px' } }}>
          <Box sx={{ width: '100%', position: 'relative' }}>
            {src === '' ? (
              <Button
                variant="contained"
                component="label"
                sx={{
                  backgroundColor: '#00000059',
                  '&:hover': {
                    background: '#00000059',
                  },
                }}
                fullWidth
              >
                <input
                  type="file"
                  onChange={handleImg}
                  style={{ cursor: 'pointer' }}
                />
              </Button>
            ) : (
              <HighlightOffTwoToneIcon
                onClick={removeImg}
                fontSize="large"
                sx={{
                  color: 'background: black(0.5)',
                  m: 1,
                  cursor: 'pointer',
                  position: 'absolute',
                  top: '-30px',
                  right: '-34px',
                }}
              />
            )}
            {src === '' ? '' : <Img sx={{ my: 2 }} src={src} alt={alt} />}
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Button
            sx={{
              mb: 4,
              backgroundColor: '#000000',
              '&:hover': {
                background: '#000000',
              },
            }}
            variant="contained"
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
export default AddUser;
//#endregion
