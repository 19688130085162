import React, { useEffect, useState } from "react";
import {
  TextField,
  InputAdornment,
  IconButton,
  FormHelperText,
  OutlinedInput,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import UserIndex from "../../UserIndex";
import UserSagaActions from "../../../../redux/Usersaga/action";
import Grid from "@mui/material/Grid";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const { GetProfile, logoutSaga, ChangePassword } = UserSagaActions;

export default function MyProfile() {
  const [expanded, setExpanded] = useState(false);
  const [Profile, setProfile] = useState("");
  const [value, setValue] = useState(0);
  const [tab, setTab] = useState("Ratings");
  const dispatch = UserIndex.useDispatch();
  const history = UserIndex.useHistory();
  const navigate = UserIndex.useHistory();
  const [password, setPassword] = useState({
    c: "",
    new: "",
    newC: "",
  });
  const [error, setError] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const handleClickShowPassword1 = () => setShowPassword1(!showPassword1);
  const handleMouseDownPassword1 = () => setShowPassword1(!showPassword1);
  const handleClickShowPassword2 = () => setShowPassword2(!showPassword2);
  const handleMouseDownPassword2 = () => setShowPassword2(!showPassword2);
  const Token = UserIndex.useSelector((state) => {
    return state.Usersaga.Token;
  });
  const ProfileData = UserIndex.useSelector((state) => {
    return state.Usersaga.Profile;
  });
  const getFaq = async () => {
    let urlencoded = new URLSearchParams();
    dispatch(GetProfile(urlencoded, navigate, Token));
  };

  useEffect(() => {
    getFaq();
  }, []);

  useEffect(() => {
    setProfile(ProfileData);
  }, [ProfileData]);

  const handleChangePassword = (e) => {
    const { name, value } = e.target;
    setPassword((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  const validate = (values) => {
    const errors = {};
    if (!values.c) {
      errors.c = "Old password is required";
    }
    if (!values.new) {
      errors.new = "New password is required";
    }
    if (!values.newC) {
      errors.newC = "New confirm password is required";
    } else if (values.newC !== values.new) {
      errors.newC = "Confirm password and new password are not matched";
    }
    return errors;
  };

  const handleSubmit = () => {
    if (Object.keys(validate(password)).length) {
      setError(validate(password));
    } else {
      setError({});
      let urlencoded = new URLSearchParams();
      urlencoded.append("password", password.c);
      urlencoded.append("newpassword", password.new);
      dispatch(ChangePassword(urlencoded, navigate, Token));
    }
  };


  return (
    <div>
      <ToastContainer />
      <UserIndex.Header />
      <UserIndex.Box className="faq-question-main">
        <UserIndex.Box className="container">
          <UserIndex.Box className="title-faq-asked">
            <UserIndex.Typography className="frequently-text">
              My Profile
            </UserIndex.Typography>
          </UserIndex.Box>
          <UserIndex.Box className="mo-tabs-box">
            <UserIndex.Box className="mo-tabs-main custom-tabs-main">
              <UserIndex.Tabs
                value={tab}
                onChange={handleChange}
                aria-label="basic tabs example"
                // className="my-profile-tabs-lable"
              >
                <UserIndex.Tab
                  label="Ratings"
                  value="Ratings"
                  className="my-profile-tabs-lable"
                />
                <UserIndex.Tab
                  label="Brand Image"
                  value="BrandImage"
                  className="my-profile-tabs-lable"
                />
                <UserIndex.Tab
                  label="Certificates"
                  value="Certificates"
                  className="my-profile-tabs-lable"
                />
                <UserIndex.Tab
                  label="Products"
                  value="Products"
                  className="my-profile-tabs-lable"
                />
                <UserIndex.Tab
                  label="Orders"
                  value="Orders"
                  className="my-profile-tabs-lable"
                />
                <UserIndex.Tab
                  label="Settings"
                  value="Settings"
                  className="my-profile-tabs-lable"
                />
              </UserIndex.Tabs>
            </UserIndex.Box>
          </UserIndex.Box>
          {tab === "Ratings" ? (
            <>
              {/* <UserIndex.Typography variant="h5">Ratings</UserIndex.Typography> */}
            </>
          ) : (
            ""
          )}
          {tab === "BrandImage" ? (
            <>
              <br />
              <br />
              <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
              >
                <Grid item xs={2} sm={4} md={4}>
                  <img
                    style={{ height: "50vh", width: "100%" }}
                    src={Profile?.certificate?.uploadBrandImage}
                  />
                </Grid>
              </Grid>
            </>
          ) : (
            ""
          )}
          {tab === "Certificates" ? (
            <>
              <br />
              <br />
              <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
              >
                {Profile?.certificate?.image?.map((data) => {
                  return (
                    <>
                      <Grid item xs={2} sm={4} md={4}>
                        <img
                          style={{ height: "50vh", width: "100%" }}
                          src={data}
                        />
                      </Grid>
                    </>
                  );
                })}
              </Grid>
            </>
          ) : (
            ""
          )}
          {tab === "Products" ? (
            <>
              <UserIndex.Box className="my-profile-product-main">
                <UserIndex.Box sx={{ marginLeft: "20px" }}>
                  <UserIndex.Typography className="my-profile-product-count-text">
                    Uploaded product count{" "}
                    <span className="my-profile-products-end-colun">:</span>
                  </UserIndex.Typography>
                  <UserIndex.Typography className="my-profile-product-count-text">
                    Pending product count{" "}
                    <span className="my-profile-products-end-colun">:</span>
                  </UserIndex.Typography>
                  <UserIndex.Typography className="my-profile-product-count-text">
                    Rejected product count{" "}
                    <span className="my-profile-products-end-colun">:</span>
                  </UserIndex.Typography>
                </UserIndex.Box>
                <UserIndex.Box className="my-profile-products-right-side">
                  <UserIndex.Typography className="my-profile-product-count">
                    {Profile?.uploadedProduct.length
                      ? Profile?.uploadedProduct[0]["Uploaded product count"]
                      : 0}
                  </UserIndex.Typography>
                  <UserIndex.Typography className="my-profile-product-count">
                    {Profile?.pendingProduct.length
                      ? Profile?.pendingProduct[0]["Pending product count"]
                      : 0}
                  </UserIndex.Typography>
                  <UserIndex.Typography className="my-profile-product-count">
                    {Profile?.rajectedProduct.length
                      ? Profile?.rajectedProduct[0]["Rejected product count"]
                      : 0}
                  </UserIndex.Typography>
                </UserIndex.Box>
              </UserIndex.Box>
            </>
          ) : (
            ""
          )}
          {tab === "Orders" ? (
            <>
              <UserIndex.Box className="my-profile-product-main">
                <UserIndex.Box sx={{ marginLeft: "20px" }}>
                  <UserIndex.Typography className="my-profile-product-count-text">
                    Receive order count{" "}
                    <span className="my-profile-products-end-colun">:</span>
                  </UserIndex.Typography>
                  <UserIndex.Typography className="my-profile-product-count-text">
                    Order place count{" "}
                    <span className="my-profile-products-end-colun">:</span>
                  </UserIndex.Typography>
                </UserIndex.Box>
                <UserIndex.Box className="my-profile-products-right-side">
                  <UserIndex.Typography className="my-profile-product-count">
                    {Profile?.receiveOrderCount.length
                      ? Profile?.receiveOrderCount[0]["Receive order count "]
                      : 0}
                  </UserIndex.Typography>
                  <UserIndex.Typography className="my-profile-product-count">
                    {Profile?.orderPlaceCount.length
                      ? Profile?.orderPlaceCount[0]["Oredr place count "]
                      : 0}
                  </UserIndex.Typography>
                </UserIndex.Box>
              </UserIndex.Box>
            </>
          ) : (
            ""
          )}
          {tab === "Settings" ? (
            <>
              <UserIndex.Typography className="myprofile-change-password-heading">
                Change Password
              </UserIndex.Typography>
              <UserIndex.Box className="input-box">
                <FormHelperText className="input-lable">
                  Current Password
                  <span style={{ color: "red", fontSize: "15px" }}> * </span>
                </FormHelperText>
                <OutlinedInput
                  type={showPassword ? "text" : "password"}
                  onChange={handleChangePassword}
                  value={password.c}
                  name="c"
                  id="filled-basic"
                  variant="filled"
                  autoComplete="new-password"
                  // onKeyDown={handleKeyDown}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText className="error-input-lable">
                  {error?.c}
                </FormHelperText>
              </UserIndex.Box>

              <UserIndex.Box className="input-box">
                <FormHelperText className="input-lable">
                  New Password
                  <span style={{ color: "red", fontSize: "15px" }}> * </span>
                </FormHelperText>
                <OutlinedInput
                  type={showPassword1 ? "text" : "password"}
                  onChange={handleChangePassword}
                  value={password.new}
                  name="new"
                  id="filled-basic"
                  variant="filled"
                  // onKeyDown={handleKeyDown}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword1}
                        onMouseDown={handleMouseDownPassword1}
                      >
                        {showPassword1 ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText className="error-input-lable">
                  {error?.new}
                </FormHelperText>
              </UserIndex.Box>

              <UserIndex.Box className="input-box">
                <FormHelperText className="input-lable">
                  New Confirm Password
                  <span style={{ color: "red", fontSize: "15px" }}> * </span>
                </FormHelperText>
                <OutlinedInput
                  type={showPassword2 ? "text" : "password"}
                  onChange={handleChangePassword}
                  value={password.newC}
                  name="newC"
                  id="filled-basic"
                  variant="filled"
                  // onKeyDown={handleKeyDown}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword2}
                        onMouseDown={handleMouseDownPassword2}
                      >
                        {showPassword2 ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText className="error-input-lable">
                  {error?.newC}
                </FormHelperText>
              </UserIndex.Box>

              <UserIndex.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
              >
                <UserIndex.Box
                  sx={{ display: "flex", justifyContent: "start" }}
                  className="primary-btn-box save-btn-profile-box"
                >
                  <UserIndex.PrimaryButton
                    btnclass="primary-btn save-btn-profile"
                    onClick={() => handleSubmit()}
                    buttonvalue="Save"
                  />
                </UserIndex.Box>
              </UserIndex.Box>
            </>
          ) : (
            ""
          )}
        </UserIndex.Box>
      </UserIndex.Box>
      <UserIndex.Footer />
    </div>
  );
}
