import React, { useState, useEffect } from "react";
import UserSagaActions from "../../../redux/Usersaga/action";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserIndex from "../../../container/user/UserIndex";
import { price } from "../../customComponents/commonFunctions/CommonFunctions";

const { addcartSaga } = UserSagaActions;

const ProductGrid = (props) => {
  const { item, individual } = props;
  let params = UserIndex.useParams();
  const dispatch = UserIndex.useDispatch();
  const navigate = UserIndex.useHistory();
  const history = UserIndex.useHistory();
  const [product, setProduct] = useState();
  const [material, setMaterial] = useState();

  const token = UserIndex.useSelector((state) => {
    return state.Usersaga.Token;
  });
  const Cartdata = UserIndex.useSelector((state) => {
    return state.Usersaga.Cartdata;
  });

  const Userdata = UserIndex.useSelector((state) => {
    return state.Usersaga.Userdata;
  });

  const getAddToCart = () => {
    let urlencoded = new URLSearchParams();
    if (item) {
      urlencoded.append("productId", item._id);
    } else {
      urlencoded.append("productId", individual._id);
    }
    urlencoded.append("quantity", "1");
    urlencoded.append("shipping", "100");
    dispatch(addcartSaga(urlencoded, navigate, token));
  };

  useEffect(() => {
    setProduct(Cartdata);
  }, [Cartdata]);

  const outOfStock = () => toast.error("Out Of Stock", { autoClose: 1000 });

  useEffect(() => {
    let arr = [];
    item?.materialIdArray?.map((data, i) => {
      arr.push(data?.material_id?.material);
    });
    setMaterial(arr);
  }, [item]);

  const showToastMessage = () => {
    toast.error("Please login first to view/buy product", { autoClose: 1000 });
  };

  const requestCustomeProduct = () => {
    if (token === "") {
      showToastMessage();
    } else {
      history.push(`/user/ProductDetails/${item?._id}`);
    }
  };

  return (
    <>
      <UserIndex.Box
        gridColumn={{
          xs: "span 12",
          sm: "span 6",
          md: "span 4",
          lg: "span 4",
        }}
      >
        <UserIndex.Box className="product-box">
          {item?.no_of_pieces_available_inStock !== 0 ? (
            ""
          ) : (
            <>
              <UserIndex.Box
                sx={{
                  color: "#FF0303",
                  fontWeight: 600,
                  fontSize: "19px",
                  fontFamily: "mulish-semibold",
                  textAlign:'center'
                }}
              >
                Out Of Stock
              </UserIndex.Box>
            </>
          )}
          <UserIndex.Box className="product-img-box">
            <img src={item.image[0]} className="product-img"></img>
            {token && token !== null ? (
              <>
                {item.user_id == Userdata?.[0]?._id ? (
                  ""
                ) : (
                  <>
                    {item?.no_of_pieces_available_inStock !== 0 ? (
                      <>
                        <UserIndex.Box className="show-hover">
                          <UserIndex.Box className="add-cart-box-hover">
                            <UserIndex.Button
                              onClick={() => {
                                getAddToCart(Cartdata);
                              }}
                              className="btn hover-btns"
                            >
                              <UserIndex.Box className="add-cart-bg">
                                <img
                                  src={UserIndex.svg.iconcart}
                                  className="hover-img"
                                ></img>
                              </UserIndex.Box>
                            </UserIndex.Button>
                          </UserIndex.Box>
                        </UserIndex.Box>
                      </>
                    ) : (
                      <>
                        <UserIndex.Box className="show-hover">
                          <UserIndex.Box className="add-cart-box-hover">
                            <UserIndex.Button
                              onClick={() => {
                                outOfStock();
                              }}
                              className="btn hover-btns"
                            >
                              <UserIndex.Box className="add-cart-bg">
                                <img
                                  src={UserIndex.svg.iconcart}
                                  className="hover-img"
                                ></img>
                              </UserIndex.Box>
                            </UserIndex.Button>
                          </UserIndex.Box>
                        </UserIndex.Box>
                      </>
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                <UserIndex.Box className="show-hover">
                  <UserIndex.Box className="add-cart-box-hover">
                    <UserIndex.Button
                      onClick={() => {
                        showToastMessage();
                      }}
                      className="btn hover-btns"
                    >
                      <UserIndex.Box className="add-cart-bg">
                        <img
                          src={UserIndex.svg.iconcart}
                          className="hover-img"
                        ></img>
                      </UserIndex.Box>
                    </UserIndex.Button>
                  </UserIndex.Box>
                </UserIndex.Box>
              </>
            )}
          </UserIndex.Box>
          <UserIndex.Box className="product-content">
            <UserIndex.Box className="product-title">
              {item?.item_id?.item}
            </UserIndex.Box>
            <UserIndex.Box className="product-sub-title">
              {material?.toString()}
            </UserIndex.Box>
            <UserIndex.Box className="product-price">
              {token && token !== null ? (
                <>₹{price(item?.total_payable_amount)}</>
              ) : (
                ""
              )}
            </UserIndex.Box>
            <UserIndex.Box className="product-btn-box">
              <UserIndex.Button
                onClick={() => {
                  requestCustomeProduct();
                }}
                className="btn btn-product"
              >
                View
              </UserIndex.Button>
            </UserIndex.Box>
          </UserIndex.Box>
        </UserIndex.Box>
      </UserIndex.Box>
    </>
  );
};

export default ProductGrid;
