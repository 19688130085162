import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import {
  Grid,
  Container,
  Typography,
  Button,
  Box,
  TextField,
  TextareaAutosize,
  Tooltip,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { Api } from '../../../../config/api';
import dataService from '../../../../config/dataService';
import { toast } from 'react-toastify';
import ContainedButton from '../../../../component/customComponents/buttons/ContainedButton';
import ImgUploadButton from '../../../../component/customComponents/buttons/ImgUploadButton';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import {
  ErrorMsg,
  TextAreaErrorMsg,
} from '../../../../component/customComponents/errorMsg/ErrorMsg';
import {
  minCategoryNameLength,
  categoryNameLength,
  minCategoryDescriptionLength,
  categoryDescriptionLength,
  NO_WHITE_SPACE_BEGINNING_END,
  CATEGORY_REGEX,
} from '../../../../component/customComponents/validation/Validation';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import UploadIcon from '@mui/icons-material/Upload';

import AdminIndex from '../../AdminIndex';
//#region ALL HOOKS AND VARIABALS
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const Img = styled('img')({
  display: 'block',
  maxWidth: '100%',
  height: 'auto',
  maxHeight: '50vh',
  borderRadius: '6px',
});


const { getAllCategoryList, addCategory } = AdminIndex.AdminSagaActions;
const AddCategory = () => {
  let token = localStorage.getItem('auth');
  const dispatch = AdminIndex.useDispatch();
  const history = useHistory();
  const navigate = useHistory();
  const [image, setImage] = useState('');
  const [url, setUrl] = useState('');
  const [category, setCategory] = useState([]);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  const schema = yup.object().shape({
    files: yup.mixed().test('require', 'Please select a file', (value) => {
      return value && value.length;
    }),
  });
  //#endregion

  //#region ALL EVENT HANDLERS AND  FUCTIONS

  const categoryData = async () => {
    let formdata = new FormData();
    dispatch(getAllCategoryList(token,formdata));
    // await dataService
    //   .post(Api.Admin.getAllCategory, formdata)
    //   .then((res) => {
    //     setCategory(res.data.data);
    //   })
    //   .catch((err) => toast.error(err?.message || 'Something went wrong'));
  };

  const loading = AdminIndex.useSelector((state) => {
    return state.AdminSaga.loading;
  });

  const categoryListData = AdminIndex.useSelector((state) => {
    return state.AdminSaga.categoryData;
  });

  useEffect(() => {
    setCategory(categoryListData);
  }, [categoryListData]);

  const handleImg = (e) => {
    if (e.target.files.length) {
      setImage(e.target.files[0]);
      setUrl(window.URL.createObjectURL(e.target.files[0]));
    }
  };

  const removeImg = () => {
    setImage('');
    setUrl('');
  };

  const handleSave = async (data) => {
    try {
      let formdata = new FormData();
      formdata.append('category_name', data?.category_name?.trim());
      formdata.append(
        'category_description',
        data?.category_description?.trim()
      );
      // formdata.append('status', user.status?.trim());
      // formdata.append('catalogue', user.catalogue?.trim());
      formdata.append('images', image);

      dispatch(addCategory(token, formdata, navigate))
      // const userResp = await dataService.post(Api.Admin.addCategory, formdata, {
      //   headers: {
      //     'Content-Type': 'multipart/form-data',
      //     auth: token,
      //   },
      // });

      // if (userResp.data.status === 1) {
      //   toast.success('Category added successfully');
      //   history.push('/admin/categorymanagement');
      // }
    } catch (err) {
      toast.error(err?.message || 'Something went wrong');
    }
  };

  let sameCategory = category.map((val) => {
    if (
      val.category_name.toLowerCase() == watch('category_name')?.toLowerCase()
    ) {
      return false;
    } else {
      return true;
    }
  });

  useEffect(() => {
    categoryData();
  }, []);
  //#endregion

  //#region RETURN JSX
  return (
    <>
      <form onSubmit={handleSubmit(handleSave)}>
        <Container maxWidth="false">
          <Typography sx={{ mt: 2, mb: 7 }} align="center" variant="h4">
            Add Category
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12} md={2.3} />
            <Grid item xs={12} md={2}>
              <Typography align="left" sx={{ mt: { lg: 3.5, xs: 1 } }}>
                Category Name
              </Typography>
            </Grid>
            <Grid item xs={12} md={7.7}>
              <TextField
                autoComplete="off"
                sx={{
                  mt: 2,
                  width: { lg: '300px', xs: '100%' },
                  '& .MuiFormHelperText-root': { ml: 0, mt: '5px' },
                }}
                size="small"
                fullWidth
                className="form-control border-none"
                name="category_name"
                type="text"
                {...register('category_name', {
                  required: <ErrorMsg msg="Please enter Heading" />,
                  minLength: {
                    value: minCategoryNameLength,
                    message: (
                      <ErrorMsg
                        msg={`Minimum ${minCategoryNameLength} Characters Allowed`}
                      />
                    ),
                  },
                  maxLength: {
                    value: categoryNameLength,
                    message: (
                      <ErrorMsg
                        msg={`Maximum ${categoryNameLength} Characters Allowed`}
                      />
                    ),
                  },
                  pattern: {
                    value: NO_WHITE_SPACE_BEGINNING_END,
                    message: (
                      <ErrorMsg msg="Whitespace is not allowed at beginning or end" />
                    ),
                  },
                  pattern: {
                    value: CATEGORY_REGEX,
                    message: <ErrorMsg msg="Please enter valid Category" />,
                  },
                  validate: {
                    sameValCheck: (val) =>
                      !sameCategory.includes(false) || (
                        <ErrorMsg msg={'Catgeory already exists'} />
                      ),
                  },
                })}
                helperText={
                  <ErrorMessage errors={errors} name="category_name" />
                }
              />
            </Grid>
            <Grid item xs={12} md={2.3} />
            <Grid item xs={12} md={2}>
              <Typography align="left" sx={{ mt: { lg: 3.5, xs: 1 } }}>
                Category Description
              </Typography>
            </Grid>
            <Grid item xs={12} md={7.7}>
              <Box className="text-area-control-main">
                <TextareaAutosize
                  autoComplete="off"
                  sx={{ width: '300px !important' }}
                  minRows={0}
                  size="small"
                  className="form-control border-none text-area-control3 text-area-control-admin"
                  name="category_description"
                  type="text"
                  {...register('category_description', {
                    required: (
                      <TextAreaErrorMsg msg="Please enter Description" />
                    ),
                    minLength: {
                      value: minCategoryDescriptionLength,
                      message: (
                        <TextAreaErrorMsg
                          msg={`Minimum ${minCategoryDescriptionLength} Characters Allowed`}
                        />
                      ),
                    },
                    maxLength: {
                      value: categoryDescriptionLength,
                      message: (
                        <TextAreaErrorMsg
                          msg={`Maximum ${categoryDescriptionLength} Characters Allowed`}
                        />
                      ),
                    },
                    pattern: {
                      value: NO_WHITE_SPACE_BEGINNING_END,
                      message: (
                        <TextAreaErrorMsg msg="Whitespace is not allowed at beginning or end" />
                      ),
                    },
                  })}
                />
                <ErrorMessage errors={errors} name="category_description" />
              </Box>
            </Grid>
            <Grid item xs={12} md={2.3} />
            <Grid item xs={12} md={2}>
              <Typography align="left" sx={{ mt: 2 }}>
                Upload Thumbnail
              </Typography>
            </Grid>
            <Grid item xs={12} md={7.7}>
              <Box
                sx={{
                  width: { lg: '300px', xs: '100%' },
                  position: 'relative',
                  mt: 1,
                }}
              >
                {image === '' ? (
                  <>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        mx: { xs: 3 },
                      }}
                    >
                      <Box sx={{ width: { lg: '300px', xs: '100%' } }}>
                        <Button
                          component="label"
                          sx={{
                            borderRadius: 0,
                            textTransform: 'none',
                            width: { lg: '300px', xs: '100%' },
                          }}
                          variant="contained"
                          onChange={handleImg}
                        >
                          Upload
                          <UploadIcon />
                          <input
                            name="files"
                            hidden
                            accept="image/*"
                            type="file"
                            {...register('files', {
                              required: <ErrorMsg msg="Please select Image" />,
                            })}
                          />
                        </Button>
                        <ErrorMessage errors={errors} name="files" />
                      </Box>
                    </Box>
                  </>
                ) : null}
                {image === '' ? (
                  ''
                ) : (
                  <Box className="updateitem-img-box">
                    <Img
                      sx={{
                        my: 2,
                        height: '200px',
                        width: '100%',
                        maxWidth: '300px',
                        borderRadius: '0px',
                        position: 'relative',
                        margin: 'auto',
                      }}
                      src={window.URL.createObjectURL(image)}
                      alt="no image"
                    />
                    <Button className="remove-btn-upadteitem">
                      <Tooltip title="Remove Images" placement="bottom">
                        <DisabledByDefaultIcon
                          className="remove-img-icon close-upadte-item"
                          onClick={removeImg}
                          fontSize="large"
                          sx={{ mt: 5 }}
                        />
                      </Tooltip>
                    </Button>
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
          <Typography sx={{ mt: 4 }} align="center" variant="h4">
            <ContainedButton name="Add" type="submit" />
          </Typography>
        </Container>
      </form>
    </>
  );
};

export default AddCategory;
//#endregion
