import footercall from "../assets/images/svg/call.svg"
import footerlocation from "../assets/images/svg/location.svg"
import sectionbg from "../assets/images/svg/section-bg.svg"
import dropwhitedown from "../assets/images/svg/drop-icon.svg"
import membercard from "../assets/images/svg/member-card.svg"
import facebook from "../assets/images/svg/facebook.svg"
import linkdin from "../assets/images/svg/linkdin.svg"
import twitter from "../assets/images/svg/twitter.svg"
import greydown from "../assets/images/svg/greydown.svg"
import iconheart from "../assets/images/svg/icon-heart.svg"
import iconcart from "../assets/images/svg/icon-cart.svg"
import iconsearch from "../assets/images/svg/icon-search.svg"
import location from "../assets/images/svg/yellow-location.svg"
import membership from "../assets/images/svg/yellow-membership.svg"
import send from "../assets/images/svg/yellow-send.svg"
import yellowupload from "../assets/images/svg/yellow-upload.svg"
import editicon from "../assets/images/svg/edit-icon.svg"
import plusicon from "../assets/images/svg/plus-icon.svg"
import sidemenuimg from "../assets/images/svg/open-side-menu.svg"
import nextarrow from "../assets/images/svg/next-arow.svg"
import closeimg from "../assets/images/svg/close-white.svg"
import navdown from "../assets/images/svg/nav-down.svg"
import deleteblack from "../assets/images/svg/delete-black.svg"
import iconedit from "../assets/images/svg/iconedit.svg"
import trashIcon from "../assets/images/svg/trashIcon.svg"

const Svg = {
    footercall,
    footerlocation,
    sectionbg,
    dropwhitedown,
    membercard,
    facebook,
    linkdin,
    twitter,
    greydown,
    iconheart,
    iconcart,
        iconsearch,
    location,
    membership,
    send,
    yellowupload,
    editicon,
    plusicon,
    sidemenuimg,
    nextarrow,
    closeimg,
    navdown,
    deleteblack,
    iconedit,
    trashIcon,
}
export default Svg;