//#region ALL IMPORTS
import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import {
  Grid,
  Container,
  Typography,
  Button,
  TextareaAutosize,
  Box,
  TextField,
  Accordion,
  Tooltip,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import AddIcon from '@mui/icons-material/Add';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Api } from '../../../../../../config/api';
import dataService from '../../../../../../config/dataService';
import ContainedButton from '../../../../../../component/customComponents/buttons/ContainedButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useFieldArray, useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import {
  ErrorMsg,
  TextAreaErrorMsg,
} from '../../../../../../component/customComponents/errorMsg/ErrorMsg';
import {
  minBlogHeadingLength,
  blogHeadingLength,
  minBlogDescriptionLength,
  blogDescriptionLength,
  minBlogSubHeadingLength,
  blogSubHeadingLength,
  NO_WHITE_SPACE_BEGINNING_END,
} from '../../../../../../component/customComponents/validation/Validation';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import UploadIcon from '@mui/icons-material/Upload';
//#endregion

import AdminIndex from '../../../../AdminIndex';
//#region ALL HOOKS AND VARIABALS
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const Img = styled('img')({
  display: 'block',
  maxWidth: '100%',
  height: 'auto',
  maxHeight: '50vh',
  borderRadius: '6px',
});

const { getBlogList, updateSingleBlog } = AdminIndex.AdminSagaActions;
const UpdateBlogs = () => {
  let token = localStorage.getItem('auth');
  const history = useHistory();
  const navigate = useHistory();
  const dispatch = AdminIndex.useDispatch();
  const [customerServicesId, setCustomerServicesId] = useState({});
  const [data, setData] = useState('');
  const [image, setImage] = useState('');
  const [url, setUrl] = useState('');

  const [rotateChevron, setRotateChevron] = useState(false);
  const rotate = rotateChevron ? 'rotate(90deg)' : 'rotate(0)';

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
    watch,
  } = useForm();

  const { fields, append, remove } = useFieldArray({
    name: 'subHeadingAndDescription',
    control,
  });
  //#endregion

  //#region ALL EVENT HANDLERS AND  FUCTIONS
  useEffect(() => {
    getData();
  }, []);

  const handleRotate = () => setRotateChevron(!rotateChevron);

  const getData = async () => {
    let urlencoded = new URLSearchParams();
    try {
      dispatch(getBlogList(token, urlencoded));
      // const Data = await dataService.post(Api.Admin.listBlog, urlencoded, {
      //   headers: {
      //     auth: token,
      //   },
      // });
      // setData(Data?.data?.data?.[0]);
      // setCustomerServicesId(Data?.data?.data?.[0]?._id);
    } catch (err) {
      toast.error(err?.message || 'Something went wrong');
    }
  };

  const blogList = AdminIndex.useSelector((state) => {
    return state.AdminSaga.blogList;
  });

  useEffect(() => {
    setData(blogList?.[0]);
    setCustomerServicesId(blogList?.[0]?._id);
  }, [blogList]);

  useEffect(() => {
    setUrl(data?.image);
    setValue('blogHeading', data?.heading);
    setValue('customerServiceDescription', data?.description);
    setValue('subHeadingAndDescription', data?.add_more);
  }, [data]);
  const handleImg = (e) => {
    if (e.target.files.length) {
      setImage(e.target.files[0]);
      setUrl(window.URL.createObjectURL(e.target.files[0]));
    }
  };

  const removeImg = () => {
    setImage('');
    setUrl('');
  };

  const handleSave = async (data) => {
    try {
      let formdata = new URLSearchParams();

      formdata.append('id', customerServicesId);
      // formdata.append('images', image);
      formdata.append('heading', data?.blogHeading?.trim());
      // formdata.append('description', data?.customerServiceDescription?.trim());
      formdata.append(
        'add_more',
        JSON.stringify(data?.subHeadingAndDescription)
      );

      dispatch(updateSingleBlog(token, formdata,navigate));
      // const blogData = await dataService.post(Api.Admin.updateBlog, formdata, {
      //   headers: {
      //     auth: token,
      //   },
      // });

      // if (blogData.data.status === 1) {
      //   toast.success('Blog updated successfully');
      //   history.push('/admin/blogs');
      // }
    } catch (err) {
      toast.error(err?.message || 'Something went wrong');
    }
  };
  //#endregion

  //#region RETURN JSX
  return (
    <>
      <form onSubmit={handleSubmit(handleSave)}>
        <Container maxWidth="false">
          <Typography sx={{ my: 2 }} align="center" variant="h4">
            Blogs management
          </Typography>

          <Grid container spacing={1}>
            {/* <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography align="left" sx={{ mt: 2, mb: 1.5 }}>
                Upload Image
              </Typography>
              <Box sx={{ width: '100%', position: 'relative' }}>
                {image === '' && url == '' && (
                  <>
                    <Button
                      component="label"
                      sx={{ borderRadius: 0, textTransform: 'none' }}
                      variant="contained"
                      onChange={handleImg}
                    >
                      Upload
                      <UploadIcon />
                      <input
                        name="files"
                        hidden
                        accept="image/*"
                        type="file"
                        {...register('files', {
                          required: <ErrorMsg msg="Please select Image" />,
                        })}
                      />
                    </Button>
                    <ErrorMessage errors={errors} name="files" />
                  </>
                )}
                {image === '' && url == '' ? (
                  ''
                ) : (
                  <Box className="aboutUs-img-box">
                    <Img
                      sx={{
                        my: 2,
                        borderRadius: '0px',
                        height: '130px',
                        width: '200px',
                      }}
                      src={url}
                      alt="no image"
                    />
                    <Button className="aboutUs-top-btn">
                      <Tooltip title="Remove this Image" placement="bottom">
                        <DisabledByDefaultIcon
                          className="remove-img-icon"
                          onClick={removeImg}
                          sx={{
                            // bgcolor: 'black(0.5)',
                            // color: 'black',
                            mt: 5,
                          }}
                        />
                      </Tooltip>
                    </Button>
                  </Box>
                )}
              </Box>
            </Grid> */}
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography align="left" sx={{ mt: 2 }}>
                Blog Heading
              </Typography>
              <TextField
                autoComplete="off"
                sx={{
                  mt: 2,
                  '& .MuiFormHelperText-root': { ml: 0, mt: '5px' },
                }}
                size="small"
                fullWidth
                className="form-control border-none"
                name="blogHeading"
                {...register('blogHeading', {
                  required: <ErrorMsg msg="Please enter Blog Heading" />,
                  minLength: {
                    value: minBlogHeadingLength,
                    message: (
                      <ErrorMsg
                        msg={`Minimum ${minBlogHeadingLength} Characters Allowed`}
                      />
                    ),
                  },
                  maxLength: {
                    value: blogHeadingLength,
                    message: (
                      <ErrorMsg
                        msg={`Maximum ${blogHeadingLength} Characters Allowed`}
                      />
                    ),
                  },
                  pattern: {
                    value: NO_WHITE_SPACE_BEGINNING_END,
                    message: (
                      <ErrorMsg msg="Whitespace is not allowed at beginning or end" />
                    ),
                  },
                })}
                helperText={<ErrorMessage errors={errors} name="blogHeading" />}
              />
            </Grid>
            {/* <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography align="left">Description</Typography>
              <Box className="text-area-control-main">
                <TextareaAutosize
                  autoComplete="off"
                  minRows={0}
                  maxRows={5}
                  size="small"
                  fullWidth
                  className="form-control border-none text-area-control1 text-area-control-admin"
                  name="customerServiceDescription"
                  {...register('customerServiceDescription', {
                    required: (
                      <TextAreaErrorMsg msg="Please enter Description" />
                    ),
                    minLength: {
                      value: minCustomerServiceDescriptionLength,
                      message: (
                        <TextAreaErrorMsg
                          msg={`Minimum ${minCustomerServiceDescriptionLength} Characters Allowed`}
                        />
                      ),
                    },
                    maxLength: {
                      value: customerServiceDescriptionLength,
                      message: (
                        <TextAreaErrorMsg
                          msg={`Maximum ${customerServiceDescriptionLength} Characters Allowed`}
                        />
                      ),
                    },
                    pattern: {
                      value: NO_WHITE_SPACE_BEGINNING_END,
                      message: (
                        <TextAreaErrorMsg msg="Whitespace is not allowed at beginning or end" />
                      ),
                    },
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="customerServiceDescription"
                />
              </Box>
            </Grid> */}
          </Grid>

          <Box sx={{ border: 1, my: 3 }}></Box>

          {fields?.map((input, index) => {
            return (
              <>
                <Accordion
                  sx={{
                    marginBottom: 1,
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{
                      // backgroundColor: '#c29958',
                      '& .MuiButtonBase-root-MuiAccordionSummary-root': {
                        borderRadius: '10px !important',
                      },
                    }}
                  >
                    <Grid container>
                      <Grid item lg={3}>
                        Sub-Heading/Description
                        {watch('subHeadingAndDescription').length >= 2
                          ? ` - ${index + 1} ) `
                          : ''}
                      </Grid>
                      {/* <Grid item lg={10}>
                        {watch(`subHeadingAndDescription.${index}.sub_heading`)}
                      </Grid> */}
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={1} sx={{ mt: 2, border: 1, p: 2 }}>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Typography align="left" sx={{ mt: 2 }}>
                          Sub-Heading
                        </Typography>
                        <TextField
                          autoComplete="off"
                          sx={{
                            mt: 2,
                            '& .MuiFormHelperText-root': { ml: 0, mt: '5px' },
                          }}
                          size="small"
                          fullWidth
                          className="form-control border-none"
                          name="sub_heading"
                          type="text"
                          {...register(
                            `subHeadingAndDescription.${index}.sub_heading`,
                            {
                              required: (
                                <ErrorMsg msg="Please enter Sub-Heading" />
                              ),
                              minLength: {
                                value: minBlogSubHeadingLength,
                                message: (
                                  <ErrorMsg
                                    msg={`Minimum ${minBlogSubHeadingLength} Characters Allowed`}
                                  />
                                ),
                              },
                              maxLength: {
                                value: blogSubHeadingLength,
                                message: (
                                  <ErrorMsg
                                    msg={`Maximum ${blogSubHeadingLength} Characters Allowed`}
                                  />
                                ),
                              },
                              pattern: {
                                value: NO_WHITE_SPACE_BEGINNING_END,
                                message: (
                                  <TextAreaErrorMsg msg="Whitespace is not allowed at beginning or end" />
                                ),
                              },
                            }
                          )}
                          helperText={
                            <ErrorMessage
                              errors={errors}
                              name={`subHeadingAndDescription.${index}.sub_heading`}
                            />
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Typography align="left" sx={{ mt: 2 }}>
                          Blog Description
                        </Typography>
                        <Box className="text-area-control-main">
                          <TextareaAutosize
                            autoComplete="off"
                            minRows={0}
                            maxRows={5}
                            size="small"
                            fullWidth
                            className="form-control border-none text-area-control1 text-area-control-admin"
                            name="description"
                            type="text"
                            {...register(
                              `subHeadingAndDescription.${index}.description`,
                              {
                                required: (
                                  <TextAreaErrorMsg msg="Please enter description" />
                                ),
                                minLength: {
                                  value: minBlogDescriptionLength,
                                  message: (
                                    <TextAreaErrorMsg
                                      msg={`Minimum ${minBlogDescriptionLength} Characters Allowed`}
                                    />
                                  ),
                                },
                                maxLength: {
                                  value: blogDescriptionLength,
                                  message: (
                                    <TextAreaErrorMsg
                                      msg={`Maximum ${blogDescriptionLength} Characters Allowed`}
                                    />
                                  ),
                                },
                                pattern: {
                                  value: NO_WHITE_SPACE_BEGINNING_END,
                                  message: (
                                    <TextAreaErrorMsg msg="Whitespace is not allowed at beginning or end" />
                                  ),
                                },
                              }
                            )}
                          />
                          <ErrorMessage
                            errors={errors}
                            name={`subHeadingAndDescription.${index}.description`}
                          />
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        textAlign="right"
                      >
                        <ContainedButton
                          display={
                            watch('subHeadingAndDescription').length <= 1 &&
                            'none'
                          }
                          onClick={() => remove(index)}
                          name="Remove"
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </>
            );
          })}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              variant="contained"
              sx={{ textTransform: 'none', mt: 2 }}
              onClick={() => {
                append({ sub_heading: '', description: '' });
                handleRotate();
              }}
            >
              Add Sub-Heading/Description
              <AddIcon
                sx={{ ml: 1 }}
                style={{ transform: rotate, transition: 'all 0.1s linear' }}
                onClick={handleRotate}
              />
            </Button>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', my: 5 }}>
            <ContainedButton name="Update" type="submit" />
          </Box>
        </Container>
      </form>
    </>
  );
};

export default UpdateBlogs;
//#endregion
