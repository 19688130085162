import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { Button, Typography } from "@mui/material";
import svg from "../../../../assets/svg";
import png from "../../../../assets/png";
import Header from "../../../../component/user/defaultLayout/Header";
import Footer from "../../../../component/user/defaultLayout/Footer";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";

import UserIndex from "../../UserIndex";

const { ListProductDescriptionSaga } = UserIndex.UserSagaActions;

const Item = styled(Paper)(({ theme }) => ({}));

export default function Ringsinfo() {
  const [productDescriptionData, setProductDescriptionData] = useState("");

  const params = UserIndex.useParams();
  const dispatch = UserIndex.useDispatch();
  const navigate = UserIndex.useHistory();
  const loding = UserIndex.useSelector((state) => {
    return state.Usersaga.loading;
  });
  const ListProductDescriptionData = UserIndex.useSelector((state) => {
    return state.Usersaga.ListProductDescriptionData;
  });

  const getProductDescriptionData = async () => {
    let urlencoded = new URLSearchParams();
    dispatch(ListProductDescriptionSaga(urlencoded, navigate));
  };

  useEffect(() => {
    setProductDescriptionData(ListProductDescriptionData);
  }, [ListProductDescriptionData]);

  useEffect(() => {
    getProductDescriptionData();
  }, []);

  useEffect(() => {
    let newcategory = ListProductDescriptionData?.filter((element) => {
      if (element.product_type == params.item) {
        return element;
      } else {
        return;
      }
    });
    setProductDescriptionData(newcategory);
  }, [ListProductDescriptionData, params]);

  return (
    <div>
      <Header />
      {ListProductDescriptionData && ListProductDescriptionData.length ? (
        <>
          <Box
            className="ringinfo-hero-sec"
            sx={{ backgroundImage: `url(${productDescriptionData[0]?.image})` }}
          >
            <Box className="container">
              <Box className="goldinfo-banner-main">
                <Box className="faq-title-main customer-title-main gold-main">
                  <Typography className="faq-typo-custom" variant="h1">
                    {productDescriptionData[0]?.product_type}
                  </Typography>
                  <Box className="img-side-yellow">
                    <img
                      src={png.banneryellow}
                      className="yellow-left-banner"
                    ></img>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className="rings-info-top-sec">
            <Box className="container">
              <Box sx={{ width: 1 }}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={{ xs: 0, sm: 5, md: 5, lg: 5 }}
                >
                  <Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 6",
                      lg: "span 12",
                    }}
                  >
                    <Box className="ringsinfo-sub-title-box ">
                      <Typography
                        component="h3"
                        variant="h3"
                        className="ringsinfo-sub-title ringstop-sub-title"
                      >
                        {productDescriptionData[0]?.heading}
                      </Typography>
                    </Box>
                    <Box className="rings-info-content">
                      <Box className="ringsinfo-para-box">
                        <Typography
                          variantMapping="p"
                          className="ringsinfo-para mb-30"
                        >
                          {productDescriptionData[0]?.description}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </>
      ) : (
        <Typography className="view-cart-empty-message">
          No Record Found
        </Typography>
      )}

      <Footer />
    </div>
  );
}
