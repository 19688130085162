import React, { useState, useEffect } from 'react';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import {
  Grid,
  TextField,
  Typography,
  Button,
  Box,
  Modal,
  FormHelperText,
  TextareaAutosize,
  Tooltip,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import OwlCarousel from 'react-owl-carousel';
import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Api } from '../../../../config/api';
import dataService from '../../../../config/dataService';
import { useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import Loader from '../../../../component/customComponents/loader/Loader';
import {
  ErrorMsg,
  TextAreaErrorMsg,
} from '../../../../component/customComponents/errorMsg/ErrorMsg';
import {
  minRejectReasonLength,
  rejectReasonLength,
  NO_WHITE_SPACE_BEGINNING_END,
} from '../../../../component/customComponents/validation/Validation';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { downloadImageWithoutBlob } from '../../../../component/customComponents/commonFunctions/CommonFunctions';

import AdminIndex from '../../AdminIndex';

// import './ManageUser.css';
const Img = styled('img')({
  display: 'block',
  maxWidth: '100%',
  height: 'auto',
  maxHeight: '50vh',
  borderRadius: '6px',
});

//#region ALL HOOKS AND VARIABALS

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 367,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function createData(date, orderId, price, orderStatus) {
  return { date, orderId, price, orderStatus };
}

const { getUserDetail } = AdminIndex.AdminSagaActions;

export default function ManageUser() {
  let token = localStorage.getItem('auth');
  
  const dispatch = AdminIndex.useDispatch();
  const history = useHistory();
  const params = useParams();
  const [details, setDetails] = useState(false);
  const [tab, setTab] = useState('1');
  const [open, setOpen] = useState(false);
  const [rejectReason, setRejectReason] = useState('');
  const [error, setError] = React.useState('');
  const [material, setMaterial] = React.useState([]);
  const [item, setItem] = React.useState([]);
  
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
    watch,
  } = useForm();

  useEffect(() => {
    getInfo();
  }, []);

  const rows = [
    createData('28-04-2022', 'P00000', '0000', 'Accepted'),
    createData('28-04-2022', 'P00000', '0000', 'Rejected'),
    createData('28-04-2022', 'P00000', '0000', 'Accepted'),
    createData('28-04-2022', 'P00000', '0000', 'Rejected'),
    createData('28-04-2022', 'P00000', '0000', 'Accepted'),
    createData('28-04-2022', 'P00000', '0000', 'Rejected'),
    createData('28-04-2022', 'P00000', '0000', 'Accepted'),
    createData('28-04-2022', 'P00000', '0000', 'Rejected'),
  ];
  //#endregion

  //#region ALL EVENT HANDLERS AND  FUCTIONS
  const getInfo = async () => {
    let formdata = new URLSearchParams();
    formdata.append('id', params.id);

    dispatch(getUserDetail(token, formdata));
    // try {
    //   formdata.append('id', params.id);
    //   const user = await dataService.post(Api.Admin.userDetails, formdata, {
    //     headers: {
    //       auth: token,
    //     },
    //   });
    //   setDetails(user.data.data[0]);
    //   setMaterial(user.data.data?.[0]?.material?.map((val) => val.material));
    //   setItem(user.data.data?.[0]?.item?.map((val) => val.item));
    // } catch (err) {
    //   toast.error(err?.message || 'Something went wrong');
    // }
  };

  const loading = AdminIndex.useSelector((state) => {
    return state.AdminSaga.loading;
  });

  const userDetail = AdminIndex.useSelector((state) => {
    return state.AdminSaga.userDetail;
  });

  useEffect(() => {
    setDetails(userDetail[0]);
    setMaterial(userDetail?.[0]?.material?.map((val) => val.material));
    setItem(userDetail?.[0]?.item?.map((val) => val.item));
  }, [userDetail]);
  
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChangeReject = (e) => {
    setRejectReason(e.target.value);
  };

  const reject = async (data) => {
    try {
      let urlencoded = new URLSearchParams();
      urlencoded.append('id', params.id);
      urlencoded.append('reasonOfRejection', data.rejectReason?.trim());
      urlencoded.append('approveStatus', '2');
      const rejData = await dataService.post(Api.Admin.deleteUser, urlencoded, {
        headers: {
          auth: token,
        },
      });
      if (rejData.data.status === 1) {
        toast.success('User profile rejected successfully');
        history.push('/admin/usermanagement');
      }
    } catch (err) {
      toast.error(err?.message || 'Something went wrong');
    }
  };

  const accept = async () => {
    const urlencoded = new URLSearchParams();
    urlencoded.append('id', params.id);

    try {
      const rejData = await dataService.post(
        Api.Admin.approveUser,
        urlencoded,
        {
          headers: {
            auth: token,
          },
        }
      );
      if (rejData.data.status == '1') {
        toast.success('User profile accepted successfully');
        history.push(`/admin/usermanagement`);
      }
    } catch (err) {
      toast.error(err?.message || 'Something went wrong');
    }
  };

  //#endregion

  //#region RETURN JSX
  return (
    <>{loading ? (
      <Loader />
    ) : (
      <Container maxWidth="false" sx={{ mb: 5 }}>
        <Box>
          <Grid container>
            <Grid item xs={12} lg={6}>
              <Box className="mange-user-content">
                <Typography className="text-header-manageuser">
                  User details :
                </Typography>
                <Box className="manage-user-data-box">
                  <span className="manage-user-titles">User Id -</span>
                  {details?.userCount?.userCount
                    ? details?.userCount?.userCount
                    : details?.userCount}
                </Box>

                <Box className="manage-user-data-box">
                  <span className="manage-user-titles">Name -</span>
                  {details?.user_id?.name
                    ? details?.user_id?.name
                    : details?.name}
                </Box>

                <Box className="manage-user-data-box">
                  <span className="manage-user-titles">Contact No -</span>
                  {details?.contact_number}
                </Box>

                <Box className="manage-user-data-box">
                  <span className="manage-user-titles">Email -</span>
                  {details?.email}
                </Box>

                <Box className="manage-user-data-box">
                  <span className="manage-user-titles">Membership -</span>
                  Yes
                </Box>

                <Box className="manage-user-data-box">
                  <span className="manage-user-titles">Account -</span>
                  {details?.approveStatus == 1 ? 'Active' : 'Inactive'}
                </Box>

                <Box className="manage-user-data-box">
                  <span className="manage-user-titles">Company -</span>
                  {details?.company_name}
                </Box>

                <Box className="manage-user-data-box">
                  <span className="manage-user-titles">
                    Parmanent Address -
                  </span>
                  {details?.permanent_address}
                </Box>

                <Box className="manage-user-data-box">
                  <span className="manage-user-titles">Company Address -</span>
                  {details?.company_address}
                </Box>

                <Box className="manage-user-data-box">
                  <span className="manage-user-titles">Business Type -</span>
                  {details?.business_type}
                </Box>

                <Box className="manage-user-data-box">
                  <span className="manage-user-titles">GST No -</span>
                  {details?.gst_number}
                </Box>

                <Box className="manage-user-data-box">
                  <span className="manage-user-titles">Adhar No -</span>
                  {details?.uam_number}
                </Box>

                <Box className="manage-user-data-box">
                  <span className="manage-user-titles">Total Order -</span>
                  00
                </Box>

                <Box className="manage-user-data-box">
                  <span className="manage-user-titles">Pending Order -</span>
                  00
                </Box>
                {material?.length ? (
                  <Box className="manage-user-data-box">
                    <span className="manage-user-titles">Material -</span>
                    {String(material).replace(/,/g, ', ')}
                    {/* <Grid container>
                      <Grid item></Grid>
                      <Grid item></Grid>
                    </Grid> */}
                  </Box>
                ) : null}
                {item?.length ? (
                  <Box className="manage-user-data-box">
                    <Box sx={{ display: 'flex' }}>
                      <Box sx={{ width: '65px' }}>
                        <Typography className="manage-user-titles">
                          Item -
                        </Typography>
                      </Box>

                      <Box>{String(item).replace(/,/g, ', ')}</Box>
                    </Box>
                  </Box>
                ) : null}
              </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box className="mange-user-content">
                <Typography className="text-header-manageuser bold-manageuser">
                  Account details :
                </Typography>

                <Box className="manage-user-data-box">
                  <span className="manage-user-titles">Account name -</span>
                  {details?.account_name}
                </Box>

                <Box className="manage-user-data-box">
                  <span className="manage-user-titles">Account number -</span>
                  {details?.account_number}
                </Box>

                <Box className="manage-user-data-box">
                  <span className="manage-user-titles">IFSC code -</span>
                  {details?.ifsc_code}
                </Box>

                <Box className="manage-user-data-box">
                  <span className="manage-user-titles">Bank name -</span>
                  {details?.bank_name}
                </Box>

                <Box className="manage-user-data-box">
                  <span className="manage-user-titles">Branch address -</span>
                  {details?.branch_address}
                </Box>
                <Box
                  className="manage-user-data-box"
                  sx={{mt: 5}}
                >
                  <span className="text-header-manageuser bold-manageuser">Certificates :</span>
                  <Box sx={{ width: '100%', position: 'relative', mt: 5 }}>
                    <Grid container>
                      {/* {details?.image?.map((element, index) => (
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={4}
                          lg={6}
                          alignSelf="center"
                        >
                          <Box className="product-img-box product-img-box-set">
                            <Img
                              key={element}
                              src={element}
                              alt="no image"
                              className="product-img-design"
                              id={element}
                            />

                            <Box className="product-download-box"></Box>
                            <Tooltip title="Tap to Download">
                              <DownloadIcon
                                className="product-download-btn1"
                                onClick={() => {
                                  let imgElement =
                                    document.getElementById(element);
                                  imgElement.addEventListener(
                                    'click',
                                    downloadImageWithoutBlob
                                  );
                                  imgElement.click();
                                  imgElement.removeEventListener(
                                    'click',
                                    downloadImageWithoutBlob
                                  );
                                }}
                              />
                            </Tooltip>
                          </Box>
                        </Grid>
                      ))} */}
                      <Box
                        zeroMinWidth
                        sx={{
                          width: { xs: '330px', md: '350px' },
                          height: { xs: 'auto', md: '200px' },
                        }}
                      >
                        <OwlCarousel
                          sx={{
                            zIndex: 0,
                            display: 'gird',
                            justifyContent: 'flex-start',
                          }}
                          className="owl-theme"
                          margin={1}
                          nav
                          items="1"
                          dots={false}
                          autoplay
                          loop
                        >
                          {details?.image?.map((element, index) => (
                            <img
                            height="200px" width="100%"
                              key={element}
                              src={element}
                              alt="no image"
                              // className="product-img-design"
                              id={element}
                            />
                          ))}
                        </OwlCarousel>
                      </Box>
                    </Grid>
                  </Box>
                </Box>
                <Box
                  className="manage-user-data-box"
                  sx={{mt: 5}}
                >
                  <span className="text-header-manageuser bold-manageuser">Brand Images :</span>
                  <Box sx={{ width: '100%', position: 'relative', mt: 5 }}>
                    <Grid container>
                      {/* {details?.image?.map((element, index) => (
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={4}
                          lg={6}
                          alignSelf="center"
                        >
                          <Box className="product-img-box product-img-box-set">
                            <Img
                              key={element}
                              src={element}
                              alt="no image"
                              className="product-img-design"
                              id={element}
                            />

                            <Box className="product-download-box"></Box>
                            <Tooltip title="Tap to Download">
                              <DownloadIcon
                                className="product-download-btn1"
                                onClick={() => {
                                  let imgElement =
                                    document.getElementById(element);
                                  imgElement.addEventListener(
                                    'click',
                                    downloadImageWithoutBlob
                                  );
                                  imgElement.click();
                                  imgElement.removeEventListener(
                                    'click',
                                    downloadImageWithoutBlob
                                  );
                                }}
                              />
                            </Tooltip>
                          </Box>
                        </Grid>
                      ))} */}
                      <Box
                        zeroMinWidth
                        sx={{
                          width: { xs: '330px', md: '350px' },
                          height: { xs: 'auto', md: '200px' },
                        }}
                      >
                        <OwlCarousel
                          sx={{
                            zIndex: 0,
                            display: 'gird',
                            justifyContent: 'flex-start',
                          }}
                          className="owl-theme"
                          margin={1}
                          nav
                          items="1"
                          dots={false}
                          autoplay
                          loop
                        >
                          {/* {details?.image?.map((element, index) => ( */}
                            <img
                            height="200px" width="100%"
                              // key={element}
                              src={details?.uploadBrandImage}
                              alt="no image"
                              // className="product-img-design"
                              // id={element}
                            />
                          {/* ))} */}
                        </OwlCarousel>
                      </Box>
                    </Grid>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '260px',
            }}
          >
            <Button
              disabled={
                details?.approveStatus == '1' ||
                (details?.approveStatus == '2' && true)
              }
              variant="contained"
              sx={{
                borderRadius: 5,
                textTransform: 'none',
                width: '90px',
                bgcolor: 'green',
              }}
              onClick={(e) => {
                if (
                  window.confirm('Are you sure you want to Approve this User?')
                ) {
                  accept(e);
                }
              }}
            >
              Approve
            </Button>
            <Button
              disabled={details?.approveStatus == '2' && true}
              variant="contained"
              sx={{
                borderRadius: 5,
                textTransform: 'none',
                width: '160px',
                bgcolor: 'red',
              }}
              onClick={(e) => {
                if (
                  window.confirm('Are you sure you want to Decline this User?')
                ) {
                  handleOpen(e);
                }
              }}
            >
              Deactivate Account
            </Button>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <form onSubmit={handleSubmit(reject)}>
                <Box sx={style}>
                  <Typography>Rejection reason</Typography>
                  <Box className="text-area-control-main">
                    <TextareaAutosize
                      autoComplete="off"
                      sx={{
                        mt: 2,
                        width: { lg: '300px', xs: '150px' },
                      }}
                      size="small"
                      fullWidth
                      // id="rejectReason"
                      className="form-control border-none text-area-control1 text-area-control-admin"
                      name="rejectReason"
                      type="text"
                      {...register('rejectReason', {
                        required: (
                          <TextAreaErrorMsg msg="Please enter Rejection reason" />
                        ),
                        minLength: {
                          value: minRejectReasonLength,
                          message: (
                            <TextAreaErrorMsg
                              msg={`Maximum ${minRejectReasonLength} Characters Allowed`}
                            />
                          ),
                        },
                        maxLength: {
                          value: rejectReasonLength,
                          message: (
                            <TextAreaErrorMsg
                              msg={`Maximum ${rejectReasonLength} Characters Allowed`}
                            />
                          ),
                        },
                        pattern: {
                          value: NO_WHITE_SPACE_BEGINNING_END,
                          message: (
                            <TextAreaErrorMsg msg="Whitespace is not allowed at beginning or end" />
                          ),
                        },
                      })}
                    />
                    <ErrorMessage errors={errors} name="rejectReason" />
                  </Box>
                  <Button
                    sx={{ mt: 2, textTransform: 'none' }}
                    variant="contained"
                    type="submit"
                  >
                    Submit
                  </Button>
                </Box>
              </form>
            </Modal>
          </Box>
        </Box>
      </Container>
    )}
    </>
  );
}
//#endregion
