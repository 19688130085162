import React from 'react';
import { Box, Typography } from '@mui/material';

const NoDataFound = () => {
  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="h5" align='center'>No Records found</Typography>
    </Box>
  );
};

export default NoDataFound;
