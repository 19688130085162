import React, { useState, useEffect } from 'react';
import {
  Grid,
  TextField,
  Typography,
  Button,
  Box,
  Container,
  Modal,
  FormHelperText,
  TextareaAutosize,
} from '@mui/material';
import { useParams, useHistory } from 'react-router-dom';
import { Api } from '../../../../config/api';
import dataService from '../../../../config/dataService';
import { ToastContainer, toast } from 'react-toastify';
import DeclineButton from '../../../../component/customComponents/buttons/DeclineButton';
import ContainedButton from '../../../../component/customComponents/buttons/ContainedButton';
import Png from '../../../../assets/png';
import moment from 'moment';
import { price } from '../../../../component/customComponents/commonFunctions/CommonFunctions';
import Loader from '../../../../component/customComponents/loader/Loader';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import {
  ErrorMsg,
  TextAreaErrorMsg,
} from '../../../../component/customComponents/errorMsg/ErrorMsg';
import {
  minRejectReasonLength,
  rejectReasonLength,
  NO_WHITE_SPACE_BEGINNING_END,
} from '../../../../component/customComponents/validation/Validation';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import AdminIndex from '../../AdminIndex';


//#region ALL HOOKS AND VARIABALS
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 367,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const { getProductDetail, rejectProduct } = AdminIndex.AdminSagaActions;
const ViewUploadedProduct = () => {
  let token = localStorage.getItem('auth');
  const dispatch = AdminIndex.useDispatch();
  const history = useHistory();
  const navigate = useHistory();
  const { id } = useParams();
  // const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [details, setDetails] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
    watch,
  } = useForm();

  useEffect(() => {
    getInfo();
  }, []);
  //#endregion

  //#region ALL EVENT HANDLERS AND  FUCTIONS
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const getInfo = async () => {
    // setLoading(true);
    const urlencoded = new URLSearchParams();
    try {
      urlencoded.append('id', id);
      dispatch(getProductDetail(token,urlencoded));
      // const Data = await dataService.post(Api.Admin.getProduct, urlencoded, {
      //   headers: {
      //     auth: token,
      //   },
      // });

      // setDetails(Data.data.data);
    } catch (err) {
      toast.error(err?.message || 'Something went wrong');
    } finally {
      // setLoading(false);
    }
  };

  const loading = AdminIndex.useSelector((state) => {
    return state.AdminSaga.loading;
  });

  const productDetail = AdminIndex.useSelector((state) => {
    return state.AdminSaga.productDetail;
  });

  useEffect(() => {
    setDetails(productDetail);
  }, [productDetail]);

  const reject = async (data) => {
    let urlencoded = new URLSearchParams();
    try {
      urlencoded.append('id', id);
      urlencoded.append('reasonOfRejection', data.rejectReason?.trim());

      dispatch(rejectProduct(token,urlencoded,navigate));
      // const rejData = await dataService.post(
      //   Api.Admin.productRejected,
      //   urlencoded,
      //   {
      //     headers: {
      //       auth: token,
      //     },
      //   }
      // );

      // if (rejData.data.status === 1) {
      //   toast.success('Product rejected successfully');
      //   history.push('/admin/productmanagement/rejectedproducts');
      // }
    } catch (err) {
      toast.error(err?.message || 'Something went wrong');
    }
  };
  //#endregion

  //#region RETURN JSX
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Container sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box sx={{ width: '100%', border: 1, mt: 2, p: 2 }}>
            <Box className="mange-user-content">
              <Box className="manage-user-data-box">
                <span className="manage-user-titles">Uploaded Date -</span>
                {/* {new Date(details[0]?.updatedAt).toLocaleDateString()} */}
                {moment(details[0]?.updatedAt).format('DD/MM/YYYY')}
              </Box>
              <Box className="manage-user-data-box">
                <span className="manage-user-titles">Seller -</span>
                {details[0]?.name || details[0]?.company_name}
              </Box>

              <Box className="manage-user-data-box">
                <span className="manage-user-titles">Company -</span>
                {details[0]?.company_name ? details[0]?.company_name : 'Admin'}
              </Box>

              <Box className="manage-user-data-box">
                <span className="manage-user-titles">Product -</span>
                {details[0]?.item_id?.item}
              </Box>

              <Box className="manage-user-data-box">
                <span className="manage-user-titles">Price -</span>
                {price(details[0]?.total_payable_amount)}
                <span>
                  <img src={Png.rupee} className="rupee-icon"></img>
                </span>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '200px',
                }}
              >
                <DeclineButton
                  onClick={(e) => {
                    if (
                      window.confirm(
                        'Are you sure you want to Decline this Product?'
                      )
                    ) {
                      handleOpen(e);
                    }
                  }}
                />
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <form onSubmit={handleSubmit(reject)}>
                    <Box sx={style}>
                      <Typography>Rejection reason</Typography>
                      <Box className="text-area-control-main">
                        <TextareaAutosize
                          autoComplete="off"
                          sx={{
                            mt: 2,
                            width: { lg: '300px', xs: '150px' },
                          }}
                          size="small"
                          fullWidth
                          // id="rejectReason"
                          className="form-control border-none text-area-control1 text-area-control-admin"
                          name="rejectReason"
                          type="text"
                          {...register('rejectReason', {
                            required: (
                              <TextAreaErrorMsg msg="Please enter Rejection reason" />
                            ),
                            minLength: {
                              value: minRejectReasonLength,
                              message: (
                                <TextAreaErrorMsg
                                  msg={`Minimum ${minRejectReasonLength} Characters Allowed`}
                                />
                              ),
                            },
                            maxLength: {
                              value: rejectReasonLength,
                              message: (
                                <TextAreaErrorMsg
                                  msg={`Maximum ${rejectReasonLength} Characters Allowed`}
                                />
                              ),
                            },
                            pattern: {
                              value: NO_WHITE_SPACE_BEGINNING_END,
                              message: (
                                <TextAreaErrorMsg msg="Whitespace is not allowed at beginning or end" />
                              ),
                            },
                          })}
                        />
                        <ErrorMessage errors={errors} name="rejectReason" />
                      </Box>
                      <Button
                        sx={{ mt: 2, textTransform: 'none' }}
                        variant="contained"
                        type="submit"
                      >
                        Submit
                      </Button>
                    </Box>
                  </form>
                </Modal>
                <ContainedButton
                  name="Edit"
                  onClick={() =>
                    history.push(
                      `/admin/updateproductdetails/${details[0]?._id}`
                    )
                  }
                />
              </Box>
            </Box>
          </Box>
        </Container>
      )}
    </>
  );
};

export default ViewUploadedProduct;
//#endregion
