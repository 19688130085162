import React from 'react';
import { Button } from '@mui/material';

const ApproveButton = (props) => {
  return (
    <Button
      disabled={props.disabled}
      className={'right-btns' && props.className}
      variant="contained"
      sx={{
        borderRadius: 5,
        textTransform: 'none',
        width: '90px',
        bgcolor: 'green',
        display: props.display
      }}
      onClick={props.onClick}
    >
      Approve
    </Button>
  );
};

export default ApproveButton;
