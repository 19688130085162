import React from "react";
import jwt_decode from "jwt-decode";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Sidebar from "../component/admin/DefaultLayout/Sidebar";
import Home from "../container/user/pages/home/Home";
import Faq from "../container/user/pages/FAQ/Faq";
import Customerservices from "../container/user/pages/customerservice/Customerservices";
import Aboutus from "../container/user/pages/aboutus/Aboutus";
import Blogs from "../container/user/pages/blogs/Blogs";
import Rings from "../container/user/pages/rings/Rings";
import Requestproduct1 from "../container/user/pages/requestproduct1/Requestproduct1";
import Mensjewelry from "../container/user/pages/mensjewellery/Mensjewelry";
import Womensjewelry from "../container/user/pages/womensjewellery/Womensjewelry";
import Pricing from "../container/user/pages/goldinfo/Pricing";
import Uploadingproducts from "../container/user/pages/uploadingproducts/Uploadingproducts";
import Membershipform from "../container/user/pages/membershipform/Membershipform";
import Guidelines from "../container/user/pages/blogs/Guidelines";
import AdminLogin from "../container/Admin/Pages/Authentication/Login";
import Membership from "../container/user/pages/membershipform/Membership";
import Ringsinfo from "../container/user/pages/rings/Ringsinfo";
import Requestingproducts from "../container/user/pages/requestproduct1/Requestingproducts";
import ProductDetails from "../container/user/pages/mensjewellery/ProductDetails";
import Rings2 from "../container/user/pages/rings/Rings2";
import Myproduct from "../container/user/pages/myproduct/Myproduct";
import DetailsPage from "../container/user/pages/detailsPage/DetailsPage";
import AddProduct from "../container/user/pages/addproduct/AddProduct";
import PreciousStones from "../container/user/pages/preciousStones/PreciousStones";
import AddProduct1 from "../container/user/pages/addproduct/AddProduct1";
import MyOrder from "../container/user/pages/myOrder/MyOrder";
import CustomeProduct from "../container/user/pages/customeProduct/CustomeProduct";
import OrderPlaceViewPage from "../container/user/pages/orderPlace/OrderPlaceViewPage";
import OrdersReceivedViewPage from "../container/user/pages/ordersReceived/OrdersReceivedViewPage";
import MyCart from "../container/user/pages/myCart/MyCart";
import PaymentMethod from "../container/user/pages/paymentMethod/PaymentMethod";
import ReturnOrder from "../container/user/pages/returnOrder/ReturnOrder";
import ViewReturnOrder from "../container/user/pages/returnOrder/ViewReturnOrder";
import PreciousStone from "../container/user/pages/addproduct/PreciousStone";
import MyPreciousStone from "../container/user/pages/myproduct/MyPreciousStone";
import AccountDetails from "../container/user/pages/AccountDetails/AccountDetails";
import MyProfile from "../container/user/pages/MyProfile/MyProfile";
import ViewCoustomeProductData from "../container/user/pages/customeProduct/ViewCoustomeProductData";
import FilterPage from "../container/user/pages/filterPage/FilterPage";
import CustomeOrderPlaceView from "../container/user/pages/orderPlace/CustomeOrderPlaceView";
import Catalogues from "../container/user/pages/home/Catalogues";
import CataloguesSeller from "../container/user/pages/home/CataloguesSeller";
import CatalogueDetails from "../container/user/pages/home/CatalogueDetails";
import ForgotPassword from "../container/Admin/Pages/Authentication/ForgotPassword";
import { useHistory } from "react-router-dom";
import ResetPassword from "../container/Admin/Pages/Authentication/ResetPassword";
import UserIndex from "../container/user/UserIndex";
import OtpVerify from "../container/Admin/Pages/Authentication/OtpVerify";
import Purity from "../container/user/pages/goldinfo/Purity";
import ViewReceiveReturnOrder from "../container/user/pages/returnOrder/ViewReceiveReturnOrder";
import ForgotPasswordEmail from "../container/user/auth/forgotPassword/ForgotPasswordEmail";
import UserForgotPassword from "../container/user/auth/forgotPassword/UserForgotPassword";
import ReturnPolicy from "../container/user/pages/Return Policy/ReturnPolicy";
import TermsConditions from "../container/user/pages/Terms & Conditions/TermsConditions";
import PrivacyPolicy from "../container/user/pages/privacyPolicy/PrivacyPolicy";
import ContactUs from "../container/user/pages/contactUs/ContactUs";
// import UserOtpVerify from "../container/user/auth/forgotPassword/UserOtpVerify";

export default function AllRoutes(props) {
  const history = useHistory();
  const login = localStorage.getItem("auth");
  const token = UserIndex.useSelector((state) => {
    return state.Usersaga.Token;
  });
  // const login = UserIndex.useSelector((state) => {
  //   return state.AdminSaga.auth;
  // });

  const Userdata = UserIndex.useSelector((state) => {
    return state.Usersaga.Userdata;
  });

  if (login) {
    var decoded = jwt_decode(login);
    const expirationTime = decoded.exp * 1000;
    if (expirationTime < Date.now()) {
      localStorage.removeItem("auth");
      history?.push("/admin/login");
    } else {
    }
  }
  const Admin = ({ match }) => {
    return (
      <>
        <Route path={`${match.url}/login`} component={AdminLogin} />
        <Route
          path={`${match.url}/forgotpassword`}
          component={ForgotPassword}
        />
        <Route path={`${match.url}/resetpassword`} component={ResetPassword} />
        <Route path={`${match.url}/otpverify`} component={OtpVerify} />
        {login !== "" ? (
          <>
            <Route exact path={`${match.url}/`} component={Sidebar} />
            <Route
              exact
              path={`${match.url}/usermanagement/`}
              component={Sidebar}
            />
            <Route
              path={`${match.url}/usermanagement/manageuser/:id`}
              component={Sidebar}
            />
            <Route
              path={`${match.url}/usermanagement/adduser`}
              component={Sidebar}
            />
            <Route
              exact
              path={`${match.url}/categorymanagement`}
              component={Sidebar}
            />
            <Route
              exact
              path={`${match.url}/materialmanagement`}
              component={Sidebar}
            />
            <Route
              path={`${match.url}/materialmanagement/addmaterial`}
              component={Sidebar}
            />
            <Route
              path={`${match.url}/materialmanagement/updatematerial/:id`}
              component={Sidebar}
            />
            <Route
              exact
              path={`${match.url}/itemmanagement`}
              component={Sidebar}
            />
            <Route
              path={`${match.url}/itemmanagement/additem`}
              component={Sidebar}
            />
            <Route
              path={`${match.url}/itemmanagement/updateitem/:id`}
              component={Sidebar}
            />
            <Route
              path={`${match.url}/categorymanagement/addcategory`}
              component={Sidebar}
            />
            <Route
              path={`${match.url}/categorymanagement/updatecategory/:id`}
              component={Sidebar}
            />
            <Route
              path={`${match.url}/categorymanagement/addsubcategory`}
              component={Sidebar}
            />
            <Route path={`${match.url}/apimanagement`} component={Sidebar} />
            <Route
              path={`${match.url}/paymentmanagement`}
              component={Sidebar}
            />
            <Route path={`${match.url}/notification`} component={Sidebar} />
            <Route
              exact
              path={`${match.url}/contentmanagement`}
              component={Sidebar}
            />
            <Route
              path={`${match.url}/contentmanagement/addaboutus`}
              component={Sidebar}
            />
            <Route
              path={`${match.url}/contentmanagement/updateaboutus/:id`}
              component={Sidebar}
            />
            <Route
              path={`${match.url}/contentmanagement/addtermsandconditions`}
              component={Sidebar}
            />
            <Route
              path={`${match.url}/contentmanagement/updatetermsandconditions/:id`}
              component={Sidebar}
            />
            <Route
              path={`${match.url}/contentmanagement/returnpolicy`}
              component={Sidebar}
            />
            <Route
              path={`${match.url}/contentmanagement/updatereturnpolicy/:id`}
              component={Sidebar}
            />
            <Route
              path={`${match.url}/contentmanagement/addprivacypolicy`}
              component={Sidebar}
            />
            <Route
              path={`${match.url}/contentmanagement/updateprivacypolicy/:id`}
              component={Sidebar}
            />
            <Route
              path={`${match.url}/contentmanagement/addcustomerservices`}
              component={Sidebar}
            />
            <Route
              path={`${match.url}/contentmanagement/updatecustomerservices/:id`}
              component={Sidebar}
            />
            <Route
              exact
              path={`${match.url}/contentmanagement/faq`}
              component={Sidebar}
            />
            <Route
              path={`${match.url}/contentmanagement/faq/addfaq`}
              component={Sidebar}
            />
            <Route
              path={`${match.url}/contentmanagement/faq/updatefaq/:id`}
              component={Sidebar}
            />
            <Route
              exact
              path={`${match.url}/contentmanagement/topdeals`}
              component={Sidebar}
            />
            <Route
              path={`${match.url}/contentmanagement/topdeals/addtopdeals`}
              component={Sidebar}
            />
            <Route
              path={`${match.url}/contentmanagement/topdeals/updatetopdeals/:id`}
              component={Sidebar}
            />
            <Route
              path={`${match.url}/purchaseorder/:id`}
              component={Sidebar}
            />
            <Route
              path={`${match.url}/admintosellerinvoice/:id/:pdfName`}
              component={Sidebar}
            />

            <Route exact path={`${match.url}/blogs`} component={Sidebar} />
            <Route exact path={`${match.url}/subblogs`} component={Sidebar} />
            <Route path={`${match.url}/blogs/addblogs`} component={Sidebar} />
            <Route
              path={`${match.url}/blogs/updateblogs/:id`}
              component={Sidebar}
            />
            <Route exact path={`${match.url}/guidelines`} component={Sidebar} />
            <Route
              path={`${match.url}/blogs/addguidelines`}
              component={Sidebar}
            />
            <Route
              path={`${match.url}/blogs/updateguidelines/:id`}
              component={Sidebar}
            />
            <Route
              exact
              path={`${match.url}/productdetails`}
              component={Sidebar}
            />
            <Route
              path={`${match.url}/blogs/addproductdetails`}
              component={Sidebar}
            />
            <Route
              path={`${match.url}/blogs/updateproductdetails/:id`}
              component={Sidebar}
            />
            <Route
              exact
              path={`${match.url}/productpurity`}
              component={Sidebar}
            />
            <Route
              path={`${match.url}/blogs/addproductpurity`}
              component={Sidebar}
            />
            <Route
              path={`${match.url}/blogs/updateproductpurity/:id`}
              component={Sidebar}
            />
            <Route
              exact
              path={`${match.url}/productpricing`}
              component={Sidebar}
            />
            <Route
              path={`${match.url}/blogs/addproductpricing`}
              component={Sidebar}
            />
            <Route
              path={`${match.url}/blogs/updateproductpricing/:id`}
              component={Sidebar}
            />
            <Route exact path={`${match.url}/membership`} component={Sidebar} />
            <Route
              path={`${match.url}/membership/membershipcontent`}
              component={Sidebar}
            />
            <Route
              path={`${match.url}/membership/formfillinguide`}
              component={Sidebar}
            />
            <Route
              path={`${match.url}/membership/membershiptermsandcondition`}
              component={Sidebar}
            />
            <Route
              path={`${match.url}/masteradminaccount`}
              component={Sidebar}
            />
            <Route
              exact
              path={`${match.url}/productmanagement`}
              component={Sidebar}
            />
            <Route
              path={`${match.url}/productmanagement/addproducts`}
              component={Sidebar}
            />
            <Route
              path={`${match.url}/productmanagement/addproducts1`}
              component={Sidebar}
            />
            <Route
              exact
              path={`${match.url}/ordermanagement`}
              component={Sidebar}
            />
            <Route
              exact
              path={`${match.url}/pendingorders`}
              component={Sidebar}
            />
            <Route
              exact
              path={`${match.url}/ordermanagement/orderdescription/:id`}
              component={Sidebar}
            />
            <Route
              exact
              path={`${match.url}/pendingorders/pendingorderdescription/:id`}
              component={Sidebar}
            />
            <Route
              exact
              path={`${match.url}/rejectedorders`}
              component={Sidebar}
            />
            <Route
              exact
              path={`${match.url}/returnorders`}
              component={Sidebar}
            />
            <Route
              exact
              path={`${match.url}/returnorders/viewreturnorder/:id`}
              component={Sidebar}
            />
            <Route path={`${match.url}/reports`} component={Sidebar} />
            <Route
              exact
              path={`${match.url}/productmanagement/customproducts`}
              component={Sidebar}
            />
            <Route
              path={`${match.url}/productmanagement/customproducts/viewcustomproduct/:id`}
              component={Sidebar}
            />
            <Route
              path={`${match.url}/productmanagement/customproducts/updatecustomproduct/:id`}
              component={Sidebar}
            />
            <Route
              path={`${match.url}/productmanagement/bidproducts`}
              component={Sidebar}
            />
            <Route
              exact
              path={`${match.url}/usermanagement/manageuser`}
              component={Sidebar}
            />
            <Route
              exact
              path={`${match.url}/productmanagement/pendingproducts/viewpendingproducts/:id`}
              component={Sidebar}
            />
            <Route
              exact
              path={`${match.url}/updateproductdetails/:id`}
              component={Sidebar}
            />
            <Route
              exact
              path={`${match.url}/productmanagement/pendingproducts/viewuploadedproducts/:id`}
              component={Sidebar}
            />
            <Route
              exact
              path={`${match.url}/productmanagement/pendingproducts`}
              component={Sidebar}
            />
            <Route
              path={`${match.url}/productmanagement/uploadedproducts`}
              component={Sidebar}
            />
            <Route
              path={`${match.url}/productmanagement/rejectedproducts`}
              component={Sidebar}
            />
            <Route
              path={`${match.url}/ordermanagement/allorders`}
              component={Sidebar}
            />
            <Route
              path={`${match.url}/ordermanagement/singlemultipleorders`}
              component={Sidebar}
            />
            <Route
              path={`${match.url}/ordermanagement/bulkorders`}
              component={Sidebar}
            />
          </>
        ) : (
          <>
            <Redirect to={`${match.url}/login`} component={AdminLogin} />
          </>
        )}
      </>
    );
  };

  const User = ({ match }) => {
    return (
      <>
        <Route exact path={`${match.url}/`} component={Home} />
        <Route path={`${match.url}/faq`} component={Faq} />
        <Route path={`${match.url}/return-policy`} component={ReturnPolicy} />
        <Route path={`${match.url}/terms-conditions`} component={TermsConditions} />
        <Route path={`${match.url}/privacy-policy`} component={PrivacyPolicy} />
        <Route path={`${match.url}/contact-us`} component={ContactUs} />
        <Route path={`${match.url}/aboutus`} component={Aboutus} />
        <Route path={`${match.url}/blogs`} component={Blogs} />
        <Route path={`${match.url}/guidelines`} component={Guidelines} />
        {/* new */}
        <Route
          path={`${match.url}/forgotpassword`}
          component={UserForgotPassword}
        />
        {/* <Route
          path={`${match.url}/forgotpassword`}
          component={ForgotPasswordEmail}
        /> */}
        {/* <Route path={`${match.url}/otpverify`} component={UserOtpVerify} /> */}

        {/* new */}
        <Route
          path={`${match.url}/customerservices`}
          component={Customerservices}
        />
        <Route path={`${match.url}/membership`} component={Membership} />
        <Route path={`${match.url}/catalogues`} component={Catalogues} />
        <Route
          path={`${match.url}/category/:category_id`}
          component={Mensjewelry}
        />
        <Route
          path={`${match.url}/categoryd/:category_id/:item_id`}
          component={Mensjewelry}
        />
        <Route
          path={`${match.url}/uploadingproducts`}
          component={Uploadingproducts}
        />
        <Route
          path={`${match.url}/uploadingproductstype/:type`}
          component={Uploadingproducts}
        />
        <Route
          path={`${match.url}/uploadingproductsid/:id`}
          component={Uploadingproducts}
        />
        <Route path={`${match.url}/purity/:material`} component={Purity} />
        <Route path={`${match.url}/pricing/:category`} component={Pricing} />
        <Route
          path={`${match.url}/product-dimensions/:item`}
          component={Ringsinfo}
        />
        <Route
          path={`${match.url}/membershipform`}
          component={Membershipform}
        />
        {token !== "" ? (
          <>
            <Route
              path={`${match.url}/account-details`}
              component={AccountDetails}
            />
            <Route path={`${match.url}/my-profile`} component={MyProfile} />
            <Route path={`${match.url}/rings`} component={Rings} />
            <Route
              path={`${match.url}/requestproduct1`}
              component={Requestproduct1}
            />
            <Route
              path={`${match.url}/womensjewelry`}
              component={Womensjewelry}
            />
            <Route
              path={`${match.url}/filterpage/:filter_text`}
              component={FilterPage}
            />
            <Route
              path={`${match.url}/requestingproduct`}
              component={Requestingproducts}
            />
            <Route
              path={`${match.url}/requestingproductid/:id`}
              component={Requestingproducts}
            />
            <Route
              path={`${match.url}/ProductDetail`}
              component={ProductDetails}
            />
            <Route
              path={`${match.url}/ProductDetails/:id`}
              component={ProductDetails}
            />
            <Route path={`${match.url}/rings2`} component={Rings2} />
            <Route path={`${match.url}/myproduct`} component={Myproduct} />
            <Route path={`${match.url}/detailspage`} component={DetailsPage} />
            <Route
              path={`${match.url}/detailspageid/:id`}
              component={DetailsPage}
            />
            <Route
              path={`${match.url}/viewcustomproductdata/:custom_id`}
              component={ViewCoustomeProductData}
            />
            <Route path={`${match.url}/addproduct`} component={AddProduct} />
            <Route
              path={`${match.url}/addproductid/:id`}
              component={AddProduct}
            />
            <Route
              path={`${match.url}/preciousstone`}
              component={PreciousStone}
            />
            <Route
              path={`${match.url}/preciousstoneid/:id`}
              component={PreciousStone}
            />
            <Route
              path={`${match.url}/mypreciousstone`}
              component={MyPreciousStone}
            />
            {/* <Route path={`${match.url}/preciousstone`} component={PreciousStones} /> */}
            <Route path={`${match.url}/addproduct1`} component={AddProduct1} />
            <Route path={`${match.url}/myorder`} component={MyOrder} />
            <Route
              path={`${match.url}/customeproduct`}
              component={CustomeProduct}
            />
            <Route
              path={`${match.url}/orderplaceviewpage/:getorder_id`}
              component={OrderPlaceViewPage}
            />
            <Route
              path={`${match.url}/customeorderplaceview/:getorder_id`}
              component={CustomeOrderPlaceView}
            />
            <Route
              path={`${match.url}/ordersreceivedviewpage/:receive_id`}
              component={OrdersReceivedViewPage}
            />
            <Route path={`${match.url}/mycart`} component={MyCart} />
            <Route path={`${match.url}/returnOrder`} component={ReturnOrder} />
            <Route
              path={`${match.url}/viewreturnorder/:id`}
              component={ViewReturnOrder}
            />
            <Route
              path={`${match.url}/viewreceivereturnorder/:id`}
              component={ViewReceiveReturnOrder}
            />
            <Route
              path={`${match.url}/paymentmethod`}
              component={PaymentMethod}
            />
            <Route
              path={`${match.url}/paymentmethodid/:id`}
              component={PaymentMethod}
            />
            <Route
              path={`${match.url}/paymentmethoddata/:custom_product_data`}
              component={PaymentMethod}
            />
            <Route
              path={`${match.url}/cataloguesseller/:item_id`}
              component={CataloguesSeller}
            />
            <Route
              path={`${match.url}/cataloguedetails/:item_data`}
              component={CatalogueDetails}
            />
          </>
        ) : (
          // <Redirect to={`${match.url}`} component={Home} />
          <Redirect to={`${match.url}`} component={User} />
        )}
      </>
    );
  };

  return (
    <>
      <BrowserRouter basename="/#">
        <ToastContainer />
        <Switch>
          <Route exact path="/">
            <Redirect to="/user" />
          </Route>
          <Route path="/user" component={User} />
          <Route path={`/admin/login`} component={AdminLogin} />
          <Route path="/admin" component={Admin} />
        </Switch>
      </BrowserRouter>
    </>
  );
}
