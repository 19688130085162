import React, { useEffect, useMemo, useRef, useState } from 'react'
import { price } from '../../../../component/customComponents/commonFunctions/CommonFunctions'
import UserIndex from '../../UserIndex'

const {
  catelogueProductSaga,
  productDesignsSaga,
  AddToCartBulkProductSaga,
  addcartSaga,
} = UserIndex.UserSagaActions

const CatalogueDetails = () => {
  const [selectedValue, setSelectedValue] = useState('individual')
  const [catalogueProduct, setCatalogueProduct] = useState('')
  const [noOfDesigns, setNoOfDesigns] = useState('')
  const [noOfPiecesEachDesigns, setNoOfPiecesEachDesigns] = useState('')
  const [bulkOrder, setBulkOrder] = useState('')
  const [addToCartBulkProduct, setAddToCartBulkProduct] = useState('')
  const [isCheckAll, setIsCheckAll] = useState(false)
  const [selectedItem, setSelectedItem] = useState([])
  const [product, setProduct] = useState()

  const dispatch = UserIndex.useDispatch()
  const history = UserIndex.useHistory()
  const navigate = UserIndex.useHistory()
  let params = UserIndex.useParams()

  var decodedString = JSON.parse(window.atob(params.item_data))

  const Token = UserIndex.useSelector((state) => {
    return state.Usersaga.Token
  })

  const token = UserIndex.useSelector((state) => {
    return state.Usersaga.Token
  })

  const Cartdata = UserIndex.useSelector((state) => {
    return state.Usersaga.Cartdata
  })

  const Userdata = UserIndex.useSelector((state) => {
    return state.Usersaga.Userdata
  })

  const CatelogueProduct = UserIndex.useSelector((state) => {
    return state.Usersaga.CatelogueProduct
  })

  const ProductDesigns = UserIndex.useSelector((state) => {
    return state.Usersaga.ProductDesigns
  })

  const AddToCartBulkProduct = UserIndex.useSelector((state) => {
    return state.Usersaga.AddToCartBulkProduct
  })

  const [noOfDesignsError, setNoOfDesignsError] = useState('')
  const [noOfPiecesEachDesignsError, setNoOfPiecesEachDesignsError] = useState(
    '',
  )

  const errorHandling = () => {
    if (noOfDesigns === '') {
      setNoOfDesignsError('Please enter no of designs')
    } else {
      setNoOfDesignsError('')
    }

    if (noOfPiecesEachDesigns === '') {
      setNoOfPiecesEachDesignsError('Please enter no of pieces in each designs')
    } else {
      setNoOfPiecesEachDesignsError('')
    }
  }

  const getCatelogueProduct = async () => {
    let urlencoded = new URLSearchParams()
    urlencoded.append('item_id', decodedString.itemId)
    urlencoded.append('seller_id', decodedString.sellerId)
    dispatch(catelogueProductSaga(urlencoded, navigate))
  }

  useEffect(() => {
    getCatelogueProduct()
  }, [decodedString.itemId, decodedString.sellerId])

  useEffect(() => {
    setCatalogueProduct(CatelogueProduct)
  }, [CatelogueProduct])

  const handleDesigns = (e) => {
    setNoOfDesigns(e.target.value)
  }

  const handlePiecesDesigns = (e) => {
    setNoOfPiecesEachDesigns(e.target.value)
  }

  const [bulkProduct, setBulkProduct] = useState('')

  const getBulkProduct = async () => {
    errorHandling()
    if (noOfDesigns !== '' && noOfPiecesEachDesigns !== '') {
      let urlencoded = new URLSearchParams()
      urlencoded.append('item_id', decodedString.itemId)
      urlencoded.append('seller_id', decodedString.sellerId)
      urlencoded.append('no_of_pieces', noOfDesigns)
      urlencoded.append('no_of_design', noOfPiecesEachDesigns)
      let res = dispatch(productDesignsSaga(urlencoded, navigate))
      setBulkProduct('1')
    } else {
    }
  }

  useEffect(() => {
    setBulkOrder(ProductDesigns)
  }, [ProductDesigns])

  const handleChange = (event) => {
    setSelectedValue(event.target.value)
  }

  const getAddToCartSelectedItem = async () => {
    let urlencoded = new URLSearchParams()
    if (selectedItem.length > 0) {
      urlencoded.append('productIdArray', JSON.stringify(selectedItem))
      dispatch(AddToCartBulkProductSaga(urlencoded, navigate, Token))
    }
  }

  const getAddToCartBulkProduct = async () => {
    let urlencoded = new URLSearchParams()
    urlencoded.append('item_id', decodedString.itemId)
    urlencoded.append('seller_id', decodedString.sellerId)
    urlencoded.append('no_of_pieces', noOfDesigns)
    urlencoded.append('no_of_design', noOfPiecesEachDesigns)
    dispatch(AddToCartBulkProductSaga(urlencoded, navigate, Token))
  }

  useEffect(() => {
    setAddToCartBulkProduct(AddToCartBulkProduct)
  }, [AddToCartBulkProduct])

  const controlProps = (item) => ({
    checked: selectedValue === item,
    onChange: handleChange,
    value: item,
    name: 'color-radio-button-demo',
    inputProps: { 'aria-label': item },
  })

  const getAddToCart = (e, id) => {
    let urlencoded = new URLSearchParams()
    urlencoded.append('productId', id)
    urlencoded.append('quantity', '1')
    urlencoded.append('shipping', '100')
    dispatch(addcartSaga(urlencoded, navigate, token))
  }

  useEffect(() => {
    setProduct(Cartdata)
  }, [Cartdata])

  const outOfStock = () => UserIndex.toast.error('Out Of Stock')

  const showToastMessage = () => {
    UserIndex.toast.error('Please login first to view/buy product' )
  }


  const selectItemHandler = (e, id) => {
    let arr = [...selectedItem]
    let { checked } = e.target
    if (checked) {
      setSelectedItem((arr) => {
        return [...arr, id]
      })
    } else {
      setSelectedItem((arr) => arr.filter((x) => x !== id))
    }
  }

  const handleSelectAll = (e) => {
    let itemId = []
    catalogueProduct?.map((item) => {
      itemId.push(item._id)
    })
    setIsCheckAll(!isCheckAll)
    setSelectedItem(itemId)
    if (isCheckAll) {
      setSelectedItem([])
    }
  }

  return (
    <div>
      <UserIndex.Header />
      <UserIndex.ValidatorForm>
        <UserIndex.Box className="catalogues-details-main-container">
          <UserIndex.Box className="container">
            <UserIndex.Box className="catalogues-details-select-bulk-section">
              <UserIndex.Button
                variant="text"
                className="catalogues-details-selectall-button"
                onClick={handleSelectAll}
              >
                Select All
              </UserIndex.Button>

              <UserIndex.Box className="catalogues-details-bulk-section">
                <UserIndex.Typography className="catalogues-details-ramdom-product-text">
                  Choose random products :
                </UserIndex.Typography>

                <UserIndex.FormHelperText className="catalogues-details-input-lable ">
                  No Of Pieces In Each Designs
                </UserIndex.FormHelperText>

                <UserIndex.Box className="radio-main">
                  <UserIndex.Box className="input-box addproduct-input-box justify-custom">
                    <UserIndex.TextValidator
                      fullWidth
                      id="no_of_pieces_in_each_designs"
                      className="form-control border-none"
                      value={noOfPiecesEachDesigns}
                      onChange={(e) => {
                        handlePiecesDesigns(e)
                      }}
                      name="no_of_pieces_in_each_designs"
                      type="numeber"
                      validators={['required']}
                    />
                  </UserIndex.Box>
                </UserIndex.Box>

                <UserIndex.FormHelperText className="catalogues-details-input-lable">
                  No Of Designs
                </UserIndex.FormHelperText>

                <UserIndex.Box className="radio-main">
                  <UserIndex.Box className="input-box addproduct-input-box">
                    <UserIndex.TextValidator
                      fullWidth
                      id="no_of_designs"
                      className="form-control border-none inputcustom-box"
                      value={noOfDesigns}
                      onChange={(e) => {
                        handleDesigns(e)
                      }}
                      name="no_of_designs"
                      type="number"
                      validators={['required']}
                    />
                  </UserIndex.Box>
                </UserIndex.Box>

                <UserIndex.Box className="product-btn-box">
                  <UserIndex.Button
                    onClick={getBulkProduct}
                    className="btn btn-product catalogues-details-bulk-btn"
                  >
                    Apply
                  </UserIndex.Button>
                </UserIndex.Box>
              </UserIndex.Box>
            </UserIndex.Box>
            <UserIndex.Box className="catalogues-details-errors-messages">
              <UserIndex.Box className="catalogues-details-errors-div">
                <UserIndex.Box>
                  <UserIndex.FormHelperText className="catalogues-details-error-input-lable">
                    {noOfPiecesEachDesignsError}
                  </UserIndex.FormHelperText>
                </UserIndex.Box>

                <UserIndex.Box>
                  <UserIndex.FormHelperText className="catalogues-details-error-input-lable">
                    {noOfDesignsError}
                  </UserIndex.FormHelperText>
                </UserIndex.Box>
              </UserIndex.Box>
            </UserIndex.Box>
            <UserIndex.Box>
              {bulkProduct == 1 ? (
                <>
                  <UserIndex.Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={{ xs: 3, sm: 5, md: 3, lg: 3 }}
                  >
                    {bulkOrder?.length
                      ? bulkOrder.map((item) => {
                          return (
                            <UserIndex.Box
                              gridColumn={{
                                xs: 'span 12',
                                sm: 'span 6',
                                md: 'span 3',
                                lg: 'span 3',
                              }}
                            >
                              <UserIndex.Box className="product-box">
                                <UserIndex.Box className="product-img-box">
                                  <img
                                    src={item.image[0]}
                                    className="product-img"
                                  ></img>
                                </UserIndex.Box>
                                <UserIndex.Box className="product-content">
                                  <UserIndex.Box className="product-title">
                                    {item?.item_id?.item}
                                  </UserIndex.Box>
                                  <UserIndex.Box className="product-sub-title">
                                    {item?.materialIdArray?.map((item) => {
                                      return (
                                        <>
                                          <UserIndex.Box>
                                            {item?.material_id?.material}
                                          </UserIndex.Box>
                                        </>
                                      )
                                    })}
                                  </UserIndex.Box>
                                  <UserIndex.Box className="product-price">
                                    ₹{price(item?.total_payable_amount)}
                                  </UserIndex.Box>
                                  <UserIndex.Box className="product-btn-box">
                                    <UserIndex.PrimaryButton
                                      btnclass="btn btn-product"
                                      onClick={() => {
                                        history.push(
                                          `/user/ProductDetails/${item?._id}`,
                                        )
                                      }}
                                      buttonvalue="View"
                                    />
                                  </UserIndex.Box>
                                </UserIndex.Box>
                              </UserIndex.Box>
                            </UserIndex.Box>
                          )
                        })
                      : 'No Record Found'}
                  </UserIndex.Box>
                  {bulkOrder && bulkOrder ? (
                    <UserIndex.Box className="product-btn-box catalogue-details-bulk-addtocart">
                      <UserIndex.PrimaryButton
                        btnclass="btn btn-product catalogues-details-bulk-btn"
                        onClick={getAddToCartBulkProduct}
                        buttonvalue="Add To Cart"
                      />
                    </UserIndex.Box>
                  ) : (
                    ''
                  )}
                </>
              ) : (
                <>
                  <UserIndex.Box>
                    <UserIndex.Box sx={{ width: 1 }}>
                      <UserIndex.Box
                        display="grid"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gap={{ xs: 3, sm: 5, md: 4, lg: 4 }}
                      >
                        {catalogueProduct.length
                          ? catalogueProduct.map((item) => {
                              return (
                                <UserIndex.Box
                                  gridColumn={{
                                    xs: 'span 12',
                                    sm: 'span 6',
                                    md: 'span 3',
                                    lg: 'span 3',
                                  }}
                                >
                                  <UserIndex.Box className="product-box">
                                    <UserIndex.Box className="product-img-box">
                                      <img
                                        src={item.image[0]}
                                        className="product-img"
                                      ></img>
                                      <UserIndex.Box className="show-hover">
                                        <UserIndex.Box className="add-cart-box-hover">
                                          {item.user_id == item?.[0]?._id ? (
                                            <>
                                              <UserIndex.Button className="btn hover-btns">
                                                <UserIndex.Box className="add-cart-bg">
                                                  <img
                                                    src={UserIndex.svg.iconcart}
                                                    className="hover-img"
                                                  ></img>
                                                </UserIndex.Box>
                                              </UserIndex.Button>
                                            </>
                                          ) : (
                                            <>
                                              {item?.no_of_pieces_available_inStock !==
                                              0 ? (
                                                <>
                                                  <UserIndex.Button
                                                    onClick={() => {
                                                      getAddToCart(
                                                        Cartdata,
                                                        item?._id,
                                                      )
                                                    }}
                                                    className="btn hover-btns"
                                                  >
                                                    <UserIndex.Box className="add-cart-bg">
                                                      <img
                                                        src={
                                                          UserIndex.svg.iconcart
                                                        }
                                                        className="hover-img"
                                                      ></img>
                                                    </UserIndex.Box>
                                                  </UserIndex.Button>
                                                </>
                                              ) : (
                                                <>
                                                  <UserIndex.Button
                                                    onClick={() => {
                                                      outOfStock()
                                                    }}
                                                    className="btn hover-btns"
                                                  >
                                                    <UserIndex.Box className="add-cart-bg">
                                                      <img
                                                        src={
                                                          UserIndex.svg.iconcart
                                                        }
                                                        className="hover-img"
                                                      ></img>
                                                    </UserIndex.Box>
                                                  </UserIndex.Button>
                                                </>
                                              )}
                                            </>
                                          )}
                                        </UserIndex.Box>
                                      </UserIndex.Box>
                                    </UserIndex.Box>
                                    <UserIndex.Box className="product-content">
                                      <UserIndex.Box className="product-title">
                                        {item?.item_id?.item}
                                      </UserIndex.Box>
                                      <UserIndex.Box className="product-sub-title">
                                        {item?.materialIdArray?.map((item) => {
                                          return (
                                            <>
                                              <UserIndex.Box>
                                                {item?.material_id?.material}
                                              </UserIndex.Box>
                                            </>
                                          )
                                        })}
                                      </UserIndex.Box>
                                      <UserIndex.Box className="product-price">
                                        {token && token !== null ? (
                                          <>
                                            ₹
                                            {price(
                                              item?.total_payable_amount,
                                            )}
                                          </>
                                        ) : (
                                          ''
                                        )}
                                      </UserIndex.Box>
                                      <UserIndex.Box className="product-btn-box">
                                        <UserIndex.Box>
                                          <UserIndex.Checkbox
                                            checked={selectedItem?.includes(
                                              item._id,
                                            )}
                                            // checked={true}
                                            onChange={(e) =>
                                              selectItemHandler(e, item._id)
                                            }
                                            sx={{
                                              color: '#c29958',
                                              '&.Mui-checked': {
                                                color: '#c29958',
                                              },
                                            }}
                                          />
                                        </UserIndex.Box>
                                        <UserIndex.Button
                                          onClick={() => {
                                            if (token === '') {
                                              showToastMessage()
                                            } else {
                                              history.push(
                                                `/user/ProductDetails/${item?._id}`,
                                              )
                                            }
                                          }}
                                          className="btn btn-product"
                                        >
                                          View
                                        </UserIndex.Button>
                                      </UserIndex.Box>
                                    </UserIndex.Box>
                                  </UserIndex.Box>
                                </UserIndex.Box>
                              )
                            })
                          : 'No Record Found'}
                      </UserIndex.Box>
                      <UserIndex.Box className="product-btn-box catalogue-details-bulk-addtocart">
                        <UserIndex.PrimaryButton
                          btnclass="btn btn-product catalogues-details-bulk-btn"
                          onClick={getAddToCartSelectedItem}
                          buttonvalue="Add To Cart"
                        />
                      </UserIndex.Box>
                    </UserIndex.Box>
                  </UserIndex.Box>
                </>
              )}
            </UserIndex.Box>
          </UserIndex.Box>
        </UserIndex.Box>
      </UserIndex.ValidatorForm>
      <UserIndex.Footer />
    </div>
  )
}

export default CatalogueDetails
