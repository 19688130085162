import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

import Header from '../../../../component/user/defaultLayout/Header';
import Footer from '../../../../component/user/defaultLayout/Footer';
import Pagination from '@mui/material/Pagination';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import UserSagaActions from '../../../../redux/Usersaga/action';
import { useDispatch, useSelector } from 'react-redux';

import ProductGrid from '../../../../component/user/productList/ProductGrid';
import { useHistory } from 'react-router-dom';
import FilterSection from '../../../../component/user/productList/FilterSection';
import { log } from '../../../../config/CommonFunctions';

const { AllproductSaga } = UserSagaActions;

export default function Rings() {
  const dispatch = useDispatch();
  const navigate = useHistory();
  const loding = useSelector((state) => {
    return state.Usersaga.loading;
  });
  const AllProductdata = useSelector((state) => {
    return state.Usersaga.AllProductdata;
  });

  const [allProduct, setAllProduct] = useState([]);

  const getAllProducts = async () => {
    let urlencoded = new URLSearchParams();
    dispatch(AllproductSaga(urlencoded, navigate));
  };

  useEffect(() => {
    setAllProduct(AllProductdata);
  }, [AllProductdata]);

  useEffect(() => {
    getAllProducts();
  }, []);

  return (
    <div>
      <Header />
      <Box className="inner-hero-sec">
        <Box className="container">
          <Box className="hero-title-box">
            <Typography variant="h4" className="hero-inner-title">
              <span>Rings</span>
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box className="rings-section">
        <Box className="container">
          <Box sx={{ width: 1 }}>
            <Box
              display="grid"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 0, sm: 0, md: 6, lg: 6 }}
            >
              <Box
                gridColumn={{
                  xs: 'span 12',
                  sm: 'span 12',
                  md: 'span 3',
                  lg: 'span 3',
                }}
              >
                <FilterSection />
              </Box>
              <Box
                gridColumn={{
                  xs: 'span 12',
                  sm: 'span 12',
                  md: 'span 9',
                  lg: 'span 9',
                }}
              >
                <Box sx={{ width: 1 }}>
                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={{ xs: 2, sm: 5, md: 4, lg: 4 }}
                  >
                    {allProduct.at.length
                      ? allProduct.map((item, err) => {
                          return <ProductGrid item={item} />;
                        })
                      : 'No Records Found'}
                  </Box>
                </Box>
                <Box sx={{ width: 1 }}>
                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={{ xs: 0, sm: 0, md: 5, lg: 5 }}
                  >
                    <Box
                      gridColumn={{
                        xs: 'span 12',
                        sm: 'span 12',
                        md: 'span 12',
                        lg: 'span 12',
                      }}
                    >
                      <Box className="pagination-box">
                        <Pagination
                          count={3}
                          variant="outlined"
                          shape="rounded"
                          className="pagination"
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Footer />
    </div>
  );
}
