import axios from "axios";
import { API_ENDPOINT } from "../config/dataService";
import { toast } from "react-toastify";
// const BASE_URL = 'http://35.177.56.74:3004';
// const BASE_URL = 'http://localhost:3004';

const BASE_URL = API_ENDPOINT;

const setHeadersWithAccessToken = (token) => {
  axios.defaults.headers.post["Auth"] = token;
};
const setGetHeadersWithAccessToken = (token) => {
  axios.defaults.headers.get["Auth"] = token;
};
const setHeadersWithContentType = () => {
  axios.defaults.headers.post["Content-Type"] =
    "application/x-www-form-urlencoded";
};
const setHeadersWithContentType2 = () => {
  axios.defaults.headers.post["Content-Type"] = "multipart/form-data";
};
const handleError = (error) =>
  new Promise(() => {
    console.log(error);
  });

export const registerApiHandler = (params) => {
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/user/signUp`, params)
    .then((e) => e.data)
    .catch(handleError);
};

export const memberApiHandler = (params) => {
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/remainingMemberDetails`, params)
    .then((e) => e.data)
    .catch(handleError);
};

export const loginApiHandler = (params) => {
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/user/signIn`, params)
    .then((e) => e.data)
    .catch(handleError);
};
export const userProductApiHandler = (params, Token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(Token);
  return axios
    .post(`${BASE_URL}/getUserProduct`, params)
    .then((e) => e.data)
    .catch(handleError);
};

export const allProductApiHandler = (params) => {
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/getAllProductUserside`, params)
    .then((e) => e.data)
    .catch(handleError);
};

export const singleUserProductApiHandler = (params) => {
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/getUserProductView`, params)
    .then((e) => e.data)
    .catch(handleError);
};

export const CategoryMenuApiHandler = (params) => {
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/getCategoryMenu`, params)
    .then((e) => e.data)
    .catch(handleError);
};

export const addToCartApiHandler = (params, Token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(Token);
  return axios
    .post(`${BASE_URL}/addToCart`, params)
    .then((e) => e.data)
    .catch(handleError);
};

export const viewCartApiHandler = (params, Token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(Token);
  return axios
    .post(`${BASE_URL}/viewCart`, params)
    .then((e) => e.data)
    .catch(handleError);
};

export const updateCartQuantityApiHandler = (params, Token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(Token);
  return axios
    .post(`${BASE_URL}/updateCartQuantity`, params)
    .then((e) => e.data)
    .catch(handleError);
};

export const deleteCartItemApiHandler = (params) => {
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/deleteCartItem`, params)
    .then((e) => e.data)
    .catch(handleError);
};

export const userCreateProductApiHandler = (params, Token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(Token);
  return axios
    .post(`${BASE_URL}/userCreateProduct`, params)
    .then((e) => e.data)
    .catch(handleError);
};

export const getSingleUserProductApiHandler = (params, Token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(Token);
  return axios
    .post(`${BASE_URL}/getSingleUserProduct`, params)
    .then((e) => e.data)
    .catch(handleError);
};

export const getListItemApiHandler = (params) => {
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/listItem`, params)
    .then((e) => e.data)
    .catch(handleError);
};

export const getListMaterialApiHandler = (params) => {
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/listMaterial`, params)
    .then((e) => e.data)
    .catch(handleError);
};

export const deleteUserProductApiHandler = (params, Token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(Token);
  return axios
    .post(`${BASE_URL}/deleteUserProduct`, params)
    .then((e) => e.data)
    .catch(handleError);
};

export const orderPlaceApiHandler = (params, Token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(Token);
  return axios
    .post(`${BASE_URL}/placeOrder`, params)
    .then((e) => e.data)
    .catch(handleError);
};

export const receivedOrderApiHandler = (params, Token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(Token);
  return axios
    .post(`${BASE_URL}/receivedOrder`, params)
    .then((e) => e.data)
    .catch(handleError);
};

export const viewReceivedOrderApiHandler = (params, Token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(Token);
  return axios
    .post(`${BASE_URL}/viewReceivedOrder`, params)
    .then((e) => e.data)
    .catch(handleError);
};

export const getOrderListApiHandler = (params, Token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(Token);
  return axios
    .post(`${BASE_URL}/getOrderList`, params)
    .then((e) => e.data)
    .catch(handleError);
};

export const viewOrderApiHandler = (params, Token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(Token);
  return axios
    .post(`${BASE_URL}/viewOrder`, params)
    .then((e) => e.data)
    .catch(handleError);
};

export const faqApiHandler = (params) => {
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/listFaqs`, params)
    .then((e) => e.data)
    .catch(handleError);
};

export const raiseCustomProductApiHandler = (params, Token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(Token);
  return axios
    .post(`${BASE_URL}/raiseCustomProduct`, params)
    .then((e) => e.data)
    .catch(handleError);
};

export const viewCustomProductApiHandler = (params, Token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(Token);
  return axios
    .post(`${BASE_URL}/viewCustomProduct`, params)
    .then((e) => e.data)
    .catch(handleError);
};

export const getSingleCustomProductApiHandler = (params, Token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(Token);
  return axios
    .post(`${BASE_URL}/getSingleCustomProduct`, params)
    .then((e) => e.data)
    .catch(handleError);
};

export const getAllCmsApiHandler = (params, Token) => {
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/getAllCms`, params)
    .then((e) => e.data)
    .catch(handleError);
};

export const addUpdateRatingApiHandler = (params, Token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(Token);
  return axios
    .post(`${BASE_URL}/addUpdateRating`, params)
    .then((e) => e.data)
    .catch(handleError);
};

export const singleRatingApiHandler = (params) => {
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/singleRating`, params)
    .then((e) => e.data)
    .catch(handleError);
};

export const getAllRatingApiHandler = (params) => {
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/getAllRating`, params)
    .then((e) => e.data)
    .catch(handleError);
};

export const recievedCustomProductApiHandler = (params, Token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(Token);
  return axios
    .post(`${BASE_URL}/recievedCustomProduct`, params)
    .then((e) => e.data)
    .catch(handleError);
};

export const acceptCustomProductApiHandler = (params, Token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(Token);
  return axios
    .post(`${BASE_URL}/acceptCustomProduct`, params)
    .then((e) => e.data)
    .catch(handleError);
};

export const returnOrderApiHandler = (params, Token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(Token);
  return axios
    .post(`${BASE_URL}/returnOrder`, params)
    .then((e) => e.data)
    .catch(handleError);
};

export const getallReturnOrderApiHandler = (params, Token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(Token);
  return axios
    .post(`${BASE_URL}/getallReturnOrder`, params)
    .then((e) => e.data)
    .catch(handleError);
};

export const getSingleReturnOrderApiHandler = (params, Token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(Token);
  return axios
    .post(`${BASE_URL}/getSingleReturnOrder`, params)
    .then((e) => e.data)
    .catch(handleError);
};

export const userCreatePriciousApiHandler = (params, Token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(Token);
  return axios
    .post(`${BASE_URL}/userCreateProduct`, params)
    .then((e) => e.data)
    .catch(handleError);
};

export const listBlogApiHandler = (params) => {
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/listBlog`, params)
    .then((e) => e.data)
    .catch(handleError);
};

export const listGuidelineApiHandler = (params) => {
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/listGuideline`, params)
    .then((e) => e.data)
    .catch(handleError);
};

export const singleBankDetailsApiHandler = (params, Token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(Token);
  return axios
    .post(`${BASE_URL}/listBankDetailsUser`, params)
    .then((e) => e.data)
    .catch(handleError);
};

export const listProductDescriptionApiHandler = (params) => {
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/listProductDescription`, params)
    .then((e) => e.data)
    .catch(handleError);
};

export const listProductPricingApiHandler = (params) => {
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/listProductPricing`, params)
    .then((e) => e.data)
    .catch(handleError);
};

export const listProductPurityApiHandler = (params) => {
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/listProductPurity`, params)
    .then((e) => e.data)
    .catch(handleError);
};

export const singleguidelineApiHandler = (params) => {
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/singleguideline`, params)
    .then((e) => e.data)
    .catch(handleError);
};

export const listCustomerServicesApiHandler = (params) => {
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/listCustomerServices`, params)
    .then((e) => e.data)
    .catch(handleError);
};

export const getAllProductHomeServicesApiHandler = (params) => {
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/getAllProductHome`, params)
    .then((e) => e.data)
    .catch(handleError);
};

export const trendingProductsServicesApiHandler = (params) => {
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/trendingProducts`, params)
    .then((e) => e.data)
    .catch(handleError);
};

export const listTopDealsServicesApiHandler = (params) => {
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/listTopDeals`, params)
    .then((e) => e.data)
    .catch(handleError);
};

export const listBenefitsServicesApiHandler = (params) => {
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/listBenefits`, params)
    .then((e) => e.data)
    .catch(handleError);
};

export const verifyOtpEmailApiHandler = (params) => {
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/verifyOtp`, params)
    .then((e) => e.data)
    .catch(handleError);
};
export const sendOtpEmailApiHandler = (params) => {
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/sendOtpMail`, params)
    .then((e) => e.data)
    .catch(handleError);
};

export const getAllPreciousStoneProductsApiHandler = (params, Token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(Token);
  return axios
    .post(`${BASE_URL}/getAllPreciousStoneProducts`, params)
    .then((e) => e.data)
    .catch(handleError);
};

export const updateDeliveryStausApiHandler = (params, Token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(Token);
  return axios
    .post(`${BASE_URL}/updateDeliveryStaus`, params)
    .then((e) => e.data)
    .catch(handleError);
};

export const getProfileApiHandler = (params, Token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(Token);
  return axios
    .post(`${BASE_URL}/getMyProfile`, params)
    .then((e) => e.data)
    .catch(handleError);
};

export const getAccountApiHandler = (params, Token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(Token);
  return axios
    .post(`${BASE_URL}/getSingleMember`, params)
    .then((e) => e.data)
    .catch(handleError);
};

export const updateAccountApiHandler = (params, Token) => {
  setHeadersWithContentType2();
  setHeadersWithAccessToken(Token);
  return axios
    .post(`${BASE_URL}/updateMyaccount`, params)
    .then((e) => e.data)
    .catch(handleError);
};

export const updatePasswordApiHandler = (params, Token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(Token);
  return axios
    .post(`${BASE_URL}/changePassword`, params)
    .then((e) => e.data)
    .catch(handleError);
};

export const filterMaterialAndItemApiHandler = (params, Token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(Token);
  return axios
    .post(`${BASE_URL}/filterMaterialAndItem`, params)
    .then((e) => e.data)
    .catch(handleError);
};

export const catelogueSellerApiHandler = (params) => {
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/catelogueSeller`, params)
    .then((e) => e.data)
    .catch(handleError);
};

export const catelogueProductApiHandler = (params) => {
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/catelogueProduct`, params)
    .then((e) => e.data)
    .catch(handleError);
};

export const productDesignsApiHandler = (params) => {
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/productDesigns`, params)
    .then((e) => e.data)
    .catch(handleError);
};

export const addToCartBulkProductApiHandler = (params, Token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(Token);
  return axios
    .post(`${BASE_URL}/addToCartBulkProduct`, params)
    .then((e) => e.data)
    .catch(handleError);
};

export const catelogueItemsApiHandler = (params) => {
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/catelogueItems`, params)
    .then((e) => e.data)
    .catch(handleError);
};

export const forgotPasswordApiHandler = (params) => {
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/forgotPassword`, params)
    .then((e) => e.data)
    .catch(handleError);
};

export const resendUserPasswordApiHandler = (params) => {
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/resendOtp`, params)
    .then((e) => e.data)
    .catch(handleError);
};
export const resetPasswordApiHandler = (params) => {
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/resetPassword`, params)
    .then((e) => e.data)
    .catch(handleError);
};

export const getAllReceivedReturnOrderApiHandler = (Token) => {
  setGetHeadersWithAccessToken(Token);
  return axios
    .get(`${BASE_URL}/getAllReceivedReturnOrder`)
    .then((e) => e.data)
    .catch(handleError);
};

export const viewReceivedReturnOrderApiHandler = (params, Token) => {
  setGetHeadersWithAccessToken(Token);
  return axios
    .get(`${BASE_URL}/viewReceivedReturnOrder/${params}`)
    .then((e) => e.data)
    .catch(handleError);
};
export const contactUsAction = (data, Token) => {
  setGetHeadersWithAccessToken(Token);
  return axios
    .post(`${BASE_URL}/addContactUs`,data)
    .then((e) => e.data)
    .catch(((error)=>{
      console.log(error);
      toast.error(error?.response?.data?.mes)
    }));
};


export const privacyPolicyAction = (params, Token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(Token);
  return axios
    .post(`${BASE_URL}/getUserProduct`, params)
    .then((e) => e.data)
    .catch(handleError);
};