import React, { useState } from 'react';
import Collapse from '@mui/material/Collapse';

import { useLocation, useHistory, Link } from 'react-router-dom';
import { Box, List, ListItemButton, Button } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import Actions from '../../../redux/adminSaga/action';

const { logoutSaga } = Actions;

const DashBordList = () => {
  const dispatch = useDispatch();
  const navigate = useHistory();
  const [openOrderManagement, setOpenOrderManagement] = useState(false);
  const [openProductManagement, setOpenProductManagement] = useState(false);

  const history = useHistory();
  const location = useLocation();

  const handleOpenCloseProductManagement = () => {
    setOpenProductManagement(!openProductManagement);
  };
  const handleOpenCloseOrderManagement = () => {
    setOpenOrderManagement(!openOrderManagement);
  };

  const handleClose = () => {
    dispatch(logoutSaga(navigate));
  };

  return (
    <>
      <Box
        noWrap
        variant="p"
        sx={{
          margin: '0px 0px 15px 16px',
          borderLeftColor: location.pathname == '/admin' ? '#32BEF1' : '',
          borderLeftStyle: location.pathname == '/admin' ? 'solid' : '',
          borderLeftWidth: location.pathname == '/admin' ? '2px' : '',
        }}
      >
        <Link
          to={'/admin'}
          className={
            location.pathname == '/admin'
              ? 'side-bar-link-selected'
              : 'side-bar-link'
          }
        >
          Dashboard
        </Link>
      </Box>

      <Box
        variant="p"
        noWrap
        sx={{
          margin: '0px 0px 15px 16px',
          borderLeftColor:
            location.pathname == '/admin/usermanagement' ? '#32BEF1' : '',
          borderLeftStyle:
            location.pathname == '/admin/usermanagement' ? 'solid' : '',
          borderLeftWidth:
            location.pathname == '/admin/usermanagement' ? '2px' : '',
        }}
      >
        <Link
          to={'/admin/usermanagement'}
          className={
            location.pathname == '/admin/usermanagement'
              ? 'side-bar-link-selected'
              : 'side-bar-link'
          }
        >
          User Management
        </Link>
      </Box>

      <List
        sx={{
          '& .css-cvhtoe-MuiButtonBase-root-MuiListItemButton-root': {
            p: 0,
            pl: '19px',
            mt: '0px',
          },
          '& .MuiList-root': {
            px: 2,
            mt: '0px !important',
          },
        }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        className="nested-list-subheader-sidebar"
      >
        <ListItemButton onClick={handleOpenCloseProductManagement}>
          <Box
            variant="p"
            noWrap
            sx={{
              borderLeftColor:
                location.pathname == '/admin/productmanagement'
                  ? '#32BEF1'
                  : '',
              borderLeftStyle:
                location.pathname == '/admin/productmanagement' ? 'solid' : '',
              borderLeftWidth:
                location.pathname == '/admin/productmanagement' ? '2px' : '',
            }}
          >
            <Link
              className={
                location.pathname == '/admin/productmanagement'
                  ? 'side-bar-link-selected'
                  : 'side-bar-link'
              }
            >
              Product Management
            </Link>
          </Box>

          {openProductManagement ? (
            <ExpandLess sx={{ color: 'white' }} />
          ) : (
            <ExpandMore sx={{ color: 'white' }} />
          )}
        </ListItemButton>
        <Collapse in={openProductManagement} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <Box
              variant="p"
              noWrap
              sx={{
                margin: '0px 0px 15px 19px',
                borderLeftColor:
                  location.pathname ==
                  '/admin/productmanagement/pendingproducts'
                    ? '#32BEF1'
                    : '',
                borderLeftStyle:
                  location.pathname ==
                  '/admin/productmanagement/pendingproducts'
                    ? 'solid'
                    : '',
                borderLeftWidth:
                  location.pathname ==
                  '/admin/productmanagement/pendingproducts'
                    ? '2px'
                    : '',
              }}
            >
              <Link
                to={'/admin/productmanagement/pendingproducts'}
                className={
                  location.pathname ==
                  '/admin/productmanagement/pendingproducts'
                    ? 'side-bar-link-selected'
                    : 'side-bar-link'
                }
              >
                Pending Products
              </Link>
            </Box>
            <Box
              variant="p"
              noWrap
              sx={{
                margin: '0px 0px 15px 19px',
                borderLeftColor:
                  location.pathname ==
                  '/admin/productmanagement/uploadedproducts'
                    ? '#32BEF1'
                    : '',
                borderLeftStyle:
                  location.pathname ==
                  '/admin/productmanagement/uploadedproducts'
                    ? 'solid'
                    : '',
                borderLeftWidth:
                  location.pathname ==
                  '/admin/productmanagement/uploadedproducts'
                    ? '2px'
                    : '',
              }}
            >
              <Link
                to={'/admin/productmanagement/uploadedproducts'}
                className={
                  location.pathname ==
                  '/admin/productmanagement/uploadedproducts'
                    ? 'side-bar-link-selected'
                    : 'side-bar-link'
                }
              >
                Uploaded Products
              </Link>
            </Box>
            <Box
              variant="p"
              noWrap
              sx={{
                margin: '0px 0px 15px 19px',
                borderLeftColor:
                  location.pathname ==
                  '/admin/productmanagement/rejectedproducts'
                    ? '#32BEF1'
                    : '',
                borderLeftStyle:
                  location.pathname ==
                  '/admin/productmanagement/rejectedproducts'
                    ? 'solid'
                    : '',
                borderLeftWidth:
                  location.pathname ==
                  '/admin/productmanagement/rejectedproducts'
                    ? '2px'
                    : '',
              }}
            >
              <Link
                to={'/admin/productmanagement/rejectedproducts'}
                className={
                  location.pathname ==
                  '/admin/productmanagement/rejectedproducts'
                    ? 'side-bar-link-selected'
                    : 'side-bar-link'
                }
              >
                Rejected Products
              </Link>
            </Box>
            <Box
              variant="p"
              noWrap
              sx={{
                margin: '0px 0px 15px 19px',
                borderLeftColor:
                  location.pathname == '/admin/productmanagement/customproducts'
                    ? '#32BEF1'
                    : '',
                borderLeftStyle:
                  location.pathname == '/admin/productmanagement/customproducts'
                    ? 'solid'
                    : '',
                borderLeftWidth:
                  location.pathname == '/admin/productmanagement/customproducts'
                    ? '2px'
                    : '',
              }}
            >
              <Link
                to={'/admin/productmanagement/customproducts'}
                className={
                  location.pathname == '/admin/productmanagement/customproducts'
                    ? 'side-bar-link-selected'
                    : 'side-bar-link'
                }
              >
                Custom Products
              </Link>
            </Box>
          </List>
        </Collapse>
      </List>

      <List
        sx={{
          '& .css-cvhtoe-MuiButtonBase-root-MuiListItemButton-root': {
            p: 0,
            pl: '19px',
            mt: '8px',
          },
          '& .css-1mk9mw3-MuiList-root': {
            px: 2,
            mt: 1,
          },
        }}
        className="nested-list-subheader-sidebar"
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        <ListItemButton onClick={handleOpenCloseOrderManagement}>
          <Box
            variant="p"
            noWrap
            sx={{
              borderLeftColor:
                location.pathname == '/admin/allorder' ? '#32BEF1' : '',
              borderLeftStyle:
                location.pathname == '/admin/allorder' ? 'solid' : '',
              borderLeftWidth:
                location.pathname == '/admin/allorder' ? '2px' : '',
            }}
          >
            <Link
              className={
                location.pathname == '/admin/allorder'
                  ? 'side-bar-link-selected'
                  : 'side-bar-link'
              }
            >
              Order Management
            </Link>
          </Box>

          {openOrderManagement ? (
            <ExpandLess sx={{ color: 'white' }} />
          ) : (
            <ExpandMore sx={{ color: 'white' }} />
          )}
        </ListItemButton>
        <Collapse in={openOrderManagement} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <Box
              variant="p"
              noWrap
              sx={{
                margin: '0px 0px 15px 19px',
                borderLeftColor:
                  location.pathname == '/admin/pendingorders' ? '#32BEF1' : '',
                borderLeftStyle:
                  location.pathname == '/admin/pendingorders' ? 'solid' : '',
                borderLeftWidth:
                  location.pathname == '/admin/pendingorders' ? '2px' : '',
              }}
            >
              <Link
                to={'/admin/pendingorders'}
                className={
                  location.pathname == '/admin/pendingorders'
                    ? 'side-bar-link-selected'
                    : 'side-bar-link'
                }
              >
                Pending Orders
              </Link>
            </Box>
            <Box
              variant="p"
              noWrap
              sx={{
                margin: '0px 0px 15px 19px',
                borderLeftColor:
                  location.pathname == '/admin/ordermanagement'
                    ? '#32BEF1'
                    : '',
                borderLeftStyle:
                  location.pathname == '/admin/ordermanagement' ? 'solid' : '',
                borderLeftWidth:
                  location.pathname == '/admin/ordermanagement' ? '2px' : '',
              }}
            >
              <Link
                to={'/admin/ordermanagement'}
                className={
                  location.pathname == '/admin/ordermanagement'
                    ? 'side-bar-link-selected'
                    : 'side-bar-link'
                }
              >
                Approved Orders
              </Link>
            </Box>
            <Box
              variant="p"
              noWrap
              sx={{
                margin: '0px 0px 15px 19px',
                borderLeftColor:
                  location.pathname == '/admin/rejectedorders' ? '#32BEF1' : '',
                borderLeftStyle:
                  location.pathname == '/admin/rejectedorders' ? 'solid' : '',
                borderLeftWidth:
                  location.pathname == '/admin/rejectedorders' ? '2px' : '',
              }}
            >
              <Link
                to={'/admin/rejectedorders'}
                className={
                  location.pathname == '/admin/rejectedorders'
                    ? 'side-bar-link-selected'
                    : 'side-bar-link'
                }
              >
                Rejected Orders
              </Link>
            </Box>
          </List>
        </Collapse>
      </List>
      <Box
        noWrap
        variant="p"
        sx={{
          margin: '0px 0px 15px 16px',
          borderLeftColor:
            location.pathname == '/admin/returnorders' ? '#32BEF1' : '',
          borderLeftStyle:
            location.pathname == '/admin/returnorders' ? 'solid' : '',
          borderLeftWidth:
            location.pathname == '/admin/returnorders' ? '2px' : '',
        }}
      >
        <Link
          to={'/admin/returnorders'}
          className={
            location.pathname == '/admin/returnorders'
              ? 'side-bar-link-selected'
              : 'side-bar-link'
          }
        >
          Return Orders
        </Link>
      </Box>
      <Box
        noWrap
        variant="p"
        sx={{
          margin: '0px 0px 15px 16px',
          borderLeftColor:
            location.pathname == '/admin/categorymanagement' ? '#32BEF1' : '',
          borderLeftStyle:
            location.pathname == '/admin/categorymanagement' ? 'solid' : '',
          borderLeftWidth:
            location.pathname == '/admin/categorymanagement' ? '2px' : '',
        }}
      >
        <Link
          to={'/admin/categorymanagement'}
          className={
            location.pathname == '/admin/categorymanagement'
              ? 'side-bar-link-selected'
              : 'side-bar-link'
          }
        >
          Category Management
        </Link>
      </Box>
      <Box
        noWrap
        variant="p"
        sx={{
          margin: '0px 0px 15px 16px',
          borderLeftColor:
            location.pathname == '/admin/materialmanagement' ? '#32BEF1' : '',
          borderLeftStyle:
            location.pathname == '/admin/materialmanagement' ? 'solid' : '',
          borderLeftWidth:
            location.pathname == '/admin/materialmanagement' ? '2px' : '',
        }}
      >
        <Link
          to={'/admin/materialmanagement'}
          className={
            location.pathname == '/admin/materialmanagement'
              ? 'side-bar-link-selected'
              : 'side-bar-link'
          }
        >
          Material Management
        </Link>
      </Box>
      <Box
        noWrap
        variant="p"
        sx={{
          margin: '0px 0px 15px 16px',
          borderLeftColor:
            location.pathname == '/admin/itemmanagement' ? '#32BEF1' : '',
          borderLeftStyle:
            location.pathname == '/admin/itemmanagement' ? 'solid' : '',
          borderLeftWidth:
            location.pathname == '/admin/itemmanagement' ? '2px' : '',
        }}
      >
        <Link
          to={'/admin/itemmanagement'}
          className={
            location.pathname == '/admin/itemmanagement'
              ? 'side-bar-link-selected'
              : 'side-bar-link'
          }
        >
          Item Management
        </Link>
      </Box>
      <Box
        variant="p"
        noWrap
        sx={{
          margin: '0px 0px 15px 16px',
          borderLeftColor:
            location.pathname == '/admin/apimanagement' ? '#32BEF1' : '',
          borderLeftStyle:
            location.pathname == '/admin/apimanagement' ? 'solid' : '',
          borderLeftWidth:
            location.pathname == '/admin/apimanagement' ? '2px' : '',
        }}
      >
        <Link
          to={'/admin/apimanagement'}
          className={
            location.pathname == '/admin/apimanagement'
              ? 'side-bar-link-selected'
              : 'side-bar-link'
          }
        >
          Api Management
        </Link>
      </Box>
      <Box
        noWrap
        variant="p"
        sx={{
          margin: '0px 0px 15px 16px',
          borderLeftColor:
            location.pathname == '/admin/paymentmanagement' ? '#32BEF1' : '',
          borderLeftStyle:
            location.pathname == '/admin/paymentmanagement' ? 'solid' : '',
          borderLeftWidth:
            location.pathname == '/admin/paymentmanagement' ? '2px' : '',
        }}
      >
        <Link
          to={'/admin/paymentmanagement'}
          className={
            location.pathname == '/admin/paymentmanagement'
              ? 'side-bar-link-selected'
              : 'side-bar-link'
          }
        >
          Payment Management
        </Link>
      </Box>
      <Box
        noWrap
        variant="p"
        sx={{
          margin: '0px 0px 15px 16px',
          borderLeftColor:
            location.pathname == '/admin/contentmanagement' ? '#32BEF1' : '',
          borderLeftStyle:
            location.pathname == '/admin/contentmanagement' ? 'solid' : '',
          borderLeftWidth:
            location.pathname == '/admin/contentmanagement' ? '2px' : '',
        }}
      >
        <Link
          to={'/admin/contentmanagement'}
          className={
            location.pathname == '/admin/contentmanagement'
              ? 'side-bar-link-selected'
              : 'side-bar-link'
          }
        >
          Content Management
        </Link>
      </Box>
      <Box
        noWrap
        variant="p"
        sx={{
          margin: '0px 0px 15px 16px',
          borderLeftColor:
            location.pathname == '/admin/masteradminaccount' ? '#32BEF1' : '',
          borderLeftStyle:
            location.pathname == '/admin/masteradminaccount' ? 'solid' : '',
          borderLeftWidth:
            location.pathname == '/admin/masteradminaccount' ? '2px' : '',
        }}
      >
        <Link
          to={'/admin/masteradminaccount'}
          className={
            location.pathname == '/admin/masteradminaccount'
              ? 'side-bar-link-selected'
              : 'side-bar-link'
          }
        >
          Admin Control
        </Link>
      </Box>
      <Box
        noWrap
        variant="p"
        sx={{
          margin: '0px 0px 15px 16px',
          borderLeftColor:
            location.pathname == '/admin/reports' ? '#32BEF1' : '',
          borderLeftStyle: location.pathname == '/admin/reports' ? 'solid' : '',
          borderLeftWidth: location.pathname == '/admin/reports' ? '2px' : '',
        }}
      >
        <Link
          to={'/admin/reports'}
          className={
            location.pathname == '/admin/reports'
              ? 'side-bar-link-selected'
              : 'side-bar-link'
          }
        >
          Reports
        </Link>
      </Box>
      <Box
        className="sidebar-logout-btn-box"
        noWrap
        variant="p"
        sx={{
          margin: '0px 0px 15px 16px',
          borderLeftColor:
            location.pathname == '/admin/notification' ? '#32BEF1' : '',
          borderLeftStyle:
            location.pathname == '/admin/notification' ? 'solid' : '',
          borderLeftWidth:
            location.pathname == '/admin/notification' ? '2px' : '',
        }}
      >
        <Button
          className="sidebar-logout-btn"
          sx={{ textTransform: 'none' }}
          onClick={handleClose}
        >
          <Link
            className={
              location.pathname == '/admin/notification'
                ? 'side-bar-link-selected'
                : 'side-bar-link'
            }
          >
            Logout
          </Link>
        </Button>
      </Box>
    </>
  );
};
export default DashBordList;
