//#region ALL IMPORTS
import React, { useState, useEffect } from 'react';
import {
  Grid,
  Container,
  Typography,
  Button,
  TextField,
  Box,
} from '@mui/material';
import { useHistory, useParams } from 'react-router-dom';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { Api } from '../../../../config/api';
import dataService from '../../../../config/dataService';
import { ToastContainer, toast } from 'react-toastify';
import RegEx from '../../../../component/customComponents/regEx/RegEx';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { ErrorMsg } from '../../../../component/customComponents/errorMsg/ErrorMsg';
import {
  minBankNameLength,
  bankNameLength,
  minAccountNameLength,
  accountNameLength,
  accountNumberLength,
  ifscCodeLength,
  NO_WHITE_SPACE_BEGINNING_END,
  IFSC_REGEX,
} from '../../../../component/customComponents/validation/Validation';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import UploadIcon from '@mui/icons-material/Upload';
//#endregion

//#region ALL HOOKS AND VARIABALS
const BankDetails = (props) => {
  //   const { id } = useParams();
  let token = localStorage.getItem('auth');
  const history = useHistory();
  const [id, setId] = useState('');
  const [getData, setGetData] = useState('');

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
    watch,
  } = useForm({ mode: 'onChange' });
  //#endregion

  //#region ALL EVENT HANDLERS AND  FUCTIONS
  const getBankDetails = async () => {
    let urlencoded = new URLSearchParams();
    if (props.id) {
      try {
        urlencoded.append('id', props.id);
        const Data = await dataService.post(
          Api.Admin.singleBankDetails,
          urlencoded,
          {
            headers: {
              auth: token,
            },
          }
        );
        setGetData(Data?.data?.data);
        setId(Data?.data?.data?._id);
      } catch (err) {
        toast.error(err?.message || 'Something went wrong');
      }
    }
  };

  useEffect(() => {
    if (props.id != '' && props.id != ' ') {
      getBankDetails();
    }
  }, []);

  useEffect(() => {
    if (props.id !== '') {
      setValue('bankName', getData?.bank_name);
      setValue('accountName', getData?.account_holder_name);
      setValue('accountNumber', getData?.account_number);
      setValue('ifscCode', getData?.ifsc_code);
    }
  }, [getData]);

  const handleSave = async (data) => {
    // e.preventDefault();
    try {
      let urlencoded = new URLSearchParams();
      if (props.id !== '') {
        urlencoded.append('id', props.id);
        urlencoded.append('bank_name', data?.bankName?.trim());
        urlencoded.append('account_holder_name', data?.accountName?.trim());
        urlencoded.append('account_number', data?.accountNumber);
        urlencoded.append('ifsc_code', data?.ifscCode?.trim());

        const bankDetailsData = await dataService.post(
          Api.Admin.updateBankDetails,
          urlencoded,
          {
            headers: {
              auth: token,
            },
          }
        );

        if (bankDetailsData.data.status == 1) {
          toast.success('Bank Details updated successfully');
        }
      } else {
        urlencoded.append('bank_name', data?.bankName?.trim());
        urlencoded.append('account_holder_name', data?.accountName?.trim());
        urlencoded.append('account_number', data?.accountNumber?.trim());
        urlencoded.append('ifsc_code', data?.ifscCode?.trim());

        const bankDetailsData = await dataService.post(
          Api.Admin.addBankDetails,
          urlencoded,
          {
            headers: {
              auth: token,
            },
          }
        );

        if (bankDetailsData.data.status == 1) {
          toast.success('Bank Details added successfully');
        }
      }
    } catch (err) {
      toast.error(err?.message || 'Something went wrong');
    }
  };
  //#endregion

  //#region RETURN JSX
  return (
    <>
      <form onSubmit={handleSubmit(handleSave)}>
        <Container maxWidth="false">
          <Typography sx={{ my: 3 }} align="center" variant="h4">
            Bank Details
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <TextField
              label="Bank Name"
              InputLabelProps={{ shrink: watch('bankName') ? true : false }}
              autoComplete="off"
              sx={{
                mt: 2,
                width: { lg: '300px', xs: '100%' },
                '& .MuiFormHelperText-root': { ml: 0, mt: '5px' },
              }}
              size="small"
              fullWidth
              className="form-control2 border-none"
              name="bankName"
              type="text"
              {...register('bankName', {
                required: <ErrorMsg msg="Please enter Bank Name" />,
                minLength: {
                  value: minBankNameLength,
                  message: (
                    <ErrorMsg
                      msg={`Minimum ${minBankNameLength} Characters Allowed`}
                    />
                  ),
                },
                maxLength: {
                  value: bankNameLength,
                  message: (
                    <ErrorMsg
                      msg={`Maximum ${bankNameLength} Characters Allowed`}
                    />
                  ),
                },
                pattern: {
                  value: NO_WHITE_SPACE_BEGINNING_END,
                  message: (
                    <ErrorMsg msg="Whitespace is not allowed at beginning or end" />
                  ),
                },
              })}
              helperText={<ErrorMessage errors={errors} name="bankName" />}
            />
            <TextField
              label="Account Name"
              InputLabelProps={{ shrink: watch('accountName') ? true : false }}
              autoComplete="off"
              sx={{
                mt: 2,
                width: { lg: '300px', xs: '100%' },
                '& .MuiFormHelperText-root': { ml: 0, mt: '5px' },
              }}
              size="small"
              fullWidth
              className="form-control2 border-none"
              name="accountName"
              type="text"
              {...register('accountName', {
                required: <ErrorMsg msg="Please enter Account Name" />,
                minLength: {
                  value: minAccountNameLength,
                  message: (
                    <ErrorMsg
                      msg={`Minimum ${minAccountNameLength} Characters Allowed`}
                    />
                  ),
                },
                maxLength: {
                  value: accountNameLength,
                  message: (
                    <ErrorMsg
                      msg={`Maximum ${accountNameLength} Characters Allowed`}
                    />
                  ),
                },
                pattern: {
                  value: NO_WHITE_SPACE_BEGINNING_END,
                  message: (
                    <ErrorMsg msg="Whitespace is not allowed at beginning or end" />
                  ),
                },
              })}
              helperText={<ErrorMessage errors={errors} name="accountName" />}
            />
            <TextField
              label="Account Number"
              InputLabelProps={{
                shrink: watch('accountNumber') ? true : false,
              }}
              autoComplete="off"
              sx={{
                mt: 2,
                width: { lg: '300px', xs: '100%' },
                '& .MuiFormHelperText-root': { ml: 0, mt: '5px' },
              }}
              size="small"
              fullWidth
              className="form-control2 border-none"
              name="accountNumber"
              onWheel={(e) => e.target.blur()}
              type="number"
              {...register('accountNumber', {
                required: <ErrorMsg msg="Please enter Account Number" />,
                maxLength: {
                  value: accountNumberLength,
                  message: (
                    <ErrorMsg
                      msg={`Maximum ${accountNumberLength} Characters Allowed`}
                    />
                  ),
                },
                pattern: {
                  value: NO_WHITE_SPACE_BEGINNING_END,
                  message: (
                    <ErrorMsg msg="Whitespace is not allowed at beginning or end" />
                  ),
                },
              })}
              helperText={<ErrorMessage errors={errors} name="accountNumber" />}
            />
            <TextField
              label="IFSC Code"
              InputLabelProps={{ shrink: watch('ifscCode') ? true : false }}
              autoComplete="off"
              sx={{
                mt: 2,
                width: { lg: '300px', xs: '100%' },
                '& .MuiFormHelperText-root': { ml: 0, mt: '5px' },
              }}
              size="small"
              fullWidth
              className="form-control2 border-none"
              name="ifscCode"
              type="text"
              {...register('ifscCode', {
                required: <ErrorMsg msg="Please enter IFSC code" />,
                maxLength: {
                  value: ifscCodeLength,
                  message: (
                    <ErrorMsg
                      msg={`Maximum ${ifscCodeLength} Characters Allowed`}
                    />
                  ),
                },
                pattern: {
                  value: IFSC_REGEX,
                  message: <ErrorMsg msg="Please enter valid IFSC code" />,
                },
              })}
              helperText={<ErrorMessage errors={errors} name="ifscCode" />}
            />
          </Box>
          <Typography align="center" variant="h4">
            <Button
              sx={{
                my: 5,
                width: '125px',
                textTransform: 'none',
              }}
              variant="contained"
              type="submit"
            >
              {props.id == '' ? 'Submit' : 'Update'}
            </Button>
          </Typography>
        </Container>
      </form>
    </>
  );
};

export default BankDetails;
//#endregion
