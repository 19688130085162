import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { Button, Typography } from "@mui/material";
import png from "../../../../assets/png";
import Header from "../../../../component/user/defaultLayout/Header";
import Footer from "../../../../component/user/defaultLayout/Footer";
import FormHelperText from "@mui/material/FormHelperText";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "react-redux";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import UserIndex from "../../UserIndex";
import UserSagaActions from "../../../../redux/Usersaga/action";
import { useParams } from "react-router-dom";
import { price } from "../../../../component/customComponents/commonFunctions/CommonFunctions";

const { SingleBankDetailsSaga, OrderPlaceSaga, ProductSaga } = UserSagaActions;

const PaymentMethod = () => {
  const [age, setAge] = useState("");
  const [paymentData, setPaymentData] = useState("");
  const [singleBankDetailsData, setSingleBankDetailsData] = useState("");
  const [singleProductData, setSingleProductData] = useState("");

  const dispatch = UserIndex.useDispatch();
  const history = UserIndex.useHistory();
  const navigate = UserIndex.useHistory();
  let params = useParams();
  if (params.custom_product_data) {
    var decodedString = JSON.parse(window.atob(params.custom_product_data));
  } else if (params.id) {
    var buyNowDatas = JSON.parse(window.atob(params.id));
  }

  const loding = UserIndex.useSelector((state) => {
    return state.Usersaga.loading;
  });
  const Token = UserIndex.useSelector((state) => {
    return state.Usersaga.Token;
  });
  const ViewCustomProductData = UserIndex.useSelector((state) => {
    return state.Usersaga.ViewCustomProductData;
  });
  const SingleBankDetailsData = UserIndex.useSelector((state) => {
    return state.Usersaga.SingleBankDetailsData;
  });
  const OrderPlacedata = useSelector((state) => {
    return state.Usersaga.OrderPlacedata;
  });

  const Viewcartdata = useSelector((state) => {
    return state.Usersaga.Viewcartdata;
  });

  const ProductDetaildata = useSelector((state) => {
    return state.Usersaga.ProductDetaildata;
  });

  const getProduct = () => {
    if (params.id) {
      let urlencoded = new URLSearchParams();
      urlencoded.append("id", buyNowDatas?.id);
      dispatch(ProductSaga(urlencoded, navigate, Token));
    }
  };

  useEffect(() => {
    if (params.id) {
      getProduct();
    }
  }, []);

  useEffect(() => {
    setSingleProductData(ProductDetaildata);
  }, [ProductDetaildata]);

  useEffect(() => {
    setPaymentData(Viewcartdata);
  }, [Viewcartdata]);
  const getSingleBankDetails = () => {
    let urlencoded = new URLSearchParams();
    dispatch(SingleBankDetailsSaga(urlencoded, navigate, Token));
  };

  useEffect(() => {
    getSingleBankDetails();
  }, []);

  useEffect(() => {
    setSingleBankDetailsData(SingleBankDetailsData);
  }, [SingleBankDetailsData]);

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const [transactionId, setTransactionId] = useState("");


  const transactionHandle = (e) => {
    setTransactionId(e.target.value);
  };

  const [transactionIdError, setTransactionIdError] = useState("");

  const errorHandling = () => {
    const whiteSpace = new RegExp(/^[^\s]+(\s+[^\s]+)*$/);
    const alphanumeric = new RegExp(/^[A-Za-z0-9\s]+$/);
    if (transactionId === "") {
      setTransactionIdError("Please enter Transaction Id");
      return false;
    } else if (!whiteSpace.test(transactionId)) {
      setTransactionIdError("White space not allowed in Transaction Id");
      return false;
    } else if (!alphanumeric.test(transactionId)) {
      setTransactionIdError("Alphanumeric value not allowed in Transaction Id");
      return false;
    } else {
      setTransactionIdError("");
      return true;
    }
  };

  const getOrderPlace = () => {
    const whiteSpace = new RegExp(/^[^\s]+(\s+[^\s]+)*$/);
    const alphanumeric = new RegExp(/^[A-Za-z0-9\s]+$/);
    errorHandling();
    if(!transactionId || ! whiteSpace.test(transactionId) || !alphanumeric.test(transactionId) ){
      return ;
    }
      let urlencoded = new URLSearchParams();
      if (params?.custom_product_data) {
        urlencoded.append("custom_product_id", decodedString?.customeProductId);
        urlencoded.append(
          "allocate_to_seller_id",
          decodedString?.item?.seller_id._id
        );
        urlencoded.append(
          "totalPayableAmount",
          decodedString?.item?.total_payable_amount
        );
      } else {
        if (params.id) {
          urlencoded.append("product_id", buyNowDatas?.id);
          urlencoded.append("quantity", buyNowDatas?.qty);
        } else {
          urlencoded.append("cart_id", paymentData?._id);
        }
      }
      urlencoded.append("discount", "0");
      urlencoded.append("taxes", "0");
      urlencoded.append("paymentMethod", "RTGS");
      urlencoded.append("shipping", "100");
      urlencoded.append("transaction_id", transactionId);
      dispatch(OrderPlaceSaga(urlencoded, navigate, Token))
  };

  return (
    <div>
      <Header />
      <ToastContainer />
      <Box className="body-bg">
        <Box className="payment-sec">
          <Box className="container">
            <Box className="addinfo-text-box">
              <Typography variant="h2" className="addinfo-text">
                Payment Method
              </Typography>
            </Box>
            <Box className="payment-main">
              <Box className="payment-col-1">
                {/* <Box className="payment-card-box">
                  <Box className="payment-radio-box">
                    <FormControl>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="female"
                        name="radio-buttons-group"
                      >
                        <FormControlLabel
                          value="female"
                          control={<Radio />}
                          className="payment-radio-lable"
                        />
                      </RadioGroup>
                    </FormControl>
                    <Box className="radio-text-box">
                      <Typography
                        variant="h3"
                        component="h3"
                        className="radio-text"
                      >
                        Credit & Debit Cards
                      </Typography>
                    </Box>
                    <Box className="payment-image-box">
                      <img src={png.visaimg} className="visa-img" />
                    </Box>
                  </Box>
                </Box>
                <Box className="payment-form-content">
                  <Box className="payment-inner-form">
                    <Box className="input-box membership-input-box">
                      <FormHelperText className="input-lable">
                        Card Number
                      </FormHelperText>
                      <TextField
                        fullWidth
                        id="fullWidth"
                        className="form-control payment-form-control border-none"
                        value=""
                      />
                    </Box>
                  </Box>
                  <Box className="payment-inner-form">
                    <Box className="expit-security-main">
                      <Box className="expir-date-flex">
                        <Box className="exp-input-lable">
                          <FormHelperText className="input-lable">
                            Expiration Date
                          </FormHelperText>
                        </Box>
                        <Box className="flex-inner-grid">
                          <Box className="input-box payment-dropdown">
                            <Box className="dropdown-main">
                              <FormControl>
                                <Box className="dropdown-inner">
                                  <Select
                                    className="dropdown-select"
                                    value={age}
                                    onChange={handleChange}
                                    displayEmpty
                                    inputProps={{
                                      "aria-label": "Without label",
                                    }}
                                  >
                                    <MenuItem
                                      value=""
                                      className="dropdown-menuitem"
                                    >
                                      Month
                                    </MenuItem>
                                    <MenuItem
                                      value={10}
                                      className="dropdown-menuitem"
                                    >
                                      Ten
                                    </MenuItem>
                                    <MenuItem
                                      value={20}
                                      className="dropdown-menuitem"
                                    >
                                      Twenty
                                    </MenuItem>
                                    <MenuItem
                                      value={30}
                                      className="dropdown-menuitem"
                                    >
                                      Thirty
                                    </MenuItem>
                                  </Select>
                                  <span className="blackdown-img-box">
                                    <img
                                      src={png.solidblackdown}
                                      className="blackdown-img"
                                    />
                                  </span>
                                </Box>
                              </FormControl>
                            </Box>
                          </Box>
                          <Box className="input-box payment-dropdown">
                            <Box className="dropdown-main">
                              <FormControl>
                                <Box className="dropdown-inner">
                                  <Select
                                    className="dropdown-select"
                                    value={age}
                                    onChange={handleChange}
                                    displayEmpty
                                    inputProps={{
                                      "aria-label": "Without label",
                                    }}
                                  >
                                    <MenuItem
                                      value=""
                                      className="dropdown-menuitem"
                                    >
                                      Year
                                    </MenuItem>
                                    <MenuItem
                                      value={10}
                                      className="dropdown-menuitem"
                                    >
                                      Ten
                                    </MenuItem>
                                    <MenuItem
                                      value={20}
                                      className="dropdown-menuitem"
                                    >
                                      Twenty
                                    </MenuItem>
                                    <MenuItem
                                      value={30}
                                      className="dropdown-menuitem"
                                    >
                                      Thirty
                                    </MenuItem>
                                  </Select>
                                  <Box className="blackdown-img-box">
                                    <img
                                      src={png.solidblackdown}
                                      className="blackdown-img"
                                    />
                                  </Box>
                                </Box>
                              </FormControl>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                      <Box className="security-flex">
                        <Box className="flex-inner-grid">
                          <Box className="input-box membership-input-box">
                            <FormHelperText className="input-lable">
                              Security Code
                            </FormHelperText>
                            <TextField
                              fullWidth
                              id="fullWidth"
                              className="form-control payment-form-control border-none"
                              value=""
                            />
                            <span>
                              <img
                                src={png.questionmark}
                                className="questionmark-img"
                              ></img>
                            </span>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box className="payment-card-box bottom-payment-card">
                  <Box className="payment-radio-box">
                    <FormControl>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="female"
                        name="radio-buttons-group"
                      >
                        <FormControlLabel
                          value="female"
                          control={<Radio />}
                          className="payment-radio-lable"
                        />
                      </RadioGroup>
                    </FormControl>
                    <Box className="radio-text-box">
                      <Typography
                        variant="h3"
                        component="h3"
                        className="radio-text"
                      >
                        RazorPay
                      </Typography>
                    </Box>
                    <Box className="paypal-image-box">
                      <img src={png.rozorpay} className="paypal-img" />
                    </Box>
                  </Box>
                </Box> */}
                <Box className="payment-card-box payment-main-card-box">
                  <Box className="payment-card-box-inner">
                    <Box className="bank-details">
                      <Box>Bank Name </Box>
                      <Box>{singleBankDetailsData[0]?.bank_name}</Box>
                    </Box>
                    <Box className="bank-details">
                      <Box>Account Name </Box>
                      <Box>{singleBankDetailsData[0]?.account_holder_name}</Box>
                    </Box>
                    <Box className="bank-details">
                      <Box>Account Number </Box>
                      <Box>{singleBankDetailsData[0]?.account_number}</Box>
                    </Box>
                    <Box className="bank-details">
                      <Box>IFSC Code </Box>
                      <Box>{singleBankDetailsData[0]?.ifsc_code}</Box>
                    </Box>
                  </Box>
                  <Box className="payment-card-box-inner">
                    <Box className="input-box membership-input-box payment-text-field-main">
                      <FormHelperText className="payment-input-lable">
                        Transaction ID{" "}
                        <span
                          style={{
                            color: "red",
                            fontSize: "15px",
                            marginTop: "-4px",
                          }}
                        >
                          *
                        </span>
                      </FormHelperText>
                      <TextField
                        fullWidth
                        id="fullWidth"
                        className="payment-text-field"
                        // value={}
                        onChange={(e) => {
                          transactionHandle(e);
                        }}
                      />
                      <FormHelperText className="error-input-lable">
                        {transactionIdError}
                      </FormHelperText>
                    </Box>
                  </Box>
                  <Box className="payment-note">
                    Note : Please make the payment on the given bank account
                    details, and enter the transaction id here to process with
                    the order.
                  </Box>
                </Box>
              </Box>
              <Box className="payment-col-2">
                {params.id ? (
                  <>
                    <Box className="cart-total-box payment-cart-total-box">
                      <Typography className="cart-total-title">
                        Cart Total
                      </Typography>
                      <Box className="cart-total-content">
                        <Box className="cart-total-main">
                          <Typography className="cart-total-text">
                            Sub Total
                          </Typography>
                          <Typography className="cart-total-text">
                            ₹
                            {price(
                              singleProductData?.[0]?.total_payable_amount
                            )}
                          </Typography>
                        </Box>
                        <Box className="cart-total-main">
                          <Typography className="cart-total-text">
                            Shipping
                          </Typography>
                          <Typography className="cart-total-text">
                            ₹00
                          </Typography>
                        </Box>
                        <Box className="cart-total-main">
                          <Typography className="cart-total-text">
                            Total
                          </Typography>
                          <Typography className="cart-total-text primary-text-color">
                            ₹
                            {price(
                              singleProductData?.[0]?.total_payable_amount
                            )}
                          </Typography>
                        </Box>
                      </Box>
                      <Box className="primary-btn-box checkout-btn-box">
                        <Button
                          onClick={() => {
                            errorHandling();
                            getOrderPlace();
                          }}
                          className="primary-btn checkout-btn"
                        >
                          Confirm & Pay
                        </Button>
                      </Box>
                    </Box>
                  </>
                ) : params.custom_product_data ? (
                  <>
                    <Box className="cart-total-box payment-cart-total-box">
                      <Typography className="cart-total-title">
                        Cart Total
                      </Typography>
                      <Box className="cart-total-content">
                        <Box className="cart-total-main">
                          <Typography className="cart-total-text">
                            Sub Total
                          </Typography>
                          <Typography className="cart-total-text">
                            ₹{price(decodedString?.item?.total_payable_amount)}
                          </Typography>
                        </Box>
                        <Box className="cart-total-main">
                          <Typography className="cart-total-text">
                            Shipping
                          </Typography>
                          <Typography className="cart-total-text">
                            ₹00
                          </Typography>
                        </Box>
                        <Box className="cart-total-main">
                          <Typography className="cart-total-text">
                            Total
                          </Typography>
                          <Typography className="cart-total-text primary-text-color">
                            ₹{price(decodedString?.item?.total_payable_amount)}
                          </Typography>
                        </Box>
                      </Box>
                      <Box className="primary-btn-box checkout-btn-box">
                        <Button
                          onClick={() => {
                            errorHandling();
                            getOrderPlace();
                          }}
                          className="primary-btn checkout-btn"
                        >
                          Confirm & Pay
                        </Button>
                      </Box>
                    </Box>
                  </>
                ) : (
                  <>
                    <Box className="cart-total-box payment-cart-total-box">
                      <Typography className="cart-total-title">
                        Cart Total
                      </Typography>
                      <Box className="cart-total-content">
                        <Box className="cart-total-main">
                          <Typography className="cart-total-text">
                            Sub Total
                          </Typography>
                          <Typography className="cart-total-text">
                            ₹{price(paymentData?.total)}
                          </Typography>
                        </Box>
                        <Box className="cart-total-main">
                          <Typography className="cart-total-text">
                            Shipping
                          </Typography>
                          <Typography className="cart-total-text">
                            ₹00
                          </Typography>
                        </Box>
                        <Box className="cart-total-main">
                          <Typography className="cart-total-text">
                            Total
                          </Typography>
                          <Typography className="cart-total-text primary-text-color">
                            ₹{price(paymentData?.total)}
                          </Typography>
                        </Box>
                      </Box>
                      <Box className="primary-btn-box checkout-btn-box">
                        <Button
                          onClick={() => {
                            errorHandling();
                            getOrderPlace();
                          }}
                          className="primary-btn checkout-btn"
                        >
                          Confirm & Pay
                        </Button>
                      </Box>
                    </Box>
                  </>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Footer />
    </div>
  );
};

export default PaymentMethod;
