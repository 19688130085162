import React, { useEffect } from 'react';
import { styled, useTheme, createMuiTheme, alpha } from '@mui/material/styles';
import { Grid, Card, IconButton, Icon } from '@mui/material';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import InputBase from '@mui/material/InputBase';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import MoreIcon from '@mui/icons-material/MoreVert';
import MenuItem from '@mui/material/MenuItem';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Menu from '@mui/material/Menu';
import DashBoard from '../../../container/Admin/Pages/DashBoard/dashboard';
import UserManagement from '../../../container/Admin/Pages/UserManagement/userManagement';
import ApiManagement from '../../../container/Admin/Pages/ApiManagement/ApiManagement';
import Payment from '../../../container/Admin/Pages/Payment/Payment';
import Notification from '../../../container/Admin/Pages/Notification/Notification';
import ContentManagement from '../../../container/Admin/Pages/ContentManagement/contentManagement';
import MasterAdminAccount from '../../../container/Admin/Pages/MasterAdminAccount/MasterAdminAccount';
import AddUser from '../../../container/Admin/Pages/UserManagement/AddUser';
import CategoryManagement from '../../../container/Admin/Pages/CategoryManagement/CategoryManagement';
import AddCategory from '../../../container/Admin/Pages/CategoryManagement/addcategory';
import UpdateCategory from '../../../container/Admin/Pages/CategoryManagement/UpdateCategory';
import AddSubCategory from '../../../container/Admin/Pages/CategoryManagement/addSubCategory';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Logo from '../../../assets/images/Zaverica-Final-12.png';
import LogoText from '../../../assets/images/Zaverica-final-13.png';
import '../../../index.css';
import {
  Button,
  Container,
  TextField,
  Typography,
  ListItemButton,
  List,
  ListItemIcon,
  ListItemText,
  Divider,
} from '@mui/material';
import { BrowserRouter, Switch, Route, useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import MailIcon from '@mui/icons-material/Mail';
import InboxIcon from '@mui/icons-material/Inbox';
import { useParams } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import DashBordList from './sidebarList';
import ProductManagement from '../../../container/Admin/Pages/ProductManagement/ProductManagement';
import OrderManagement from '../../../container/Admin/Pages/OrderManagement/OrderManagement';
import AllOrders from '../../../container/Admin/Pages/OrderManagement/AllOrders';
import SingleMultipleOrders from '../../../container/Admin/Pages/OrderManagement/SingleMultipleOrders';
import BulkOrders from '../../../container/Admin/Pages/OrderManagement/BulkOrders';
import Reports from '../../../container/Admin/Pages/Reports/Reports';
import ManageUser from '../../../container/Admin/Pages/UserManagement/ManageUser';
import CustomProducts from '../../../container/Admin/Pages/ProductManagement/CustomProducts';
import BidProducts from '../../../container/Admin/Pages/ProductManagement/BidProducts';
import PendingProducts from '../../../container/Admin/Pages/ProductManagement/PendingProducts';
import UploadedProducts from '../../../container/Admin/Pages/ProductManagement/UploadedProducts';
import RejectedProducts from '../../../container/Admin/Pages/ProductManagement/RejectedProducts';
import AddProducts from '../../../container/Admin/Pages/ProductManagement/AddProducts';
// import AddProducts1 from '../../../container/Admin/Pages/ProductManagement/AddProducts1';
import ViewPendingProduct from '../../../container/Admin/Pages/ProductManagement/ViewPendingProduct';
import ViewUploadedProduct from '../../../container/Admin/Pages/ProductManagement/ViewUploadedProducts';
import UpdateProductDetails from '../../../container/Admin/Pages/ProductManagement/UpdateProductDetails';
import OrderDescription from '../../../container/Admin/Pages/OrderManagement/OrderDescription';
import MaterialManagement from '../../../container/Admin/Pages/MaterialManagement/MaterialManagement';
import ItemManagement from '../../../container/Admin/Pages/ItemManagement/ItemManagement';
import AddMaterial from '../../../container/Admin/Pages/MaterialManagement/AddMaterial';
import UpdateMaterial from '../../../container/Admin/Pages/MaterialManagement/UpdateMaterial';
import AddItem from '../../../container/Admin/Pages/ItemManagement/AddItem';
import UpdateItem from '../../../container/Admin/Pages/ItemManagement/UpdateItem';
import AddAboutUs from '../../../container/Admin/Pages/ContentManagement/AddAboutUs';
import UpdateAboutUs from '../../../container/Admin/Pages/ContentManagement/UpdateAboutUs';
import Faq from '../../../container/Admin/Pages/ContentManagement/faq/Faq';
import AddFaq from '../../../container/Admin/Pages/ContentManagement/faq/AddFaq';
import UpdateFaq from '../../../container/Admin/Pages/ContentManagement/faq/UpdateFaq';
import AddTermsAndConditions from '../../../container/Admin/Pages/ContentManagement/termsAndCondition/AddTermsAndConditions';
import UpdateTermsAndConditions from '../../../container/Admin/Pages/ContentManagement/termsAndCondition/UpdateTermsAndConditions';
import ViewCustomProduct from '../../../container/Admin/Pages/ProductManagement/ViewCustomProduct';
import UpdateCustomProduct from '../../../container/Admin/Pages/ProductManagement/UpdateCustomProduct';
import ReturnOrders from '../../../container/Admin/Pages/ReturnOrder/ReturnOrders';
import ViewReturnOrder from '../../../container/Admin/Pages/ReturnOrder/ViewReturnOrder';
import Blogs from '../../../container/Admin/Pages/ContentManagement/blogs/Blogs';
import SubBlogs from '../../../container/Admin/Pages/ContentManagement/blogs/blogsDetails/Blogs';
import AddBlogs from '../../../container/Admin/Pages/ContentManagement/blogs/blogsDetails/AddBlogs';
import UpdateBlogs from '../../../container/Admin/Pages/ContentManagement/blogs/blogsDetails/UpdateBlogs';
import Guidelines from '../../../container/Admin/Pages/ContentManagement/blogs/guidelines/Guidelines';
import AddGuidelines from '../../../container/Admin/Pages/ContentManagement/blogs/guidelines/AddGuidelines';
import UpdateGuidelines from '../../../container/Admin/Pages/ContentManagement/blogs/guidelines/UpdateGuidelines';
import ProductDetails from '../../../container/Admin/Pages/ContentManagement/blogs/productDetails/ProductDetails';
import AddProductDetails from '../../../container/Admin/Pages/ContentManagement/blogs/productDetails/AddProductDetails';
import UpdateProductDetailsCms from '../../../container/Admin/Pages/ContentManagement/blogs/productDetails/UpdateProductDetails';
import ProductPricing from '../../../container/Admin/Pages/ContentManagement/blogs/productPricing/ProductPricing';
import AddProductPricing from '../../../container/Admin/Pages/ContentManagement/blogs/productPricing/AddProductPricing';
import UpdateProductPricingCms from '../../../container/Admin/Pages/ContentManagement/blogs/productPricing/UpdateProductPricing';
import AddCustomerServices from '../../../container/Admin/Pages/ContentManagement/customerServices/AddCustomerServices';
import UpdateCustmerServices from '../../../container/Admin/Pages/ContentManagement/customerServices/UpdateCustomerServices';
import AddTopDeals from '../../../container/Admin/Pages/ContentManagement/topDeals/AddTopDeals';
import TopDeals from '../../../container/Admin/Pages/ContentManagement/topDeals/TopDeals';
import UpdateTopDeals from '../../../container/Admin/Pages/ContentManagement/topDeals/UpdateTopDeals';
import PurchaseOrder from '../../../container/Admin/Pages/OrderManagement/PurchaseOrder';
import Membership from '../../../container/Admin/Pages/ContentManagement/membership/Membership';
import MembershipContent from '../../../container/Admin/Pages/ContentManagement/membership/MembershipContent';
import FormFillingGuide from '../../../container/Admin/Pages/ContentManagement/membership/FormFillingGuide';
import MembershipTermsAndCondition from '../../../container/Admin/Pages/ContentManagement/membership/MembershipTermsAndCondition';
import { toast } from 'react-toastify';
import GoToTop from '../../customComponents/goToTop/GoToTop';
import AdminToSellerInvoice from '../../../container/Admin/Pages/OrderManagement/AdminToSellerInvoice';
import PendingOrders from '../../../container/Admin/Pages/OrderManagement/PendingOrders';
import PendingOrderDescription from '../../../container/Admin/Pages/OrderManagement/PendingOrderDescription';
import ProductPurity from '../../../container/Admin/Pages/ContentManagement/blogs/productPurity/ProductPurity';
import AddProductPurity from '../../../container/Admin/Pages/ContentManagement/blogs/productPurity/AddProductPurity';
import UpdateProductPurity from '../../../container/Admin/Pages/ContentManagement/blogs/productPurity/UpdateProductPurity';
import RejectedOrders from '../../../container/Admin/Pages/OrderManagement/RejectedOrders';
import { useSelector, useDispatch } from 'react-redux'
import Actions from '../../../redux/adminSaga/action'
import AddPrivacyPolicy from '../../../container/Admin/Pages/ContentManagement/privacyPolicy/AddPrivacyPolicy';
import UpdatePrivacyPolicy from '../../../container/Admin/Pages/ContentManagement/privacyPolicy/UpdatePrivacyPolicy';
import AddReturnPolicy from '../../../container/Admin/Pages/ContentManagement/returnPolicy/AddReturnPolicy';
import UpdateReturnPolicy from '../../../container/Admin/Pages/ContentManagement/returnPolicy/UpdateReturnPolicy';
const drawerWidth = 280;
const menuId = 'primary-search-account-menu';

const { logoutSaga } = Actions

// type Anchor = 'top' | 'left' | 'bottom' | 'right';

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const Img1 = styled('img')({
  width: '50px',
});
const Img2 = styled('img')({
  width: '100px',
});

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

// const THEME = createMuiTheme({
//     typography: {
//         "fontFamily": `"Roboto", "Helvetica", "Arial", sans-serif`,
//         "fontSize": 14,
//         "fontWeightLight": 300,
//         "fontWeightRegular": 400,
//         "fontWeightMedium": 500,
//     }
// });

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '30ch',
    },
  },
}));

export default function SideBar(props) {
  const params = useParams();
  const dispatch = useDispatch()
  const history = useHistory();
  const navigate = useHistory();
  const login = localStorage.getItem('auth')
    ? localStorage.getItem('auth')
    : '';
  const theme = useTheme();
  const location = useLocation();
  let isToken = localStorage.getItem('auth');
  isToken =
    isToken == '' || isToken == null || isToken == undefined ? false : true;
  const [open, setOpen] = React.useState(true);
  const [address, SetAddress] = React.useState('');
  // const isMenuOpen = Boolean(anchorEl);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  // if(!isToken){history.push('/admin/login')}
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openManu = Boolean(anchorEl);

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {['All mail', 'Trash', 'Spam'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    // localStorage.removeItem('auth');
    dispatch(logoutSaga(navigate))
    // history.push('/admin/login');
    // toast.success('Logged out successfully')
    setAnchorEl(null);
  };

  // const handleMenuClose = () => {
  //     setAnchorEl(null);
  //     handleMobileMenuClose();
  // };
  const handleMenuLogout = () => {
    localStorage.removeItem('userInfo');
    history.push('/');
  };
  // const handleMobileMenuClose = () => {
  //     setMobileMoreAnchorEl(null);
  // };

  // const handleProfileMenuOpen = (event) => {
  //     setAnchorEl(event.currentTarget);
  // };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const mobileMenuId = 'primary-search-account-menu-mobile';
  // const renderMobileMenu = (
  //     <Menu
  //         anchorEl={mobileMoreAnchorEl}
  //         anchorOrigin={{
  //             vertical: 'top',
  //             horizontal: 'right',
  //         }}
  //         id={mobileMenuId}
  //         keepMounted
  //         transformOrigin={{
  //             vertical: 'top',
  //             horizontal: 'right',
  //         }}
  //         open={isMobileMenuOpen}
  //         onClose={handleMobileMenuClose}
  //     >
  //         <MenuItem>
  //             `${address}`
  //         </MenuItem>
  //         <MenuItem onClick={handleMenuLogout}>
  //             Logout
  //         </MenuItem>
  //     </Menu>
  // );
  useEffect(() => {
    SetAddress(localStorage.getItem('Address'));
  }, []);
  useEffect(() => {
    if (login && login != '' && login != 'null') {
    } else {
      history.push('/admin/login');
    }
  }, []);  

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="fixed" open={open} className="app-bar">
          <Toolbar sx={{ backgroundColor: '#000000' }}>
            <Box
              component="img"
              sx={{
                heiht: 38,
                width: 38,
                m: 1,
                ...(open && { display: 'none' }),
              }}
              alt="The house from the offer."
              src={Logo}
            />
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ m: 1, ...(open && { display: 'none' }) }}
            >
              <MenuIcon />
            </IconButton>
            {/* <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
              <Typography sx={{ m: 1 }} variant="p">
                Product
              </Typography>
              <Typography sx={{ m: 1 }} variant="p">
                Catalogue
              </Typography>
              <Typography sx={{ m: 1 }} variant="p">
                Order
              </Typography>
              <Typography sx={{ m: 1 }} variant="p">
                Bid
              </Typography>
            </Box> */}
            <Box sx={{ flexGrow: 1 }} />
            <div>
              <Button
                id="demo-positioned-button"
                aria-controls={open ? 'demo-positioned-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
              >
                <AccountCircle sx={{ m: 1 }} />
              </Button>
              <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={openManu}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <MenuItem onClick={() => history.push('/admin/masteradminaccount')}>Profile</MenuItem>
                <MenuItem>My account</MenuItem>
                <MenuItem onClick={handleClose}>Logout</MenuItem>
              </Menu>
            </div>
            <NotificationsIcon sx={{ m: 1 }} />
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MoreIcon />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
        {/* {renderMobileMenu} */}

        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
              backgroundColor: '#000000',
              overflow: 'hidden',
            },
            '& .MuiDrawer-paper:hover': {
              overflowY: 'auto',
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? (
              <ChevronLeftIcon
                sx={{ color: '#fff', margin: '14px 0px 0px 238px' }}
              />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
          <Box
            component="img"
            sx={{
              height: 38,
              width: 189,
              margin: '-32px 5px 33px 31px',
            }}
            alt="The house from the offer."
            src={LogoText}
          />
          <DashBordList />
        </Drawer>
        <Main open={open}>
          <DrawerHeader />
          <Box sx={{ mt: 1 }} />

          {location.pathname == '/admin' ? <DashBoard /> : ''}
          {location.pathname == '/admin/usermanagement' ? (
            <UserManagement />
          ) : (
            ''
          )}
          {location.pathname == '/admin/usermanagement/adduser' ? (
            <AddUser />
          ) : (
            ''
          )}
          {location.pathname == '/admin/categorymanagement' ? (
            <CategoryManagement />
          ) : (
            ''
          )}
          {location.pathname == '/admin/materialmanagement' ? (
            <MaterialManagement />
          ) : (
            ''
          )}
          {location.pathname == '/admin/materialmanagement/addmaterial' ? (
            <AddMaterial />
          ) : (
            ''
          )}
          {location.pathname.includes(
            '/admin/materialmanagement/updatematerial'
          ) ? (
            <UpdateMaterial />
          ) : (
            ''
          )}
          {location.pathname == '/admin/itemmanagement' ? (
            <ItemManagement />
          ) : (
            ''
          )}
          {location.pathname == '/admin/itemmanagement/additem' ? (
            <AddItem />
          ) : (
            ''
          )}
          {location.pathname.includes('/admin/itemmanagement/updateitem') ? (
            <UpdateItem />
          ) : (
            ''
          )}
          {location.pathname == '/admin/categorymanagement/addcategory' ? (
            <AddCategory />
          ) : (
            ''
          )}
          {location.pathname.includes(
            '/admin/categorymanagement/updatecategory'
          ) ? (
            <UpdateCategory />
          ) : (
            ''
          )}
          {location.pathname == '/admin/categorymanagement/addsubcategory' ? (
            <AddSubCategory />
          ) : (
            ''
          )}
          {location.pathname == '/admin/apimanagement' ? <ApiManagement /> : ''}
          {location.pathname == '/admin/paymentmanagement' ? <Payment /> : ''}
          {location.pathname == '/admin/notification' ? <Notification /> : ''}
          {location.pathname == '/admin/contentmanagement' ? (
            <ContentManagement />
          ) : (
            ''
          )}
          {location.pathname == '/admin/contentmanagement/addaboutus' ? (
            <AddAboutUs />
          ) : (
            ''
          )}
          {location.pathname.includes(
            '/admin/contentmanagement/updateaboutus'
          ) ? (
            <UpdateAboutUs />
          ) : (
            ''
          )}
          {location.pathname == '/admin/contentmanagement/addprivacypolicy' ? (
            <AddPrivacyPolicy />
          ) : (
            ''
          )}
          {location.pathname.includes(
            '/admin/contentmanagement/updateprivacypolicy'
          ) ? (
            <UpdatePrivacyPolicy />
          ) : (
            ''
          )}
          {location.pathname ==
          '/admin/contentmanagement/addtermsandconditions' ? (
            <AddTermsAndConditions />
          ) : (
            ''
          )}
          {location.pathname.includes(
            '/admin/contentmanagement/updatetermsandconditions'
          ) ? (
            <UpdateTermsAndConditions />
          ) : (
            ''
          )}
          {location.pathname ==
          '/admin/contentmanagement/returnpolicy' ? (
            <AddReturnPolicy />
          ) : (
            ''
          )}
          {location.pathname.includes(
            '/admin/contentmanagement/updatereturnpolicy'
          ) ? (
            <UpdateReturnPolicy />
          ) : (
            ''
          )}
          {location.pathname ==
          '/admin/contentmanagement/addcustomerservices' ? (
            <AddCustomerServices />
          ) : (
            ''
          )}
          {location.pathname.includes(
            '/admin/contentmanagement/updatecustomerservices'
          ) ? (
            <UpdateCustmerServices />
          ) : (
            ''
          )}
          {location.pathname == '/admin/contentmanagement/faq' ? <Faq /> : ''}
          {location.pathname == '/admin/contentmanagement/faq/addfaq' ? (
            <AddFaq />
          ) : (
            ''
          )}
          {location.pathname.includes(
            '/admin/contentmanagement/faq/updatefaq'
          ) ? (
            <UpdateFaq />
          ) : (
            ''
          )}
          {location.pathname == '/admin/contentmanagement/topdeals' ? (
            <TopDeals />
          ) : (
            ''
          )}
          {location.pathname ==
          '/admin/contentmanagement/topdeals/addtopdeals' ? (
            <AddTopDeals />
          ) : (
            ''
          )}
          {location.pathname.includes(
            '/admin/contentmanagement/topdeals/updatetopdeals'
          ) ? (
            <UpdateTopDeals />
          ) : (
            ''
          )}
          {location.pathname.includes('/admin/purchaseorder') ? (
            <PurchaseOrder />
          ) : (
            ''
          )}
          {location.pathname.includes('/admin/admintosellerinvoice') ? (
            <AdminToSellerInvoice />
          ) : (
            ''
          )}
          {location.pathname == '/admin/blogs' ? <Blogs /> : ''}
          {location.pathname == '/admin/subblogs' ? <SubBlogs /> : ''}
          {location.pathname == '/admin/blogs/addblogs' ? <AddBlogs /> : ''}
          {location.pathname.includes('/admin/blogs/updateblogs') ? (
            <UpdateBlogs />
          ) : (
            ''
          )}
          {location.pathname == '/admin/guidelines' ? <Guidelines /> : ''}
          {location.pathname == '/admin/blogs/addguidelines' ? (
            <AddGuidelines />
          ) : (
            ''
          )}
          {location.pathname.includes('/admin/blogs/updateguidelines') ? (
            <UpdateGuidelines />
          ) : (
            ''
          )}
          {location.pathname == '/admin/productdetails' ? (
            <ProductDetails />
          ) : (
            ''
          )}
          {location.pathname == '/admin/blogs/addproductdetails' ? (
            <AddProductDetails />
          ) : (
            ''
          )}
          {location.pathname.includes('/admin/blogs/updateproductdetails') ? (
            <UpdateProductDetailsCms />
          ) : (
            ''
          )}
          {location.pathname == '/admin/productpricing' ? (
            <ProductPricing />
          ) : (
            ''
          )}
          {location.pathname == '/admin/blogs/addproductpricing' ? (
            <AddProductPricing />
          ) : (
            ''
          )}
          {location.pathname.includes('/admin/blogs/updateproductpricing') ? (
            <UpdateProductPricingCms />
          ) : (
            ''
          )}
          {location.pathname == '/admin/productpurity' ? (
            <ProductPurity />
          ) : (
            ''
          )}
          {location.pathname == '/admin/blogs/addproductpurity' ? (
            <AddProductPurity />
          ) : (
            ''
          )}
          {location.pathname.includes('/admin/blogs/updateproductpurity') ? (
            <UpdateProductPurity />
          ) : (
            ''
          )}
          {location.pathname == '/admin/membership' ? <Membership /> : ''}
          {location.pathname.includes('/admin/membership/membershipcontent') ? (
            <MembershipContent />
          ) : (
            ''
          )}
          {location.pathname.includes('/admin/membership/formfillinguide') ? (
            <FormFillingGuide />
          ) : (
            ''
          )}
          {location.pathname.includes('/admin/membership/membershiptermsandcondition') ? (
            <MembershipTermsAndCondition />
          ) : (
            ''
          )}
          {location.pathname == '/admin/masteradminaccount' ? (
            <MasterAdminAccount />
          ) : (
            ''
          )}
          {/* {location.pathname == '/admin/productmanagement' ? (
            <ProductManagement />
          ) : (
            ''
          )} */}
          {location.pathname == '/admin/productmanagement/addproducts' ? (
            <AddProducts />
          ) : (
            ''
          )}
          {location.pathname == '/admin/ordermanagement' ? (
            <OrderManagement />
          ) : (
            ''
          )}
          {location.pathname == '/admin/pendingorders' ? (
            <PendingOrders />
          ) : (
            ''
          )}
          {location.pathname.includes(
            '/admin/ordermanagement/orderdescription'
          ) ? (
            <OrderDescription />
          ) : (
            ''
          )}
          {location.pathname.includes(
            '/admin/pendingorders/pendingorderdescription'
          ) ? (
            <PendingOrderDescription />
          ) : (
            ''
          )}
          {location.pathname == '/admin/rejectedorders' ? (
            <RejectedOrders />
          ) : (
            ''
          )}
          {location.pathname == '/admin/returnorders' ? <ReturnOrders /> : ''}
          {location.pathname.includes('/admin/returnorders/viewreturnorder') ? (
            <ViewReturnOrder />
          ) : (
            ''
          )}
          {location.pathname == '/admin/reports' ? <Reports /> : ''}
          {location.pathname == '/admin/productmanagement/customproducts' ? (
            <CustomProducts />
          ) : (
            ''
          )}
          {location.pathname.includes(
            '/admin/productmanagement/customproducts/viewcustomproduct'
          ) ? (
            <ViewCustomProduct />
          ) : (
            ''
          )}
          {location.pathname.includes(
            '/admin/productmanagement/customproducts/updatecustomproduct'
          ) ? (
            <UpdateCustomProduct />
          ) : (
            ''
          )}
          {location.pathname == '/admin/productmanagement/bidproducts' ? (
            <BidProducts />
          ) : (
            ''
          )}
          {/* {location.pathname == '/admin/usermanagement/manageuser' ? (
            <ManageUser />
          ) : (
            ''
          )} */}
          {location.pathname == '/admin/productmanagement/pendingproducts' ? (
            <PendingProducts />
          ) : (
            ''
          )}

          {location.pathname.includes(
            '/admin/productmanagement/pendingproducts/viewpendingproducts'
          ) ? (
            <ViewPendingProduct />
          ) : (
            ''
          )}
          {location.pathname.includes('/admin/updateproductdetails') ? (
            <UpdateProductDetails />
          ) : (
            ''
          )}
          {location.pathname.includes(
            '/admin/productmanagement/pendingproducts/viewuploadedproducts'
          ) ? (
            <ViewUploadedProduct />
          ) : (
            ''
          )}
          {location.pathname == '/admin/productmanagement/uploadedproducts' ? (
            <UploadedProducts />
          ) : (
            ''
          )}
          {location.pathname == '/admin/productmanagement/rejectedproducts' ? (
            <RejectedProducts />
          ) : (
            ''
          )}
          {location.pathname == '/admin/ordermanagement/allorders' ? (
            <AllOrders />
          ) : (
            ''
          )}
          {location.pathname ==
          '/admin/ordermanagement/singlemultipleorders' ? (
            <SingleMultipleOrders />
          ) : (
            ''
          )}
          {location.pathname == '/admin/ordermanagement/bulkorders' ? (
            <BulkOrders />
          ) : (
            ''
          )}
          {location.pathname ==
          `/admin/usermanagement/manageuser/${params.id}` ? (
            <ManageUser />
          ) : (
            ''
          )}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            {/* <GoToTop /> */}
          </Box>
        </Main>
      </Box>
    </>
  );
}
