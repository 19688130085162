import React, { useState, useEffect } from 'react';
import {
  Container,
  Box,
  Grid,
  Typography,
  Stack,
  ListItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Button,
  Modal,
  TextareaAutosize,
  CardMedia,
} from '@mui/material';
import dataService from '../../../../config/dataService';
import { Api } from '../../../../config/api';
import { useParams, useHistory } from 'react-router-dom';
import { totalPrice } from '../../../../component/customComponents/priceCalculation/TotalPrice';
import { toast } from 'react-toastify';
import ContainedButton from '../../../../component/customComponents/buttons/ContainedButton';
import Png from '../../../../assets/png';
import Loader from '../../../../component/customComponents/loader/Loader';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import {
  commaSeparated,
  ConvertIntoTwoDigits,
  price
} from '../../../../component/customComponents/commonFunctions/CommonFunctions';
import {
  minRejectReasonLength,
  rejectReasonLength,
  NO_WHITE_SPACE_BEGINNING_END,
} from '../../../../component/customComponents/validation/Validation';
import moment from 'moment';
import { ErrorMsg, TextAreaErrorMsg } from '../../../../component/customComponents/errorMsg/ErrorMsg';
//#region ALL HOOKS AND VARIABALS

import AdminIndex from '../../AdminIndex';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const { getReturnOrderDetail, approveReturnOrder, rejectReturnOrder, updateOrderTransactionId } = AdminIndex.AdminSagaActions;
const ViewReturnOrder = () => {
  let token = localStorage.getItem('auth');
  const dispatch = AdminIndex.useDispatch();
  let history = useHistory();
  let navigate = useHistory();
  const { id } = useParams();
  // const [loading, setLoading] = useState(false);
  const [returnData, setReturnData] = useState([]);
  const [transactionIdData, setTransactionIdData] = useState('');
  const [returnProductData, setReturnProductData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [rejectReason, setRejectReason] = useState('');
  const [transactionId, setTransactionId] = useState('');
  const [transactionIdError, setTransactionIdError] = useState('');
  const [error, setError] = React.useState('');
  const [returnProductId, setReturnProductId] = useState([]);

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
    watch,
  } = useForm();
  //#endregion

  //#region ALL EVENT HANDLERS AND  FUCTIONS

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChangeReject = (e) => {
    setRejectReason(e.target.value);
  };

  const handleChangeTransactionId = (e) => {
    setTransactionId(e.target.value);
  };

  const tIdValidation = () => {
    if (
      transactionId == '' ||
      transactionId == ' ' ||
      transactionId == undefined
    ) {
      setTransactionIdError('Please enter Transaction Id');
    } else {
      setTransactionIdError('');
    }
  };
  const tIdValidation1 = (e) => {
    if (e.target.value == '') {
      setTransactionIdError('Please enter Transaction Id');
    } else {
      setTransactionIdError('');
    }
  };

  const handleChangePymentStatus = (event) => {
    // setOrderItemStatus(event.target.value);
  };
  const sendTransactionId = async () => {
    const urlencoded = new URLSearchParams();
    tIdValidation();
    if (returnData.return_status != 1) {
      toast.error('Please accept return order');
    }
    if (
      transactionId !== ' ' &&
      transactionId !== '' &&
      returnData.return_status == 1
    ) {
      urlencoded.append('id', id);
      urlencoded.append('productId', returnProductId);
      urlencoded.append('transaction_id', transactionId);
      try {
        dispatch(updateOrderTransactionId(token,urlencoded,navigate));
        // const transactionId = await dataService.post(
        //   Api.Admin.updateTransactionStatus,
        //   urlencoded,
        //   {
        //     headers: {
        //       auth: token,
        //     },
        //   }
        // );
        // if (transactionId.data.status == '1') {
        //   toast.success('Transaction Id updated successfully');
        //   history.push('/admin/paymentmanagement');
        // }
      } catch (err) {
        toast.error(err?.message || 'Something went wrong');
      }
    }
  };

  const getSingleReturnOrder = async () => {
    const urlencoded = new URLSearchParams();
    try {
      urlencoded.append('id', id);
      dispatch(getReturnOrderDetail(token,urlencoded));
      // const returnData = await dataService.post(
      //   Api.Admin.getSingleReturnOrderAdmin,
      //   urlencoded,
      //   {
      //     headers: {
      //       auth: token,
      //     },
      //   }
      // );
      // setReturnData(returnData?.data?.data?.viewReturnOrder);
      // setTransactionId(returnData?.data?.data?.paymentDetails?.transaction_id);
      // setReturnProductData(returnData?.data?.data?.viewReturnOrder?.products);
      // setProductData(returnData?.data?.data?.viewReturnOrder?.products?.[0]);
      // setReturnProductId(
      //   returnData?.data?.data?.viewReturnOrder?.products?.[0]?.productId
      // );
    } catch (err) {
      toast.error(err?.message || 'Something went wrong');
    } finally {
      // setLoading(false);
    }
  };

  const loading = AdminIndex.useSelector((state) => {
    return state.AdminSaga.loading;
  });

  const returnOrderDetail = AdminIndex.useSelector((state) => {
    return state.AdminSaga.returnOrderDetail;
  });

  useEffect(() => {
    setReturnData(returnOrderDetail?.viewReturnOrder);
    setTransactionId(returnOrderDetail?.paymentDetails?.transaction_id);
    setReturnProductData(returnOrderDetail?.viewReturnOrder?.products);
    setProductData(returnOrderDetail?.viewReturnOrder?.products?.[0]);
    setReturnProductId(
      returnOrderDetail?.viewReturnOrder?.products?.[0]?.productId
    );
  }, [returnOrderDetail]);

  useEffect(() => {
    getSingleReturnOrder();
  }, []);

  const accept = async () => {
    const urlencoded = new URLSearchParams();
    try {
      urlencoded.append('id', id);

      dispatch(approveReturnOrder(token,urlencoded,navigate));
      // const approve = await dataService.post(
      //   Api.Admin.approveRetrunOrder,
      //   urlencoded,
      //   {
      //     headers: {
      //       auth: token,
      //     },
      //   }
      // );
      // if (approve.data.status === 1) {
      //   toast.success('Return order request Accepted successfully');
      //   history.push('/admin/paymentmanagement');
      // }
    } catch (err) {
      toast.error(err?.message || 'Something went wrong');
    }
  };

  const reject = async (data) => {
    let formdata = new URLSearchParams();
    try {
        formdata.append('id', id);
        formdata.append('reason_of_rejection', data.rejectReason?.trim());
        dispatch(rejectReturnOrder(token,formdata,navigate));
        // const rejData = await dataService.post(
        //   Api.Admin.rejectRetrunOrder,
        //   formdata,
        //   {
        //     headers: {
        //       auth: token,
        //     },
        //   }
        // );
        // if (rejData.data.status === 1) {
        //   toast.success('Return order request Rejected successfully');
        //   history.push('/admin/returnorders');
        // }
    } catch (err) {
      toast.error(err?.message || 'Something went wrong');
    }
  };
  //#endregion

  //#region RETURN JSX
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Container maxWidth="false" sx={{ mb: 5 }}>
          {/* <Typography align="center" variant="h4" sx={{ fontWeight: 'bold' }}>
          Order Description
        </Typography> */}
          <Box
            sx={{
              border: '1px solid black',
              borderBottom: 0,
            }}
          >
            <Grid container>
              <Grid item xs={12} lg={6}>
                <Stack spacing={2}>
                  <ListItem>
                    Order Id - {returnData?.order_id?.order_id}
                  </ListItem>
                  <ListItem>
                    Date -{' '}
                    {/* {new Date(
                    returnData?.order_id?.createdAt
                  ).toLocaleDateString()} */}
                    {moment(returnData?.order_id?.createdAt).format(
                      'DD/MM/YYYY'
                    )}
                  </ListItem>
                  <ListItem>Buyer Name - {returnData?.user_id?.name}</ListItem>
                </Stack>
              </Grid>

              <Grid item xs={12} lg={6}>
                <Stack spacing={2}>
                  <ListItem>
                    Order Item Id - {productData?.order_item_id}
                  </ListItem>
                  <ListItem>
                    Date -{' '}
                    {/* {new Date(
                    returnData?.order_id?.createdAt
                  ).toLocaleDateString()} */}
                    {moment(returnData?.order_id?.createdAt).format(
                      'DD/MM/YYYY'
                    )}
                  </ListItem>
                  <ListItem>
                    Seller -{' '}
                    {returnProductData?.[0]?.seller_id?.name ||
                      returnProductData?.[0]?.admin_id?.name}
                  </ListItem>
                  {/* <ListItem>Total Price - </ListItem> */}
                </Stack>
              </Grid>
            </Grid>
          </Box>
          {returnProductData?.length &&
            returnProductData?.map((product, index) => {
              return (
                <Box>
                  <Typography
                    sx={{
                      border: '1px solid black',
                      height: '50px',
                      borderBottom: 0,
                      pt: 1.5,
                      pl: 2,
                      bgcolor: '#acacff',
                      fontWeight: 'bold',
                    }}
                  >
                    Product Details
                  </Typography>
                  <Box
                    sx={{
                      border: '1px solid black',
                      borderBottom: 0,
                    }}
                  >
                    <Grid container>
                      <Grid item xs={12} lg={4}>
                        <Stack spacing={2}>
                          <ListItem>
                            Product Id - {product?.orderProductId}
                          </ListItem>
                          <ListItem>
                            Product Name - {product?.productName}
                          </ListItem>
                          <ListItem>
                            Qty - {ConvertIntoTwoDigits(product?.quantity)}
                          </ListItem>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} lg={4}>
                        <Stack spacing={2}>
                          {/* <ListItem>Product - </ListItem> */}
                          <ListItem>
                            Wt (gm) -{' '}
                            {product?.productWeight
                              ? ConvertIntoTwoDigits(product?.productWeight)
                              : '00'}
                          </ListItem>
                          <ListItem>
                            Purity -{' '}
                            {product?.productPurity
                              ? ConvertIntoTwoDigits(product?.productPurity)
                              : '00'}
                          </ListItem>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} lg={4}>
                        <Stack spacing={2}>
                          <ListItem>
                            Price -{' '}
                            {product?.total_price
                              ? price(product?.total_price)
                              : price(product?.price)}
                            <span>
                              <img src={Png.rupee} className="rupee-icon"></img>
                            </span>
                          </ListItem>
                          <ListItem>
                            Discount -{' '}
                            {product?.discount
                              ? ConvertIntoTwoDigits(product?.discount)
                              : '00'}
                          </ListItem>
                          <ListItem>
                            Taxes -{' '}
                            {product?.taxes
                              ? ConvertIntoTwoDigits(product?.taxes)
                              : '00'}
                          </ListItem>
                          {/* <ListItem>Total Price - </ListItem> */}
                        </Stack>
                      </Grid>
                    </Grid>
                  </Box>
                  <Grid
                    container
                    sx={{
                      display: 'flex',
                      border: '1px solid black',
                      py: 1.5,
                      pl: 2,
                      // bgcolor: '#acacff',
                      borderBottom: 0,
                    }}
                  >
                    <Grid item lg={1.7}>
                      <Typography
                        sx={{
                          fontWeight: 'bold',
                        }}
                      >
                        Reason for return -
                      </Typography>
                    </Grid>
                    <Grid item lg={10.3}>
                      <Typography sx={{ ml: 1 }}>
                        {returnData?.return_reason}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Box
                    sx={{
                      border: '1px solid black',
                      py: 1.5,
                      pl: 2,
                      borderBottom: 0,
                      fontWeight: 'bold',
                    }}
                  >
                    <Typography variant="p">Video</Typography>
                    <Box className="main-return-box">
                      <CardMedia
                        sx={{
                          width: '500px',
                          height: '300px',
                        }}
                        component="iframe"
                        src={returnData.video}
                        allow="autoPlay"
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      border: '1px solid black',
                      py: 1.5,
                      pl: 2,
                      borderBottom: returnData?.return_status != '1' ? 1 : 0
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '200px',
                      }}
                    >
                      <Button
                        disabled={
                          (returnData?.return_status == '1' ||
                            returnData?.return_status == '2' ||
                            returnData?.return_status == '3') &&
                          true
                        }
                        variant="contained"
                        sx={{
                          borderRadius: 5,
                          textTransform: 'none',
                          width: '90px',
                          bgcolor: 'green',
                        }}
                        onClick={(e) => {
                          if (
                      window.confirm(
                        'Are you sure you want to Accept this Return Order?'
                      )
                    ) {
                          accept(e);
                          }
                        }}
                      >
                        Approve
                      </Button>
                      <Button
                        disabled={
                          (returnData?.return_status == '2' ||
                            returnData?.return_status == '3') &&
                          true
                        }
                        variant="contained"
                        sx={{
                          borderRadius: 5,
                          textTransform: 'none',
                          width: '90px',
                          bgcolor: 'red',
                        }}
                        onClick={(e) => {
                          if (
                      window.confirm(
                        'Are you sure you want to Decline this Return Order?'
                      )
                    ) {
                          handleOpen(e);
                          }
                        }}
                      >
                        Decline
                      </Button>
                      <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <form onSubmit={handleSubmit(reject)}>
                    <Box sx={style}>
                      <Typography>Rejection reason</Typography>
                      <Box className="text-area-control-main">
                        <TextareaAutosize
                          autoComplete="off"
                          sx={{
                            mt: 2,
                            width: { lg: '300px', xs: '150px' },
                          }}
                          size="small"
                          fullWidth
                          // id="rejectReason"
                          className="form-control border-none text-area-control1 text-area-control-admin"
                          name="rejectReason"
                          type="text"
                          {...register('rejectReason', {
                            required: (
                              <TextAreaErrorMsg msg="Please enter Rejection reason" />
                            ),
                            minLength: {
                              value: minRejectReasonLength,
                              message: (
                                <TextAreaErrorMsg
                                  msg={`Minimum ${minRejectReasonLength} Characters Allowed`}
                                />
                              ),
                            },
                            maxLength: {
                              value: rejectReasonLength,
                              message: (
                                <TextAreaErrorMsg
                                  msg={`Maximum ${rejectReasonLength} Characters Allowed`}
                                />
                              ),
                            },
                            pattern: {
                              value: NO_WHITE_SPACE_BEGINNING_END,
                              message: (
                                <TextAreaErrorMsg msg="Whitespace is not allowed at beginning or end" />
                              ),
                            },
                          })}
                        />
                        <ErrorMessage errors={errors} name="rejectReason" />
                      </Box>
                      <Button
                        sx={{ mt: 2, textTransform: 'none' }}
                        variant="contained"
                        type="submit"
                      >
                        Submit
                      </Button>
                    </Box>
                  </form>
                      </Modal>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      border: '1px solid black',
                      py: 1.5,
                      pl: 2,
                      display:
                      returnData?.return_status == '2' ||
                          returnData?.return_status == '0' 
                            ? 'none'
                            : 'block',
                      // display: 'flex'
                    }}
                  >
                    <Box
                      sx={{
                        display:
                        returnData?.return_status == '2' ||
                          returnData?.return_status == '0'
                            ? 'none'
                            : 'block',
                      }}
                    >
                      {/* <ListItem sx={{ justifyContent: 'center', py: 4 }}> */}
                      <Typography sx={{ my: 2 }} align="center" variant="h6">
                        Transaction Id{' '}
                      </Typography>
                      <Typography sx={{ my: 2 }} align="center" variant="h4">
                        <TextField
                          disabled={
                            (returnData?.return_status == '2' ||
                              returnData?.return_status == '3') &&
                            true
                          }
                          value={transactionId}
                          id="outlined-basic"
                          variant="outlined"
                          sx={{
                            mx: 1,
                            '& .MuiFormHelperText-root': { ml: 0, mt: '5px' },
                            minWidth: '350px',
                          }}
                          onChange={(e) => {
                            handleChangeTransactionId(e);
                            tIdValidation1(e);
                          }}
                          helperText={<ErrorMsg msg={transactionIdError} />}
                        />
                      </Typography>
                      <Typography align="center" variant="h4">
                        <ContainedButton
                          disabled={
                            (returnData?.return_status == '2' ||
                              returnData?.return_status == '3') &&
                            true
                          }
                          name="Submit"
                          onClick={sendTransactionId}
                        />
                      </Typography>
                      {/* </ListItem> */}
                      {/* <Typography
                    align="center"
                    sx={{ color: '#d32f2f', ml: '14px', mt: '4px' }}
                  >
                    {transactionIdError}
                  </Typography> */}
                    </Box>
                    {/* <Typography textAlign="center">
                      {returnData?.return_status == '3' ? (
                        <Typography sx={{ color: 'green' }}>
                          Return Order request Accepted
                        </Typography>
                      ) : returnData?.return_status == '2' ? (
                        <Typography sx={{ color: 'red' }}>
                          Return Order request Rejected
                        </Typography>
                      ) : (
                        ''
                      )}
                    </Typography> */}
                  </Box>
                </Box>
              );
            })}
        </Container>
      )}
    </>
  );
};

export default ViewReturnOrder;
//#endregion
