//#region ALL IMPORTS
import React, { useState } from 'react';
import {
  Grid,
  Container,
  Typography,
  TextareaAutosize,
  Box,
  FormHelperText,
  TextField,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { Api } from '../../../../../config/api';
import dataService from '../../../../../config/dataService';
import { toast } from 'react-toastify';
import ContainedButton from '../../../../../component/customComponents/buttons/ContainedButton';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import {
  ErrorMsg,
  TextAreaErrorMsg,
} from '../../../../../component/customComponents/errorMsg/ErrorMsg';
import {
  minQuestionLength,
  questionLength,
  minAnswerLength,
  answerLength,
  QUESTION_REGEX,
} from '../../../../../component/customComponents/validation/Validation';
//#endregion

import AdminIndex from '../../../AdminIndex';


const { addNewFAQ } = AdminIndex.AdminSagaActions;
//#region ALL HOOKS AND VARIABALS
const AddFaq = () => {
  let token = localStorage.getItem('auth');
  const dispatch = AdminIndex.useDispatch();
  const history = useHistory();
  const navigate = useHistory();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  //#endregion

  //#region ALL EVENT HANDLERS AND  FUCTIONS

  const handleSave = async (data) => {
    try {
      let urlencoded = new URLSearchParams();
      urlencoded.append('question', data.question?.trim());
      urlencoded.append('answer', data.answer?.trim());

      dispatch(addNewFAQ(token,urlencoded,navigate));
      // const questionAndAnswer = await dataService.post(
      //   Api.Admin.addFaqs,
      //   urlencoded,
      //   {
      //     headers: {
      //       auth: token,
      //     },
      //   }
      // );

      // if (questionAndAnswer.data.status === 1) {
      //   toast.success('FAQ added successfully');
      //   history.push('/admin/contentmanagement/faq');
      // }
    } catch (err) {
      toast.error(err?.message || 'Something went wrong');
    }
  };
  //#endregion

  //#region RETURN JSX
  return (
    <>
      <form onSubmit={handleSubmit(handleSave)}>
        <Container maxWidth="false">
          <Typography sx={{ my: 2 }} align="center" variant="h4">
            Add FAQ
          </Typography>

          <Grid container spacing={5}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography align="left" sx={{ mt: 2 }}>
                Question
              </Typography>
              <TextField
                autoComplete="off"
                sx={{
                  mt: 2,
                  '& .MuiFormHelperText-root': { ml: 0, mt: '5px' },
                }}
                size="small"
                fullWidth
                className="form-control border-none"
                name="question"
                type="text"
                {...register('question', {
                  required: <ErrorMsg msg="Please enter Question" />,
                  minLength: {
                    value: minQuestionLength,
                    message: (
                      <ErrorMsg
                        msg={`Minimum ${minQuestionLength} Characters Allowed`}
                      />
                    ),
                  },
                  maxLength: {
                    value: questionLength,
                    message: (
                      <ErrorMsg
                        msg={`Maximum ${questionLength} Characters Allowed`}
                      />
                    ),
                  },

                  pattern: {
                    value: QUESTION_REGEX,
                    message: (
                      <ErrorMsg
                        msg={'"?" mark is required at the end of the Question'}
                      />
                    ),
                  },
                })}
                helperText={<ErrorMessage errors={errors} name="question" />}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography align="left" sx={{ mt: 2 }}>
                Answer
              </Typography>
              <Box className="text-area-control-main">
                <TextareaAutosize
                  autoComplete="off"
                  minRows={0}
                  size="small"
                  fullWidth
                  className="form-control border-none text-area-control1 text-area-control-admin"
                  name="answer"
                  type="text"
                  {...register('answer', {
                    required: <TextAreaErrorMsg msg="Please enter Answer" />,
                    minLength: {
                      value: minAnswerLength,
                      message: (
                        <TextAreaErrorMsg
                          msg={`Minimum ${minAnswerLength} Characters Allowed`}
                        />
                      ),
                    },
                    maxLength: {
                      value: answerLength,
                      message: (
                        <TextAreaErrorMsg
                          msg={`Maximum ${answerLength} Characters Allowed`}
                        />
                      ),
                    },
                  })}
                />
                <ErrorMessage errors={errors} name="answer" />
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}>
            <ContainedButton name="Submit" type="submit" />
          </Box>
        </Container>
      </form>
    </>
  );
};

export default AddFaq;
//#endregion
