import React, { useState, useEffect } from 'react';
import {
  Container,
  Box,
  Grid,
  Typography,
  Stack,
  ListItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Modal,
  Tooltip,
  Button,
  TextareaAutosize
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import dataService from '../../../../config/dataService';
import { Api } from '../../../../config/api';
import { useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Png from '../../../../assets/png';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import {
  commaSeparated,
  ConvertIntoTwoDigits,
  price,
  twoValAfterDesimal,
} from '../../../../component/customComponents/commonFunctions/CommonFunctions';
import { regExValidation } from '../../../../component/customComponents/validation/Validation';
import Loader from '../../../../component/customComponents/loader/Loader';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import {
  ErrorMsg,
  TextAreaErrorMsg,
} from '../../../../component/customComponents/errorMsg/ErrorMsg';
import {
  minRejectReasonLength,
  rejectReasonLength,
  NO_WHITE_SPACE_BEGINNING_END,
} from '../../../../component/customComponents/validation/Validation';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import AdminIndex from '../../AdminIndex';

const Img = styled('img')({
  display: 'block',
  maxWidth: '100%',
  height: 'auto',
  maxHeight: '50vh',
  borderRadius: '6px',
});

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 367,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const { getOrderDetail, approveOrder, rejectOrder, getOrderRating } = AdminIndex.AdminSagaActions;
//#region ALL HOOKS AND VARIABALS
const PendingOrderDescription = () => {
  let token = localStorage.getItem('auth');
  const dispatch = AdminIndex.useDispatch();
  let history = useHistory();
  let navigate = useHistory();
  const params = useParams();
  const today = new Date();
  const { id } = useParams();
  const [details, setDetails] = useState(false);
  const [open, setOpen] = useState(false);
  const [expanded, setExpanded] = useState('panel1');
  const [transactionId, setTransactionId] = useState({});
  const [transactionIdError, setTransactionIdError] = useState([]);
  const [customTransactionIdError, setCustomTransactionIdError] = useState('');
  const [orderData, setOrderData] = useState([]);
  const [transactionIdData, setTransactionIdData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [orderItemStatusArray, setOrderItemStatusArray] = useState({});
  const [ratingArray, setRatingArray] = useState([]);
  const [ratingArray1, setRatingArray1] = useState({});
  const [ratingArray2, setRatingArray2] = useState({});
  const [rating, setRating] = useState([]);
  const [customProductData, setCustomProductData] = useState({});
  const [productSellerData, setCustomProductSellerData] = useState({});
  const [customOrderItemStatus, setCustomOrderItemStatus] = useState('');
  const [customTransactionId, setCustomTransactionId] = useState('');
  const [startDate, setStartDate] = useState({});
  const [customStartDate, setCustomStartDate] = useState('');
  const [startDateError, setStartDateError] = useState([]);
  const [customStartDateError, setCustomStartDateError] = useState('');
  const [url, setUrl] = useState('');

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
    watch,
  } = useForm();

  useEffect(() => {
    fetchOrderDetail();
    getAllRating();
  }, []);

  useEffect(() => {
    setCustomOrderInitialValueInStatus();
    setCustomStartDate(orderData?.deliveryDate ? orderData?.deliveryDate : '');
  }, [customProductData]);

  useEffect(() => {
    setInitialValueInStatus();
    setInitialDate();
    setInitialRate();
    setInitialTransactionId();
    setInitialCustomOrderTransactionId();
  }, [productData]);
  //#endregion

  //#region ALL EVENT HANDLERS AND  FUCTIONS

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const accept = async () => {
    const urlencoded = new URLSearchParams();
    urlencoded.append('id', params.id);

    try {
      dispatch(approveOrder(token,urlencoded,navigate));
      // const rejData = await dataService.post(
      //   Api.Admin.approveOrder,
      //   urlencoded,
      //   {
      //     headers: {
      //       auth: token,
      //     },
      //   }
      // );

      // if (rejData.data.status == '1') {
      //   toast.success('Order accepted successfully');
      //   history.push(`/admin/ordermanagement`);
      // }
    } catch (err) {
      toast.error(err?.message || 'Something went wrong');
    }
  };

  const reject = async (data) => {
    try {
      let urlencoded = new URLSearchParams();
      urlencoded.append('id', params.id);
      urlencoded.append('reasonOfRejection', data.rejectReason?.trim());
      
      dispatch(rejectOrder(token,urlencoded,navigate));
      // urlencoded.append('approveStatus', '2');
      // const rejData = await dataService.post(Api.Admin.rejectOrder, urlencoded, {
      //   headers: {
      //     auth: token,
      //   },
      // });
      // if (rejData.data.status === 1) {
      //   toast.success('Order rejected successfully');
      //   history.push('/admin/rejectedorders');
      // }
    } catch (err) {
      // toast.error(err?.message || 'Something went wrong');
    }
  };

  const dateValidation = (date, dateName) => {
    if (date == null) {
      // setStartDateError('Please select date');
      setStartDateError((prev) => ({
        ...prev,
        [dateName]: 'Please select date',
      }));
    } else if (
      regExValidation.date(moment(date).format('DD/MM/YYYY')) == false
    ) {
      // setStartDateError('Please select valid date');
      setStartDateError((prev) => ({
        ...prev,
        [dateName]: 'Please select valid date',
      }));
    } else {
      // setStartDateError('');
      setStartDateError((prev) => ({
        ...prev,
        [dateName]: '',
      }));
    }
  };
  const dateValidation1 = (date, e, dateName) => {
    if (date == null) {
      // setStartDateError('Please select date');
      setStartDateError((prev) => ({
        ...prev,
        [dateName]: 'Please select date',
      }));
    } else if (
      regExValidation.date(moment(e.$d).format('DD/MM/YYYY')) == false
    ) {
      // setStartDateError('Please select valid date');
      setStartDateError((prev) => ({
        ...prev,
        [dateName]: 'Please select valid date',
      }));
    } else {
      // setStartDateError('');
      setStartDateError((prev) => ({
        ...prev,
        [dateName]: '',
      }));
    }
  };
  const dateValidation2 = (e) => {
    if (e.$d == null || e.$d == undefined || e.$d == '') {
      setCustomStartDateError('Please select date');
    } else if (
      regExValidation.date(moment(e.$d).format('DD/MM/YYYY')) == false
    ) {
      setCustomStartDateError('Please select valid date');
    } else {
      setCustomStartDateError('');
    }
  };
  const dateValidation3 = (date) => {
    if (date == null || date == '' || date == ' ') {
      setCustomStartDateError('Please select date');
    } else if (
      regExValidation.date(moment(date).format('DD/MM/YYYY')) == false
    ) {
      setCustomStartDateError('Please select valid date');
    } else {
      setCustomStartDateError('');
    }
  };

  const tIdvalidation = (transactionId, tIdName) => {
    if (
      transactionId == null ||
      transactionId == undefined ||
      transactionId == '' ||
      transactionId == ' '
    ) {
      // setTransactionIdError('Please select Transaction Id');
      setTransactionIdError((prev) => ({
        ...prev,
        [tIdName]: 'Please select Transaction Id',
      }));
    } else {
      // setTransactionIdError('');
      setTransactionIdError((prev) => ({
        ...prev,
        [tIdName]: '',
      }));
    }
  };
  const tIdvalidation3 = (transactionId, tIdName) => {
    if (transactionId == null || transactionId == undefined) {
      // setTransactionIdError('Please select Transaction Id');
      setTransactionIdError((prev) => ({
        ...prev,
        [tIdName]: 'Please select Transaction Id',
      }));
    } else {
      // setTransactionIdError('');
      setTransactionIdError((prev) => ({
        ...prev,
        [tIdName]: '',
      }));
    }
  };
  const tIdvalidation1 = (e) => {
    let val = e.target.value;
    if (val == null || val == undefined) {
      setCustomTransactionIdError('Please select Transaction Id');
    } else {
      setCustomTransactionIdError('');
    }
  };
  const tIdvalidation2 = (transactionId) => {
    if (
      transactionId == null ||
      transactionId == undefined ||
      transactionId == '' ||
      transactionId == ' '
    ) {
      setCustomTransactionIdError('Please select Transaction Id');
    } else {
      setCustomTransactionIdError('');
    }
  };

  const setInitialValueInStatus = () => {
    if (productData?.length) {
      let tempObj = {};
      productData.map((product, iNum) => {
        if (product?.orderItemStatus == '0') {
          tempObj['product' + (iNum + 1)] = '0';
        } else if (product?.orderItemStatus == '1') {
          tempObj['product' + (iNum + 1)] = '1';
        } else if (product?.orderItemStatus == '2') {
          tempObj['product' + (iNum + 1)] = '2';
        } else if (product?.orderItemStatus == '3') {
          tempObj['product' + (iNum + 1)] = '3';
        } else if (product?.orderItemStatus == '4') {
          tempObj['product' + (iNum + 1)] = '4';
        } else {
          tempObj['product' + (iNum + 1)] = '0';
        }
      });
      setOrderItemStatusArray(tempObj);
    }
  };
  const setCustomOrderInitialValueInStatus = () => {
    if (orderData?.orderItemStatus == '0') {
      setCustomOrderItemStatus('0');
    } else if (orderData?.orderItemStatus == '1') {
      setCustomOrderItemStatus('1');
    } else if (orderData?.orderItemStatus == '2') {
      setCustomOrderItemStatus('2');
    } else if (orderData?.orderItemStatus == '3') {
      setCustomOrderItemStatus('3');
    } else if (orderData?.orderItemStatus == '4') {
      setCustomOrderItemStatus('4');
    } else {
      setCustomOrderItemStatus('0');
    }
  };

  const setInitialRate = () => {
    if (productData?.length) {
      let finalArray = {};
      productData?.map((data, i) => {
        if (data?._id) {
          finalArray['rating' + (i + 1)] = data?.rate?.[0]?.rate_from_admin
            ? data?.rate?.[0]?.rate_from_admin
            : null;
        }
      });
      setRatingArray1(finalArray);
    }
  };

  const setInitialDate = () => {
    if (productData?.length) {
      let finalArray = {};
      productData?.map((data, i) => {
        if (data?._id) {
          finalArray['date' + (i + 1)] = data?.deliveryDate
            ? data?.deliveryDate
            : null;
        }
      });
      setStartDate(finalArray);
    }
  };

  const setInitialTransactionId = () => {
    let finalArray = {};
    productData?.map((val, i) => {
      if (transactionIdData.length !== 0) {
        transactionIdData.map((data) => {
          if (
            val?.productId?._id === data.product_id &&
            val?.productId?.product_id === data?.product_user_id &&
            data?.payment_by_admin === true
          ) {
            finalArray['transactionId' + (i + 1)] =
              data.transaction_id !== ' ' ? data.transaction_id : null;
          }
        });
      } else {
        finalArray['transactionId' + (i + 1)] = null;
      }
    });
    setTransactionId(finalArray);
  };
  const setInitialCustomOrderTransactionId = () => {
    let finalArray;
    transactionIdData?.map((data) => {
      if (data?.payment_by_admin === true) {
        finalArray = data.transaction_id !== ' ' ? data.transaction_id : null;
      }
    });
    setCustomTransactionId(finalArray);
  };

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const handleChangeDate = (event, date) => {
    setStartDate((prev) => ({
      ...prev,
      [date]: event.$d,
    }));
  };
  const handleChangeCustomDate = (event) => {
    setCustomStartDate(event.$d);
  };
  const handleChangeOrderItemStatus = (event, product, date, transactionId) => {
    if (event.target.value == 0 || event.target.value == 1) {
      setOrderItemStatusArray((prev) => ({
        ...prev,
        [product]: event.target.value,
      }));
    }
    if (
      event.target.value == 2 &&
      date !== null &&
      regExValidation.date(moment(date).format('DD/MM/YYYY'))
    ) {
      setOrderItemStatusArray((prev) => ({
        ...prev,
        [product]: event.target.value,
      }));
    }
    if (
      event.target.value == 3 &&
      transactionId !== null &&
      transactionId !== undefined
    ) {
      setOrderItemStatusArray((prev) => ({
        ...prev,
        [product]: event.target.value,
      }));
    }
  };
  const handleChangeCustomOrderItemStatus = (
    event,
    customStartDate,
    customTransactionId
  ) => {
    if (event.target.value == 0 || event.target.value == 1) {
      setCustomOrderItemStatus(event.target.value);
    }
    if (
      event.target.value == 2 &&
      customStartDate !== null &&
      customStartDate !== '' &&
      customStartDate !== ' ' &&
      regExValidation.date(moment(customStartDate).format('DD/MM/YYYY'))
    ) {
      setCustomOrderItemStatus(event.target.value);
    }
    if (
      event.target.value == 3 &&
      customTransactionId !== null &&
      customTransactionId !== undefined &&
      customTransactionId !== '' &&
      customTransactionId !== ' '
    ) {
      setCustomOrderItemStatus(event.target.value);
    }
  };
  const handleChangeRating = (event, rating) => {
    setRatingArray1((prev) => ({
      ...prev,
      [rating]: event.target.value,
    }));
  };
  const handleChangeTransactionId = (event, transactionId) => {
    setTransactionId((prev) => ({
      ...prev,
      [transactionId]: event.target.value,
    }));
  };
  const handleChangeCustomTransactionId = (event) => {
    setCustomTransactionId(event.target.value);
  };

  const getAllRating = async () => {
    const urlencoded = new URLSearchParams();
    try {
      urlencoded.append('id', id);
      dispatch(getOrderRating(token,urlencoded));
      // const getAllRating = await dataService.post(
      //   Api.Admin.getAllRating,
      //   urlencoded
      // );
      // setRating(getAllRating?.data?.data);
    } catch (err) {
      toast.error(err?.message || 'Something went wrong');
    }
  };

  const orderRatings = AdminIndex.useSelector((state) => {
    return state.AdminSaga.orderRatings;
  });

  useEffect(() => {
    setRating(orderRatings);
  }, [orderRatings]);

  const loading = AdminIndex.useSelector((state) => {
    return state.AdminSaga.loading;
  });

  const fetchOrderDetail = async () => {
    const urlencoded = new URLSearchParams();
    try {
      urlencoded.append('id', id);
      dispatch(getOrderDetail(token,urlencoded));
      // const getData = await dataService.post(
      //   Api.Admin.viewOrderAdmin,
      //   urlencoded,
      //   {
      //     headers: {
      //       auth: token,
      //     },
      //   }
      // );
      // setOrderData(getData?.data?.data?.viewOrder);
      // setTransactionIdData(getData?.data?.data?.paymentDetails);
      // setProductData(getData?.data?.data?.viewOrder?.products);
      // setCustomProductData(getData?.data?.data?.viewOrder?.custom_product_id);
      // setCustomProductSellerData(
      //   getData?.data?.data?.viewOrder?.allocate_to_seller_id
      // );
      // setUrl(getData?.data?.data?.viewOrder?.products?.[0]?.invoiceToCustomer);
    } catch (err) {
      toast.error(err?.message || 'Something went wrong');
    } finally {
      // setLoading(false);
    }
  };

  const orderDetail = AdminIndex.useSelector((state) => {
    return state.AdminSaga.orderDetail;
  });

  useEffect(() => {
    setOrderData(orderDetail?.viewOrder);
    setTransactionIdData(orderDetail?.paymentDetails);
    setProductData(orderDetail?.viewOrder?.products);
    setCustomProductData(orderDetail?.viewOrder?.custom_product_id);
    setCustomProductSellerData(
      orderDetail?.viewOrder?.allocate_to_seller_id
    );
    setUrl(orderDetail?.viewOrder?.products?.[0]?.invoiceToCustomer);
  }, [orderDetail]);

  const statusUpdate = async (
    productId,
    orderItemStatus,
    transactionId,
    paymentId,
    date,
    dateName,
    tIdName
  ) => {
    if (orderItemStatus == 2) {
      dateValidation(date, dateName);
    }
    if (orderItemStatus == 3) {
      tIdvalidation3(transactionId, tIdName);
    }
    let urlencoded = new URLSearchParams();
    urlencoded.append('id', id);
    urlencoded.append('productId', productId);
    urlencoded.append('orderItemStatus', orderItemStatus);
    if (orderItemStatus == 3) {
      urlencoded.append('transaction_id', transactionId);
    }
    if (orderItemStatus == 2) {
      urlencoded.append('deliveryDate', moment(date).format());
    }
    if (paymentId !== undefined && paymentId) {
      urlencoded.append('payment_id', paymentId);
    }

    if (orderItemStatus == 0 || orderItemStatus == 1) {
      try {
        const aboutUsResp = await dataService.post(
          Api.Admin.updateOrderstatus,
          urlencoded,
          {
            headers: {
              auth: token,
            },
          }
        );
        if (aboutUsResp.data.status == '1') {
          toast.success('Order Item Status updated successfully');
          history.push('/admin/paymentmanagement');
        }
      } catch (err) {
        toast.error(err?.message || 'Something went wrong');
      }
    } else if (
      orderItemStatus == 2 &&
      date !== null &&
      regExValidation.date(moment(date).format('DD/MM/YYYY'))
    ) {
      try {
        const aboutUsResp = await dataService.post(
          Api.Admin.updateOrderstatus,
          urlencoded,
          {
            headers: {
              auth: token,
            },
          }
        );
        if (aboutUsResp.data.status == '1') {
          toast.success('Order Item Status updated successfully');
          history.push('/admin/paymentmanagement');
        }
      } catch (err) {
        toast.error(err?.message || 'Something went wrong');
      }
    } else if (
      orderItemStatus == 3 &&
      transactionId !== null &&
      transactionId !== undefined
    ) {
      try {
        const aboutUsResp = await dataService.post(
          Api.Admin.updateOrderstatus,
          urlencoded,
          {
            headers: {
              auth: token,
            },
          }
        );
        if (aboutUsResp.data.status == '1') {
          toast.success('Order Item Status updated successfully');
          history.push('/admin/paymentmanagement');
        }
      } catch (err) {
        toast.error(err?.message || 'Something went wrong');
      }
    }
  };

  const customProductStatusUpdate = async (
    custom_product_id,
    orderItemStatus,
    transaction_id,
    paymentId,
    date
  ) => {
    if (orderItemStatus == 2) {
      dateValidation3(date);
    }
    if (orderItemStatus == 3) {
      tIdvalidation2(transaction_id);
    }
    let urlencoded = new URLSearchParams();
    urlencoded.append('id', id);
    urlencoded.append('custom_product_id', custom_product_id);
    urlencoded.append('orderItemStatus', orderItemStatus);
    if (orderItemStatus == 3) {
      urlencoded.append('transaction_id', transaction_id);
    }
    if (orderItemStatus == 2) {
      urlencoded.append('deliveryDate', moment(date).format());
    }
    if (paymentId !== undefined && paymentId) {
      urlencoded.append('paymentId', paymentId);
    }
    if (orderItemStatus == 0 || orderItemStatus == 1) {
      try {
        const aboutUsResp = await dataService.post(
          Api.Admin.updateOrderstatus,
          urlencoded,
          {
            headers: {
              auth: token,
            },
          }
        );
        if (aboutUsResp.data.status == '1') {
          toast.success('Order Item Status updated successfully');
          history.push('/admin/paymentmanagement');
        }
      } catch (err) {
        toast.error(err?.message || 'Something went wrong');
      }
    } else if (
      orderItemStatus == 2 &&
      date !== null &&
      date !== '' &&
      date !== ' ' &&
      regExValidation.date(moment(date).format('DD/MM/YYYY'))
    ) {
      try {
        const aboutUsResp = await dataService.post(
          Api.Admin.updateOrderstatus,
          urlencoded,
          {
            headers: {
              auth: token,
            },
          }
        );
        if (aboutUsResp.data.status == '1') {
          toast.success('Order Item Status updated successfully');
          history.push('/admin/paymentmanagement');
        }
      } catch (err) {
        toast.error(err?.message || 'Something went wrong');
      }
    } else if (
      orderItemStatus == 3 &&
      transaction_id !== null &&
      transaction_id !== undefined &&
      transaction_id !== '' &&
      transaction_id !== ' '
    ) {
      try {
        const aboutUsResp = await dataService.post(
          Api.Admin.updateOrderstatus,
          urlencoded,
          {
            headers: {
              auth: token,
            },
          }
        );
        if (aboutUsResp.data.status == '1') {
          toast.success('Order Item Status updated successfully');
          history.push('/admin/paymentmanagement');
        }
      } catch (err) {
        toast.error(err?.message || 'Something went wrong');
      }
    }
  };

  const ratingUpdate = async (
    seller_id,
    value,
    ratingId,
    product_id,
    index,
    admin_id
  ) => {
    let urlencoded = new URLSearchParams();
    if (ratingId?.length !== undefined) {
      urlencoded.append('id', ratingId);
    }
    urlencoded.append('order_id', id);
    if (admin_id?.length !== undefined) {
      urlencoded.append('admin_id', admin_id);
    } else {
      urlencoded.append('seller_id', seller_id);
    }
    urlencoded.append('rate_from_admin', value);
    urlencoded.append('product_id', product_id);

    try {
      const ratingRes = await dataService.post(
        Api.Admin.addUpdateRatingAdmin,
        urlencoded,
        {
          headers: {
            auth: token,
          },
        }
      );
      if (
        ratingRes.data.status == '1' &&
        ratingRes.data.message == 'Rating updated'
      ) {
        toast.success('Rating Updated successfully');
      } else if (
        ratingRes.data.status == '1' &&
        ratingRes.data.message == 'Rating added'
      ) {
        toast.success('Rating Added successfully');
      }
    } catch (err) {
      toast.error(err?.message || 'Something went wrong');
    }
  };
  //#endregion

  //#region RETURN JSX
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Container maxWidth="false" sx={{ mb: 5 }}>
          <Typography align="center" variant="h4" sx={{ fontWeight: 'bold' }}>
            Order Description
          </Typography>
          <Box
            sx={{
              border: '1px solid black',
              minHeight: '200px',
              borderBottom: 0,
              display: 'felx',
              alignSelf: 'flex-start',
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Typography align="center" sx={{ py: 3, fontWeight: 'bold' }}>
                Order Id -
              </Typography>
              <Typography align="center" sx={{ py: 3, ml: 1 }}>
                {orderData?.order_id}
              </Typography>
            </Box>
            <Box
              sx={{ display: 'flex', justifyContent: 'space-between', mt: 6 }}
            >
              <Typography sx={{ py: 2, ml: 2 }}>
                <Typography component="span" sx={{ fontWeight: 'bold', mr: 1 }}>
                  Order Date-
                </Typography>{' '}
                {moment(orderData?.createdAt).format('DD/MM/YYYY')}
              </Typography>
              <Typography sx={{ py: 2, mr: 2 }}>
                <Typography component="span" sx={{ fontWeight: 'bold', mr: 1 }}>
                  Order Status-
                </Typography>{' '}
                {orderData?.orderItemStatus == 'Close' ? 'Close' : 'Open'}
              </Typography>
            </Box>
          </Box>
          <Typography
            sx={{
              border: '1px solid black',
              height: '50px',
              borderBottom: 0,
              pt: 1.5,
              pl: 2,
              bgcolor: '#acacff',
            }}
          >
            Buyers Information
          </Typography>
          <Box
            sx={{
              border: '1px solid black',
              borderBottom: 0,
              py: 2,
            }}
          >
            <Grid container>
              <Grid item xs={12} lg={6}>
                <Stack spacing={2}>
                  <ListItem>Name - {orderData?.user_id?.name}</ListItem>
                  <ListItem>
                    Owner -{' '}
                    {orderData?.user_id?.owner_name
                      ? orderData?.user_id?.owner_name
                      : orderData?.user_id?.name}
                  </ListItem>
                  <ListItem>
                    Phone No - {orderData?.user_id?.contact_number}
                  </ListItem>
                  <ListItem>Email - {orderData?.user_id?.email}</ListItem>
                </Stack>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Stack spacing={2}>
                  <ListItem>
                    Company Address - {orderData?.user_id?.company_address}
                  </ListItem>
                  <ListItem>
                    Permanent Address - {orderData?.user_id?.permanent_address}
                  </ListItem>
                </Stack>
              </Grid>
            </Grid>
          </Box>
          {productData?.length ? (
            productData?.map((product, index) => {
              return (
                <Box>
                  <Typography
                    sx={{
                      border: '1px solid black',
                      py: 1.5,
                      pl: 2,
                      bgcolor: '#acacff',
                      borderBottom: 0,
                    }}
                  >
                    Product - {index + 1}
                  </Typography>
                  <Box
                    sx={{
                      border: '1px solid black',
                      borderBottom: 0,
                    }}
                  >
                    <Grid container>
                      <Grid item xs={12} lg={4}>
                        <Stack spacing={2}>
                          <ListItem>
                            Order Item Id- {product?.order_item_id}
                          </ListItem>
                          <ListItem>
                            Product Id - {product?.orderProductId}
                          </ListItem>
                          <ListItem
                            sx={{
                              marginLeft: 1,
                              width: '100%',
                              display: 'block',
                              padding: 1,
                            }}
                          >
                            <Accordion
                              expanded={expanded === 'panel1'}
                              onChange={handleChange('panel1')}
                              sx={{
                                marginLeft: 1,
                                marginBottom: 1,
                                border: 1,
                              }}
                            >
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                sx={{
                                  '& .MuiButtonBase-root-MuiAccordionSummary-root':
                                    {
                                      borderRadius: '10px !important',
                                    },
                                  borderBottom: expanded === 'panel1' ? 1 : 0,
                                }}
                              >
                                <Typography>Weight/Purity</Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                {product?.productId?.gold?.length ||
                                product?.productId?.silver?.length ? (
                                  <Grid container>
                                    <Grid item lg={4}></Grid>
                                    <Grid
                                      item
                                      lg={4}
                                      sx={{ textAlignLast: 'center' }}
                                    >
                                      Wt (gm)
                                    </Grid>
                                    <Grid
                                      item
                                      lg={4}
                                      sx={{ textAlignLast: 'center' }}
                                    >
                                      {' '}
                                      Purity (%)
                                    </Grid>
                                  </Grid>
                                ) : null}
                                {product?.productId?.gold?.length ? (
                                  <Grid container>
                                    {product?.productId?.gold?.map(
                                      (val, index) => {
                                        return (
                                          <>
                                            <Grid item lg={4}>
                                              {product?.productId?.gold.length >
                                              1
                                                ? `Gold${index + 1}`
                                                : 'Gold'}
                                            </Grid>
                                            <Grid
                                              item
                                              lg={4}
                                              sx={{ textAlignLast: 'center' }}
                                            >
                                              {ConvertIntoTwoDigits(
                                                val?.weight
                                              )}
                                            </Grid>
                                            <Grid
                                              item
                                              lg={4}
                                              sx={{ textAlignLast: 'center' }}
                                            >
                                              {' '}
                                              {ConvertIntoTwoDigits(
                                                val?.purity
                                              )}
                                            </Grid>
                                          </>
                                        );
                                      }
                                    )}
                                  </Grid>
                                ) : null}
                                {product?.productId?.silver?.length ? (
                                  <Grid container>
                                    {product?.productId?.silver?.map(
                                      (val, index) => {
                                        return (
                                          <>
                                            <Grid item lg={4}>
                                              {product?.productId?.silver
                                                .length > 1
                                                ? `Silver${index + 1}`
                                                : 'Silver'}
                                            </Grid>
                                            <Grid
                                              item
                                              lg={4}
                                              sx={{ textAlignLast: 'center' }}
                                            >
                                              {ConvertIntoTwoDigits(
                                                val?.weight
                                              )}
                                            </Grid>
                                            <Grid
                                              item
                                              lg={4}
                                              sx={{ textAlignLast: 'center' }}
                                            >
                                              {' '}
                                              {ConvertIntoTwoDigits(
                                                val?.purity
                                              )}
                                            </Grid>
                                          </>
                                        );
                                      }
                                    )}
                                  </Grid>
                                ) : null}
                                {product?.productId?.gemstone?.length ||
                                product?.productId?.diamond?.length ? (
                                  <>
                                    <Box sx={{ my: 4 }}></Box>
                                    <Grid container>
                                      <Grid item lg={4}></Grid>
                                      <Grid
                                        item
                                        lg={4}
                                        sx={{ textAlignLast: 'center' }}
                                      >
                                        Wt (gm)
                                      </Grid>
                                      <Grid
                                        item
                                        lg={4}
                                        sx={{ textAlignLast: 'center' }}
                                      >
                                        {' '}
                                        Purity (Carat)
                                      </Grid>
                                    </Grid>
                                  </>
                                ) : null}
                                {product?.productId?.gemstone?.length ? (
                                  <Grid container>
                                    {product?.productId?.gemstone?.map(
                                      (val, index) => {
                                        return (
                                          <>
                                            <Grid item lg={4}>
                                              {product?.productId?.gemstone
                                                .length > 1
                                                ? `Gemstone${index + 1}`
                                                : 'Gemstone'}
                                            </Grid>
                                            <Grid
                                              item
                                              lg={4}
                                              sx={{ textAlignLast: 'center' }}
                                            >
                                              {ConvertIntoTwoDigits(
                                                val?.total_weight
                                              )}
                                            </Grid>
                                            <Grid
                                              item
                                              lg={4}
                                              sx={{ textAlignLast: 'center' }}
                                            >
                                              {ConvertIntoTwoDigits(
                                                val?.purity
                                              )}
                                            </Grid>
                                          </>
                                        );
                                      }
                                    )}
                                  </Grid>
                                ) : null}
                                {product?.productId?.diamond?.length ? (
                                  <Grid container>
                                    {product?.productId?.diamond?.map(
                                      (val, index) => {
                                        return (
                                          <>
                                            <Grid item lg={4}>
                                              {product?.productId?.diamond
                                                .length > 1
                                                ? `Diamond${index + 1}`
                                                : 'Diamond'}
                                            </Grid>
                                            <Grid
                                              item
                                              lg={4}
                                              sx={{ textAlignLast: 'center' }}
                                            >
                                              {ConvertIntoTwoDigits(
                                                val?.total_weight
                                              )}
                                            </Grid>
                                            <Grid
                                              item
                                              lg={4}
                                              sx={{ textAlignLast: 'center' }}
                                            >
                                              {ConvertIntoTwoDigits(
                                                val?.purity
                                              )}
                                            </Grid>
                                          </>
                                        );
                                      }
                                    )}
                                  </Grid>
                                ) : null}
                              </AccordionDetails>
                            </Accordion>
                          </ListItem>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} lg={4}>
                        <Stack spacing={2}>
                          <ListItem>Product - {product?.productName}</ListItem>
                          <ListItem>
                            Qty - {ConvertIntoTwoDigits(product?.quantity)}
                          </ListItem>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} lg={4}>
                        <Stack spacing={2}>
                          <ListItem>
                            Price - {price(product?.price)}
                            <span>
                              <img src={Png.rupee} className="rupee-icon"></img>
                            </span>
                          </ListItem>
                          <ListItem>
                            Discount -{' '}
                            {product?.discount ? product?.discount : '00'}%
                          </ListItem>
                          <ListItem>
                            Taxes - {product?.taxes ? product?.taxes : '00'}%
                          </ListItem>
                          <ListItem>
                            Total Price -{' '}
                            {product?.quantity >= 2
                              ? price(product?.price * product?.quantity)
                              : price(product?.price)}
                            <span>
                              <img src={Png.rupee} className="rupee-icon"></img>
                            </span>
                          </ListItem>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box>
                    <Accordion
                      sx={{
                        border: '1px solid black',
                        borderBottom: 0,
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={{
                          '& .MuiButtonBase-root-MuiAccordionSummary-root': {
                            borderRadius: '10px !important',
                          },
                        }}
                      >
                        <Typography>Sellers Information</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container>
                          <Grid item xs={12} lg={6}>
                            <Stack spacing={2}>
                              <ListItem>
                                Name -{' '}
                                {product?.seller_id?.name ||
                                  product?.admin_id?.name}
                              </ListItem>
                              <ListItem>
                                Owner -{' '}
                                {(product?.seller_id?.ower_name
                                  ? product?.seller_id?.ower_name
                                  : product?.seller_id?.name) ||
                                  (product?.admin_id?.ower_name
                                    ? product?.admin_id?.ower_name
                                    : product?.admin_id?.name)}
                              </ListItem>
                              <ListItem>
                                Phone No -{' '}
                                {product?.seller_id?.contact_number || 'Admin'}
                              </ListItem>
                              <ListItem>
                                Email -{' '}
                                {product?.seller_id?.email ||
                                  product?.admin_id?.email}
                              </ListItem>
                            </Stack>
                          </Grid>
                          <Grid item xs={12} lg={6}>
                            <Stack spacing={2}>
                              <ListItem>
                                Company Address -{' '}
                                {product?.seller_id?.company_address || 'Admin'}
                              </ListItem>
                              <ListItem>
                                Permanent Address -{' '}
                                {product?.seller_id?.permanent_address ||
                                  'Admin'}
                              </ListItem>
                            </Stack>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                  
                </Box>
              );
            })
          ) : (
            <Box>
              <Typography
                sx={{
                  border: '1px solid black',
                  py: 1.5,
                  pl: 2,
                  bgcolor: '#acacff',
                  borderBottom: 0,
                }}
              >
                Product -
              </Typography>
              <Box
                sx={{
                  border: '1px solid black',
                  borderBottom: 0,
                }}
              >
                <Grid container>
                  <Grid item xs={12} lg={4}>
                    <Stack spacing={2}>
                      <ListItem>
                        Order Item Id- {orderData?.order_item_id}
                      </ListItem>
                      <ListItem>
                        Product Id - {customProductData?.product_id}
                      </ListItem>
                      <ListItem
                        sx={{
                          marginLeft: 1,
                          width: '100%',
                          display: 'block',
                          padding: 1,
                        }}
                      >
                        <Accordion
                          expanded={expanded === 'panel1'}
                          onChange={handleChange('panel1')}
                          sx={{
                            marginLeft: 1,
                            marginBottom: 1,
                            border: 1,
                          }}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            sx={{
                              '& .MuiButtonBase-root-MuiAccordionSummary-root':
                                {
                                  borderRadius: '10px !important',
                                },
                              borderBottom: expanded === 'panel1' ? 1 : 0,
                            }}
                          >
                            <Typography>Weight/Purity</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            {customProductData?.gold?.length ||
                            customProductData?.silver?.length ? (
                              <Grid container>
                                <Grid item lg={4}></Grid>
                                <Grid
                                  item
                                  lg={4}
                                  sx={{ textAlignLast: 'center' }}
                                >
                                  Wt (gm)
                                </Grid>
                                <Grid
                                  item
                                  lg={4}
                                  sx={{ textAlignLast: 'center' }}
                                >
                                  {' '}
                                  Purity (%)
                                </Grid>
                              </Grid>
                            ) : null}
                            {customProductData?.gold?.length ? (
                              <Grid container>
                                {customProductData?.gold?.map((val, index) => {
                                  return (
                                    <>
                                      <Grid item lg={4}>
                                        {customProductData?.gold.length > 1
                                          ? `Gold${index + 1}`
                                          : 'Gold'}
                                      </Grid>
                                      <Grid
                                        item
                                        lg={4}
                                        sx={{ textAlignLast: 'center' }}
                                      >
                                        {ConvertIntoTwoDigits(val?.weight)}
                                      </Grid>
                                      <Grid
                                        item
                                        lg={4}
                                        sx={{ textAlignLast: 'center' }}
                                      >
                                        {' '}
                                        {ConvertIntoTwoDigits(val?.purity)}
                                      </Grid>
                                    </>
                                  );
                                })}
                              </Grid>
                            ) : null}
                            {customProductData?.silver?.length ? (
                              <Grid container>
                                {customProductData?.silver?.map(
                                  (val, index) => {
                                    return (
                                      <>
                                        <Grid item lg={4}>
                                          {customProductData?.silver.length > 1
                                            ? `Silver${index + 1}`
                                            : 'Silver'}
                                        </Grid>
                                        <Grid
                                          item
                                          lg={4}
                                          sx={{ textAlignLast: 'center' }}
                                        >
                                          {ConvertIntoTwoDigits(val?.weight)}
                                        </Grid>
                                        <Grid
                                          item
                                          lg={4}
                                          sx={{ textAlignLast: 'center' }}
                                        >
                                          {' '}
                                          {ConvertIntoTwoDigits(val?.purity)}
                                        </Grid>
                                      </>
                                    );
                                  }
                                )}
                              </Grid>
                            ) : null}
                            {customProductData?.gemstone?.length ||
                            customProductData?.diamond?.length ? (
                              <>
                                <Box sx={{ my: 4 }}></Box>
                                <Grid container>
                                  <Grid item lg={4}></Grid>
                                  <Grid
                                    item
                                    lg={4}
                                    sx={{ textAlignLast: 'center' }}
                                  >
                                    Wt (gm)
                                  </Grid>
                                  <Grid
                                    item
                                    lg={4}
                                    sx={{ textAlignLast: 'center' }}
                                  >
                                    {' '}
                                    Purity (Carat)
                                  </Grid>
                                </Grid>
                              </>
                            ) : null}
                            {customProductData?.gemstone?.length ? (
                              <Grid container>
                                {customProductData?.gemstone?.map(
                                  (val, index) => {
                                    return (
                                      <>
                                        <Grid item lg={4}>
                                          {customProductData?.gemstone.length >
                                          1
                                            ? `Gemstone${index + 1}`
                                            : 'Gemstone'}
                                        </Grid>
                                        <Grid
                                          item
                                          lg={4}
                                          sx={{ textAlignLast: 'center' }}
                                        >
                                          {ConvertIntoTwoDigits(
                                            val?.total_weight
                                          )}
                                        </Grid>
                                        <Grid
                                          item
                                          lg={4}
                                          sx={{ textAlignLast: 'center' }}
                                        >
                                          {ConvertIntoTwoDigits(val?.purity)}
                                        </Grid>
                                      </>
                                    );
                                  }
                                )}
                              </Grid>
                            ) : null}
                            {customProductData?.diamond?.length ? (
                              <Grid container>
                                {customProductData?.diamond?.map(
                                  (val, index) => {
                                    return (
                                      <>
                                        <Grid item lg={4}>
                                          {customProductData?.diamond.length > 1
                                            ? `Diamond${index + 1}`
                                            : 'Diamond'}
                                        </Grid>
                                        <Grid
                                          item
                                          lg={4}
                                          sx={{ textAlignLast: 'center' }}
                                        >
                                          {ConvertIntoTwoDigits(
                                            val?.total_weight
                                          )}
                                        </Grid>
                                        <Grid
                                          item
                                          lg={4}
                                          sx={{ textAlignLast: 'center' }}
                                        >
                                          {ConvertIntoTwoDigits(val?.purity)}
                                        </Grid>
                                      </>
                                    );
                                  }
                                )}
                              </Grid>
                            ) : null}
                          </AccordionDetails>
                        </Accordion>
                      </ListItem>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Stack spacing={2}>
                      <ListItem>Product - {orderData?.productName}</ListItem>
                      <ListItem>Qty - {ConvertIntoTwoDigits(1)}</ListItem>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Stack spacing={2}>
                      <ListItem>
                        Price - {price(orderData?.totalAmount)}
                      </ListItem>
                      <ListItem>
                        Discount -{' '}
                        {customProductData?.discount
                          ? customProductData?.discount
                          : '00'}
                        %
                      </ListItem>
                      <ListItem>
                        Taxes -{' '}
                        {customProductData?.taxes
                          ? customProductData?.taxes
                          : '00'}
                        %
                      </ListItem>
                      <ListItem>
                        Total Price - {price(orderData?.totalAmount)}
                      </ListItem>
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
              <Box>
                <Accordion
                  sx={{
                    border: '1px solid black',
                    borderBottom: 0,
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{
                      '& .MuiButtonBase-root-MuiAccordionSummary-root': {
                        borderRadius: '10px !important',
                      },
                    }}
                  >
                    <Typography>Sellers Information</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container>
                      <Grid item xs={12} lg={6}>
                        <Stack spacing={2}>
                          <ListItem>Name - {productSellerData?.name}</ListItem>
                          <ListItem>
                            Owner -{' '}
                            {productSellerData?.ower_name
                              ? productSellerData?.ower_name
                              : productSellerData?.name}
                          </ListItem>
                          <ListItem>
                            Phone No - {productSellerData?.contact_number}
                          </ListItem>
                          <ListItem>
                            Email - {productSellerData?.email}
                          </ListItem>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <Stack spacing={2}>
                          <ListItem>
                            Company Address -{' '}
                            {productSellerData?.company_address}
                          </ListItem>
                          <ListItem>
                            Permanent Address -{' '}
                            {productSellerData?.permanent_address}
                          </ListItem>
                        </Stack>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Box>
              <Box
                sx={{
                  border: '1px solid black',
                  py: 3,
                }}
              >
                <Grid container>
                  <Grid item xs={12} lg={6}>
                    <Stack spacing={2}>
                      <ListItem>
                        <Typography sx={{ fontWeight: 'bold' }}>
                          Order Item Status -
                        </Typography>
                        <FormControl sx={{ width: '200px', ml: 1 }}>
                          <Select
                            disabled={
                              customOrderItemStatus == '3' ||
                              customOrderItemStatus == '4'
                                ? true
                                : false
                            }
                            value={customOrderItemStatus}
                            onChange={(e) => {
                              if (
                                customOrderItemStatus == '1' ||
                                customOrderItemStatus == '2'
                              ) {
                                if (
                                  window.confirm(
                                    'Are you sure you want to update order item status?'
                                  )
                                ) {
                                  customProductStatusUpdate(
                                    customProductData?._id,
                                    e.target.value,
                                    customTransactionId,
                                    orderData?.paymentId,
                                    customStartDate
                                  );
                                  handleChangeCustomOrderItemStatus(
                                    e,
                                    customStartDate,
                                    customTransactionId
                                  );
                                }
                              } else {
                                toast.error('Yet to Dispatch from seller');
                              }
                            }}
                          >
                            <MenuItem value={'0'} disabled>
                              Order Placed
                            </MenuItem>
                            <MenuItem value={'1'} disabled>
                              Dispatched
                            </MenuItem>
                            <MenuItem value={'2'}>Delivered</MenuItem>
                            <MenuItem value={'3'}>Payment Completed</MenuItem>
                            <MenuItem value={'4'} disabled>
                              Order Canceled
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </ListItem>
                      <ListItem>
                        <Typography sx={{ fontWeight: 'bold' }}>
                          Transaction Id -{' '}
                        </Typography>
                        <TextField
                          disabled={
                            customOrderItemStatus == '2'
                            //  ||
                            // customOrderItemStatus == '4'
                              ? false
                              : true
                          }
                          value={customTransactionId}
                          onChange={(e) => {
                            handleChangeCustomTransactionId(e);
                            tIdvalidation1(e);
                          }}
                          id="outlined-basic"
                          variant="outlined"
                          sx={{
                            ml: 1,
                            '& .MuiFormHelperText-root': { ml: 0, mt: '5px' },
                          }}
                          helperText={
                            <ErrorMsg msg={customTransactionIdError} />
                          }
                        />
                      </ListItem>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Stack spacing={2}>
                      <ListItem className="delivery-list-item">
                        <Typography
                          sx={{ fontWeight: 'bold' }}
                          className="delivery-text"
                        >
                          Delivery Date -
                        </Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DesktopDatePicker
                            disabled={
                              customOrderItemStatus == '2' ||
                              customOrderItemStatus == '3' ||
                              customOrderItemStatus == '4'
                                ? true
                                : false
                            }
                            // label="Delivery Date"
                            sx={{
                              '& .MuiFormHelperText-root': { ml: 0, mt: '5px' },
                            }}
                            inputFormat="DD/MM/YYYY"
                            value={moment(customStartDate)}
                            onChange={(e) => {
                              handleChangeCustomDate(e);
                              dateValidation2(e);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                sx={{
                                  width: '100%',
                                  '& .MuiFormHelperText-root': {
                                    ml: 0,
                                    mt: '5px',
                                  },
                                }}
                                error
                                helperText={customStartDateError}
                              />
                            )}
                            className="delivery-date-picker"
                          />
                        </LocalizationProvider>
                      </ListItem>
                      <ListItem
                        className="delivery-list-item"
                        sx={{
                          display: orderData?.invoiceToCustomer
                            ? 'flex'
                            : 'none',
                        }}
                      >
                        <Typography
                          sx={{ fontWeight: 'bold' }}
                          className="delivery-text"
                        >
                          Invoice to Customer -
                        </Typography>
                        <a
                          key={url}
                          href={orderData?.invoiceToCustomer}
                          download="file"
                        >
                          <Tooltip title="Tap to Download">
                            <Img
                              sx={{
                                my: 2,
                                height: '30px',
                                width: '30px',
                              }}
                              src={orderData?.invoiceToCustomer}
                              alt="no image"
                              className="guidelines-image"
                            />
                          </Tooltip>
                        </a>
                      </ListItem>
                    </Stack>
                  </Grid>
                </Grid>
                <Typography align="center" sx={{ pt: 2, fontWeight: 'bold' }}>
                  Tracking Id - 00000000
                </Typography>
              </Box>
            </Box>
          )}
          <Box
                    sx={{
                      border: '1px solid black',
                      py: 3,
                    }}
                  >
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '200px',
            }}
          >
            <Button
              disabled={
                details.approveStatus == '1' ||
                (details.approveStatus == '2' && true)
              }
              variant="contained"
              sx={{
                borderRadius: 5,
                textTransform: 'none',
                width: '90px',
                bgcolor: 'green',
              }}
              onClick={(e) => {
                if (
                  window.confirm('Are you sure you want to Approve this Order?')
                ) {
                  accept(e);
                }
              }}
            >
              Approve
            </Button>
            <Button
              disabled={details.approveStatus == '2' && true}
              variant="contained"
              sx={{
                borderRadius: 5,
                textTransform: 'none',
                width: '90px',
                bgcolor: 'red',
              }}
              onClick={(e) => {
                if (
                  window.confirm('Are you sure you want to Decline this Order?')
                ) {
                  handleOpen(e);
                }
              }}
            >
             Decline
            </Button>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <form onSubmit={handleSubmit(reject)}>
                <Box sx={style}>
                  <Typography>Rejection reason</Typography>
                  <Box className="text-area-control-main">
                    <TextareaAutosize
                      autoComplete="off"
                      sx={{
                        mt: 2,
                        width: { lg: '300px', xs: '150px' },
                      }}
                      size="small"
                      fullWidth
                      // id="rejectReason"
                      className="form-control border-none text-area-control1 text-area-control-admin"
                      name="rejectReason"
                      type="text"
                      {...register('rejectReason', {
                        required: (
                          <TextAreaErrorMsg msg="Please enter Rejection reason" />
                        ),
                        minLength: {
                          value: minRejectReasonLength,
                          message: (
                            <TextAreaErrorMsg
                              msg={`Minimum ${minRejectReasonLength} Characters Allowed`}
                            />
                          ),
                        },
                        maxLength: {
                          value: rejectReasonLength,
                          message: (
                            <TextAreaErrorMsg
                              msg={`Maximum ${rejectReasonLength} Characters Allowed`}
                            />
                          ),
                        },
                        pattern: {
                          value: NO_WHITE_SPACE_BEGINNING_END,
                          message: (
                            <TextAreaErrorMsg msg="Whitespace is not allowed at beginning or end" />
                          ),
                        },
                      })}
                    />
                    <ErrorMessage errors={errors} name="rejectReason" />
                  </Box>
                  <Button
                    sx={{ mt: 2, textTransform: 'none' }}
                    variant="contained"
                    type="submit"
                  >
                    Submit
                  </Button>
                </Box>
              </form>
            </Modal>
          </Box>
        </Box>
                  </Box>
        </Container>
      )}
    </>
  );
};

export default PendingOrderDescription;
//#endregion
