//#region ALL IMPORTS
import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, TextField } from '@mui/material';
import { useHistory, useParams } from 'react-router-dom';
import { Api } from '../../../../config/api';
import dataService from '../../../../config/dataService';
import { toast } from 'react-toastify';
import ContainedButton from '../../../../component/customComponents/buttons/ContainedButton';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { ErrorMsg } from '../../../../component/customComponents/errorMsg/ErrorMsg';
import {
  minMaterialLength,
  materialLength,
  MATERIAL_REGEX,
} from '../../../../component/customComponents/validation/Validation';
//#endregion

import AdminIndex from '../../AdminIndex';
//#region ALL HOOKS AND VARIABALS

const { getMaterialDetail, editMaterial } = AdminIndex.AdminSagaActions;
const UpdateMaterial = () => {
  const { id } = useParams();
  let token = localStorage.getItem('auth');
  const dispatch = AdminIndex.useDispatch();
  const history = useHistory();
  const navigate = useHistory();
  const [getData, setGetData] = useState('');

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  //#endregion

  //#region ALL EVENT HANDLERS AND  FUCTIONS
  const getMaterialData = async () => {
    let urlencoded = new URLSearchParams();
    try {
      urlencoded.append('id', id);
      dispatch(getMaterialDetail(token, urlencoded));
      // const Data = await dataService.post(Api.Admin.singleMaterial, urlencoded);
      // setGetData(Data.data.data);
    } catch (err) {
      toast.error(err?.message || 'Something went wrong');
    }
  };

  const materialDetail = AdminIndex.useSelector((state) => {
    return state.AdminSaga.materialDetail;
  });

  useEffect(() => {
    setGetData(materialDetail);
  }, [materialDetail]);

  useEffect(() => {
    getMaterialData();
  }, []);

  useEffect(() => {
    setValue('material', getData?.material);
  }, [getData]);

  const handleSave = async (data) => {
    try {
      let urlencoded = new URLSearchParams();
      urlencoded.append('id', id);
      urlencoded.append('material', data.material?.trim());

      dispatch(editMaterial(token, urlencoded, navigate));
      // const userResp = await dataService.post(
      //   Api.Admin.updateMaterial,
      //   urlencoded,
      //   {
      //     headers: {
      //       auth: token,
      //     },
      //   }
      // );
      // if (userResp.data.status === 1) {
      //   toast.success('Material updated successfully');
      //   history.push('/admin/materialmanagement');
      // }
    } catch (err) {
      toast.error(err?.message || 'Something went wrong');
    }
  };
  //#endregion

  //#region RETURN JSX
  return (
    <>
      <form onSubmit={handleSubmit(handleSave)}>
        <Container maxWidth="false">
          <Box sx={{ border: 1, mx: { lg: 35, sm: 5 }, mt: 15, py: 5 }}>
            <Typography sx={{ my: 2 }} align="center" variant="h4">
              Update Material
            </Typography>
            <Typography sx={{ my: 2 }} align="center" variant="h4">
              <TextField
                autoComplete="off"
                sx={{
                  mt: 2,
                  width: { lg: '300px', xs: '150px' },
                  '& .MuiFormHelperText-root': { ml: 0, mt: '5px' },
                }}
                size="small"
                fullWidth
                className="form-control border-none"
                name="material"
                type="text"
                {...register('material', {
                  required: <ErrorMsg msg="Please enter Material" />,
                  minLength: {
                    value: minMaterialLength,
                    message: (
                      <ErrorMsg
                        msg={`Minimum ${minMaterialLength} Characters Allowed`}
                      />
                    ),
                  },
                  maxLength: {
                    value: materialLength,
                    message: (
                      <ErrorMsg
                        msg={`Maximum ${materialLength} Characters Allowed`}
                      />
                    ),
                  },
                  pattern: {
                    value: MATERIAL_REGEX,
                    message: <ErrorMsg msg="Please enter valid Material" />,
                  },
                })}
                helperText={<ErrorMessage errors={errors} name="material" />}
              />
            </Typography>
            <Typography align="center" variant="h4">
              <ContainedButton name="Update" type="submit" />
            </Typography>
          </Box>
        </Container>
      </form>
    </>
  );
};

export default UpdateMaterial;
//#endregion
