import React, { useEffect, useState, useRef } from 'react'
import UserIndex from '../../UserIndex'
import UserSagaActions from '../../../../redux/Usersaga/action'
// import { Grid } from "@mui/material";
// import Box from "@mui/material/Box";
// import Button from "@mui/material/Button";
// import Typography from "@mui/material/Typography";
import { Modal } from '@mui/material'
import Backdrop from '@mui/material/Backdrop'
import Fade from '@mui/material/Fade'
import TextareaAutosize from '@mui/material/TextareaAutosize'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}

const {
  ViewOrderSaga,
  AddUpdateRatingSaga,
  SingleRatingSaga,
  GetAllRatingSaga,
  ReturnOrderSaga,
  UpdateDeliveryStausSaga,
} = UserSagaActions

const Accordion = UserIndex.styled((props) => (
  <UserIndex.MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}))

const AccordionSummary = UserIndex.styled((props) => (
  <UserIndex.MuiAccordionSummary
    expandIcon={
      <UserIndex.ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}))

const AccordionDetails = UserIndex.styled(UserIndex.MuiAccordionDetails)(
  ({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
  }),
)

const CustomeOrderPlaceView = () => {
  const [expanded, setExpanded] = useState('panel1')
  const [rate, setRate] = useState()
  const [key, setKey] = useState([])
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const [viewOrder, setViewOrder] = useState([])
  const [newData, setNewData] = useState([])
  const [sellerId, setSellerId] = useState('')
  const [orderId, setOrderId] = useState('')
  const [allRate, setAllRate] = useState([])
  const [singleRate, setSingleRate] = useState([])
  const [ProductDetails, setProductDetails] = useState([])

  const [reasonForReturn, setReasonForReturn] = useState('')
  const [source, setSource] = useState()
  const [video, setVideo] = useState()
  const [productId, setProductId] = useState()

  const [dispatchStatusError, setDispatchStatusError] = useState('')
  const [dispatchProductIdError, setDispatchProductIdError] = useState('')

  const inputRef = useRef()

  const [isSelected, setIsSelected] = useState(false)

  const [defaultDispatchStatus, setDefaultDispatchStatus] = useState('')

  const [productIdData, setProductIdData] = useState('')
  const dispatch = UserIndex.useDispatch()
  const history = UserIndex.useHistory()
  const params = UserIndex.useParams()
  const navigate = UserIndex.useHistory()
  const loding = UserIndex.useSelector((state) => {
    return state.Usersaga.loading
  })
  const Token = UserIndex.useSelector((state) => {
    return state.Usersaga.Token
  })
  const ViewOrderData = UserIndex.useSelector((state) => {
    return state.Usersaga.ViewOrderData
  })

  const AddUpdateRatingData = UserIndex.useSelector((state) => {
    return state.Usersaga.AddUpdateRatingData
  })
  const SingleRatingData = UserIndex.useSelector((state) => {
    return state.Usersaga.SingleRatingData
  })
  const ReturnOrderData = UserIndex.useSelector((state) => {
    return state.Usersaga.ReturnOrderData
  })

  const GetAllRatingData = UserIndex.useSelector((state) => {
    return state.Usersaga.GetAllRatingData
  })

  const getViewOrderData = async () => {
    let urlencoded = new URLSearchParams()
    urlencoded.append('id', params.getorder_id)
    dispatch(ViewOrderSaga(urlencoded, navigate, Token))
  }

  const GetAllRating = async () => {
    let urlencoded = new URLSearchParams()
    dispatch(GetAllRatingSaga(urlencoded, navigate, Token))
    setAllRate(GetAllRatingData)
  }

  useEffect(() => {
    setAllRate(GetAllRatingData)
  }, [GetAllRatingData])

  useEffect(() => {
    let result = allRate?.filter(
      (allRate) => allRate.order_id?._id === params.getorder_id,
    )
    getSingleRating(result)
  }, [allRate])

  const getSingleRating = async (result) => {
    let urlencoded = new URLSearchParams()
    result?.map((item) => {
      urlencoded.append('id', item.order_id._id)
    })
    dispatch(SingleRatingSaga(urlencoded, navigate))
  }

  useEffect(() => {
    GetAllRating()
    getViewOrderData()
    getSingleRating()
  }, [])

  useEffect(() => {
    setSingleRate(SingleRatingData)
  }, [SingleRatingData])

  // useEffect((data) => {
  //   newData
  //   setRate(singleRate?.rate_from_user);
  // }, [singleRate]);

  useEffect(() => {
    setViewOrder(ViewOrderData)
    let arr = []
    let finalArray = []
    ViewOrderData?.products?.map((data, i, array) => {
      let obj = {}
      obj = { ...data }
      if (arr.includes(data.order_item_id) === false) {
        arr.push(data.order_item_id)
        finalArray.push({ index: data.order_item_id, data: [data] })
      } else {
        const myIndex = finalArray.findIndex(
          (el) => el.index === data.order_item_id,
        )
        finalArray[myIndex].data.push(data)
      }
    })
    setNewData(finalArray)
  }, [ViewOrderData])

  const setProduct = () => {
    newData[0]?.data.map((item, index) => {
      setProductDetails(item.productId)
      setRate(item?.rate?.[0]?.rate_from_user)
    })
  }

  useEffect(() => {
    setProduct()
  }, [newData])

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  const myOrderhandleChange = (event, data, array) => {
    const { name, value } = event.target
    setRate((prevState) => ({
      ...prevState,
      [`key-${event.target.value.split('-')[0]}`]: value,
    }))
    let urlencoded = new URLSearchParams()
    {
      data.admin_id
        ? urlencoded.append('admin_id', data.admin_id)
        : urlencoded.append('seller_id', data.seller_id)
    }
    urlencoded.append('rate_from_user', event.target.value.split('-')[1])
    urlencoded.append('order_id', params.getorder_id)
    // urlencoded.append("seller_id", data.seller_id?data.seller_id:null);
    urlencoded.append('product_id', data.orderProductId)
    // urlencoded.append("product_id", data.productId?data.productId:null);
    dispatch(AddUpdateRatingSaga(urlencoded, navigate, Token))
  }

  const handleChangeProductDescription = (e) => {
    setReasonForReturn(e.target.value)
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0]
    const url = URL.createObjectURL(file)
    setSource(url)
    setVideo(file)
  }

  const getReturnOrder = async () => {
    let formdata = new FormData()
    formdata.append('id', params.getorder_id)
    formdata.append('productId', productId)
    formdata.append('return_reason', reasonForReturn)
    formdata.append('images', video)
    formdata.append('return_status', '0')
    dispatch(ReturnOrderSaga(formdata, navigate, Token))
    handleClose()
  }

  useEffect(() => {
    setProductIdData(newData?.[0]?.data?.[0].productId)
  }, [newData?.[0]?.data?.[0].productId])

  const goldFields = () => {
    return (
      <>
        {productIdData?.gold ? (
          <UserIndex.Box className="order-data-main">
            <UserIndex.Box className="order-inner-main">
              <UserIndex.Typography
                component="h3"
                variant="h3"
                className="order-sub-title"
              >
                Gold Wt(gm) -
              </UserIndex.Typography>
              <UserIndex.Typography
                component="p"
                variant="p"
                className="order-sub-data"
              >
                {productIdData?.gold.weight}
              </UserIndex.Typography>
            </UserIndex.Box>
            <UserIndex.Box className="order-inner-main">
              <UserIndex.Typography
                component="h3"
                variant="h3"
                className="order-sub-title"
              >
                Gold Purity -
              </UserIndex.Typography>
              <UserIndex.Typography
                component="p"
                variant="p"
                className="order-sub-data"
              >
                {productIdData?.gold.purity}
              </UserIndex.Typography>
            </UserIndex.Box>
          </UserIndex.Box>
        ) : (
          ''
        )}
      </>
    )
  }

  const silverFields = () => {
    return (
      <>
        {productIdData?.silver ? (
          <UserIndex.Box className="order-data-main">
            <UserIndex.Box className="order-inner-main">
              <UserIndex.Typography
                component="h3"
                variant="h3"
                className="order-sub-title"
              >
                Silver Wt(gm) -
              </UserIndex.Typography>
              <UserIndex.Typography
                component="p"
                variant="p"
                className="order-sub-data"
              >
                {productIdData?.silver.weight}
              </UserIndex.Typography>
            </UserIndex.Box>
            <UserIndex.Box className="order-inner-main">
              <UserIndex.Typography
                component="h3"
                variant="h3"
                className="order-sub-title"
              >
                Silver Purity -
              </UserIndex.Typography>
              <UserIndex.Typography
                component="p"
                variant="p"
                className="order-sub-data"
              >
                {productIdData?.silver.purity}
              </UserIndex.Typography>
            </UserIndex.Box>
          </UserIndex.Box>
        ) : (
          ''
        )}
      </>
    )
  }

  const gemstoneFields = () => {
    return (
      <>
        {productIdData?.gemstone?.length
          ? productIdData?.gemstone?.map((gems) => {
              return (
                <UserIndex.Box className="order-data-main">
                  <UserIndex.Box className="order-inner-main">
                    <UserIndex.Typography
                      component="h3"
                      variant="h3"
                      className="order-sub-title"
                    >
                      Gemstone Wt(gm) -
                    </UserIndex.Typography>
                    <UserIndex.Typography
                      component="p"
                      variant="p"
                      className="order-sub-data"
                    >
                      {gems.total_weight}
                    </UserIndex.Typography>
                  </UserIndex.Box>
                  <UserIndex.Box className="order-inner-main">
                    <UserIndex.Typography
                      component="h3"
                      variant="h3"
                      className="order-sub-title"
                    >
                      Gemstone Purity -
                    </UserIndex.Typography>
                    <UserIndex.Typography
                      component="p"
                      variant="p"
                      className="order-sub-data"
                    >
                      {gems.purity}
                    </UserIndex.Typography>
                  </UserIndex.Box>
                </UserIndex.Box>
              )
            })
          : ''}
      </>
    )
  }

  const diamondFields = () => {
    return (
      <>
        {productIdData?.diamond?.length
          ? productIdData?.diamond?.map((diam) => {
              return (
                <UserIndex.Box className="order-data-main">
                  <UserIndex.Box className="order-inner-main">
                    <UserIndex.Typography
                      component="h3"
                      variant="h3"
                      className="order-sub-title"
                    >
                      Diamond Wt(gm) -
                    </UserIndex.Typography>
                    <UserIndex.Typography
                      component="p"
                      variant="p"
                      className="order-sub-data"
                    >
                      {diam.total_weight}
                    </UserIndex.Typography>
                  </UserIndex.Box>
                  <UserIndex.Box className="order-inner-main">
                    <UserIndex.Typography
                      component="h3"
                      variant="h3"
                      className="order-sub-title"
                    >
                      Diamond Purity -
                    </UserIndex.Typography>
                    <UserIndex.Typography
                      component="p"
                      variant="p"
                      className="order-sub-data"
                    >
                      {diam.purity}
                    </UserIndex.Typography>
                  </UserIndex.Box>
                </UserIndex.Box>
              )
            })
          : ''}
      </>
    )
  }

  const renderFieldMaterialWise = () => {
    switch (true) {
      case productIdData?.gold && productIdData?.diamond.length > 0:
        return (
          <>
            {goldFields()}
            {diamondFields()}
          </>
        )
      case productIdData?.gold && productIdData?.gemstone.length > 0:
        return (
          <>
            {goldFields()}
            {gemstoneFields()}
          </>
        )
      case productIdData?.silver && productIdData?.diamond.length > 0:
        return (
          <>
            {silverFields()}
            {diamondFields()}
          </>
        )
      case productIdData?.silver && productIdData?.gemstone.length > 0:
        return (
          <>
            {silverFields()}
            {gemstoneFields()}
          </>
        )
      case productIdData?.silver && productIdData?.gold:
        return (
          <>
            {silverFields()}
            {goldFields()}
          </>
        )
      case productIdData?.gemstone.length > 0 &&
        productIdData?.diamond.length > 0:
        return (
          <>
            {gemstoneFields()}
            {diamondFields()}
          </>
        )
      case productIdData?.gold &&
        productIdData?.silver &&
        productIdData?.diamond.length > 0:
        return (
          <>
            {silverFields()}
            {goldFields()}
            {diamondFields()}
          </>
        )
      case productIdData?.silver &&
        productIdData?.gold &&
        productIdData?.gemstone.length > 0:
        return (
          <>
            {silverFields()}
            {goldFields()}
            {gemstoneFields()}
          </>
        )
      case productIdData?.gold &&
        productIdData?.diamond.length > 0 &&
        productIdData?.gemstone.length > 0:
        return (
          <>
            {goldFields()}
            {diamondFields()}
            {gemstoneFields()}
          </>
        )
      case productIdData?.silver &&
        productIdData?.diamond.length > 0 &&
        productIdData?.gemstone.length > 0:
        return (
          <>
            {silverFields()}
            {gemstoneFields()}
            {diamondFields()}
          </>
        )
      case productIdData?.gold &&
        productIdData?.silver &&
        productIdData?.gemstone.length > 0 &&
        productIdData?.diamond.length > 0:
        return (
          <>
            {goldFields()}
            {silverFields()}
            {gemstoneFields()}
            {diamondFields()}
          </>
        )
      case productIdData?.gold && productIdData?.materialIdArray.length === 1:
        return goldFields()
      case productIdData?.silver && productIdData?.materialIdArray.length === 1:
        return silverFields()
      case productIdData?.diamond.length > 0:
        return diamondFields()
      case productIdData?.diamond.length > 0:
        return gemstoneFields()
      default:
        return ''
    }
  }

  return (
    <div>
      <UserIndex.Header />
      <UserIndex.Box className="body-bg">
        <UserIndex.Box className="order-place-sec">
          <UserIndex.Box className="container">
            <UserIndex.Box className="row">
              <UserIndex.Box className="addinfo-text-box pg-main-title-box">
                <UserIndex.Typography
                  variant="h2"
                  className="addinfo-text pg-main-title"
                >
                  My Orders
                </UserIndex.Typography>
              </UserIndex.Box>
            </UserIndex.Box>
            <UserIndex.Box className="row">
              <UserIndex.Box className="order-place-content-main">
                <UserIndex.Box className="order-content-box">
                  <UserIndex.Box className="order-content-left">
                    <UserIndex.Box className="order-data-main">
                      <UserIndex.Box className="order-inner-main">
                        <UserIndex.Typography
                          component="h3"
                          variant="h3"
                          className="order-sub-title"
                        >
                          Order Id -
                        </UserIndex.Typography>
                        <UserIndex.Typography
                          component="p"
                          variant="p"
                          className="order-sub-data"
                        >
                          {viewOrder?.order_id}
                        </UserIndex.Typography>
                      </UserIndex.Box>
                      <UserIndex.Box className="order-inner-main">
                        <UserIndex.Typography
                          component="h3"
                          variant="h3"
                          className="order-sub-title"
                        >
                          Order Date -
                        </UserIndex.Typography>
                        <UserIndex.Typography
                          component="p"
                          variant="p"
                          className="order-sub-data"
                        >
                          {new Date(viewOrder?.createdAt).toLocaleDateString()}
                        </UserIndex.Typography>
                      </UserIndex.Box>
                      <UserIndex.Box className="order-inner-main">
                        <UserIndex.Typography
                          component="h3"
                          variant="h3"
                          className="order-sub-title"
                        >
                          Order Item Status -
                        </UserIndex.Typography>
                        <UserIndex.Typography
                          component="p"
                          variant="p"
                          className="order-sub-data"
                        >
                          {viewOrder?.orderItemStatus == '0'
                            ? 'Order Placed'
                            : viewOrder?.orderItemStatus == '1'
                            ? 'Delivery In Process'
                            : viewOrder?.orderItemStatus == '2'
                            ? 'Delivered'
                            : viewOrder?.orderItemStatus == '3'
                            ? 'Payment Completed'
                            : ''}
                        </UserIndex.Typography>
                      </UserIndex.Box>
                    </UserIndex.Box>
                  </UserIndex.Box>
                  <UserIndex.Box className="order-content-right">
                    <UserIndex.Box className="order-data-main">
                      <UserIndex.Box className="order-inner-main">
                        <UserIndex.Typography
                          component="h3"
                          variant="h3"
                          className="order-sub-title"
                        >
                          Total Amount - {viewOrder?.totalAmount}
                        </UserIndex.Typography>
                        <UserIndex.Typography
                          component="p"
                          variant="p"
                          className="order-sub-data"
                        ></UserIndex.Typography>
                      </UserIndex.Box>
                      <UserIndex.Box className="order-inner-main">
                        <UserIndex.Typography
                          component="h3"
                          variant="h3"
                          className="order-sub-title"
                        >
                          Payment Method -
                        </UserIndex.Typography>
                        <UserIndex.Typography
                          component="p"
                          variant="p"
                          className="order-sub-data"
                        >
                          Paid by {viewOrder?.paymentMethod}
                        </UserIndex.Typography>
                      </UserIndex.Box>
                    </UserIndex.Box>
                  </UserIndex.Box>
                </UserIndex.Box>
                <UserIndex.Box className="deliver-address-box">
                  <UserIndex.Typography
                    variant="h3"
                    component="h3"
                    className="deliver-address-text"
                  >
                    Delivery Address
                  </UserIndex.Typography>
                </UserIndex.Box>
                <UserIndex.Box className="deliver-address-para-box">
                  <UserIndex.Typography
                    variant="p"
                    component="p"
                    className="deliver-address-para"
                  >
                    {viewOrder?.deliveryAddress}
                  </UserIndex.Typography>
                </UserIndex.Box>
                <UserIndex.Box className="order-place-accordian">
                  {newData?.length
                    ? newData?.map((data, index, array) => {
                        return (
                          <Accordion
                            // expanded={expanded === "panel1"}
                            // onChange={handleChange("panel1")}
                            className="po-accordian"
                          >
                            <AccordionSummary
                              aria-controls="panel1d-content"
                              id="panel1d-header"
                              className="po-acc-summary"
                            >
                              <UserIndex.Box className="po-summary-title-box">
                                <UserIndex.Typography className="po-sub-title">
                                  Order Item Id - {data.index}
                                </UserIndex.Typography>
                                <UserIndex.Box className="order-btn-end-box">
                                  <UserIndex.Box className="or-track-btn-box  primary-btn-box">
                                    <UserIndex.PrimaryButton
                                      // onClick={handleOpen}
                                      btnclass="primary-btn or-track-btn"
                                      buttonvalue="Track"
                                    />
                                  </UserIndex.Box>
                                  {/* <UserIndex.Box className="po-return-btn-box primary-btn-box">
                                  <UserIndex.PrimaryButton
                                      // onClick={handleOpen}
                                      btnclass="primary-btn po-return-btn"
                                      buttonvalue="Return"
                                    />
                                </UserIndex.Box> */}
                                </UserIndex.Box>
                              </UserIndex.Box>
                            </AccordionSummary>
                            <UserIndex.Box>
                              <Modal
                                className="order-modal-sec"
                                aria-labelledby="transition-modal-title"
                                aria-describedby="transition-modal-description"
                                open={open}
                                onClose={handleClose}
                                closeAfterTransition
                                BackdropComponent={Backdrop}
                                BackdropProps={{
                                  timeout: 500,
                                }}
                              >
                                <Fade in={open}>
                                  <UserIndex.Box sx={style}>
                                    <UserIndex.Box className="input-box">
                                      <UserIndex.Typography
                                        className="input-lable"
                                        component="p"
                                        variant="p"
                                      >
                                        Reason For Return
                                      </UserIndex.Typography>
                                      <UserIndex.Box className="text-return">
                                        <TextareaAutosize
                                          aria-label="empty textarea"
                                          className="textarea-main-dis"
                                          onChange={(e) => {
                                            handleChangeProductDescription(e)
                                          }}
                                        />
                                      </UserIndex.Box>
                                    </UserIndex.Box>
                                    <UserIndex.Box className="input-box">
                                      <UserIndex.Typography
                                        className="input-lable"
                                        component="p"
                                        variant="p"
                                      >
                                        Upload Video
                                      </UserIndex.Typography>
                                      <UserIndex.Box className="upload-reason-video">
                                        <UserIndex.Box className="add-product-left">
                                          <div className="VideoInput">
                                            <input
                                              ref={inputRef}
                                              className="VideoInput_input"
                                              type="file"
                                              onChange={handleFileChange}
                                              accept=".mov,.mp4"
                                            />
                                            {source && (
                                              <video
                                                mt={2}
                                                className="VideoInput_video return_video_file"
                                                width="100%"
                                                height="200px"
                                                controls
                                                src={source}
                                              />
                                            )}
                                          </div>
                                        </UserIndex.Box>
                                      </UserIndex.Box>
                                    </UserIndex.Box>
                                    <UserIndex.Box className="modal-btn-box">
                                      <UserIndex.PrimaryButton
                                        onClick={() => getReturnOrder()}
                                        btnclass="modal-btn"
                                        buttonvalue="Submit"
                                      />
                                    </UserIndex.Box>
                                  </UserIndex.Box>
                                </Fade>
                              </Modal>
                            </UserIndex.Box>
                            {data.data.length
                              ? data.data.map((data, index) => {
                                  return (
                                    <AccordionDetails className="po-acc-details">
                                      <UserIndex.Box className="po-inner-accordian-main">
                                        <Accordion className="po-accordian po-inner-accordian">
                                          <AccordionSummary
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                            className="po-acc-summary po-inner-accordian-summary"
                                          >
                                            <UserIndex.Box className="order-main-row">
                                              <UserIndex.Box className="order-inner-main order-divide-row">
                                                <UserIndex.Typography
                                                  component="h3"
                                                  variant="h3"
                                                  className="order-sub-title"
                                                >
                                                  Product Id -
                                                </UserIndex.Typography>
                                                <UserIndex.Typography
                                                  component="p"
                                                  variant="p"
                                                  className="order-sub-data"
                                                >
                                                  {data.orderProductId}
                                                </UserIndex.Typography>
                                              </UserIndex.Box>
                                              <UserIndex.Box className="order-inner-main order-divide-row">
                                                <UserIndex.Typography
                                                  component="h3"
                                                  variant="h3"
                                                  className="order-sub-title"
                                                >
                                                  Price -
                                                </UserIndex.Typography>
                                                <UserIndex.Typography
                                                  component="p"
                                                  variant="p"
                                                  className="order-sub-data"
                                                >
                                                  {data.price}
                                                </UserIndex.Typography>
                                              </UserIndex.Box>
                                              <UserIndex.Box className="order-last-box">
                                                <UserIndex.Box className="order-inner-main order-inner-main-last">
                                                  <UserIndex.Typography
                                                    component="h3"
                                                    variant="h3"
                                                    className="order-sub-title"
                                                  >
                                                    Delievery Status
                                                  </UserIndex.Typography>
                                                  <UserIndex.Box className=" po-dropdown"></UserIndex.Box>
                                                </UserIndex.Box>
                                              </UserIndex.Box>
                                            </UserIndex.Box>
                                          </AccordionSummary>
                                          <AccordionDetails className="po-acc-details po-inner-accordian-details">
                                            <UserIndex.Box className="accordian-order-content-main">
                                              <UserIndex.Box className="order-content-first">
                                                <UserIndex.Box className="order-data-main">
                                                  <UserIndex.Box className="order-inner-main">
                                                    <UserIndex.Typography
                                                      component="h3"
                                                      variant="h3"
                                                      className="order-sub-title"
                                                    >
                                                      Product Name -
                                                    </UserIndex.Typography>
                                                    <UserIndex.Typography
                                                      component="p"
                                                      variant="p"
                                                      className="order-sub-data"
                                                    >
                                                      {data.productName}
                                                    </UserIndex.Typography>
                                                  </UserIndex.Box>
                                                  <UserIndex.Box className="order-inner-main">
                                                    <UserIndex.Typography
                                                      component="h3"
                                                      variant="h3"
                                                      className="order-sub-title"
                                                    >
                                                      Qty -
                                                    </UserIndex.Typography>
                                                    <UserIndex.Typography
                                                      component="p"
                                                      variant="p"
                                                      className="order-sub-data"
                                                    >
                                                      {data.quantity}
                                                    </UserIndex.Typography>
                                                  </UserIndex.Box>
                                                  {renderFieldMaterialWise()}
                                                </UserIndex.Box>
                                              </UserIndex.Box>
                                              <UserIndex.Box className="order-content-second">
                                                <UserIndex.Box className="order-inner-main">
                                                  <UserIndex.Typography
                                                    component="h3"
                                                    variant="h3"
                                                    className="order-sub-title"
                                                  >
                                                    Discount -
                                                  </UserIndex.Typography>
                                                  <UserIndex.Typography
                                                    component="p"
                                                    variant="p"
                                                    className="order-sub-data"
                                                  >
                                                    {data.discount}%
                                                  </UserIndex.Typography>
                                                </UserIndex.Box>
                                                <UserIndex.Box className="order-inner-main">
                                                  <UserIndex.Typography
                                                    component="h3"
                                                    variant="h3"
                                                    className="order-sub-title"
                                                  >
                                                    Taxes -
                                                  </UserIndex.Typography>
                                                  <UserIndex.Typography
                                                    component="p"
                                                    variant="p"
                                                    className="order-sub-data"
                                                  >
                                                    {data.taxes}%
                                                  </UserIndex.Typography>
                                                </UserIndex.Box>
                                              </UserIndex.Box>
                                              <UserIndex.Box className="order-content-third">
                                                <UserIndex.Box className="order-data-main">
                                                  <UserIndex.Box className="order-inner-main">
                                                    <UserIndex.Box className="order-rate-main">
                                                      <UserIndex.Typography
                                                        component="h3"
                                                        variant="h3"
                                                        className="order-sub-title"
                                                      >
                                                        Rate Out of 5
                                                      </UserIndex.Typography>
                                                      <UserIndex.Box className=" po-dropdown">
                                                        <UserIndex.FormControl className="po-form-control">
                                                          <UserIndex.Select
                                                            className="po-select"
                                                            name="ok"
                                                            value={rate}
                                                            // value={1}
                                                            onChange={(e) =>
                                                              myOrderhandleChange(
                                                                e,
                                                                data,
                                                                array,
                                                              )
                                                            }
                                                            displayEmpty
                                                            inputProps={{
                                                              'aria-label':
                                                                'Without label',
                                                            }}
                                                          >
                                                            <UserIndex.MenuItem
                                                              value="Select"
                                                              className="membershipitem"
                                                            >
                                                              <em>{''}</em>
                                                            </UserIndex.MenuItem>
                                                            <UserIndex.MenuItem
                                                              value={`${data._id}-1`}
                                                              name={`${rate}-1`}
                                                            >
                                                              1
                                                            </UserIndex.MenuItem>
                                                            <UserIndex.MenuItem
                                                              value={`${data._id}-2`}
                                                              name={`${rate}-2`}
                                                            >
                                                              2
                                                            </UserIndex.MenuItem>
                                                            <UserIndex.MenuItem
                                                              value={`${data._id}-3`}
                                                              name={`${rate}-3`}
                                                            >
                                                              3
                                                            </UserIndex.MenuItem>
                                                            <UserIndex.MenuItem
                                                              value={`${data._id}-4`}
                                                              name={`${rate}-4`}
                                                            >
                                                              4
                                                            </UserIndex.MenuItem>
                                                            <UserIndex.MenuItem
                                                              value={`${data._id}-5`}
                                                              name={`${rate}-5`}
                                                            >
                                                              5
                                                            </UserIndex.MenuItem>
                                                          </UserIndex.Select>
                                                          <span>
                                                            <img
                                                              src={
                                                                UserIndex.png
                                                                  .blackdown
                                                              }
                                                              className="black-down-img"
                                                            ></img>
                                                          </span>
                                                        </UserIndex.FormControl>
                                                      </UserIndex.Box>
                                                    </UserIndex.Box>
                                                  </UserIndex.Box>
                                                  <UserIndex.Box
                                                    sx={{ display: 'block' }}
                                                    className="po-return-btn-box primary-btn-box return"
                                                  >
                                                    <UserIndex.Button
                                                      className="primary-btn po-return-btn"
                                                      onClick={() => {
                                                        handleOpen()
                                                        setProductId(
                                                          data.productId._id,
                                                        )
                                                      }}
                                                    >
                                                      Return
                                                    </UserIndex.Button>
                                                  </UserIndex.Box>
                                                </UserIndex.Box>
                                              </UserIndex.Box>
                                            </UserIndex.Box>
                                          </AccordionDetails>
                                        </Accordion>
                                      </UserIndex.Box>
                                    </AccordionDetails>
                                  )
                                })
                              : 'No Record Found'}
                          </Accordion>
                        )
                      })
                    : 'No Record Found'}
                </UserIndex.Box>
              </UserIndex.Box>
            </UserIndex.Box>
          </UserIndex.Box>
        </UserIndex.Box>
      </UserIndex.Box>
      <UserIndex.Footer />
    </div>
  )
}

export default CustomeOrderPlaceView
