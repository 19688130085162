import React, { useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Png from "../../../../assets/png";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import UserSagaActions from "../../../../redux/Usersaga/action";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { styled } from "@mui/material/styles";
import HighlightOffTwoToneIcon from "@mui/icons-material/CancelTwoTone";
import { PrimaryButton } from "../../../../component/user/button/Button";
import { Theme, useTheme } from "@mui/material/styles";
import DisabledByDefaultIcon from "@mui/icons-material/DisabledByDefault";
import UserIndex from "../../UserIndex";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
const {
  SignInSaga,
  memberSaga,
  VerifyOtpEmail,
  ListMaterialSaga,
  ListItemSaga,
} = UserSagaActions;

export default function Membershipform() {
  const theme = useTheme();
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useHistory();
  const loding = useSelector((state) => {
    return state.Usersaga.loading;
  });
  const SignInData = useSelector((state) => {
    return state.Usersaga.SignInData;
  });
  const ListMaterialData = useSelector((state) => {
    return state.Usersaga.ListMaterialData;
  });
  const ListItemData = useSelector((state) => {
    return state.Usersaga.ListItemData;
  });

  const [age, setAge] = useState("");
  const [name, setname] = useState("");
  const [contact_number, setcontact_number] = useState("");
  const [permanent_address, setpermanent_address] = useState("");
  const [uam_number, setuam_number] = useState("");
  const [company_name, setcompany_name] = useState("");
  const [company_address, setcompany_address] = useState("");
  const [gst_number, setgst_number] = useState("");
  const [account_name, setaccount_name] = useState("");
  const [account_number, setaccount_number] = useState("");
  const [bank_name, setbank_name] = useState("");
  const [ifsc_code, setifsc_code] = useState("");
  const [branch_address, setbranch_address] = useState("");
  const [material, setMaterial] = useState([]);
  const [Item, setItem] = useState([]);
  const [images, setImages] = useState([]);
  const [oldImages, setOldImages] = useState([]);
  const [additional_detailsError, setAdditional_detailsError] = useState("");

  const [{ src, backend, oldImage }, setImg] = useState({
    src: "",
    backend: {},
    oldImage: "",
  });

  const [nameError, setNameError] = useState("");
  const [contactNumberError, setContactNumberError] = useState("");
  const [permanentAddressError, setPermanentAddressError] = useState("");
  const [uamNumberError, setUamNumberError] = useState("");
  const [companyNameError, setCompanyNameError] = useState("");
  const [companyAddressError, setCompanyAddressError] = useState("");
  const [gstNumberError, setGstNumberError] = useState("");
  const [accountNameError, setAccountNameError] = useState("");
  const [accountNumberError, setAccountNumberError] = useState("");
  const [bankNameError, setBankNameError] = useState("");
  const [ifscCodeError, setIfscCodeError] = useState("");
  const [branchAddressError, setBranchAddressError] = useState("");
  const [materialError, setMaterialError] = useState("");
  const [itemError, setItemError] = useState("");
  const [imageError, setImageError] = useState("");
  const [brandImageError, setBrandImageError] = useState("");
  const [ageError, setAgeError] = useState("");


  const errorHandling = () => {
    const ifscRegex = /^[A-Z]{4}0[A-Z0-9]{6}$/;
    const gstRegex =
      /^([0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[A-Z\d]{1})$/;
    const contactNumberRegex = /^[6789][0-9]{9}$/;
    if (name == "") {
      setNameError("Please enter full name");
    } else {
      setNameError("");
    }
    if (contact_number == "") {
      setContactNumberError("Please enter contact number");
    } else if (contact_number == 0) {
      setContactNumberError("Please enter valid contact number");
    } else if (!contactNumberRegex.test(contact_number)) {
      setContactNumberError("Please enter valid contact number");
    } else {
      setContactNumberError("");
    }
    if (permanent_address == "") {
      setPermanentAddressError("Please enter permanent address");
    } else {
      setPermanentAddressError("");
    }
    if (age == "") {
      setAgeError("Please select business type");
    } else {
      setAgeError("");
    }
    if (material == "") {
      setMaterialError("Please select material");
    } else {
      setMaterialError("");
    }
    if (Item == "") {
      setItemError("Please select item");
    } else {
      setItemError("");
    }
    if (company_name == "") {
      setCompanyNameError("Please enter company / shop / store name");
    } else {
      setCompanyNameError("");
    }
    if (company_address == "") {
      setCompanyAddressError("Please enter company / shop / store address");
    } else {
      setCompanyAddressError("");
    }
    if (gst_number == "") {
      setGstNumberError("Please enter GST number");
    } else if (!gstRegex.test(gst_number)) {
      setGstNumberError("Please enter valid GST number");
    } else {
      setGstNumberError("");
    }
    if (uam_number == "") {
      setUamNumberError("Please enter udyam aadhar number");
    } else {
      setUamNumberError("");
    }
    if (src.length === 0) {
      setBrandImageError("Please select brand image");
    } else {
      setBrandImageError("");
    }
    if (account_name == "") {
      setAccountNameError("Please enter account name");
    } else {
      setAccountNameError("");
    }
    if (account_number == "") {
      setAccountNumberError("Please enter account number");
    } else if (String(account_number)?.length < 14) {
      setAccountNumberError(
        "Please enter account number must be at least 14 characters"
      );
    } else {
      setAccountNumberError("");
    }
    if (bank_name == "") {
      setBankNameError("Please enter bank name");
    } else {
      setBankNameError("");
    }
    if (ifsc_code == "") {
      setIfscCodeError("Please enter IFSC code");
    } else if (!ifscRegex.test(ifsc_code)) {
      setIfscCodeError("Please enter valid IFSC code");
    } else {
      setIfscCodeError("");
    }
    if (branch_address == "") {
      setBranchAddressError("Please enter branch address");
    } else {
      setBranchAddressError("");
    }
    if (images.length === 0) {
      setImageError("Please select image");
    } else {
      setImageError("");
    }
  };

  const getListMaterial = async () => {
    let urlencoded = new URLSearchParams();
    dispatch(ListMaterialSaga(urlencoded, navigate));
  };
  const getListItem = async () => {
    let urlencoded = new URLSearchParams();
    dispatch(ListItemSaga(urlencoded, navigate));
  };

  useEffect(() => {
    getListMaterial();
    getListItem();
  }, []);

  const getOtp = useSelector((state) => {
    return state.Usersaga.SendOtpEmail;
  });

  const removeImg = () => {
    setImg({
      src: "",
      oldImage: "",
      backend: {},
    });
  };
  const removeOldImg = () => {
    setImg({
      backend: "",
      src: "",
      backend: {},
    });
  };
  const handleImg = (e) => {
    if (e.target.files[0]) {
      setImg({
        src: URL.createObjectURL(e.target.files[0]),
        backend: e.target.files[0],
        oldImage: "",
      });
    }
  };

  const handleChangeImage = (e) => {
    errorHandling();
    const { files } = e.target;
    if (files.length < 6 && images.length < 5) {
      setImages((prevState) => [...prevState, ...files]);
    }
  };

  const removePreviewImage = (data) => {
    var array = [...images];
    let filterArray = array.filter((item) => item !== data);
    setImages(filterArray);
  };

  const removeOldImages = (data) => {
    var array = [...oldImages];
    let filterArray = array.filter((item) => item !== data);
    setOldImages(filterArray);
  };

  const handelsubmit = () => {
    errorHandling();
    let materialArray = [];
    material.map((item) => {
      let temp = ListMaterialData.filter((a) => a.material == item);
      materialArray.push({
        material_id: temp[0]._id,
        material: temp[0].material,
      });
    });

    let itemArray = [];
    Item.map((item) => {
      let temp = ListItemData.filter((a) => a.item == item);
      itemArray.push({ item_id: temp[0]._id, item: temp[0].item });
    });

    if (
      age &&
      age.trim() !== "" &&
      name &&
      name.trim() !== "" &&
      contact_number &&
      contact_number.trim() !== "" &&
      permanent_address &&
      permanent_address.trim() !== "" &&
      uam_number &&
      uam_number.trim() !== "" &&
      company_name &&
      company_name.trim() !== "" &&
      company_address &&
      company_address.trim() !== "" &&
      gst_number &&
      gst_number.trim() !== "" &&
      account_name &&
      account_name.trim() !== "" &&
      account_number &&
      account_number.trim() !== "" &&
      bank_name &&
      bank_name.trim() !== "" &&
      ifsc_code &&
      ifsc_code.trim() !== "" &&
      branch_address &&
      branch_address.trim() !== "" &&
      src.length !== 0 &&
      images.length !== 0
    ) {
      let urlencoded = new FormData();
      urlencoded.append("id", getOtp?.data._id);
      urlencoded.append("name", name);
      urlencoded.append("contact_number", contact_number);
      urlencoded.append("permanent_address", permanent_address);
      urlencoded.append("business_type", age);
      urlencoded.append("company_name", company_name);
      urlencoded.append("company_address", company_address);
      urlencoded.append("gst_number", gst_number);
      urlencoded.append("uam_number", uam_number);
      urlencoded.append("account_name", account_name);
      urlencoded.append("account_number", account_number);
      urlencoded.append("bank_name", bank_name);
      urlencoded.append("ifsc_code", ifsc_code);
      urlencoded.append("branch_address", branch_address);
      urlencoded.append("material", JSON.stringify(materialArray));
      urlencoded.append("item", JSON.stringify(itemArray));
      for (const key of Object.keys(images)) {
        urlencoded.append("images", images[key]);
      }
      if (backend?.name !== "") {
        urlencoded.append("otherImage", backend);
      }
      dispatch(memberSaga(urlencoded, navigate));
    } else {
      // CustomToast();
    }
  };

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  const [personName, setPersonName] = useState([]);
  const [materials, setMaterials] = useState([]);

  const handleMaterialSelect = (event) => {
    const {
      target: { value },
    } = event;
    setMaterial(event.target.value);
  };

  const handleItemSelect = (event) => {
    setItem(event.target.value);
  };

  return (
    <div>
      <UserIndex.Header />
      <UserIndex.Box className="membership-sec">
        <UserIndex.Box className="membership-bg">
          <UserIndex.Box className="container">
            <UserIndex.Box className="membership-main">
              <UserIndex.Box className="membership-title">
                Membership Form
              </UserIndex.Box>

              <UserIndex.Box className="input-box membership-input-box">
                <UserIndex.FormHelperText className="input-lable">
                  Full Name{" "}
                  <span style={{ color: "red", fontSize: "15px" }}> * </span>
                </UserIndex.FormHelperText>
                <UserIndex.TextField
                  fullWidth
                  id="fullWidth"
                  type="text"
                  autoComplete="off"
                  className="form-control border-none"
                  placeholder="Full Name"
                  value={name}
               
                  onChange={(e) => {
                    if (e.target.value.length < 41) {
                      setname(e.target.value.trimStart().replace(/\s\s+/g, " "));
                    }
                  }}
              
                  onKeyPress={(e) => {
                    // Check if the pressed key is a special symbol or a number
                    if (/[^\w\s]/.test(e.key) || /\d/.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
                <UserIndex.FormHelperText className="error-input-lable">
                  {nameError}
                </UserIndex.FormHelperText>
              </UserIndex.Box>

              <UserIndex.Box className="input-box membership-input-box">
                <UserIndex.FormHelperText className="input-lable">
                  Contact Number{" "}
                  <span style={{ color: "red", fontSize: "15px" }}> * </span>
                </UserIndex.FormHelperText>
                <UserIndex.TextField
                  fullWidth
                  id="fullWidth"
                  type="number"
                  placeholder="Contact Number"
                  step="0.01"
                  className="form-control border-none"
                  maxLength="10"
                  autoComplete="off"
                  value={contact_number}
                  onChange={(e) => {
                    if (e.target.value.length < 11) {
                      setcontact_number(e.target.value);
                    }
                  }}
                  // onKeyPress={(e) => {
                  //   if (e.key === "e") {
                  //     e.preventDefault();
                  //   }
                  // }}
                  onKeyPress={(e) => {
                    if (e.key === "e" || e.key === "-") {
                      e.preventDefault();
                    }
                  }}
                />
                <UserIndex.FormHelperText className="error-input-lable">
                  {contactNumberError}
                </UserIndex.FormHelperText>
              </UserIndex.Box>

              <UserIndex.Box className="input-box membership-input-box">
                <UserIndex.FormHelperText className="input-lable">
                  Permanent Address{" "}
                  <span style={{ color: "red", fontSize: "15px" }}> * </span>
                </UserIndex.FormHelperText>
                <UserIndex.TextField
                  fullWidth
                  autoComplete="off"
                  id="fullWidth"
                  placeholder="Permanent Address"
                  className="form-control border-none"
                  value={permanent_address}
                  // onChange={(e) => {
                  //   if (e.target.value.length < 49) {
                  //     setpermanent_address(e.target.value);
                  //   }
                  // }}
                  onChange={(e) => {
                    const newValue = e.target.value.replace(/^\s+/, ""); // Remove leading space if present
                    const trimmedValue = newValue.substring(0, 50); // Limit the length to 30 characters
                    setpermanent_address(trimmedValue);
                  }}
                  onKeyPress={(e) => {
                    // Check if the pressed key is a special symbol
                    if (/[^\w\s\d]/.test(e.key)) {
                      e.preventDefault(); // Prevent default behavior (typing the character)
                    }
                  }}
                />
                <UserIndex.FormHelperText className="error-input-lable">
                  {permanentAddressError}
                </UserIndex.FormHelperText>
              </UserIndex.Box>

              <UserIndex.Box className="input-box membership-input-box">
                <UserIndex.FormHelperText className="input-lable">
                  Business Type{" "}
                  <span style={{ color: "red", fontSize: "15px" }}> * </span>
                </UserIndex.FormHelperText>
                <FormControl className="membership-formcontrol">
                  <Select
                    className="membershop-select custom-dropdown-arrow"
                    value={age}
                    onChange={handleChange}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    renderValue={(selected) => {
                      if (!selected) {
                        return (
                          <em
                            sx={{
                              fontFamily: "mulish-semibold",
                              color: "#8a8a8f",
                            }}
                          >
                            Select Business Type
                          </em>
                        );
                      }
                      return selected;
                    }}
                    MenuProps={MenuProps}
                  >
                    <UserIndex.MenuItem value={"Retailer"}>
                      Retailer
                    </UserIndex.MenuItem>
                    <UserIndex.MenuItem value={"Wholesaler"}>
                      Wholesaler
                    </UserIndex.MenuItem>
                    <UserIndex.MenuItem value={"Manufacturer"}>
                      Manufacturer
                    </UserIndex.MenuItem>
                  </Select>

                  {/* <span>
                    <img
                      src={Png.darkgreydown}
                      className="arkgreydown-img"
                    ></img>
                  </span> */}
                </FormControl>
                <UserIndex.FormHelperText className="error-input-lable">
                  {ageError}
                </UserIndex.FormHelperText>
              </UserIndex.Box>

              {age === "Manufacturer" ? (
                <>
                  <UserIndex.Box className="input-box membership-input-box">
                    <UserIndex.FormHelperText className="input-lable">
                      Material
                      <span style={{ color: "red", fontSize: "15px" }}>*</span>
                    </UserIndex.FormHelperText>
                    <FormControl
                      sx={{ width: 559 }}
                      className="member-form-control"
                    >
                      <Select
                        className="member-select"
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        multiple="true"
                        value={material}
                        onChange={handleMaterialSelect}
                        inputProps={{
                          "aria-label": "Without label",
                        }}
                        displayEmpty
                        // renderValue={(selected) => (
                        //   <UserIndex.Box
                        //     sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                        //   >
                        //     {selected.map((value) => {
                        //       return (
                        //         <UserIndex.Chip key={value} label={value} />
                        //       );
                        //     })}
                        //   </UserIndex.Box>
                        // )}
                        renderValue={
                          (selected) =>
                            selected.length !== 0 ? (
                              <UserIndex.Box
                                sx={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  gap: 0.5,
                                }}
                              >
                                {selected.map((value) => (
                                  <UserIndex.Chip key={value} label={value} />
                                ))}
                              </UserIndex.Box>
                            ) : (
                              "Select Material"
                            ) // Placeholder when no values selected
                        }
                        // MenuProps={MenuProps}
                      >
                        {ListMaterialData?.map((row) => {
                          return (
                            <UserIndex.MenuItem
                              key={row?.material}
                              value={row?.material}
                            >
                              {row?.material}
                            </UserIndex.MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                    <UserIndex.FormHelperText className="error-input-lable">
                      {materialError}
                    </UserIndex.FormHelperText>
                  </UserIndex.Box>

                  <UserIndex.Box className="input-box membership-input-box">
                    <UserIndex.FormHelperText className="input-lable">
                      Item
                      <span style={{ color: "red", fontSize: "15px" }}>*</span>
                    </UserIndex.FormHelperText>
                    <FormControl
                      sx={{ width: 559 }}
                      className="member-form-control"
                    >
                      <Select
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        multiple="true"
                        value={Item}
                        onChange={handleItemSelect}
                        inputProps={{
                          "aria-label": "Without label",
                        }}
                        displayEmpty
                        renderValue={(selected) =>
                          selected.length !== 0 ? (
                            <UserIndex.Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.5,
                              }}
                            >
                              {selected.map((value) => (
                                <UserIndex.Chip key={value} label={value} />
                              ))}
                            </UserIndex.Box>
                          ) : (
                            "Select Item"
                          )
                        }
                        MenuProps={MenuProps}
                      >
                        {ListItemData?.map((row) => {
                          return (
                            <UserIndex.MenuItem
                              key={row?.item}
                              value={row?.item}
                            >
                              {row?.item}
                            </UserIndex.MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                    <UserIndex.FormHelperText className="error-input-lable">
                      {itemError}
                    </UserIndex.FormHelperText>
                  </UserIndex.Box>
                </>
              ) : (
                ""
              )}

              <UserIndex.Box className="input-box membership-input-box">
                <UserIndex.FormHelperText className="input-lable">
                  Company / Shop / Store Name{" "}
                  <span style={{ color: "red", fontSize: "15px" }}> * </span>
                </UserIndex.FormHelperText>
                <UserIndex.TextField
                  fullWidth
                  id="fullWidth"
                  type="text"
                  autoComplete="off"
                  placeholder="Company / Shop / Store Name"
                  className="form-control border-none"
                  value={company_name}
                  onChange={(e) => {
                    if (e.target.value.length < 31) {
                      setcompany_name(e.target.value);
                    }
                  }}
                />
                <UserIndex.FormHelperText className="error-input-lable">
                  {companyNameError}
                </UserIndex.FormHelperText>
              </UserIndex.Box>

              <UserIndex.Box className="input-box membership-input-box">
                <UserIndex.FormHelperText className="input-lable">
                  Company / Shop / Store Address{" "}
                  <span style={{ color: "red", fontSize: "15px" }}> * </span>
                </UserIndex.FormHelperText>
                <UserIndex.TextField
                  fullWidth
                  id="fullWidth"
                  autoComplete="off"
                  placeholder="Company / Shop / Store Address"
                  className="form-control border-none"
                  value={company_address}
                  onChange={(e) => {
                    if (e.target.value.length < 40) {
                      setcompany_address(e.target.value);
                    }
                  }}
                />
                <UserIndex.FormHelperText className="error-input-lable">
                  {companyAddressError}
                </UserIndex.FormHelperText>
              </UserIndex.Box>

              <UserIndex.Box className="flex-colum-form">
                <UserIndex.Box className="input-box membership-input-box mr-47">
                  <UserIndex.FormHelperText className="input-lable">
                    GST Number{" "}
                    <span style={{ color: "red", fontSize: "15px" }}> * </span>
                  </UserIndex.FormHelperText>
                  <UserIndex.TextField
                    fullWidth
                    id="fullWidth"
                    autoComplete="off"
                    placeholder="GST Number"
                    className="flex-form-control border-none"
                    value={gst_number}
                    onChange={(e) => {
                      if (e.target.value.length < 16) {
                        setgst_number(e.target.value);
                      }
                    }}
                  />
                  <UserIndex.FormHelperText className="error-input-lable">
                    {gstNumberError}
                  </UserIndex.FormHelperText>
                </UserIndex.Box>
                <UserIndex.Box className="membership-form-or-text">
                  OR
                </UserIndex.Box>
                <UserIndex.Box className="input-box membership-input-box">
                  <UserIndex.FormHelperText className="input-lable">
                    Udyam Aadhar Number(UAM){" "}
                    <span style={{ color: "red", fontSize: "15px" }}> * </span>
                  </UserIndex.FormHelperText>
                  <UserIndex.TextField
                    fullWidth
                    id="fullWidth"
                    type="number"
                    name="uam_number"
                    placeholder="Udyam Aadhar Number(UAM)"
                    autoComplete="off"
                    className="flex-form-control border-none"
                    value={uam_number}
                    onChange={(e) => {
                      if (e.target.value.length < 13) {
                        setuam_number(e.target.value);
                      }
                    }}
                  />
                  <UserIndex.FormHelperText className="error-input-lable">
                    {uamNumberError}
                  </UserIndex.FormHelperText>
                </UserIndex.Box>
              </UserIndex.Box>

              <UserIndex.Box className="add-product-left">
                <UserIndex.Box className="add-upload-bg">
                  <UserIndex.Button
                    variant="contained"
                    component="label"
                    className="add-upload-btn"
                  >
                    + Upload Brand Image{" "}
                    <input
                      hidden
                      accept="image/png, image/jpeg"
                      type="file"
                      onChange={(e) => {
                        handleImg(e);
                      }}
                    />
                  </UserIndex.Button>
                </UserIndex.Box>
                <UserIndex.FormHelperText className="error-input-lable">
                  {src.length == 0 ? (
                    <>
                      <UserIndex.FormHelperText
                        sx={{
                          color: "#d32f2f",
                          fontSize: "14px",
                          fontFamily: "mulish-semibold",
                          margin: "6px 0px 0px 0px",
                        }}
                      >
                        {brandImageError}
                      </UserIndex.FormHelperText>
                    </>
                  ) : (
                    ""
                  )}
                </UserIndex.FormHelperText>
              </UserIndex.Box>

              <UserIndex.Box className="addproduct-textarea-main input-box ">
                <UserIndex.Box sx={{ marginTop: "15px" }}>
                  <UserIndex.FormHelperText className="input-lable">
                    Uploaded Brand Image
                    <span style={{ color: "red", fontSize: "15px" }}> * </span>
                  </UserIndex.FormHelperText>
                </UserIndex.Box>
                <UserIndex.Box sx={{ width: "95%", position: "relative" }}>
                  <UserIndex.Grid sx={{ width: "100%" }} container>
                    {src === "" || src === undefined ? (
                      ""
                    ) : (
                      <>
                        <UserIndex.Grid container spacing={2}>
                          <UserIndex.Grid item xs={6}>
                            <UserIndex.Box sx={{ position: "relative" }}>
                              <UserIndex.IconButton
                                onClick={() => removeImg()}
                                sx={{
                                  position: "absolute",
                                  left: "86%",
                                  color: "#c29958",
                                }}
                                color="error"
                                aria-label="upload picture"
                                component="label"
                              >
                                <DisabledByDefaultIcon />
                              </UserIndex.IconButton>
                              <img
                                src={src}
                                style={{
                                  width: "100%",
                                  height: "130px",
                                }}
                              />
                            </UserIndex.Box>
                          </UserIndex.Grid>
                        </UserIndex.Grid>
                      </>
                    )}
                  </UserIndex.Grid>
                </UserIndex.Box>
              </UserIndex.Box>

              <UserIndex.Box className="bank-details-title">
                Bank details for Product payment / Refund
              </UserIndex.Box>

              <UserIndex.Box className="input-box membership-input-box">
                <UserIndex.FormHelperText className="input-lable">
                  Account Name{" "}
                  <span style={{ color: "red", fontSize: "15px" }}> * </span>
                </UserIndex.FormHelperText>
                <UserIndex.TextField
                  fullWidth
                  id="fullWidth"
                  placeholder="Account Name"
                  className="form-control border-none"
                  value={account_name}
                  autoComplete="off"
                  type="text"
                
                  onChange={(e) => {
                    if (e.target.value.length < 41) {
                      setaccount_name(e.target.value.trimStart().replace(/\s\s+/g, " "));
                    }
                  }}
                  onKeyPress={(e) => {
                    // Check if the pressed key is a special symbol or a number
                    if (/[^\w\s]/.test(e.key) || /\d/.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
                <UserIndex.FormHelperText className="error-input-lable">
                  {accountNameError}
                </UserIndex.FormHelperText>
              </UserIndex.Box>

              <UserIndex.Box className="input-box membership-input-box">
                <UserIndex.FormHelperText className="input-lable">
                  Account Number{" "}
                  <span style={{ color: "red", fontSize: "15px" }}> * </span>
                </UserIndex.FormHelperText>
                <UserIndex.TextField
                  fullWidth
                  id="fullWidth"
                  placeholder="Account Number"
                  className="form-control border-none"
                  value={account_number}
                  type="number"
                  autoComplete="off"
                  onChange={(e) => {
                    // if (e.target.value.length < 19) {
                    if (e.target.value.length < 15) {
                      setaccount_number(e.target.value);
                    }
                  }}
                  onKeyPress={(e) => {
                    // Check if the pressed key is a special symbol
                    if (/[^\w\s\d]/.test(e.key)) {
                      e.preventDefault(); // Prevent default behavior (typing the character)
                    }
                  }}
                />
                <UserIndex.FormHelperText className="error-input-lable">
                  {accountNumberError}
                </UserIndex.FormHelperText>
              </UserIndex.Box>

              <UserIndex.Box className="input-box membership-input-box">
                <UserIndex.FormHelperText className="input-lable">
                  Bank Name{" "}
                  <span style={{ color: "red", fontSize: "15px" }}> * </span>
                </UserIndex.FormHelperText>
                <UserIndex.TextField
                  fullWidth
                  id="fullWidth"
                  placeholder="Bank Name"
                  className="form-control border-none"
                  value={bank_name}
                  type="text"
                  autoComplete="off"
                  onChange={(e) => {
                    if (e.target.value.length < 41) {
                      setbank_name(e.target.value.trimStart().replace(/\s\s+/g, " "));
                    }
                  }}
                  onKeyPress={(e) => {
                    // Check if the pressed key is a special symbol or a number
                    if (/[^\w\s]/.test(e.key) || /\d/.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
                <UserIndex.FormHelperText className="error-input-lable">
                  {bankNameError}
                </UserIndex.FormHelperText>
              </UserIndex.Box>

              <UserIndex.Box className="input-box membership-input-box">
                <UserIndex.FormHelperText className="input-lable">
                  IFSC Code{" "}
                  <span style={{ color: "red", fontSize: "15px" }}> * </span>
                </UserIndex.FormHelperText>
                <UserIndex.TextField
                  fullWidth
                  id="fullWidth"
                  autoComplete="off"
                  placeholder="IFSC Code"
                  className="form-control border-none"
                  value={ifsc_code}
                  onChange={(e) => {
                    if (e.target.value.length <= 11) {
                      setifsc_code(e.target.value);
                    }
                  }}
                  onKeyPress={(e) => {
                    // Check if the pressed key is a special symbol
                    if (/[^\w\s\d]/.test(e.key)) {
                      e.preventDefault(); // Prevent default behavior (typing the character)
                    }
                  }}
                />
                <UserIndex.FormHelperText className="error-input-lable">
                  {ifscCodeError}
                </UserIndex.FormHelperText>
              </UserIndex.Box>

              <UserIndex.Box className="input-box membership-input-box">
                <UserIndex.FormHelperText className="input-lable">
                  Branch Address{" "}
                  <span style={{ color: "red", fontSize: "15px" }}> * </span>
                </UserIndex.FormHelperText>
                <UserIndex.TextField
                  fullWidth
                  id="fullWidth"
                  autoComplete="off"
                  placeholder="Branch Address"
                  className="form-control border-none"
                  value={branch_address}
                  // onChange={(e) => {
                  //   if (e.target.value.length < 31) {
                  //     setbranch_address(e.target.value);
                  //   }
                  // }}
                  onChange={(e) => {
                    const newValue = e.target.value.replace(/^\s+/, ""); // Remove leading space if present
                    const trimmedValue = newValue.substring(0, 30); // Limit the length to 30 characters
                    setbranch_address(trimmedValue);
                  }}
                  onKeyPress={(e) => {
                    // Check if the pressed key is a special symbol
                    if (/[^\w\s\d]/.test(e.key)) {
                      e.preventDefault(); // Prevent default behavior (typing the character)
                    }
                  }}
                />
                <UserIndex.FormHelperText className="error-input-lable">
                  {branchAddressError}
                </UserIndex.FormHelperText>
              </UserIndex.Box>

              <UserIndex.Box className="add-product-left">
                <UserIndex.Box className="add-upload-bg">
                  <UserIndex.Button
                    variant="contained"
                    component="label"
                    className="add-upload-btn"
                  >
                    + Upload Certificate{" "}
                    <input
                      hidden
                      accept="image/png, image/jpeg"
                      multiple
                      type="file"
                      onChange={(e) => {
                        handleChangeImage(e);
                      }}
                    />
                  </UserIndex.Button>
                </UserIndex.Box>
                <UserIndex.FormHelperText
                  sx={{ color: "#d32f2f", fontSize: "14px" }}
                  className="error-input-lable"
                >
                  {images.length == 0 ? (
                    <>
                      <UserIndex.FormHelperText
                        sx={{
                          color: "#d32f2f",
                          fontSize: "14px",
                          fontFamily: "mulish-semibold",
                          margin: "6px 0px 0px 0px",
                        }}
                      >
                        {imageError}
                      </UserIndex.FormHelperText>
                    </>
                  ) : (
                    ""
                  )}
                </UserIndex.FormHelperText>
              </UserIndex.Box>

              <UserIndex.Box className="addproduct-textarea-main input-box ">
                <UserIndex.Box sx={{ marginTop: "15px" }}>
                  <UserIndex.FormHelperText className="input-lable">
                    Uploaded Cerificates
                    <span style={{ color: "red", fontSize: "15px" }}> * </span>
                  </UserIndex.FormHelperText>
                </UserIndex.Box>
                <UserIndex.Box sx={{ width: "95%", position: "relative" }}>
                  <UserIndex.Grid sx={{ width: "100%" }} container>
                    {images
                      ? images?.map((data) => {
                          return (
                            <>
                              <UserIndex.Grid item xs={6}>
                                <UserIndex.Box
                                  sx={{
                                    position: "relative",
                                    padding: "10px",
                                  }}
                                >
                                  <UserIndex.IconButton
                                    onClick={() => removePreviewImage(data)}
                                    sx={{
                                      position: "absolute",
                                      left: "80%",
                                      color: "#c29958",
                                    }}
                                    aria-label="upload picture"
                                    component="label"
                                  >
                                    <DisabledByDefaultIcon />
                                  </UserIndex.IconButton>
                                  <img
                                    src={URL.createObjectURL(data)}
                                    style={{
                                      my: 2,
                                      width: "100%",
                                      height: "130px",
                                    }}
                                  />
                                </UserIndex.Box>
                              </UserIndex.Grid>
                            </>
                          );
                        })
                      : ""}
                  </UserIndex.Grid>
                </UserIndex.Box>
              </UserIndex.Box>

              <UserIndex.Box
                sx={{ marginBottom: "50px" }}
                className="modal-btn-box"
              >
                <PrimaryButton
                  btnclass="btn modal-btn membership-submit-btn"
                  onClick={() => {
                    handelsubmit();
                  }}
                  buttonvalue="Submit"
                />
              </UserIndex.Box>
            </UserIndex.Box>
          </UserIndex.Box>
        </UserIndex.Box>
      </UserIndex.Box>
      <UserIndex.Footer />
    </div>
  );
}
