import {
  Typography,
  Button,
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Container,
  InputBase,
  TablePagination,
  Tooltip,
  Card,
  TextField,
  FormHelperText,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton
} from '@mui/material';
import { MuiOtpInput } from 'mui-one-time-password-input';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled, alpha } from '@mui/material/styles';

const ToBeStyledTooltip = ({ className, ...props }) => (
  <Tooltip {...props} classes={{ tooltip: className }} />
);
const GrayTooltip = styled(ToBeStyledTooltip)(({ theme }) => ({
  color: 'white',
  backgroundColor: '#808080',
  width: '100% !important',
  maxWidth: '100% !important',
  opacity: 'none',
}));
const GrayTooltip1 = styled(ToBeStyledTooltip)(({ theme }) => ({
  color: 'white',
  backgroundColor: '#808080',
  width: '100% !important',
  maxWidth: '100% !important',
  opacity: 'none',
  marginTop: '-12px !important',
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: '25px',
  backgroundColor: alpha(theme.palette.common.white, 0.25),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '150px',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: '340px',
  },
  border: '1px solid black',
}));

const SearchMaxWidth = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: '25px',
  backgroundColor: alpha(theme.palette.common.white, 0.25),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '250px',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: '540px',
  },
  border: '1px solid black',
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

export default {
  // starts mui elements
  Typography,
  Button,
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Container,
  InputBase,
  TablePagination,
  Tooltip,
  Card,
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormHelperText,
  MuiOtpInput,
  // end mui elements

  // starts mui styled
  styled,
  alpha,

  TableCell,
  tableCellClasses,

  GrayTooltip,
  GrayTooltip1,
  GrayTooltip,
  GrayTooltip1,
  StyledTableCell,
  StyledTableRow,
  Search,
  SearchMaxWidth,
  SearchIconWrapper,
  StyledInputBase,
  // end mui styled
};
