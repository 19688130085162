import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import FilterSection from "../../../../component/user/productList/FilterSection";
import ProductGrid from "../../../../component/user/productList/ProductGrid";
import UserIndex from "../../UserIndex";

const { AllproductSaga, ListItemSaga } = UserIndex.UserSagaActions;

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <UserIndex.Box sx={{ p: 3 }}>
          <UserIndex.Typography>{children}</UserIndex.Typography>
        </UserIndex.Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const BpIcon = UserIndex.styled("span")(({ theme }) => ({
  borderRadius: 2,
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = UserIndex.styled(BpIcon)({
  backgroundColor: "#137cbd",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#106ba3",
  },
});

// Inspired by blueprintjs
function BpCheckbox(props) {
  return (
    <UserIndex.Checkbox
      sx={{
        "&:hover": { bgcolor: "transparent" },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      inputProps={{ "aria-label": "Checkbox demo" }}
      {...props}
    />
  );
}

export default function Mensjewelry() {
  const [value, setValue] = useState(0);
  const [tab, setTab] = useState(0);
  const [categoryData, setCategoryData] = useState("");
  const [allProduct, setAllProduct] = useState([]);
  const [filterProduct, setFilterProduct] = useState([]);
  const [itemList, setItemList] = useState([]);
  const [dataPerPage, setDataPerPage] = useState(12);
  const [page, setPage] = useState(1);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const params = UserIndex.useParams();
  const dispatch = UserIndex.useDispatch();
  const navigate = UserIndex.useHistory();
  const loding = UserIndex.useSelector((state) => {
    return state.Usersaga.loading;
  });
  const AllProductdata = UserIndex.useSelector((state) => {
    return state.Usersaga.AllProductdata;
  });

  const Categorymenudata = UserIndex.useSelector((state) => {
    return state.Usersaga.Categorymenudata;
  });

  const ListItemData = UserIndex.useSelector((state) => {
    return state.Usersaga.ListItemData;
  });

  useEffect(() => {
    setCategoryData(Categorymenudata);
  }, [Categorymenudata]);

  const getAllUserProducts = async () => {
    let item = [params.item_id];
    let material = [params.material_id];
    let urlencoded = new URLSearchParams();
    urlencoded.append("category_id", params.category_id);
    urlencoded.append("item_id", JSON.stringify(item));
    urlencoded.append("material_id", JSON.stringify(material));
    dispatch(AllproductSaga(urlencoded, navigate));
  };

  useEffect(() => {
    setAllProduct(AllProductdata);
  }, [AllProductdata]);

  const indexOfLastData = page * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = allProduct.slice(indexOfFirstData, indexOfLastData);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(allProduct.length / dataPerPage); i++) {
    pageNumbers.push(i);
  }

  useEffect(() => {
    getAllUserProducts();
  }, [params.category_id]);

  const [filterdItems, setFilterdItems] = useState({});
  const filterItem = () => {
    const item = categoryData?.find((item) => params.category_id == item._id);
    setFilterdItems(item);
  };

  useEffect(() => {
    if (categoryData.length > 0) {
      filterItem();
    }
  }, [params?.category_id, categoryData]);

  const getFilterData = (productItem) => {
    setFilterProduct(
      allProduct.filter((item) => item.item_id.item == productItem)
    );
  };
  return (
    <div>
      <UserIndex.Header />
      <UserIndex.Box className="inner-hero-sec">
        <UserIndex.Box className="container">
          <UserIndex.Box className="hero-title-box">
            <UserIndex.Typography variant="h4" className="hero-inner-title">
              <span>
                {categoryData.length
                  ? categoryData?.map((item) => {
                      return (
                        <>
                          {item._id === params.category_id
                            ? item.category_name
                            : ""}
                        </>
                      );
                    })
                  : ""}
              </span>
            </UserIndex.Typography>
          </UserIndex.Box>
        </UserIndex.Box>
      </UserIndex.Box>
      <UserIndex.Box className="rings-section">
        <UserIndex.Box className="container">
          <UserIndex.Box>
            <UserIndex.Box
              display="grid"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 0, sm: 0, md: 4, lg: 4 }}
            >
              <UserIndex.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 3",
                  lg: "span 3",
                }}
              >
                <FilterSection />
              </UserIndex.Box>
              <UserIndex.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 9",
                  lg: "span 9",
                }}
              >
                <UserIndex.Box>
                  <UserIndex.Box className="mens-tabs-box">
                    <UserIndex.Tabs
                      value={value}
                      onChange={handleChange}
                      aria-label="basic tabs example"
                      className="tabs"
                    >
                      <UserIndex.Tab
                        label="All Products"
                        {...a11yProps(0)}
                        className="selected mens-tabs"
                      />
                      {filterdItems?.item?.length
                        ? filterdItems?.item?.map((items) => {
                            return (
                              <UserIndex.Tab
                                label={items.item}
                                {...a11yProps(1)}
                                onClick={() => {
                                  setTab(items.item);
                                  getFilterData(items.item);
                                }}
                                className="mens-tabs"
                              />
                            );
                          })
                        : ""}
                    </UserIndex.Tabs>
                  </UserIndex.Box>
                  <TabPanel value={value} index={0} className="tabspanel">
                    <UserIndex.Box sx={{ width: 1 }}>
                      <UserIndex.Box
                        className="mj-flex"
                        display="grid"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gap={{ xs: 3, sm: 5, md: 4, lg: 4 }}
                      >
                        {currentData.length
                          ? currentData.map((item, err) => {
                              return <ProductGrid item={item} />;
                            })
                          : "No Records Found"}
                      </UserIndex.Box>
                    </UserIndex.Box>
                  </TabPanel>
                  <TabPanel>
                    <UserIndex.Box sx={{ width: 1 }}>
                      <UserIndex.Box
                        className="mj-ke-flex"
                        display="grid"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gap={{ xs: 3, sm: 5, md: 3, lg: 3 }}
                      >
                        {filterProduct.length
                          ? filterProduct.map((item, err) => {
                              return <ProductGrid item={item} />;
                            })
                          : ""}
                      </UserIndex.Box>
                    </UserIndex.Box>
                  </TabPanel>
                </UserIndex.Box>

                <UserIndex.Box>
                  {allProduct && allProduct ? (
                    <UserIndex.Box
                      display="grid"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gap={{ xs: 0, sm: 0, md: 0, lg: 5 }}
                    >
                      <UserIndex.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 12",
                          md: "span 12",
                          lg: "span 12",
                        }}
                      >
                        <UserIndex.Box className="pagination-box-mens-womens">
                          {currentData.length ? (
                            <UserIndex.Pagination
                              variant="outlined"
                              shape="rounded"
                              className="pagination"
                              component="div"
                              count={pageNumbers.length}
                              page={page}
                              onChange={handleChange}
                            />
                          ) : (
                            ""
                          )}
                        </UserIndex.Box>
                      </UserIndex.Box>
                    </UserIndex.Box>
                  ) : (
                    ""
                  )}
                </UserIndex.Box>
              </UserIndex.Box>
            </UserIndex.Box>
          </UserIndex.Box>
        </UserIndex.Box>
      </UserIndex.Box>
      <UserIndex.Footer />
    </div>
  );
}
