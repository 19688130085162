//#region ALL IMPORTS
import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import {
  Grid,
  Container,
  Typography,
  Button,
  Box,
  TextField,
  Tooltip,
} from '@mui/material';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import UploadIcon from '@mui/icons-material/Upload';
import { useHistory, useParams } from 'react-router-dom';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { Api } from '../../../../config/api';
import dataService from '../../../../config/dataService';
import { ToastContainer, toast } from 'react-toastify';
import ContainedButton from '../../../../component/customComponents/buttons/ContainedButton';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { ErrorMsg } from '../../../../component/customComponents/errorMsg/ErrorMsg';
import {
  minItemLength,
  itemLength,
  ITEM_REGEX,
} from '../../../../component/customComponents/validation/Validation';
//#endregion

import AdminIndex from '../../AdminIndex';

//#region ALL HOOKS AND VARIABALS
const Img = styled('img')({
  display: 'block',
  maxWidth: '100%',
  height: 'auto',
  maxHeight: '50vh',
  borderRadius: '6px',
});

const { getItemDetail, editItem } = AdminIndex.AdminSagaActions;
const UpdateItem = () => {
  const { id } = useParams();
  let token = localStorage.getItem('auth');
  const dispatch = AdminIndex.useDispatch();
  const navigate = useHistory();
  const history = useHistory();
  const [image, setImage] = useState('');
  const [url, setUrl] = useState('');
  const [getData, setGetData] = useState('');

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  //#endregion

  //#region ALL EVENT HANDLERS AND  FUCTIONS
  const getItemData = async () => {
    let formdata = new URLSearchParams();
    try {
      formdata.append('id', id);
      dispatch(getItemDetail(token, formdata));
      // const Data = await dataService.post(Api.Admin.singleItem, formdata);
      // setGetData(Data.data.data);
    } catch (err) {
      toast.error(err?.message || 'Something went wrong');
    }
  };

  const itemDetail = AdminIndex.useSelector((state) => {
    return state.AdminSaga.itemDetail;
  });

  useEffect(() => {
    setGetData(itemDetail);
  }, [itemDetail]);

  useEffect(() => {
    getItemData();
  }, []);

  useEffect(() => {
    setUrl(getData?.image);
    setValue('item', getData?.item);
  }, [getData]);

  const handleImg = (e) => {
    if (e.target.files.length) {
      setImage(e.target.files[0]);
      setUrl(window.URL.createObjectURL(e.target.files[0]));
    }
  };

  const removeImg = () => {
    setImage('');
    setUrl('');
  };

  const handleSave = async (data) => {
    try {
      let formdata = new FormData();
      formdata.append('id', id);
      formdata.append('item', data.item?.trim());
      formdata.append('images', image);
      formdata.append('fileurl', url);

      dispatch(editItem(token,formdata,navigate));
      // const userResp = await dataService.post(Api.Admin.updateItem, formdata);

      // if (userResp.data.status === 1) {
      //   toast.success('Item updated successfully');
      //   history.push('/admin/itemmanagement');
      // }
    } catch (err) {
      toast.error(err?.message || 'Something went wrong');
    }
  };
  //#endregion

  //#region RETURN JSX
  return (
    <>
      <form onSubmit={handleSubmit(handleSave)}>
        <Container maxWidth="false">
          <Box sx={{ border: 1, mx: { lg: 35, sm: 5 }, mt: 15, py: 5 }}>
            <Typography sx={{ my: 2 }} align="center" variant="h4">
              Update Item
            </Typography>
            <Typography sx={{ my: 2 }} align="center" variant="h4">
              <TextField
                autoComplete="off"
                sx={{
                  mt: 2,
                  width: { lg: '300px', xs: '150px' },
                  '& .MuiFormHelperText-root': { ml: 0, mt: '5px' },
                }}
                size="small"
                fullWidth
                className="form-control border-none"
                name="item"
                type="text"
                {...register('item', {
                  required: <ErrorMsg msg="Please enter Item" />,
                  minLength: {
                    value: minItemLength,
                    message: (
                      <ErrorMsg
                        msg={`Minimum ${minItemLength} Characters Allowed`}
                      />
                    ),
                  },
                  maxLength: {
                    value: itemLength,
                    message: (
                      <ErrorMsg
                        msg={`Maximum ${itemLength} Characters Allowed`}
                      />
                    ),
                  },
                  pattern: {
                    value: ITEM_REGEX,
                    message: <ErrorMsg msg="Please enter valid Item" />,
                  },
                })}
                helperText={<ErrorMessage errors={errors} name="item" />}
              />
            </Typography>
            <Typography align="center">
              {image === '' && url == '' && (
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      mx: { xs: 3 },
                    }}
                  >
                    <Box sx={{ width: { lg: '300px', xs: '100%' } }}>
                      <Button
                        component="label"
                        sx={{
                          borderRadius: 0,
                          textTransform: 'none',
                          width: { lg: '300px', xs: '100%' },
                        }}
                        variant="contained"
                        onChange={handleImg}
                      >
                        Upload
                        <UploadIcon />
                        <input
                          name="files"
                          hidden
                          accept="image/*"
                          type="file"
                          {...register('files', {
                            required: <ErrorMsg msg="Please select Image" />,
                          })}
                        />
                      </Button>
                      <ErrorMessage errors={errors} name="files" />
                    </Box>
                  </Box>
                </>
              )}
              {image === '' && url == '' ? (
                ''
              ) : (
                <>
                  <Box className="updateitem-img-box">
                    <Img
                      sx={{
                        my: 2,
                        height: '200px',
                        width: '100%',
                        maxWidth: '300px',
                        borderRadius: '0px',
                        position: 'relative',
                        margin: 'auto',
                      }}
                      src={url}
                      alt="no image"
                    />
                    <Button className="remove-btn-upadteitem">
                      <Tooltip title="Remove Images" placement="bottom">
                        <DisabledByDefaultIcon
                          className="remove-img-icon close-upadte-item"
                          onClick={removeImg}
                          fontSize="large"
                          sx={{ mt: 5 }}
                        />
                      </Tooltip>
                    </Button>
                  </Box>
                </>
              )}
            </Typography>
            <Typography sx={{ mt: 2 }} align="center" variant="h4">
              <ContainedButton name="Update" type="submit" />
            </Typography>
          </Box>
        </Container>
      </form>
    </>
  );
};

export default UpdateItem;
//#endregion
