import React from 'react';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { Button } from '@mui/material';

const PdfDownloader = ({ rootElement, rootElementId, name, flag }) => {
  const downloadPdfDocument = () => {
    const divElement = document.getElementById(rootElementId);
    divElement.innerHTML = rootElement;
    html2canvas(divElement).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();
      pdf.addImage(imgData, 'JPEG', -45, 20);
      pdf.save(`${name}.pdf`);
      if(flag == undefined){
        divElement.innerHTML=null;
      }
    });
  };

  return (
    <Button
      variant="contained"
      sx={{ mr: '5px', mt: '5px', textTransform: 'none', bgcolor: '#7699aa' }}
      onClick={downloadPdfDocument}
    >
      Download
    </Button>
  );
};

export default PdfDownloader;
