import axios from "axios";
// export const API_ENDPOINT = "http://localhost:3004";
// const API_ENDPOINT = 'mongodb://Admin:Vhits92@162.241.149.26:27017/?authMechanism=DEFAULT&authSource=admin'

// // on appwork demo server link
export const API_ENDPOINT = "https://api-zaverica.appworkdemo.com";

// export const API_ENDPOINT =
//   "https://th2px9nl-3008.inc1.devtunnels.ms";

let token = localStorage.getItem("auth");

export default axios.create({
  baseURL: API_ENDPOINT,
  headers: {
    "Content-type": "application/x-www-form-urlencoded",
    auth: token,
  },
});
