import React, { useEffect, useState } from "react";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import UserIndex from "../../UserIndex";
import UserSagaActions from "../../../../redux/Usersaga/action";
import { price } from "../../../../component/customComponents/commonFunctions/CommonFunctions";

const { GetSingleReturnOrderSaga } = UserSagaActions;

const Accordion = UserIndex.styled((props) => (
  <UserIndex.MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = UserIndex.styled((props) => (
  <UserIndex.MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = UserIndex.styled(UserIndex.MuiAccordionDetails)(
  ({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: "1px solid rgba(0, 0, 0, .125)",
  })
);

const ViewReturnOrder = () => {
  const [expanded, setExpanded] = useState("panel1");
  const [rate, setRate] = useState({});
  const [key, setKey] = useState([]);
  const [product, setProduct] = useState("");
  const [singleData, setSingleData] = useState([]);

  const dispatch = UserIndex.useDispatch();
  const history = UserIndex.useHistory();
  const params = UserIndex.useParams();
  const navigate = UserIndex.useHistory();
  const loding = UserIndex.useSelector((state) => {
    return state.Usersaga.loading;
  });
  const Token = UserIndex.useSelector((state) => {
    return state.Usersaga.Token;
  });
  const GetSingleReturnOrderData = UserIndex.useSelector((state) => {
    return state.Usersaga.GetSingleReturnOrderData;
  });

  const getSingleReturnOrder = async () => {
    let urlencoded = new URLSearchParams();
    urlencoded.append("id", params.id);
    dispatch(GetSingleReturnOrderSaga(urlencoded, navigate, Token));
  };

  useEffect(() => {
    getSingleReturnOrder();
  }, []);

  useEffect(() => {
    setSingleData(GetSingleReturnOrderData);
  }, [GetSingleReturnOrderData]);

  useEffect(() => {
    setProduct(singleData?.products?.[0]?.productId);
  }, [singleData?.products?.[0]?.productId]);


  const goldFields = () => {
    return (
      <>
        {product?.gold?.length
          ? product?.gold?.map((gold) => {
              return (
                <>
                  <UserIndex.Box className="order-data-main">
                    <UserIndex.Box className="order-inner-main">
                      <UserIndex.Typography
                        component="h3"
                        variant="h3"
                        className="order-sub-title"
                      >
                        Gold Wt(gm) -
                      </UserIndex.Typography>
                      <UserIndex.Typography
                        component="p"
                        variant="p"
                        className="order-sub-data"
                      >
                        {gold.weight}
                      </UserIndex.Typography>
                    </UserIndex.Box>
                    <UserIndex.Box className="order-inner-main">
                      <UserIndex.Typography
                        component="h3"
                        variant="h3"
                        className="order-sub-title"
                      >
                        Gold Purity -
                      </UserIndex.Typography>
                      <UserIndex.Typography
                        component="p"
                        variant="p"
                        className="order-sub-data"
                      >
                        {gold.purity}
                      </UserIndex.Typography>
                    </UserIndex.Box>
                  </UserIndex.Box>
                </>
              );
            })
          : ""}
      </>
    );
  };

  const silverFields = () => {
    return (
      <>
        {product?.silver?.length
          ? product?.silver?.map((silver) => {
              return (
                <>
                  <UserIndex.Box className="order-data-main">
                    <UserIndex.Box className="order-inner-main">
                      <UserIndex.Typography
                        component="h3"
                        variant="h3"
                        className="order-sub-title"
                      >
                        Silver Wt(gm) -
                      </UserIndex.Typography>
                      <UserIndex.Typography
                        component="p"
                        variant="p"
                        className="order-sub-data"
                      >
                        {silver?.weight}
                      </UserIndex.Typography>
                    </UserIndex.Box>
                    <UserIndex.Box className="order-inner-main">
                      <UserIndex.Typography
                        component="h3"
                        variant="h3"
                        className="order-sub-title"
                      >
                        Silver Purity -
                      </UserIndex.Typography>
                      <UserIndex.Typography
                        component="p"
                        variant="p"
                        className="order-sub-data"
                      >
                        {silver?.purity}
                      </UserIndex.Typography>
                    </UserIndex.Box>
                  </UserIndex.Box>
                </>
              );
            })
          : ""}
      </>
    );
  };

  const gemstoneFields = () => {
    return (
      <>
        {product?.gemstone?.length
          ? product?.gemstone?.map((gems) => {
              return (
                <UserIndex.Box className="order-data-main">
                  <UserIndex.Box className="order-inner-main">
                    <UserIndex.Typography
                      component="h3"
                      variant="h3"
                      className="order-sub-title"
                    >
                      Gemstone Wt(gm) -
                    </UserIndex.Typography>
                    <UserIndex.Typography
                      component="p"
                      variant="p"
                      className="order-sub-data"
                    >
                      {gems.total_weight}
                    </UserIndex.Typography>
                  </UserIndex.Box>
                  <UserIndex.Box className="order-inner-main">
                    <UserIndex.Typography
                      component="h3"
                      variant="h3"
                      className="order-sub-title"
                    >
                      Gemstone Purity -
                    </UserIndex.Typography>
                    <UserIndex.Typography
                      component="p"
                      variant="p"
                      className="order-sub-data"
                    >
                      {gems.purity}
                    </UserIndex.Typography>
                  </UserIndex.Box>
                </UserIndex.Box>
              );
            })
          : ""}
      </>
    );
  };

  const diamondFields = () => {
    return (
      <>
        {product?.diamond?.length
          ? product?.diamond?.map((diam) => {
              return (
                <UserIndex.Box className="order-data-main">
                  <UserIndex.Box className="order-inner-main">
                    <UserIndex.Typography
                      component="h3"
                      variant="h3"
                      className="order-sub-title"
                    >
                      Diamond Wt(gm) -
                    </UserIndex.Typography>
                    <UserIndex.Typography
                      component="p"
                      variant="p"
                      className="order-sub-data"
                    >
                      {diam.total_weight}
                    </UserIndex.Typography>
                  </UserIndex.Box>
                  <UserIndex.Box className="order-inner-main">
                    <UserIndex.Typography
                      component="h3"
                      variant="h3"
                      className="order-sub-title"
                    >
                      Diamond Purity -
                    </UserIndex.Typography>
                    <UserIndex.Typography
                      component="p"
                      variant="p"
                      className="order-sub-data"
                    >
                      {diam.purity}
                    </UserIndex.Typography>
                  </UserIndex.Box>
                </UserIndex.Box>
              );
            })
          : ""}
      </>
    );
  };

  const renderFieldMaterialWise = () => {
    switch (true) {
      case product?.gold?.length > 0 &&
        product?.diamond?.length > 0 &&
        product?.materialIdArray?.length === 2:
        return (
          <>
            {goldFields()}
            {diamondFields()}
          </>
        );
      case product?.gold?.length > 0 &&
        product?.gemstone?.length > 0 &&
        product?.materialIdArray?.length === 2:
        return (
          <>
            {goldFields()}
            {gemstoneFields()}
          </>
        );
      case product?.silver?.length > 0 &&
        product?.diamond?.length > 0 &&
        product?.materialIdArray?.length === 2:
        return (
          <>
            {silverFields()}
            {diamondFields()}
          </>
        );
      case product?.silver?.length > 0 &&
        product?.gemstone?.length > 0 &&
        product?.materialIdArray?.length === 2:
        return (
          <>
            {silverFields()}
            {gemstoneFields()}
          </>
        );
      case product?.silver?.length > 0 &&
        product?.gold?.length > 0 &&
        product?.materialIdArray?.length === 2:
        return (
          <>
            {silverFields()}
            {goldFields()}
          </>
        );
      case product?.gemstone?.length > 0 &&
        product?.diamond?.length > 0 &&
        product?.materialIdArray?.length === 2:
        return (
          <>
            {gemstoneFields()}
            {diamondFields()}
          </>
        );
      case product?.gold?.length > 0 &&
        product?.silver?.length > 0 &&
        product?.diamond?.length > 0 &&
        product?.materialIdArray?.length === 3:
        return (
          <>
            {silverFields()}
            {goldFields()}
            {diamondFields()}
          </>
        );
      case product?.silver?.length > 0 &&
        product?.gold?.length > 0 &&
        product?.gemstone?.length > 0 &&
        product?.materialIdArray?.length === 3:
        return (
          <>
            {silverFields()}
            {goldFields()}
            {gemstoneFields()}
          </>
        );
      case product?.gold?.length > 0 &&
        product?.diamond?.length > 0 &&
        product?.gemstone?.length > 0 &&
        product?.materialIdArray?.length === 3:
        return (
          <>
            {goldFields()}
            {diamondFields()}
            {gemstoneFields()}
          </>
        );
      case product?.silver?.length > 0 &&
        product?.diamond?.length > 0 &&
        product?.gemstone?.length > 0 &&
        product?.materialIdArray?.length === 3:
        return (
          <>
            {silverFields()}
            {gemstoneFields()}
            {diamondFields()}
          </>
        );
      case product?.gold?.length > 0 &&
        product?.silver?.length > 0 &&
        product?.gemstone?.length > 0 &&
        product?.diamond?.length > 0 &&
        product?.materialIdArray?.length === 4:
        return (
          <>
            {goldFields()}
            {silverFields()}
            {gemstoneFields()}
            {diamondFields()}
          </>
        );
      case product?.gold?.length > 0:
        return goldFields();
      case product?.silver?.length > 0:
        return silverFields();
      case product?.diamond?.length > 0:
        return diamondFields();
      case product?.gemstone?.length > 0:
        return gemstoneFields();
      default:
        return "";
    }
  };

  return (
    <div>
      <UserIndex.Header />
      <UserIndex.Box className="body-bg">
        <UserIndex.Box className="order-place-sec">
          <UserIndex.Box className="container">
            <UserIndex.Box className="row">
              <UserIndex.Box className="addinfo-text-box pg-main-title-box">
                <UserIndex.Typography
                  variant="h2"
                  className="addinfo-text pg-main-title"
                >
                  My Return Order
                </UserIndex.Typography>
              </UserIndex.Box>
            </UserIndex.Box>
            <UserIndex.Box className="row">
              <UserIndex.Box className="order-place-content-main">
                <UserIndex.Box className="order-content-box">
                  <UserIndex.Box className="order-content-left">
                    <UserIndex.Box className="order-data-main">
                      <UserIndex.Box className="order-inner-main">
                        <UserIndex.Typography
                          component="h3"
                          variant="h3"
                          className="order-sub-title"
                        >
                          Order Id -
                        </UserIndex.Typography>
                        <UserIndex.Typography
                          component="p"
                          variant="p"
                          className="order-sub-data"
                        >
                          {singleData?.order_id?.order_id}
                        </UserIndex.Typography>
                      </UserIndex.Box>
                      <UserIndex.Box className="order-inner-main">
                        <UserIndex.Typography
                          component="h3"
                          variant="h3"
                          className="order-sub-title"
                        >
                          Order Date -
                        </UserIndex.Typography>
                        <UserIndex.Typography
                          component="p"
                          variant="p"
                          className="order-sub-data"
                        >
                          {new Date(singleData?.createdAt).toLocaleDateString()}
                        </UserIndex.Typography>
                      </UserIndex.Box>
                      <UserIndex.Box className="order-inner-main">
                        <UserIndex.Typography
                          component="h3"
                          variant="h3"
                          className="order-sub-title"
                        >
                          Order Item Status -
                        </UserIndex.Typography>
                        <UserIndex.Typography
                          component="p"
                          variant="p"
                          className="order-sub-data"
                        >
                          {singleData?.order_id?.orderItemStatus == "0"
                            ? "Order Placed"
                            : singleData?.order_id?.orderItemStatus == "1"
                            ? "Delivery In Process"
                            : singleData?.order_id?.orderItemStatus == "2"
                            ? "Delivered"
                            : ""}
                          {/* {singleData?.order_id?.orderItemStatus} */}
                        </UserIndex.Typography>
                      </UserIndex.Box>
                    </UserIndex.Box>
                  </UserIndex.Box>
                  {/* <UserIndex.Box className="order-content-right">
                    <UserIndex.Box className="order-data-main">
                      <UserIndex.Box className="order-inner-main">
                        <UserIndex.Typography
                          component="h3"
                          variant="h3"
                          className="order-sub-title"
                        >
                          Total Amount -{singleData?.order_id?.totalAmount}
                        </UserIndex.Typography>
                        <UserIndex.Typography
                          component="p"
                          variant="p"
                          className="order-sub-data"
                        ></UserIndex.Typography>
                      </UserIndex.Box>
                      <UserIndex.Box className="order-inner-main">
                        <UserIndex.Typography
                          component="h3"
                          variant="h3"
                          className="order-sub-title"
                        >
                          Payment Method -
                        </UserIndex.Typography>
                        <UserIndex.Typography
                          component="p"
                          variant="p"
                          className="order-sub-data"
                        >
                          Paid by
                          {singleData?.order_id?.paymentMethod}
                        </UserIndex.Typography>
                      </UserIndex.Box>
                    </UserIndex.Box>
                  </UserIndex.Box> */}
                </UserIndex.Box>
                {/* <UserIndex.Box className="deliver-address-para-box">
                  <UserIndex.Typography
                    variant="p"
                    component="p"
                    className="deliver-address-para"
                  >
                    -
                  </UserIndex.Typography>
                </UserIndex.Box> */}
                <UserIndex.Box className="order-place-accordian">
                  {/* {singleData?.products?.length
                    ? singleData?.products?.map((oid, index) => {
                        return ( */}
                  <Accordion className="po-accordian">
                    <AccordionSummary
                      aria-controls="panel1d-content"
                      id="panel1d-header"
                      className="po-acc-summary"
                    >
                      <UserIndex.Box className="po-summary-title-box">
                        <UserIndex.Typography className="po-sub-title">
                          Order Item Id -
                          {singleData?.order_id?.products[0]?.order_item_id}
                        </UserIndex.Typography>
                      </UserIndex.Box>
                    </AccordionSummary>
                    {/* {oid?.data.length
                              ? oid?.data?.map((data, index) => {
                                  return ( */}
                    <AccordionDetails className="po-acc-details">
                      <UserIndex.Box className="po-inner-accordian-main">
                        <Accordion className="po-accordian po-inner-accordian">
                          <AccordionSummary
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            className="po-acc-summary po-inner-accordian-summary"
                          >
                            <UserIndex.Box className="order-main-row">
                              <UserIndex.Box className="order-inner-main order-divide-row">
                                <UserIndex.Typography
                                  component="h3"
                                  variant="h3"
                                  className="order-sub-title"
                                >
                                  Product Id -
                                </UserIndex.Typography>
                                <UserIndex.Typography
                                  component="p"
                                  variant="p"
                                  className="order-sub-data"
                                >
                                  {
                                    singleData?.order_id?.products[0]
                                      ?.orderProductId
                                  }
                                </UserIndex.Typography>
                              </UserIndex.Box>
                              <UserIndex.Box className="order-inner-main order-divide-row">
                                <UserIndex.Typography
                                  component="h3"
                                  variant="h3"
                                  className="order-sub-title"
                                >
                                  Price -
                                </UserIndex.Typography>
                                <UserIndex.Typography
                                  component="p"
                                  variant="p"
                                  className="order-sub-data"
                                >
                                  {price(singleData?.order_id?.products[0]?.price)}
                                </UserIndex.Typography>
                              </UserIndex.Box>
                              <UserIndex.Box className="order-last-box">
                                <UserIndex.Box className="order-inner-main order-inner-main-last">
                                  <UserIndex.Typography
                                    component="h3"
                                    variant="h3"
                                    className="order-sub-title"
                                  >
                                    Delievery Status -
                                  </UserIndex.Typography>
                                  <UserIndex.Typography
                                    component="p"
                                    variant="p"
                                    className="order-sub-data"
                                  >
                                    {singleData.return_status == "0"
                                      ? "Product Returned"
                                      : singleData.return_status == "1"
                                      ? "Return Product Approved"
                                      : singleData.return_status == "2"
                                      ? "Return Product Rejected"
                                      : singleData.return_status == "3"
                                      ? "-"
                                      : ""}
                                  </UserIndex.Typography>
                                </UserIndex.Box>
                              </UserIndex.Box>
                            </UserIndex.Box>
                          </AccordionSummary>
                          <AccordionDetails className="po-acc-details po-inner-accordian-details">
                            <UserIndex.Box className="accordian-order-content-main">
                              <UserIndex.Box className="order-content-first">
                                <UserIndex.Box className="order-data-main">
                                  <UserIndex.Box className="order-inner-main">
                                    <UserIndex.Typography
                                      component="h3"
                                      variant="h3"
                                      className="order-sub-title"
                                    >
                                      Product Name -
                                    </UserIndex.Typography>
                                    <UserIndex.Typography
                                      component="p"
                                      variant="p"
                                      className="order-sub-data"
                                    >
                                      {
                                        singleData?.order_id?.products[0]
                                          ?.productName
                                      }
                                    </UserIndex.Typography>
                                  </UserIndex.Box>
                                  {renderFieldMaterialWise()}
                                </UserIndex.Box>
                              </UserIndex.Box>
                              <UserIndex.Box className="order-content-second">
                                <UserIndex.Box className="order-inner-main">
                                  <UserIndex.Typography
                                    component="h3"
                                    variant="h3"
                                    className="order-sub-title"
                                  >
                                    Qty -
                                  </UserIndex.Typography>
                                  <UserIndex.Typography
                                    component="p"
                                    variant="p"
                                    className="order-sub-data"
                                  >
                                    {
                                      singleData?.order_id?.products[0]
                                        ?.quantity
                                    }
                                  </UserIndex.Typography>
                                </UserIndex.Box>
                              </UserIndex.Box>
                              <UserIndex.Box className="order-content-third">
                                <UserIndex.Box className="order-data-main">
                                  <UserIndex.Box className="order-inner-main">
                                    <UserIndex.Typography
                                      component="h3"
                                      variant="h3"
                                      className="order-sub-title"
                                    >
                                      Discount -
                                    </UserIndex.Typography>
                                    <UserIndex.Typography
                                      component="p"
                                      variant="p"
                                      className="order-sub-data"
                                    >
                                      {
                                        singleData?.order_id?.products[0]
                                          ?.discount
                                      }
                                      %
                                    </UserIndex.Typography>
                                  </UserIndex.Box>
                                  <UserIndex.Box className="order-inner-main">
                                    <UserIndex.Typography
                                      component="h3"
                                      variant="h3"
                                      className="order-sub-title"
                                    >
                                      Taxes -
                                    </UserIndex.Typography>
                                    <UserIndex.Typography
                                      component="p"
                                      variant="p"
                                      className="order-sub-data"
                                    >
                                      {singleData?.order_id?.products[0]?.taxes}
                                      %
                                    </UserIndex.Typography>
                                  </UserIndex.Box>
                                </UserIndex.Box>
                              </UserIndex.Box>
                            </UserIndex.Box>
                          </AccordionDetails>
                        </Accordion>
                      </UserIndex.Box>
                    </AccordionDetails>
                    {/* );
                                })
                              : "No Record Found"} */}
                  </Accordion>
                  {/* );
                      })
                    : "No Record Found"} */}
                </UserIndex.Box>
              </UserIndex.Box>
            </UserIndex.Box>
          </UserIndex.Box>
        </UserIndex.Box>
      </UserIndex.Box>
      <UserIndex.Footer />
    </div>
  );
};

export default ViewReturnOrder;
