//#region ALL IMPORTS
import React, { useEffect, useState } from "react";
import {
  Button,
  Typography,
  Grid,
  Tooltip,
  Box,
  FormHelperText,
  TextareaAutosize,
  MenuItem,
  FormControl,
  Select,
  TextField,
  Menu,
} from "@mui/material";
import png from "../../../../assets/png";
import AddIcon from "@mui/icons-material/Add";
import DownloadIcon from "@mui/icons-material/Download";
import { styled } from "@mui/material/styles";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import UploadIcon from "@mui/icons-material/Upload";
import { Api } from "../../../../config/api";
import dataService from "../../../../config/dataService";
import { toast } from "react-toastify";
import DisabledByDefaultIcon from "@mui/icons-material/DisabledByDefault";
import ContainedButton from "../../../../component/customComponents/buttons/ContainedButton";
import ImgUploadButton from "../../../../component/customComponents/buttons/ImgUploadButton";
import { downloadImageWithoutBlob } from "../../../../component/customComponents/commonFunctions/CommonFunctions";
import { useFieldArray, useForm, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import {
  ErrorMsg,
  TextAreaErrorMsg,
} from "../../../../component/customComponents/errorMsg/ErrorMsg";
import {
  minNoOfPiecesAvailableInStockLength,
  maxNoOfPiecesAvailableInStockLength,
  minSpecifyLength,
  maxSpecifyLength,
  minAdditionalDetailsLength,
  maxAdditionalDetailsLength,
  minPurityLength,
  maxPurityLength,
  minWeightLength,
  maxWeightLength,
  minMakingChargesLength,
  makingChargesLength,
  minOtherChargesLength,
  maxOtherChargesLength,
  minPieceLength,
  maxPieceLength,
  minQuantityLength,
  maxQuantityLength,
  minPricePerQuantityLength,
  maxPricePerQuantityLength,
  minSuppliersMakingChargesLength,
  maxSuppliersMakingChargesLength,
  minProductDimensionsInnerCircumferenceLength,
  maxProductDimensionsInnerCircumferenceLength,
  minProductDimensionsLengthsLength,
  maxProductDimensionsLengthsLength,
  minProductDimensionsDepthLength,
  maxProductDimensionsDepthLength,
  minProductDimensionsMetaTitleLength,
  maxProductDimensionsMetaTitleLength,
  minProductDimensionsMetaDescriptionLength,
  maxProductDimensionsMetaDescriptionLength,
  NO_WHITE_SPACE_BEGINNING_END,
  NO_DECIMAL,
  PURITY_PERCENTAGE_REGEX,
  ALPHANUMRIC_REGEX,
  WEIGHT_REGEX,
  PURITY_IN_CARAT_REGEX,
  minPurityCaratLength,
  maxPurityCaratLength,
  PRODUCT_WEIGHT_REGEX,
  QUANTITY_REGEX,
} from "../../../../component/customComponents/validation/Validation";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ScrollUp } from "../../../../component/customComponents/goToTop/GoToTop";
//#endregion

import AdminIndex from "../../AdminIndex";
//#region ALL HOOKS AND VARIABALS

const Img = styled("img")({
  display: "block",
  maxWidth: "100%",
  height: "auto",
  maxHeight: "50vh",
  borderRadius: "6px",
});

const {
  getCustomProductDetail,
  getItemData,
  getMaterialList,
  getAllCategoryList,
  updateCustomProduct,
} = AdminIndex.AdminSagaActions;

const UpdateCustomProductDetail = () => {
  let token = localStorage.getItem("auth");
  const dispatch = AdminIndex.useDispatch();
  const navigate = useHistory();
  const { id } = useParams();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [show, setShow] = useState(true);

  const [getData, setGetData] = useState([]);

  const [material, setMaterial] = useState([]);
  const [materialId, setMaterialId] = useState([]);
  const [itemId, setItemId] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [selectItem, setSelectItem] = useState([]);
  const [selectMaterial, setSelectMaterial] = useState([]);
  const [selectCategory, setSelectCategory] = useState([]);
  const [image, setImage] = useState([]);
  const [url, seturl] = useState([]);

  let materialArray = [];

  const [rotateChevron, setRotateChevron] = useState(false);
  const rotate = rotateChevron ? "rotate(90deg)" : "rotate(0)";
  const handleRotate = () => setRotateChevron(!rotateChevron);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
    watch,
  } = useForm({
    defaultValues: {
      goldInput: [
        {
          purity: "",
          weight: "",
        },
      ],

      silverInput: [
        {
          purity: "",
          weight: "",
        },
      ],

      gemstoneInput: [
        {
          piece: "",
          quantity: "",
          purity: "",
          total_weight: "",
          price_of_total_quantity: 0,
          final_price: 0,
        },
      ],

      diamondInput: [
        {
          piece: "",
          quantity: "",
          purity: "",
          total_weight: "",
          price_of_total_quantity: 0,
          final_price: 0,
        },
      ],
    },
  });

  const {
    fields: dynamicGoldInputFields,
    append: dynamicGoldInputAppend,
    remove: dynamicGoldInputRemove,
  } = useFieldArray({ control, name: "goldInput" });

  const {
    fields: dynamicSilverInputFields,
    append: dynamicSilverInputAppend,
    remove: dynamicSilverInputRemove,
  } = useFieldArray({ control, name: "silverInput" });

  const {
    fields: dynamicGemstoneInputFields,
    append: dynamicGemstoneInputAppend,
    remove: dynamicGemstoneInputRemove,
  } = useFieldArray({ control, name: "gemstoneInput" });

  const {
    fields: dynamicDiamondInputFields,
    append: dynamicDiamondInputAppend,
    remove: dynamicDiamondInputRemove,
  } = useFieldArray({ control, name: "diamondInput" });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    handleRotate();
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    watch("material").length > 0 &&
      watch("material").map((item) => {
        let temp = selectMaterial.filter((a) => a.material == item);
        materialArray.push({ material_id: temp[0]?._id });
        setMaterialId(materialArray);
      });
  }, [watch("material"), selectMaterial]);

  useEffect(() => {
    getInfo();
    getItemList();
    getMaterialData();
    getCategoryData();
  }, []);

  useEffect(() => {
    setItemId(getData?.item_id?._id);
    setCategoryId(getData?.category_id?._id);
    setValue("category", getData?.category_id?.category_name);
    if (getData?.materialIdArray) {
      setMaterial(
        getData?.materialIdArray?.map((val) => val?.material_id?.material)
      );
      setValue(
        "material",
        getData?.materialIdArray?.map((val) => val?.material_id?.material)
      );
      setValue("item", getData?.item_id?.item);
      setValue("specify", getData?.specify);
      setValue("additionalDetails", getData?.additional_details);

      if (
        getData?.materialIdArray
          ?.map((val) => val?.material_id?.material)
          .includes("Gold")
      ) {
        setValue("goldInput", getData?.gold);
      }
      if (
        getData?.materialIdArray
          ?.map((val) => val?.material_id?.material)
          .includes("Silver")
      ) {
        setValue("silverInput", getData?.silver);
      }
      if (
        getData?.materialIdArray
          ?.map((val) => val?.material_id?.material)
          .includes("Gemstone")
      ) {
        setValue("gemstoneInput", getData?.gemstone);
      }
      if (
        getData?.materialIdArray
          ?.map((val) => val?.material_id?.material)
          .includes("Diamond")
      ) {
        setValue("diamondInput", getData?.diamond);
      }

      seturl(getData?.image);
      setValue(
        "innerCircumference",
        getData?.product_dimension?.inner_circumference
      );
      setValue("length", getData?.product_dimension?.length);
      setValue("depth", getData?.product_dimension?.depth);
      setValue("metaTitle", getData?.product_dimension?.meta_title);
      setValue("metaDescription", getData?.product_dimension?.meta_description);
    }
  }, [getData]);

  useEffect(() => {
    if (
      getData?.materialIdArray
        ?.map((val) => val?.material_id?.material)
        .includes("Gold")
    ) {
      setValue("goldInput", getData?.gold);
    }
    if (
      getData?.materialIdArray
        ?.map((val) => val?.material_id?.material)
        .includes("Silver")
    ) {
      setValue("silverInput", getData?.silver);
    }
    if (
      getData?.materialIdArray
        ?.map((val) => val?.material_id?.material)
        .includes("Gemstone")
    ) {
      setValue("gemstoneInput", getData?.gemstone);
    }
    if (
      getData?.materialIdArray
        ?.map((val) => val?.material_id?.material)
        .includes("Diamond")
    ) {
      setValue("diamondInput", getData?.diamond);
    }
  }, [material]);
  //#endregion

  //#region ALL EVENT HANDLERS AND FUCTIONS
  const nextPage = () => {
    if (
      watch("noOfPiecesAvailableInStock") !== "" &&
      watch("item") !== "" &&
      watch("material") !== "" &&
      watch("category") !== "" &&
      watch("specify") !== "" &&
      watch("additionalDetails") !== "" &&
      (image != "" || url != "")
    ) {
      setShow(false);
    }
  };

  const getItemList = async () => {
    const urlencoded = new URLSearchParams();
    try {
      dispatch(getItemData(token, urlencoded));
      // const itemData = await dataService.post(Api.Admin.listItem, urlencoded);
      // setSelectItem(itemData.data.data);
    } catch (err) {
      toast.error(err?.message || "Something went wrong");
    }
  };

  const itemData = AdminIndex.useSelector((state) => {
    return state.AdminSaga.itemData;
  });

  useEffect(() => {
    setSelectItem(itemData);
  }, [itemData]);

  const getMaterialData = async () => {
    const urlencoded = new URLSearchParams();
    try {
      dispatch(getMaterialList(token, urlencoded));
      // const materialData = await dataService.post(
      //   Api.Admin.listMaterial,
      //   urlencoded
      // );
      // setSelectMaterial(materialData.data.data);
    } catch (err) {
      toast.error(err?.message || "Something went wrong");
    }
  };

  const materialData = AdminIndex.useSelector((state) => {
    return state.AdminSaga.materialData;
  });

  useEffect(() => {
    setSelectMaterial(materialData);
  }, [materialData]);

  const getCategoryData = async () => {
    const urlencoded = new URLSearchParams();
    try {
      dispatch(getAllCategoryList(token, urlencoded));
      // const categoryData = await dataService.post(
      //   Api.Admin.getAllCategory,
      //   urlencoded
      // );
      // setSelectCategory(categoryData.data.data);
      // setSelectCategoryId(categoryData.data.data.map((id) => id._id));
    } catch (err) {
      toast.error(err?.message || "Something went wrong");
    }
  };

  const categoryData = AdminIndex.useSelector((state) => {
    return state.AdminSaga.categoryData;
  });

  useEffect(() => {
    setSelectCategory(categoryData);
    // setSelectCategoryId(categoryData.data.data.map((id) => id._id));
  }, [categoryData]);

  const handleImg = (e) => {
    setImage([...image, ...e.target.files]);
    let newurldata = [...url];
    [...e.target.files].map((element) => {
      newurldata = [...newurldata, window.URL.createObjectURL(element)];
      seturl(newurldata);
    });
  };

  const removeImg = () => {
    setImage("");
    seturl([]);
  };

  const removeSpecificImg = (element) => {
    const filterIndex = url.indexOf(element);
    if (filterIndex > -1) {
      url.splice(filterIndex, 1);
      seturl(url.filter((element, i) => i !== element));
    }
  };

  const handleAddMaterial = (event) => {
    const { myValue } = event.currentTarget.dataset;
    if (!watch("material").includes(myValue)) {
      setValue("material", [...watch("material"), myValue]);
    }
  };

  const goldFields = () => {
    return (
      <>
        {dynamicGoldInputFields.map((input, index) => (
          <Box sx={{ border: 1, p: 2, my: 1 }}>
            <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={1}>
              <Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
              >
                <Box className="addinfo-text-box-1">
                  <Typography variant="h2" className="addinfo-text-1">
                    Gold
                    {watch("goldInput").length >= 2 ? ` - ${index + 1}` : ""}
                  </Typography>
                </Box>
              </Box>
              <Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 6",
                }}
              >
                <Box className="refrence-product-input addproduct-1-input-box">
                  <Box className="input-box addproduct-input-box">
                    <FormHelperText className="input-lable">
                      Purity(%)
                    </FormHelperText>
                    <TextField
                      autoComplete="off"
                      sx={{
                        // mt: 2,
                        "& .MuiFormHelperText-root": { ml: 0, mt: "5px" },
                      }}
                      fullWidth
                      size="small"
                      className="form-control border-none"
                      name="purity"
                      type="text"
                      {...register(`goldInput.${index}.purity`, {
                        required: <ErrorMsg msg="Please enter Purity(%)" />,
                        minLength: {
                          value: minPurityLength,
                          message: (
                            <ErrorMsg
                              msg={`Minimum ${minPurityLength} Characters Allowed`}
                            />
                          ),
                        },
                        maxLength: {
                          value: maxPurityLength,
                          message: (
                            <ErrorMsg
                              msg={`Maximum ${maxPurityLength} Characters Allowed`}
                            />
                          ),
                        },
                        pattern: {
                          value: NO_WHITE_SPACE_BEGINNING_END,
                          message: (
                            <ErrorMsg
                              msg={
                                "Whitespace is not allowed at beginning or end"
                              }
                            />
                          ),
                        },
                        validate: {
                          isNumber: (val) =>
                            !isNaN(val) || (
                              <ErrorMsg msg={"Must be a Number"} />
                            ),
                          purityPercentage: (val) =>
                            (PURITY_PERCENTAGE_REGEX.test(val) && val != 0) || (
                              <ErrorMsg msg={"Please enter valid Purity"} />
                            ),
                        },
                      })}
                      helperText={
                        <ErrorMessage
                          errors={errors}
                          name={`goldInput.${index}.purity`}
                        />
                      }
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 6",
                }}
              >
                <Box className="refrence-product-input">
                  <Box className="input-box addproduct-1-input-box">
                    <FormHelperText className="input-lable">
                      Weight(gm)
                    </FormHelperText>
                    <TextField
                      autoComplete="off"
                      sx={{
                        // mt: 2,
                        "& .MuiFormHelperText-root": { ml: 0, mt: "5px" },
                      }}
                      fullWidth
                      size="small"
                      className="form-control border-none"
                      name="weight"
                      type="text"
                      {...register(`goldInput.${index}.weight`, {
                        required: <ErrorMsg msg="Please enter Weight(gm)" />,
                        minLength: {
                          value: minWeightLength,
                          message: (
                            <ErrorMsg
                              msg={`Minimum ${minWeightLength} Characters Allowed`}
                            />
                          ),
                        },
                        maxLength: {
                          value: maxWeightLength,
                          message: (
                            <ErrorMsg
                              msg={`Maximum ${maxWeightLength} Characters Allowed`}
                            />
                          ),
                        },
                        pattern: {
                          value: NO_WHITE_SPACE_BEGINNING_END,
                          message: (
                            <ErrorMsg
                              msg={
                                "Whitespace is not allowed at beginning or end"
                              }
                            />
                          ),
                        },
                        validate: {
                          isNumber: (val) =>
                            !isNaN(val) || (
                              <ErrorMsg msg={"Must be a Number"} />
                            ),
                          noZero: (val) =>
                            val != 0 || (
                              <ErrorMsg msg={"Please enter valid Weight"} />
                            ),
                        },
                      })}
                      helperText={
                        <ErrorMessage
                          errors={errors}
                          name={`goldInput.${index}.weight`}
                        />
                      }
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <ContainedButton
                  display={
                    watch("goldInput").length <= 1 ||
                    watch("goldInput").length == undefined
                      ? "none"
                      : "block"
                  }
                  onClick={() => dynamicGoldInputRemove(index)}
                  name="Remove"
                />
              </Box>
            </Box>
          </Box>
        ))}
      </>
    );
  };
  const silverFields = () => {
    return (
      <>
        {dynamicSilverInputFields.map((input, index) => (
          <Box sx={{ border: 1, p: 2, my: 1 }}>
            <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={1}>
              <Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
              >
                <Box className="addinfo-text-box-1">
                  <Typography variant="h2" className="addinfo-text-1">
                    Silver
                    {watch("silverInput").length >= 2 ? ` - ${index + 1}` : ""}
                  </Typography>
                </Box>
              </Box>
              <Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 6",
                }}
              >
                <Box className="refrence-product-input addproduct-1-input-box">
                  <Box className="input-box addproduct-input-box">
                    <FormHelperText className="input-lable">
                      Purity(%)
                    </FormHelperText>
                    <TextField
                      autoComplete="off"
                      sx={{
                        // mt: 2,
                        "& .MuiFormHelperText-root": { ml: 0, mt: "5px" },
                      }}
                      fullWidth
                      size="small"
                      className="form-control border-none"
                      name="purity"
                      type="text"
                      {...register(`silverInput.${index}.purity`, {
                        required: <ErrorMsg msg="Please enter Purity(%)" />,
                        minLength: {
                          value: minPurityLength,
                          message: (
                            <ErrorMsg
                              msg={`Minimum ${minPurityLength} Characters Allowed`}
                            />
                          ),
                        },
                        maxLength: {
                          value: maxPurityLength,
                          message: (
                            <ErrorMsg
                              msg={`Maximum ${maxPurityLength} Characters Allowed`}
                            />
                          ),
                        },
                        pattern: {
                          value: NO_WHITE_SPACE_BEGINNING_END,
                          message: (
                            <ErrorMsg
                              msg={
                                "Whitespace is not allowed at beginning or end"
                              }
                            />
                          ),
                        },
                        validate: {
                          isNumber: (val) =>
                            !isNaN(val) || (
                              <ErrorMsg msg={"Must be a Number"} />
                            ),
                          purityPercentage: (val) =>
                            (PURITY_PERCENTAGE_REGEX.test(val) && val != 0) || (
                              <ErrorMsg msg={"Please enter valid Purity"} />
                            ),
                        },
                      })}
                      helperText={
                        <ErrorMessage
                          errors={errors}
                          name={`silverInput.${index}.purity`}
                        />
                      }
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 6",
                }}
              >
                <Box className="refrence-product-input">
                  <Box className="input-box addproduct-1-input-box">
                    <FormHelperText className="input-lable">
                      Weight(gm)
                    </FormHelperText>
                    <TextField
                      autoComplete="off"
                      sx={{
                        // mt: 2,
                        "& .MuiFormHelperText-root": { ml: 0, mt: "5px" },
                      }}
                      fullWidth
                      size="small"
                      className="form-control border-none"
                      name="weight"
                      type="text"
                      {...register(`silverInput.${index}.weight`, {
                        required: <ErrorMsg msg="Please enter Weight(gm)" />,
                        minLength: {
                          value: minWeightLength,
                          message: (
                            <ErrorMsg
                              msg={`Minimum ${minWeightLength} Characters Allowed`}
                            />
                          ),
                        },
                        maxLength: {
                          value: maxWeightLength,
                          message: (
                            <ErrorMsg
                              msg={`Maximum ${maxWeightLength} Characters Allowed`}
                            />
                          ),
                        },
                        pattern: {
                          value: NO_WHITE_SPACE_BEGINNING_END,
                          message: (
                            <ErrorMsg
                              msg={
                                "Whitespace is not allowed at beginning or end"
                              }
                            />
                          ),
                        },
                        validate: {
                          isNumber: (val) =>
                            !isNaN(val) || (
                              <ErrorMsg msg={"Must be a Number"} />
                            ),
                          noZero: (val) =>
                            val != 0 || (
                              <ErrorMsg msg={"Please enter valid Weight"} />
                            ),
                        },
                      })}
                      helperText={
                        <ErrorMessage
                          errors={errors}
                          name={`silverInput.${index}.weight`}
                        />
                      }
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <ContainedButton
                  display={
                    watch("silverInput").length <= 1 ||
                    watch("silverInput").length == undefined
                      ? "none"
                      : "block"
                  }
                  onClick={() => dynamicSilverInputRemove(index)}
                  name="Remove"
                />
              </Box>
            </Box>
          </Box>
        ))}
      </>
    );
  };
  const gemstoneFields = () => {
    return (
      <>
        {dynamicGemstoneInputFields.map((input, index) => (
          <Box sx={{ border: 1, p: 2, my: 1 }}>
            <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={1}>
              <Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
              >
                <Box className="addinfo-text-box-1">
                  <Typography variant="h2" className="addinfo-text-1">
                    Gemstone
                    {watch("gemstoneInput").length >= 2
                      ? ` - ${index + 1}`
                      : ""}
                  </Typography>
                </Box>
              </Box>
              <Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 6",
                }}
              >
                <Box className="refrence-product-input addproduct-1-input-box">
                  <Box className="input-box addproduct-input-box">
                    <FormHelperText className="input-lable">
                      Name
                    </FormHelperText>
                    <TextField
                      autoComplete="off"
                      sx={{
                        // mt: 2,
                        "& .MuiFormHelperText-root": { ml: 0, mt: "5px" },
                      }}
                      fullWidth
                      size="small"
                      className="form-control border-none"
                      name="piece"
                      type="text"
                      {...register(`gemstoneInput.${index}.piece`, {
                        required: <ErrorMsg msg="Please enter Piece" />,
                        minLength: {
                          value: minPieceLength,
                          message: (
                            <ErrorMsg
                              msg={`Minimum ${minPieceLength} Characters Allowed`}
                            />
                          ),
                        },
                        maxLength: {
                          value: maxPieceLength,
                          message: (
                            <ErrorMsg
                              msg={`Maximum ${maxPieceLength} Characters Allowed`}
                            />
                          ),
                        },
                        pattern: {
                          value: NO_WHITE_SPACE_BEGINNING_END,
                          message: (
                            <ErrorMsg
                              msg={
                                "Whitespace is not allowed at beginning or end"
                              }
                            />
                          ),
                        },
                        // validate: {
                        //   isNumber: (val) =>
                        //     !isNaN(val) || (
                        //       <ErrorMsg msg={'Must be a Number'} />
                        //     ),
                        //   noDecimal: (val) =>
                        //     NO_DECIMAL.test(val) || (
                        //       <ErrorMsg msg={'Must be Non Decimal number'} />
                        //     ),
                        // },
                        validate: {
                          sameValCheck: (val) =>
                            ALPHANUMRIC_REGEX.test(val) || (
                              <ErrorMsg
                                msg={"Only Alphanumric characters are allowed"}
                              />
                            ),
                        },
                      })}
                      helperText={
                        <ErrorMessage
                          errors={errors}
                          name={`gemstoneInput.${index}.piece`}
                        />
                      }
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 6",
                }}
              >
                <Box className="refrence-product-input">
                  <Box className="input-box addproduct-1-input-box">
                    <FormHelperText className="input-lable">
                      Quantity
                    </FormHelperText>
                    <TextField
                      autoComplete="off"
                      sx={{
                        // mt: 2,
                        "& .MuiFormHelperText-root": { ml: 0, mt: "5px" },
                      }}
                      fullWidth
                      size="small"
                      className="form-control border-none"
                      name="quantity"
                      type="text"
                      {...register(`gemstoneInput.${index}.quantity`, {
                        required: <ErrorMsg msg="Please enter Quantity" />,
                        minLength: {
                          value: minQuantityLength,
                          message: (
                            <ErrorMsg
                              msg={`Minimum ${minQuantityLength} Characters Allowed`}
                            />
                          ),
                        },
                        maxLength: {
                          value: maxQuantityLength,
                          message: (
                            <ErrorMsg
                              msg={`Maximum ${maxQuantityLength} Characters Allowed`}
                            />
                          ),
                        },
                        pattern: {
                          value: NO_WHITE_SPACE_BEGINNING_END,
                          message: (
                            <ErrorMsg
                              msg={
                                "Whitespace is not allowed at beginning or end"
                              }
                            />
                          ),
                        },
                        validate: {
                          // isNumber: (val) =>
                          //   !isNaN(val) || (
                          //     <ErrorMsg msg={'Must be a Number'} />
                          //   ),
                          // noDecimal: (val) =>
                          //   NO_DECIMAL.test(val) || (
                          //     <ErrorMsg msg={'Must be Non Decimal number'} />
                          //   ),
                          quantity: (val) =>
                            QUANTITY_REGEX.test(val) || (
                              <ErrorMsg
                                msg={
                                  "Please enter a number between 1 or/and 20."
                                }
                              />
                            ),
                        },
                      })}
                      helperText={
                        <ErrorMessage
                          errors={errors}
                          name={`gemstoneInput.${index}.quantity`}
                        />
                      }
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 6",
                }}
              >
                <Box className="refrence-product-input">
                  <Box className="input-box addproduct-1-input-box">
                    <FormHelperText className="input-lable">
                      Purity(Carat)
                    </FormHelperText>
                    <TextField
                      autoComplete="off"
                      sx={{
                        // mt: 2,
                        "& .MuiFormHelperText-root": { ml: 0, mt: "5px" },
                      }}
                      fullWidth
                      size="small"
                      className="form-control border-none"
                      name="purity"
                      type="text"
                      {...register(`gemstoneInput.${index}.purity`, {
                        required: <ErrorMsg msg="Please enter Purity(Carat)" />,
                        minLength: {
                          value: minPurityCaratLength,
                          message: (
                            <ErrorMsg
                              msg={`Minimum ${minPurityCaratLength} Characters Allowed`}
                            />
                          ),
                        },
                        maxLength: {
                          value: maxPurityCaratLength,
                          message: (
                            <ErrorMsg
                              msg={`Maximum ${maxPurityCaratLength} Characters Allowed`}
                            />
                          ),
                        },
                        pattern: {
                          value: NO_WHITE_SPACE_BEGINNING_END,
                          message: (
                            <ErrorMsg
                              msg={
                                "Whitespace is not allowed at beginning or end"
                              }
                            />
                          ),
                        },
                        validate: {
                          // isNumber: (val) =>
                          //   !isNaN(val) || (
                          //     <ErrorMsg msg={'Must be a Number'} />
                          //   ),
                          purityInCarat: (val) =>
                            PURITY_IN_CARAT_REGEX.test(val) || (
                              <ErrorMsg msg={"Please enter valid Purity"} />
                            ),
                        },
                      })}
                      helperText={
                        <ErrorMessage
                          errors={errors}
                          name={`gemstoneInput.${index}.purity`}
                        />
                      }
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 6",
                }}
              >
                <Box className="refrence-product-input">
                  <Box className="input-box addproduct-1-input-box">
                    <FormHelperText className="input-lable">
                      Weight(gm)
                    </FormHelperText>
                    <TextField
                      autoComplete="off"
                      sx={{
                        // mt: 2,
                        "& .MuiFormHelperText-root": { ml: 0, mt: "5px" },
                      }}
                      fullWidth
                      size="small"
                      className="form-control border-none"
                      name="total_weight"
                      type="text"
                      {...register(`gemstoneInput.${index}.total_weight`, {
                        required: <ErrorMsg msg="Please enter Weight(gm)" />,
                        minLength: {
                          value: minWeightLength,
                          message: (
                            <ErrorMsg
                              msg={`Minimum ${minWeightLength} Characters Allowed`}
                            />
                          ),
                        },
                        maxLength: {
                          value: maxWeightLength,
                          message: (
                            <ErrorMsg
                              msg={`Maximum ${maxWeightLength} Characters Allowed`}
                            />
                          ),
                        },
                        pattern: {
                          value: NO_WHITE_SPACE_BEGINNING_END,
                          message: (
                            <ErrorMsg
                              msg={
                                "Whitespace is not allowed at beginning or end"
                              }
                            />
                          ),
                        },
                        validate: {
                          isNumber: (val) =>
                            !isNaN(val) || (
                              <ErrorMsg msg={"Must be a Number"} />
                            ),
                          noZero: (val) =>
                            val != 0 || (
                              <ErrorMsg msg={"Please enter valid Weight"} />
                            ),
                          weightRegex: (val) =>
                            PRODUCT_WEIGHT_REGEX.test(val) || (
                              <ErrorMsg msg={"Please enter valid Weight"} />
                            ),
                        },
                      })}
                      helperText={
                        <ErrorMessage
                          errors={errors}
                          name={`gemstoneInput.${index}.total_weight`}
                        />
                      }
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <ContainedButton
                  display={watch("gemstoneInput").length <= 1 && "none"}
                  onClick={() => dynamicGemstoneInputRemove(index)}
                  name="Remove"
                />
              </Box>
            </Box>
          </Box>
        ))}
        {/* <Button
          variant="contained"
          onClick={addGemstoneFields}
          sx={{
            my: 2,
            textTransform: 'none',
          }}
        >
          Add more Gemstone
        </Button> */}
      </>
    );
  };
  const diamondFields = () => {
    return (
      <>
        {dynamicDiamondInputFields.map((input, index) => (
          <Box sx={{ border: 1, p: 2, my: 1 }}>
            <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={1}>
              <Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
              ></Box>
              <Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
              >
                <Box className="addinfo-text-box-1">
                  <Typography variant="h2" className="addinfo-text-1">
                    Diamond
                    {watch("diamondInput").length >= 2 ? ` - ${index + 1}` : ""}
                  </Typography>
                </Box>
              </Box>
              <Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 6",
                }}
              >
                <Box className="refrence-product-input addproduct-1-input-box">
                  <Box className="input-box addproduct-input-box">
                    <FormHelperText className="input-lable">
                      Name
                    </FormHelperText>
                    <TextField
                      autoComplete="off"
                      sx={{
                        // mt: 2,
                        "& .MuiFormHelperText-root": { ml: 0, mt: "5px" },
                      }}
                      fullWidth
                      size="small"
                      className="form-control border-none"
                      name="piece"
                      type="text"
                      {...register(`diamondInput.${index}.piece`, {
                        required: <ErrorMsg msg="Please enter Piece" />,
                        minLength: {
                          value: minPieceLength,
                          message: (
                            <ErrorMsg
                              msg={`Minimum ${minPieceLength} Characters Allowed`}
                            />
                          ),
                        },
                        maxLength: {
                          value: maxPieceLength,
                          message: (
                            <ErrorMsg
                              msg={`Maximum ${maxPieceLength} Characters Allowed`}
                            />
                          ),
                        },
                        pattern: {
                          value: NO_WHITE_SPACE_BEGINNING_END,
                          message: (
                            <ErrorMsg
                              msg={
                                "Whitespace is not allowed at beginning or end"
                              }
                            />
                          ),
                        },
                        // validate: {
                        //   isNumber: (val) =>
                        //     !isNaN(val) || (
                        //       <ErrorMsg msg={'Must be a Number'} />
                        //     ),
                        //   noDecimal: (val) =>
                        //     NO_DECIMAL.test(val) || (
                        //       <ErrorMsg msg={'Must be Non Decimal number'} />
                        //     ),
                        // },
                        validate: {
                          sameValCheck: (val) =>
                            ALPHANUMRIC_REGEX.test(val) || (
                              <ErrorMsg
                                msg={"Only Alphanumric characters are allowed"}
                              />
                            ),
                        },
                      })}
                      helperText={
                        <ErrorMessage
                          errors={errors}
                          name={`diamondInput.${index}.piece`}
                        />
                      }
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 6",
                }}
              >
                <Box className="refrence-product-input">
                  <Box className="input-box addproduct-1-input-box">
                    <FormHelperText className="input-lable">
                      Quantity
                    </FormHelperText>
                    <TextField
                      autoComplete="off"
                      sx={{
                        // mt: 2,
                        "& .MuiFormHelperText-root": { ml: 0, mt: "5px" },
                      }}
                      fullWidth
                      size="small"
                      className="form-control border-none"
                      name="quantity"
                      type="text"
                      {...register(`diamondInput.${index}.quantity`, {
                        required: <ErrorMsg msg="Please enter Quantity" />,
                        minLength: {
                          value: minQuantityLength,
                          message: (
                            <ErrorMsg
                              msg={`Minimum ${minQuantityLength} Characters Allowed`}
                            />
                          ),
                        },
                        maxLength: {
                          value: maxQuantityLength,
                          message: (
                            <ErrorMsg
                              msg={`Maximum ${maxQuantityLength} Characters Allowed`}
                            />
                          ),
                        },
                        pattern: {
                          value: NO_WHITE_SPACE_BEGINNING_END,
                          message: (
                            <ErrorMsg
                              msg={
                                "Whitespace is not allowed at beginning or end"
                              }
                            />
                          ),
                        },
                        validate: {
                          // isNumber: (val) =>
                          //   !isNaN(val) || (
                          //     <ErrorMsg msg={'Must be a Number'} />
                          //   ),
                          // noDecimal: (val) =>
                          //   NO_DECIMAL.test(val) || (
                          //     <ErrorMsg msg={'Must be Non Decimal number'} />
                          //   ),
                          quantity: (val) =>
                            QUANTITY_REGEX.test(val) || (
                              <ErrorMsg
                                msg={
                                  "Please enter a number between 1 or/and 20."
                                }
                              />
                            ),
                        },
                      })}
                      helperText={
                        <ErrorMessage
                          errors={errors}
                          name={`diamondInput.${index}.quantity`}
                        />
                      }
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 6",
                }}
              >
                <Box className="refrence-product-input">
                  <Box className="input-box addproduct-1-input-box">
                    <FormHelperText className="input-lable">
                      Purity(Carat)
                    </FormHelperText>
                    <TextField
                      autoComplete="off"
                      sx={{
                        // mt: 2,
                        "& .MuiFormHelperText-root": { ml: 0, mt: "5px" },
                      }}
                      fullWidth
                      size="small"
                      className="form-control border-none"
                      name="purity"
                      type="text"
                      {...register(`diamondInput.${index}.purity`, {
                        required: <ErrorMsg msg="Please enter Purity(Carat)" />,
                        minLength: {
                          value: minPurityCaratLength,
                          message: (
                            <ErrorMsg
                              msg={`Minimum ${minPurityCaratLength} Characters Allowed`}
                            />
                          ),
                        },
                        maxLength: {
                          value: maxPurityCaratLength,
                          message: (
                            <ErrorMsg
                              msg={`Maximum ${maxPurityCaratLength} Characters Allowed`}
                            />
                          ),
                        },
                        pattern: {
                          value: NO_WHITE_SPACE_BEGINNING_END,
                          message: <ErrorMsg msg={'"?" mark is required'} />,
                        },
                        validate: {
                          isNumber: (val) =>
                            !isNaN(val) || (
                              <ErrorMsg msg={"Must be a Number"} />
                            ),
                          purityInCarat: (val) =>
                            PURITY_IN_CARAT_REGEX.test(val) || (
                              <ErrorMsg msg={"Please enter valid Purity"} />
                            ),
                        },
                      })}
                      helperText={
                        <ErrorMessage
                          errors={errors}
                          name={`diamondInput.${index}.purity`}
                        />
                      }
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 6",
                }}
              >
                <Box className="refrence-product-input">
                  <Box className="input-box addproduct-1-input-box">
                    <FormHelperText className="input-lable">
                      Weight(gm)
                    </FormHelperText>
                    <TextField
                      autoComplete="off"
                      sx={{
                        // mt: 2,
                        "& .MuiFormHelperText-root": { ml: 0, mt: "5px" },
                      }}
                      fullWidth
                      size="small"
                      className="form-control border-none"
                      name="total_weight"
                      type="text"
                      {...register(`diamondInput.${index}.total_weight`, {
                        required: <ErrorMsg msg="Please enter  Weight(gm)" />,
                        minLength: {
                          value: minWeightLength,
                          message: (
                            <ErrorMsg
                              msg={`Minimum ${minWeightLength} Characters Allowed`}
                            />
                          ),
                        },
                        maxLength: {
                          value: maxWeightLength,
                          message: (
                            <ErrorMsg
                              msg={`Maximum ${maxWeightLength} Characters Allowed`}
                            />
                          ),
                        },
                        pattern: {
                          value: NO_WHITE_SPACE_BEGINNING_END,
                          message: (
                            <ErrorMsg
                              msg={
                                "Whitespace is not allowed at beginning or end"
                              }
                            />
                          ),
                        },
                        validate: {
                          isNumber: (val) =>
                            !isNaN(val) || (
                              <ErrorMsg msg={"Must be a Number"} />
                            ),
                          noZero: (val) =>
                            val != 0 || (
                              <ErrorMsg msg={"Please enter valid Weight"} />
                            ),
                          weightRegex: (val) =>
                            PRODUCT_WEIGHT_REGEX.test(val) || (
                              <ErrorMsg msg={"Please enter valid Weight"} />
                            ),
                        },
                      })}
                      helperText={
                        <ErrorMessage
                          errors={errors}
                          name={`diamondInput.${index}.total_weight`}
                        />
                      }
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <ContainedButton
                  display={watch("diamondInput").length <= 1 && "none"}
                  onClick={() => dynamicDiamondInputRemove(index)}
                  name="Remove"
                />
              </Box>
            </Box>
          </Box>
        ))}
        {/* <Button
          variant="contained"
          onClick={addDiamondFields}
          sx={{
            my: 2,
            textTransform: 'none',
          }}
        >
          Add More Diamond
        </Button> */}
      </>
    );
  };

  const renderFieldMaterialWise = () => {
    switch (true) {
      case watch("material").includes("Gold") && watch("material").length === 1:
        return goldFields();
      case watch("material").includes("Silver") &&
        watch("material").length === 1:
        return silverFields();
      case watch("material").includes("Diamond") &&
        watch("material").length === 1:
        return diamondFields();
      case watch("material").includes("Gemstone") &&
        watch("material").length === 1:
        return gemstoneFields();
      case watch("material").includes("Gold") &&
        watch("material").includes("Diamond") &&
        watch("material").length === 2:
        return (
          <>
            {goldFields()}
            {diamondFields()}
          </>
        );
      case watch("material").includes("Gold") &&
        watch("material").includes("Gemstone") &&
        watch("material").length === 2:
        return (
          <>
            {goldFields()}
            {gemstoneFields()}
          </>
        );
      case watch("material").includes("Silver") &&
        watch("material").includes("Diamond") &&
        watch("material").length === 2:
        return (
          <>
            {silverFields()}
            {diamondFields()}
          </>
        );
      case watch("material").includes("Silver") &&
        watch("material").includes("Gemstone") &&
        watch("material").length === 2:
        return (
          <>
            {silverFields()}
            {gemstoneFields()}
          </>
        );
      case watch("material").includes("Silver") &&
        watch("material").includes("Gold") &&
        watch("material").length === 2:
        return (
          <>
            {silverFields()}
            {goldFields()}
          </>
        );
      case watch("material").includes("Gemstone") &&
        watch("material").includes("Diamond") &&
        watch("material").length === 2:
        return (
          <>
            {gemstoneFields()}
            {diamondFields()}
          </>
        );
      case watch("material").includes("Gold") &&
        watch("material").includes("Silver") &&
        watch("material").includes("Diamond") &&
        watch("material").length === 3:
        return (
          <>
            {silverFields()}
            {goldFields()}
            {diamondFields()}
          </>
        );
      case watch("material").includes("Gold") &&
        watch("material").includes("Silver") &&
        watch("material").includes("Gemstone") &&
        watch("material").length === 3:
        return (
          <>
            {silverFields()}
            {goldFields()}
            {gemstoneFields()}
          </>
        );
      case watch("material").includes("Gold") &&
        watch("material").includes("Diamond") &&
        watch("material").includes("Gemstone") &&
        watch("material").length === 3:
        return (
          <>
            {goldFields()}
            {diamondFields()}
            {gemstoneFields()}
          </>
        );
      case watch("material").includes("Silver") &&
        watch("material").includes("Diamond") &&
        watch("material").includes("Gemstone") &&
        watch("material").length === 3:
        return (
          <>
            {silverFields()}
            {gemstoneFields()}
            {diamondFields()}
          </>
        );
      case watch("material").includes("Gold") &&
        watch("material").includes("Silver") &&
        watch("material").includes("Gemstone") &&
        watch("material").includes("Diamond") &&
        watch("material").length === 4:
        return (
          <>
            {goldFields()}
            {silverFields()}
            {gemstoneFields()}
            {diamondFields()}
          </>
        );

      default:
        return <h1>No Form found</h1>;
    }
  };

  const getInfo = async (e) => {
    let formdata = new URLSearchParams();
    try {
      formdata.append("id", id);
      dispatch(getCustomProductDetail(token, formdata));
      // const user = await dataService.post(
      //   Api.Admin.getSingleCustomProductAdmin,
      //   formdata,
      //   {
      //     headers: {
      //       auth: token,
      //     },
      //   }
      // );
      // setGetData(user.data.data);
    } catch (err) {
      toast.error(err?.message || "Something went wrong");
    }
  };

  const productDetail = AdminIndex.useSelector((state) => {
    return state.AdminSaga.productDetail;
  });

  useEffect(() => {
    setGetData(productDetail[0]);
  }, [productDetail]);

  let urlarray = [...url];
  let oldurlarray = urlarray.filter((element) => !element.includes("blob"));

  const submit = async (data) => {
    let formdata = new FormData();
    try {
      formdata.append("id", id);
      formdata.append(
        "no_of_pieces_available_inStock",
        data.noOfPiecesAvailableInStock
      );
      formdata.append("item_id", itemId?.trim());
      formdata.append("materialIdArray", JSON.stringify(materialId));
      formdata.append("category_id", categoryId?.trim());
      formdata.append("specify", data.specify?.trim());
      formdata.append("additional_details", data.additionalDetails?.trim());
      formdata.append("oldurlarray", JSON.stringify(oldurlarray));
      image?.map((element) => formdata.append("images", element));
      formdata.append("isSubmitted", "1");
      if (getData?.status == "2") {
        formdata.append("status", "0");
      }

      if (
        watch("material").includes("Gold") &&
        watch("material").length === 1
      ) {
        formdata.append("gold", JSON.stringify(data.goldInput));
      } else if (
        watch("material").includes("Silver") &&
        watch("material").length === 1
      ) {
        formdata.append("silver", JSON.stringify(data.silverInput));
      } else if (
        watch("material").includes("Gemstone") &&
        watch("material").length === 1
      ) {
        formdata.append("gemstoneArray", JSON.stringify(data.gemstoneInput));
      } else if (
        watch("material").includes("Diamond") &&
        watch("material").length === 1
      ) {
        formdata.append("diamondArray", JSON.stringify(data.diamondInput));
      } else if (
        watch("material").includes("Gold") &&
        watch("material").includes("Diamond") &&
        watch("material").length === 2
      ) {
        formdata.append("gold", JSON.stringify(data.goldInput));
        formdata.append("diamondArray", JSON.stringify(data.diamondInput));
      } else if (
        watch("material").includes("Gold") &&
        watch("material").includes("Gemstone") &&
        watch("material").length === 2
      ) {
        formdata.append("gold", JSON.stringify(data.goldInput));
        formdata.append("gemstoneArray", JSON.stringify(data.gemstoneInput));
      } else if (
        watch("material").includes("Silver") &&
        watch("material").includes("Diamond") &&
        watch("material").length === 2
      ) {
        formdata.append("silver", JSON.stringify(data.silverInput));
        formdata.append("diamondArray", JSON.stringify(data.diamondInput));
      } else if (
        watch("material").includes("Silver") &&
        watch("material").includes("Gemstone") &&
        watch("material").length === 2
      ) {
        formdata.append("silver", JSON.stringify(data.silverInput));
        formdata.append("gemstoneArray", JSON.stringify(data.gemstoneInput));
      } else if (
        watch("material").includes("Silver") &&
        watch("material").includes("Gold") &&
        watch("material").length === 2
      ) {
        formdata.append("silver", JSON.stringify(data.silverInput));
        formdata.append("gold", JSON.stringify(data.goldInput));
      } else if (
        watch("material").includes("Diamond") &&
        watch("material").includes("Gemstone") &&
        watch("material").length === 2
      ) {
        formdata.append("diamondArray", JSON.stringify(data.diamondInput));
        formdata.append("gemstoneArray", JSON.stringify(data.gemstoneInput));
      } else if (
        watch("material").includes("Silver") &&
        watch("material").includes("Gold") &&
        watch("material").includes("Diamond") &&
        watch("material").length === 3
      ) {
        formdata.append("silver", JSON.stringify(data.silverInput));
        formdata.append("gold", JSON.stringify(data.goldInput));
        formdata.append("diamondArray", JSON.stringify(data.diamondInput));
      } else if (
        watch("material").includes("Silver") &&
        watch("material").includes("Gold") &&
        watch("material").includes("Gemstone") &&
        watch("material").length === 3
      ) {
        formdata.append("silver", JSON.stringify(data.silverInput));
        formdata.append("gold", JSON.stringify(data.goldInput));
        formdata.append("gemstoneArray", JSON.stringify(data.gemstoneInput));
      } else if (
        watch("material").includes("Diamond") &&
        watch("material").includes("Gold") &&
        watch("material").includes("Gemstone") &&
        watch("material").length === 3
      ) {
        formdata.append("gold", JSON.stringify(data.goldInput));
        formdata.append("gemstoneArray", JSON.stringify(data.gemstoneInput));
        formdata.append("diamondArray", JSON.stringify(data.diamondInput));
      } else if (
        watch("material").includes("Diamond") &&
        watch("material").includes("Silver") &&
        watch("material").includes("Gemstone") &&
        watch("material").length === 3
      ) {
        formdata.append("silver", JSON.stringify(data.silverInput));
        formdata.append("gemstoneArray", JSON.stringify(data.gemstoneInput));
        formdata.append("diamondArray", JSON.stringify(data.diamondInput));
      } else if (
        watch("material").includes("Silver") &&
        watch("material").includes("Gold") &&
        watch("material").includes("Gemstone") &&
        watch("material").includes("Diamond") &&
        watch("material").length === 4
      ) {
        formdata.append("silver", JSON.stringify(data.silverInput));
        formdata.append("gold", JSON.stringify(data.goldInput));
        formdata.append("gemstoneArray", JSON.stringify(data.gemstoneInput));
        formdata.append("diamondArray", JSON.stringify(data.diamondInput));
      }

      formdata.append("inner_circumference", data.innerCircumference?.trim());
      formdata.append("length", data.length?.trim());
      formdata.append("depth", data.depth?.trim());
      formdata.append("meta_title", data.metaTitle?.trim());
      formdata.append("meta_description", data.metaDescription?.trim());

      dispatch(updateCustomProduct(token, formdata, navigate));
      // const userResp = await dataService.post(
      //   Api.Admin.updateCustomProductAdmin,
      //   formdata,
      //   {
      //     headers: {
      //       'Content-Type': 'multipart/form-data',
      //       auth: token,
      //     },
      //   }
      // );

      // if (userResp.data.status === 1) {
      //   toast.success('Custom Product updated successfully');
      //   history.push('/admin/productmanagement/customproducts');
      // }
    } catch (err) {
      toast.error(err?.message || "Something went wrong");
    }
  };
  //#endregion

  //#region RETURN JSX
  return (
    <div>
      <ScrollUp />
      <form onSubmit={handleSubmit(submit)}>
        {show ? (
          <Box className="addproduct-sec">
            <Box className="container">
              <Box sx={{ width: 1 }}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={{ xs: 2, sm: 5, md: 1, lg: 1 }}
                >
                  <Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 12",
                      md: "span 12",
                      lg: "span 12",
                    }}
                  >
                    <Box className="addinfo-text-box-1">
                      <Typography variant="h2" className="addinfo-text-1">
                        Product Description:
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 12",
                      md: "span 12",
                      lg: "span 12",
                    }}
                  >
                    <Box className="addproduct-textarea-main input-box ">
                      <FormHelperText className="input-lable">
                        Product Images
                      </FormHelperText>
                      <Box sx={{ width: "100%", position: "relative" }}>
                        {image === "" ? (
                          <>
                            <Button
                              component="label"
                              sx={{ borderRadius: 0, textTransform: "none" }}
                              variant="contained"
                              onChange={handleImg}
                            >
                              Upload
                              <UploadIcon />
                              <input
                                multiple
                                name="files"
                                hidden
                                accept="image/*"
                                type="file"
                                {...register("files", {
                                  required: (
                                    <ErrorMsg msg="Please select Image" />
                                  ),
                                })}
                              />
                            </Button>
                            <ErrorMessage errors={errors} name="files" />
                          </>
                        ) : (
                          <>
                            <Box sx={{ display: "flex" }}>
                              <ImgUploadButton onChange={handleImg} />
                              <Tooltip title="Remove Images" placement="bottom">
                                <DisabledByDefaultIcon
                                  className="remove-img-icon"
                                  onClick={removeImg}
                                  fontSize="large"
                                  sx={{
                                    color: "background: black(0.5)",
                                    ml: 1,
                                    cursor: "pointer !important",
                                    // position: 'absolute',
                                    // top: '-30px',
                                    // right: '0px',
                                  }}
                                />
                              </Tooltip>
                            </Box>
                          </>
                        )}
                        <Grid container>
                          {url?.map((element, index) => (
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              md={4}
                              lg={3}
                              alignSelf="center"
                            >
                              <Box className="product-img-box product-img-box-set">
                                {/* <a key={element} href={element}  download="Image"
                                  onClick={(e) => downloadImageWithoutBlob(e)}>
                                  <Tooltip title="Tap to Download"> */}
                                <Img
                                  key={element}
                                  sx={{
                                    my: 2,
                                    height: "130px",
                                    width: "200px",
                                    borderRadius: "0px",
                                  }}
                                  src={element}
                                  alt="no image"
                                  id={element}
                                />
                                {/* </Tooltip>
                                </a> */}
                                {/* {element.includes('blob') ? (
                                  ''
                                ) : ( */}
                                {/* <Button className="product-top-btn">
                                    <Tooltip
                                      title="Remove this Image"
                                      placement="bottom"
                                    >
                                      <DisabledByDefaultIcon
                                        className="remove-img-icon"
                                        onClick={() =>
                                          removeSpecificImg(element)
                                        }
                                        sx={{
                                          // bgcolor: 'black(0.5)',
                                          // color: 'black',
                                          mt: 5,
                                        }}
                                      />
                                    </Tooltip>
                                  </Button> */}
                                <Box className="product-download-box"></Box>
                                <Tooltip title="Tap to Download">
                                  <DownloadIcon
                                    className="product-download-btn"
                                    onClick={() => {
                                      let imgElement =
                                        document.getElementById(element);
                                      imgElement.addEventListener(
                                        "click",
                                        downloadImageWithoutBlob
                                      );
                                      imgElement.click();
                                      imgElement.removeEventListener(
                                        "click",
                                        downloadImageWithoutBlob
                                      );
                                    }}
                                  />
                                </Tooltip>
                                <Tooltip
                                  title="Remove this Image"
                                  placement="bottom"
                                >
                                  <DisabledByDefaultIcon
                                    className="product-download-btn1"
                                    onClick={() => removeSpecificImg(element)}
                                    sx={
                                      {
                                        // bgcolor: 'black(0.5)',
                                        // color: 'black',
                                        // mt: 5,
                                      }
                                    }
                                  />
                                </Tooltip>
                                {/* )} */}
                              </Box>
                            </Grid>
                          ))}
                        </Grid>
                      </Box>
                    </Box>
                  </Box>
                  {/* <Box
                    gridColumn={{
                      xs: 'span 12',
                      sm: 'span 6',
                      md: 'span 6',
                      lg: 'span 6',
                    }}
                  >
                    <Box className="refrence-product-input addproduct-input-box">
                      <Box className="input-box addproduct-input-box">
                        <FormHelperText className="input-lable">
                          No of pieces available in stock
                        </FormHelperText>
                        <TextValidator
                          autoComplete="off"
                          fullWidth
                          id="no_of_pieces_available_inStock"
                          className="form-control border-none"
                          value={description?.no_of_pieces_available_inStock}
                          name="no_of_pieces_available_inStock"
                          onChange={(e) => {
                            handleChangeProductDescription(e);
                            errorHandling(e);
                          }}
                          onWheel={(e) => e.target.blur()}
                          type="number"
                          validators={['required', 'minNumber:0']}
                          errorMessages={[
                            'Please enter sub category name',
                            'Please enter Positive number',
                          ]}
                        />
                        <FormHelperText className="error-input-lable">
                          {noOfPiecesAvailableInStockError}
                        </FormHelperText>
                      </Box>
                    </Box>
                  </Box> */}
                  <Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 6",
                      lg: "span 6",
                    }}
                  >
                    <Box className="input-box ">
                      <FormHelperText className="input-lable">
                        Item
                      </FormHelperText>
                      <FormControl className="membership-formcontrol">
                        <Controller
                          name="item"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value } }) => (
                            <Select
                              sx={{
                                "& .MuiFormHelperText-root": {
                                  ml: 0,
                                  mt: "5px",
                                },
                              }}
                              defaultValue="1"
                              labelId="item"
                              className="membershop-select"
                              id="item"
                              value={value}
                              onChange={onChange}
                              inputProps={{ "aria-label": "Without label" }}
                              {...register("item", {
                                required: <ErrorMsg msg="Please select Item" />,
                              })}
                            >
                              {/* {selectItem?.map((item) => (
                                <MenuItem value={item._id}>
                                  {item.item}
                                </MenuItem>
                              ))} */}
                              {selectItem?.map((item) => (
                                <MenuItem
                                  onClick={() => setItemId(item._id)}
                                  value={item.item}
                                >
                                  {item.item}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        />
                        <span>
                          <img
                            src={png.blackdown}
                            className="black-down-img1"
                          ></img>
                        </span>
                      </FormControl>
                      <ErrorMessage errors={errors} name="item" />
                    </Box>
                  </Box>
                  <Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 6",
                      lg: "span 6",
                    }}
                  >
                    <Box className="refrence-product-input addproduct-drop-box">
                      <Box className="input-box">
                        <FormHelperText className="input-lable">
                          Material
                        </FormHelperText>
                        <FormControl className="membership-formcontrol">
                          <Controller
                            name="material"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value } }) => (
                              <Select
                                sx={{
                                  "& .MuiFormHelperText-root": {
                                    ml: 0,
                                    mt: "5px",
                                  },
                                }}
                                multiple
                                className="membershop-select"
                                value={value ? value : []}
                                onChange={onChange}
                                labelId="material"
                                id="material"
                                inputProps={{ "aria-label": "Without label" }}
                                {...register("material", {
                                  required: (
                                    <ErrorMsg msg="Please select Material" />
                                  ),
                                })}
                              >
                                {selectMaterial?.map((material) => (
                                  <MenuItem value={material.material}>
                                    {material.material}
                                  </MenuItem>
                                ))}
                              </Select>
                            )}
                          />
                          <span>
                            <img
                              src={png.blackdown}
                              className="black-down-img1"
                            ></img>
                          </span>
                        </FormControl>
                        <ErrorMessage errors={errors} name="material" />
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 6",
                      lg: "span 6",
                    }}
                  >
                    {/* <Box className="refrence-product-input addproduct-drop-box"> */}
                    <Box className="input-box">
                      <FormHelperText className="input-lable">
                        Category
                      </FormHelperText>
                      <FormControl className="membership-formcontrol">
                        <Controller
                          name="category"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value } }) => (
                            <Select
                              sx={{
                                "& .MuiFormHelperText-root": {
                                  ml: 0,
                                  mt: "5px",
                                },
                              }}
                              className="membershop-select"
                              value={value}
                              labelId="category"
                              onChange={onChange}
                              id="category"
                              inputProps={{ "aria-label": "Without label" }}
                              {...register("category", {
                                required: (
                                  <ErrorMsg msg="Please select Category" />
                                ),
                              })}
                            >
                              {selectCategory?.map((category) => (
                                <MenuItem
                                  onClick={() => setCategoryId(category._id)}
                                  value={category.category_name}
                                >
                                  {category.category_name}
                                  <Typography sx={{ display: "none" }}>
                                    {category._id}
                                  </Typography>
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        />
                        <span>
                          <img
                            src={png.blackdown}
                            className="black-down-img1"
                          ></img>
                        </span>
                      </FormControl>
                      <ErrorMessage errors={errors} name="category" />
                    </Box>
                    {/* </Box> */}
                  </Box>
                  <Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 6",
                      lg: "span 6",
                    }}
                  >
                    <Box className="refrence-product-input">
                      <Box className="input-box addproduct-input-box">
                        <FormHelperText className="input-lable">
                          Specify
                        </FormHelperText>
                        <TextField
                          autoComplete="off"
                          sx={{
                            // mt: 2,
                            "& .MuiFormHelperText-root": { ml: 0, mt: "5px" },
                          }}
                          fullWidth
                          size="small"
                          className="form-control border-none"
                          name="specify"
                          type="text"
                          {...register(`specify`, {
                            required: <ErrorMsg msg="Please enter Specify" />,
                            minLength: {
                              value: minSpecifyLength,
                              message: (
                                <ErrorMsg
                                  msg={`Minimum ${minSpecifyLength} Characters Allowed`}
                                />
                              ),
                            },
                            maxLength: {
                              value: maxSpecifyLength,
                              message: (
                                <ErrorMsg
                                  msg={`Maximum ${maxSpecifyLength} Characters Allowed`}
                                />
                              ),
                            },
                            pattern: {
                              value: NO_WHITE_SPACE_BEGINNING_END,
                              message: (
                                <ErrorMsg
                                  msg={
                                    "Whitespace is not allowed at beginning or end"
                                  }
                                />
                              ),
                            },
                          })}
                          helperText={
                            <ErrorMessage errors={errors} name={`specify`} />
                          }
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 12",
                      md: "span 12",
                      lg: "span 12",
                    }}
                  >
                    <Box className="addproduct-textarea-main input-box ">
                      <FormHelperText className="input-lable">
                        Additional Details
                      </FormHelperText>
                      <TextareaAutosize
                        autoComplete="off"
                        fullWidth
                        size="small"
                        className="addinfo-textarea admin-addproduct-area text-area-control-admin"
                        name="additionalDetails"
                        type="text"
                        {...register(`additionalDetails`, {
                          required: (
                            <TextAreaErrorMsg msg="Please enter Additional Details" />
                          ),
                          minLength: {
                            value: minAdditionalDetailsLength,
                            message: (
                              <TextAreaErrorMsg
                                msg={`Minimum ${minAdditionalDetailsLength} Characters Allowed`}
                              />
                            ),
                          },
                          maxLength: {
                            value: maxAdditionalDetailsLength,
                            message: (
                              <TextAreaErrorMsg
                                msg={`Maximum ${maxAdditionalDetailsLength} Characters Allowed`}
                              />
                            ),
                          },
                          pattern: {
                            value: NO_WHITE_SPACE_BEGINNING_END,
                            message: (
                              <TextAreaErrorMsg
                                msg={
                                  "Whitespace is not allowed at beginning or end"
                                }
                              />
                            ),
                          },
                        })}
                      />
                      <ErrorMessage errors={errors} name="additionalDetails" />
                    </Box>
                  </Box>
                  <Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 12",
                      md: "span 12",
                      lg: "span 12",
                    }}
                  >
                    <Box className="primary-btn-box next-btn-box product-details-btn-box">
                      <Button
                        variant="contained"
                        className=" next-btn-1"
                        onClick={(e) => {
                          nextPage(e);
                        }}
                        type="submit"
                      >
                        Next
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        ) : (
          <Box className="addproduct-1-sec">
            <ScrollUp />
            <Box className="container">
              <Box>{renderFieldMaterialWise()}</Box>
              <>
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    variant="contained"
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                    sx={{ textTransform: "none", mt: 2 }}
                  >
                    Add Material
                    <AddIcon
                      sx={{ ml: 1 }}
                      style={{
                        transform: rotate,
                        transition: "all 0.1s linear",
                      }}
                      onClick={handleRotate}
                    />
                  </Button>
                </Box>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem
                    data-my-value={"Gold"}
                    onClick={(e) => {
                      watch("material").includes("Gold") &&
                      watch("goldInput").length >= 1
                        ? dynamicGoldInputAppend({
                            purity: "",
                            weight: "",
                          })
                        : handleClose();
                      handleAddMaterial(e);
                      handleClose();
                    }}
                  >
                    Gold
                  </MenuItem>
                  <MenuItem
                    data-my-value={"Silver"}
                    onClick={(e) => {
                      watch("material").includes("Silver") &&
                      watch("silverInput").length >= 1
                        ? dynamicSilverInputAppend({
                            purity: "",
                            weight: "",
                          })
                        : handleClose();
                      handleAddMaterial(e);
                      handleClose();
                    }}
                  >
                    Silver
                  </MenuItem>
                  <MenuItem
                    data-my-value={"Diamond"}
                    onClick={(e) => {
                      watch("material").includes("Diamond") &&
                      watch("diamondInput").length >= 1
                        ? dynamicDiamondInputAppend({
                            piece: "",
                            quantity: "",
                            purity: "",
                            total_weight: "",
                            price_of_total_quantity: 0,
                            final_price: 0,
                          })
                        : handleClose();
                      handleAddMaterial(e);
                      handleClose();
                    }}
                  >
                    Diamond
                  </MenuItem>
                  <MenuItem
                    data-my-value={"Gemstone"}
                    onClick={(e) => {
                      watch("material").includes("Gemstone") &&
                      watch("gemstoneInput").length >= 1
                        ? dynamicGemstoneInputAppend({
                            piece: "",
                            quantity: "",
                            purity: "",
                            total_weight: "",
                            price_of_total_quantity: 0,
                            final_price: 0,
                          })
                        : handleClose();
                      handleAddMaterial(e);
                      handleClose();
                    }}
                  >
                    Gemstone
                  </MenuItem>
                </Menu>
              </>
              <Box sx={{ width: 1, mt: 3 }}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={{ xs: 0, sm: 1, md: 1, lg: 1 }}
                >
                  <Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 12",
                      md: "span 12",
                      lg: "span 12",
                    }}
                  >
                    <Box className="addinfo-text-box-1">
                      <Typography
                        variant="h2"
                        className="addinfo-text-1 product-dimension-1"
                      >
                        Product Dimensions
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 4",
                      lg: "span 4",
                    }}
                  >
                    <Box className="refrence-product-input addproduct-1-input-box">
                      <Box className="input-box addproduct-1-input-box">
                        <FormHelperText className="input-lable">
                          Inner circumference
                        </FormHelperText>
                        <TextField
                          autoComplete="off"
                          sx={{
                            // mt: 2,
                            "& .MuiFormHelperText-root": { ml: 0, mt: "5px" },
                          }}
                          fullWidth
                          size="small"
                          className="form-control border-none"
                          name="innerCircumference"
                          type="text"
                          {...register(`innerCircumference`, {
                            required: (
                              <ErrorMsg msg="Please enter Inner circumference" />
                            ),
                            minLength: {
                              value:
                                minProductDimensionsInnerCircumferenceLength,
                              message: (
                                <ErrorMsg
                                  msg={`Minimum ${minProductDimensionsInnerCircumferenceLength} Characters Allowed`}
                                />
                              ),
                            },
                            maxLength: {
                              value:
                                maxProductDimensionsInnerCircumferenceLength,
                              message: (
                                <ErrorMsg
                                  msg={`Maximum ${maxProductDimensionsInnerCircumferenceLength} Characters Allowed`}
                                />
                              ),
                            },
                            pattern: {
                              value: NO_WHITE_SPACE_BEGINNING_END,
                              message: (
                                <ErrorMsg
                                  msg={
                                    "Whitespace is not allowed at beginning or end"
                                  }
                                />
                              ),
                            },
                            validate: {
                              isNumber: (val) =>
                                !isNaN(val) || (
                                  <ErrorMsg msg={"Must be a Number"} />
                                ),
                            },
                          })}
                          helperText={
                            <ErrorMessage
                              errors={errors}
                              name={`innerCircumference`}
                            />
                          }
                        />
                        <span className="addproduct-mm-text">mm</span>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 3",
                      lg: "span 3",
                    }}
                  >
                    <Box className="refrence-product-input">
                      <Box className="input-box addproduct-1-input-box">
                        <FormHelperText className="input-lable">
                          Length
                        </FormHelperText>
                        <TextField
                          autoComplete="off"
                          sx={{
                            // mt: 2,
                            "& .MuiFormHelperText-root": { ml: 0, mt: "5px" },
                          }}
                          fullWidth
                          size="small"
                          className="form-control border-none"
                          name="length"
                          type="text"
                          {...register(`length`, {
                            required: <ErrorMsg msg="Please enter Length" />,
                            minLength: {
                              value: minProductDimensionsLengthsLength,
                              message: (
                                <ErrorMsg
                                  msg={`Minimum ${minProductDimensionsLengthsLength} Characters Allowed`}
                                />
                              ),
                            },
                            maxLength: {
                              value: maxProductDimensionsLengthsLength,
                              message: (
                                <ErrorMsg
                                  msg={`Maximum ${maxProductDimensionsLengthsLength} Characters Allowed`}
                                />
                              ),
                            },
                            pattern: {
                              value: NO_WHITE_SPACE_BEGINNING_END,
                              message: (
                                <ErrorMsg
                                  msg={
                                    "Whitespace is not allowed at beginning or end"
                                  }
                                />
                              ),
                            },
                            validate: {
                              isNumber: (val) =>
                                !isNaN(val) || (
                                  <ErrorMsg msg={"Must be a Number"} />
                                ),
                            },
                          })}
                          helperText={
                            <ErrorMessage errors={errors} name={`length`} />
                          }
                        />
                        <span className="addproduct-mm-text">mm</span>
                      </Box>
                    </Box>
                  </Box>

                  <Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 5",
                      lg: "span 5",
                    }}
                  >
                    <Box className="refrence-product-input">
                      <Box className="input-box addproduct-1-input-box">
                        <FormHelperText className="input-lable">
                          Depth
                        </FormHelperText>
                        <TextField
                          autoComplete="off"
                          sx={{
                            // mt: 2,
                            "& .MuiFormHelperText-root": { ml: 0, mt: "5px" },
                          }}
                          fullWidth
                          size="small"
                          className="form-control border-none"
                          name="depth"
                          type="text"
                          {...register(`depth`, {
                            required: <ErrorMsg msg="Please enter Depth" />,
                            minLength: {
                              value: minProductDimensionsDepthLength,
                              message: (
                                <ErrorMsg
                                  msg={`Minimum ${minProductDimensionsDepthLength} Characters Allowed`}
                                />
                              ),
                            },
                            maxLength: {
                              value: maxProductDimensionsDepthLength,
                              message: (
                                <ErrorMsg
                                  msg={`Maximum ${maxProductDimensionsDepthLength} Characters Allowed`}
                                />
                              ),
                            },
                            pattern: {
                              value: NO_WHITE_SPACE_BEGINNING_END,
                              message: (
                                <ErrorMsg
                                  msg={
                                    "Whitespace is not allowed at beginning or end"
                                  }
                                />
                              ),
                            },
                            validate: {
                              isNumber: (val) =>
                                !isNaN(val) || (
                                  <ErrorMsg msg={"Must be a Number"} />
                                ),
                            },
                          })}
                          helperText={
                            <ErrorMessage errors={errors} name={`depth`} />
                          }
                        />
                        <span className="addproduct-mm-text">mm</span>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 12",
                      lg: "span 12",
                    }}
                  >
                    <Box className="refrence-product-input">
                      <Box className="input-box addproduct-1-input-box">
                        <FormHelperText className="input-lable">
                          Meta Title
                        </FormHelperText>
                        <TextField
                          autoComplete="off"
                          sx={{
                            // mt: 2,
                            "& .MuiFormHelperText-root": { ml: 0, mt: "5px" },
                          }}
                          fullWidth
                          size="small"
                          className="form-control border-none"
                          name="metaTitle"
                          type="text"
                          {...register(`metaTitle`, {
                            required: (
                              <ErrorMsg msg="Please enter Meta Title" />
                            ),
                            minLength: {
                              value: minProductDimensionsMetaTitleLength,
                              message: (
                                <ErrorMsg
                                  msg={`Minimum ${minProductDimensionsMetaTitleLength} Characters Allowed`}
                                />
                              ),
                            },
                            maxLength: {
                              value: maxProductDimensionsMetaTitleLength,
                              message: (
                                <ErrorMsg
                                  msg={`Maximum ${maxProductDimensionsMetaTitleLength} Characters Allowed`}
                                />
                              ),
                            },
                            pattern: {
                              value: NO_WHITE_SPACE_BEGINNING_END,
                              message: (
                                <ErrorMsg
                                  msg={
                                    "Whitespace is not allowed at beginning or end"
                                  }
                                />
                              ),
                            },
                          })}
                          helperText={
                            <ErrorMessage errors={errors} name={`metaTitle`} />
                          }
                        />
                        <Typography
                          variant="p"
                          component="p"
                          className="form-validation-text"
                        >
                          A short 2-3 words
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 12",
                      md: "span 12",
                      lg: "span 12",
                    }}
                  >
                    <Box className="addproduct-textarea-main input-box ">
                      <FormHelperText className="input-lable">
                        Meta Description
                      </FormHelperText>
                      <TextareaAutosize
                        autoComplete="off"
                        sx={{
                          // mt: 2,
                          "& .MuiFormHelperText-root": { ml: 0, mt: "5px" },
                        }}
                        fullWidth
                        size="small"
                        className="addinfo-textarea admin-addproduct-area text-area-control-admin"
                        name="metaDescription"
                        type="text"
                        {...register(`metaDescription`, {
                          required: (
                            <ErrorMsg msg="Please enter Meta Description" />
                          ),
                          minLength: {
                            value: minProductDimensionsMetaDescriptionLength,
                            message: (
                              <ErrorMsg
                                msg={`Minimum ${minProductDimensionsMetaDescriptionLength} Characters Allowed`}
                              />
                            ),
                          },
                          maxLength: {
                            value: maxProductDimensionsMetaDescriptionLength,
                            message: (
                              <ErrorMsg
                                msg={`Maximum ${maxProductDimensionsMetaDescriptionLength} Characters Allowed`}
                              />
                            ),
                          },
                          pattern: {
                            value: NO_WHITE_SPACE_BEGINNING_END,
                            message: (
                              <ErrorMsg
                                msg={
                                  "Whitespace is not allowed at beginning or end"
                                }
                              />
                            ),
                          },
                        })}
                        helperText={
                          <ErrorMessage
                            errors={errors}
                            name={`metaDescription`}
                          />
                        }
                      />
                      <ErrorMessage errors={errors} name="metaDescription" />
                    </Box>
                  </Box>
                  <Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 12",
                      md: "span 12",
                      lg: "span 12",
                    }}
                  >
                    <Box className="primary-btn-box next-back-btn-box">
                      <Button
                        variant="contained"
                        className=" back-btn-1"
                        onClick={() => {
                          setShow(true);
                        }}
                      >
                        Back
                      </Button>
                      <Button
                        variant="contained"
                        className=" next-btn-1"
                        type="submit"
                        // onClick={handleSave}
                      >
                        Submit
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </form>
    </div>
  );
};

export default UpdateCustomProductDetail;
//#endregion
