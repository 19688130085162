import React, { useState } from "react";
import {
  Button,
  FormControl,
  InputLabel,
  FormHelperText,
  Box,
  Card,
  IconButton,
  InputAdornment,
  OutlinedInput,
  TextField,
  CircularProgress,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Api } from "../../../../config/api";
import dataService from "../../../../config/dataService";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import Png from "../../../../assets/png";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { ErrorMsg1 } from "../../../../component/customComponents/errorMsg/ErrorMsg";
import { useSelector, useDispatch } from "react-redux";
import actions from "../../../../redux/adminSaga/action";

const { resetPasswordSaga } = actions;
//#region ALL HOOKS AND VARIABALS
const ResetPassword = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const navigate = useHistory();
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const adminData = useSelector((state) => {
    return state.AdminSaga.forgotPasswordData;
  });

  const loading = useSelector((state) => {
    return state.AdminSaga.loading;
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowPassword2 = () => setShowPassword2((show) => !show);
  //#endregion

  //#region ALL EVENT HANDLERS AND  FUCTIONS
  const onSubmit = async (data) => {
    if (data.password != data.confirmPassword) {
      toast.error("Password and confirm password should be same");
    } else {
      const userData = JSON.parse(localStorage.getItem("admin-details"));

      const urlencoded = new URLSearchParams();
      urlencoded.append("email", adminData?.email);
      urlencoded.append("newPassword", data.password);
      dispatch(resetPasswordSaga(urlencoded, navigate));
      // await dataService.post(Api.Admin.resetPassword, urlencoded).then((res) => {
      //   if (res.data.status === 1) {
      //     toast.success(`${res.data.message}`);
      //     localStorage.removeItem('auth');
      //     history.push('/admin/login');
      //   }
      // });
    }
  };
  //#endregion

  //#region RETURN JSX
  return (
    <Card
      variant="outlined"
      sx={{
        margin: "auto",
        marginTop: { sm: "10%", xs: "50%" },
        width: { sm: 500, xs: "100%" },
        maxWidth: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            margin: "auto",
            width: { sm: 500, xs: "100%" },
            maxWidth: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Box>
            <img src={Png.footerlogo} className="login-zaverica-logo"></img>
          </Box>
          <h1 style={{ textAlign: "center" }}>Reset Password</h1>

          <FormControl
            variant="outlined"
            sx={{
              margin: "auto",
              mt: 2,
              width: { sm: "70%", xs: "85%" },
            }}
          >
            <InputLabel htmlFor="outlined-adornment-password">
              Password
            </InputLabel>
            <OutlinedInput
              autoComplete="off"
              // required
              fullWidth
              label="Password"
              id="password"
              type={showPassword ? "text" : "password"}
              name="password"
              {...register("password", {
                required: <ErrorMsg1 msg="Password is required" />,
              })}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <ErrorMessage errors={errors} name="password" />
          </FormControl>

          <FormControl
            variant="outlined"
            sx={{
              margin: "auto",
              my: 2,
              width: { sm: "70%", xs: "85%" },
            }}
          >
            <InputLabel htmlFor="outlined-adornment-password">
              Confirm Password
            </InputLabel>
            <OutlinedInput
              variant="outlined"
              autoComplete="off"
              sx={{
                "& .MuiFormHelperText-root": { ml: 0, mt: "5px" },
              }}
              label="Confirm Password"
              type={showPassword2 ? "text" : "password"}
              name="confirmPassword"
              {...register("confirmPassword", {
                required: <ErrorMsg1 msg="Confirm Password is required" />,
              })}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword2}
                    edge="end"
                  >
                    {showPassword2 ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <ErrorMessage errors={errors} name="confirmPassword" />
          </FormControl>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              sx={{ my: 2, width: "150px", textTransform: "none" }}
              variant="contained"
              type="submit"
            >
              Reset
              {loading && (
                <CircularProgress sx={{ color: "white", ml: 1 }} size="20px" />
              )}
            </Button>
          </Box>
        </Box>
      </form>
    </Card>
  );
};
export default ResetPassword;
//#endregion
