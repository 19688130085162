import React, {useState, useEffect} from 'react';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Typography, Button, Box, Table, TableBody, TableContainer, TableHead, TableRow, Paper, ToggleButton, InputBase, Container, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import ApproveButton from '../../../../component/customComponents/buttons/ApproveButton';
import DeclineButton from '../../../../component/customComponents/buttons/DeclineButton';
import NoDataFound from '../../../../component/customComponents/noDataFound/NoDataFound';

//#region ALL HOOKS AND VARIABALS
function createData(date, user, seller, product, price, action) {
  return { date, user, seller, product, price, action };
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function BidProducts() {
  const [age, setAge] = React.useState('');
  const [flag, setFlag] = useState(false);    
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    setInterval(() => {
      setFlag(true);
    }, 500);
  }, []);


  const rows = [
    createData('28-04-2022', 'Name', 'Name', 'Name', '500000', 'Approved'),
    createData('28-04-2022', 'Name', 'Name', 'Name', '500000', 'Approved'),
    createData('28-04-2022', 'Name', 'Name', 'Name', '500000', 'Approved'),
    createData('28-04-2022', 'Name', 'Name', 'Name', '500000', 'Approved'),
    createData('28-04-2022', 'Name', 'Name', 'Name', '500000', 'Approved'),
  ];  
//#endregion

//#region ALL EVENT HANDLERS AND  FUCTIONS 
const handleChange = (event) => {
  setAge(event.target.value);
};
//#endregion

//#region RETURN JSX 
  return (
    <>
      <Container sx={{ mt: 2, mb: 5 }} maxWidth="false">
        <Typography variant="h4" align="center" sx={{ fontWeight: 'bold' }}>
          Bid Management
        </Typography>
        <br />
          <Box
            sx={{
              display: 'flex',
              bgcolor: 'background.paper',
              borderRadius: 1,
            }}
          >
            <Box sx={{flexGrow: 1}}>
            <FormControl
              fullWidth
              size="small"
              sx={{ my: 1, maxWidth: '230px' }}
            >
              <InputLabel id="demo-simple-select-label">Select</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={age}
                label="Select"
                onChange={handleChange}
                sx={{
                    borderRadius: 0
                }}
              >
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl>
            </Box>
            <Box sx={{ alignSelf: 'center' }}>
              <Button
                variant="outlined"
                sx={{
                  borderRadius: 0,
                  textTransform: 'none',
                }}
              >
                Filter <FilterAltIcon />
              </Button>
            </Box>
            
          </Box>
       
        <br />
        <TableContainer
          elevation={0}
          sx={{
            maxWidth: {
              xs: '365px',
              sm: '600px',
              md: '100%',
              lg: '100%',
            },
            '&::-webkit-scrollbar': {
              width: 10,
            },
            '&::-webkit-scrollbar': {
              height: '8px',
              width: '12px',
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: '#fff',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(224, 224, 224, 1)',
              borderRadius: '30px',
              width: '20px',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              background: '#B0B0B0',
            },
          }}
          component={Paper}
        >
          <Table
            sx={{
              width: {
                xs: 'max-content',
                sm: 'max-content',
                md: '100%',
                lg: '100%',
              },
            }}
            size="small"
          >
            <TableHead>
              <StyledTableRow>
                <StyledTableCell align="center">Date</StyledTableCell>
                <StyledTableCell align="center">User</StyledTableCell>
                <StyledTableCell align="center">Seller</StyledTableCell>
                <StyledTableCell align="center">Product</StyledTableCell>
                <StyledTableCell align="center">Price</StyledTableCell>
                <StyledTableCell align="right">Action</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {rows.length 
              ? rows.map((row) => (
                <StyledTableRow key={row.name}>
                  <StyledTableCell align="center">{row.date}</StyledTableCell>
                  <StyledTableCell align="center">{row.user}</StyledTableCell>
                  <StyledTableCell align="center">{row.seller}</StyledTableCell>
                  <StyledTableCell align="center">{row.product}</StyledTableCell>
                  <StyledTableCell align="center">{row.price}</StyledTableCell>
                  <StyledTableCell align="right">
                    <ApproveButton />
                    <DeclineButton className='common-button' />
                  </StyledTableCell>
                </StyledTableRow>
              )) : ''}
            </TableBody>
          </Table>
          {!rows.length  && flag ? <NoDataFound /> : ''}
        </TableContainer>
      </Container>
    </>
  );
}
//#endregion
