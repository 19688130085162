import axios from "axios";
import { API_ENDPOINT } from "../config/dataService";
import { toast } from "react-toastify";

// const BASE_URL = "http://35.177.56.74:3004";
const BASE_URL = API_ENDPOINT;

// const BASE_URL = "https://psychic-zebra-7v975g5xjxp4cpwqv-3004.app.github.dev";

const setHeadersWithAccessToken = (token) => {
  axios.defaults.headers.post["auth"] = token;
};
const setGetHeadersWithAccessToken = (token) => {
  axios.defaults.headers.get["auth"] = token;
};
const setHeadersWithContentType = () => {
  axios.defaults.headers.post["Content-Type"] =
    "application/x-www-form-urlencoded";
};
const setHeadersWithContentType2 = () => {
  axios.defaults.headers.post["Content-Type"] = "multipart/form-data";
};
const handleError = (error) =>
  new Promise(() => {
    console.log(error?.response?.data?.message);
    toast.error(error?.response?.data?.message);
  });

// Login API
export const loginApiHandler = (params) => {
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/loginAdmin`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Forgot password
export const forgotPasswordApiHandler = (params) => {
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/admin/forgotPassword`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Verify OTP
export const otpVerifyApiHandler = (params) => {
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/admin/verifyOtp`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Reset password
export const resetPasswordApiHandler = (params) => {
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/admin/resetPassword`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Get count for dashboard
export const getDynamicCountApiHandler = (params, token) => {
  setGetHeadersWithAccessToken(token);
  setHeadersWithContentType();
  return axios
    .get(`${BASE_URL}/getDynamicCount`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Get all users
export const getUserListApiHandler = (params, token) => {
  setGetHeadersWithAccessToken(token);
  setHeadersWithContentType();
  return axios
    .get(`${BASE_URL}/getUserList`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Get specific user details
export const getUserDetailApiHandler = (params, token) => {
  setGetHeadersWithAccessToken(token);
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/userDetails`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Delete user
export const deleteUserApiHandler = (params, token) => {
  setHeadersWithAccessToken(token);
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/deleteUser`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Get all product
export const getPendingProductApiHandler = (params, token) => {
  setGetHeadersWithAccessToken(token);
  setHeadersWithContentType();
  return axios
    .get(`${BASE_URL}/getAllProduct`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Get all custom product
export const getAllCustomProductApiHandler = (params, token) => {
  setHeadersWithAccessToken(token);
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/recievedCustomProductAdmin`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Get single product
export const getProductDetailApiHandler = (params, token) => {
  setHeadersWithAccessToken(token);
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/getProduct`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Approve product
export const approveProductApiHandler = (params, token) => {
  setHeadersWithAccessToken(token);
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/productApprove`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Reject product
export const rejectProductApiHandler = (params, token) => {
  setHeadersWithAccessToken(token);
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/productRejected`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Delete product
export const deleteProductApiHandler = (params, token) => {
  setHeadersWithAccessToken(token);
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/deleteProduct`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Get all items data
export const getItemApiHandler = (params, token) => {
  setHeadersWithAccessToken(token);
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/listItem`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Get all material data
export const getMaterialDataApiHandler = (params, token) => {
  setHeadersWithAccessToken(token);
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/listMaterial`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Get all category data
export const getCategoryDataApiHandler = (params, token) => {
  setHeadersWithAccessToken(token);
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/getAllCategory`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Update product
export const updateProductApiHandler = (params, token) => {
  setHeadersWithAccessToken(token);
  setHeadersWithContentType2();
  return axios
    .post(`${BASE_URL}/updateProduct`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Approve custom project by admin
export const approveCustomProductApiHandler = (params, token) => {
  setHeadersWithAccessToken(token);
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/approveCustomProductAdmin`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Reject custom product by admin
export const rejectCustomProductApiHandler = (params, token) => {
  setHeadersWithAccessToken(token);
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/rejectedCustomProductAdmin`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Get single custom product details
export const getCustomProductDetailApiHandler = (params, token) => {
  setHeadersWithAccessToken(token);
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/getSingleCustomProductAdmin`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Update custom products
export const updateCustomProductApiHandler = (params, token) => {
  setHeadersWithAccessToken(token);
  setHeadersWithContentType2();
  return axios
    .post(`${BASE_URL}/updateCustomProductAdmin`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Get all order list
export const getAllOrdersApiHandler = (params, token) => {
  setHeadersWithAccessToken(token);
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/getallorder`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Get specific order details
export const getOrderDetailApiHandler = (params, token) => {
  setHeadersWithAccessToken(token);
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/viewOrderAdmin`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Approve Order
export const approveOrderApiHandler = (params, token) => {
  setHeadersWithAccessToken(token);
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/approveOrder`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Reject Order
export const rejectOrderApiHandler = (params, token) => {
  setHeadersWithAccessToken(token);
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/rejectOrder`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Reject Order
export const getOrderRatingApiHandler = (params, token) => {
  setHeadersWithAccessToken(token);
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/getAllRating`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Update Order Rating
export const updateOrderRatingApiHandler = (params, token) => {
  setHeadersWithAccessToken(token);
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/addUpdateRatingAdmin`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Update Order Status
export const updateOrderStatusApiHandler = (params, token) => {
  setHeadersWithAccessToken(token);
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/updateOrderstatus`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Get All Return Order
export const getAllReturnOrdersApiHandler = (params, token) => {
  setHeadersWithAccessToken(token);
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/getAllReturnOrderAdmin`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Get Single Return Order Detail
export const getReturnOrderDetailApiHandler = (params, token) => {
  setHeadersWithAccessToken(token);
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/getSingleReturnOrderAdmin`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Accept Return Order
export const acceptReturnOrderApiHandler = (params, token) => {
  setHeadersWithAccessToken(token);
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/approveRetrunOrder`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Reject Return Order
export const rejectReturnOrderApiHandler = (params, token) => {
  setHeadersWithAccessToken(token);
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/rejectRetrunOrder`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Update Order Transaction Id
export const updateOrderTransactionIdApiHandler = (params, token) => {
  setHeadersWithAccessToken(token);
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/updateTransactionStatus`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Delete single category
export const deleteCategoryApiHandler = (params, token) => {
  setHeadersWithAccessToken(token);
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/deleteCategory`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Add Category
export const addCategoryApiHandler = (params, token) => {
  setHeadersWithAccessToken(token);
  setHeadersWithContentType2();
  return axios
    .post(`${BASE_URL}/addCategory`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Get single category
export const getSingleCategoryApiHandler = (params, token) => {
  setHeadersWithAccessToken(token);
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/getCategory`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Update Category
export const updateCategoryApiHandler = (params, token) => {
  setHeadersWithAccessToken(token);
  setHeadersWithContentType2();
  return axios
    .post(`${BASE_URL}/updateCategory`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Delete single material
export const deleteMaterialApiHandler = (params, token) => {
  setHeadersWithAccessToken(token);
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/deleteMaterial`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Add Material
export const addMaterialApiHandler = (params, token) => {
  setHeadersWithAccessToken(token);
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/addMaterial`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Get single material
export const getSingleMaterialApiHandler = (params, token) => {
  setHeadersWithAccessToken(token);
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/singleMaterial`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Update Material
export const updateMaterialApiHandler = (params, token) => {
  setHeadersWithAccessToken(token);
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/updateMaterial`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Delete single item
export const deleteItemApiHandler = (params, token) => {
  setHeadersWithAccessToken(token);
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/deleteItem`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Add Item
export const addItemApiHandler = (params, token) => {
  setHeadersWithAccessToken(token);
  setHeadersWithContentType2();
  return axios
    .post(`${BASE_URL}/addItem`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Get single Item
export const getSingleItemApiHandler = (params, token) => {
  setHeadersWithAccessToken(token);
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/singleItem`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Update Item
export const updateItemApiHandler = (params, token) => {
  setHeadersWithAccessToken(token);
  setHeadersWithContentType2();
  return axios
    .post(`${BASE_URL}/updateItem`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Get Payment List
export const paymentListApiHandler = (params, token) => {
  setHeadersWithAccessToken(token);
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/listPayment`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Get Refund Chart Data
export const refundChartDataApiHandler = (params, token) => {
  setHeadersWithAccessToken(token);
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/chartDetailsForRefund`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Get Refund Chart Data
export const revenueChartDataApiHandler = (params, token) => {
  setHeadersWithAccessToken(token);
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/totalRevenueGenerated`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Get Chart Data
export const chartDataApiHandler = (params, token) => {
  setHeadersWithAccessToken(token);
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/chartDetails`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Get Bank Details
export const getBankDetailApiHandler = (params, token) => {
  setHeadersWithAccessToken(token);
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/listBankDetails`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Get Making Charge Details
export const getMakingChargeApiHandler = (params, token) => {
  setHeadersWithAccessToken(token);
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/listZavericaMakingCharges`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Get Making Charge Details
export const getNotificationDetailApiHandler = (params, token) => {
  setHeadersWithAccessToken(token);
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/listNotificationDetails`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Get Customer Services List
export const getCustomerServicesListApiHandler = (params, token) => {
  setHeadersWithAccessToken(token);
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/listCustomerServices`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Get Customer Services List
export const getAllCMSApiHandler = (params, token) => {
  setHeadersWithAccessToken(token);
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/getAllCms`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Get Term and Condition
export const getTermConditionApiHandler = (params, token) => {
  setHeadersWithAccessToken(token);
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/getCms`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Update Term and Condition
export const updateTermConditionApiHandler = (params, token) => {
  setHeadersWithAccessToken(token);
  setHeadersWithContentType2();
  return axios
    .post(`${BASE_URL}/createTermsAndCondition`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Get FAQ List
export const getFAQListApiHandler = (params, token) => {
  setHeadersWithAccessToken(token);
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/listFaqs`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Delete FAQ
export const deleteFAQApiHandler = (params, token) => {
  setHeadersWithAccessToken(token);
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/deleteFaqs`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Add FAQ
export const addFAQApiHandler = (params, token) => {
  setHeadersWithAccessToken(token);
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/addFaqs`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Update FAQ
export const updateFAQApiHandler = (params, token) => {
  setHeadersWithAccessToken(token);
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/updateFaqs`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Get FAQ details
export const getFAQDetailApiHandler = (params, token) => {
  setHeadersWithAccessToken(token);
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/singleFaqs`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Get Blog List
export const getBlogListApiHandler = (params, token) => {
  setHeadersWithAccessToken(token);
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/listBlog`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Get Guideline List
export const getGuidelineListApiHandler = (params, token) => {
  setHeadersWithAccessToken(token);
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/listGuideline`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Update Blog
export const updateBlogApiHandler = (params, token) => {
  setHeadersWithAccessToken(token);
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/updateBlog`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Update Blog
export const updateCustomerServiceApiHandler = (params, token) => {
  setHeadersWithAccessToken(token);
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/updateCustomerServices`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Get Deal List
export const getDealListApiHandler = (params, token) => {
  setHeadersWithAccessToken(token);
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/listTopDeals`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Delete single deal
export const deleteDealApiHandler = (params, token) => {
  setHeadersWithAccessToken(token);
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/deleteTopDeal`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Add deal
export const addDealApiHandler = (params, token) => {
  setHeadersWithAccessToken(token);
  setHeadersWithContentType2();
  return axios
    .post(`${BASE_URL}/addTopDeals`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Get Single Deal
export const getSingleDealApiHandler = (params, token) => {
  setHeadersWithAccessToken(token);
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/singleTopDeal`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Add deal
export const updateDealApiHandler = (params, token) => {
  setHeadersWithAccessToken(token);
  setHeadersWithContentType2();
  return axios
    .post(`${BASE_URL}/updateTopDeals`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Get Benefit Data
export const getBenefitListApiHandler = (params, token) => {
  setHeadersWithAccessToken(token);
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/listBenefits`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Add Benefit
export const addBenefitApiHandler = (params, token) => {
  setHeadersWithAccessToken(token);
  setHeadersWithContentType2();
  return axios
    .post(`${BASE_URL}/updateTopDeals`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Add Gideline
export const addGuidelineApiHandler = (params, token) => {
  setHeadersWithAccessToken(token);
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/addGuideline`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Get product description list
export const getProductDescriptionListApiHandler = (params, token) => {
  setHeadersWithAccessToken(token);
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/listProductDescription`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Add Product Description
export const addProductDescriptionApiHandler = (params, token) => {
  setHeadersWithAccessToken(token);
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/addProductDescription`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Delete Product Description
export const deleteProductDescriptionApiHandler = (params, token) => {
  setHeadersWithAccessToken(token);
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/deleteProductDescription`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Get single product description list
export const getSingleProductDescriptionApiHandler = (params, token) => {
  setHeadersWithAccessToken(token);
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/singleProductDescription`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Update Product Description
export const updateProductDescriptionApiHandler = (params, token) => {
  setHeadersWithAccessToken(token);
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/updateProductDescription`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Get product pricing list
export const getProductPricingApiHandler = (params, token) => {
  setHeadersWithAccessToken(token);
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/listProductPricing`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Delete Product Pricing
export const deleteProductPricingApiHandler = (params, token) => {
  setHeadersWithAccessToken(token);
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/deleteProductPricing`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Add Product Pricing
export const addProductPricingApiHandler = (params, token) => {
  setHeadersWithAccessToken(token);
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/addProductPricing`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Update Product Pricing
export const updateProductPricingApiHandler = (params, token) => {
  setHeadersWithAccessToken(token);
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/updateProductPricing`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Get single product pricing
export const getSingleProductPricingApiHandler = (params, token) => {
  setHeadersWithAccessToken(token);
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/singleProductPricing`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Get product purity
export const getProductPurityApiHandler = (params, token) => {
  setHeadersWithAccessToken(token);
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/listProductPurity`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Delete Product Purity
export const deleteProductPurityApiHandler = (params, token) => {
  setHeadersWithAccessToken(token);
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/deleteProductPurity`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Add Product Purity
export const addProductPurityApiHandler = (params, token) => {
  setHeadersWithAccessToken(token);
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/addProductPurity`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Update Product Purity
export const updateProductPurityApiHandler = (params, token) => {
  setHeadersWithAccessToken(token);
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/updateProductPurity`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Get single product purity
export const getSingleProductPurityApiHandler = (params, token) => {
  setHeadersWithAccessToken(token);
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/singleProductPurity`, params)
    .then((e) => e.data)
    .catch(handleError);
};

// Update Blog Term & Condition
export const updateBlogTermConditionApiHandler = (params, token) => {
  setHeadersWithAccessToken(token);
  setHeadersWithContentType();
  return axios
    .post(`${BASE_URL}/addBenefits`, params)
    .then((e) => e.data)
    .catch(handleError);
};
