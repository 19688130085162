//#region ALL IMPORTS
import React, { useState, useEffect } from "react";
import { Grid, Container, Typography, Button, TextField } from "@mui/material";
import { useHistory, useParams } from "react-router-dom";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { Api } from "../../../../config/api";
import dataService from "../../../../config/dataService";
import { toast } from "react-toastify";
import Png from "../../../../assets/png";
import { Box } from "@mui/system";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { ErrorMsg } from "../../../../component/customComponents/errorMsg/ErrorMsg";
import {
  makingChargesLength,
  minMakingChargesLength,
  MAKING_CHARGES,
} from "../../../../component/customComponents/validation/Validation";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import UploadIcon from "@mui/icons-material/Upload";
//#endregion

//#region ALL HOOKS AND VARIABALS
const MakingCharges = (props) => {
  let token = localStorage.getItem("auth");
  const [id, setId] = useState("");
  const [getData, setGetData] = useState("");

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
    watch,
  } = useForm({ mode: "onChange" });
  //#endregion

  //#region ALL EVENT HANDLERS AND  FUCTIONS

  const getMakingCharges = async () => {
    let urlencoded = new URLSearchParams();
    if (props.id) {
      try {
        urlencoded.append("id", props.id);
        const Data = await dataService.post(
          Api.Admin.singleZavericaMakingCharges,
          urlencoded,
          {
            headers: {
              auth: token,
            },
          }
        );
        setGetData(Data.data.data);
        setId(Data.data.data._id);
      } catch (err) {
        toast.error(err?.message || "Something went wrong1");
      }
    }
  };

  useEffect(() => {
    if (props.id !== "" && props.id != " ") {
      getMakingCharges();
    }
  }, []);

  useEffect(() => {
    setValue("makingCharges", getData?.zaverica_making_charges);
  }, [getData]);

  const handleSave = async (data) => {
    try {
      let urlencoded = new URLSearchParams();
      if (props.id !== "") {
        urlencoded.append("id", props.id);
        urlencoded.append("zaverica_making_charges", data?.makingCharges);

        const makingChargesData = await dataService.post(
          Api.Admin.updateZavericaMakingCharges,
          urlencoded,
          {
            headers: {
              auth: token,
            },
          }
        );

        if (makingChargesData.data.status == 1) {
          toast.success("Making charges Updated successfully");
          window.location.reload();
        }
      } else {
        urlencoded.append("zaverica_making_charges", data?.makingCharges);

        const makingChargesData = await dataService.post(
          Api.Admin.addZavericaMakingCharges,
          urlencoded,
          {
            headers: {
              auth: token,
            },
          }
        );

        if (makingChargesData.data.status == 1) {
          toast.success("Making charges added successfully");
          window.location.reload();
        }
      }
    } catch (err) {
      toast.error(err?.message || "Something went wrong");
    }
  };
  //#endregion

  //#region RETURN JSX
  return (
    <>
      <form onSubmit={handleSubmit(handleSave)}>
        <Container maxWidth="false">
          <Typography sx={{ my: 2 }} align="center" variant="h4">
            Zaverica's Making Charges
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <TextField
              label="Making Charges"
              InputLabelProps={{
                shrink: watch("makingCharges") ? true : false,
              }}
              autoComplete="off"
              sx={{
                mt: 2,
                width: { lg: "300px", xs: "150px" },
                "& .MuiFormHelperText-root": { ml: 0, mt: "5px" },
              }}
              size="small"
              fullWidth
              className="form-control2 border-none"
              name="makingCharges"
              onWheel={(e) => e.target.blur()}
              type="text"
              {...register("makingCharges", {
                required: <ErrorMsg msg="Please enter Making Charges" />,
                minLength: {
                  value: minMakingChargesLength,
                  message: (
                    <ErrorMsg
                      msg={`Minimum ${minMakingChargesLength} Characters Allowed`}
                    />
                  ),
                },
                maxLength: {
                  value: makingChargesLength,
                  message: (
                    <ErrorMsg
                      msg={`Maximum ${makingChargesLength} Characters Allowed`}
                    />
                  ),
                },
                pattern: {
                  value: MAKING_CHARGES,
                  message: <ErrorMsg msg="Please enter valid Making Charges" />,
                },
              })}
              helperText={<ErrorMessage errors={errors} name="makingCharges" />}
            ></TextField>
          </Box>
          <Typography align="center" variant="h4">
            <Button
              sx={{
                my: 5,
                width: "125px",
                textTransform: "none",
              }}
              variant="contained"
              type="submit"
            >
              {props.id == "" ? "Submit" : "Update"}
            </Button>
          </Typography>
        </Container>
      </form>
    </>
  );
};

export default MakingCharges;
//#endregion
