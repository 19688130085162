//#region ALL IMPORTS
import React, { useEffect, useState } from 'react';
import {
  Button,
  Typography,
  Grid,
  Modal,
  Box,
  FormHelperText,
  TextField,
  TextareaAutosize,
  Tooltip,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import DownloadIcon from '@mui/icons-material/Download';
import {
  useHistory,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min';

import Loader from '../../../../component/customComponents/loader/Loader';
import { Api } from '../../../../config/api';
import dataService from '../../../../config/dataService';
import { ToastContainer, toast } from 'react-toastify';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import DeclineButton from '../../../../component/customComponents/buttons/DeclineButton';
import ApproveButton from '../../../../component/customComponents/buttons/ApproveButton';
import ContainedButton from '../../../../component/customComponents/buttons/ContainedButton';
import { downloadImageWithoutBlob } from '../../../../component/customComponents/commonFunctions/CommonFunctions';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import {
  ErrorMsg,
  TextAreaErrorMsg,
} from '../../../../component/customComponents/errorMsg/ErrorMsg';
import {
  minRejectReasonLength,
  rejectReasonLength,
  NO_WHITE_SPACE_BEGINNING_END,
  ALPHABET_IS_MUST_REGEX,
} from '../../../../component/customComponents/validation/Validation';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ScrollUp } from '../../../../component/customComponents/goToTop/GoToTop';
//#endregion
import AdminIndex from '../../AdminIndex';

//#region ALL HOOKS AND VARIABALS

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 367,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const Img = styled('img')({
  display: 'block',
  maxWidth: '100%',
  height: 'auto',
  maxHeight: '50vh',
  borderRadius: '6px',
});


const { getProductDetail, approveProduct, rejectProduct, deleteProduct } = AdminIndex.AdminSagaActions;
const ViewPendingProduct = () => {
  let token = localStorage.getItem('auth');
  const history = useHistory();
  const { id } = useParams();
  const goldObject = {
    purity: '',
    weight: '',
    making_charges: '',
    other_charges: '',
  };
  const silverObject = {
    purity: '',
    weight: '',
    making_charges: '',
    other_charges: '',
  };
  const gemstoneObject = {
    piece: '',
    quantity: '',
    purity: '',
    total_weight: '',
    price_per_quantity: '',
    price_of_total_quantity: 0,
    final_price: 0,
    making_charge: '',
  };
  const diamondObject = {
    piece: '',
    quantity: '',
    purity: '',
    total_weight: '',
    price_per_quantity: '',
    price_of_total_quantity: 0,
    final_price: 0,
    making_charge: '',
  };
  const [goldInputFields, setGoldInputFields] = useState([goldObject]);
  const [silverInputFields, setSilverInputFields] = useState([silverObject]);
  const [gemstoneInputFields, setGemstoneInputFields] = useState([
    gemstoneObject,
  ]);
  const [diamondInputFields, setDiamondInputFields] = useState([diamondObject]);

  const [show, setShow] = useState(true);

  const staticInputsOneForm = {
    piece: '',
    quantity: '',
    purity: '',
    total_weight: '',
    price_per_quantity: '',
    price_of_total_quantity: 0,
    final_price: 0,
    making_charge: '',
  };
  const [inputFields, setInputFields] = useState([staticInputsOneForm]);
  
  const dispatch = AdminIndex.useDispatch();
  const navigate = useHistory();
  const [open, setOpen] = useState(false);
  const [rejectReason, setRejectReason] = useState('');
  const [error, setError] = useState('');
  const [details, setDetails] = useState(false);

  const [getData, setGetData] = useState([]);
  const [material, setMaterial] = useState([]);
  const [category, setCategory] = useState('');
  const [url, seturl] = useState([]);

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
    watch,
  } = useForm();

  const [description, setDescription] = useState({
    no_of_pieces_available_inStock: '',
    item: '',
    specify: '',
    additional_details: '',
  });

  const [dimension, setDimension] = useState({
    inner_circumference: '',
    length: '',
    depth: '',
    meta_title: '',
    meta_description: '',
  });

  const [goldProperties, setGoldProperties] = useState({
    purity: '',
    weight: '',
    making_charges: '',
    other_charges: '',
  });

  const [silverProperties, setSilverProperties] = useState({
    purity: '',
    weight: '',
    making_charges: '',
    other_charges: '',
  });

  const [gemstoneProperties, setGemstoneProperties] = useState({
    type_of_gemstone: '',
    no_of_gemstones: '',
    total_price: '',
    additional_information: '',
  });

  const [diamondProperties, setDiamondProperties] = useState({
    type_of_diamonds: '',
    total_no_Of_Diamonds: '',
    total_weight: '',
    additional_information: '',
  });

  useEffect(() => {
    getInfo();
  }, []);

  useEffect(() => {
    setCategory(getData?.category_id?.category_name);
    if (getData?.materialIdArray) {
      setMaterial(
        getData?.materialIdArray.map((val) => val.material_id?.material)
      );
      setDescription({
        no_of_pieces_available_inStock: getData?.no_of_pieces_available_inStock,
        item: getData?.item_id?.item,
        specify: getData?.specify,
        additional_details: getData?.additional_details,
      });
      setGoldProperties({
        purity: getData?.gold?.purity,
        weight: getData?.gold?.weight,
        making_charges: getData?.gold?.making_charges,
        other_charges: getData?.gold?.other_charges,
      });
      setSilverProperties({
        purity: getData?.silver?.purity,
        weight: getData?.silver?.weight,
        making_charges: getData?.silver?.making_charges,
        other_charges: getData?.silver?.other_charges,
      });
      setGoldInputFields(getData?.gold);
      setSilverInputFields(getData?.silver);
      setGemstoneInputFields(getData?.gemstone);
      setDiamondInputFields(getData?.diamond);
      seturl(getData?.image);
      setDimension({
        inner_circumference: getData?.product_dimension?.inner_circumference,
        length: getData?.product_dimension?.length,
        depth: getData?.product_dimension?.depth,
        meta_title: getData?.product_dimension?.meta_title,
        meta_description: getData?.product_dimension?.meta_description,
      });
    }
  }, [getData]);


  useEffect(() => {
    if (material === 'Gold') {
      setGoldProperties({
        purity: getData?.gold?.purity,
        weight: getData?.gold?.weight,
        making_charges: getData?.gold?.making_charges,
        other_charges: getData?.gold?.other_charges,
      });
    }
    if (material === 'Silver') {
      setSilverProperties({
        purity: getData?.silver?.purity,
        weight: getData?.silver?.weight,
        making_charges: getData?.silver?.making_charges,
        other_charges: getData?.silver?.other_charges,
      });
    }
    if (material === 'Gemstone') {
      setGemstoneProperties({
        additional_information: getData?.gemstone.additional_information,
        no_of_gemstones: getData?.gemstone.no_of_gemstones,
        total_price: getData?.gemstone.total_price,
        type_of_gemstone: getData?.gemstone.type_of_gemstone,
      });
    }
    if (material === 'Diamond') {
      setDiamondProperties({
        additional_information: getData?.diamond.additional_information,
        total_no_Of_Diamonds: getData?.diamond.total_no_Of_Diamonds,
        total_weight: getData?.diamond.total_weight,
        type_of_diamonds: getData?.diamond.type_of_diamonds,
      });
    }
    if (getData?.materialIdArray) {
      setGoldInputFields(getData?.gold);
      setSilverInputFields(getData?.silver);
      setGemstoneInputFields(getData?.gemstone);
      setDiamondInputFields(getData?.diamond);
    }
  }, [material]);
  //#endregion

  //#region ALL EVENT HANDLERS AND FUCTIONS
  // useEffect(() => {
  //   if (id) getInfoOne();
  //   errorHandler();
  // }, [id, error]);

  const errorHandler = () => {
    if (rejectReason !== '') {
      setError('');
    }
  };

  const getInfoOne = async () => {
    const urlencoded = new URLSearchParams();
    try {
      urlencoded.append('id', id);
      const user = await dataService.post(Api.Admin.getProduct, urlencoded, {
        headers: {
          auth: token,
        },
      });

      setDetails(user.data.data);
    } catch (err) {
      toast.error(err?.message || 'Something went wrong');
    }
  };

  const nextPage = () => {
    setShow(false);
  };

  const removeProduct = async (id) => {
    let formdata = new URLSearchParams();
    formdata.append('id', id);

    try {
      dispatch(deleteProduct(token,formdata,navigate));
      // const deletePendingProduct = await dataService.post(
      //   Api.Admin.deleteProduct,
      //   formdata,
      //   {
      //     headers: {
      //       auth: token,
      //     },
      //   }
      // );
      // if (deletePendingProduct.data.status == 1) {
      //   toast.success('Product deleted successfully');
      //   history.push(`/admin/productmanagement/pendingproducts`);
      // }
    } catch (err) {
      toast.error(err?.message || 'Something went wrong');
    }
    getInfo();
  };

  const loading = AdminIndex.useSelector((state) => {
    return state.AdminSaga.loading;
  });

  const handleChangeReject = (e) => {
    setRejectReason(e.target.value);
  };

  const reject = async (data) => {
    let urlencoded = new URLSearchParams();
    try {
      urlencoded.append('id', id);
      urlencoded.append('reasonOfRejection', data.rejectReason?.trim());
      dispatch(rejectProduct(token,urlencoded,navigate));
      // const rejData = await dataService.post(
      //   Api.Admin.productRejected,
      //   urlencoded,
      //   {
      //     headers: {
      //       auth: token,
      //     },
      //   }
      // );

      // if (rejData.data.status === 1) {
      //   toast.success('Product rejected successfully');
      //   history.push('/admin/productmanagement/rejectedproducts');
      // }
    } catch (err) {
      toast.error(err?.message || 'Something went wrong');
    }
  };

  const getInfo = async (e) => {
    let formdata = new URLSearchParams();
    try {
      formdata.append('id', id);
      
      dispatch(getProductDetail(token,formdata));
      // const user = await dataService.post(Api.Admin.getProduct, formdata);

      // setGetData(user.data.data[0]);
    } catch (err) {
      toast.error(err?.message || 'Something went wrong');
    }
  };

  const productDetail = AdminIndex.useSelector((state) => {
    return state.AdminSaga.productDetail;
  });

  useEffect(() => {
    setGetData(productDetail[0]);
  }, [productDetail]);


  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const accept = async () => {
    const urlencoded = new URLSearchParams();
    try {
      urlencoded.append('id', id);
      dispatch(approveProduct(token,urlencoded,navigate));
      // const acceptData = await dataService.post(
      //   Api.Admin.productApprove,
      //   urlencoded,
      //   {
      //     headers: {
      //       auth: token,
      //     },
      //   }
      // );

      // if (acceptData.data.status === 1) {
      //   toast.success('Product accepted successfully');
      //   history.push('/admin/productmanagement/uploadedproducts');
      // }
    } catch (err) {
      toast.error(err?.message || 'Something went wrong');
    }
  };

  const goldFields = () => {
    return (
      <>
        {goldInputFields.map((input, index) => (
          <Box sx={{ border: 1, p: 2, my: 1 }}>
            <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={1}>
              <Box
                gridColumn={{
                  xs: 'span 12',
                  sm: 'span 12',
                  md: 'span 12',
                  lg: 'span 12',
                }}
              >
                <Box className="addinfo-text-box-1">
                  <Typography variant="h2" className="addinfo-text-1">
                    Gold{goldInputFields.length >= 2 ? ' - ' : ''}
                    {goldInputFields.length >= 2 ? index + 1 : ''}
                  </Typography>
                </Box>
              </Box>
              <Box
                gridColumn={{
                  xs: 'span 12',
                  sm: 'span 6',
                  md: 'span 6',
                  lg: 'span 6',
                }}
              >
                <Box className="refrence-product-input addproduct-1-input-box">
                  <Box className="input-box addproduct-input-box">
                    <FormHelperText className="input-lable">
                      Purity(%)
                    </FormHelperText>
                    <TextValidator
                      disabled
                      autoComplete="off"
                      fullWidth
                      id="purity"
                      className="form-control border-none"
                      value={input.purity}
                      name="purity"
                      type="number"
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                gridColumn={{
                  xs: 'span 12',
                  sm: 'span 6',
                  md: 'span 6',
                  lg: 'span 6',
                }}
              >
                <Box className="refrence-product-input">
                  <Box className="input-box addproduct-1-input-box">
                    <FormHelperText className="input-lable">
                      Weight(gm)
                    </FormHelperText>
                    <TextValidator
                      disabled
                      autoComplete="off"
                      fullWidth
                      id="weight"
                      className="form-control border-none"
                      value={input.weight}
                      name="weight"
                      type="number"
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                gridColumn={{
                  xs: 'span 12',
                  sm: 'span 6',
                  md: 'span 6',
                  lg: 'span 6',
                }}
              >
                <Box className="refrence-product-input">
                  <Box className="input-box addproduct-1-input-box">
                    <FormHelperText className="input-lable">
                      Making Charges(%)
                    </FormHelperText>
                    <TextValidator
                      disabled
                      autoComplete="off"
                      fullWidth
                      id="making_charges"
                      className="form-control border-none"
                      value={input.making_charges}
                      name="making_charges"
                      type="number"
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                gridColumn={{
                  xs: 'span 12',
                  sm: 'span 6',
                  md: 'span 6',
                  lg: 'span 6',
                }}
              >
                <Box className="refrence-product-input">
                  <Box className="input-box addproduct-1-input-box">
                    <FormHelperText className="input-lable">
                      Other Charges
                    </FormHelperText>
                    <TextValidator
                      disabled
                      autoComplete="off"
                      fullWidth
                      id="other_charges"
                      className="form-control border-none"
                      value={input.other_charges}
                      name="other_charges"
                      type="number"
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        ))}
      </>
    );
  };
  const silverFields = () => {
    return (
      <>
        {silverInputFields.map((input, index) => (
          <Box sx={{ border: 1, p: 2, my: 1 }}>
            <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={1}>
              <Box
                gridColumn={{
                  xs: 'span 12',
                  sm: 'span 12',
                  md: 'span 12',
                  lg: 'span 12',
                }}
              >
                <Box className="addinfo-text-box-1">
                  <Typography variant="h2" className="addinfo-text-1">
                    Silver{silverInputFields.length >= 2 ? ' - ' : ''}
                    {silverInputFields.length >= 2 ? index + 1 : ''}
                  </Typography>
                </Box>
              </Box>
              <Box
                gridColumn={{
                  xs: 'span 12',
                  sm: 'span 6',
                  md: 'span 6',
                  lg: 'span 6',
                }}
              >
                <Box className="refrence-product-input addproduct-1-input-box">
                  <Box className="input-box addproduct-input-box">
                    <FormHelperText className="input-lable">
                      Purity(%)
                    </FormHelperText>
                    <TextValidator
                      disabled
                      autoComplete="off"
                      fullWidth
                      id="purity"
                      className="form-control border-none"
                      value={input.purity}
                      name="purity"
                      type="number"
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                gridColumn={{
                  xs: 'span 12',
                  sm: 'span 6',
                  md: 'span 6',
                  lg: 'span 6',
                }}
              >
                <Box className="refrence-product-input">
                  <Box className="input-box addproduct-1-input-box">
                    <FormHelperText className="input-lable">
                      Weight(gm)
                    </FormHelperText>
                    <TextValidator
                      disabled
                      autoComplete="off"
                      fullWidth
                      id="weight"
                      className="form-control border-none"
                      value={input.weight}
                      name="weight"
                      type="number"
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                gridColumn={{
                  xs: 'span 12',
                  sm: 'span 6',
                  md: 'span 6',
                  lg: 'span 6',
                }}
              >
                <Box className="refrence-product-input">
                  <Box className="input-box addproduct-1-input-box">
                    <FormHelperText className="input-lable">
                      Making Charges(%)
                    </FormHelperText>
                    <TextValidator
                      disabled
                      autoComplete="off"
                      fullWidth
                      id="making_charges"
                      className="form-control border-none"
                      value={input.making_charges}
                      name="making_charges"
                      type="number"
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                gridColumn={{
                  xs: 'span 12',
                  sm: 'span 6',
                  md: 'span 6',
                  lg: 'span 6',
                }}
              >
                <Box className="refrence-product-input">
                  <Box className="input-box addproduct-1-input-box">
                    <FormHelperText className="input-lable">
                      Other Charges
                    </FormHelperText>
                    <TextValidator
                      disabled
                      autoComplete="off"
                      fullWidth
                      id="other_charges"
                      className="form-control border-none"
                      value={input.other_charges}
                      name="other_charges"
                      type="number"
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        ))}
      </>
    );
  };
  const gemstoneFields = () => {
    return (
      <>
        {gemstoneInputFields.map((input, index) => (
          <Box sx={{ border: 1, p: 2, my: 1 }}>
            <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={1}>
              <Box
                gridColumn={{
                  xs: 'span 12',
                  sm: 'span 12',
                  md: 'span 12',
                  lg: 'span 12',
                }}
              >
                <Box className="addinfo-text-box-1">
                  <Typography variant="h2" className="addinfo-text-1">
                    Gemstone{gemstoneInputFields.length >= 2 ? ' - ' : ''}
                    {gemstoneInputFields.length >= 2 ? index + 1 : ''}
                  </Typography>
                </Box>
              </Box>
              <Box
                gridColumn={{
                  xs: 'span 12',
                  sm: 'span 6',
                  md: 'span 6',
                  lg: 'span 6',
                }}
              >
                <Box className="refrence-product-input addproduct-1-input-box">
                  <Box className="input-box addproduct-input-box">
                    <FormHelperText className="input-lable">
                      Name
                    </FormHelperText>
                    <TextValidator
                      disabled
                      autoComplete="off"
                      fullWidth
                      id="piece"
                      className="form-control border-none"
                      value={input.piece}
                      name="piece"
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                gridColumn={{
                  xs: 'span 12',
                  sm: 'span 6',
                  md: 'span 6',
                  lg: 'span 6',
                }}
              >
                <Box className="refrence-product-input">
                  <Box className="input-box addproduct-1-input-box">
                    <FormHelperText className="input-lable">
                      Quantity
                    </FormHelperText>
                    <TextValidator
                      disabled
                      autoComplete="off"
                      fullWidth
                      id="quantity"
                      className="form-control border-none"
                      value={input.quantity}
                      name="quantity"
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                gridColumn={{
                  xs: 'span 12',
                  sm: 'span 6',
                  md: 'span 6',
                  lg: 'span 6',
                }}
              >
                <Box className="refrence-product-input">
                  <Box className="input-box addproduct-1-input-box">
                    <FormHelperText className="input-lable">
                      Purity(Carat)
                    </FormHelperText>
                    <TextValidator
                      disabled
                      autoComplete="off"
                      fullWidth
                      id="purity"
                      className="form-control border-none"
                      value={input.purity}
                      name="purity"
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                gridColumn={{
                  xs: 'span 12',
                  sm: 'span 6',
                  md: 'span 6',
                  lg: 'span 6',
                }}
              >
                <Box className="refrence-product-input">
                  <Box className="input-box addproduct-1-input-box">
                    <FormHelperText className="input-lable">
                      Weight(gm)
                    </FormHelperText>
                    <TextValidator
                      disabled
                      autoComplete="off"
                      fullWidth
                      id="total_weight"
                      className="form-control border-none"
                      value={input.total_weight}
                      name="total_weight"
                    />
                  </Box>
                </Box>
              </Box>
              <>
                <Box
                  gridColumn={{
                    xs: 'span 12',
                    sm: 'span 6',
                    md: 'span 6',
                    lg: 'span 6',
                  }}
                >
                  <Box className="refrence-product-input">
                    <Box className="input-box addproduct-1-input-box">
                      <FormHelperText className="input-lable">
                        Price per quantity(Rs)
                      </FormHelperText>
                      <TextValidator
                        disabled
                        autoComplete="off"
                        fullWidth
                        id="price_per_quantity"
                        className="form-control border-none"
                        value={input.price_per_quantity}
                        name="price_per_quantity"
                      />
                    </Box>
                  </Box>
                </Box>
                <Box
                  gridColumn={{
                    xs: 'span 12',
                    sm: 'span 6',
                    md: 'span 6',
                    lg: 'span 6',
                  }}
                >
                  <Box className="addproduct-textarea-main input-box addproduct-1-textarea-main">
                    <FormHelperText className="input-lable">
                      Suppliers Making Charges(Rs)
                    </FormHelperText>
                    <TextValidator
                      disabled
                      autoComplete="off"
                      fullWidth
                      id="making_charge"
                      className="form-control border-none"
                      value={input.making_charge}
                      name="making_charge"
                    />
                  </Box>
                </Box>
              </>
            </Box>
          </Box>
        ))}
      </>
    );
  };
  const diamondFields = () => {
    return (
      <>
        {diamondInputFields.map((input, index) => (
          <Box sx={{ border: 1, p: 2, my: 1 }}>
            <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={1}>
              <Box
                gridColumn={{
                  xs: 'span 12',
                  sm: 'span 12',
                  md: 'span 12',
                  lg: 'span 12',
                }}
              ></Box>
              <Box
                gridColumn={{
                  xs: 'span 12',
                  sm: 'span 12',
                  md: 'span 12',
                  lg: 'span 12',
                }}
              >
                <Box className="addinfo-text-box-1">
                  <Typography variant="h2" className="addinfo-text-1">
                    Diamond{diamondInputFields.length >= 2 ? ' - ' : ''}
                    {diamondInputFields.length >= 2 ? index + 1 : ''}
                  </Typography>
                </Box>
              </Box>
              <Box
                gridColumn={{
                  xs: 'span 12',
                  sm: 'span 6',
                  md: 'span 6',
                  lg: 'span 6',
                }}
              >
                <Box className="refrence-product-input addproduct-1-input-box">
                  <Box className="input-box addproduct-input-box">
                    <FormHelperText className="input-lable">
                      Name
                    </FormHelperText>
                    <TextValidator
                      disabled
                      autoComplete="off"
                      fullWidth
                      id="piece"
                      className="form-control border-none"
                      value={input.piece}
                      name="piece"
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                gridColumn={{
                  xs: 'span 12',
                  sm: 'span 6',
                  md: 'span 6',
                  lg: 'span 6',
                }}
              >
                <Box className="refrence-product-input">
                  <Box className="input-box addproduct-1-input-box">
                    <FormHelperText className="input-lable">
                      Quantity
                    </FormHelperText>
                    <TextValidator
                      disabled
                      autoComplete="off"
                      fullWidth
                      id="quantity"
                      className="form-control border-none"
                      value={input.quantity}
                      name="quantity"
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                gridColumn={{
                  xs: 'span 12',
                  sm: 'span 6',
                  md: 'span 6',
                  lg: 'span 6',
                }}
              >
                <Box className="refrence-product-input">
                  <Box className="input-box addproduct-1-input-box">
                    <FormHelperText className="input-lable">
                      Purity(Carat)
                    </FormHelperText>
                    <TextValidator
                      disabled
                      autoComplete="off"
                      fullWidth
                      id="purity"
                      className="form-control border-none"
                      value={input.purity}
                      name="purity"
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                gridColumn={{
                  xs: 'span 12',
                  sm: 'span 6',
                  md: 'span 6',
                  lg: 'span 6',
                }}
              >
                <Box className="refrence-product-input">
                  <Box className="input-box addproduct-1-input-box">
                    <FormHelperText className="input-lable">
                      Weight(gm)
                    </FormHelperText>
                    <TextValidator
                      disabled
                      autoComplete="off"
                      fullWidth
                      id="total_weight"
                      className="form-control border-none"
                      value={input.total_weight}
                      name="total_weight"
                    />
                  </Box>
                </Box>
              </Box>
              <>
                <Box
                  gridColumn={{
                    xs: 'span 12',
                    sm: 'span 6',
                    md: 'span 6',
                    lg: 'span 6',
                  }}
                >
                  <Box className="refrence-product-input">
                    <Box className="input-box addproduct-1-input-box">
                      <FormHelperText className="input-lable">
                        Price per quantity(Rs)
                      </FormHelperText>
                      <TextValidator
                        disabled
                        autoComplete="off"
                        fullWidth
                        id="price_per_quantity"
                        className="form-control border-none"
                        value={input.price_per_quantity}
                        name="price_per_quantity"
                      />
                    </Box>
                  </Box>
                </Box>
                <Box
                  gridColumn={{
                    xs: 'span 12',
                    sm: 'span 6',
                    md: 'span 6',
                    lg: 'span 6',
                  }}
                >
                  <Box className="addproduct-textarea-main input-box addproduct-1-textarea-main">
                    <FormHelperText className="input-lable">
                      Suppliers Making Charges(Rs)
                    </FormHelperText>
                    <TextValidator
                      disabled
                      autoComplete="off"
                      fullWidth
                      id="making_charge"
                      className="form-control border-none"
                      value={input.making_charge}
                      name="making_charge"
                    />
                  </Box>
                </Box>
              </>
            </Box>
          </Box>
        ))}
      </>
    );
  };

  const renderFieldMaterialWise = () => {
    switch (true) {
      case material.includes('Gold') && material.length === 1:
        return goldFields();
      case material.includes('Silver') && material.length === 1:
        return silverFields();
      case material.includes('Diamond') && material.length === 1:
        return diamondFields();
      case material.includes('Gemstone') && material.length === 1:
        return gemstoneFields();
      case material.includes('Gold') &&
        material.includes('Diamond') &&
        material.length === 2:
        return (
          <>
            {goldFields()}
            {diamondFields()}
          </>
        );
      case material.includes('Gold') &&
        material.includes('Gemstone') &&
        material.length === 2:
        return (
          <>
            {goldFields()}
            {gemstoneFields()}
          </>
        );
      case material.includes('Silver') &&
        material.includes('Diamond') &&
        material.length === 2:
        return (
          <>
            {silverFields()}
            {diamondFields()}
          </>
        );
      case material.includes('Silver') &&
        material.includes('Gemstone') &&
        material.length === 2:
        return (
          <>
            {silverFields()}
            {gemstoneFields()}
          </>
        );
      case material.includes('Silver') &&
        material.includes('Gold') &&
        material.length === 2:
        return (
          <>
            {silverFields()}
            {goldFields()}
          </>
        );
      case material.includes('Gemstone') &&
        material.includes('Diamond') &&
        material.length === 2:
        return (
          <>
            {gemstoneFields()}
            {diamondFields()}
          </>
        );
      case material.includes('Gold') &&
        material.includes('Silver') &&
        material.includes('Diamond') &&
        material.length === 3:
        return (
          <>
            {silverFields()}
            {goldFields()}
            {diamondFields()}
          </>
        );
      case material.includes('Gold') &&
        material.includes('Silver') &&
        material.includes('Gemstone') &&
        material.length === 3:
        return (
          <>
            {silverFields()}
            {goldFields()}
            {gemstoneFields()}
          </>
        );
      case material.includes('Gold') &&
        material.includes('Diamond') &&
        material.includes('Gemstone') &&
        material.length === 3:
        return (
          <>
            {goldFields()}
            {diamondFields()}
            {gemstoneFields()}
          </>
        );
      case material.includes('Silver') &&
        material.includes('Diamond') &&
        material.includes('Gemstone') &&
        material.length === 3:
        return (
          <>
            {silverFields()}
            {gemstoneFields()}
            {diamondFields()}
          </>
        );
      case material.includes('Gold') &&
        material.includes('Silver') &&
        material.includes('Gemstone') &&
        material.includes('Diamond') &&
        material.length === 4:
        return (
          <>
            {goldFields()}
            {silverFields()}
            {gemstoneFields()}
            {diamondFields()}
          </>
        );

      default:
        return <h1>No Form found</h1>;
    }
  };
  //#endregion

  //#region RETURN JSX
  return (
    <>{loading ? (
      <Loader />
    ) : (
    <div>
    <ScrollUp />
      <ValidatorForm>
        {show ? (
          <Box className="addproduct-sec">
            <Box className="container">
              <Box sx={{ width: 1 }}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={{ xs: 2, sm: 5, md: 1, lg: 1 }}
                >
                  <Box
                    gridColumn={{
                      xs: 'span 12',
                      sm: 'span 12',
                      md: 'span 12',
                      lg: 'span 6',
                    }}
                  >
                    <Box className="addinfo-text-box-1">
                      <Typography variant="h2" className="addinfo-text-1">
                        Product Description:
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    gridColumn={{
                      xs: 'span 12',
                      sm: 'span 12',
                      md: 'span 12',
                      lg: 'span 12',
                    }}
                  >
                    <Box className="addproduct-textarea-main input-box ">
                      <FormHelperText className="input-lable">
                        Product Images
                      </FormHelperText>
                      <Box sx={{ width: '100%', position: 'relative' }}>
                        <Grid container>
                          {url?.map((element, index) => (
                            <Grid item xs={12} sm={6} md={4} lg={3} alignSelf="center">
                              <Box className="product-img-box product-img-box-set">
                                {/* <a
                                  key={element}
                                  href={element}
                                  download="file"
                                  target="/blank"
                                  onClick={(e) => downloadImageWithoutBlob(e)}
                                > */}
                                <Img
                                  // onClick=
                                  key={element}
                                  src={element}
                                  alt="no image"
                                  className="product-img-design"
                                  id={element}
                                  // disabled={true}
                                />
                                {/* </a> */}

                                <Box className="product-download-box"></Box>
                                <Tooltip title="Tap to Download">
                                  <DownloadIcon
                                    className="product-download-btn1"
                                    onClick={() => {
                                      let imgElement =
                                        document.getElementById(element);
                                      imgElement.addEventListener(
                                        'click',
                                        downloadImageWithoutBlob
                                      );
                                      imgElement.click();
                                      imgElement.removeEventListener(
                                        'click',downloadImageWithoutBlob);
                                    }}
                                  />
                                </Tooltip>
                              </Box>
                            </Grid>
                          ))}
                        </Grid>
                      </Box>
                    </Box>
                  </Box>

                  <Box
                    gridColumn={{
                      xs: 'span 12',
                      sm: 'span 6',
                      md: 'span 6',
                      lg: 'span 6',
                    }}
                  >
                    <Box className="refrence-product-input addproduct-input-box">
                      <Box className="input-box addproduct-input-box">
                        <FormHelperText className="input-lable">
                          No of pieces available in stock
                        </FormHelperText>
                        <TextValidator
                          disabled
                          fullWidth
                          id="no_of_pieces_available_inStock"
                          className="form-control border-none"
                          value={description?.no_of_pieces_available_inStock}
                          name="no_of_pieces_available_inStock"
                          type="number"
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    gridColumn={{
                      xs: 'span 12',
                      sm: 'span 6',
                      md: 'span 6',
                      lg: 'span 6',
                    }}
                  >
                    <Box className="input-box ">
                      <FormHelperText className="input-lable">
                        Item
                      </FormHelperText>
                      <TextValidator
                        disabled
                        autoComplete="off"
                        fullWidth
                        id="specify"
                        className="form-control border-none"
                        value={description.item}
                        name="specify"
                        type="text"
                      />
                    </Box>
                  </Box>
                  <Box
                    gridColumn={{
                      xs: 'span 12',
                      sm: 'span 6',
                      md: 'span 6',
                      lg: 'span 6',
                    }}
                  >
                    <Box className="refrence-product-input addproduct-drop-box">
                      <Box className="input-box">
                        <FormHelperText className="input-lable">
                          Material
                        </FormHelperText>

                        <TextValidator
                          disabled
                          fullWidth
                          id="specify"
                          className="form-control border-none"
                          value={material}
                          name="specify"
                          type="text"
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    gridColumn={{
                      xs: 'span 12',
                      sm: 'span 6',
                      md: 'span 6',
                      lg: 'span 6',
                    }}
                  >
                    {/* <Box className="refrence-product-input addproduct-drop-box"> */}
                    <Box className="input-box">
                      <FormHelperText className="input-lable">
                        Category
                      </FormHelperText>

                      <TextValidator
                        disabled
                        fullWidth
                        id="specify"
                        className="form-control border-none"
                        value={category}
                        name="specify"
                        type="text"
                      />
                    </Box>
                    {/* </Box> */}
                  </Box>

                  <Box
                    gridColumn={{
                      xs: 'span 12',
                      sm: 'span 12',
                      md: 'span 12',
                      lg: 'span 12',
                    }}
                  >
                    <Box className="refrence-product-input">
                      <Box className="input-box addproduct-input-box">
                        <FormHelperText className="input-lable">
                          Specify
                        </FormHelperText>
                        <TextValidator
                          disabled
                          fullWidth
                          id="specify"
                          className="form-control border-none"
                          value={description.specify}
                          name="specify"
                          type="text"
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    gridColumn={{
                      xs: 'span 12',
                      sm: 'span 12',
                      md: 'span 12',
                      lg: 'span 12',
                    }}
                  >
                    <Box className="addproduct-textarea-main input-box ">
                      <FormHelperText className="input-lable">
                        Additional Details
                      </FormHelperText>
                      <TextareaAutosize
                        disabled
                        fullWidth
                        id="additional_details"
                        className="addinfo-textarea admin-addproduct-area text-area-control-admin"
                        value={description.additional_details}
                        name="additional_details"
                        type="text"
                      />
                    </Box>
                  </Box>
                  <Box
                    gridColumn={{
                      xs: 'span 12',
                      sm: 'span 12',
                      md: 'span 12',
                      lg: 'span 6',
                    }}
                  >
                    <Box className="right-btn-main">
                      <ApproveButton
                        onClick={(e) => {
                          if (
                            window.confirm(
                              'Are you sure you want to Accept this Product?'
                            )
                          ) {
                            accept(e);
                          }
                        }}
                      />
                      <DeclineButton
                        className="common-button"
                        onClick={(e) => {
                          if (
                            window.confirm(
                              'Are you sure you want to Decline this Product?'
                            )
                          ) {
                            handleOpen(e);
                          }
                        }}
                      />
                      <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <form onSubmit={handleSubmit(reject)}>
                          <Box sx={style}>
                            <Typography>Rejection reason</Typography>
                            <Box className="text-area-control-main">
                              <TextareaAutosize
                                autoComplete="off"
                                sx={{
                                  mt: 2,
                                  width: { lg: '300px', xs: '150px' },
                                }}
                                size="small"
                                fullWidth
                                // id="rejectReason"
                                className="form-control border-none text-area-control1 text-area-control-admin"
                                name="rejectReason"
                                type="text"
                                {...register('rejectReason', {
                                  required: (
                                    <TextAreaErrorMsg msg="Please enter Rejection reason" />
                                  ),
                                  minLength: {
                                    value: minRejectReasonLength,
                                    message: (
                                      <TextAreaErrorMsg
                                        msg={`Minimum ${minRejectReasonLength} Characters Allowed`}
                                      />
                                    ),
                                  },
                                  maxLength: {
                                    value: rejectReasonLength,
                                    message: (
                                      <TextAreaErrorMsg
                                        msg={`Minimum ${rejectReasonLength} Characters Allowed`}
                                      />
                                    ),
                                  },
                                  pattern: {
                                    value: NO_WHITE_SPACE_BEGINNING_END,
                                    message: (
                                      <TextAreaErrorMsg msg="Whitespace is not allowed at beginning or end" />
                                    ),
                                  },
                                  validate: {
                                    isNumber: (val) =>
                                    ALPHABET_IS_MUST_REGEX.test(val) || (
                                        <ErrorMsg msg={'Must required alphabetical character at the beginning of the text'} />
                                      ),
                                  },
                                })}
                              />
                              <ErrorMessage
                                errors={errors}
                                name="rejectReason"
                              />
                            </Box>
                            <Button
                              sx={{ mt: 2, textTransform: 'none' }}
                              variant="contained"
                              type="submit"
                            >
                              Submit
                            </Button>
                          </Box>
                        </form>
                      </Modal>
                      <ContainedButton
                        className="common-button"
                        name="Delete"
                        onClick={() => {
                          if (
                            window.confirm(
                              'Are you sure you want to Delete this Product?'
                            )
                          ) {
                            removeProduct(getData?._id);
                          }
                        }}
                      />
                    </Box>
                  </Box>
                  <Box
                    gridColumn={{
                      xs: 'span 12',
                      sm: 'span 12',
                      md: 'span 12',
                      lg: 'span 12',
                    }}
                  >
                    <Box className="primary-btn-box next-btn-box product-details-btn-box">
                      <Button
                        variant="contained"
                        className=" next-btn-1"
                        onClick={(e) => {
                          nextPage(e);
                        }}
                      >
                        Next
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        ) : (
          <Box className="addproduct-1-sec">
          <ScrollUp />
            <Box className="container">
              <Box>{renderFieldMaterialWise()}</Box>
              <Box sx={{ width: 1, mt: 3 }}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={{ xs: 0, sm: 1, md: 1, lg: 1 }}
                >
                  <Box
                    gridColumn={{
                      xs: 'span 12',
                      sm: 'span 12',
                      md: 'span 12',
                      lg: 'span 12',
                    }}
                  >
                    <Box className="addinfo-text-box-1">
                      <Typography
                        variant="h2"
                        className="addinfo-text-1 product-dimension-1"
                      >
                        Product Dimensions
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    gridColumn={{
                      xs: 'span 12',
                      sm: 'span 6',
                      md: 'span 4',
                      lg: 'span 4',
                    }}
                  >
                    <Box className="refrence-product-input addproduct-1-input-box">
                      <Box className="input-box addproduct-1-input-box">
                        <FormHelperText className="input-lable">
                          Inner circumference
                        </FormHelperText>
                        <TextValidator
                          disabled
                          fullWidth
                          id="inner_circumference"
                          className="form-control border-none"
                          value={dimension.inner_circumference}
                          name="inner_circumference"
                          type="number"
                        />
                        <span className="addproduct-mm-text">mm</span>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    gridColumn={{
                      xs: 'span 12',
                      sm: 'span 6',
                      md: 'span 3',
                      lg: 'span 3',
                    }}
                  >
                    <Box className="refrence-product-input">
                      <Box className="input-box addproduct-1-input-box">
                        <FormHelperText className="input-lable">
                          Length
                        </FormHelperText>
                        <TextValidator
                          disabled
                          fullWidth
                          id="length"
                          className="form-control border-none"
                          value={dimension.length}
                          name="length"
                          type="number"
                        />
                        <span className="addproduct-mm-text">mm</span>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    gridColumn={{
                      xs: 'span 12',
                      sm: 'span 6',
                      md: 'span 5',
                      lg: 'span 5',
                    }}
                  >
                    <Box className="refrence-product-input">
                      <Box className="input-box addproduct-1-input-box">
                        <FormHelperText className="input-lable">
                          Depth
                        </FormHelperText>
                        <TextValidator
                          disabled
                          fullWidth
                          id="depth"
                          className="form-control border-none"
                          value={dimension.depth}
                          name="depth"
                          type="number"
                        />
                        <span className="addproduct-mm-text">mm</span>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    gridColumn={{
                      xs: 'span 12',
                      sm: 'span 6',
                      md: 'span 12',
                      lg: 'span 12',
                    }}
                  >
                    <Box className="refrence-product-input">
                      <Box className="input-box addproduct-1-input-box">
                        <FormHelperText className="input-lable">
                          Meta Title
                        </FormHelperText>
                        <TextValidator
                          disabled
                          fullWidth
                          id="meta_title"
                          className="form-control border-none"
                          value={dimension.meta_title}
                          name="meta_title"
                          type="text"
                        />
                        <Typography
                          variant="p"
                          component="p"
                          className="form-validation-text"
                        >
                          A short 2-3 words
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    gridColumn={{
                      xs: 'span 12',
                      sm: 'span 12',
                      md: 'span 12',
                      lg: 'span 12',
                    }}
                  >
                    <Box className="addproduct-textarea-main input-box ">
                      <FormHelperText className="input-lable">
                        Meta Description
                      </FormHelperText>
                      <TextareaAutosize
                        disabled
                        fullWidth
                        id="meta_description"
                        className="addinfo-textarea admin-addproduct-area text-area-control-admin"
                        value={dimension.meta_description}
                        name="meta_description"
                        type="text"
                      />
                    </Box>
                  </Box>
                  <Box
                    gridColumn={{
                      xs: 'span 12',
                      sm: 'span 12',
                      md: 'span 12',
                      lg: 'span 12',
                    }}
                  >
                    <Box className="primary-btn-box next-back-btn-box">
                      <Button
                        variant="contained"
                        className=" back-btn-1"
                        onClick={() => {
                          setShow(true);
                        }}
                      >
                        Back
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </ValidatorForm>
    </div>
    )}
    </>
  );
};

export default ViewPendingProduct;
//#endregion
