import React, { useEffect, useState } from "react";
import UserIndex from "../../UserIndex";

const Item = UserIndex.styled(UserIndex.Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const {
  UserCreatePriciousSaga,
  SingleUserProductSaga,
  ListItemSaga,
  ListMaterialSaga,
} = UserIndex.UserSagaActions;

const Input = UserIndex.styled("input")({
  display: "none",
});

const Img = UserIndex.styled("img")({
  display: "block",
  maxWidth: "100%",
  height: "auto",
  maxHeight: "50vh",
  borderRadius: "6px",
});

const fileTypes = ["JPEG", "UserIndex.png", "GIF"];

const checkValidAllArray = (array) => {
  return array.every((obj) => obj.isValidObj === true);
};

const AddProducts = () => {
  const history = UserIndex.useHistory();
  const params = UserIndex.useParams();
  const dispatch = UserIndex.useDispatch();
  const navigate = UserIndex.useHistory();
  const loding = UserIndex.useSelector((state) => {
    return state.Usersaga.loading;
  });
  let token = UserIndex.useSelector((state) => {
    return state.Usersaga.Token;
  });
  const UserCreatePriciousData = UserIndex.useSelector((state) => {
    return state.Usersaga.UserCreatePriciousData;
  });

  const Productdata = UserIndex.useSelector((state) => {
    return state.Usersaga.Productdata;
  });

  const ListItemData = UserIndex.useSelector((state) => {
    return state.Usersaga.ListItemData;
  });

  const ListMaterialData = UserIndex.useSelector((state) => {
    return state.Usersaga.ListMaterialData;
  });

  const Categorymenudata = UserIndex.useSelector((state) => {
    return state.Usersaga.Categorymenudata;
  });

  const SingleUserProductData = UserIndex.useSelector((state) => {
    return state.Usersaga.SingleUserProductData;
  });

  const inputArr = [
    {
      piece: "",
      quantity: "",
      purity: "",
      total_weight: "",
      price_per_quantity: "",
      price_of_total_quantity: "00",
      making_charge: "",
      final_price: "00",
    },
  ];

  const DiamonsFieldsArr = [
    {
      piece: "",
      quantity: "",
      purity: "",
      total_weight: "",
      price_per_quantity: "",
      price_of_total_quantity: "00",
      making_charge: "",
      final_price: "00",
    },
  ];
  const [clicked, setClicked] = useState(false);
  const [categoryData, setCategoryData] = useState("");
  const [arr, setArr] = useState(inputArr);
  const [diamondArr, setDiamondArr] = useState(DiamonsFieldsArr);
  const [show, setShow] = useState(true);
  const [noOfPiecesAvailableInStockError, setNoOfPiecesAvailableInStockError] =
    useState("");
  const [itemError, setItemError] = useState("");
  const [specifyError, setSpecifyError] = useState("");
  const [additional_detailsError, setAdditional_detailsError] = useState("");
  const [imageError, setImageError] = useState("");
  const [materialError, setMaterialError] = useState("");
  const [innerCircumference, setInnerCircumference] = useState("");
  const [lengthError, setLengthError] = useState("");
  const [depthError, setDepthError] = useState("");
  const [metaTitleError, setMetaTitleError] = useState("");
  const [categoryDataError, setCategoryDataError] = useState("");
  const [metaDescriptionError, setMetaDescriptionError] = useState("");
  const [oneProductData, setOneProductData] = useState([]);
  const [description, setDescription] = useState({
    no_of_pieces_available_inStock: "",
    specify: "",
    additional_details: "",
  });
  const [dimension, setDimension] = useState({
    inner_circumference: 0,
    length: 0,
    depth: 0,
    meta_title: "",
    meta_description: "",
  });
  const [gemstoneProperties, setGemstoneProperties] = useState({
    piece: "",
    quantity: "",
    purity: "",
    total_weight: "",
    price_per_quantity: "",
    price_of_total_quantity: "00",
    making_charge: "",
    final_price: "00",
  });
  const [diamondProperties, setDiamondProperties] = useState({
    piece: "",
    quantity: "",
    purity: "",
    total_weight: "",
    price_per_quantity: "",
    price_of_total_quantity: "00",
    making_charge: "",
    final_price: "00",
  });
  const [itemList, setItemList] = useState([]);
  const [materialList, setMaterialList] = useState([]);
  const [material, setMaterial] = useState([]);
  const [materialId, setMaterialId] = useState([]);
  const [item, setItem] = useState("");
  const [itemId, setItemId] = useState("");
  const [category, setCategory] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [images, setImages] = useState([]);
  const [oldImages, setOldImages] = useState([]);

  // Gemstone Dynamic Fields

  const addInput = () => {
    setArr((s) => {
      return [
        ...s,
        {
          piece: "",
          quantity: "",
          purity: "",
          total_weight: "",
          price_per_quantity: "",
          price_of_total_quantity: "00",
          making_charge: "",
          final_price: "00",
        },
      ];
    });
  };

  const minusInput = (index) => {
    let data = [...arr];
    if (data.length >= 1) {
      data.splice(-1);
    }
    setArr(data);
  };

  const handleChange = (e, name) => {
    const index = e.target.id;
    setArr((s) => {
      const newArr = s.slice();
      newArr[index][`${name}`] =
        name == "total_weight"
          ? e.target.value.slice(0, 6)
          : name == "purity"
          ? e.target.value.slice(0, 4)
          : name == "quantity"
          ? parseInt(e.target.value)
          : name == "price_per_quantity"
          ? e.target.value.slice(0, 8)
          : name == "making_charge"
          ? e.target.value.slice(0, 3)
          : e.target.value;
      return newArr;
    });
  };

  // End Gemstone Dynamic Fields

  // Start Dynamic Diamonds Fields

  const addDiamondInput = () => {
    setDiamondArr((s) => {
      return [
        ...s,
        {
          piece: "",
          quantity: "",
          purity: "",
          total_weight: "",
          price_per_quantity: "",
          price_of_total_quantity: "00",
          making_charge: "",
          final_price: "00",
        },
      ];
    });
  };

  const minusDiamondInput = (index) => {
    let data = [...diamondArr];
    if (data.length >= 1) {
      data.splice(-1);
    }
    setDiamondArr(data);
  };

  const handleDiamondChange = (e, name) => {
    const index = e.target.id;
    setDiamondArr((s) => {
      const newArr = s.slice();
      newArr[index][`${name}`] =
        name == "total_weight"
          ? e.target.value.slice(0, 6)
          : name == "purity"
          ? e.target.value.slice(0, 4)
          : name == "quantity"
          ? parseInt(e.target.value)
          : name == "price_per_quantity"
          ? e.target.value.slice(0, 8)
          : name == "making_charge"
          ? e.target.value.slice(0, 3)
          : e.target.value;
      return newArr;
    });
  };

  // End Dynamic Diamond Fields

  const handleChangeMaterial = (e) => {
    setMaterial(e.target.value);
  };

  const handleChangeItem = (e) => {
    let arr = itemList.map((list, index) => {
      if (e.target.value === list.item) {
        setItem(list.item);
        setItemId(list._id);
      }
    });
  };

  const handleChangeCategory = (e) => {
    let arr = categoryData.map((list, index) => {
      if (e.target.value === list.category_name) {
        setCategory(list.category_name);
        setCategoryId(list._id);
      }
    });
  };

  const handleChangeProductDescription = (e) => {
    const { name, value } = e.target;
    setDescription({
      ...description,
      [name]:
        name === "no_of_pieces_available_inStock"
          ? value.slice(0, 4)
          : name === "specify"
          ? value.trimStart().replace(/\s\s+/g, " ")
          : name === "additional_details"
          ? value.trimStart().replace(/\s\s+/g, " ")
          : value,
    });
  };

  const handleChangeDimension = (e) => {
    const { name, value } = e.target;


    setDimension({
      ...dimension,
      [name]:
        name === "meta_title"
          ? value.slice(0, 30)
          : name === "meta_description"
          ? value.slice(0, 150)
          : name === "length"
          ? value.slice(0, 5)
          : name === "depth"
          ? value.slice(0, 5)
          : name === "inner_circumference"
          ? value.slice(0, 5)
          : value,
    });
  };

  const handleChangeImage = (e) => {
    const { files } = e.target;

    if (files.length < 6 && images.length < 5) {
      const arr = [];
      for (const key of Object.keys(files)) {
        if (
          files[key].type === "image/png" ||
          files[key].type === "image/jpeg"
        ) {
          arr.push(files[key]);
        }
      }
      setImages((e) => [...e, ...arr]);
    }
  };


  const removePreviewImage = (data) => {
    var array = [...images];
    let filterArray = array.filter((item) => item !== data);
    setImages(filterArray);
  };

  const removeOldImages = (data) => {
    var array = [...oldImages];
    let filterArray = array.filter((item) => item !== data);
    setOldImages(filterArray);
  };

  const getListItem = async () => {
    let urlencoded = new URLSearchParams();
    dispatch(ListItemSaga(urlencoded, navigate));
  };

  const getListMaterial = async () => {
    let urlencoded = new URLSearchParams();
    dispatch(ListMaterialSaga(urlencoded, navigate));
  };

  useEffect(() => {
    setItemList(ListItemData);
  }, [ListItemData]);

  useEffect(() => {
    setMaterialList(ListMaterialData);
  }, [ListMaterialData]);

  useEffect(() => {
    getListItem();
    getListMaterial();
  }, []);

  useEffect(() => {
    setCategoryData(Categorymenudata);
  }, [Categorymenudata]);

  useEffect(() => {
    if (params.id) {
      let urlencoded = new URLSearchParams();
      urlencoded.append("id", params.id);
      dispatch(SingleUserProductSaga(urlencoded, navigate, token));
    }
  }, []);

  const editHandleMaterialData = () => {
    if (params.id) {
      setOneProductData(SingleUserProductData);
      const tampMaterial = SingleUserProductData[0].materialIdArray?.map(
        (item) => item?.material_id?.material
      );
      setMaterial(tampMaterial);

      if (
        SingleUserProductData[0]?.gemstone?.length > 0 &&
        SingleUserProductData[0]?.materialIdArray?.length === 1
      ) {
        let datas = [];
        const tempGemstone = SingleUserProductData[0].gemstone?.map((item) => {
          let obj = {};
          obj.piece = item.piece;
          obj.quantity = item.quantity;
          obj.purity = item.purity;
          obj.total_weight = item.total_weight;
          obj.price_per_quantity = item.price_per_quantity;
          obj.making_charge = item.making_charge;
          obj.price_of_total_quantity = "00";
          obj.final_price = "00";
          datas.push(obj);
        });
        setArr(datas);
      } else if (
        SingleUserProductData[0]?.diamond?.length > 0 &&
        SingleUserProductData[0]?.materialIdArray?.length === 1
      ) {
        let data = [];
        const tempDiamond = SingleUserProductData[0].diamond?.map((item) => {
          let obj = {};
          obj.piece = item.piece;
          obj.quantity = item.quantity;
          obj.purity = item.purity;
          obj.total_weight = item.total_weight;
          obj.price_per_quantity = item.price_per_quantity;
          obj.making_charge = item.making_charge;
          obj.price_of_total_quantity = "00";
          obj.final_price = "00";
          data.push(obj);
        });
        setDiamondArr(data);
      } else if (
        SingleUserProductData[0]?.diamond?.length > 0 &&
        SingleUserProductData[0]?.gemstone?.length > 0 &&
        SingleUserProductData[0]?.materialIdArray?.length === 2
      ) {
        let data = [];
        const tempDiamond = SingleUserProductData[0].diamond?.map((item) => {
          let obj = {};
          obj.piece = item.piece;
          obj.quantity = item.quantity;
          obj.purity = item.purity;
          obj.total_weight = item.total_weight;
          obj.price_per_quantity = item.price_per_quantity;
          obj.making_charge = item.making_charge;
          obj.price_of_total_quantity = "00";
          obj.final_price = "00";
          data.push(obj);
        });
        setDiamondArr(data);

        let datas = [];
        const tempGemstone = SingleUserProductData[0].gemstone?.map((item) => {
          let obj = {};
          obj.piece = item.piece;
          obj.quantity = item.quantity;
          obj.purity = item.purity;
          obj.total_weight = item.total_weight;
          obj.price_per_quantity = item.price_per_quantity;
          obj.making_charge = item.making_charge;
          obj.price_of_total_quantity = "00";
          obj.final_price = "00";
          datas.push(obj);
        });
        setArr(datas);
      }
    }
  };

  useEffect(() => {
    if (SingleUserProductData.length > 0) {
      editHandleMaterialData();
    }
  }, [SingleUserProductData?.[0]]);

  const editHandleProductData = () => {
    if (oneProductData) {
      setDescription({
        no_of_pieces_available_inStock:
          oneProductData[0]?.no_of_pieces_available_inStock,
        specify: oneProductData[0]?.specify,
        additional_details: oneProductData[0]?.additional_details,
      });
      setOldImages(oneProductData[0]?.image);
      setImages([]);
      setItem(oneProductData[0]?.item_id.item);
      setItemId(oneProductData[0]?.item_id._id);
      setCategory(oneProductData[0]?.category_id.category_name);
      setCategoryId(oneProductData[0]?.category_id._id);
      setDimension({
        inner_circumference:
          oneProductData[0]?.product_dimension.inner_circumference,
        length: oneProductData[0]?.product_dimension.length,
        depth: oneProductData[0]?.product_dimension.depth,
        meta_title: oneProductData[0]?.product_dimension.meta_title,
        meta_description: oneProductData[0]?.product_dimension.meta_description,
      });
    }
  };

  useEffect(() => {
    if (oneProductData.length > 0) {
      editHandleProductData();
    }
  }, [oneProductData]);

  const gemstoneFields = () => {
    return (
      <>
        <UserIndex.Box sx={{ display: "contents" }}>
          {arr?.map((item, i) => {
            return (
              <>
                <UserIndex.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 12",
                    lg: "span 12",
                  }}
                >
                  <UserIndex.Box className="addinfo-text-box">
                    <UserIndex.Typography variant="h2" className="addinfo-text">
                      Gemstone
                    </UserIndex.Typography>
                  </UserIndex.Box>
                </UserIndex.Box>
                <UserIndex.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 6",
                  }}
                >
                  <UserIndex.Box className="refrence-product-input addproduct-1-input-box">
                    <UserIndex.Box className="input-box addproduct-input-box">
                      <UserIndex.FormHelperText className="input-lable">
                        Name
                        <span style={{ color: "red", fontSize: "15px" }}>
                          {" "}
                          *{" "}
                        </span>
                      </UserIndex.FormHelperText>
                      <UserIndex.TextValidator
                        fullWidth
                        id={i}
                        className="form-control border-none"
                        value={item?.piece}
                        onChange={(e) => {
                          handleChange(e, "piece");
                        }}
                        type="text"
                      />
                      <UserIndex.FormHelperText className="error-input-lable">
                        {item[`pieceError-${i}`]}
                      </UserIndex.FormHelperText>
                    </UserIndex.Box>
                  </UserIndex.Box>
                </UserIndex.Box>
                <UserIndex.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 6",
                  }}
                >
                  <UserIndex.Box className="refrence-product-input">
                    <UserIndex.Box className="input-box addproduct-1-input-box">
                      <UserIndex.FormHelperText className="input-lable">
                        Quantity
                        <span style={{ color: "red", fontSize: "15px" }}>
                          {" "}
                          *{" "}
                        </span>
                      </UserIndex.FormHelperText>
                      <UserIndex.TextValidator
                        fullWidth
                        id={i}
                        className="form-control border-none"
                        value={item?.quantity}
                        onChange={(e) => {
                          handleChange(e, "quantity");
                        }}
                        type="text"
                      />
                      <UserIndex.FormHelperText className="error-input-lable">
                        {item[`quantityError-${i}`]}
                      </UserIndex.FormHelperText>
                    </UserIndex.Box>
                  </UserIndex.Box>
                </UserIndex.Box>
                <UserIndex.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 6",
                  }}
                >
                  <UserIndex.Box className="refrence-product-input addproduct-1-input-box">
                    <UserIndex.Box className="input-box addproduct-input-box">
                      <UserIndex.FormHelperText className="input-lable">
                        Purity (Carat)
                        <span style={{ color: "red", fontSize: "15px" }}>
                          {" "}
                          *{" "}
                        </span>
                      </UserIndex.FormHelperText>
                      <UserIndex.TextValidator
                        fullWidth
                        id={i}
                        className="form-control border-none"
                        value={item?.purity}
                        onChange={(e) => {
                          handleChange(e, "purity");
                        }}
                        type="text"
                      />
                      <UserIndex.FormHelperText className="error-input-lable">
                        {item[`purityError-${i}`]}
                      </UserIndex.FormHelperText>
                    </UserIndex.Box>
                  </UserIndex.Box>
                </UserIndex.Box>
                <UserIndex.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 6",
                  }}
                >
                  <UserIndex.Box className="refrence-product-input">
                    <UserIndex.Box className="input-box addproduct-1-input-box">
                      <UserIndex.FormHelperText className="input-lable">
                        Total Weight (gm)
                        <span style={{ color: "red", fontSize: "15px" }}>
                          {" "}
                          *{" "}
                        </span>
                      </UserIndex.FormHelperText>
                      <UserIndex.TextValidator
                        fullWidth
                        id={i}
                        className="form-control border-none"
                        value={item?.total_weight}
                        onChange={(e) => {
                          handleChange(e, "total_weight");
                        }}
                        type="text"
                      />
                      <UserIndex.FormHelperText className="error-input-lable">
                        {item[`totalWeightError-${i}`]}
                      </UserIndex.FormHelperText>
                    </UserIndex.Box>
                  </UserIndex.Box>
                </UserIndex.Box>
                <UserIndex.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 6",
                  }}
                >
                  <UserIndex.Box className="refrence-product-input addproduct-1-input-box">
                    <UserIndex.Box className="input-box addproduct-input-box">
                      <UserIndex.FormHelperText className="input-lable">
                        Price Per Quantity(Rs)
                        <span style={{ color: "red", fontSize: "15px" }}>
                          {" "}
                          *{" "}
                        </span>
                      </UserIndex.FormHelperText>
                      <UserIndex.TextValidator
                        fullWidth
                        id={i}
                        className="form-control border-none"
                        value={item?.price_per_quantity}
                        onChange={(e) => {
                          handleChange(e, "price_per_quantity");
                        }}
                        type="text"
                      />
                      <UserIndex.FormHelperText className="error-input-lable">
                        {item[`pricePerQuantityError-${i}`]}
                      </UserIndex.FormHelperText>
                    </UserIndex.Box>
                  </UserIndex.Box>
                </UserIndex.Box>
                <UserIndex.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 6",
                  }}
                >
                  <UserIndex.Box className="refrence-product-input">
                    <UserIndex.Box className="input-box addproduct-1-input-box">
                      <UserIndex.FormHelperText className="input-lable">
                        Supplier's Making Charges(%)
                        <span style={{ color: "red", fontSize: "15px" }}>
                          {" "}
                          *{" "}
                        </span>
                      </UserIndex.FormHelperText>
                      <UserIndex.TextValidator
                        fullWidth
                        id={i}
                        className="form-control border-none"
                        value={item?.making_charge}
                        onChange={(e) => {
                          handleChange(e, "making_charge");
                        }}
                        type="text"
                      />
                      <UserIndex.FormHelperText className="error-input-lable">
                        {item[`makingChargeError-${i}`]}
                      </UserIndex.FormHelperText>
                    </UserIndex.Box>
                  </UserIndex.Box>
                </UserIndex.Box>
                <UserIndex.Box
                  className="add-product-addmore-remove-btns"
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 12",
                    lg: "span 12",
                  }}
                >
                  <UserIndex.Box
                    className="primary-btn-box next-btn-box next-btn-box-remove"
                    sx={{
                      display: arr.length == 1 ? "block" : "flex",
                    }}
                  >
                    <UserIndex.PrimaryButton
                      sx={{
                        my: 2,
                        textTransform: "none",
                      }}
                      onClick={() => {
                        minusInput();
                      }}
                      btnclass="primary-btn next-btn"
                      buttonvalue="Remove"
                    />
                  </UserIndex.Box>
                </UserIndex.Box>
              </>
            );
          })}

          {arr && arr.length ? (
            <>
              <UserIndex.Box
                className="add-product-addmore-remove-btns"
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
              >
                <UserIndex.Box className="primary-btn-box next-btn-box next-btn-box-add">
                  <UserIndex.PrimaryButton
                    sx={{
                      my: 2,
                      textTransform: "none",
                    }}
                    onClick={addInput}
                    btnclass="primary-btn next-btn"
                    buttonvalue="Add More"
                  />
                </UserIndex.Box>
              </UserIndex.Box>
            </>
          ) : (
            ""
          )}
        </UserIndex.Box>
      </>
    );
  };

  const diamondFields = () => {
    return (
      <>
        <UserIndex.Box sx={{ display: "contents" }}>
          {diamondArr?.map((item, i) => {
            return (
              <>
                <UserIndex.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 12",
                    lg: "span 12",
                  }}
                >
                  <UserIndex.Box className="addinfo-text-box">
                    <UserIndex.Typography variant="h2" className="addinfo-text">
                      Diamond
                    </UserIndex.Typography>
                  </UserIndex.Box>
                </UserIndex.Box>
                <UserIndex.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 6",
                  }}
                >
                  <UserIndex.Box className="refrence-product-input addproduct-1-input-box">
                    <UserIndex.Box className="input-box addproduct-input-box">
                      <UserIndex.FormHelperText className="input-lable">
                        Name
                        <span style={{ color: "red", fontSize: "15px" }}>
                          {" "}
                          *{" "}
                        </span>
                      </UserIndex.FormHelperText>
                      <UserIndex.TextValidator
                        fullWidth
                        id={i}
                        className="form-control border-none"
                        value={item?.piece}
                        onChange={(e) => {
                          handleDiamondChange(e, "piece");
                        }}
                        type="text"
                      />
                      <UserIndex.FormHelperText className="error-input-lable">
                        {item[`pieceError-${i}`]}
                      </UserIndex.FormHelperText>
                    </UserIndex.Box>
                  </UserIndex.Box>
                </UserIndex.Box>
                <UserIndex.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 6",
                  }}
                >
                  <UserIndex.Box className="refrence-product-input">
                    <UserIndex.Box className="input-box addproduct-1-input-box">
                      <UserIndex.FormHelperText className="input-lable">
                        Quantity
                        <span style={{ color: "red", fontSize: "15px" }}>
                          {" "}
                          *{" "}
                        </span>
                      </UserIndex.FormHelperText>
                      <UserIndex.TextValidator
                        fullWidth
                        id={i}
                        className="form-control border-none"
                        value={item?.quantity}
                        onChange={(e) => {
                          handleDiamondChange(e, "quantity");
                        }}
                        type="text"
                      />
                      <UserIndex.FormHelperText className="error-input-lable">
                        {item[`quantityError-${i}`]}
                      </UserIndex.FormHelperText>
                    </UserIndex.Box>
                  </UserIndex.Box>
                </UserIndex.Box>
                <UserIndex.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 6",
                  }}
                >
                  <UserIndex.Box className="refrence-product-input addproduct-1-input-box">
                    <UserIndex.Box className="input-box addproduct-input-box">
                      <UserIndex.FormHelperText className="input-lable">
                        Purity (Carat)
                        <span style={{ color: "red", fontSize: "15px" }}>
                          {" "}
                          *{" "}
                        </span>
                      </UserIndex.FormHelperText>
                      <UserIndex.TextValidator
                        fullWidth
                        id={i}
                        className="form-control border-none"
                        value={item?.purity}
                        onChange={(e) => {
                          handleDiamondChange(e, "purity");
                        }}
                        type="text"
                      />
                      <UserIndex.FormHelperText className="error-input-lable">
                        {item[`purityError-${i}`]}
                      </UserIndex.FormHelperText>
                    </UserIndex.Box>
                  </UserIndex.Box>
                </UserIndex.Box>
                <UserIndex.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 6",
                  }}
                >
                  <UserIndex.Box className="refrence-product-input">
                    <UserIndex.Box className="input-box addproduct-1-input-box">
                      <UserIndex.FormHelperText className="input-lable">
                        Total Weight (gm)
                        <span style={{ color: "red", fontSize: "15px" }}>
                          {" "}
                          *{" "}
                        </span>
                      </UserIndex.FormHelperText>
                      <UserIndex.TextValidator
                        fullWidth
                        id={i}
                        className="form-control border-none"
                        value={item?.total_weight}
                        onChange={(e) => {
                          handleDiamondChange(e, "total_weight");
                        }}
                        type="text"
                      />
                      <UserIndex.FormHelperText className="error-input-lable">
                        {item[`totalWeightError-${i}`]}
                      </UserIndex.FormHelperText>
                    </UserIndex.Box>
                  </UserIndex.Box>
                </UserIndex.Box>
                <UserIndex.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 6",
                  }}
                >
                  <UserIndex.Box className="refrence-product-input addproduct-1-input-box">
                    <UserIndex.Box className="input-box addproduct-input-box">
                      <UserIndex.FormHelperText className="input-lable">
                        Price Per Quantity(Rs)
                        <span style={{ color: "red", fontSize: "15px" }}>
                          {" "}
                          *{" "}
                        </span>
                      </UserIndex.FormHelperText>
                      <UserIndex.TextValidator
                        fullWidth
                        id={i}
                        className="form-control border-none"
                        value={item?.price_per_quantity}
                        onChange={(e) => {
                          handleDiamondChange(e, "price_per_quantity");
                        }}
                        type="text"
                      />
                      <UserIndex.FormHelperText className="error-input-lable">
                        {item[`pricePerQuantityError-${i}`]}
                      </UserIndex.FormHelperText>
                    </UserIndex.Box>
                  </UserIndex.Box>
                </UserIndex.Box>
                <UserIndex.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 6",
                  }}
                >
                  <UserIndex.Box className="refrence-product-input">
                    <UserIndex.Box className="input-box addproduct-1-input-box">
                      <UserIndex.FormHelperText className="input-lable">
                        Supplier's Making Charges(%)
                        <span style={{ color: "red", fontSize: "15px" }}>
                          {" "}
                          *{" "}
                        </span>
                      </UserIndex.FormHelperText>
                      <UserIndex.TextValidator
                        fullWidth
                        id={i}
                        className="form-control border-none"
                        value={item?.making_charge}
                        onChange={(e) => {
                          handleDiamondChange(e, "making_charge");
                        }}
                        type="text"
                      />
                      <UserIndex.FormHelperText className="error-input-lable">
                        {item[`makingChargeError-${i}`]}
                      </UserIndex.FormHelperText>
                    </UserIndex.Box>
                  </UserIndex.Box>
                </UserIndex.Box>
                <UserIndex.Box
                  className="add-product-addmore-remove-btns"
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 12",
                    lg: "span 12",
                  }}
                >
                  <UserIndex.Box
                    className="primary-btn-box next-btn-box next-btn-box-remove"
                    sx={{
                      display: diamondArr.length == 1 ? "block" : "flex",
                    }}
                  >
                    <UserIndex.PrimaryButton
                      sx={{
                        my: 2,
                        textTransform: "none",
                      }}
                      onClick={() => {
                        minusDiamondInput();
                      }}
                      btnclass="primary-btn next-btn"
                      buttonvalue="Remove"
                    />
                  </UserIndex.Box>
                </UserIndex.Box>
              </>
            );
          })}

          {diamondArr && diamondArr.length ? (
            <>
              <UserIndex.Box
                className="add-product-addmore-remove-btns"
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
              >
                <UserIndex.Box className="primary-btn-box next-btn-box next-btn-box-add">
                  <UserIndex.PrimaryButton
                    sx={{
                      my: 2,
                      textTransform: "none",
                      display:
                        material.includes("Gold") || material.includes("Silver")
                          ? "none"
                          : "block",
                    }}
                    onClick={addDiamondInput}
                    btnclass="primary-btn next-btn"
                    buttonvalue="Add More"
                  />
                </UserIndex.Box>
              </UserIndex.Box>
            </>
          ) : (
            ""
          )}
        </UserIndex.Box>
      </>
    );
  };

  const renderFieldMaterialWise = () => {
    switch (true) {
      case material.includes("Diamond") && material.length === 1:
        return diamondFields();
      case material.includes("Gemstone") && material.length === 1:
        return gemstoneFields();
      case material.includes("Gemstone") &&
        material.includes("Diamond") &&
        material.length === 2:
        return (
          <>
            {gemstoneFields()}
            {diamondFields()}
          </>
        );

      default:
        return "";
    }
  };

  const gemstoneArrayValidation = (inputArr) => {
    let gemstoneErrors = inputArr.map((data, i) => {
      const regex = new RegExp(/^[A-Za-z0-9\s]+$/);
      return {
        ...data,
        [`pieceError-${i}`]: `${
          data.piece === ""
            ? "Please enter name"
            : !regex.test(data.piece)
            ? "Please Enter Only AlphaNumeric Value"
            : ""
        }`,
        [`quantityError-${i}`]: `${
          data.quantity > 20 || data.quantity < 1
            ? "Please enter quantity between 1 to 20"
            : ""
        }`,
        [`purityError-${i}`]: `${
          data.purity === ""
            ? "Please enter purity"
            : Number(data.purity) <= 0.99
            ? "Purity cannot be zero"
            : ""
        }`,
        [`totalWeightError-${i}`]: `${
          data.total_weight === ""
            ? "Please enter total weight"
            : Number(data.total_weight) <= 0.99
            ? "Total weight cannot be zero"
            : ""
        }`,
        [`pricePerQuantityError-${i}`]: `${
          data.price_per_quantity === ""
            ? "Please enter price per quantity"
            : Number(data.price_per_quantity) <= 0.99
            ? "Price per quantity cannot be zero"
            : ""
        }`,
        [`makingChargeError-${i}`]: `${
          data.making_charge === ""
            ? "Please enter Supplier'smaking charge"
            : Number(data.making_charge) <= 0.99
            ? "Making charge cannot be zero"
            : Number(data.making_charge) >= 100
            ? "Making charge cannot be out of 100"
            : ""
        }`,
        isValidObj:
          data.piece === "" ||
          regex.test(data.piece) ||
          data.quantity > 20 ||
          data.quantity < 1 ||
          data.purity === "" ||
          Number(data.purity) <= 0.99 ||
          data.total_weight === "" ||
          Number(data.total_weight) <= 0.99 ||
          data.price_per_quantity === "" ||
          Number(data.price_per_quantity) <= 0.99 ||
          data.making_charge === "" ||
          Number(data.making_charge) <= 0.99 ||
          Number(data.making_charge) >= 100
            ? false
            : true,
      };
    });
    setArr(gemstoneErrors);
    return material.includes("Gemstone")
      ? checkValidAllArray(gemstoneErrors)
      : true;
  };

  const diamondArrayValidation = (DiamonsFieldsArr) => {
    let diamondErrors = DiamonsFieldsArr.map((data, i) => {
      const regex = new RegExp(/^[A-Za-z0-9\s]+$/);
      return {
        ...data,
        [`pieceError-${i}`]: `${
          data.piece === ""
            ? "Please enter name"
            : !regex.test(data.piece)
            ? "Please Enter Only AlphaNumeric Value"
            : ""
        }`,
        [`quantityError-${i}`]: `${
          data.quantity > 20 || data.quantity < 1
            ? "Please enter quantity between 1 to 20"
            : ""
        }`,
        [`purityError-${i}`]: `${
          data.purity === ""
            ? "Please enter purity"
            : Number(data.purity) <= 0.99
            ? "Purity cannot be zero"
            : ""
        }`,
        [`totalWeightError-${i}`]: `${
          data.total_weight === ""
            ? "Please enter total weight"
            : Number(data.total_weight) <= 0.99
            ? "Total weight cannot be zero"
            : ""
        }`,
        [`pricePerQuantityError-${i}`]: `${
          data.price_per_quantity === ""
            ? "Please enter price per quantity"
            : Number(data.price_per_quantity) <= 0.99
            ? "Price per quantity cannot be zero"
            : ""
        }`,
        [`makingChargeError-${i}`]: `${
          data.making_charge === ""
            ? "Please enter Supplier'smaking charge"
            : Number(data.making_charge) <= 0.99
            ? "Making charge cannot be zero"
            : Number(data.making_charge) >= 100
            ? "Making charge cannot be out of 100"
            : ""
        }`,
        isValidObj:
          data.piece === "" ||
          regex.test(data.piece) ||
          data.quantity > 20 ||
          data.quantity < 1 ||
          data.purity === "" ||
          Number(data.purity) <= 0.99 ||
          data.total_weight === "" ||
          Number(data.total_weight) <= 0.99 ||
          data.price_per_quantity === "" ||
          Number(data.price_per_quantity) <= 0.99 ||
          data.making_charge === "" ||
          Number(data.making_charge) <= 0.99 ||
          Number(data.making_charge) >= 100
            ? false
            : true,
      };
    });
    setDiamondArr(diamondErrors);
    return material.includes("Diamond")
      ? checkValidAllArray(diamondErrors)
      : true;
  };

  const errorHandling = (e) => {
    const regex = new RegExp(/^[A-Za-z0-9.,\s]+$/);
    if (images.length === 0) {
      setImageError("Please select product Images");
    } else {
      setImageError("");
    }

    if (oldImages?.length === 0) {
      setImageError("Please select product Images");
    } else {
      setImageError("");
    }

    if (description.no_of_pieces_available_inStock === "") {
      setNoOfPiecesAvailableInStockError(
        "Please enter no of pieces available in stock"
      );
    } else {
      setNoOfPiecesAvailableInStockError("");
    }

    if (item === "") {
      setItemError("Please select item");
    } else {
      setItemError("");
    }

    if (!regex.test(description.specify)) {
      setSpecifyError("Please enter specify");
    } else if (description.specify === "") {
      setSpecifyError("Please enter specify");
    } else {
      setSpecifyError("");
    }

    if (!regex.test(description.additional_details)) {
      setAdditional_detailsError("Please enter additional details");
    } else if (description.additional_details === "") {
      setAdditional_detailsError("Please enter additional details");
    } else {
      setAdditional_detailsError("");
    }

    if (material.length === 0) {
      setMaterialError("Please select material");
    } else {
      setMaterialError("");
    }

    if (category === "") {
      setCategoryDataError("Please select category");
    } else {
      setCategoryDataError("");
    }
  };

  const nextPage = () => {
    errorHandling();
    const regex = new RegExp(/^[A-Za-z0-9.,\s]+$/);
    if (
      images.length !== 0 &&
      description.no_of_pieces_available_inStock !== "" &&
      material.length !== 0 &&
      category !== "" &&
      item !== "" &&
      regex.test(description.specify) &&
      description.specify !== "" &&
      regex.test(description.additional_details) &&
      description.additional_details !== ""
    ) {
      setShow(false);
    } else if (
      oldImages.length !== 0 &&
      description.no_of_pieces_available_inStock !== "" &&
      material.length !== 0 &&
      category !== "" &&
      item !== "" &&
      regex.test(description.specify) &&
      description.specify !== "" &&
      regex.test(description.additional_details) &&
      description.additional_details !== ""
    ) {
      setShow(false);
    }
  };

  const secPageErrorHandling = () => {
    const regex = new RegExp(/^[a-zA-Z0-9]+$/);
    const number = new RegExp(/^[1-9][0-9]*$/);

    if (dimension.inner_circumference == "") {
      setInnerCircumference("");
    } else if (!number.test(dimension.inner_circumference)) {
      setInnerCircumference("Inner circumference cannot be zero");
    } else {
      setInnerCircumference("");
    }

    if (dimension.length == "") {
      setLengthError("");
    } else if (!number.test(dimension.length)) {
      setLengthError("Length cannot be zero");
    } else {
      setLengthError("");
    }

    if (dimension.depth == "") {
      setDepthError("");
    } else if (!number.test(dimension.depth)) {
      setDepthError("Depth cannot be zero");
    } else {
      setDepthError("");
    }

    if (!regex.test(dimension.meta_title)) {
      setMetaTitleError("Please Enter Only AlphaNumeric Value");
    } else if (dimension.meta_title === "") {
      setMetaTitleError("Please enter meta title");
    } else if (dimension.meta_title.length < 6) {
      setMetaTitleError("Please enter minimum 6 character meta title ");
    } else {
      setMetaTitleError("");
    }

    if (!regex.test(dimension.meta_title)) {
      setMetaTitleError("Please Enter Only AlphaNumeric Value");
    } else if (dimension.meta_title === "") {
      setMetaTitleError("Please enter meta title");
    } else if (dimension.meta_title.length < 6) {
      setMetaTitleError("Please enter minimum 6 character meta title ");
    } else {
      setMetaTitleError("");
    }

    if (!regex.test(dimension.meta_description)) {
      setMetaDescriptionError("Please Enter Only AlphaNumeric Value");
    } else if (dimension.meta_description === "") {
      setMetaDescriptionError("Please enter meta description");
    } else {
      setMetaDescriptionError("");
    }
  };

  const CreateProduct = () => {
    const regex = new RegExp(/^[a-zA-Z0-9]+$/);
    const number = new RegExp(/^[1-9][0-9]*$/);
    secPageErrorHandling();
    if (
      dimension.inner_circumference == "" ||
      dimension.length == "" ||
      dimension.depth == "" ||
      number.test(dimension.inner_circumference) ||
      number.test(dimension.length) ||
      number.test(dimension.depth) ||
      (dimension.meta_title !== "" &&
        dimension.meta_title.length > 6 &&
        regex.test(dimension.meta_title) &&
        dimension.meta_description !== "" &&
        regex.test(dimension.meta_description) &&
        gemstoneArrayValidation(arr) &&
        diamondArrayValidation(diamondArr))
    ) {
      handleSave();
    }
  };

  const handleSave = async () => {
    let materialArray = [];
    material.map((item) => {
      let temp = materialList.filter((a) => a.material == item);
      materialArray.push({ material_id: temp[0]._id });
    });
    let formdata = new FormData();
    for (const key of Object.keys(images)) {
      formdata.append("images", images[key]);
    }
    formdata.append(
      "no_of_pieces_available_inStock",
      description?.no_of_pieces_available_inStock
    );
    formdata.append("item_id", itemId);
    formdata.append("category_id", categoryId);
    formdata.append("materialIdArray", JSON.stringify(materialArray));
    formdata.append("specify", description?.specify.trim());
    formdata.append(
      "additional_details",
      description?.additional_details.trim()
    );
    formdata.append(
      "inner_circumference",
      dimension?.inner_circumference.trim()
    );
    // switch (true) {
    // }
    if (material.includes("Gemstone") && material.length === 1) {
      formdata.append("gemstoneArray", JSON.stringify(arr));
    } else if (material.includes("Diamond") && material.length === 1) {
      formdata.append("diamondArray", JSON.stringify(diamondArr));
    } else if (
      material.includes("Diamond") &&
      material.includes("Gemstone") &&
      material.length === 2
    ) {
      formdata.append("gemstoneArray", JSON.stringify(arr));
      formdata.append("diamondArray", JSON.stringify(diamondArr));
    }

    if (oneProductData?.[0]?.status == "2") {
      formdata.append("status", 0);
    }
    formdata.append("length", dimension.length?.trim());
    formdata.append("depth", dimension.depth?.trim());
    formdata.append("meta_title", dimension.meta_title?.trim());
    formdata.append("meta_description", dimension.meta_description?.trim());
    if (params.id) {
      formdata.append("id", params.id);
      formdata.append("oldurlarray", JSON.stringify(oldImages));
    }
    setClicked(true);
    dispatch(UserCreatePriciousSaga(formdata, navigate, token));
  };

  const onTop = () => {
    window.scrollTo({ top: 0 });
  };

  useEffect(() => {
    onTop();
  }, []);

  return (
    <div>
      <UserIndex.Header />
      <UserIndex.ValidatorForm
      // onClick={(e) => {
      //   handleSave(e);
      //   secPageErrorHandling(e);
      // }}
      >
        {show ? (
          <UserIndex.Box className="addproduct-sec">
            <UserIndex.Box className="container">
              <UserIndex.Box
                sx={{ marginTop: "15px" }}
                className="product-img-lable-main"
              >
                <UserIndex.FormHelperText className="input-lable product-img-lable">
                  Product Images
                  <span style={{ color: "red", fontSize: "15px" }}> * </span>
                </UserIndex.FormHelperText>
              </UserIndex.Box>
              <UserIndex.Box sx={{ width: 1 }} className="grid-main">
                <UserIndex.Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={{ xs: 2, sm: 2, md: 0, lg: 5 }}
                >
                  <UserIndex.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 8",
                      lg: "span 5",
                    }}
                    className="grid-column"
                  >
                    <Item className="grid-item">
                      <UserIndex.Box className="add-product-left">
                        <UserIndex.Box className="add-upload-bg">
                          <UserIndex.Button
                            variant="contained"
                            component="label"
                            className="add-upload-btn"
                          >
                            + Upload Pictures
                            <input
                              hidden
                              accept="image/png, image/jpeg"
                              multiple
                              type="file"
                              onChange={(e) => {
                                handleChangeImage(e);
                              }}
                            />
                          </UserIndex.Button>
                        </UserIndex.Box>
                      </UserIndex.Box>
                      <UserIndex.Box className="addproduct-textarea-main input-box ">
                        {/* <UserIndex.Box sx={{ marginTop: "15px" }}>
                          <UserIndex.FormHelperText className="input-lable">
                            Product Images
                            <span style={{ color: "red", fontSize: "15px" }}>
                              {" "}
                              *{" "}
                            </span>
                          </UserIndex.FormHelperText>
                        </UserIndex.Box> */}
                        <UserIndex.Box sx={{ position: "relative" }}>
                          {images.length == 0 ? (
                            <>
                              <UserIndex.FormHelperText className="error-input-lable">
                                {imageError}
                              </UserIndex.FormHelperText>
                            </>
                          ) : (
                            ""
                          )}
                          <UserIndex.Grid sx={{ width: "100%" }} container>
                            {oldImages?.map((data) => {
                              return (
                                <>
                                  <UserIndex.Grid item xs={6}>
                                    <UserIndex.Box
                                      sx={{
                                        position: "relative",
                                        padding: "10px",
                                      }}
                                    >
                                      <UserIndex.IconButton
                                        onClick={() => removeOldImages(data)}
                                        sx={{
                                          position: "absolute",
                                          left: "80%",
                                          color: "#c29958",
                                          zIndex: "1",
                                        }}
                                        aria-label="upload picture"
                                        component="label"
                                      >
                                        <UserIndex.DisabledByDefaultIcon />
                                      </UserIndex.IconButton>
                                      <img
                                        src={data}
                                        style={{
                                          width: "100%",
                                          height: "130px",
                                        }}
                                      />
                                    </UserIndex.Box>
                                  </UserIndex.Grid>
                                </>
                              );
                            })}
                            {images
                              ? images?.map((data) => {
                                  return (
                                    <>
                                      <UserIndex.Grid item xs={6}>
                                        <UserIndex.Box
                                          sx={{
                                            position: "relative",
                                            padding: "10px",
                                          }}
                                        >
                                          <UserIndex.IconButton
                                            onClick={() =>
                                              removePreviewImage(data)
                                            }
                                            sx={{
                                              position: "absolute",
                                              left: "80%",
                                              color: "#c29958",
                                              zIndex: "1",
                                            }}
                                            aria-label="upload picture"
                                            component="label"
                                          >
                                            <UserIndex.DisabledByDefaultIcon />
                                          </UserIndex.IconButton>
                                          <img
                                            src={URL.createObjectURL(data)}
                                            style={{
                                              my: 2,
                                              width: "100%",
                                              height: "130px",
                                            }}
                                          />
                                        </UserIndex.Box>
                                      </UserIndex.Grid>
                                    </>
                                  );
                                })
                              : ""}
                          </UserIndex.Grid>
                        </UserIndex.Box>
                      </UserIndex.Box>
                    </Item>
                  </UserIndex.Box>
                  <UserIndex.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 4",
                      lg: "span 7",
                    }}
                    className="grid-column"
                  >
                    <Item className="grid-item">
                      <UserIndex.Box className="add-product-right">
                        <UserIndex.Box sx={{ width: 1 }}>
                          <UserIndex.Box
                            display="grid"
                            gridTemplateColumns="repeat(12, 1fr)"
                            gap={{ xs: 2, sm: 5, md: 1, lg: 1 }}
                          >
                            <UserIndex.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 12",
                                md: "span 12",
                                lg: "span 12",
                              }}
                            >
                              <UserIndex.Box className="addinfo-text-box">
                                <UserIndex.Typography
                                  variant="h2"
                                  className="addinfo-text"
                                >
                                  Product Description:
                                </UserIndex.Typography>
                              </UserIndex.Box>
                            </UserIndex.Box>
                            <UserIndex.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 6",
                              }}
                            >
                              <UserIndex.Box className="refrence-product-input addproduct-input-box">
                                <UserIndex.Box className="input-box addproduct-input-box">
                                  <UserIndex.FormHelperText className="input-lable">
                                    No of pieces available in stock
                                    <span
                                      style={{ color: "red", fontSize: "15px" }}
                                    >
                                      {" "}
                                      *{" "}
                                    </span>
                                  </UserIndex.FormHelperText>
                                  <UserIndex.TextValidator
                                    fullWidth
                                    id="no_of_pieces_available_inStock"
                                    className="form-control border-none"
                                    value={
                                      description.no_of_pieces_available_inStock
                                    }
                                    name="no_of_pieces_available_inStock"
                                    placeholder="No of pieces available in stock"
                                    onChange={(e) => {
                                      handleChangeProductDescription(e);
                                    }}
                                    type="number"
                                    validators={["required"]}
                                  />
                                  <UserIndex.FormHelperText className="error-input-lable">
                                    {noOfPiecesAvailableInStockError}
                                  </UserIndex.FormHelperText>
                                </UserIndex.Box>
                              </UserIndex.Box>
                            </UserIndex.Box>
                            <UserIndex.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 6",
                              }}
                            >
                              <UserIndex.Box className="input-box ">
                                <UserIndex.FormHelperText className="input-lable">
                                  Item
                                  <span
                                    style={{ color: "red", fontSize: "15px" }}
                                  >
                                    {" "}
                                    *{" "}
                                  </span>
                                </UserIndex.FormHelperText>
                                <UserIndex.FormControl className="membership-formcontrol">
                                  <UserIndex.Select
                                    className="membershop-select custom-dropdown-arrow"
                                    value={item}
                                    onChange={(e) => {
                                      handleChangeItem(e);
                                    }}
                                    inputProps={{
                                      "aria-label": "Without label",
                                    }}
                                    displayEmpty
                                    renderValue={(selected) => {
                                      if (!selected) {
                                        return <em>Select Item</em>;
                                      }
                                      return selected;
                                    }}
                                  >
                                    {itemList?.length ? (
                                      itemList.map((data) => {
                                        return (
                                          <UserIndex.MenuItem
                                            value={data?.item}
                                          >
                                            {data?.item}
                                          </UserIndex.MenuItem>
                                        );
                                      })
                                    ) : (
                                      <UserIndex.MenuItem value="123">
                                        <em>None</em>
                                      </UserIndex.MenuItem>
                                    )}
                                  </UserIndex.Select>
                                  {/* <span>
                                    <img
                                      src={UserIndex.png.blackdown}
                                      className="black-down-img"
                                    ></img>
                                  </span> */}
                                </UserIndex.FormControl>

                                <UserIndex.FormHelperText className="error-input-lable">
                                  {itemError}
                                </UserIndex.FormHelperText>
                              </UserIndex.Box>
                            </UserIndex.Box>
                            <UserIndex.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 6",
                              }}
                            >
                              <UserIndex.Box className="refrence-product-input addproduct-drop-box">
                                <UserIndex.Box className="input-box">
                                  <UserIndex.FormHelperText className="input-lable">
                                    Material
                                    <span
                                      style={{ color: "red", fontSize: "15px" }}
                                    >
                                      {" "}
                                      *{" "}
                                    </span>
                                  </UserIndex.FormHelperText>
                                  <UserIndex.FormControl className="membership-formcontrol">
                                    <UserIndex.Select
                                      multiple
                                      className="membershop-select custom-dropdown-arrow"
                                      value={material}
                                      onChange={(e) => {
                                        handleChangeMaterial(e);
                                      }}
                                      inputProps={{
                                        "aria-label": "Without label",
                                      }}
                                      displayEmpty
                                      // renderValue={(selected) => {
                                      //   if (!selected) {
                                      //     return <em>Material</em>;
                                      //   }
                                      //   return selected;
                                      // }}
                                      renderValue={
                                        material.length !== 0
                                          ? undefined // If there are selected values, render them as usual
                                          : () => "Select Material" // If no values selected, render placeholder
                                      }
                                    >
                                      {materialList?.length ? (
                                        materialList.map((item) => {
                                          if (
                                            item.material == "Gemstone" ||
                                            item.material == "Diamond"
                                          ) {
                                            return (
                                              <UserIndex.MenuItem
                                                value={item.material}
                                              >
                                                {item.material}
                                              </UserIndex.MenuItem>
                                            );
                                          }
                                        })
                                      ) : (
                                        // "No Records Found"}
                                        <UserIndex.MenuItem value="123">
                                          <em>None</em>
                                        </UserIndex.MenuItem>
                                      )}
                                    </UserIndex.Select>
                                    {/* <span>
                                      <img
                                        src={UserIndex.png.blackdown}
                                        className="black-down-img"
                                      ></img>
                                    </span> */}
                                  </UserIndex.FormControl>
                                  <UserIndex.FormHelperText className="error-input-lable">
                                    {materialError}
                                  </UserIndex.FormHelperText>
                                </UserIndex.Box>
                              </UserIndex.Box>
                            </UserIndex.Box>
                            <UserIndex.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 6",
                              }}
                            >
                              <UserIndex.Box className="refrence-product-input addproduct-drop-box">
                                <UserIndex.Box className="input-box">
                                  <UserIndex.FormHelperText className="input-lable">
                                    Category
                                    <span
                                      style={{ color: "red", fontSize: "15px" }}
                                    >
                                      {" "}
                                      *{" "}
                                    </span>
                                  </UserIndex.FormHelperText>
                                  <UserIndex.FormControl className="membership-formcontrol">
                                    <UserIndex.Select
                                      className="membershop-select custom-dropdown-arrow"
                                      value={category}
                                      onChange={(e) => {
                                        handleChangeCategory(e);
                                      }}
                                      inputProps={{
                                        "aria-label": "Without label",
                                      }}
                                      displayEmpty
                                      renderValue={(selected) => {
                                        if (!selected) {
                                          return <em>Select Category</em>;
                                        }
                                        return selected;
                                      }}
                                    >
                                      {categoryData?.length ? (
                                        categoryData?.map((item) => {
                                          return (
                                            <UserIndex.MenuItem
                                              value={item?.category_name}
                                            >
                                              {item.category_name}
                                            </UserIndex.MenuItem>
                                          );
                                        })
                                      ) : (
                                        <UserIndex.MenuItem value="123">
                                          <em>None</em>
                                        </UserIndex.MenuItem>
                                      )}
                                    </UserIndex.Select>
                                    {/* <span>
                                      <img
                                        src={UserIndex.png.blackdown}
                                        className="black-down-img"
                                      ></img>
                                    </span> */}
                                    <UserIndex.FormHelperText className="error-input-lable">
                                      {categoryDataError}
                                    </UserIndex.FormHelperText>
                                  </UserIndex.FormControl>
                                </UserIndex.Box>
                              </UserIndex.Box>
                            </UserIndex.Box>
                            <UserIndex.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 6",
                              }}
                            >
                              <UserIndex.Box className="refrence-product-input">
                                <UserIndex.Box className="input-box addproduct-input-box">
                                  <UserIndex.FormHelperText className="input-lable">
                                    Specify
                                    <span
                                      style={{ color: "red", fontSize: "15px" }}
                                    >
                                      {" "}
                                      *{" "}
                                    </span>
                                  </UserIndex.FormHelperText>
                                  <UserIndex.TextValidator
                                    fullWidth
                                    id="specify"
                                    placeholder="Specify"
                                    className="form-control border-none"
                                    value={description.specify}
                                    name="specify"
                                    onChange={(e) => {
                                      handleChangeProductDescription(e);
                                    }}
                                    type="text"
                                    validators={["required"]}
                                  />
                                  <UserIndex.FormHelperText className="error-input-lable">
                                    {specifyError}
                                  </UserIndex.FormHelperText>
                                </UserIndex.Box>
                              </UserIndex.Box>
                            </UserIndex.Box>
                            <UserIndex.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 12",
                                md: "span 12",
                                lg: "span 12",
                              }}
                            >
                              <UserIndex.Box className="addproduct-textarea-main input-box ">
                                <UserIndex.FormHelperText className="input-lable">
                                  Additional Details
                                  <span
                                    style={{ color: "red", fontSize: "15px" }}
                                  >
                                    {" "}
                                    *{" "}
                                  </span>
                                </UserIndex.FormHelperText>
                                <UserIndex.TextareaAutosize
                                  aria-label="empty textarea"
                                  id="additional_details"
                                  placeholder="Additional Details"
                                  className="form-control border-none addproduct-textarea"
                                  value={description.additional_details}
                                  name="additional_details"
                                  onChange={(e) => {
                                    handleChangeProductDescription(e);
                                  }}
                                  type="text"
                                  validators={["required"]}
                                />
                                <UserIndex.FormHelperText className="error-input-lable">
                                  {additional_detailsError}
                                </UserIndex.FormHelperText>
                              </UserIndex.Box>
                            </UserIndex.Box>
                            <UserIndex.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 12",
                                md: "span 12",
                                lg: "span 12",
                              }}
                            ></UserIndex.Box>
                            <UserIndex.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 12",
                                md: "span 12",
                                lg: "span 12",
                              }}
                            >
                              <UserIndex.Box
                                sx={{ display: "flex", justifyContent: "end" }}
                                className="primary-btn-box next-btn-box"
                              >
                                <UserIndex.PrimaryButton
                                  btnclass="primary-btn next-btn"
                                  onClick={(e) => {
                                    nextPage(e);
                                  }}
                                  buttonvalue="Next"
                                />
                              </UserIndex.Box>
                            </UserIndex.Box>
                          </UserIndex.Box>
                        </UserIndex.Box>
                      </UserIndex.Box>
                    </Item>
                  </UserIndex.Box>
                </UserIndex.Box>
              </UserIndex.Box>
            </UserIndex.Box>
          </UserIndex.Box>
        ) : (
          <UserIndex.Box className="addproduct-1-sec">
            <UserIndex.Box className="container">
              <UserIndex.Box sx={{ width: 1 }}>
                <UserIndex.Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={1}
                >
                  {renderFieldMaterialWise()}
                </UserIndex.Box>
              </UserIndex.Box>

              <UserIndex.Box sx={{ width: 1 }}>
                <UserIndex.Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={{ xs: 0, sm: 1, md: 1, lg: 1 }}
                >
                  <UserIndex.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 12",
                      md: "span 12",
                      lg: "span 12",
                    }}
                  >
                    <UserIndex.Box className="addinfo-text-box">
                      <UserIndex.Typography
                        variant="h2"
                        className="addinfo-text product-dimension"
                      >
                        Product Dimensions
                      </UserIndex.Typography>
                    </UserIndex.Box>
                  </UserIndex.Box>
                  <UserIndex.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 4",
                      lg: "span 4",
                    }}
                  >
                    <UserIndex.Box className="refrence-product-input addproduct-1-input-box">
                      <UserIndex.Box className="input-box addproduct-1-input-box">
                        <UserIndex.FormHelperText className="input-lable">
                          Inner circumference
                          <span style={{ color: "red", fontSize: "15px" }}>
                            {" "}
                            *{" "}
                          </span>
                        </UserIndex.FormHelperText>
                        <UserIndex.TextValidator
                          fullWidth
                          id="inner_circumference"
                          className="form-control border-none"
                          value={dimension.inner_circumference}
                          name="inner_circumference"
                          onChange={(e) => {
                            handleChangeDimension(e);
                          }}
                          type="text"
                        />
                        <span className="addproduct-mm-text">mm</span>
                        <UserIndex.FormHelperText className="error-input-lable">
                          {innerCircumference}
                        </UserIndex.FormHelperText>
                      </UserIndex.Box>
                    </UserIndex.Box>
                  </UserIndex.Box>
                  <UserIndex.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 3",
                      lg: "span 3",
                    }}
                  >
                    <UserIndex.Box className="refrence-product-input">
                      <UserIndex.Box className="input-box addproduct-1-input-box">
                        <UserIndex.FormHelperText className="input-lable">
                          Length
                          <span style={{ color: "red", fontSize: "15px" }}>
                            {" "}
                            *{" "}
                          </span>
                        </UserIndex.FormHelperText>
                        <UserIndex.TextValidator
                          fullWidth
                          id="length"
                          className="form-control border-none"
                          value={dimension.length}
                          name="length"
                          onChange={(e) => {
                            handleChangeDimension(e);
                          }}
                        />
                        <span className="addproduct-mm-text">mm</span>
                        <UserIndex.FormHelperText className="error-input-lable">
                          {lengthError}
                        </UserIndex.FormHelperText>
                      </UserIndex.Box>
                    </UserIndex.Box>
                  </UserIndex.Box>
                  <UserIndex.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 5",
                      lg: "span 5",
                    }}
                  >
                    <UserIndex.Box className="refrence-product-input">
                      <UserIndex.Box className="input-box addproduct-1-input-box">
                        <UserIndex.FormHelperText className="input-lable">
                          Depth
                          <span style={{ color: "red", fontSize: "15px" }}>
                            {" "}
                            *{" "}
                          </span>
                        </UserIndex.FormHelperText>
                        <UserIndex.TextValidator
                          fullWidth
                          id="depth"
                          className="form-control border-none"
                          value={dimension.depth}
                          name="depth"
                          onChange={(e) => {
                            handleChangeDimension(e);
                          }}
                          type="text"
                        />
                        <span className="addproduct-mm-text">mm</span>
                        <UserIndex.FormHelperText className="error-input-lable">
                          {depthError}
                        </UserIndex.FormHelperText>
                      </UserIndex.Box>
                    </UserIndex.Box>
                  </UserIndex.Box>
                  <UserIndex.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 12",
                      lg: "span 12",
                    }}
                  >
                    <UserIndex.Box className="refrence-product-input">
                      <UserIndex.Box className="input-box addproduct-1-input-box">
                        <UserIndex.FormHelperText className="input-lable">
                          Meta Title
                          <span style={{ color: "red", fontSize: "15px" }}>
                            {" "}
                            *{" "}
                          </span>
                        </UserIndex.FormHelperText>
                        <UserIndex.TextValidator
                          fullWidth
                          id="meta_title"
                          className="form-control border-none"
                          value={dimension.meta_title}
                          name="meta_title"
                          onChange={(e) => {
                            handleChangeDimension(e);
                          }}
                          type="text"
                        />
                        <UserIndex.Typography
                          variant="p"
                          component="p"
                          className="form-validation-text"
                        >
                          A short 2-3 words
                        </UserIndex.Typography>
                        <UserIndex.FormHelperText className="error-input-lable">
                          {metaTitleError}
                        </UserIndex.FormHelperText>
                      </UserIndex.Box>
                    </UserIndex.Box>
                  </UserIndex.Box>
                  <UserIndex.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 12",
                      md: "span 12",
                      lg: "span 12",
                    }}
                  >
                    <UserIndex.Box className="addproduct-textarea-main input-box ">
                      <UserIndex.FormHelperText className="input-lable">
                        Meta Description
                        <span style={{ color: "red", fontSize: "15px" }}>
                          {" "}
                          *{" "}
                        </span>
                      </UserIndex.FormHelperText>
                      <UserIndex.TextValidator
                        fullWidth
                        id="meta_description"
                        className="form-control border-none"
                        value={dimension.meta_description}
                        name="meta_description"
                        onChange={(e) => {
                          handleChangeDimension(e);
                        }}
                        type="text"
                      />
                      <UserIndex.FormHelperText className="error-input-lable">
                        {metaDescriptionError}
                      </UserIndex.FormHelperText>
                    </UserIndex.Box>
                  </UserIndex.Box>
                  <UserIndex.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 12",
                      md: "span 12",
                      lg: "span 12",
                    }}
                  >
                    <UserIndex.Box className="primary-btn-box next-btn-box">
                      <UserIndex.PrimaryButton
                        btnclass="primary-btn next-btn"
                        onClick={() => {
                          window.scrollTo({ top: 0 });
                          setShow(true);
                        }}
                        buttonvalue="Back"
                      />
                      <UserIndex.Button
                        className="primary-btn next-btn"
                        disabled={clicked}
                        onClick={() => {
                          // handleSave()
                          CreateProduct();
                        }}
                      >
                        Submit
                      </UserIndex.Button>
                    </UserIndex.Box>
                  </UserIndex.Box>
                </UserIndex.Box>
              </UserIndex.Box>
            </UserIndex.Box>
          </UserIndex.Box>
        )}
      </UserIndex.ValidatorForm>
      <UserIndex.Footer />
    </div>
  );
};

export default AddProducts;
