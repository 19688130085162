import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  FormHelperText,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  CircularProgress,
  FormControl,
} from "@mui/material";
import { styled } from "@mui/system";
import png from "../../../../assets/png";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { PrimaryButton } from "../../../../component/user/button/Button";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 631,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const StyledProgress = styled(CircularProgress)(() => ({
  position: "absolute",
  top: "6px",
  left: "25px",
}));

const PlaceOrder = () => {
  const history = useHistory();
  const Categorymenudata = useSelector((state) => {
    return state.Usersaga.Categorymenudata;
  });
  const [categoryData, setCategoryData] = useState("");
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState("");
  const [categoryId, setCategoryId] = useState("");

  const [categoryError, setCategoryError] = useState("");

  const categoryErrorHandling = () => {
    if (category === "") {
      setCategoryError("Please Select Category");
    } else {
      setCategoryError("");
    }
  };

  const handleChangeCategory = (e) => {
    let arr = categoryData.map((list, index) => {
      if (e.target.value === list.category_name) {
        setCategory(list.category_name);
        setCategoryId(list._id);
      }
    });
  };

  useEffect(() => {
    setCategoryData(Categorymenudata);
  }, [Categorymenudata]);

  return (
    <div>
      <Box sx={style}>
        <Box className="modal-content">
          <Typography
            className="modal-modal-title order-madal-title"
            variant="h6"
            component="h2"
          >
            Place Order
          </Typography>
          <Box className="order-radio-box input-box">
            <FormControl className="radio-control">
              <FormLabel className="input-lable radio-title">
                Type of Product
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                className="radio-group"
                name="radio-buttons-group"
              >
                <FormControlLabel
                  value="female"
                  control={<Radio />}
                  label="Single Product"
                  className="radio-lable"
                />
                <FormControlLabel
                  value="male"
                  control={<Radio />}
                  label="Multiple Products"
                  className="radio-lable"
                />
                <FormControlLabel
                  value="other"
                  control={<Radio />}
                  label="Bulk Orders"
                  className="radio-lable"
                />
              </RadioGroup>
            </FormControl>
          </Box>
          {/* <Box className="input-box">
            <FormHelperText className="input-lable">Quantity</FormHelperText>
            <TextField
              fullWidth
              id="fullWidth"
              className="form-control border-none modal-form-control"
              value=""
            />
          </Box> */}
          <Box className="input-box">
            <Box className="refrence-product-input addproduct-drop-box">
              <Box className="input-box">
                <FormHelperText className="input-lable">
                  Category
                </FormHelperText>
                <FormControl className="membership-formcontrol">
                  <Select
                    className="membershop-select custom-dropdown-arrow
"
                    value={category}
                    onChange={(e) => {
                      handleChangeCategory(e);
                    }}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    renderValue={(selected) => {
                      if (!selected) {
                        return (
                          <em
                            sx={{
                              fontFamily: "mulish-semibold",
                              color: "#8a8a8f",
                            }}
                          >
                            Select Category
                          </em>
                        );
                      }
                      return selected;
                    }}
                  >
                    {categoryData?.length
                      ? categoryData.map((item) => {
                          return (
                            <MenuItem value={item.category_name}>
                              {item.category_name}
                            </MenuItem>
                          );
                        })
                      : "No Records Found"}
                  </Select>
                  {/* <span>
                    <img src={png.blackdown} className="black-down-img"></img>
                  </span> */}
                </FormControl>
              </Box>
            </Box>
          </Box>

          <Box className="place-an-order-error-message">{categoryError}</Box>

          {category !== "" ? (
            <Box className="modal-btn-box">
              <Button
                className="btn modal-btn placeorder-btn"
                onClick={() => history.push(`/user/category/${categoryId}`)}
              >
                Submit
              </Button>
              {/* {loading && <StyledProgress size={24} className="buttonProgress" />} */}
            </Box>
          ) : (
            <Box className="modal-btn-box">
              <Button
                onClick={categoryErrorHandling}
                className="btn modal-btn placeorder-btn"
              >
                Submit
              </Button>
              {/* {loading && <StyledProgress size={24} className="buttonProgress" />} */}
            </Box>
          )}
        </Box>
      </Box>
    </div>
  );
};

export default PlaceOrder;
