function log(message) {
  console.log(message);
}
const productType = [{ name: "Ring", slug: "ring" },
{ name: "Earings", slug: "earings" },
{ name: "Bracelets", slug: "bracelets" },
{ name: "Bengal", slug: "bengal" },
{ name: "Nose Pins", slug: "nose-pins" },
{ name: "Chains", slug: "Chains" },
{ name: "Kadas", slug: "Kadas" },
{ name: "Sets", slug: "Sets" },

];

export { log,productType};
