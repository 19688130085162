//#region ALL IMPORTS
import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import {
  Container,
  Typography,
  Box,
  TextField,
  Tooltip,
  Button,
} from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import { useHistory } from 'react-router-dom';
import { Api } from '../../../../config/api';
import dataService from '../../../../config/dataService';
import { toast } from 'react-toastify';
import ContainedButton from '../../../../../src/component/customComponents/buttons/ContainedButton';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { ErrorMsg } from '../../../../component/customComponents/errorMsg/ErrorMsg';
import {
  minItemLength,
  itemLength,
  ITEM_REGEX,
} from '../../../../component/customComponents/validation/Validation';
//#endregion

import AdminIndex from '../../AdminIndex';

const Img = styled('img')({
  display: 'block',
  maxWidth: '100%',
  height: 'auto',
  maxHeight: '50vh',
  borderRadius: '6px',
});

const { getItemData, addItem } = AdminIndex.AdminSagaActions;
//#region ALL HOOKS AND VARIABALS
const AddNewItem = () => {
  let token = localStorage.getItem('auth');
  const history = useHistory();
  const navigate = useHistory();
  const dispatch = AdminIndex.useDispatch();
  const [image, setImage] = useState('');
  const [item, setItem] = useState([]);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  //#endregion

  //#region ALL EVENT HANDLERS AND  FUCTIONS
  const handleImg = (e) => {
    if (e.target.files.length) {
      setImage(e.target.files[0]);
    }
  };

  const removeImg = () => {
    setImage('');
  };

  const fetchitemData = async () => {
    let urlencoded = new URLSearchParams();
    dispatch(getItemData(token, urlencoded));
    // await dataService
    //   .post(Api.Admin.listItem, urlencoded)
    //   .then((res) => {
    //     setItem(res.data.data);
    //   })
    //   .catch((err) => toast.error(err?.message || 'Something went wrong'));
  };

  const itemData = AdminIndex.useSelector((state) => {
    return state.AdminSaga.itemData;
  });

  useEffect(() => {
    setItem(itemData);
  }, [itemData]);
  
  const handleSave = async (data) => {
    try {
      let formdata = new FormData();
      formdata.append('item', data.item?.trim());
      formdata.append('images', image);

      dispatch(addItem(token,formdata,navigate));
      // const userResp = await dataService.post(Api.Admin.addItem, formdata);
      // if (userResp.data.status === 1) {
      //   toast.success('Item added successfully');
      //   history.push('/admin/itemmanagement');
      // }
    } catch (err) {
      toast.error(err?.message || 'Something went wrong');
    }
  };

  useEffect(() => {
    fetchitemData();
  }, []);

  let sameItem = item.map((val) => {
    if (val.item.toLowerCase() == watch('item')?.toLowerCase()) {
      return false;
    } else {
      return true;
    }
  });

  //#endregion

  //#region RETURN JSX
  return (
    <>
      <form onSubmit={handleSubmit(handleSave)}>
        <Container maxWidth="false">
          <Box sx={{ border: 1, mx: { lg: 35, sm: 5 }, mt: 15, py: 5 }}>
            <Typography sx={{ my: 2 }} align="center" variant="h4">
              Add Item
            </Typography>
            <Typography
              sx={{ my: 2, mx: { xs: 3 } }}
              align="center"
              variant="h4"
            >
              <TextField
                label="Item"
                InputLabelProps={{ shrink: watch('item') ? true : false }}
                autoComplete="off"
                sx={{
                  mt: 2,
                  width: { lg: '300px', xs: '100%' },
                  '& .MuiFormHelperText-root': { ml: 0, mt: '5px' },
                }}
                size="small"
                fullWidth
                className="form-control2 border-none"
                name="item"
                type="text"
                {...register('item', {
                  required: <ErrorMsg msg="Please enter Item" />,
                  minLength: {
                    value: minItemLength,
                    message: (
                      <ErrorMsg
                        msg={`Minimum ${minItemLength} Characters Allowed`}
                      />
                    ),
                  },
                  maxLength: {
                    value: itemLength,
                    message: (
                      <ErrorMsg
                        msg={`Maximum ${itemLength} Characters Allowed`}
                      />
                    ),
                  },
                  pattern: {
                    value: ITEM_REGEX,
                    message: <ErrorMsg msg="Please enter valid Item" />,
                  },
                  validate: {
                    sameValCheck: (val) =>
                      !sameItem.includes(false) || (
                        <ErrorMsg msg={'Item already exists'} />
                      ),
                  },
                })}
                helperText={<ErrorMessage errors={errors} name="item" />}
              />
            </Typography>
            <Typography align="center">
              {image === '' ? (
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      mx: { xs: 3 },
                    }}
                  >
                    <Box sx={{ width: { lg: '300px', xs: '100%' } }}>
                      <Button
                        component="label"
                        sx={{
                          borderRadius: 0,
                          textTransform: 'none',
                          width: { lg: '300px', xs: '100%' },
                        }}
                        variant="contained"
                        onChange={handleImg}
                      >
                        Upload
                        <UploadIcon />
                        <input
                          name="files"
                          hidden
                          accept="image/*"
                          type="file"
                          {...register('files', {
                            required: <ErrorMsg msg="Please select Image" />,
                          })}
                        />
                      </Button>
                      <ErrorMessage errors={errors} name="files" />
                    </Box>
                  </Box>
                </>
              ) : null}
              {image === '' ? (
                ''
              ) : (
                <Box className="updateitem-img-box">
                  <Img
                    sx={{
                      my: 2,
                      height: '200px',
                      width: '100%',
                      maxWidth: '300px',
                      borderRadius: '0px',
                      position: 'relative',
                      margin: 'auto',
                    }}
                    src={window.URL.createObjectURL(image)}
                    alt="no image"
                  />
                  <Button className="remove-btn-upadteitem">
                    <Tooltip title="Remove Images" placement="bottom">
                      <DisabledByDefaultIcon
                        className="remove-img-icon close-upadte-item"
                        onClick={removeImg}
                        fontSize="large"
                        sx={{ mt: 5 }}
                      />
                    </Tooltip>
                  </Button>
                </Box>
              )}
            </Typography>
            <Typography sx={{ mt: 2 }} align="center" variant="h4">
              <ContainedButton name="Add" type="submit" />
            </Typography>
          </Box>
        </Container>
      </form>
    </>
  );
};

export default AddNewItem;
//#endregion
