//#region ALL IMPORTS
import React, { useState, useEffect } from 'react';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import {
  Typography,
  Button,
  Box,
  styled,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Container,
  TablePagination,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useHistory } from 'react-router-dom';
import { Api } from '../../../../../../config/api';
import dataService from '../../../../../../config/dataService';
import NoDataFound from '../../../../../../component/customComponents/noDataFound/NoDataFound';
import { toast } from 'react-toastify';
import Loader from '../../../../../../component/customComponents/loader/Loader';
import { ConvertToLink } from '../../../../../../component/customComponents/commonFunctions/CommonFunctions';
//#endregion

import AdminIndex from '../../../../AdminIndex';
//#region ALL HOOKS AND VARIABALS
const columns = [
  { id: 'srno', label: 'Sr No' },
  { id: 'image', label: 'Image' },
  { id: 'material', label: 'Material' },
  { id: 'heading', label: 'Heading' },
  { id: 'description', label: 'Description' },
  { id: 'action', label: 'Action', align: 'center' },
];

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const Img = styled('img')({
  display: 'block',
  maxWidth: '100%',
  height: 'auto',
  maxHeight: '50vh',
  borderRadius: '6px',
});

const { getProductPricingList, deleteProductPricing } = AdminIndex.AdminSagaActions;
export default function ProductPricing() {
  let token = localStorage.getItem('auth');
  const history = useHistory();
  // const [loading, setLoading] = useState(false);
  const dispatch = AdminIndex.useDispatch();
  const [flag, setFlag] = useState(false);
  const [productPricing, setProductPricing] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    productPricingData();
    setInterval(() => {
      setFlag(true);
    }, 500);
  }, []);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  //#endregion

  //#region ALL EVENT HANDLERS AND  FUCTIONS
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const productPricingData = async () => {
    // setLoading(true);
    let urlencoded = new URLSearchParams();
    dispatch(getProductPricingList(token, urlencoded));
    // await dataService
    //   .post(Api.Admin.listProductPricing, urlencoded, {
    //     headers: {
    //       auth: token,
    //     },
    //   })
    //   .then((res) => {
    //     setProductPricing(res.data.data);
    //   })
    //   .catch((err) => toast.error(err?.message || 'Something went wrong'))
    //   .finally(() => {
    //     setLoading(false);
    //   });
  };

  const loading = AdminIndex.useSelector((state) => {
    return state.AdminSaga.loading;
  });

  const productPricingList = AdminIndex.useSelector((state) => {
    return state.AdminSaga.productPricing;
  });

  useEffect(() => {
    setProductPricing(productPricingList);
  }, [productPricingList]);

  const deleteSingleProductPricing = async (id) => {
    let urlencoded = new URLSearchParams();
    try {
      urlencoded.append('id', id);
      dispatch(deleteProductPricing(token, urlencoded));
      // await dataService.post(Api.Admin.deleteProductPricing, urlencoded, {
      //   headers: {
      //     auth: token,
      //   },
      // });
      // productPricingData();
      // toast.success('Deleted Successfully');
    } catch (err) {
      toast.error(err?.message || 'Something went wrong');
    }
  };
  //#endregion

  //#region RETURN JSX
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Container sx={{ mt: 2 }} maxWidth="false">
          <Typography variant="h4" align="center" sx={{ fontWeight: 'bold' }}>
            Product Pricing Management
          </Typography>
          <br />
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ flexGrow: 1 }} />
            <Button
              onClick={() => {
                history.push('/admin/blogs/addproductpricing');
              }}
              variant="contained"
              sx={{
                borderRadius: '6px',
                margin: '0px 15px 0px 0px',
                height: '28px',
                background: '#3198F9 0% 0% no-repeat padding-box',
                borderRadius: 0,
                px: 5,
                py: 3,
                textTransform: 'none',
              }}
            >
              Add Product Pricing
            </Button>
          </Box>
          <br />
          <TableContainer
            elevation={0}
            sx={{
              maxWidth: {
                xs: '365px',
                sm: '600px',
                md: '100%',
                lg: '100%',
              },
              '&::-webkit-scrollbar': {
                width: 10,
              },
              '&::-webkit-scrollbar': {
                height: '8px',
                width: '12px',
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: '#fff',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(224, 224, 224, 1)',
                borderRadius: '30px',
                width: '20px',
              },
              '&::-webkit-scrollbar-thumb:hover': {
                background: '#B0B0B0',
              },
            }}
            component={Paper}
          >
            <Table
              sx={{
                width: {
                  xs: 'max-content',
                  sm: 'max-content',
                  md: '100%',
                  lg: '100%',
                },
              }}
              size="small"
            >
              <TableHead>
                <TableRow sx={{ bgcolor: 'black', whiteSpace: 'nowrap' }}>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth, bgcolor: 'red' }}
                      className="pagination-table-header-row"
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {productPricing?.length
                  ? productPricing
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row._id}
                            sx={{
                              '&:last-child td, &:last-child th': { border: 0 },
                            }}
                          >
                            <StyledTableCell align="left">
                              {index + 1}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Img
                                key={row.image}
                                sx={{
                                  my: 2,
                                  height: '30px',
                                  width: '30px',
                                }}
                                src={row.image}
                                alt="no image"
                                // className="guidelines-image"
                              />
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row.product_type}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row.heading}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {/* {row.description} */}
                              {<ConvertToLink content={row.description} />}
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              sx={{ whiteSpace: 'nowrap' }}
                            >
                              <Button>
                                <EditIcon
                                  sx={{ m: 1 }}
                                  onClick={() => {
                                    history.push(
                                      `/admin/blogs/updateproductpricing/${row._id}`
                                    );
                                  }}
                                />
                              </Button>
                              <Button>
                                <DeleteIcon
                                  sx={{ m: 1, color: 'red' }}
                                  onClick={() => {
                                    if (
                                      window.confirm(
                                        'Are you sure you want to delete this Product Pricing?'
                                      )
                                    ) {
                                      deleteSingleProductPricing(row._id);
                                    }
                                  }}
                                />
                              </Button>
                            </StyledTableCell>
                          </TableRow>
                        );
                      })
                  : ''}
              </TableBody>
            </Table>
            {!productPricing?.length && flag ? <NoDataFound /> : ''}
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            component="div"
            count={productPricing?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Container>
      )}
    </>
  );
}
//#endregion
