import React, { useEffect, useState } from "react";
import UserIndex from "../../UserIndex";
import UserSagaActions from "../../../../redux/Usersaga/action";
import { ToastContainer, toast } from "react-toastify";
import { price } from "../../../../component/customComponents/commonFunctions/CommonFunctions";

const { ViewCartSaga, UpdateCartQuantitySaga, DeleteCartItemSaga } =
  UserSagaActions;

const MyCart = () => {
  const history = UserIndex.useHistory();

  const dispatch = UserIndex.useDispatch();
  const navigate = UserIndex.useHistory();

  const loading = UserIndex.useSelector((state) => {
    return state.Usersaga.loading;
  });
  const Token = UserIndex.useSelector((state) => {
    return state.Usersaga.Token;
  });
  const Viewcartdata = UserIndex.useSelector((state) => {
    return state.Usersaga.Viewcartdata;
  });
  const UpdateCartQuantity = UserIndex.useSelector((state) => {
    return state.Usersaga.UpdateCartQuantity;
  });
  const DeleteCartItemdata = UserIndex.useSelector((state) => {
    return state.Usersaga.DeleteCartItemdata;
  });

  const [viewCartData, setViewCartData] = useState([]);
  const [qty, setQty] = useState([]);


  const qtyPlus = (id) => {
    let urlencoded = new URLSearchParams();
    urlencoded.append("id", viewCartData?._id);
    urlencoded.append("productId", id);
    urlencoded.append("type", "Add");
    dispatch(UpdateCartQuantitySaga(urlencoded, navigate, Token));
    getViewCart();
  };
  const qtyMinus = (id) => {
    let urlencoded = new URLSearchParams();
    urlencoded.append("id", viewCartData._id);
    urlencoded.append("productId", id);
    urlencoded.append("type", "Remove");
    dispatch(UpdateCartQuantitySaga(urlencoded, navigate, Token));
    getViewCart();
  };

  const getViewCart = () => {
    let urlencoded = new URLSearchParams();
    dispatch(ViewCartSaga(urlencoded, navigate, Token));
  };

  const getDeleteItem = (productId) => {
    let urlencoded = new URLSearchParams();
    urlencoded.append("id", viewCartData?._id);
    urlencoded.append("objId", productId);
    dispatch(DeleteCartItemSaga(urlencoded, navigate));
  };

  useEffect(() => {
    getViewCart();
  }, []);

  const cartData = () => {
    setViewCartData(Viewcartdata);
    let newarray = [];
    Viewcartdata?.products?.map((element, index) => {
      newarray = [
        ...newarray,
        { productId: element?.productId, quantity: element?.quantity },
      ];
      setQty(newarray);
    });
  };

  useEffect(() => {
    cartData();
  }, [Viewcartdata]);


  return (
    <div>
      <UserIndex.Header />
      {viewCartData?.products?.length > 0 ||
      // Object.keys(viewCartData)?.length > 0?.length ?
      Object.keys(viewCartData?.length > 0) ? (
        <>
          <UserIndex.Box className="body-bg">
            <UserIndex.Box className="mycart-sec">
              <UserIndex.Box className="container">
                <UserIndex.Box className="row">
                  <UserIndex.Box className="addinfo-text-box pg-main-title-box">
                    <UserIndex.Typography
                      variant="h2"
                      className="addinfo-text pg-main-title"
                    >
                      Cart
                    </UserIndex.Typography>
                  </UserIndex.Box>
                  <UserIndex.Box className="mycart-main">
                    <UserIndex.Box className="mo-table-main mycart-table-main">
                      <UserIndex.TableContainer
                        component={UserIndex.Paper}
                        className="mo-table-container mycart-tablecontainer"
                      >
                        <UserIndex.Table
                          sx={{ minWidth: 650 }}
                          aria-label="simple table"
                          className="mo-table mycart-table"
                        >
                          <UserIndex.TableHead className="mo-tablehead mycart-tablehead">
                            <UserIndex.TableRow>
                              <UserIndex.TableCell className="th-mycart">
                                Thumbnail
                              </UserIndex.TableCell>
                              <UserIndex.TableCell className="th-mycart">
                                Product
                              </UserIndex.TableCell>
                              <UserIndex.TableCell className="th-mycart">
                                Price
                              </UserIndex.TableCell>
                              <UserIndex.TableCell className="th-mycart">
                                Quantity
                              </UserIndex.TableCell>
                              <UserIndex.TableCell className="th-mycart">
                                Total
                              </UserIndex.TableCell>
                              <UserIndex.TableCell className="th-mycart">
                                Remove
                              </UserIndex.TableCell>
                            </UserIndex.TableRow>
                          </UserIndex.TableHead>
                          <UserIndex.TableBody className="mo-table-body mycart-table-body">
                            {viewCartData?.products?.length
                              ? viewCartData?.products?.map((item) => {
                                  return (
                                    <>
                                      <UserIndex.TableRow>
                                        <UserIndex.TableCell className="td-mycart">
                                          <img
                                            src={item?.image[0]}
                                            className="custome-img"
                                          ></img>
                                        </UserIndex.TableCell>
                                        <UserIndex.TableCell className="td-mycart">
                                          {item?.productName}
                                        </UserIndex.TableCell>
                                        <UserIndex.TableCell className="td-mycart">
                                          ₹{price(item?.price)}
                                        </UserIndex.TableCell>
                                        <UserIndex.TableCell className="td-mycart ">
                                          <UserIndex.Box className="add-quenty-box rings1-btn-box">
                                            <UserIndex.Box className="add-quenty-btn-box">
                                              <UserIndex.Box className="quenty-btn-box mycart-quenty-btn">
                                                <UserIndex.ButtonGroup className="qty-btn-group mycart-btn-group">
                                                  {/* {qty.map((data, index) => {
                                                    return (
                                                      <> */}
                                                  {item.quantity > 1 ? (
                                                    <UserIndex.PrimaryButton
                                                      onClick={() =>
                                                        qtyMinus(
                                                          item?.productId?._id
                                                            ? item?.productId
                                                                ?._id
                                                            : item?.productId
                                                        )
                                                      }
                                                      btnclass="btn btn-qty"
                                                      buttonvalue="-"
                                                    />
                                                  ) : (
                                                    <UserIndex.Button className="btn btn-qty">
                                                      -
                                                    </UserIndex.Button>
                                                  )}
                                                  {/* </>
                                                    )
                                                  })} */}
                                                  <UserIndex.Button className="btn btn-qty">
                                                    {
                                                      qty[
                                                        qty.findIndex(
                                                          (element) =>
                                                            element?.productId ==
                                                            item?.productId
                                                        )
                                                      ]?.quantity
                                                    }
                                                  </UserIndex.Button>
                                                  <UserIndex.PrimaryButton
                                                    onClick={() =>
                                                      qtyPlus(
                                                        item?.productId?._id
                                                          ? item?.productId?._id
                                                          : item?.productId
                                                      )
                                                    }
                                                    btnclass="btn btn-qty"
                                                    buttonvalue="+"
                                                  />
                                                </UserIndex.ButtonGroup>
                                              </UserIndex.Box>
                                            </UserIndex.Box>
                                          </UserIndex.Box>
                                        </UserIndex.TableCell>
                                        <UserIndex.TableCell className="td-mycart">
                                          ₹
                                          {price(
                                            qty[
                                              qty.findIndex(
                                                (element) =>
                                                  element?.productId ==
                                                  item?.productId
                                              )
                                            ].quantity * item?.price
                                          )}
                                        </UserIndex.TableCell>
                                        <UserIndex.TableCell className="td-mycart">
                                          <UserIndex.Box className="mycart-delete-button-box">
                                            <UserIndex.IconButton
                                              onClick={() => {
                                                getDeleteItem(
                                                  item?.productId?._id
                                                    ? item?.productId?._id
                                                    : item?.productId
                                                );
                                              }}
                                              className="mycart-delete-button"
                                            >
                                              <img
                                                src={UserIndex.svg.deleteblack}
                                                className="trash-img"
                                              ></img>
                                            </UserIndex.IconButton>
                                          </UserIndex.Box>
                                        </UserIndex.TableCell>
                                      </UserIndex.TableRow>
                                    </>
                                  );
                                })
                              : "No Records Found"}
                            <UserIndex.TableRow>
                              <UserIndex.TableCell
                                className="td-mycart"
                                colSpan="4"
                              ></UserIndex.TableCell>
                              <UserIndex.TableCell
                                className="td-mycart"
                                colSpan="2"
                              >
                                {viewCartData?.products?.length > 0 ? (
                                  <UserIndex.Box className="cart-total-box">
                                    <UserIndex.Typography className="cart-total-title">
                                      Cart Total
                                    </UserIndex.Typography>
                                    <UserIndex.Box className="cart-total-content">
                                      <UserIndex.Box className="cart-total-main">
                                        <UserIndex.Typography className="cart-total-text">
                                          Sub Total
                                        </UserIndex.Typography>
                                        <UserIndex.Typography className="cart-total-text">
                                          ₹{price(viewCartData?.total)}
                                        </UserIndex.Typography>
                                      </UserIndex.Box>
                                      <UserIndex.Box className="cart-total-main">
                                        <UserIndex.Typography className="cart-total-text">
                                          Shipping
                                        </UserIndex.Typography>
                                        <UserIndex.Typography className="cart-total-text">
                                          ₹00
                                        </UserIndex.Typography>
                                      </UserIndex.Box>
                                      <UserIndex.Box className="cart-total-main">
                                        <UserIndex.Typography className="cart-total-text">
                                          Total
                                        </UserIndex.Typography>
                                        <UserIndex.Typography className="cart-total-text primary-text-color">
                                          ₹{price(viewCartData?.total)}
                                        </UserIndex.Typography>
                                      </UserIndex.Box>
                                    </UserIndex.Box>
                                    <UserIndex.Box className="primary-btn-box checkout-btn-box">
                                      <UserIndex.PrimaryButton
                                        onClick={() => {
                                          history.push("/user/paymentmethod");
                                        }}
                                        btnclass="primary-btn checkout-btn"
                                        buttonvalue="Proceed to Checkout"
                                      />
                                    </UserIndex.Box>
                                  </UserIndex.Box>
                                ) : (
                                  ""
                                )}
                              </UserIndex.TableCell>
                            </UserIndex.TableRow>
                          </UserIndex.TableBody>
                        </UserIndex.Table>
                      </UserIndex.TableContainer>
                    </UserIndex.Box>
                  </UserIndex.Box>
                </UserIndex.Box>
              </UserIndex.Box>
            </UserIndex.Box>
          </UserIndex.Box>
        </>
      ) : (
        <>
          <UserIndex.Box className="view-cart-empty-message">
            Cart Is Empty
          </UserIndex.Box>
        </>
      )}
      <UserIndex.Footer />
    </div>
  );
};

export default MyCart;
