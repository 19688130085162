import React from 'react';
import { Button } from '@mui/material';

const DeclineButton = (props) => {
  return (
    <Button
    disabled={props.disabled}
      className={"right-btns" && props.className}
      variant="contained"
      sx={{
        borderRadius: 5,
        textTransform: 'none',
        width: '90px',
        bgcolor: 'red',
        display: props.display
      }}
      onClick={props.onClick}
    >
      Decline
    </Button>
  );
};

export default DeclineButton;
