import * as React from 'react';
import Mui from '../../../../component/customComponents/mui/Mui';
import MuiIcon from '../../../../component/customComponents/mui/MuiIcon';

//#region ALL HOOKS AND VARIABALS

function createData(api, status, action) {
  return { api, status, action };
}

const data =
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc maximus';

const rows = [
  createData(data, 'Enable'),
  createData(data, 'Enable'),
  createData(data, 'Enable'),
  createData(data, 'Enable'),
  createData(data, 'Enable'),
  createData(data, 'Enable'),
];

export default function ApiManagement() {
  //#endregion

  //#region ALL EVENT HANDLERS AND  FUCTIONS
  //#endregion

  //#region RETURN JSX
  return (
    <>
      <Mui.Container maxWidth="false">
        <Mui.Typography variant="h4" align="center" sx={{ fontWeight: 'bold' }}>
          API Management
        </Mui.Typography>
        <Mui.Box
          sx={{ display: 'flex', justifyContent: 'center', mt: 5, mb: 2 }}
        >
          <Mui.Tooltip title="Search by ()" placement="bottom-start">
            <Mui.SearchMaxWidth>
              <Mui.SearchIconWrapper>
                <MuiIcon.SearchIcon />
              </Mui.SearchIconWrapper>
              <Mui.StyledInputBase
                placeholder="Search"
                inputProps={{ 'aria-label': 'search' }}
              />
            </Mui.SearchMaxWidth>
          </Mui.Tooltip>
        </Mui.Box>
        <Mui.TableContainer
          elevation={0}
          sx={{
            maxWidth: {
              xs: '360px',
              sm: '600px',
              md: '100%',
              lg: '100%',
            },
            '&::-webkit-scrollbar': {
              width: 10,
            },
            '&::-webkit-scrollbar': {
              height: '8px',
              width: '12px',
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: '#fff',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(224, 224, 224, 1)',
              borderRadius: '30px',
              width: '20px',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              background: '#B0B0B0',
            },
          }}
          component={Mui.Paper}
        >
          <Mui.Table
            sx={{
              width: {
                xs: 'max-content',
                sm: 'max-content',
                md: '100%',
                lg: '100%',
              },
            }}
            size="small"
          >
            <Mui.TableHead>
              <Mui.TableRow>
                <Mui.StyledTableCell align="center">API</Mui.StyledTableCell>
                <Mui.StyledTableCell align="right">Status</Mui.StyledTableCell>
                <Mui.StyledTableCell align="center">Action</Mui.StyledTableCell>
              </Mui.TableRow>
            </Mui.TableHead>
            <Mui.TableBody>
              {rows.map((row) => (
                <Mui.StyledTableRow key={row.name}>
                  <Mui.StyledTableCell align="center">
                    {row.api}
                  </Mui.StyledTableCell>
                  <Mui.StyledTableCell align="right">
                    {row.status}
                  </Mui.StyledTableCell>
                  <Mui.StyledTableCell align="center">
                    <Mui.Button
                      variant="contained"
                      sx={{ textTransform: 'none', borderRadius: 0 }}
                    >
                      Disable
                    </Mui.Button>
                  </Mui.StyledTableCell>
                </Mui.StyledTableRow>
              ))}
            </Mui.TableBody>
          </Mui.Table>
        </Mui.TableContainer>
      </Mui.Container>
    </>
  );
}
//#endregion
