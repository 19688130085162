//#region ALL IMPORTS
import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import {
  Grid,
  Container,
  Typography,
  Button,
  TextareaAutosize,
  Box,
  FormHelperText,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Tooltip,
} from '@mui/material';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import { useHistory, useParams } from 'react-router-dom';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { ToastContainer, toast } from 'react-toastify';
import { Api } from '../../../../../../config/api';
import dataService from '../../../../../../config/dataService';
import ContainedButton from '../../../../../../component/customComponents/buttons/ContainedButton';
import ImgUploadButton from '../../../../../../component/customComponents/buttons/ImgUploadButton';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import {
  ErrorMsg,
  TextAreaErrorMsg,
} from '../../../../../../component/customComponents/errorMsg/ErrorMsg';
import {
  minGuidelinesHeadingLength,
  guidelinesHeadingLength,
  minGuidelinesDescriptionLength,
  guidelinesDescriptionLength,
  NO_WHITE_SPACE_BEGINNING_END,
} from '../../../../../../component/customComponents/validation/Validation';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import UploadIcon from '@mui/icons-material/Upload';
//#endregion

//#region ALL HOOKS AND VARIABALS
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const Img = styled('img')({
  display: 'block',
  maxWidth: '100%',
  height: 'auto',
  maxHeight: '50vh',
  borderRadius: '6px',
});

const UpdateGuidelines = () => {
  const { id } = useParams();
  let token = localStorage.getItem('auth');
  const history = useHistory();
  const [getData, setGetData] = useState('');
  const [image, setImage] = useState('');
  const [url, setUrl] = useState('');

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({ mode: 'onChange' });
  //#endregion

  //#region ALL EVENT HANDLERS AND  FUCTIONS
  const getGuidelines = async () => {
    let urlencoded = new URLSearchParams();
    try {
      urlencoded.append('id', id);
      const Data = await dataService.post(
        Api.Admin.singleguideline,
        urlencoded
      );
      setGetData(Data.data.data);
    } catch (err) {
      toast.error(err?.message || 'Something went wrong');
    }
  };

  useEffect(() => {
    getGuidelines();
  }, []);

  useEffect(() => {
    setUrl(getData?.image);
    setValue('guidelinesHeading', getData?.heading);
    setValue('guidelinesDescription', getData?.description);
    setValue('guidelinetype', getData?.guideline_type, {
      shouldValidate: true,
    });
  }, [getData]);

  const handleImg = (e) => {
    if (e.target.files.length) {
      setImage(e.target.files[0]);
      setUrl(window.URL.createObjectURL(e.target.files[0]));
    }
  };

  const removeImg = () => {
    setImage('');
    setUrl('');
  };

  const handleSave = async (data) => {
    try {
      let formdata = new FormData();
      formdata.append('id', id);
      formdata.append('heading', data?.guidelinesHeading?.trim());
      formdata.append('description', data?.guidelinesDescription?.trim());
      formdata.append('guideline_type', data?.guidelinetype?.trim());
      formdata.append('images', image);
      formdata.append('fileurl', url);

      const blogData = await dataService.post(
        Api.Admin.updateGuideline,
        formdata,
        {
          headers: {
            auth: token,
          },
        }
      );

      if (blogData.data.status === 1) {
        toast.success('Guidelines Updated successfully');
        history.push('/admin/guidelines');
      }
    } catch (err) {
      toast.error(err?.message || 'Something went wrong');
    }
  };
  //#endregion

  //#region RETURN JSX
  return (
    <>
      <form onSubmit={handleSubmit(handleSave)}>
        <Container maxWidth="false">
          <Typography sx={{ my: 2 }} align="center" variant="h4">
            Update Guideline
          </Typography>

          <Grid container spacing={5}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography align="left" sx={{ mt: 2 }}>
                Guidelines Heading
              </Typography>
              <TextField
                autoComplete="off"
                sx={{
                  mt: 2,
                  '& .MuiFormHelperText-root': { ml: 0, mt: '5px' },
                }}
                size="small"
                fullWidth
                className="form-control border-none"
                name="guidelinesHeading"
                type="text"
                {...register('guidelinesHeading', {
                  required: <ErrorMsg msg="Please enter Heading" />,
                  minLength: {
                    value: minGuidelinesHeadingLength,
                    message: (
                      <ErrorMsg
                        msg={`Minimum ${minGuidelinesHeadingLength} Characters Allowed`}
                      />
                    ),
                  },
                  maxLength: {
                    value: guidelinesHeadingLength,
                    message: (
                      <ErrorMsg
                        msg={`Maximum ${guidelinesHeadingLength} Characters Allowed`}
                      />
                    ),
                  },
                  pattern: {
                    value: NO_WHITE_SPACE_BEGINNING_END,
                    message: (
                      <ErrorMsg msg="Whitespace is not allowed at beginning or end" />
                    ),
                  },
                })}
                helperText={
                  <ErrorMessage errors={errors} name="guidelinesHeading" />
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography align="left" sx={{ mt: 2 }}>
                Description
              </Typography>
              <Box className="text-area-control-main">
                <TextareaAutosize
                  autoComplete="off"
                  minRows={0}
                  size="small"
                  fullWidth
                  className="form-control border-none text-area-control1 text-area-control-admin"
                  name="guidelinesDescription"
                  type="text"
                  {...register('guidelinesDescription', {
                    required: (
                      <TextAreaErrorMsg msg="Please enter Description" />
                    ),
                    minLength: {
                      value: minGuidelinesDescriptionLength,
                      message: (
                        <TextAreaErrorMsg
                          msg={`Minimum ${minGuidelinesDescriptionLength} Characters Allowed`}
                        />
                      ),
                    },
                    maxLength: {
                      value: guidelinesDescriptionLength,
                      message: (
                        <TextAreaErrorMsg
                          msg={`Maximum ${guidelinesDescriptionLength} Characters Allowed`}
                        />
                      ),
                    },
                    pattern: {
                      value: NO_WHITE_SPACE_BEGINNING_END,
                      message: (
                        <TextAreaErrorMsg msg="Whitespace is not allowed at beginning or end" />
                      ),
                    },
                  })}
                />
                <ErrorMessage errors={errors} name="guidelinesDescription" />
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography align="left" sx={{ mt: 2 }}>
                Guidline Type
              </Typography>
              <Box className="text-area-control-main1">
                <Controller
                  name="guidelinetype"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value } }) => (
                    <Select
                      sx={{ py: '6px' }}
                      defaultValue="1"
                      labelId="guidelinetype"
                      className="form-control1 border-none text-area-control2"
                      id="guidelinetype"
                      size="small"
                      value={value}
                      onChange={onChange}
                    >
                      <MenuItem value={'Uploading Products on Zaverica'}>
                        Uploading Products on Zaverica
                      </MenuItem>
                      <MenuItem value={'Requesting Custom Products'}>
                        Requesting Custom Products
                      </MenuItem>
                      <MenuItem value={'Placing Bulk Order'}>
                        Placing Bulk Order
                      </MenuItem>
                      <MenuItem value={'Trading Precious Stone'}>
                        Trading Precious Stone
                      </MenuItem>
                    </Select>
                  )}
                />
                <ErrorMessage errors={errors} name="guidelinetype" />
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography align="left" sx={{ mt: 2, mb: 1.5 }}>
                Upload Image
              </Typography>
              <Box sx={{ width: '100%', position: 'relative' }}>
                {image === '' && url == '' && (
                  <>
                    <Button
                      component="label"
                      sx={{ borderRadius: 0, textTransform: 'none' }}
                      variant="contained"
                      onChange={handleImg}
                    >
                      Upload
                      <UploadIcon />
                      <input
                        name="files"
                        hidden
                        accept="image/*"
                        type="file"
                        {...register('files', {
                          required: <ErrorMsg msg="Please select Image" />,
                        })}
                      />
                    </Button>
                    <ErrorMessage errors={errors} name="files" />
                  </>
                )}
                {image === '' && url == '' ? (
                  ''
                ) : (
                  <Box className="aboutUs-img-box">
                    <Img
                      sx={{
                        my: 2,
                        borderRadius: '0px',
                        height: '130px',
                        width: '200px',
                      }}
                      src={url}
                      alt="no image"
                    />
                    <Button className="aboutUs-top-btn">
                      <Tooltip title="Remove this Image" placement="bottom">
                        <DisabledByDefaultIcon
                          className="remove-img-icon"
                          onClick={removeImg}
                          sx={{
                            // bgcolor: 'black(0.5)',
                            // color: 'black',
                            mt: 5,
                          }}
                        />
                      </Tooltip>
                    </Button>
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ display: 'flex', justifyContent: 'center', my: 5 }}>
            <ContainedButton name="Update" type="submit" />
          </Box>
        </Container>
      </form>
    </>
  );
};

export default UpdateGuidelines;
//#endregion
