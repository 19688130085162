import React from 'react';
import Box from "@mui/material/Box";
import { Button, Typography } from '@mui/material';
import svg from "../../../../assets/svg";
import png from "../../../../assets/png";
import Header from '../../../../component/user/defaultLayout/Header';
import Footer from '../../../../component/user/defaultLayout/Footer';



export default function Requestproduct1() {
  return (
    <div>
      <Header />
      <Box className='requestproduct1-sec'>
        <Box className='container'>
          <Box className='requestproduct1-content'>
            <img src={png.thankyou} className="thankyou-img"></img>
            <Typography className='thankyou-text' variant='h1'>Thank You</Typography>
            <Typography className='thankyou-para' variantMapping='p'>Our team will connect with you within 24 hours.</Typography>
          </Box>
        </Box>
      </Box>
      <Footer />
    </div>
  )
}
