//#region ALL IMPORTS
import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import {
  Grid,
  Container,
  Typography,
  Button,
  TextareaAutosize,
  Box,
  FormHelperText,
  Input,
  FormControl,
  Select,
  MenuItem,
  Tooltip,
  TextField,
} from '@mui/material';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import { useHistory, useParams } from 'react-router-dom';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { ToastContainer, toast } from 'react-toastify';
import { Api } from '../../../../../../config/api';
import dataService from '../../../../../../config/dataService';
import ContainedButton from '../../../../../../component/customComponents/buttons/ContainedButton';
import ImgUploadButton from '../../../../../../component/customComponents/buttons/ImgUploadButton';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import {
  ErrorMsg,
  TextAreaErrorMsg,
} from '../../../../../../component/customComponents/errorMsg/ErrorMsg';
import {
  minDetailsHeadingLength,
  detailsHeadingLength,
  minDetailsDescriptionLength,
  detailsDescriptionLength,
  NO_WHITE_SPACE_BEGINNING_END,
} from '../../../../../../component/customComponents/validation/Validation';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import UploadIcon from '@mui/icons-material/Upload';
//#endregion

import Loader from '../../../../../../component/customComponents/loader/Loader';

import AdminIndex from '../../../../AdminIndex';
//#region ALL HOOKS AND VARIABALS
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const Img = styled('img')({
  display: 'block',
  maxWidth: '100%',
  height: 'auto',
  maxHeight: '50vh',
  borderRadius: '6px',
});

const { getSingleProductDescription, updateProductDescription } = AdminIndex.AdminSagaActions;
const UpdateProductDetails = () => {
  const { id } = useParams();
  let token = localStorage.getItem('auth');
  const dispatch = AdminIndex.useDispatch();
  const navigate = AdminIndex.useHistory();
  const history = useHistory();
  const [getData, setGetData] = useState('');
  const [image, setImage] = useState('');
  const [url, setUrl] = useState('');

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({ mode: 'onChange' });
  //#endregion

  //#region ALL EVENT HANDLERS AND  FUCTIONS
  const getGuidelines = async () => {
    let urlencoded = new URLSearchParams();
    try {
      urlencoded.append('id', id);
      dispatch(getSingleProductDescription(token, urlencoded));
      // const Data = await dataService.post(
      //   Api.Admin.singleProductDescription,
      //   urlencoded
      // );
      // setGetData(Data.data.data);
    } catch (err) {
      toast.error(err?.message || 'Something went wrong');
    }
  };

  const loading = AdminIndex.useSelector((state) => {
    return state.AdminSaga.loading;
  });

  const singleProductDescription = AdminIndex.useSelector((state) => {
    return state.AdminSaga.singleProductDescription;
  });

  useEffect(() => {
    setGetData(singleProductDescription);
  }, [singleProductDescription]);

  useEffect(() => {
    getGuidelines();
  }, []);

  useEffect(() => {
    setUrl(getData?.image);
    setValue('productDetailsHeading', getData?.heading);
    setValue('productDetailsDescription', getData?.description);
    setValue('item', getData?.product_type, { shouldValidate: true });
  }, [getData]);

  const CustomToast = () => {
    toast('Please enter valid data', {
      toastId: 'success1',
    });
  };

  const handleImg = (e) => {
    if (e.target.files.length) {
      setImage(e.target.files[0]);
      setUrl(window.URL.createObjectURL(e.target.files[0]));
    }
  };

  const removeImg = () => {
    setImage('');
    setUrl('');
  };

  const handleSave = async (data) => {
    try {
      let formdata = new FormData();
      formdata.append('id', id);
      formdata.append('heading', data?.productDetailsHeading?.trim());
      formdata.append('description', data?.productDetailsDescription?.trim());
      formdata.append('product_type', data?.item?.trim());
      formdata.append('images', image);
      formdata.append('fileurl', url);

      dispatch(updateProductDescription(token, formdata, navigate));
      // const blogData = await dataService.post(
      //   Api.Admin.updateProductDescription,
      //   formdata,
      //   {
      //     headers: {
      //       auth: token,
      //     },
      //   }
      // );

      // if (blogData.data.status === 1) {
      //   toast.success('Product Details Updated successfully');
      //   history.push('/admin/productdetails');
      // }
    } catch (err) {
      toast.error(err?.message || 'Something went wrong');
    }
  };
  //#endregion

  //#region RETURN JSX
  return (
    <>{loading ? (
      <Loader />
    ) : (
      <form onSubmit={handleSubmit(handleSave)}>
        <Container maxWidth="false">
          <Typography sx={{ my: 2 }} align="center" variant="h4">
            Update Product Details
          </Typography>

          <Grid container spacing={5}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography align="left" sx={{ mt: 2 }}>
                Product Details Heading
              </Typography>
              <TextField
                autoComplete="off"
                sx={{
                  mt: 2,
                  '& .MuiFormHelperText-root': { ml: 0, mt: '5px' },
                }}
                size="small"
                fullWidth
                className="form-control border-none"
                name="productDetailsHeading"
                type="text"
                {...register('productDetailsHeading', {
                  required: <ErrorMsg msg="Please enter Heading" />,
                  minLength: {
                    value: minDetailsHeadingLength,
                    message: (
                      <ErrorMsg
                        msg={`Minimum ${minDetailsHeadingLength} Characters Allowed`}
                      />
                    ),
                  },
                  maxLength: {
                    value: detailsHeadingLength,
                    message: (
                      <ErrorMsg
                        msg={`Maximum ${detailsHeadingLength} Characters Allowed`}
                      />
                    ),
                  },
                  pattern: {
                    value: NO_WHITE_SPACE_BEGINNING_END,
                    message: (
                      <ErrorMsg msg="Whitespace is not allowed at beginning or end" />
                    ),
                  },
                })}
                helperText={
                  <ErrorMessage errors={errors} name="productDetailsHeading" />
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography align="left" sx={{ mt: 2 }}>
                Description
              </Typography>
              <Box className="text-area-control-main">
                <TextareaAutosize
                  autoComplete="off"
                  minRows={0}
                  size="small"
                  fullWidth
                  className="form-control border-none text-area-control1 text-area-control-admin"
                  name="productDetailsDescription"
                  type="text"
                  {...register('productDetailsDescription', {
                    required: (
                      <TextAreaErrorMsg msg="Please enter Description" />
                    ),
                    minLength: {
                      value: minDetailsDescriptionLength,
                      message: (
                        <TextAreaErrorMsg
                          msg={`Minimum ${minDetailsDescriptionLength} Characters Allowed`}
                        />
                      ),
                    },
                    maxLength: {
                      value: detailsDescriptionLength,
                      message: (
                        <TextAreaErrorMsg
                          msg={`Maximum ${detailsDescriptionLength} Characters Allowed`}
                        />
                      ),
                    },
                    pattern: {
                      value: NO_WHITE_SPACE_BEGINNING_END,
                      message: (
                        <TextAreaErrorMsg msg="Whitespace is not allowed at beginning or end" />
                      ),
                    },
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="productDetailsDescription"
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography align="left" sx={{ mt: 2 }}>
                Item
              </Typography>
              <Box className="text-area-control-main1">
                <Controller
                  name="item"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value } }) => (
                    <Select
                      sx={{ py: '6px' }}
                      defaultValue="1"
                      labelId="item"
                      className="form-control1 border-none text-area-control2"
                      id="item"
                      size="small"
                      value={value}
                      onChange={onChange}
                    >
                      <MenuItem value={'Ring'}>Ring</MenuItem>
                      <MenuItem value={'Necklace'}>Necklace</MenuItem>
                      <MenuItem value={'Mangalsutra'}>Mangalsutra</MenuItem>
                    </Select>
                  )}
                />
                <ErrorMessage errors={errors} name="item" />
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography align="left" sx={{ mt: 2, mb: 1.5 }}>
                Upload Image
              </Typography>
              <Box sx={{ width: '100%', position: 'relative' }}>
                {image === '' && url == '' && (
                  <>
                    <Button
                      component="label"
                      sx={{ borderRadius: 0, textTransform: 'none' }}
                      variant="contained"
                      onChange={handleImg}
                    >
                      Upload
                      <UploadIcon />
                      <input
                        name="files"
                        hidden
                        accept="image/*"
                        type="file"
                        {...register('files', {
                          required: <ErrorMsg msg="Please select Image" />,
                        })}
                      />
                    </Button>
                    <ErrorMessage errors={errors} name="files" />
                  </>
                )}
                {image === '' && url == '' ? (
                  ''
                ) : (
                  <Box className="aboutUs-img-box">
                    <Img
                      sx={{
                        my: 2,
                        borderRadius: '0px',
                        height: '130px',
                        width: '200px',
                      }}
                      src={url}
                      alt="no image"
                    />
                    <Button className="aboutUs-top-btn">
                      <Tooltip title="Remove this Image" placement="bottom">
                        <DisabledByDefaultIcon
                          className="remove-img-icon"
                          onClick={removeImg}
                          sx={{
                            // bgcolor: 'black(0.5)',
                            // color: 'black',
                            mt: 5,
                          }}
                        />
                      </Tooltip>
                    </Button>
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ display: 'flex', justifyContent: 'center', my: 5 }}>
            <ContainedButton name="Update" type="submit" />
          </Box>
        </Container>
      </form>
      )}
    </>
  );
};

export default UpdateProductDetails;
//#endregion
