import React from 'react';
import { FormHelperText } from '@mui/material';

export const ErrorMsg = ({msg}) => {
  return (
      <FormHelperText sx={{ color: '#d32f2f', mt: '10px' }}>
        {msg}
      </FormHelperText>
  );
};
export const TextAreaErrorMsg = ({msg}) => {
  return (
      <FormHelperText sx={{ color: '#d32f2f' }}>
        {msg}
      </FormHelperText>
  );
};

export const ErrorMsg1 = ({msg}) => {
  return (
      <FormHelperText sx={{ color: '#d32f2f', ml: '0px', mt: '5px' }}>
        {msg}
      </FormHelperText>
  );
};
