//#region ALL IMPORTS
import React, { useEffect, useState } from 'react';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import {
  Typography,
  Button,
  Box,
  styled,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Container,
  Table,
  TablePagination,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useHistory } from 'react-router-dom';
import { Api } from '../../../../config/api';
import dataService from '../../../../config/dataService';
import { toast, ToastContainer } from 'react-toastify';
import Loader from '../../../../component/customComponents/loader/Loader';
import NoDataFound from '../../../../component/customComponents/noDataFound/NoDataFound';
//#endregion

import AdminIndex from '../../AdminIndex';

//#region ALL HOOKS AND VARIABALS
const columns = [
  { id: 'index', label: 'Sr No' },
  { id: 'image', label: 'Image' },
  { id: 'item', label: 'Item' },
  {
    id: 'action',
    label: 'Action',
    align: 'center',
  },
];

const Img = styled('img')({
  display: 'block',
  maxWidth: '100%',
  height: 'auto',
  maxHeight: '50vh',
  borderRadius: '6px',
});

const { getItemData, deleteItem } = AdminIndex.AdminSagaActions;
export default function ItemManagement() {
  const history = useHistory();
  let token = localStorage.getItem('auth');
  // const [loading, setLoading] = useState(false);
  const dispatch = AdminIndex.useDispatch();
  const [flag, setFlag] = useState(false);
  const [item, setItem] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    fetchItemData();
    setInterval(() => {
      setFlag(true);
    }, 500);
  }, []);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  //#endregion

  //#region ALL EVENT HANDLERS AND  FUCTIONS
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  
  const fetchItemData = async () => {
    let urlencoded = new URLSearchParams();
    dispatch(getItemData(token,urlencoded));
    // await dataService
    //   .post(Api.Admin.listItem, urlencoded)
    //   .then((res) => {
    //     setItem(res.data.data);
    //   })
    //   .catch((err) => toast.error(err?.message || 'Something went wrong'))
    //   .finally(() => {
    //     setLoading(false);
    //   });
  };

  const loading = AdminIndex.useSelector((state) => {
    return state.AdminSaga.loading;
  });

  const itemData = AdminIndex.useSelector((state) => {
    return state.AdminSaga.itemData;
  });

  useEffect(() => {
    setItem(itemData);
  }, [itemData]);

  const deleteSingleItem = async (id) => {
    let urlencoded = new URLSearchParams();
    urlencoded.append('id', id);

    try {
      dispatch(deleteItem(token, urlencoded))
      // const deleteItem = await dataService.post(
      //   Api.Admin.deleteItem,
      //   urlencoded
      // );
      // if (deleteItem.data.status == 1) {
      //   toast.success('Item deleted successfully');
      // }
      // itemData();
    } catch (err) {
      toast.error(err?.message || 'Something went wrong');
    }
  };
  //#endregion

  //#region RETURN JSX
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Container sx={{ mt: 2, mb: 5 }} maxWidth="false">
          <Typography variant="h4" align="center" sx={{ fontWeight: 'bold' }}>
            Item Management
          </Typography>
          <br />
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ flexGrow: 1 }} />
            <Button
              onClick={() => {
                history.push('/admin/itemmanagement/additem');
              }}
              variant="contained"
              sx={{
                borderRadius: '6px',
                margin: '0px 15px 0px 0px',
                height: '28px',
                background: '#3198F9 0% 0% no-repeat padding-box',
                borderRadius: 0,
                px: 5,
                py: 3,
                textTransform: 'none',
              }}
            >
              Add Item
            </Button>
          </Box>
          <br />
          <TableContainer
            elevation={0}
            sx={{
              maxWidth: {
                xs: '365px',
                sm: '600px',
                md: '100%',
                lg: '100%',
              },
              '&::-webkit-scrollbar': {
                width: 10,
              },
              '&::-webkit-scrollbar': {
                height: '8px',
                width: '12px',
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: '#fff',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(224, 224, 224, 1)',
                borderRadius: '30px',
                width: '20px',
              },
              '&::-webkit-scrollbar-thumb:hover': {
                background: '#B0B0B0',
              },
            }}
            component={Paper}
          >
            <Table
              sx={{
                width: {
                  xs: 'max-content',
                  sm: 'max-content',
                  md: '100%',
                  lg: '100%',
                },
              }}
              size="small"
            >
              <TableHead>
                <TableRow sx={{ bgcolor: 'black' }}>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth, bgcolor: 'red' }}
                      className="pagination-table-header-row"
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {item
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row._id}
                      >
                        <StyledTableCell align="left">
                          {index + 1}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <Img
                            key={row.image}
                            sx={{
                              my: 2,
                              height: '30px',
                              width: '30px',
                            }}
                            src={row.image}
                            alt="no image"
                            // className="guidelines-image"
                          />
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row.item}
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          sx={{ whiteSpace: 'nowrap' }}
                        >
                          <Button>
                            <EditIcon
                              sx={{ m: 1 }}
                              onClick={() => {
                                history.push(
                                  `/admin/itemmanagement/updateitem/${row._id}`
                                );
                              }}
                            />
                          </Button>
                          <Button>
                            <DeleteIcon
                              sx={{ m: 1, color: 'red' }}
                              onClick={() => {
                                if (
                                  window.confirm(
                                    'Are you sure you want to delete this Item?'
                                  )
                                ) {
                                  deleteSingleItem(row._id);
                                }
                              }}
                            />
                          </Button>
                        </StyledTableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            {!item.length && flag ? <NoDataFound /> : ''}
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            component="div"
            count={item.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Container>
      )}
    </>
  );
}
//#endregion
