//#region ALL IMPORTS
import React, { useState, useEffect } from 'react';
import {
  Grid,
  Container,
  Typography,
  Button,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from '@mui/material';
import png from '../../../../assets/png';
import { useHistory, useParams } from 'react-router-dom';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { Api } from '../../../../config/api';
import dataService from '../../../../config/dataService';
import { toast } from 'react-toastify';
import Png from '../../../../assets/png';
import { Box } from '@mui/system';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { ErrorMsg } from '../../../../component/customComponents/errorMsg/ErrorMsg';
import {
  makingChargesLength,
  minMakingChargesLength,
  MAKING_CHARGES,
} from '../../../../component/customComponents/validation/Validation';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import UploadIcon from '@mui/icons-material/Upload';
//#endregion

//#region ALL HOOKS AND VARIABALS
const CustomProductRequestsNotification = (props) => {
  let token = localStorage.getItem('auth');
  const [id, setId] = useState('');
  const [getData, setGetData] = useState('');

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
    watch,
  } = useForm({ mode: 'onChange' });
  //#endregion

  //#region ALL EVENT HANDLERS AND  FUCTIONS
  const getCustomProductRequestNotification = async () => {
    let urlencoded = new URLSearchParams();
    if (props.id) {
      try {
        urlencoded.append('id', props.id);
        const Data = await dataService.post(
          Api.Admin.notificationDetails,
          urlencoded,
          {
            headers: {
              auth: token,
            },
          }
        );
        setGetData(Data.data.data);
        setId(Data.data.data._id);
      } catch (err) {
        toast.error(err?.message || 'Something went wrong');
      }
    }
  };

  useEffect(() => {
    if (props.id != '' && props.id != ' ') {
      getCustomProductRequestNotification();
    }
  }, []);

  useEffect(() => {
    setValue('notificationcount', getData?.notification_count);
    setValue('notificationperiod', getData?.time_period);
  }, [getData]);

  const handleSave = async (data) => {
    try {
      let urlencoded = new URLSearchParams();
      if (props.id !== '' && props.id !== ' ') {
        urlencoded.append('id', props.id);
        urlencoded.append('notificationCount', data?.notificationcount);
        urlencoded.append('timePeriod', data?.notificationperiod);

        const makingChargesData = await dataService.post(
          Api.Admin.updateNotification,
          urlencoded,
          {
            headers: {
              auth: token,
            },
          }
        );

        if (makingChargesData.data.status == 1) {
          toast.success(
            'Custom product request notification Updated successfully'
          );
          window.location.reload();
        }
      } else {
        urlencoded.append('notificationCount', data?.notificationcount);
        urlencoded.append('timePeriod', data?.notificationperiod);

        const makingChargesData = await dataService.post(
          Api.Admin.addNotification,
          urlencoded,
          {
            headers: {
              auth: token,
            },
          }
        );
        if (makingChargesData.data.status == 1) {
          toast.success(
            'Custom product request notification added successfully'
          );
          window.location.reload();
        }
      }
    } catch (err) {
      toast.error(err?.message || 'Something went wrong');
    }
  };
  //#endregion

  //#region RETURN JSX
  return (
    <>
      <form onSubmit={handleSubmit(handleSave)}>
        <Container maxWidth="false">
          <Typography sx={{ my: 2 }} align="center" variant="h4">
            Custom Product Requests Notification
          </Typography>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              mt: 7,
            }}
          >
            {/* <TextField
              label="Making Charges"
              InputLabelProps={{
                shrink: watch('makingCharges') ? true : false,
              }}
              autoComplete="off"
              sx={{
                mt: 2,
                width: { lg: '300px', xs: '150px' },
                '& .MuiFormHelperText-root': { ml: 0, mt: '5px' },
              }}
              size="small"
              fullWidth
              className="form-control2 border-none"
              name="makingCharges"
              onWheel={(e) => e.target.blur()}
              type="text"
              {...register('makingCharges', {
                required: <ErrorMsg msg="Please enter Making Charges" />,
                minLength: {
                  value: minMakingChargesLength,
                  message: (
                    <ErrorMsg
                      msg={`Minimum ${minMakingChargesLength} Characters Allowed`}
                    />
                  ),
                },
                maxLength: {
                  value: makingChargesLength,
                  message: (
                    <ErrorMsg
                      msg={`Maximum ${makingChargesLength} Characters Allowed`}
                    />
                  ),
                },
                pattern: {
                  value: MAKING_CHARGES,
                  message: <ErrorMsg msg="Please enter valid Making Charges" />,
                },
              })}
              helperText={<ErrorMessage errors={errors} name="makingCharges" />}
            ></TextField> */}
            <FormControl
              className="membership-formcontrol1"
              sx={{
                '& .MuiFormHelperText-root': {
                  ml: 0,
                  mt: '5px',
                },
              }}
            >
              <InputLabel id="demo-simple-select-label">
                Notification Count
              </InputLabel>
              <Controller
                name="notificationcount"
                control={control}
                defaultValue=""
                render={({ field: { onChange, value } }) => (
                  <Select
                    label="Notification Count"
                    sx={{
                      '& .MuiFormHelperText-root': {
                        ml: 0,
                        mt: '5px',
                      },
                    }}
                    defaultValue="1"
                    labelId="demo-simple-select-label"
                    className="membershop-select1"
                    id="notificationcount"
                    value={value}
                    onChange={onChange}
                    inputProps={{ 'aria-label': 'Without label' }}
                    {...register('notificationcount', {
                      required: (
                        <ErrorMsg msg="Please select Notification Count" />
                      ),
                    })}
                  >
                    {Array.from({ length: 10 }, (_, i) => i + 1)?.map(
                      (item) => (
                        <MenuItem value={item}>{item}</MenuItem>
                      )
                    )}
                  </Select>
                )}
              />
              <ErrorMessage errors={errors} name="notificationcount" />
            </FormControl>
            <FormControl
              className="membership-formcontrol1"
              sx={{
                mt: 5,
                '& .MuiFormHelperText-root': {
                  ml: 0,
                  mt: '5px',
                },
              }}
            >
              <InputLabel id="demo-simple-select-label">
                Notification Period
              </InputLabel>
              <Controller
                name="notificationperiod"
                control={control}
                defaultValue=""
                render={({ field: { onChange, value } }) => (
                  <Select
                    label="Notification Period"
                    sx={{
                      '& .MuiFormHelperText-root': {
                        ml: 0,
                        mt: '5px',
                      },
                    }}
                    defaultValue="1"
                    labelId="demo-simple-select-label"
                    className="membershop-select1"
                    id="notificationperiod"
                    value={value}
                    onChange={onChange}
                    inputProps={{ 'aria-label': 'Without label' }}
                    {...register('notificationperiod', {
                      required: (
                        <ErrorMsg msg="Please select Notification Period" />
                      ),
                    })}
                  >
                    {Array.from({ length: 30 }, (_, i) => i + 1)?.map(
                      (item) => (
                        <MenuItem value={item}>{item}</MenuItem>
                      )
                    )}
                  </Select>
                )}
              />
              <ErrorMessage errors={errors} name="notificationperiod" />
            </FormControl>
          </Box>
          <Typography align="center" variant="h4">
            <Button
              sx={{
                my: 5,
                width: '125px',
                textTransform: 'none',
              }}
              variant="contained"
              type="submit"
            >
              {props.id == '' ? 'Submit' : 'Update'}
              {/* Submit */}
            </Button>
          </Typography>
        </Container>
      </form>
    </>
  );
};

export default CustomProductRequestsNotification;
//#endregion
