import React from 'react'
import Box from "@mui/material/Box";
import { Button, Typography } from '@mui/material';
import svg from "../../../../assets/svg";
import png from "../../../../assets/png";
import Header from '../../../../component/user/defaultLayout/Header';
import Footer from '../../../../component/user/defaultLayout/Footer';
import ButtonGroup from '@mui/material/ButtonGroup';
import Certificates from '../../../../component/user/defaultLayout/Certificates';
import ProductDescription from '../../../../component/user/defaultLayout/ProductDescription';

export default function Rings2() {
      return (
            <div>
                  <Header />
                  <Box className='rings1-flower-sec'>
                        <Box className="container">
                              <Box sx={{ width: 1 }}>
                                    <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={{ xs: 0, sm: 5, md: 8, lg: 8 }}>
                                          <Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 6", lg: "span 6" }}>
                                                <Box className='rings-left-banner-box'>
                                                      <img src={png.ringsleftbanner} className="rings-left-banner-img"></img>
                                                </Box>
                                                <Box className='rings1-images-main'>
                                                      <Box className='rings1-img-box'>
                                                            <img src={png.ringsleftbanner} className="rings1-img"></img>
                                                      </Box>
                                                      <Box className='rings1-img-box'>
                                                            <img src={png.ringsleftbanner} className="rings1-img"></img>
                                                      </Box>
                                                      <Box className='rings1-img-box'>
                                                            <img src={png.ringsleftbanner} className="rings1-img"></img>
                                                      </Box>
                                                </Box>
                                          </Box>
                                          <Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 6", lg: "span 6" }}>
                                                <Box className='flower-text-box'>
                                                      <Typography variant='h4' component='h4' className='flower-title'>Flower Goldcrop Ring</Typography>

                                                      <Typography variant='h6' component='h6' className='rosegold-text'>Rose gold</Typography>

                                                      <Typography variant='h6' component='h6'
                                                            className='weight-text'>Weight : 8g</Typography>

                                                      <Typography variant='h6' component='h6' className='rings1-price-text'>$12,350.00</Typography>

                                                      <Typography variant='h6' component='h6' className='description-text'>Description</Typography>

                                                      <Typography variant='p' component='p' className='description-para mb-26'>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren</Typography>

                                                      <Typography variant='p' component='p' className='description-para '>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et </Typography>
                                                </Box>
                                                <Box className='add-quenty-box rings1-btn-box'>
                                                      <Box className='add-quenty-btn-box'>
                                                            <Box className='quenty-text'>Qty :</Box>
                                                            <Box className='quenty-btn-box'>
                                                                  <ButtonGroup className='qty-btn-group'>
                                                                        <Button className='btn btn-qty'>-</Button>
                                                                        <Button className='btn btn-qty'>1</Button>
                                                                        <Button className='btn btn-qty'>+</Button>
                                                                  </ButtonGroup>
                                                            </Box>
                                                      </Box>
                                                      <Button className='btn rings1-btn add-cart-btn'>Bid</Button>
                                                </Box>
                                                <Box className='rings1-btn-box'>
                                                      <Button className='btn rings1-btn customis-btn'>Buy Now</Button>
                                                      <Button className='btn rings1-btn buy-now-btn'>Add to Cart</Button>
                                                </Box>
                                          </Box>
                                    </Box>
                              </Box>
                        </Box>
                  </Box>
                  <ProductDescription/>
                  <Certificates />
                  <Footer />
            </div>
      )
}
