import actions from "./action";
import { toast } from "react-toastify";

const initState = {
  CartCount: 0,
  forgotSuccess: false,
  Token: "",
  loading: false,
  islogin: false,
  open: false,
  Userdata: [],
  Cartdata: [],
  Productdata: [],
  AllProductdata: [],
  ProductDetaildata: [],
  Categorymenudata: [],
  Viewcartdata: [],
  UpdateCartQuantity: [],
  DeleteCartItemdata: [],
  SignInData: {},
  UserCreateProductData: [],
  SingleUserProductData: [],
  ListItemData: [],
  ListMaterialData: [],
  DeleteUserProductData: [],
  ReceivedOrderdata: [],
  ViewReceivedOrderdata: [],
  GetOrderListdata: [],
  ViewOrderData: [],
  FaqData: [],
  RecievedCustomProductData: [],
  AcceptCustomProductData: [],
  ReturnOrderData: [],
  GetallReturnOrderData: [],
  GetSingleReturnOrderData: [],
  UserCreatePriciousData: [],
  ListBlogData: [],
  ListGuidelineData: [],
  SingleBankDetailsData: [],
  ListProductDescriptionData: [],
  ListProductPurityData: [],
  ListProductPricingData: [],
  SingleguidelineData: [],
  ListCustomerServicesData: [],
  GetAllProductHomeData: [],
  TrendingProductsData: [],
  ListTopDealsData: [],
  ListBenefitsData: [],
  SendOtpEmail: "",
  VerifyOtpEmail: "",
  ListBenefitsData: [],
  AllPreciousStoneProductsData: [],
  UpdateDeliveryStausData: [],
  Profile: [],
  AccountDetails: [],
  GetSingleCustomProductData: [],
  FilterMaterialAndItemData: [],
  CatelogueSeller: [],
  CatelogueProduct: [],
  ProductDesigns: [],
  AddToCartBulkProduct: [],
  CatelogueItemsData: [],
  ForgotPasswordData: [],
  ResetPasswordData: [],
  AllReceivedReturnOrderData: [],
  ViewReceivedReturnOrderData: [],
  verifyOtpSuccess: false
};

export default function Usersaga(state = initState, action) {

  switch (action.type) {
    case actions.LOGOUT:
      return initState;
    case actions.ERROR:
      toast.error(action.message);
      return {
        ...state,
        open: false,
        forgotSuccess: false,
        verifyOtpSuccess: false
      };
    case actions.STATE_EMPTY_SUCCESS:
      toast.error(action.message);
      return {
        ...state,
        forgotSuccess: false,
        verifyOtpSuccess: false
      };


    case actions.UPDATE_MODAL_SUCCESS:
      return {
        ...state,
        open: false,
      };
    case actions.LODER_ON:
      return {
        ...state,
        loading: true,
      };
    case actions.LODER_OFF:
      return {
        ...state,
        loading: false,
      };
    case actions.Register_SUCCESS:
      toast.success("Successfully register");
      return {
        ...state,
        loading: false,
      };
    case actions.Login_SUCCESS:
      toast.success("Sign in successfully");
      return {
        ...state,
        Userdata: action.data,
        Token: action.data[0].token,
        islogin: true,
        loading: false,
      };
    case actions.ForgotPassword_REQUEST:
      // toast.success("Sign in successfully");


      return {
        ...state,
        forgotSuccess: false
      };
    case actions.Member_SUCCESS:
      toast.success("Registered Successfully");
      return {
        ...state,
        loading: false,
      };
    case actions.Addcart_SUCCESS:
      toast.success("Add To Cart Successfully");
      return {
        ...state,
        Cartdata: action.data,
        CartCount: action.data.data.products.length,
        loading: false,
      };
    case actions.Userproduct_SUCCESS:
      return {
        ...state,
        Productdata: action.data,
        loading: false,
      };
    case actions.AllProduct_SUCCESS:
      return {
        ...state,
        AllProductdata: action.data,
        loading: false,
      };
    case actions.Product_SUCCESS:
      return {
        ...state,
        ProductDetaildata: action.data,
        loading: false,
      };
    case actions.CategoryMenu_SUCCESS:
      return {
        ...state,
        Categorymenudata: action.data,
        loading: false,
      };
    case actions.ViewCart_SUCCESS:
      return {
        ...state,
        Viewcartdata: action.data,
        Cartdata: action.data.products.length,
        CartCount: action.data.products.length
          ? action.data.products?.length
          : 0,
        loading: false,
      };
    case actions.UpdateCartQuantity_SUCCESS:
      return {
        ...state,
        Viewcartdata: action.data[0],
        loading: false,
      };
    case actions.DeleteCartItem_SUCCESS:
      toast.success("Delete Product Successfully");
      return {
        ...state,
        Viewcartdata: action.data[0],
        Cartdata: action.data[0].products.length,
        CartCount: action.data[0]?.products.length,
        loading: false,
      };
    case actions.SignIn_SUCCESS:
      return {
        ...state,
        SignInData: action.data,
        loading: false,
      };
    case actions.UserCreateProduct_SUCCESS:
      return {
        ...state,
        UserCreateProductData: action.data,
        loading: false,
      };
    case actions.SingleUserProduct_SUCCESS:
      return {
        ...state,
        SingleUserProductData: action.data,
        loading: false,
      };
    case actions.ListItem_SUCCESS:
      return {
        ...state,
        ListItemData: action.data,
        loading: false,
      };
    case actions.ListMaterial_SUCCESS:
      return {
        ...state,
        ListMaterialData: action.data,
        loading: false,
      };
    case actions.DeleteUserProduct_SUCCESS:
      toast.success("Product Delete Successfully");
      return {
        ...state,
        Productdata: action.data,
        loading: false,
      };
    case actions.OrderPlace_SUCCESS:
      toast.success("Order Placed Successfully");
      return {
        ...state,
        OrderPlacedata: action.data,
        loading: false,
      };
    case actions.ReceivedOrder_SUCCESS:
      return {
        ...state,
        ReceivedOrderdata: action.data,
        loading: false,
      };
    case actions.ViewReceivedOrder_SUCCESS:
      return {
        ...state,
        ViewReceivedOrderdata: action.data,
        loading: false,
      };
    case actions.GetOrderList_SUCCESS:
      return {
        ...state,
        GetOrderListdata: action.data,
        loading: false,
      };
    case actions.ViewOrder_SUCCESS:
      return {
        ...state,
        ViewOrderData: action.data,
        loading: false,
      };
    case actions.Faq_SUCCESS:
      return {
        ...state,
        FaqData: action.data,
        loading: false,
      };
    case actions.RaiseCustomProduct_SUCCESS:
      return {
        ...state,
        RaiseCustomProductData: action.data,
        loading: false,
      };
    case actions.ViewCustomProduct_SUCCESS:
      return {
        ...state,
        ViewCustomProductData: action.data,
        loading: false,
      };
    case actions.GetSingleCustomProduct_SUCCESS:
      return {
        ...state,
        GetSingleCustomProductData: action.data,
        loading: false,
      };
    case actions.GetAllCms_SUCCESS:
      return {
        ...state,
        GetAllCmsData: action.data,
        loading: false,
      };
    case actions.AddUpdateRating_SUCCESS:
      return {
        ...state,
        AddUpdateRatingData: action.data,
        loading: false,
      };
    case actions.SingleRating_SUCCESS:
      return {
        ...state,
        SingleRatingData: action.data,
        loading: false,
      };
    case actions.GetAllRating_SUCCESS:
      return {
        ...state,
        GetAllRatingData: action.data,
        loading: false,
      };
    case actions.RecievedCustomProduct_SUCCESS:
      return {
        ...state,
        RecievedCustomProductData: action.data,
        loading: false,
      };
    case actions.AcceptCustomProduct_SUCCESS:
      toast.success("Accept Product Successfully");
      return {
        ...state,
        AcceptCustomProductData: action.data,
        loading: false,
      };
    case actions.ReturnOrder_SUCCESS:
      toast.success("Order Returned Successfully");
      return {
        ...state,
        ReturnOrderData: action.data,
        loading: false,
      };
    case actions.GetallReturnOrder_SUCCESS:
      return {
        ...state,
        GetallReturnOrderData: action.data,
        loading: false,
      };
    case actions.GetSingleReturnOrder_SUCCESS:
      return {
        ...state,
        GetSingleReturnOrderData: action.data,
        loading: false,
      };
    case actions.UserCreatePricious_SUCCESS:
      return {
        ...state,
        UserCreatePriciousData: action.data,
        loading: false,
      };
    case actions.ListBlog_SUCCESS:
      return {
        ...state,
        ListBlogData: action.data,
        loading: false,
      };
    case actions.ListGuideline_SUCCESS:
      return {
        ...state,
        ListGuidelineData: action.data,
        loading: false,
      };
    case actions.SingleBankDetails_SUCCESS:
      return {
        ...state,
        SingleBankDetailsData: action.data,
        loading: false,
      };
    case actions.ListProductDescription_SUCCESS:
      return {
        ...state,
        ListProductDescriptionData: action.data,
        loading: false,
      };
    case actions.ListProductPricing_SUCCESS:
      return {
        ...state,
        ListProductPricingData: action.data,
        loading: false,
      };
    case actions.ListProductPurity_SUCCESS:
      return {
        ...state,
        ListProductPurityData: action.data,
        loading: false,
      };
    case actions.Singleguideline_SUCCESS:
      return {
        ...state,
        SingleguidelineData: action.data,
        loading: false,
      };
    case actions.ListCustomerServices_SUCCESS:
      return {
        ...state,
        ListCustomerServicesData: action.data,
        loading: false,
      };
    case actions.GetAllProductHome_SUCCESS:
      return {
        ...state,
        GetAllProductHomeData: action.data,
        loading: false,
      };
    case actions.TrendingProducts_SUCCESS:
      return {
        ...state,
        TrendingProductsData: action.data,
        loading: false,
      };
    case actions.ListTopDeals_SUCCESS:
      return {
        ...state,
        ListTopDealsData: action.data,
        loading: false,
      };
    case actions.ListBenefits_SUCCESS:
      return {
        ...state,
        ListBenefitsData: action.data,
        loading: false,
      };
    case actions.VerifyOtp_SUCCESS:
      toast.success("Otp Verify Successfully");
      return {
        ...state,
        VerifyOtpEmail: action.data,
        loading: false,
        forgotSuccess: false,
        verifyOtpSuccess: true,

        // open: false,
      };
    case actions.SendOtpEmail_SUCCESS:
      toast.success("OTP send to register email successfully");
      return {
        ...state,
        SendOtpEmail: action.data,
        open: true,
        // open: action.open,
      };
    case actions.AllPreciousStoneProducts_SUCCESS:
      return {
        ...state,
        AllPreciousStoneProductsData: action.data,
        loading: false,
      };
    case actions.UpdateDeliveryStaus_SUCCESS:
      toast.success("Order Dispatch Successfully");
      return {
        ...state,
        UpdateDeliveryStausData: action.data,
        loading: false,
      };
    case actions.GET_PROFILE_SUCCESS:
      return {
        ...state,
        Profile: action.data,
        loading: false,
      };
    case actions.GET_ACCOUNT_SUCCESS:
      return {
        ...state,
        AccountDetails: action.data,
        loading: false,
      };
    case actions.UPDATE_ACCOUNT_SUCCESS:
      toast.success("Updated Successfully");
      return {
        ...state,
        AccountDetails: action.data,
        loading: false,
      };
    case actions.CHANGE_PASSWORD_SUCCESS:
      toast.success("Updated Successfully");
      return {
        ...state,
        loading: false,
      };
    case actions.FilterMaterialAndItem_SUCCESS:
      return {
        ...state,
        FilterMaterialAndItemData: action.data,
        loading: false,
      };
    case actions.CatelogueSeller_SUCCESS:
      return {
        ...state,
        CatelogueSeller: action.data,
        loading: false,
      };
    case actions.CatelogueProduct_SUCCESS:
      return {
        ...state,
        CatelogueProduct: action.data,
        loading: false,
      };
    case actions.ProductDesigns_SUCCESS:
      return {
        ...state,
        ProductDesigns: action.data,
        loading: false,
      };
    case actions.AddToCartBulkProduct_SUCCESS:
      return {
        ...state,
        AddToCartBulkProduct: action.data,
        loading: false,
      };
    case actions.CatelogueItems_SUCCESS:
      return {
        ...state,
        CatelogueItemsData: action.data,
        loading: false,
      };
    case actions.ForgotPassword_SUCCESS:
      toast.success("Forgot password mail sent successfully");
      return {

        ...state,
        ForgotPasswordData: action.data,
        loading: false,
        open: true,
        forgotSuccess: true
      };
    case actions.ResendUserOtp_SUCCESS:
      toast.success("Resend OTP successfully");
      return {
        ...state,
        loading: false,
        open: true,
      };
    case actions.ResetPassword_SUCCESS:
      toast.success("Password Reset Successfully");
      return {
        ...state,
        ResetPasswordData: action.data,
        loading: false,
        open: true,
        verifyOtpSuccess: false,
        forgotSuccess: false
      };
    case actions.AllReceivedReturnOrder_SUCCESS:
      // toast.success("Password Reset Successfully");
      return {
        ...state,
        AllReceivedReturnOrderData: action.data,
        loading: false,
      };
    case actions.ViewReceivedReturnOrder_SUCCESS:
      // toast.success("Password Reset Successfully");
      return {
        ...state,
        ViewReceivedReturnOrderData: action.data,
        loading: false,
      };

    default:
      return state;
  }
}
