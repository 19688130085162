import React, { useState, useEffect } from 'react'
import UserIndex from '../../UserIndex'

const { ListGuidelineSaga } = UserIndex.UserSagaActions

export default function Guidelines() {
  const [guidelinesData, setGuidelinesData] = useState('')
  const dispatch = UserIndex.useDispatch()
  const history = UserIndex.useHistory()
  const navigate = UserIndex.useHistory()
  const loding = UserIndex.useSelector((state) => {
    return state.Usersaga.loading
  })
  const ListGuidelineData = UserIndex.useSelector((state) => {
    return state.Usersaga.ListGuidelineData
  })

  const getListGuideline = async () => {
    let urlencoded = new URLSearchParams()
    dispatch(ListGuidelineSaga(urlencoded, navigate))
  }

  useEffect(() => {
    getListGuideline()
  }, [])

  useEffect(() => {
    setGuidelinesData(ListGuidelineData)
  }, [ListGuidelineData])

  return (
    <div>
      <UserIndex.Header />

      <UserIndex.Box className="inner-hero-sec">
        <UserIndex.Box className="container">
          <UserIndex.Box className="hero-title-box">
            <UserIndex.Typography variant="h4" className="hero-inner-title">
              <span>Guidelines</span>
            </UserIndex.Typography>
          </UserIndex.Box>
        </UserIndex.Box>
      </UserIndex.Box>

      <UserIndex.Box className="blogs-guideline-main">
        <UserIndex.Box className="container">
          <UserIndex.Box className="blog-guidelines-title">
            <UserIndex.Typography
              component="h5"
              variant="h5"
              className="guideline-text"
            >
              Guidelines
            </UserIndex.Typography>
          </UserIndex.Box>
          <UserIndex.Box className="box-card-about-details">
            <UserIndex.Box sx={{ width: 1 }}>
              <UserIndex.Box
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                gap={{ xs: 2, sm: 5, md: 4, lg: 4 }}
              >
                {guidelinesData.length
                  ? guidelinesData.map((data) => {
                      return (
                        <UserIndex.Box
                          gridColumn={{
                            xs: 'span 12',
                            sm: 'span 6',
                            md: 'span 3',
                            lg: 'span 3',
                          }}
                          onClick={() =>
                            history.push(
                              `/user/uploadingproductsid/${data._id}`,
                            )
                          }
                        >
                          <UserIndex.Card className="about-card blogs-card">
                            <UserIndex.CardContent className="body-about-card">
                              <UserIndex.Box class="inner-box-aboutus">
                                <img
                                  className="about-membership"
                                  src={UserIndex.svg.yellowupload}
                                ></img>
                                <UserIndex.Typography
                                  variant="h5"
                                  class="title-card-abouts"
                                >
                                  {data.heading}
                                </UserIndex.Typography>
                                <UserIndex.Typography
                                  variant="h6"
                                  class="desc-card-aboutus"
                                >
                                  {data.description}
                                </UserIndex.Typography>
                              </UserIndex.Box>
                            </UserIndex.CardContent>
                          </UserIndex.Card>
                        </UserIndex.Box>
                      )
                    })
                  : 'No Records Found'}
              </UserIndex.Box>
            </UserIndex.Box>
          </UserIndex.Box>
        </UserIndex.Box>
      </UserIndex.Box>

      <UserIndex.Footer />
    </div>
  )
}
