import React, { useEffect, useState } from "react";
import UserIndex from "../../UserIndex";


const { ListCustomerServicesSaga } = UserIndex.UserSagaActions;

export default function Customerservices() {
  const dispatch = UserIndex.useDispatch();
  const history = UserIndex.useHistory();
  const navigate = UserIndex.useHistory();
  const loding = UserIndex.useSelector((state) => {
    return state.Usersaga.loading;
  });

  const token = UserIndex.useSelector((state) => {
    return state.Usersaga.Token;
  });

  const ListCustomerServicesData = UserIndex.useSelector((state) => {
    return state.Usersaga.ListCustomerServicesData;
  });

  const [listCustomerServices, setListCustomerServices] = useState("");

  const getListCustomerServices = async () => {
    let urlencoded = new URLSearchParams();
    dispatch(ListCustomerServicesSaga(urlencoded, navigate));
  };

  useEffect(() => {
    setListCustomerServices(ListCustomerServicesData);
  }, [ListCustomerServicesData]);

  useEffect(() => {
    getListCustomerServices();
  }, []);


  const bull = (
    <UserIndex.Box
      component="span"
      sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
    >
      •
    </UserIndex.Box>
  );
  return (
    <div>
      <UserIndex.Header />
      <UserIndex.Box
        className="faq-main-banner"
        sx={{
          background:
            `linear-gradient(45deg,rgb(34 34 34 / 76%), rgb(34 34 34 / 76%)),url(${UserIndex.png?.faqBanner})`
        }}
      >
        <UserIndex.Box className="container">
          <UserIndex.Box className="faq-inner-content">
            <UserIndex.Typography className="lot-text">
                            Help & Support
                        </UserIndex.Typography>
            <UserIndex.Box className="faq-title-main">
              <UserIndex.Typography className="faq-typo-custom" variant="h1">
                {" "}
                Customer Service
              </UserIndex.Typography>
              <UserIndex.Box className="img-side-yellow">
                <img
                  src={UserIndex.png.banneryellow}
                  className="yellow-left-banner"
                ></img>
              </UserIndex.Box>
            </UserIndex.Box>
          </UserIndex.Box>
        </UserIndex.Box>
      </UserIndex.Box>
      <UserIndex.Box className="bg-29958 customer-help-main">
        <UserIndex.Box className="title-inner-help">
          <UserIndex.Typography variant="h4" class="how-help-text">
            How can we help?
          </UserIndex.Typography>
        </UserIndex.Box>
        <UserIndex.Box className="search-customer-main">
          <UserIndex.Box className="header-search-input">
            <UserIndex.Box className="form-group">
              <UserIndex.TextField
                fullWidth
                id="fullWidth"
                className="header-search-form-control"
                placeholder="Type your question"
              />
              <span className="search-grey-box">
                <img src={UserIndex.png.searchgrey} className="search-grey-img"></img>
              </span>
            </UserIndex.Box>
          </UserIndex.Box>
        </UserIndex.Box>
      </UserIndex.Box>

      <UserIndex.Box className="customer-content-details">
        <UserIndex.Box className="container">
          <UserIndex.Box className="upper-content-services">
            <UserIndex.Typography variant="h5" className="title-typo-services">
              {listCustomerServices?.[0]?.heading}
            </UserIndex.Typography>
            <UserIndex.Typography variant="p" className="title-typo-services-inner">
              {listCustomerServices?.[0]?.description}
            </UserIndex.Typography>
          </UserIndex.Box>

          <UserIndex.Box class="customer-services-cards">
            <UserIndex.Box sx={{ width: 1 }}>
              <UserIndex.Box
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                gap={{ xs: 3, sm: 5, md: 4, lg: 4 }}
              >
                {listCustomerServices?.[0]?.questions.length
                  ? listCustomerServices?.[0]?.questions.map((data, i) => {
                    return (
                      <UserIndex.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 4",
                          lg: "span 4",
                        }}
                      >
                        <UserIndex.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 4",
                            lg: "span 4",
                          }}
                        >
                          <UserIndex.Card className="services-card">
                            <UserIndex.CardContent className="body-services-card">
                              <UserIndex.Box class="inner-box-services">
                                <UserIndex.Typography
                                  variant="h5"
                                  class="title-card-services"
                                >
                                  {data.question}
                                </UserIndex.Typography>
                                <UserIndex.Typography
                                  variant="h6"
                                  class="desc-card-services"
                                >
                                  {data.answer}
                                </UserIndex.Typography>
                              </UserIndex.Box>
                            </UserIndex.CardContent>
                          </UserIndex.Card>
                        </UserIndex.Box>
                      </UserIndex.Box>
                    );
                  })
                  : ""}
              </UserIndex.Box>
            </UserIndex.Box>
          </UserIndex.Box>
        </UserIndex.Box>
      </UserIndex.Box>
      <UserIndex.Footer />
    </div>
  );
}