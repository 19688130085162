import React, { useEffect, useState } from 'react'
import UserIndex from '../../UserIndex'

const { GetAccountDetails, UpdateAccountDetails } = UserIndex.UserSagaActions

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflowY: 'scroll',
  height: 500,
}

const Img = UserIndex.styled('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '150px',
  maxHeight: '100%',
})

export default function AccountDetails() {
  const [AccountDetails, setAccountDetails] = useState({})
  const [showPassword, setShowPassword] = useState(false)
  const handleClickShowPassword = () => setShowPassword(!showPassword)
  const handleMouseDownPassword = () => setShowPassword(!showPassword)
  const [personalDetails, setPersonalDetails] = useState({
    ownerName: '',
    email: '',
    contactNumber: '',
    permanentAddress: '',
    password: '',
  })
  const [companyDetails, setCompanyDetails] = useState({
    companyName: '',
    companyAddress: '',
    businessType: '',
    GST: '',
    password: '',
  })
  const [bankDetails, setBankDetails] = useState({
    accountName: '',
    accountNumber: '',
    bankName: '',
    branchAddress: '',
    IFSC: '',
    password: '',
  })
  const [images, setImages] = useState([])
  const [oldImages, setOldImages] = useState([])
  const [{ src, backend, oldImage }, setImg] = useState({
    src: null,
    backend: {},
    oldImage: '',
  })
  const [openPersonalDetails, setOpenPersonalDetails] = useState(false)
  const [openCompanyDetails, setOpenCompanyDetails] = useState(false)
  const [openBankDetails, setOpenBankDetails] = useState(false)
  const dispatch = UserIndex.useDispatch()
  const history = UserIndex.useHistory()
  const navigate = UserIndex.useHistory()

  const Token = UserIndex.useSelector((state) => {
    return state.Usersaga.Token
  })
  const AccountDetailsData = UserIndex.useSelector((state) => {
    return state.Usersaga.AccountDetails
  })

  const removeImg = () => {
    setImg({
      src: '',
      oldImage: '',
      backend: {},
    })
  }
  const removeOldImg = () => {
    setImg({
      backend: '',
      src: '',
      backend: {},
    })
  }
  const handleImg = (e) => {
    if (e.target.files[0]) {
      setImg({
        src: URL.createObjectURL(e.target.files[0]),
        backend: e.target.files[0],
        oldImage: '',
      })
    }
  }

  const handleChangePersonalDetails = (e) => {
    const { name, value } = e.target
    setPersonalDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleChangeCompanyDetails = (e) => {
    const { name, value } = e.target
    setCompanyDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleChangeBankDetails = (e) => {
    const { name, value } = e.target
    setBankDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleChangeImage = (e) => {
    const { files } = e.target;

    if (files.length < 6 && images.length < 5) {
      const arr = []
      for (const key of Object.keys(files)) {
        if(files[key].type === "image/png" || files[key].type === "image/jpeg"){
          arr.push(files[key])
        }
      }
      setImages((e)=>[...e,...arr])
    }
  }

  const removePreviewImage = (data) => {
    var array = [...images]
    let filterArray = array.filter((item) => item !== data)
    setImages(filterArray)
  }

  const removeOldImages = (data) => {
    var array = [...oldImages]
    let filterArray = array.filter((item) => item !== data)
    setOldImages(filterArray)
  }

  const getFaq = async () => {
    let urlencoded = new URLSearchParams()
    dispatch(GetAccountDetails(urlencoded, navigate, Token))
  }

  const updateDetails = async (data) => {
    if (data === 'personalDetails') {
      let urlencoded = new FormData()
      urlencoded.append('details', data)
      urlencoded.append('password', personalDetails.password)
      urlencoded.append('owner_name', personalDetails.ownerName)
      urlencoded.append('email', personalDetails.email)
      urlencoded.append('contact_number', personalDetails.contactNumber)
      urlencoded.append('permanent_address', personalDetails.permanentAddress)

      dispatch(UpdateAccountDetails(urlencoded, navigate, Token))
    } else if (data === 'companyDetails') {
      let urlencoded = new FormData()
      urlencoded.append('details', data)
      urlencoded.append('password', companyDetails.password)
      urlencoded.append('company_name', companyDetails.companyName)
      urlencoded.append('business_type', companyDetails.businessType)
      urlencoded.append('company_address', companyDetails.companyAddress)
      urlencoded.append('gst_number', companyDetails.GST)
      urlencoded.append('oldurlarray', JSON.stringify(oldImages))
      for (const key of Object.keys(images)) {
        urlencoded.append('images', images[key])
      }
      if (backend?.name !== '') {
        urlencoded.append('otherImage', backend)
      }
      dispatch(UpdateAccountDetails(urlencoded, navigate, Token))
    } else if (data === 'bankDetails') {
      let urlencoded = new FormData()
      urlencoded.append('details', data)
      urlencoded.append('password', bankDetails?.password)
      urlencoded.append('account_name', bankDetails?.accountName)
      urlencoded.append('account_number', bankDetails?.accountNumber)
      urlencoded.append('bank_name', bankDetails?.bankName)
      urlencoded.append('ifsc_code', bankDetails?.IFSC)
      urlencoded.append('branch_address', bankDetails?.branchAddress)
      dispatch(UpdateAccountDetails(urlencoded, navigate, Token))
      handleClose()
    }
  }

  useEffect(() => {
    getFaq()
  }, [])

  useEffect(() => {
    setAccountDetails(AccountDetailsData)
  }, [AccountDetailsData])

  const handleOpen = (details) => {
    if (details === 'personalDetails') {
      setOpenPersonalDetails(true)
      setPersonalDetails({
        ownerName: AccountDetails?.owner_name,
        email: AccountDetails?.email,
        contactNumber: AccountDetails?.contact_number,
        permanentAddress: AccountDetails?.permanent_address,
        password: '',
      })
    } else if (details === 'companyDetails') {
      setOpenCompanyDetails(true)
      setCompanyDetails({
        companyName: AccountDetails?.company_name,
        companyAddress: AccountDetails?.company_address,
        businessType: AccountDetails?.business_type,
        GST: AccountDetails?.gst_number,
        password: '',
        img: AccountDetails?.image,
      })
      setOldImages(AccountDetails?.image)
      setImages([])
      setImg({
        oldImage: AccountDetails?.uploadBrandImage,
        src: '',
        backend: {},
      })
    } else if (details === 'bankDetails') {
      setOpenBankDetails(true)
      setBankDetails({
        accountName: AccountDetails.account_name,
        accountNumber: AccountDetails.account_number,
        bankName: AccountDetails.bank_name,
        branchAddress: AccountDetails.branch_address,
        IFSC: AccountDetails.ifsc_code,
        password: '',
      })
    }
  }
  const handleClose = () => {
    setOpenBankDetails(false)
    setOpenCompanyDetails(false)
    setOpenPersonalDetails(false)
  }
  return (
    <div>
      <UserIndex.Header />
      <UserIndex.Box className="faq-question-main">
        <UserIndex.Box className="container">
          <UserIndex.Box className="title-faq-asked">
            <UserIndex.Typography className="frequently-text">
              Account Details
            </UserIndex.Typography>
          </UserIndex.Box>
          <UserIndex.Box>
            <UserIndex.Box>
              {Object.keys(AccountDetails).length !== 0 ? (
                <>
                  <UserIndex.Card elevation={3}>
                    <UserIndex.CardContent>
                      <UserIndex.Typography variant="h4" component="div">
                        Personal Details
                      </UserIndex.Typography>
                      <br />
                      <UserIndex.Typography variant="h6" component="div">
                        Owner name : {AccountDetails.owner_name}
                        <br />
                        Email : {AccountDetails.email}
                        <br />
                        Contact number : {AccountDetails.contact_number}
                        <br />
                        Permanent address : {AccountDetails.permanent_address}
                      </UserIndex.Typography>
                    </UserIndex.CardContent>
                    <UserIndex.CardActions>
                      <UserIndex.Button
                        size="small"
                        onClick={() => handleOpen('personalDetails')}
                      >
                        Edit
                      </UserIndex.Button>
                    </UserIndex.CardActions>
                  </UserIndex.Card>
                </>
              ) : (
                ''
              )}
            </UserIndex.Box>
          </UserIndex.Box>
          <UserIndex.Box sx={{ my: 2 }}>
            <UserIndex.Box>
              {Object.keys(AccountDetails).length !== 0 ? (
                <>
                  <UserIndex.Card elevation={3}>
                    <UserIndex.CardContent>
                      <UserIndex.Typography variant="h4" component="div">
                        Company Details
                      </UserIndex.Typography>
                      <br />
                      <UserIndex.Typography variant="h6" component="div">
                        Company name : {AccountDetails.company_name}
                        <br />
                        Company address : {AccountDetails.company_address}
                        <br />
                        Business type : {AccountDetails.business_type}
                        <br />
                        GST number : {AccountDetails.gst_number}
                        <br />
                        Brand Image :
                        <br />
                        <img
                          style={{
                            height: '50vh',
                            width: '100%',
                            maxWidth: "fit-content"
                          }}
                          src={AccountDetails.uploadBrandImage}
                        />
                        <br />
                        <br />
                        Certificates :
                        <UserIndex.Grid
                          container
                          spacing={{ xs: 2, md: 3 }}
                          columns={{ xs: 4, sm: 8, md: 12 }}
                        >
                          {AccountDetails?.image?.map((data) => {
                            return (
                              <>
                                <UserIndex.Grid item xs={2} sm={4} md={4}>
                                  <img
                                    style={{
                                      height: '50vh',
                                      width: '100%',
                                      objectFit: 'contain',
                                    }}
                                    src={data}
                                  />
                                </UserIndex.Grid>
                              </>
                            )
                          })}
                        </UserIndex.Grid>

                      </UserIndex.Typography>
                    </UserIndex.CardContent>
                    <UserIndex.CardActions>
                      <UserIndex.Button
                        onClick={() => handleOpen('companyDetails')}
                      >
                        Edit
                      </UserIndex.Button>
                    </UserIndex.CardActions>
                  </UserIndex.Card>
                </>
              ) : (
                ''
              )}
            </UserIndex.Box>
          </UserIndex.Box>
          <UserIndex.Box>
            <UserIndex.Box>
              {Object.keys(AccountDetails).length !== 0 ? (
                <>
                  <UserIndex.Card elevation={3}>
                    <UserIndex.CardContent>
                      <UserIndex.Typography variant="h4" component="div">
                        Bank Account Details
                      </UserIndex.Typography>
                      <br />
                      <UserIndex.Typography variant="h6" component="div">
                        Account name : {AccountDetails.account_name}
                        <br />
                        Account number : {AccountDetails.account_number}
                        <br />
                        Bank name : {AccountDetails.bank_name}
                        <br />
                        Branch address : {AccountDetails.branch_address}
                        <br />
                        IFSC Code: {AccountDetails.ifsc_code}
                      </UserIndex.Typography>
                    </UserIndex.CardContent>
                    <UserIndex.CardActions>
                      <UserIndex.Button
                        onClick={() => handleOpen('bankDetails')}
                      >
                        Edit
                      </UserIndex.Button>
                    </UserIndex.CardActions>
                  </UserIndex.Card>
                </>
              ) : (
                ''
              )}
            </UserIndex.Box>
          </UserIndex.Box>
        </UserIndex.Box>
      </UserIndex.Box>
      <UserIndex.Modal
        open={openPersonalDetails}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onClose={handleClose}
      >
        <UserIndex.Box sx={style}>
          {Object.keys(personalDetails).length ? (
            <>
              <UserIndex.Typography variant="h6">
                Personal Details
              </UserIndex.Typography>
              <br />
              <UserIndex.Box>
                <UserIndex.TextField
                  id="full-width-text-field"
                  fullWidth
                  value={personalDetails.ownerName}
                  onChange={handleChangePersonalDetails}
                  name="ownerName"
                  label="Owner name"
                  variant="filled"
                />
                <UserIndex.TextField
                  id="full-width-text-field"
                  fullWidth
                  value={personalDetails.email}
                  onChange={handleChangePersonalDetails}
                  name="email"
                  label="email"
                  variant="filled"
                />
                <UserIndex.TextField
                  id="full-width-text-field"
                  fullWidth
                  value={personalDetails.contactNumber}
                  onChange={handleChangePersonalDetails}
                  name="contactNumber"
                  label="contact number"
                  variant="filled"
                />
                <UserIndex.TextField
                  id="full-width-text-field"
                  fullWidth
                  value={personalDetails.permanentAddress}
                  onChange={handleChangePersonalDetails}
                  name="permanentAddress"
                  label="permanent address"
                  variant="filled"
                />
                <br />
                <br />
                <UserIndex.TextField
                  id="full-width-text-field"
                  fullWidth
                  type={showPassword ? 'text' : 'password'}
                  InputProps={{
                    // <-- This is where the toggle button is added.
                    endAdornment: (
                      <UserIndex.InputAdornment position="end">
                        <UserIndex.IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <UserIndex.Visibility /> : <UserIndex.VisibilityOff />}
                        </UserIndex.IconButton>
                      </UserIndex.InputAdornment>
                    ),
                  }}
                  value={personalDetails.password}
                  onChange={handleChangePersonalDetails}
                  name="password"
                  label="password"
                  variant="filled"
                />
                <br />
                <br />
                <UserIndex.Button
                  onClick={() => updateDetails('personalDetails')}
                >
                  Save
                </UserIndex.Button>
                <UserIndex.Button onClick={() => handleClose()}>
                  Cancel
                </UserIndex.Button>
              </UserIndex.Box>
            </>
          ) : (
            ''
          )}
        </UserIndex.Box>
      </UserIndex.Modal>
      <UserIndex.Modal
        open={openCompanyDetails}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onClose={handleClose}
      >
        <UserIndex.Box sx={style}>
          {Object.keys(companyDetails).length ? (
            <>
              <UserIndex.Typography variant="h6">
                Company Details
              </UserIndex.Typography>
              <br />
              <UserIndex.Box>
                <UserIndex.TextField
                  id="full-width-text-field"
                  fullWidth
                  value={companyDetails.companyName}
                  onChange={handleChangeCompanyDetails}
                  name="companyName"
                  label="company name"
                  variant="filled"
                />
                <UserIndex.TextField
                  id="full-width-text-field"
                  fullWidth
                  value={companyDetails.companyAddress}
                  onChange={handleChangeCompanyDetails}
                  name="companyAddress"
                  label="company address"
                  variant="filled"
                />
                <UserIndex.TextField
                  id="full-width-text-field"
                  fullWidth
                  value={companyDetails.businessType}
                  onChange={handleChangeCompanyDetails}
                  name="businessType"
                  label="business type"
                  variant="filled"
                />
                <UserIndex.TextField
                  id="full-width-text-field"
                  fullWidth
                  value={companyDetails.GST}
                  onChange={handleChangeCompanyDetails}
                  name="GST"
                  label="GST number"
                  variant="filled"
                />
                <br />
                <br />
                certificate :
                <br />
                <br />
                <UserIndex.Grid container spacing={2}>
                  {oldImages?.map((data) => {
                    return (
                      <>
                        <UserIndex.Grid item xs={6}>
                          <UserIndex.Box sx={{ position: 'relative' }}>
                            <UserIndex.IconButton
                              onClick={() => removeOldImages(data)}
                              sx={{ position: 'absolute', left: '80%',zIndex: '1' }}
                              color="error"
                              aria-label="upload picture"
                              component="label"
                            >
                              <UserIndex.DisabledByDefaultIcon />
                            </UserIndex.IconButton>
                            <img
                              src={data}
                              style={{
                                width: '100%',
                                height: '25vh',
                              }}
                              alt=""
                            />
                          </UserIndex.Box>
                        </UserIndex.Grid>
                      </>
                    )
                  })}
                  {images
                    ? images?.map((data) => {
                        return (
                          <>
                            <UserIndex.Grid item xs={6}>
                              <UserIndex.Box sx={{ position: 'relative' }}>
                                <UserIndex.IconButton
                                  onClick={() => removePreviewImage(data)}
                                  sx={{ position: 'absolute', left: '80%',zIndex: '1' }}
                                  color="error"
                                  aria-label="upload picture"
                                  component="label"
                                >
                                  <UserIndex.DisabledByDefaultIcon />
                                </UserIndex.IconButton>
                                <img
                                  src={URL.createObjectURL(data)}
                                  style={{
                                    width: '100%',
                                    height: '25vh',
                                  }}
                                  alt=""
                                />
                              </UserIndex.Box>
                            </UserIndex.Grid>
                          </>
                        )
                      })
                    : ''}
                </UserIndex.Grid>
                <UserIndex.IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="label"
                >
                  <input
                    type="file"
                    hidden
                    accept="image/png, image/jpeg"
                    multiple
                    onChange={(e) => handleChangeImage(e)}
                    name="newImg"
                  />
                  <UserIndex.AddCircleIcon />
                </UserIndex.IconButton>
                <br />
                <br />
                Brand image :
                <br />
                <br />
                {src === '' || src === undefined ? (
                  ''
                ) : (
                  <>
                    <UserIndex.Grid container spacing={2}>
                      <UserIndex.Grid item xs={6}>
                        <UserIndex.Box sx={{ position: 'relative' }}>
                          <UserIndex.IconButton
                            onClick={() => removeImg()}
                            sx={{ position: 'absolute', left: '80%',zIndex: '1' }}
                            color="error"
                            aria-label="upload picture"
                            component="label"
                          >
                            <UserIndex.DisabledByDefaultIcon />
                          </UserIndex.IconButton>
                          <img
                            src={src}
                            style={{
                              width: '100%',
                              height: '25vh',
                            }}
                            alt=""
                          />
                        </UserIndex.Box>
                      </UserIndex.Grid>
                    </UserIndex.Grid>
                  </>
                )}
                {oldImage === '' || oldImage === undefined ? (
                  ''
                ) : (
                  <>
                    <UserIndex.Grid container spacing={2}>
                      <UserIndex.Grid item xs={6}>
                        <UserIndex.Box sx={{ position: 'relative' }}>
                          <UserIndex.IconButton
                            onClick={() => removeOldImg()}
                            sx={{ position: 'absolute', left: '80%',zIndex: '1' }}
                            color="error"
                            aria-label="upload picture"
                            component="label"
                          >
                            <UserIndex.DisabledByDefaultIcon />
                          </UserIndex.IconButton>
                          <img
                            src={oldImage}
                            style={{
                              width: '100%',
                              height: '25vh',
                            }}
                            alt=""
                          />
                        </UserIndex.Box>
                      </UserIndex.Grid>
                    </UserIndex.Grid>
                  </>
                )}
                <UserIndex.IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="label"
                >
                  <input
                    type="file"
                    hidden
                    accept="image/png, image/jpeg"
                    onChange={(e) =>{
                       handleImg(e)
                      }}
                      onClick={(e)=> { 
                        e.target.value = null
                   }}
                    name="newImg"
                  />
                  <UserIndex.AddCircleIcon />
                </UserIndex.IconButton>
                <br />
                <br />
                <UserIndex.TextField
                  id="full-width-text-field"
                  fullWidth
                  type={showPassword ? 'text' : 'password'}
                  InputProps={{
                    // <-- This is where the toggle button is added.
                    endAdornment: (
                      <UserIndex.InputAdornment position="end">
                        <UserIndex.IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <UserIndex.Visibility /> : <UserIndex.VisibilityOff />}
                        </UserIndex.IconButton>
                      </UserIndex.InputAdornment>
                    ),
                  }}
                  value={companyDetails.password}
                  onChange={handleChangeCompanyDetails}
                  name="password"
                  label="password"
                  variant="filled"
                />
                <br />
                <br />
                <UserIndex.Button
                  onClick={() => updateDetails('companyDetails')}
                >
                  Save
                </UserIndex.Button>
                <UserIndex.Button onClick={() => handleClose()}>
                  Cancel
                </UserIndex.Button>
              </UserIndex.Box>
            </>
          ) : (
            ''
          )}
        </UserIndex.Box>
      </UserIndex.Modal>
      <UserIndex.Modal
        open={openBankDetails}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onClose={handleClose}
      >
        <UserIndex.Box sx={style}>
          {Object.keys(bankDetails).length ? (
            <>
              <UserIndex.Typography variant="h6">
                Bank Account Details
              </UserIndex.Typography>
              <br />
              <UserIndex.Box>
                <UserIndex.TextField
                  id="full-width-text-field"
                  fullWidth
                  value={bankDetails.accountName}
                  onChange={handleChangeBankDetails}
                  name="accountName"
                  label="account name"
                  variant="filled"
                />
                <UserIndex.TextField
                  id="full-width-text-field"
                  fullWidth
                  value={bankDetails.accountNumber}
                  onChange={handleChangeBankDetails}
                  name="accountNumber"
                  label="account number"
                  variant="filled"
                />
                <UserIndex.TextField
                  id="full-width-text-field"
                  fullWidth
                  value={bankDetails.bankName}
                  onChange={handleChangeBankDetails}
                  name="bankName"
                  label="bank name"
                  variant="filled"
                />
                <UserIndex.TextField
                  id="full-width-text-field"
                  fullWidth
                  value={bankDetails.branchAddress}
                  onChange={handleChangeBankDetails}
                  name="branchAddress"
                  label="branch address"
                  variant="filled"
                />
                <UserIndex.TextField
                  id="full-width-text-field"
                  fullWidth
                  value={bankDetails.IFSC}
                  onChange={handleChangeBankDetails}
                  name="IFSC"
                  label="IFSC"
                  variant="filled"
                />
                <br />
                <br />
                <UserIndex.TextField
                  id="full-width-text-field"
                  fullWidth
                  type={showPassword ? 'text' : 'password'}
                  InputProps={{
                    // <-- This is where the toggle button is added.
                    endAdornment: (
                      <UserIndex.InputAdornment position="end">
                        <UserIndex.IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <UserIndex.Visibility /> : <UserIndex.VisibilityOff />}
                        </UserIndex.IconButton>
                      </UserIndex.InputAdornment>
                    ),
                  }}
                  value={bankDetails.password}
                  onChange={handleChangeBankDetails}
                  name="password"
                  label="password"
                  variant="filled"
                />
                <br />
                <br />
                <UserIndex.Button onClick={() => updateDetails('bankDetails')}>
                  Save
                </UserIndex.Button>
                <UserIndex.Button onClick={() => handleClose()}>
                  Cancel
                </UserIndex.Button>
              </UserIndex.Box>
            </>
          ) : (
            ''
          )}
        </UserIndex.Box>
      </UserIndex.Modal>
      <UserIndex.Footer />
    </div>
  )
}
