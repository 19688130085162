//#region ALL IMPORTS
import React, { useState, useEffect } from 'react';
import {
  Grid,
  Container,
  Typography,
  TextField,
  FormControl,
  Box,
  FormHelperText,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { Api } from '../../../../../config/api';
import dataService from '../../../../../config/dataService';
import { toast } from 'react-toastify';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ContainedButton from '../../../../../component/customComponents/buttons/ContainedButton';
import { noWhiteSpace } from '../../../../../component/customComponents/validation/Validation';
import JoditEditor from 'jodit-react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import {
  ErrorMsg,
  TextAreaErrorMsg,
} from '../../../../../component/customComponents/errorMsg/ErrorMsg';
import {
  minMembershipTAndCHeadingLength,
  membershipTAndCHeadingLength,
  NO_WHITE_SPACE_BEGINNING_END,
} from '../../../../../component/customComponents/validation/Validation';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
//#endregion

import AdminIndex from '../../../AdminIndex';
//#region ALL HOOKS AND VARIABALS

const { getBenefitList, updateBlogTermCondition } = AdminIndex.AdminSagaActions;
const MembershipTermsAndCondition = () => {
  let token = localStorage.getItem('auth');
  const dispatch = AdminIndex.useDispatch();
  const history = useHistory();
  const navigate = useHistory();
  const [tAndCId, setTAndCId] = useState(null);
  const [getData, setGetData] = useState('');

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm();

  const schema = yup.object().shape({
    files: yup.mixed().test('require', 'Please select a file', (value) => {
      return value && value.length;
    }),
  });
  //#endregion

  //#region ALL EVENT HANDLERS AND  FUCTIONS
  const getMembershipTAndCData = async (e) => {
    let urlencoded = new URLSearchParams();
    try {
      dispatch(getBenefitList(token, urlencoded));
      // const Data = await dataService.post(Api.Admin.listBenefits, urlencoded);

      // setGetData(Data?.data?.listTermsCondition?.[0]);
      // setTAndCId(Data?.data?.listTermsCondition?.[0]?._id);
    } catch (err) {
      toast.error(err?.message || 'Something went wrong');
    }
  };

  const termConditionList = AdminIndex.useSelector((state) => {
    return state.AdminSaga.termConditionList;
  });

  useEffect(() => {
    setGetData(termConditionList?.[0]);
    setTAndCId(termConditionList?.[0]?._id);
  }, [termConditionList]);

  useEffect(() => {
    getMembershipTAndCData();
    register('tAndCDescription');
  }, []);

  useEffect(() => {
    setValue('tAndCHeading', getData?.head_line);
    setValue('tAndCDescription', getData?.description);
  }, [getData]);

  const handleSave = async (data) => {
    try {
      if (tAndCId) {
        let urlencoded = new URLSearchParams();
        urlencoded.append('id', tAndCId);
        urlencoded.append('head_line', data?.tAndCHeading?.trim());
        urlencoded.append('description', data?.tAndCDescription?.trim());

        dispatch(updateBlogTermCondition(token, urlencoded, navigate));
        // const tAndCHeadingData = await dataService.post(
        //   Api.Admin.addBenefits,
        //   urlencoded,
        //   {
        //     headers: {
        //       auth: token,
        //     },
        //   }
        // );

        // if (tAndCHeadingData.data.status === 1) {
        //   toast.success('Terms and Conditions Updated successfully');
        //   history.push('/admin/membership');
        // }
      } else {
        let urlencoded = new URLSearchParams();
        urlencoded.append('head_line', data?.tAndCHeading?.trim());
        urlencoded.append('description', data?.tAndCDescription?.trim());

        dispatch(updateBlogTermCondition(token, urlencoded, navigate));
        // const tAndCHeadingData = await dataService.post(
        //   Api.Admin.addBenefits,
        //   urlencoded,
        //   {
        //     headers: {
        //       auth: token,
        //     },
        //   }
        // );

        // if (tAndCHeadingData.data.status === 1) {
        //   toast.success('Terms and Conditions added successfully');
        //   // setTimeout(() => {
        //   history.push('/admin/membership');
        //   // }, '1000');
        // }
      }
    } catch (err) {
      toast.error(err?.message || 'Something went wrong');
    }
  };

  //#endregion

  //#region RETURN JSX
  return (
    <>
      <form onSubmit={handleSubmit(handleSave)}>
        <Container maxWidth="false">
          <Typography sx={{ my: 2 }} align="center" variant="h4">
            Terms & Condition
          </Typography>

          <Grid container spacing={5}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography align="left" sx={{ mt: 2 }}>
                Heading
              </Typography>
              <TextField
                autoComplete="off"
                sx={{
                  mt: 2,
                  '& .MuiFormHelperText-root': { ml: 0, mt: '5px' },
                }}
                size="small"
                fullWidth
                className="form-control border-none"
                name="tAndCHeading"
                type="text"
                {...register('tAndCHeading', {
                  required: <ErrorMsg msg="Please enter Heading" />,
                  minLength: {
                    value: minMembershipTAndCHeadingLength,
                    message: (
                      <ErrorMsg
                        msg={`Minimum ${minMembershipTAndCHeadingLength} Characters Allowed`}
                      />
                    ),
                  },
                  maxLength: {
                    value: membershipTAndCHeadingLength,
                    message: (
                      <ErrorMsg
                        msg={`Maximum ${membershipTAndCHeadingLength} Characters Allowed`}
                      />
                    ),
                  },
                  pattern: {
                    value: NO_WHITE_SPACE_BEGINNING_END,
                    message: (
                      <ErrorMsg msg="Whitespace is not allowed at beginning or end" />
                    ),
                  },
                })}
                helperText={
                  <ErrorMessage errors={errors} name="tAndCHeading" />
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography align="left" sx={{ my: 2 }}>
                Description
              </Typography>
              <Box className="text-area-control-main">
                {/* <CKEditor
                  editor={ClassicEditor}
                  data={tAndC ? tAndC : ''}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setTAndC(data);
                  }}
                /> */}
                <Controller
                  name="tAndCDescription"
                  control={control}
                  render={({ field }) => <JoditEditor {...field} />}
                  {...register('tAndCDescription', {
                    required: <ErrorMsg msg="Please enter Description" />,
                  })}
                />
                <ErrorMessage errors={errors} name="tAndCDescription" />
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ display: 'flex', justifyContent: 'center', my: 5 }}>
            <ContainedButton name={tAndCId ? 'Update' : 'Save'} type="submit" />
          </Box>
        </Container>
      </form>
    </>
  );
};

export default MembershipTermsAndCondition;
//#endregion
