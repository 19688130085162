import React from 'react';
import { Button } from '@mui/material';

const ContainedButton = (props) => {
  return (
    <Button
      className={props.className}
      variant="contained"
      type={props.type}
      disabled={props.disabled}
      sx={{
        width: '90px',
        borderRadius: 5,
        textTransform: 'none',
        display: props.display,
      }}
      onClick={props.onClick}
    >
      {props.name}
    </Button>
  );
};

export default ContainedButton;
