import React, { useEffect, useState } from 'react'
import { price } from '../../../../component/customComponents/commonFunctions/CommonFunctions'
import UserIndex from '../../UserIndex'

const { SingleUserProductSaga } = UserIndex.UserSagaActions

const DetailsPage = () => {
  const [product, setProduct] = useState('')
  const [dataUser, setDataUser] = useState('')
  const [material, setMaterial] = useState()
  let params = UserIndex.useParams()
  const dispatch = UserIndex.useDispatch()
  const navigate = UserIndex.useHistory()
  const loding = UserIndex.useSelector((state) => {
    return state.Usersaga.loading
  })
  const SingleUserProductData = UserIndex.useSelector((state) => {
    return state.Usersaga.SingleUserProductData
  })
  const Token = UserIndex.useSelector((state) => {
    return state.Usersaga.Token
  })
  const Userdata = UserIndex.useSelector((state) => {
    return state.Usersaga.Userdata
  })

  const ProductData = () => {
    if (params.id) {
      let urlencoded = new URLSearchParams()
      urlencoded.append('id', params.id)
      dispatch(SingleUserProductSaga(urlencoded, navigate, Token))
    }
  }

  useEffect(() => {
    ProductData()
  }, [])

  useEffect(() => {
    setProduct(SingleUserProductData)
  }, [SingleUserProductData])


  useEffect(() => {
    setDataUser(Userdata)
  }, [Userdata])

  useEffect(() => {
    let arr = []
    {
      product?.[0]?.materialIdArray?.map((item) => {
        arr.push(item?.material_id?.material)
      })
    }

    setMaterial(arr)
  }, [product?.[0]])

  const goldFields = () => {
    return (
      <>
        {product?.[0]?.gold
          ? product?.[0]?.gold?.map((gold) => {
              return (
                <UserIndex.Typography
                  variant="h6"
                  component="h6"
                  className="weight-text"
                >
                  Gold Wt(gm) :{gold?.weight}g
                </UserIndex.Typography>
              )
            })
          : ''}
      </>
    )
  }

  const silverFields = () => {
    return (
      <>
        {product?.[0]?.silver?.length
          ? product?.[0]?.silver?.map((silver) => {
              return (
                <UserIndex.Typography
                  variant="h6"
                  component="h6"
                  className="weight-text"
                >
                  Silver Wt(gm) :{silver?.weight} g
                </UserIndex.Typography>
              )
            })
          : ''}
      </>
    )
  }

  const gemstoneFields = () => {
    return (
      <>
        {product?.[0]?.gemstone?.length
          ? product?.[0]?.gemstone?.map((gems) => {
              return (
                <UserIndex.Typography
                  variant="h6"
                  component="h6"
                  className="weight-text"
                >
                  Gemstone Wt(gm) :{gems.total_weight}g
                </UserIndex.Typography>
              )
            })
          : ''}
      </>
    )
  }

  const diamondFields = () => {
    return (
      <>
        {product?.[0]?.diamond?.length
          ? product?.[0]?.diamond?.map((diam) => {
              return (
                <UserIndex.Typography
                  variant="h6"
                  component="h6"
                  className="weight-text"
                >
                  Diamond Wt(gm) :{diam.total_weight}g
                </UserIndex.Typography>
              )
            })
          : ''}
      </>
    )
  }

  const renderFieldMaterialWise = () => {
    switch (true) {
      case product?.[0]?.gold?.length > 0 &&
        product?.[0]?.diamond?.length > 0 &&
        product?.[0]?.materialIdArray?.length === 2:
        return (
          <>
            {goldFields()}
            {diamondFields()}
          </>
        )
      case product?.[0]?.gold?.length > 0 &&
        product?.[0]?.gemstone?.length > 0 &&
        product?.[0]?.materialIdArray?.length === 2:
        return (
          <>
            {goldFields()}
            {gemstoneFields()}
          </>
        )
      case product?.[0]?.silver?.length > 0 &&
        product?.[0]?.diamond?.length > 0 &&
        product?.[0]?.materialIdArray?.length === 2:
        return (
          <>
            {silverFields()}
            {diamondFields()}
          </>
        )
      case product?.[0]?.silver?.length > 0 &&
        product?.[0]?.gemstone?.length > 0 &&
        product?.[0]?.materialIdArray?.length === 2:
        return (
          <>
            {silverFields()}
            {gemstoneFields()}
          </>
        )
      case product?.[0]?.silver?.length > 0 &&
        product?.[0]?.gold?.length > 0 &&
        product?.[0]?.materialIdArray?.length === 2:
        return (
          <>
            {silverFields()}
            {goldFields()}
          </>
        )
      case product?.[0]?.gemstone?.length > 0 &&
        product?.[0]?.diamond?.length > 0 &&
        product?.[0]?.materialIdArray?.length === 2:
        return (
          <>
            {gemstoneFields()}
            {diamondFields()}
          </>
        )
      case product?.[0]?.gold?.length > 0 &&
        product?.[0]?.silver?.length > 0 &&
        product?.[0]?.diamond?.length > 0 &&
        product?.[0]?.materialIdArray?.length === 3:
        return (
          <>
            {silverFields()}
            {goldFields()}
            {diamondFields()}
          </>
        )
      case product?.[0]?.silver?.length > 0 &&
        product?.[0]?.gold?.length > 0 &&
        product?.[0]?.gemstone?.length > 0 &&
        product?.[0]?.materialIdArray?.length === 3:
        return (
          <>
            {silverFields()}
            {goldFields()}
            {gemstoneFields()}
          </>
        )
      case product?.[0]?.gold?.length > 0 &&
        product?.[0]?.diamond?.length > 0 &&
        product?.[0]?.gemstone?.length > 0 &&
        product?.[0]?.materialIdArray?.length === 3:
        return (
          <>
            {goldFields()}
            {diamondFields()}
            {gemstoneFields()}
          </>
        )
      case product?.[0]?.silver?.length > 0 &&
        product?.[0]?.diamond?.length > 0 &&
        product?.[0]?.gemstone?.length > 0 &&
        product?.[0]?.materialIdArray?.length === 3:
        return (
          <>
            {silverFields()}
            {gemstoneFields()}
            {diamondFields()}
          </>
        )

      case product?.[0]?.gold?.length > 0 &&
        product?.[0]?.silver?.length > 0 &&
        product?.[0]?.gemstone?.length > 0 &&
        product?.[0]?.diamond?.length > 0 &&
        product?.[0]?.materialIdArray?.length === 4:
        return (
          <>
            {goldFields()}
            {silverFields()}
            {gemstoneFields()}
            {diamondFields()}
          </>
        )

      case product?.[0]?.gold?.length > 0:
        return goldFields()
      case product?.[0]?.silver?.length > 0:
        return silverFields()
      case product?.[0]?.diamond?.length > 0:
        return diamondFields()
      case product?.[0]?.diamond?.length > 0:
        return gemstoneFields()
      default:
        return ''
    }
  }

  return (
    <div>
      <UserIndex.Header />
      <UserIndex.Box className="rings1-flower-sec">
        <UserIndex.Box className="container">
          <UserIndex.Box sx={{ width: 1 }}>
            <UserIndex.Box
              display="grid"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 0, sm: 5, md: 10, lg: 5 }}
            >
              <UserIndex.Box
                gridColumn={{
                  xs: 'span 12',
                  sm: 'span 6',
                  md: 'span 6',
                  lg: 'span 6',
                }}
              >
                <UserIndex.Box className="rings-left-banner-box">
                  <img
                    src={product[0]?.image[0]}
                    className="rings-left-banner-img"
                  ></img>
                </UserIndex.Box>
                <UserIndex.Box className="rings1-images-main">
                  {product[0]?.image?.map((element) => (
                    <UserIndex.Box
                      sx={{ display: 'flex' }}
                      className="rings1-img-box"
                    >
                      <img src={element} className="rings1-img"></img>
                    </UserIndex.Box>
                  ))}
                </UserIndex.Box>
              </UserIndex.Box>
              <UserIndex.Box
                gridColumn={{
                  xs: 'span 12',
                  sm: 'span 6',
                  md: 'span 6',
                  lg: 'span 6',
                }}
              >
                <UserIndex.Box className="flower-text-box">
                  <UserIndex.Typography
                    variant="h4"
                    component="h4"
                    className="flower-title"
                  >
                    {product[0]?.product_dimension?.meta_title}
                  </UserIndex.Typography>

                  <UserIndex.Typography
                    variant="h6"
                    component="h6"
                    className="rosegold-text"
                  >
                    {material?.toString()}
                  </UserIndex.Typography>

                  {renderFieldMaterialWise()}

                  <UserIndex.Typography
                    variant="h6"
                    component="h6"
                    className="rings1-price-text"
                  >
                    ₹{price(product?.[0]?.total_payable_amount)}
                  </UserIndex.Typography>

                  <UserIndex.Typography
                    variant="h6"
                    component="h6"
                    className="description-text"
                  >
                    Description
                  </UserIndex.Typography>

                  <UserIndex.Typography
                    variant="p"
                    component="p"
                    className="description-para mb-26"
                  >
                    {product[0]?.product_dimension?.meta_description}
                  </UserIndex.Typography>

                  {product[0]?.reasonOfRejection ? (
                    <>
                      <UserIndex.Typography
                        variant="p"
                        component="p"
                        className="description-para reject-reason-details-page"
                      >
                        Admin was reject Your Product
                      </UserIndex.Typography>
                      <UserIndex.Typography
                        variant="p"
                        component="p"
                        className="description-para mb-26 reject-reason-details-page"
                      >
                        Reason of rejection :- {product[0]?.reasonOfRejection}
                      </UserIndex.Typography>
                    </>
                  ) : (
                    ''
                  )}
                </UserIndex.Box>
              </UserIndex.Box>
            </UserIndex.Box>
          </UserIndex.Box>
        </UserIndex.Box>
      </UserIndex.Box>
      <UserIndex.ProductDescription data={product?.[0]} />
      <UserIndex.Certificates data={product?.[0]} />
      <UserIndex.Footer />
    </div>
  )
}

export default DetailsPage
