import React from 'react';
import { Button } from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload';
const ImgUploadButton = ({ className, type, display, onChange, width, name }) => {
  return (
    <Button
      component="label"
      className={className}
      sx={{ borderRadius: 0, textTransform: 'none', display: display, width: width }}
      variant="contained"
      type={type}

    >
      Upload
      <UploadIcon />
      <input name={name} hidden accept="image/*" multiple type="file" onChange={onChange} />
    </Button>
  );
};

export default ImgUploadButton;
