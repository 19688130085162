import { all, takeEvery, put, fork } from "redux-saga/effects"; // You can import select
import actions from "./action";
import { push } from "react-router-redux";
import {
  registerApiHandler,
  memberApiHandler,
  loginApiHandler,
  userProductApiHandler,
  allProductApiHandler,
  singleUserProductApiHandler,
  CategoryMenuApiHandler,
  addToCartApiHandler,
  viewCartApiHandler,
  updateCartQuantityApiHandler,
  deleteCartItemApiHandler,
  userCreateProductApiHandler,
  getSingleUserProductApiHandler,
  getListItemApiHandler,
  getListMaterialApiHandler,
  deleteUserProductApiHandler,
  orderPlaceApiHandler,
  receivedOrderApiHandler,
  viewReceivedOrderApiHandler,
  getOrderListApiHandler,
  viewOrderApiHandler,
  faqApiHandler,
  raiseCustomProductApiHandler,
  viewCustomProductApiHandler,
  getSingleCustomProductApiHandler,
  getAllCmsApiHandler,
  addUpdateRatingApiHandler,
  singleRatingApiHandler,
  getAllRatingApiHandler,
  recievedCustomProductApiHandler,
  acceptCustomProductApiHandler,
  returnOrderApiHandler,
  getallReturnOrderApiHandler,
  getSingleReturnOrderApiHandler,
  userCreatePriciousApiHandler,
  listBlogApiHandler,
  listGuidelineApiHandler,
  singleBankDetailsApiHandler,
  listProductDescriptionApiHandler,
  listProductPricingApiHandler,
  singleguidelineApiHandler,
  listCustomerServicesApiHandler,
  getAllProductHomeServicesApiHandler,
  trendingProductsServicesApiHandler,
  listTopDealsServicesApiHandler,
  listBenefitsServicesApiHandler,
  verifyOtpEmailApiHandler,
  sendOtpEmailApiHandler,
  getAllPreciousStoneProductsApiHandler,
  updateDeliveryStausApiHandler,
  getProfileApiHandler,
  getAccountApiHandler,
  updateAccountApiHandler,
  updatePasswordApiHandler,
  filterMaterialAndItemApiHandler,
  catelogueSellerApiHandler,
  catelogueProductApiHandler,
  productDesignsApiHandler,
  addToCartBulkProductApiHandler,
  catelogueItemsApiHandler,
  forgotPasswordApiHandler,
  resetPasswordApiHandler,
  listProductPurityApiHandler,
  getAllReceivedReturnOrderApiHandler,
  viewReceivedReturnOrderApiHandler,
  resendUserPasswordApiHandler,
} from "../../services/services";

export function* logOutRequest() {
  yield takeEvery("LOGOUT_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    yield put({
      type: actions.LOGOUT,
    });
    // payload.navigate.push(`/user/`);
  });
}
export function* modalStateEmpty() {
  yield takeEvery("STATE_EMPTY", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    yield put({
      type: actions.STATE_EMPTY_SUCCESS,
    });
    // payload.navigate.push(`/user/`);
  });
}

export function* updateModalRequest() {
  yield takeEvery("UPDATE_MODAL_REQUEST", function* (payload) {
    yield put({
      type: actions.UPDATE_MODAL_SUCCESS,
    });
  });
}

export function* registerRequest() {
  yield takeEvery("Register_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield registerApiHandler(payload.payload);

    if (response.status == 1 && response.data[0]) {
      yield put({
        type: actions.Register_SUCCESS,
        data: response.data,
      });
      payload.navigate.push(`/user/membershipform/${response.data[0]._id}`);
      // yield put(push("/user/membershipform"))
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* MembershipRequest() {
  yield takeEvery("Member_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield memberApiHandler(payload.payload);

    if (response.status == 1 && response.data) {
      yield put({
        type: actions.Member_SUCCESS,
        data: response.data,
      });
      payload.navigate.push(`/user/`);
      // yield put(push("/user/membershipform"))
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* loginRequest() {
  yield takeEvery("Login_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield loginApiHandler(payload.payload);

    if (response.status == 1 && response.data) {
      yield put({
        type: actions.Login_SUCCESS,
        data: response.data,
      });
      payload.navigate.push(`/user/my-profile`);
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* AddcartRequest() {
  yield takeEvery("Addcart_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield addToCartApiHandler(payload.payload, payload.token);
    if (response) {
      yield put({
        type: actions.Addcart_SUCCESS,
        data: response,
      });
      // payload.navigate.push(`/user/`)
    } else {
      yield put({ type: actions.ERROR, message: "Error" });
    }
  });
}

export function* UserproductRequest() {
  yield takeEvery("Userproduct_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield userProductApiHandler(
      payload.payload,
      payload.token
    );

    if (response.status == 1) {
      yield put({
        type: actions.Userproduct_SUCCESS,
        data: response.data,
      });
      // payload.navigate.push(`/user/`)
    } else {
      // yield put({ type: actions.ERROR, message: "Error" });
    }
  });
}

export function* AllProductRequest() {
  yield takeEvery("AllProduct_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield allProductApiHandler(payload.payload, payload.token);

    if (response.status == 1) {
      yield put({
        type: actions.AllProduct_SUCCESS,
        data: response.data,
      });
      // payload.navigate.push(`/user/`)
      // yield put(push("/user/membershipform"))
    } else {
      // yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* ProductRequest() {
  yield takeEvery("Product_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield singleUserProductApiHandler(
      payload.payload,
      payload.token
    );

    if (response.status == 1 && response.data) {
      yield put({
        type: actions.Product_SUCCESS,
        data: response.data,
      });
      // payload.navigate.push(`/user/`)
      // yield put(push("/user/membershipform"))
    } else {
      // yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* CategoryMenuRequest() {
  yield takeEvery("CategoryMenu_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield CategoryMenuApiHandler(payload.payload);

    if (response.status == 200 && response.data) {
      yield put({
        type: actions.CategoryMenu_SUCCESS,
        data: response.data,
      });
      // payload.navigate.push(`/user/`)
      // yield put(push("/user/membershipform"))
    } else {
      // yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* ViewCartRequest() {
  yield takeEvery("ViewCart_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield viewCartApiHandler(payload.payload, payload.token);

    if (response.status == 1 && response.data) {
      yield put({
        type: actions.ViewCart_SUCCESS,
        data: response.data,
      });
      // payload.navigate.push(`/user/`)
      // yield put(push("/user/membershipform"))
    } else {
      // yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* UpdateCartQuantityRequest() {
  yield takeEvery("updateCartQuantity_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield updateCartQuantityApiHandler(
      payload.payload,
      payload.token
    );
    if (response.status == 1 && response.data) {
      yield put({
        type: actions.UpdateCartQuantity_SUCCESS,
        data: response.data,
      });
      // payload.navigate.push(`/user/`)
      // yield put(push("/user/membershipform"))
    } else {
      // yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* DeleteCartItemRequest() {
  yield takeEvery("DeleteCartItem_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield deleteCartItemApiHandler(payload.payload);
    if (response.status == 1 && response.data) {
      yield put({
        type: actions.DeleteCartItem_SUCCESS,
        data: response.data,
      });
      // payload.navigate.push(`/user/`)
      // yield put(push("/user/membershipform"))
    } else {
      // yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* SignInRequest() {
  yield takeEvery("SignIn_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield sendOtpEmailApiHandler(payload.payload);

    if (response.status == 1 && response.message !== "User already exist!") {
      yield put({
        type: actions.SendOtpEmail_SUCCESS,
        data: response,
        open: true,
      });
      // payload.navigate.push("/user/mypreciousstone")
    } else {
      yield put({
        type: actions.ERROR,
        message: response.message,
        data: response.message,
        open: false,
      });
    }

    // yield put({
    //   type: actions.SignIn_SUCCESS,
    //   data: payload.payload,
    // });
    // payload.navigate.push(`/user/membershipform`);
  });
}

export function* UserCreateProductRequest() {
  yield takeEvery("UserCreateProduct_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield userCreateProductApiHandler(
      payload.payload,
      payload.token
    );
    if (response.status == 1) {
      yield put({
        type: actions.UserCreateProduct_SUCCESS,
        data: response.data,
      });
      payload.navigate.push("/user/myproduct");
    } else {
      // yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* SingleUserProductRequest() {
  yield takeEvery("SingleUserProduct_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield getSingleUserProductApiHandler(
      payload.payload,
      payload.token
    );

    if (response.status == 1) {
      yield put({
        type: actions.SingleUserProduct_SUCCESS,
        data: response.data,
      });
      // payload.navigate.push("/user/myproduct")
    } else {
      // yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* ListItemRequest() {
  yield takeEvery("ListItem_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield getListItemApiHandler(payload.payload);

    if (response.status == 1) {
      yield put({
        type: actions.ListItem_SUCCESS,
        data: response.data,
      });
      // payload.navigate.push("/user/myproduct")
    } else {
      // yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* ListMaterialRequest() {
  yield takeEvery("ListMaterial_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield getListMaterialApiHandler(payload.payload);

    if (response.status == 1) {
      yield put({
        type: actions.ListMaterial_SUCCESS,
        data: response.data,
      });
      // payload.navigate.push("/user/myproduct")
    } else {
      // yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* DeleteUserProductRequest() {
  yield takeEvery("DeleteUserProduct_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield deleteUserProductApiHandler(
      payload.payload,
      payload.token
    );

    if (response.status == 1) {
      yield put({
        type: actions.DeleteUserProduct_SUCCESS,
        data: response.data,
      });
      // payload.navigate.push("/user/myproduct")
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* OrderPlaceRequest() {
  yield takeEvery("OrderPlace_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield orderPlaceApiHandler(payload.payload, payload.token);
    if (response.status == 1) {
      yield put({
        type: actions.OrderPlace_SUCCESS,
        data: response.data,
      });
      payload.navigate.push("/user/myorder");
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* ReceivedOrderRequest() {
  yield takeEvery("ReceivedOrder_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield receivedOrderApiHandler(
      payload.payload,
      payload.token
    );

    if (response.status == 1) {
      yield put({
        type: actions.ReceivedOrder_SUCCESS,
        data: response.data,
      });
      // payload.navigate.push("/user/myproduct")
    } else {
      // yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* ViewReceivedOrderRequest() {
  yield takeEvery("ViewReceivedOrder_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield viewReceivedOrderApiHandler(
      payload.payload,
      payload.token
    );

    if (response.status == 1) {
      yield put({
        type: actions.ViewReceivedOrder_SUCCESS,
        data: response.data,
      });
      // payload.navigate.push("/user/myproduct")
    } else {
      // yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* GetOrderListRequest() {
  yield takeEvery("GetOrderList_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield getOrderListApiHandler(
      payload.payload,
      payload.token
    );

    if (response.status == 1) {
      yield put({
        type: actions.GetOrderList_SUCCESS,
        data: response.data,
      });
      // payload.navigate.push("/user/myproduct")
    } else {
      // yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* ViewOrderRequest() {
  yield takeEvery("ViewOrder_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield viewOrderApiHandler(payload.payload, payload.token);

    if (response.status == 1) {
      yield put({
        type: actions.ViewOrder_SUCCESS,
        data: response.data,
      });
      // payload.navigate.push("/user/myproduct")
    } else {
      // yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* FaqRequest() {
  yield takeEvery("Faq_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield faqApiHandler(payload.payload);

    if (response.status == 1) {
      yield put({
        type: actions.Faq_SUCCESS,
        data: response.data,
      });
      // payload.navigate.push("/user/myproduct")
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* RaiseCustomProductRequest() {
  yield takeEvery("RaiseCustomProduct_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield raiseCustomProductApiHandler(
      payload.payload,
      payload.token
    );

    if (response.status == 1) {
      yield put({
        type: actions.RaiseCustomProduct_SUCCESS,
        data: response.data,
      });
      // payload.navigate.push("/user/requestproduct1")
    } else {
      // yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* ViewCustomProductRequest() {
  yield takeEvery("ViewCustomProduct_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield viewCustomProductApiHandler(
      payload.payload,
      payload.token
    );

    if (response.status == 1) {
      yield put({
        type: actions.ViewCustomProduct_SUCCESS,
        data: response.data,
      });
      // payload.navigate.push("/user/myproduct")
    } else {
      // yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* GetSingleCustomProductRequest() {
  yield takeEvery("GetSingleCustomProduct_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield getSingleCustomProductApiHandler(
      payload.payload,
      payload.token
    );

    if (response.status == 1) {
      yield put({
        type: actions.GetSingleCustomProduct_SUCCESS,
        data: response.data,
      });
      // payload.navigate.push("/user/myproduct")
    } else {
      // yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* GetAllCmsRequest() {
  yield takeEvery("GetAllCms_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield getAllCmsApiHandler(payload.payload);

    if (response.status == 1) {
      yield put({
        type: actions.GetAllCms_SUCCESS,
        data: response.data,
      });
      // payload.navigate.push("/user/myproduct")
    } else {
      // yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* AddUpdateRatingRequest() {
  yield takeEvery("AddUpdateRating_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield addUpdateRatingApiHandler(
      payload.payload,
      payload.token
    );

    if (response.status == 1) {
      yield put({
        type: actions.AddUpdateRating_SUCCESS,
        data: response.data,
      });
      // payload.navigate.push("/user/myproduct")
    } else {
      // yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* SingleRatingRequest() {
  yield takeEvery("SingleRating_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield singleRatingApiHandler(payload.payload);

    if (response.status == 1) {
      yield put({
        type: actions.SingleRating_SUCCESS,
        data: response.data,
      });
      // payload.navigate.push("/user/myproduct")
    } else {
      // yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* GetAllRatingRequest() {
  yield takeEvery("GetAllRating_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield getAllRatingApiHandler(payload.payload);

    if (response.status == 1) {
      yield put({
        type: actions.GetAllRating_SUCCESS,
        data: response.data,
      });
      // payload.navigate.push("/user/myproduct")
    } else {
      // yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* RecievedCustomProductRequest() {
  yield takeEvery("RecievedCustomProduct_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield recievedCustomProductApiHandler(
      payload.payload,
      payload.token
    );

    if (response.status == 1) {
      yield put({
        type: actions.RecievedCustomProduct_SUCCESS,
        data: response.data,
      });
      // payload.navigate.push("/user/myproduct")
    } else {
      // yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* AcceptCustomProductRequest() {
  yield takeEvery("AcceptCustomProduct_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield acceptCustomProductApiHandler(
      payload.payload,
      payload.token
    );

    if (response.status == 1) {
      yield put({
        type: actions.AcceptCustomProduct_SUCCESS,
        data: response.data,
      });
      // payload.navigate.push("/user/myproduct")
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* ReturnOrderRequest() {
  yield takeEvery("ReturnOrder_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield returnOrderApiHandler(
      payload.payload,
      payload.token
    );
    if (response.status == 1) {
      yield put({
        type: actions.ReturnOrder_SUCCESS,
        data: response.data,
      });
      payload.navigate.push("/user/returnOrder");
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* GetallReturnOrderRequest() {
  yield takeEvery("GetallReturnOrder_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield getallReturnOrderApiHandler(
      payload.payload,
      payload.token
    );

    if (response.status == 1) {
      yield put({
        type: actions.GetallReturnOrder_SUCCESS,
        data: response.data,
      });
      // payload.navigate.push("/user/myproduct")
    } else {
      // yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* GetSingleReturnOrderRequest() {
  yield takeEvery("GetSingleReturnOrder_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield getSingleReturnOrderApiHandler(
      payload.payload,
      payload.token
    );

    if (response.status == 1) {
      yield put({
        type: actions.GetSingleReturnOrder_SUCCESS,
        data: response.data,
      });
      // payload.navigate.push("/user/myproduct")
    } else {
      // yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* UserCreatePriciousRequest() {
  yield takeEvery("UserCreatePricious_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield userCreatePriciousApiHandler(
      payload.payload,
      payload.token
    );

    if (response.status == 1) {
      yield put({
        type: actions.UserCreatePricious_SUCCESS,
        data: response.data,
      });
      payload.navigate.push("/user/mypreciousstone");
    } else {
      // yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* listBlogRequest() {
  yield takeEvery("ListBlog_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield listBlogApiHandler(payload.payload);

    if (response.status == 1) {
      yield put({
        type: actions.ListBlog_SUCCESS,
        data: response.data,
      });
      // payload.navigate.push("/user/mypreciousstone")
    } else {
      // yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* ListGuidelineRequest() {
  yield takeEvery("ListGuideline_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield listGuidelineApiHandler(payload.payload);

    if (response.status == 1) {
      yield put({
        type: actions.ListGuideline_SUCCESS,
        data: response.data,
      });
      // payload.navigate.push("/user/mypreciousstone")
    } else {
      // yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* SingleBankDetailsRequest() {
  yield takeEvery("SingleBankDetails_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield singleBankDetailsApiHandler(
      payload.payload,
      payload.token
    );

    if (response.status == 1) {
      yield put({
        type: actions.SingleBankDetails_SUCCESS,
        data: response.data,
      });
      // payload.navigate.push("/user/mypreciousstone")
    } else {
      // yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* ListProductDescriptionRequest() {
  yield takeEvery("ListProductDescription_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield listProductDescriptionApiHandler(payload.payload);

    if (response.status == 1) {
      yield put({
        type: actions.ListProductDescription_SUCCESS,
        data: response.data,
      });
      // payload.navigate.push("/user/mypreciousstone")
    } else {
      // yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* ListProductPricingRequest() {
  yield takeEvery("ListProductPricing_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield listProductPricingApiHandler(payload.payload);

    if (response.status == 1) {
      yield put({
        type: actions.ListProductPricing_SUCCESS,
        data: response.data,
      });
      // payload.navigate.push("/user/mypreciousstone")
    } else {
      // yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* ListProductPurityRequest() {
  yield takeEvery("ListProductPurity_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield listProductPurityApiHandler(payload.payload);

    if (response.status == 1) {
      yield put({
        type: actions.ListProductPurity_SUCCESS,
        data: response.data,
      });
      // payload.navigate.push("/user/mypreciousstone")
    } else {
      // yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* SingleguidelineRequest() {
  yield takeEvery("Singleguideline_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield singleguidelineApiHandler(payload.payload);

    if (response.status == 1) {
      yield put({
        type: actions.Singleguideline_SUCCESS,
        data: response.data,
      });
      // payload.navigate.push("/user/mypreciousstone")
    } else {
      // yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* ListCustomerServicesRequest() {
  yield takeEvery("ListCustomerServices_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield listCustomerServicesApiHandler(payload.payload);

    if (response.status == 1) {
      yield put({
        type: actions.ListCustomerServices_SUCCESS,
        data: response.data,
      });
      // payload.navigate.push("/user/mypreciousstone")
    } else {
      // yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* GetAllProductHomeServicesRequest() {
  yield takeEvery("GetAllProductHome_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield getAllProductHomeServicesApiHandler(payload.payload);

    if (response.status == 1) {
      yield put({
        type: actions.GetAllProductHome_SUCCESS,
        data: response.data,
      });
      // payload.navigate.push("/user/mypreciousstone")
    } else {
      // yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* TrendingProductsServicesRequest() {
  yield takeEvery("TrendingProducts_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield trendingProductsServicesApiHandler(payload.payload);

    if (response.status == 1) {
      yield put({
        type: actions.TrendingProducts_SUCCESS,
        data: response.data,
      });
      // payload.navigate.push("/user/mypreciousstone")
    } else {
      // yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* ListTopDealsServicesRequest() {
  yield takeEvery("ListTopDeals_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield listTopDealsServicesApiHandler(payload.payload);

    if (response.status == 1) {
      yield put({
        type: actions.ListTopDeals_SUCCESS,
        data: response.data,
      });
      // payload.navigate.push("/user/mypreciousstone")
    } else {
      // yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* ListBenefitsServicesRequest() {
  yield takeEvery("ListBenefits_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield listBenefitsServicesApiHandler(payload.payload);

    if (response.status == 1) {
      yield put({
        type: actions.ListBenefits_SUCCESS,
        data: response,
      });
      // payload.navigate.push("/user/mypreciousstone")
    } else {
      // yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* AllPreciousStoneProductsRequest() {
  yield takeEvery("AllPreciousStoneProducts_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield getAllPreciousStoneProductsApiHandler(
      payload.payload,
      payload.token
    );

    if (response.status == 1) {
      yield put({
        type: actions.AllPreciousStoneProducts_SUCCESS,
        data: response.data,
      });
      // payload.navigate.push("/user/myproduct")
    } else {
      // yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* VerifyOtpEmailRequest() {
  yield takeEvery("VerifyOtp_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield verifyOtpEmailApiHandler(payload.payload);
    if (response.status == 1) {
      yield put({
        type: actions.VerifyOtp_SUCCESS,
        data: response,
        forgotSuccess:false
      });
      if (payload?.forgotPassword !== "forgotPassword") {
        payload.navigate.push("/user/membershipform");
      } else {
        // payload.navigate.push("/user/forgotpassword");
      }
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* sendOtpEmailRequest() {
  yield takeEvery("SendOtpEmail_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield sendOtpEmailApiHandler(payload.payload);
    if (response.status == 1 && response.message != "User already exist!") {
      yield put({
        type: actions.SendOtpEmail_SUCCESS,
        data: response,
      });
      // payload.navigate.push("/user/mypreciousstone")
    } else {
      yield put({
        type: actions.ERROR,
        message: response.message,
        data: response.message,
      });
    }
  });
}

export function* UpdateDeliveryStausRequest() {
  yield takeEvery("UpdateDeliveryStaus_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield updateDeliveryStausApiHandler(
      payload.payload,
      payload.token
    );

    if (response.status == 1) {
      yield put({
        type: actions.UpdateDeliveryStaus_SUCCESS,
        data: response.data,
      });
      // payload.navigate.push("/user/myproduct")
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* GetProfileRequest() {
  yield takeEvery("GET_PROFILE_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield getProfileApiHandler(payload.payload, payload.token);

    if (response.status == 1) {
      yield put({
        type: actions.GET_PROFILE_SUCCESS,
        data: response.data,
      });
      // payload.navigate.push("/user/myproduct")
    } else {
      // yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* GetAccountRequest() {
  yield takeEvery("GET_ACCOUNT_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield getAccountApiHandler(payload.payload, payload.token);

    if (response.status == 1) {
      yield put({
        type: actions.GET_ACCOUNT_SUCCESS,
        data: response.data,
      });
      // payload.navigate.push("/user/myproduct")
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* UpdateAccountRequest() {
  yield takeEvery("UPDATE_ACCOUNT_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield updateAccountApiHandler(
      payload.payload,
      payload.token
    );

    if (response.status == 1) {
      yield put({
        type: actions.UPDATE_ACCOUNT_SUCCESS,
        data: response.data,
      });
      // payload.navigate.push("/user/myproduct")
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* ChangePasswordRequest() {
  yield takeEvery("CHANGE_PASSWORD_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield updatePasswordApiHandler(
      payload.payload,
      payload.token
    );

    if (response.status == 1) {
      yield put({
        type: actions.CHANGE_PASSWORD_SUCCESS,
        data: response.data,
      });
      yield put({
        type: actions.LOGOUT,
      });

      payload.navigate.push("/user/");
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* FilterMaterialAndItemRequest() {
  yield takeEvery("FilterMaterialAndItem_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield filterMaterialAndItemApiHandler(
      payload.payload,
      payload.token
    );

    if (response.status == 1) {
      yield put({
        type: actions.FilterMaterialAndItem_SUCCESS,
        data: response.data,
      });
      // payload.navigate.push("/user/myproduct")
    } else {
      // yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* CatelogueSellerRequest() {
  yield takeEvery("CatelogueSeller_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield catelogueSellerApiHandler(payload.payload);

    if (response.status == 1) {
      yield put({
        type: actions.CatelogueSeller_SUCCESS,
        data: response.data,
      });
      // payload.navigate.push("/user/myproduct")
    } else {
      // yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* CatelogueProductRequest() {
  yield takeEvery("CatelogueProduct_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield catelogueProductApiHandler(payload.payload);

    if (response.status == 1) {
      yield put({
        type: actions.CatelogueProduct_SUCCESS,
        data: response.data,
      });
      // payload.navigate.push("/user/myproduct")
    } else {
      // yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* ProductDesignsRequest() {
  yield takeEvery("ProductDesigns_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield productDesignsApiHandler(payload.payload);

    if (response.status == 1) {
      yield put({
        type: actions.ProductDesigns_SUCCESS,
        data: response.data,
      });
      // payload.navigate.push("/user/myproduct")
    } else {
      // yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* AddToCartBulkProductRequest() {
  yield takeEvery("AddToCartBulkProduct_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield addToCartBulkProductApiHandler(
      payload.payload,
      payload.token
    );

    if (response.status == 1) {
      yield put({
        type: actions.AddToCartBulkProduct_SUCCESS,
        data: response.data,
      });
      // payload.navigate.push("/user/myproduct")
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* CatelogueItemsRequest() {
  yield takeEvery("CatelogueItems_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield catelogueItemsApiHandler(payload.payload);

    if (response.status == 1) {
      yield put({
        type: actions.CatelogueItems_SUCCESS,
        data: response.data,
      });
      // payload.navigate.push("/user/myproduct")
    } else {
      // yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* ForgotPasswordRequest() {
  yield takeEvery("ForgotPassword_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield forgotPasswordApiHandler(payload.payload);
    if (response.status == 1) {
      yield put({
        type: actions.ForgotPassword_SUCCESS,
        data: response.data,
        message: response.message,
        forgotSuccess:true,
        open:true
      });
      // payload.navigate.push("/user/otpverify");
      // payload.navigate.push("/user/myproduct")
    } else {
      yield put({ type:actions.ERROR, message:response.message ,forgotSuccess:false});
    }
  });
}

// export function* ResendUserOtpRequest() {
//   yield takeEvery("ResendUserOtp_REQUEST", function* (payload) {
//     yield put({
//       type: actions.LODER_ON,
//     });
//     const response = yield resendUserPasswordApiHandler(payload.payload);

//     yield put({
//       type: actions.ResendUserOtp_SUCCESS,
//       data: response.data,
//       message: response.message,
//     });
//     // payload.navigate.push("/user/otpverify");
//     // payload.navigate.push("/user/myproduct")
//     // else {
//     //   yield put({ type: actions.ERROR, message: response.message });
//     // }
//   });
// }
export function* ResendUserOtpRequest() {
  yield takeEvery("ResendUserOtp_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield resendUserPasswordApiHandler(payload.payload);

    if (response.status == 1 && response.data) {
      yield put({
        type: actions.ResendUserOtp_SUCCESS,
        data: response.data,
      });
      // yield put(push("/user/membershipform"))
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
    // payload.navigate.push("/user/otpverify");
    // payload.navigate.push("/user/myproduct")
    // else {
    //   yield put({ type: actions.ERROR, message: response.message });
    // }
  });
}

export function* ResetPasswordRequest() {
  yield takeEvery("ResetPassword_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield resetPasswordApiHandler(payload.payload);

    if (response.status == 1) {
      yield put({
        type: actions.ResetPassword_SUCCESS,
        data: response.data,
      });
      payload.navigate.push("/user");
      // payload.navigate.push("/user/myproduct")
    } else {
      yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* AllReceivedReturnOrderRequest() {
  yield takeEvery("AllReceivedReturnOrder_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield getAllReceivedReturnOrderApiHandler(payload.token);

    if (response.status == 1) {
      yield put({
        type: actions.AllReceivedReturnOrder_SUCCESS,
        data: response.data,
      });
      // payload.navigate.push("/user/myproduct")
    } else {
      // yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export function* ViewReceivedReturnOrderRequest() {
  yield takeEvery("ViewReceivedReturnOrder_REQUEST", function* (payload) {
    yield put({
      type: actions.LODER_ON,
    });
    const response = yield viewReceivedReturnOrderApiHandler(
      payload.payload,
      payload.token
    );

    if (response.status == 1) {
      yield put({
        type: actions.ViewReceivedReturnOrder_SUCCESS,
        data: response.data,
      });
      // payload.navigate.push("/user/myproduct")
    } else {
      // yield put({ type: actions.ERROR, message: response.message });
    }
  });
}

export default function* Usersaga() {
  yield all([
    fork(registerRequest),
    fork(MembershipRequest),
    fork(loginRequest),
    fork(AddcartRequest),
    fork(UserproductRequest),
    fork(AllProductRequest),
    fork(ProductRequest),
    fork(logOutRequest),
    fork(modalStateEmpty),
    fork(CategoryMenuRequest),
    fork(ViewCartRequest),
    fork(UpdateCartQuantityRequest),
    fork(DeleteCartItemRequest),
    fork(SignInRequest),
    fork(UserCreateProductRequest),
    fork(SingleUserProductRequest),
    fork(ListItemRequest),
    fork(ListMaterialRequest),
    fork(DeleteUserProductRequest),
    fork(OrderPlaceRequest),
    fork(ReceivedOrderRequest),
    fork(ViewReceivedOrderRequest),
    fork(GetOrderListRequest),
    fork(ViewOrderRequest),
    fork(FaqRequest),
    fork(RaiseCustomProductRequest),
    fork(ViewCustomProductRequest),
    fork(GetSingleCustomProductRequest),
    fork(GetAllCmsRequest),
    fork(AddUpdateRatingRequest),
    fork(SingleRatingRequest),
    fork(GetAllRatingRequest),
    fork(RecievedCustomProductRequest),
    fork(AcceptCustomProductRequest),
    fork(ReturnOrderRequest),
    fork(GetallReturnOrderRequest),
    fork(GetSingleReturnOrderRequest),
    fork(UserCreatePriciousRequest),
    fork(listBlogRequest),
    fork(ListGuidelineRequest),
    fork(SingleBankDetailsRequest),
    fork(ListProductDescriptionRequest),
    fork(ListProductPurityRequest),
    fork(ListProductPricingRequest),
    fork(SingleguidelineRequest),
    fork(ListCustomerServicesRequest),
    fork(GetAllProductHomeServicesRequest),
    fork(TrendingProductsServicesRequest),
    fork(ListTopDealsServicesRequest),
    fork(ListBenefitsServicesRequest),
    fork(VerifyOtpEmailRequest),
    fork(sendOtpEmailRequest),
    fork(AllPreciousStoneProductsRequest),
    fork(UpdateDeliveryStausRequest),
    fork(GetProfileRequest),
    fork(GetAccountRequest),
    fork(UpdateAccountRequest),
    fork(ChangePasswordRequest),
    fork(FilterMaterialAndItemRequest),
    fork(CatelogueSellerRequest),
    fork(CatelogueProductRequest),
    fork(ProductDesignsRequest),
    fork(AddToCartBulkProductRequest),
    fork(CatelogueItemsRequest),
    fork(ForgotPasswordRequest),
    fork(ResendUserOtpRequest),
    fork(ResetPasswordRequest),
    fork(updateModalRequest),
    fork(AllReceivedReturnOrderRequest),
    fork(ViewReceivedReturnOrderRequest),
  ]);
}
