import React, { useState, useEffect } from 'react';
import {
  Grid,
  Container,
  Typography,
  Button,
  Box,
  TextField,
  FormHelperText,
  TextareaAutosize,
  Tooltip,
} from '@mui/material';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import { styled } from '@mui/material/styles';
import { useHistory, useParams } from 'react-router-dom';
import { Api } from '../../../../config/api';
import dataService from '../../../../config/dataService';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { toast } from 'react-toastify';
import ContainedButton from '../../../../component/customComponents/buttons/ContainedButton';
import ImgUploadButton from '../../../../component/customComponents/buttons/ImgUploadButton';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import {
  ErrorMsg,
  TextAreaErrorMsg,
} from '../../../../component/customComponents/errorMsg/ErrorMsg';
import {
  minAboutUsHeadingLength,
  aboutUsHeadingLength,
  minAboutUsDescriptionLength,
  aboutUsDescriptionLength,
  NO_WHITE_SPACE_BEGINNING_END,
} from '../../../../component/customComponents/validation/Validation';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import UploadIcon from '@mui/icons-material/Upload';
import AdminIndex from '../../AdminIndex';

//#region ALL HOOKS AND VARIABALS
const Img = styled('img')({
  display: 'block',
  maxWidth: '100%',
  height: 'auto',
  maxHeight: '50vh',
  borderRadius: '6px',
});

const { getTermCondition, updateTermCondition } = AdminIndex.AdminSagaActions;
const UpdateAboutUs = () => {
  let token = localStorage.getItem('auth');
  const { id } = useParams();
  const dispatch = AdminIndex.useDispatch();
  const navigate = useHistory();
  const [getData, setGetData] = useState([]);
  const [image, setImage] = useState('');
  const [url, setUrl] = useState('');

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
    watch,
  } = useForm({ mode: 'onChange' });

  useEffect(() => {
    getAboutUsData();
  }, []);

  useEffect(() => {
    setUrl(getData?.image);
    setValue('aboutUsHeading', getData?.about_us);
    setValue('aboutUsDescription', getData?.about_us_description);
  }, [getData]);
  //#endregion

  //#region ALL EVENT HANDLERS AND  FUCTIONS
  const handleImg = (e) => {
    if (e.target.files.length) {
      setImage(e.target.files[0]);
      setUrl(window.URL.createObjectURL(e.target.files[0]));
    }
  };

  const removeImg = () => {
    setImage('');
    setUrl('');
  };

  const getAboutUsData = async (e) => {
    let formdata = new URLSearchParams();
    try {
      formdata.append('id', id);
      dispatch(getTermCondition(token,formdata));
      // const Data = await dataService.post(Api.Admin.getCms, formdata);
      // setGetData(Data.data.data);
    } catch (err) {
      toast.error(err?.message || 'Something went wrong');
    }
  };

  const termCondition = AdminIndex.useSelector((state) => {
    return state.AdminSaga.termCondition;
  });

  useEffect(() => {
    setGetData(termCondition);
  }, [termCondition]);

  const handleSave = async (data) => {
    try {
      let formdata = new FormData();
      formdata.append('id', id);
      formdata.append('about_us', data?.aboutUsHeading?.trim());
      formdata.append('about_us_description', data?.aboutUsDescription?.trim());
      formdata.append('images', image);
      formdata.append('fileurl', url);

      dispatch(updateTermCondition(token,formdata,navigate))
      // const aboutUsResp = await dataService.post(
      //   Api.Admin.createTermsAndCondition,
      //   formdata,
      //   {
      //     headers: {
      //       'Content-Type': 'multipart/form-data',
      //       auth: token,
      //     },
      //   }
      // );

      // if (aboutUsResp.data.status == '1') {
      //   toast.success('About us data updated successfully');
      //   history.push('/admin/contentmanagement');
      // }
    } catch (err) {
      toast.error(err?.message || 'Something went wrong');
    }
  };
  //#endregion

  //#region RETURN JSX
  return (
    <>
      <form onSubmit={handleSubmit(handleSave)}>
        <Container maxWidth="false">
          <Typography sx={{ my: 2 }} align="center" variant="h4">
            About us
          </Typography>

          <Grid container spacing={5}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography align="left" sx={{ mt: 2 }}>
                Heading Name
              </Typography>
              <TextField
                autoComplete="off"
                sx={{
                  mt: 2,
                  '& .MuiFormHelperText-root': { ml: 0, mt: '5px' },
                }}
                size="small"
                fullWidth
                className="form-control border-none"
                name="aboutUsHeading"
                type="text"
                {...register('aboutUsHeading', {
                  required: <ErrorMsg msg="Please enter Heading" />,
                  minLength: {
                    value: minAboutUsHeadingLength,
                    message: (
                      <ErrorMsg
                        msg={`Minimum ${minAboutUsHeadingLength} Characters Allowed`}
                      />
                    ),
                  },
                  maxLength: {
                    value: aboutUsHeadingLength,
                    message: (
                      <ErrorMsg
                        msg={`Maximum ${aboutUsHeadingLength} Characters Allowed`}
                      />
                    ),
                  },
                  pattern: {
                    value: NO_WHITE_SPACE_BEGINNING_END,
                    message: (
                      <ErrorMsg msg="Whitespace is not allowed at beginning or end" />
                    ),
                  },
                })}
                helperText={
                  <ErrorMessage errors={errors} name="aboutUsHeading" />
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography align="left" sx={{ mt: 2 }}>
                About us Description
              </Typography>
              <Box className="text-area-control-main">
                <TextareaAutosize
                  autoComplete="off"
                  minRows={0}
                  size="small"
                  fullWidth
                  className="form-control border-none text-area-control1 text-area-control-admin"
                  name="aboutUsDescription"
                  type="text"
                  {...register('aboutUsDescription', {
                    required: (
                      <TextAreaErrorMsg msg="Please enter Description" />
                    ),
                    minLength: {
                      value: minAboutUsDescriptionLength,
                      message: (
                        <TextAreaErrorMsg
                          msg={`Minimum ${minAboutUsDescriptionLength} Characters Allowed`}
                        />
                      ),
                    },
                    maxLength: {
                      value: aboutUsDescriptionLength,
                      message: (
                        <TextAreaErrorMsg
                          msg={`Maximum ${aboutUsDescriptionLength} Characters Allowed`}
                        />
                      ),
                    },
                    pattern: {
                      value: NO_WHITE_SPACE_BEGINNING_END,
                      message: (
                        <TextAreaErrorMsg msg="Whitespace is not allowed at beginning or end" />
                      ),
                    },
                  })}
                />
                <ErrorMessage errors={errors} name="aboutUsDescription" />
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography align="left" sx={{ mt: 2, mb: 1.5 }}>
                Upload Image
              </Typography>
              <Box sx={{ width: '100%', position: 'relative' }}>
                {image === '' && url == '' && (
                  <>
                    <Button
                      component="label"
                      sx={{ borderRadius: 0, textTransform: 'none' }}
                      variant="contained"
                      onChange={handleImg}
                    >
                      Upload
                      <UploadIcon />
                      <input
                        name="files"
                        hidden
                        accept="image/*"
                        type="file"
                        {...register('files', {
                          required: <ErrorMsg msg="Please select Image" />,
                        })}
                      />
                    </Button>
                    <ErrorMessage errors={errors} name="files" />
                  </>
                )}
                {image === '' && url == '' ? (
                  ''
                ) : (
                  <Box className="aboutUs-img-box">
                    <Img
                      sx={{
                        my: 2,
                        borderRadius: '0px',
                        height: '130px',
                        width: '200px',
                      }}
                      src={url}
                      alt="no image"
                    />
                    <Button className="aboutUs-top-btn">
                      <Tooltip title="Remove this Image" placement="bottom">
                        <DisabledByDefaultIcon
                          className="remove-img-icon"
                          onClick={removeImg}
                          sx={{
                            // bgcolor: 'black(0.5)',
                            // color: 'black',
                            mt: 5,
                          }}
                        />
                      </Tooltip>
                    </Button>
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ display: 'flex', justifyContent: 'center', my: 5 }}>
            <ContainedButton name="Update" type="submit" />
          </Box>
        </Container>
      </form>
    </>
  );
};

export default UpdateAboutUs;
//#endregion
