export const ConvertIntoTwoDigits = (value) => {
  let result = String(value);
  let check = result?.includes('.');
  let finalResult;

  if (check) {
    let data = result.split(/[.]/);
    let left = data[0];
    let right = data[1];
    let tempLeft;
    let tempRight;

    if (left) {
      tempLeft = left.padStart(2, '0');
    }
    if (right) {
      tempRight = right.padStart(2, '0');
    }
    if (tempLeft !== undefined && tempRight !== undefined) {
      finalResult = tempLeft + '.' + tempRight;
      return finalResult;
    } else {
      finalResult = left + '.' + right;
      return finalResult;
    }
  } else {
    if (result.length <= 1) {
      return (result = result.padStart(2, '0'));
    } else {
      return (result = result);
    }
  }
};

export const commaSeparated = (value) => {
  return value?.toLocaleString('en-IN'); // converts price like this ----> 1,26,89,256 INDIAN format
  //   return value.toLocaleString('en-US');   // converts price like this ----> 12,689,256 USA format
};

// export const commaSeparated = (value) =>  {

//   let result = String(value);
//   let check = result?.includes('.');
//   let finalResult;
//   if(check){
//     // let data1 = twoValAfterDesimal(result)
//     let data = result.split(/[.]/);
//     let left = Number(data[0]);
//     let right = Number(data[1]);
//     let tempLeft;
//     let tempRight;

//     if (left) {
//       tempLeft = left.toLocaleString('en-IN');
//     }
//     if (right) {
//       tempRight = right;
//     }
//     if (tempLeft !== undefined && tempRight !== undefined) {
//       finalResult = tempLeft + '.' + tempRight;
//       return finalResult;
//     } else {
//       finalResult = left + '.' + right;
//       return finalResult;
//     }
//   }else{
//     return value.toLocaleString('en-IN');       // converts price like this ----> 1,26,89,256 INDIAN format
//     //   return value.toLocaleString('en-US');   // converts price like this ----> 12,689,256 USA format
//   }
// }

// export const price = (value) => {
//   let result = String(value);
//   let result1 = value;
//   let check = result?.includes('.');
//   let finalResult;

//   if (check) {
//     let data1 = twoValAfterDesimal(result1);
//     let data2 = String(data1);
//     let data = data2.split(/[.]/);
//     let left = Number(data[0]);
//     let right = String(data[1]);
//     let tempLeft;
//     let tempRight;
//     if (left) {
//       tempLeft = left.toLocaleString('en-IN');
//     }
//     if (right) {
//       tempRight = right;
//     }
//     if (tempLeft !== undefined && tempRight !== undefined) {
//       finalResult = tempLeft + '.' + tempRight;
//       return finalResult;
//     } else {
//       finalResult = left + '.' + right;
//       return finalResult;
//     }
//   } else {
//     let val = Number(value)
//     return val?.toLocaleString('en-In').concat('.00'); // converts price like this ----> 1,26,89,256.00 INDIAN format
//     //   return value.toLocaleString('en-US');   // converts price like this ----> 12,689,256.00 USA format
//   }
// };

export const price = (value) => {
  return Math.round(value).toLocaleString('en-IN')
  // return Math.round(value)
}

export const twoValAfterDesimal = (value) => {
  return parseFloat(value).toFixed(2);
};

export const ConvertToLink = ({ content }) => {
  let str = String(content);
  const words = str.split(' ');
  const URL_REGEX =
    /^(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gm;
  return (
    <p>
      {words.map((word) => {
        return word.match(URL_REGEX) ? (
          <>
            <a href={word} target="_blank">
              {' '}
              {word}{' '}
            </a>{' '}
          </>
        ) : (
          word + ' '
        );
      })}
    </p>
  );
};

export const downloadImageWithoutBlob = (e) => {
  e.preventDefault();
  fetch(e.target.src, {
    method: 'GET',
    headers: {},
  })
    .then((response) => {
      response.arrayBuffer().then(function (buffer) {
        const url = window.URL.createObjectURL(new Blob([buffer]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'image.png'); //or any other extension
        document.body.appendChild(link);
        link.click();
        
      });
    })
    .catch((err) => {
      console.log(err);
    });
};
