import { Button, Grid, TextField } from '@mui/material'
import React from 'react'

const Profile = () => {
  return (
    <Grid
    container
    spacing={4}
    direction="column"
    alignItems="center"
    justifyContent="center"
    sx={{mt: 5}}
  >
    <Grid item xs={6}>
      <TextField
      sx={{width: { lg: '300px', xs: '100%' }}}
      className="form-control2 border-none"
        label="Change Name"
        defaultValue="sam"
        fullWidth
      />
    </Grid>
    <Grid item xs={6}>
      <TextField
      sx={{width: { lg: '300px', xs: '100%' }}}
      className="form-control2 border-none"
        label="Mobile Number"
        defaultValue="1234567890"
        fullWidth
      />
    </Grid>
    <Grid item xs={6}>
      <TextField
      sx={{width: { lg: '300px', xs: '100%' }}}
      className="form-control2 border-none"
        label="Email"
        defaultValue="sam@gmail.com"
        fullWidth
      />
    </Grid>
    <Grid item xs={6}>
      <Button
              sx={{
                // my: 5,
                width: '125px',
                textTransform: 'none',
              }}
              variant="contained"
              type="submit"
            >
              Submit
            </Button>
    </Grid>
  </Grid>
  )
}

export default Profile