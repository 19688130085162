import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
// import Link from '@mui/material/Link';
import { Link } from "react-router-dom";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import UserSagaActions from "../../../redux/Usersaga/action";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useNavigate } from "react-router-dom";

const { CategoryMenuSaga } = UserSagaActions;

const AllJewellery = () => {
  const dispatch = useDispatch();
  const navigate = useHistory();
  const history = useHistory();
  const loading = useSelector((state) => {
    return state.Usersaga.loading;
  });
  const Categorymenudata = useSelector((state) => {
    return state.Usersaga.Categorymenudata;
  });

  const [categoryMenu, setCategoryMenu] = useState();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getCategoryMenu = () => {
    let urlencoded = new URLSearchParams();
    dispatch(CategoryMenuSaga(urlencoded, navigate));
  };
  useEffect(() => {
    setCategoryMenu(Categorymenudata);
  }, [Categorymenudata]);

  useEffect(() => {
    getCategoryMenu();
  }, []);

  return (
    <Box className="nav-inner-main">
      {categoryMenu?.length
        ? categoryMenu.map((index) => {
            return (
              <>
                <Box className="nav-jewelry-one">
                  <Box clasName="nav-hover-data-box">
                    <Box className="inner-data">
                      <List className="nav-hover-ul">
                        <ListItem className="nav-hover-li">
                          <Link
                            onClick={() =>
                              history.push(`/user/category/${index?._id}`)
                            }
                          >
                            <ListItemText
                              onClick={handleClose}
                              className="nav-hover-li-heading"
                            >
                              {index?.category_name}
                            </ListItemText>
                          </Link>
                        </ListItem>
                      </List>
                      <List className="nav-hover-ul flex-inner-ul">
                        {index.item?.length ? (
                          index?.item.map((data) => {
                            return (
                              <ListItem className="nav-hover-li">
                                <Link
                                  onClick={() =>
                                    history.push(
                                      `/user/categoryd/${index?._id}/${data?._id}`
                                    )
                                  }
                                >
                                  <ListItemText className="nav-hover-li-data">
                                    {data?.item}
                                  </ListItemText>
                                </Link>
                              </ListItem>
                            );
                          })
                        ) : (
                          <Box className="blank-text">No Records Found</Box>
                        )}
                      </List>
                    </Box>
                  </Box>
                </Box>
              </>
            );
          })
        : "No Records Found"}
    </Box>
  );
};

export default AllJewellery;
