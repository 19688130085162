import React,{useState} from 'react';
import Box from "@mui/material/Box";
import { Button, Typography } from '@mui/material';
import svg from "../../../../assets/svg";
import png from "../../../../assets/png";
import Header from '../../../../component/user/defaultLayout/Header';
import Footer from '../../../../component/user/defaultLayout/Footer';
import Pagination from '@mui/material/Pagination';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { styled } from '@mui/material/styles';


function TabPanel(props) {
      const { children, value, index, ...other } = props;

      return (
            <div
                  role="tabpanel"
                  hidden={value !== index}
                  id={`simple-tabpanel-${index}`}
                  aria-labelledby={`simple-tab-${index}`}
                  {...other}
            >
                  {value === index && (
                        <Box sx={{ p: 3 }}>
                              <Typography>{children}</Typography>
                        </Box>
                  )}
            </div>
      );
}

TabPanel.propTypes = {
      children: PropTypes.node,
      index: PropTypes.number.isRequired,
      value: PropTypes.number.isRequired,
};

function a11yProps(index) {
      return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
      };
}


const PreciousStones = () => {

      const [value, setValue] = useState(0);

      const handleChange = (event, newValue) => {
            setValue(newValue);
      };

      return (
            <div>
                  <Header />
                  <Box className='precious-stone-sec'>
                        <Box className='container'>
                              <Box className='row'>
                                    <Box className='myproduct-title-box'>
                                          <Typography variant='h4' className='myproduct-title'><span>Precious Stones</span></Typography>
                                    </Box>
                              </Box>
                              <Box className='myproduct-tabs-box'>
                                    <Box className='my-product-tabs-main'>
                                          <Box sx={{ width: '100%' }}>
                                                <Box>
                                                      <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className='myproduct-tabs'>
                                                            <Tab label="Uploaded" {...a11yProps(0)} className="myproduct-tabs-lable" />
                                                            <Tab label="Pending" {...a11yProps(1)} className="myproduct-tabs-lable" />
                                                            <Tab label="Rejected" {...a11yProps(2)} className="myproduct-tabs-lable" />
                                                      </Tabs>
                                                </Box>
                                          </Box>
                                    </Box>
                                    <Box className='add-product-btn-main'>
                                          <Box className='primary-btn-box'>
                                                <Button className='primary-btn add-product-btn'>Add Product</Button>
                                          </Box>
                                    </Box>
                              </Box>
                              <Box className='my-product-tabs-content'>
                                    <TabPanel value={value} index={0} className="myproduct-tabs-panel">
                                          <Box sx={{ width: 1 }}>
                                                <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={{ xs: 0, sm: 5, md: 5, lg: 5 }}>
                                                      <Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 3", lg: "span 3" }}>
                                                            <Box className='product-box'>
                                                                  <Box className='product-img-box'>
                                                                        <img src={png.pre1} className="product-img"></img>
                                                                        <Box className='show-hover'>
                                                                              <Box className='add-cart-box-hover'>
                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconheart} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>

                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconcart} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>
                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconsearch} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>
                                                                              </Box>
                                                                        </Box>
                                                                  </Box>
                                                                  <Box className='product-content'>
                                                                        <Box className='product-title'>Flower Goldcrop Ring</Box>
                                                                        <Box className='product-sub-title'>Rose gold</Box>
                                                                        <Box className='product-price'>$12,350.00</Box>
                                                                        <Box className="product-btn-box">
                                                                              <Button className='btn btn-product'>View</Button>
                                                                        </Box>
                                                                  </Box>
                                                            </Box>
                                                      </Box>
                                                      <Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 3", lg: "span 3" }}>
                                                            <Box className='product-box'>
                                                                  <Box className='product-img-box'>
                                                                        <img src={png.pre2} className="product-img"></img>
                                                                        <Box className='show-hover'>
                                                                              <Box className='add-cart-box-hover'>
                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconheart} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>

                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconcart} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>
                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconsearch} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>
                                                                              </Box>
                                                                        </Box>
                                                                  </Box>
                                                                  <Box className='product-content'>
                                                                        <Box className='product-title'>Flower Goldcrop Ring</Box>
                                                                        <Box className='product-sub-title'>Rose gold</Box>
                                                                        <Box className='product-price'>$12,350.00</Box>
                                                                        <Box className="product-btn-box">
                                                                              <Button className='btn btn-product'>View</Button>
                                                                        </Box>
                                                                  </Box>
                                                            </Box>
                                                      </Box>
                                                      <Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 3", lg: "span 3" }}>
                                                            <Box className='product-box'>
                                                                  <Box className='product-img-box'>
                                                                        <img src={png.pre3} className="product-img"></img>
                                                                        <Box className='show-hover'>
                                                                              <Box className='add-cart-box-hover'>
                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconheart} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>

                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconcart} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>
                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconsearch} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>
                                                                              </Box>
                                                                        </Box>
                                                                  </Box>
                                                                  <Box className='product-content'>
                                                                        <Box className='product-title'>Flower Goldcrop Ring</Box>
                                                                        <Box className='product-sub-title'>Rose gold</Box>
                                                                        <Box className='product-price'>$12,350.00</Box>
                                                                        <Box className="product-btn-box">
                                                                              <Button className='btn btn-product'>View</Button>
                                                                        </Box>
                                                                  </Box>
                                                            </Box>
                                                      </Box>
                                                      <Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 3", lg: "span 3" }}>
                                                            <Box className='product-box'>
                                                                  <Box className='product-img-box'>
                                                                        <img src={png.pre4} className="product-img"></img>
                                                                        <Box className='show-hover'>
                                                                              <Box className='add-cart-box-hover'>
                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconheart} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>

                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconcart} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>
                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconsearch} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>
                                                                              </Box>
                                                                        </Box>
                                                                  </Box>
                                                                  <Box className='product-content'>
                                                                        <Box className='product-title'>Flower Goldcrop Ring</Box>
                                                                        <Box className='product-sub-title'>Rose gold</Box>
                                                                        <Box className='product-price'>$12,350.00</Box>
                                                                        <Box className="product-btn-box">
                                                                              <Button className='btn btn-product'>View</Button>
                                                                        </Box>
                                                                  </Box>
                                                            </Box>
                                                      </Box>
                                                      <Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 3", lg: "span 3" }}>
                                                            <Box className='product-box'>
                                                                  <Box className='product-img-box'>
                                                                        <img src={png.pre5} className="product-img"></img>
                                                                        <Box className='show-hover'>
                                                                              <Box className='add-cart-box-hover'>
                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconheart} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>

                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconcart} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>
                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconsearch} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>
                                                                              </Box>
                                                                        </Box>
                                                                  </Box>
                                                                  <Box className='product-content'>
                                                                        <Box className='product-title'>Flower Goldcrop Ring</Box>
                                                                        <Box className='product-sub-title'>Rose gold</Box>
                                                                        <Box className='product-price'>$12,350.00</Box>
                                                                        <Box className="product-btn-box">
                                                                              <Button className='btn btn-product'>View</Button>
                                                                        </Box>
                                                                  </Box>
                                                            </Box>
                                                      </Box>
                                                      <Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 3", lg: "span 3" }}>
                                                            <Box className='product-box'>
                                                                  <Box className='product-img-box'>
                                                                        <img src={png.pre6} className="product-img"></img>
                                                                        <Box className='show-hover'>
                                                                              <Box className='add-cart-box-hover'>
                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconheart} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>

                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconcart} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>
                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconsearch} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>
                                                                              </Box>
                                                                        </Box>
                                                                  </Box>
                                                                  <Box className='product-content'>
                                                                        <Box className='product-title'>Flower Goldcrop Ring</Box>
                                                                        <Box className='product-sub-title'>Rose gold</Box>
                                                                        <Box className='product-price'>$12,350.00</Box>
                                                                        <Box className="product-btn-box">
                                                                              <Button className='btn btn-product'>View</Button>
                                                                        </Box>
                                                                  </Box>
                                                            </Box>
                                                      </Box>
                                                      <Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 3", lg: "span 3" }}>
                                                            <Box className='product-box'>
                                                                  <Box className='product-img-box'>
                                                                        <img src={png.pre1} className="product-img"></img>
                                                                        <Box className='show-hover'>
                                                                              <Box className='add-cart-box-hover'>
                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconheart} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>

                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconcart} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>
                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconsearch} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>
                                                                              </Box>
                                                                        </Box>
                                                                  </Box>
                                                                  <Box className='product-content'>
                                                                        <Box className='product-title'>Flower Goldcrop Ring</Box>
                                                                        <Box className='product-sub-title'>Rose gold</Box>
                                                                        <Box className='product-price'>$12,350.00</Box>
                                                                        <Box className="product-btn-box">
                                                                              <Button className='btn btn-product'>View</Button>
                                                                        </Box>
                                                                  </Box>
                                                            </Box>
                                                      </Box>
                                                      <Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 3", lg: "span 3" }}>
                                                            <Box className='product-box'>
                                                                  <Box className='product-img-box'>
                                                                        <img src={png.pre2} className="product-img"></img>
                                                                        <Box className='show-hover'>
                                                                              <Box className='add-cart-box-hover'>
                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconheart} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>

                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconcart} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>
                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconsearch} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>
                                                                              </Box>
                                                                        </Box>
                                                                  </Box>
                                                                  <Box className='product-content'>
                                                                        <Box className='product-title'>Flower Goldcrop Ring</Box>
                                                                        <Box className='product-sub-title'>Rose gold</Box>
                                                                        <Box className='product-price'>$12,350.00</Box>
                                                                        <Box className="product-btn-box">
                                                                              <Button className='btn btn-product'>View</Button>
                                                                        </Box>
                                                                  </Box>
                                                            </Box>
                                                      </Box>
                                                      <Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 3", lg: "span 3" }}>
                                                            <Box className='product-box'>
                                                                  <Box className='product-img-box'>
                                                                        <img src={png.pre3} className="product-img"></img>
                                                                        <Box className='show-hover'>
                                                                              <Box className='add-cart-box-hover'>
                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconheart} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>

                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconcart} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>
                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconsearch} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>
                                                                              </Box>
                                                                        </Box>
                                                                  </Box>
                                                                  <Box className='product-content'>
                                                                        <Box className='product-title'>Flower Goldcrop Ring</Box>
                                                                        <Box className='product-sub-title'>Rose gold</Box>
                                                                        <Box className='product-price'>$12,350.00</Box>
                                                                        <Box className="product-btn-box">
                                                                              <Button className='btn btn-product'>View</Button>
                                                                        </Box>
                                                                  </Box>
                                                            </Box>
                                                      </Box>
                                                      <Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 3", lg: "span 3" }}>
                                                            <Box className='product-box'>
                                                                  <Box className='product-img-box'>
                                                                        <img src={png.pre4} className="product-img"></img>
                                                                        <Box className='show-hover'>
                                                                              <Box className='add-cart-box-hover'>
                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconheart} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>

                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconcart} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>
                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconsearch} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>
                                                                              </Box>
                                                                        </Box>
                                                                  </Box>
                                                                  <Box className='product-content'>
                                                                        <Box className='product-title'>Flower Goldcrop Ring</Box>
                                                                        <Box className='product-sub-title'>Rose gold</Box>
                                                                        <Box className='product-price'>$12,350.00</Box>
                                                                        <Box className="product-btn-box">
                                                                              <Button className='btn btn-product'>View</Button>
                                                                        </Box>
                                                                  </Box>
                                                            </Box>
                                                      </Box>
                                                      <Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 3", lg: "span 3" }}>
                                                            <Box className='product-box'>
                                                                  <Box className='product-img-box'>
                                                                        <img src={png.pre5} className="product-img"></img>
                                                                        <Box className='show-hover'>
                                                                              <Box className='add-cart-box-hover'>
                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconheart} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>

                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconcart} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>
                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconsearch} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>
                                                                              </Box>
                                                                        </Box>
                                                                  </Box>
                                                                  <Box className='product-content'>
                                                                        <Box className='product-title'>Flower Goldcrop Ring</Box>
                                                                        <Box className='product-sub-title'>Rose gold</Box>
                                                                        <Box className='product-price'>$12,350.00</Box>
                                                                        <Box className="product-btn-box">
                                                                              <Button className='btn btn-product'>View</Button>
                                                                        </Box>
                                                                  </Box>
                                                            </Box>
                                                      </Box>
                                                      <Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 3", lg: "span 3" }}>
                                                            <Box className='product-box'>
                                                                  <Box className='product-img-box'>
                                                                        <img src={png.pre6} className="product-img"></img>
                                                                        <Box className='show-hover'>
                                                                              <Box className='add-cart-box-hover'>
                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconheart} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>

                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconcart} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>
                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconsearch} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>
                                                                              </Box>
                                                                        </Box>
                                                                  </Box>
                                                                  <Box className='product-content'>
                                                                        <Box className='product-title'>Flower Goldcrop Ring</Box>
                                                                        <Box className='product-sub-title'>Rose gold</Box>
                                                                        <Box className='product-price'>$12,350.00</Box>
                                                                        <Box className="product-btn-box">
                                                                              <Button className='btn btn-product'>View</Button>
                                                                        </Box>
                                                                  </Box>
                                                            </Box>
                                                      </Box>
                                                </Box>
                                          </Box>
                                    </TabPanel>
                                    <TabPanel value={value} index={1} className="myproduct-tabs-panel">
                                          <Box sx={{ width: 1 }}>
                                                <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={{ xs: 0, sm: 5, md: 5, lg: 5 }}>
                                                      <Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 3", lg: "span 3" }}>
                                                            <Box className='product-box'>
                                                                  <Box className='product-img-box'>
                                                                        <img src={png.pre1} className="product-img"></img>
                                                                        <Box className='show-hover'>
                                                                              <Box className='add-cart-box-hover'>
                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconheart} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>

                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconcart} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>
                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconsearch} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>
                                                                              </Box>
                                                                        </Box>
                                                                  </Box>
                                                                  <Box className='product-content'>
                                                                        <Box className='product-title'>Flower Goldcrop Ring</Box>
                                                                        <Box className='product-sub-title'>Rose gold</Box>
                                                                        <Box className='product-price'>$12,350.00</Box>
                                                                        <Box className="product-btn-box">
                                                                              <Button className='btn btn-product'>View</Button>
                                                                        </Box>
                                                                  </Box>
                                                            </Box>
                                                      </Box>
                                                      <Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 3", lg: "span 3" }}>
                                                            <Box className='product-box'>
                                                                  <Box className='product-img-box'>
                                                                        <img src={png.pre2} className="product-img"></img>
                                                                        <Box className='show-hover'>
                                                                              <Box className='add-cart-box-hover'>
                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconheart} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>

                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconcart} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>
                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconsearch} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>
                                                                              </Box>
                                                                        </Box>
                                                                  </Box>
                                                                  <Box className='product-content'>
                                                                        <Box className='product-title'>Flower Goldcrop Ring</Box>
                                                                        <Box className='product-sub-title'>Rose gold</Box>
                                                                        <Box className='product-price'>$12,350.00</Box>
                                                                        <Box className="product-btn-box">
                                                                              <Button className='btn btn-product'>View</Button>
                                                                        </Box>
                                                                  </Box>
                                                            </Box>
                                                      </Box>
                                                      <Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 3", lg: "span 3" }}>
                                                            <Box className='product-box'>
                                                                  <Box className='product-img-box'>
                                                                        <img src={png.pre3} className="product-img"></img>
                                                                        <Box className='show-hover'>
                                                                              <Box className='add-cart-box-hover'>
                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconheart} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>

                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconcart} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>
                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconsearch} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>
                                                                              </Box>
                                                                        </Box>
                                                                  </Box>
                                                                  <Box className='product-content'>
                                                                        <Box className='product-title'>Flower Goldcrop Ring</Box>
                                                                        <Box className='product-sub-title'>Rose gold</Box>
                                                                        <Box className='product-price'>$12,350.00</Box>
                                                                        <Box className="product-btn-box">
                                                                              <Button className='btn btn-product'>View</Button>
                                                                        </Box>
                                                                  </Box>
                                                            </Box>
                                                      </Box>
                                                      <Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 3", lg: "span 3" }}>
                                                            <Box className='product-box'>
                                                                  <Box className='product-img-box'>
                                                                        <img src={png.pre4} className="product-img"></img>
                                                                        <Box className='show-hover'>
                                                                              <Box className='add-cart-box-hover'>
                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconheart} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>

                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconcart} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>
                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconsearch} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>
                                                                              </Box>
                                                                        </Box>
                                                                  </Box>
                                                                  <Box className='product-content'>
                                                                        <Box className='product-title'>Flower Goldcrop Ring</Box>
                                                                        <Box className='product-sub-title'>Rose gold</Box>
                                                                        <Box className='product-price'>$12,350.00</Box>
                                                                        <Box className="product-btn-box">
                                                                              <Button className='btn btn-product'>View</Button>
                                                                        </Box>
                                                                  </Box>
                                                            </Box>
                                                      </Box>
                                                      <Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 3", lg: "span 3" }}>
                                                            <Box className='product-box'>
                                                                  <Box className='product-img-box'>
                                                                        <img src={png.pre5} className="product-img"></img>
                                                                        <Box className='show-hover'>
                                                                              <Box className='add-cart-box-hover'>
                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconheart} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>

                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconcart} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>
                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconsearch} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>
                                                                              </Box>
                                                                        </Box>
                                                                  </Box>
                                                                  <Box className='product-content'>
                                                                        <Box className='product-title'>Flower Goldcrop Ring</Box>
                                                                        <Box className='product-sub-title'>Rose gold</Box>
                                                                        <Box className='product-price'>$12,350.00</Box>
                                                                        <Box className="product-btn-box">
                                                                              <Button className='btn btn-product'>View</Button>
                                                                        </Box>
                                                                  </Box>
                                                            </Box>
                                                      </Box>
                                                      <Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 3", lg: "span 3" }}>
                                                            <Box className='product-box'>
                                                                  <Box className='product-img-box'>
                                                                        <img src={png.pre6} className="product-img"></img>
                                                                        <Box className='show-hover'>
                                                                              <Box className='add-cart-box-hover'>
                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconheart} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>

                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconcart} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>
                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconsearch} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>
                                                                              </Box>
                                                                        </Box>
                                                                  </Box>
                                                                  <Box className='product-content'>
                                                                        <Box className='product-title'>Flower Goldcrop Ring</Box>
                                                                        <Box className='product-sub-title'>Rose gold</Box>
                                                                        <Box className='product-price'>$12,350.00</Box>
                                                                        <Box className="product-btn-box">
                                                                              <Button className='btn btn-product'>View</Button>
                                                                        </Box>
                                                                  </Box>
                                                            </Box>
                                                      </Box>
                                                      <Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 3", lg: "span 3" }}>
                                                            <Box className='product-box'>
                                                                  <Box className='product-img-box'>
                                                                        <img src={png.pre1} className="product-img"></img>
                                                                        <Box className='show-hover'>
                                                                              <Box className='add-cart-box-hover'>
                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconheart} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>

                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconcart} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>
                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconsearch} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>
                                                                              </Box>
                                                                        </Box>
                                                                  </Box>
                                                                  <Box className='product-content'>
                                                                        <Box className='product-title'>Flower Goldcrop Ring</Box>
                                                                        <Box className='product-sub-title'>Rose gold</Box>
                                                                        <Box className='product-price'>$12,350.00</Box>
                                                                        <Box className="product-btn-box">
                                                                              <Button className='btn btn-product'>View</Button>
                                                                        </Box>
                                                                  </Box>
                                                            </Box>
                                                      </Box>
                                                      <Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 3", lg: "span 3" }}>
                                                            <Box className='product-box'>
                                                                  <Box className='product-img-box'>
                                                                        <img src={png.pre2} className="product-img"></img>
                                                                        <Box className='show-hover'>
                                                                              <Box className='add-cart-box-hover'>
                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconheart} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>

                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconcart} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>
                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconsearch} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>
                                                                              </Box>
                                                                        </Box>
                                                                  </Box>
                                                                  <Box className='product-content'>
                                                                        <Box className='product-title'>Flower Goldcrop Ring</Box>
                                                                        <Box className='product-sub-title'>Rose gold</Box>
                                                                        <Box className='product-price'>$12,350.00</Box>
                                                                        <Box className="product-btn-box">
                                                                              <Button className='btn btn-product'>View</Button>
                                                                        </Box>
                                                                  </Box>
                                                            </Box>
                                                      </Box>
                                                      <Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 3", lg: "span 3" }}>
                                                            <Box className='product-box'>
                                                                  <Box className='product-img-box'>
                                                                        <img src={png.pre3} className="product-img"></img>
                                                                        <Box className='show-hover'>
                                                                              <Box className='add-cart-box-hover'>
                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconheart} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>

                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconcart} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>
                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconsearch} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>
                                                                              </Box>
                                                                        </Box>
                                                                  </Box>
                                                                  <Box className='product-content'>
                                                                        <Box className='product-title'>Flower Goldcrop Ring</Box>
                                                                        <Box className='product-sub-title'>Rose gold</Box>
                                                                        <Box className='product-price'>$12,350.00</Box>
                                                                        <Box className="product-btn-box">
                                                                              <Button className='btn btn-product'>View</Button>
                                                                        </Box>
                                                                  </Box>
                                                            </Box>
                                                      </Box>
                                                      <Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 3", lg: "span 3" }}>
                                                            <Box className='product-box'>
                                                                  <Box className='product-img-box'>
                                                                        <img src={png.pre4} className="product-img"></img>
                                                                        <Box className='show-hover'>
                                                                              <Box className='add-cart-box-hover'>
                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconheart} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>

                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconcart} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>
                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconsearch} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>
                                                                              </Box>
                                                                        </Box>
                                                                  </Box>
                                                                  <Box className='product-content'>
                                                                        <Box className='product-title'>Flower Goldcrop Ring</Box>
                                                                        <Box className='product-sub-title'>Rose gold</Box>
                                                                        <Box className='product-price'>$12,350.00</Box>
                                                                        <Box className="product-btn-box">
                                                                              <Button className='btn btn-product'>View</Button>
                                                                        </Box>
                                                                  </Box>
                                                            </Box>
                                                      </Box>
                                                      <Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 3", lg: "span 3" }}>
                                                            <Box className='product-box'>
                                                                  <Box className='product-img-box'>
                                                                        <img src={png.pre5} className="product-img"></img>
                                                                        <Box className='show-hover'>
                                                                              <Box className='add-cart-box-hover'>
                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconheart} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>

                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconcart} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>
                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconsearch} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>
                                                                              </Box>
                                                                        </Box>
                                                                  </Box>
                                                                  <Box className='product-content'>
                                                                        <Box className='product-title'>Flower Goldcrop Ring</Box>
                                                                        <Box className='product-sub-title'>Rose gold</Box>
                                                                        <Box className='product-price'>$12,350.00</Box>
                                                                        <Box className="product-btn-box">
                                                                              <Button className='btn btn-product'>View</Button>
                                                                        </Box>
                                                                  </Box>
                                                            </Box>
                                                      </Box>
                                                      <Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 3", lg: "span 3" }}>
                                                            <Box className='product-box'>
                                                                  <Box className='product-img-box'>
                                                                        <img src={png.pre6} className="product-img"></img>
                                                                        <Box className='show-hover'>
                                                                              <Box className='add-cart-box-hover'>
                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconheart} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>

                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconcart} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>
                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconsearch} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>
                                                                              </Box>
                                                                        </Box>
                                                                  </Box>
                                                                  <Box className='product-content'>
                                                                        <Box className='product-title'>Flower Goldcrop Ring</Box>
                                                                        <Box className='product-sub-title'>Rose gold</Box>
                                                                        <Box className='product-price'>$12,350.00</Box>
                                                                        <Box className="product-btn-box">
                                                                              <Button className='btn btn-product'>View</Button>
                                                                        </Box>
                                                                  </Box>
                                                            </Box>
                                                      </Box>
                                                </Box>
                                          </Box>
                                    </TabPanel>
                                    <TabPanel value={value} index={2} className="myproduct-tabs-panel">
                                          <Box sx={{ width: 1 }}>
                                                <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={{ xs: 0, sm: 5, md: 5, lg: 5 }}>
                                                      <Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 3", lg: "span 3" }}>
                                                            <Box className='product-box'>
                                                                  <Box className='product-img-box'>
                                                                        <img src={png.pre1} className="product-img"></img>
                                                                        <Box className='show-hover'>
                                                                              <Box className='add-cart-box-hover'>
                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconheart} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>

                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconcart} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>
                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconsearch} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>
                                                                              </Box>
                                                                        </Box>
                                                                  </Box>
                                                                  <Box className='product-content'>
                                                                        <Box className='product-title'>Flower Goldcrop Ring</Box>
                                                                        <Box className='product-sub-title'>Rose gold</Box>
                                                                        <Box className='product-price'>$12,350.00</Box>
                                                                        <Box className="product-btn-box">
                                                                              <Button className='btn btn-product'>View</Button>
                                                                        </Box>
                                                                  </Box>
                                                            </Box>
                                                      </Box>
                                                      <Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 3", lg: "span 3" }}>
                                                            <Box className='product-box'>
                                                                  <Box className='product-img-box'>
                                                                        <img src={png.pre2} className="product-img"></img>
                                                                        <Box className='show-hover'>
                                                                              <Box className='add-cart-box-hover'>
                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconheart} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>

                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconcart} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>
                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconsearch} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>
                                                                              </Box>
                                                                        </Box>
                                                                  </Box>
                                                                  <Box className='product-content'>
                                                                        <Box className='product-title'>Flower Goldcrop Ring</Box>
                                                                        <Box className='product-sub-title'>Rose gold</Box>
                                                                        <Box className='product-price'>$12,350.00</Box>
                                                                        <Box className="product-btn-box">
                                                                              <Button className='btn btn-product'>View</Button>
                                                                        </Box>
                                                                  </Box>
                                                            </Box>
                                                      </Box>
                                                      <Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 3", lg: "span 3" }}>
                                                            <Box className='product-box'>
                                                                  <Box className='product-img-box'>
                                                                        <img src={png.pre3} className="product-img"></img>
                                                                        <Box className='show-hover'>
                                                                              <Box className='add-cart-box-hover'>
                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconheart} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>

                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconcart} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>
                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconsearch} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>
                                                                              </Box>
                                                                        </Box>
                                                                  </Box>
                                                                  <Box className='product-content'>
                                                                        <Box className='product-title'>Flower Goldcrop Ring</Box>
                                                                        <Box className='product-sub-title'>Rose gold</Box>
                                                                        <Box className='product-price'>$12,350.00</Box>
                                                                        <Box className="product-btn-box">
                                                                              <Button className='btn btn-product'>View</Button>
                                                                        </Box>
                                                                  </Box>
                                                            </Box>
                                                      </Box>
                                                      <Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 3", lg: "span 3" }}>
                                                            <Box className='product-box'>
                                                                  <Box className='product-img-box'>
                                                                        <img src={png.pre4} className="product-img"></img>
                                                                        <Box className='show-hover'>
                                                                              <Box className='add-cart-box-hover'>
                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconheart} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>

                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconcart} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>
                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconsearch} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>
                                                                              </Box>
                                                                        </Box>
                                                                  </Box>
                                                                  <Box className='product-content'>
                                                                        <Box className='product-title'>Flower Goldcrop Ring</Box>
                                                                        <Box className='product-sub-title'>Rose gold</Box>
                                                                        <Box className='product-price'>$12,350.00</Box>
                                                                        <Box className="product-btn-box">
                                                                              <Button className='btn btn-product'>View</Button>
                                                                        </Box>
                                                                  </Box>
                                                            </Box>
                                                      </Box>
                                                      <Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 3", lg: "span 3" }}>
                                                            <Box className='product-box'>
                                                                  <Box className='product-img-box'>
                                                                        <img src={png.pre5} className="product-img"></img>
                                                                        <Box className='show-hover'>
                                                                              <Box className='add-cart-box-hover'>
                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconheart} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>

                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconcart} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>
                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconsearch} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>
                                                                              </Box>
                                                                        </Box>
                                                                  </Box>
                                                                  <Box className='product-content'>
                                                                        <Box className='product-title'>Flower Goldcrop Ring</Box>
                                                                        <Box className='product-sub-title'>Rose gold</Box>
                                                                        <Box className='product-price'>$12,350.00</Box>
                                                                        <Box className="product-btn-box">
                                                                              <Button className='btn btn-product'>View</Button>
                                                                        </Box>
                                                                  </Box>
                                                            </Box>
                                                      </Box>
                                                      <Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 3", lg: "span 3" }}>
                                                            <Box className='product-box'>
                                                                  <Box className='product-img-box'>
                                                                        <img src={png.pre6} className="product-img"></img>
                                                                        <Box className='show-hover'>
                                                                              <Box className='add-cart-box-hover'>
                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconheart} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>

                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconcart} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>
                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconsearch} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>
                                                                              </Box>
                                                                        </Box>
                                                                  </Box>
                                                                  <Box className='product-content'>
                                                                        <Box className='product-title'>Flower Goldcrop Ring</Box>
                                                                        <Box className='product-sub-title'>Rose gold</Box>
                                                                        <Box className='product-price'>$12,350.00</Box>
                                                                        <Box className="product-btn-box">
                                                                              <Button className='btn btn-product'>View</Button>
                                                                        </Box>
                                                                  </Box>
                                                            </Box>
                                                      </Box>
                                                      <Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 3", lg: "span 3" }}>
                                                            <Box className='product-box'>
                                                                  <Box className='product-img-box'>
                                                                        <img src={png.pre1} className="product-img"></img>
                                                                        <Box className='show-hover'>
                                                                              <Box className='add-cart-box-hover'>
                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconheart} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>

                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconcart} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>
                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconsearch} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>
                                                                              </Box>
                                                                        </Box>
                                                                  </Box>
                                                                  <Box className='product-content'>
                                                                        <Box className='product-title'>Flower Goldcrop Ring</Box>
                                                                        <Box className='product-sub-title'>Rose gold</Box>
                                                                        <Box className='product-price'>$12,350.00</Box>
                                                                        <Box className="product-btn-box">
                                                                              <Button className='btn btn-product'>View</Button>
                                                                        </Box>
                                                                  </Box>
                                                            </Box>
                                                      </Box>
                                                      <Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 3", lg: "span 3" }}>
                                                            <Box className='product-box'>
                                                                  <Box className='product-img-box'>
                                                                        <img src={png.pre2} className="product-img"></img>
                                                                        <Box className='show-hover'>
                                                                              <Box className='add-cart-box-hover'>
                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconheart} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>

                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconcart} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>
                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconsearch} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>
                                                                              </Box>
                                                                        </Box>
                                                                  </Box>
                                                                  <Box className='product-content'>
                                                                        <Box className='product-title'>Flower Goldcrop Ring</Box>
                                                                        <Box className='product-sub-title'>Rose gold</Box>
                                                                        <Box className='product-price'>$12,350.00</Box>
                                                                        <Box className="product-btn-box">
                                                                              <Button className='btn btn-product'>View</Button>
                                                                        </Box>
                                                                  </Box>
                                                            </Box>
                                                      </Box>
                                                      <Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 3", lg: "span 3" }}>
                                                            <Box className='product-box'>
                                                                  <Box className='product-img-box'>
                                                                        <img src={png.pre3} className="product-img"></img>
                                                                        <Box className='show-hover'>
                                                                              <Box className='add-cart-box-hover'>
                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconheart} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>

                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconcart} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>
                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconsearch} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>
                                                                              </Box>
                                                                        </Box>
                                                                  </Box>
                                                                  <Box className='product-content'>
                                                                        <Box className='product-title'>Flower Goldcrop Ring</Box>
                                                                        <Box className='product-sub-title'>Rose gold</Box>
                                                                        <Box className='product-price'>$12,350.00</Box>
                                                                        <Box className="product-btn-box">
                                                                              <Button className='btn btn-product'>View</Button>
                                                                        </Box>
                                                                  </Box>
                                                            </Box>
                                                      </Box>
                                                      <Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 3", lg: "span 3" }}>
                                                            <Box className='product-box'>
                                                                  <Box className='product-img-box'>
                                                                        <img src={png.pre4} className="product-img"></img>
                                                                        <Box className='show-hover'>
                                                                              <Box className='add-cart-box-hover'>
                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconheart} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>

                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconcart} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>
                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconsearch} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>
                                                                              </Box>
                                                                        </Box>
                                                                  </Box>
                                                                  <Box className='product-content'>
                                                                        <Box className='product-title'>Flower Goldcrop Ring</Box>
                                                                        <Box className='product-sub-title'>Rose gold</Box>
                                                                        <Box className='product-price'>$12,350.00</Box>
                                                                        <Box className="product-btn-box">
                                                                              <Button className='btn btn-product'>View</Button>
                                                                        </Box>
                                                                  </Box>
                                                            </Box>
                                                      </Box>
                                                      <Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 3", lg: "span 3" }}>
                                                            <Box className='product-box'>
                                                                  <Box className='product-img-box'>
                                                                        <img src={png.pre5} className="product-img"></img>
                                                                        <Box className='show-hover'>
                                                                              <Box className='add-cart-box-hover'>
                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconheart} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>

                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconcart} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>
                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconsearch} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>
                                                                              </Box>
                                                                        </Box>
                                                                  </Box>
                                                                  <Box className='product-content'>
                                                                        <Box className='product-title'>Flower Goldcrop Ring</Box>
                                                                        <Box className='product-sub-title'>Rose gold</Box>
                                                                        <Box className='product-price'>$12,350.00</Box>
                                                                        <Box className="product-btn-box">
                                                                              <Button className='btn btn-product'>View</Button>
                                                                        </Box>
                                                                  </Box>
                                                            </Box>
                                                      </Box>
                                                      <Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 3", lg: "span 3" }}>
                                                            <Box className='product-box'>
                                                                  <Box className='product-img-box'>
                                                                        <img src={png.pre6} className="product-img"></img>
                                                                        <Box className='show-hover'>
                                                                              <Box className='add-cart-box-hover'>
                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconheart} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>

                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconcart} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>
                                                                                    <Button className='btn hover-btns'>
                                                                                          <Box className='add-cart-bg'>
                                                                                                <img src={svg.iconsearch} className="hover-img"></img>
                                                                                          </Box>
                                                                                    </Button>
                                                                              </Box>
                                                                        </Box>
                                                                  </Box>
                                                                  <Box className='product-content'>
                                                                        <Box className='product-title'>Flower Goldcrop Ring</Box>
                                                                        <Box className='product-sub-title'>Rose gold</Box>
                                                                        <Box className='product-price'>$12,350.00</Box>
                                                                        <Box className="product-btn-box">
                                                                              <Button className='btn btn-product'>View</Button>
                                                                        </Box>
                                                                  </Box>
                                                            </Box>
                                                      </Box>
                                                </Box>
                                          </Box>
                                    </TabPanel>
                              </Box>
                              <Box className='row'>
                                    <Box sx={{ width: 1 }}>
                                          <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={{ xs: 0, sm: 0, md: 5, lg: 5 }}>
                                                <Box gridColumn={{ xs: "span 12", sm: "span 12", md: "span 12", lg: "span 12" }}>
                                                      <Box className='pagination-box precious-pagination'>
                                                            <Pagination count={3} variant="outlined" shape="rounded" className='pagination' />
                                                      </Box>
                                                </Box>
                                          </Box>
                                    </Box>
                              </Box>
                        </Box>
                  </Box>
                  <Footer />
            </div>
      )
}

export default PreciousStones