import React from "react";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import svg from "../../../assets/svg";
import png from "../../../assets/png";
import Typography from "@mui/material/Typography";
import { Image } from "@mui/icons-material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { useHistory } from "react-router-dom";
// import Link from "@mui/material/Link";
import { Link } from "react-router-dom";
// import svg from "../../../assets/images/svg";

const Item = styled(Paper)(({ theme }) => ({}));

const Footer = () => {
  const history = useHistory();

  return (
    <div>
      <Box className="footer-sec">
        <Box className="container">
          <Box sx={{ width: 1 }}>
            <Box
              display="grid"
              gridTemplateColumns="repeat(12, 1fr)"
              className="grid"
              gap={{ xs: 3, sm: 5, md: 10, lg: 13 }}
            >
              <Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 3",
                  lg: "span 3",
                }}
                className="grid-colum"
              >
                <Item className="footer-items">
                  <Box className="footer-colum">
                    <Box className="footer-logo-box">
                      <img
                        src={png.footerlogo}
                        className="footer-logo-img"
                      ></img>
                    </Box>
                    <Box className="footer-info-box">
                      <img
                        src={svg.footercall}
                        className="footer-info-img"
                      ></img>
                      202-555-0172
                    </Box>
                    <Box className="footer-info-box">
                      <img
                        src={svg.footerlocation}
                        className="footer-info-img"
                      ></img>
                      34 W 4425 S, Salt Lake City, ut, 84128 United States
                    </Box>
                  </Box>
                </Item>
              </Box>
              <Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 3",
                  lg: "span 3",
                }}
                className="grid-colum"
              >
                <Item className="footer-items">
                  <Box className="footer-colum">
                    <Box className="footer-title">Company</Box>
                  </Box>
                  <Box className="footer-ul-main">
                    <List className="footer-ul">
                    <Link onClick={() => history.push("/user/return-policy")}>
                        <ListItem className="footer-li">
                          <Typography className="footer-li">
                            Return Policy
                          </Typography>
                        </ListItem>
                      </Link>
                     
                    <Link onClick={() => history.push("/user/terms-conditions")}>
                        <ListItem className="footer-li">
                          <Typography className="footer-li">
                            Terms & Conditions
                          </Typography>
                        </ListItem>
                      </Link>
                    <Link onClick={() => history.push("/user/privacy-policy")}>
                     
                        <ListItem className="footer-li">
                          <Typography className="footer-li">
                            Privacy Policy
                          </Typography>
                        </ListItem>
                      </Link>
                      
                    <Link onClick={() => history.push("/user/customerservices")}>
                        <ListItem className="footer-li">
                          <Typography className="footer-li">
                            Customer Service
                          </Typography>
                        </ListItem>
                      </Link>
                    <Link onClick={() => history.push("/user/contact-us")}>
                    
                        <ListItem className="footer-li">
                          <Typography className="footer-li">
                            Contact Us
                          </Typography>
                        </ListItem>
                      </Link>
                    </List>
                  </Box>
                </Item>
              </Box>
              <Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 3",
                  lg: "span 3",
                }}
                className="grid-colum"
              >
                <Item className="footer-items">
                  <Box className="footer-colum">
                    <Box className="footer-title">Help</Box>
                  </Box>
                  <Box className="footer-ul-main">
                    <List className="footer-ul">
                    <Link onClick={() => history.push("/user/privacy-policy")}>
                        <ListItem className="footer-li">
                          <Typography className="footer-li">
                            Payments
                          </Typography>
                        </ListItem>
                      </Link>
                      <Link onClick={() => history.push("/user/privacy-policy")}>
                        <ListItem className="footer-li">
                          <Typography className="footer-li">
                            Shipping
                          </Typography>
                        </ListItem>
                      </Link>
                      <Link onClick={() => history.push("/user/privacy-policy")}>
                        <ListItem className="footer-li">
                          <Typography className="footer-li">
                            Cancellation & Returns
                          </Typography>
                        </ListItem>
                      </Link>
                      <Link onClick={() => history.push("/user/faq")}>
                        <ListItem className="footer-li">
                          <Typography className="footer-li">FAQs</Typography>
                        </ListItem>
                      </Link>
                    </List>
                  </Box>
                </Item>
              </Box>
              {/* <Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 3",
                  lg: "span 3",
                }}
                className="grid-colum"
              >
                <Item className="footer-items">
                  <Box className="footer-colum">
                    <Box className="footer-title">Useful Links</Box>
                  </Box>
                  <Box className="footer-ul-main">
                    <List className="footer-ul">
                      <Link to="#">
                        <ListItem className="footer-li">
                          <Typography className="footer-li">Link1</Typography>
                        </ListItem>
                      </Link>
                      <Link to="#">
                        <ListItem className="footer-li">
                          <Typography className="footer-li">Link2</Typography>
                        </ListItem>
                      </Link>
                      <Link to="#">
                        <ListItem className="footer-li">
                          <Typography className="footer-li">Link3</Typography>
                        </ListItem>
                      </Link>
                    </List>
                  </Box>
                </Item>
              </Box> */}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className="footer-copyright-sec">
        <Box className="footer-copyright-text-box">
          <Typography className="footer-copyright-text">
            All Right Reserved
          </Typography>
        </Box>
      </Box>
    </div>
  );
};

export default Footer;