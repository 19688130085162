import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import {
  tableCellClasses,
  TableCell,
  Typography,
  Button,
  Container,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Table,
  TableBody,
  Box,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import { Api } from '../../../../config/api';
import dataService from '../../../../config/dataService';
import CommonButton from '../../../../component/customComponents/buttons/CommonButton';
import { toast } from 'react-toastify';

import Loader from '../../../../component/customComponents/loader/Loader';
//#region ALL HOOKS AND VARIABALS

import AdminIndex from '../../AdminIndex';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const { listCustomerServices, getAllCMS } = AdminIndex.AdminSagaActions;
export default function ContentManagement() {
  let token = localStorage.getItem('auth');
  const history = useHistory();
  const dispatch = AdminIndex.useDispatch();
  const [getData, setGetData] = useState([]);
  const [getTempData, setGetTempData] = useState();
  const [idArray, setIdArray] = useState('');

  const [getCMS, setGetCMS] = useState('');
  const [tAndcId, setTAndcId] = useState('');
  const [pAndPId, setPAndPId] = useState('');
  const [returnPolicyId, setReturnPolicyId] = useState('');
  const [customerServicesId, setCustomerServicesId] = useState({});

  // const isKeyInArray = (array, key) => {
  //   return array.some((obj) => obj.hasOwnProperty(key !==''));
  // };

  const allCMS = AdminIndex.useSelector((state) => {
    return state.AdminSaga.allCMS;
  });
  const returnPolicyAddBtn = (
    <CommonButton
      display={(allCMS?.return_policy !== "") && 'none'}
      name="Add"
      onClick={() =>
        history.push('/admin/contentmanagement/returnpolicy')
      }
    />
  );
  const tAndCAddBtn = (
    <CommonButton
    display={(allCMS?.terms_condition !=="") && 'none'}
      name="Add"
      onClick={() =>
        history.push('/admin/contentmanagement/addtermsandconditions')
      }
    />
  );
  const privacyPolicyAddBtn = (
    <CommonButton
    display={(allCMS?.privacy_policy !=="") && 'none'}
      name="Add"
      onClick={() =>
        history.push('/admin/contentmanagement/addprivacypolicy')
      }
    />
  );
  const btn2 = <CommonButton name="Add" />;
  const faqAddBtn = (
    <CommonButton
      name="Add"
      onClick={() => history.push('/admin/contentmanagement/faq')}
    />
  );
  const aboutUsAddBtn = (
    <CommonButton
      display={(allCMS?.about_us_description !=="") && 'none'}
      name="Add"
      onClick={() => history.push('/admin/contentmanagement/addaboutus')}
    />
  );

  const btn5 = <CommonButton name="Add" />;
  const btn6 = <CommonButton name="Add" />;
  const btn7 = (
    <CommonButton name="Add" onClick={() => history.push('/admin/blogs')} />
  );

  const customerServicesAddBtn = (
    <CommonButton
      display={customerServicesId && 'none'}
      name="Add"
      onClick={() =>
        history.push('/admin/contentmanagement/addcustomerservices')
      }
    />
  );

  const topDealsAddBtn = (
    <CommonButton
      name="Add"
      onClick={() => history.push('/admin/contentmanagement/topdeals')}
    />
  );

  const membershipAddBtn = (
    <CommonButton
      name="Add"
      onClick={() => history.push('/admin/membership')}
    />
  );


  const aboutUsEditBtn = (
    <CommonButton
      display={(allCMS?.about_us_description == "") && 'none'}
      name="Edit"
      onClick={() =>
        history.push(`/admin/contentmanagement/updateaboutus/${allCMS?._id}`)
      }
    />
  );

  
  const returnPolicyEditBtn = (
    <CommonButton
      display={(allCMS?.return_policy =='') && 'none'}
      name="Edit"
      onClick={() =>
        history.push(
          `/admin/contentmanagement/updatereturnpolicy/${allCMS?._id}`
        )
      }
    />
  );
  const tAndCEditBtn = (
    <CommonButton
      display={allCMS?.terms_condition =="" && 'none'}
      name="Edit"
      onClick={() =>
        history.push(
          `/admin/contentmanagement/updatetermsandconditions/${allCMS?._id}`
        )
      }
    />
  );
  const customerServicesEditBtn = (
    <CommonButton
      display={!customerServicesId && 'none'}
      name="Edit"
      onClick={() =>
        history.push(
          `/admin/contentmanagement/updatecustomerservices/${customerServicesId}`
        )
      }
    />
  );
  const privacyPolicyEditBtn = (
    <CommonButton
      display={allCMS?.privacy_policy =="" && 'none'}
      name="Edit"
      onClick={() =>
        history.push(
          `/admin/contentmanagement/updateprivacypolicy/${allCMS?._id}`
        )
      }
    />
  );


  function createData(content, addBtn, editBtn) {
    return { content, addBtn, editBtn };
  }

  const rows = [
    createData('Return Policy', returnPolicyAddBtn, returnPolicyEditBtn),
    createData('Terms & Conditions', tAndCAddBtn, tAndCEditBtn),
    createData('Privacy Policy', privacyPolicyAddBtn,privacyPolicyEditBtn),
    createData('FAQs', faqAddBtn),
    createData('About Us', aboutUsAddBtn, aboutUsEditBtn),
    createData('Website Information', btn5),
    createData('Social Media', btn6),
    createData('Blogs', btn7),
    createData(
      'Coustomer services',
      customerServicesAddBtn,
      customerServicesEditBtn
    ),
    createData('Top Deals', topDealsAddBtn),
    createData('Membership', membershipAddBtn),
  ];

  useEffect(() => {
    getAboutUsData();
    getCustomerServicesId();
  }, []);

  //#endregion

  //#region ALL EVENT HANDLERS AND  FUCTIONS

  const getCustomerServicesId = async () => {
    let urlencoded = new URLSearchParams();
    try {
      
      dispatch(listCustomerServices(token,urlencoded));
      // const Data = await dataService.post(
      //   Api.Admin.listCustomerServices,
      //   urlencoded,
      //   {
      //     headers: {
      //       auth: token,
      //     },
      //   }
      // );
      // setCustomerServicesId(Data?.data?.data?.[0]?._id);
    } catch (err) {
      toast.error(err?.message || 'Something went wrong');
    }
  };

  const loading = AdminIndex.useSelector((state) => {
    return state.AdminSaga.loading;
  });

  const customerServices = AdminIndex.useSelector((state) => {
    return state.AdminSaga.customerServices;
  });

  useEffect(() => {
    setCustomerServicesId(customerServices?.[0]?._id);
  }, [customerServices]);

  const getAboutUsData = async (e) => {
    let formdata = new URLSearchParams();
    try {
      dispatch(getAllCMS(token, formdata));
      // const Data = await dataService.post(Api.Admin.getAllCms, formdata);

      // setGetData(Data?.data?.data);
      // setGetTempData(Data.data.data);
    } catch (err) {
      toast.error(err?.message || 'Something went wrong');
    }
  };



  useEffect(() => {
    setGetData(allCMS);
    setGetTempData(allCMS);
  }, [allCMS]);

  // useEffect(() => {
  //   filter();
  // }, [getData, getTempData]);

  // const filter = () => {
  //   let filterData = getTempData.filter((Data) => {
  //     console.log(Data,'nirmal123');
  //     setGetCMS(Data);
  //     // if (Object.getOwnPropertyNames(Data).includes('about_us')) {
  //     //   setAboutUsId(Data._id);
  //     // }
  //     // if (Object.getOwnPropertyNames(Data).includes('terms_condition')) {
  //     //   setTAndcId(Data._id);
  //     // }
  //     // if (Object.getOwnPropertyNames(Data).includes('privacy_policy')) {
  //     //   setPAndPId(Data._id);
  //     // }
  //     // if (Object.getOwnPropertyNames(Data).includes('return_policy')) {
  //     //   setReturnPolicyId(Data._id);
  //     // }
  //   });
  //   return setIdArray(filterData);
  // };
  //#endregion

  //#region RETURN JSX
  return (
    <>{loading ? (
      <Loader />
    ) : (
      <Container maxWidth="false" sx={{ mb: 5 }}>
        <Typography
          sx={{ my: 2, fontWeight: 'bold' }}
          variant="h4"
          align="center"
        >
          Content Management
        </Typography>
        <TableContainer
          elevation={0}
          sx={{
            mt: 8,
            maxWidth: {
              xs: '365px',
              sm: '600px',
              md: '100%',
              lg: '100%',
            },
            '&::-webkit-scrollbar': {
              width: 10,
            },
            '&::-webkit-scrollbar': {
              height: '8px',
              width: '12px',
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: '#fff',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(224, 224, 224, 1)',
              borderRadius: '30px',
              width: '20px',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              background: '#B0B0B0',
            },
          }}
          component={Paper}
        >
          <Table>
            <TableHead>
              <TableRow sx={{ whiteSpace: 'nowrap' }}>
                <StyledTableCell
                  colSpan={2}
                  align="left"
                  sx={{ fontWeight: 'bold', fontSize: '25px' }}
                >
                  Content
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <StyledTableRow key={row.name} sx={{ whiteSpace: 'nowrap' }}>
                  <StyledTableCell align="left">{row.content}</StyledTableCell>
                  <StyledTableCell align="right">
                    {row.addBtn}
                    {row.editBtn}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
      )}
    </>
  );
}
//#endregion
