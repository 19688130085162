import React, { useEffect, useState } from "react";
import UserIndex from "../../UserIndex";
import { price } from "../../../../component/customComponents/commonFunctions/CommonFunctions";

const { GetSingleCustomProductSaga } = UserIndex.UserSagaActions;

const ViewCoustomeProductData = () => {
  const [customeProduct, setCustomeProduct] = useState("");
  const [material, setMaterial] = useState();
  const [dataUser, setDataUser] = useState("");
  let params = UserIndex.useParams();
  const dispatch = UserIndex.useDispatch();
  const navigate = UserIndex.useHistory();
  const history = UserIndex.useHistory();
  const loding = UserIndex.useSelector((state) => {
    return state.Usersaga.loading;
  });
  const Token = UserIndex.useSelector((state) => {
    return state.Usersaga.Token;
  });
  const Userdata = UserIndex.useSelector((state) => {
    return state.Usersaga.Userdata;
  });
  const GetSingleCustomProductData = UserIndex.useSelector((state) => {
    return state.Usersaga.GetSingleCustomProductData;
  });

  const ProductData = () => {
    let urlencoded = new URLSearchParams();
    urlencoded.append("id", params?.custom_id);
    dispatch(GetSingleCustomProductSaga(urlencoded, navigate, Token));
  };

  useEffect(() => {
    ProductData();
  }, []);

  useEffect(() => {
    setCustomeProduct(GetSingleCustomProductData);
  }, [GetSingleCustomProductData]);

  useEffect(() => {
    setDataUser(Userdata);
  }, [Userdata]);

  useEffect(() => {
    let arr = [];
    {
      customeProduct?.materialIdArray?.map((item) => {
        arr.push(item?.material_id?.material);
      });
    }

    setMaterial(arr);
  }, [customeProduct]);

  const goldFields = () => {
    return (
      <>
        {customeProduct?.gold?.length
          ? customeProduct?.gold?.map((gold) => {
              return (
                <UserIndex.Typography
                  variant="h6"
                  component="h6"
                  className="weight-text"
                >
                  Gold Wt(gm) :{gold.weight}g
                </UserIndex.Typography>
              );
            })
          : ""}
      </>
    );
  };

  const silverFields = () => {
    return (
      <>
        {customeProduct?.silver?.length
          ? customeProduct?.silver?.map((silver) => {
              return (
                <UserIndex.Typography
                  variant="h6"
                  component="h6"
                  className="weight-text"
                >
                  Silver Wt(gm) :{silver?.weight}g
                </UserIndex.Typography>
              );
            })
          : ""}
      </>
    );
  };

  const gemstoneFields = () => {
    return (
      <>
        {customeProduct?.gemstone?.length
          ? customeProduct?.gemstone?.map((gems) => {
              return (
                <UserIndex.Typography
                  variant="h6"
                  component="h6"
                  className="weight-text"
                >
                  Gemstone Wt(gm) :{gems?.total_weight}g
                </UserIndex.Typography>
              );
            })
          : ""}
      </>
    );
  };

  const diamondFields = () => {
    return (
      <>
        {customeProduct?.diamond?.length
          ? customeProduct?.diamond?.map((diam) => {
              return (
                <UserIndex.Typography
                  variant="h6"
                  component="h6"
                  className="weight-text"
                >
                  Diamond Wt(gm) :{diam.total_weight}g
                </UserIndex.Typography>
              );
            })
          : ""}
      </>
    );
  };

  const renderFieldMaterialWise = () => {
    switch (true) {
      case customeProduct?.gold?.length > 0 &&
        customeProduct?.diamond?.length > 0 &&
        customeProduct?.materialIdArray?.length === 2:
        return (
          <>
            {goldFields()}
            {diamondFields()}
          </>
        );
      case customeProduct?.gold?.length > 0 &&
        customeProduct?.gemstone?.length > 0 &&
        customeProduct?.materialIdArray?.length === 2:
        return (
          <>
            {goldFields()}
            {gemstoneFields()}
          </>
        );
      case customeProduct?.silver?.length > 0 &&
        customeProduct?.diamond?.length > 0 &&
        customeProduct?.materialIdArray?.length === 2:
        return (
          <>
            {silverFields()}
            {diamondFields()}
          </>
        );
      case customeProduct?.silver?.length > 0 &&
        customeProduct?.gemstone?.length > 0 &&
        customeProduct?.materialIdArray?.length === 2:
        return (
          <>
            {silverFields()}
            {gemstoneFields()}
          </>
        );
      case customeProduct?.silver?.length > 0 &&
        customeProduct?.gold?.length > 0 &&
        customeProduct?.materialIdArray?.length === 2:
        return (
          <>
            {silverFields()}
            {goldFields()}
          </>
        );
      case customeProduct?.gemstone?.length > 0 &&
        customeProduct?.diamond?.length > 0 &&
        customeProduct?.materialIdArray?.length === 2:
        return (
          <>
            {gemstoneFields()}
            {diamondFields()}
          </>
        );
      case customeProduct?.gold?.length > 0 &&
        customeProduct?.silver?.length > 0 &&
        customeProduct?.diamond?.length > 0 &&
        customeProduct?.materialIdArray?.length === 3:
        return (
          <>
            {silverFields()}
            {goldFields()}
            {diamondFields()}
          </>
        );
      case customeProduct?.silver?.length > 0 &&
        customeProduct?.gold?.length > 0 &&
        customeProduct?.gemstone?.length > 0 &&
        customeProduct?.materialIdArray?.length === 3:
        return (
          <>
            {silverFields()}
            {goldFields()}
            {gemstoneFields()}
          </>
        );
      case customeProduct?.gold?.length > 0 &&
        customeProduct?.diamond?.length > 0 &&
        customeProduct?.gemstone?.length > 0 &&
        customeProduct?.materialIdArray?.length === 3:
        return (
          <>
            {goldFields()}
            {diamondFields()}
            {gemstoneFields()}
          </>
        );
      case customeProduct?.silver?.length > 0 &&
        customeProduct?.diamond?.length > 0 &&
        customeProduct?.gemstone?.length > 0 &&
        customeProduct?.materialIdArray?.length === 3:
        return (
          <>
            {silverFields()}
            {gemstoneFields()}
            {diamondFields()}
          </>
        );
      case customeProduct?.gold?.length > 0 &&
        customeProduct?.silver?.length > 0 &&
        customeProduct?.gemstone?.length > 0 &&
        customeProduct?.diamond?.length > 0 &&
        customeProduct?.materialIdArray?.length === 4:
        return (
          <>
            {goldFields()}
            {silverFields()}
            {gemstoneFields()}
            {diamondFields()}
          </>
        );
      case customeProduct?.gold?.length > 0:
        return goldFields();
      case customeProduct?.silver?.length > 0:
        return silverFields();
      case customeProduct?.diamond?.length > 0:
        return diamondFields();
      case customeProduct?.diamond?.length > 0:
        return gemstoneFields();
      default:
        return "";
    }
  };


  return (
    <div>
      <UserIndex.Header />
      <UserIndex.Box className="rings1-flower-sec">
        <UserIndex.Box className="container">
          <UserIndex.Box sx={{ width: 1 }}>
            <UserIndex.Box
              display="grid"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 0, sm: 5, md: 10, lg: 5 }}
            >
              <UserIndex.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 6",
                }}
              >
                <UserIndex.Box className="rings-left-banner-box">
                  <img
                    src={customeProduct?.image?.[0]}
                    className="rings-left-banner-img"
                  ></img>
                </UserIndex.Box>
                <UserIndex.Box className="rings1-images-main">
                  {customeProduct?.image?.map((element) => (
                    <UserIndex.Box
                      sx={{ display: "flex" }}
                      className="rings1-img-box"
                    >
                      <img src={element} className="rings1-img"></img>
                    </UserIndex.Box>
                  ))}
                </UserIndex.Box>
              </UserIndex.Box>
              <UserIndex.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 6",
                }}
              >
                <UserIndex.Box className="flower-text-box">
                  <UserIndex.Typography
                    variant="h4"
                    component="h4"
                    className="flower-title"
                  >
                    {customeProduct?.product_dimension?.meta_title}
                  </UserIndex.Typography>

                  <UserIndex.Typography
                    variant="h6"
                    component="h6"
                    className="rosegold-text"
                  >
                    {material?.toString()}
                  </UserIndex.Typography>

                  {renderFieldMaterialWise()}

                  <UserIndex.Typography
                    variant="h6"
                    component="h6"
                    className="description-text"
                  >
                    Description
                  </UserIndex.Typography>

                  <UserIndex.Typography
                    variant="p"
                    component="p"
                    className="description-para mb-26"
                  >
                    {customeProduct?.product_dimension?.meta_description}
                  </UserIndex.Typography>

                  <UserIndex.Box>
                    {customeProduct.status == "0" ? (
                      <UserIndex.Typography
                        variant="p"
                        component="p"
                        className="description-para-Pending mb-26"
                      >
                        Your Custom Product Is Pending.
                      </UserIndex.Typography>
                    ) : customeProduct.status == "1" ? (
                      <UserIndex.Typography
                        variant="p"
                        component="p"
                        className="description-para-Approved mb-26"
                      >
                        Your Custom Product Is Approved By Admin.
                      </UserIndex.Typography>
                    ) : customeProduct.status == "2" ? (
                      <UserIndex.Typography
                        variant="p"
                        component="p"
                        className="description-para-Rejected mb-26"
                      >
                        Your Custom Product Is Rejected By Admin.
                      </UserIndex.Typography>
                    ) : (
                      ""
                    )}
                  </UserIndex.Box>
                </UserIndex.Box>
              </UserIndex.Box>
            </UserIndex.Box>
          </UserIndex.Box>
        </UserIndex.Box>
      </UserIndex.Box>
      <UserIndex.ProductDescription data={customeProduct} />

      {customeProduct?.sellers?.length > 0 ? (
        <UserIndex.Box className="seller-details-custome-product-view-main-div">
          <UserIndex.Box className="mo-table-main seller-details-custome-product-view">
            <UserIndex.Box className="product-desc-title-box">
              <UserIndex.Typography
                variant="h4"
                component="h4"
                className="product-desc-title"
              >
                Seller Details
              </UserIndex.Typography>
            </UserIndex.Box>
            <UserIndex.TableContainer
              component={UserIndex.Paper}
              className="mo-table-container"
            >
              <UserIndex.Table
                sx={{ minWidth: 650 }}
                aria-label="simple table"
                className="mo-table"
              >
                <UserIndex.TableHead className="mo-tablehead">
                  <UserIndex.TableRow>
                    <UserIndex.TableCell className="th-recieved">
                      Brand Image
                    </UserIndex.TableCell>
                    <UserIndex.TableCell className="th-recieved">
                      Company Name
                    </UserIndex.TableCell>
                    <UserIndex.TableCell className="th-recieved">
                      Seller Name
                    </UserIndex.TableCell>
                    <UserIndex.TableCell className="th-recieved">
                      Price
                    </UserIndex.TableCell>
                    <UserIndex.TableCell className="th-recieved">
                      Place Order
                    </UserIndex.TableCell>
                  </UserIndex.TableRow>
                </UserIndex.TableHead>
                <UserIndex.TableBody className="mo-table-body">
                  {customeProduct?.sellers?.length
                    ? customeProduct?.sellers?.map((item) => {
                        let data = {
                          item: item,
                          customeProductId: customeProduct?._id,
                        };
                        return (
                          <UserIndex.TableRow
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <UserIndex.TableCell
                              component="td"
                              scope="row"
                              className="td-recieved"
                            >
                              <img
                                src={item?.seller_id?.uploadBrandImage}
                                className="custome-img"
                              ></img>
                            </UserIndex.TableCell>
                            <UserIndex.TableCell
                              component="td"
                              className="td-recieved"
                            >
                              {item?.seller_id?.company_name}
                            </UserIndex.TableCell>
                            <UserIndex.TableCell
                              component="td"
                              className="td-recieved"
                            >
                              {item?.seller_id?.owner_name}
                            </UserIndex.TableCell>
                            <UserIndex.TableCell
                              component="td"
                              className="td-recieved success-text"
                            >
                              {price(item?.total_payable_amount)}
                            </UserIndex.TableCell>
                            <UserIndex.TableCell
                              component="td"
                              className="td-recieved"
                            >
                              <UserIndex.Box className="primary-btn-box ">
                                <UserIndex.PrimaryButton
                                  onClick={() => {
                                    history.push(
                                      `/user/paymentmethoddata/${window.btoa(
                                        JSON.stringify(data)
                                      )}`
                                    );
                                  }}
                                  btnclass="primary-btn view-custome-order-place-button"
                                  buttonvalue="Place Order"
                                />
                              </UserIndex.Box>
                            </UserIndex.TableCell>
                          </UserIndex.TableRow>
                        );
                      })
                    : ""}
                </UserIndex.TableBody>
              </UserIndex.Table>
            </UserIndex.TableContainer>
          </UserIndex.Box>
        </UserIndex.Box>
      ) : (
        ""
      )}

      <UserIndex.Footer />
    </div>
  );
};

export default ViewCoustomeProductData;
