import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { Button, Typography, CircularProgress } from "@mui/material";
import svg from "../../../assets/svg";
import png from "../../../assets/png";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import IconButton from "@mui/material/IconButton";

const Searchbar = () => {
  const [age, setAge] = useState("");
  const [searchData, setSearchData] = useState("");
  const navigate = useHistory();
  const history = useHistory();
  const dispatch = useDispatch();

  const FilterMaterialAndItemData = useSelector((state) => {
    return state.Usersaga.FilterMaterialAndItemData;
  });

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const handleChangeFilterData = (event) => {
    setSearchData(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      setSearchData(event.target.value);
      history.push(`/user/filterpage/${searchData}`);
    }
  };

  const Categorymenudata = useSelector((state) => {
    return state.Usersaga.Categorymenudata;
  });

  const [categoryMenu, setCategoryMenu] = useState();

  useEffect(() => {
    setCategoryMenu(Categorymenudata);
  }, [Categorymenudata]);

  return (
    <div>
      <Box className="search-main-header">
        <Box className="header-search-input">
          <Box className="form-group">
            <TextField
              fullWidth
              id="fullWidth"
              className="header-search-form-control"
              placeholder="Search here...."
              onChange={(event) => {
                handleChangeFilterData(event);
              }}
              onKeyPress={handleKeyDown}
            />
            <span className="search-grey-box search-icon">
              <IconButton
                onClick={() => {
                  history.push(`/user/filterpage/${searchData}`);
                  // history.push(`/user/filterpage/abc`);
                }}
              >
                <img
                  src={png.searchgrey}
                  className="search-grey-img mobile-search-img"
                ></img>
              </IconButton>
            </span>
          </Box>
        </Box>
        <Box className="header-categories-main">
          <FormControl
            sx={{ m: 1, minWidth: 120 }}
            className="header-categories-dropdown"
          >
            <Select
              className="header-select"
              value={age}
              onChange={handleChange}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value="" className="header-menuitems">
                All Categories
              </MenuItem>
              {categoryMenu?.length
                ? categoryMenu?.map((index) => {
                    return (
                      <MenuItem
                        // value={20}
                        value={index?.category_name}
                        onClick={() =>
                          history.push(`/user/category/${index._id}`)
                        }
                        className="header-menuitems"
                      >
                        {index?.category_name}
                      </MenuItem>
                    );
                  })
                : "No Records Found"}
              {/* <MenuItem value={30} className="header-menuitems">
                Women's
              </MenuItem> */}
            </Select>
            <span className="search-grey-box">
              <img src={svg.greydown} className="downgrey-img"></img>
            </span>
          </FormControl>
        </Box>
      </Box>
    </div>
  );
};

export default Searchbar;
