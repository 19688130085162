// import React from 'react';
import React from 'react';
import ReactApexChart from 'react-apexcharts';
import moment from 'moment';

export const RefundChart = ({ price, chartType, type }) => {
  let chartDate = [];
  let dailyData = []
  let months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  let daily = price?.Daily?.map(a => a.toFixed(2));
  let weekly = price?.Weekly?.map(a => a.toFixed(2));
  let monthly = price?.Monthly?.map(a => a.toFixed(2));
  let quarterly = price?.Quaterly?.map(a => a.toFixed(2));
  let yearly = price?.Yearly?.map(a => a.toFixed(2));


  if (type == 'Daily') {
    (function Last7Days() {
      let d = new Date();
      for (let i = 0; i < 7; i++) {
        d.setDate(d.getDate() - i);
        chartDate.push(
          String(`${moment(d).format().slice(0, 10)}T00:00:00.000Z`)
        );
        price?.Daily?.reverse().forEach(val => val._id == moment(d).format().slice(0, 10) ? dailyData.push(val.totalSaleAmount) : dailyData.push(0))

      }
    })();
  } else if (type == 'Weekly') {
    (function Last4Weeks() {
      for (let i = 0; i < 28; i += 7) {
        let d = new Date();
        d.setDate(d.getDate() - i);
        chartDate.push(
          String(`${moment(d).format().slice(0, 10)}T00:00:00.000Z`)
        );
      }
    })();
  } else if (type == 'Monthly') {
    for (let i = 1; i < 7; i++) {
      let d = new Date();
      d.setMonth(d.getMonth() - i);
      chartDate.push(String(months[d.getMonth()]));
    }
  } else if (type == 'Quarterly') {
    for (let i = 0; i < 12; i += 3) {
      let d = new Date();
      d.setMonth(d.getMonth() - i);
      chartDate.push(String(months[d.getMonth()]));
    }
  } else if (type == 'Yearly') {
    for (let i = 0; i < 6; i++) {
      let d = new Date();
      d.setFullYear(d.getFullYear() - i);
      chartDate.push(String(moment(d).format('YYYY')));
    }
  }

  let Obj = {
    series: [
      // {
      //   name: 'Website Blog',
      //   type: 'column',
      //   data: [88, 96, 45, 105, 25, 84, 86]
      // },
      {
        name: 'Rupees',
        type: chartType,
        data:
          type == 'Daily'
            ? daily
            : type == 'Weekly'
            ? weekly
            : type == 'Monthly'
            ? monthly.reverse()
            : type == 'Quarterly'
            ? quarterly.reverse()
            :type == 'Yearly'
            ? yearly.reverse()
            : new Array(7),
      },
    ],
    options: {
      chart: {
        height: 350,
        type: 'area',
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'straight',
        colors: ['#c29958'],
      },
      fill: {
        type: 'solid',
        colors: ['#c29958'],
      },
      xaxis: {
        // datetimeFormatter: {
        //   year: 'yyyy',
        // },
        type:
          type == 'Monthly' || type == 'Quarterly' || type == 'Yearly'
            ? 'category'
            : 'datetime',
        categories:
          type == 'Quarterly'|| type == 'Yearly' || type == 'Monthly'
            ? chartDate.reverse()
            : chartDate,
      },
      yaxis: {
        title: {
          text: 'Price',
        },
      },
      tooltip: {
        x: {
          format: 'MM/dd/yy',
        },
      },
    },
  };

  return (
    <ReactApexChart
      className="dashboard-chart-scatter"
      options={Obj.options}
      series={Obj.series}
      type="area"
      height={350}
    />
  );
};
