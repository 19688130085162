import React, { useEffect, useState } from "react";
import parse from 'html-react-parser';
import UserIndex from "../../UserIndex";

const { ListBenefitsSaga } = UserIndex.UserSagaActions;

export default function Membership() {

  const dispatch = UserIndex.useDispatch();
  const history = UserIndex.useHistory();
  const navigate = UserIndex.useHistory();

const [listBenefits, setListBenefits] = useState("") 

  const ListBenefitsData = UserIndex.useSelector((state) => {
    return state.Usersaga.ListBenefitsData;
  });

  const getListBenefits = async () => {
    let urlencoded = new URLSearchParams();
    dispatch(ListBenefitsSaga(urlencoded, navigate));
  };

  useEffect(() => {
    getListBenefits();
  }, []);

  useEffect(() => {
    setListBenefits(ListBenefitsData);
  }, [ListBenefitsData]);


  return (
    <div>
      <UserIndex.Header />
      <UserIndex.Box className="inner-hero-sec">
        <UserIndex.Box className="container">
          <UserIndex.Box className="hero-title-box">
            <UserIndex.Typography variant="h4" className="hero-inner-title">
              <span>Become a Member</span>
            </UserIndex.Typography>
          </UserIndex.Box>
        </UserIndex.Box>
      </UserIndex.Box>

      <UserIndex.Box className="membership-content-main">
        <UserIndex.Box className="container">
          <UserIndex.Box className="membership-title-box">
            <UserIndex.Typography variant="h1" className="become-title">
              {listBenefits?.data?.[0]?.heading}
            </UserIndex.Typography>
          </UserIndex.Box>

          <UserIndex.Typography >
             {parse(listBenefits?.data?.[0]?.sub_heading ? listBenefits?.data?.[0]?.sub_heading : "")}
          </UserIndex.Typography>

          <UserIndex.Box className="membership-title-box">
            <UserIndex.Typography variant="h1" className="become-title form-filling-text">
            {listBenefits?.listFormFillGuideline?.[0]?.title}
            </UserIndex.Typography>
          </UserIndex.Box>

          <UserIndex.Box className="form-filing-main">  
            <UserIndex.Box className="right-membership-main">
              <UserIndex.Typography >
              {parse(listBenefits?.listFormFillGuideline?.[0]?.list ? listBenefits?.listFormFillGuideline?.[0]?.list : "")}
              </UserIndex.Typography>
            </UserIndex.Box>
          </UserIndex.Box>

          <UserIndex.Box className="membership-title-box">
            <UserIndex.Typography variant="h1" className="become-title terms-text">
              {listBenefits?.listTermsCondition?.[0]?.head_line}
            </UserIndex.Typography>
          </UserIndex.Box>

          <UserIndex.Typography>
          {parse(listBenefits?.listTermsCondition?.[0]?.description ? listBenefits?.listTermsCondition?.[0]?.description: "")}
          </UserIndex.Typography>
        </UserIndex.Box>
      </UserIndex.Box>
      <UserIndex.Footer />
    </div>
  );
}
