//#region ALL IMPORTS
import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import {
  Grid,
  Container,
  Typography,
  Button,
  TextareaAutosize,
  Box,
  FormHelperText,
  TextField,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Api } from '../../../../../config/api';
import dataService from '../../../../../config/dataService';
import ContainedButton from '../../../../../component/customComponents/buttons/ContainedButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useFieldArray, useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import {
  ErrorMsg,
  TextAreaErrorMsg,
} from '../../../../../component/customComponents/errorMsg/ErrorMsg';
import {
  minCustomerServiceHeadingLength,
  customerServiceHeadingLength,
  minCustomerServiceDescriptionLength,
  customerServiceDescriptionLength,
  minQuestionLength,
  questionLength,
  minAnswerLength,
  answerLength,
  NO_WHITE_SPACE_BEGINNING_END,
  QUESTION_REGEX,
} from '../../../../../component/customComponents/validation/Validation';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import UploadIcon from '@mui/icons-material/Upload';
//#endregion

//#region ALL HOOKS AND VARIABALS
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const Img = styled('img')({
  display: 'block',
  maxWidth: '100%',
  height: 'auto',
  maxHeight: '50vh',
  borderRadius: '6px',
});

const AddCustomerServices = () => {
  let token = localStorage.getItem('auth');
  const history = useHistory();
  const [image, setImage] = useState('');

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
    watch,
  } = useForm({
    defaultValues: {
      queAndAns: [{ question: '', answer: '' }],
    },
  });

  const { fields, append, remove } = useFieldArray({
    name: 'queAndAns',
    control,
  });
  //#endregion

  //#region ALL EVENT HANDLERS AND  FUCTIONS

  const handleImg = (e) => {
    if (e.target.files.length) {
      setImage(e.target.files[0]);
    } else if (!e.target.files.length) {
      toast.error('select image');
    }
  };

  const removeImg = () => {
    setImage('');
  };

  const handleSave = async (data) => {
    try {
      let formdata = new FormData();

      formdata.append('images', image);
      formdata.append('heading', data?.customerServiceHeading?.trim());
      formdata.append('description', data?.customerServiceDescription?.trim());
      formdata.append('questions', JSON.stringify(data?.queAndAns));

      const blogData = await dataService.post(
        Api.Admin.addcustomerService,
        formdata,
        {
          headers: {
            auth: token,
          },
        }
      );

      if (blogData.data.status === 1) {
        toast.success('Customer Services added successfully');
        history.push('/admin/contentmanagement');
      }
    } catch (err) {
      toast.error(err?.message || 'Something went wrong');
    }
  };
  //#endregion

  //#region RETURN JSX
  return (
    <>
      <form onSubmit={handleSubmit(handleSave)}>
        <Container maxWidth="false">
          <Typography sx={{ my: 2 }} align="center" variant="h4">
            Customer Services management
          </Typography>

          <Grid container spacing={1}>
            {/* <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography align="left" sx={{ mt: 2, mb: 1.5 }}>
                Upload Image
              </Typography>
              <Box sx={{ width: '100%', position: 'relative' }}>
                {image === '' ? (
                  <>
                    <Button
                      component="label"
                      sx={{ borderRadius: 0, textTransform: 'none' }}
                      variant="contained"
                      onChange={handleImg}
                    >
                      Upload
                      <UploadIcon />
                      <input
                        name="files"
                        hidden
                        accept="image/*"
                        type="file"
                        {...register('files', {
                          required: <ErrorMsg msg="Please select Image" />,
                        })}
                      />
                    </Button>
                    <ErrorMessage errors={errors} name="files" />
                  </>
                ) : null}
                {image === '' ? (
                  ''
                ) : (
                  <Box className="aboutUs-img-box">
                    <Img
                      sx={{
                        my: 2,
                        borderRadius: '0px',
                        height: '130px',
                        width: '200px',
                      }}
                      src={window.URL.createObjectURL(image)}
                      alt="no image"
                    />
                    <Button className="aboutUs-top-btn">
                      <Tooltip title="Remove this Image" placement="bottom">
                        <DisabledByDefaultIcon
                          className="remove-img-icon"
                          onClick={removeImg}
                          sx={{
                            // bgcolor: 'black(0.5)',
                            // color: 'black',
                            mt: 5,
                          }}
                        />
                      </Tooltip>
                    </Button>
                  </Box>
                )}
              </Box>
            </Grid> */}
            {/* <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography align="left" sx={{ mt: 2 }}>
                Heading
              </Typography>
              <TextField
                autoComplete="off"
                sx={{
                  mt: 2,
                  '& .MuiFormHelperText-root': { ml: 0, mt: '5px' },
                }}
                size="small"
                fullWidth
                className="form-control border-none"
                name="customerServiceHeading"
                {...register('customerServiceHeading', {
                  required: <ErrorMsg msg="Please enter Heading" />,
                  minLength: {
                    value: minCustomerServiceHeadingLength,
                    message: (
                      <ErrorMsg
                        msg={`Minimum ${minCustomerServiceHeadingLength} Characters Allowed`}
                      />
                    ),
                  },
                  maxLength: {
                    value: customerServiceHeadingLength,
                    message: (
                      <ErrorMsg
                        msg={`Maximum ${customerServiceHeadingLength} Characters Allowed`}
                      />
                    ),
                  },
                  pattern: {
                    value: NO_WHITE_SPACE_BEGINNING_END,
                    message: (
                      <ErrorMsg msg="Whitespace is not allowed at beginning or end" />
                    ),
                  },
                })}
                helperText={
                  <ErrorMessage errors={errors} name="customerServiceHeading" />
                }
              />
            </Grid> */}
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography align="left">Description</Typography>
              <Box className="text-area-control-main">
                <TextareaAutosize
                  autoComplete="off"
                  minRows={0}
                  maxRows={5}
                  size="small"
                  fullWidth
                  className="form-control border-none text-area-control1 text-area-control-admin"
                  name="customerServiceDescription"
                  {...register('customerServiceDescription', {
                    required: (
                      <TextAreaErrorMsg msg="Please enter Description" />
                    ),
                    minLength: {
                      value: minCustomerServiceDescriptionLength,
                      message: (
                        <TextAreaErrorMsg
                          msg={`Minimum ${minCustomerServiceDescriptionLength} Characters Allowed`}
                        />
                      ),
                    },
                    maxLength: {
                      value: customerServiceDescriptionLength,
                      message: (
                        <TextAreaErrorMsg
                          msg={`Maximum ${customerServiceDescriptionLength} Characters Allowed`}
                        />
                      ),
                    },
                    pattern: {
                      value: NO_WHITE_SPACE_BEGINNING_END,
                      message: (
                        <TextAreaErrorMsg msg="Whitespace is not allowed at beginning or end" />
                      ),
                    },
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="customerServiceDescription"
                />
              </Box>
            </Grid>
          </Grid>

          <Box sx={{ border: 1, my: 3 }}></Box>

          {fields?.map((input, index) => {
            return (
              <>
                <Accordion
                  sx={{
                    marginBottom: 1,
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{
                      // backgroundColor: '#c29958',
                      '& .MuiButtonBase-root-MuiAccordionSummary-root': {
                        borderRadius: '10px !important',
                      },
                    }}
                  >
                    <Grid container>
                      <Grid item lg={1.5}>{`Question - ${index + 1} )  `}</Grid>
                      <Grid item lg={10}>
                        {input.question}
                        {watch(`queAndAns.${index}.question`)}
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={1} sx={{ mt: 2, border: 1, p: 2 }}>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Typography align="left" sx={{ mt: 2 }}>
                          Question
                        </Typography>
                        <TextField
                          autoComplete="off"
                          sx={{
                            mt: 2,
                            '& .MuiFormHelperText-root': { ml: 0, mt: '5px' },
                          }}
                          size="small"
                          fullWidth
                          className="form-control border-none"
                          name="question"
                          type="text"
                          {...register(`queAndAns.${index}.question`, {
                            required: <ErrorMsg msg="Please enter Question" />,
                            minLength: {
                              value: minQuestionLength,
                              message: (
                                <ErrorMsg
                                  msg={`Minimum ${minQuestionLength} Characters Allowed`}
                                />
                              ),
                            },
                            maxLength: {
                              value: questionLength,
                              message: (
                                <ErrorMsg
                                  msg={`Maximum ${questionLength} Characters Allowed`}
                                />
                              ),
                            },
                            pattern: {
                              value: QUESTION_REGEX,
                              message: (
                                <ErrorMsg msg={'"?" mark is required'} />
                              ),
                            },
                          })}
                          helperText={
                            <ErrorMessage
                              errors={errors}
                              name={`queAndAns.${index}.question`}
                            />
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Typography align="left" sx={{ mt: 2 }}>
                          Answer
                        </Typography>
                        <Box className="text-area-control-main">
                          <TextareaAutosize
                            autoComplete="off"
                            minRows={0}
                            maxRows={5}
                            size="small"
                            fullWidth
                            className="form-control border-none text-area-control1 text-area-control-admin"
                            name="answer"
                            type="text"
                            {...register(`queAndAns.${index}.answer`, {
                              required: (
                                <TextAreaErrorMsg msg="Please enter Answer" />
                              ),
                              minLength: {
                                value: minAnswerLength,
                                message: (
                                  <TextAreaErrorMsg
                                    msg={`Minimum ${minAnswerLength} Characters Allowed`}
                                  />
                                ),
                              },
                              maxLength: {
                                value: answerLength,
                                message: (
                                  <TextAreaErrorMsg
                                    msg={`Maximum ${answerLength} Characters Allowed`}
                                  />
                                ),
                              },
                              pattern: {
                                value: NO_WHITE_SPACE_BEGINNING_END,
                                message: (
                                  <TextAreaErrorMsg msg="Whitespace is not allowed at beginning or end" />
                                ),
                              },
                            })}
                          />
                          <ErrorMessage
                            errors={errors}
                            name={`queAndAns.${index}.answer`}
                          />
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        textAlign="right"
                      >
                        <ContainedButton
                          // display={inputFields.length <= 1 && 'none'}
                          onClick={() => remove(index)}
                          name="Remove"
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </>
            );
          })}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              disabled={fields.length == 9 && true}
              variant="contained"
              sx={{ textTransform: 'none' }}
              onClick={() => {
                if (fields.length <= 9) {
                  append({ question: '', answer: '' });
                }
              }}
            >
              Add Question
            </Button>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', my: 5 }}>
            <ContainedButton name="Submit" type="submit" />
          </Box>
        </Container>
      </form>
    </>
  );
};

export default AddCustomerServices;
//#endregion
