import React,{useEffect,useState} from "react";
import UserIndex from "../../UserIndex";
import UserSagaActions from "../../../../redux/Usersaga/action";

const { SingleguidelineSaga } = UserIndex.UserSagaActions;

export default function Uploadingproducts() {
  const [singleGuidelinesData, setSingleGuidelinesData] = useState("");
  const dispatch = UserIndex.useDispatch();
  const params = UserIndex.useParams();
  const history = UserIndex.useHistory();
  const navigate = UserIndex.useHistory();

  const loding = UserIndex.useSelector((state) => {
    return state.Usersaga.loading;
  });
  const SingleguidelineData = UserIndex.useSelector((state) => {
    return state.Usersaga.SingleguidelineData;
  });

  const ListGuidelineData = UserIndex.useSelector((state) => {
      return state.Usersaga.ListGuidelineData;
    });

  const getSingleguideline = async () => {
    let urlencoded = new URLSearchParams();
      if(params.id) {
        urlencoded.append("id",params?.id)
      }else{
        urlencoded.append("guideline_type",params?.type)
      }
    dispatch(SingleguidelineSaga(urlencoded, navigate));
  };

  useEffect(() => {
      getSingleguideline();
  }, [params]);

  useEffect(() => {
      setSingleGuidelinesData(SingleguidelineData);
  }, [SingleguidelineData,params]);


  return (
    <div>
      <UserIndex.Header />
      <UserIndex.Box className="uploadingproducts-sec">
        <UserIndex.Box className="container">
          <UserIndex.Box className="uploading-banner-main">
            <UserIndex.Box className="uploading-info">
              <UserIndex.Typography variant="h4" className="uploading-inner-title">
                {singleGuidelinesData?.guideline_type}
              </UserIndex.Typography>
            </UserIndex.Box>
          </UserIndex.Box>
        </UserIndex.Box>
      </UserIndex.Box>
      <UserIndex.Box className="goldinfo-content-main">
        <UserIndex.Box className="container">
          <UserIndex.Box className="goldinfo-content">
            <UserIndex.Typography
              variant="h6"
              component="h6"
              className="goldinfo-sub-text"
            >
            {singleGuidelinesData?.heading}
            </UserIndex.Typography>

            <UserIndex.Typography
              variantMapping="p"
              component="p"
              className="goldinfo-para mb-30"
            >
            {singleGuidelinesData?.description}
            </UserIndex.Typography>
          </UserIndex.Box>
        </UserIndex.Box>
      </UserIndex.Box>
      <UserIndex.Footer />
    </div>
  );
}