import React from 'react'
import Box from '@mui/material/Box'
import { Button, Typography } from '@mui/material'

const Certificates = ({ data }) => {
  return (
    <div>
      {data?.user_id ? (
        <>
          <Box className="seler-certi-sec">
            <Box className="container">
              <Box className="seler-certi-text-box">
                <Typography
                  variant="h2"
                  component="h2"
                  className="seler-serti-text"
                >
                  Seller's Certificates
                </Typography>
              </Box>
              <Box sx={{ width: 1 }}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={{ xs: 2, sm: 5, md: 4, lg: 4 }}
                >
                  {data?.user_id?.image?.map((element) => {
                    return (
                      <Box
                        gridColumn={{
                          xs: 'span 12',
                          sm: 'span 6',
                          md: 'span 4',
                          lg: 'span 4',
                        }}
                      >
                        <Box className="certi-img-box">
                          <img src={element} className="certi-img"></img>
                        </Box>
                      </Box>
                    )
                  })}
                </Box>
              </Box>
            </Box>
          </Box>
        </>
      ) : (
        ''
      )}
    </div>
  )
}

export default Certificates
