import React from 'react'

//#region ALL HOOKS AND VARIABALS
const AllOrders = () => {
//#endregion

//#region ALL EVENT HANDLERS AND  FUCTIONS 
//#endregion

//#region RETURN JSX 
  return (
    <div>AllOrders</div>
  )
}

export default AllOrders
//#endregion