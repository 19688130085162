import React, { useEffect, useState } from "react";
import UserIndex from "../../UserIndex";
import DisabledByDefaultIcon from "@mui/icons-material/DisabledByDefault";
import { Button } from "@mui/material";

const Item = UserIndex.styled(UserIndex.Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const {
  GetSingleCustomProductSaga,
  ListItemSaga,
  ListMaterialSaga,
  RaiseCustomProductSaga,
} = UserIndex.UserSagaActions;

const Input = UserIndex.styled("input")({
  display: "none",
});

const Img = UserIndex.styled("img")({
  display: "block",
  maxWidth: "100%",
  height: "auto",
  maxHeight: "50vh",
  borderRadius: "6px",
});

const checkValidAllArray = (array) => {
  return array.every((obj) => obj.isValidObj === true);
};

const fileTypes = ["JPEG", "UserIndex.png", "GIF"];

const Requestingproducts = () => {
  const history = UserIndex.useHistory();
  const params = UserIndex.useParams();
  const dispatch = UserIndex.useDispatch();
  const navigate = UserIndex.useHistory();
  const loding = UserIndex.useSelector((state) => {
    return state.Usersaga.loading;
  });
  const Token = UserIndex.useSelector((state) => {
    return state.Usersaga.Token;
  });
  const GetSingleCustomProductData = UserIndex.useSelector((state) => {
    return state.Usersaga.GetSingleCustomProductData;
  });

  const Productdata = UserIndex.useSelector((state) => {
    return state.Usersaga.Productdata;
  });

  const ListItemData = UserIndex.useSelector((state) => {
    return state.Usersaga.ListItemData;
  });

  const ListMaterialData = UserIndex.useSelector((state) => {
    return state.Usersaga.ListMaterialData;
  });

  const Categorymenudata = UserIndex.useSelector((state) => {
    return state.Usersaga.Categorymenudata;
  });

  const inputArr = [
    {
      piece: "",
      quantity: "",
      purity: "",
      total_weight: "",
    },
  ];
  const DiamonsFieldsArr = [
    {
      piece: "",
      quantity: "",
      purity: "",
      total_weight: "",
    },
  ];

  const GoldFieldsArr = [
    {
      purity: "",
      weight: "",
    },
  ];

  const SilverFieldsArr = [
    {
      purity: "",
      weight: "",
    },
  ];
  const [clicked, setClicked] = useState(false);
  const [categoryData, setCategoryData] = useState("");
  const [arr, setArr] = useState(inputArr);
  const [diamondArr, setDiamondArr] = useState(DiamonsFieldsArr);
  const [goldArr, setGoldArr] = useState(GoldFieldsArr);
  const [silverArr, setSilverArr] = useState(SilverFieldsArr);
  const [show, setShow] = useState(true);
  const [validation, setValidation] = useState(true);
  const [validation2, setValidation2] = useState(true);
  const [image, setImage] = useState([]);
  const [Url, setUrl] = useState([]);
  const [noOfPiecesAvailableInStockError, setNoOfPiecesAvailableInStockError] =
    useState("");
  const [itemError, setItemError] = useState("");
  const [specifyError, setSpecifyError] = useState("");
  const [additional_detailsError, setAdditional_detailsError] = useState("");
  const [imageError, setImageError] = useState("");
  const [materialError, setMaterialError] = useState("");
  const [innerCircumference, setInnerCircumference] = useState("");
  const [lengthError, setLengthError] = useState("");
  const [depthError, setDepthError] = useState("");
  const [metaTitleError, setMetaTitleError] = useState("");
  const [categoryDataError, setCategoryDataError] = useState("");
  const [metaDescriptionError, setMetaDescriptionError] = useState("");
  const [oneProductData, setOneProductData] = useState({});
  const [description, setDescription] = useState({
    specify: "",
    additional_details: "",
  });


  const [item, setItem] = useState("");
  const [dimension, setDimension] = useState({
    inner_circumference: "",
    length: 0,
    depth: 0,
    meta_title: "",
    meta_description: "",
  });
  const [goldProperties, setGoldProperties] = useState({
    purity: "",
    weight: "",
    making_charges: "",
    other_charges: "",
  });
  const [silverProperties, setSilverProperties] = useState({
    purity: "",
    weight: "",
    making_charges: "",
    other_charges: "",
  });
  const [gemstoneProperties, setGemstoneProperties] = useState({
    piece: "",
    quantity: "",
    purity: "",
    total_weight: "",
    price_per_quantity: "",
    price_of_total_quantity: "00",
    making_charge: "",
    final_price: "00",
  });

  const [diamondProperties, setDiamondProperties] = useState({
    piece: "",
    quantity: "",
    purity: "",
    total_weight: "",
    price_per_quantity: "",
    price_of_total_quantity: "00",
    making_charge: "",
    final_price: "00",
  });

  const [itemList, setItemList] = useState([]);
  const [materialList, setMaterialList] = useState([]);
  const [material, setMaterial] = useState([]);
  const [materialId, setMaterialId] = useState([]);
  const [itemId, setItemId] = useState("");
  const [category, setCategory] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [images, setImages] = useState([]);
  const [oldImages, setOldImages] = useState([]);
  const [oldImagesError, setOldImagesError] = useState([]);
  const [submitProduct, setSubmitProduct] = useState("");

  const addInput = () => {
    setArr((s) => {
      return [
        ...s,
        {
          piece: "",
          quantity: "",
          purity: "",
          total_weight: "",
        },
      ];
    });
  };

  const minusInput = (index) => {
    let data = [...arr];
    if (data.length >= 1) {
      data.splice(-1);
    }
    setArr(data);
  };

  const handleChange = (e, name) => {
    const index = e.target.id;
    setArr((s) => {
      const newArr = s.slice();
      newArr[index][`${name}`] =
        name == "total_weight"
          ? e.target.value.slice(0, 6)
          : name == "purity"
          ? e.target.value.slice(0, 4)
          : name == "quantity"
          ? parseInt(e.target.value)
          : e.target.value;
      return newArr;
    });
  };

  const addDiamondInput = () => {
    setDiamondArr((s) => {
      return [
        ...s,
        {
          piece: "",
          quantity: "",
          purity: "",
          total_weight: "",
        },
      ];
    });
  };

  const minusDiamondInput = (index) => {
    let data = [...diamondArr];
    if (data.length >= 1) {
      data.splice(-1);
    }
    setDiamondArr(data);
  };

  const handleDiamondChange = (e, name) => {
    const index = e.target.id;
    setDiamondArr((s) => {
      const newArr = s.slice();
      newArr[index][`${name}`] =
        name == "total_weight"
          ? e.target.value.slice(0, 6)
          : name == "purity"
          ? e.target.value.slice(0, 4)
          : name == "quantity"
          ? parseInt(e.target.value)
          : e.target.value;
      return newArr;
    });
  };

  const addGoldInput = () => {
    setGoldArr((s) => {
      return [
        ...s,
        {
          purity: "",
          weight: "",
          making_charges: "",
          other_charges: "",
        },
      ];
    });
  };

  const minusGoldInput = (index) => {
    let data = [...goldArr];
    if (data.length >= 1) {
      data.splice(-1);
    }
    setGoldArr(data);
  };

  const handleGoldChange = (e, name) => {
    const index = e.target.id;
    setGoldArr((s) => {
      const newArr = s.slice();
      newArr[index][`${name}`] =
        name == "purity"
          ? e.target.value.slice(0, 4)
          : name == "weight"
          ? e.target.value.slice(0, 6)
          : e.target.value;
      return newArr;
    });
  };

  const addSilverInput = () => {
    setSilverArr((s) => {
      return [
        ...s,
        {
          purity: "",
          weight: "",
          making_charges: "",
          other_charges: "",
        },
      ];
    });
  };

  const minusSilverInput = (index) => {
    let data = [...silverArr];
    if (data.length >= 1) {
      data.splice(-1);
    }
    setSilverArr(data);
  };

  const handleSilverChange = (e, name) => {
    const index = e.target.id;
    setSilverArr((s) => {
      const newArr = s.slice();

      newArr[index][`${name}`] =
        name == "purity"
          ? e.target.value.slice(0, 4)
          : name == "weight"
          ? e.target.value.slice(0, 6)
          : e.target.value;
      return newArr;
    });
  };

  const handleChangeMaterial = (e) => {
    setMaterial(e.target.value);
  };

  const handleChangeItem = (e) => {
    let arr = itemList.map((list, index) => {
      if (e.target.value === list.item) {
        setItem(list.item);
        setItemId(list._id);
      }
    });
  };

  const handleChangeCategory = (e) => {
    let arr = categoryData.map((list, index) => {
      if (e.target.value === list.category_name) {
        setCategory(list.category_name);
        setCategoryId(list._id);
      }
    });
  };

  const handleChangeProductDescription = (e) => {
    const { name, value } = e.target;
    setDescription({
      ...description,
      [name]:
        name === "no_of_pieces_available_inStock"
          ? value.slice(0, 4)
          : name === "specify"
          ? value.trimStart().replace(/\s\s+/g, " ")
          : name === "additional_details"
          ? value.trimStart().replace(/\s\s+/g, " ")
          : value,
    });
  };

  const handleChangeDimension = (e) => {
    const { name, value } = e.target;
    setDimension({
      ...dimension,
      [name]:
        name === "meta_title"
          ? value.slice(0, 30)
          : name === "meta_description"
          ? value.slice(0, 150)
          : name === "length"
          ? value.slice(0, 5)
          : name === "depth"
          ? value.slice(0, 5)
          : name === "inner_circumference"
          ? value.slice(0, 5)
          : value,
    });
  };

  const handleChangeImage = (e) => {
    const { files } = e.target;
    if (files.length < 6 && images.length < 5) {
      const arr = [];
      for (const key of Object.keys(files)) {
        if (
          files[key].type === "image/png" ||
          files[key].type === "image/jpeg"
        ) {
          arr.push(files[key]);
        }
      }
      setImages((e) => [...e, ...arr]);
    }
  };

  const removePreviewImage = (data) => {
    var array = [...images];
    let filterArray = array.filter((item) => item !== data);
    setImages(filterArray);
  };

  const removeOldImages = (data) => {
    var array = [...oldImages];
    let filterArray = array.filter((item) => item !== data);
    setOldImages(filterArray);
  };

  const getListItem = async () => {
    let urlencoded = new URLSearchParams();
    dispatch(ListItemSaga(urlencoded, navigate));
  };

  const getListMaterial = async () => {
    let urlencoded = new URLSearchParams();
    dispatch(ListMaterialSaga(urlencoded, navigate));
  };

  useEffect(() => {
    setItemList(ListItemData);
  }, [ListItemData]);

  useEffect(() => {
    setMaterialList(ListMaterialData);
  }, [ListMaterialData]);

  useEffect(() => {
    getListItem();
    getListMaterial();
  }, []);

  useEffect(() => {
    setCategoryData(Categorymenudata);
  }, [Categorymenudata]);

  useEffect(() => {
    if (params.id) {
      let urlencoded = new URLSearchParams();
      urlencoded.append("id", params.id);
      dispatch(GetSingleCustomProductSaga(urlencoded, navigate, Token));
    }
  }, []);

  const editProductMaterial = () => {
    if (params.id) {
      setOneProductData(GetSingleCustomProductData);
      const tampMaterial = GetSingleCustomProductData?.materialIdArray?.map(
        (item) => item?.material_id?.material
      );
      setMaterial(tampMaterial);

      if (
        GetSingleCustomProductData?.gemstone?.length > 0 &&
        GetSingleCustomProductData?.materialIdArray?.length === 1
      ) {
        let datas = [];
        const tempGemstone = GetSingleCustomProductData.gemstone?.map(
          (item) => {
            let obj = {};
            obj.piece = item.piece;
            obj.quantity = item.quantity;
            obj.purity = item.purity;
            obj.total_weight = item.total_weight;
            obj.price_per_quantity = item.price_per_quantity;
            obj.making_charge = item.making_charge;
            obj.price_of_total_quantity = "00";
            obj.final_price = "00";
            datas.push(obj);
          }
        );
        setArr(datas);
      } else if (
        GetSingleCustomProductData?.diamond?.length > 0 &&
        GetSingleCustomProductData?.materialIdArray?.length === 1
      ) {
        let data = [];
        const tempDiamond = GetSingleCustomProductData.diamond?.map((item) => {
          let obj = {};
          obj.piece = item.piece;
          obj.quantity = item.quantity;
          obj.purity = item.purity;
          obj.total_weight = item.total_weight;
          obj.price_per_quantity = item.price_per_quantity;
          obj.making_charge = item.making_charge;
          obj.price_of_total_quantity = "00";
          obj.final_price = "00";
          data.push(obj);
        });
        setDiamondArr(data);
      } else if (
        GetSingleCustomProductData?.gold?.length > 0 &&
        GetSingleCustomProductData?.materialIdArray?.length === 1
      ) {
        let data = [];
        const tempGold = GetSingleCustomProductData.gold?.map((item) => {
          let obj = {};
          obj.purity = item.purity;
          obj.weight = item.weight;
          obj.making_charges = item.making_charges;
          obj.other_charges = item.other_charges;
          data.push(obj);
        });
        setGoldArr(data);
      } else if (
        GetSingleCustomProductData?.silver?.length > 0 &&
        GetSingleCustomProductData?.materialIdArray?.length === 1
      ) {
        let data = [];
        const tempSilver = GetSingleCustomProductData.silver?.map((item) => {
          let obj = {};
          obj.purity = item.purity;
          obj.weight = item.weight;
          obj.making_charges = item.making_charges;
          obj.other_charges = item.other_charges;
          data.push(obj);
        });
        setSilverArr(data);
      } else if (
        GetSingleCustomProductData?.diamond?.length > 0 &&
        GetSingleCustomProductData?.gemstone?.length > 0 &&
        GetSingleCustomProductData?.materialIdArray?.length === 2
      ) {
        let data = [];
        const tempDiamond = GetSingleCustomProductData.diamond?.map((item) => {
          let obj = {};
          obj.piece = item.piece;
          obj.quantity = item.quantity;
          obj.purity = item.purity;
          obj.total_weight = item.total_weight;
          obj.price_per_quantity = item.price_per_quantity;
          obj.making_charge = item.making_charge;
          obj.price_of_total_quantity = "00";
          obj.final_price = "00";
          data.push(obj);
        });
        setDiamondArr(data);

        let datas = [];
        const tempGemstone = GetSingleCustomProductData.gemstone?.map(
          (item) => {
            let obj = {};
            obj.piece = item.piece;
            obj.quantity = item.quantity;
            obj.purity = item.purity;
            obj.total_weight = item.total_weight;
            obj.price_per_quantity = item.price_per_quantity;
            obj.making_charge = item.making_charge;
            obj.price_of_total_quantity = "00";
            obj.final_price = "00";
            datas.push(obj);
          }
        );
        setArr(datas);
      } else if (
        GetSingleCustomProductData?.gold?.length > 0 &&
        GetSingleCustomProductData?.diamond?.length > 0 &&
        GetSingleCustomProductData?.materialIdArray?.length === 2
      ) {
        let data = [];
        const tempGold = GetSingleCustomProductData.gold?.map((item) => {
          let obj = {};
          obj.purity = item.purity;
          obj.weight = item.weight;
          obj.making_charges = item.making_charges;
          obj.other_charges = item.other_charges;
          data.push(obj);
        });
        setGoldArr(data);

        let diamondData = [];
        const tempDiamond = GetSingleCustomProductData.diamond?.map((item) => {
          let obj = {};
          obj.piece = item.piece;
          obj.quantity = item.quantity;
          obj.purity = item.purity;
          obj.total_weight = item.total_weight;
          obj.price_per_quantity = item.price_per_quantity;
          obj.making_charge = item.making_charge;
          obj.price_of_total_quantity = "00";
          obj.final_price = "00";
          diamondData.push(obj);
        });
        setDiamondArr(diamondData);
      } else if (
        GetSingleCustomProductData?.gold?.length > 0 &&
        GetSingleCustomProductData?.gemstone?.length > 0 &&
        GetSingleCustomProductData?.materialIdArray?.length === 2
      ) {
        let data = [];
        const tempGold = GetSingleCustomProductData.gold?.map((item) => {
          let obj = {};
          obj.purity = item.purity;
          obj.weight = item.weight;
          obj.making_charges = item.making_charges;
          obj.other_charges = item.other_charges;
          data.push(obj);
        });
        setGoldArr(data);

        let gemstoneData = [];
        const tempGemstone = GetSingleCustomProductData.gemstone?.map(
          (item) => {
            let obj = {};
            obj.piece = item.piece;
            obj.quantity = item.quantity;
            obj.purity = item.purity;
            obj.total_weight = item.total_weight;
            obj.price_per_quantity = item.price_per_quantity;
            obj.making_charge = item.making_charge;
            obj.price_of_total_quantity = "00";
            obj.final_price = "00";
            gemstoneData.push(obj);
          }
        );
        setArr(gemstoneData);
      } else if (
        GetSingleCustomProductData?.silver?.length > 0 &&
        GetSingleCustomProductData?.diamond?.length > 0 &&
        GetSingleCustomProductData?.materialIdArray?.length === 2
      ) {
        let data = [];
        const tempSilver = GetSingleCustomProductData.silver?.map((item) => {
          let obj = {};
          obj.purity = item.purity;
          obj.weight = item.weight;
          obj.making_charges = item.making_charges;
          obj.other_charges = item.other_charges;
          data.push(obj);
        });
        setSilverArr(data);

        let diamondData = [];
        const tempDiamond = GetSingleCustomProductData.diamond?.map((item) => {
          let obj = {};
          obj.piece = item.piece;
          obj.quantity = item.quantity;
          obj.purity = item.purity;
          obj.total_weight = item.total_weight;
          obj.price_per_quantity = item.price_per_quantity;
          obj.making_charge = item.making_charge;
          obj.price_of_total_quantity = "00";
          obj.final_price = "00";
          diamondData.push(obj);
        });
        setDiamondArr(diamondData);
      } else if (
        GetSingleCustomProductData?.silver?.length > 0 &&
        GetSingleCustomProductData?.gemstone?.length > 0 &&
        GetSingleCustomProductData?.materialIdArray?.length === 2
      ) {
        let data = [];
        const tempSilver = GetSingleCustomProductData.silver?.map((item) => {
          let obj = {};
          obj.purity = item.purity;
          obj.weight = item.weight;
          obj.making_charges = item.making_charges;
          obj.other_charges = item.other_charges;
          data.push(obj);
        });
        setSilverArr(data);

        let gemstoneData = [];
        const tempGemstone = GetSingleCustomProductData.gemstone?.map(
          (item) => {
            let obj = {};
            obj.piece = item.piece;
            obj.quantity = item.quantity;
            obj.purity = item.purity;
            obj.total_weight = item.total_weight;
            obj.price_per_quantity = item.price_per_quantity;
            obj.making_charge = item.making_charge;
            obj.price_of_total_quantity = "00";
            obj.final_price = "00";
            gemstoneData.push(obj);
          }
        );
        setArr(gemstoneData);
      } else if (
        GetSingleCustomProductData?.gold?.length > 0 &&
        GetSingleCustomProductData?.silver?.length > 0 &&
        GetSingleCustomProductData?.materialIdArray?.length === 2
      ) {
        let data = [];
        const tempGold = GetSingleCustomProductData.gold?.map((item) => {
          let obj = {};
          obj.purity = item.purity;
          obj.weight = item.weight;
          obj.making_charges = item.making_charges;
          obj.other_charges = item.other_charges;
          data.push(obj);
        });
        setGoldArr(data);

        let silverData = [];
        const tempSilver = GetSingleCustomProductData.silver?.map((item) => {
          let obj = {};
          obj.purity = item.purity;
          obj.weight = item.weight;
          obj.making_charges = item.making_charges;
          obj.other_charges = item.other_charges;
          silverData.push(obj);
        });
        setSilverArr(silverData);
      } else if (
        GetSingleCustomProductData?.gold?.length > 0 &&
        GetSingleCustomProductData?.silver?.length > 0 &&
        GetSingleCustomProductData?.diamond?.length > 0 &&
        GetSingleCustomProductData?.materialIdArray?.length === 3
      ) {
        let data = [];
        const tempGold = GetSingleCustomProductData.gold?.map((item) => {
          let obj = {};
          obj.purity = item.purity;
          obj.weight = item.weight;
          obj.making_charges = item.making_charges;
          obj.other_charges = item.other_charges;
          data.push(obj);
        });
        setGoldArr(data);

        let silverData = [];
        const tempSilver = GetSingleCustomProductData.silver?.map((item) => {
          let obj = {};
          obj.purity = item.purity;
          obj.weight = item.weight;
          obj.making_charges = item.making_charges;
          obj.other_charges = item.other_charges;
          silverData.push(obj);
        });
        setSilverArr(silverData);

        let diamondData = [];
        const tempDiamond = GetSingleCustomProductData.diamond?.map((item) => {
          let obj = {};
          obj.piece = item.piece;
          obj.quantity = item.quantity;
          obj.purity = item.purity;
          obj.total_weight = item.total_weight;
          obj.price_per_quantity = item.price_per_quantity;
          obj.making_charge = item.making_charge;
          obj.price_of_total_quantity = "00";
          obj.final_price = "00";
          diamondData.push(obj);
        });
        setDiamondArr(diamondData);
      } else if (
        GetSingleCustomProductData?.gold?.length > 0 &&
        GetSingleCustomProductData?.silver?.length > 0 &&
        GetSingleCustomProductData?.gemstone?.length > 0 &&
        GetSingleCustomProductData?.materialIdArray?.length === 3
      ) {
        let data = [];
        const tempGold = GetSingleCustomProductData.gold?.map((item) => {
          let obj = {};
          obj.purity = item.purity;
          obj.weight = item.weight;
          obj.making_charges = item.making_charges;
          obj.other_charges = item.other_charges;
          data.push(obj);
        });
        setGoldArr(data);

        let silverData = [];
        const tempSilver = GetSingleCustomProductData.silver?.map((item) => {
          let obj = {};
          obj.purity = item.purity;
          obj.weight = item.weight;
          obj.making_charges = item.making_charges;
          obj.other_charges = item.other_charges;
          silverData.push(obj);
        });
        setSilverArr(silverData);

        let gemstoneData = [];
        const tempGemstone = GetSingleCustomProductData.gemstone?.map(
          (item) => {
            let obj = {};
            obj.piece = item.piece;
            obj.quantity = item.quantity;
            obj.purity = item.purity;
            obj.total_weight = item.total_weight;
            obj.price_per_quantity = item.price_per_quantity;
            obj.making_charge = item.making_charge;
            obj.price_of_total_quantity = "00";
            obj.final_price = "00";
            gemstoneData.push(obj);
          }
        );
        setArr(gemstoneData);
      } else if (
        GetSingleCustomProductData?.gold?.length > 0 &&
        GetSingleCustomProductData?.diamond?.length > 0 &&
        GetSingleCustomProductData?.gemstone?.length > 0 &&
        GetSingleCustomProductData?.materialIdArray?.length === 3
      ) {
        let data = [];
        const tempGold = GetSingleCustomProductData.gold?.map((item) => {
          let obj = {};
          obj.purity = item.purity;
          obj.weight = item.weight;
          obj.making_charges = item.making_charges;
          obj.other_charges = item.other_charges;
          data.push(obj);
        });
        setGoldArr(data);

        let diamondData = [];
        const tempDiamond = GetSingleCustomProductData.diamond?.map((item) => {
          let obj = {};
          obj.piece = item.piece;
          obj.quantity = item.quantity;
          obj.purity = item.purity;
          obj.total_weight = item.total_weight;
          obj.price_per_quantity = item.price_per_quantity;
          obj.making_charge = item.making_charge;
          obj.price_of_total_quantity = "00";
          obj.final_price = "00";
          diamondData.push(obj);
        });
        setDiamondArr(diamondData);

        let gemstoneData = [];
        const tempGemstone = GetSingleCustomProductData.gemstone?.map(
          (item) => {
            let obj = {};
            obj.piece = item.piece;
            obj.quantity = item.quantity;
            obj.purity = item.purity;
            obj.total_weight = item.total_weight;
            obj.price_per_quantity = item.price_per_quantity;
            obj.making_charge = item.making_charge;
            obj.price_of_total_quantity = "00";
            obj.final_price = "00";
            gemstoneData.push(obj);
          }
        );
        setArr(gemstoneData);
      } else if (
        GetSingleCustomProductData?.silver?.length > 0 &&
        GetSingleCustomProductData?.diamond?.length > 0 &&
        GetSingleCustomProductData?.gemstone?.length > 0 &&
        GetSingleCustomProductData?.materialIdArray?.length === 3
      ) {
        let data = [];
        const tempSilver = GetSingleCustomProductData.silver?.map((item) => {
          let obj = {};
          obj.purity = item.purity;
          obj.weight = item.weight;
          obj.making_charges = item.making_charges;
          obj.other_charges = item.other_charges;
          data.push(obj);
        });
        setSilverArr(data);

        let diamondData = [];
        const tempDiamond = GetSingleCustomProductData.diamond?.map((item) => {
          let obj = {};
          obj.piece = item.piece;
          obj.quantity = item.quantity;
          obj.purity = item.purity;
          obj.total_weight = item.total_weight;
          obj.price_per_quantity = item.price_per_quantity;
          obj.making_charge = item.making_charge;
          obj.price_of_total_quantity = "00";
          obj.final_price = "00";
          diamondData.push(obj);
        });
        setDiamondArr(diamondData);

        let gemstoneData = [];
        const tempGemstone = GetSingleCustomProductData.gemstone?.map(
          (item) => {
            let obj = {};
            obj.piece = item.piece;
            obj.quantity = item.quantity;
            obj.purity = item.purity;
            obj.total_weight = item.total_weight;
            obj.price_per_quantity = item.price_per_quantity;
            obj.making_charge = item.making_charge;
            obj.price_of_total_quantity = "00";
            obj.final_price = "00";
            gemstoneData.push(obj);
          }
        );
        setArr(gemstoneData);
      } else if (
        GetSingleCustomProductData?.gold?.length > 0 &&
        GetSingleCustomProductData?.silver?.length > 0 &&
        GetSingleCustomProductData?.diamond?.length > 0 &&
        GetSingleCustomProductData?.gemstone?.length > 0 &&
        GetSingleCustomProductData?.materialIdArray?.length === 4
      ) {
        let data = [];
        const tempGold = GetSingleCustomProductData.gold?.map((item) => {
          let obj = {};
          obj.purity = item.purity;
          obj.weight = item.weight;
          obj.making_charges = item.making_charges;
          obj.other_charges = item.other_charges;
          data.push(obj);
        });
        setGoldArr(data);

        let silverData = [];
        const tempSilver = GetSingleCustomProductData.silver?.map((item) => {
          let obj = {};
          obj.purity = item.purity;
          obj.weight = item.weight;
          obj.making_charges = item.making_charges;
          obj.other_charges = item.other_charges;
          silverData.push(obj);
        });
        setSilverArr(silverData);

        let diamondData = [];
        const tempDiamond = GetSingleCustomProductData.diamond?.map((item) => {
          let obj = {};
          obj.piece = item.piece;
          obj.quantity = item.quantity;
          obj.purity = item.purity;
          obj.total_weight = item.total_weight;
          obj.price_per_quantity = item.price_per_quantity;
          obj.making_charge = item.making_charge;
          obj.price_of_total_quantity = "00";
          obj.final_price = "00";
          diamondData.push(obj);
        });
        setDiamondArr(diamondData);

        let gemstoneData = [];
        const tempGemstone = GetSingleCustomProductData.gemstone?.map(
          (item) => {
            let obj = {};
            obj.piece = item.piece;
            obj.quantity = item.quantity;
            obj.purity = item.purity;
            obj.total_weight = item.total_weight;
            obj.price_per_quantity = item.price_per_quantity;
            obj.making_charge = item.making_charge;
            obj.price_of_total_quantity = "00";
            obj.final_price = "00";
            gemstoneData.push(obj);
          }
        );
        setArr(gemstoneData);
      }
    }
  };

  useEffect(() => {
    if (GetSingleCustomProductData) {
      editProductMaterial();
    }
  }, [GetSingleCustomProductData]);

  const editProductData = () => {
    if (oneProductData) {
      setDescription({
        specify: oneProductData?.specify,
        additional_details: oneProductData?.additional_details,
      });
      setOldImages(oneProductData?.image);
      setImages([]);
      setItem(oneProductData?.item_id?.item);
      setItemId(oneProductData?.item_id?._id);
      setCategory(oneProductData?.category_id?.category_name);
      setCategoryId(oneProductData?.category_id?._id);
      if (oneProductData?.gold) {
        setGoldProperties({
          purity: oneProductData?.gold?.purity,
          weight: oneProductData?.gold?.weight,
        });
      } else if (oneProductData?.silver) {
        setSilverProperties({
          purity: oneProductData?.silver?.purity,
          weight: oneProductData?.silver?.weight,
        });
      }
      setDimension({
        inner_circumference:
          oneProductData?.product_dimension?.inner_circumference == "undefined"
            ? " "
            : oneProductData?.product_dimension?.inner_circumference,
        length:
          oneProductData?.product_dimension?.length == "undefined"
            ? " "
            : oneProductData?.product_dimension?.length,
        depth:
          oneProductData?.product_dimension?.depth == "undefined"
            ? ""
            : oneProductData?.product_dimension?.depth,
        meta_title: oneProductData?.product_dimension?.meta_title,
        meta_description: oneProductData?.product_dimension?.meta_description,
      });
    }
  };

  useEffect(() => {
    if (oneProductData) {
      editProductData();
    }
  }, [oneProductData]);

  const goldFields = () => {
    return (
      <>
        {goldArr?.map((item, i) => {
          return (
            <>
              <UserIndex.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
              >
                <UserIndex.Box className="addinfo-text-box">
                  <UserIndex.Typography variant="h2" className="addinfo-text">
                    Gold
                  </UserIndex.Typography>
                </UserIndex.Box>
              </UserIndex.Box>
              <UserIndex.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 6",
                }}
              >
                <UserIndex.Box className="refrence-product-input addproduct-1-input-box">
                  <UserIndex.Box className="input-box addproduct-input-box">
                    <UserIndex.FormHelperText className="input-lable">
                      Purity (In %)
                      <span style={{ color: "red", fontSize: "15px" }}>
                        {" "}
                        *{" "}
                      </span>
                    </UserIndex.FormHelperText>
                    <UserIndex.TextValidator
                      fullWidth
                      id={i}
                      className="form-control border-none"
                      value={item?.purity}
                      name={"purity"}
                      onChange={(e) => {
                        handleGoldChange(e, "purity");
                      }}
                      type="number"
                    />
                    <UserIndex.FormHelperText className="error-input-lable">
                      {item[`purityError-${i}`]}
                    </UserIndex.FormHelperText>
                  </UserIndex.Box>
                </UserIndex.Box>
              </UserIndex.Box>
              <UserIndex.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 6",
                }}
              >
                <UserIndex.Box className="refrence-product-input">
                  <UserIndex.Box className="input-box addproduct-1-input-box">
                    <UserIndex.FormHelperText className="input-lable">
                      Weight (In Gram)
                      <span style={{ color: "red", fontSize: "15px" }}>
                        {" "}
                        *{" "}
                      </span>
                    </UserIndex.FormHelperText>
                    <UserIndex.TextValidator
                      fullWidth
                      id={i}
                      className="form-control border-none"
                      value={item?.weight}
                      name={"weight"}
                      onChange={(e) => {
                        handleGoldChange(e, "weight");
                      }}
                      type="number"
                    />
                    <UserIndex.FormHelperText className="error-input-lable">
                      {item[`weightError-${i}`]}
                    </UserIndex.FormHelperText>
                  </UserIndex.Box>
                </UserIndex.Box>
              </UserIndex.Box>

              <UserIndex.Box
                className="add-product-addmore-remove-btns"
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
              >
                <UserIndex.Box
                  className="primary-btn-box next-btn-box next-btn-box-remove"
                  sx={{
                    display: goldArr.length == 1 ? "block" : "flex",
                  }}
                >
                  <UserIndex.PrimaryButton
                    sx={{
                      my: 2,
                      textTransform: "none",
                    }}
                    onClick={() => {
                      minusGoldInput();
                    }}
                    btnclass="primary-btn next-btn"
                    buttonvalue="Remove"
                  />
                </UserIndex.Box>
              </UserIndex.Box>
            </>
          );
        })}

        {goldArr && goldArr.length ? (
          <>
            <UserIndex.Box
              className="add-product-addmore-remove-btns"
              gridColumn={{
                xs: "span 12",
                sm: "span 12",
                md: "span 12",
                lg: "span 12",
              }}
            >
              <UserIndex.Box className="primary-btn-box next-btn-box next-btn-box-add">
                <UserIndex.PrimaryButton
                  sx={{
                    my: 2,
                    textTransform: "none",
                  }}
                  onClick={addGoldInput}
                  btnclass="primary-btn next-btn"
                  buttonvalue="Add More"
                />
              </UserIndex.Box>
            </UserIndex.Box>
          </>
        ) : (
          ""
        )}
      </>
    );
  };

  const silverFields = () => {
    return (
      <>
        {silverArr?.map((item, i) => {
          return (
            <>
              <UserIndex.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
              >
                <UserIndex.Box className="addinfo-text-box">
                  <UserIndex.Typography variant="h2" className="addinfo-text">
                    {" "}
                    Silver
                  </UserIndex.Typography>
                </UserIndex.Box>
              </UserIndex.Box>
              <UserIndex.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 6",
                }}
              >
                <UserIndex.Box className="refrence-product-input addproduct-1-input-box">
                  <UserIndex.Box className="input-box addproduct-input-box">
                    <UserIndex.FormHelperText className="input-lable">
                      Purity (In %)
                      <span style={{ color: "red", fontSize: "15px" }}>
                        {" "}
                        *{" "}
                      </span>
                    </UserIndex.FormHelperText>
                    <UserIndex.TextValidator
                      fullWidth
                      id={i}
                      className="form-control border-none"
                      value={item?.purity}
                      name={"purity"}
                      onChange={(e) => {
                        handleSilverChange(e, "purity");
                      }}
                      type="number"
                    />
                    <UserIndex.FormHelperText className="error-input-lable">
                      {item[`purityError-${i}`]}
                    </UserIndex.FormHelperText>
                  </UserIndex.Box>
                </UserIndex.Box>
              </UserIndex.Box>
              <UserIndex.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 6",
                }}
              >
                <UserIndex.Box className="refrence-product-input">
                  <UserIndex.Box className="input-box addproduct-1-input-box">
                    <UserIndex.FormHelperText className="input-lable">
                      Weight (In Gram)
                      <span style={{ color: "red", fontSize: "15px" }}>
                        {" "}
                        *{" "}
                      </span>
                    </UserIndex.FormHelperText>
                    <UserIndex.TextValidator
                      fullWidth
                      id={i}
                      className="form-control border-none"
                      value={item?.weight}
                      name={"weight"}
                      onChange={(e) => {
                        handleSilverChange(e, "weight");
                      }}
                      type="number"
                    />
                    <UserIndex.FormHelperText className="error-input-lable">
                      {item[`weightError-${i}`]}
                    </UserIndex.FormHelperText>
                  </UserIndex.Box>
                </UserIndex.Box>
              </UserIndex.Box>

              <UserIndex.Box
                className="add-product-addmore-remove-btns"
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
              >
                <UserIndex.Box
                  className="primary-btn-box next-btn-box next-btn-box-remove"
                  style={{
                    display: silverArr.length == 1 ? "block" : "flex",
                  }}
                >
                  <UserIndex.PrimaryButton
                    sx={{
                      my: 2,
                      textTransform: "none",
                    }}
                    onClick={() => {
                      minusSilverInput();
                    }}
                    btnclass="primary-btn next-btn"
                    buttonvalue="Remove"
                  />
                </UserIndex.Box>
              </UserIndex.Box>
            </>
          );
        })}

        {silverArr && silverArr.length ? (
          <UserIndex.Box
            className="add-product-addmore-remove-btns"
            gridColumn={{
              xs: "span 12",
              sm: "span 12",
              md: "span 12",
              lg: "span 12",
            }}
          >
            <UserIndex.Box className="primary-btn-box next-btn-box next-btn-box-add">
              <UserIndex.PrimaryButton
                sx={{
                  my: 2,
                  textTransform: "none",
                }}
                onClick={addSilverInput}
                btnclass="primary-btn next-btn"
                buttonvalue="Add More"
              />
            </UserIndex.Box>
          </UserIndex.Box>
        ) : (
          ""
        )}
      </>
    );
  };

  const gemstoneFields = () => {
    return (
      <>
        <UserIndex.Box sx={{ display: "contents" }}>
          {arr?.map((item, i) => {
            return (
              <>
                <UserIndex.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 12",
                    lg: "span 12",
                  }}
                >
                  <UserIndex.Box className="addinfo-text-box">
                    <UserIndex.Typography variant="h2" className="addinfo-text">
                      Gemstone
                    </UserIndex.Typography>
                  </UserIndex.Box>
                </UserIndex.Box>
                <UserIndex.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 6",
                  }}
                >
                  <UserIndex.Box className="refrence-product-input addproduct-1-input-box">
                    <UserIndex.Box className="input-box addproduct-input-box">
                      <UserIndex.FormHelperText className="input-lable">
                        Name
                        <span style={{ color: "red", fontSize: "15px" }}>
                          {" "}
                          *{" "}
                        </span>
                      </UserIndex.FormHelperText>
                      <UserIndex.TextValidator
                        fullWidth
                        id={i}
                        className="form-control border-none"
                        value={item?.piece}
                        onChange={(e) => {
                          handleChange(e, "piece");
                        }}
                        type="text"
                      />
                      <UserIndex.FormHelperText className="error-input-lable">
                        {item[`pieceError-${i}`]}
                      </UserIndex.FormHelperText>
                    </UserIndex.Box>
                  </UserIndex.Box>
                </UserIndex.Box>
                <UserIndex.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 6",
                  }}
                >
                  <UserIndex.Box className="refrence-product-input">
                    <UserIndex.Box className="input-box addproduct-1-input-box">
                      <UserIndex.FormHelperText className="input-lable">
                        Quantity
                        <span style={{ color: "red", fontSize: "15px" }}>
                          {" "}
                          *{" "}
                        </span>
                      </UserIndex.FormHelperText>
                      <UserIndex.TextValidator
                        fullWidth
                        id={i}
                        className="form-control border-none"
                        value={item?.quantity}
                        onChange={(e) => {
                          handleChange(e, "quantity");
                        }}
                        type="number"
                      />
                      <UserIndex.FormHelperText className="error-input-lable">
                        {item[`quantityError-${i}`]}
                      </UserIndex.FormHelperText>
                    </UserIndex.Box>
                  </UserIndex.Box>
                </UserIndex.Box>
                <UserIndex.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 6",
                  }}
                >
                  <UserIndex.Box className="refrence-product-input addproduct-1-input-box">
                    <UserIndex.Box className="input-box addproduct-input-box">
                      <UserIndex.FormHelperText className="input-lable">
                        Purity (Carat)
                        <span style={{ color: "red", fontSize: "15px" }}>
                          {" "}
                          *{" "}
                        </span>
                      </UserIndex.FormHelperText>
                      <UserIndex.TextValidator
                        fullWidth
                        id={i}
                        className="form-control border-none"
                        value={item?.purity}
                        onChange={(e) => {
                          handleChange(e, "purity");
                        }}
                        type="number"
                      />
                      <UserIndex.FormHelperText className="error-input-lable">
                        {item[`purityError-${i}`]}
                      </UserIndex.FormHelperText>
                    </UserIndex.Box>
                  </UserIndex.Box>
                </UserIndex.Box>
                <UserIndex.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 6",
                  }}
                >
                  <UserIndex.Box className="refrence-product-input">
                    <UserIndex.Box className="input-box addproduct-1-input-box">
                      <UserIndex.FormHelperText className="input-lable">
                        Total Weight (gm)
                        <span style={{ color: "red", fontSize: "15px" }}>
                          {" "}
                          *{" "}
                        </span>
                      </UserIndex.FormHelperText>
                      <UserIndex.TextValidator
                        fullWidth
                        id={i}
                        className="form-control border-none"
                        value={item?.total_weight}
                        onChange={(e) => {
                          handleChange(e, "total_weight");
                        }}
                        type="number"
                      />
                      <UserIndex.FormHelperText className="error-input-lable">
                        {item[`totalWeightError-${i}`]}
                      </UserIndex.FormHelperText>
                    </UserIndex.Box>
                  </UserIndex.Box>
                </UserIndex.Box>
                <UserIndex.Box
                  className="add-product-addmore-remove-btns"
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 12",
                    lg: "span 12",
                  }}
                >
                  <UserIndex.Box
                    className="primary-btn-box next-btn-box next-btn-box-remove"
                    sx={{
                      display: arr.length == 1 ? "block" : "flex",
                    }}
                  >
                    <UserIndex.PrimaryButton
                      sx={{
                        my: 2,
                        textTransform: "none",
                      }}
                      onClick={() => {
                        minusInput();
                      }}
                      btnclass="primary-btn next-btn"
                      buttonvalue="Remove"
                    />
                  </UserIndex.Box>
                </UserIndex.Box>
              </>
            );
          })}

          {arr && arr.length ? (
            <>
              <UserIndex.Box
                className="add-product-addmore-remove-btns"
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
              >
                <UserIndex.Box className="primary-btn-box next-btn-box next-btn-box-add">
                  <UserIndex.PrimaryButton
                    sx={{
                      my: 2,
                      textTransform: "none",
                    }}
                    onClick={addInput}
                    btnclass="primary-btn next-btn"
                    buttonvalue="Add More"
                  />
                </UserIndex.Box>
              </UserIndex.Box>
            </>
          ) : (
            ""
          )}
        </UserIndex.Box>
      </>
    );
  };

  const diamondFields = () => {
    return (
      <>
        <UserIndex.Box sx={{ display: "contents" }}>
          {diamondArr?.map((item, i) => {
            return (
              <>
                <UserIndex.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 12",
                    lg: "span 12",
                  }}
                >
                  <UserIndex.Box className="addinfo-text-box">
                    <UserIndex.Typography variant="h2" className="addinfo-text">
                      Diamond
                    </UserIndex.Typography>
                  </UserIndex.Box>
                </UserIndex.Box>
                <UserIndex.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 6",
                  }}
                >
                  <UserIndex.Box className="refrence-product-input addproduct-1-input-box">
                    <UserIndex.Box className="input-box addproduct-input-box">
                      <UserIndex.FormHelperText className="input-lable">
                        Name
                        <span style={{ color: "red", fontSize: "15px" }}>
                          {" "}
                          *{" "}
                        </span>
                      </UserIndex.FormHelperText>
                      <UserIndex.TextValidator
                        fullWidth
                        id={i}
                        className="form-control border-none"
                        value={item?.piece}
                        onChange={(e) => {
                          handleDiamondChange(e, "piece");
                        }}
                        type="text"
                      />
                      <UserIndex.FormHelperText className="error-input-lable">
                        {item[`pieceError-${i}`]}
                      </UserIndex.FormHelperText>
                    </UserIndex.Box>
                  </UserIndex.Box>
                </UserIndex.Box>
                <UserIndex.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 6",
                  }}
                >
                  <UserIndex.Box className="refrence-product-input">
                    <UserIndex.Box className="input-box addproduct-1-input-box">
                      <UserIndex.FormHelperText className="input-lable">
                        Quantity
                        <span style={{ color: "red", fontSize: "15px" }}>
                          {" "}
                          *{" "}
                        </span>
                      </UserIndex.FormHelperText>
                      <UserIndex.TextValidator
                        fullWidth
                        id={i}
                        className="form-control border-none"
                        value={item?.quantity}
                        onChange={(e) => {
                          handleDiamondChange(e, "quantity");
                        }}
                        type="number"
                      />
                      <UserIndex.FormHelperText className="error-input-lable">
                        {item[`quantityError-${i}`]}
                      </UserIndex.FormHelperText>
                    </UserIndex.Box>
                  </UserIndex.Box>
                </UserIndex.Box>
                <UserIndex.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 6",
                  }}
                >
                  <UserIndex.Box className="refrence-product-input addproduct-1-input-box">
                    <UserIndex.Box className="input-box addproduct-input-box">
                      <UserIndex.FormHelperText className="input-lable">
                        Purity (Carat)
                        <span style={{ color: "red", fontSize: "15px" }}>
                          {" "}
                          *{" "}
                        </span>
                      </UserIndex.FormHelperText>
                      <UserIndex.TextValidator
                        fullWidth
                        id={i}
                        className="form-control border-none"
                        value={item?.purity}
                        onChange={(e) => {
                          handleDiamondChange(e, "purity");
                        }}
                        type="number"
                      />
                      <UserIndex.FormHelperText className="error-input-lable">
                        {item[`purityError-${i}`]}
                      </UserIndex.FormHelperText>
                    </UserIndex.Box>
                  </UserIndex.Box>
                </UserIndex.Box>
                <UserIndex.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 6",
                  }}
                >
                  <UserIndex.Box className="refrence-product-input">
                    <UserIndex.Box className="input-box addproduct-1-input-box">
                      <UserIndex.FormHelperText className="input-lable">
                        Total Weight (gm)
                        <span style={{ color: "red", fontSize: "15px" }}>
                          {" "}
                          *{" "}
                        </span>
                      </UserIndex.FormHelperText>
                      <UserIndex.TextValidator
                        fullWidth
                        id={i}
                        className="form-control border-none"
                        value={item?.total_weight}
                        onChange={(e) => {
                          handleDiamondChange(e, "total_weight");
                        }}
                        type="number"
                      />
                      <UserIndex.FormHelperText className="error-input-lable">
                        {item[`totalWeightError-${i}`]}
                      </UserIndex.FormHelperText>
                    </UserIndex.Box>
                  </UserIndex.Box>
                </UserIndex.Box>
                <UserIndex.Box
                  className="add-product-addmore-remove-btns"
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 12",
                    lg: "span 12",
                  }}
                >
                  <UserIndex.Box
                    className="primary-btn-box next-btn-box next-btn-box-remove"
                    sx={{
                      display: diamondArr.length == 1 ? "block" : "flex",
                    }}
                  >
                    <UserIndex.PrimaryButton
                      sx={{
                        my: 2,
                        textTransform: "none",
                      }}
                      onClick={() => {
                        minusDiamondInput();
                      }}
                      btnclass="primary-btn next-btn"
                      buttonvalue="Remove"
                    />
                  </UserIndex.Box>
                </UserIndex.Box>
              </>
            );
          })}

          {diamondArr && diamondArr.length ? (
            <>
              <UserIndex.Box
                className="add-product-addmore-remove-btns"
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
              >
                <UserIndex.Box className="primary-btn-box next-btn-box next-btn-box-add">
                  <UserIndex.PrimaryButton
                    sx={{
                      my: 2,
                      textTransform: "none",
                      display:
                        material.includes("Gold") || material.includes("Silver")
                          ? "none"
                          : "block",
                    }}
                    onClick={addDiamondInput}
                    btnclass="primary-btn next-btn"
                    buttonvalue="Add More"
                  />
                </UserIndex.Box>
              </UserIndex.Box>
            </>
          ) : (
            ""
          )}
        </UserIndex.Box>
      </>
    );
  };

  const renderFieldMaterialWise = () => {
    switch (true) {
      case material.includes("Gold") && material?.length === 1:
        return goldFields();
      case material.includes("Silver") && material?.length === 1:
        return silverFields();
      case material.includes("Diamond") && material?.length === 1:
        return diamondFields();
      case material.includes("Gemstone") && material?.length === 1:
        return gemstoneFields();
      case material.includes("Gold") &&
        material.includes("Diamond") &&
        material?.length === 2:
        return (
          <>
            {goldFields()}
            {diamondFields()}
          </>
        );
      case material.includes("Gold") &&
        material.includes("Gemstone") &&
        material?.length === 2:
        return (
          <>
            {goldFields()}
            {gemstoneFields()}
          </>
        );
      case material.includes("Silver") &&
        material.includes("Diamond") &&
        material?.length === 2:
        return (
          <>
            {silverFields()}
            {diamondFields()}
          </>
        );
      case material.includes("Silver") &&
        material.includes("Gemstone") &&
        material?.length === 2:
        return (
          <>
            {silverFields()}
            {gemstoneFields()}
          </>
        );
      case material.includes("Silver") &&
        material.includes("Gold") &&
        material?.length === 2:
        return (
          <>
            {silverFields()}
            {goldFields()}
          </>
        );
      case material.includes("Gemstone") &&
        material.includes("Diamond") &&
        material?.length === 2:
        return (
          <>
            {gemstoneFields()}
            {diamondFields()}
          </>
        );
      case material.includes("Gold") &&
        material.includes("Silver") &&
        material.includes("Diamond") &&
        material?.length === 3:
        return (
          <>
            {silverFields()}
            {goldFields()}
            {diamondFields()}
          </>
        );
      case material.includes("Gold") &&
        material.includes("Silver") &&
        material.includes("Gemstone") &&
        material?.length === 3:
        return (
          <>
            {silverFields()}
            {goldFields()}
            {gemstoneFields()}
          </>
        );
      case material.includes("Gold") &&
        material.includes("Diamond") &&
        material.includes("Gemstone") &&
        material?.length === 3:
        return (
          <>
            {goldFields()}
            {diamondFields()}
            {gemstoneFields()}
          </>
        );
      case material.includes("Silver") &&
        material.includes("Diamond") &&
        material.includes("Gemstone") &&
        material?.length === 3:
        return (
          <>
            {silverFields()}
            {gemstoneFields()}
            {diamondFields()}
          </>
        );
      case material.includes("Gold") &&
        material.includes("Silver") &&
        material.includes("Gemstone") &&
        material.includes("Diamond") &&
        material?.length === 4:
        return (
          <>
            {goldFields()}
            {silverFields()}
            {gemstoneFields()}
            {diamondFields()}
          </>
        );

      default:
        return <h1>No Form found</h1>;
    }
  };

  const goldArrayValidation = (GoldFieldsArr) => {
    let errors = GoldFieldsArr.map((data, i) => {
      return {
        ...data,
        [`purityError-${i}`]: `${
          data.purity === ""
            ? "Please enter purity"
            : Number(data.purity) <= 0.99
            ? "Purity cannot be zero"
            : ""
        }`,
        [`weightError-${i}`]: `${
          data.weight === ""
            ? "Please enter weight"
            : Number(data.weight) <= 0.99
            ? "Weight cannot be zero"
            : ""
        }`,
        isValidObj:
          data.purity === "" ||
          Number(data.purity) <= 0.99 ||
          data.weight === "" ||
          Number(data.weight) <= 0.99
            ? false
            : true,
      };
    });
    setGoldArr(errors);
    return material.includes("Gold") ? checkValidAllArray(errors) : true;
  };

  const silverArrayValidation = (SilverFieldsArr) => {
    let silverErrors = SilverFieldsArr.map((data, i) => {
      return {
        ...data,
        [`purityError-${i}`]: `${
          data.purity === ""
            ? "Please enter purity"
            : Number(data.purity) <= 0.99
            ? "Purity cannot be zero"
            : ""
        }`,
        [`weightError-${i}`]: `${
          data.weight === ""
            ? "Please enter weight"
            : Number(data.weight) <= 0.99
            ? "Weight cannot be zero"
            : ""
        }`,
        isValidObj:
          data.purity === "" ||
          Number(data.purity) <= 0.99 ||
          data.weight === "" ||
          Number(data.weight) <= 0.99
            ? false
            : true,
      };
    });
    setSilverArr(silverErrors);
    return material.includes("Silver")
      ? checkValidAllArray(silverErrors)
      : true;
  };

  const gemstoneArrayValidation = (inputArr) => {
    let gemstoneErrors = inputArr.map((data, i) => {
      const regex = new RegExp(/^[A-Za-z0-9\s]+$/);
      return {
        ...data,
        [`pieceError-${i}`]: `${
          data.piece === ""
            ? "Please enter name"
            : !regex.test(data.piece)
            ? "Please Enter Only AlphaNumeric Value"
            : ""
        }`,
        [`quantityError-${i}`]: `${
          data.quantity > 20 || data.quantity < 1
            ? "Please enter quantity between 1 to 20"
            : ""
        }`,
        [`purityError-${i}`]: `${
          data.purity === ""
            ? "Please enter purity"
            : Number(data.purity) <= 0.99
            ? "Purity cannot be zero"
            : ""
        }`,
        [`totalWeightError-${i}`]: `${
          data.total_weight === ""
            ? "Please enter total weight"
            : Number(data.total_weight) <= 0.99
            ? "Total weight cannot be zero"
            : ""
        }`,

        isValidObj:
          data.piece === "" ||
          regex.test(data.piece) ||
          data.quantity > 20 ||
          data.quantity < 1 ||
          data.purity === "" ||
          Number(data.purity) <= 0.99 ||
          data.total_weight === "" ||
          Number(data.total_weight) <= 0.99
            ? false
            : true,
      };
    });
    setArr(gemstoneErrors);
    return material.includes("Gemstone")
      ? checkValidAllArray(gemstoneErrors)
      : true;
  };

  const diamondArrayValidation = (DiamonsFieldsArr) => {
    let diamondErrors = DiamonsFieldsArr.map((data, i) => {
      const regex = new RegExp(/^[A-Za-z0-9\s]+$/);
      return {
        ...data,
        [`pieceError-${i}`]: `${
          data.piece === ""
            ? "Please enter name"
            : !regex.test(data.piece)
            ? "Please Enter Only AlphaNumeric Value"
            : ""
        }`,
        [`quantityError-${i}`]: `${
          data.quantity > 20 || data.quantity < 1
            ? "Please enter quantity between 1 to 20"
            : ""
        }`,
        [`purityError-${i}`]: `${
          data.purity === ""
            ? "Please enter purity"
            : Number(data.purity) <= 0.99
            ? "Purity cannot be zero"
            : ""
        }`,
        [`totalWeightError-${i}`]: `${
          data.total_weight === ""
            ? "Please enter total weight"
            : Number(data.total_weight) <= 0.99
            ? "Total weight cannot be zero"
            : ""
        }`,

        isValidObj:
          data.piece === "" ||
          regex.test(data.piece) ||
          data.quantity > 20 ||
          data.quantity < 1 ||
          data.purity === "" ||
          Number(data.purity) <= 0.99 ||
          data.total_weight === "" ||
          Number(data.total_weight) <= 0.99
            ? false
            : true,
      };
    });
    setDiamondArr(diamondErrors);
    return material.includes("Diamond")
      ? checkValidAllArray(diamondErrors)
      : true;
  };

  const errorHandling = (e) => {
    const regex = new RegExp(/^[A-Za-z0-9.,\s]+$/);
    if (images.length == 0) {
      setImageError("Please select product images");
    } else {
      setImageError("");
    }

    if (oldImages?.length == 0) {
      setOldImagesError("Please select product images");
    } else {
      setOldImagesError("");
    }

    if (item === undefined) {
      setItemError("Please select item");
    } else {
      setItemError("");
    }

    // if (!regex.test(description.specify)) {
    //   setSpecifyError("Please Enter Only AlphaNumeric Value");
    // } else if (description.specify === "") {
    //   setSpecifyError("Please enter specify");
    // } else {
    //   setSpecifyError("");
    // }

    if (description.specify === "" || description.specify == undefined) {
      setSpecifyError("Please enter specify");
    } else {
      setSpecifyError("");
    }

    // if (!regex.test(description.additional_details)) {
    //   setAdditional_detailsError("Please Enter Only AlphaNumeric Value");
    // } else if (description.additional_details === "") {
    //   setAdditional_detailsError("Please enter additional details");
    // } else {
    //   setAdditional_detailsError("");
    // }

    if (
      description.additional_details === "" ||
      description.additional_details == undefined
    ) {
      setAdditional_detailsError("Please enter additional details");
    } else {
      setAdditional_detailsError("");
    }

    if (material.length === 0) {
      setMaterialError("Please select material");
    } else {
      setMaterialError("");
    }

    if (category === undefined) {
      setCategoryDataError("Please select category");
    } else {
      setCategoryDataError("");
    }
  };

  const nextPage = () => {
    errorHandling();
    const regex = new RegExp(/^[A-Za-z0-9.,\s]+$/);
    if (
      images.length !== 0 &&
      material.length !== 0 &&
      category !== "" &&
      item !== "" &&
      regex.test(description.specify) &&
      description.specify !== "" &&
      regex.test(description.additional_details) &&
      description.additional_details !== ""
    ) {
      setShow(false);
    } else if (
      oldImages.length !== 0 &&
      material.length !== 0 &&
      category !== "" &&
      item !== "" &&
      regex.test(description.specify) &&
      description.specify !== "" &&
      regex.test(description.additional_details) &&
      description.additional_details !== ""
    ) {
      setShow(false);
    }
  };

  const secPageErrorHandling = () => {
    if (dimension.inner_circumference === "") {
      setInnerCircumference("");
    } else {
      if (dimension.inner_circumference === "0") {
        setInnerCircumference("Inner circumference cannot be 0");
      } else {
        setInnerCircumference("");
      }
    }

    if (dimension.length.length < 1) {
      setLengthError("");
    } else {
      if (dimension.length === "0") {
        setLengthError("Length cannot be 0");
      } else {
        setLengthError("");
      }
    }

    if (dimension.depth == "") {
      setDepthError("");
    } else {
      if (dimension.depth === "0") {
        setDepthError("Depth cannot be 0");
      } else {
        setLengthError("");
      }
    }

    if (dimension.meta_title === "") {
      setMetaTitleError("Please enter meta title");
    } else if (dimension.meta_title?.length < 6) {
      setMetaTitleError("Please enter minimum 6 character meta title ");
    } else {
      setMetaTitleError("");
    }

    if (dimension.meta_description === "") {
      setMetaDescriptionError("Please enter meta description");
    } else {
      setMetaDescriptionError("");
    }
  };

  const CreateProduct = (e, id) => {
    secPageErrorHandling();
    goldArrayValidation(goldArr);
    silverArrayValidation(silverArr);
    gemstoneArrayValidation(arr);
    diamondArrayValidation(diamondArr);
    // if (
    //   dimension.inner_circumference === "" ||
    //   dimension.length === "" ||
    //   dimension.depth === "" ||
    //   dimension.inner_circumference === "0" ||
    //   dimension.length === "0" ||
    //   dimension.depth === "0" ||
    //   dimension.meta_title === "" ||
    //   dimension.meta_title?.length < 6 ||
    //   dimension.meta_description === "" ||
    //   goldArrayValidation(goldArr) ||
    //   silverArrayValidation(silverArr) ||
    //   gemstoneArrayValidation(arr) ||
    //   diamondArrayValidation(diamondArr)
    // ) {
    //   return;
    // }
    if (
      dimension.inner_circumference == "" ||
      dimension.length == "" ||
      dimension.depth == "" ||
      dimension.inner_circumference !== "0" ||
      dimension.length !== "0" ||
      dimension.depth !== "0" ||
      dimension.meta_title !== "" ||
      dimension.meta_title?.length > 6 ||
      dimension.meta_description !== "" ||
      goldArrayValidation(goldArr) ||
      silverArrayValidation(silverArr) ||
      gemstoneArrayValidation(arr) ||
      diamondArrayValidation(diamondArr)
    ) {
      handleSave(e, id);
      // alert("CallEnd");
    }
  };

  const [isSubmit, setIsSubmit] = useState("");

  const handleSave = async (e, id) => {
    let materialArray = [];
    material.map((item) => {
      let temp = materialList.filter((a) => a.material == item);
      materialArray.push({ material_id: temp[0]._id });
    });
    let formdata = new FormData();
    for (const key of Object.keys(images)) {
      formdata.append("images", images[key]);
    }
    formdata.append("item_id", itemId);
    formdata.append("category_id", categoryId);
    formdata.append("materialIdArray", JSON.stringify(materialArray));
    formdata.append("specify", description.specify?.trim());
    formdata.append(
      "additional_details",
      description.additional_details?.trim()
    );
    formdata.append(
      "inner_circumference",
      dimension.inner_circumference?.trim()
    );

    // switch (true) {
    // }
    if (material.includes("Gold") && material.length === 1) {
      formdata.append("gold", JSON.stringify(goldArr));
    } else if (material.includes("Silver") && material.length === 1) {
      formdata.append("silver", JSON.stringify(silverArr));
    } else if (material.includes("Gemstone") && material.length === 1) {
      formdata.append("gemstoneArray", JSON.stringify(arr));
    } else if (material.includes("Diamond") && material.length === 1) {
      formdata.append("diamondArray", JSON.stringify(diamondArr));
    } else if (
      material.includes("Gold") &&
      material.includes("Diamond") &&
      material.length === 2
    ) {
      formdata.append("gold", JSON.stringify(goldArr));
      formdata.append("diamondArray", JSON.stringify(diamondArr));
    } else if (
      material.includes("Gold") &&
      material.includes("Gemstone") &&
      material.length === 2
    ) {
      formdata.append("gold", JSON.stringify(goldArr));
      formdata.append("gemstoneArray", JSON.stringify(arr));
    } else if (
      material.includes("Silver") &&
      material.includes("Diamond") &&
      material.length === 2
    ) {
      formdata.append("silver", JSON.stringify(silverArr));
      formdata.append("diamondArray", JSON.stringify(diamondArr));
    } else if (
      material.includes("Silver") &&
      material.includes("Gemstone") &&
      material.length === 2
    ) {
      formdata.append("silver", JSON.stringify(silverArr));
      formdata.append("gemstoneArray", JSON.stringify(arr));
    } else if (
      material.includes("Silver") &&
      material.includes("Gold") &&
      material.length === 2
    ) {
      formdata.append("gold", JSON.stringify(goldArr));
      formdata.append("silver", JSON.stringify(silverArr));
    } else if (
      material.includes("Diamond") &&
      material.includes("Gemstone") &&
      material.length === 2
    ) {
      formdata.append("gemstoneArray", JSON.stringify(arr));
      formdata.append("diamondArray", JSON.stringify(diamondArr));
    } else if (
      material.includes("Silver") &&
      material.includes("Gold") &&
      material.includes("Diamond") &&
      material.length === 3
    ) {
      formdata.append("gold", JSON.stringify(goldArr));
      formdata.append("silver", JSON.stringify(silverArr));
      formdata.append("diamondArray", JSON.stringify(diamondArr));
    } else if (
      material.includes("Silver") &&
      material.includes("Gold") &&
      material.includes("Gemstone") &&
      material.length === 3
    ) {
      formdata.append("gold", JSON.stringify(goldArr));
      formdata.append("silver", JSON.stringify(silverArr));
      formdata.append("gemstoneArray", JSON.stringify(arr));
    } else if (
      material.includes("Diamond") &&
      material.includes("Gold") &&
      material.includes("Gemstone") &&
      material.length === 3
    ) {
      formdata.append("diamondArray", JSON.stringify(diamondArr));
      formdata.append("gold", JSON.stringify(goldArr));
      formdata.append("gemstoneArray", JSON.stringify(arr));
    } else if (
      material.includes("Diamond") &&
      material.includes("Silver") &&
      material.includes("Gemstone") &&
      material.length === 3
    ) {
      formdata.append("diamondArray", JSON.stringify(diamondArr));
      formdata.append("silver", JSON.stringify(silverArr));
      formdata.append("gemstoneArray", JSON.stringify(arr));
    } else if (
      material.includes("Silver") &&
      material.includes("Gold") &&
      material.includes("Gemstone") &&
      material.includes("Diamond") &&
      material.length === 4
    ) {
      formdata.append("gold", JSON.stringify(goldArr));
      formdata.append("silver", JSON.stringify(silverArr));
      formdata.append("gemstoneArray", JSON.stringify(arr));
      formdata.append("diamondArray", JSON.stringify(diamondArr));
    }

    if (oneProductData?.[0]?.status == "2") {
      formdata.append("status", 0);
    }
    formdata.append("length", dimension.length?.trim());
    formdata.append("depth", dimension.depth?.trim());
    formdata.append("meta_title", dimension.meta_title?.trim());
    formdata.append("meta_description", dimension.meta_description?.trim());
    if (id == 1) {
      formdata.append("isSubmitted", id);
      history.push("/user/requestproduct1");
    }
    if (params.id) {
      formdata.append("id", params.id);
      formdata.append("oldurlarray", JSON.stringify(oldImages));
    }
    dispatch(RaiseCustomProductSaga(formdata, navigate, Token));
    setClicked(true);
    history.push("/user/customeproduct");
  };

  const onTop = () => {
    window.scrollTo({ top: 0 });
  };

  useEffect(() => {
    onTop();
  }, []);

  return (
    <div>
      <UserIndex.Header />
      <UserIndex.ValidatorForm
      // onClick={(e) => {
      //   handleSave(e);
      //   secPageErrorHandling(e);
      // }}
      >
        {show ? (
          <UserIndex.Box className="addproduct-sec">
            <UserIndex.Box className="container">
              <UserIndex.Box sx={{ width: 1 }} className="grid-main">
                <UserIndex.Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={{ xs: 2, sm: 2, md: 0, lg: 5 }}
                >
                  <UserIndex.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 8",
                      lg: "span 5",
                    }}
                    className="grid-column"
                  >
                    <Item className="grid-item">
                      <UserIndex.Box className="add-product-left">
                        <UserIndex.Box className="add-upload-bg">
                          <UserIndex.Button
                            variant="contained"
                            component="label"
                            className="add-upload-btn"
                          >
                            + Upload Pictures
                            <input
                              hidden
                              accept="image/png, image/jpeg"
                              multiple
                              type="file"
                              onChange={(e) => {
                                handleChangeImage(e);
                              }}
                            />
                          </UserIndex.Button>
                        </UserIndex.Box>
                      </UserIndex.Box>
                      <UserIndex.Box className="addproduct-textarea-main input-box ">
                        <UserIndex.Box sx={{ marginTop: "15px" }}>
                          <UserIndex.FormHelperText className="input-lable">
                            Product Images
                            <span style={{ color: "red", fontSize: "15px" }}>
                              {" "}
                              *{" "}
                            </span>
                          </UserIndex.FormHelperText>
                        </UserIndex.Box>
                        <UserIndex.Box sx={{ position: "relative" }}>
                          <>
                            <UserIndex.FormHelperText className="error-input-lable">
                              {imageError}
                              {setOldImagesError}
                            </UserIndex.FormHelperText>
                          </>

                          <UserIndex.Grid sx={{ width: "100%" }} container>
                            {oldImages?.map((data) => {
                              return (
                                <>
                                  <UserIndex.Grid item xs={6}>
                                    <UserIndex.Box
                                      sx={{
                                        position: "relative",
                                        padding: "10px",
                                      }}
                                    >
                                      <UserIndex.IconButton
                                        onClick={() => removeOldImages(data)}
                                        sx={{
                                          position: "absolute",
                                          left: "80%",
                                          color: "#c29958",
                                          zIndex: "1",
                                        }}
                                        aria-label="upload picture"
                                        component="label"
                                      >
                                        <DisabledByDefaultIcon />
                                      </UserIndex.IconButton>
                                      <img
                                        src={data}
                                        style={{
                                          width: "100%",
                                          height: "130px",
                                        }}
                                      />
                                    </UserIndex.Box>
                                  </UserIndex.Grid>
                                </>
                              );
                            })}
                            {images
                              ? images?.map((data) => {
                                  return (
                                    <>
                                      <UserIndex.Grid item xs={6}>
                                        <UserIndex.Box
                                          sx={{
                                            position: "relative",
                                            padding: "10px",
                                          }}
                                        >
                                          <UserIndex.IconButton
                                            onClick={() =>
                                              removePreviewImage(data)
                                            }
                                            sx={{
                                              position: "absolute",
                                              left: "80%",
                                              color: "#c29958",
                                              zIndex: "1",
                                            }}
                                            aria-label="upload picture"
                                            component="label"
                                          >
                                            <DisabledByDefaultIcon />
                                          </UserIndex.IconButton>
                                          <img
                                            src={URL.createObjectURL(data)}
                                            style={{
                                              my: 2,
                                              width: "100%",
                                              height: "130px",
                                            }}
                                          />
                                        </UserIndex.Box>
                                      </UserIndex.Grid>
                                    </>
                                  );
                                })
                              : ""}
                          </UserIndex.Grid>
                        </UserIndex.Box>
                      </UserIndex.Box>
                    </Item>
                  </UserIndex.Box>
                  <UserIndex.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 4",
                      lg: "span 7",
                    }}
                    className="grid-column"
                  >
                    <Item className="grid-item">
                      <UserIndex.Box className="add-product-right">
                        <UserIndex.Box sx={{ width: 1 }}>
                          <UserIndex.Box
                            display="grid"
                            gridTemplateColumns="repeat(12, 1fr)"
                            gap={{ xs: 2, sm: 5, md: 1, lg: 1 }}
                          >
                            <UserIndex.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 12",
                                md: "span 12",
                                lg: "span 12",
                              }}
                            >
                              <UserIndex.Box className="addinfo-text-box">
                                <UserIndex.Typography
                                  variant="h2"
                                  className="addinfo-text"
                                >
                                  Product Description:
                                </UserIndex.Typography>
                              </UserIndex.Box>
                            </UserIndex.Box>
                            <UserIndex.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 6",
                              }}
                            >
                              <UserIndex.Box className="input-box ">
                                <UserIndex.FormHelperText className="input-lable">
                                  Item
                                  <span
                                    style={{ color: "red", fontSize: "15px" }}
                                  >
                                    {" "}
                                    *{" "}
                                  </span>
                                </UserIndex.FormHelperText>
                                <UserIndex.FormControl
                                  sx={{ maxWidth: "305px" }}
                                  className="membership-formcontrol"
                                >
                                  <UserIndex.Select
                                    className="membershop-select custom-dropdown-arrow"
                                    value={item}
                                    onChange={(e) => {
                                      handleChangeItem(e);
                                    }}
                                    displayEmpty
                                    inputProps={{
                                      "aria-label": "Without label",
                                    }}
                                    renderValue={(selected) => {
                                      if (!selected) {
                                        return (
                                          <em
                                            sx={{
                                              fontFamily: "mulish-semibold",
                                              color: "#8a8a8f",
                                            }}
                                          >
                                            Select Item
                                          </em>
                                        );
                                      }
                                      return selected;
                                    }}
                                  >
                                    {itemList?.length ? (
                                      itemList.map((data) => {
                                        return (
                                          <UserIndex.MenuItem
                                            value={data?.item}
                                          >
                                            {data?.item}
                                          </UserIndex.MenuItem>
                                        );
                                      })
                                    ) : (
                                      <UserIndex.MenuItem value="123">
                                        <em>None</em>
                                      </UserIndex.MenuItem>
                                    )}
                                  </UserIndex.Select>
                                  {/* <span>
                                    <img
                                      src={UserIndex.png.blackdown}
                                      className="black-down-img"
                                    ></img>
                                  </span> */}
                                </UserIndex.FormControl>
                                <UserIndex.FormHelperText className="error-input-lable">
                                  {itemError}
                                </UserIndex.FormHelperText>
                              </UserIndex.Box>
                            </UserIndex.Box>
                            <UserIndex.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 6",
                              }}
                            >
                              <UserIndex.Box className="refrence-product-input addproduct-drop-box">
                                <UserIndex.Box className="input-box">
                                  <UserIndex.FormHelperText className="input-lable">
                                    Material
                                    <span
                                      style={{ color: "red", fontSize: "15px" }}
                                    >
                                      {" "}
                                      *{" "}
                                    </span>
                                  </UserIndex.FormHelperText>
                                  <UserIndex.FormControl className="membership-formcontrol">
                                    <UserIndex.Select
                                      multiple
                                      className="membershop-select custom-dropdown-arrow"
                                      value={material}
                                      onChange={(e) => {
                                        handleChangeMaterial(e);
                                      }}
                                      inputProps={{
                                        "aria-label": "Without label",
                                      }}
                                      displayEmpty
                                      renderValue={(selected) =>
                                        selected.length !== 0 ? (
                                          <div style={{ padding: "0px" }}>
                                            {selected.join(", ")}
                                          </div>
                                        ) : (
                                          "Select Material"
                                        )
                                      }
                                    >
                                      {materialList?.length ? (
                                        materialList?.map((item) => {
                                          return (
                                            <UserIndex.MenuItem
                                              value={item?.material}
                                            >
                                              {item.material}
                                            </UserIndex.MenuItem>
                                          );
                                        })
                                      ) : (
                                        <UserIndex.MenuItem value="123">
                                          <em>None</em>
                                        </UserIndex.MenuItem>
                                      )}
                                    </UserIndex.Select>
                                    {/* <span>
                                      <img
                                        src={UserIndex.png.blackdown}
                                        className="black-down-img"
                                      ></img>
                                    </span> */}
                                  </UserIndex.FormControl>
                                  <UserIndex.FormHelperText className="error-input-lable">
                                    {materialError}
                                  </UserIndex.FormHelperText>
                                </UserIndex.Box>
                              </UserIndex.Box>
                            </UserIndex.Box>
                            <UserIndex.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 6",
                              }}
                            >
                              <UserIndex.Box className="refrence-product-input addproduct-drop-box">
                                <UserIndex.Box className="input-box">
                                  <UserIndex.FormHelperText className="input-lable">
                                    Category
                                    <span
                                      style={{ color: "red", fontSize: "15px" }}
                                    >
                                      {" "}
                                      *{" "}
                                    </span>
                                  </UserIndex.FormHelperText>
                                  <UserIndex.FormControl className="membership-formcontrol">
                                    <UserIndex.Select
                                      className="membershop-select custom-dropdown-arrow"
                                      value={category}
                                      onChange={(e) => {
                                        handleChangeCategory(e);
                                      }}
                                      displayEmpty
                                      inputProps={{
                                        "aria-label": "Without label",
                                      }}
                                      renderValue={(selected) => {
                                        if (!selected) {
                                          return (
                                            <em
                                              sx={{
                                                fontFamily:
                                                  "mulish-semibold !important",
                                                color: "#8a8a8f",
                                              }}
                                            >
                                              Select Category
                                            </em>
                                          );
                                        }
                                        return selected;
                                      }}
                                    >
                                      {categoryData?.length ? (
                                        categoryData?.map((item) => {
                                          return (
                                            <UserIndex.MenuItem
                                              value={item?.category_name}
                                            >
                                              {item.category_name}
                                            </UserIndex.MenuItem>
                                          );
                                        })
                                      ) : (
                                        <UserIndex.MenuItem value="123">
                                          <em>None</em>
                                        </UserIndex.MenuItem>
                                      )}
                                    </UserIndex.Select>
                                    {/* <span>
                                      <img
                                        src={UserIndex.png.blackdown}
                                        className="black-down-img"
                                      ></img>
                                    </span> */}
                                    <UserIndex.FormHelperText className="error-input-lable">
                                      {categoryDataError}
                                    </UserIndex.FormHelperText>
                                  </UserIndex.FormControl>
                                </UserIndex.Box>
                              </UserIndex.Box>
                            </UserIndex.Box>
                            <UserIndex.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 6",
                              }}
                            >
                              <UserIndex.Box className="refrence-product-input">
                                <UserIndex.Box className="input-box addproduct-input-box">
                                  <UserIndex.FormHelperText className="input-lable">
                                    Specify
                                    <span
                                      style={{ color: "red", fontSize: "15px" }}
                                    >
                                      {" "}
                                      *{" "}
                                    </span>
                                  </UserIndex.FormHelperText>
                                  <UserIndex.TextValidator
                                    fullWidth
                                    id="specify"
                                    className="form-control border-none"
                                    placeholder="Specify"
                                    value={description.specify}
                                    name="specify"
                                    onChange={(e) => {
                                      handleChangeProductDescription(e);
                                    }}
                                    type="text"
                                    validators={["required"]}
                                    onKeyPress={(e) => {
                                      // Check if the pressed key is a special symbol or a number
                                      if (
                                        /[^\w\s]/.test(e.key) ||
                                        /\d/.test(e.key)
                                      ) {
                                        e.preventDefault();
                                      }
                                    }}
                                  />
                                  <UserIndex.FormHelperText className="error-input-lable">
                                    {specifyError}
                                  </UserIndex.FormHelperText>
                                </UserIndex.Box>
                              </UserIndex.Box>
                            </UserIndex.Box>
                            <UserIndex.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 12",
                                md: "span 12",
                                lg: "span 12",
                              }}
                            >
                              <UserIndex.Box className="addproduct-textarea-main input-box ">
                                <UserIndex.FormHelperText className="input-lable">
                                  Additional Details
                                  <span
                                    style={{ color: "red", fontSize: "15px" }}
                                  >
                                    {" "}
                                    *{" "}
                                  </span>
                                </UserIndex.FormHelperText>
                                <UserIndex.TextareaAutosize
                                  aria-label="empty textarea"
                                  // placeholder="Empty"
                                  id="additional_details"
                                  placeholder="Additional Details"
                                  className="form-control border-none addproduct-textarea"
                                  value={description.additional_details}
                                  name="additional_details"
                                  onChange={(e) => {
                                    handleChangeProductDescription(e);
                                  }}
                                  type="text"
                                  onKeyPress={(e) => {
                                    // Check if the pressed key is a special symbol or a number
                                    if (
                                      /[^\w\s]/.test(e.key) ||
                                      /\d/.test(e.key)
                                    ) {
                                      e.preventDefault();
                                    }
                                  }}
                                  validators={["required"]}
                                />
                                <UserIndex.FormHelperText className="error-input-lable">
                                  {additional_detailsError}
                                </UserIndex.FormHelperText>
                              </UserIndex.Box>
                              <UserIndex.Typography
                                sx={{ color: "#d32f2f", textAlign: "left" }}
                              >
                                Note : Please mention the reference product Id
                                if you would like the seller to refer to already
                                uploaded product
                              </UserIndex.Typography>
                            </UserIndex.Box>
                            <UserIndex.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 12",
                                md: "span 12",
                                lg: "span 12",
                              }}
                            ></UserIndex.Box>
                            <UserIndex.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 12",
                                md: "span 12",
                                lg: "span 12",
                              }}
                            >
                              <UserIndex.Box
                                sx={{ display: "flex", justifyContent: "end" }}
                                className="primary-btn-box next-btn-box"
                              >
                                <UserIndex.PrimaryButton
                                  btnclass="primary-btn next-btn"
                                  onClick={(e) => {
                                    // errorHandling()
                                    nextPage(e);
                                    window.scrollTo({ top: 0 });
                                  }}
                                  buttonvalue="Next"
                                />
                              </UserIndex.Box>
                            </UserIndex.Box>
                          </UserIndex.Box>
                        </UserIndex.Box>
                      </UserIndex.Box>
                    </Item>
                  </UserIndex.Box>
                </UserIndex.Box>
              </UserIndex.Box>
            </UserIndex.Box>
          </UserIndex.Box>
        ) : (
          <UserIndex.Box className="addproduct-1-sec">
            <UserIndex.Box className="container">
              <UserIndex.Box sx={{ width: 1 }}>
                <UserIndex.Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={1}
                >
                  {renderFieldMaterialWise()}
                </UserIndex.Box>
              </UserIndex.Box>

              <UserIndex.Box sx={{ width: 1 }}>
                <UserIndex.Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={{ xs: 0, sm: 1, md: 1, lg: 1 }}
                >
                  <UserIndex.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 12",
                      md: "span 12",
                      lg: "span 12",
                    }}
                  >
                    <UserIndex.Box className="addinfo-text-box">
                      <UserIndex.Typography
                        variant="h2"
                        className="addinfo-text product-dimension"
                      >
                        Product Dimensions
                      </UserIndex.Typography>
                    </UserIndex.Box>
                  </UserIndex.Box>
                  <UserIndex.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 4",
                      lg: "span 4",
                    }}
                  >
                    <UserIndex.Box className="refrence-product-input addproduct-1-input-box">
                      <UserIndex.Box className="input-box addproduct-1-input-box">
                        <UserIndex.FormHelperText className="input-lable">
                          Inner circumference
                        </UserIndex.FormHelperText>
                        <UserIndex.TextValidator
                          fullWidth
                          id="inner_circumference"
                          className="form-control border-none"
                          value={dimension.inner_circumference}
                          type="number"
                          name="inner_circumference"
                          onChange={(e) => {
                            handleChangeDimension(e);
                          }}
                        />
                        <span className="addproduct-mm-text">mm</span>
                        <UserIndex.FormHelperText className="error-input-lable">
                          {innerCircumference}
                        </UserIndex.FormHelperText>
                      </UserIndex.Box>
                    </UserIndex.Box>
                  </UserIndex.Box>
                  <UserIndex.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 3",
                      lg: "span 3",
                    }}
                  >
                    <UserIndex.Box className="refrence-product-input">
                      <UserIndex.Box className="input-box addproduct-1-input-box">
                        <UserIndex.FormHelperText className="input-lable">
                          Length
                        </UserIndex.FormHelperText>
                        <UserIndex.TextValidator
                          fullWidth
                          id="length"
                          className="form-control border-none"
                          value={dimension.length}
                          type="number"
                          name="length"
                          onChange={(e) => {
                            handleChangeDimension(e);
                          }}
                        />
                        <span className="addproduct-mm-text">mm</span>
                        <UserIndex.FormHelperText className="error-input-lable">
                          {lengthError}
                        </UserIndex.FormHelperText>
                      </UserIndex.Box>
                    </UserIndex.Box>
                  </UserIndex.Box>
                  <UserIndex.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 5",
                      lg: "span 5",
                    }}
                  >
                    <UserIndex.Box className="refrence-product-input">
                      <UserIndex.Box className="input-box addproduct-1-input-box">
                        <UserIndex.FormHelperText className="input-lable">
                          Depth
                        </UserIndex.FormHelperText>
                        <UserIndex.TextValidator
                          fullWidth
                          id="depth"
                          className="form-control border-none"
                          value={dimension.depth}
                          type="number"
                          name="depth"
                          onChange={(e) => {
                            handleChangeDimension(e);
                          }}
                        />
                        <span className="addproduct-mm-text">mm</span>
                        <UserIndex.FormHelperText className="error-input-lable">
                          {depthError}
                        </UserIndex.FormHelperText>
                      </UserIndex.Box>
                    </UserIndex.Box>
                  </UserIndex.Box>
                  <UserIndex.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 12",
                      lg: "span 12",
                    }}
                  >
                    <UserIndex.Box className="refrence-product-input">
                      <UserIndex.Box className="input-box addproduct-1-input-box">
                        <UserIndex.FormHelperText className="input-lable">
                          Meta Title
                          <span style={{ color: "red", fontSize: "15px" }}>
                            {" "}
                            *{" "}
                          </span>
                        </UserIndex.FormHelperText>
                        <UserIndex.TextValidator
                          fullWidth
                          id="meta_title"
                          className="form-control border-none"
                          value={dimension.meta_title}
                          name="meta_title"
                          onChange={(e) => {
                            handleChangeDimension(e);
                          }}
                          type="text"
                        />
                        <UserIndex.Typography
                          variant="p"
                          component="p"
                          className="form-validation-text"
                        >
                          A short 2-3 words
                        </UserIndex.Typography>
                        <UserIndex.FormHelperText className="error-input-lable">
                          {metaTitleError}
                        </UserIndex.FormHelperText>
                      </UserIndex.Box>
                    </UserIndex.Box>
                  </UserIndex.Box>
                  <UserIndex.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 12",
                      md: "span 12",
                      lg: "span 12",
                    }}
                  >
                    <UserIndex.Box className="addproduct-textarea-main input-box ">
                      <UserIndex.FormHelperText className="input-lable">
                        Meta Description
                        <span style={{ color: "red", fontSize: "15px" }}>
                          {" "}
                          *{" "}
                        </span>
                      </UserIndex.FormHelperText>
                      <UserIndex.TextValidator
                        fullWidth
                        id="meta_description"
                        className="form-control border-none"
                        value={dimension.meta_description}
                        name="meta_description"
                        onChange={(e) => {
                          handleChangeDimension(e);
                        }}
                        type="text"
                      />
                      <UserIndex.FormHelperText className="error-input-lable">
                        {metaDescriptionError}
                      </UserIndex.FormHelperText>
                    </UserIndex.Box>
                  </UserIndex.Box>
                  <UserIndex.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 12",
                      md: "span 12",
                      lg: "span 12",
                    }}
                  >
                    <UserIndex.Box className="primary-btn-box next-btn-box">
                      <UserIndex.PrimaryButton
                        btnclass="primary-btn next-btn"
                        onClick={() => {
                          window.scrollTo({ top: 0 });
                          setShow(true);
                        }}
                        buttonvalue="Back"
                      />
                      <UserIndex.PrimaryButton
                        btnclass="primary-btn next-btn"
                        onClick={(e) => {
                          CreateProduct(e);
                        }}
                        buttonvalue="Save"
                      />
                      <Button
                        className="primary-btn next-btn"
                        disabled={clicked}
                        onClick={(e) => {
                          // setIsSubmit("1")
                          CreateProduct(e, 1);
                        }}
                      >
                        Submit
                      </Button>
                    </UserIndex.Box>
                  </UserIndex.Box>
                </UserIndex.Box>
              </UserIndex.Box>
            </UserIndex.Box>
          </UserIndex.Box>
        )}
      </UserIndex.ValidatorForm>
      <UserIndex.Footer />
    </div>
  );
};

export default Requestingproducts;
