//#region ALL IMPORTS
import React, { useState, useEffect } from 'react';
import { Grid, Container, Typography, Button, TextField } from '@mui/material';
import { useHistory, useParams } from 'react-router-dom';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { Api } from '../../../../config/api';
import dataService from '../../../../config/dataService';
import { toast } from 'react-toastify';
import Png from '../../../../assets/png';
import { Box } from '@mui/system';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { ErrorMsg } from '../../../../component/customComponents/errorMsg/ErrorMsg';
import {
  minTcsChargesLength,
  tcsChargesLength,
  TCS_CHARGES,
} from '../../../../component/customComponents/validation/Validation';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import UploadIcon from '@mui/icons-material/Upload';
//#endregion

//#region ALL HOOKS AND VARIABALS
const TcsCharges = (props) => {
  let token = localStorage.getItem('auth');
  const [id, setId] = useState('');
  const [getData, setGetData] = useState('');

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
    watch,
  } = useForm({ mode: 'onChange' });
  //#endregion

  //#region ALL EVENT HANDLERS AND  FUCTIONS
  const getTcsCharges = async () => {
    let urlencoded = new URLSearchParams();
    if (props.id) {
    try {
      urlencoded.append('id', props.id);
      const Data = await dataService.post(
        Api.Admin.singleZavericaMakingCharges,
        urlencoded,
        {
          headers: {
            auth: token,
          },
        }
      );
      setGetData(Data.data.data);
      setId(Data.data.data);
    } catch (err) {
      toast.error(err?.message || 'Something went wrong1');
    }
    }
  };

  useEffect(() => {
    if (props.id !== '' && props.id != ' ') {
      getTcsCharges();
    }
  }, []);

  useEffect(() => {
    setValue('tcsCharges', getData?.tcs);
  }, [getData]);

  const handleSave = async (data) => {
    try {
      let urlencoded = new URLSearchParams();
      if (props.id !== '') {
        urlencoded.append('id', props.id);
        urlencoded.append(
          'tcs',
          data?.tcsCharges?.trim()
        );

        const makingChargesData = await dataService.post(
          Api.Admin.updateZavericaMakingCharges,
          urlencoded,
          {
            headers: {
              auth: token,
            },
          }
        );

        if (makingChargesData.data.status == 1) {
          toast.success('TCS charges Updated successfully');
          window.location.reload();
        }
      } else {
        urlencoded.append(
          'tcs',
          data?.tcsCharges?.trim()
        );

        const makingChargesData = await dataService.post(
          Api.Admin.addZavericaMakingCharges,
          urlencoded,
          {
            headers: {
              auth: token,
            },
          }
        );

        if (makingChargesData.data.status == 1) {
          toast.success('TCS charges added successfully');
          window.location.reload();
        }
      }
    } catch (err) {
      toast.error(err?.message || 'Something went wrong');
    }
  };
  //#endregion

  //#region RETURN JSX
  return (
    <>
      <form onSubmit={handleSubmit(handleSave)}>
        <Container maxWidth="false">
          <Typography sx={{ my: 2 }} align="center" variant="h4">
            TCS %
          </Typography>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <TextField
              label="TCS Charges"
              InputLabelProps={{
                shrink: watch('tcsCharges') ? true : false,
              }}
              autoComplete="off"
              sx={{
                mt: 2,
                width: { lg: '300px', xs: '150px' },
                '& .MuiFormHelperText-root': { ml: 0, mt: '5px' },
              }}
              size="small"
              fullWidth
              className="form-control2 border-none"
              name="tcsCharges"
              onWheel={(e) => e.target.blur()}
              type="text"
              {...register('tcsCharges', {
                required: <ErrorMsg msg="Please enter TCS Charges" />,
                minLength: {
                  value: minTcsChargesLength,
                  message: (
                    <ErrorMsg
                      msg={`Minimum ${minTcsChargesLength} Characters Allowed`}
                    />
                  ),
                },
                maxLength: {
                  value: tcsChargesLength,
                  message: (
                    <ErrorMsg
                      msg={`Maximum ${tcsChargesLength} Characters Allowed`}
                    />
                  ),
                },
                pattern: {
                  value: TCS_CHARGES,
                  message: <ErrorMsg msg="Please enter valid TCS Charges" />,
                },
              })}
              helperText={<ErrorMessage errors={errors} name="tcsCharges" />}
            ></TextField>
          </Box>
          <Typography align="center" variant="h4">
            <Button
              sx={{
                my: 5,
                width: '125px',
                textTransform: 'none',
              }}
              variant="contained"
              type="submit"
            >
              {props.id == '' ? 'Submit' : 'Update'}
            </Button>
          </Typography>
        </Container>
      </form>
    </>
  );
};

export default TcsCharges;
//#endregion
