import footerlogo from './images/png/footer-logo.png';
import trending1 from './images/png/trending1.png';
import trending2 from './images/png/trending2.png';
import trending3 from './images/png/trending3.png';
import trending4 from './images/png/trending4.png';
import precious1 from './images/png/precious1.png';
import precious2 from './images/png/precious2.png';
import precious3 from './images/png/precious3.png';
import precious4 from './images/png/precious4.png';
import deals1 from './images/png/deal1.png';
import deals2 from './images/png/deal2.png';
import antic1 from './images/png/antic1.png';
import antic2 from './images/png/antic2.png';
import antic3 from './images/png/antic3.png';
import bannerleft from './images/png/banner-left.png';
import bannerright from './images/png/banner-right.png';
import whitecart from './images/png/white-cart.png';
import headeruser from './images/png/header-user.png';
import searchgrey from './images/png/grey-search.png';
import mens1 from './images/png/mens/ring1.png';
import mens2 from './images/png/mens/ring2.png';
import mens3 from './images/png/mens/ring3.png';
import mens4 from './images/png/mens/ring4.png';
import thankyou from './images/png/thankyou.png';
import goldinfo from './images/png/goldinfo-banner.png';
import darkgreydown from './images/png/darkgreydown.png';
import uploadingproducts from './images/png/uploadingproducts-banner.png';
import p1 from './images/png/women/p1.png';
import p2 from './images/png/women/p2.png';
import p3 from './images/png/women/p3.png';
import p4 from './images/png/women/p4.png';
import p5 from './images/png/women/p5.png';
import p6 from './images/png/women/p6.png';
import p7 from './images/png/women/p7.png';
import p8 from './images/png/women/p8.png';
import p9 from './images/png/women/p9.png';
import m1 from './images/png/mens/m1.png';
import m2 from './images/png/mens/m2.png';
import m3 from './images/png/mens/m3.png';
import m4 from './images/png/mens/m4.png';
import m5 from './images/png/mens/m5.png';
import m6 from './images/png/mens/m6.png';
import m7 from './images/png/mens/m7.png';
import m8 from './images/png/mens/m8.png';
import m9 from './images/png/mens/m9.png';
import banneryellow from '../assets/images/png/banner-yellow.png';
import about from '../assets/images/png/about.png';
import teamimg1 from '../assets/images/png/team1.png';
import teamimg2 from '../assets/images/png/team2.png';
import teamimg3 from '../assets/images/png/team3.png';
import facebook from '../assets/images/png/facebook.png';
import linkdin from '../assets/images/png/linkdin.png';
import twiiter from '../assets/images/png/twiiter.png';
import usertestimonial from '../assets/images/png/user-testimonial.png';
import textimonialquote from '../assets/images/png/textimonial-quote.png';
import greyround from '../assets/images/png/grey-round.png';
import smalldot from '../assets/images/png/small-dot.png';
import ringsinfo from '../assets/images/png/ringsinfo.png';
import addinfo from '../assets/images/png/add-info.png';
import certi1 from '../assets/images/png/certi1.png';
import ringsleftbanner from '../assets/images/png/rings1-left-banner.png';
import userprofile from '../assets/images/png/userprofile.png';
import primarydown from '../assets/images/png/primary-down.png';
import detailpage from '../assets/images/png/detail-page.png';
import detailinner from '../assets/images/png/detail-inner.png';
import comment1 from '../assets/images/png/comment1.png';
import pre1 from '../assets/images/png/priciustone/pri-1.png';
import pre2 from '../assets/images/png/priciustone/pri-2.png';
import pre3 from '../assets/images/png/priciustone/pri-3.png';
import pre4 from '../assets/images/png/priciustone/pri-4.png';
import pre5 from '../assets/images/png/priciustone/pri-5.png';
import pre6 from '../assets/images/png/priciustone/pri-6.png';
import blackdown from '../assets/images/png/black-down.png';
import custome1 from '../assets/images/png/custom/custom1.png';
import custome2 from '../assets/images/png/custom/custom2.png';
import custome3 from '../assets/images/png/custom/custom3.png';
import custome4 from '../assets/images/png/custom/custom4.png';
import custome5 from '../assets/images/png/custom/custom5.png';
import custome6 from '../assets/images/png/custom/custom6.png';
import visaimg from '../assets/images/png/visa-img.png';
import rozorpay from '../assets/images/png/rozorpay-img.png';
import solidblackdown from '../assets/images/png/solid-black-down.png';
import questionmark from '../assets/images/png/questionmark.png';
import mobilemenu from '../assets/images/png/mobilemenu.png';
import percentage from '../assets/images/png/percentage.png';
import rupee from '../assets/images/png/rupee.png'
import faqBanner from "../assets/images/png/faq-banner.png"

const Png = {
  footerlogo,
  trending1,
  trending2,
  trending3,
  trending4,
  precious1,
  precious2,
  precious3,
  precious4,
  deals1,
  deals2,
  antic1,
  antic2,
  antic3,
  bannerleft,
  bannerright,
  whitecart,
  headeruser,
  searchgrey,
  banneryellow,
  about,
  teamimg1,
  teamimg2,
  teamimg3,
  facebook,
  linkdin,
  twiiter,
  mens1,
  mens2,
  mens3,
  mens4,
  thankyou,
  goldinfo,
  uploadingproducts,
  darkgreydown,
  p1,
  p2,
  p3,
  p4,
  p5,
  p6,
  p7,
  p8,
  p9,
  m1,
  m2,
  m3,
  m4,
  m5,
  m6,
  m7,
  m8,
  m9,
  usertestimonial,
  textimonialquote,
  greyround,
  smalldot,
  ringsinfo,
  addinfo,
  certi1,
  ringsleftbanner,
  userprofile,
  primarydown,
  detailpage,
  detailinner,
  comment1,
  pre1,
  pre2,
  pre3,
  pre4,
  pre5,
  pre6,
  blackdown,
  custome1,
  custome2,
  custome3,
  custome4,
  custome5,
  custome6,
  visaimg,
  rozorpay,
  solidblackdown,
  questionmark,
  mobilemenu,
  percentage,
  rupee,
  faqBanner
};
export default Png;
