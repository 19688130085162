import React, { useState, useEffect } from 'react';
import { styled, alpha } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import {
  Typography,
  Button,
  Container,
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  MenuItem,
  Menu,
  InputBase,
  TablePagination,
  Tooltip,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Api } from '../../../../config/api';
import dataService from '../../../../config/dataService';
import NoDataFound from '../../../../component/customComponents/noDataFound/NoDataFound';
import Png from '../../../../assets/png';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from '../../../../component/customComponents/loader/Loader';
import moment from 'moment';
import {
  commaSeparated,
  price,
} from '../../../../component/customComponents/commonFunctions/CommonFunctions';
import Mui from '../../../../component/customComponents/mui/Mui';
import { RupeeSymbol } from '../../../../component/customComponents/rupeeSymbol/RupeeSymbol';

import AdminIndex from '../../AdminIndex';
//#region ALL HOOKS AND VARIABALS

const { getPaymentList } = AdminIndex.AdminSagaActions;
export default function Payment() {
  let token = localStorage.getItem('auth');
  const dispatch = AdminIndex.useDispatch();
  const history = useHistory();
  // const [loading, setLoading] = useState(false);
  const [flag, setFlag] = useState(false);
  const [open, setOpen] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const openManu = Boolean(anchorEl);
  const [openType, setOpenType] = useState(true);
  const [anchorElType, setAnchorElType] = useState(null);
  const openManuType = Boolean(anchorElType);
  const [openStatus, setOpenStatus] = useState(true);
  const [anchorElStatus, setAnchorElStatus] = useState(null);
  const openManuStatus = Boolean(anchorElStatus);
  const [age, setAge] = useState('');
  const [tab, setTab] = useState('1');
  const [data, setData] = useState([]);
  const [tempData, setTempData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    getPaymentData();
    setInterval(() => {
      setFlag(true);
    }, 500);
  }, []);

  //#endregion

  //#region ALL EVENT HANDLERS AND  FUCTIONS
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getPaymentData = async () => {
    // setLoading(true);
    let formdata = new FormData();
    dispatch(getPaymentList(token,formdata));
    // await dataService
    //   .post(Api.Admin.listPayment, formdata, {
    //     headers: {
    //       auth: token,
    //     },
    //   })
    //   .then((res) => {
    //     setData(res.data.data);
    //     setTempData(res.data.data);
    //   })
    //   .catch((err) => toast.error(err?.message || 'Something went wrong'))
    //   .finally(() => {
    //     setLoading(false);
    //   });
  };

  const loading = AdminIndex.useSelector((state) => {
    return state.AdminSaga.loading;
  });

  const paymentData = AdminIndex.useSelector((state) => {
    return state.AdminSaga.paymentData;
  });

  useEffect(() => {
    setData(paymentData);
    setTempData(paymentData);
  }, [paymentData]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickType = (event) => {
    setAnchorElType(event.currentTarget);
  };

  const handleClickStatus = (event) => {
    setAnchorElStatus(event.currentTarget);
  };

  const sortDateInAscedingOrder = (e) => {
    let sortedDate = tempData.sort(
      (a, b) =>
        moment(...a.createdAt.split('/').reverse()) -
        moment(...b.createdAt.split('/').reverse())
    );
    setData(sortedDate);
  };

  const sortDateInDescendingOrder = (e) => {
    let sortedDate = tempData.sort(
      (a, b) =>
        moment(...b.createdAt.split('/').reverse()) -
        moment(...a.createdAt.split('/').reverse())
    );
    setData(sortedDate);
  };

  const debitType = (e) => {
    let debitData = tempData.filter((val) => val.type == 'Debit');
    setData(debitData);
  };
  const creditType = (e) => {
    let creditData = tempData.filter((val) => val.type == 'Credit');
    setData(creditData);
  };

  const pendingStatus = (e) => {
    let pendingData = tempData.filter(
      (val) => val.transaction_status == 'Pending'
    );
    setData(pendingData);
  };
  const completedStatus = (e) => {
    let completedData = tempData.filter(
      (val) => val.transaction_status == 'Complete'
    );
    setData(completedData);
  };

  const filter = (e) => {
    let data = e.target.value;
    let value = data.trim().toLowerCase();
    let filterData = tempData.filter(
      (data) =>
        data?.productName?.toLowerCase().includes(value) ||
        data?.order_item_id?.toLowerCase().includes(value) ||
        data?.transaction_id?.toLowerCase().includes(value) ||
        data?.product_user_id?.toLowerCase().includes(value)
    );
    setData(filterData);
  };

  const dateFilter = () => {
    return (
      <>
        <span>Date</span>
        <Button
          id="demo-positioned-button"
          aria-controls={open ? 'demo-positioned-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          sx={{
            textTransform: 'none',
          }}
          className="filter-dropdown-btn"
        >
          <ArrowDropDownIcon />
        </Button>
        <Menu
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          anchorEl={anchorEl}
          open={openManu}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <MenuItem
            onClick={() => {
              sortDateInAscedingOrder();
              setAnchorEl(null);
            }}
          >
            Asceding Order
          </MenuItem>
          <MenuItem
            onClick={() => {
              sortDateInDescendingOrder();
              setAnchorEl(null);
            }}
          >
            Descending Order
          </MenuItem>
        </Menu>
      </>
    );
  };
  const typeFilter = () => {
    return (
      <>
        <span>Type</span>
        <Button
          id="demo-positioned-button"
          aria-controls={openType ? 'demo-positioned-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={openType ? 'true' : undefined}
          onClick={handleClickType}
          sx={{
            textTransform: 'none',
          }}
          className="filter-dropdown-btn"
        >
          <ArrowDropDownIcon />
        </Button>
        <Menu
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          anchorEl={anchorElType}
          open={openManuType}
          onClose={() => setAnchorElType(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <MenuItem
            onClick={() => {
              debitType();
              setAnchorElType(null);
            }}
          >
            Debit
          </MenuItem>
          <MenuItem
            onClick={() => {
              creditType();
              setAnchorElType(null);
            }}
          >
            Credit
          </MenuItem>
          <MenuItem
            onClick={() => {
              getPaymentData();
              setAnchorElType(null);
            }}
          >
            All
          </MenuItem>
        </Menu>
      </>
    );
  };
  const transactionStatusFilter = () => {
    return (
      <>
        <span>Transaction Status</span>
        <Button
          id="demo-positioned-button"
          aria-controls={openStatus ? 'demo-positioned-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={openStatus ? 'true' : undefined}
          onClick={handleClickStatus}
          // variant="outlined"
          sx={{
            // borderRadius: '6px',
            // borderRadius: 0,
            textTransform: 'none',
          }}
          className="filter-dropdown-btn"
        >
          <ArrowDropDownIcon />
        </Button>
        <Menu
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          anchorEl={anchorElStatus}
          open={openManuStatus}
          onClose={() => setAnchorElStatus(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <MenuItem
            onClick={() => {
              pendingStatus();
              setAnchorElStatus(null);
            }}
          >
            Pending
          </MenuItem>
          <MenuItem
            onClick={() => {
              completedStatus();
              setAnchorElStatus(null);
            }}
          >
            Completed
          </MenuItem>
          <MenuItem
            onClick={() => {
              getPaymentData();
              setAnchorElStatus(null);
            }}
          >
            All
          </MenuItem>
        </Menu>
      </>
    );
  };

  const columns = [
    { id: 'date', label: dateFilter() },
    { id: 'transactiontype', label: 'Transaction type' },
    { id: 'productid', label: 'Product Id' },
    { id: 'productname', label: 'Product Name' },
    { id: 'admintosellerinvoice', label: 'Admin To Seller Invoice' },
    { id: 'orderitemid', label: 'Order Item Id' },
    { id: 'amount', label: <RupeeSymbol heading='Amount' /> },
    { id: 'type', label: typeFilter() },
    { id: 'transactionid', label: 'Transaction Id' },
    { id: 'transactionstatus', label: transactionStatusFilter() },
  ];

  //#endregion

  //#region RETURN JSX
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Container maxWidth="false" className="main-payment">
          <Typography
            sx={{ my: 1, fontWeight: 'bold' }}
            variant="h4"
            align="center"
          >
            Payment
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 5, mb: 2 }}>
            <Mui.GrayTooltip
              title="Search by (Product Name / Order Item Id / Transaction Id / Product Id)"
              placement="bottom-start"
            >
              <Mui.SearchMaxWidth>
                <Mui.SearchIconWrapper>
                  <SearchIcon />
                </Mui.SearchIconWrapper>
                <Mui.StyledInputBase
                  sx={{
                    width: '100%',
                    '& .MuiInputBase-input': {
                      width: '100%',
                      '&:focus, &.MuiInputBase-input': {
                        width: '100%',
                      },
                    },
                  }}
                  placeholder="Search"
                  inputProps={{ 'aria-label': 'search' }}
                  onChange={filter}
                />
              </Mui.SearchMaxWidth>
            </Mui.GrayTooltip>
          </Box>
          <Box className="main-payment-table">
            <TableContainer
              className="payment-table"
              elevation={0}
              sx={{
                maxWidth: {
                  xs: '365px',
                  sm: '600px',
                  md: '100%',
                  lg: '100%',
                },
                '&::-webkit-scrollbar': {
                  width: 10,
                },
                '&::-webkit-scrollbar': {
                  height: '8px',
                  width: '12px',
                },
                '&::-webkit-scrollbar-track': {
                  backgroundColor: '#fff',
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: 'rgba(224, 224, 224, 1)',
                  borderRadius: '30px',
                  width: '20px',
                },
                '&::-webkit-scrollbar-thumb:hover': {
                  background: '#B0B0B0',
                },
                mt: 2,
              }}
              component={Paper}
            >
              <Table
                sx={{
                  width: {
                    xs: 'max-content',
                    sm: 'max-content',
                    md: '100%',
                    lg: '100%',
                  },
                }}
                size="small"
              >
                <TableHead>
                  <TableRow sx={{ bgcolor: 'black', whiteSpace: 'nowrap' }}>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, bgcolor: 'red' }}
                        className="pagination-table-header-row"
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(data && data.length > 0)
                    ? data
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row._id}
                              sx={{
                                '&:last-child td, &:last-child th': {
                                  border: 0,
                                },
                                whiteSpace: 'nowrap',
                              }}
                            >
                              <Mui.StyledTableCell align="left">
                                {row.order_place_date
                                  ? moment(row.order_place_date).format(
                                      'DD/MM/YYYY'
                                    )
                                  : row.return_order_date
                                  ? moment(row.return_order_date).format(
                                      'DD/MM/YYYY'
                                    )
                                  : moment(row.createdAt).format('DD/MM/YYYY')}
                              </Mui.StyledTableCell>
                              <Mui.StyledTableCell align="center">
                                {row.return_order_id
                                  ? 'Return Order'
                                  : row?.order_id?.custom_product_id?.product_id
                                  ? 'Custom Order'
                                  : 'Regular Order'}
                                {/* hi */}
                              </Mui.StyledTableCell>
                              <Mui.StyledTableCell align="center">
                                {row.product_user_id
                                  ? row.product_user_id
                                  : row?.order_id?.custom_product_id
                                      ?.product_id}
                              </Mui.StyledTableCell>
                              <Mui.StyledTableCell align="center">
                                {row.productName
                                  ? row.productName
                                  : row?.order_id?.productName}
                              </Mui.StyledTableCell>
                              <Mui.StyledTableCell align="center">
                                {/* {row.order_place_date ? (
                                <Button
                                  onClick={() => {
                                    history.push(
                                      `/admin/purchaseorder/${row._id}`
                                    );
                                  }}
                                >
                                  {row.order_item_id ? row.order_item_id : row?.order_id?.order_item_id}
                                </Button>
                              ) : (
                                row.order_item_id ? row.order_item_id : row?.order_id?.order_item_id
                              )} */}
                                {/* {row.return_order_id ? (
                                  row.order_item_id ? (
                                    row.order_item_id
                                  ) : (
                                    row?.order_id?.order_item_id
                                  )
                                ) : row?.order_id?.custom_product_id
                                    ?.product_id ? (
                                  row.order_item_id ? (
                                    row.order_item_id
                                  ) : (
                                    row?.order_id?.order_item_id
                                  )
                                ) : (
                                  <Button
                                    onClick={() => {
                                      history.push(
                                        `/admin/admintosellerinvoice/${row._id}`
                                      );
                                    }}
                                  >
                                    {row.order_item_id
                                      ? row.order_item_id
                                      : row?.order_id?.order_item_id}
                                  </Button>
                                )} */}
                                {row.pdfName ? 
                                <Link style={{color: 'cornflowerblue'}} to={`/admin/admintosellerinvoice/${row._id}/${row.pdfName}`}>{row.pdfName}</Link>
                                : '-'}
                              </Mui.StyledTableCell>
                              <Mui.StyledTableCell align="center">
                                {/* {row.order_place_date ? (
                                <Button
                                  onClick={() => {
                                    history.push(
                                      `/admin/purchaseorder/${row._id}`
                                    );
                                  }}
                                >
                                  {row.order_item_id ? row.order_item_id : row?.order_id?.order_item_id}
                                </Button>
                              ) : (
                                row.order_item_id ? row.order_item_id : row?.order_id?.order_item_id
                              )} */}
                                {row.return_order_id ? (
                                  row.order_item_id ? (
                                    row.order_item_id
                                  ) : (
                                    row?.order_id?.order_item_id
                                  )
                                ) : row?.order_id?.custom_product_id
                                    ?.product_id ? (
                                  row.order_item_id ? (
                                    row.order_item_id
                                  ) : (
                                    row?.order_id?.order_item_id
                                  )
                                ) : (
                                
                                  <Link style={{color: 'cornflowerblue'}} to={`/admin/purchaseorder/${row._id}`}>{row.order_item_id
                                      ? row.order_item_id
                                      : row?.order_id?.order_item_id}</Link>
                                )}
                              </Mui.StyledTableCell>
                              <Mui.StyledTableCell align="center">
                                {row.amount
                                  ? price(row.amount)
                                  : price(row.order_id.totalAmount)}
                                {/* <span>
                                  <img
                                    src={Png.rupee}
                                    className="rupee-icon"
                                  ></img>
                                </span> */}
                                {/* {row.amount} */}
                              </Mui.StyledTableCell>
                              <Mui.StyledTableCell align="center">
                                {row.type}
                              </Mui.StyledTableCell>
                              <Mui.StyledTableCell align="center">
                                {row.transaction_id}
                              </Mui.StyledTableCell>
                              <Mui.StyledTableCell align="center">
                                {row.transaction_status}
                              </Mui.StyledTableCell>
                            </TableRow>
                          );
                        })
                    : ''}
                </TableBody>
              </Table>
              {!data && flag ? <NoDataFound /> : ''}
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 15]}
              component="div"
              count={data?data.length:0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        </Container>
      )}
    </>
  );
}
//#endregion
