import React, { useEffect, useState } from "react";
import png from "../../../../assets/png";
import PropTypes from "prop-types";
import UserIndex from "../../UserIndex";
import UserSagaActions from "../../../../redux/Usersaga/action";

const { GetallReturnOrderSaga, AllReceivedReturnOrderSaga } = UserSagaActions;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <UserIndex.Box sx={{ p: 3 }}>
          <UserIndex.Typography>{children}</UserIndex.Typography>
        </UserIndex.Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ReturnOrder = () => {
  const [value, setValue] = useState(0);
  const [tab, setTab] = useState(0);
  const [allReturnOrder, setAllReturnOrder] = useState([]);
  const [allReturnReceiveOrder, setAllReturnReceiveOrder] = useState([]);

  const dispatch = UserIndex.useDispatch();
  const history = UserIndex.useHistory();
  const navigate = UserIndex.useHistory();
  const loding = UserIndex.useSelector((state) => {
    return state.Usersaga.loading;
  });
  const Token = UserIndex.useSelector((state) => {
    return state.Usersaga.Token;
  });
  const GetallReturnOrderData = UserIndex.useSelector((state) => {
    return state.Usersaga.GetallReturnOrderData;
  });
  const AllReceivedReturnOrderData = UserIndex.useSelector((state) => {
    return state.Usersaga.AllReceivedReturnOrderData;
  });

  const getallReturnOrder = async () => {
    let urlencoded = new URLSearchParams();
    dispatch(GetallReturnOrderSaga(urlencoded, navigate, Token));
  };

  const getAllReceivedReturnOrderData = async () => {
    dispatch(AllReceivedReturnOrderSaga(navigate, Token));
  };

  useEffect(() => {
    getallReturnOrder();
  }, [tab]);

  useEffect(() => {
    getAllReceivedReturnOrderData();
  }, [tab]);

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    setAllReturnOrder(GetallReturnOrderData);
  }, [GetallReturnOrderData]);

  useEffect(() => {
    setAllReturnReceiveOrder(AllReceivedReturnOrderData);
  }, [AllReceivedReturnOrderData]);

  return (
    <div>
      <UserIndex.Header />
      <UserIndex.Box className="body-bg">
        <UserIndex.Box className="myorder-sec">
          <UserIndex.Box className="container">
            <UserIndex.Box className="row">
              <UserIndex.Box className="addinfo-text-box pg-main-title-box">
                <UserIndex.Typography
                  variant="h2"
                  className="addinfo-text pg-main-title"
                >
                  My Return Orders
                </UserIndex.Typography>
              </UserIndex.Box>

              <UserIndex.Box className="mo-tabs-box">
                <UserIndex.Box className="mo-tabs-main">
                  <UserIndex.Tabs
                    value={value}
                    onChange={(_, d) => {
                      handleChange(d);
                    }}
                    aria-label="basic tabs example"
                    className="mo-tabs"
                  >
                    <UserIndex.Tab
                      label="Orders Placed"
                      onClick={() => setTab(0)}
                      className="mo-tabs-lable"
                    />
                    <UserIndex.Tab
                      label="Orders Received"
                      onClick={() => {
                        setTab(1);
                      }}
                      className="mo-tabs-lable"
                    />
                  </UserIndex.Tabs>
                </UserIndex.Box>
                <UserIndex.Box className="mo-tabs-content">
                  <UserIndex.Box>
                    <UserIndex.Box className="input-box">
                      <UserIndex.FormControl className="membership-formcontrol mo-form-control-drop">
                        <UserIndex.Select
                          className="membershop-select"
                          // value={age}
                          // onChange={myOrderhandleChange}
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                        >
                          <UserIndex.MenuItem
                            value=""
                            className="membershipitem"
                          >
                            All
                          </UserIndex.MenuItem>
                          <UserIndex.MenuItem value={10}>
                            Open
                          </UserIndex.MenuItem>
                          <UserIndex.MenuItem value={20}>
                            Close
                          </UserIndex.MenuItem>
                        </UserIndex.Select>
                        <span>
                          <img
                            src={png.blackdown}
                            className="black-down-img"
                          ></img>
                        </span>
                      </UserIndex.FormControl>
                    </UserIndex.Box>
                    <UserIndex.Box className="mo-table-main">
                      <UserIndex.TableContainer
                        component={UserIndex.Paper}
                        className="mo-table-container"
                      >
                        <UserIndex.Table
                          sx={{ minWidth: 650 }}
                          aria-label="simple table"
                          className="mo-table"
                        >
                          <UserIndex.TableHead className="mo-tablehead">
                            <UserIndex.TableRow>
                              <UserIndex.TableCell className="mo-th">
                                Return Date
                              </UserIndex.TableCell>
                              <UserIndex.TableCell className="mo-th">
                                Order Item Id
                              </UserIndex.TableCell>
                              <UserIndex.TableCell className="mo-th">
                                Order Id
                              </UserIndex.TableCell>
                              <UserIndex.TableCell className="mo-th mo-th4">
                                Return Id
                              </UserIndex.TableCell>
                              <UserIndex.TableCell className="mo-th">
                                Status
                              </UserIndex.TableCell>
                              <UserIndex.TableCell className="mo-th"></UserIndex.TableCell>
                            </UserIndex.TableRow>
                          </UserIndex.TableHead>
                          <UserIndex.TableBody className="mo-table-body">
                            {tab == 0 ? (
                              <>
                                {allReturnOrder?.length ? (
                                  allReturnOrder?.map((data, index) => {
                                    return (
                                      <UserIndex.TableRow
                                        sx={{
                                          "&:last-child td, &:last-child th": {
                                            border: 0,
                                          },
                                        }}
                                      >
                                        <UserIndex.TableCell
                                          component="th"
                                          scope="row"
                                          className="mo-td"
                                        >
                                          {new Date(
                                            data?.createdAt
                                          ).toLocaleDateString()}
                                        </UserIndex.TableCell>
                                        <UserIndex.TableCell className="mo-td">
                                          {
                                            data.order_id?.products[0]
                                              .order_item_id
                                          }
                                        </UserIndex.TableCell>
                                        <UserIndex.TableCell className="mo-td">
                                          {data?.order_id?.order_id}
                                        </UserIndex.TableCell>
                                        <UserIndex.TableCell className="mo-td">
                                          {data?.return_id}
                                        </UserIndex.TableCell>
                                        <UserIndex.TableCell className="mo-td success-text">
                                          {data.return_status == "0"
                                            ? "Pending"
                                            : data.return_status == "1"
                                            ? "Approved"
                                            : data.return_status == "2"
                                            ? "Rejected"
                                            : data.return_status == "3"
                                            ? "Return Complete"
                                            : ""}
                                        </UserIndex.TableCell>
                                        <UserIndex.TableCell className="mo-td">
                                          <UserIndex.Box className="primary-btn-box myorder-view-btn-box">
                                            <UserIndex.PrimaryButton
                                              btnclass="primary-btn myorder-view-btn "
                                              buttonvalue="View"
                                              onClick={() =>
                                                history.push(
                                                  `/user/viewreturnorder/${data?._id}`
                                                )
                                              }
                                            />
                                          </UserIndex.Box>
                                        </UserIndex.TableCell>
                                      </UserIndex.TableRow>
                                    );
                                  })
                                ) : (
                                  <UserIndex.Box className="return-order-products-order-message">
                                    No Record Found
                                  </UserIndex.Box>
                                )}
                              </>
                            ) : (
                              <>
                                {allReturnReceiveOrder?.length ? (
                                  allReturnReceiveOrder?.map((data, index) => {
                                    return (
                                      <UserIndex.TableRow
                                        sx={{
                                          "&:last-child td, &:last-child th": {
                                            border: 0,
                                          },
                                        }}
                                      >
                                        <UserIndex.TableCell
                                          component="th"
                                          scope="row"
                                          className="mo-td"
                                        >
                                          {new Date(
                                            data?.createdAt
                                          ).toLocaleDateString()}
                                        </UserIndex.TableCell>
                                        <UserIndex.TableCell className="mo-td">
                                          {data?.products?.[0]?.order_item_id}
                                        </UserIndex.TableCell>
                                        <UserIndex.TableCell className="mo-td">
                                          {data?.products?.[0]?.orderProductId}
                                        </UserIndex.TableCell>
                                        <UserIndex.TableCell className="mo-td">
                                          {data?.return_id}
                                        </UserIndex.TableCell>
                                        <UserIndex.TableCell className="mo-td success-text">
                                          {data.return_status == "1"
                                            ? "Approved"
                                            : data.return_status == "3"
                                            ? "Return Complete"
                                            : ""}
                                        </UserIndex.TableCell>
                                        <UserIndex.TableCell className="mo-td">
                                          <UserIndex.Box className="primary-btn-box myorder-view-btn-box">
                                            <UserIndex.PrimaryButton
                                              btnclass="primary-btn myorder-view-btn "
                                              buttonvalue="View"
                                              onClick={() =>
                                                history.push(
                                                  `/user/viewreceivereturnorder/${data?._id}`
                                                )
                                              }
                                            />
                                          </UserIndex.Box>
                                        </UserIndex.TableCell>
                                      </UserIndex.TableRow>
                                    );
                                  })
                                ) : (
                                  <UserIndex.Box className="return-order-products-order-message">
                                    No Record Found
                                  </UserIndex.Box>
                                )}
                              </>
                            )}
                          </UserIndex.TableBody>
                        </UserIndex.Table>
                      </UserIndex.TableContainer>
                    </UserIndex.Box>
                  </UserIndex.Box>
                </UserIndex.Box>
              </UserIndex.Box>
            </UserIndex.Box>
          </UserIndex.Box>
        </UserIndex.Box>
      </UserIndex.Box>
      <UserIndex.Footer />
    </div>
  );
};

export default ReturnOrder;

{
  /* <UserIndex.Box className="mo-tabs-box">
<UserIndex.Box className="mo-tabs-content">
  <UserIndex.Box>
    <UserIndex.Box className="input-box"></UserIndex.Box>
    <UserIndex.Box className="mo-table-main">
      <UserIndex.TableContainer
        component={UserIndex.Paper}
        className="mo-table-container"
      >
        <UserIndex.Table
          sx={{ minWidth: 650 }}
          aria-label="simple table"
          className="mo-table"
        >
          <UserIndex.TableHead className="mo-tablehead">
            <UserIndex.TableRow>
              <UserIndex.TableCell className="mo-th">
                Return Date
              </UserIndex.TableCell>
              <UserIndex.TableCell className="mo-th">
                Order Item Id
              </UserIndex.TableCell>
              <UserIndex.TableCell className="mo-th">
                Order Id
              </UserIndex.TableCell>
              <UserIndex.TableCell className="mo-th mo-th4">
                Return Id
              </UserIndex.TableCell>
              <UserIndex.TableCell className="mo-th">
                Status
              </UserIndex.TableCell>
              <UserIndex.TableCell className="mo-th"></UserIndex.TableCell>
            </UserIndex.TableRow>
          </UserIndex.TableHead>
          <UserIndex.TableBody className="mo-table-body">
            {allReturnOrder.length
              ? allReturnOrder.map((data, index) => {
                  return (
                    <UserIndex.TableRow
                      sx={{
                        "&:last-child td, &:last-child th": {
                          border: 0,
                        },
                      }}
                    >
                      <UserIndex.TableCell
                        component="th"
                        scope="row"
                        className="mo-td"
                      >
                        {new Date(
                          data?.createdAt
                        ).toLocaleDateString()}
                      </UserIndex.TableCell>
                      <UserIndex.TableCell className="mo-td">
                        {
                          data.order_id?.products[0]
                            .order_item_id
                        }
                      </UserIndex.TableCell>
                      <UserIndex.TableCell className="mo-td">
                        {data?.order_id?.order_id}
                      </UserIndex.TableCell>
                      <UserIndex.TableCell className="mo-td">
                        {data?.return_id}
                      </UserIndex.TableCell>
                      <UserIndex.TableCell className="mo-td success-text">
                        {data.return_status == "0"
                          ? "Pending"
                          : data.return_status == "1"
                          ? "Return Complete"
                          :data.return_status == "2"
                          ? "Rejected"
                          : ""}
                      </UserIndex.TableCell>
                      <UserIndex.TableCell className="mo-td">
                        <UserIndex.Box className="primary-btn-box myorder-view-btn-box">
                          <UserIndex.PrimaryButton
                            btnclass="primary-btn myorder-view-btn "
                            buttonvalue="View"
                            onClick={() =>
                              history.push(
                                `/user/viewreturnorder/${data?._id}`
                              )
                            }
                          />
                        </UserIndex.Box>
                      </UserIndex.TableCell>
                    </UserIndex.TableRow>
                  );
                })
              : <UserIndex.Box className="return-order-products-order-message"> No Record Found  </UserIndex.Box>}
          </UserIndex.TableBody>
        </UserIndex.Table>
      </UserIndex.TableContainer>
    </UserIndex.Box>
  </UserIndex.Box>
</UserIndex.Box>
</UserIndex.Box> */
}
