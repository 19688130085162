import React, { useState, useEffect } from 'react';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Container,
  InputBase,
  Tooltip,
} from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import ContainedButton from '../../../../component/customComponents/buttons/ContainedButton';
import { Api } from '../../../../config/api';
import dataService from '../../../../config/dataService';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import NoDataFound from '../../../../component/customComponents/noDataFound/NoDataFound';
import { toast, ToastContainer } from 'react-toastify';
import Loader from '../../../../component/customComponents/loader/Loader';
import Png from '../../../../assets/png';
import moment from 'moment';
import Mui from '../../../../component/customComponents/mui/Mui';
import AdminIndex from '../../AdminIndex';

//#region ALL HOOKS AND VARIABALS
const columns = [
  { id: 'date', label: 'Date' },
  { id: 'seller', label: 'Seller', align: 'center' },
  {
    id: 'productid	',
    label: 'Product Id	',
    align: 'center',
  },
  { id: 'category', label: 'Category', align: 'center' },
  { id: 'product', label: 'Product', align: 'center' },
  { id: 'status', label: 'Status', align: 'center' },
  { id: 'invoicetocustomer', label: 'Invoice to Customer', align: 'center' },
  { id: 'invoicetoseller', label: 'Invoice to Seller', align: 'center' },
  { id: 'action', label: '' },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: '25px',
  backgroundColor: alpha(theme.palette.common.white, 0.25),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '250px',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: '540px',
  },
  border: '1px solid black',
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

const { getAllCustomProduct } = AdminIndex.AdminSagaActions;
export default function CustomProducts() {
  let token = localStorage.getItem('auth');
  const dispatch = AdminIndex.useDispatch();
  const history = useHistory();
  // const [loading, setLoading] = useState(false);
  const [flag, setFlag] = useState(false);
  const [age, setAge] = useState('');
  const [data, setData] = useState([]);
  const [tempData, setTempData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    getCustomProduct();
    setInterval(() => {
      setFlag(true);
    }, 500);
  }, []);
  //#endregion

  //#region ALL EVENT HANDLERS AND  FUCTIONS
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const getCustomProduct = async () => {
    
    dispatch(getAllCustomProduct(token));
    // setLoading(true);
    // let formdata = new URLSearchParams();
    // await dataService
    //   .post(Api.Admin.recievedCustomProductAdmin, formdata, {
    //     headers: {
    //       auth: token,
    //     },
    //   })
    //   .then((res) => {
    //     setData(res.data.data);
    //     setTempData(res.data.data);
    //   })
    //   .catch((err) => toast.error(err?.message || 'Something went wrong'))
    //   .finally(() => {
    //     setLoading(false);
    //   });
  };

  const loading = AdminIndex.useSelector((state) => {
    return state.AdminSaga.loading;
  });

  const customProduct = AdminIndex.useSelector((state) => {
    return state.AdminSaga.allCustomProduct;
  });

  useEffect(() => {
    setData(customProduct);
    setTempData(customProduct);
  }, [customProduct]);

  const filter = (e) => {
    let data = e.target.value;
    let value = data.trim().toLowerCase();
    let filterData = tempData.filter(
      (data) =>
        data?.name?.toLowerCase().includes(value) ||
        data?.product_id?.toLowerCase().includes(value) ||
        data?.materialIdArray
          ?.map((val) =>
            val.material_id?.material?.toLowerCase().includes(value)
          )
          .includes(true) ||
        data?.item_id?.item?.toLowerCase().includes(value)
    );
    setData(filterData);
  };

  //#endregion

  //#region RETURN JSX
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Container sx={{ mt: 2, mb: 5 }} maxWidth="false">
          <ToastContainer />
          <Typography variant="h4" align="center" sx={{ fontWeight: 'bold' }}>
            Custom Product Management
          </Typography>
          {/* <br /> */}
          <Box
            sx={{ display: 'flex', justifyContent: 'center', mt: 5, mb: 2 }}
            className="tooltip-main"
          >
            {/* <MuiThemeProvider theme={theme}> */}
            <Mui.GrayTooltip
              title="Search by (Seller / Company Name / Product Id / Product / Category)"
              placement="bottom-start"
              // className="admin-tooltip"
              // sx={{'& .css-ja5taz-MuiTooltip-tooltip':{
              //   maxWidth: '100% !important'
              // }}}
            >
              <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  sx={{
                    width: '100%',
                    '& .MuiInputBase-input': {
                      width: '100%',
                      '&:focus, &.MuiInputBase-input': {
                        width: '100%',
                      },
                    },
                  }}
                  placeholder="Search"
                  inputProps={{ 'aria-label': 'search' }}
                  onChange={filter}
                />
              </Search>
            </Mui.GrayTooltip>
            {/* </MuiThemeProvider> */}
          </Box>
          {/* <br /> */}
          <Box className="main-payment-table">
            <TableContainer
              elevation={0}
              sx={{
                maxWidth: {
                  xs: '365px',
                  sm: '600px',
                  md: '100%',
                  lg: '100%',
                },
                '&::-webkit-scrollbar': {
                  width: 10,
                },
                '&::-webkit-scrollbar': {
                  height: '8px',
                  width: '12px',
                },
                '&::-webkit-scrollbar-track': {
                  backgroundColor: '#fff',
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: 'rgba(224, 224, 224, 1)',
                  borderRadius: '30px',
                  width: '20px',
                },
                '&::-webkit-scrollbar-thumb:hover': {
                  background: '#B0B0B0',
                },
              }}
              component={Paper}
            >
              <Table
                sx={{
                  width: {
                    xs: 'max-content',
                    sm: 'max-content',
                    md: '100%',
                    lg: '100%',
                  },
                }}
                size="small"
              >
                <TableHead>
                  <TableRow sx={{ bgcolor: 'black', whiteSpace: 'nowrap' }}>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, bgcolor: 'red' }}
                        className="pagination-table-header-row"
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.length
                    ? data
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => {
                          return (
                            <StyledTableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row._id}
                              sx={{
                                '&:last-child td, &:last-child th': {
                                  border: 0,
                                },
                                whiteSpace: 'nowrap',
                              }}
                            >
                              <StyledTableCell
                                align="left"
                                sx={{ border: '0px' }}
                              >
                                {/* {new Date(row.updatedAt).toLocaleDateString()} */}
                                {moment(row.updatedAt).format('DD/MM/YYYY')}
                              </StyledTableCell>
                              <StyledTableCell
                                align="center"
                                sx={{ border: '0px' }}
                              >
                                {row.name || row.company_name}
                              </StyledTableCell>
                              <StyledTableCell
                                align="center"
                                sx={{ border: '0px' }}
                              >
                                {row.product_id}
                              </StyledTableCell>
                              {/* <StyledTableCell align="center">
                              {row?.materialIdArray?.map((val) => (
                                <ol>{val?.material_id?.material}</ol>
                              ))}
                            </StyledTableCell> */}
                              <Mui.GrayTooltip1
                                title={String(row?.materialIdArray
                                    ?.map((val) => val.material_id?.material)
                                    .sort()).replace(/,/g, ', ')}
                                placement="bottom"
                              >
                                <StyledTableCell
                                  align="center"
                                  sx={{
                                    display: 'block',
                                    width: '90px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden !important',
                                    textOverflow: 'ellipsis',
                                    p: '14px',
                                    border: '0px',
                                  }}
                                >
                                  {row?.materialIdArray
                                    ?.map((val) => val.material_id?.material)
                                    .sort()}
                                </StyledTableCell>
                              </Mui.GrayTooltip1>
                              <StyledTableCell
                                align="center"
                                sx={{ border: '0px' }}
                              >
                                {row?.item_id?.item}
                              </StyledTableCell>
                              <StyledTableCell
                                align="center"
                                sx={{ border: '0px' }}
                              >
                                {row.status == '0' ? (
                                  <Typography>Pending</Typography>
                                ) : row.status == '1' ? (
                                  <Typography sx={{ color: 'green' }}>
                                    Approved
                                  </Typography>
                                ) : row.status == '2' ? (
                                  <Typography sx={{ color: 'red' }}>
                                    Rejected
                                  </Typography>
                                ) : row.status == '3' ?(
                                  <Typography sx={{ color: 'red' }}>
                                    Closed
                                  </Typography>
                                ): 'Canceled'}
                              </StyledTableCell>
                              <StyledTableCell
                                align="center"
                                sx={{ border: '0px' }}
                              >
                                {/* {parseInt(
                              row?.total_payable_amount
                            )}
                              <span>
                                <img
                                  src={Png.rupee}
                                  className="rupee-icon"
                                ></img>
                              </span> */}
                                -
                              </StyledTableCell>
                              <StyledTableCell
                                align="center"
                                sx={{ border: '0px' }}
                              >
                                {/* {parseInt(
                                row?.admin_to_seller
                              )}
                              <span>
                                <img
                                  src={Png.rupee}
                                  className="rupee-icon"
                                ></img>
                              </span> */}
                                -
                              </StyledTableCell>
                              <StyledTableCell
                                align="center"
                                sx={{ whiteSpace: 'nowrap', border: '0px' }}
                              >
                                <ContainedButton
                                  name="View"
                                  onClick={() =>
                                    history.push(
                                      `/admin/productmanagement/customproducts/viewcustomproduct/${row._id}`
                                    )
                                  }
                                />
                                <ContainedButton
                                disabled={(row.status == '1' ||row.status == '2' || row.status == '3' || row.status == '4') && true}
                                  className="common-button"
                                  name="Edit"
                                  onClick={() =>
                                    history.push(
                                      `/admin/productmanagement/customproducts/updatecustomproduct/${row?._id}`
                                    )
                                  }
                                />
                              </StyledTableCell>
                            </StyledTableRow>
                          );
                        })
                    : ''}
                </TableBody>
              </Table>
              {!data?.length && flag ? <NoDataFound /> : ''}
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 15]}
              component="div"
              count={data?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        </Container>
      )}
    </>
  );
}
//#endregion
