import {
  Button,
  Typography,
  Grid,
  Box,
  MenuItem,
  FormControl,
  Select,
  Paper,
  Tabs,
  Tab,
  Pagination,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormHelperText,
  TextareaAutosize,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Modal,
  ButtonGroup,
  TextField,
  IconButton,
  Card,
  CardContent,
  CardActions,
  Checkbox,
  InputAdornment,
  Chip,
  Tooltip,
} from '@mui/material'

import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import HighlightOffTwoToneIcon from '@mui/icons-material/CancelTwoTone'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import { styled } from '@mui/material/styles'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault'

import PropTypes from "prop-types";

import svg from '../../assets/svg'
import png from '../../assets/png'
import Header from '../../component/user/defaultLayout/Header'
import Footer from '../../component/user/defaultLayout/Footer'
import { useDispatch, useSelector } from 'react-redux'
import UserSagaActions from '../../redux/Usersaga/action'
import { useHistory, useParams } from 'react-router-dom'
import { PrimaryButton } from '../../component/user/button/Button'
import Certificates from '../../component/user/defaultLayout/Certificates'
import ProductDescription from '../../component/user/defaultLayout/ProductDescription'
import OwlCarousel from 'react-owl-carousel2'
import ProductGrid from '../../component/user/productList/ProductGrid'
import FilterSection from '../../component/user/productList/FilterSection'

import { ToastContainer, toast } from 'react-toastify'

export default {
  IconButton,
  Checkbox,
  Pagination,
  UserSagaActions,
  useDispatch,
  useSelector,
  useHistory,
  TextField,
  useParams,
  PrimaryButton,
  Header,
  Footer,
  svg,
  png,
  HighlightOffTwoToneIcon,
  Button,
  Typography,
  Grid,
  Box,
  MenuItem,
  FormControl,
  Select,
  Paper,
  Tabs,
  Tab,
  styled,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormHelperText,
  TextareaAutosize,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  MuiAccordion,
  MuiAccordionSummary,
  MuiAccordionDetails,
  Card,
  CardContent,
  ExpandMoreIcon,
  ButtonGroup,
  Modal,
  Certificates,
  ProductDescription,
  Visibility,
  VisibilityOff,
  ArrowForwardIosSharpIcon,
  OwlCarousel,
  ProductGrid,
  FilterSection,
  InputAdornment,
  Chip,
  TextValidator,
  ValidatorForm,
  AddCircleIcon,
  DisabledByDefaultIcon,
  CardActions,
  PropTypes,
  Tooltip,
  ToastContainer,
  toast,
}
