import React,{useState} from 'react';
import Box from "@mui/material/Box";
import { Button, Typography } from '@mui/material';
import svg from "../../../../assets/svg";
import png from "../../../../assets/png";
import Header from '../../../../component/user/defaultLayout/Header';
import Footer from '../../../../component/user/defaultLayout/Footer';
import Pagination from '@mui/material/Pagination';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';


function TabPanel(props) {
      const { children, value, index, ...other } = props;
      return (
            <div
                  role="tabpanel"
                  hidden={value !== index}
                  id={`simple-tabpanel-${index}`}
                  aria-labelledby={`simple-tab-${index}`}
                  {...other}
            >
                  {value === index && (
                        <Box sx={{ p: 3 }}>
                              <Typography>{children}</Typography>
                        </Box>
                  )}
            </div>
      );
}
TabPanel.propTypes = {
      children: PropTypes.node,
      index: PropTypes.number.isRequired,
      value: PropTypes.number.isRequired,
};

function a11yProps(index) {
      return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
      };
}



export default function Womensjewelry() {

      const [value, setValue] = useState(0);
      const handleChange = (event, newValue) => {
            setValue(newValue);
      };

      return (
            <div>
                  <Header />
                  <Box className="inner-hero-sec">
                        <Box className='container'>
                              <Box className='hero-title-box'>
                                    <Typography variant='h4' className='hero-inner-title'><span>Women's Jewellery</span></Typography>
                              </Box>
                        </Box>
                  </Box>
                  <Box className='rings-section'>
                        <Box className="container">
                              <Box sx={{ width: 1 }}>
                                    <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={{ xs: 0, sm: 0, md: 4, lg: 4 }}>
                                          <Box gridColumn={{ xs: "span 12", sm: "span 12", md: "span 3", lg: "span 3" }}>
                                                <Box className='side-filter-main'>
                                                      <Box className='filter-text-box'>
                                                            <Typography variant='h3' className='filter-text'>Filter</Typography>
                                                            <Typography variant='h3' className='reset-text'>Reset all</Typography>
                                                      </Box>
                                                      <Box className='filter-chekbox-main'>
                                                            <Accordion className='filter-accordian'>
                                                                  <AccordionSummary className='filter-accordian-summary'
                                                                        expandIcon={<ExpandMoreIcon />}
                                                                        aria-controls="panel1a-content"
                                                                        id="panel1a-header"
                                                                  >
                                                                        <Typography className='filter-accordian-text'>Product Type</Typography>
                                                                  </AccordionSummary>
                                                                  <AccordionDetails className='filter-accordian-details'>
                                                                        <FormControlLabel control={<Checkbox defaultChecked />} label="Ring" className='filter-lables' />
                                                                        <FormControlLabel control={<Checkbox />} label="Earings" className='filter-lables' />

                                                                        <FormControlLabel control={<Checkbox />} label="Bracelets" className='filter-lables' />

                                                                        <FormControlLabel control={<Checkbox />} label="Bengal" className='filter-lables' />

                                                                        <FormControlLabel control={<Checkbox />} label="Nose Pins" className='filter-lables' />

                                                                        <FormControlLabel control={<Checkbox />} label="Chains" className='filter-lables' />

                                                                        <FormControlLabel control={<Checkbox />} label="Pendants" className='filter-lables' />

                                                                        <FormControlLabel control={<Checkbox />} label="Kadas" className='filter-lables' />

                                                                        <FormControlLabel control={<Checkbox />} label="Sets" className='filter-lables' />
                                                                  </AccordionDetails>
                                                            </Accordion>
                                                            <Accordion className='filter-accordian'>
                                                                  <AccordionSummary
                                                                        expandIcon={<ExpandMoreIcon />}
                                                                        aria-controls="panel2a-content"
                                                                        id="panel2a-header"
                                                                        className='filter-accordian-summary'
                                                                  >
                                                                        <Typography className='filter-accordian-text'>Price</Typography>
                                                                  </AccordionSummary>
                                                                  <AccordionDetails className='filter-accordian-details'>

                                                                        <FormControlLabel control={<Checkbox />} label="Under 5000" className='filter-lables' />

                                                                        <FormControlLabel control={<Checkbox />} label="6000 - 10000" className='filter-lables' />

                                                                        <FormControlLabel control={<Checkbox />} label="11000 - 20000" className='filter-lables' />

                                                                        <FormControlLabel control={<Checkbox />} label="21000 - 30000" className='filter-lables' />

                                                                        <FormControlLabel control={<Checkbox />} label="31000 - 40000" className='filter-lables' />

                                                                        <FormControlLabel control={<Checkbox />} label="41000 - 50000" className='filter-lables' />

                                                                        <FormControlLabel control={<Checkbox />} label="5100 - 75000" className='filter-lables' />

                                                                        <FormControlLabel control={<Checkbox />} label="Over 75000" className='filter-lables' />
                                                                  </AccordionDetails>
                                                            </Accordion>
                                                            <Accordion className='filter-accordian'>
                                                                  <AccordionSummary
                                                                        expandIcon={<ExpandMoreIcon />}
                                                                        aria-controls="panel3a-content"
                                                                        id="panel3a-header"
                                                                        className='filter-accordian-summary'
                                                                  >
                                                                        <Typography className='filter-accordian-text'>Weight Ranges</Typography>
                                                                  </AccordionSummary>
                                                                  <AccordionDetails className='filter-accordian-details'>

                                                                        <FormControlLabel control={<Checkbox />} label="0 - 2g" className='filter-lables' />

                                                                        <FormControlLabel control={<Checkbox />} label="2 - 5g" className='filter-lables' />

                                                                        <FormControlLabel control={<Checkbox />} label="5 - 10g" className='filter-lables' />

                                                                        <FormControlLabel control={<Checkbox />} label="10 - 20g" className='filter-lables' />


                                                                        <FormControlLabel control={<Checkbox />} label="20 - 30g" className='filter-lables' />

                                                                        <FormControlLabel control={<Checkbox />} label="> 30g" className='filter-lables' />


                                                                  </AccordionDetails>
                                                            </Accordion>
                                                            <Accordion className='filter-accordian'>
                                                                  <AccordionSummary
                                                                        expandIcon={<ExpandMoreIcon />}
                                                                        aria-controls="panel4a-content"
                                                                        id="panel4a-header"
                                                                        className='filter-accordian-summary'
                                                                  >
                                                                        <Typography className='filter-accordian-text'>Material</Typography>
                                                                  </AccordionSummary>
                                                                  <AccordionDetails className='filter-accordian-details'>

                                                                        <FormControlLabel control={<Checkbox />} label="Platinum" className='filter-lables' />

                                                                        <FormControlLabel control={<Checkbox />} label="Gold" className='filter-lables' />

                                                                        <FormControlLabel control={<Checkbox />} label="Diamond" className='filter-lables' />

                                                                        <FormControlLabel control={<Checkbox />} label="Gemstone" className='filter-lables' />

                                                                  </AccordionDetails>
                                                            </Accordion>
                                                      </Box>
                                                </Box>
                                          </Box>
                                          <Box gridColumn={{ xs: "span 12", sm: "span 12", md: "span 9", lg: "span 9" }}>
                                                <Box >
                                                      <Box className='tabs-main'>
                                                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className='tabs'>
                                                                  <Tab label="All Products" {...a11yProps(0)} />
                                                                  <Tab label="Braceletes" {...a11yProps(1)} />
                                                                  <Tab label="Bangles" {...a11yProps(2)} />
                                                                  <Tab label="Rings" {...a11yProps(3)} />
                                                                  <Tab label="Chains" {...a11yProps(4)} />
                                                                  <Tab label="Pendants" {...a11yProps(5)} />
                                                                  <Tab label="Mangalsutra" {...a11yProps(6)} />
                                                                  <Tab label="Nosepins" {...a11yProps(7)} />
                                                            </Tabs>
                                                      </Box>
                                                      <TabPanel value={value} index={0} className="tabspanel">
                                                            <Box sx={{ width: 1 }}>
                                                                  <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={{ xs: 3, sm: 5, md: 4, lg: 4 }}>
                                                                        <Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 4", lg: "span 4" }}>
                                                                              <Box className='product-box'>
                                                                                    <Box className='product-img-box'>
                                                                                          <img src={png.p1} className="product-img"></img>
                                                                                          <Box className='show-hover'>
                                                                                                <Box className='add-cart-box-hover'>
                                                                                                      <Button className='btn hover-btns'>
                                                                                                            <Box className='add-cart-bg'>
                                                                                                                  <img src={svg.iconheart} className="hover-img"></img>
                                                                                                            </Box>
                                                                                                      </Button>

                                                                                                      <Button className='btn hover-btns'>
                                                                                                            <Box className='add-cart-bg'>
                                                                                                                  <img src={svg.iconcart} className="hover-img"></img>
                                                                                                            </Box>
                                                                                                      </Button>
                                                                                                      <Button className='btn hover-btns'>
                                                                                                            <Box className='add-cart-bg'>
                                                                                                                  <img src={svg.iconsearch} className="hover-img"></img>
                                                                                                            </Box>
                                                                                                      </Button>
                                                                                                </Box>
                                                                                          </Box>
                                                                                    </Box>
                                                                                    <Box className='product-content'>
                                                                                          <Box className='product-title'>Flower Goldcrop Ring</Box>
                                                                                          <Box className='product-sub-title'>Rose gold</Box>
                                                                                          <Box className='product-price'>$12,350.00</Box>
                                                                                          <Box className="product-btn-box">
                                                                                                <Button className='btn btn-product'>View</Button>
                                                                                          </Box>
                                                                                    </Box>
                                                                              </Box>
                                                                        </Box>
                                                                        <Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 4", lg: "span 4" }}>
                                                                              <Box className='product-box'>
                                                                                    <Box className='product-img-box'>
                                                                                          <img src={png.p2} className="product-img"></img>
                                                                                          <Box className='show-hover'>
                                                                                                <Box className='add-cart-box-hover'>
                                                                                                      <Button className='btn hover-btns'>
                                                                                                            <Box className='add-cart-bg'>
                                                                                                                  <img src={svg.iconheart} className="hover-img"></img>
                                                                                                            </Box>
                                                                                                      </Button>

                                                                                                      <Button className='btn hover-btns'>
                                                                                                            <Box className='add-cart-bg'>
                                                                                                                  <img src={svg.iconcart} className="hover-img"></img>
                                                                                                            </Box>
                                                                                                      </Button>
                                                                                                      <Button className='btn hover-btns'>
                                                                                                            <Box className='add-cart-bg'>
                                                                                                                  <img src={svg.iconsearch} className="hover-img"></img>
                                                                                                            </Box>
                                                                                                      </Button>
                                                                                                </Box>
                                                                                          </Box>
                                                                                    </Box>
                                                                                    <Box className='product-content'>
                                                                                          <Box className='product-title'>Flower Goldcrop Ring</Box>
                                                                                          <Box className='product-sub-title'>Rose gold</Box>
                                                                                          <Box className='product-price'>$12,350.00</Box>
                                                                                          <Box className="product-btn-box">
                                                                                                <Button className='btn btn-product'>View</Button>
                                                                                          </Box>
                                                                                    </Box>
                                                                              </Box>
                                                                        </Box>
                                                                        <Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 4", lg: "span 4" }}>
                                                                              <Box className='product-box'>
                                                                                    <Box className='product-img-box'>
                                                                                          <img src={png.p3} className="product-img"></img>
                                                                                          <Box className='show-hover'>
                                                                                                <Box className='add-cart-box-hover'>
                                                                                                      <Button className='btn hover-btns'>
                                                                                                            <Box className='add-cart-bg'>
                                                                                                                  <img src={svg.iconheart} className="hover-img"></img>
                                                                                                            </Box>
                                                                                                      </Button>

                                                                                                      <Button className='btn hover-btns'>
                                                                                                            <Box className='add-cart-bg'>
                                                                                                                  <img src={svg.iconcart} className="hover-img"></img>
                                                                                                            </Box>
                                                                                                      </Button>
                                                                                                      <Button className='btn hover-btns'>
                                                                                                            <Box className='add-cart-bg'>
                                                                                                                  <img src={svg.iconsearch} className="hover-img"></img>
                                                                                                            </Box>
                                                                                                      </Button>
                                                                                                </Box>
                                                                                          </Box>
                                                                                    </Box>
                                                                                    <Box className='product-content'>
                                                                                          <Box className='product-title'>Flower Goldcrop Ring</Box>
                                                                                          <Box className='product-sub-title'>Rose gold</Box>
                                                                                          <Box className='product-price'>$12,350.00</Box>
                                                                                          <Box className="product-btn-box">
                                                                                                <Button className='btn btn-product'>View</Button>
                                                                                          </Box>
                                                                                    </Box>
                                                                              </Box>
                                                                        </Box>
                                                                        <Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 4", lg: "span 4" }}>
                                                                              <Box className='product-box'>
                                                                                    <Box className='product-img-box'>
                                                                                          <img src={png.p4} className="product-img"></img>
                                                                                          <Box className='show-hover'>
                                                                                                <Box className='add-cart-box-hover'>
                                                                                                      <Button className='btn hover-btns'>
                                                                                                            <Box className='add-cart-bg'>
                                                                                                                  <img src={svg.iconheart} className="hover-img"></img>
                                                                                                            </Box>
                                                                                                      </Button>

                                                                                                      <Button className='btn hover-btns'>
                                                                                                            <Box className='add-cart-bg'>
                                                                                                                  <img src={svg.iconcart} className="hover-img"></img>
                                                                                                            </Box>
                                                                                                      </Button>
                                                                                                      <Button className='btn hover-btns'>
                                                                                                            <Box className='add-cart-bg'>
                                                                                                                  <img src={svg.iconsearch} className="hover-img"></img>
                                                                                                            </Box>
                                                                                                      </Button>
                                                                                                </Box>
                                                                                          </Box>
                                                                                    </Box>
                                                                                    <Box className='product-content'>
                                                                                          <Box className='product-title'>Flower Goldcrop Ring</Box>
                                                                                          <Box className='product-sub-title'>Rose gold</Box>
                                                                                          <Box className='product-price'>$12,350.00</Box>
                                                                                          <Box className="product-btn-box">
                                                                                                <Button className='btn btn-product'>View</Button>
                                                                                          </Box>
                                                                                    </Box>
                                                                              </Box>
                                                                        </Box>
                                                                        <Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 4", lg: "span 4" }}>
                                                                              <Box className='product-box'>
                                                                                    <Box className='product-img-box'>
                                                                                          <img src={png.p5} className="product-img"></img>
                                                                                          <Box className='show-hover'>
                                                                                                <Box className='add-cart-box-hover'>
                                                                                                      <Button className='btn hover-btns'>
                                                                                                            <Box className='add-cart-bg'>
                                                                                                                  <img src={svg.iconheart} className="hover-img"></img>
                                                                                                            </Box>
                                                                                                      </Button>

                                                                                                      <Button className='btn hover-btns'>
                                                                                                            <Box className='add-cart-bg'>
                                                                                                                  <img src={svg.iconcart} className="hover-img"></img>
                                                                                                            </Box>
                                                                                                      </Button>
                                                                                                      <Button className='btn hover-btns'>
                                                                                                            <Box className='add-cart-bg'>
                                                                                                                  <img src={svg.iconsearch} className="hover-img"></img>
                                                                                                            </Box>
                                                                                                      </Button>
                                                                                                </Box>
                                                                                          </Box>
                                                                                    </Box>
                                                                                    <Box className='product-content'>
                                                                                          <Box className='product-title'>Flower Goldcrop Ring</Box>
                                                                                          <Box className='product-sub-title'>Rose gold</Box>
                                                                                          <Box className='product-price'>$12,350.00</Box>
                                                                                          <Box className="product-btn-box">
                                                                                                <Button className='btn btn-product'>View</Button>
                                                                                          </Box>
                                                                                    </Box>
                                                                              </Box>
                                                                        </Box>
                                                                        <Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 4", lg: "span 4" }}>
                                                                              <Box className='product-box'>
                                                                                    <Box className='product-img-box'>
                                                                                          <img src={png.p6} className="product-img"></img>
                                                                                          <Box className='show-hover'>
                                                                                                <Box className='add-cart-box-hover'>
                                                                                                      <Button className='btn hover-btns'>
                                                                                                            <Box className='add-cart-bg'>
                                                                                                                  <img src={svg.iconheart} className="hover-img"></img>
                                                                                                            </Box>
                                                                                                      </Button>

                                                                                                      <Button className='btn hover-btns'>
                                                                                                            <Box className='add-cart-bg'>
                                                                                                                  <img src={svg.iconcart} className="hover-img"></img>
                                                                                                            </Box>
                                                                                                      </Button>
                                                                                                      <Button className='btn hover-btns'>
                                                                                                            <Box className='add-cart-bg'>
                                                                                                                  <img src={svg.iconsearch} className="hover-img"></img>
                                                                                                            </Box>
                                                                                                      </Button>
                                                                                                </Box>
                                                                                          </Box>
                                                                                    </Box>
                                                                                    <Box className='product-content'>
                                                                                          <Box className='product-title'>Flower Goldcrop Ring</Box>
                                                                                          <Box className='product-sub-title'>Rose gold</Box>
                                                                                          <Box className='product-price'>$12,350.00</Box>
                                                                                          <Box className="product-btn-box">
                                                                                                <Button className='btn btn-product'>View</Button>
                                                                                          </Box>
                                                                                    </Box>
                                                                              </Box>
                                                                        </Box>
                                                                        <Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 4", lg: "span 4" }}>
                                                                              <Box className='product-box'>
                                                                                    <Box className='product-img-box'>
                                                                                          <img src={png.p7} className="product-img"></img>
                                                                                          <Box className='show-hover'>
                                                                                                <Box className='add-cart-box-hover'>
                                                                                                      <Button className='btn hover-btns'>
                                                                                                            <Box className='add-cart-bg'>
                                                                                                                  <img src={svg.iconheart} className="hover-img"></img>
                                                                                                            </Box>
                                                                                                      </Button>

                                                                                                      <Button className='btn hover-btns'>
                                                                                                            <Box className='add-cart-bg'>
                                                                                                                  <img src={svg.iconcart} className="hover-img"></img>
                                                                                                            </Box>
                                                                                                      </Button>
                                                                                                      <Button className='btn hover-btns'>
                                                                                                            <Box className='add-cart-bg'>
                                                                                                                  <img src={svg.iconsearch} className="hover-img"></img>
                                                                                                            </Box>
                                                                                                      </Button>
                                                                                                </Box>
                                                                                          </Box>
                                                                                    </Box>
                                                                                    <Box className='product-content'>
                                                                                          <Box className='product-title'>Flower Goldcrop Ring</Box>
                                                                                          <Box className='product-sub-title'>Rose gold</Box>
                                                                                          <Box className='product-price'>$12,350.00</Box>
                                                                                          <Box className="product-btn-box">
                                                                                                <Button className='btn btn-product'>View</Button>
                                                                                          </Box>
                                                                                    </Box>
                                                                              </Box>
                                                                        </Box>
                                                                        <Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 4", lg: "span 4" }}>
                                                                              <Box className='product-box'>
                                                                                    <Box className='product-img-box'>
                                                                                          <img src={png.p8} className="product-img"></img>
                                                                                          <Box className='show-hover'>
                                                                                                <Box className='add-cart-box-hover'>
                                                                                                      <Button className='btn hover-btns'>
                                                                                                            <Box className='add-cart-bg'>
                                                                                                                  <img src={svg.iconheart} className="hover-img"></img>
                                                                                                            </Box>
                                                                                                      </Button>

                                                                                                      <Button className='btn hover-btns'>
                                                                                                            <Box className='add-cart-bg'>
                                                                                                                  <img src={svg.iconcart} className="hover-img"></img>
                                                                                                            </Box>
                                                                                                      </Button>
                                                                                                      <Button className='btn hover-btns'>
                                                                                                            <Box className='add-cart-bg'>
                                                                                                                  <img src={svg.iconsearch} className="hover-img"></img>
                                                                                                            </Box>
                                                                                                      </Button>
                                                                                                </Box>
                                                                                          </Box>
                                                                                    </Box>
                                                                                    <Box className='product-content'>
                                                                                          <Box className='product-title'>Flower Goldcrop Ring</Box>
                                                                                          <Box className='product-sub-title'>Rose gold</Box>
                                                                                          <Box className='product-price'>$12,350.00</Box>
                                                                                          <Box className="product-btn-box">
                                                                                                <Button className='btn btn-product'>View</Button>
                                                                                          </Box>
                                                                                    </Box>
                                                                              </Box>
                                                                        </Box>
                                                                        <Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 4", lg: "span 4" }}>
                                                                              <Box className='product-box'>
                                                                                    <Box className='product-img-box'>
                                                                                          <img src={png.p9} className="product-img"></img>
                                                                                          <Box className='show-hover'>
                                                                                                <Box className='add-cart-box-hover'>
                                                                                                      <Button className='btn hover-btns'>
                                                                                                            <Box className='add-cart-bg'>
                                                                                                                  <img src={svg.iconheart} className="hover-img"></img>
                                                                                                            </Box>
                                                                                                      </Button>

                                                                                                      <Button className='btn hover-btns'>
                                                                                                            <Box className='add-cart-bg'>
                                                                                                                  <img src={svg.iconcart} className="hover-img"></img>
                                                                                                            </Box>
                                                                                                      </Button>
                                                                                                      <Button className='btn hover-btns'>
                                                                                                            <Box className='add-cart-bg'>
                                                                                                                  <img src={svg.iconsearch} className="hover-img"></img>
                                                                                                            </Box>
                                                                                                      </Button>
                                                                                                </Box>
                                                                                          </Box>
                                                                                    </Box>
                                                                                    <Box className='product-content'>
                                                                                          <Box className='product-title'>Flower Goldcrop Ring</Box>
                                                                                          <Box className='product-sub-title'>Rose gold</Box>
                                                                                          <Box className='product-price'>$12,350.00</Box>
                                                                                          <Box className="product-btn-box">
                                                                                                <Button className='btn btn-product'>View</Button>
                                                                                          </Box>
                                                                                    </Box>
                                                                              </Box>
                                                                        </Box>
                                                                  </Box>
                                                            </Box>


                                                      </TabPanel>
                                                      <TabPanel value={value} index={1}>
                                                            Item Two
                                                      </TabPanel>
                                                      <TabPanel value={value} index={2}>
                                                            Item Three
                                                      </TabPanel>
                                                      <TabPanel value={value} index={3}>
                                                            Item four
                                                      </TabPanel>
                                                      <TabPanel value={value} index={4}>
                                                            Item five
                                                      </TabPanel>
                                                      <TabPanel value={value} index={5}>
                                                            Item six
                                                      </TabPanel>
                                                      <TabPanel value={value} index={6}>
                                                            Item seven
                                                      </TabPanel>
                                                </Box>
                                                <Box>
                                                      <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={{ xs: 0, sm: 0, md: 0, lg: 5 }}>
                                                            <Box gridColumn={{ xs: "span 12", sm: "span 12", md: "span 12", lg: "span 12" }}>
                                                                  <Box className='pagination-box-mens-womens'>
                                                                        <Pagination count={3} variant="outlined" shape="rounded" className='pagination' />
                                                                  </Box>
                                                            </Box>
                                                      </Box>
                                                </Box>
                                          </Box>
                                    </Box>
                              </Box>
                        </Box>
                  </Box>
                  <Footer />
            </div>
      )
}
