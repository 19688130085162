import React, { useState, useEffect } from 'react';
import {
  Container,
  Box,
  Grid,
  Typography,
  Stack,
  ListItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  styled,
  TableRow,
} from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import dataService from '../../../../config/dataService';
import { Api } from '../../../../config/api';
import { useParams, useHistory } from 'react-router-dom';
import { totalPrice } from '../../../../component/customComponents/priceCalculation/TotalPrice';
import { toast } from 'react-toastify';
import ContainedButton from '../../../../component/customComponents/buttons/ContainedButton';
import Png from '../../../../assets/png';
import PdfDownloader from '../../../../component/customComponents/pdfDownloader/PdfDownloader';
import moment from 'moment';
import Loader from '../../../../component/customComponents/loader/Loader';
import {
  commaSeparated,
  ConvertIntoTwoDigits,
  price,
} from '../../../../component/customComponents/commonFunctions/CommonFunctions';
const ReactDOMServer = require('react-dom/server');
const HtmlToReactParser = require('html-to-react').Parser;

//#region ALL HOOKS AND VARIABALS

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const PurchaseOrder = () => {
  let token = localStorage.getItem('auth');
  let history = useHistory();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState('panel1');
  const [buyerData, setBuyerData] = useState({});
  const [sellerData, setSellerData] = useState({});
  const [productData, setProductData] = useState({});
  const [productData1, setProductData1] = useState([]);
  const [orderData, setOrderData] = useState({});
  const [admin, setAdmin] = useState({});
  const [data, setData] = useState({});
  const [templetData, setTempletData] = useState({});

  //#endregion

  //#region ALL EVENT HANDLERS AND  FUCTIONS

  const getPaymentOrder = async () => {
    setLoading(true);
    const urlencoded = new URLSearchParams();
    try {
      urlencoded.append('id', id);
      const returnData = await dataService.post(
        Api.Admin.singlePayment,
        urlencoded,
        {
          headers: {
            auth: token,
          },
        }
      );
      setBuyerData(returnData?.data?.data?.singlePayment?.user_id);
      setSellerData(returnData?.data?.data?.singlePayment?.seller_id);
      setProductData(
        returnData?.data?.data?.singlePayment?.order_id?.products?.[0]
      );
      setProductData1(
        returnData?.data?.data?.singlePayment?.order_id?.products
      );
      setOrderData(returnData?.data?.data?.singlePayment?.order_id);
      setData(returnData?.data?.data?.singlePayment);
      setAdmin(returnData?.data?.data?.adminDetails);
    } catch (err) {
      toast.error(err?.message || 'Something went wrong');
    } finally {
      setLoading(false);
    }
  };

  const getTempletPdf = async () => {
    const urlencoded = new URLSearchParams();
    try {
      urlencoded.append('id', id);
      const tempData = await dataService.post(
        Api.Admin.templatePurchaseOrderPdf,
        urlencoded,
        {
          headers: {
            auth: token,
          },
        }
      );
      setTempletData(tempData.data);
    } catch (err) {
      toast.error(err?.message || 'Something went wrong');
    }
  };

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  let elem = templetData;
  var htmlToReactParser = new HtmlToReactParser();
  var reactElement = htmlToReactParser.parse(elem);
  var reactHtml = ReactDOMServer.renderToStaticMarkup(reactElement);
  // assert.equal(reactHtml, elem)

  useEffect(() => {
    getPaymentOrder();
    getTempletPdf();
  }, []);

  //#endregion

  //#region RETURN JSX
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Container maxWidth="false" sx={{ mb: 5 }}>
          <Typography align="center" variant="h4" sx={{ fontWeight: 'bold' }}>
            Purchase Order
          </Typography>
          <Box
            sx={{
              border: '1px solid black',
              borderBottom: 0,
            }}
          >
            <Grid container>
              <Grid item xs={12} lg={10}>
                <Typography
                  align="center"
                  variant="h6"
                  sx={{ fontWeight: 'bold' }}
                >
                  {/* Zaverica */}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                lg={2}
                sx={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                <PdfDownloader
                  rootElement={elem}
                  rootElementId="pdf"
                  name="Invoice"
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} lg={8}>
                <Stack spacing={0}>
                  <ListItem>Company Address - {admin?.name}</ListItem>
                  <ListItem>City Name - </ListItem>
                  <ListItem>Phone No - {admin?.mobile_number}</ListItem>
                  <ListItem>Email - {admin?.email}</ListItem>
                </Stack>
              </Grid>

              <Grid item xs={12} lg={4}>
                <Stack spacing={0}>
                  <ListItem sx={{ fontSize: '25px' }}>Purchase Order </ListItem>
                  <ListItem>
                    Order Date -{' '}
                    {/* {new Date(data?.order_place_date).toLocaleDateString()} */}
                    {moment(data?.order_place_date).format('DD/MM/YYYY')}
                  </ListItem>
                  <ListItem>Order Id - {orderData?.order_id}</ListItem>
                  <ListItem>Order Item Id - {data?.order_item_id}</ListItem>
                </Stack>
              </Grid>
            </Grid>
          </Box>
          <Box>
            <Typography
              sx={{
                border: '1px solid black',
                height: '50px',
                borderBottom: 0,
                pt: 1.5,
                pl: 2,
                bgcolor: '#7699aa',
                fontWeight: 'bold',
              }}
            >
              Sellers Information
            </Typography>
            <Grid
              container
              sx={{
                border: '1px solid black',
                borderBottom: 0,
              }}
            >
              <Grid item xs={12} lg={6}>
                <Stack spacing={0}>
                  <ListItem>
                    Name - {sellerData ? sellerData?.company_name : admin?.name}
                  </ListItem>
                  <ListItem>
                    Owner Name -{' '}
                    {sellerData
                      ? sellerData?.name || sellerData?.owner_name
                      : admin?.name}
                  </ListItem>
                  <ListItem>
                    Phone No -{' '}
                    {sellerData
                      ? sellerData?.contact_number
                      : admin?.mobile_number}
                  </ListItem>
                  <ListItem>
                    Email - {sellerData ? sellerData?.email : admin?.email}
                  </ListItem>
                </Stack>
              </Grid>

              <Grid item xs={12} lg={6}>
                <Stack spacing={0}>
                  <ListItem>
                    Address - {sellerData ? sellerData?.company_address : ''}
                  </ListItem>
                  <ListItem>
                    City - {sellerData ? sellerData?.company_address : ''}
                  </ListItem>
                </Stack>
              </Grid>
            </Grid>
            <Typography
              sx={{
                border: '1px solid black',
                height: '50px',
                borderBottom: 0,
                pt: 1.5,
                pl: 2,
                bgcolor: '#7699aa',
                fontWeight: 'bold',
              }}
            >
              Buyers Information
            </Typography>
            <Grid
              container
              sx={{
                border: '1px solid black',
                borderBottom: 0,
              }}
            >
              <Grid item xs={12} lg={6}>
                <Stack spacing={0}>
                  <ListItem>Name - {buyerData?.company_name}</ListItem>
                  <ListItem>
                    Owner Name - {buyerData?.name || buyerData?.owner_name}
                  </ListItem>
                  <ListItem>Phone No - {buyerData?.contact_number}</ListItem>
                  <ListItem>Email - {buyerData?.email}</ListItem>
                </Stack>
              </Grid>

              <Grid item xs={12} lg={6}>
                <Stack spacing={0}>
                  <ListItem>Address - {buyerData?.company_address}</ListItem>
                  <ListItem>City - {buyerData?.company_address}</ListItem>
                </Stack>
              </Grid>
            </Grid>
          </Box>
          <Table class="tg">
            <thead>
              <tr class="purchase-order-table-head-color">
                <th class="tg-0pky">Sr No</th>
                <th class="tg-0pky">Product Id</th>
                <th class="tg-0pky">Product</th>
                <th colspan="2">Weight/Purity</th>
                <th class="tg-0pky">Price</th>
                <th class="tg-0pky">Qty</th>
                <th class="tg-0pky">Total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="tg-0pky">1</td>
                <td class="tg-0pky">{productData?.orderProductId}</td>
                <td class="tg-0pky">{productData?.productName}</td>
                <td class="tg-0pky" colspan="2">
                  <Accordion
                    expanded={expanded === 'panel1'}
                    onChange={handleChange('panel1')}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      Weight/Purity
                    </AccordionSummary>
                    <AccordionDetails>
                      {data?.product_id?.gold?.length ||
                      data?.product_id?.silver?.length ? (
                        <Grid container>
                          <Grid item lg={4}></Grid>
                          <Grid item lg={4} sx={{ textAlignLast: 'center' }}>
                            Wt (gm)
                          </Grid>
                          <Grid item lg={4} sx={{ textAlignLast: 'center' }}>
                            {' '}
                            Purity(%)
                          </Grid>
                        </Grid>
                      ) : null}
                      {data?.product_id?.gold?.length ? (
                        <Grid container>
                          {data?.product_id?.gold?.map((val, index) => {
                            return (
                              <>
                                <Grid item lg={4}>
                                  {data?.product_id?.gold.length > 1
                                    ? `Gold${index + 1}`
                                    : 'Gold'}
                                </Grid>
                                <Grid
                                  item
                                  lg={4}
                                  sx={{ textAlignLast: 'center' }}
                                >
                                  {ConvertIntoTwoDigits(val?.weight)}
                                </Grid>
                                <Grid
                                  item
                                  lg={4}
                                  sx={{ textAlignLast: 'center' }}
                                >
                                  {ConvertIntoTwoDigits(val?.purity)}
                                </Grid>
                              </>
                            );
                          })}
                        </Grid>
                      ) : null}
                      {data?.product_id?.silver?.length ? (
                        <Grid container>
                          {data?.product_id?.silver?.map((val, index) => {
                            return (
                              <>
                                <Grid item lg={4}>
                                  {data?.product_id?.silver.length > 1
                                    ? `Silver${index + 1}`
                                    : 'Silver'}
                                </Grid>
                                <Grid
                                  item
                                  lg={4}
                                  sx={{ textAlignLast: 'center' }}
                                >
                                  {ConvertIntoTwoDigits(val?.weight)}
                                </Grid>
                                <Grid
                                  item
                                  lg={4}
                                  sx={{ textAlignLast: 'center' }}
                                >
                                  {ConvertIntoTwoDigits(val?.purity)}
                                </Grid>
                              </>
                            );
                          })}
                        </Grid>
                      ) : null}
                      {data?.product_id?.gemstone?.length ||
                      data?.product_id?.diamond?.length ? (
                        <>
                          <Box sx={{ my: 4 }}></Box>
                          <Grid container>
                            <Grid item lg={4}></Grid>
                            <Grid item lg={4} sx={{ textAlignLast: 'center' }}>
                              Wt (gm)
                            </Grid>
                            <Grid item lg={4} sx={{ textAlignLast: 'center' }}>
                              {' '}
                              Purity(Carat)
                            </Grid>
                          </Grid>
                        </>
                      ) : null}
                      {data?.product_id?.gemstone?.length ? (
                        <Grid container>
                          {data?.product_id?.gemstone?.map((val, index) => {
                            return (
                              <>
                                <Grid item lg={4}>
                                  {data?.product_id?.gemstone.length > 1
                                    ? `Gemstone${index + 1}`
                                    : 'Gemstone'}
                                </Grid>
                                <Grid
                                  item
                                  lg={4}
                                  sx={{ textAlignLast: 'center' }}
                                >
                                  {ConvertIntoTwoDigits(val?.total_weight)}
                                </Grid>
                                <Grid
                                  item
                                  lg={4}
                                  sx={{ textAlignLast: 'center' }}
                                >
                                  {ConvertIntoTwoDigits(val?.purity)}
                                </Grid>
                              </>
                            );
                          })}
                        </Grid>
                      ) : null}
                      {data?.product_id?.diamond?.length ? (
                        <Grid container>
                          {data?.product_id?.diamond?.map((val, index) => {
                            return (
                              <>
                                <Grid item lg={4}>
                                  {data?.product_id?.diamond.length > 1
                                    ? `Diamond${index + 1}`
                                    : 'Diamond'}
                                </Grid>
                                <Grid
                                  item
                                  lg={4}
                                  sx={{ textAlignLast: 'center' }}
                                >
                                  {ConvertIntoTwoDigits(val?.total_weight)}
                                </Grid>
                                <Grid
                                  item
                                  lg={4}
                                  sx={{ textAlignLast: 'center' }}
                                >
                                  {ConvertIntoTwoDigits(val?.purity)}
                                </Grid>
                              </>
                            );
                          })}
                        </Grid>
                      ) : null}
                    </AccordionDetails>
                  </Accordion>
                </td>
                <td class="tg-0pky">
                  {productData1?.map((product) => {
                    if (product?.productId == data?.product_id?._id) {
                      return price(product?.price);
                    }
                  })}
                  <span>
                    <img src={Png.rupee} className="rupee-icon"></img>
                  </span>
                </td>
                <td class="tg-0pky">
                  {productData1?.map((product) => {
                    if (product?.productId == data?.product_id?._id) {
                      return ConvertIntoTwoDigits(product?.quantity);
                    }
                  })}
                </td>
                <td class="tg-0pky">
                  {productData1?.map((product) => {
                    if (product?.productId == data?.product_id?._id) {
                      return price(product?.total_price);
                    }
                  })}
                  <span>
                    <img src={Png.rupee} className="rupee-icon"></img>
                  </span>
                </td>
              </tr>
              <tr>
                <td class="tg-0pky purchase-order-table-head-color" colspan="5">
                  {' '}
                  Additional Notes:
                </td>
                <td class="tg-0pky">Subtotal</td>
                <td class="tg-0pky"></td>
                <td class="tg-0pky">
                  {productData1?.map((product) => {
                    if (product?.productId == data?.product_id?._id) {
                      return price(product?.total_price);
                    }
                  })}
                  <span>
                    <img src={Png.rupee} className="rupee-icon"></img>
                  </span>
                </td>
              </tr>
              <tr>
                <td class="tg-0pky" colspan="5" rowspan="3"></td>
                <td class="tg-0pky">Taxes</td>
                <td class="tg-0pky">00%</td>
                <td class="tg-0pky">0000</td>
              </tr>
              <tr>
                <td class="tg-0pky">Discount</td>
                <td class="tg-0pky">00%</td>
                <td class="tg-0pky">0000</td>
              </tr>
              <tr>
                <td class="tg-0pky">Total</td>
                <td class="tg-0pky"></td>
                <td class="tg-0pky">
                  {productData1?.map((product) => {
                    if (product?.productId == data?.product_id?._id) {
                      return price(product?.total_price);
                    }
                  })}
                  <span>
                    <img src={Png.rupee} className="rupee-icon"></img>
                  </span>
                </td>
              </tr>
            </tbody>
          </Table>
          <Box id="pdf"></Box>
          {/* <Box id="pdf">{reactElement}</Box>  to see responce pdf from api */}
        </Container>
      )}
    </>
  );
};

export default PurchaseOrder;
//#endregion
