//#region ALL IMPORTS
import React, { useState, useEffect, useMemo } from 'react';
import {
    Grid,
    Container,
    Typography,
    TextareaAutosize,
    Box,
    TextField,
} from '@mui/material';
import { useHistory, useParams } from 'react-router-dom';
import { Api } from '../../../../../config/api';
import dataService from '../../../../../config/dataService';
import { toast } from 'react-toastify';
import ContainedButton from '../../../../../component/customComponents/buttons/ContainedButton';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import {
    ErrorMsg,
    TextAreaErrorMsg,
} from '../../../../../component/customComponents/errorMsg/ErrorMsg';
import {
    descriptionLength,
    headingLength,
    minDescriptionLength,
    minHeadingLength,
    NO_WHITE_SPACE_BEGINNING_END,
} from '../../../../../component/customComponents/validation/Validation';
//#endregion

import AdminIndex from '../../../AdminIndex';


const { updatePrivacyPolicy, getPrivacyPolicy } = AdminIndex.AdminSagaActions;
//#region ALL HOOKS AND VARIABALS
const UpdatePrivacyPolicy = () => {
    const { id } = useParams();
    let token = localStorage.getItem('auth');

    const dispatch = AdminIndex.useDispatch();
    const navigate = useHistory();
    const [getData, setGetData] = useState('');

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm();
    //#endregion

    //#region ALL EVENT HANDLERS AND  FUCTIONS
    const getTAndC = async () => {
        let urlencoded = new URLSearchParams();
        try {
            urlencoded.append('id', id);

            dispatch(getPrivacyPolicy(token, urlencoded));
            // const Data = await dataService.post(Api.Admin.getCms, urlencoded);
            // setGetData(Data.data.data);
        } catch (err) {
            toast.error(err?.message || 'Something went wrong');
        }
    };

    const termCondition = AdminIndex.useSelector((state) => {
        return state.AdminSaga.privacyPolicy;
    });

    useEffect(() => {
        setGetData(termCondition);
    }, [termCondition]);

    useEffect(() => {
        getTAndC();
    }, []);

    useEffect(() => {
        setValue('tAndCHeading', getData?.heading_privacy_policy);
        setValue('tAndC', getData?.privacy_policy);
    }, [getData]);

    const handleSave = async (data) => {
        try {
            let urlencoded = new URLSearchParams();
            urlencoded.append('id', id);
            urlencoded.append('heading_privacy_policy', data?.tAndCHeading?.trim());
            urlencoded.append('privacy_policy', data?.tAndC?.trim());

            dispatch(updatePrivacyPolicy(token, urlencoded, navigate));
            // const tAndCHeadingAndtAndC = await dataService.post(
            //   Api.Admin.createTermsAndCondition,
            //   urlencoded,
            //   {
            //     headers: {
            //       auth: token,
            //     },
            //   }
            // );

            // if (tAndCHeadingAndtAndC.data.status == 1) {
            //   toast.success('Terms & Conditions updated successfully');
            //   history.push('/admin/contentmanagement');
            // }
        } catch (err) {
            toast.error(err?.message || 'Something went wrong');
        }
    };
    //#endregion

    //#region RETURN JSX
    return (
        <>
            <form onSubmit={handleSubmit(handleSave)}>
                <Container maxWidth="false">
                    <Typography sx={{ my: 2 }} align="center" variant="h4">
                        Privacy and Policy Management
                    </Typography>

                    <Grid container spacing={5}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Typography align="left" sx={{ mt: 2 }}>
                                Privacy and Policy Heading
                            </Typography>
                            <Box className="text-area-control-main">
                                <TextField
                                    className="form-control border-none"
                                    autoComplete="off"
                                    sx={{ mt: 2, color: 'red' }}
                                    size="small"
                                    fullWidth
                                    name="tAndCHeading"
                                    type="text"
                                    {...register('tAndCHeading', {
                                        required: (
                                            <ErrorMsg msg="Please enter privacy and policy heading" />
                                        ),
                                        minLength: {
                                            value: minHeadingLength,
                                            message: (
                                                <ErrorMsg
                                                    msg={`Minimum ${minHeadingLength} Characters Allowed`}
                                                />
                                            ),
                                        },
                                        maxLength: {
                                            value: headingLength,
                                            message: (
                                                <ErrorMsg
                                                    msg={`Maximum ${headingLength} Characters Allowed`}
                                                />
                                            ),
                                        },
                                        pattern: {
                                            value: NO_WHITE_SPACE_BEGINNING_END,
                                            message: (
                                                <TextAreaErrorMsg msg="Whitespace is not allowed at beginning or end" />
                                            ),
                                        },
                                    })}
                                />
                            </Box>
                            <ErrorMessage errors={errors} name="tAndCHeading" />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Typography align="left" sx={{ mt: 2 }}>
                                Privacy and Policy Description
                            </Typography>
                            <Box className="text-area-control-main">
                                <TextareaAutosize
                                    autoComplete="off"
                                    minRows={0}
                                    size="small"
                                    fullWidth
                                    className="form-control border-none text-area-control1 text-area-control-admin"
                                    name="tAndC"
                                    type="text"
                                    {...register('tAndC', {
                                        required: (
                                            <TextAreaErrorMsg msg="Please enter privacy and policy" />
                                        ),
                                        minLength: {
                                            value: minDescriptionLength,
                                            message: (
                                                <TextAreaErrorMsg
                                                    msg={`Minimum ${minDescriptionLength} Characters Allowed`}
                                                />
                                            ),
                                        },
                                        maxLength: {
                                            value: descriptionLength,
                                            message: (
                                                <TextAreaErrorMsg
                                                    msg={`Maximum ${descriptionLength} Characters Allowed`}
                                                />
                                            ),
                                        },
                                        pattern: {
                                            value: NO_WHITE_SPACE_BEGINNING_END,
                                            message: (
                                                <TextAreaErrorMsg msg="Whitespace is not allowed at beginning or end" />
                                            ),
                                        },
                                    })}
                                />
                                <ErrorMessage errors={errors} name="tAndC" />
                            </Box>
                        </Grid>
                    </Grid>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}>
                        <ContainedButton name="Update" type="submit" />
                    </Box>
                </Container>
            </form>
        </>
    );
};

export default UpdatePrivacyPolicy;
//#endregion
