//#region ALL IMPORTS
import React, { useState } from 'react';
import {
    Grid,
    Container,
    Typography,
    TextareaAutosize,
    Box,
    TextField,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import { Api } from '../../../../../config/api';
import dataService from '../../../../../config/dataService';
import { toast } from 'react-toastify';
import ContainedButton from '../../../../../component/customComponents/buttons/ContainedButton';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import {
    ErrorMsg,
    TextAreaErrorMsg,
} from '../../../../../component/customComponents/errorMsg/ErrorMsg';
import {
    descriptionLength,
    headingLength,
    minDescriptionLength,
    minHeadingLength,
    NO_WHITE_SPACE_BEGINNING_END,
} from '../../../../../component/customComponents/validation/Validation';
//#endregion

//#region ALL HOOKS AND VARIABALS
const AddReturnPolicy = () => {
    let token = localStorage.getItem('auth');
    const history = useHistory();

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm();
    //#endregion

    //#region ALL EVENT HANDLERS AND  FUCTIONS

    const handleSave = async (data) => {
        try {
            let urlencoded = new URLSearchParams();
            urlencoded.append('heading_return_policy', data?.tAndCHeading?.trim());
            urlencoded.append('return_policy', data?.tAndC?.trim());

            const tAndCHeadingData = await dataService.post(
                Api.Admin.createTermsAndCondition,
                urlencoded,
                {
                    headers: {
                        auth: token,
                    },
                }
            );

            if (tAndCHeadingData.data.status === 1) {
                toast.success('Return policy added successfully');
                history.push('/admin/contentmanagement');
            }
        } catch (err) {
            toast.error(err?.message || 'Something went wrong');
        }
    };
    //#endregion

    //#region RETURN JSX
    return (
        <>
            <form onSubmit={handleSubmit(handleSave)}>
                <Container maxWidth="false">
                    <Typography sx={{ my: 2 }} align="center" variant="h4">
                    Return Policy Management
                    </Typography>

                    <Grid container spacing={5}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Typography align="left" sx={{ mt: 2 }}>
                            Return Policy Heading
                            </Typography>
                            <Box className="text-area-control-main">
                                <TextField
                                    autoComplete="off"
                                    sx={{ mt: 2 }}
                                    size="small"
                                    fullWidth
                                    className="form-control border-none"
                                    name="tAndCHeading"
                                    type="text"
                                    {...register('tAndCHeading', {
                                        required: (
                                            <ErrorMsg msg="Please enter Return policy heading" />
                                        ),
                                        minLength: {
                                            value: minHeadingLength,
                                            message: (
                                                <ErrorMsg
                                                    msg={`Minimum ${minHeadingLength} Characters Allowed`}
                                                />
                                            ),
                                        },
                                        maxLength: {
                                            value: headingLength,
                                            message: (
                                                <ErrorMsg
                                                    msg={`Maximum ${headingLength} Characters Allowed`}
                                                />
                                            ),
                                        },
                                        pattern: {
                                            value: NO_WHITE_SPACE_BEGINNING_END,
                                            message: (
                                                <TextAreaErrorMsg msg="Whitespace is not allowed at beginning or end" />
                                            ),
                                        },
                                    })}
                                />
                            </Box>
                            <ErrorMessage errors={errors} name="tAndCHeading" />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Typography align="left" sx={{ mt: 2 }}>
                            Return Policy Description
                            </Typography>
                            <Box className="text-area-control-main">
                                <TextareaAutosize
                                    autoComplete="off"
                                    minRows={0}
                                    size="small"
                                    fullWidth
                                    className="form-control border-none text-area-control1 text-area-control-admin"
                                    name="tAndC"
                                    type="text"
                                    {...register('tAndC', {
                                        required: (
                                            <TextAreaErrorMsg msg="Please enter return policy" />
                                        ),
                                        minLength: {
                                            value: minDescriptionLength,
                                            message: (
                                                <TextAreaErrorMsg
                                                    msg={`Minimum ${minDescriptionLength} Characters Allowed`}
                                                />
                                            ),
                                        },
                                        maxLength: {
                                            value: descriptionLength,
                                            message: (
                                                <TextAreaErrorMsg
                                                    msg={`Maximum ${descriptionLength} Characters Allowed`}
                                                />
                                            ),
                                        },
                                        pattern: {
                                            value: NO_WHITE_SPACE_BEGINNING_END,
                                            message: (
                                                <TextAreaErrorMsg msg="Whitespace is not allowed at beginning or end" />
                                            ),
                                        },
                                    })}
                                />
                                <ErrorMessage errors={errors} name="tAndC" />
                            </Box>
                        </Grid>
                    </Grid>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}>
                        <ContainedButton name="Submit" type="submit" />
                    </Box>
                </Container>
            </form>
        </>
    );
};

export default AddReturnPolicy;
//#endregion
