import React, { useEffect, useState } from "react";
import UserIndex from "../../UserIndex";

const { ListProductPuritySaga } = UserIndex.UserSagaActions;

const Purity = () => {
    const [listProductPurityData, setlistProductPurityData] = useState("");
    const params = UserIndex.useParams();
    const dispatch = UserIndex.useDispatch();
    const navigate = UserIndex.useHistory();
    const loding = UserIndex.useSelector((state) => {
      return state.Usersaga.loading;
    });
    const ListProductPurityData = UserIndex.useSelector((state) => {
      return state.Usersaga.ListProductPurityData;
    });
  
    const getListProductPurityData = async () => {
      let urlencoded = new URLSearchParams();
      dispatch(ListProductPuritySaga(urlencoded, navigate));
    };
  
    // useEffect(() => {
    //   setProductPricingData(ListProductPricingData);
    // }, [ListProductPricingData]);
  
    useEffect(() => {
        getListProductPurityData();
    }, []);
  
    useEffect(() => {
      let newcategory = ListProductPurityData?.filter((element) => {
        if (element.material_type == params.material) {
          return element;
        } else {
          return;
        }
      });
      setlistProductPurityData(newcategory?.[0]);
    }, [ListProductPurityData, params]);
  return (
    <div>
      <UserIndex.Header />
      {ListProductPurityData && ListProductPurityData.length ? (
        <>
          <UserIndex.Box
            className="goldinfo-sec"
            sx={{ backgroundImage: `url(${listProductPurityData?.image})` }}
          >
            <UserIndex.Box className="container">
              <UserIndex.Box className="goldinfo-banner-main">
                <UserIndex.Box className="faq-title-main customer-title-main gold-main">
                  <UserIndex.Typography className="faq-typo-custom" variant="h1">
                    {listProductPurityData?.material_type}
                  </UserIndex.Typography>
                  <UserIndex.Box className="img-side-yellow">
                    <img
                      src={UserIndex.png.banneryellow}
                      className="yellow-left-banner"
                    ></img>
                  </UserIndex.Box>
                </UserIndex.Box>
              </UserIndex.Box>
            </UserIndex.Box>
          </UserIndex.Box>
          <UserIndex.Box className="goldinfo-content-main">
            <UserIndex.Box className="container">
              <UserIndex.Box className="goldinfo-content">
                <UserIndex.Typography
                  variant="h6"
                  component="h6"
                  className="goldinfo-sub-text"
                >
                  {listProductPurityData?.heading}
                </UserIndex.Typography>

                <UserIndex.Typography
                  variantMapping="p"
                  component="p"
                  className="goldinfo-para mb-30"
                >
                  {listProductPurityData?.description}
                </UserIndex.Typography>
              </UserIndex.Box>
            </UserIndex.Box>
          </UserIndex.Box>
        </>
      ) : (
        <UserIndex.Typography className="view-cart-empty-message">No Record Found</UserIndex.Typography>
      )}

      <UserIndex.Footer />
    </div>
  )
}

export default Purity