import React, { useState, useEffect } from 'react';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import {
  Typography,
  Button,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Container,
  TablePagination,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Api } from '../../../../config/api';
import dataService from '../../../../config/dataService';
import ContainedButton from '../../../../component/customComponents/buttons/ContainedButton';
import NoDataFound from '../../../../component/customComponents/noDataFound/NoDataFound';
import { toast } from 'react-toastify';
import Loader from '../../../../component/customComponents/loader/Loader';
import moment from 'moment';
import Mui from '../../../../component/customComponents/mui/Mui';
import MuiIcon from '../../../../component/customComponents/mui/MuiIcon';

import AdminIndex from '../../AdminIndex';
//#region ALL HOOKS AND VARIABALS
const columns = [
  { id: 'date', label: 'Date' },
  { id: 'seller', label: 'Seller', align: 'center' },
  {
    id: 'productid	',
    label: 'Product Id	',
    align: 'center',
  },
  { id: 'product', label: 'Product', align: 'center' },
  { id: 'reason', label: 'Reason', align: 'center' },
  // { id: 'action', label: 'Action', align: 'center' },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


const { getAllProduct } = AdminIndex.AdminSagaActions;
export default function RejectedProducts() {
  const history = useHistory();
  const dispatch = AdminIndex.useDispatch();
  // const [loading, setLoading] = useState(false);
  let token = localStorage.getItem('auth');
  const [flag, setFlag] = useState(false);
  const [productData, setProductData] = useState([]);
  const [array, setArray] = useState([]);
  const [tempArray, setTempArray] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    getProductData();
    setInterval(() => {
      setFlag(true);
    }, 500);
  }, []);
  //#endregion

  //#region ALL EVENT HANDLERS AND  FUCTIONS
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getProductData = async () => {
    
    dispatch(getAllProduct(token));
    // setLoading(true);
    // await dataService
    //   .get(Api.Admin.getAllProduct)
    //   .then((res) => {
    //     setProductData(res.data.data);
    //   })
    //   .catch((err) => toast.error(err?.message || 'Something went wrong'))
    //   .finally(() => {
    //     setLoading(false);
    //   });
  };

  const loading = AdminIndex.useSelector((state) => {
    return state.AdminSaga.loading;
  });

  const rejectedProduct = AdminIndex.useSelector((state) => {
    return state.AdminSaga.allProduct;
  });

  useEffect(() => {
    setProductData(rejectedProduct);
  }, [rejectedProduct]);

  const filter = () => {
    var filtered = [];
    for (var arr in productData) {
      if (productData[arr]?.status == '2') {
        filtered.push(productData[arr]);
      }
    }
    setArray(filtered);
    setTempArray(filtered);
  };

  const searchFilter = (e) => {
    let data = e.target.value;
    let value = data.trim().toLowerCase();
    let filterData = tempArray.filter(
      (data) =>
        data?.name?.toLowerCase().includes(value) ||
        data?.reasonOfRejection?.toLowerCase().includes(value) ||
        data?.company_name?.toLowerCase().includes(value) ||
        data?.product_id?.toLowerCase().includes(value) ||
        // data?.materialIdArray
        //   ?.map((val) =>
        //     val.material_id?.material?.toLowerCase().includes(value)
        //   )
        //   .includes(true) ||
        data?.item_id?.item?.toLowerCase().includes(value)
    );
    setArray(filterData);
  };

  useEffect(() => {
    filter();
  }, [productData]);

  //#endregion

  //#region RETURN JSX
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Container sx={{ mt: 2, mb: 5 }} maxWidth="false">
          <Typography variant="h4" align="center" sx={{ fontWeight: 'bold' }}>
            Rejected Products List
          </Typography>
          <br />
          <Mui.Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
            <Mui.Box>
              <Mui.GrayTooltip
                title="Search by (Seller / Product Id / Product / Reason)"
                placement="bottom-start"
              >
                <Mui.SearchMaxWidth>
                  <Mui.SearchIconWrapper>
                    <MuiIcon.SearchIcon />
                  </Mui.SearchIconWrapper>
                  <Mui.StyledInputBase
                    onChange={searchFilter}
                    placeholder="Search"
                    inputProps={{ 'aria-label': 'search' }}
                    sx={{
                      width: '100%',
                      '& .MuiInputBase-input': {
                        width: '100%',
                        '&:focus, &.MuiInputBase-input': {
                          width: '100%',
                        },
                      },
                    }}
                  />
                </Mui.SearchMaxWidth>
              </Mui.GrayTooltip>
            </Mui.Box>
            {/* <Box sx={{ display: 'flex', alignSelf: 'center' }}>
              <Button
                variant="contained"
                sx={{ borderRadius: 0, textTransform: 'none', ml: 2 }}
                onClick={() => {
                  history.push('/admin/productmanagement/addproducts');
                }}
              >
                Add Product
              </Button>
            </Box> */}
          </Mui.Box>
          <TableContainer
            elevation={0}
            sx={{
              maxWidth: {
                xs: '365px',
                sm: '600px',
                md: '100%',
                lg: '100%',
              },
              '&::-webkit-scrollbar': {
                width: 10,
              },
              '&::-webkit-scrollbar': {
                height: '8px',
                width: '12px',
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: '#fff',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(224, 224, 224, 1)',
                borderRadius: '30px',
                width: '20px',
              },
              '&::-webkit-scrollbar-thumb:hover': {
                background: '#B0B0B0',
              },
            }}
            component={Paper}
          >
            <Table
              sx={{
                width: {
                  xs: 'max-content',
                  sm: 'max-content',
                  md: '100%',
                  lg: '100%',
                },
              }}
              size="small"
            >
              <TableHead>
                <TableRow sx={{ bgcolor: 'black' }}>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth, bgcolor: 'red' }}
                      className="pagination-table-header-row"
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {array.length
                  ? array
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row._id}
                            sx={{
                              '&:last-child td, &:last-child th': { border: 0 },
                            }}
                          >
                            <StyledTableCell align="left">
                              {/* {new Date(row.updatedAt).toLocaleDateString()} */}
                              {moment(row.updatedAt).format('DD/MM/YYYY')}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row.name || row.company_name}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row.product_id}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row?.item_id?.item}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row.reasonOfRejection}
                            </StyledTableCell>
                            {/* <StyledTableCell align="center">
                              <ContainedButton
                                name="Edit"
                                onClick={() =>
                                  history.push(
                                    `/admin/updateproductdetails/${row?._id}`
                                  )
                                }
                              />
                            </StyledTableCell> */}
                          </TableRow>
                        );
                      })
                  : ''}
              </TableBody>
            </Table>
            {array.length == 0 && flag ? <NoDataFound /> : ''}
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            component="div"
            count={array.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Container>
      )}
    </>
  );
}
//#endregion
