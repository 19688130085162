import * as React from 'react';
import { Grid, Typography, Container, Box, Alert, Collapse, Button } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

//#region ALL HOOKS AND VARIABALS
export default function Notification() {
    const [open, setOpen] = React.useState(true);
//#endregion

//#region ALL EVENT HANDLERS AND  FUCTIONS 
//#endregion

//#region RETURN JSX 
    return (
        <>
            <Container sx={{ my: 4 }} maxWidth="false">
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={8} lg={8.5}>
                        <Typography variant="h5" component="h2">
                            Notifications
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={2} sm={12} lg={2}>
                        <Button  sx={{textTransform:"none"}} variant="contained">Mark all as read</Button>
                    </Grid>
                    <Grid item xs={12} md={2} sm={12} lg={1.5}>
                        <Button  sx={{textTransform:"none"}} variant="contained">Clear All</Button>
                    </Grid>
                </Grid>

            </Container>
            <Container sx={{ my: 4 }} maxWidth="false">
                {Array.from(Array(6)).map((_, index) => (
                    <Box sx={{ width: '100%' }}>
                        <Collapse in={open} >
                            <Alert
                            icon={false} 
                                action={
                                    <IconButton
                                        aria-label="close"
                                       
                                        size="small"
                                        onClick={() => {
                                            setOpen(false);
                                        }}
                                    >
                                        <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                }
                                sx={{ mb: 2 ,border:1}}
                            >
                                <Typography sx={{color:"black"}} variant='h6'>
                                Login Attempted from new lp
                                </Typography>
                                <Typography sx={{color:"black"}} variant='p'>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc maximus, nulla ut commodo sagittis, sapien dui mattis dui, non pulvinar lorem felis nec erat
                                </Typography>
                            </Alert>
                        </Collapse>
                    </Box>
                ))}
            </Container>
        </>

    );
}
//#endregion