import React, { useEffect } from "react";
import UserIndex from "../../UserIndex";

const { GetAllCmsSaga } = UserIndex.UserSagaActions;
// import 'react-owl-carousel2/style.css';

export default function Aboutus() {
  const options = {
    items: 1,
    nav: false,
    rewind: true,
    autoplay: true,
  };
  const bull = (
    <UserIndex.Box
      component="span"
      sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
    >
      •
    </UserIndex.Box>
  );

  const params = UserIndex.useParams();
  const dispatch = UserIndex.useDispatch();
  const history = UserIndex.useHistory();
  const navigate = UserIndex.useHistory();
  const loding = UserIndex.useSelector((state) => {
    return state.Usersaga.loading;
  });
  const GetAllCmsData = UserIndex.useSelector((state) => {
    return state.Usersaga.GetAllCmsData;
  });

  const getAllCmsApi = () => {
    let urlencoded = new URLSearchParams();
    dispatch(GetAllCmsSaga(urlencoded, navigate));
  };

  useEffect(() => {
    getAllCmsApi();
  }, []);

  return (
    <div>
      <UserIndex.Header />
      <UserIndex.Box className="faq-main-banner about-main-banner">
        <UserIndex.Box className="container">
          <UserIndex.Box className="faq-inner-content customer-inner-content">
            <UserIndex.Typography className="lot-text">
              LISTEN OUR STORY
            </UserIndex.Typography>
            <UserIndex.Box className="faq-title-main customer-title-main">
              <UserIndex.Typography className="faq-typo-custom" variant="h1">
                About us
              </UserIndex.Typography>
              <UserIndex.Box className="img-side-yellow">
                <img
                  src={UserIndex.png.banneryellow}
                  className="yellow-left-banner"
                ></img>
              </UserIndex.Box>
            </UserIndex.Box>
          </UserIndex.Box>
        </UserIndex.Box>
      </UserIndex.Box>

      <UserIndex.Box className="about-uslorem-main">
        <UserIndex.Box className="container">
          <UserIndex.Box className="about-inner-details">
            <UserIndex.Box sx={{ width: 1 }}>
              <UserIndex.Box
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                gap={{ xs: 0, sm: 5, md: 4, lg: 4 }}
              >
                <UserIndex.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 5",
                    md: "span 5",
                    lg: "span 5",
                  }}
                >
                  <UserIndex.Box className="about-img-content">
                    <img
                      src={UserIndex.png.about}
                      className="about-img-set"
                    ></img>
                  </UserIndex.Box>
                </UserIndex.Box>
                <UserIndex.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 7",
                    md: "span 7",
                    lg: "span 7",
                  }}
                >
                  {/* <UserIndex.Box  gridColumn="span 12"> */}
                  <UserIndex.Box className="about-set-details">
                    <UserIndex.Typography
                      variant="h5"
                      className="about-sets-title"
                    >
                      About us
                    </UserIndex.Typography>
                    <UserIndex.Box className="details-sets-us-text">
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      sed diam nonumy
                    </UserIndex.Box>
                    <UserIndex.Box className="set-lorem-aboutus">
                      <UserIndex.Typography
                        varientMapping="p"
                        className="aboutus-inner-set"
                      >
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                        sed diam nonumy eirmod tempor invidunt ut labore et
                        dolore magna aliquyam erat, sed diam voluptua. At vero
                        eos et accusam et justo duo dolores et ea rebum. Stet
                        clita kasd gubergren,
                      </UserIndex.Typography>
                      <UserIndex.Typography
                        varientMapping="p"
                        className="aboutus-inner-set"
                      >
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                        sed diam nonumy eirmod tempor invidunt ut labore et
                        dolore magna aliquyam{" "}
                      </UserIndex.Typography>
                    </UserIndex.Box>
                  </UserIndex.Box>
                </UserIndex.Box>
              </UserIndex.Box>
            </UserIndex.Box>
          </UserIndex.Box>
        </UserIndex.Box>
      </UserIndex.Box>

      <UserIndex.Box className="why-choose-main">
        <UserIndex.Box className="container">
          <UserIndex.Box className="titile-inside-why">
            <UserIndex.Typography variant="h5" className="us-inner-why-chose">
              Why Choose Us
            </UserIndex.Typography>
            <UserIndex.Typography
              variantMapping="p"
              className="map-inner-why-chose"
            >
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
            </UserIndex.Typography>
          </UserIndex.Box>
          <UserIndex.Box className="box-card-about-details">
            <UserIndex.Box sx={{ width: 1 }}>
              <UserIndex.Box
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                gap={{ xs: 2, sm: 5, md: 4, lg: 4 }}
              >
                <UserIndex.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 4",
                  }}
                >
                  <UserIndex.Card className="about-card">
                    <UserIndex.CardContent className="body-about-card">
                      <UserIndex.Box class="inner-box-aboutus">
                        <img
                          className="about-membership"
                          src={UserIndex.svg.membercard}
                        ></img>
                        <UserIndex.Typography
                          variant="h5"
                          class="title-card-abouts"
                        >
                          Free Shipping
                        </UserIndex.Typography>
                        <UserIndex.Typography
                          variant="h6"
                          class="desc-card-aboutus"
                        >
                          Lorem ipsum dolor sit amet, consetetur sadipscing
                          elitr, sed diam nonumy eirmod tempor{" "}
                        </UserIndex.Typography>
                      </UserIndex.Box>
                    </UserIndex.CardContent>
                  </UserIndex.Card>
                </UserIndex.Box>
                <UserIndex.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 4",
                  }}
                >
                  <UserIndex.Card className="about-card">
                    <UserIndex.CardContent className="body-about-card">
                      <UserIndex.Box class="inner-box-aboutus">
                        <img
                          className="about-membership"
                          src={UserIndex.svg.membercard}
                        ></img>
                        <UserIndex.Typography
                          variant="h5"
                          class="title-card-abouts"
                        >
                          Free Shipping
                        </UserIndex.Typography>
                        <UserIndex.Typography
                          variant="h6"
                          class="desc-card-aboutus"
                        >
                          Lorem ipsum dolor sit amet, consetetur sadipscing
                          elitr, sed diam nonumy eirmod tempor{" "}
                        </UserIndex.Typography>
                      </UserIndex.Box>
                    </UserIndex.CardContent>
                  </UserIndex.Card>
                </UserIndex.Box>
                <UserIndex.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 4",
                  }}
                >
                  <UserIndex.Card className="about-card">
                    <UserIndex.CardContent className="body-about-card">
                      <UserIndex.Box class="inner-box-aboutus">
                        <img
                          className="about-membership"
                          src={UserIndex.svg.membercard}
                        ></img>
                        <UserIndex.Typography
                          variant="h5"
                          class="title-card-abouts"
                        >
                          Free Shipping
                        </UserIndex.Typography>
                        <UserIndex.Typography
                          variant="h6"
                          class="desc-card-aboutus"
                        >
                          Lorem ipsum dolor sit amet, consetetur sadipscing
                          elitr, sed diam nonumy eirmod tempor{" "}
                        </UserIndex.Typography>
                      </UserIndex.Box>
                    </UserIndex.CardContent>
                  </UserIndex.Card>
                </UserIndex.Box>
              </UserIndex.Box>
            </UserIndex.Box>
          </UserIndex.Box>
        </UserIndex.Box>
      </UserIndex.Box>

      <UserIndex.Box className="testimonial-about-us">
        <UserIndex.Box className="container">
          <UserIndex.Box className="testimonial-titles-main">
            <UserIndex.Typography variant="h5" className="testimonial-title">
              Testimonials
            </UserIndex.Typography>
            <UserIndex.Typography
              variantMapping="p"
              className="testimonial-details-inner"
            >
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
            </UserIndex.Typography>
          </UserIndex.Box>
          <UserIndex.Box>
            <UserIndex.OwlCarousel options={options}>
              <UserIndex.Box className="items-main-owl">
                <UserIndex.Box className="main-flex-text">
                  <UserIndex.Box className="main-img-testimonial">
                    <UserIndex.Box className="back-img-yellow"></UserIndex.Box>
                    <UserIndex.Box className="back-yellow-user">
                      <img
                        alt="usertestimonial"
                        src={UserIndex.png.usertestimonial}
                        className="user-test-img"
                      ></img>
                    </UserIndex.Box>
                  </UserIndex.Box>
                  <UserIndex.Box className="side-details-testimonial">
                    <UserIndex.Box className="textimonial-quote-top">
                      <img
                        src={UserIndex.png.textimonialquote}
                        alt="textimonialquote"
                        class="textimonialquote-top"
                      ></img>
                    </UserIndex.Box>
                    <UserIndex.Box className="testi-details-user">
                      <UserIndex.Typography
                        className="lorem-user-testi"
                        variant="p"
                        component="p"
                      >
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                        sed diam nonumy eirmod tempor invidunt ut
                        adipscing.Lorem ipsum dolor sit amet, consetetur
                        sadipscing elitr, sed diam nonumy eirmod tempor invidunt
                        ut adipscing.
                      </UserIndex.Typography>
                    </UserIndex.Box>
                    <UserIndex.Box className="textimonial-quote-top textimonial-quote-bottom">
                      <img
                        src={UserIndex.png.textimonialquote}
                        alt="textimonialquote"
                        class="textimonialquote-top bottom-quotes-testi"
                      ></img>
                    </UserIndex.Box>
                    <UserIndex.Box className="text-quote-testimonial">
                      <UserIndex.Typography
                        className="p-text-quote"
                        component="p"
                        variant="p"
                      >
                        William S. Dye
                      </UserIndex.Typography>
                    </UserIndex.Box>
                  </UserIndex.Box>
                </UserIndex.Box>
              </UserIndex.Box>
              <UserIndex.Box className="items-main-owl">
                <UserIndex.Box className="main-flex-text">
                  <UserIndex.Box className="main-img-testimonial">
                    <UserIndex.Box className="back-img-yellow"></UserIndex.Box>
                    <UserIndex.Box className="back-yellow-user">
                      <img
                        alt="usertestimonial"
                        src={UserIndex.png.usertestimonial}
                        className="user-test-img"
                      ></img>
                    </UserIndex.Box>
                  </UserIndex.Box>
                  <UserIndex.Box className="side-details-testimonial">
                    <UserIndex.Box className="textimonial-quote-top">
                      <img
                        src={UserIndex.png.textimonialquote}
                        alt="textimonialquote"
                        class="textimonialquote-top"
                      ></img>
                    </UserIndex.Box>
                    <UserIndex.Box className="testi-details-user">
                      <UserIndex.Typography
                        className="lorem-user-testi"
                        variant="p"
                        component="p"
                      >
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                        sed diam nonumy eirmod tempor invidunt ut
                        adipscing.Lorem ipsum dolor sit amet, consetetur
                        sadipscing elitr, sed diam nonumy eirmod tempor invidunt
                        ut adipscing.
                      </UserIndex.Typography>
                    </UserIndex.Box>
                    <UserIndex.Box className="textimonial-quote-top textimonial-quote-bottom">
                      <img
                        src={UserIndex.png.textimonialquote}
                        alt="textimonialquote"
                        class="textimonialquote-top bottom-quotes-testi"
                      ></img>
                    </UserIndex.Box>
                    <UserIndex.Box className="text-quote-testimonial">
                      <UserIndex.Typography
                        className="p-text-quote"
                        component="p"
                        variant="p"
                      >
                        William S. Dye
                      </UserIndex.Typography>
                    </UserIndex.Box>
                  </UserIndex.Box>
                </UserIndex.Box>
              </UserIndex.Box>
              <UserIndex.Box className="items-main-owl">
                <UserIndex.Box className="main-flex-text">
                  <UserIndex.Box className="main-img-testimonial">
                    <UserIndex.Box className="back-img-yellow"></UserIndex.Box>
                    <UserIndex.Box className="back-yellow-user">
                      <img
                        alt="usertestimonial"
                        src={UserIndex.png.usertestimonial}
                        className="user-test-img"
                      ></img>
                    </UserIndex.Box>
                  </UserIndex.Box>
                  <UserIndex.Box className="side-details-testimonial">
                    <UserIndex.Box className="textimonial-quote-top">
                      <img
                        src={UserIndex.png.textimonialquote}
                        alt="textimonialquote"
                        class="textimonialquote-top"
                      ></img>
                    </UserIndex.Box>
                    <UserIndex.Box className="testi-details-user">
                      <UserIndex.Typography
                        className="lorem-user-testi"
                        variant="p"
                        component="p"
                      >
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                        sed diam nonumy eirmod tempor invidunt ut
                        adipscing.Lorem ipsum dolor sit amet, consetetur
                        sadipscing elitr, sed diam nonumy eirmod tempor invidunt
                        ut adipscing.
                      </UserIndex.Typography>
                    </UserIndex.Box>
                    <UserIndex.Box className="textimonial-quote-top textimonial-quote-bottom">
                      <img
                        src={UserIndex.png.textimonialquote}
                        alt="textimonialquote"
                        class="textimonialquote-top bottom-quotes-testi"
                      ></img>
                    </UserIndex.Box>
                    <UserIndex.Box className="text-quote-testimonial">
                      <UserIndex.Typography
                        className="p-text-quote"
                        component="p"
                        variant="p"
                      >
                        William S. Dye
                      </UserIndex.Typography>
                    </UserIndex.Box>
                  </UserIndex.Box>
                </UserIndex.Box>
              </UserIndex.Box>
              <UserIndex.Box className="items-main-owl">
                <UserIndex.Box className="main-flex-text">
                  <UserIndex.Box className="main-img-testimonial">
                    <UserIndex.Box className="back-img-yellow"></UserIndex.Box>
                    <UserIndex.Box className="back-yellow-user">
                      <img
                        alt="usertestimonial"
                        src={UserIndex.png.usertestimonial}
                        className="user-test-img"
                      ></img>
                    </UserIndex.Box>
                  </UserIndex.Box>
                  <UserIndex.Box className="side-details-testimonial">
                    <UserIndex.Box className="textimonial-quote-top">
                      <img
                        src={UserIndex.png.textimonialquote}
                        alt="textimonialquote"
                        class="textimonialquote-top"
                      ></img>
                    </UserIndex.Box>
                    <UserIndex.Box className="testi-details-user">
                      <UserIndex.Typography
                        className="lorem-user-testi"
                        variant="p"
                        component="p"
                      >
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                        sed diam nonumy eirmod tempor invidunt ut
                        adipscing.Lorem ipsum dolor sit amet, consetetur
                        sadipscing elitr, sed diam nonumy eirmod tempor invidunt
                        ut adipscing.
                      </UserIndex.Typography>
                    </UserIndex.Box>
                    <UserIndex.Box className="textimonial-quote-top textimonial-quote-bottom">
                      <img
                        src={UserIndex.png.textimonialquote}
                        alt="textimonialquote"
                        class="textimonialquote-top bottom-quotes-testi"
                      ></img>
                    </UserIndex.Box>
                    <UserIndex.Box className="text-quote-testimonial">
                      <UserIndex.Typography
                        className="p-text-quote"
                        component="p"
                        variant="p"
                      >
                        William S. Dye
                      </UserIndex.Typography>
                    </UserIndex.Box>
                  </UserIndex.Box>
                </UserIndex.Box>
              </UserIndex.Box>
              <UserIndex.Box className="items-main-owl">
                <UserIndex.Box className="main-flex-text">
                  <UserIndex.Box className="main-img-testimonial">
                    <UserIndex.Box className="back-img-yellow"></UserIndex.Box>
                    <UserIndex.Box className="back-yellow-user">
                      <img
                        alt="usertestimonial"
                        src={UserIndex.png.usertestimonial}
                        className="user-test-img"
                      ></img>
                    </UserIndex.Box>
                  </UserIndex.Box>
                  <UserIndex.Box className="side-details-testimonial">
                    <UserIndex.Box className="textimonial-quote-top">
                      <img
                        src={UserIndex.png.textimonialquote}
                        alt="textimonialquote"
                        class="textimonialquote-top"
                      ></img>
                    </UserIndex.Box>
                    <UserIndex.Box className="testi-details-user">
                      <UserIndex.Typography
                        className="lorem-user-testi"
                        variant="p"
                        component="p"
                      >
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                        sed diam nonumy eirmod tempor invidunt ut
                        adipscing.Lorem ipsum dolor sit amet, consetetur
                        sadipscing elitr, sed diam nonumy eirmod tempor invidunt
                        ut adipscing.
                      </UserIndex.Typography>
                    </UserIndex.Box>
                    <UserIndex.Box className="textimonial-quote-top textimonial-quote-bottom">
                      <img
                        src={UserIndex.png.textimonialquote}
                        alt="textimonialquote"
                        class="textimonialquote-top bottom-quotes-testi"
                      ></img>
                    </UserIndex.Box>
                    <UserIndex.Box className="text-quote-testimonial">
                      <UserIndex.Typography
                        className="p-text-quote"
                        component="p"
                        variant="p"
                      >
                        William S. Dye
                      </UserIndex.Typography>
                    </UserIndex.Box>
                  </UserIndex.Box>
                </UserIndex.Box>
              </UserIndex.Box>
            </UserIndex.OwlCarousel>
          </UserIndex.Box>
        </UserIndex.Box>
      </UserIndex.Box>

      <UserIndex.Box className="ourteam-main-content">
        <UserIndex.Box className="our-team-about-us">
          <UserIndex.Box className="container">
            <UserIndex.Box className="ourteam-titles-main">
              <UserIndex.Typography variant="h5" className="team-title">
                Why Choose Us
              </UserIndex.Typography>
              <UserIndex.Typography
                variantMapping="p"
                className="team-details-inner"
              >
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam
              </UserIndex.Typography>
            </UserIndex.Box>
          </UserIndex.Box>
        </UserIndex.Box>
        <UserIndex.Box className="user-aboutus-details">
          <UserIndex.Box className="container">
            <UserIndex.Box sx={{ width: 1 }}>
              <UserIndex.Box
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                gap={{ xs: 2, sm: 5, md: 4, lg: 4 }}
              >
                <UserIndex.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 4",
                  }}
                >
                  <UserIndex.Box>
                    <UserIndex.Box className="team-img-main">
                      <img
                        src={UserIndex.png.teamimg1}
                        className="img-team"
                      ></img>
                    </UserIndex.Box>
                    <UserIndex.Card className="user-about-card">
                      <UserIndex.CardContent class="body-usercontent">
                        <UserIndex.Typography
                          gutterBottom
                          variant="h5"
                          component="div"
                          className="user-about-titles"
                        >
                          Mary B. Pearce
                        </UserIndex.Typography>
                        <UserIndex.Typography
                          gutterBottom
                          variant="h6"
                          component="div"
                          className="photo-about-user"
                        >
                          Photography
                        </UserIndex.Typography>
                        <UserIndex.Typography
                          variant="body2"
                          color="text.secondary"
                          className="user-about-inner-des"
                        >
                          Lorem ipsum dolor sit amet, consetetur sadipscing
                          elitr, sed diam nonumy eirmod tempor
                        </UserIndex.Typography>
                      </UserIndex.CardContent>
                      <UserIndex.Box className="icons-about-user">
                        <a href="https://www.facebook.com" target="_blank">
                          <UserIndex.Box className="icon-main-social">
                            <svg
                              className="social-icon"
                              xmlns="http://www.w3.org/2000/svg"
                              width="12.323"
                              height="23.009"
                              viewBox="0 0 12.323 23.009"
                            >
                              <path
                                id="Icon_awesome-facebook-f"
                                data-name="Icon awesome-facebook-f"
                                d="M13.125,12.943l.639-4.164h-4v-2.7a2.082,2.082,0,0,1,2.348-2.25h1.816V.281A22.152,22.152,0,0,0,10.708,0C7.418,0,5.267,1.994,5.267,5.6V8.779H1.609v4.164H5.267V23.009h4.5V12.943Z"
                                transform="translate(-1.609)"
                                fill="#c29958"
                              />
                            </svg>
                          </UserIndex.Box>
                        </a>
                        <a href="https://in.linkedin.com" target="_blank">
                          <UserIndex.Box className="icon-main-social">
                            <svg
                              className="social-icon"
                              xmlns="http://www.w3.org/2000/svg"
                              width="20.133"
                              height="20.133"
                              viewBox="0 0 20.133 20.133"
                            >
                              <path
                                id="Icon_awesome-linkedin-in"
                                data-name="Icon awesome-linkedin-in"
                                d="M4.507,20.133H.333V6.692H4.507ZM2.417,4.858a2.429,2.429,0,1,1,2.417-2.44A2.438,2.438,0,0,1,2.417,4.858ZM20.129,20.133H15.964V13.59c0-1.559-.031-3.559-2.17-3.559-2.17,0-2.5,1.694-2.5,3.447v6.656H7.121V6.692h4V8.525h.058a4.386,4.386,0,0,1,3.949-2.171c4.224,0,5,2.782,5,6.395v7.384Z"
                                transform="translate(0 -0.001)"
                                fill="#c29958"
                              />
                            </svg>
                          </UserIndex.Box>
                        </a>
                        <a href="https://twitter.com" target="_blank">
                          <UserIndex.Box className="icon-main-social">
                            <svg
                              className="social-icon"
                              xmlns="http://www.w3.org/2000/svg"
                              width="23.009"
                              height="18.688"
                              viewBox="0 0 23.009 18.688"
                            >
                              <path
                                id="Icon_awesome-twitter"
                                data-name="Icon awesome-twitter"
                                d="M20.644,8.038c.015.2.015.409.015.613A13.325,13.325,0,0,1,7.242,22.068,13.326,13.326,0,0,1,0,19.952a9.755,9.755,0,0,0,1.139.058A9.444,9.444,0,0,0,6.993,18a4.724,4.724,0,0,1-4.409-3.27,5.947,5.947,0,0,0,.891.073,4.988,4.988,0,0,0,1.241-.161A4.716,4.716,0,0,1,.934,10.009V9.951a4.749,4.749,0,0,0,2.132.6,4.723,4.723,0,0,1-1.46-6.307,13.4,13.4,0,0,0,9.723,4.935,5.323,5.323,0,0,1-.117-1.08A4.72,4.72,0,0,1,19.374,4.87a9.284,9.284,0,0,0,2.993-1.139,4.7,4.7,0,0,1-2.073,2.6,9.454,9.454,0,0,0,2.716-.73,10.137,10.137,0,0,1-2.365,2.438Z"
                                transform="translate(0 -3.381)"
                                fill="#c29958"
                              />
                            </svg>
                          </UserIndex.Box>
                        </a>
                      </UserIndex.Box>
                    </UserIndex.Card>
                  </UserIndex.Box>
                </UserIndex.Box>
                <UserIndex.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 4",
                  }}
                >
                  <UserIndex.Box>
                    <UserIndex.Box className="team-img-main">
                      <img
                        src={UserIndex.png.teamimg2}
                        className="img-team"
                      ></img>
                    </UserIndex.Box>
                    {/* <UserIndex.Card className="user-about-card">
                      <UserIndex.CardContent class="body-usercontent">
                        <UserIndex.Typography
                          gutterBottom
                          variant="h5"
                          component="div"
                          className="user-about-titles"
                        >
                          Mary B. Pearce
                        </UserIndex.Typography>
                        <UserIndex.Typography
                          gutterBottom
                          variant="h6"
                          component="div"
                          className="photo-about-user"
                        >
                          Photography
                        </UserIndex.Typography>
                        <UserIndex.Typography
                          variant="body2"
                          color="text.secondary"
                          className="user-about-inner-des"
                        >
                          Lorem ipsum dolor sit amet, consetetur sadipscing
                          elitr, sed diam nonumy eirmod tempor
                        </UserIndex.Typography>
                      </UserIndex.CardContent>
                      <UserIndex.Box className="icons-about-user">
                        <UserIndex.Box className="icon-main-social">
                          <svg
                            className="social-icon"
                            xmlns="http://www.w3.org/2000/svg"
                            width="12.323"
                            height="23.009"
                            viewBox="0 0 12.323 23.009"
                          >
                            <path
                              id="Icon_awesome-facebook-f"
                              data-name="Icon awesome-facebook-f"
                              d="M13.125,12.943l.639-4.164h-4v-2.7a2.082,2.082,0,0,1,2.348-2.25h1.816V.281A22.152,22.152,0,0,0,10.708,0C7.418,0,5.267,1.994,5.267,5.6V8.779H1.609v4.164H5.267V23.009h4.5V12.943Z"
                              transform="translate(-1.609)"
                              fill="#c29958"
                            />
                          </svg>
                        </UserIndex.Box>
                        <UserIndex.Box className="icon-main-social">
                          <svg
                            className="social-icon"
                            xmlns="http://www.w3.org/2000/svg"
                            width="20.133"
                            height="20.133"
                            viewBox="0 0 20.133 20.133"
                          >
                            <path
                              id="Icon_awesome-linkedin-in"
                              data-name="Icon awesome-linkedin-in"
                              d="M4.507,20.133H.333V6.692H4.507ZM2.417,4.858a2.429,2.429,0,1,1,2.417-2.44A2.438,2.438,0,0,1,2.417,4.858ZM20.129,20.133H15.964V13.59c0-1.559-.031-3.559-2.17-3.559-2.17,0-2.5,1.694-2.5,3.447v6.656H7.121V6.692h4V8.525h.058a4.386,4.386,0,0,1,3.949-2.171c4.224,0,5,2.782,5,6.395v7.384Z"
                              transform="translate(0 -0.001)"
                              fill="#c29958"
                            />
                          </svg>
                        </UserIndex.Box>
                        <UserIndex.Box className="icon-main-social">
                          <svg
                            className="social-icon"
                            xmlns="http://www.w3.org/2000/svg"
                            width="23.009"
                            height="18.688"
                            viewBox="0 0 23.009 18.688"
                          >
                            <path
                              id="Icon_awesome-twitter"
                              data-name="Icon awesome-twitter"
                              d="M20.644,8.038c.015.2.015.409.015.613A13.325,13.325,0,0,1,7.242,22.068,13.326,13.326,0,0,1,0,19.952a9.755,9.755,0,0,0,1.139.058A9.444,9.444,0,0,0,6.993,18a4.724,4.724,0,0,1-4.409-3.27,5.947,5.947,0,0,0,.891.073,4.988,4.988,0,0,0,1.241-.161A4.716,4.716,0,0,1,.934,10.009V9.951a4.749,4.749,0,0,0,2.132.6,4.723,4.723,0,0,1-1.46-6.307,13.4,13.4,0,0,0,9.723,4.935,5.323,5.323,0,0,1-.117-1.08A4.72,4.72,0,0,1,19.374,4.87a9.284,9.284,0,0,0,2.993-1.139,4.7,4.7,0,0,1-2.073,2.6,9.454,9.454,0,0,0,2.716-.73,10.137,10.137,0,0,1-2.365,2.438Z"
                              transform="translate(0 -3.381)"
                              fill="#c29958"
                            />
                          </svg>
                        </UserIndex.Box>
                      </UserIndex.Box>
                    </UserIndex.Card> */}
                    <UserIndex.Card className="user-about-card">
                      <UserIndex.CardContent class="body-usercontent">
                        <UserIndex.Typography
                          gutterBottom
                          variant="h5"
                          component="div"
                          className="user-about-titles"
                        >
                          Mary B. Pearce
                        </UserIndex.Typography>
                        <UserIndex.Typography
                          gutterBottom
                          variant="h6"
                          component="div"
                          className="photo-about-user"
                        >
                          Photography
                        </UserIndex.Typography>
                        <UserIndex.Typography
                          variant="body2"
                          color="text.secondary"
                          className="user-about-inner-des"
                        >
                          Lorem ipsum dolor sit amet, consetetur sadipscing
                          elitr, sed diam nonumy eirmod tempor
                        </UserIndex.Typography>
                      </UserIndex.CardContent>
                      <UserIndex.Box className="icons-about-user">
                        <a href="https://www.facebook.com" target="_blank">
                          <UserIndex.Box className="icon-main-social">
                            <svg
                              className="social-icon"
                              xmlns="http://www.w3.org/2000/svg"
                              width="12.323"
                              height="23.009"
                              viewBox="0 0 12.323 23.009"
                            >
                              <path
                                id="Icon_awesome-facebook-f"
                                data-name="Icon awesome-facebook-f"
                                d="M13.125,12.943l.639-4.164h-4v-2.7a2.082,2.082,0,0,1,2.348-2.25h1.816V.281A22.152,22.152,0,0,0,10.708,0C7.418,0,5.267,1.994,5.267,5.6V8.779H1.609v4.164H5.267V23.009h4.5V12.943Z"
                                transform="translate(-1.609)"
                                fill="#c29958"
                              />
                            </svg>
                          </UserIndex.Box>
                        </a>
                        <a href="https://in.linkedin.com" target="_blank">
                          <UserIndex.Box className="icon-main-social">
                            <svg
                              className="social-icon"
                              xmlns="http://www.w3.org/2000/svg"
                              width="20.133"
                              height="20.133"
                              viewBox="0 0 20.133 20.133"
                            >
                              <path
                                id="Icon_awesome-linkedin-in"
                                data-name="Icon awesome-linkedin-in"
                                d="M4.507,20.133H.333V6.692H4.507ZM2.417,4.858a2.429,2.429,0,1,1,2.417-2.44A2.438,2.438,0,0,1,2.417,4.858ZM20.129,20.133H15.964V13.59c0-1.559-.031-3.559-2.17-3.559-2.17,0-2.5,1.694-2.5,3.447v6.656H7.121V6.692h4V8.525h.058a4.386,4.386,0,0,1,3.949-2.171c4.224,0,5,2.782,5,6.395v7.384Z"
                                transform="translate(0 -0.001)"
                                fill="#c29958"
                              />
                            </svg>
                          </UserIndex.Box>
                        </a>
                        <a href="https://twitter.com" target="_blank">
                          <UserIndex.Box className="icon-main-social">
                            <svg
                              className="social-icon"
                              xmlns="http://www.w3.org/2000/svg"
                              width="23.009"
                              height="18.688"
                              viewBox="0 0 23.009 18.688"
                            >
                              <path
                                id="Icon_awesome-twitter"
                                data-name="Icon awesome-twitter"
                                d="M20.644,8.038c.015.2.015.409.015.613A13.325,13.325,0,0,1,7.242,22.068,13.326,13.326,0,0,1,0,19.952a9.755,9.755,0,0,0,1.139.058A9.444,9.444,0,0,0,6.993,18a4.724,4.724,0,0,1-4.409-3.27,5.947,5.947,0,0,0,.891.073,4.988,4.988,0,0,0,1.241-.161A4.716,4.716,0,0,1,.934,10.009V9.951a4.749,4.749,0,0,0,2.132.6,4.723,4.723,0,0,1-1.46-6.307,13.4,13.4,0,0,0,9.723,4.935,5.323,5.323,0,0,1-.117-1.08A4.72,4.72,0,0,1,19.374,4.87a9.284,9.284,0,0,0,2.993-1.139,4.7,4.7,0,0,1-2.073,2.6,9.454,9.454,0,0,0,2.716-.73,10.137,10.137,0,0,1-2.365,2.438Z"
                                transform="translate(0 -3.381)"
                                fill="#c29958"
                              />
                            </svg>
                          </UserIndex.Box>
                        </a>
                      </UserIndex.Box>
                    </UserIndex.Card>
                  </UserIndex.Box>
                </UserIndex.Box>
                <UserIndex.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 4",
                  }}
                >
                  <UserIndex.Box>
                    <UserIndex.Box className="team-img-main">
                      <img
                        src={UserIndex.png.teamimg3}
                        className="img-team"
                      ></img>
                    </UserIndex.Box>
                    {/* <UserIndex.Card className="user-about-card">
                      <UserIndex.CardContent class="body-usercontent">
                        <UserIndex.Typography
                          gutterBottom
                          variant="h5"
                          component="div"
                          className="user-about-titles"
                        >
                          Mary B. Pearce
                        </UserIndex.Typography>
                        <UserIndex.Typography
                          gutterBottom
                          variant="h6"
                          component="div"
                          className="photo-about-user"
                        >
                          Photography
                        </UserIndex.Typography>
                        <UserIndex.Typography
                          variant="body2"
                          color="text.secondary"
                          className="user-about-inner-des"
                        >
                          Lorem ipsum dolor sit amet, consetetur sadipscing
                          elitr, sed diam nonumy eirmod tempor
                        </UserIndex.Typography>
                      </UserIndex.CardContent>
                      <UserIndex.Box className="icons-about-user">
                        <UserIndex.Box className="icon-main-social">
                          <svg
                            className="social-icon"
                            xmlns="http://www.w3.org/2000/svg"
                            width="12.323"
                            height="23.009"
                            viewBox="0 0 12.323 23.009"
                          >
                            <path
                              id="Icon_awesome-facebook-f"
                              data-name="Icon awesome-facebook-f"
                              d="M13.125,12.943l.639-4.164h-4v-2.7a2.082,2.082,0,0,1,2.348-2.25h1.816V.281A22.152,22.152,0,0,0,10.708,0C7.418,0,5.267,1.994,5.267,5.6V8.779H1.609v4.164H5.267V23.009h4.5V12.943Z"
                              transform="translate(-1.609)"
                              fill="#c29958"
                            />
                          </svg>
                        </UserIndex.Box>
                        <UserIndex.Box className="icon-main-social">
                          <svg
                            className="social-icon"
                            xmlns="http://www.w3.org/2000/svg"
                            width="20.133"
                            height="20.133"
                            viewBox="0 0 20.133 20.133"
                          >
                            <path
                              id="Icon_awesome-linkedin-in"
                              data-name="Icon awesome-linkedin-in"
                              d="M4.507,20.133H.333V6.692H4.507ZM2.417,4.858a2.429,2.429,0,1,1,2.417-2.44A2.438,2.438,0,0,1,2.417,4.858ZM20.129,20.133H15.964V13.59c0-1.559-.031-3.559-2.17-3.559-2.17,0-2.5,1.694-2.5,3.447v6.656H7.121V6.692h4V8.525h.058a4.386,4.386,0,0,1,3.949-2.171c4.224,0,5,2.782,5,6.395v7.384Z"
                              transform="translate(0 -0.001)"
                              fill="#c29958"
                            />
                          </svg>
                        </UserIndex.Box>
                        <UserIndex.Box className="icon-main-social">
                          <svg
                            className="social-icon"
                            xmlns="http://www.w3.org/2000/svg"
                            width="23.009"
                            height="18.688"
                            viewBox="0 0 23.009 18.688"
                          >
                            <path
                              id="Icon_awesome-twitter"
                              data-name="Icon awesome-twitter"
                              d="M20.644,8.038c.015.2.015.409.015.613A13.325,13.325,0,0,1,7.242,22.068,13.326,13.326,0,0,1,0,19.952a9.755,9.755,0,0,0,1.139.058A9.444,9.444,0,0,0,6.993,18a4.724,4.724,0,0,1-4.409-3.27,5.947,5.947,0,0,0,.891.073,4.988,4.988,0,0,0,1.241-.161A4.716,4.716,0,0,1,.934,10.009V9.951a4.749,4.749,0,0,0,2.132.6,4.723,4.723,0,0,1-1.46-6.307,13.4,13.4,0,0,0,9.723,4.935,5.323,5.323,0,0,1-.117-1.08A4.72,4.72,0,0,1,19.374,4.87a9.284,9.284,0,0,0,2.993-1.139,4.7,4.7,0,0,1-2.073,2.6,9.454,9.454,0,0,0,2.716-.73,10.137,10.137,0,0,1-2.365,2.438Z"
                              transform="translate(0 -3.381)"
                              fill="#c29958"
                            />
                          </svg>
                        </UserIndex.Box>
                      </UserIndex.Box>
                    </UserIndex.Card> */}
                    <UserIndex.Card className="user-about-card">
                      <UserIndex.CardContent class="body-usercontent">
                        <UserIndex.Typography
                          gutterBottom
                          variant="h5"
                          component="div"
                          className="user-about-titles"
                        >
                          Mary B. Pearce
                        </UserIndex.Typography>
                        <UserIndex.Typography
                          gutterBottom
                          variant="h6"
                          component="div"
                          className="photo-about-user"
                        >
                          Photography
                        </UserIndex.Typography>
                        <UserIndex.Typography
                          variant="body2"
                          color="text.secondary"
                          className="user-about-inner-des"
                        >
                          Lorem ipsum dolor sit amet, consetetur sadipscing
                          elitr, sed diam nonumy eirmod tempor
                        </UserIndex.Typography>
                      </UserIndex.CardContent>
                      <UserIndex.Box className="icons-about-user">
                        <a href="https://www.facebook.com" target="_blank">
                          <UserIndex.Box className="icon-main-social">
                            <svg
                              className="social-icon"
                              xmlns="http://www.w3.org/2000/svg"
                              width="12.323"
                              height="23.009"
                              viewBox="0 0 12.323 23.009"
                            >
                              <path
                                id="Icon_awesome-facebook-f"
                                data-name="Icon awesome-facebook-f"
                                d="M13.125,12.943l.639-4.164h-4v-2.7a2.082,2.082,0,0,1,2.348-2.25h1.816V.281A22.152,22.152,0,0,0,10.708,0C7.418,0,5.267,1.994,5.267,5.6V8.779H1.609v4.164H5.267V23.009h4.5V12.943Z"
                                transform="translate(-1.609)"
                                fill="#c29958"
                              />
                            </svg>
                          </UserIndex.Box>
                        </a>
                        <a href="https://in.linkedin.com" target="_blank">
                          <UserIndex.Box className="icon-main-social">
                            <svg
                              className="social-icon"
                              xmlns="http://www.w3.org/2000/svg"
                              width="20.133"
                              height="20.133"
                              viewBox="0 0 20.133 20.133"
                            >
                              <path
                                id="Icon_awesome-linkedin-in"
                                data-name="Icon awesome-linkedin-in"
                                d="M4.507,20.133H.333V6.692H4.507ZM2.417,4.858a2.429,2.429,0,1,1,2.417-2.44A2.438,2.438,0,0,1,2.417,4.858ZM20.129,20.133H15.964V13.59c0-1.559-.031-3.559-2.17-3.559-2.17,0-2.5,1.694-2.5,3.447v6.656H7.121V6.692h4V8.525h.058a4.386,4.386,0,0,1,3.949-2.171c4.224,0,5,2.782,5,6.395v7.384Z"
                                transform="translate(0 -0.001)"
                                fill="#c29958"
                              />
                            </svg>
                          </UserIndex.Box>
                        </a>
                        <a href="https://twitter.com" target="_blank">
                          <UserIndex.Box className="icon-main-social">
                            <svg
                              className="social-icon"
                              xmlns="http://www.w3.org/2000/svg"
                              width="23.009"
                              height="18.688"
                              viewBox="0 0 23.009 18.688"
                            >
                              <path
                                id="Icon_awesome-twitter"
                                data-name="Icon awesome-twitter"
                                d="M20.644,8.038c.015.2.015.409.015.613A13.325,13.325,0,0,1,7.242,22.068,13.326,13.326,0,0,1,0,19.952a9.755,9.755,0,0,0,1.139.058A9.444,9.444,0,0,0,6.993,18a4.724,4.724,0,0,1-4.409-3.27,5.947,5.947,0,0,0,.891.073,4.988,4.988,0,0,0,1.241-.161A4.716,4.716,0,0,1,.934,10.009V9.951a4.749,4.749,0,0,0,2.132.6,4.723,4.723,0,0,1-1.46-6.307,13.4,13.4,0,0,0,9.723,4.935,5.323,5.323,0,0,1-.117-1.08A4.72,4.72,0,0,1,19.374,4.87a9.284,9.284,0,0,0,2.993-1.139,4.7,4.7,0,0,1-2.073,2.6,9.454,9.454,0,0,0,2.716-.73,10.137,10.137,0,0,1-2.365,2.438Z"
                                transform="translate(0 -3.381)"
                                fill="#c29958"
                              />
                            </svg>
                          </UserIndex.Box>
                        </a>
                      </UserIndex.Box>
                    </UserIndex.Card>
                  </UserIndex.Box>
                </UserIndex.Box>
              </UserIndex.Box>
            </UserIndex.Box>
          </UserIndex.Box>
        </UserIndex.Box>
      </UserIndex.Box>

      <UserIndex.Footer />
    </div>
  );
}
