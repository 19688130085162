//#region ALL IMPORTS
import React, { useState, useEffect } from 'react';
import {
  Grid,
  Container,
  Typography,
  Button,
  Box,
  FormHelperText,
  TextField,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { Api } from '../../../../../config/api';
import dataService from '../../../../../config/dataService';
import { ToastContainer, toast } from 'react-toastify';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ContainedButton from '../../../../../component/customComponents/buttons/ContainedButton';
import { noWhiteSpace } from '../../../../../component/customComponents/validation/Validation';
import JoditEditor from 'jodit-react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import {
  ErrorMsg,
  TextAreaErrorMsg,
} from '../../../../../component/customComponents/errorMsg/ErrorMsg';
import {
  minMembershipFFGHeadingLength,
  membershipFFGHeadingLength,
  NO_WHITE_SPACE_BEGINNING_END,
} from '../../../../../component/customComponents/validation/Validation';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

//#endregion

import AdminIndex from '../../../AdminIndex';

//#region ALL HOOKS AND VARIABALS

const { getBenefitList, updateFormFillGuideline } = AdminIndex.AdminSagaActions;
const FormFillingGuide = () => {
  let token = localStorage.getItem('auth');
  
  const dispatch = AdminIndex.useDispatch();
  const history = useHistory();
  const navigate = useHistory();
  const [formFillingGuideId, setFormFillingGuideId] = useState(null);
  const [getData, setGetData] = useState('');

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm();

  const schema = yup.object().shape({
    files: yup.mixed().test('require', 'Please select a file', (value) => {
      return value && value.length;
    }),
  });
  //#endregion

  //#region ALL EVENT HANDLERS AND  FUCTIONS
  const getFormFillingGuideData = async (e) => {
    let urlencoded = new URLSearchParams();
    try {
      dispatch(getBenefitList(token, urlencoded));
      // const Data = await dataService.post(Api.Admin.listBenefits, urlencoded);

      // setGetData(Data?.data?.listFormFillGuideline?.[0]);
      // setFormFillingGuideId(Data?.data?.listFormFillGuideline?.[0]?._id);
    } catch (err) {
      toast.error(err?.message || 'Something went wrong');
    }
  };

  const listFormFillGuideline = AdminIndex.useSelector((state) => {
    return state.AdminSaga.listFormFillGuideline;
  });

  useEffect(() => {
    setGetData(listFormFillGuideline?.[0]);
    setFormFillingGuideId(listFormFillGuideline?.[0]?._id);
  }, [listFormFillGuideline]);

  useEffect(() => {
    getFormFillingGuideData();
  }, []);

  useEffect(() => {
    setValue('title', getData?.title);
    setValue('list', getData?.list);
  }, [getData]);

  const handleSave = async (data) => {
    try {
      if (formFillingGuideId) {
        let urlencoded = new URLSearchParams();
        urlencoded.append('id', formFillingGuideId);
        urlencoded.append('title', data?.title?.trim());
        urlencoded.append('list', data?.list?.trim());

        dispatch(updateFormFillGuideline(token, urlencoded, navigate));
        // const titleData = await dataService.post(
        //   Api.Admin.addBenefits,
        //   urlencoded,
        //   {
        //     headers: {
        //       auth: token,
        //     },
        //   }
        // );

        // if (titleData.data.status === 1) {
        //   toast.success('Form filling Guide Updated successfully');
        //   setTimeout(() => {
        //     history.push('/admin/membership');
        //   }, '1000');
        // }
      } else {
        let urlencoded = new URLSearchParams();
        urlencoded.append('title', data?.title?.trim());
        urlencoded.append('list', data?.list?.trim());
        dispatch(updateFormFillGuideline(token, urlencoded, navigate));
        // const titleData = await dataService.post(
        //   Api.Admin.addBenefits,
        //   urlencoded,
        //   {
        //     headers: {
        //       auth: token,
        //     },
        //   }
        // );

        // if (titleData.data.status === 1) {
        //   toast.success('Form filling Guide added successfully');
        //   history.push('/admin/membership');
        // }
      }
    } catch (err) {
      toast.error(err?.message || 'Something went wrong');
    }
  };
  //#endregion

  //#region RETURN JSX
  return (
    <>
      <form onSubmit={handleSubmit(handleSave)}>
        <Container maxWidth="false" sx={{ mb: 5 }}>
          <Typography sx={{ my: 2 }} align="center" variant="h4">
            Form Filling Guide
          </Typography>

          <Grid container spacing={5}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography align="left" sx={{ mt: 2 }}>
                Heading
              </Typography>
              <TextField
                autoComplete="off"
                sx={{
                  mt: 2,
                  '& .MuiFormHelperText-root': { ml: 0, mt: '5px' },
                }}
                size="small"
                fullWidth
                className="form-control border-none"
                name="title"
                type="text"
                {...register('title', {
                  required: <ErrorMsg msg="Please enter Heading" />,
                  minLength: {
                    value: minMembershipFFGHeadingLength,
                    message: (
                      <ErrorMsg
                        msg={`Minimum ${minMembershipFFGHeadingLength} Characters Allowed`}
                      />
                    ),
                  },
                  maxLength: {
                    value: membershipFFGHeadingLength,
                    message: (
                      <ErrorMsg
                        msg={`Maximum ${membershipFFGHeadingLength} Characters Allowed`}
                      />
                    ),
                  },
                  pattern: {
                    value: NO_WHITE_SPACE_BEGINNING_END,
                    message: (
                      <ErrorMsg msg="Whitespace is not allowed at beginning or end" />
                    ),
                  },
                })}
                helperText={<ErrorMessage errors={errors} name="title" />}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography align="left" sx={{ my: 2 }}>
                Description
              </Typography>
              <Box className="text-area-control-main">
                {/* <CKEditor
                  editor={ClassicEditor}
                  data={list ? list : ''}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setList(data);
                  }}
                /> */}
                <Controller
                  name="list"
                  control={control}
                  render={({ field }) => <JoditEditor {...field} />}
                  {...register('list', {
                    required: <ErrorMsg msg="Please enter Description" />,
                  })}
                />
                <ErrorMessage errors={errors} name="list" />
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ display: 'flex', justifyContent: 'center', my: 5 }}>
            <ContainedButton
              name={formFillingGuideId ? 'Update' : 'Save'}
              type="submit"
            />
          </Box>
        </Container>
      </form>
    </>
  );
};

export default FormFillingGuide;
//#endregion
