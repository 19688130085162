import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {
  Grid,
  Container,
  Typography,
  Button,
  Box,
  FormHelperText,
} from '@mui/material';
import HighlightOffTwoToneIcon from '@mui/icons-material/CancelTwoTone';
import { Api } from '../../../../config/api';
import dataService from '../../../../config/dataService';
import { ToastContainer, toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';

//#region ALL HOOKS AND VARIABALS
const Img = styled('img')({
  display: 'block',
  maxWidth: '100%',
  height: 'auto',
  maxHeight: '50vh',
  borderRadius: '6px',
});

const AddSubCategory = () => {
  const [fileError, setFileError] = useState('');
  const [selectCategoryError, setSelectCategoryError] = useState('');

  const [user, setUser] = useState({
    category: '',
    sub_category_name: '',
    category_description: '',
    thumbnail: '',
  });
//#endregion

//#region ALL EVENT HANDLERS AND  FUCTIONS 
  const CustomToast = () => {
    toast('Please enter valid data', {
      toastId: 'success1',
    });
  };

  const handleChangeUser = (e) => {
    const { name, value } = e.target;
    setUser({
      ...user,
      [name]: value,
    });
  };

  const handleImg = (e) => {
    if (e.target.files.length) {
      setUser({
        ...user,
        thumbnail: e.target.files[0],
      });
    }
  };

  const removeImg = () => {
    setUser({
      ...user,
      thumbnail: '',
    });
  };

  const handleSave = async (e) => {
    e.preventDefault();

    if (user.thumbnail === '') {
      setFileError('Please select file');
    } else if (user.category === '') {
      setSelectCategoryError('Please select category');
    } 
    else if (
      user.thumbnail !== ''
    ) {
      let formdata = new FormData();
      formdata.append('category', user.category);
      formdata.append('sub_category_name', user.sub_category_name?.trim());
      formdata.append(
        'category_description',
        user.category_description?.trim()
      );
      formdata.append('images', user.thumbnail);

      const userResp = await dataService.post(
        Api.Admin.addSubCategory,
        formdata,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (userResp.data.status === 1) {
        toast.success('Data submitted successfully');
      } else {
        CustomToast();
      }
    }
  };
//#endregion

//#region RETURN JSX 
  return (
    <>
      <ValidatorForm onSubmit={handleSave}>
        <Container maxWidth="false">
          <Typography sx={{ my: 2 }} align="center" variant="h4">
            Add Sub-Category
          </Typography>
          <Box>
            <Grid container spacing={4}>
              <Grid item xs={12} md={1} />
              <Grid item xs={12} md={3}>
                <Typography align="left" sx={{ mt: 2 }}>
                  Select Category
                </Typography>
              </Grid>
              <Grid item xs={12} md={8}>
                <FormControl
                  variant="standard"
                  sx={{ width: { lg: '300px', xs: '150px' } }}
                >
                  <InputLabel id="demo-simple-select-standard-label">
                    Select
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    name="category"
                    value={user.category}
                    onChange={handleChangeUser}
                    label="Select"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                  <FormHelperText
                    sx={{ color: '#d32f2f', ml: '14px', mt: '4px' }}
                  >
                    {selectCategoryError}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={1} />
              <Grid item xs={12} md={3}>
                <Typography align="left" sx={{ mt: 2 }}>
                  Sub-Category Name
                </Typography>
              </Grid>
              <Grid item xs={12} md={8}>
                <TextValidator
                  sx={{ mt: 2, width: { lg: '300px', xs: '150px' } }}
                  size="small"
                  fullWidth
                  id="sub_category_name"
                  className="form-control border-none"
                  value={user.sub_category_name}
                  name="sub_category_name"
                  onChange={handleChangeUser}
                  type="text"
                  validators={['required']}
                  errorMessages={['Please enter sub category name']}
                />
              </Grid>
              <Grid item xs={12} md={1} />
              <Grid item xs={12} md={3}>
                <Typography align="left" sx={{ mt: 2 }}>
                  Category Description
                </Typography>
              </Grid>
              <Grid item xs={12} md={8}>
                <TextValidator
                  sx={{ mt: 2, width: { lg: '300px', xs: '150px' } }}
                  size="small"
                  fullWidth
                  id="category_description"
                  className="form-control border-none"
                  value={user.category_description}
                  name="category_description"
                  onChange={handleChangeUser}
                  type="text"
                  validators={['required']}
                  errorMessages={['Please enter category description']}
                />
              </Grid>
              <Grid item xs={12} md={1} />
              <Grid item xs={12} md={3}>
                <Typography align="left" sx={{ mt: 2 }}>
                  Upload Thumbnail
                </Typography>
              </Grid>
              <Grid item xs={12} md={8}>
                <Box sx={{ width: '50%', position: 'relative' }}>
                  {user.thumbnail === '' ? (
                    <>
                      <Button
                        variant="contained"
                        component="label"
                        sx={{
                          width: { lg: '300px', xs: '150px' },
                          backgroundColor: '#00000059',
                          '&:hover': {
                            background: '#00000059',
                          },
                        }}
                      >
                        <input
                          type="file"
                          onChange={handleImg}
                          style={{ cursor: 'pointer' }}
                        />
                      </Button>
                      <FormHelperText
                        sx={{ color: '#d32f2f', ml: '14px', mt: '4px' }}
                      >
                        {fileError}
                      </FormHelperText>
                    </>
                  ) : (
                    <HighlightOffTwoToneIcon
                      onClick={removeImg}
                      fontSize="large"
                      sx={{
                        color: 'background: black(0.5)',
                        m: 1,
                        cursor: 'pointer',
                        position: 'absolute',
                        top: '-30px',
                        right: '-34px',
                      }}
                    />
                  )}
                  {user.thumbnail === '' ? (
                    ''
                  ) : (
                    <Img
                      sx={{ my: 2 }}
                      src={window.URL.createObjectURL(user.thumbnail)}
                      alt="No Image"
                    />
                  )}
                </Box>
              </Grid>
            </Grid>
            <Typography align="center" variant="h4">
              <Button
                sx={{
                  my: 5,
                  width: '125px',
                  backgroundColor: '#00000059',
                  '&:hover': {
                    background: '#00000059',
                  },
                }}
                variant="contained"
                type="submit"
              >
                Save
              </Button>
            </Typography>
          </Box>
        </Container>
      </ValidatorForm>
    </>
  );
};
export default AddSubCategory;
//#endregion