import Usersaga from './Usersaga/reduser';
import AdminSaga from './adminSaga/reducer'
import { combineReducers } from 'redux';

const createReducer = () =>
  combineReducers({
    Usersaga,
    AdminSaga
  });

export default createReducer;
