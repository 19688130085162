import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import {
  tableCellClasses,
  TableCell,
  Typography,
  Container,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Table,
  TableBody,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import { Api } from '../../../../../config/api';
import dataService from '../../../../../config/dataService';
import CommonButton from '../../../../../component/customComponents/buttons/CommonButton';
import { toast } from 'react-toastify';
//#region ALL HOOKS AND VARIABALS

import AdminIndex from '../../../AdminIndex';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const { getBenefitList } = AdminIndex.AdminSagaActions;
export default function Membership() {
  let token = localStorage.getItem('auth');
  const history = useHistory();
  const dispatch = AdminIndex.useDispatch();
  const [getData, setGetData] = useState([]);
  const [membershipId, setMembershipId] = useState(null);
  const [formFillingGuideId, setFormFillingGuideId] = useState(null);
  const [tAndCId, setTAndCId] = useState(null);

  const isKeyInArray = (array, key) => {
    return array.some((obj) => obj.hasOwnProperty(key));
  };

  const membershipBtn = (
    <CommonButton
      display={membershipId && 'none'}
      name="Add"
      onClick={() => history.push('/admin/membership/membershipcontent')}
    />
  );
  const formFillingGuideBtn = (
    <CommonButton
      display={formFillingGuideId && 'none'}
      name="Add"
      onClick={() => history.push('/admin/membership/formfillinguide')}
    />
  );
  const memberTAndCBtn = (
    <CommonButton
      display={tAndCId && 'none'}
      name="Add"
      onClick={() =>
        history.push('/admin/membership/membershiptermsandcondition')
      }
    />
  );

  const membershipEditBtn = (
    <CommonButton
      display={!membershipId && 'none'}
      name="Edit"
      onClick={() =>
        history.push(`/admin/membership/membershipcontent/${membershipId}`)
      }
    />
  );
  const formFillingGuideEditBtn = (
    <CommonButton
      display={!formFillingGuideId && 'none'}
      name="Edit"
      onClick={() =>
        history.push(`/admin/membership/formfillinguide/${formFillingGuideId}`)
      }
    />
  );
  const tAndCEditBtn = (
    <CommonButton
      display={!tAndCId && 'none'}
      name="Edit"
      onClick={() =>
        history.push(`/admin/membership/membershiptermsandcondition/${tAndCId}`)
      }
    />
  );
  function createData(content, addBtn, editBtn) {
    return { content, addBtn, editBtn };
  }

  const rows = [
    createData('Membership', membershipBtn, membershipEditBtn),
    createData(
      'Form filing guide',
      formFillingGuideBtn,
      formFillingGuideEditBtn
    ),
    createData('T&C', memberTAndCBtn, tAndCEditBtn),
  ];

  useEffect(() => {
    getAboutUsData();
  }, []);

  //#endregion

  //#region ALL EVENT HANDLERS AND  FUCTIONS
  const getAboutUsData = async (e) => {
    let urlencoded = new URLSearchParams();
    try {
      dispatch(getBenefitList(token, urlencoded));
      // const Data = await dataService.post(Api.Admin.listBenefits, urlencoded);

      // setGetData(Data?.data?.data);
      // setMembershipId(Data?.data?.data?.[0]?._id);
      // setFormFillingGuideId(Data?.data?.listFormFillGuideline?.[0]?._id);
      // setTAndCId(Data?.data?.listTermsCondition?.[0]?._id);
    } catch (err) {
      toast.error(err?.message || 'Something went wrong');
    }
  };

  const loading = AdminIndex.useSelector((state) => {
    return state.AdminSaga.loading;
  });

  const benefitList = AdminIndex.useSelector((state) => {
    return state.AdminSaga.benefitList;
  });

  useEffect(() => {
    setGetData(benefitList);
    setMembershipId(benefitList?.[0]?._id);
    setFormFillingGuideId(benefitList?.listFormFillGuideline?.[0]?._id);
    setTAndCId(benefitList?.listTermsCondition?.[0]?._id);
  }, [benefitList]);
  //#endregion

  //#region RETURN JSX
  return (
    <>
      <Container maxWidth="false">
        <Typography
          sx={{ my: 2, fontWeight: 'bold' }}
          variant="h4"
          align="center"
        >
          Membership Management
        </Typography>
        <TableContainer
          elevation={0}
          sx={{
            mt: 8,
            maxWidth: {
              xs: '365px',
              sm: '600px',
              md: '100%',
              lg: '100%',
            },
            '&::-webkit-scrollbar': {
              width: 10,
            },
            '&::-webkit-scrollbar': {
              height: '8px',
              width: '12px',
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: '#fff',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(224, 224, 224, 1)',
              borderRadius: '30px',
              width: '20px',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              background: '#B0B0B0',
            },
          }}
          component={Paper}
        >
          <Table>
            <TableHead>
              <TableRow sx={{ whiteSpace: 'nowrap' }}>
                <StyledTableCell
                  colSpan={2}
                  align="left"
                  sx={{ fontWeight: 'bold', fontSize: '25px' }}
                >
                  Content
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <StyledTableRow key={row.name} sx={{ whiteSpace: 'nowrap' }}>
                  <StyledTableCell align="left">{row.content}</StyledTableCell>
                  <StyledTableCell align="right">
                    {row.addBtn}
                    {row.editBtn}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </>
  );
}
//#endregion
