const actions = {
  LOGOUT_REQUEST: "LOGOUT_REQUEST",
  STATE_EMPTY: "STATE_EMPTY",
  STATE_EMPTY_SUCCESS: "STATE_EMPTY_SUCCESS",
  UPDATE_MODAL_REQUEST: "UPDATE_MODAL_REQUEST",
  UPDATE_MODAL_SUCCESS: "UPDATE_MODAL_SUCCESS",
  LODER_ON: "LODER_ON",
  LODER_OFF: "LODER_OFF",
  LOGOUT: "LOGOUT",
  ERROR: "ERROR",
  Register_REQUEST: "Register_REQUEST",
  Register_SUCCESS: "Register_SUCCESS",
  Member_REQUEST: "Member_REQUEST",
  Member_SUCCESS: "Member_SUCCESS",
  Login_REQUEST: "Login_REQUEST",
  Login_SUCCESS: "Login_SUCCESS",
  Addcart_REQUEST: "Addcart_REQUEST",
  Addcart_SUCCESS: "Addcart_SUCCESS",
  Userproduct_REQUEST: "Userproduct_REQUEST",
  Userproduct_SUCCESS: "Userproduct_SUCCESS",
  AllProduct_REQUEST: "AllProduct_REQUEST",
  AllProduct_SUCCESS: "AllProduct_SUCCESS",
  Product_REQUEST: "Product_REQUEST",
  Product_SUCCESS: "Product_SUCCESS",
  CategoryMenu_REQUEST: "CategoryMenu_REQUEST",
  CategoryMenu_SUCCESS: "CategoryMenu_SUCCESS",
  ViewCart_REQUEST: "ViewCart_REQUEST",
  ViewCart_SUCCESS: "ViewCart_SUCCESS",
  UpdateCartQuantity_REQUEST: "updateCartQuantity_REQUEST",
  UpdateCartQuantity_SUCCESS: "updateCartQuantity_SUCCESS",
  DeleteCartItem_REQUEST: "DeleteCartItem_REQUEST",
  DeleteCartItem_SUCCESS: "DeleteCartItem_SUCCESS",
  SignIn_REQUEST: "SignIn_REQUEST",
  SignIn_SUCCESS: "SignIn_SUCCESS",
  UserCreateProduct_REQUEST: "UserCreateProduct_REQUEST",
  UserCreateProduct_SUCCESS: "UserCreateProduct_SUCCESS",
  SingleUserProduct_REQUEST: "SingleUserProduct_REQUEST",
  SingleUserProduct_SUCCESS: "SingleUserProduct_SUCCESS",
  ListItem_REQUEST: "ListItem_REQUEST",
  ListItem_SUCCESS: "ListItem_SUCCESS",
  ListMaterial_REQUEST: "ListMaterial_REQUEST",
  ListMaterial_SUCCESS: "ListMaterial_SUCCESS",
  DeleteUserProduct_REQUEST: "DeleteUserProduct_REQUEST",
  DeleteUserProduct_SUCCESS: "DeleteUserProduct_SUCCESS",
  OrderPlace_REQUEST: "OrderPlace_REQUEST",
  OrderPlace_SUCCESS: "OrderPlace_SUCCESS",
  ReceivedOrder_REQUEST: "ReceivedOrder_REQUEST",
  ReceivedOrder_SUCCESS: "ReceivedOrder_SUCCESS",
  ViewReceivedOrder_REQUEST: "ViewReceivedOrder_REQUEST",
  ViewReceivedOrder_SUCCESS: "ViewReceivedOrder_SUCCESS",
  GetOrderList_REQUEST: "GetOrderList_REQUEST",
  GetOrderList_SUCCESS: "GetOrderList_SUCCESS",
  ViewOrder_REQUEST: "ViewOrder_REQUEST",
  ViewOrder_SUCCESS: "ViewOrder_SUCCESS",
  Faq_REQUEST: "Faq_REQUEST",
  Faq_SUCCESS: "Faq_SUCCESS",
  RaiseCustomProduct_REQUEST: "RaiseCustomProduct_REQUEST",
  RaiseCustomProduct_SUCCESS: "RaiseCustomProduct_SUCCESS",
  ViewCustomProduct_REQUEST: "ViewCustomProduct_REQUEST",
  ViewCustomProduct_SUCCESS: "ViewCustomProduct_SUCCESS",
  GetSingleCustomProduct_REQUEST: "GetSingleCustomProduct_REQUEST",
  GetSingleCustomProduct_SUCCESS: "GetSingleCustomProduct_SUCCESS",
  GetAllCms_REQUEST: "GetAllCms_REQUEST",
  GetAllCms_SUCCESS: "GetAllCms_SUCCESS",
  AddUpdateRating_REQUEST: "AddUpdateRating_REQUEST",
  AddUpdateRating_SUCCESS: "AddUpdateRating_SUCCESS",
  SingleRating_REQUEST: "SingleRating_REQUEST",
  SingleRating_SUCCESS: "SingleRating_SUCCESS",
  GetAllRating_REQUEST: "GetAllRating_REQUEST",
  GetAllRating_SUCCESS: "GetAllRating_SUCCESS",
  RecievedCustomProduct_REQUEST: "RecievedCustomProduct_REQUEST",
  RecievedCustomProduct_SUCCESS: "RecievedCustomProduct_SUCCESS",
  AllocateCustomProductRequest_REQUEST: "AllocateCustomProductRequest_REQUEST",
  AllocateCustomProductRequest_SUCCESS: "AllocateCustomProductRequest_SUCCESS",
  AcceptCustomProduct_REQUEST: "AcceptCustomProduct_REQUEST",
  AcceptCustomProduct_SUCCESS: "AcceptCustomProduct_SUCCESS",
  ReturnOrder_REQUEST: "ReturnOrder_REQUEST",
  ReturnOrder_SUCCESS: "ReturnOrder_SUCCESS",
  GetallReturnOrder_REQUEST: "GetallReturnOrder_REQUEST",
  GetallReturnOrder_SUCCESS: "GetallReturnOrder_SUCCESS",
  GetSingleReturnOrder_REQUEST: "GetSingleReturnOrder_REQUEST",
  GetSingleReturnOrder_SUCCESS: "GetSingleReturnOrder_SUCCESS",
  UserCreatePricious_REQUEST: "UserCreatePricious_REQUEST",
  UserCreatePricious_SUCCESS: "UserCreatePricious_SUCCESS",
  ListBlog_REQUEST: "ListBlog_REQUEST",
  ListBlog_SUCCESS: "ListBlog_SUCCESS",
  ListGuideline_REQUEST: "ListGuideline_REQUEST",
  ListGuideline_SUCCESS: "ListGuideline_SUCCESS",
  SingleBankDetails_REQUEST: "SingleBankDetails_REQUEST",
  SingleBankDetails_SUCCESS: "SingleBankDetails_SUCCESS",
  ListProductDescription_REQUEST: "ListProductDescription_REQUEST",
  ListProductDescription_SUCCESS: "ListProductDescription_SUCCESS",
  ListProductPurity_REQUEST: "ListProductPurity_REQUEST",
  ListProductPurity_SUCCESS: "ListProductPurity_SUCCESS",
  ListProductPricing_REQUEST: "ListProductPricing_REQUEST",
  ListProductPricing_SUCCESS: "ListProductPricing_SUCCESS",
  Singleguideline_REQUEST: "Singleguideline_REQUEST",
  Singleguideline_SUCCESS: "Singleguideline_SUCCESS",
  ListCustomerServices_REQUEST: "ListCustomerServices_REQUEST",
  ListCustomerServices_SUCCESS: "ListCustomerServices_SUCCESS",
  GetAllProductHome_REQUEST: "GetAllProductHome_REQUEST",
  GetAllProductHome_SUCCESS: "GetAllProductHome_SUCCESS",
  TrendingProducts_REQUEST: "TrendingProducts_REQUEST",
  TrendingProducts_SUCCESS: "TrendingProducts_SUCCESS",
  ListTopDeals_REQUEST: "ListTopDeals_REQUEST",
  ListTopDeals_SUCCESS: "ListTopDeals_SUCCESS",
  ListBenefits_REQUEST: "ListBenefits_REQUEST",
  ListBenefits_SUCCESS: "ListBenefits_SUCCESS",
  VerifyOtp_REQUEST: "VerifyOtp_REQUEST",
  VerifyOtp_SUCCESS: "VerifyOtp_SUCCESS",
  SendOtpEmail_REQUEST: "SendOtpEmail_REQUEST",
  SendOtpEmail_SUCCESS: "SendOtpEmail_SUCCESS",
  AllPreciousStoneProducts_REQUEST: "AllPreciousStoneProducts_REQUEST",
  AllPreciousStoneProducts_SUCCESS: "AllPreciousStoneProducts_SUCCESS",
  UpdateDeliveryStaus_REQUEST: "UpdateDeliveryStaus_REQUEST",
  UpdateDeliveryStaus_SUCCESS: "UpdateDeliveryStaus_SUCCESS",
  GET_PROFILE_REQUEST: "GET_PROFILE_REQUEST",
  GET_PROFILE_SUCCESS: "GET_PROFILE_SUCCESS",
  GET_ACCOUNT_REQUEST: "GET_ACCOUNT_REQUEST",
  GET_ACCOUNT_SUCCESS: "GET_ACCOUNT_SUCCESS",
  UPDATE_ACCOUNT_REQUEST: "UPDATE_ACCOUNT_REQUEST",
  UPDATE_ACCOUNT_SUCCESS: "UPDATE_ACCOUNT_SUCCESS",
  CHANGE_PASSWORD_REQUEST: "CHANGE_PASSWORD_REQUEST",
  CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
  FilterMaterialAndItem_REQUEST: "FilterMaterialAndItem_REQUEST",
  FilterMaterialAndItem_SUCCESS: "FilterMaterialAndItem_SUCCESS",
  CatelogueSeller_REQUEST: "CatelogueSeller_REQUEST",
  CatelogueSeller_SUCCESS: "CatelogueSeller_SUCCESS",
  CatelogueProduct_REQUEST: "CatelogueProduct_REQUEST",
  CatelogueProduct_SUCCESS: "CatelogueProduct_SUCCESS",
  ProductDesigns_REQUEST: "ProductDesigns_REQUEST",
  ProductDesigns_SUCCESS: "ProductDesigns_SUCCESS",
  AddToCartBulkProduct_REQUEST: "AddToCartBulkProduct_REQUEST",
  AddToCartBulkProduct_SUCCESS: "AddToCartBulkProduct_SUCCESS",
  CatelogueItems_REQUEST: "CatelogueItems_REQUEST",
  CatelogueItems_SUCCESS: "CatelogueItems_SUCCESS",
  ForgotPassword_REQUEST: "ForgotPassword_REQUEST",
  ResendUserOtp_REQUEST: "ResendUserOtp_REQUEST",
  ResendUserOtp_SUCCESS: "ResendUserOtp_SUCCESS",

  ForgotPassword_SUCCESS: "ForgotPassword_SUCCESS",
  ResetPassword_REQUEST: "ResetPassword_REQUEST",
  ResetPassword_SUCCESS: "ResetPassword_SUCCESS",
  AllReceivedReturnOrder_REQUEST: "AllReceivedReturnOrder_REQUEST",
  AllReceivedReturnOrder_SUCCESS: "AllReceivedReturnOrder_SUCCESS",
  ViewReceivedReturnOrder_REQUEST: "ViewReceivedReturnOrder_REQUEST",
  ViewReceivedReturnOrder_SUCCESS: "ViewReceivedReturnOrder_SUCCESS",

  logoutSaga: (navigate) => ({
    type: actions.LOGOUT_REQUEST,
    navigate: navigate,
  }),
  stateEmpty: (navigate) => ({
    type: actions.STATE_EMPTY,
    navigate: navigate,
  }),
  updateModalSaga: (navigate) => ({
    type: actions.UPDATE_MODAL_REQUEST,
    navigate: navigate,
  }),
  loginSaga: (payload, navigate) => ({
    type: actions.Login_REQUEST,
    payload: payload,
    navigate: navigate,
  }),
  registerSaga: (payload, navigate) => ({
    type: actions.Register_REQUEST,
    payload: payload,
    navigate: navigate,
  }),
  memberSaga: (payload, navigate) => ({
    type: actions.Member_REQUEST,
    payload: payload,
    navigate: navigate,
  }),
  addcartSaga: (payload, navigate, Token) => ({
    type: actions.Addcart_REQUEST,
    payload: payload,
    token: Token,
    navigate: navigate,
  }),
  UserproductSaga: (payload, navigate, Token) => ({
    type: actions.Userproduct_REQUEST,
    payload: payload,
    token: Token,
    navigate: navigate,
  }),
  AllproductSaga: (payload, navigate) => ({
    type: actions.AllProduct_REQUEST,
    payload: payload,
    navigate: navigate,
  }),
  ProductSaga: (payload, navigate, Token) => ({
    type: actions.Product_REQUEST,
    token: Token,
    payload: payload,
    navigate: navigate,
  }),
  CategoryMenuSaga: (payload, navigate) => ({
    type: actions.CategoryMenu_REQUEST,
    payload: payload,
    navigate: navigate,
  }),
  ViewCartSaga: (payload, navigate, Token) => ({
    type: actions.ViewCart_REQUEST,
    token: Token,
    payload: payload,
    navigate: navigate,
  }),
  UpdateCartQuantitySaga: (payload, navigate, Token) => ({
    type: actions.UpdateCartQuantity_REQUEST,
    token: Token,
    payload: payload,
    navigate: navigate,
  }),
  DeleteCartItemSaga: (payload, navigate) => ({
    type: actions.DeleteCartItem_REQUEST,
    payload: payload,
    navigate: navigate,
  }),
  SignInSaga: (payload, navigate) => ({
    type: actions.SignIn_REQUEST,
    payload: payload,
    navigate: navigate,
  }),
  UserCreateProductSaga: (payload, navigate, Token) => ({
    type: actions.UserCreateProduct_REQUEST,
    payload: payload,
    token: Token,
    navigate: navigate,
  }),
  SingleUserProductSaga: (payload, navigate, Token) => ({
    type: actions.SingleUserProduct_REQUEST,
    payload: payload,
    token: Token,
    navigate: navigate,
  }),
  ListItemSaga: (payload, navigate) => ({
    type: actions.ListItem_REQUEST,
    payload: payload,
    navigate: navigate,
  }),
  ListMaterialSaga: (payload, navigate) => ({
    type: actions.ListMaterial_REQUEST,
    payload: payload,
    navigate: navigate,
  }),
  DeleteUserProductSaga: (payload, navigate, Token) => ({
    type: actions.DeleteUserProduct_REQUEST,
    payload: payload,
    token: Token,
    navigate: navigate,
  }),
  OrderPlaceSaga: (payload, navigate, Token) => ({
    type: actions.OrderPlace_REQUEST,
    payload: payload,
    token: Token,
    navigate: navigate,
  }),
  ReceivedOrderSaga: (payload, navigate, Token) => ({
    type: actions.ReceivedOrder_REQUEST,
    payload: payload,
    token: Token,
    navigate: navigate,
  }),
  ViewReceivedOrderSaga: (payload, navigate, Token) => ({
    type: actions.ViewReceivedOrder_REQUEST,
    payload: payload,
    token: Token,
    navigate: navigate,
  }),
  GetOrderListSaga: (payload, navigate, Token) => ({
    type: actions.GetOrderList_REQUEST,
    payload: payload,
    token: Token,
    navigate: navigate,
  }),
  ViewOrderSaga: (payload, navigate, Token) => ({
    type: actions.ViewOrder_REQUEST,
    payload: payload,
    token: Token,
    navigate: navigate,
  }),
  FaqSaga: (payload, navigate) => ({
    type: actions.Faq_REQUEST,
    payload: payload,
    navigate: navigate,
  }),
  RaiseCustomProductSaga: (payload, navigate, Token) => ({
    type: actions.RaiseCustomProduct_REQUEST,
    payload: payload,
    token: Token,
    navigate: navigate,
  }),
  ViewCustomProductSaga: (payload, navigate, Token) => ({
    type: actions.ViewCustomProduct_REQUEST,
    payload: payload,
    token: Token,
    navigate: navigate,
  }),
  GetSingleCustomProductSaga: (payload, navigate, Token) => ({
    type: actions.GetSingleCustomProduct_REQUEST,
    payload: payload,
    token: Token,
    navigate: navigate,
  }),
  GetAllCmsSaga: (payload, navigate) => ({
    type: actions.GetAllCms_REQUEST,
    payload: payload,
    navigate: navigate,
  }),
  AddUpdateRatingSaga: (payload, navigate, Token) => ({
    type: actions.AddUpdateRating_REQUEST,
    payload: payload,
    token: Token,
    navigate: navigate,
  }),
  SingleRatingSaga: (payload, navigate) => ({
    type: actions.SingleRating_REQUEST,
    payload: payload,
    navigate: navigate,
  }),
  GetAllRatingSaga: (payload, navigate) => ({
    type: actions.GetAllRating_REQUEST,
    payload: payload,
    navigate: navigate,
  }),
  RecievedCustomProductSaga: (payload, navigate, Token) => ({
    type: actions.RecievedCustomProduct_REQUEST,
    payload: payload,
    token: Token,
    navigate: navigate,
  }),
  RecievedCustomProductSaga: (payload, navigate, Token) => ({
    type: actions.RecievedCustomProduct_REQUEST,
    payload: payload,
    token: Token,
    navigate: navigate,
  }),
  AcceptCustomProductSaga: (payload, navigate, Token) => ({
    type: actions.AcceptCustomProduct_REQUEST,
    payload: payload,
    token: Token,
    navigate: navigate,
  }),
  ReturnOrderSaga: (payload, navigate, Token) => ({
    type: actions.ReturnOrder_REQUEST,
    payload: payload,
    token: Token,
    navigate: navigate,
  }),
  GetallReturnOrderSaga: (payload, navigate, Token) => ({
    type: actions.GetallReturnOrder_REQUEST,
    payload: payload,
    token: Token,
    navigate: navigate,
  }),
  GetSingleReturnOrderSaga: (payload, navigate, Token) => ({
    type: actions.GetSingleReturnOrder_REQUEST,
    payload: payload,
    token: Token,
    navigate: navigate,
  }),
  UserCreatePriciousSaga: (payload, navigate, Token) => ({
    type: actions.UserCreatePricious_REQUEST,
    payload: payload,
    token: Token,
    navigate: navigate,
  }),
  ListBlogSaga: (payload, navigate) => ({
    type: actions.ListBlog_REQUEST,
    payload: payload,
    navigate: navigate,
  }),
  ListGuidelineSaga: (payload, navigate) => ({
    type: actions.ListGuideline_REQUEST,
    payload: payload,
    navigate: navigate,
  }),
  SingleBankDetailsSaga: (payload, navigate, Token) => ({
    type: actions.SingleBankDetails_REQUEST,
    token: Token,
    payload: payload,
    navigate: navigate,
  }),
  ListProductDescriptionSaga: (payload, navigate) => ({
    type: actions.ListProductDescription_REQUEST,
    payload: payload,
    navigate: navigate,
  }),
  ListProductPuritySaga: (payload, navigate) => ({
    type: actions.ListProductPurity_REQUEST,
    payload: payload,
    navigate: navigate,
  }),
  ListProductPricingSaga: (payload, navigate) => ({
    type: actions.ListProductPricing_REQUEST,
    payload: payload,
    navigate: navigate,
  }),
  SingleguidelineSaga: (payload, navigate) => ({
    type: actions.Singleguideline_REQUEST,
    payload: payload,
    navigate: navigate,
  }),
  ListCustomerServicesSaga: (payload, navigate) => ({
    type: actions.ListCustomerServices_REQUEST,
    payload: payload,
    navigate: navigate,
  }),
  GetAllProductHomeServicesSaga: (payload, navigate) => ({
    type: actions.GetAllProductHome_REQUEST,
    payload: payload,
    navigate: navigate,
  }),
  TrendingProductsSaga: (payload, navigate) => ({
    type: actions.TrendingProducts_REQUEST,
    payload: payload,
    navigate: navigate,
  }),
  ListTopDealsSaga: (payload, navigate) => ({
    type: actions.ListTopDeals_REQUEST,
    payload: payload,
    navigate: navigate,
  }),
  ListBenefitsSaga: (payload, navigate) => ({
    type: actions.ListBenefits_REQUEST,
    payload: payload,
    navigate: navigate,
  }),
  sendOtpEmail: (payload, navigate) => ({
    type: actions.SendOtpEmail_REQUEST,
    payload: payload,
    navigate: navigate,
  }),
  VerifyOtpEmail: (payload, navigate, forgotPassword) => ({
    type: actions.VerifyOtp_REQUEST,
    payload: payload,
    navigate: navigate,
    forgotPassword: forgotPassword,
  }),
  AllPreciousStoneProductsSaga: (payload, navigate, Token) => ({
    type: actions.AllPreciousStoneProducts_REQUEST,
    token: Token,
    payload: payload,
    navigate: navigate,
  }),
  UpdateDeliveryStausSaga: (payload, navigate, Token) => ({
    type: actions.UpdateDeliveryStaus_REQUEST,
    token: Token,
    payload: payload,
    navigate: navigate,
  }),
  GetProfile: (payload, navigate, Token) => ({
    type: actions.GET_PROFILE_REQUEST,
    token: Token,
    payload: payload,
    navigate: navigate,
  }),
  GetAccountDetails: (payload, navigate, token) => ({
    type: actions.GET_ACCOUNT_REQUEST,
    token,
    payload: payload,
    navigate: navigate,
  }),
  UpdateAccountDetails: (payload, navigate, token) => ({
    type: actions.UPDATE_ACCOUNT_REQUEST,
    token,
    payload: payload,
    navigate: navigate,
  }),
  ChangePassword: (payload, navigate, token) => ({
    type: actions.CHANGE_PASSWORD_REQUEST,
    token,
    payload: payload,
    navigate: navigate,
  }),
  FilterMaterialAndItemSaga: (payload, navigate, token) => ({
    type: actions.FilterMaterialAndItem_REQUEST,
    token,
    payload: payload,
    navigate: navigate,
  }),
  catelogueSellerSaga: (payload, navigate) => ({
    type: actions.CatelogueSeller_REQUEST,
    payload: payload,
    navigate: navigate,
  }),
  catelogueProductSaga: (payload, navigate) => ({
    type: actions.CatelogueProduct_REQUEST,
    payload: payload,
    navigate: navigate,
  }),
  productDesignsSaga: (payload, navigate) => ({
    type: actions.ProductDesigns_REQUEST,
    payload: payload,
    navigate: navigate,
  }),
  AddToCartBulkProductSaga: (payload, navigate, Token) => ({
    type: actions.AddToCartBulkProduct_REQUEST,
    token: Token,
    payload: payload,
    navigate: navigate,
  }),
  CatelogueItemsSaga: (payload, navigate) => ({
    type: actions.CatelogueItems_REQUEST,
    payload: payload,
    navigate: navigate,
  }),
  ForgotPasswordSaga: (payload, navigate) => ({
    type: actions.ForgotPassword_REQUEST,
    payload: payload,
    navigate: navigate,
   
  }),
  ResendUserOtpSaga: (payload, navigate) => ({
    type: actions.ResendUserOtp_REQUEST,
    payload: payload,
    navigate: navigate,
  }),
  ResetPasswordSaga: (payload, navigate) => ({
    // type: actions.ForgotPassword_REQUEST,
    type: actions.ResetPassword_REQUEST,
    payload: payload,
    navigate: navigate,
  }),
  AllReceivedReturnOrderSaga: (navigate, Token) => ({
    type: actions.AllReceivedReturnOrder_REQUEST,
    token: Token,
    navigate: navigate,
  }),
  ViewReceivedReturnOrderSaga: (payload, navigate, Token) => ({
    type: actions.ViewReceivedReturnOrder_REQUEST,
    token: Token,
    payload: payload,
    navigate: navigate,
  }),
};
export default actions;
