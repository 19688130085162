import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  Grid,
  Container,
  Typography,
  TextField,
  Box,
  Button,
  Tooltip,
  TextareaAutosize,
} from "@mui/material";
import { useHistory, useParams } from "react-router-dom";
import DisabledByDefaultIcon from "@mui/icons-material/DisabledByDefault";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { Api } from "../../../../config/api";
import dataService from "../../../../config/dataService";
import { toast } from "react-toastify";
import ContainedButton from "../../../../component/customComponents/buttons/ContainedButton";
import ImgUploadButton from "../../../../component/customComponents/buttons/ImgUploadButton";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import {
  ErrorMsg,
  TextAreaErrorMsg,
} from "../../../../component/customComponents/errorMsg/ErrorMsg";
import {
  minCategoryNameLength,
  categoryNameLength,
  minCategoryDescriptionLength,
  categoryDescriptionLength,
  NO_WHITE_SPACE_BEGINNING_END,
} from "../../../../component/customComponents/validation/Validation";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import UploadIcon from "@mui/icons-material/Upload";

import AdminIndex from "../../AdminIndex";
//#region ALL HOOKS AND VARIABALS
const Img = styled("img")({
  display: "block",
  maxWidth: "100%",
  height: "auto",
  maxHeight: "50vh",
  borderRadius: "6px",
});

const { getCategoryDetail, updateCategory } = AdminIndex.AdminSagaActions;
const UpdateCategory = () => {
  const { id } = useParams();
  let token = localStorage.getItem("auth");
  const dispatch = AdminIndex.useDispatch();
  const history = useHistory();
  const navigate = useHistory();
  const [getData, setGetData] = useState([]);
  const [image, setImage] = useState("");
  const [url, setUrl] = useState("");

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
    watch,
  } = useForm({ mode: "onChange" });

  useEffect(() => {
    getInfo();
  }, []);

  useEffect(() => {
    setValue("category_name", getData?.category_name);
    setValue("category_description", getData?.category_description);
    setUrl(getData?.thumbnail);
  }, [getData]);
  //#endregion

  //#region ALL EVENT HANDLERS AND  FUCTIONS

  const handleImg = (e) => {
    if (e.target.files.length) {
      setImage(e.target.files[0]);
      setUrl(window.URL.createObjectURL(e.target.files[0]));
    }
  };

  const removeImg = () => {
    setImage("");
    setUrl("");
  };

  const getInfo = async () => {
    try {
      let formdata = new URLSearchParams();
      formdata.append("id", id);
      // const user = await dataService.post(Api.Admin.getCategory, formdata, {
      //   headers: {
      //     auth: token,
      //   },
      // });
      // setGetData(user.data.data);

      dispatch(getCategoryDetail(token, formdata));
    } catch (err) {
      toast.error(err?.message || "Something went wrong");
    }
  };

  const categoryDetail = AdminIndex.useSelector((state) => {
    return state.AdminSaga.categoryDetail;
  });

  useEffect(() => {
    setGetData(categoryDetail);
  }, [categoryDetail]);

  const handleSave = async (data) => {
    try {
      let formdata = new FormData();
      formdata.append("_id", id);
      formdata.append("category_name", data?.category_name);
      formdata.append("category_description", data?.category_description);
      formdata.append("images", image);
      formdata.append("fileurl", url);

      dispatch(updateCategory(token, formdata, navigate));
      // const userResp = await dataService.post(
      //   Api.Admin.updateCategory,
      //   formdata,
      //   {
      //     headers: {
      //       'Content-Type': 'multipart/form-data',
      //       auth: token,
      //     },
      //   }
      // );

      // if (userResp.data.status === 1) {
      //   toast.success('Category updated successfully');
      //   history.push('/admin/categorymanagement');
      // }
    } catch (err) {
      toast.error(err?.message || "Something went wrong");
    }
  };
  //#endregion

  //#region RETURN JSX
  return (
    <>
      <form onSubmit={handleSubmit(handleSave)}>
        <Container maxWidth="false">
          <Typography sx={{ mt: 2, mb: 7 }} align="center" variant="h4">
            Update Category
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12} md={2.3} />
            <Grid item xs={12} md={2}>
              <Typography align="left" sx={{ mt: { lg: 3.5, xs: 1 } }}>
                Category Name
              </Typography>
            </Grid>
            <Grid item xs={12} md={7.7}>
              <TextField
                autoComplete="off"
                sx={{
                  mt: 2,
                  width: { lg: "300px", xs: "100%" },
                  "& .MuiFormHelperText-root": { ml: 0, mt: "5px" },
                }}
                size="small"
                fullWidth
                className="form-control border-none"
                name="category_name"
                type="text"
                {...register("category_name", {
                  required: <ErrorMsg msg="Please enter Heading" />,
                  minLength: {
                    value: minCategoryNameLength,
                    message: (
                      <ErrorMsg
                        msg={`Minimum ${minCategoryNameLength} Characters Allowed`}
                      />
                    ),
                  },
                  maxLength: {
                    value: categoryNameLength,
                    message: (
                      <ErrorMsg
                        msg={`Maximum ${categoryNameLength} Characters Allowed`}
                      />
                    ),
                  },
                  pattern: {
                    value: NO_WHITE_SPACE_BEGINNING_END,
                    message: (
                      <ErrorMsg msg="Whitespace is not allowed at beginning or end" />
                    ),
                  },
                })}
                helperText={
                  <ErrorMessage errors={errors} name="category_name" />
                }
              />
            </Grid>
            <Grid item xs={12} md={2.3} />
            <Grid item xs={12} md={2}>
              <Typography align="left" sx={{ mt: { lg: 3.5, xs: 1 } }}>
                Category Description
              </Typography>
            </Grid>
            <Grid item xs={12} md={7.7}>
              <Box className="text-area-control-main">
                <TextareaAutosize
                  autoComplete="off"
                  sx={{ width: "300px !important" }}
                  minRows={0}
                  size="small"
                  className="form-control border-none text-area-control3 text-area-control-admin"
                  name="category_description"
                  type="text"
                  {...register("category_description", {
                    required: (
                      <TextAreaErrorMsg msg="Please enter Description" />
                    ),
                    minLength: {
                      value: minCategoryDescriptionLength,
                      message: (
                        <TextAreaErrorMsg
                          msg={`Minimum ${minCategoryDescriptionLength} Characters Allowed`}
                        />
                      ),
                    },
                    maxLength: {
                      value: categoryDescriptionLength,
                      message: (
                        <TextAreaErrorMsg
                          msg={`Maximum ${categoryDescriptionLength} Characters Allowed`}
                        />
                      ),
                    },
                    pattern: {
                      value: NO_WHITE_SPACE_BEGINNING_END,
                      message: (
                        <TextAreaErrorMsg msg="Whitespace is not allowed at beginning or end" />
                      ),
                    },
                  })}
                />
                <ErrorMessage errors={errors} name="category_description" />
              </Box>
            </Grid>
            <Grid item xs={12} md={2.3} />
            <Grid item xs={12} md={2}>
              <Typography align="left" sx={{ mt: 2 }}>
                Upload Thumbnail
              </Typography>
            </Grid>
            <Grid item xs={12} md={7.7}>
              <Box
                sx={{
                  width: { lg: "300px", xs: "100%" },
                  position: "relative",
                  mt: 1,
                }}
              >
                {image === "" && url == "" && (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        mx: { xs: 3 },
                      }}
                    >
                      <Box sx={{ width: { lg: "300px", xs: "100%" } }}>
                        <Button
                          component="label"
                          sx={{
                            borderRadius: 0,
                            textTransform: "none",
                            width: { lg: "300px", xs: "100%" },
                          }}
                          variant="contained"
                          onChange={handleImg}
                        >
                          Upload
                          <UploadIcon />
                          <input
                            name="files"
                            hidden
                            accept="image/*"
                            type="file"
                            {...register("files", {
                              required: <ErrorMsg msg="Please select Image" />,
                            })}
                          />
                        </Button>
                        <ErrorMessage errors={errors} name="files" />
                      </Box>
                    </Box>
                  </>
                )}
                {image === "" && url == "" ? (
                  ""
                ) : (
                  <>
                    <Box className="updateitem-img-box">
                      <Img
                        sx={{
                          my: 2,
                          height: "200px",
                          width: "100%",
                          maxWidth: "300px",
                          borderRadius: "0px",
                          position: "relative",
                          margin: "auto",
                        }}
                        src={url}
                        alt="no image"
                      />
                      <Button className="remove-btn-upadteitem">
                        <Tooltip title="Remove Images" placement="bottom">
                          <DisabledByDefaultIcon
                            className="remove-img-icon close-upadte-item"
                            onClick={removeImg}
                            fontSize="large"
                            sx={{ mt: 5 }}
                          />
                        </Tooltip>
                      </Button>
                    </Box>
                  </>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} md={1} />
          </Grid>
          <Typography align="center" variant="h4">
            <ContainedButton name="Update" type="submit" />
          </Typography>
        </Container>
      </form>
    </>
  );
};

export default UpdateCategory;
//#endregion
