import React, { useEffect, useState } from 'react'
import UserIndex from '../../UserIndex'

const { CatelogueItemsSaga } = UserIndex.UserSagaActions

const Catalogues = () => {
  const dispatch = UserIndex.useDispatch()
  const history = UserIndex.useHistory()
  const navigate = UserIndex.useHistory()

  const [items, setItems] = useState([])

  const CatelogueItemsData = UserIndex.useSelector((state) => {
    return state.Usersaga.CatelogueItemsData
  })

  const getListItem = async () => {
    dispatch(CatelogueItemsSaga( navigate))
  }

  useEffect(() => {
    getListItem()
  }, [])

  useState(() => {
    setItems(CatelogueItemsData)
  }, [CatelogueItemsData])

  return (
    <div>
      <UserIndex.Header />
      <UserIndex.Box style={{ backgroundColor: '#f6f6f6' }}>
        <UserIndex.Box className="catalogues-main-container">
          <UserIndex.Box>
            <UserIndex.Box className="title-faq-asked">
              <UserIndex.Typography
                sx={{ textAlign: 'left' }}
                className="frequently-text"
              >
                Catalogues
              </UserIndex.Typography>
            </UserIndex.Box>
            <UserIndex.Box sx={{ width: 1 }}>
              <UserIndex.Box
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                gap={{ xs: 3, sm: 5, md: 4, lg: 4 }}
                
              >
                {items.length
                  ? items.map((item, index) => {
                      return (
                        <>
                          <UserIndex.Box
                            gridColumn={{
                              xs: 'span 12',
                              sm: 'span 6',
                              md: 'span 3',
                              lg: 'span 3',
                            }}
                            sx={{cursor:"pointer"}}
                            onClick={() => {
                              history.push(`/user/cataloguesseller/${item._id}`)
                            }}
                          >
                            <UserIndex.Box className="product-box">
                              <UserIndex.Box className="product-img-box">
                                <img
                                  src={item.image}
                                  className="product-img"
                                ></img>
                              </UserIndex.Box>
                              <UserIndex.Box className="product-content">
                                <UserIndex.Box className="product-title">
                                  {item.item}
                                </UserIndex.Box>
                              </UserIndex.Box>
                            </UserIndex.Box>
                          </UserIndex.Box>
                        </>
                      )
                    })
                  : ''}
              </UserIndex.Box>
            </UserIndex.Box>
          </UserIndex.Box>
        </UserIndex.Box>
      </UserIndex.Box>
      <UserIndex.Footer />
    </div>
  )
}

export default Catalogues
