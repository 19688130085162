import React, { useState, useEffect } from 'react';
import { Box } from '@mui/system';
import {
  Container,
  Typography,
  TextField,
  Grid,
  Button,
  Tab,
} from '@mui/material';
import { TabList, TabPanel, TabContext } from '@mui/lab';
import BankDetails from '../../../../container/Admin/Pages/MasterAdminAccount/BankDetails';
import { Api } from '../../../../config/api';
import dataService from '../../../../config/dataService';
import MakingCharges from './MakingCharges';
import { toast } from 'react-toastify';
import Profile from './Profile';
import CustomProductRequestsNotification from './CustomProductRequestsNotification';
import TcsCharges from './TcsCharges';

import Loader from '../../../../component/customComponents/loader/Loader';
import AdminIndex from '../../AdminIndex';

const { bankDetails, getMakingCharges, getNotificationDetail } = AdminIndex.AdminSagaActions;
//#region ALL HOOKS AND VARIABALS
const ResetPassword = () => {
  let token = localStorage.getItem('auth');
  const [value, setValue] = useState('1');
  const dispatch = AdminIndex.useDispatch();
  const [getData, setGetData] = useState('');
  const [id, setId] = useState('');
  const [makingChargesId, setMakingChargesId] = useState('');
  const [tcsChargesId, setTcsChargesId] = useState('');
  const [customProductRequestNotificationId, setCustomProductRequestNotificationId] = useState('');
  //#endregion


  //#region ALL EVENT HANDLERS AND  FUCTIONS
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getBankDetails = async () => {
    let urlencoded = new URLSearchParams();
    dispatch(bankDetails(token,urlencoded))
    // await dataService
    // .post(Api.Admin.listBankDetails, urlencoded)
    // .then((res) => {
    //   setId(res.data.data[0]._id);
    // })
    // .catch((err) => toast.error(err?.message || 'Something went wrong'));
  };

  const loading = AdminIndex.useSelector((state) => {
    return state.AdminSaga.loading;
  });

  const bankDetail = AdminIndex.useSelector((state) => {
    return state.AdminSaga.bankDetail;
  });

  useEffect(() => {
    setId(bankDetail?.[0]?._id);
  }, [bankDetail]);

  const fetchMakingCharges = async () => {
    let urlencoded = new URLSearchParams();
    dispatch(getMakingCharges(token, urlencoded));
    // await dataService
    // .post(Api.Admin.listZavericaMakingCharges, urlencoded)
    // .then((res) => {
    //   setMakingChargesId(res.data.data.filter(val => val.hasOwnProperty('zaverica_making_charges'))?.[0]._id);
    //   setTcsChargesId(res.data.data.filter(val => val.hasOwnProperty('tcs'))?.[0]._id);
    // })
    // .catch((err) => toast.error(err?.message || 'Something went wrong'));
  };

  const makingChargeDetail = AdminIndex.useSelector((state) => {
    return state.AdminSaga.makingChargeDetail;
  });

  useEffect(() => {
    setMakingChargesId(makingChargeDetail?.filter(val => val.hasOwnProperty('zaverica_making_charges'))?.[0]?._id);
    setTcsChargesId(makingChargeDetail?.filter(val => val.hasOwnProperty('tcs'))?.[0]?._id);
  }, [makingChargeDetail]);


  const getCustomProductRequestNotification = async () => {
    let urlencoded = new URLSearchParams();
    dispatch(getNotificationDetail(token, urlencoded));
    // await dataService
    // .post(Api.Admin.listNotificationDetails, urlencoded)
    // .then((res) => {
    //   setCustomProductRequestNotificationId(res.data.data[0]._id);
    // })
    // .catch((err) => toast.error(err?.message || 'Something went wrong'));
  };

  const notificationDetail = AdminIndex.useSelector((state) => {
    return state.AdminSaga.notificationDetail;
  });

  useEffect(() => {
    setCustomProductRequestNotificationId(notificationDetail?notificationDetail[0]?._id:'');
  }, [notificationDetail]);

  useEffect(() => {
    getBankDetails();
    fetchMakingCharges();
    getCustomProductRequestNotification();
  }, []);

  const ResetPasswordText = () => {
    return (
      <>
        <Grid
          container
          spacing={4}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={6}>
            <Typography variant="h4">Reset Password</Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="standard-helperText"
              label="Old Password"
              defaultValue="***********"
              variant="standard"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="standard-helperText"
              label="New Password"
              defaultValue="***********"
              variant="standard"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="standard-helperText"
              label="Confirm Password"
              defaultValue="***********"
              variant="standard"
            />
          </Grid>
          <Grid item xs={6}>
            <Button variant="contained">Save</Button>
          </Grid>
          <Grid item xs={6}>
            <Button variant="text">Forgot Password?</Button>
          </Grid>
        </Grid>
      </>
    );
  };
  //#endregion

  //#region RETURN JSX
  return (
    <>{loading ? (
      <Loader />
    ) : (
      <Container maxWidth="false">
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={value}>
            <Box>
              <TabList
                TabIndicatorProps={{ sx: { display: 'none' } }}
                sx={{
                  '& .MuiTabs-flexContainer': {
                    flexWrap: 'wrap',
                  },
                  borderBottom: 1,
                  '& .Mui-selected ': {
                    bgcolor: '#c29958',
                    m: 2,
                    borderRadius: 1,
                    color: 'white !important',
                  },
                  '& .MuiTabs-indicator': {
                    height: 0,
                    color: 'black !important',
                  }
                }}
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab
                  label="profile"
                  value="1"
                />
                <Tab
                  label="sub-admins"
                  value="2"
                />
                {/* <Tab
                  sx={{
                    '.css-1e7b5m4-MuiButtonBase-root-MuiTab-root': {
                      bgcolor: 'red',
                    },
                  }}
                  label="Update system"
                  value="3"
                /> */}
                <Tab
                  label="Bank Details"
                  value="4"
                />
                <Tab
                  label="Making Charges %"
                  value="5"
                />
                <Tab
                  label="TCS %"
                  value="6"
                />
                <Tab sx={{maxWidth: '100%'}}
                  label="Custom Product Requests Notification"
                  value="7"
                />
              </TabList>
            </Box>
            <TabPanel value="1">
              <Profile />
            </TabPanel>
            <TabPanel value="2">sub-admins</TabPanel>
            {/* <TabPanel value="3">system</TabPanel> */}
            <TabPanel value="4">
              <BankDetails id={id} />
            </TabPanel>
            <TabPanel value="5">
              <MakingCharges id={makingChargesId} />
            </TabPanel>
            <TabPanel value="6">
              <TcsCharges id={tcsChargesId} />
            </TabPanel>
            <TabPanel value="7">
              <CustomProductRequestsNotification id={customProductRequestNotificationId} />
            </TabPanel>
          </TabContext>
        </Box>
      </Container>
    )}
    </>
  );
};
export default ResetPassword;
//#endregion
