import React, { useEffect, useState } from "react";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import {
  Typography,
  Button,
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Container,
  InputBase,
  TablePagination,
  Tooltip,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { styled, alpha } from "@mui/material/styles";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Api } from "../../../../config/api";
import dataService from "../../../../config/dataService";
import ContainedButton from "../../../../component/customComponents/buttons/ContainedButton";
import NoDataFound from "../../../../component/customComponents/noDataFound/NoDataFound";
import Png from "../../../../assets/png";
import { toast } from "react-toastify";
import Loader from "../../../../component/customComponents/loader/Loader";
import {
  commaSeparated,
  price,
} from "../../../../component/customComponents/commonFunctions/CommonFunctions";
import moment from "moment";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { RupeeSymbol } from "../../../../component/customComponents/rupeeSymbol/RupeeSymbol";
import Mui from "../../../../component/customComponents/mui/Mui";
//#region ALL HOOKS AND VARIABALS

import AdminIndex from "../../AdminIndex";

const columns = [
  { id: "date", label: "Date" },
  { id: "seller", label: "Seller", align: "center" },
  { id: "productid	", label: "Product Id	", align: "center" },
  { id: "category", label: "Category", align: "center" },
  { id: "product", label: "Product", align: "center" },
  {
    id: "invoicetocustomer",
    label: <RupeeSymbol heading="Invoice to Customer" />,
    align: "center",
  },
  {
    id: "invoicetoseller",
    label: <RupeeSymbol heading="Invoice to Seller" />,
    align: "center",
  },
  { id: "action", label: "" },
];

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "25px",
  backgroundColor: alpha(theme.palette.common.white, 0.25),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "150px",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "340px",
  },
  border: "1px solid black",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const { getAllProduct } = AdminIndex.AdminSagaActions;

export default function UploadedProducts() {
  const history = useHistory();
  let token = localStorage.getItem("auth");
  const dispatch = AdminIndex.useDispatch();
  // const [loading, setLoading] = useState(false);
  const [flag, setFlag] = useState(false);
  const [productData, setProductData] = useState([]);
  // const [tempProductData, setTempProductData] = useState([]);
  const [seller, setSeller] = useState({});
  const [array, setArray] = useState([]);
  const [tempArray, setTempArray] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    getProductData();
    // getAdminDetails();
    setInterval(() => {
      setFlag(true);
    }, 500);
  }, []);
  //#endregion

  //#region ALL EVENT HANDLERS AND  FUCTIONS
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getProductData = async () => {
    dispatch(getAllProduct(token));
    // setLoading(true);
    // const urlencoded = new URLSearchParams();
    // await dataService
    //   .get(Api.Admin.getAllProduct, urlencoded)
    //   .then((res) => {
    //     setProductData(res.data.data);
    //     setTempProductData(res.data.data);
    //   })
    //   .catch((err) => toast.error(err?.message || 'Something went wrong'))
    //   .finally(() => {
    //     setLoading(false);
    //   });
  };

  const loading = AdminIndex.useSelector((state) => {
    return state.AdminSaga.loading;
  });

  const uploadedProduct = AdminIndex.useSelector((state) => {
    return state.AdminSaga.allProduct;
  });

  useEffect(() => {
    setProductData(uploadedProduct);
  }, [uploadedProduct]);

  const getAdminDetails = async () => {
    try {
      const adminData = await dataService.get(Api.Admin.getAdmin, {
        headers: {
          auth: token,
        },
      });
      setSeller(adminData.data.data);
    } catch (err) {
      toast.error(err?.message || "Something went wrong");
    }
  };

  const filterPendingProducts = () => {
    var filtered = [];
    for (var arr in productData) {
      if (productData[arr]?.status == "1") {
        filtered.push(productData[arr]);
      }
    }
    setArray(filtered);
    setTempArray(filtered);
  };

  const filter = (e) => {
    let data = e.target.value;
    let value = data.trim().toLowerCase();
    let filterData = tempArray.filter(
      (data) =>
        data?.name?.toLowerCase().includes(value) ||
        data?.company_name?.toLowerCase().includes(value) ||
        data?.product_id?.toLowerCase().includes(value) ||
        data?.materialIdArray
          ?.map((val) =>
            val.material_id?.material?.toLowerCase().includes(value)
          )
          .includes(true) ||
        data?.item_id?.item?.toLowerCase().includes(value)
    );
    setArray(filterData);
  };

  useEffect(() => {
    filterPendingProducts();
  }, [productData]);
  //#endregion

  //#region RETURN JSX
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Container sx={{ mt: 2, mb: 5 }} maxWidth="false">
          <Typography variant="h4" align="center" sx={{ fontWeight: "bold" }}>
            Uploaded Products
          </Typography>
          <br />
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Box>
              <Mui.GrayTooltip
                title="Search by (Seller / Company Name / Product Id / Product / Category)"
                placement="bottom-start"
              >
                <Mui.SearchMaxWidth>
                  <Mui.SearchIconWrapper>
                    <SearchIcon />
                  </Mui.SearchIconWrapper>
                  <Mui.StyledInputBase
                    onChange={filter}
                    placeholder="Search"
                    inputProps={{ "aria-label": "search" }}
                    sx={{
                      width: "100%",
                      "& .MuiInputBase-input": {
                        width: "100%",
                        "&:focus, &.MuiInputBase-input": {
                          width: "100%",
                        },
                      },
                    }}
                  />
                </Mui.SearchMaxWidth>
              </Mui.GrayTooltip>
            </Box>
            {/* <Box sx={{ display: 'flex', alignSelf: 'center' }}>
              <Button
                variant="contained"
                sx={{ borderRadius: 0, textTransform: 'none', ml: 2 }}
                onClick={() => {
                  history.push('/admin/productmanagement/addproducts');
                }}
              >
                Add Product
              </Button>
            </Box> */}
          </Box>
          <br />
          <TableContainer
            elevation={0}
            sx={{
              maxWidth: {
                xs: "365px",
                sm: "600px",
                md: "100%",
                lg: "100%",
              },
              "&::-webkit-scrollbar": {
                width: 10,
              },
              "&::-webkit-scrollbar": {
                height: "8px",
                width: "12px",
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "#fff",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "rgba(224, 224, 224, 1)",
                borderRadius: "30px",
                width: "20px",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                background: "#B0B0B0",
              },
            }}
            component={Paper}
          >
            <Table
              sx={{
                width: {
                  xs: "max-content",
                  sm: "max-content",
                  md: "100%",
                  lg: "100%",
                },
                // '& .MuiTableCell-root': {},
              }}
              size="small"
            >
              <TableHead>
                <TableRow sx={{ bgcolor: "black", whiteSpace: "nowrap" }}>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth, bgcolor: "red" }}
                      className="pagination-table-header-row"
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {array.length
                  ? array
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => {
                        return (
                          <StyledTableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row._id}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                                // whiteSpace: 'nowrap',
                              },
                            }}
                          >
                            <Mui.StyledTableCell
                              align="left"
                              sx={{ border: "0px" }}
                            >
                              {/* {new Date(row.updatedAt).toLocaleDateString()} */}
                              {moment(row.updatedAt).format("DD/MM/YYYY")}
                            </Mui.StyledTableCell>
                            <Mui.StyledTableCell
                              align="center"
                              sx={{ border: "0px" }}
                            >
                              {row.name || row.company_name}
                            </Mui.StyledTableCell>
                            <Mui.StyledTableCell
                              align="center"
                              sx={{ border: "0px" }}
                            >
                              {row.product_id}
                            </Mui.StyledTableCell>
                            <Mui.GrayTooltip1
                              title={String(
                                row?.materialIdArray
                                  ?.map((val) => val.material_id?.material)
                                  .sort()
                              ).replace(/,/g, ", ")}
                              placement="bottom"
                            >
                              <Mui.StyledTableCell
                                align="center"
                                sx={{
                                  display: "block",
                                  width: "90px",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden !important",
                                  textOverflow: "ellipsis",
                                  p: "14px",
                                  border: "0px",
                                }}
                              >
                                {row?.materialIdArray
                                  ?.map((val) => val.material_id?.material)
                                  .sort()}
                              </Mui.StyledTableCell>
                            </Mui.GrayTooltip1>
                            <Mui.StyledTableCell
                              align="center"
                              sx={{ border: "0px" }}
                            >
                              {row?.item_id?.item}
                            </Mui.StyledTableCell>
                            <Mui.StyledTableCell
                              align="center"
                              sx={{ border: "0px" }}
                            >
                              {price(row?.total_payable_amount)}
                            </Mui.StyledTableCell>
                            <Mui.StyledTableCell
                              align="center"
                              sx={{ border: "0px" }}
                            >
                              {price(row?.admin_to_seller)}
                              {/* <span>
                                <img
                                  src={Png.rupee}
                                  className="rupee-icon"
                                ></img>
                              </span> */}
                            </Mui.StyledTableCell>
                            {/* <StyledTableCell align="right">
                            {row.value}
                          </StyledTableCell> */}
                            <Mui.StyledTableCell
                              align="center"
                              sx={{ border: "0px" }}
                            >
                              <ContainedButton
                                name="View"
                                onClick={() =>
                                  history.push(
                                    `/admin/productmanagement/pendingproducts/viewuploadedproducts/${row._id}`
                                  )
                                }
                              />
                            </Mui.StyledTableCell>
                          </StyledTableRow>
                        );
                      })
                  : ""}
              </TableBody>
            </Table>
            {!array.length && flag ? <NoDataFound /> : ""}
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            component="div"
            count={array.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Container>
      )}
    </>
  );
}
//#endregion
