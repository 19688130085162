import React, {useEffect} from 'react';
import { Button, Tooltip  } from '@mui/material';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';

const GoToTop = () => {
  return (
    <div>
     <Tooltip title="Go to top" placement="bottom">
      <Button
        sx={{
          backgroundColor: 'transparent',
          '&:hover': {
            backgroundColor: 'transparent',
          },
          display:
            window.innerWidth > document.documentElement.clientWidth
              ? 'block'
              : 'none',
        }}
        onClick={() => window.scrollTo(0, 0)}
      >
        <ArrowCircleUpIcon fontSize="large" className='go-to-top-icon' />
      </Button>
    </Tooltip>
    </div>
  );
};

export const ScrollUp = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null; 
}

export default GoToTop;

