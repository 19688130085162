//#region ALL IMPORTS
import React, { useState, useEffect } from 'react';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import {
  Typography,
  Button,
  Box,
  styled,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Container,
  TablePagination,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useHistory } from 'react-router-dom';
import { Api } from '../../../../config/api';
import dataService from '../../../../config/dataService';
import NoDataFound from '../../../../component/customComponents/noDataFound/NoDataFound';
import { toast, ToastContainer } from 'react-toastify';
import Loader from '../../../../component/customComponents/loader/Loader';
//#endregion

import AdminIndex from '../../AdminIndex';
//#region ALL HOOKS AND VARIABALS
const columns = [
  { id: 'index', label: 'Sr No' },
  { id: 'material', label: 'Material' },
  {
    id: 'action',
    label: 'Action',
    align: 'center',
  },
];

const { getMaterialList, deleteMaterial } = AdminIndex.AdminSagaActions;
export default function MaterialManagement() {
  const history = useHistory();
  let token = localStorage.getItem('auth');
  const dispatch = AdminIndex.useDispatch();
  // const [loading, setLoading] = useState(false);
  const [flag, setFlag] = useState(false);
  const [material, setMaterial] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    getMaterialData();
    setInterval(() => {
      setFlag(true);
    }, 500);
  }, []);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  //#endregion

  //#region ALL EVENT HANDLERS AND  FUCTIONS
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getMaterialData = async () => {
    let urlencoded = new URLSearchParams();
    dispatch(getMaterialList(token,urlencoded));
    // await dataService
    //   .post(Api.Admin.listMaterial, urlencoded)
    //   .then((res) => {
    //     setMaterial(res.data.data);
    //   })
    //   .catch((err) => toast.error(err?.message || 'Something went wrong'))
    //   .finally(() => {
    //     setLoading(false);
    //   });
  };

  const loading = AdminIndex.useSelector((state) => {
    return state.AdminSaga.loading;
  });

  const materialData = AdminIndex.useSelector((state) => {
    return state.AdminSaga.materialData;
  });

  useEffect(() => {
    setMaterial(materialData);
  }, [materialData]);

  const deleteSingleMaterial = async (id) => {
    let urlencoded = new URLSearchParams();
    urlencoded.append('id', id);

    try {
      // const deleteMaterial = await dataService.post(
      //   Api.Admin.deleteMaterial,
      //   urlencoded
      // );
      // if (deleteMaterial.data.status == 1) {
      //   toast.success('Material deleted successfully');
      // }
      dispatch(deleteMaterial(token, urlencoded));
    } catch (err) {
      toast.error(err?.message || 'Something went wrong');
    }
    getMaterialData();
  };
  //#endregion

  //#region RETURN JSX
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Container sx={{ mt: 2 }} maxWidth="false">
          <Typography variant="h4" align="center" sx={{ fontWeight: 'bold' }}>
            Material Management
          </Typography>
          <br />
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ flexGrow: 1 }} />
            <Button
              onClick={() => {
                history.push('/admin/materialmanagement/addmaterial');
              }}
              variant="contained"
              sx={{
                borderRadius: '6px',
                margin: '0px 15px 0px 0px',
                height: '28px',
                background: '#3198F9 0% 0% no-repeat padding-box',
                borderRadius: 0,
                px: 5,
                py: 3,
                textTransform: 'none',
              }}
            >
              Add Material
            </Button>
          </Box>
          <br />
          <TableContainer
            elevation={0}
            sx={{
              maxWidth: {
                xs: '365px',
                sm: '600px',
                md: '100%',
                lg: '100%',
              },
              '&::-webkit-scrollbar': {
                width: 10,
              },
              '&::-webkit-scrollbar': {
                height: '8px',
                width: '12px',
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: '#fff',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(224, 224, 224, 1)',
                borderRadius: '30px',
                width: '20px',
              },
              '&::-webkit-scrollbar-thumb:hover': {
                background: '#B0B0B0',
              },
            }}
            component={Paper}
          >
            <Table
              sx={{
                width: {
                  xs: 'max-content',
                  sm: 'max-content',
                  md: '100%',
                  lg: '100%',
                },
              }}
              size="small"
            >
              <TableHead>
                <TableRow sx={{ bgcolor: 'black' }}>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth, bgcolor: 'red' }}
                      className="pagination-table-header-row"
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {material.length
                  ? material
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        {
                          /* <StyledTableRow
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <StyledTableCell align="left">
                        {index + 1}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.material}
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        sx={{ whiteSpace: 'nowrap' }}
                      >
                        <Button>
                          <EditIcon
                            sx={{ m: 1 }}
                            onClick={() => {
                              history.push(
                                `/admin/materialmanagement/updatematerial/${row._id}`
                              );
                            }}
                          />
                        </Button>
                        <Button>
                          <DeleteIcon
                            sx={{ m: 1, color: 'red' }}
                            onClick={() => {
                              if (
                                window.confirm(
                                  'Are you sure you want to delete this Material?'
                                )
                              ) {
                                deleteMaterial(row._id);
                              }
                            }}
                          />
                        </Button>
                      </StyledTableCell>
                    </StyledTableRow> */
                        }
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row._id}
                          >
                            <StyledTableCell align="left">
                              {index + 1}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row.material}
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              sx={{ whiteSpace: 'nowrap' }}
                            >
                              <Button>
                                <EditIcon
                                  sx={{ m: 1 }}
                                  onClick={() => {
                                    history.push(
                                      `/admin/materialmanagement/updatematerial/${row._id}`
                                    );
                                  }}
                                />
                              </Button>
                              <Button>
                                <DeleteIcon
                                  sx={{ m: 1, color: 'red' }}
                                  onClick={() => {
                                    if (
                                      window.confirm(
                                        'Are you sure you want to delete this Material?'
                                      )
                                    ) {
                                      deleteSingleMaterial(row._id);
                                    }
                                  }}
                                />
                              </Button>
                            </StyledTableCell>
                          </TableRow>
                        );
                      })
                  : ''}
              </TableBody>
            </Table>
            {!material.length && flag ? <NoDataFound /> : ''}
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            component="div"
            count={material.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Container>
      )}
    </>
  );
}
//#endregion
