import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { Button, Typography, CircularProgress } from "@mui/material";
import svg from "../../../assets/svg";
import png from "../../../assets/png";
import { Link } from "react-router-dom";
// import {Link} from "@mui/material";
import Modal from "@mui/material/Modal";
import Drawer, { drawerClasses } from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { useHistory } from "react-router-dom";
import { styled } from "@mui/system";
import Login from "../../../container/user/auth/login/Login";
import SignUp from "../../../container/user/auth/signUp/SignUp";
import PlaceOrder from "../../../container/user/pages/placeOrder/PlaceOrder";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import ListSubheader from "@mui/material/ListSubheader";
import PopperUnstyled from "@mui/base/PopperUnstyled";
import Searchbar from "./Searchbar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector, useDispatch } from "react-redux";
import AllJewellery from "../header/AllJewellery";
import UserSagaActions from "../../../redux/Usersaga/action";
import OtpVerify from "../../../container/user/pages/membershipform/otpVerifyModal";
// import ForgotPassword from "../../../container/user/auth/forgotPassword/UserForgotPassword";
import UserForgotPassword from "../../../container/user/auth/forgotPassword/UserForgotPassword";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const { logoutSaga, updateModalSaga, stateEmpty, state } = UserSagaActions;

const StyledProgress = styled(CircularProgress)(() => ({
  position: "absolute",
  top: "6px",
  left: "25px",
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 631,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const Header = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const navigate = useHistory();
  const loding = useSelector((state) => {
    return state.Usersaga.loading;
  });

  const count = useSelector((state) => {
    return state.Usersaga.CartCount;
  });

  const Cartdata = useSelector((state) => {
    return state.Usersaga.Cartdata;
  });

  const AllProductdata = useSelector((state) => {
    return state.Usersaga.AllProductdata;
  });

  const ListProductPricingData = useSelector((state) => {
    return state.Usersaga.ListProductPricingData;
  });

  const ListProductDescriptionData = useSelector((state) => {
    return state.Usersaga.ListProductDescriptionData;
  });

  const ListProductPurityData = useSelector((state) => {
    return state.Usersaga.ListProductPurityData;
  });

  const token = useSelector((state) => {
    return state.Usersaga.Token;
  });

  const [age, setAge] = useState("");
  const [user, setUser] = useState({
    email: "",
    password: "",
  });

  const [registration, setRegistration] = useState({
    email: "",
    password: "",
  });

  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const handleChangeUser = (e) => {
    const { name, value } = e.target;
    setUser({
      ...user,
      [name]: value,
    });
  };

  const handle = () => {
    dispatch(logoutSaga());
    history.push("/user/");
  };

  const [openLogin, setOpenLogin] = useState(false);
  const [openSignup, setOpenSignup] = useState(false);
  const [openPlaceOrder, setOpenPlaceOrder] = useState(false);
  const [openOtpForm, setOpenOtpForm] = useState(false);
  const [forgotPasswordModal, setForgotPasswordModal] = useState(false);
  const [userResendOtpEmail, setUserResendOtpEmail] = useState("");

  const [countdata, SetCount] = useState(0);
  const handleOpenLogin = () => {
    setOpenLogin(true)
    dispatch(dispatch(stateEmpty()))

  };
  const handleCloseLogin = () => setOpenLogin(false);

  const handleOpenSignup = () => {
    setOpenOtpForm(false);
    setOpenSignup(true);
    dispatch(dispatch(stateEmpty()))
  };
  const handleCloseSignup = () => setOpenSignup(false);

  const handleOpenPlaceOrder = () => setOpenPlaceOrder(true);
  const handleClosePlaceOrder = () => setOpenPlaceOrder(false);

  const handleOpenOtpForm = () => setOpenOtpForm(true);
  const handleCloseOtpForm = () => {
    setOpenOtpForm(false);
    dispatch(updateModalSaga(navigate));
  };

  const handleOpenForgotPasswordModal = () => setForgotPasswordModal(true);
  const handleCloseForgotPasswordModal = () => setForgotPasswordModal(false);

  const [forgotData, setForgotData] = useState("");
  const [backBtnType, setBackBtnType] = useState(false);

  const [loginData, setLoginData] = useState("");

  const Userdata = useSelector((state) => {
    return state.Usersaga.Userdata;
  });

  useEffect(() => {
    setLoginData(Userdata);
  }, [Userdata]);

  const showToastMessage = () => {
    toast.error("You need to login to request custom product");
  };

  const requestCustomeProduct = () => {
    if (token === "") {
      showToastMessage();
    } else {
      history.push("/user/requestingproduct");
    }
  };

  // start desktop drawerClasses
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const list = (anchor) => (
    <Box className="main-side-menu-set">
      <Box className="side-menu">
        <Box
          className="side-menu-main"
          role="presentation"
          onClick={toggleDrawer(anchor, false)}
          onKeyDown={toggleDrawer(anchor, false)}
        >
          <Box className="side-menu-user-box">
            <Box className="side-menu-user">
              <Box
                className="side-user-name-box"
                onClick={() => history.push("/user/account-details")}
              >
                <Box className="user-round">
                  <img
                    src={png.userprofile}
                    className="side-menu-user-img"
                  ></img>
                </Box>
                <Typography
                  className="username-text"
                  variant="h2"
                  component="h2"
                >
                  {loginData?.[0]?.name}
                </Typography>
              </Box>
            </Box>
            <Box className="side-menu-user">
              <img src={svg.closeimg} className="side-menu-close-img"></img>
            </Box>
          </Box>
          <List className="side-menu-list">
            <ListItem disablePadding className="list-item-menu">
              <ListItemButton
                onClick={() => history.push("/user/myorder")}
                // className="list-item-menu-btn"
                className={`list-item-menu-btn ${location.pathname == "/user/myorder" ? "active" : ""
                  }`}
              >
                <img src={svg.nextarrow} className="next-arrow-img"></img>
                <ListItemText
                  primary={"My Orders"}
                  className="side-list-item-text"
                />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding className="list-item-menu inner-menu-set">
              <ListItemButton
                onClick={() => history.push("/user/myproduct")}
                // className="list-item-menu-btn"
                className={`list-item-menu-btn ${location.pathname == "/user/myproduct" ||
                    location.pathname == "/user/customeproduct" ||
                    location.pathname == "/user/mypreciousstone"
                    ? "active"
                    : ""
                  }`}
              >
                <img src={svg.nextarrow} className="next-arrow-img"></img>
                <ListItemText
                  primary={"My Products"}
                  className="side-list-item-text"
                />
              </ListItemButton>
              <Box className="ineer-menu">
                <List className="side-menu-list2">
                  <ListItem disablePadding className="list-item-menu">
                    <ListItemButton
                      onClick={() => history.push("/user/customeproduct")}
                      // className="list-item-menu-btn"
                      className={`list-item-menu-btn ${location.pathname == "/user/customeproduct"
                          ? "active"
                          : ""
                        }`}
                    >
                      <ListItemText
                        primary={"Custom Product"}
                        className="side-list-item-text"
                      />
                    </ListItemButton>
                  </ListItem>

                  <ListItem disablePadding className="list-item-menu">
                    <ListItemButton
                      onClick={() => history.push("/user/mypreciousstone")}
                      // className="list-item-menu-btn"
                      className={`list-item-menu-btn ${location.pathname == "/user/mypreciousstone"
                          ? "active"
                          : ""
                        }`}
                    >
                      <ListItemText
                        primary={"Precious Stone"}
                        className="side-list-item-text"
                      />
                    </ListItemButton>
                  </ListItem>
                </List>
              </Box>
            </ListItem>
            <ListItem disablePadding className="list-item-menu">
              <ListItemButton
                // onClick={() => history.push("/user/myproduct")}
                // className="list-item-menu-btn"
                className={`list-item-menu-btn ${location.pathname == "/user/my-profile" ? "active" : ""
                  }`}
              >
                <img src={svg.nextarrow} className="next-arrow-img"></img>
                <ListItemText
                  onClick={() => history.push("/user/my-profile")}
                  primary={"My Profile"}
                  className="side-list-item-text"
                />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding className="list-item-menu">
              <ListItemButton
                // className="list-item-menu-btn"
                className={`list-item-menu-btn ${location.pathname == "/user/customerservices" ? "active" : ""
                  }`}
              >
                <img src={svg.nextarrow} className="next-arrow-img"></img>
                <ListItemText
                  onClick={() => history.push("/user/customerservices")}
                  primary={"Customer Service"}
                  className="side-list-item-text"
                />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding className="list-item-menu">
              <ListItemButton
                onClick={() => history.push("/user/returnOrder")}
                // className="list-item-menu-btn"
                className={`list-item-menu-btn ${location.pathname == "/user/returnOrder" ? "active" : ""
                  }`}
              >
                <img src={svg.nextarrow} className="next-arrow-img"></img>
                <ListItemText
                  primary={"My Return Order"}
                  className="side-list-item-text"
                />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </Box>
    </Box>
  );

  //  desktop drawerClasses

  // start mobile menu drawerClasses

  const [drawer, setDrawer] = useState([
    {
      top: false,
      left: false,
      bottom: false,
      right: false,
    },
  ]);

  const toggleDrawer1 = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawer({ ...drawer, [anchor]: open });
  };

  // end mobile menu drawerClasses

  // open sub menu in mobile

  const [openAlljewellery, setOpenAlljewellery] = useState(false);

  const handleClickAlljewellery = () => {
    setOpenAlljewellery(!openAlljewellery);
  };

  const [openMensjewellry, setOpenMensjewellry] = useState(false);

  const handleClickMensjewellry = () => {
    setOpenMensjewellry(!openMensjewellry);
  };
  const [openGoldjewellery, setOpenGoldjewellery] = useState(false);

  const handleClickGoldjewellery = () => {
    setOpenGoldjewellery(!openGoldjewellery);
  };

  const [openPreciousStone, setOpenPreciousStone] = useState(false);

  const handleClickPreciousStone = () => {
    setOpenPreciousStone(!openPreciousStone);
  };

  const [openWomesjewellery, setOpenWomesjewellery] = useState(false);

  const handleClickWomesjewellery = () => {
    setOpenWomesjewellery(!openWomesjewellery);
  };

  const [openKundanjewellry, setOpenKundanjewellry] = useState(false);

  const handleClickKundanjewellry = () => {
    setOpenKundanjewellry(!openKundanjewellry);
  };

  const [openDiamondJewellery, setOpenDiamondJewellery] = useState(false);

  const handleClickDiamondJewellery = () => {
    setOpenDiamondJewellery(!openDiamondJewellery);
  };

  const [openUseWebsite, setOpenUseWebsite] = useState(false);

  const handleClickUseWebsite = () => {
    setOpenUseWebsite(!openDiamondJewellery);
  };

  const [openBlog, setOpenBlog] = useState(false);

  const handleClickBlog = () => {
    setOpenBlog(!openBlog);
  };

  const [openGuideLines, setOpenGuideLines] = useState(false);

  const handleClickGuideLines = () => {
    setOpenGuideLines(!openGuideLines);
  };

  const [openProductDimensions, setOpenProductDimensions] = useState(false);

  const handleClickProductDimensions = () => {
    setOpenProductDimensions(!openProductDimensions);
  };

  const [openPricing, setOpenPricing] = useState(false);

  const handleClickPricing = () => {
    setOpenPricing(!openPricing);
  };

  const [openFaqs, setOpenFaqs] = useState(false);

  const handleClickFaqs = () => {
    setOpenFaqs(!openFaqs);
  };

  const [openCustomerService, setOpenCustomerService] = useState(false);

  const handleClickCustomerService = () => {
    setOpenCustomerService(!openCustomerService);
  };

  const [openPurity, setOpenPurity] = useState(false);

  const handleClickPurity = () => {
    setOpenPurity(!openPurity);
  };

  const StyledPopperDiv = styled("div")(
    ({ theme }) => `
  `
  );

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  useEffect(() => {
    SetCount(count);
  }, [count]);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;
  // close sub menu in mobile

  const notify = () => toast.error("Please login first to view/buy product");

  return (
    <div>
      {/* start mobile menu */}
      <Box className="mobile-menu-main">
        <Box className="container">
          <Box className="mobile-content-header">
            <Box className="mobile-logo-box">
              <img src={png.footerlogo} className="mobile-logo-img"></img>
            </Box>
            <Box className="mobile-search-box">
              <Button
                className="mobile-search-btn"
                aria-describedby={id}
                type="button"
                onClick={handleClick}
              >
                <img src={png.searchgrey} className="search-grey-img"></img>
              </Button>
            </Box>
            <PopperUnstyled
              id={id}
              open={open}
              anchorEl={anchorEl}
              className="mobile-search-popup"
            >
              <Box className="header-mobile-search-main">
                <Searchbar />
              </Box>
            </PopperUnstyled>
            <Box className="header-tablet-search-main">
              <Searchbar />
            </Box>
            <Box className="header-right-main">
              <Box className="header-user-info-box">
                <Box className="header-link-box">
                  {token && token != "" ? (
                    <>
                      <Box className="header-user-info header-vertical-line mobile-modal">
                        <Link className="header-link" onClick={handleOpenLogin}>
                          <Typography className="header-link-text">
                            Sign In
                          </Typography>
                        </Link>
                      </Box>
                      <Box className="header-user-info mobile-modal">
                        <Link
                          className="header-link"
                          onClick={handleOpenSignup}
                        >
                          <Typography className="header-link-text">
                            Sign Up
                          </Typography>
                        </Link>
                      </Box>
                    </>
                  ) : (
                    ""
                  )}
                </Box>
                <Box className="header-user-info">
                  <Link className="header-link-text">
                    <Box className="header-cart-bg">
                      <img
                        src={png.whitecart}
                        className="header-cart-img"
                      ></img>
                    </Box>
                  </Link>
                </Box>
                <Box className="header-user-info">
                  <Link className="header-link-text">
                    <Box className="header-user-bg">
                      <img
                        src={png.headeruser}
                        className="header-user-img"
                      ></img>
                    </Box>
                  </Link>
                </Box>
              </Box>
            </Box>
            <Box className="mobile-menu-drawer">
              {["left"].map((anchor) => (
                <React.Fragment key={anchor}>
                  <Button
                    className="mobile-nav-btn"
                    onClick={toggleDrawer1(anchor, true)}
                  >
                    <img
                      className="burger-menu"
                      src={png.mobilemenu}
                      alt="burger-btn"
                    ></img>
                  </Button>
                  <SwipeableDrawer
                    anchor={anchor}
                    open={drawer[anchor]}
                    onClose={toggleDrawer1(anchor, false)}
                    onOpen={toggleDrawer1(anchor, true)}
                    className="mobile-swipebale-drawer"
                  >
                    <List
                      sx={{ width: "100%", bgcolor: "background.paper" }}
                      component="nav"
                      className="mobile-nav-ul"
                      aria-labelledby="nested-list-subheader"
                      subheader={
                        <ListSubheader
                          component="div"
                          id="nested-list-subheader"
                          className="list-sub-header"
                        ></ListSubheader>
                      }
                    >
                      <ListItem disablePadding className="mobile-nav-items">
                        <Link to="#">
                          <ListItemButton className="mobile-nav-button">
                            About
                          </ListItemButton>
                        </Link>
                      </ListItem>

                      <ListItem disablePadding className="mobile-nav-items">
                        <Link>
                          <ListItemButton
                            onClick={handleClickAlljewellery}
                            className="mobile-nav-button"
                          >
                            All Jewellery
                            {openAlljewellery ? <ExpandMore /> : <ExpandLess />}
                          </ListItemButton>
                        </Link>
                      </ListItem>

                      <Box className="mobile-sub-menu-main">
                        <Collapse
                          in={openAlljewellery}
                          timeout="auto"
                          unmountOnExit
                          className="mobile-collapse-menu"
                        >
                          <List
                            component="div"
                            disablePadding
                            className="mobile-inner-nav-ul"
                          >
                            <ListItemButton className="mobile-inner-nav-button">
                              <ListItem
                                disablePadding
                                className="mobile-inner-nav-items"
                              >
                                <Link to={"#"}>
                                  <ListItemButton
                                    onClick={handleClickMensjewellry}
                                    className="mobile-inner-nav-button"
                                  >
                                    Men's Jewellery
                                    {openMensjewellry ? (
                                      <ExpandLess />
                                    ) : (
                                      <ExpandMore />
                                    )}
                                  </ListItemButton>
                                </Link>
                              </ListItem>
                            </ListItemButton>
                          </List>
                        </Collapse>
                        <Collapse
                          in={openMensjewellry}
                          timeout="auto"
                          unmountOnExit
                          className="mobile-inner-collapse-menu"
                        >
                          <List
                            component="div"
                            disablePadding
                            className="mobile-sub-menu-list"
                          >
                            <ListItem className="mobile-nav-sub-item">
                              <Link to="#">
                                <ListItemText className="mobile-nav-sub-text">
                                  Bracelets
                                </ListItemText>
                              </Link>
                            </ListItem>
                            <ListItem className="mobile-nav-sub-item">
                              <Link to="#">
                                <ListItemText className="mobile-nav-sub-text">
                                  Kadas
                                </ListItemText>
                              </Link>
                            </ListItem>
                            <ListItem className="mobile-nav-sub-item">
                              <Link to="#">
                                <ListItemText className="mobile-nav-sub-text">
                                  Rings
                                </ListItemText>
                              </Link>
                            </ListItem>
                            <ListItem className="mobile-nav-sub-item">
                              <Link to="#">
                                <ListItemText className="mobile-nav-sub-text">
                                  Studs
                                </ListItemText>
                              </Link>
                            </ListItem>
                            <ListItem className="mobile-nav-sub-item">
                              <Link to="#">
                                <ListItemText className="mobile-nav-sub-text">
                                  Chains
                                </ListItemText>
                              </Link>
                            </ListItem>
                            <ListItem className="mobile-nav-sub-item">
                              <Link to="#">
                                <ListItemText className="mobile-nav-sub-text">
                                  Pendants
                                </ListItemText>
                              </Link>
                            </ListItem>
                            <ListItem className="mobile-nav-sub-item">
                              <Link to="#">
                                <ListItemText className="mobile-nav-sub-text">
                                  Cufflinks
                                </ListItemText>
                              </Link>
                            </ListItem>
                          </List>
                        </Collapse>
                      </Box>
                      <Box className="mobile-sub-menu-main">
                        <Collapse
                          in={openAlljewellery}
                          timeout="auto"
                          unmountOnExit
                          className="mobile-collapse-menu"
                        >
                          <List
                            component="div"
                            disablePadding
                            className="mobile-inner-nav-ul"
                          >
                            <ListItemButton className="mobile-inner-nav-button">
                              <ListItem
                                disablePadding
                                className="mobile-inner-nav-items"
                              >
                                <Link to={"#"}>
                                  <ListItemButton
                                    onClick={handleClickGoldjewellery}
                                    className="mobile-inner-nav-button"
                                  >
                                    Gold Jewellery
                                    {openGoldjewellery ? (
                                      <ExpandLess />
                                    ) : (
                                      <ExpandMore />
                                    )}
                                  </ListItemButton>
                                </Link>
                              </ListItem>
                            </ListItemButton>
                          </List>
                        </Collapse>
                        <Collapse
                          in={openGoldjewellery}
                          timeout="auto"
                          unmountOnExit
                          className="mobile-inner-collapse-menu"
                        >
                          <List
                            component="div"
                            disablePadding
                            className="mobile-sub-menu-list"
                          >
                            <ListItem className="mobile-nav-sub-item">
                              <Link to="#">
                                <ListItemText className="mobile-nav-sub-text">
                                  Bracelets
                                </ListItemText>
                              </Link>
                            </ListItem>
                            <ListItem className="mobile-nav-sub-item">
                              <Link to="#">
                                <ListItemText className="mobile-nav-sub-text">
                                  Bangles
                                </ListItemText>
                              </Link>
                            </ListItem>
                            <ListItem className="mobile-nav-sub-item">
                              <Link to="#">
                                <ListItemText className="mobile-nav-sub-text">
                                  Rings
                                </ListItemText>
                              </Link>
                            </ListItem>
                            <ListItem className="mobile-nav-sub-item">
                              <Link to="#">
                                <ListItemText className="mobile-nav-sub-text">
                                  Mangalsutra
                                </ListItemText>
                              </Link>
                            </ListItem>
                            <ListItem className="mobile-nav-sub-item">
                              <Link to="#">
                                <ListItemText className="mobile-nav-sub-text">
                                  Nose Pins
                                </ListItemText>
                              </Link>
                            </ListItem>
                            <ListItem className="mobile-nav-sub-item">
                              <Link to="#">
                                <ListItemText className="mobile-nav-sub-text">
                                  Nose Pins
                                </ListItemText>
                              </Link>
                            </ListItem>
                            <ListItem className="mobile-nav-sub-item">
                              <Link to="#">
                                <ListItemText className="mobile-nav-sub-text">
                                  Pendants
                                </ListItemText>
                              </Link>
                            </ListItem>
                            <ListItem className="mobile-nav-sub-item">
                              <Link to="#">
                                <ListItemText className="mobile-nav-sub-text">
                                  Earings
                                </ListItemText>
                              </Link>
                            </ListItem>
                            <ListItem className="mobile-nav-sub-item">
                              <Link to="#">
                                <ListItemText className="mobile-nav-sub-text">
                                  Necklaces
                                </ListItemText>
                              </Link>
                            </ListItem>
                          </List>
                        </Collapse>
                      </Box>
                      <Box className="mobile-sub-menu-main">
                        <Collapse
                          in={openAlljewellery}
                          timeout="auto"
                          unmountOnExit
                          className="mobile-collapse-menu"
                        >
                          <List
                            component="div"
                            disablePadding
                            className="mobile-inner-nav-ul"
                          >
                            <ListItemButton className="mobile-inner-nav-button">
                              <ListItem
                                disablePadding
                                className="mobile-inner-nav-items"
                              >
                                <Link to={"#"}>
                                  <ListItemButton
                                    onClick={handleClickPreciousStone}
                                    className="mobile-inner-nav-button"
                                  >
                                    Precious Stones
                                    {openPreciousStone ? (
                                      <ExpandLess />
                                    ) : (
                                      <ExpandMore />
                                    )}
                                  </ListItemButton>
                                </Link>
                              </ListItem>
                            </ListItemButton>
                          </List>
                        </Collapse>
                        <Collapse
                          in={openPreciousStone}
                          timeout="auto"
                          unmountOnExit
                          className="mobile-inner-collapse-menu"
                        >
                          <List
                            component="div"
                            disablePadding
                            className="mobile-sub-menu-list"
                          >
                            <ListItem className="mobile-nav-sub-item">
                              <Link to="#">
                                <ListItemText className="mobile-nav-sub-text">
                                  Bracelets
                                </ListItemText>
                              </Link>
                            </ListItem>
                            <ListItem className="mobile-nav-sub-item">
                              <Link to="#">
                                <ListItemText className="mobile-nav-sub-text">
                                  Bangles
                                </ListItemText>
                              </Link>
                            </ListItem>
                            <ListItem className="mobile-nav-sub-item">
                              <Link to="#">
                                <ListItemText className="mobile-nav-sub-text">
                                  Rings
                                </ListItemText>
                              </Link>
                            </ListItem>
                            <ListItem className="mobile-nav-sub-item">
                              <Link to="#">
                                <ListItemText className="mobile-nav-sub-text">
                                  Chains
                                </ListItemText>
                              </Link>
                            </ListItem>
                            <ListItem className="mobile-nav-sub-item">
                              <Link to="#">
                                <ListItemText className="mobile-nav-sub-text">
                                  Pendants
                                </ListItemText>
                              </Link>
                            </ListItem>
                            <ListItem className="mobile-nav-sub-item">
                              <Link to="#">
                                <ListItemText className="mobile-nav-sub-text">
                                  Earings
                                </ListItemText>
                              </Link>
                            </ListItem>
                          </List>
                        </Collapse>
                      </Box>
                      <Box className="mobile-sub-menu-main">
                        <Collapse
                          in={openAlljewellery}
                          timeout="auto"
                          unmountOnExit
                          className="mobile-collapse-menu"
                        >
                          <List
                            component="div"
                            disablePadding
                            className="mobile-inner-nav-ul"
                          >
                            <ListItemButton className="mobile-inner-nav-button">
                              <ListItem
                                disablePadding
                                className="mobile-inner-nav-items"
                              >
                                <Link to={"#"}>
                                  <ListItemButton
                                    onClick={handleClickWomesjewellery}
                                    className="mobile-inner-nav-button"
                                  >
                                    Women's Jewellery
                                    {openWomesjewellery ? (
                                      <ExpandLess />
                                    ) : (
                                      <ExpandMore />
                                    )}
                                  </ListItemButton>
                                </Link>
                              </ListItem>
                            </ListItemButton>
                          </List>
                        </Collapse>
                        <Collapse
                          in={openWomesjewellery}
                          timeout="auto"
                          unmountOnExit
                          className="mobile-inner-collapse-menu"
                        >
                          <List
                            component="div"
                            disablePadding
                            className="mobile-sub-menu-list"
                          >
                            <ListItem className="mobile-nav-sub-item">
                              <Link to="#">
                                <ListItemText className="mobile-nav-sub-text">
                                  Bracelets
                                </ListItemText>
                              </Link>
                            </ListItem>
                            <ListItem className="mobile-nav-sub-item">
                              <Link to="#">
                                <ListItemText className="mobile-nav-sub-text">
                                  Bangles
                                </ListItemText>
                              </Link>
                            </ListItem>
                            <ListItem className="mobile-nav-sub-item">
                              <Link to="#">
                                <ListItemText className="mobile-nav-sub-text">
                                  Rings
                                </ListItemText>
                              </Link>
                            </ListItem>
                            <ListItem className="mobile-nav-sub-item">
                              <Link to="#">
                                <ListItemText className="mobile-nav-sub-text">
                                  Mangalsutra
                                </ListItemText>
                              </Link>
                            </ListItem>
                            <ListItem className="mobile-nav-sub-item">
                              <Link to="#">
                                <ListItemText className="mobile-nav-sub-text">
                                  Nose Pins
                                </ListItemText>
                              </Link>
                            </ListItem>
                            <ListItem className="mobile-nav-sub-item">
                              <Link to="#">
                                <ListItemText className="mobile-nav-sub-text">
                                  Chains
                                </ListItemText>
                              </Link>
                            </ListItem>
                            <ListItem className="mobile-nav-sub-item">
                              <Link to="#">
                                <ListItemText className="mobile-nav-sub-text">
                                  Pendants
                                </ListItemText>
                              </Link>
                            </ListItem>
                            <ListItem className="mobile-nav-sub-item">
                              <Link to="#">
                                <ListItemText className="mobile-nav-sub-text">
                                  Earings
                                </ListItemText>
                              </Link>
                            </ListItem>
                            <ListItem className="mobile-nav-sub-item">
                              <Link to="#">
                                <ListItemText className="mobile-nav-sub-text">
                                  Necklaces
                                </ListItemText>
                              </Link>
                            </ListItem>
                          </List>
                        </Collapse>
                      </Box>
                      <Box className="mobile-sub-menu-main">
                        <Collapse
                          in={openAlljewellery}
                          timeout="auto"
                          unmountOnExit
                          className="mobile-collapse-menu"
                        >
                          <List
                            component="div"
                            disablePadding
                            className="mobile-inner-nav-ul"
                          >
                            <ListItemButton className="mobile-inner-nav-button">
                              <ListItem
                                disablePadding
                                className="mobile-inner-nav-items"
                              >
                                <Link to={"#"}>
                                  <ListItemButton
                                    onClick={handleClickKundanjewellry}
                                    className="mobile-inner-nav-button"
                                  >
                                    Kundan Jewellery
                                    {openKundanjewellry ? (
                                      <ExpandLess />
                                    ) : (
                                      <ExpandMore />
                                    )}
                                  </ListItemButton>
                                </Link>
                              </ListItem>
                            </ListItemButton>
                          </List>
                        </Collapse>
                        <Collapse
                          in={openKundanjewellry}
                          timeout="auto"
                          unmountOnExit
                          className="mobile-inner-collapse-menu"
                        >
                          <List
                            component="div"
                            disablePadding
                            className="mobile-sub-menu-list"
                          >
                            <ListItem className="mobile-nav-sub-item">
                              <Link to="#">
                                <ListItemText className="mobile-nav-sub-text">
                                  Bracelets
                                </ListItemText>
                              </Link>
                            </ListItem>
                            <ListItem className="mobile-nav-sub-item">
                              <Link to="#">
                                <ListItemText className="mobile-nav-sub-text">
                                  Bangles
                                </ListItemText>
                              </Link>
                            </ListItem>
                            <ListItem className="mobile-nav-sub-item">
                              <Link to="#">
                                <ListItemText className="mobile-nav-sub-text">
                                  Rings
                                </ListItemText>
                              </Link>
                            </ListItem>
                            <ListItem className="mobile-nav-sub-item">
                              <Link to="#">
                                <ListItemText className="mobile-nav-sub-text">
                                  Mangalsutra
                                </ListItemText>
                              </Link>
                            </ListItem>
                            <ListItem className="mobile-nav-sub-item">
                              <Link to="#">
                                <ListItemText className="mobile-nav-sub-text">
                                  Pendants
                                </ListItemText>
                              </Link>
                            </ListItem>
                            <ListItem className="mobile-nav-sub-item">
                              <Link to="#">
                                <ListItemText className="mobile-nav-sub-text">
                                  Earings
                                </ListItemText>
                              </Link>
                            </ListItem>
                            <ListItem className="mobile-nav-sub-item">
                              <Link to="#">
                                <ListItemText className="mobile-nav-sub-text">
                                  Necklaces
                                </ListItemText>
                              </Link>
                            </ListItem>
                          </List>
                        </Collapse>
                      </Box>
                      <Box className="mobile-sub-menu-main">
                        <Collapse
                          in={openAlljewellery}
                          timeout="auto"
                          unmountOnExit
                          className="mobile-collapse-menu"
                        >
                          <List
                            component="div"
                            disablePadding
                            className="mobile-inner-nav-ul"
                          >
                            <ListItemButton className="mobile-inner-nav-button">
                              <ListItem
                                disablePadding
                                className="mobile-inner-nav-items"
                              >
                                <Link to={"#"}>
                                  <ListItemButton
                                    onClick={handleClickDiamondJewellery}
                                    className="mobile-inner-nav-button"
                                  >
                                    Diamond Jewellery
                                    {openDiamondJewellery ? (
                                      <ExpandLess />
                                    ) : (
                                      <ExpandMore />
                                    )}
                                  </ListItemButton>
                                </Link>
                              </ListItem>
                            </ListItemButton>
                          </List>
                        </Collapse>
                        <Collapse
                          in={openDiamondJewellery}
                          timeout="auto"
                          unmountOnExit
                          className="mobile-inner-collapse-menu"
                        >
                          <List
                            component="div"
                            disablePadding
                            className="mobile-sub-menu-list"
                          >
                            <ListItem className="mobile-nav-sub-item">
                              <Link to="#">
                                <ListItemText className="mobile-nav-sub-text">
                                  Bracelets
                                </ListItemText>
                              </Link>
                            </ListItem>
                            <ListItem className="mobile-nav-sub-item">
                              <Link to="#">
                                <ListItemText className="mobile-nav-sub-text">
                                  Bangles
                                </ListItemText>
                              </Link>
                            </ListItem>
                            <ListItem className="mobile-nav-sub-item">
                              <Link to="#">
                                <ListItemText className="mobile-nav-sub-text">
                                  Rings
                                </ListItemText>
                              </Link>
                            </ListItem>
                            <ListItem className="mobile-nav-sub-item">
                              <Link to="#">
                                <ListItemText className="mobile-nav-sub-text">
                                  Mangalsutra
                                </ListItemText>
                              </Link>
                            </ListItem>
                            <ListItem className="mobile-nav-sub-item">
                              <Link to="#">
                                <ListItemText className="mobile-nav-sub-text">
                                  Nose Pins
                                </ListItemText>
                              </Link>
                            </ListItem>
                            <ListItem className="mobile-nav-sub-item">
                              <Link to="#">
                                <ListItemText className="mobile-nav-sub-text">
                                  Chains
                                </ListItemText>
                              </Link>
                            </ListItem>
                            <ListItem className="mobile-nav-sub-item">
                              <Link to="#">
                                <ListItemText className="mobile-nav-sub-text">
                                  Pendants
                                </ListItemText>
                              </Link>
                            </ListItem>
                            <ListItem className="mobile-nav-sub-item">
                              <Link to="#">
                                <ListItemText className="mobile-nav-sub-text">
                                  Earings
                                </ListItemText>
                              </Link>
                            </ListItem>
                            <ListItem className="mobile-nav-sub-item">
                              <Link to="#">
                                <ListItemText className="mobile-nav-sub-text">
                                  Necklaces
                                </ListItemText>
                              </Link>
                            </ListItem>
                          </List>
                        </Collapse>
                      </Box>
                      <ListItem disablePadding className="mobile-nav-items">
                        <Link to="#">
                          <ListItemButton className="mobile-nav-button">
                            Membership
                          </ListItemButton>
                        </Link>
                      </ListItem>
                      <List
                        sx={{ width: "100%", bgcolor: "background.paper" }}
                        component="nav"
                        className="mobile-nav-ul"
                        aria-labelledby="nested-list-subheader"
                        subheader={
                          <ListSubheader
                            component="div"
                            id="nested-list-subheader"
                          ></ListSubheader>
                        }
                      >
                        <Link>
                          <ListItem disablePadding className="mobile-nav-items">
                            <ListItemButton
                              onClick={handleClickBlog}
                              className="mobile-nav-button"
                            >
                              Blog
                              {openBlog ? <ExpandMore /> : <ExpandLess />}
                            </ListItemButton>
                          </ListItem>
                        </Link>
                        <Box className="mobile-sub-menu-main">
                          <Collapse
                            in={openBlog}
                            timeout="auto"
                            unmountOnExit
                            className="mobile-collapse-menu"
                          >
                            <List
                              component="div"
                              disablePadding
                              className="mobile-inner-nav-ul"
                            >
                              <ListItemButton className="mobile-inner-nav-button">
                                <ListItem
                                  disablePadding
                                  className="mobile-inner-nav-items"
                                >
                                  <Link to={"#"}>
                                    <ListItemButton
                                      onClick={handleClickUseWebsite}
                                      className="mobile-inner-nav-button"
                                    >
                                      How to use the website
                                      {openUseWebsite ? (
                                        <ExpandLess />
                                      ) : (
                                        <ExpandMore />
                                      )}
                                    </ListItemButton>
                                  </Link>
                                </ListItem>
                              </ListItemButton>
                            </List>
                          </Collapse>
                          <Collapse
                            in={openUseWebsite}
                            timeout="auto"
                            unmountOnExit
                            className="mobile-inner-collapse-menu"
                          ></Collapse>
                        </Box>
                        <Box className="mobile-sub-menu-main">
                          <Collapse
                            in={openBlog}
                            timeout="auto"
                            unmountOnExit
                            className="mobile-collapse-menu"
                          >
                            <List
                              component="div"
                              disablePadding
                              className="mobile-inner-nav-ul"
                            >
                              <ListItemButton className="mobile-inner-nav-button">
                                <ListItem
                                  disablePadding
                                  className="mobile-inner-nav-items"
                                >
                                  <Link to={"#"}>
                                    <ListItemButton
                                      onClick={handleClickGuideLines}
                                      className="mobile-inner-nav-button"
                                    >
                                      Guidelines
                                      {openGuideLines ? (
                                        <ExpandLess />
                                      ) : (
                                        <ExpandMore />
                                      )}
                                    </ListItemButton>
                                  </Link>
                                </ListItem>
                              </ListItemButton>
                            </List>
                          </Collapse>
                          <Collapse
                            in={openGuideLines}
                            timeout="auto"
                            unmountOnExit
                            className="mobile-inner-collapse-menu"
                          >
                            <List
                              component="div"
                              disablePadding
                              className="mobile-sub-menu-list"
                            >
                              <ListItem className="mobile-nav-sub-item">
                                <Link to="#">
                                  <ListItemText className="mobile-nav-sub-text">
                                    Uploading Products on Zaverica
                                  </ListItemText>
                                </Link>
                              </ListItem>
                              <ListItem className="mobile-nav-sub-item">
                                <Link to="#">
                                  <ListItemText className="mobile-nav-sub-text">
                                    Requesting Custom Products
                                  </ListItemText>
                                </Link>
                              </ListItem>
                              <ListItem className="mobile-nav-sub-item">
                                <Link to="#">
                                  <ListItemText className="mobile-nav-sub-text">
                                    Placing Bulk Order
                                  </ListItemText>
                                </Link>
                              </ListItem>
                              <ListItem className="mobile-nav-sub-item">
                                <Link to="#">
                                  <ListItemText className="mobile-nav-sub-text">
                                    Trading Precious Stone
                                  </ListItemText>
                                </Link>
                              </ListItem>
                            </List>
                          </Collapse>
                        </Box>
                        <Box className="mobile-sub-menu-main">
                          <Collapse
                            in={openBlog}
                            timeout="auto"
                            unmountOnExit
                            className="mobile-collapse-menu"
                          >
                            <List
                              component="div"
                              disablePadding
                              className="mobile-inner-nav-ul"
                            >
                              <ListItemButton className="mobile-inner-nav-button">
                                <ListItem
                                  disablePadding
                                  className="mobile-inner-nav-items"
                                >
                                  <Link to={"#"}>
                                    <ListItemButton
                                      onClick={handleClickProductDimensions}
                                      className="mobile-inner-nav-button"
                                    >
                                      ProductDimensions
                                      {openProductDimensions ? (
                                        <ExpandLess />
                                      ) : (
                                        <ExpandMore />
                                      )}
                                    </ListItemButton>
                                  </Link>
                                </ListItem>
                              </ListItemButton>
                            </List>
                          </Collapse>
                          <Collapse
                            in={openProductDimensions}
                            timeout="auto"
                            unmountOnExit
                            className="mobile-inner-collapse-menu"
                          >
                            <List
                              component="div"
                              disablePadding
                              className="mobile-sub-menu-list"
                            >
                              <ListItem className="mobile-nav-sub-item">
                                <Link to="#">
                                  <ListItemText className="mobile-nav-sub-text">
                                    Ring
                                  </ListItemText>
                                </Link>
                              </ListItem>
                              <ListItem className="mobile-nav-sub-item">
                                <Link to="#">
                                  <ListItemText className="mobile-nav-sub-text">
                                    Necklace
                                  </ListItemText>
                                </Link>
                              </ListItem>
                              <ListItem className="mobile-nav-sub-item">
                                <Link to="#">
                                  <ListItemText className="mobile-nav-sub-text">
                                    Mangalsutra
                                  </ListItemText>
                                </Link>
                              </ListItem>
                            </List>
                          </Collapse>
                        </Box>
                        <Box className="mobile-sub-menu-main">
                          <Collapse
                            in={openBlog}
                            timeout="auto"
                            unmountOnExit
                            className="mobile-collapse-menu"
                          >
                            <List
                              component="div"
                              disablePadding
                              className="mobile-inner-nav-ul"
                            >
                              <ListItemButton className="mobile-inner-nav-button">
                                <ListItem
                                  disablePadding
                                  className="mobile-inner-nav-items"
                                >
                                  <Link to={"#"}>
                                    <ListItemButton
                                      onClick={handleClickPricing}
                                      className="mobile-inner-nav-button"
                                    >
                                      Pricing
                                      {openPricing ? (
                                        <ExpandLess />
                                      ) : (
                                        <ExpandMore />
                                      )}
                                    </ListItemButton>
                                  </Link>
                                </ListItem>
                              </ListItemButton>
                            </List>
                          </Collapse>
                          <Collapse
                            in={openPricing}
                            timeout="auto"
                            unmountOnExit
                            className="mobile-inner-collapse-menu"
                          >
                            <List
                              component="div"
                              disablePadding
                              className="mobile-sub-menu-list"
                            >
                              <ListItem className="mobile-nav-sub-item">
                                <Link to="#">
                                  <ListItemText className="mobile-nav-sub-text">
                                    Gold Pricing
                                  </ListItemText>
                                </Link>
                              </ListItem>
                              <ListItem className="mobile-nav-sub-item">
                                <Link to="#">
                                  <ListItemText className="mobile-nav-sub-text">
                                    Silver Pricing
                                  </ListItemText>
                                </Link>
                              </ListItem>
                              <ListItem className="mobile-nav-sub-item">
                                <Link to="#">
                                  <ListItemText className="mobile-nav-sub-text">
                                    Precious Stones
                                  </ListItemText>
                                </Link>
                              </ListItem>
                            </List>
                          </Collapse>
                        </Box>
                        <Box className="mobile-sub-menu-main">
                          <Collapse
                            in={openBlog}
                            timeout="auto"
                            unmountOnExit
                            className="mobile-collapse-menu"
                          >
                            <List
                              component="div"
                              disablePadding
                              className="mobile-inner-nav-ul"
                            >
                              <ListItemButton className="mobile-inner-nav-button">
                                <ListItem
                                  disablePadding
                                  className="mobile-inner-nav-items"
                                >
                                  <Link to={"#"}>
                                    <ListItemButton
                                      onClick={handleClickCustomerService}
                                      className="mobile-inner-nav-button"
                                    >
                                      Customer Service
                                      {openCustomerService ? (
                                        <ExpandLess />
                                      ) : (
                                        <ExpandMore />
                                      )}
                                    </ListItemButton>
                                  </Link>
                                </ListItem>
                              </ListItemButton>
                            </List>
                          </Collapse>
                          <Collapse
                            in={openCustomerService}
                            timeout="auto"
                            unmountOnExit
                            className="mobile-inner-collapse-menu"
                          ></Collapse>
                        </Box>
                        <Box className="mobile-sub-menu-main">
                          <Collapse
                            in={openBlog}
                            timeout="auto"
                            unmountOnExit
                            className="mobile-collapse-menu"
                          >
                            <List
                              component="div"
                              disablePadding
                              className="mobile-inner-nav-ul"
                            >
                              <ListItemButton className="mobile-inner-nav-button">
                                <ListItem
                                  disablePadding
                                  className="mobile-inner-nav-items"
                                >
                                  <Link to={"#"}>
                                    <ListItemButton
                                      onClick={handleClickPurity}
                                      className="mobile-inner-nav-button"
                                    >
                                      Purity
                                      {openPurity ? (
                                        <ExpandLess />
                                      ) : (
                                        <ExpandMore />
                                      )}
                                    </ListItemButton>
                                  </Link>
                                </ListItem>
                              </ListItemButton>
                            </List>
                          </Collapse>
                          <Collapse
                            in={openPurity}
                            timeout="auto"
                            unmountOnExit
                            className="mobile-inner-collapse-menu"
                          >
                            <List
                              component="div"
                              disablePadding
                              className="mobile-sub-menu-list"
                            >
                              <ListItem className="mobile-nav-sub-item">
                                <Link to="#">
                                  <ListItemText className="mobile-nav-sub-text">
                                    Gold
                                  </ListItemText>
                                </Link>
                              </ListItem>
                              <ListItem className="mobile-nav-sub-item">
                                <Link to="#">
                                  <ListItemText className="mobile-nav-sub-text">
                                    Silver
                                  </ListItemText>
                                </Link>
                              </ListItem>
                              <ListItem className="mobile-nav-sub-item">
                                <Link to="#">
                                  <ListItemText className="mobile-nav-sub-text">
                                    Gemstone
                                  </ListItemText>
                                </Link>
                              </ListItem>
                              <ListItem className="mobile-nav-sub-item">
                                <Link to="#">
                                  <ListItemText className="mobile-nav-sub-text">
                                    Diamond
                                  </ListItemText>
                                </Link>
                              </ListItem>
                            </List>
                          </Collapse>
                        </Box>
                      </List>
                      <ListItem disablePadding className="mobile-nav-items">
                        <Link to="#">
                          <ListItemButton
                            className="mobile-nav-button"
                            onClick={handleOpenPlaceOrder}
                          >
                            Place an Order
                          </ListItemButton>
                        </Link>
                      </ListItem>
                      <ListItem disablePadding className="mobile-nav-items">
                        <Link to="#">
                          <ListItemButton className="mobile-nav-button">
                            Request a Custom Products
                          </ListItemButton>
                        </Link>
                      </ListItem>

                      {token && token != "" ? (
                        ""
                      ) : (
                        <>
                          {" "}
                          <ListItem disablePadding className="mobile-nav-items">
                            <Link to="#" onClick={handleOpenLogin}>
                              <ListItemButton className="mobile-nav-button">
                                Sign In
                              </ListItemButton>
                            </Link>
                          </ListItem>
                          <ListItem disablePadding className="mobile-nav-items">
                            <Link to="#" onClick={handleOpenSignup}>
                              <ListItemButton className="mobile-nav-button">
                                Sign Up
                              </ListItemButton>
                            </Link>
                          </ListItem>
                        </>
                      )}
                    </List>
                  </SwipeableDrawer>
                </React.Fragment>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
      {/* end mobile menu */}

      <Box className="header-main">
        <Box className="container">
          <Box sx={{ width: 1 }}>
            <Box
              display="grid"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 0, sm: 0, md: 10, lg: 10 }}
              className="header-row"
            >
              <Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 2",
                  md: "span 2",
                  lg: "span 2",
                }}
              >
                <Box className="header-logo-box">
                  <img
                    onClick={() => history.push("/user/")}
                    src={png.footerlogo}
                    className="header-logo-img"
                  ></img>
                </Box>
              </Box>
              <Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 4",
                  md: "span 6",
                  lg: "span 6",
                }}
              >
                <Box className="main-header-searchbaar">
                  <Searchbar />
                </Box>
              </Box>
              <Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 4",
                  lg: "span 4",
                }}
              >
                <Box className="header-right-main">
                  <Box className="header-user-info-box">
                    <Box className="header-link-box">
                      {token && token !== null ? (
                        <>
                          <Box className="header-user-info">
                            <Link className="header-link" onClick={handle}>
                              <Typography className="header-link-text">
                                Sign Out
                              </Typography>
                            </Link>
                          </Box>
                        </>
                      ) : (
                        <>
                          <Box className="header-user-info header-vertical-line">
                            <Link
                              className="header-link"
                              onClick={handleOpenLogin}
                            >
                              <Typography className="header-link-text">
                                Sign In
                              </Typography>
                            </Link>
                          </Box>
                          <Box className="header-user-info">
                            <Link
                              className="header-link"
                              onClick={handleOpenSignup}
                            >
                              <Typography className="header-link-text">
                                Sign Up
                              </Typography>
                            </Link>
                          </Box>
                        </>
                      )}
                    </Box>
                    {token && token ? (
                      <>
                        <Box className="header-user-info">
                          <Link
                            onClick={() => history.push("/user/my-profile")}
                            className="header-link-text"
                          >
                            <Box className="header-user-bg">
                              <img
                                src={png.headeruser}
                                className="header-user-img"
                              ></img>
                            </Box>
                          </Link>
                        </Box>
                      </>
                    ) : (
                      ""
                    )}

                    {token && token !== null ? (
                      <>
                        <Box
                          onClick={() => {
                            history.push("/user/mycart");
                          }}
                          className="header-user-info cart-user-info"
                        >
                          <Link className="header-link-text">
                            <Box className="header-cart-bg">
                              <img
                                src={png.whitecart}
                                className="header-cart-img"
                              ></img>
                              {countdata === 0 ? (
                                ""
                              ) : (
                                <h4 style={{ marginLeft: "4px" }}>
                                  {countdata}
                                </h4>
                              )}
                            </Box>
                          </Link>
                        </Box>
                      </>
                    ) : (
                      <>
                        <Box
                          onClick={notify}
                          className="header-user-info cart-user-info"
                        >
                          <Link className="header-link-text">
                            <Box className="header-cart-bg">
                              <img
                                src={png.whitecart}
                                className="header-cart-img"
                              ></img>
                              {countdata === 0 ? "" : <h4>{countdata}</h4>}
                            </Box>
                          </Link>
                        </Box>
                      </>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box className="navigation-sec">
        <Box className="container">
          <Box className="navigation-main">
            <Box className="open-sidebar">
              {["left"].map((anchor) => (
                <React.Fragment key={anchor}>
                  {token && token !== null ? (
                    <Button onClick={toggleDrawer(anchor, true)}>
                      <img
                        src={svg.sidemenuimg}
                        className="side-menu-img"
                      ></img>
                    </Button>
                  ) : (
                    ""
                  )}
                  <Drawer
                    anchor={anchor}
                    open={state[anchor]}
                    onClose={toggleDrawer(anchor, false)}
                  >
                    {list(anchor)}
                  </Drawer>
                </React.Fragment>
              ))}
            </Box>
            <Box className="navigation-baar">
              <nav aria-label="main mailbox folders">
                <List className="nav-ul">
                  <ListItem disablePadding className="nav-items">
                    <Link onClick={() => history.push("/user/aboutus")}>
                      <ListItemButton
                        // className="nav-button"
                        className={`nav-button ${location.pathname == "/user/aboutus" ? "active" : ""
                          }`}
                      >
                        About
                      </ListItemButton>
                    </Link>
                  </ListItem>
                  <ListItem disablePadding className="nav-items">
                    <Link to="#">
                      <ListItemButton className="nav-button">
                        All Jewellery
                        <span>
                          <img src={svg.navdown} className="navdown-img"></img>
                        </span>
                      </ListItemButton>
                      <Box className="hover-nav hover-nav-jewellery">
                        <AllJewellery />
                      </Box>
                    </Link>
                  </ListItem>

                  <ListItem disablePadding className="nav-items">
                    <Link onClick={() => history.push("/user/membership")}>
                      <ListItemButton
                        // className="nav-button"
                        className={`nav-button ${location.pathname == "/user/membership"
                            ? "active"
                            : ""
                          }`}
                      >
                        Membership
                        {/* <Box className="nav-button">Membership</Box> */}
                      </ListItemButton>
                    </Link>
                  </ListItem>

                  <ListItem disablePadding className="nav-items">
                    <Link to="#">
                      <ListItemButton className="nav-button">
                        Blogs{" "}
                        <span>
                          <img src={svg.navdown} className="navdown-img"></img>
                        </span>
                      </ListItemButton>
                      <Box className="hover-nav">
                        <Box className="nav-inner-main nav-inner-blog-count">
                          <Box className="nav-blog-left nav-blog-left-inner">
                            <Box clasName="nav-hover-data-box">
                              <List className="nav-hover-ul">
                                <ListItem className="nav-hover-li">
                                  <Link
                                    onClick={() => history.push("/user/blogs")}
                                  >
                                    <ListItemText className="nav-hover-li-heading">
                                      How to use the website
                                    </ListItemText>
                                  </Link>
                                </ListItem>
                              </List>
                            </Box>
                            <Box clasName="nav-hover-data-box">
                              <List className="nav-hover-ul">
                                <ListItem className="nav-hover-li">
                                  <Link
                                    onClick={() =>
                                      history.push("/user/guidelines")
                                    }
                                  >
                                    <ListItemText className="nav-hover-li-heading ">
                                      Guidelines
                                    </ListItemText>
                                  </Link>
                                </ListItem>

                                <ListItem className="nav-hover-li">
                                  <Link
                                    onClick={() =>
                                      history.push(
                                        `/user/uploadingproductstype/${"Uploading Products on Zaverica"}`
                                      )
                                    }
                                  >
                                    <ListItemText className="nav-hover-li-data">
                                      Uploading Products on Zaverica
                                    </ListItemText>
                                  </Link>
                                </ListItem>

                                <ListItem className="nav-hover-li">
                                  <Link
                                    onClick={() =>
                                      history.push(
                                        `/user/uploadingproductstype/${"Requesting Custom Products"}`
                                      )
                                    }
                                  >
                                    <ListItemText className="nav-hover-li-data">
                                      Requesting Custom Products
                                    </ListItemText>
                                  </Link>
                                </ListItem>

                                <ListItem className="nav-hover-li">
                                  <Link
                                    onClick={() =>
                                      history.push(
                                        `/user/uploadingproductstype/${"Placing Bulk Order"}`
                                      )
                                    }
                                  >
                                    <ListItemText className="nav-hover-li-data">
                                      Placing Bulk Order
                                    </ListItemText>
                                  </Link>
                                </ListItem>

                                <ListItem className="nav-hover-li">
                                  <Link
                                    onClick={() =>
                                      history.push(
                                        `/user/uploadingproductstype/${"Trading Precious Stone"}`
                                      )
                                    }
                                  >
                                    <ListItemText className="nav-hover-li-data">
                                      Trading Precious Stone
                                    </ListItemText>
                                  </Link>
                                </ListItem>
                              </List>
                            </Box>
                            <Box clasName="nav-hover-data-box">
                              <List className="nav-hover-ul">
                                <ListItem className="nav-hover-li">
                                  <Link to="#">
                                    <ListItemText className="nav-hover-li-heading">
                                      Product Dimensions
                                    </ListItemText>
                                  </Link>
                                </ListItem>
                                {ListProductDescriptionData.length
                                  ? ListProductDescriptionData.map((item) => {
                                    return (
                                      <ListItem className="nav-hover-li">
                                        <Link
                                          onClick={() =>
                                            history.push(
                                              `/user/product-dimensions/${item?.product_type}`
                                            )
                                          }
                                        >
                                          <ListItemText className="nav-hover-li-data">
                                            {item?.product_type}
                                          </ListItemText>
                                        </Link>
                                      </ListItem>
                                    );
                                  })
                                  : ""}
                                {/* <ListItem className="nav-hover-li">
                                  <Link
                                    onClick={() =>
                                      history.push(`/user/ringsinfo/${"Ring"}`)
                                    }
                                  >
                                    <ListItemText className="nav-hover-li-data">
                                      Ring
                                    </ListItemText>
                                  </Link>
                                </ListItem>
                                <ListItem className="nav-hover-li">
                                  <Link
                                    onClick={() =>
                                      history.push(
                                        `/user/ringsinfo/${"Necklace"}`
                                      )
                                    }
                                  >
                                    <ListItemText className="nav-hover-li-data">
                                      Necklace
                                    </ListItemText>
                                  </Link>
                                </ListItem>
                                <ListItem className="nav-hover-li">
                                  <Link
                                    onClick={() =>
                                      history.push(
                                        `/user/ringsinfo/${"Mangalsutra"}`
                                      )
                                    }
                                  >
                                    <ListItemText className="nav-hover-li-data">
                                      Mangalsutra
                                    </ListItemText>
                                  </Link>
                                </ListItem> */}
                              </List>
                            </Box>
                          </Box>
                          <Box className="nav-blog-right">
                            <Box clasName="nav-hover-data-box">
                              <List className="nav-hover-ul">
                                <ListItem className="nav-hover-li">
                                  <Link>
                                    <ListItemText className="nav-hover-li-heading last">
                                      Pricing
                                    </ListItemText>
                                  </Link>
                                </ListItem>
                                {ListProductPricingData.length
                                  ? ListProductPricingData.map((data, i) => {
                                    return (
                                      <>
                                        <ListItem className="nav-hover-li">
                                          <Link
                                            onClick={() =>
                                              history.push(
                                                `/user/pricing/${data.product_type}`
                                              )
                                            }
                                          >
                                            <ListItemText className="nav-hover-li-data">
                                              {data.product_type}
                                            </ListItemText>
                                          </Link>
                                        </ListItem>
                                      </>
                                    );
                                  })
                                  : ""}
                                {/* <ListItem className="nav-hover-li">
                                  <Link
                                    onClick={() =>
                                      history.push(`/user/pricing/${'Gold'}`)
                                    }
                                  >
                                    <ListItemText className="nav-hover-li-data">
                                      Gold Pricing
                                    </ListItemText>
                                  </Link>
                                </ListItem>
                                <ListItem className="nav-hover-li">
                                  <Link
                                    onClick={() =>
                                      history.push(`/user/pricing/${'Silver'}`)
                                    }
                                  >
                                    <ListItemText className="nav-hover-li-data">
                                      Silver Pricing
                                    </ListItemText>
                                  </Link>
                                </ListItem>
                                <ListItem className="nav-hover-li">
                                  <Link
                                    onClick={() =>
                                      history.push(
                                        `/user/pricing/${'Precious Stone'}`
                                      )
                                    }
                                  >
                                    <ListItemText className="nav-hover-li-data">
                                      Precious Stones
                                    </ListItemText>
                                  </Link>
                                </ListItem> */}
                              </List>
                            </Box>
                            <Box clasName="nav-hover-data-box">
                              <List className="nav-hover-ul">
                                <ListItem className="nav-hover-li">
                                  <Link
                                    onClick={() => history.push("/user/faq")}
                                  >
                                    <ListItemText className="nav-hover-li-heading last">
                                      FAQs
                                    </ListItemText>
                                  </Link>
                                </ListItem>
                              </List>
                            </Box>
                            <Box clasName="nav-hover-data-box">
                              <List className="nav-hover-ul">
                                <ListItem className="nav-hover-li">
                                  <Link
                                    onClick={() =>
                                      history.push("/user/customerservices")
                                    }
                                  >
                                    <ListItemText className="nav-hover-li-heading last">
                                      Customer Service
                                    </ListItemText>
                                  </Link>
                                </ListItem>
                              </List>
                            </Box>
                            <Box clasName="nav-hover-data-box">
                              <List className="nav-hover-ul">
                                <ListItem className="nav-hover-li">
                                  <Link to="#">
                                    <ListItemText className="nav-hover-li-heading last">
                                      Purity
                                    </ListItemText>
                                  </Link>
                                </ListItem>
                                {ListProductPurityData?.length
                                  ? ListProductPurityData.map((item) => {
                                    return (
                                      <ListItem className="nav-hover-li">
                                        <Link
                                          onClick={() =>
                                            history.push(
                                              `/user/purity/${item?.material_type}`
                                            )
                                          }
                                        >
                                          <ListItemText className="nav-hover-li-data">
                                            {item?.material_type}
                                          </ListItemText>
                                        </Link>
                                      </ListItem>
                                    );
                                  })
                                  : ""}
                                {/* <ListItem className="nav-hover-li">
                                  <Link to="#">
                                    <ListItemText className="nav-hover-li-data">
                                      Gold
                                    </ListItemText>
                                  </Link>
                                </ListItem>
                                <ListItem className="nav-hover-li">
                                  <Link to="#">
                                    <ListItemText className="nav-hover-li-data">
                                      Silver
                                    </ListItemText>
                                  </Link>
                                </ListItem>
                                <ListItem className="nav-hover-li">
                                  <Link to="#">
                                    <ListItemText className="nav-hover-li-data">
                                      Gemstone
                                    </ListItemText>
                                  </Link>
                                </ListItem>
                                <ListItem className="nav-hover-li">
                                  <Link to="#">
                                    <ListItemText className="nav-hover-li-data">
                                      Diamond
                                    </ListItemText>
                                  </Link>
                                </ListItem> */}
                              </List>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Link>
                  </ListItem>

                  <ListItem disablePadding className="nav-items">
                    <Link to="#">
                      <ListItemButton
                        className="nav-button place-on-order-li"
                        onClick={handleOpenPlaceOrder}
                      >
                        Place an Order
                      </ListItemButton>
                    </Link>
                  </ListItem>

                  <ListItem disablePadding className="nav-items">
                    <Link onClick={() => requestCustomeProduct()}>
                      <ListItemButton
                        // className="nav-button"
                        className={`nav-button ${location.pathname == "/user/requestingproduct"
                            ? "active"
                            : ""
                          }`}
                      >
                        Request a Custom Product
                      </ListItemButton>
                    </Link>
                  </ListItem>
                </List>
              </nav>
            </Box>
          </Box>
        </Box>
      </Box>

      {/* start login modal */}
      <Box className="header-modals">
        <Box className="modal-scroll-padding">
          <Modal
            className="login-modal"
            open={openLogin}
          // onClose={handleCloseLogin}
          >
            <Login
              Closemodel={setOpenLogin}
              handleCloseLogin={handleCloseLogin}
              Openmodel={setOpenSignup}
              OpenOtpmodel={setOpenOtpForm}
              setForgotData={setForgotData}
              setBackBtnType={setBackBtnType}
              backBtnType={backBtnType}
            />
          </Modal>
        </Box>
      </Box>
      {/* end login modal */}

      {/* start signup modal */}
      <Box className="header-modals">
        <Modal
          className="login-modal"
          open={openSignup}
        // onClose={handleCloseSignup}
        >
          <SignUp
            Closemodel={setOpenSignup}
            Openmodel={setOpenLogin}
            OpenOtpmodel={setOpenOtpForm}
            setOpenOtpForm={setOpenOtpForm}
            openOtpForm={openOtpForm}
            setBackBtnType={setBackBtnType}
            userResendOtpEmail={userResendOtpEmail}
            setUserResendOtpEmail={setUserResendOtpEmail}
          />
        </Modal>
      </Box>
      {/* end signup modal */}

      <Box className="header-modals">
        <Modal
          className="login-modal"
          open={openOtpForm}
        // onClose={handleCloseOtpForm}
        >
          <OtpVerify
            data={forgotData}
            Openmodel={setForgotPasswordModal}
            setOpenOtpForm={setOpenOtpForm}
            Closemodel={handleCloseOtpForm}
            OpenLoginModel={setOpenLogin}
            handleCloseSignup={handleCloseSignup}
            handleOpenSignup={handleOpenSignup}
            setBackBtnType={setBackBtnType}
            backBtnType={backBtnType}
            userResendOtpEmail={userResendOtpEmail}
          />
        </Modal>
      </Box>

      {/* start place order modal */}
      <Box className="header-modals">
        <Modal
          className="order-modal"
          open={openPlaceOrder}
          onClose={handleClosePlaceOrder}
        >
          <PlaceOrder />
        </Modal>
      </Box>
      {/* start place order modal */}

      {/*Start forgot password modal*/}
      <Box className="header-modals">
        <Modal
          className="login-modal"
          open={forgotPasswordModal}
          onClose={handleCloseForgotPasswordModal}
        >
          <UserForgotPassword Closemodel={setForgotPasswordModal} />
        </Modal>
      </Box>
      {/*end forgot password modal*/}
    </div>
  );
};

export default Header;
