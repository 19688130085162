//#region ALL IMPORTS
import React, { useEffect, useState } from 'react';
import {
  Button,
  Typography,
  Grid,
  Tooltip,
  Box,
  FormHelperText,
  TextareaAutosize,
  MenuItem,
  FormControl,
  Select,
  Modal,
  TextField,
} from '@mui/material';
import png from '../../../../assets/png';
import DownloadIcon from '@mui/icons-material/Download';
import { styled } from '@mui/material/styles';
import {
  useHistory,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min';
import { Api } from '../../../../config/api';
import dataService from '../../../../config/dataService';
import { toast } from 'react-toastify';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import HighlightOffTwoToneIcon from '@mui/icons-material/CancelTwoTone';
import ContainedButton from '../../../../component/customComponents/buttons/ContainedButton';
import ApproveButton from '../../../../component/customComponents/buttons/ApproveButton';
import DeclineButton from '../../../../component/customComponents/buttons/DeclineButton';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import {
  ErrorMsg,
  TextAreaErrorMsg,
} from '../../../../component/customComponents/errorMsg/ErrorMsg';
import {
  minRejectReasonLength,
  rejectReasonLength,
  NO_WHITE_SPACE_BEGINNING_END,
} from '../../../../component/customComponents/validation/Validation';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { downloadImageWithoutBlob } from '../../../../component/customComponents/commonFunctions/CommonFunctions';
import { ScrollUp } from '../../../../component/customComponents/goToTop/GoToTop';
//#endregion
import AdminIndex from '../../AdminIndex';

//#region ALL HOOKS AND VARIABALS

const Img = styled('img')({
  display: 'block',
  maxWidth: '100%',
  height: 'auto',
  maxHeight: '50vh',
  borderRadius: '6px',
});

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 367,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const { getCustomProductDetail, approveCustomProduct, rejectCustomProduct } = AdminIndex.AdminSagaActions;
const ViewCustomProduct = () => {
  const history = useHistory();
  const navigate = useHistory();
  const { id } = useParams();
  let token = localStorage.getItem('auth');
  const dispatch = AdminIndex.useDispatch();
  const [show, setShow] = useState(true);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState('');
  const [validation, setValidation] = useState(true);
  const [validation2, setValidation2] = useState(true);
  const [dynamicErrorState, setDynamicErrorState] = useState(false);
  const goldObject = {
    purity: '',
          weight: '',
  };
  const silverObject = {
    purity: '',
          weight: '',
  };
  const gemstoneObject = {
    piece: '',
    quantity: '',
    purity: '',
    total_weight: '',
    price_of_total_quantity: 0,
    final_price: 0,
  };
  const diamondObject = {
    piece: '',
    quantity: '',
    purity: '',
    total_weight: '',
    price_of_total_quantity: 0,
    final_price: 0,
  };
  const [goldInputFields, setGoldInputFields] = useState([goldObject]);
  const [silverInputFields, setSilverInputFields] = useState([silverObject]);
  const [gemstoneInputFields, setGemstoneInputFields] = useState([
    gemstoneObject,
  ]);
  const [diamondInputFields, setDiamondInputFields] = useState([diamondObject]);

  const [getData, setGetData] = useState([]);
  const [material, setMaterial] = useState([]);
  const [materialId, setMaterialId] = useState([]);
  const [rejectReason, setRejectReason] = useState('');
  let materialArray = [];

  let isDiamondFieldsEmpty = diamondInputFields.map(
    (val) =>
      val.piece !== '' &&
      val.quantity !== '' &&
      val.purity !== '' &&
      val.price_of_total_quantity !== '' &&
      val.price_per_quantity !== '' &&
      val.making_charge !== ''
  );
  let isGemstoneFieldsEmpty = gemstoneInputFields.map(
    (val) =>
      val.piece !== '' &&
      val.quantity !== '' &&
      val.purity !== '' &&
      val.price_of_total_quantity !== '' &&
      val.price_per_quantity !== '' &&
      val.making_charge !== ''
  );

  useEffect(() => {
    material?.map((item) => {
      let temp = selectMaterial.filter((a) => a.material == item);
      materialArray.push({ material_id: temp[0]?._id });
      setMaterialId(materialArray);
    });
  }, [material]);

  const [itemId, setItemId] = useState('');
  const [categoryId, setCategoryId] = useState('');

  const [category, setCategory] = useState('');
  const [image, setImage] = useState([]);
  const [url, seturl] = useState([]);

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
    watch,
  } = useForm();

  const [noOfPiecesAvailableInStockError, setNoOfPiecesAvailableInStockError] =
    useState('');
  const [itemError, setItemError] = useState('');
  const [specifyError, setSpecifyError] = useState('');
  const [additional_detailsError, setAdditional_detailsError] = useState('');
  const [categoryError, setCategoryError] = useState('');
  const [materialError, setMaterialError] = useState('');
  const [imageError, setImageError] = useState('');

  const [innerCircumference, setInnerCircumference] = useState('');
  const [lengthError, setLengthError] = useState('');
  const [depthError, setDepthError] = useState('');
  const [metaTitleError, setMetaTitleError] = useState('');
  const [metaDescriptionError, setMetaDescriptionError] = useState('');

  const [selectItem, setSelectItem] = useState([]);
  const [selectMaterial, setSelectMaterial] = useState([]);
  const [selectCategory, setSelectCategory] = useState([]);
  const [selectCategoryId, setSelectCategoryId] = useState([]);

  const [goldPropertiesPurityError, setGoldPropertiesPurityError] =
    useState('');
  const [goldPropertiesWeightError, setGoldPropertiesWeightError] =
    useState('');
  const [
    goldPropertiesMakingChargesError,
    setGoldPropertiesMakingChargesError,
  ] = useState('');
  const [goldPropertiesOtherChargesError, setGoldPropertiesOtherChargesError] =
    useState('');
  const [silverPropertiesPurityError, setSilverPropertiesPurityError] =
    useState('');
  const [silverPropertiesWeightError, setSilverPropertiesWeightError] =
    useState('');
  const [
    silverPropertiesMakingChargesError,
    setSilverPropertiesMakingChargesError,
  ] = useState('');
  const [
    silverPropertiesOtherChargesError,
    setSilverPropertiesOtherChargesError,
  ] = useState('');
  const [description, setDescription] = useState({
    no_of_pieces_available_inStock: '',
    item: '',
    specify: '',
    additional_details: '',
  });

  const [dimension, setDimension] = useState({
    inner_circumference: '',
    length: '',
    depth: '',
    meta_title: '',
    meta_description: '',
  });

  const [goldProperties, setGoldProperties] = useState({
    purity: '',
    weight: '',
  });

  const [silverProperties, setSilverProperties] = useState({
    purity: '',
    weight: '',
  });

  let isGoldFieldsNotEmpty =
    goldProperties.purity !== '' && goldProperties.weight !== '';

  let isSilverFieldsNotEmpty =
    silverProperties.purity !== '' && silverProperties.weight !== '';

  useEffect(() => {
    errorHandling();
    secPageErrorHandling();
  }, [
    validation,
    validation2,
    description,
    material,
    dimension,
    goldProperties,
    silverProperties,
  ]);

  useEffect(() => {
    getItemData();
    getMaterialData();
    getCategoryData();
  }, []);

  useEffect(() => {
    getInfo();
  }, []);

  useEffect(() => {
    setCategory(getData?.category_id?.category_name);
    if (getData?.materialIdArray) {
      setMaterial(
        getData?.materialIdArray.map((val) => val.material_id.material)
      );
      setDescription({
        no_of_pieces_available_inStock: getData?.no_of_pieces_available_inStock,
        item: getData?.item_id?.item,
        specify: getData?.specify,
        additional_details: getData?.additional_details,
      });
      setGoldProperties({
        purity: getData?.gold?.purity,
        weight: getData?.gold?.weight,
      });
      setSilverProperties({
        purity: getData?.silver?.purity,
        weight: getData?.silver?.weight,
      });
      setGoldInputFields(getData?.gold);
      setSilverInputFields(getData?.silver);
      setGemstoneInputFields(getData?.gemstone);
      setDiamondInputFields(getData?.diamond);
      seturl(getData?.image);
      setDimension({
        inner_circumference: getData?.product_dimension?.inner_circumference,
        length: getData?.product_dimension?.length,
        depth: getData?.product_dimension?.depth,
        meta_title: getData?.product_dimension?.meta_title,
        meta_description: getData?.product_dimension?.meta_description,
      });
    }
  }, [getData]);

  useEffect(() => {
    if (material === 'Gold') {
      setGoldProperties({
        purity: getData?.gold?.purity,
        weight: getData?.gold?.weight,
      });
    }
    if (material === 'Silver') {
      setSilverProperties({
        purity: getData?.silver?.purity,
        weight: getData?.silver?.weight,
      });
    }
    if (getData?.materialIdArray) {
      setGoldInputFields(getData?.gold);
      setSilverInputFields(getData?.silver);
    setGemstoneInputFields(getData?.gemstone);
    setDiamondInputFields(getData?.diamond);
      }
  }, [material]);
  //#endregion

  //#region ALL EVENT HANDLERS AND FUCTIONS
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChangeReject = (e) => {
    setRejectReason(e.target.value);
  };

  const reject = async (data) => {
    let urlencoded = new URLSearchParams();
    try {
        urlencoded.append('id', id);
        urlencoded.append('reasonOfRejection', data.rejectReason?.trim());
        dispatch(rejectCustomProduct(token,urlencoded,navigate));
        // const rejData = await dataService.post(
        //   Api.Admin.rejectedCustomProductAdmin,
        //   urlencoded,
        //   {
        //     headers: {
        //       auth: token,
        //     },
        //   }
        // );

        // if (rejData.data.status === 1) {
        //   toast.success('Custom Product rejected successfully');
        //     history.push('/admin/productmanagement/customproducts');
        // }
    } catch (err) {
      toast.error(err?.message || 'Something went wrong');
    }
  };

  const nextPage = () => {
    errorHandling();
    setValidation(false);

    if (
      description.no_of_pieces_available_inStock !== '' &&
      description.item !== '' &&
      description.specify !== '' &&
      description.additional_details !== '' &&
      (image != '' || url != '')
    ) {
      setShow(false);
    }
  };

  const getItemData = async () => {
    const urlencoded = new URLSearchParams();
    try {
      const itemData = await dataService.post(Api.Admin.listItem, urlencoded);
      setSelectItem(itemData.data.data);

    } catch (err) {
      toast.error(err?.message || 'Something went wrong');
    }
  };

  const getMaterialData = async () => {
    const urlencoded = new URLSearchParams();
    try {
      const materialData = await dataService.post(
        Api.Admin.listMaterial,
        urlencoded
      );
      setSelectMaterial(materialData.data.data);
    } catch (err) {
      toast.error(err?.message || 'Something went wrong');
    }
  };

  const getCategoryData = async () => {
    const urlencoded = new URLSearchParams();
    try {
      const categoryData = await dataService.post(
        Api.Admin.getAllCategory,
        urlencoded
      );
      setSelectCategory(categoryData.data.data);
      setSelectCategoryId(categoryData.data.data.map((id) => id._id));
    } catch (err) {
      toast.error(err?.message || 'Something went wrong');
    }
  };

  const removeSpecificImg = (element) => {
    const filterIndex = url.indexOf(element);
    if (filterIndex > -1) {
      url.splice(filterIndex, 1);
      seturl(url.filter((element, i) => i !== element));
    }
  };

  const goldFields = () => {
    return (
      <>
      {goldInputFields.map((input, index) => (
        <Box sx={{ border: 1, p: 2, my: 1 }}>
          <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={1}>
            <Box
              gridColumn={{
                xs: 'span 12',
                sm: 'span 12',
                md: 'span 12',
                lg: 'span 12',
              }}
            >
              <Box className="addinfo-text-box-1">
                <Typography variant="h2" className="addinfo-text-1">
                Gold{goldInputFields.length >= 2 ? ' - ' : ''}
                    {goldInputFields.length >= 2 ? index + 1 : ''}
                </Typography>
              </Box>
            </Box>
            <Box
              gridColumn={{
                xs: 'span 12',
                sm: 'span 6',
                md: 'span 6',
                lg: 'span 6',
              }}
            >
              <Box className="refrence-product-input addproduct-1-input-box">
                <Box className="input-box addproduct-input-box">
                  <FormHelperText className="input-lable">
                    Purity(%)
                  </FormHelperText>
                  <TextField
                    disabled
                    autoComplete="off"
                    fullWidth
                    id="purity"
                    className="form-control border-none"
                    value={input?.purity}
                    name="purity"
                    type="number"
                  />
                </Box>
              </Box>
            </Box>
            <Box
              gridColumn={{
                xs: 'span 12',
                sm: 'span 6',
                md: 'span 6',
                lg: 'span 6',
              }}
            >
              <Box className="refrence-product-input">
                <Box className="input-box addproduct-1-input-box">
                  <FormHelperText className="input-lable">
                    Weight(gm)
                  </FormHelperText>
                  <TextField
                    disabled
                    autoComplete="off"
                    fullWidth
                    id="weight"
                    className="form-control border-none"
                    value={input.weight}
                    name="weight"
                    type="number"
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        ))}
      </>
    );
  };

  const silverFields = () => {
    return (
      <>
      {silverInputFields.map((input, index) => (
        <Box sx={{ border: 1, p: 2, my: 1 }}>
          <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={1}>
            <Box
              gridColumn={{
                xs: 'span 12',
                sm: 'span 12',
                md: 'span 12',
                lg: 'span 12',
              }}
            >
              <Box className="addinfo-text-box-1">
                <Typography variant="h2" className="addinfo-text-1">
                  Silver{silverInputFields.length >= 2 ? ' - ' : ''}
                    {silverInputFields.length >= 2 ? index + 1 : ''}
                </Typography>
              </Box>
            </Box>
            <Box
              gridColumn={{
                xs: 'span 12',
                sm: 'span 6',
                md: 'span 6',
                lg: 'span 6',
              }}
            >
              <Box className="refrence-product-input addproduct-1-input-box">
                <Box className="input-box addproduct-input-box">
                  <FormHelperText className="input-lable">
                    Purity(%)
                  </FormHelperText>
                  <TextField
                    disabled
                    autoComplete="off"
                    fullWidth
                    id="purity"
                    className="form-control border-none"
                    value={input.purity}
                    name="purity"
                    type="number"
                  />
                </Box>
              </Box>
            </Box>
            <Box
              gridColumn={{
                xs: 'span 12',
                sm: 'span 6',
                md: 'span 6',
                lg: 'span 6',
              }}
            >
              <Box className="refrence-product-input">
                <Box className="input-box addproduct-1-input-box">
                  <FormHelperText className="input-lable">
                    Weight(gm)
                  </FormHelperText>
                  <TextField
                    disabled
                    autoComplete="off"
                    fullWidth
                    id="weight"
                    className="form-control border-none"
                    value={input.weight}
                    name="weight"
                    type="number"
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        ))}
      </>
    );
  };
  const gemstoneFields = () => {
    return (
      <>
        {gemstoneInputFields.map((input, index) => (
          <Box sx={{ border: 1, p: 2, my: 1 }}>
            <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={1}>
              <Box
                gridColumn={{
                  xs: 'span 12',
                  sm: 'span 12',
                  md: 'span 12',
                  lg: 'span 12',
                }}
              >
                <Box className="addinfo-text-box-1">
                  <Typography variant="h2" className="addinfo-text-1">
                    Gemstone{gemstoneInputFields.length >= 2 ? ' - ' : ''}
                    {gemstoneInputFields.length >= 2 ? index + 1 : ''}
                  </Typography>
                </Box>
              </Box>
              <Box
                gridColumn={{
                  xs: 'span 12',
                  sm: 'span 6',
                  md: 'span 6',
                  lg: 'span 6',
                }}
              >
                <Box className="refrence-product-input addproduct-1-input-box">
                  <Box className="input-box addproduct-input-box">
                    <FormHelperText className="input-lable">
                      Name
                    </FormHelperText>
                    <TextField
                      disabled
                      autoComplete="off"
                      fullWidth
                      id="piece"
                      className="form-control border-none"
                      value={input.piece}
                      name="piece"
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                gridColumn={{
                  xs: 'span 12',
                  sm: 'span 6',
                  md: 'span 6',
                  lg: 'span 6',
                }}
              >
                <Box className="refrence-product-input">
                  <Box className="input-box addproduct-1-input-box">
                    <FormHelperText className="input-lable">
                      Quantity
                    </FormHelperText>
                    <TextField
                      disabled
                      autoComplete="off"
                      fullWidth
                      id="quantity"
                      className="form-control border-none"
                      value={input.quantity}
                      name="quantity"
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                gridColumn={{
                  xs: 'span 12',
                  sm: 'span 6',
                  md: 'span 6',
                  lg: 'span 6',
                }}
              >
                <Box className="refrence-product-input">
                  <Box className="input-box addproduct-1-input-box">
                    <FormHelperText className="input-lable">
                      Purity(Carat)
                    </FormHelperText>
                    <TextField
                      disabled
                      autoComplete="off"
                      fullWidth
                      id="purity"
                      className="form-control border-none"
                      value={input.purity}
                      name="purity"
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                gridColumn={{
                  xs: 'span 12',
                  sm: 'span 6',
                  md: 'span 6',
                  lg: 'span 6',
                }}
              >
                <Box className="refrence-product-input">
                  <Box className="input-box addproduct-1-input-box">
                    <FormHelperText className="input-lable">
                      Weight(gm)
                    </FormHelperText>
                    <TextField
                      disabled
                      autoComplete="off"
                      fullWidth
                      id="total_weight"
                      className="form-control border-none"
                      value={input.total_weight}
                      name="total_weight"
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        ))}
      </>
    );
  };
  const diamondFields = () => {
    return (
      <>
        {diamondInputFields.map((input, index) => (
          <Box sx={{ border: 1, p: 2, my: 1 }}>
            <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={1}>
              <Box
                gridColumn={{
                  xs: 'span 12',
                  sm: 'span 12',
                  md: 'span 12',
                  lg: 'span 12',
                }}
              ></Box>
              <Box
                gridColumn={{
                  xs: 'span 12',
                  sm: 'span 12',
                  md: 'span 12',
                  lg: 'span 12',
                }}
              >
                <Box className="addinfo-text-box-1">
                  <Typography variant="h2" className="addinfo-text-1">
                    Diamond{diamondInputFields.length >= 2 ? ' - ' : ''}
                    {diamondInputFields.length >= 2 ? index + 1 : ''}
                  </Typography>
                </Box>
              </Box>
              <Box
                gridColumn={{
                  xs: 'span 12',
                  sm: 'span 6',
                  md: 'span 6',
                  lg: 'span 6',
                }}
              >
                <Box className="refrence-product-input addproduct-1-input-box">
                  <Box className="input-box addproduct-input-box">
                    <FormHelperText className="input-lable">
                      Name
                    </FormHelperText>
                    <TextField
                      disabled
                      autoComplete="off"
                      fullWidth
                      id="piece"
                      className="form-control border-none"
                      value={input.piece}
                      name="piece"
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                gridColumn={{
                  xs: 'span 12',
                  sm: 'span 6',
                  md: 'span 6',
                  lg: 'span 6',
                }}
              >
                <Box className="refrence-product-input">
                  <Box className="input-box addproduct-1-input-box">
                    <FormHelperText className="input-lable">
                      Quantity
                    </FormHelperText>
                    <TextField
                      disabled
                      autoComplete="off"
                      fullWidth
                      id="quantity"
                      className="form-control border-none"
                      value={input.quantity}
                      name="quantity"
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                gridColumn={{
                  xs: 'span 12',
                  sm: 'span 6',
                  md: 'span 6',
                  lg: 'span 6',
                }}
              >
                <Box className="refrence-product-input">
                  <Box className="input-box addproduct-1-input-box">
                    <FormHelperText className="input-lable">
                      Purity(Carat)
                    </FormHelperText>
                    <TextField
                      disabled
                      autoComplete="off"
                      fullWidth
                      id="purity"
                      className="form-control border-none"
                      value={input.purity}
                      name="purity"
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                gridColumn={{
                  xs: 'span 12',
                  sm: 'span 6',
                  md: 'span 6',
                  lg: 'span 6',
                }}
              >
                <Box className="refrence-product-input">
                  <Box className="input-box addproduct-1-input-box">
                    <FormHelperText className="input-lable">
                      Weight(gm)
                    </FormHelperText>
                    <TextField
                      disabled
                      autoComplete="off"
                      fullWidth
                      id="total_weight"
                      className="form-control border-none"
                      value={input.total_weight}
                      name="total_weight"
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        ))}
      </>
    );
  };

  const renderFieldMaterialWise = () => {
    switch (true) {
      case material.includes('Gold') && material.length === 1:
        return goldFields();
      case material.includes('Silver') && material.length === 1:
        return silverFields();
      case material.includes('Diamond') && material.length === 1:
        return diamondFields();
      case material.includes('Gemstone') && material.length === 1:
        return gemstoneFields();
      case material.includes('Gold') &&
        material.includes('Diamond') &&
        material.length === 2:
        return (
          <>
            {goldFields()}
            {diamondFields()}
          </>
        );
      case material.includes('Gold') &&
        material.includes('Gemstone') &&
        material.length === 2:
        return (
          <>
            {goldFields()}
            {gemstoneFields()}
          </>
        );
      case material.includes('Silver') &&
        material.includes('Diamond') &&
        material.length === 2:
        return (
          <>
            {silverFields()}
            {diamondFields()}
          </>
        );
      case material.includes('Silver') &&
        material.includes('Gemstone') &&
        material.length === 2:
        return (
          <>
            {silverFields()}
            {gemstoneFields()}
          </>
        );
      case material.includes('Silver') &&
        material.includes('Gold') &&
        material.length === 2:
        return (
          <>
            {silverFields()}
            {goldFields()}
          </>
        );
      case material.includes('Gemstone') &&
        material.includes('Diamond') &&
        material.length === 2:
        return (
          <>
            {gemstoneFields()}
            {diamondFields()}
          </>
        );
      case material.includes('Gold') &&
        material.includes('Silver') &&
        material.includes('Diamond') &&
        material.length === 3:
        return (
          <>
            {silverFields()}
            {goldFields()}
            {diamondFields()}
          </>
        );
      case material.includes('Gold') &&
        material.includes('Silver') &&
        material.includes('Gemstone') &&
        material.length === 3:
        return (
          <>
            {silverFields()}
            {goldFields()}
            {gemstoneFields()}
          </>
        );
      case material.includes('Gold') &&
        material.includes('Diamond') &&
        material.includes('Gemstone') &&
        material.length === 3:
        return (
          <>
            {goldFields()}
            {diamondFields()}
            {gemstoneFields()}
          </>
        );
      case material.includes('Silver') &&
        material.includes('Diamond') &&
        material.includes('Gemstone') &&
        material.length === 3:
        return (
          <>
            {silverFields()}
            {gemstoneFields()}
            {diamondFields()}
          </>
        );
      case material.includes('Gold') &&
        material.includes('Silver') &&
        material.includes('Gemstone') &&
        material.includes('Diamond') &&
        material.length === 4:
        return (
          <>
            {goldFields()}
            {silverFields()}
            {gemstoneFields()}
            {diamondFields()}
          </>
        );

      default:
        return <h1>No Form found</h1>;
    }
  };

  //#region ERROR HANDLING
  const errorHandling = () => {
    if (
      description.no_of_pieces_available_inStock === '' &&
      validation === false
    ) {
      setNoOfPiecesAvailableInStockError(
        'Please enter no of pieces available in Stock'
      );
    } else {
      setNoOfPiecesAvailableInStockError('');
    }

    if (description.item === '' && validation === false) {
      setItemError('Please select item');
    } else {
      setItemError('');
    }

    if (description.specify === '' && validation === false) {
      setSpecifyError('Please specify');
    } else {
      setSpecifyError('');
    }

    if (description.additional_details === '' && validation === false) {
      setAdditional_detailsError('Please enter additional details');
    } else {
      setAdditional_detailsError('');
    }

    if (category === '' && validation === false) {
      setCategoryError('Please select category');
    } else {
      setCategoryError('');
    }

    if (material.length == 0 && validation === false) {
      setMaterialError('Please select material');
    } else {
      setMaterialError('');
    }

    if (url.length == 0 && validation === false) {
      setImageError('Please select Image');
    } else {
      setImageError('');
    }
  };

  const secPageErrorHandling = () => {
    if (dimension.inner_circumference === '' && validation2 === false) {
      setInnerCircumference('Please enter no of pieces available in Stock');
    } else if (dimension.inner_circumference !== '') {
      setInnerCircumference('');
    }

    if (dimension.length === '' && validation2 === false) {
      setLengthError('Please select item');
    } else if (dimension.length !== '') {
      setLengthError('');
    }

    if (dimension.depth === '' && validation2 === false) {
      setDepthError('Please specify');
    } else if (dimension.depth !== '') {
      setDepthError('');
    }

    if (dimension.meta_title === '' && validation2 === false) {
      setMetaTitleError('Please enter additional details');
    } else if (dimension.meta_title !== '') {
      setMetaTitleError('');
    }

    if (dimension.meta_description === '' && validation2 === false) {
      setMetaDescriptionError('Please select material');
    } else if (dimension.meta_description !== '') {
      setMetaDescriptionError('');
    }

    if (material.includes('Gold')) {
      if (goldProperties.purity === '' && validation2 === false) {
        setGoldPropertiesPurityError('Please enter purity');
      } else {
        setGoldPropertiesPurityError('');
      }
      if (goldProperties.weight === '' && validation2 === false) {
        setGoldPropertiesWeightError('Please enter weight');
      } else {
        setGoldPropertiesWeightError('');
      }
      if (goldProperties.making_charges === '' && validation2 === false) {
        setGoldPropertiesMakingChargesError('Please enter making charges');
      } else {
        setGoldPropertiesMakingChargesError('');
      }
      if (goldProperties.other_charges === '' && validation2 === false) {
        setGoldPropertiesOtherChargesError('Please enter other charges');
      } else {
        setGoldPropertiesOtherChargesError('');
      }
    }
    if (material.includes('Silver')) {
      if (silverProperties.purity === '' && validation2 === false) {
        setSilverPropertiesPurityError('Please enter purity');
      } else {
        setSilverPropertiesPurityError('');
      }
      if (silverProperties.weight === '' && validation2 === false) {
        setSilverPropertiesWeightError('Please enter weight');
      } else {
        setSilverPropertiesWeightError('');
      }
      if (silverProperties.making_charges === '' && validation2 === false) {
        setSilverPropertiesMakingChargesError('Please enter making charges');
      } else {
        setSilverPropertiesMakingChargesError('');
      }
      if (silverProperties.other_charges === '' && validation2 === false) {
        setSilverPropertiesOtherChargesError('Please enter other charges');
      } else {
        setSilverPropertiesOtherChargesError('');
      }
    }
  };
  //#endregion

  const getInfo = async (e) => {
    let formdata = new URLSearchParams();
    try {
      formdata.append('id', id);
      
      dispatch(getCustomProductDetail(token,formdata));
      // const user = await dataService.post(
      //   Api.Admin.getSingleCustomProductAdmin,
      //   formdata,
      //   {
      //     headers: {
      //       auth: token,
      //     },
      //   }
      // );
      // setGetData(user.data.data);
    } catch (err) {
      toast.error(err?.message || 'Something went wrong');
    }
  };

  const productDetail = AdminIndex.useSelector((state) => {
    return state.AdminSaga.productDetail;
  });

  useEffect(() => {
    setGetData(productDetail[0]);
  }, [productDetail]);

  const approveCustomProdcut = async () => {
    const urlencoded = new URLSearchParams();
    try {
      urlencoded.append('id', id);
      dispatch(approveCustomProduct(token,urlencoded,navigate));
      // const accept = await dataService.post(
      //   Api.Admin.approveCustomProductAdmin,
      //   urlencoded,
      //   {
      //     headers: {
      //       auth: token,
      //     },
      //   }
      // );
      // if (accept.data.status === 1) {
      //   toast.success('Custom Product accepted successfully');
      //   history.push('/admin/productmanagement/customproducts');
      // }
    } catch (err) {
      toast.error(err?.message || 'Something went wrong');
    }
  };
  //#endregion

  //#region RETURN JSX
  return (
    <div>
    <ScrollUp />
        {show ? (
          <Box className="addproduct-sec">
            <Box className="container">
              <Box sx={{ width: 1 }}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={{ xs: 2, sm: 5, md: 1, lg: 1 }}
                >
                  <Box
                    gridColumn={{
                      xs: 'span 12',
                      sm: 'span 12',
                      md: 'span 12',
                      lg: 'span 6',
                    }}
                  >
                    <Box className="addinfo-text-box-1">
                      <Typography variant="h2" className="addinfo-text-1">
                        Product Description:
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    gridColumn={{
                      xs: 'span 12',
                      sm: 'span 12',
                      md: 'span 12',
                      lg: 'span 12',
                    }}
                  >
                    <Box className="addproduct-textarea-main input-box ">
                      <FormHelperText className="input-lable">
                        Product Images
                      </FormHelperText>
                      <Box sx={{ width: '100%', position: 'relative' }}>
                        <Grid container>
                          {url?.map((element, index) => (
                            <Grid item xs={12} sm={6} md={4} lg={3} alignSelf="center">
                              <Box className="product-img-box product-img-box-set">
                                {/* <a key={element} href={element} download="file" target="/blank"
                                  onClick={(e) => downloadImageWithoutBlob(e)}> */}
                                  <Img
                                    // onClick={(e) => downloadImageWithoutBlob(e)}
                                    key={element}
                                    src={element}
                                    alt="no image"
                                    className="product-img-design"
                                    id={element}
                                  />
                                {/* </a> */}
                                <Box className="product-download-box"></Box>
                                <Tooltip title="Tap to Download">
                                  <DownloadIcon
                                    className="product-download-btn1"
                                    onClick={() => {
                                      let imgElement =
                                        document.getElementById(element);
                                      imgElement.addEventListener(
                                        'click',
                                        downloadImageWithoutBlob
                                      );
                                      imgElement.click();
                                      imgElement.removeEventListener(
                                        'click',downloadImageWithoutBlob);
                                    }}
                                  />
                                </Tooltip>
                              </Box>
                            </Grid>
                          ))}
                        </Grid>
                      </Box>
                    </Box>
                  </Box>

                  {/* <Box
                    gridColumn={{
                      xs: 'span 12',
                      sm: 'span 6',
                      md: 'span 6',
                      lg: 'span 6',
                    }}
                  >
                    <Box className="refrence-product-input addproduct-input-box">
                      <Box className="input-box addproduct-input-box">
                        <FormHelperText className="input-lable">
                          No of pieces available in stock
                        </FormHelperText>
                        <TextValidator
                          disabled
                          fullWidth
                          id="no_of_pieces_available_inStock"
                          className="form-control border-none"
                          value={description?.no_of_pieces_available_inStock}
                          name="no_of_pieces_available_inStock"
                          type="number"
                        />
                      </Box>
                    </Box>
                  </Box> */}
                  <Box
                    gridColumn={{
                      xs: 'span 12',
                      sm: 'span 6',
                      md: 'span 6',
                      lg: 'span 6',
                    }}
                  >
                    <Box className="refrence-product-input addproduct-drop-box">
                      <Box className="input-box ">
                        <FormHelperText className="input-lable">
                          Item
                        </FormHelperText>
                        <TextField
                          disabled
                          autoComplete="off"
                          fullWidth
                          id="specify"
                          className="form-control border-none"
                          value={description.item}
                          name="specify"
                          type="text"
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    gridColumn={{
                      xs: 'span 12',
                      sm: 'span 6',
                      md: 'span 6',
                      lg: 'span 6',
                    }}
                  >
                    <Box className="refrence-product-input addproduct-drop-box">
                      <Box className="input-box">
                        <FormHelperText className="input-lable">
                          Material
                        </FormHelperText>

                        <TextField
                          disabled
                          fullWidth
                          id="specify"
                          className="form-control border-none"
                          value={material}
                          name="specify"
                          type="text"
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    gridColumn={{
                      xs: 'span 12',
                      sm: 'span 6',
                      md: 'span 6',
                      lg: 'span 6',
                    }}
                  >
                    <Box className="refrence-product-input addproduct-drop-box">
                      <Box className="input-box">
                        <FormHelperText className="input-lable">
                          Category
                        </FormHelperText>

                        <TextField
                          disabled
                          fullWidth
                          id="specify"
                          className="form-control border-none"
                          value={category}
                          name="specify"
                          type="text"
                        />
                      </Box>
                    </Box>
                  </Box>

                  <Box
                    gridColumn={{
                      xs: 'span 12',
                      sm: 'span 6',
                      md: 'span 6',
                      lg: 'span 6',
                    }}
                  >
                    <Box className="refrence-product-input">
                      <Box className="input-box addproduct-input-box">
                        <FormHelperText className="input-lable">
                          Specify
                        </FormHelperText>
                        <TextField
                          disabled
                          fullWidth
                          id="specify"
                          className="form-control border-none"
                          value={description.specify}
                          name="specify"
                          type="text"
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    gridColumn={{
                      xs: 'span 12',
                      sm: 'span 12',
                      md: 'span 12',
                      lg: 'span 12',
                    }}
                  >
                    <Box className="addproduct-textarea-main input-box ">
                      <FormHelperText className="input-lable">
                        Additional Details
                      </FormHelperText>
                      <TextareaAutosize
                        disabled
                        fullWidth
                        id="additional_details"
                        className="addinfo-textarea admin-addproduct-area text-area-control-admin"
                        value={description.additional_details}
                        name="additional_details"
                        type="text"
                      />
                    </Box>
                  </Box>
                  <Box
                    gridColumn={{
                      xs: 'span 12',
                      sm: 'span 12',
                      md: 'span 12',
                      lg: 'span 12',
                    }}
                  >
                    <Box className="right-btn-main">
                      <ApproveButton
                        disabled={
                          getData?.status == '1' ||
                          (getData?.status == '2' && true)
                        }
                        display={getData?.status == '2' && 'none'}
                        onClick={(e) => {
                          if (
                            window.confirm(
                              'Are you sure you want to approve this Custome product?'
                            )
                          ) {
                            approveCustomProdcut(e);
                          }
                        }}
                      />
                      <Box sx={{ ml: 1 }}></Box>
                      <DeclineButton
                        disabled={getData?.status == '2' && true}
                        display={getData?.status == '1' && 'none'}
                        onClick={(e) => {
                          if (
                            window.confirm(
                              'Are you sure you want to decline this Custome product?'
                            )
                          ) {
                            handleOpen(e);
                          }
                        }}
                      />
                      <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <form onSubmit={handleSubmit(reject)}>
                    <Box sx={style}>
                      <Typography>Rejection reason</Typography>
                      <Box className="text-area-control-main">
                        <TextareaAutosize
                          autoComplete="off"
                          sx={{
                            mt: 2,
                            width: { lg: '300px', xs: '150px' },
                          }}
                          size="small"
                          fullWidth
                          // id="rejectReason"
                          className="form-control border-none text-area-control1 text-area-control-admin"
                          name="rejectReason"
                          type="text"
                          {...register('rejectReason', {
                            required: (
                              <TextAreaErrorMsg msg="Please enter Rejection reason" />
                            ),
                            minLength: {
                              value: minRejectReasonLength,
                              message: (
                                <TextAreaErrorMsg
                                  msg={`Minimum ${minRejectReasonLength} Characters Allowed`}
                                />
                              ),
                            },
                            maxLength: {
                              value: rejectReasonLength,
                              message: (
                                <TextAreaErrorMsg
                                  msg={`Maximum ${rejectReasonLength} Characters Allowed`}
                                />
                              ),
                            },
                            pattern: {
                              value: NO_WHITE_SPACE_BEGINNING_END,
                              message: (
                                <TextAreaErrorMsg msg="Whitespace is not allowed at beginning or end" />
                              ),
                            },
                          })}
                        />
                        <ErrorMessage errors={errors} name="rejectReason" />
                      </Box>
                      <Button
                        sx={{ mt: 2, textTransform: 'none' }}
                        variant="contained"
                        type="submit"
                      >
                        Submit
                      </Button>
                    </Box>
                  </form>
                      </Modal>
                    </Box>
                  </Box>
                  <Box
                    gridColumn={{
                      xs: 'span 12',
                      sm: 'span 12',
                      md: 'span 12',
                      lg: 'span 12',
                    }}
                  >
                    <Box className="primary-btn-box next-btn-box product-details-btn-box">
                      <Button
                        variant="contained"
                        className=" next-btn-1"
                        onClick={(e) => {
                          nextPage(e);
                        }}
                      >
                        Next
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        ) : (
          <Box className="addproduct-1-sec">
          <ScrollUp />
            <Box className="container">
              <Box>{renderFieldMaterialWise()}</Box>
              <Box sx={{ width: 1, mt: 3 }}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={{ xs: 0, sm: 1, md: 1, lg: 1 }}
                >
                  <Box
                    gridColumn={{
                      xs: 'span 12',
                      sm: 'span 12',
                      md: 'span 12',
                      lg: 'span 12',
                    }}
                  >
                    <Box className="addinfo-text-box-1">
                      <Typography
                        variant="h2"
                        className="addinfo-text-1 product-dimension-1"
                      >
                        Product Dimensions
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    gridColumn={{
                      xs: 'span 12',
                      sm: 'span 6',
                      md: 'span 4',
                      lg: 'span 4',
                    }}
                  >
                    <Box className="refrence-product-input addproduct-1-input-box">
                      <Box className="input-box addproduct-1-input-box">
                        <FormHelperText className="input-lable">
                          Inner circumference
                        </FormHelperText>
                        <TextField
                          disabled
                          fullWidth
                          id="inner_circumference"
                          className="form-control border-none"
                          value={dimension.inner_circumference}
                          name="inner_circumference"
                          type="number"
                        />
                        <span className="addproduct-mm-text">mm</span>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    gridColumn={{
                      xs: 'span 12',
                      sm: 'span 6',
                      md: 'span 3',
                      lg: 'span 3',
                    }}
                  >
                    <Box className="refrence-product-input">
                      <Box className="input-box addproduct-1-input-box">
                        <FormHelperText className="input-lable">
                          Length
                        </FormHelperText>
                        <TextField
                          disabled
                          fullWidth
                          id="length"
                          className="form-control border-none"
                          value={dimension.length}
                          name="length"
                          type="number"
                        />
                        <span className="addproduct-mm-text">mm</span>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    gridColumn={{
                      xs: 'span 12',
                      sm: 'span 6',
                      md: 'span 5',
                      lg: 'span 5',
                    }}
                  >
                    <Box className="refrence-product-input">
                      <Box className="input-box addproduct-1-input-box">
                        <FormHelperText className="input-lable">
                          Depth
                        </FormHelperText>
                        <TextField
                          disabled
                          fullWidth
                          id="depth"
                          className="form-control border-none"
                          value={dimension.depth}
                          name="depth"
                          type="number"
                        />
                        <span className="addproduct-mm-text">mm</span>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    gridColumn={{
                      xs: 'span 12',
                      sm: 'span 6',
                      md: 'span 12',
                      lg: 'span 12',
                    }}
                  >
                    <Box className="refrence-product-input">
                      <Box className="input-box addproduct-1-input-box">
                        <FormHelperText className="input-lable">
                          Meta Title
                        </FormHelperText>
                        <TextField
                          disabled
                          fullWidth
                          id="meta_title"
                          className="form-control border-none"
                          value={dimension.meta_title}
                          name="meta_title"
                          type="text"
                        />
                        <Typography
                          variant="p"
                          component="p"
                          className="form-validation-text"
                        >
                          A short 2-3 words
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    gridColumn={{
                      xs: 'span 12',
                      sm: 'span 12',
                      md: 'span 12',
                      lg: 'span 12',
                    }}
                  >
                    <Box className="addproduct-textarea-main input-box ">
                      <FormHelperText className="input-lable">
                        Meta Description
                      </FormHelperText>
                      <TextareaAutosize
                        disabled
                        fullWidth
                        id="meta_description"
                        className="addinfo-textarea admin-addproduct-area text-area-control-admin"
                        value={dimension.meta_description}
                        name="meta_description"
                        type="text"
                      />
                    </Box>
                  </Box>
                  <Box
                    gridColumn={{
                      xs: 'span 12',
                      sm: 'span 12',
                      md: 'span 12',
                      lg: 'span 12',
                    }}
                  >
                    <Box className="primary-btn-box next-back-btn-box">
                      <Button
                        variant="contained"
                        className=" back-btn-1"
                        onClick={() => {
                          setShow(true);
                        }}
                      >
                        Back
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
    </div>
  );
};

export default ViewCustomProduct;
//#endregion
