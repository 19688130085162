import React, { useEffect, useState } from "react";
import UserIndex from "../../UserIndex";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { price } from "../../../../component/customComponents/commonFunctions/CommonFunctions";

const { AllPreciousStoneProductsSaga, DeleteUserProductSaga } =
  UserIndex.UserSagaActions;

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const params = UserIndex.useParams();

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <UserIndex.Box sx={{ p: 3 }}>
          <UserIndex.Typography>{children}</UserIndex.Typography>
        </UserIndex.Box>
      )}
    </div>
  );
}

const MyPreciousStone = () => {
  const [value, setValue] = useState(0);
  const [tab, setTab] = useState(0);
  const dispatch = UserIndex.useDispatch();
  const history = UserIndex.useHistory();
  const navigate = UserIndex.useHistory();
  const loding = UserIndex.useSelector((state) => {
    return state.Usersaga.loading;
  });
  const Token = UserIndex.useSelector((state) => {
    return state.Usersaga.Token;
  });
  const AllPreciousStoneProductsData = UserIndex.useSelector((state) => {
    return state.Usersaga.AllPreciousStoneProductsData;
  });

  const DeleteUserProductData = UserIndex.useSelector((state) => {
    return state.Usersaga.Productdata;
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [products, setProducts] = useState([]);

  const getUserProduct = async () => {
    let urlencoded = new URLSearchParams();
    urlencoded.append("status", tab);
    dispatch(AllPreciousStoneProductsSaga(urlencoded, navigate, Token));
  };

  const getDeleteUserProduct = async (id) => {
    let urlencoded = new URLSearchParams();
    urlencoded.append("id", id);
    dispatch(DeleteUserProductSaga(urlencoded, navigate, Token));
  };

  useEffect(() => {
    getUserProduct();
  }, [tab]);

  useEffect(() => {
    setProducts(AllPreciousStoneProductsData);
  }, [AllPreciousStoneProductsData]);

  const onTop = () => {
    window.scrollTo({ top: 0 });
  };

  useEffect(() => {
    onTop();
  }, []); 

  return (
    <>
      <div>
        <ToastContainer />
        <UserIndex.Header />
        <UserIndex.Box className="body-bg">
          <UserIndex.Box className="myproduct-sec">
            <UserIndex.Box className="container">
              <UserIndex.Box className="row">
                <UserIndex.Box className="myproduct-title-box">
                  <UserIndex.Typography
                    variant="h4"
                    className="myproduct-title"
                  >
                    <span>Precious Stones</span>
                  </UserIndex.Typography>
                </UserIndex.Box>
              </UserIndex.Box>
              <UserIndex.Box className="myproduct-tabs-box">
                <UserIndex.Box className="my-product-tabs-main">
                  <UserIndex.Box sx={{ width: "100%" }}>
                    <UserIndex.Box>
                      <UserIndex.Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                        className="myproduct-tabs"
                      >
                        <UserIndex.Tab
                          label="Uploaded"
                          value={1}
                          onClick={() => setTab(1)}
                          className="myproduct-tabs-lable"
                        />
                        <UserIndex.Tab
                          label="Pending"
                          value={0}
                          onClick={() => setTab(0)}
                          className="myproduct-tabs-lable"
                        />
                        <UserIndex.Tab
                          label="Rejected"
                          value={2}
                          onClick={() => setTab(2)}
                          className="myproduct-tabs-lable"
                        />
                      </UserIndex.Tabs>
                    </UserIndex.Box>
                  </UserIndex.Box>
                </UserIndex.Box>
                <UserIndex.Box className="add-product-btn-main">
                  <UserIndex.Box className="primary-btn-box">
                    <UserIndex.PrimaryButton
                      btnclass="primary-btn add-product-btn"
                      buttonvalue="Add Product"
                      onClick={() => history.push("/user/preciousstone")}
                    />
                  </UserIndex.Box>
                </UserIndex.Box>
              </UserIndex.Box>
              <UserIndex.Box className="my-product-tabs-content">
                <UserIndex.Box sx={{ width: 1, mt: 4 }}>
                  <UserIndex.Grid container spacing={2}>
                    {products?.length ? (
                      products.map((porductList, err) => {
                        return (
                          <UserIndex.Grid item md={3}>
                            <UserIndex.Box className="product-box">
                              <UserIndex.Box className="product-img-box">
                                <img
                                  src={porductList.image[0]}
                                  className="product-img"
                                ></img>
                                <UserIndex.Box className="show-hover">
                                  <UserIndex.Box className="add-cart-box-hover">
                                    {/* <UserIndex.Button className="btn hover-btns">
                                      <UserIndex.Box className="add-cart-bg">
                                        <img
                                          src={UserIndex.svg.iconheart}
                                          className="hover-img"
                                        ></img>
                                      </UserIndex.Box>
                                    </UserIndex.Button> */}

                                    {porductList.status === "1" ? (
                                      <UserIndex.Button
                                        onClick={() => {
                                          getDeleteUserProduct(porductList._id);
                                        }}
                                        className="btn hover-btns"
                                      >
                                        <UserIndex.Box className="add-cart-bg">
                                          <img
                                            src={UserIndex.svg.trashIcon}
                                            className="hover-img"
                                          ></img>
                                        </UserIndex.Box>
                                      </UserIndex.Button>
                                    ) : (
                                      ""
                                    )}

                                    {porductList.status === "1" ? (
                                      ""
                                    ) : (
                                      <UserIndex.Button
                                        onClick={() => {
                                          history.push(
                                            `/user/preciousstoneid/${porductList._id}`
                                          );
                                        }}
                                        className="btn hover-btns"
                                      >
                                        <UserIndex.Box className="add-cart-bg">
                                          <img
                                            src={UserIndex.svg.iconedit}
                                            className="hover-img"
                                          ></img>
                                        </UserIndex.Box>
                                      </UserIndex.Button>
                                    )}
                                  </UserIndex.Box>
                                </UserIndex.Box>
                              </UserIndex.Box>
                              <UserIndex.Box className="product-content">
                                <UserIndex.Box className="product-title">
                                  {porductList.item_id.item}
                                </UserIndex.Box>
                                <UserIndex.Box className="product-sub-title">
                                  {porductList?.materialIdArray?.map(
                                    (material) => {
                                      return (
                                        <>{material?.material_id?.material}</>
                                      );
                                    }
                                  )}
                                </UserIndex.Box>
                                <UserIndex.Box className="product-price">
                                  ₹
                                  {/* {porductList.gold
                                    ? parseInt(
                                        porductList?.gold?.customer_payable_amount
                                      )
                                    : porductList.silver
                                    ? parseInt(
                                        porductList?.silver?.customer_payable_amount
                                      )
                                    : porductList?.material_id?.material === "Diamond"
                                    ? parseInt(
                                        porductList?.customer_payable_amount
                                      ) 
                                    : porductList?.material_id?.material === "Gemstone"
                                    ? 
                                      porductList?.customer_payable_amount
                                    : ""}  */}
                                {price(porductList?.total_payable_amount)}
                                </UserIndex.Box>
                                <UserIndex.Box className="product-btn-box">
                                  <UserIndex.PrimaryButton
                                    btnclass="btn btn-product"
                                    buttonvalue="View"
                                    onClick={() => {
                                      history.push(
                                        `/user/detailspageid/${porductList._id}`
                                      );
                                    }}
                                  />
                                </UserIndex.Box>
                              </UserIndex.Box>
                            </UserIndex.Box>
                          </UserIndex.Grid>
                        );
                      })
                    ) : (
                      <UserIndex.Grid item md={12}>
                        {" "}
                        No Records Found
                      </UserIndex.Grid>
                    )}
                  </UserIndex.Grid>
                </UserIndex.Box>
              </UserIndex.Box>
            </UserIndex.Box>
          </UserIndex.Box>
        </UserIndex.Box>
        <UserIndex.Footer />
      </div>
    </>
  );
};

export default MyPreciousStone;
