import React from 'react';
import { Button } from '@mui/material';

const commonButton = (props) => {
    return (
      <Button
        sx={{ mx: 1, borderRadius: 0, textTransform: 'none', display:props.display }}
        variant="contained"
        onClick={props.onClick}
        type={props.type}
      >
        {props.name}
      </Button>
    );
  };

  export default commonButton;