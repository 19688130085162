import { Box } from "@mui/material";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';

export const RupeeSymbol = ({heading}) => {
    return (
      <>
        <Box sx={{ alignItems: 'center', display: 'flex' }}>
          <span>{heading}</span>
          <CurrencyRupeeIcon sx={{ width: '15px', ml: 0.5 }}></CurrencyRupeeIcon>
        </Box>
      </>
    );
  };