import React, { useEffect, useState } from "react";
import { price } from "../../../../component/customComponents/commonFunctions/CommonFunctions";
import UserIndex from "../../UserIndex";

const {
  GetAllProductHomeServicesSaga,
  TrendingProductsSaga,
  ListTopDealsSaga,
  CatelogueItemsSaga,
  addcartSaga,
} = UserIndex.UserSagaActions;

export default function Home() {
  const dispatch = UserIndex.useDispatch();
  const history = UserIndex.useHistory();
  const navigate = UserIndex.useHistory();

  const [allProduct, setAllProduct] = useState([]);
  const [trendingProducts, setTrendingProducts] = useState([]);
  const [topDeals, setTopDeals] = useState([]);
  const [items, setItems] = useState([]);

  const getAllUserProducts = async () => {
    dispatch(GetAllProductHomeServicesSaga(navigate));
  };

  const token = UserIndex.useSelector((state) => {
    return state.Usersaga.Token;
  });

  const Userdata = UserIndex.useSelector((state) => {
    return state.Usersaga.Userdata;
  });

  const GetAllProductHomeData = UserIndex.useSelector((state) => {
    return state.Usersaga.GetAllProductHomeData;
  });

  const CatelogueItemsData = UserIndex.useSelector((state) => {
    return state.Usersaga.CatelogueItemsData;
  });

  const Cartdata = UserIndex.useSelector((state) => {
    return state.Usersaga.Cartdata;
  });

  const getAddToCart = (cartdata, data) => {
    let urlencoded = new URLSearchParams();
    // if (item) {
    urlencoded.append("productId", data._id);
    // }
    //  else {
    //   urlencoded.append('productId', individual._id)
    // }
    urlencoded.append("quantity", "1");
    urlencoded.append("shipping", "100");
    dispatch(addcartSaga(urlencoded, navigate, token));
  };

  const getListItem = async () => {
    dispatch(CatelogueItemsSaga(navigate));
  };

  useEffect(() => {
    getListItem();
  }, []);

  useState(() => {
    setItems(CatelogueItemsData);
  }, [CatelogueItemsData]);

  useEffect(() => {
    getAllUserProducts();
  }, []);

  useEffect(() => {
    setAllProduct(GetAllProductHomeData);
  }, [GetAllProductHomeData]);

  const TrendingProductsData = UserIndex.useSelector((state) => {
    return state.Usersaga.TrendingProductsData;
  });

  const TrendingProducts = () => {
    dispatch(TrendingProductsSaga(navigate));
  };

  const ListTopDealsData = UserIndex.useSelector((state) => {
    return state.Usersaga.ListTopDealsData;
  });

  const getListTopDeals = async () => {
    dispatch(ListTopDealsSaga(navigate));
  };

  useEffect(() => {
    TrendingProducts();
    getListTopDeals();
  }, []);

  useEffect(() => {
    setTrendingProducts(TrendingProductsData);
  }, [TrendingProductsData]);

  useEffect(() => {
    setTopDeals(ListTopDealsData);
  }, [ListTopDealsData]);

  const showToastMessage = () => {
    UserIndex.toast.error("Please login first to view/buy product");
  };

  const outOfStock = () => UserIndex.toast.error("Out Of Stock");

  const requestCustomeProduct = (data) => {
    if (token === "") {
      showToastMessage();
    } else {
      history.push(`/user/ProductDetails/${data._id}`);
    }
  };

  return (
    <div>
      <UserIndex.Header />
      <UserIndex.Box className="banner-main">
        <UserIndex.Box className="banner-left-img-box">
          <img src={UserIndex.png.bannerleft} className="banner-left-img"></img>
        </UserIndex.Box>
        <UserIndex.Box className="banner-right-img-box">
          <UserIndex.Box className="bg-overlay"></UserIndex.Box>
          <img
            src={UserIndex.png.bannerright}
            className="banner-right-img"
          ></img>
          <UserIndex.Box className="banner-right-content">
            <UserIndex.Box className="banner-sub-title">SPECIAL</UserIndex.Box>
            <UserIndex.Box className="banner-title">
              Amazing
              <br />
              <span className="jewelry-text">Jewellery</span> Collection
            </UserIndex.Box>
          </UserIndex.Box>
        </UserIndex.Box>
      </UserIndex.Box>

      <UserIndex.Box className="antic-jewelry-sec">
        <UserIndex.Box className="container">
          <UserIndex.Box className="sec-antic-title">
            Antique Jewellery.
            <br />
            Beauty that is born
            <br /> of imperfection
          </UserIndex.Box>
          <UserIndex.Box className="sec-antic-title-mobile">
            Antique Jewellery,
            <br />
            Beauty that is born of imperfection
          </UserIndex.Box>
          <UserIndex.Box sx={{ width: 1 }}>
            <UserIndex.Box
              display="grid"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 3, sm: 5, md: 4, lg: 4 }}
            >
              <UserIndex.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 4",
                }}
              >
                <UserIndex.Box className="antic-jewelry-box">
                  <UserIndex.Box className="antic-jewelry-img-box">
                    <img
                      src={UserIndex.png.antic1}
                      className="antic-jewelry-img"
                    ></img>
                    <UserIndex.Box className="antic-jewelry-content-box">
                      <UserIndex.Box className="antic-jewelry-sub-title">
                        Collections
                      </UserIndex.Box>
                      <UserIndex.Box className="antic-jewelry-title">
                        Latest Bracelets
                      </UserIndex.Box>
                    </UserIndex.Box>
                  </UserIndex.Box>
                </UserIndex.Box>
              </UserIndex.Box>
              <UserIndex.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 4",
                }}
              >
                <UserIndex.Box className="antic-jewelry-box">
                  <UserIndex.Box className="antic-jewelry-img-box">
                    <img
                      src={UserIndex.png.antic2}
                      className="antic-jewelry-img2"
                    ></img>
                    <UserIndex.Box className="antic-jewelry-content-box">
                      <UserIndex.Box className="antic-jewelry-sub-title">
                        Collections
                      </UserIndex.Box>
                      <UserIndex.Box className="antic-jewelry-title">
                        Neck Laces
                      </UserIndex.Box>
                    </UserIndex.Box>
                  </UserIndex.Box>
                </UserIndex.Box>
              </UserIndex.Box>
              <UserIndex.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 4",
                }}
              >
                <UserIndex.Box className="antic-jewelry-box antic-jewelry-last-box">
                  <UserIndex.Box className="antic-jewelry-img-box">
                    <img
                      src={UserIndex.png.antic3}
                      className="antic-jewelry-img3"
                    ></img>
                    <UserIndex.Box className="antic-jewelry-content-box">
                      <UserIndex.Box className="antic-jewelry-sub-title">
                        New Arrivals
                      </UserIndex.Box>
                      <UserIndex.Box className="antic-jewelry-title">
                        Best Drop Earings
                      </UserIndex.Box>
                    </UserIndex.Box>
                  </UserIndex.Box>
                </UserIndex.Box>
              </UserIndex.Box>
            </UserIndex.Box>
          </UserIndex.Box>
        </UserIndex.Box>
      </UserIndex.Box>

      <UserIndex.Box className="trending-sec">
        <UserIndex.Box className="container">
          <UserIndex.Box className="sec-main-title">Trending</UserIndex.Box>
          <UserIndex.Box sx={{ width: 1 }}>
            <UserIndex.Box
              display="grid"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 3, sm: 5, md: 4, lg: 4 }}
            >
              {trendingProducts.length
                ? trendingProducts.map((data, index) => {
                    return (
                      <UserIndex.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 3",
                          lg: "span 3",
                        }}
                      >
                        <UserIndex.Box className="product-box">
                          <UserIndex.Box className="product-img-box">
                            <img
                              src={data.image[0]}
                              className="product-img"
                            ></img>
                            {token && token !== null ? (
                              <>
                                {data.user_id == Userdata?.[0]?._id ? (
                                  ""
                                ) : (
                                  <>
                                    {data?.no_of_pieces_available_inStock !==
                                    0 ? (
                                      <>
                                        <UserIndex.Box className="show-hover">
                                          <UserIndex.Box className="add-cart-box-hover">
                                            <UserIndex.Button
                                              onClick={() => {
                                                getAddToCart(Cartdata, data);
                                              }}
                                              className="btn hover-btns"
                                            >
                                              <UserIndex.Box className="add-cart-bg">
                                                <img
                                                  src={UserIndex.svg.iconcart}
                                                  className="hover-img"
                                                ></img>
                                              </UserIndex.Box>
                                            </UserIndex.Button>
                                          </UserIndex.Box>
                                        </UserIndex.Box>
                                      </>
                                    ) : (
                                      <>
                                        <UserIndex.Box className="show-hover">
                                          <UserIndex.Box className="add-cart-box-hover">
                                            <UserIndex.Button
                                              onClick={() => {
                                                outOfStock();
                                              }}
                                              className="btn hover-btns"
                                            >
                                              <UserIndex.Box className="add-cart-bg">
                                                <img
                                                  src={UserIndex.svg.iconcart}
                                                  className="hover-img"
                                                ></img>
                                              </UserIndex.Box>
                                            </UserIndex.Button>
                                          </UserIndex.Box>
                                        </UserIndex.Box>
                                      </>
                                    )}
                                  </>
                                )}
                              </>
                            ) : (
                              <>
                                <UserIndex.Box className="show-hover">
                                  <UserIndex.Box className="add-cart-box-hover">
                                    <UserIndex.Button
                                      onClick={() => {
                                        showToastMessage();
                                      }}
                                      className="btn hover-btns"
                                    >
                                      <UserIndex.Box className="add-cart-bg">
                                        <img
                                          src={UserIndex.svg.iconcart}
                                          className="hover-img"
                                        ></img>
                                      </UserIndex.Box>
                                    </UserIndex.Button>
                                  </UserIndex.Box>
                                </UserIndex.Box>
                              </>
                            )}
                          </UserIndex.Box>
                          <UserIndex.Box className="product-content">
                            <UserIndex.Box className="product-title">
                              {data.item_id.item}
                            </UserIndex.Box>
                            <UserIndex.Box className="product-sub-title">
                              {data?.materialIdArray?.map((material, i) => {
                                return (
                                  <>
                                    <span>
                                      {material?.material_id?.material}
                                      {data?.materialIdArray?.length - 1 === i
                                        ? " "
                                        : ", "}
                                    </span>
                                  </>
                                );
                              })}
                            </UserIndex.Box>
                            <UserIndex.Box className="product-price">
                              {token && token !== null ? (
                                <>₹{price(data?.total_payable_amount)}</>
                              ) : (
                                ""
                              )}
                            </UserIndex.Box>
                            <UserIndex.Box className="product-btn-box">
                              <UserIndex.PrimaryButton
                                btnclass="btn btn-product"
                                onClick={() => {
                                  requestCustomeProduct(data);
                                }}
                                buttonvalue="View"
                              />
                            </UserIndex.Box>
                          </UserIndex.Box>
                        </UserIndex.Box>
                      </UserIndex.Box>
                    );
                  })
                : ""}
            </UserIndex.Box>
          </UserIndex.Box>
        </UserIndex.Box>
      </UserIndex.Box>

      <UserIndex.Box className="top-deals-sec">
        <UserIndex.Box className="container">
          <UserIndex.Box className="sec-main-title">Top Deals</UserIndex.Box>
          <UserIndex.Box sx={{ width: 1 }}>
            <UserIndex.Box
              display="grid"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 3, sm: 5, md: 4, lg: 4 }}
            >
              {topDeals?.length
                ? topDeals?.map((deals, index) => {
                    return (
                      <UserIndex.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                      >
                        <UserIndex.Box className="top-deals-main-box">
                          <UserIndex.Box className="top-deals-left-img-box">
                            <img
                              src={deals.image}
                              className="top-deals-img"
                            ></img>
                          </UserIndex.Box>
                          <UserIndex.Box className="top-deals-details-box">
                            <UserIndex.Box className="top-deals-sub-title">
                              {deals.heading}
                            </UserIndex.Box>
                            <UserIndex.Box className="top-deals-title">
                              {deals.sub_heading}
                            </UserIndex.Box>
                            <UserIndex.Box className="top-deals-btn-box">
                              <UserIndex.Button className="btn top-deals-btn">
                                Shop Now
                              </UserIndex.Button>
                            </UserIndex.Box>
                          </UserIndex.Box>
                        </UserIndex.Box>
                      </UserIndex.Box>
                    );
                  })
                : ""}
            </UserIndex.Box>
          </UserIndex.Box>
        </UserIndex.Box>
      </UserIndex.Box>

      <UserIndex.Box className="catalogues-sec">
        <UserIndex.Box className="container">
          <UserIndex.Box className="catalogues-row">
            <UserIndex.Box className="sec-main-title">Catalogues</UserIndex.Box>

            <UserIndex.Box className="product-btn-box view-more-catalogues">
              <UserIndex.Button
                className="btn btn-product"
                onClick={() => {
                  history.push(`/user/catalogues`);
                }}
              >
                View More
              </UserIndex.Button>
            </UserIndex.Box>
          </UserIndex.Box>

          <UserIndex.Box sx={{ width: 1 }}>
            <UserIndex.Box
              display="grid"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 3, sm: 5, md: 4, lg: 4 }}
            >
              {items?.length
                ? items?.map((item, index) => {
                    return (
                      <>
                        {index < 4 ? (
                          <UserIndex.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 3",
                              lg: "span 3",
                            }}
                            sx={{ cursor: "pointer" }}
                            onClick={() => {
                              history.push(
                                `/user/cataloguesseller/${item?._id}`
                              );
                            }}
                          >
                            <UserIndex.Box className="product-box">
                              <UserIndex.Box className="product-img-box">
                                <img
                                  src={item?.image}
                                  className="product-img"
                                ></img>
                              </UserIndex.Box>
                              <UserIndex.Box className="product-content">
                                <UserIndex.Box className="product-title">
                                  {item?.item}
                                </UserIndex.Box>
                              </UserIndex.Box>
                            </UserIndex.Box>
                          </UserIndex.Box>
                        ) : (
                          ""
                        )}
                      </>
                    );
                  })
                : ""}
            </UserIndex.Box>
          </UserIndex.Box>
        </UserIndex.Box>
      </UserIndex.Box>

      {allProduct && allProduct?.length ? (
        <>
          <UserIndex.Box className="precious-sec">
            <UserIndex.Box className="container">
              <UserIndex.Box className="sec-main-title">
                Precious Stones
              </UserIndex.Box>

              <UserIndex.Box sx={{ width: 1 }}>
                <UserIndex.Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={{ xs: 3, sm: 5, md: 4, lg: 4 }}
                >
                  {allProduct?.map((data) => {
                    console.log(data,'nirmal')
                    return (
                      <UserIndex.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 3",
                          lg: "span 3",
                        }}
                      >
                        <UserIndex.Box className="product-box">
                          <UserIndex.Box className="product-img-box">
                            <img
                              src={data.image[0]}
                              className="product-img"
                            ></img>
                            {token && token !== null ? (
                              <>
                                {data?.user_id == Userdata?.[0]?._id ? (
                                  ""
                                ) : (
                                  <>
                                    {data?.no_of_pieces_available_inStock !==
                                    0 ? (
                                      <>
                                        <UserIndex.Box className="show-hover">
                                          <UserIndex.Box className="add-cart-box-hover">
                                            <UserIndex.Button
                                              onClick={() => {
                                                getAddToCart(Cartdata, data);
                                              }}
                                              className="btn hover-btns"
                                            >
                                              <UserIndex.Box className="add-cart-bg">
                                                <img
                                                  src={UserIndex.svg.iconcart}
                                                  className="hover-img"
                                                ></img>
                                              </UserIndex.Box>
                                            </UserIndex.Button>
                                          </UserIndex.Box>
                                        </UserIndex.Box>
                                      </>
                                    ) : (
                                      <>
                                        <UserIndex.Box className="show-hover">
                                          <UserIndex.Box className="add-cart-box-hover">
                                            <UserIndex.Button
                                              onClick={() => {
                                                outOfStock();
                                              }}
                                              className="btn hover-btns"
                                            >
                                              <UserIndex.Box className="add-cart-bg">
                                                <img
                                                  src={UserIndex.svg.iconcart}
                                                  className="hover-img"
                                                ></img>
                                              </UserIndex.Box>
                                            </UserIndex.Button>
                                          </UserIndex.Box>
                                        </UserIndex.Box>
                                      </>
                                    )}
                                  </>
                                )}
                              </>
                            ) : (
                              <>
                                <UserIndex.Box className="show-hover">
                                  <UserIndex.Box className="add-cart-box-hover">
                                    <UserIndex.Button
                                      onClick={() => {
                                        showToastMessage();
                                      }}
                                      className="btn hover-btns"
                                    >
                                      <UserIndex.Box className="add-cart-bg">
                                        <img
                                          src={UserIndex.svg.iconcart}
                                          className="hover-img"
                                        ></img>
                                      </UserIndex.Box>
                                    </UserIndex.Button>
                                  </UserIndex.Box>
                                </UserIndex.Box>
                              </>
                            )}
                          </UserIndex.Box>
                          <UserIndex.Box className="product-content">
                            <UserIndex.Box className="product-title">
                              {data?.item_id?.item&&data?.item_id?.item}
                            </UserIndex.Box>
                            <UserIndex.Box className="product-sub-title">
                              {data?.materialIdArray?.map((material, i) => {
                                return (
                                  <>
                                    <span>
                                      {material?.material_id?.material}
                                      {data?.materialIdArray?.length - 1 === i
                                        ? " "
                                        : ", "}
                                    </span>
                                  </>
                                );
                              })}
                            </UserIndex.Box>
                            <UserIndex.Box className="product-price">
                              {token && token !== null ? (
                                <>₹{price(data?.total_payable_amount)}</>
                              ) : (
                                ""
                              )}
                            </UserIndex.Box>
                            <UserIndex.Box className="product-btn-box">
                              <UserIndex.PrimaryButton
                                btnclass="btn btn-product"
                                onClick={() => {
                                  requestCustomeProduct(data);
                                }}
                                buttonvalue="View"
                              />
                            </UserIndex.Box>
                          </UserIndex.Box>
                        </UserIndex.Box>
                      </UserIndex.Box>
                    );
                  })}
                </UserIndex.Box>
              </UserIndex.Box>
            </UserIndex.Box>
          </UserIndex.Box>
        </>
      ) : (
        ""
      )}

      <UserIndex.Footer />
    </div>
  );
}
