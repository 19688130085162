import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import { Button, Typography, CircularProgress } from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { useHistory, useParams } from 'react-router-dom'
import { log } from '../../../config/CommonFunctions'
import { useDispatch, useSelector } from 'react-redux'
import UserSagaActions from '../../../redux/Usersaga/action'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

const { AllproductSaga, ListMaterialSaga, ListItemSaga } = UserSagaActions

const FilterSection = (props) => {
  const { item } = props
  let params = useParams()
  const dispatch = useDispatch()
  const navigate = useHistory()
  let id = params.item
  let history = useHistory()
  const [checked, setChecked] = useState(true)
  const [productTypeFilter, setProductTypeFilter] = useState([])
  const [materialData, setMaterialData] = useState([])
  const [categoryMenu, setCategoryMenu] = useState()
  const [subCategoryFilter, setSubCategoryFilter] = useState([])
  const [weightData, setWeightData] = useState('')
  const [materialFilter, setMaterialFilter] = useState([])
  const [itemList, setItemList] = useState([])

  const Categorymenudata = useSelector((state) => {
    return state.Usersaga.Categorymenudata
  })

  const AllProductdata = useSelector((state) => {
    return state.Usersaga.AllProductdata
  })

  const ListItemData = useSelector((state) => {
    return state.Usersaga.ListItemData
  })

  const ListMaterialData = useSelector((state) => {
    return state.Usersaga.ListMaterialData
  })

  const getListItem = async () => {
    let urlencoded = new URLSearchParams()
    dispatch(ListItemSaga(urlencoded, navigate))
  }

  const getListMaterial = async () => {
    let urlencoded = new URLSearchParams()
    dispatch(ListMaterialSaga(urlencoded, navigate))
  }

  useEffect(() => {
    getListItem()
    getListMaterial()
  }, [])

  useEffect(() => {
    setItemList(ListItemData)
  }, [ListItemData])

  useEffect(() => {
    setMaterialData(ListMaterialData)
  }, [ListMaterialData])

  useEffect(() => {
    setCategoryMenu(Categorymenudata)
  }, [Categorymenudata])

  useEffect(() => {
    let newcategory = Categorymenudata?.filter((element) => {
      if (element._id == params.category_id) {
        return element
      } else {
        return
      }
    })
    setSubCategoryFilter(newcategory[0]?.item)
  }, [Categorymenudata])

  const handleChange = (event, item) => {
    let tempProductFilter = [...productTypeFilter]
    if (productTypeFilter.includes(item._id)) {
      tempProductFilter = tempProductFilter.filter((x) => x !== item._id)
      setProductTypeFilter(tempProductFilter)
    } else {
      tempProductFilter.push(item._id)
      setProductTypeFilter(tempProductFilter)
    }
  }

  const handleMaterial = (material) => {
    let tempMaterialFilter = [...materialFilter]
    if (materialFilter.includes(material._id)) {
      tempMaterialFilter = tempMaterialFilter.filter((x) => x !== material._id)
      setMaterialFilter(tempMaterialFilter)
    } else {
      tempMaterialFilter.push(material._id)
      setMaterialFilter(tempMaterialFilter)
    }
  }

  const getAllUserProducts = async () => {
    let urlencoded = new URLSearchParams()
    if (params.category_id && params.category_id) {
      urlencoded.append('category_id', params.category_id)
      urlencoded.append('item_id', JSON.stringify(productTypeFilter))
      urlencoded.append('material_id', JSON.stringify(materialFilter) ? JSON.stringify(materialFilter) : '' )
    } else {
      urlencoded.append('item_id', JSON.stringify(productTypeFilter))
      urlencoded.append('material_id', JSON.stringify(materialFilter))
    }
    dispatch(AllproductSaga(urlencoded, navigate))
  }

  useEffect(() => {
    getAllUserProducts()
  }, [productTypeFilter, materialFilter])

  useEffect(() => {
    if (params.item_id) {
      productTypeFilter.pop()
      let tempProductFilter = [...productTypeFilter, params.item_id]
        setProductTypeFilter(tempProductFilter)
    }
  }, [params])


  return (
    <Box className="side-filter-main">
      <Box className="filter-text-box">
        <Typography variant="h3" className="filter-text">
          Filter
        </Typography>
        {/* <Button variant="text" className="reset-text">
          Reset all
        </Button> */}
      </Box>
      <Box className="filter-chekbox-main">
        <Accordion className="filter-accordian">
          <AccordionSummary
            className="filter-accordian-summary"
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className="filter-accordian-text">
              Product Type
            </Typography>
          </AccordionSummary>
          <AccordionDetails className="filter-accordian-details">
            {itemList?.map((item, err) => {
              return (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        productTypeFilter.includes(item._id) ? true : false
                      }
                      onChange={(e) => handleChange(e, item)}
                    />
                  }
                  label={item.item}
                  className="filter-lables"
                />
              )
            })}
          </AccordionDetails>
        </Accordion>

        <Accordion className="filter-accordian">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
            className="filter-accordian-summary"
          >
            <Typography className="filter-accordian-text">Material</Typography>
          </AccordionSummary>
          <AccordionDetails className="filter-accordian-details">
            {materialData.length
              ? materialData.map((material, index) => {
                  return (
                    <>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              materialFilter.includes(material?._id)
                                ? true
                                : false
                            }
                            onChange={(e) => handleMaterial(material)}
                          />
                        }
                        // control={<Checkbox />}
                        label={material.material}
                        // onChange={(e) => handleMaterial(e, material)}
                        className="filter-lables"
                      />
                    </>
                  )
                })
              : ''}
          </AccordionDetails>
        </Accordion>
      </Box>
    </Box>
  )
}

export default FilterSection
